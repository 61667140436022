import moment from "moment";
import {actions as productActionsCreate} from "@sagas/products/create";

import i18n from "@src/i18n";
import {configureStore} from "../../../../../../store/index";

const convert = (time) => moment(time).format("DD-MM-YYYY");

const store = configureStore();

const cancelationReserveDD = [
	{
		label: i18n.t("0%"),
		value: "0",
	},
	{
		label: i18n.t("5%"),
		value: "5",
	},
	{
		label: i18n.t("10%"),
		value: "10",
	},
	{
		label: i18n.t("15%"),
		value: "15",
	},
];

export const productElements = (client) => ({
	columns: [
		{
			name: i18n.t("ID"),
			key: "id",
			sortable: true,
			headerCellClass: "headerStyles",
		},
		{
			name: i18n.t("Product"),
			key: "name",
			headerCellClass: "headerStyles",
			image: "imageField",
			sortable: true,
			cellClass: "category-custom__cell",
			headerStyle: {
				fontFamily: "Montserrat",
				fontSize: "1.3rem",
			},
			cellStyle: {
				backgroundColor: "#44228b0d",
				color: "#6C1EB0",
				marginRight: "190px",
				borderRightStyle: "solid",
				borderRightColor: "#f5f5f5",
				borderRightWidth: "1px",
			},
		},
		{
			name: i18n.t("createdAt"),
			key: "createdAt",
			headerCellClass: "headerStyles",
			sortable: true,
			formatter: ({row}) => convert(row.createdAt),
		},
		{
			name: i18n.t("lastUpdated"),
			key: "updatedAt",
			headerCellClass: "headerStyles",
			sortable: true,
			formatter: ({row}) => convert(row.updatedAt),
		},
	],
	btnText: i18n.t("Submit"),

	lists: [
		{
			title: i18n.t("name"),
			field: "name",
			inputType: "text",
			required: true,
		},
		{
			title: i18n.t("ProductPrice"),
			field: "price",
			inputType: "number",
			min: 0,
			defaultValue: 0,
			required: true,
		},
		{
			title: i18n.t("Points"),
			field: "points",
			inputType: "number",
			min: 0,
			defaultValue: 0,
		},
		{
			title: i18n.t("receivedCommission"),
			field: "receivedCommision",
			inputType: "number",
			required: true,
		},
		{
			title: i18n.t("EmployeeProvision"),
			field: "employeeProvision",
			inputType: "number",
			required: true,
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("EmployeeShopProvision"),
			field: "employeeShopProvision",
			inputType: "number",
			required: true,
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("EmployeeTL"),
			field: "employeeTlProvision",
			inputType: "number",
			required: true,
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("EmployeeAB"),
			field: "employeeAbProvision",
			inputType: "number",
			required: true,
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("SPProvision"),
			field: "spProvision",
			inputType: "number",
			required: true,
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("AgencyProvision"),
			field: "agencyProvision",
			inputType: "number",
			required: true,
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("Agency70"),
			field: "agency70Provision",
			inputType: "number",
			required: true,
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("AgencySPProvision"),
			field: "agencySPProvision",
			inputType: "number",
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("TeamLeaderProvision"),
			field: "teamLeaderProvision",
			inputType: "number",
			hidden: client?.clientPayoutModel === "DISTRIBUTION_MODEL",
		},
		{
			title: i18n.t("cancellationReserve"),
			field: "cancellationReserve",
			inputType: "dropdown",
			required: true,
			options: cancelationReserveDD,
			inputValue: "obj",
		},
		{
			title: i18n.t("Tariff"),
			field: "tariffIds",
			inputType: "dropdown",
			required: true,
			multiple: true,
			inputValue: "obj",
		},
		{
			title: "",
			field: "cancellationReserveType",
			defaultValue: {
				name: "PERCENTAGE",
				value: "PERCENTAGE",
			},
			hidden: true,
			inputType: "dropdown",
		},
	],
	handleSubmit: {
		function: ({values, formActions}) =>
			store.dispatch(productActionsCreate.addProduct({values, formActions})),
	},

	setShowModal: {
		function: (bool) => store.dispatch(productActionsCreate.addProduct(bool)),
	},

	hasID: "id",
	modalTitle: i18n.t("Product"),
});
