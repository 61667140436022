import {Checkbox} from "antd";
import React from "react";

const CheckBoxComponentAntd = (props) => {
	const {
		label,
		touched,
		errors,
		errorClass = "errorClass",
		withTouched = true,
		noTouched = false,
		name,
		handleChange,
		checked,
		disabled,
	} = props;
	return (
		<div>
			<Checkbox name={name} checked={checked} disabled={disabled} onClick={handleChange}>
				{label}
			</Checkbox>
			{withTouched && touched && errors && (
				<span className={errorClass}>{touched && errors}</span>
			)}
			{noTouched && errors && <span className={errorClass}>{errors}</span>}
		</div>
	);
};

export default CheckBoxComponentAntd;
