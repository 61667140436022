import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation/index";
import {useTranslation} from "react-i18next";

function SeeMore(props) {
	const {t} = useTranslation();

	const {id, userId, navigate} = props;
	return (
		<span
			onClick={() => navigate(`/admin/calculations/${id}/${userId}`)}
			className="seeMore__btn"
		>
			{t("Details")}
		</span>
	);
}

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(SeeMore));
