import Logger from "@src/utils/logger";
import produce from "immer";
import axios from "axios";
import {ENDPOINT} from "@src/utils/endpoint";
import {put, takeLatest} from "redux-saga/effects";
import _ from "lodash";
import createAction from "../../../../utils/action-creator";

const logger = new Logger("Sagas>salesTracker>MapView");

const PREFIX = "@app/salesTracker/mapView";
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_LEADS_BY_MAP = `${PREFIX}GET_LEADS_BY_MAP`;
export const GET_LEADS_BY_MAP_SUCCESS = `${PREFIX}GET_LEADS_BY_MAP_SUCCESS`;

const _state = {
	loading: false,
	leads: {},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case GET_LEADS_BY_MAP_SUCCESS:
				const grouppedLeads = _.groupBy(
					payload || [],
					(item) => `${item.latitude},${item.longitude}`,
				);
				draft.leads = grouppedLeads;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getLeadsByMap: (payload) => createAction(GET_LEADS_BY_MAP, {payload}),
	getLEadsByMapSuccess: (payload) => createAction(GET_LEADS_BY_MAP_SUCCESS, {payload}),
};
export const sagas = {
	*getLeadsByMap({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {data} = yield axios.post(ENDPOINT.GET_LEADS_BY_MAP, payload);
			yield put(actions.getLEadsByMapSuccess(data?.data || []));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_LEADS_BY_MAP, sagas.getLeadsByMap);
};
