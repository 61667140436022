export const colors = {
	AGENCY: "#1C4CCF",
	// "AGENCY-SP": "#A6DFFF",
	EMPLOYEE: "#6C1EB0",
	SP: "#0E88F2",
};

export const optionsBarGraph = {
	scales: {
		yAxes: {
			ticks: {
				beginAtZero: true,
				callback(value) {
					if (value % 1 === 0) {
						return value;
					}
				},
			},
		},
	},
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		tooltip: {
			backgroundColor: "#f5f5f5",
			titleColor: "#3C515E",
			titleFont: {
				size: 12,
				weight: 700,
			},
			bodyColor: "#3C515E",
			displayColors: true,
			borderColor: "#3C515E",
			borderWidth: 0.5,
			bodyFont: {
				size: 11,
				weight: 600,
			},
		},
		datalabels: {
			display: true,
			font: {
				weight: "500",
				size: 12,
			},
			formatter: (value, ctx) => value || "",
			anchor: "end",
			align: "end",
		},
		legend: {
			display: true,
			align: "start",
			position: "bottom",
			labels: {
				usePointStyle: true,
				pointStyle: "rounded",
				boxHeight: 30,
				boxWidth: 30,
				padding: 15,
				font: {
					size: 13,
				},
			},
		},
		title: {
			display: false,
		},
	},
};
export const optionsPercentageGraph = {
	responsive: true,
	plugins: {
		formatter: (val, context) => `${val}%`,
		legend: {
			display: true,
			align: "center",
			position: "bottom",
			labels: {
				usePointStyle: true,
				pointStyle: "rounded",
				boxHeight: 30,
				boxWidth: 30,
				padding: 15,
				font: {
					size: 13,
				},
			},
		},
		tooltip: {
			callbacks: {
				label: (ttItem) => `${ttItem.label}: ${ttItem.parsed}%`,
			},
			backgroundColor: "#f5f5f5",
			bodyColor: "#3C515E",
			displayColors: true,
			borderColor: "#3C515E",
			borderWidth: 0.5,
			bodyFont: {
				size: 11,
				weight: 700,
			},
		},
		datalabels: {
			display: true,
			color: "#fff",
			font: {
				weight: "500",
				size: 12,
			},
			formatter: (value, ctx) => {
				const {datasets} = ctx.chart.data;
				if (ctx.datasetIndex === datasets.length - 1) {
					let sum = 0;
					// eslint-disable-next-line array-callback-return
					datasets.map((dataset) => {
						sum += dataset.data[ctx.dataIndex];
					});
					return sum !== 0 ? `${sum.toLocaleString(/* ... */)}%` : "";
				}
				return "";
			},
			anchor: "center",
			align: "center",
		},
	},
};
export const percentageGraphColors = {
	backgroundColor: ["#1C4CCF", "#6C1EB0", "#0E88F2"],
	// borderColor: ["#D7F0FF", "#A6DFFF", "#38B6FF", "#4695c3"],
};
