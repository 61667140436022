import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as categoryActions} from "@sagas/projectCategories/clientCategory";
import {actions as deletedCategoryActions} from "@sagas/projectCategories/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Sagas>Delete>Index");

const PREFIX = "@app/tariffs/deleteClientCategory";
export const DELETE_CATEGORY = `${PREFIX}DELETE_CATEGORY`;
export const DEACTIVATE_CATEGORY = `${PREFIX}DEACTIVATE_CATEGORY`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_DELETE_MODAL = `${PREFIX}SHOW_DELETE_MODAL`;

const _state = {
	loading: false,
	deleteShowModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_DELETE_MODAL:
				draft.deleteShowModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteCategory: (payload) => createAction(DELETE_CATEGORY, {payload}),
	deactivateCategory: (payload) => createAction(DEACTIVATE_CATEGORY, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowDeleteModal: (payload) => createAction(SHOW_DELETE_MODAL, {payload}),
};

export const sagas = {
	*deactivateCategory({payload}) {
		yield put(actions.setLoading(true));

		try {
			const categories = yield select(
				(state) => state.app.projectCategories.clientCategory.clientCategoriesOrder,
			);
			const {id} = payload;
			const {totalSize} = yield select((state) => state.app.projectCategories.deleted);
			const category = categories.find((e) => e.id === id);
			yield axios.put(`/projectCategory/deactivate/${id}`);
			yield put(categoryActions.deactivateCategoryOnState(id));
			yield put(deletedCategoryActions.add(category));
			ToastSuccesComponent(i18n.t("CategoryDeactiveSuccess"));
			yield put(actions.setShowDeleteModal(false));
			yield put(deletedCategoryActions.editTotalSize(totalSize + 1));
			yield put(categoryActions.clientCategoryAddDecreaseTotalSize());
			payload.handleCategoryModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteCategory({payload}) {
		yield put(actions.setLoading(true));

		try {
			const {totalSize} = yield select((state) => state.app.projectCategories.deleted);
			yield axios.put(`/projectCategory/delete/${payload?.id}`);
			yield put(deletedCategoryActions.delete(payload?.id));
			ToastSuccesComponent(i18n.t("CategoryDeleteSuccess"));
			payload.handleCategoryModal(false);
			yield put(deletedCategoryActions.editTotalSize(totalSize - 1));
			// yield put(categoryActions.clientCategoryAddDecreaseTotalSize());
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DEACTIVATE_CATEGORY, sagas.deactivateCategory);
	yield takeLatest(DELETE_CATEGORY, sagas.deleteCategory);
};
