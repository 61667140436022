import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {ReactComponent as ArrowDown} from "@assets/img/arrow_down.svg";

const GroupAccordion = ({data}) => {
	const [expanded, setExpanded] = React.useState("User");

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Accordion expanded={expanded === data.group} onChange={handleChange(data.group)}>
			{data.group !== "allOptions" && (
				<AccordionSummary
					expandIcon={<ArrowDown />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					{data.group}
				</AccordionSummary>
			)}
			<li>{data?.children}</li>
		</Accordion>
	);
};
export default GroupAccordion;
