import React from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import moment from "moment";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const CalculationTablePdf = (props) => {
	const {data, id} = props;
	const {t} = useTranslation();
	const billingMonth = data?.details?.billingMonthsView;
	const firstContract = Math.min(...data?.data?.map((item) => item?.createdAt));
	const lastContract = Math.max(...data?.data?.map((item) => item?.createdAt));
	const firstContractMonth = moment(firstContract)?.format("MMMM YYYY");
	const lastContractMonth = moment(lastContract)?.format("MMMM YYYY");
	const firstAndLastMonth = `${firstContractMonth} ${firstContractMonth !== lastContractMonth ? `- ${lastContractMonth}` : ""}`
	const label = billingMonth?.id ? "Abrechnungsmonat" : "Abrechng. - Zeitraum";
	const value = billingMonth?.id ? `${t(billingMonth?.month)} - ${billingMonth?.year}` : firstAndLastMonth;
	return (
		<>
			<View style={styles.topContentSecondTable}>
				{/* <View style={{...styles.topContentTableRows, fontFamily: "Helvetica-Bold"}}>
					<Text
						style={{
							marginLeft: 4,
							width: 100,
						}}
					>
						Status
					</Text>
					{data?.details?.agencyType === "SIMPLE" ? (
						<Text
							style={{
								width: 100,
								marginLeft: 4,
								wordWrap: "break-word",
							}}
						>
							{data?.details?.spRole === "AGENCY-SP"
								? `${i18n.t("AGENCYSP")} - ${data?.details?.companyName}`
								: i18n.t(`${data?.details?.spRole}`)}
						</Text>
					) : (
						<Text
							style={{
								width: 100,
								marginLeft: 4,
								wordWrap: "break-word",
							}}
						>
							{data?.details?.spRole === "AGENCY-SP"
								? `${i18n.t("AGENCYSP")} - ${data?.details?.spFullName}`
								: i18n.t(`${data?.details?.spRole}`)}
						</Text>
					)}
				</View> */}
				<View style={styles.topContentTableRows}>
					<Text
						style={{
							...styles.secondTableRow,
							borderRight: "1px #080808 solid",
							width: 80,
						}}
					>
						{i18n.t("BillingNumber")}
					</Text>
					<Text style={styles.secondTableContent}>{id}</Text>
				</View>
				<View style={{...styles.topContentTableRow, fontFamily: "Helvetica-Bold"}}>
					<Text
						style={{
							...styles.secondTableRow,
							borderRight: "1px #080808 solid",
							width: 80,
						}}
					>
						{i18n.t("BillingDate")}
					</Text>

					<Text style={styles.secondTableContent}>
						{moment(data?.date).format("DD-MM-YYYY")}
					</Text>
				</View>
				<View
					style={{
						...styles.topContentTableRow,
						borderTop: "1px #080808 solid",
						width: 200,
					}}
				>
					<Text
						style={{
							...styles.secondTableRow,
							borderRight: "1px #080808 solid",
							width: 80,
						}}
					>
						{label}
					</Text>
					<Text
						style={{
							...styles.secondTableContent,
							marginRight: 2,
						}}
					>
						{value}
					</Text>
				</View>
			</View>
		</>
	);
};

export default CalculationTablePdf;

const styles = StyleSheet.create({
	topContentTableRows: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px #000 solid",
	},
	topContentTableRow: {
		display: "flex",
		flexDirection: "row",
		fontSize: 7,
		fontFamily: "Helvetica",
	},
	secondTableRow: {
		marginLeft: 4,
		width: 100,
		padding: 2,
		fontSize: 7,
		fontFamily: "Helvetica",
	},
	topContentSecondTable: {
		display: "flex",
		flexDirection: "column",
		fontSize: "8px",
		border: "1px #000 solid",
		width: "200px",
	},
	topContentTableFooter: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "#e7e6e6",
	},
	tableThreeAndTitle: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 10,
	},
	secondTableContent: {
		marginLeft: 4,
		padding: 2,
		fontFamily: "Helvetica",
		fontSize: 7,
		width: 120,
		textAlign: "right",
	},
});
