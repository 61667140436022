/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {DATE_FORMAT} from "@src/common/constants";
import i18n from "@src/i18n";
import {formatNumber} from "@src/utils/convertNumber";
import moment from "moment";
import * as XLSX from "xlsx";

export const updateCustomFieldAttachments = (state, name, newValue, type = "add", arr) => {
	const object = {};
	const values = Object.entries(state);
	let fieldExists = false;
	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of values) {
		if (key === name) {
			const attachmentsArray = value?.value ? JSON.parse(value?.value) : [];
			fieldExists = true;
			const updatedArray =
				type === "add"
					? [...attachmentsArray, newValue]
					: attachmentsArray?.filter((item) => item !== newValue);

			object[key] = {
				...value,
				value: JSON.stringify(updatedArray),
			};
		} else {
			object[key] = {
				...value,
			};
		}
	}

	if (!fieldExists) {
		arr.forEach((it) => {
			if (it.name === name) {
				object[it.name] = {
					customFieldId: it.fieldId,
					key: it.name,
					value: JSON.stringify([newValue]),
					labelEn: it.labelEn,
					labelDe: it.labelDe,
					inputType: it.inputType,
					dropDownChilds: null,
					customOptionsString: null,
					isRequired: it.isRequired,
				};
			}
		});
	}
	return object;
};

const NON_MONETARY_CELLS = [
	"vpId",
	"name",
	"employeeStatus",
	"totalContractsWithCancellation",
	"status",
	"pointsWithCancellation",
];

export const exportColumns = [
	{
		field: "vpId",
		name: "VP-ID",
	},
	{
		field: "name",
		name: i18n.t("UserSP"),
	},
	{
		field: "employeeStatus",
		name: i18n.t("EmployeeStatus"),
	},
	{
		field: "totalContractsWithCancellation",
		name: i18n.t("NumOfContracts"),
	},
	{
		field: "pointsWithCancellation",
		name: i18n.t("NumOfPoints"),
	},
	{
		field: "totalCommisionWithoutCancellation",
		name: i18n.t("ProvisionNew"),
	},
	{
		field: "totalCommisionCancellation",
		name: i18n.t("StornoNew"),
	},
	{
		field: "totalBilledOverheadBonusOrPayments",
		name: i18n.t("OverheadBonuses"),
	},
	{
		field: "manualCorrectionPrice",
		name: i18n.t("ManualBookings"),
	},
	{
		field: "thisMonthCancellationReserve",
		name: i18n.t("PayoutCancellationReserve"),
	},
	{
		field: "sumDeductions",
		name: i18n.t("DepositCancellationReserve"),
	},
	{
		field: "canceledContractsCancellationReserve",
		name: i18n.t("ValueAddedtax"),
	},
	{
		field: "sumCommisionAndOverhead",
		name: i18n.t("WithdrawalAmount"),
	},
	{
		field: "cancellationReserveBeforeBilling",
		name: i18n.t("OldCancellationReserve"),
	},
	{
		field: "cancellationReserveAfterBilling",
		name: i18n.t("NewCancellationReserve"),
	},
	{
		field: "status",
		name: i18n.t("BillingStatus"),
	},
];

export const generateAndDownloadExcel = (date, data, billingMonth) => {
	const startDate = moment(date.fromDate).format(DATE_FORMAT);
	const endDate = moment(date.toDate).format(DATE_FORMAT);
	const MAX_LENGTH_ALLOWED = 32767;
	const wb = XLSX.utils.book_new();
	const xlsxTitles = exportColumns?.map((col) => col.name);
	const csvData = data?.map((item) =>
		exportColumns.map((col) => {
			const currentValue = item?.[col.field];
			if (currentValue?.length > MAX_LENGTH_ALLOWED) return "";
			if (col.field === "sumCommisionAndOverhead") {
				return `${formatNumber(
					item?.totalCommisionWithoutCancellation +
						item?.manualCorrectionPrice +
						item?.canceledContractsCancellationReserve,
				)} €`;
			}
			if (!NON_MONETARY_CELLS.includes(col.field)) {
				return `${formatNumber(currentValue || 0)} €`;
			}
			if (col.field === "employeeStatus" || col.field === "status") {
				return i18n.t(currentValue);
			}
			return `${currentValue}`;
		}),
	);
	const exportData = [xlsxTitles, ...csvData];
	const ws = XLSX.utils.aoa_to_sheet(exportData);
	const wscols = xlsxTitles?.map(() => ({wch: 25}));
	ws["!cols"] = wscols;
	XLSX.utils.book_append_sheet(wb, ws);
	const fileName = billingMonth
		? `${i18n.t(billingMonth?.month)} - ${billingMonth?.year}`
		: `${startDate} - ${endDate}`;
	XLSX.writeFileXLSX(wb, `Übersicht Abrechnung Chef - ${fileName}.xlsx`);
};

const timezone = new Date().getTimezoneOffset();

export const buildPayload = (payload, query) => {
	const getUsersValue = (value) => {
		if (typeof value === "number") {
			return [value];
		}
		return value;
	};
	const object = {
		search: payload?.search || query?.search,
		fromDate: payload?.dashboardPage
			? null
			: moment(payload?.fromDate || query?.fromDate)
					.startOf("day")
					.subtract(timezone, "minutes")
					.valueOf(),
		toDate: payload?.dashboardPage
			? null
			: moment(payload?.toDate || query?.toDate)
					.endOf("day")
					.subtract(timezone, "minutes")
					.valueOf(),
		clientId: payload?.clientId || query?.clientId,
		systemStatusId: payload?.systemStatusId || query?.systemStatusId,
		unFilteredStatus: payload?.unFilteredStatus || query?.unFilteredStatus,
		teamId: payload?.teamId || query?.teamId,
		agencyId: payload?.agencyId || query?.agencyId,
		locationId: payload?.locationId || query?.locationId,
		export: false,
		isExactSearch:
			payload?.search === "" ? false : payload?.isExactSearch || query?.isExactSearch,
		projectCategoryId: payload?.projectCategoryId || query?.projectCategoryId,
		userIds: payload !== null ? getUsersValue(payload?.userIds) : getUsersValue(query.userIds),
		salesOrganizationId: payload?.salesOrganizationId || query?.salesOrganizationId,
		clientPayoutModel: payload?.clientPayoutModel || query?.clientPayoutModel,
	};
	return object;
};
