import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as AttachLogo} from "@assets/img/attachment_black_24dp.svg";
import {actions as createMessageActions} from "@sagas/messages/create";
import InputComponent from "@common/input";
import {Formik} from "formik";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import moment from "moment";
import {Popper} from "@mui/material";
import {handleInputShowValue, handleOutputShowValue} from "@utils/message-converter";
import {ToastInfoComponent} from "@common/ToastComponent/ToastComponent";
import {
	SendMessageIcon,
	SendMessageSecondIcon,
	UploadCompletedIcon,
} from "@src/assets/SvgComponents";
import i18n from "../../../i18n";
import ReplyMessageComponent from "./replyMessage";
import UploadFilesContainer from "./UploadFilesContainer";

const BottomComponents = ({
	createMessage,
	topicId,
	loggedUser,
	addingNewMessage,
	createGroupOrPrivateMessage,
	listOfNames,
	loadingFirstMess,
	setNames,
	uploadFile,
	messageToReply,
	insideTopic,
	setMessageToReply,
	setList,
	isDeactivated,
	files,
	setFiles,
}) => {
	const users = insideTopic?.topicMembers;
	const members = users?.filter((user) => user?.id !== loggedUser?.id);
	const formRef = useRef();
	const ref = useRef();
	const mentionRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [usersState, setUsersState] = useState([]);
	const [mentionIds, setMentionIds] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [activeIndex, setActiveIndex] = useState(0);
	const [hasWriteAccess, setHasWriteAcces] = useState(insideTopic?.writeAccess);
	const [object, setObject] = useState([]);
	const [showUploaded, setShowUploaded] = useState(false);
	useEffect(() => {
		const mentionedUsersOnly = mentionIds.filter((mention) =>
			inputValue.includes(mention.value),
		);
		setMentionIds(mentionedUsersOnly);
		const splittedValue = inputValue.split(" ");
		if (splittedValue.some((value) => value === "@")) {
			setUsersState(members?.filter((it) => !it?.deactivatedAt));
		} else if (usersState.length === 0) {
			setAnchorEl(null);
			setActiveIndex(0);
		}
		setActiveIndex(0);
	}, [inputValue]);
	useEffect(() => {
		formRef.current.resetForm();
		setFiles([]);
		setMessageToReply(null);
		setInputValue("");
		setMentionIds([]);
		setAnchorEl(null);
		setActiveIndex(0);
		if (addingNewMessage) {
			setHasWriteAcces(true);
			if (listOfNames?.length === 0) {
				setInputValue("");
			} else {
				setInputValue(inputValue);
			}
		} else {
			setHasWriteAcces(users?.find((user) => user?.id === loggedUser?.id)?.writeAccess);
		}
		if (isDeactivated && !addingNewMessage && insideTopic?.topicType === "PRIVATE") {
			setHasWriteAcces(false);
		}
	}, [topicId, addingNewMessage, isDeactivated, listOfNames?.length]);
	const handleChangeInput = (e) => {
		let filteredArr = [],
			splittedSearch = e.split(" ");
		splittedSearch = splittedSearch.length > 1 ? e.split(" ") : e.split("");
		if (e?.length > 0 && e?.charAt(e.length - 2) === " " && e.charAt(e.length - 1) === "@") {
			setAnchorEl(ref.current);
		} else if (e?.length === 1 && e.charAt(e.length - 1) === "@") {
			filteredArr = members?.filter((item) =>
				item?.firstName?.toLowerCase().startsWith(e?.toLowerCase().replace("@", "")),
			);
			setAnchorEl(ref.current);
		} else if (
			splittedSearch[splittedSearch?.length - 1]?.charAt(0) === "@" ||
			(splittedSearch[0] === "@" && e.charAt(e.length - 1) !== " ")
		) {
			if (splittedSearch[0] === "@" && e.charAt(e.length - 1) !== " ") {
				filteredArr = members?.filter((item) =>
					item?.firstName?.toLowerCase().startsWith(e?.toLowerCase().replace("@", "")),
				);
			} else {
				filteredArr = users?.filter((item) =>
					item?.firstName
						?.toLowerCase()
						.startsWith(
							splittedSearch[splittedSearch?.length - 1]
								?.toLowerCase()
								.replace("@", ""),
						),
				);
			}
			setUsersState(filteredArr);
			setAnchorEl(ref.current);
		} else {
			setAnchorEl(null);
			setActiveIndex(0);
		}
		if (e.charAt(e.length - 1) === " " || e.charAt(e.length - 1) === "@") {
			// Reset Search
			setUsersState([]);
		}
		if (addingNewMessage) {
			setAnchorEl(null);
			setUsersState([]);
		}
		const testMesssage = handleInputShowValue(e);
		setInputValue(testMesssage);
	};
	const handleSelectUser = (user) => {
		const inputParts = inputValue.split("@");
		const lastPart = inputParts.pop();
		const updatedValue = `${inputParts.join("@")}${
			user
				? `{{id: ${user.id}, name: ${user.firstName}, topicId: ${user?.topicId || null}}}${
						user?.firstName?.toLowerCase().startsWith(lastPart.toLowerCase())
							? ""
							: lastPart
				  }`
				: ""
		}`;
		const testMesssage = handleInputShowValue(updatedValue);
		setInputValue(testMesssage);
		setAnchorEl(null);
		setActiveIndex(0);
		const manyObjects = handleInputShowValue(updatedValue, setObject);
		setObject([...object, ...manyObjects]);
	};
	const handleCopy = async (e) => {
		if (inputValue.includes(e)) {
			const copiedUser = handleOutputShowValue(e, object);
			const copiedObject = handleInputShowValue(copiedUser, setObject);
			setObject([...object, ...copiedObject]);
		}
	};
	const asyncMessage = async (files, finalValue) => {
		const promises = files.map((file, index) =>
			createMessage({
				topicId,
				text: index === files?.length - 1 ? finalValue || inputValue : "",
				files: [file.id],
				userId: loggedUser?.id,
				fullName: `${loggedUser?.firstName} ${loggedUser?.lastName}`,
				mentionIds:
					index === files.length - 1 ? mentionIds.map((mention) => mention.id) : [],
				createdAtTimestamp: moment().valueOf(),
				replyId:
					messageToReply !== null && index === files.length - 1
						? messageToReply.id
						: null,
			}),
		);

		await Promise.all(promises);
	};

	const submitMessage = ({formActions}) => {
		const finalValue = handleOutputShowValue(inputValue, object);
		const messageObject = {
			text: finalValue !== "" ? finalValue : inputValue,
			files: [],
			userId: loggedUser.id,
			fullName: `${loggedUser?.firstName} ${loggedUser?.lastName}`,
			mentionIds: mentionIds.map((mention) => mention.id),
			createdAtTimestamp: moment().valueOf(),
			replyId: messageToReply !== null ? messageToReply.id : null,
		};
		if (inputValue.trim() !== "" || files.length > 0) {
			if (files?.some((file) => file?.loading)) return;
			if (!addingNewMessage) {
				if (files?.length > 0) {
					asyncMessage(files, inputValue);
				} else {
					createMessage({
						...messageObject,
						topicId,
					});
				}
			} else if (addingNewMessage && files?.length > 0) {
				createGroupOrPrivateMessage({
					...messageObject,
					files,
					fromMention: true,
				});
			} else if (addingNewMessage && files?.length === 0) {
				createGroupOrPrivateMessage(messageObject);
			}
			formActions.resetForm(
				{comment: "", files: {}},
				setFiles([]),
				setMessageToReply(null),
				setInputValue(""),
				setObject([]),
				setUsersState([]),
				setAnchorEl(null),
				setActiveIndex(0),
			);
			setNames([]);
			setList([]);
		}
	};

	const handleFileUpload = (event) => {
		const fileList = event.target.files;
		if (files.length + fileList.length > 5) {
			ToastInfoComponent(i18n.t("UploadLengthInfo"));
			event.preventDefault();
			return;
		}
		const fileDataArray = Array.from(fileList).map(
			(file) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onloadend = () => {
						resolve({
							filename: file.name,
							base64: reader.result.split(",")[1],
							type: file.type,
							extension: file.name.split(".").pop()?.toLowerCase(),
							size: file.size,
						});
					};
					reader.onerror = () => {
						reader.abort();
						reject(new DOMException("Problem parsing input file."));
					};
				}),
		);
		setShowUploaded(true);
		Promise.all(fileDataArray).then(uploadFile);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;
	const handleKeyDown = (e, handleSubmit) => {
		if (e.key === "ArrowUp") {
			e.preventDefault();
			setActiveIndex((prevState) => prevState - 1);
			if (activeIndex === 0) {
				setActiveIndex(usersState?.length - 1);
			}
		} else if (e.key === "ArrowDown") {
			e.preventDefault();
			setActiveIndex((prevState) => prevState + 1);
			if (activeIndex === usersState?.length) {
				setActiveIndex(0);
			}
		}
		if (e.key === "Enter") {
			e.preventDefault();
			if (open && usersState?.length > 0) {
				handleSelectUser(usersState[activeIndex]);
			} else {
				handleSubmit();
			}
		}
	};
	useEffect(() => {
		if (mentionRef.current) {
			mentionRef.current.scrollIntoView({behavior: "smooth", block: "end"});
		}
	}, [activeIndex]);

	return (
		<>
			{files?.[0] && showUploaded && (
				<>
					<UploadFilesContainer files={files} hasReply={messageToReply} />
				</>
			)}
			{messageToReply && (
				<>
					<ReplyMessageComponent item={messageToReply} setValue={setMessageToReply} />
				</>
			)}
			<Popper
				id={id}
				open={open}
				anchorEl={anchorEl}
				placement="top-start"
				anchorOrigin="right"
				sx={{
					zIndex: 50,
				}}
			>
				<div className="popper__container">
					{usersState?.map((item, index) => (
						<div
							key={index}
							onClick={() => {
								handleSelectUser(item);
								setMentionIds((prevState) => [
									...prevState,
									{id: item?.id, value: item?.firstName},
								]);
							}}
							className={`popper__container-user ${
								index === activeIndex ? "active" : ""
							}`}
							ref={index === activeIndex ? mentionRef : null}
						>
							{item.name}
						</div>
					))}
				</div>
			</Popper>
			<Formik
				initialValues={{comment: "", files: {}}}
				innerRef={formRef}
				onSubmit={(values, actions) => submitMessage({values, formActions: actions})}
			>
				{({handleSubmit, setFieldValue}) =>
					hasWriteAccess && (
						<form
							className="bottom__components__form"
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
						>
							<div className="bottom__components__container">
								<div
									className="emoji__and__attachments"
									style={{
										opacity:
											addingNewMessage && listOfNames?.length === 0 ? 0.5 : 1,
									}}
								>
									<label htmlFor="file">
										{files?.length >= 5 ? (
											<div
												className="emoji__and__attachments-uploadComplete"
												onClick={() => setShowUploaded(!showUploaded)}
											>
												<UploadCompletedIcon width={24} height={24} />
											</div>
										) : (
											<AttachLogo className="attach__logo" />
										)}
										<input
											type="file"
											style={{display: "none"}}
											id="file"
											name="files"
											multiple={true}
											disabled={
												files?.length >= 5 ||
												(addingNewMessage && listOfNames?.length === 0)
											}
											onChange={(e) => {
												handleFileUpload(e);
												setFieldValue("files", e.target.files[0]);
											}}
											// eslint-disable-next-line max-len
											accept=".png, .jpg, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .mp4 , application/vnd.openxmlformats-officedocument.presentationml.presentation , application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
										/>
									</label>
									<div className="vertical_border"></div>
								</div>
								<div className="input__and__send__icon">
									<InputComponent
										disabled={
											loadingFirstMess ||
											(addingNewMessage && !listOfNames.length)
										}
										onPaste={(e) => handleCopy(e.target.value)}
										values={inputValue}
										onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
										handleChange={(e) => {
											setFieldValue("comment", e.target.value);
											handleChangeInput(e.target.value);
										}}
										className="send__message__input"
										placeholder={i18n.t("WriteMessage")}
										errorClass="errorClass"
										label=" "
										inputRef={ref}
									/>
									{!loadingFirstMess && (
										<div
											onClick={() => handleSubmit()}
											type="submit"
											className="send__logo"
										>
											{inputValue.trim() === "" ? (
												<SendMessageIcon />
											) : (
												<SendMessageSecondIcon />
											)}
										</div>
									)}
								</div>
							</div>
						</form>
					)
				}
			</Formik>
		</>
	);
};
const mapStateToProps = (state) => ({
	topicId: state.app.messages.index.topicId,
	listOfNames: state.app.messages.create.listOfNames,
	loggedUser: state.app.users.index.loggedUser,
	addingNewMessage: state.app.messages.create.addingNewMessage,
	loadingFirstMess: state.app.messages.create.loadingFirstMess,
	insideTopic: state.app.messages.index.insideTopic,
	files: state.app.messages.create.files,
});

const mapDispatchToProps = {
	setNames: createMessageActions.setNames,
	setList: createMessageActions.setList,
	createMessage: createMessageActions.createMessage,
	createGroupOrPrivateMessage: createMessageActions.createGroupOrPrivateMessage,
	uploadFile: createMessageActions.uploadFile,
	setFiles: createMessageActions.setFiles,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BottomComponents));
