import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as navigation} from "@sagas/navigation";
import i18n from "@src/i18n";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";

const logger = new Logger("Sagas>Orders>Import");
const PREFIX = "@app/orders/imports";

export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_IMPORTS = `${PREFIX}GET_IMPORTS`;
export const GET_IMPORTS_SUCCESS = `${PREFIX}GET_IMPORTS_SUCCESS`;
export const GET_IMPORT_BY_ID = `${PREFIX}GET_IMPORT_BY_ID`;
export const GET_IMPORT_BY_ID_SUCCESS = `${PREFIX}GET_IMPORT_BY_ID_SUCCESS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_SIZE = `${PREFIX}EDIT_SIZE`;
export const SET_SEARCH_VALUE = `${PREFIX}SET_SEARCH_VALUE`;
export const IMPORT_CSV = `${PREFIX}IMPORT_CSV`;
export const SET_FILTERED_OPTIONS = `${PREFIX}SET_FILTERED_OPTIONS`;

const _state = {
	imports: [],
	import: {},
	showModal: false,
	loading: false,
	search: "",
	page: 1,
	size: 30,
	totalPages: 1,
	totalSize: 1,
	options: [],
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case GET_IMPORTS_SUCCESS:
				const {data, totalPages, totalSize} = payload;
				draft.imports = state?.page > 1 ? [...state.imports, ...data] : data;
				draft.totalPages = totalPages;
				draft.totalSize = totalSize;
				break;
			case SHOW_MODAL:
				draft.showModal = payload;
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case EDIT_SIZE:
				draft.size = payload;
				break;
			case SET_SEARCH_VALUE:
				draft.search = payload;
				break;
			case GET_IMPORT_BY_ID_SUCCESS:
				draft.import = payload;
				break;
			case SET_FILTERED_OPTIONS:
				draft.options = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	showModal: (payload) => createAction(SHOW_MODAL, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getImports: (payload) => createAction(GET_IMPORTS, {payload}),
	getImportsSuccess: (payload) => createAction(GET_IMPORTS_SUCCESS, {payload}),
	setSearch: (payload) => createAction(SET_SEARCH_VALUE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editSize: (payload) => createAction(EDIT_SIZE, {payload}),
	importCsv: (payload) => createAction(IMPORT_CSV, {payload}),
	getImportById: (payload) => createAction(GET_IMPORT_BY_ID, {payload}),
	getImportByIDSuccess: (payload) => createAction(GET_IMPORT_BY_ID_SUCCESS, {payload}),
	setFilteredOptions: (payload) => createAction(SET_FILTERED_OPTIONS, {payload}),
};

export const sagas = {
	*getImports() {
		yield put(actions.setLoading(true));
		try {
			const {search, page, size} = yield select((state) => state.app.orders.import);
			const response = yield axios.post(
				`/csv/import_orders_csv_files?page=${page}&size=${size}`,
				{
					search,
					from: null,
					to: null,
				},
			);
			yield put(actions.getImportsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getImportById({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/csv/import_orders_csv_data/${payload}`);
			yield put(actions.getImportByIDSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*importCsv({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {csvData, rowsElem, clientId, userIds} = yield select(
				(state) => state.app.imports.create,
			);
			const {imports} = yield select((state) => state.app.orders.import);
			const foundedImport = imports?.find((item) => item?.id === payload?.data?.id);
			const obj = {
				...csvData,
				data: rowsElem,
			};
			if (payload?.checkErrors) {
				const {name, userId, clientId, status} = foundedImport;
				if (status === "In Process" || status === "Importing") {
					ToastInfoComponent(i18n.t("YouCantHaveActions"));
				} else {
					const {data, createOrder} = payload;
					yield axios.post(
						`/csv/import_orders_check_errors?id=${data?.id}&createOrder=${createOrder}`,
						{
							importCsvData: {
								columns: data?.headers,
								data: data?.rows?.map((it) => it?.payload),
								fileName: name,
							},
							userId,
							clientId,
						},
					);
				}
			} else {
				yield axios.post("/csv/import_orders_check_errors", {
					importCsvData: obj,
					userId: userIds[0],
					clientId,
				});
			}
			yield put(actions.showModal(false));
			yield put(navigation.navigate("/admin/orders"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_IMPORTS, sagas.getImports);
	yield takeLatest(IMPORT_CSV, sagas.importCsv);
	yield takeLatest(GET_IMPORT_BY_ID, sagas.getImportById);
};
