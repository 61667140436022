import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
// components
import {Redirect} from "react-router-dom";
import {LoginBigImage} from "@assets/SvgComponents";
import ForgotPassword from "./components/forgot";
import Login from "./components/login";
import Register from "./components/register";
import {actions as navigation} from "../../store/sagas/app/navigation";
import {getCurrentUser} from "../../utils/currentUser";

const Auth = ({navigate, match}) => {
	const user = getCurrentUser();
	const page = match.params.toggle;

	if (page === "forgot-password") {
		return (
			<>
				<div className="auth">
					<ForgotPassword classNames="middle" navigation={navigate} />
				</div>
			</>
		);
	}
	return (
		<>
			{!user ? (
				<div className="auth">
					{page === "login" && (
						<>
							<div className="left">
								<LoginBigImage />
							</div>
							<Login classNames="middle" navigation={navigate} />
						</>
					)}
					{page === "register" && <Register classNames="middle" navigation={navigate} />}
				</div>
			) : (
				<Redirect to="/" />
			)}
		</>
	);
};

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(Auth));
