import _ from "lodash";
import React from "react";

const PreviewInput = ({item, values, isLast}) => (
	<div className={`single-input ${isLast && "borderLess"}`}>
		<span className="title">{item.title}</span>
		<span className="value" style={{color: item.itemColor}}>
			{_.get(values, item.field) || item?.defaultValue || "-"}
		</span>
	</div>
);

export default PreviewInput;
