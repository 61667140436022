import i18n from "../../../i18n";

export const leadStatuses = [
	{id: 1, name: i18n.t("NotReached"), value: "NOT_REACHED"},
	{id: 2, name: i18n.t("NoInterest"), value: "NO_INTEREST"},
	{id: 3, name: i18n.t("FollowUp"), value: "FOLLOW_UP"},
	{id: 4, name: i18n.t("Appointment"), value: "APPOINTMENT"},
];
export const leadStatusTypes = [
	{value: "NORMAL", label: "NORMAL"},
	{value: "DATE", label: "DATE"},
];

export const salesTrackerButtonProps = {
	borderRadius: "8px",
	background: "linear-gradient(94.98deg, rgb(108, 30, 176) 0%, rgb(64, 0, 119) 100%)",
	width: "30px",
	minWidth: "30px",
	height: "30px",
	leftRounded: true,
	text: "",
};
