import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";

export const handleError = ({error, customMessage = ""}) => {
	if (error?.includes("|")) {
		const [_, message] = error?.split("|");
		ToastErrorComponent(message);
	} else if (customMessage) {
		ToastErrorComponent(customMessage);
	} else {
		ToastErrorComponent(i18n.t("unexpectedError"));
	}
};
