import React, {useState} from "react";
import {ExpiredDateIcon, SelectedIcon, ShowInfoIconBlack} from "@src/assets/SvgComponents";
import CustomPopover from "@src/common/Popover";
import {connect} from "react-redux";
import {actions as goalsActions} from "@sagas/analytics/index";
import {actions as deleteGoals} from "@sagas/analytics/goals/delete";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import HtmlTooltip from "@src/common/htmlTooltip";
import {useTranslation} from "react-i18next";
import GoalInformationComponent from "./GoalInformationComponent";
import ProgressCard from "./ProgressBar";
import {
	cardWrapperStyles,
	findStatus,
	getDisplayingDate,
	modalInitialValue,
	showMobile,
} from "../staticData";
import StatusInfo from "./components/StatusInfo";
import GoalActions from "./components/GoalActions";

const AlertsCards = ({
	item,
	setShowModal,
	deleteGoal,
	loading,
	toggleOnDashboard,
	fromDashboard = false,
	disabled,
	setModal,
	setSelected,
	isSelected,
}) => {
	const {
		amount,
		bgColor,
		description,
		id,
		name,
		totalOrders,
		textColor,
		showDashboard,
		hasExpired,
	} = item;
	const [showDeleteModal, setShowDeleteModal] = useState({
		...modalInitialValue,
	});
	const {t} = useTranslation();
	const handleActions = (option) => {
		const {key, item: itemProps, domEvent: event} = option;
		event.preventDefault();
		event.stopPropagation();
		if (key === "edit") {
			handleEditModal(item);
			setSelected([]);
			setModal("costumizeAlerts", item.id);
		} else {
			setShowDeleteModal({
				open: true,
				type: key,
				title: itemProps.props.modalTitle,
			});
		}
	};
	const handleEditModal = () => {
		setShowModal({open: true, type: "costumizeAlerts"});
	};

	const handleCloseModal = () => {
		setShowDeleteModal({...modalInitialValue});
	};
	const handleConfirm = () => {
		if (showDeleteModal.type === "deleteCard") {
			deleteGoal([id]);
		} else {
			toggleOnDashboard({
				ids: [id],
				showDashboard: showDeleteModal?.type === "addCardToDashboard" && !showDashboard,
				showMobile: showMobile(showDeleteModal?.type, item),
				mobile:
					showDeleteModal?.type === "addCardToMobile" ||
					showDeleteModal?.type === "removeCardToMobile",
			});
		}
		handleCloseModal();
	};

	const percentage = ((totalOrders || 0) / amount) * 100;
	const {icon} = findStatus(percentage, hasExpired ? "#979797" : textColor);
	const items = [{key: "menu", label: <GoalInformationComponent item={item} />}];

	const statusItems = [
		{
			key: "status",
			label: <StatusInfo status={findStatus(percentage)} />,
		},
	];

	const handleSelect = () => {
		setSelected((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((selectedId) => selectedId !== id);
			}
			return [...prevSelected, id];
		});
	};

	return (
		<>
			<DeleteModal
				setShowDeleteModal={handleCloseModal}
				open={showDeleteModal.open}
				handleClose={handleCloseModal}
				calculationModal
				newTitle={showDeleteModal.title}
				confirm={handleConfirm}
				disabled={loading}
			/>
			<div
				className="card__wrapper"
				style={cardWrapperStyles(isSelected)}
				onClick={fromDashboard || disabled || hasExpired ? () => {} : () => handleSelect()}
			>
				<div
					className="alertsCards__container"
					style={{
						display: "flex",
						background: `${hasExpired ? `#C1C1C1` : `${bgColor}c1`}`,
						borderRadius: `${isSelected ? `6px` : `12px`}`,
						padding: `${isSelected ? `10px` : `15px`}`,
						clipPath: `${
							isSelected ? "polygon(0 0, 100% 0, 100% 74%, 84% 100%, 0% 100%)" : ""
						}`,
					}}
				>
					<div className="alertsCards__container--title">
						<HtmlTooltip title={<span>{name}</span>} placement="bottom">
							<span
								className="alertsCards__container--title-text"
								style={{color: hasExpired ? "#979797" : textColor}}
							>
								{name}
							</span>
						</HtmlTooltip>
						<div className="alertsCards__container--title-infoMore">
							<CustomPopover
								menu={{items: statusItems}}
								arrow
								customClass="status__info-popover"
								minWidth="300px"
								disabled={disabled}
							>
								<div
									className="alertsCards__container--title-infoMore-status"
									style={{borderColor: hasExpired ? "#979797" : textColor}}
								>
									<span>{icon}</span>
								</div>
							</CustomPopover>
							{hasExpired ? (
								<HtmlTooltip
									maxWidth={500}
									title={
										<div className="expired__goal-box-time">
											<ExpiredDateIcon />
											<div className="time">
												{t("GoalExpired")} -
												<span>{getDisplayingDate(item)}</span>
											</div>
										</div>
									}
								>
									<div className="alertsCards__container--title-infoMore-status">
										<span>
											<ExpiredDateIcon />
										</span>
									</div>
								</HtmlTooltip>
							) : (
								<div
									className="alertsCards__container--title-infoMore-dates"
									style={{borderColor: textColor}}
								>
									<span style={{color: textColor}}>
										{getDisplayingDate(item)}
									</span>
								</div>
							)}
							<div className="alertsCards__container--title-infoMore-info">
								<CustomPopover
									menu={{items}}
									arrow
									minWidth="100px"
									customClass="alertsCards__container--popup-card"
									disabled={disabled}
								>
									<span
										className="alertsCards__container--popup"
										style={{cursor: "pointer"}}
									>
										<ShowInfoIconBlack
											stroke={hasExpired ? "#979797" : textColor}
										/>
									</span>
								</CustomPopover>
							</div>
							{!fromDashboard && (
								<div className="alertsCards__container--title-infoMore-more">
									<GoalActions
										showDashboard={showDashboard}
										fromDashboard={fromDashboard}
										handleActions={handleActions}
										textColor={hasExpired ? "#979797" : textColor}
										removeMobile={item?.showMobile}
										expired={hasExpired}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="alertsCards__container--progressBar">
						<ProgressCard
							percentage={percentage}
							strokeColor={!hasExpired ? `${bgColor}` : "#979797"}
							title="Progress"
							description=""
						/>
					</div>
					<div className="alertsCards__container--contracts">
						<span style={{color: hasExpired ? "#979797" : textColor}}>
							{totalOrders || 0} / {amount} {t("Contracts")}
						</span>
					</div>
					<div
						style={{
							maxWidth: `${isSelected ? "85%" : "100%"}`,
						}}
						className="alertsCards__container--description"
					>
						<HtmlTooltip title={<span>{description}</span>} placement="bottom">
							<span
								style={{
									color: hasExpired ? "#979797" : textColor,
								}}
							>
								{description}
							</span>
						</HtmlTooltip>
					</div>
				</div>
				{isSelected && (
					<div className="selected-icon">
						<SelectedIcon fill={bgColor} />
					</div>
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	goals: state.app.analytics.index.goals,
	loading: state.app.analytics.delete.loading,
});
const mapDispatchToProps = {
	setShowModal: goalsActions.setShowModal,
	deleteGoal: deleteGoals.deleteGoal,
	toggleOnDashboard: goalsActions.toggleGoalOnDashboard,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertsCards);
