import {EnergyIcon} from "@src/assets/SvgComponents";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import plusLogo from "@assets/img/plusIconBackground.svg";
import Button from "@src/common/components/button";
import InfoMerkmaleComponent from "@src/common/infoMerkmaleComponent";
import {connect} from "react-redux";
import Loader from "@src/common/Loader";
import {actions as userActions} from "@sagas/users/users/index";
import {checkRoles} from "@src/utils/checkRoles";
import FiltersModal from "./FiltersModal";
import OrderForm from "./OrderForm";
import {userRolesDropdown} from "./OrderForm/helper";

const Energy = ({energyState, getUserDropdown}) => {
	const {t} = useTranslation();
	const {energyTariffs, loading} = energyState;
	const [showModal, setShowModal] = useState(false);
	const [filter, setFilter] = useState(null);
	const [orderForm, setOrderForm] = useState({
		open: false,
		data: null,
	});
	useEffect(() => {
		getUserDropdown(userRolesDropdown);
	}, []);
	const closeOrderForm = () => {
		setOrderForm({open: false, data: null});
	};
	const handleModal = () => {
		closeOrderForm();
		setShowModal(true);
	};
	const handleOrderFormModal = (data) => {
		setOrderForm({open: true, data});
	};
	return (
		<div className="dashboard__container">
			<div className="header-component">
				<div className="dashboard__left__text-header">
					<div className="header-component-wrapper">
						<EnergyIcon />
						<span className="header-component-text">{t("Energy")}</span>
					</div>
				</div>
				{checkRoles("AddFilterEnergy") && (
					<div className="dashboard__left__text-actions">
						<Button
							icon={plusLogo}
							leftRounded={false}
							rightRounded={false}
							smallButtonWithTooltip
							text="Add Filter"
							handleChange={() => setShowModal(true)}
						/>
					</div>
				)}
			</div>
			<div className="energy__layout">
				{loading ? (
					<div className="energy__layout-loader">
						<Loader />
					</div>
				) : (
					<>
						{energyTariffs?.map((tariff, index) => (
							<InfoMerkmaleComponent
								key={tariff?.tarifID}
								item={tariff}
								nr={index + 1}
								handleOrderFormModal={() => handleOrderFormModal(tariff)}
							/>
						))}
					</>
				)}
				<FiltersModal
					open={showModal}
					handleClose={() => setShowModal(false)}
					setFilter={setFilter}
				/>
				<OrderForm
					open={orderForm.open}
					data={orderForm.data}
					handleClose={closeOrderForm}
					handleModal={handleModal}
					filter={filter}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	energyState: state.app.energy.index,
});

const mapDispatchToProps = {
	getUserDropdown: userActions.getUserDropdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(Energy);
