import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as orderImportsActions} from "@sagas/orders/orderImports";
import {actions as orderActions} from "@sagas/orders/index";
import Back from "@assets/img/back__icon.svg";
import {actions as navigation} from "@sagas/navigation";
import {useTranslation} from "react-i18next";
import Button from "@src/common/components/button";
import HtmlTooltip from "@src/common/htmlTooltip";
import {ReactComponent as ErrorImg} from "@assets/img/error_black_24dp.svg";
import {ReactComponent as SuccessImg} from "@assets/img/check_circle_black_24dp.svg";
import DataGrid, {Row} from "react-data-grid";
import Loader from "@src/common/Loader";
import {BackButton} from "@src/assets/SvgComponents";
import checkIcon from "../../../../assets/img/check_icon.svg";
import disableIcon from "../../../../assets/img/check-errors-disable.svg";
import importIcon from "../../../../assets/img/upload_icon.svg";
import importDisableIcon from "../../../../assets/img/import-disable-icon.svg";
import {columnObj} from "./helper";

const InsideOrderImport = (props) => {
	const {orderImportState, getImportById, match, navigate, setCurrentTab, checkErrors} = props;
	const {import: importData, loading} = orderImportState;
	const {t} = useTranslation();
	const [updatedRows, setUpdatedRows] = useState([]);
	const onRowsChange = (e) => {
		setUpdatedRows(e);
	};
	useEffect(() => {
		getImportById(match?.params?.id);
		return () => setUpdatedRows([]);
	}, [match?.params?.id]);
	const handleBack = () => {
		setCurrentTab(t("Import"));
		navigate("/admin/imports/create-order");
	};
	const rowHeight = 50;
	const rowRenderer = (row) => {
		const currentErrors = importData?.rows?.[row.rowIdx]?.errors;
		return (
			<div key={row.rowIdx}>
				{currentErrors?.length ? (
					<div className="rowErrors" style={{top: rowHeight * row.rowIdx + 40}}>
						<HtmlTooltip
							placement="top"
							title={
								<div className="errors-container">
									{currentErrors.map((e, i) => (
										<>
											<span key={i}>{e.message}</span>
											<br />
										</>
									))}
								</div>
							}
						>
							<ErrorImg />
						</HtmlTooltip>
					</div>
				) : (
					<div className="succesRows" style={{top: rowHeight * row.rowIdx + 40}}>
						<HtmlTooltip
							placement="top"
							title={<div className="success-container">{t("Valid")}</div>}
						>
							<SuccessImg />
						</HtmlTooltip>
					</div>
				)}

				<Row {...row} key={row.rowIdx} className={currentErrors?.length ? "error" : ""} />
			</div>
		);
	};

	return (
		<div className="imports__details__container" style={{padding: "20px"}}>
			<div className="imports__details__top">
				{/* <img onClick={handleBack} src={Back} alt="" /> */}
				<div onClick={handleBack} className="imports__details__top__img">
					<BackButton />
				</div>
				<div className="save__buttons-wrapper">
					<Button
						icon={
							updatedRows?.length === 0 ||
							loading ||
							importData?.rows?.some((row) => row?.status === "Imported")
								? disableIcon
								: checkIcon
						}
						smallButtonWithTooltip
						disabled={
							updatedRows?.length === 0 ||
							loading ||
							importData?.rows?.some((row) => row?.status === "Imported")
						}
						text={t("CheckErrors")}
						handleChange={() =>
							checkErrors({
								checkErrors: true,
								createOrder: false,
								data: {
									...importData,
									rows: importData?.rows?.map((item, index) => ({
										...item,
										payload: updatedRows[index],
									})),
								},
							})
						}
					/>
					<Button
						icon={
							loading || importData?.rows?.some((row) => row?.status === "Imported")
								? importDisableIcon
								: importIcon
						}
						disabled={
							loading || importData?.rows?.some((row) => row?.status === "Imported")
						}
						smallButtonWithTooltip
						text={
							importData?.rows?.some((row) => row.status !== "Success")
								? t("ImportWithWarnings")
								: t("Import")
						}
						handleChange={() =>
							checkErrors({
								checkErrors: true,
								createOrder: true,
								data: {...importData},
							})
						}
					/>
				</div>
			</div>

			<div className="uploaded__csv__container">
				{!loading && (
					<DataGrid
						className="rdg-light"
						columns={
							importData?.headers?.map((col) => ({
								...col,
								resizable: true,
								editable: true,
								width: 140,
								...columnObj,
								getRowMetaData: (row) => row,
								formatter: ({row, rowIdx, column}) => {
									const currentErrors = importData?.rows?.[rowIdx]?.errors;

									// Check if there are errors for this row
									if (currentErrors?.length > 0) {
										const errorColumns = currentErrors.map(
											(error) => error.name,
										);
										// Check if the current column has an error
										if (errorColumns.includes(column.name)) {
											// find error which name's is same with column's name
											const thisError = currentErrors.find(
												(error) => error.name === column.name,
											);
											return (
												<HtmlTooltip
													title={<span>{thisError?.message}</span>}
													placement="bottom-start"
												>
													<span className="custom__error__cell">
														{row[column.key] || "-"}
													</span>
												</HtmlTooltip>
											);
										}
									}
									// No error for this column or row, render the default row
									return <span>{row[column.key]}</span>;
								},
							})) || []
						}
						rows={
							updatedRows?.[0]
								? updatedRows
								: importData?.rows?.map((it) => it?.payload) || []
						}
						headerRowHeight={40}
						rowHeight={rowHeight}
						rowRenderer={rowRenderer}
						onRowsChange={onRowsChange}
					/>
				)}

				{loading && (
					<div className="loader__contianer">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	orderImportState: state.app.orders.import,
});

const mapDispatchToProps = {
	getImportById: orderImportsActions.getImportById,
	navigate: navigation.navigate,
	setCurrentTab: orderActions.setCurrentTab,
	checkErrors: orderImportsActions.importCsv,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InsideOrderImport));
