import {
	CopiedIcon,
	DownloadDownIcon,
	ImageMesg,
	Mp4Icon,
	PdfIcon,
	PptIcon,
	XlsIcon,
} from "@src/assets/SvgComponents";
import Loader from "@src/common/Loader";
import React from "react";
import {useTranslation} from "react-i18next";

const UploadCards = ({fileTitle, fileExt, fileMb, fileUrl, copied, setCopied}) => {
	const {t} = useTranslation();
	const filesIcon = {
		ppt: <PptIcon />,
		pdf: <PdfIcon />,
		mp4: <Mp4Icon />,
		jpg: <ImageMesg />,
		jpeg: <ImageMesg />,
		png: <ImageMesg />,
		xls: <XlsIcon />,
		xlsx: <XlsIcon />,
	};
	const getFileIcon = (fileExt) => {
		const ext = fileExt?.toLowerCase();
		return filesIcon[ext] || <PdfIcon />;
	};

	const copyToClipboard = async (link) => {
		await navigator.clipboard.writeText(link);
		setCopied({[fileUrl]: true});
	};

	return (
		<>
			<div className="uploadCards__container">
				{fileUrl === null ? (
					<div className="uploadCards__container-loader">
						<Loader />
					</div>
				) : (
					<>
						<div className="uploadCards__container_iconAndTitle">
							<div className="uploadCards__container_iconAndTitle_img">
								<DownloadDownIcon />
							</div>
							<div className="uploadCards__container_iconAndTitle_title">
								<span className="uploadCards__container_iconAndTitle_title-s1">
									{fileTitle && fileTitle.slice(0, 30)}
								</span>
								<span className="uploadCards__container_iconAndTitle_title-s2">
									{fileMb} KB
								</span>
							</div>
						</div>
						<div className="uploadCards__container_right">
							<div className="uploadCards__container_right-imgAndTxt">
								{getFileIcon(fileExt)}
								<span>{fileExt}</span>
							</div>
							<div
								className="uploadCards__container_right-button"
								// onClick={() => copyToClipboard(fileTitle)}
								onClick={() => copyToClipboard(`[#${fileUrl}#]`)}
							>
								<div className="uploadCards__container_right-button-icon">
									<CopiedIcon />
								</div>
								<span className="uploadCards__container_right-button-txt">
									{copied?.[fileUrl] ? t("Copied") : t("Copy")}
								</span>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default UploadCards;
