import {DeleteRowIcon, EditRowIcon, GrabIcon} from "@src/assets/SvgComponents";
import React, {forwardRef} from "react";
import {useUrlParams} from "@src/utils/useUrlParams";
import CustomFieldsHeader from "../components/CustomFieldsHeader";
import {getLabel} from "./helper";

export const Container = forwardRef(
	(
		{
			children,
			handleProps,
			horizontal,
			hover,
			onClick,
			onRemove,
			placeholder,
			style,
			shadow,
			section,
			disabled,
			handleSearch,
			searchValue,
			...props
		},
		ref,
	) => {
		const {setModal} = useUrlParams();

		const handleAction = (e) => {
			e.stopPropagation();
			const type = e.currentTarget.id === "edit" ? "section" : "deleteSection";
			setModal(type, section.id);
		};
		return (
			<div
				{...props}
				ref={ref}
				style={{
					...style,
				}}
				className={`Container ${horizontal && "horizontal"} ${hover && "hover"} ${
					placeholder && "placeholder"
				} ${shadow && "shadow"}`}
				onClick={onClick}
			>
				{disabled ? (
					<CustomFieldsHeader handleSearch={handleSearch} searchValue={searchValue} />
				) : (
					<div className="Container__header">
						<div className="Container__header-icons">
							<span {...handleProps} className="grab__icon">
								<GrabIcon />
							</span>
							<div className="Container__header-icons-actions">
								<span id="edit" onClick={handleAction}>
									<EditRowIcon />
								</span>
								<span id="delete" onClick={handleAction}>
									<DeleteRowIcon />
								</span>
							</div>
						</div>
						<div className="Container__header-name">
							<span>{getLabel(section, "name")}</span>
						</div>
					</div>
				)}
				<ul className="Container__content">{children}</ul>
			</div>
		);
	},
);
