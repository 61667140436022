import moment from "moment";

export const checkValidDateInMonth = (day, monthString) => {
	// Parse the month and year (assuming format MM/YYYY for month)
	const month = moment(monthString, "MM/YYYY");

	// Create a date with the given day and check if it's valid
	const date = moment(`${day}/${monthString}`, "DD/MM/YYYY");

	// Check if the day exists in the month
	return date.isValid() && date.month() === month.month();
};
export const getFocusedState = (ref) => ref?.current === document.activeElement;
export const handleRef = (current, target) => {
	current.current.blur();
	setTimeout(() => {
		target.current.focus();
	}, 100);
};
export const datesInitialValues = {
	day: "",
	month: "",
	year: "",
};

export const checkDate = (date, isBirthdayInput, minYears) => {
	if (isBirthdayInput && minYears) {
		const givenDate = moment(date);

		// Current date minus the minimum years
		const comparedDateWithMinYears = moment().subtract(minYears, "years");

		// Check if the given date is before the calculated date
		const isOlderThanMinYears = givenDate.isBefore(comparedDateWithMinYears);

		return isOlderThanMinYears;
	}
	return true;
};
export const fillValues = (value) => {
	if (value) {
		if (typeof value === "number") {
			const date = moment(value);
			const day = String(date.format("DD"));
			const month = String(date.format("MM"));
			const year = String(date.format("YYYY"));
			return {day, month, year};
		}
		const [day, month, year] = value?.split("/").map((it) => +it);
		return {day, month, year};
	}
	return datesInitialValues;
};
