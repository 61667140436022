export const fieldBasedOnLanguage = {
	en: "labelEn",
	de: "labelDe",
};

const language = localStorage.getItem("language");

export const searchCustomFields = (state, input) =>
	state?.filter((item) =>
		item[fieldBasedOnLanguage[language]].toLowerCase().includes(input.toLowerCase()),
	);
