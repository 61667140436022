/* eslint-disable no-continue */
/* eslint-disable max-len */
import produce from "immer";
import {put, takeLatest, select, call, takeEvery, delay} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import {generateRandomColor} from "@src/utils/generateRandomColor";
import {ENDPOINT} from "@src/utils/endpoint";
import i18n from "@src/i18n";
import moment from "moment";
import {convertBase64} from "../userProfile/settings";
import {filterFunc, handleSortSections} from "./utils";

const logger = new Logger("Client index");

const PREFIX = "@app/store/sagas/app/client/index";

// clients
export const FETCH_CLIENTS = `${PREFIX}FETCH_CLIENTS`;
export const FETCH_CLIENTS_SUCCESS = `${PREFIX}FETCH_CLIENTS_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;

// CRUD
export const ADD_CLIENT_SUCCESS_LIST = `${PREFIX}ADD_CLIENT_SUCCESS_LIST`;
export const UPDATE_CLIENT_SUCCESS_LIST = `${PREFIX}UPDATE_CLIENT_SUCCESS_LIST`;
export const DELETE_CLIENT_SUCCESS_LIST = `${PREFIX}DELETE_CLIENT_SUCCESS_LIST`;

// single client
export const FETCH_CLIENT = `${PREFIX}FETCH_CLIENT`;
export const FETCH_CLIENT_SUCCESS = `${PREFIX}FETCH_CLIENT_SUCCESS`;
export const CLEAR_CLIENT_OBJECT = `${PREFIX}CLEAR_CLIENT_OBJECT`;
export const SET_CLIENT_ACTIVE_TAB = `${PREFIX}SET_CLIENT_ACTIVE_TAB`;
export const CLIENT_ADD_TOTAL_SIZE_INCREASE = `${PREFIX}CLIENT_ADD_TOTAL_SIZE_INCREASE`;
export const CLIENT_ADD_TOTAL_SIZE_DECREASE = `${PREFIX}CLIENT_ADD_TOTAL_SIZE_DECREASE`;
export const FETCH_CLIENTS_BY_DROPDOWN = `${PREFIX}FETCH_CLIENTS_BY_DROPDOWN`;
export const FETCH_CLIENTS_BY_DROPDOWN_SUCCESS = `${PREFIX}FETCH_CLIENTS_BY_DROPDOWN_SUCCESS`;
export const SET_SELECTED_CLIENT_ID = `${PREFIX}SET_SELECTED_CLIENT_ID`;
export const SET_SELECTED_CLIENT_ID_SUCCESS = `${PREFIX}SET_SELECTED_CLIENT_ID_SUCCESS`;
export const CHANGE_LOGO = `${PREFIX}CHANGE_LOGO`;
export const CHANGE_LOGO_SUCCESS = `${PREFIX}CHANGE_LOGO_SUCCESS`;
export const RESET_LOGO = `${PREFIX}RESET_LOGO`;
export const UPDATE_SETTINGS = `${PREFIX}UPDATE_SETTINGS`;
export const UPDATE_SETTINGS_SUCCESS = `${PREFIX}UPDATE_SETTINGS_SUCCESS`;
export const CREATE_GENERIC_PDF = `${PREFIX}CREATE_GENERIC_PDF`;
export const REPLACE_GENERIC_PDF = `${PREFIX}REPLACE_GENERIC_PDF`;
export const CREATE_GENERIC_PDF_SUCCESS = `${PREFIX}CREATE_GENERIC_PDF_SUCCESS`;
export const FETCH_GENERIC_PDF = `${PREFIX}FETCH_GENERIC_PDF`;
export const FETCH_GENERIC_PDF_SUCCESS = `${PREFIX}FETCH_GENERIC_PDF_SUCCESS`;
export const FETCH_GENERIC_PDF_ID = `${PREFIX}FETCH_GENERIC_PDF_ID`;
export const FETCH_GENERIC_PDF_ID_SUCCESS = `${PREFIX}FETCH_GENERIC_PDF_ID_SUCCESS`;
export const DELETE_GENERIC_PDF = `${PREFIX}DELETE_GENERIC_PDF`;
export const SAVE_GENERIC_PDF = `${PREFIX}SAVE_GENERIC_PDF`;
export const SAVE_GENERIC_PDF_SUCCESS = `${PREFIX}SAVE_GENERIC_PDF_SUCCESS`;
export const UPLOADING = `${PREFIX}ULOADING`;
export const GET_ALL_COSTUM_FIELDS = `${PREFIX}GET_ALL_COSTUM_FIELDS`;
export const GET_ALL_COSTUM_FIELDS_SUCCESS = `${PREFIX}GET_ALL_COSTUM_FIELDS_SUCCESS`;
export const SAVE_GENERIC_ORDER = `${PREFIX}SAVE_GENERIC_ORDER`;
export const SAVE_GENERIC_ORDER_SUCCESS = `${PREFIX}SAVE_GENERIC_ORDER_SUCCESS`;
export const FETCH_GENERIC_ORDER_ID = `${PREFIX}FETCH_GENERIC_ORDER_ID`;
export const FETCH_GENERIC_ORDER_ID_SUCCESS = `${PREFIX}FETCH_GENERIC_ORDER_ID_SUCCESS`;
export const REMOVE_ATTACHMENT = `${PREFIX}REMOVE_ATTACHMENT`;
export const ADD_CUSTOM_FIELD = `${PREFIX}ADD_CUSTOM_FIELD`;
export const ADD_CUSTOM_FIELD_SUCCESS = `${PREFIX}ADD_CUSTOM_FIELD_SUCCESS`;
export const EDIT_CUSTOM_FIELD = `${PREFIX}EDIT_CUSTOM_FIELD`;
export const EDIT_CUSTOM_FIELD_SUCCESS = `${PREFIX}EDIT_CUSTOM_FIELD_SUCCESS`;
export const SET_SHOW_MODAL = `${PREFIX}SET_SHOW_MODAL`;
export const GET_CUSTOM_FIELD_BY_ID = `${PREFIX}GET_CUSTOM_FIELD_BY_ID`;
export const GET_CUSTOM_FIELD_BY_ID_SUCCESS = `${PREFIX}GET_CUSTOM_FIELD_BY_ID_SUCCESS`;
export const ADD_CUSTOM_FIELD_CHILD = `${PREFIX}ADD_CUSTOM_FIELD_CHILD`;
export const CLEAR_CUSTOM_FIELD_VALUES = `${PREFIX}CLEAR_CUSTOM_FIELD_VALUES`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const SET_SECTION_INITIAL_VALUES = `${PREFIX}SET_SECTION_INITIAL_VALUES`;
export const SET_ASSIGN_PDF_INITIAL_VALUES = `${PREFIX}SET_ASSIGN_PDF_INITIAL_VALUES`;
export const SET_FIELDS_SETTINGS_DATA = `${PREFIX}SET_FIELDS_SETTINGS_DATA`;
export const TEST_CONNECTION = `${PREFIX}TEST_CONNECTION`;
export const TEST_CONNECTION_SUCCESS = `${PREFIX}TEST_CONNECTION_SUCCESS`;
export const CONNECT_SFTP_SERVER = `${PREFIX}CONNECT_SFTP_SERVER`;
export const UPDATE_PDF_FIELD = `${PREFIX}UPDATE_PDF_FIELD`;
export const UPDATE_CUSTOM_FIELD = `${PREFIX}UPDATE_CUSTOM_FIELD`;
export const UPDATE_CUSTOM_FIELD_SUCCESS = `${PREFIX}UPDATE_CUSTOM_FIELD_SUCCESS`;
export const UPDATE_INITIAL_LOGO = `${PREFIX}UPDATE_INITIAL_LOGO`;
export const TOGGLE_SECTION_VISIBILITY = `${PREFIX}TOGGLE_SECTION_VISIBILITY`;
export const CREATE_SECTION = `${PREFIX}CREATE_SECTION`;
export const CREATE_SECTION_SUCCESS = `${PREFIX}CREATE_SECTION_SUCCESS`;
export const UPDATE_SECTION = `${PREFIX}UPDATE_SECTION`;
export const UPDATE_SECTION_SUCCESS = `${PREFIX}UPDATE_SECTION_SUCCESS`;
export const DELETE_SECTION = `${PREFIX}DELETE_SECTION`;
export const DELETE_SECTION_SUCCESS = `${PREFIX}DELETE_SECTION_SUCCESS`;
export const DELETE_ORDER_FIELD = `${PREFIX}DELETE_ORDER_FIELD`;
export const DELETE_ORDER_FIELD_SUCCESS = `${PREFIX}DELETE_ORDER_FIELD_SUCCESS`;
export const MOVE_ORDER_FIELD = `${PREFIX}MOVE_ORDER_FIELD`;
export const MOVE_ORDER_FIELD_SUCCESS = `${PREFIX}MOVE_ORDER_FIELD_SUCCESS`;
export const MOVE_SECTION = `${PREFIX}MOVE_SECTION`;
export const MOVE_SECTION_SUCCESS = `${PREFIX}MOVE_SECTION_SUCCESS`;

const _state = {
	optionsClients: [1, 2],
	clients: [],
	client: {},
	activeTab: "locations",
	isLoading: false,
	isUploading: false,
	page: 1,
	size: 30,
	totalSize: 5,
	totalPages: 1,
	search: "",
	selectedClient: 0,
	logoUrl: "",
	resetLogo: null,
	exportPdf: true,
	sendOfferEmail: false,
	sendOrderPdfEmail: false,
	leads: false,
	leadsLocation: false,
	sendEmailForOrder: false,
	includeGeneratedPDF: false,
	description: "",
	subject: "",
	singleGenericPdf: {},
	genericPdfs: [],
	costumFields: [],
	editedFields: [],
	editedOrderFields: [],
	sections: [],
	showModal: false,
	clientsByDropdown: [],
	testError: "null",
	initialLogo: null,
	energyClientsDropdown: [],
	groupedCustomFields: [],
	groupedOrderFields: [],
	fieldOptions: {},
	groupedFields: [],
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_CLIENTS_SUCCESS:
				draft.clients = state?.page > 1 ? [...state?.clients, ...payload] : payload;
				break;
			case CHANGE_LOGO_SUCCESS:
				draft.client = {...state?.client, logo: payload};
				draft.logoUrl = payload;
				break;
			case RESET_LOGO:
				draft.client = {
					...state.client,
					logo: state.initialLogo,
				};
				draft.logoUrl = state.initialLogo;
				break;
			case UPDATE_SETTINGS_SUCCESS:
				draft.client = {
					...payload,
					id: payload?.clientId,
				};
				break;
			case FETCH_CLIENT_SUCCESS:
				draft.client = payload;
				draft.initialLogo = payload && payload.logo;
				break;
			case CLEAR_CLIENT_OBJECT:
				draft.client = {};
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case SET_CLIENT_ACTIVE_TAB:
				draft.activeTab = payload;
				break;
			case ADD_CLIENT_SUCCESS_LIST:
				draft.clients = [payload, ...state.clients];
				break;
			case UPDATE_CLIENT_SUCCESS_LIST:
				const index = state.clients.findIndex((c) => c.id === payload.id);
				draft.clients[index] = payload;
				break;
			case DELETE_CLIENT_SUCCESS_LIST:
				// draft.clients = state.clients.filter((client) => client.id !== payload);
				const filteredClients = state.clients.filter((client) => client.id !== payload);
				if (state?.page > 1 && filteredClients.length === 0) {
					draft.page = state.page - 1;
				} else {
					draft.clients = filteredClients;
				}
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case LOADING:
				draft.isLoading = payload;
				break;
			case UPLOADING:
				draft.isUploading = payload;
				break;
			case CLIENT_ADD_TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case CLIENT_ADD_TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			case FETCH_CLIENTS_BY_DROPDOWN_SUCCESS:
				const allClients = {
					name: i18n.t("All"),
					value: null,
					clientId: null,
					leads: null,
					leadsLocation: null,
					label: i18n.t("All"),
				};
				draft.optionsClients = payload?.map((e) => ({
					name: e.clientName,
					value: e.id,
					clientId: e.clientId,
					leads: e.leads,
					label: e.clientName,
					leadsLocation: e.leadsLocation,
				}));
				draft.optionsClients.push(allClients);
				const clientsWithColors = payload?.map((client) => ({
					...client,
					pdfColor: client?.pdfColor ? client?.pdfColor : generateRandomColor(),
				}));
				draft.clientsByDropdown = clientsWithColors;
				const energyClients = payload
					?.filter((client) => client?.clientContractType === "ENERGY")
					.map((item) => ({
						name: item.clientName,
						id: item.id,
						clientId: item.clientId,
					}));
				draft.energyClientsDropdown = energyClients;
				break;
			case SET_SELECTED_CLIENT_ID_SUCCESS:
				draft.selectedClient = payload.payload;
				break;
			case CREATE_GENERIC_PDF_SUCCESS:
				// draft.genericPdfs = payload;
				draft.genericPdfs = [payload, ...state.genericPdfs];
				break;
			case FETCH_GENERIC_PDF_SUCCESS:
				draft.genericPdfs = payload;
				break;
			case FETCH_GENERIC_PDF_ID:
				const single = state.genericPdfs.find((item) => item.id === payload);
				draft.singleGenericPdf = single;
				// GET SINGLE PDF BY ID
				break;
			case DELETE_GENERIC_PDF:
				const tempArr = state.genericPdfs.filter((item) => item.id !== payload.id);
				draft.genericPdfs = tempArr;
				break;
			case GET_ALL_COSTUM_FIELDS_SUCCESS:
				const filterCostumFields = payload.filter((item) => item.showField !== false);
				const newFieldsArray = [];
				for (let i = 0; i < filterCostumFields.length; i++) {
					const field = filterCostumFields[i];
					if (field.dropDownParentId) continue;
					if (field.inputType === "DROPDOWN") {
						const foundedOptions = filterCostumFields?.filter(
							(item) => item.dropDownParentId === field.id,
						);
						newFieldsArray.push({
							...field,
							options: foundedOptions,
							parentId: "customFields",
						});
					} else {
						newFieldsArray.push({
							...field,
							parentId: "customFields",
						});
					}
				}
				draft.groupedCustomFields = newFieldsArray;
				draft.costumFields = filterCostumFields;
				break;
			case FETCH_GENERIC_PDF_ID_SUCCESS:
				draft.editedFields = payload;
				const {
					tariffId,
					fileName,
					sendEmail,
					saveOnServer,
					sendOfferEmail,
					saveOnClientServer,
				} = payload;
				draft.assignPdfData = {
					tariffId,
					fileName,
					sendEmail,
					saveOnServer,
					sendOfferEmail,
					saveOnClientServer,
				};
				break;
			case FETCH_GENERIC_ORDER_ID_SUCCESS:
				const {sectionViewList, orderFieldViewList} = payload;
				const sortOrderFields = orderFieldViewList?.sort(
					(a, b) => a.orderSortNr - b.orderSortNr,
				);
				const sortedSections = sectionViewList?.sort((a, b) => a.nr - b.nr);
				const updatedOrderFields = [];
				for (let i = 0; i < sortOrderFields.length; i++) {
					const field = sortOrderFields[i];
					if (field.dropDownParentId) continue;
					if (field.inputType === "DROPDOWN") {
						const foundedOptions = sortOrderFields?.filter(
							(item) => item.dropDownParentId === field.fieldId,
						);
						updatedOrderFields.push({
							...field,
							options: foundedOptions,
						});
					} else {
						updatedOrderFields.push(field);
					}
				}
				draft.editedOrderFields = sortOrderFields;
				draft.groupedOrderFields = updatedOrderFields;
				draft.sections = sortedSections?.map((sect) => ({
					...sect,
					show: true,
				}));
				const groupedFields = [];
				for (let i = 0; i < sectionViewList?.length; i++) {
					const section = sectionViewList[i];
					const sectionFields = orderFieldViewList?.filter(
						(item) => item.parentId === section.id,
					);
					groupedFields.push({
						...section,
						fields: sectionFields?.sort((a, b) => a?.orderSortNr - b?.orderSortNr),
					});
				}
				const optionsObj = {};
				for (let i = 0; i < orderFieldViewList.length; i++) {
					const field = orderFieldViewList[i];
					if (field.inputType === "DROPDOWN") {
						// find options for that dropdown;
						if (field.customOptionsString) {
							const newArr = field.customOptionsString?.split(",")?.map((opt) => {
								const trimmedValue = opt.trim();
								return {
									name: trimmedValue,
									labelEn: trimmedValue,
									labelDe: trimmedValue,
									label: trimmedValue,
									value: trimmedValue,
								};
							});
							optionsObj[field.fieldId] = newArr;
						} else {
							const foundedOptions = orderFieldViewList?.filter(
								(item) => item.dropDownParentId === field.fieldId,
							);
							optionsObj[field.fieldId] = foundedOptions?.map((item) => ({
								...item,
								label: item.labelEn,
								value: item.name,
							}));
						}
					}
				}
				draft.fieldOptions = optionsObj;
				draft.groupedFields = groupedFields?.sort((a, b) => a.nr - b.nr);
				break;
			case REMOVE_ATTACHMENT:
				const {attachedFiles} = state?.client;
				const {id, base64, files} = payload;
				let filteredFiles = [];
				if (id) {
					filteredFiles = filterFunc(attachedFiles, "id", id);
				} else {
					filteredFiles = filterFunc(files, "fileUrl", base64);
				}
				draft.client.attachedFiles = filteredFiles;
				break;
			case ADD_CUSTOM_FIELD_SUCCESS:
				const copiedState = [...state.groupedCustomFields];
				if (payload.dropDownParentId) {
					const foundedParentIdx = copiedState.findIndex(
						(item) => item.id === payload.dropDownParentId,
					);
					if (foundedParentIdx !== -1) {
						// If parent is found, add payload to its options
						copiedState[foundedParentIdx] = {
							...copiedState[foundedParentIdx],
							parentId: "customFields",
							options: [
								...(copiedState[foundedParentIdx].options || []), // Safely access options
								payload,
							],
						};
						draft.groupedCustomFields = copiedState;
					}
				} else {
					draft.groupedCustomFields = [
						...copiedState,
						{...payload, parentId: "customFields"},
					];
				}
				break;
			case SET_SHOW_MODAL:
				draft.showModal = payload;
				break;
			case EDIT_CUSTOM_FIELD_SUCCESS:
				const newCustomFieldsArray = state.groupedCustomFields.map((item) => {
					if (payload.dropDownParentId === item.id) {
						return {
							...item,
							options: item?.options?.map((opt) => {
								if (opt.id === payload.id) {
									return {
										...opt,
										...payload,
									};
								}
								return opt;
							}),
						};
					}
					if (item.id === payload?.id) {
						return {
							...item,
							...payload,
						};
					}
					return item;
				});
				draft.groupedCustomFields = newCustomFieldsArray;
				break;
			case GET_CUSTOM_FIELD_BY_ID_SUCCESS:
				draft.customFieldInitialValues = payload?.data;
				break;
			case ADD_CUSTOM_FIELD_CHILD:
				draft.customFieldInitialValues = {
					...state?.customFieldInitialValues,
					dropDownParentId: payload,
				};
				break;
			case SET_ASSIGN_PDF_INITIAL_VALUES:
				draft.assignPdfData = payload;
				break;
			case SET_FIELDS_SETTINGS_DATA:
				draft.client = {
					...state.client,
					fieldSettings: payload,
				};
				break;
			case TEST_CONNECTION_SUCCESS:
				draft.testError = payload;
				break;
			case UPDATE_INITIAL_LOGO:
				draft.initialLogo = payload;
				break;
			case TOGGLE_SECTION_VISIBILITY:
				draft.sections = [...state.sections]?.map((sect) => {
					if (sect.id === payload) {
						return {
							...sect,
							show: !sect.show,
						};
					}
					return sect;
				});
				break;
			case CREATE_SECTION_SUCCESS:
				draft.sections = [...state.sections, {...payload, show: true}];
				break;
			case UPDATE_CUSTOM_FIELD_SUCCESS:
				draft.groupedOrderFields = [...state.groupedOrderFields]?.map((item) => {
					if (item.id === payload.id) {
						return {
							...item,
							...payload,
						};
					}
					if (item.fieldId === payload?.dropDownParentId) {
						return {
							...item,
							options: item.options.map((opt) => {
								if (opt.id === payload.id) {
									return {
										...opt,
										...payload,
									};
								}
								return opt;
							}),
						};
					}
					return item;
				});
				break;
			case UPDATE_SECTION_SUCCESS:
				draft.sections = [...state.sections]?.map((sect) => {
					if (sect.id === payload.id) {
						return {...payload, show: true};
					}
					return sect;
				});
				break;
			case DELETE_SECTION_SUCCESS:
				const filteredOrderFields = state.groupedOrderFields?.filter(
					(item) => item?.parentId !== payload,
				);
				const newOrderFields = state.groupedOrderFields
					?.filter((item) => item.parentId === payload)
					?.map((field) => ({
						...field,
						parentId: null,
						parentNameEn: null,
						parentNameDe: null,
					}));
				draft.groupedCustomFields = [...newOrderFields, ...state.groupedCustomFields];
				draft.groupedOrderFields = filteredOrderFields;
				const filteredSections = state.sections.filter((sect) => sect.id !== payload);
				draft.sections = filteredSections;
				break;
			case DELETE_ORDER_FIELD_SUCCESS:
				const foundedOrderField = state.groupedOrderFields?.find(
					(item) => item.id === payload,
				);
				if (!foundedOrderField) {
					draft.groupedOrderFields = state.groupedOrderFields?.map((it) => {
						if (it.inputType === "DROPDOWN") {
							return {
								...it,
								options: it.options.filter((opt) => opt.id !== payload),
							};
						}
						return it;
					});
				} else {
					const updatedOrderFields = state.groupedOrderFields.filter(
						(field) => field.id !== payload,
					);
					draft.groupedOrderFields = updatedOrderFields;
				}
				break;
			case MOVE_ORDER_FIELD_SUCCESS:
				const foundedObject = state.groupedCustomFields?.find(
					(item) => item.id === payload?.customFields.id,
				);
				const insertObject = {
					...payload,
					objectType: payload?.customFields?.objectType,
					fieldId: payload?.customFields?.id,
					labelEn: payload?.customFields?.labelEn,
					labelDe: payload?.customFields?.labelDe,
					clientId: payload.client.id,
					name: payload?.customFields?.name,
				};
				if (foundedObject?.options) {
					insertObject.options = foundedObject?.options;
				}
				if (payload.dropDownParentId) {
					draft.groupedOrderFields = state.groupedOrderFields?.map((item) => {
						if (item.fieldId === payload.dropDownParentId) {
							return {
								...item,
								dropDownParentId: payload?.dropDownParentId,
								options: [
									...item.options,
									{...payload, ...payload.child, id: payload.id},
								],
							};
						}
						return item;
					});
				} else {
					const mergedState = [...state.groupedOrderFields, insertObject];
					draft.groupedOrderFields = mergedState;
				}
				break;
			case MOVE_SECTION_SUCCESS:
				const updatedSections = handleSortSections(payload, state.sections);
				draft.sections = updatedSections;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchClients: (payload) => createAction(FETCH_CLIENTS, {payload}),
	fetchClientsSuccess: (payload) => createAction(FETCH_CLIENTS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	setUploading: (payload) => createAction(UPLOADING, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterClients: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	changeLogo: (payload) => createAction(CHANGE_LOGO, {payload}),
	updateSettings: (payload) => createAction(UPDATE_SETTINGS, {payload}),
	changeLogoSuccess: (payload) => createAction(CHANGE_LOGO_SUCCESS, {payload}),
	resetLogoSuccess: (payload) => createAction(RESET_LOGO, {payload}),
	updateSettingsSuccess: (payload) => createAction(UPDATE_SETTINGS_SUCCESS, {payload}),
	addClientSuccess: (payload) => createAction(ADD_CLIENT_SUCCESS_LIST, {payload}),
	editComissionOnState: (payload) => createAction(UPDATE_CLIENT_SUCCESS_LIST, {payload}),
	deleteClientOnState: (payload) => createAction(DELETE_CLIENT_SUCCESS_LIST, {payload}),
	fetchClient: (payload) => createAction(FETCH_CLIENT, {payload}),
	fetchClientSuccess: (payload) => createAction(FETCH_CLIENT_SUCCESS, {payload}),
	clearClientObj: (payload) => createAction(FETCH_CLIENT_SUCCESS, {payload}),
	setClientActiveTab: (payload) => createAction(SET_CLIENT_ACTIVE_TAB, {payload}),
	clientAddIncreaseTotalSize: (payload) =>
		createAction(CLIENT_ADD_TOTAL_SIZE_INCREASE, {payload}),
	clientAddDecreaseTotalSize: (payload) =>
		createAction(CLIENT_ADD_TOTAL_SIZE_DECREASE, {payload}),

	fetchClientsByDropdown: (payload) => createAction(FETCH_CLIENTS_BY_DROPDOWN, {payload}),
	fetchClientsByDropdownSuccess: (payload) =>
		createAction(FETCH_CLIENTS_BY_DROPDOWN_SUCCESS, {payload}),
	setSelectedClientId: (payload) => createAction(SET_SELECTED_CLIENT_ID, {payload}),
	setSelectedClientIdSuccess: (payload) =>
		createAction(SET_SELECTED_CLIENT_ID_SUCCESS, {payload}),
	createGenericPdf: (payload) => createAction(CREATE_GENERIC_PDF, {payload}),
	replaceGenericPdf: (payload) => createAction(REPLACE_GENERIC_PDF, {payload}),
	createGenericPdfSuccess: (payload) => createAction(CREATE_GENERIC_PDF_SUCCESS, {payload}),

	fetchGenericPdf: (payload) => createAction(FETCH_GENERIC_PDF, {payload}),
	fetchGenericPdfSuccess: (payload) => createAction(FETCH_GENERIC_PDF_SUCCESS, {payload}),
	fetchGenericPdfByID: (payload) => createAction(FETCH_GENERIC_PDF_ID, {payload}),
	fetchGenericPdfByIDSuccess: (payload) => createAction(FETCH_GENERIC_PDF_ID_SUCCESS, {payload}),

	deleteGenericPdf: (payload) => createAction(DELETE_GENERIC_PDF, {payload}),
	saveGenericPdf: (payload) => createAction(SAVE_GENERIC_PDF, {payload}),
	saveGenericPdfSuccess: (payload) => createAction(SAVE_GENERIC_PDF_SUCCESS, {payload}),
	saveGenericOrder: (payload) => createAction(SAVE_GENERIC_ORDER, {payload}),
	saveGenericOrderSuccess: (payload) => createAction(SAVE_GENERIC_ORDER_SUCCESS, {payload}),
	getAllCostumFields: (payload) => createAction(GET_ALL_COSTUM_FIELDS, {payload}),
	getAllCostumFieldsSuccess: (payload) => createAction(GET_ALL_COSTUM_FIELDS_SUCCESS, {payload}),
	fetchGenericOrderByID: (payload) => createAction(FETCH_GENERIC_ORDER_ID, {payload}),
	fetchGenericOrderByIDSuccess: (payload) =>
		createAction(FETCH_GENERIC_ORDER_ID_SUCCESS, {payload}),
	removeAttachment: (payload) => createAction(REMOVE_ATTACHMENT, {payload}),
	createCustomField: (payload) => createAction(ADD_CUSTOM_FIELD, {payload}),
	createCustomFieldSuccess: (payload) => createAction(ADD_CUSTOM_FIELD_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SET_SHOW_MODAL, {payload}),
	editCustomField: (payload) => createAction(EDIT_CUSTOM_FIELD, {payload}),
	editCustomFieldSuccess: (payload) => createAction(EDIT_CUSTOM_FIELD_SUCCESS, {payload}),
	getCustomFieldById: (payload) => createAction(GET_CUSTOM_FIELD_BY_ID, {payload}),
	getCustomFieldByIdSuccess: (payload) => createAction(GET_CUSTOM_FIELD_BY_ID_SUCCESS, {payload}),
	addCustomFieldChild: (payload) => createAction(ADD_CUSTOM_FIELD_CHILD, {payload}),
	clearCustomFieldValues: (payload) => createAction(CLEAR_CUSTOM_FIELD_VALUES, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	setSection: (payload) => createAction(SET_SECTION_INITIAL_VALUES, {payload}),
	setAssignPdfData: (payload) => createAction(SET_ASSIGN_PDF_INITIAL_VALUES, {payload}),
	setFieldsSettingsData: (payload) => createAction(SET_FIELDS_SETTINGS_DATA, {payload}),
	testConnection: (payload) => createAction(TEST_CONNECTION, {payload}),
	testConnectionSuccess: (payload) => createAction(TEST_CONNECTION_SUCCESS, {payload}),
	connectSftpServer: (payload) => createAction(CONNECT_SFTP_SERVER, {payload}),
	updatePdfField: (payload) => createAction(UPDATE_PDF_FIELD, {payload}),
	updateCustomField: (payload) => createAction(UPDATE_CUSTOM_FIELD, {payload}),
	updateCustomFieldSuccess: (payload) => createAction(UPDATE_CUSTOM_FIELD_SUCCESS, {payload}),
	updateInitialLogo: (payload) => createAction(UPDATE_INITIAL_LOGO, {payload}),
	toggleSectionVisibility: (payload) => createAction(TOGGLE_SECTION_VISIBILITY, {payload}),
	createSection: (payload) => createAction(CREATE_SECTION, {payload}),
	createSectionSuccess: (payload) => createAction(CREATE_SECTION_SUCCESS, {payload}),
	updateSection: (payload) => createAction(UPDATE_SECTION, {payload}),
	updateSectionSuccess: (payload) => createAction(UPDATE_SECTION_SUCCESS, {payload}),
	deleteSection: (payload) => createAction(DELETE_SECTION, {payload}),
	deleteSectionSuccess: (payload) => createAction(DELETE_SECTION_SUCCESS, {payload}),
	deleteOrderField: (payload) => createAction(DELETE_ORDER_FIELD, {payload}),
	deleteOrderFieldSuccess: (payload) => createAction(DELETE_ORDER_FIELD_SUCCESS, {payload}),
	moveOrderField: (payload) => createAction(MOVE_ORDER_FIELD, {payload}),
	moveOrderFieldSuccess: (payload) => createAction(MOVE_ORDER_FIELD_SUCCESS, {payload}),
	moveSection: (payload) => createAction(MOVE_SECTION, {payload}),
	moveSectionSuccess: (payload) => createAction(MOVE_SECTION_SUCCESS, {payload}),
};

async function handleFileRead(event) {
	const file = event;
	const base64 = await convertBase64(file);
	return base64;
}

export const sagas = {
	*fetchClients({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size} = yield select((state) => state.app.client.index);
			const {page} = yield select((state) => state.app.client.index);
			const {search} = payload;

			const response = yield axios.get(
				`/client/all?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchClientsSuccess(response?.data?.data.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchClient(payload) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/client/${payload.payload}`);
			yield put(actions.fetchClientSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchClientsByDropdown() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/client/drop-down`);

			yield put(actions.fetchClientsByDropdownSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*setSelectedClientId(id) {
		yield put(actions.setLoading(true));
		try {
			if (id !== 0) {
				yield put(actions.setSelectedClientIdSuccess(id));
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeLogo({payload}) {
		yield put(actions.setLoading(true));

		try {
			const formData = new FormData();
			formData.append("file", payload.selectedFile);
			const obj = {
				...payload?.selectedFile,
				logo: payload?.selectedFile
					? yield call(handleFileRead, payload?.selectedFile)
					: null,
				clientId: payload?.clientId,
			};
			const response = yield axios.put(`/client/logo/update`, obj);
			yield put(actions.changeLogoSuccess(response.data?.data));
			yield put(actions.updateInitialLogo(response?.data?.data));
			ToastSuccesComponent(i18n.t("ImageChangeSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*updateSettings({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {client} = yield select((state) => state.app.client.index);
			const getValueOrDefault = (payloadKey, clientKey) =>
				payload && payloadKey in payload
					? payload[payloadKey]
					: client[clientKey]?.id || client[clientKey];
			const obj = {
				clientId: client?.id || payload?.clientId || client?.clientId,
				exportPdf: getValueOrDefault("checkedPDF", "exportPdf"),
				pdfColor: getValueOrDefault("color", "pdfColor"),
				pdfEmail: getValueOrDefault("email", "pdfEmail"),
				pdfEmailName: getValueOrDefault("emailName", "pdfEmailName"),
				sendOfferEmail: getValueOrDefault("checkedOfferEmail", "sendOfferEmail"),
				sendOrderPdfEmail: getValueOrDefault("checkedOrderPdfEmail", "sendOrderPdfEmail"),
				generateExternalId: getValueOrDefault("generateExternalId", "generateExternalId"),
				leadsLocation: getValueOrDefault("leadsLocation", "leadsLocation"),
				leads: getValueOrDefault("leads", "leads"),
				includeGeneratedPdf: getValueOrDefault(
					"includeGeneratedPdf",
					"includeGeneratedPdf",
				),
				sendEmailForOrder: getValueOrDefault("sendEmailForOrder", "sendEmailForOrder"),
				description: getValueOrDefault("description", "description"),
				subject: getValueOrDefault("subject", "subject"),
				attachedFiles: getValueOrDefault("uploadedPDFs", "attachedFiles"),
				emailData: getValueOrDefault("emailData", "emailData"),
				fieldSettings: client?.fieldSettings,
				scheduleDeletePdf: getValueOrDefault("scheduleDeletePdf", "scheduleDeletePdf"),
				defaultOrderStatus: getValueOrDefault("defaultOrderStatus", "defaultOrderStatus"),
				deliverPdfWithStatusId: getValueOrDefault(
					"deliverPdfWithStatusId",
					"deliverPdfWithStatusId",
				),
			};
			yield axios.put(`/client_settings/update`, obj);
			yield put(
				actions.updateSettingsSuccess({
					...obj,
					stringClientId: client?.stringClientId || client?.clientId,
				}),
			);
			yield put(actions.testConnectionSuccess("null"));
			ToastSuccesComponent(i18n.t("ClientSettingsSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message[0]?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*createGenericPdf({payload}) {
		yield put(actions.setUploading(true));
		try {
			const response = yield axios.post(`/client/upload_file`, {
				...payload,
				generateFileName: "|companyName|,$CURRENT_DATE$,#00001#",
			});
			yield put(actions.createGenericPdfSuccess(response.data?.data));
			ToastSuccesComponent(i18n.t("ClientSettingsSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message[0]?.message);
			logger.error(error);
		} finally {
			yield put(actions.setUploading(false));
		}
	},
	*replaceGenericPdf({payload}) {
		yield put(actions.setUploading(true));
		try {
			const {payloadObj, handleClose} = payload;
			const response = yield axios.post(`/client/replace_pdf_file`, payloadObj);
			yield put(actions.createGenericPdfSuccess(response.data?.data));
			handleClose();
			ToastSuccesComponent(i18n.t("ClientSettingsSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message[0]?.message);
			logger.error(error);
		} finally {
			yield put(actions.setUploading(false));
		}
	},
	*removeAttachment({payload}) {
		try {
			if (payload?.id) {
				yield axios.put(`client_settings/delete/${payload?.id}`);
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
	*fetchGenericPdf(id) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/client/pdf/${id?.payload}`);
			yield put(actions.fetchGenericPdfSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message?.[0]?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchGenericPdfByID({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(
				`/client/${payload?.client}/pdf_fields/${payload?.id}`,
			);
			yield put(actions.fetchGenericPdfByIDSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message[0]?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*saveGenericPdf({payload}) {
		yield put(actions.setUploading(true));
		try {
			const {values, onClose} = payload;
			yield axios.post(`/client/pdf_fields`, values);
			onClose();
			ToastSuccesComponent(i18n.t("PdfFieldsCreatedSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setUploading(false));
		}
	},
	*saveGenericOrder({payload}) {
		yield put(actions.setUploading(true));
		try {
			yield axios.post(`/client/order_fields`, payload);
			// yield put(navigateActions.navigate(`/admin/clients/${payload?.clientId}`));
			ToastSuccesComponent(i18n.t("OrderFieldsCreatedSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setUploading(false));
		}
	},
	*getAllCostumFields({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/client/custom_fields`, {
				params: {
					clientId: payload,
				},
			});
			yield put(actions.getAllCostumFieldsSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
			// console.log(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*createCustomField({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {groupedOrderFields} = yield select((state) => state.app.client.index);
			const {values, setModal} = payload;
			const onSuccess = () => setModal(false);
			const response = yield axios.post(`/client/create/custom_fields`, values);

			yield put(actions.createCustomFieldSuccess(response?.data?.data));

			if (values.dropDownParentId) {
				// send request to create order field for this dropdown
				const parent = groupedOrderFields?.find(
					(item) => item.fieldId === values?.dropDownParentId,
				);
				// check if its parent is an order field
				if (parent?.parentId) {
					const parentChildrenSortNumbers = parent?.options?.map(
						(opt) => opt.orderSortNr,
					);
					const maxSortNumber = Math.max(...parentChildrenSortNumbers);
					const data = {
						customFieldId: response?.data?.data?.id,
						orderFieldId: null,
						targetSectionId: parent.parentId,
						targetSortNumber: maxSortNumber + 1,
						previousSectionId: null,
						previousSortNumber: null,
					};
					yield delay(1000);
					yield put(
						actions.moveOrderField({
							data,
							onSuccess,
							dropDownParentId: parent.fieldId,
							child: response?.data?.data,
						}),
					);
				} else {
					onSuccess();
				}
			} else {
				onSuccess();
			}
			ToastSuccesComponent(i18n.t("CustomFieldCreated"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.code);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*editCustomField({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.put(
				`client/update/custom_fields`,
				payload?.values || payload,
			);
			yield put(actions.editCustomFieldSuccess(response?.data?.data));
			payload.setModal(false);
			ToastSuccesComponent(i18n.t("CustomFieldUpdated"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.code);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getCustomFieldById({payload}) {
		try {
			const response = yield axios.get(`client/custom_fields/${payload}`);
			yield put(
				actions.getCustomFieldByIdSuccess({
					id: response?.data?.data?.id,
					data: response?.data?.data,
				}),
			);
			yield put(actions.setShowModal(true));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
	*fetchGenericOrderByID({payload}) {
		yield put(actions.setLoading(true));
		try {
			const baseEndpoint = `/client/order_fields`;
			const newEndpoint = payload?.clientContractType
				? `${baseEndpoint}/${payload?.clientId}?clientContractType=${payload?.clientContractType}`
				: `${baseEndpoint}/${payload}`;
			const response = yield axios.get(newEndpoint);
			yield put(actions.fetchGenericOrderByIDSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message[0]?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*testConnection({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post(ENDPOINT.TEST_CONNECTION, payload);
			if (response) {
				yield put(actions.testConnectionSuccess(false));
				ToastSuccesComponent(i18n.t("ConnectedSuccess"));
			} else {
				ToastErrorComponent(i18n.t("ServiceInactive"));
				yield put(actions.testConnectionSuccess(true));
			}
		} catch (error) {
			logger.error(error);
			yield put(actions.testConnectionSuccess(true));
			ToastErrorComponent(i18n.t("CheckCredentials"));
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*connectSftpServer({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put(ENDPOINT.CONNECT_SERVER, payload);
			ToastSuccesComponent(i18n.t("ConnectedSuccess"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*updatePdfField({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {clientId, pdfId, values, handleClose} = payload;
			yield axios.put(
				ENDPOINT.UPDATE_PDF_FIELD.replace(":clientId", clientId).replace(":pdfId", pdfId),
				values,
			);
			handleClose();
			ToastSuccesComponent("PDF Field edited successfully");
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*updateCustomField({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {values, setModal} = payload;
			yield axios.put(ENDPOINT.UPDATE_CUSTOM_FIELD, values);
			yield put(actions.updateCustomFieldSuccess(values));
			setModal(false);
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*createSection({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {values, setModal} = payload;
			const payloadObj = {
				...values,
				sectionId: moment().valueOf(),
			};
			const response = yield axios.post("form_sections", payloadObj);
			yield put(
				actions.createSectionSuccess({
					...response?.data?.data,
					id: payloadObj.sectionId,
				}),
			);
			setModal(false);
			ToastSuccesComponent(i18n.t("sectionCreated"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*updateSection({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {values, setModal} = payload;
			yield axios.put(`form_sections/${values.id}`, values);
			yield put(actions.updateSectionSuccess(values));
			setModal(false);
			ToastSuccesComponent(i18n.t("sectionUpdated"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteSection({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {id, setModal} = payload;
			yield axios.delete(`form_sections/${id}`);
			yield put(actions.deleteSectionSuccess(id));
			setModal(false);
			ToastSuccesComponent(i18n.t("sectionDeleted"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteOrderField({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {id, setModal} = payload;
			yield axios.delete(`order_field/${id}`);
			yield put(actions.deleteOrderFieldSuccess(id));
			setModal(false);
			ToastSuccesComponent(i18n.t("orderFieldDeleted"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*moveOrderField({payload}) {
		try {
			const {data, onSuccess, dropDownParentId, child} = payload;
			const response = yield axios.put("order_field/dnd", data);
			if (data.customFieldId) {
				yield put(
					actions.moveOrderFieldSuccess({
						...response?.data?.data,
						...data,
						dropDownParentId,
						child,
					}),
				);
			}
			onSuccess();
		} catch (error) {
			logger.error(error);
		}
	},
	*moveSection({payload}) {
		try {
			const {data, onSuccess} = payload;
			yield axios.put("form_sections/dnd", data);
			onSuccess();
			yield put(actions.moveSectionSuccess(data));
		} catch (error) {
			logger.error(error);
		}
	},
};
export const watcher = function* w() {
	yield takeLatest(FETCH_CLIENTS, sagas.fetchClients);
	yield takeLatest(FETCH_CLIENT, sagas.fetchClient);
	yield takeLatest(FETCH_CLIENTS_BY_DROPDOWN, sagas.fetchClientsByDropdown);
	yield takeLatest(SET_SELECTED_CLIENT_ID, sagas.setSelectedClientId);
	yield takeLatest(CHANGE_LOGO, sagas.changeLogo);
	yield takeLatest(UPDATE_SETTINGS, sagas.updateSettings);
	yield takeLatest(CREATE_GENERIC_PDF, sagas.createGenericPdf);
	yield takeLatest(SAVE_GENERIC_PDF, sagas.saveGenericPdf);
	yield takeLatest(FETCH_GENERIC_PDF, sagas.fetchGenericPdf);
	yield takeLatest(GET_ALL_COSTUM_FIELDS, sagas.getAllCostumFields);
	yield takeLatest(SAVE_GENERIC_ORDER, sagas.saveGenericOrder);
	yield takeLatest(FETCH_GENERIC_PDF_ID, sagas.fetchGenericPdfByID);
	yield takeLatest(FETCH_GENERIC_ORDER_ID, sagas.fetchGenericOrderByID);
	yield takeLatest(REMOVE_ATTACHMENT, sagas.removeAttachment);
	yield takeLatest(ADD_CUSTOM_FIELD, sagas.createCustomField);
	yield takeLatest(EDIT_CUSTOM_FIELD, sagas.editCustomField);
	yield takeLatest(GET_CUSTOM_FIELD_BY_ID, sagas.getCustomFieldById);
	yield takeLatest(TEST_CONNECTION, sagas.testConnection);
	yield takeLatest(CONNECT_SFTP_SERVER, sagas.connectSftpServer);
	yield takeEvery(UPDATE_PDF_FIELD, sagas.updatePdfField);
	yield takeLatest(UPDATE_CUSTOM_FIELD, sagas.updateCustomField);
	yield takeLatest(CREATE_SECTION, sagas.createSection);
	yield takeLatest(UPDATE_SECTION, sagas.updateSection);
	yield takeLatest(DELETE_SECTION, sagas.deleteSection);
	yield takeLatest(DELETE_ORDER_FIELD, sagas.deleteOrderField);
	yield takeLatest(MOVE_ORDER_FIELD, sagas.moveOrderField);
	yield takeLatest(MOVE_SECTION, sagas.moveSection);
	yield takeLatest(REPLACE_GENERIC_PDF, sagas.replaceGenericPdf);
};
