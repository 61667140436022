/* eslint-disable max-len */
import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {changeOrderFn} from "@utils/functions";
import {actions as deleteProductActions} from "@sagas/products/deleteClientProduct";
import {ENDPOINT} from "@src/utils/endpoint";

const logger = new Logger("Product client");

const PREFIX = "@app/store/sagas/app/product/clientProduct";

// clients
export const FETCH_CLIENT_PRODUCT = `${PREFIX}FETCH_CLIENT_PRODUCT`;
export const FETCH_CLIENT_PRODUCT_SUCCESS = `${PREFIX}FETCH_CLIENT_PRODUCT_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const ADD_PRODUCT_ON_STATE = `${PREFIX}ADD_PRODUCT_ON_STATE`;
export const EDIT_PRODUCT_ON_STATE = `${PREFIX}EDIT_PRODUCT_ON_STATE`;
export const DELETE_PRODUCT_ON_STATE = `${PREFIX}DELETE_PRODCT_ON_STATE`;
export const DEACTIVATE_PRODUCT_ON_STATE = `${PREFIX}DEACTIVATE_PRODUCT_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const TOTAL_SIZE_DECREASE = `${PREFIX}TOTAL_SIZE_DECREASE`;
export const CHANGE_PRODUCT_ORDER = `${PREFIX}CHANGE_PRODUCT_ORDER`;
export const CHANGE_PRODUCT_ORDER_SUCCESS = `${PREFIX}CHANGE_PRODUCT_ORDER_SUCCESS`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const TOGGLE_PRODUCT_VISIBILITY = `${PREFIX}TOGGLE_PRODUCT_VISIBILITY`;
export const TOGGLE_PRODUCT_VISIBILITY_SUCCESS = `${PREFIX}TOGGLE_PRODUCT_VISIBILITY_SUCCESS`;
export const FETCH_PRODUCT_BY_ID = `${PREFIX}FETCH_PRODUCT_BY_ID`;
export const FETCH_PRODUCT_BY_ID_SUCCESS = `${PREFIX}FETCH_PRODUCT_BY_ID_SUCCESS`;

const _state = {
	clientProducts: [],
	isLoading: false,
	page: 1,
	size: 30,
	totalSize: 5,
	search: "",
	totalPages: 1,
	singleProduct: {},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_CLIENT_PRODUCT_SUCCESS:
				draft.clientProducts =
					state?.page > 1 ? [...state?.clientProducts, ...payload] : payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case ADD_PRODUCT_ON_STATE:
				const product = {
					...payload,
				};
				draft.clientProducts = [product].concat(state.clientProducts);
				break;
			case EDIT_PRODUCT_ON_STATE:
				const index = state.clientProducts.findIndex((c) => c.id === payload.id);
				draft.clientProducts[index] = {
					...payload,
					cancellationReserve: +payload?.cancellationReserve?.value,
				};
				break;
			case DEACTIVATE_PRODUCT_ON_STATE:
				const clientProducts = state.clientProducts.filter(
					(product) => product.id !== payload,
				);
				if (state?.page !== 1 && clientProducts.length === 0) {
					draft.page = 1;
				} else {
					draft.clientProducts = clientProducts;
				}
				break;
			case DELETE_PRODUCT_ON_STATE:
				draft.clientProducts = state.clientProducts.filter(
					(product) => product.id !== payload,
				);
				break;
			case LOADING:
				draft.isLoading = payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			case CHANGE_PRODUCT_ORDER_SUCCESS:
				const itemIndex = state.clientProducts.findIndex(
					(item) => item?.id === payload?.id,
				);
				const newValue = changeOrderFn(state?.clientProducts, itemIndex, payload?.action);
				draft.clientProducts = newValue;
				break;
			case TOGGLE_PRODUCT_VISIBILITY_SUCCESS:
				draft.clientProducts = state?.clientProducts?.map((item) =>
					item.id === payload ? {...item, isHidden: !item.isHidden} : item,
				);
				break;
			case FETCH_PRODUCT_BY_ID_SUCCESS:
				draft.singleProduct = payload;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchClientProducts: (payload) => createAction(FETCH_CLIENT_PRODUCT, {payload}),
	fetchClientProductsSuccess: (payload) => createAction(FETCH_CLIENT_PRODUCT_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	addProductOnState: (payload) => createAction(ADD_PRODUCT_ON_STATE, {payload}),
	editProductOnState: (payload) => createAction(EDIT_PRODUCT_ON_STATE, {payload}),
	deactivateProductOnState: (payload) => createAction(DEACTIVATE_PRODUCT_ON_STATE, {payload}),
	deleteProductOnState: (payload) => createAction(DELETE_PRODUCT_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterProducts: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	totalSizeDecrease: (payload) => createAction(TOTAL_SIZE_DECREASE, {payload}),
	changeProductOrder: (payload) => createAction(CHANGE_PRODUCT_ORDER, {payload}),
	changeProductOrderSuccess: (payload) => createAction(CHANGE_PRODUCT_ORDER_SUCCESS, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	toggleProductVisibility: (payload) => createAction(TOGGLE_PRODUCT_VISIBILITY, {payload}),
	toggleProductVisibilitySuccess: (payload) =>
		createAction(TOGGLE_PRODUCT_VISIBILITY_SUCCESS, {payload}),
	fetchProductById: (payload) => createAction(FETCH_PRODUCT_BY_ID, {payload}),
	fetchProductByIdSuccess: (payload) => createAction(FETCH_PRODUCT_BY_ID_SUCCESS, {payload}),
};

export const sagas = {
	*fetchClientProducts({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size, page} = yield select((state) => state.app.products.clientProduct);
			const searchType = typeof payload?.search;
			const search = searchType === "string" ? payload?.search : "";

			const response = yield axios.get(
				`/product/all/${
					payload?.clientId || payload
				}?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchClientProductsSuccess(response?.data?.data.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeProductOrder({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put("/product/change", {
				id: payload?.id,
				newRow: payload?.newRow,
			});
			yield put(actions.changeProductOrderSuccess(payload));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*toggleProductVisibility({payload}) {
		yield put(deleteProductActions.setLoading(true));
		try {
			const {id, closeModal, isHidden} = payload;
			yield axios.post(ENDPOINT.TOGGLE_PRODUCT, {id, isHidden});
			yield put(actions.toggleProductVisibilitySuccess(id));
			closeModal();
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(deleteProductActions.setLoading(false));
		}
	},
	*fetchProductById({payload}) {
		try {
			const response = yield axios.get(`/product/${payload}`);
			yield put(actions.fetchProductByIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_CLIENT_PRODUCT, sagas.fetchClientProducts);
	yield takeLatest(CHANGE_PRODUCT_ORDER, sagas.changeProductOrder);
	yield takeLatest(TOGGLE_PRODUCT_VISIBILITY, sagas.toggleProductVisibility);
	yield takeLatest(FETCH_PRODUCT_BY_ID, sagas.fetchProductById);
};
