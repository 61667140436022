import Form from "@src/common/Form";
import React from "react";
import {withRouter} from "react-router";
import {actions as orderActions} from "@sagas/orders/index";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import {connect} from "react-redux";
import {priceInputs} from "../../calculations/CalculationDetails/calcDetailsHelper";

const PriceForm = ({setManualCorrection, loading}) => {
	const {t} = useTranslation();
	const {getUrlParams, setModal} = useUrlParams();
	const {id, modal} = getUrlParams("modal", "id");
	const formData = {
		inputs: priceInputs,
		title: t("AddPrice"),
		submitFn: (values) => {
			setManualCorrection({
				...values,
				orderId: id,
				setModal,
			});
		},
	};
	return (
		<Form
			formData={formData}
			open={modal === "price"}
			loading={loading}
			handleClose={() => setModal(false)}
		/>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.orders.index.loading,
});

const mapDispatchToProps = {
	setManualCorrection: orderActions.setManualCorrection,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PriceForm));
