import React, {useState} from "react";
import InputComponent from "@src/common/input";
import i18n from "@src/i18n";
import {useFeatures} from "@src/utils/useFeatures";
import IbanInput from "@src/components/dashboard/Energy/components/IbanValidator";
import _ from "lodash";
import Signature from "@src/common/signature";
import GenericDatePicker from "@src/common/CustomDatePicker";
import EmailInput from "@src/common/EmailInput";
import MediaComponent from "@src/common/messagesComponents/MediaComponent";
import CustomSelect from "@src/common/Select";
import CheckBoxComponentAntd from "@src/common/checkbox/antdCheckbox";
import TextAreaComponent from "@src/common/TextArea/TextAreaComponent";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import {connect} from "react-redux";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {DATE_INPUTS, getDisabledState, shouldHideField, TEXT_INPUTS} from "../helpers";

const currentLanguage = i18n.language;

const FieldsRenderer = ({
	fields,
	values,
	errors,
	setFieldValue,
	orderFields,
	fieldOptions,
	retrieveDataFromGetAg,
	options,
	editMode,
	uploadFile,
	loading,
	removeFile,
	emailErrors,
	setEmailErrors,
	order,
	validateEmail,
	setState,
	state,
}) => {
	const {checkFeature} = useFeatures();
	const [confirmModal, setConfirmModal] = useState({
		file: null,
		open: false,
		name: null,
	});
	const handleConfirmModal = (file, name = null) => setConfirmModal({file, name, open: !!file});

	const handleDeleteFile = () => {
		removeFile(confirmModal);
		handleConfirmModal(false);
	};
	const isGetAgAvailable = (requestType, targetFeature) => {
		if (!requestType) {
			return true;
		}
		return checkFeature(targetFeature);
	};
	const handleDependencies = (dependencyFields, value) => {
		if (dependencyFields) {
			dependencyFields.forEach((item) => {
				const foundedDep = orderFields.find((it) => it.name === item);
				if (foundedDep.inputType === "CHECKBOX") {
					if (
						value ||
						foundedDep?.dependentFrom?.split(",").some((val) => values[val?.trim()])
					) {
						setFieldValue(foundedDep.name, true);
					} else {
						setFieldValue(foundedDep.name, false);
					}
				}
			});
		}
	};
	const handleInput = (item, value) => {
		const {defaultValue, name, dependencyFields, assignInputTo, requestType} = item;
		if (!defaultValue || value.startsWith(defaultValue)) {
			setFieldValue(name, value, true);
		}
		handleDependencies(dependencyFields, value);
		if (assignInputTo?.[0]) {
			assignInputTo.forEach((childrenItems) => {
				setFieldValue(childrenItems, value);
			});
		}
		if (requestType && isGetAgAvailable(requestType, "CHECK_ADDRESS")) {
			retrieveDataFromGetAg({...item, type: "postCode"}, value);
		}
	};
	const handleUpload = (event, field) => {
		const fileList = event.target.files;
		const images = values?.[field.name];
		if (images?.length + fileList?.length > 10) {
			ToastInfoComponent(i18n.t("UploadLengthInfo10"));
			event.preventDefault();
			return;
		}
		const fileDataArray = Array.from(fileList).map(
			(file) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onloadend = () => {
						resolve({
							filename: file.name,
							base64: reader.result,
							type: file.type,
							extension: file.name.split(".").pop()?.toLowerCase(),
							size: file.size,
							name: field?.name,
							fieldMapping: fields,
							values,
						});
					};
					reader.onerror = () => {
						reader.abort();
						reject(new DOMException("Problem parsing input file."));
					};
				}),
		);
		Promise.all(fileDataArray).then(uploadFile);
	};
	const handleDropdown = (item, value) => {
		const {dependentFrom, dependencyFields, name, requestType, isMultiple} = item;
		setFieldValue(name, isMultiple ? [value, ...(values?.[name] || [])] : value);
		handleDependencies(dependencyFields, value);
		if (requestType && requestType !== "STREET") {
			retrieveDataFromGetAg({...item, type: "location"}, value, values?.[dependentFrom]);
		}
	};
	const handleDeselect = (item, value) => {
		const {name} = item;
		setFieldValue(
			name,
			values?.[name]?.filter((v) => v !== value),
		);
	};
	return (
		<>
			<DeleteModal
				calculationModal
				newTitle={i18n.t("areYouSureDeleteFile")}
				open={confirmModal?.open}
				setShowDeleteModal={() => handleConfirmModal(false)}
				handleClose={() => handleConfirmModal(false)}
				disabled={loading}
				confirm={handleDeleteFile}
			/>
			{fields?.map((item) => {
				const {name, inputType, requestType, labelEn, labelDe, fieldId, isMultiple} = item;
				const label = currentLanguage === "en" ? labelEn : labelDe;
				if (shouldHideField({item, values})) return null;
				const isDisabled = getDisabledState(item, order);
				return (
					<div key={name} className="single__input">
						{TEXT_INPUTS.includes(inputType) && requestType !== "IBAN" && (
							<InputComponent
								label={label}
								values={values?.[name] || ""}
								errors={errors?.[name]}
								touched
								handleChange={(e) => handleInput(item, e.target.value)}
								errorClass="errorClass"
								disabled={isDisabled}
							/>
						)}
						{requestType === "IBAN" && (
							<IbanInput
								element={{name, item}}
								setFieldValue={setFieldValue}
								value={_.get(values, name) ?? ""}
								fields={fields}
								canValidate={isGetAgAvailable(requestType, "CHECK_IBAN")}
								disabled={isDisabled}
							/>
						)}
						{inputType === "IMAGE" && (
							<div className="signature__wrapper">
								<span>{label}</span>
								{editMode && _.get(values, name) ? (
									<img
										src={_.get(values, name)}
										style={{height: 100, width: 300}}
										alt={name}
									/>
								) : (
									<Signature
										setFieldValue={setFieldValue}
										element={{name, item}}
										disabled={isDisabled}
									/>
								)}
								{errors?.[name] && (
									<span className="errorClass">{errors[name]}</span>
								)}
							</div>
						)}
						{inputType === "EMAIL" && (
							<EmailInput
								handleInput={(e) => handleInput(item, e.target.value)}
								element={{name, item}}
								value={_.get(values, name)}
								label={label}
								touched
								error={errors?.[name]}
								disabled={isDisabled}
								emailErrors={emailErrors}
								setEmailErrors={setEmailErrors}
								editMode={editMode}
								validateEmail={validateEmail}
								setState={setState}
								state={state}
							/>
						)}
						{inputType === "UPLOAD" && (
							<MediaComponent
								totalFiles={{files: values?.[name] || []}}
								showTitle
								customClassName="edit-order-class"
								title={label}
								canDownload
								canUploadMore={!isDisabled}
								height="auto"
								downloadWithoutToken
								id={fieldId}
								canRemove
								onRemove={(file) => handleConfirmModal(file.fileUrl, name)}
								uploadInputHandler={(event) => handleUpload(event, item)}
							/>
						)}
						{inputType === "DROPDOWN" && (
							<>
								{!isGetAgAvailable(requestType, "CHECK_ADDRESS") ? (
									<InputComponent
										label={label}
										values={values?.[name]}
										errors={errors?.[name]}
										touched
										handleChange={(e) => handleInput(item, e.target.value)}
										errorClass="errorClass"
										disabled={isDisabled}
									/>
								) : (
									<CustomSelect
										options={
											requestType
												? options?.[name] || []
												: fieldOptions?.[fieldId]
										}
										mode={isMultiple ? "multiple" : undefined}
										onSelect={(value) => handleDropdown(item, value)}
										onClear={() => setFieldValue(name, isMultiple ? [] : null)}
										onDeselect={(value) => handleDeselect(item, value)}
										label={label}
										value={values?.[name]}
										disabled={isDisabled}
										touched
										errors={errors?.[name]}
									/>
								)}
							</>
						)}
						{DATE_INPUTS.includes(inputType) && (
							<GenericDatePicker
								setFieldValue={setFieldValue}
								element={{name, item}}
								error={_.get(errors, name)}
								value={_.get(values, name)}
								label={label}
								disabled={isDisabled}
							/>
						)}
						{inputType === "TEXTAREA" && (
							<TextAreaComponent
								label={label}
								setFieldValue={setFieldValue}
								value={values?.[name]}
								touched
								errors={errors?.[name]}
								inputName={name}
								breakLine={false}
								disabled={isDisabled}
							/>
						)}
						{inputType === "CHECKBOX" && (
							<CheckBoxComponentAntd
								label={label}
								name={name}
								handleChange={(e) => handleInput(item, e.target.checked)}
								checked={values?.[name]}
								disabled={isDisabled}
							/>
						)}
					</div>
				);
			})}
		</>
	);
};
const mapStateToProps = (state) => ({
	loading: state.app.orders.edit.loading,
});
const mapDispatchToProps = {
	uploadFile: editOrderActions.uploadOrderFile,
	removeFile: editOrderActions.removeOrderFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldsRenderer);
