/* eslint-disable max-len */
export const dataDe = [
	{
		title: "1. Datenschutz auf einen Blick",
		textBigger: "Allgemeine Hinweise",
		text: "Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.",
	},
	{
		extraSpace: "",
	},
	{
		textBigger: "Datenerfassung auf dieser Website",
	},
	{
		title: "Wer ist verantwortlich für die Datenerfassung auf dieser Website?",
		text: "Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.",
	},
	{
		title: "Wie erfassen wir Ihre Daten?",
		text: "Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.",
	},
	{
		title: "Wofür nutzen wir Ihre Daten?",
		text: "Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.",
	},
	{
		title: "Welche Rechte haben Sie bezüglich Ihrer Daten?",
		text: "Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.",
	},
	{
		title: "Analyse-Tools und Tools von Drittanbietern",
		text: "Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen. Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.",
	},
	{
		extraSpace: "",
	},
	{
		title: "2. Hosting und Content Delivery Networks (CDN)",
		textBigger: "Externes Hosting",
		text: `Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.`,
	},

	{
		rows: [
			`Wir setzen folgenden Hoster ein:`,
			"1&1 IONOS SE",
			"Der Datenschutzbeauftragte",
			"Elgendorfer Straße 57",
			"56410 Montabaur",
		],
	},
	{
		title: "3. Allgemeine Hinweise und Pflichtinformationen",
		textBigger: "Datenschutz",
		text: "Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.",
	},

	{
		textBigger: "Speicherdauer",
		text: "Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen  Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.",
	},
	{
		textBigger: "Hinweis zur Datenweitergabe in die USA",
		text: `Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.`,
	},
	{
		textBigger: "Widerruf Ihrer Einwilligung zur Datenverarbeitung",
		text: `Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.`,
	},
	{
		textBigger:
			"Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)",
		text: `Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 Lit. E oder F DSGVO erfolgt, haben sie jederzeit das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die Verarbeitung ihrer personenbezogenen Daten Widerspruch einzulegen; Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen sie dieser Datenschutzerklärung. Wenn sie Widerspruch einlegen, werden wir ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige gründe für die Verarbeitung nachweisen, die ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach art. 21 Abs. 1 DSGVO).
        Werden ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben sie das recht, jederzeit Widerspruch gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn sie widersprechen, werden ihre personenbezogenen Daten anschliessend nicht mehr zum zwecke der direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).`,
	},
	{
		textBigger: "Beschwerderecht bei der zuständigen Aufsichtsbehörde",
		text: `Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.`,
	},
	{
		textBigger: "Recht auf Datenübertragbarkeit",
		text: `Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.`,
	},
	{
		textBigger: "SSL- bzw. TLS-Verschlüsselung",
		text: `Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.`,
	},
	{
		textBigger: "Auskunft, Löschung und Berichtigung",
		text: `Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.`,
	},
	{
		textBigger: "Recht auf Einschränkung der Verarbeitung",
		text: `Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:`,
		list: [
			"Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.",
			"Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.",
			"Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.",
			"Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.",
		],
		text2: `Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.`,
	},
	{
		title: "4. Datenerfassung auf dieser Website",
		textBigger: "Cookies",
		text: `Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
        Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.`,
	},
	{
		textBigger: "Server-Log-Dateien",
		text: `Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:`,
		list: [
			"Browsertyp und Browserversion",
			"verwendetes Betriebssystem",
			"Referrer URL",
			"Hostname des zugreifenden Rechners",
			"Uhrzeit der Serveranfrage",
			"IP-Adresse",
		],
		text2: `Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.`,
	},
	{
		textBigger: "Kontaktformular",
		text: `Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.`,
	},
	{
		textBigger: "Anfrage per E-Mail, Telefon oder Telefax",
		text: `Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.`,
	},
	{
		title: "5. Soziale Medien",
		textBigger: "Facebook Plugins (Like & Share-Button)",
		text: `Auf dieser Website sind Plugins des sozialen Netzwerks Facebook integriert. Anbieter dieses Dienstes ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere Drittländer übertragen.
        Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf dieser Website. Eine Übersicht über die Facebook Plugins finden Sie hier: https://developers.facebook.com/docs/plugins/?locale=de_DE.
        Wenn Sie diese Website besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken, während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter: https://de-de.facebook.com/privacy/explanation.
        Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
        Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet werden, sind wir und die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich auf die Erfassung der Daten und deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter: https://www.facebook.com/legal/controller_addendum. Laut dieser Vereinbarung sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools und für die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die Datensicherheit der Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte (z.B. Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an Facebook weiterzuleiten.
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://www.facebook.com/legal/EU_data_transfer_addendum, https://de-de.facebook.com/help/566994660333381 und https://www.facebook.com/policy.php.`,
	},
	{
		textBigger: "Instagram Plugin",
		text: `Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland integriert.
        Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook bzw. Instagram weitergeleitet werden, sind wir und die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich auf die Erfassung der Daten und deren Weitergabe an Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter: https://www.facebook.com/legal/controller_addendum. Laut dieser Vereinbarung sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des Facebook- bzw. Instagram-Tools und für die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte (z.B. Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten können Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an Facebook weiterzuleiten.
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://www.facebook.com/legal/EU_data_transfer_addendum, https://help.instagram.com/519522125107875 und https://de-de.facebook.com/help/566994660333381.
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram: https://instagram.com/about/legal/privacy/.`,
	},
	{
		title: "6. Analyse-Tools und Werbung",
		textBigger: "WordPress Statistik",
		text: `Diese Website nutzt „WordPress Statistik“, um Besucherzugriffe statistisch auszuwerten. Anbieter ist die Automattic Inc., 60 29th Street #343, San Francisco, CA 94110-4929, USA.
        WordPress Statistik verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.B. Cookies oder Device-Fingerprinting). WordPress Statistik erfasst zur Analyse u. a. Logdateien (Referrer, IP-Adresse, Browser u. a.), die Herkunft der Websitebesucher (Land, Stadt) und welche Aktionen sie auf der Seite getätigt haben (z.B. Klicks, Ansichten, Downloads). Die so erfassten Informationen über die Benutzung dieser Website werden auf Servern in den USA gespeichert. Ihre IP-Adresse wird nach der Verarbeitung und vor der Speicherung anonymisiert.
        Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.`,
	},
	{
		title: "7. Plugins und Tools",
		textBigger: "Vimeo",
		text: `Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
        Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei Vimeo besitzen. Die von Vimeo erfassten Informationen werden an den Vimeo-Server in den USA übermittelt.
        Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen Sie Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account ausloggen.
        Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw. vergleichbare Wiedererkennungstechnologien (z.B. Device-Fingerprinting).
        Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission sowie nach Aussage von Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details finden Sie hier: https://vimeo.com/privacy.
        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter: https://vimeo.com/privacy.`,
	},
	{
		textBigger: "Google Maps",
		text: `Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://privacy.google.com/businesses/gdprcontrollerterms/ und https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: https://policies.google.com/privacy?hl=de.`,
	},
	{
		textBigger: "Facebook-Pixel",
		text: `Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen (sogenannte “Facebook-Ads”) zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Nutzern bei Facebook und innerhalb der Dienste der mit Facebook kooperierenden Partner (so genanntes “Audience Network” https://www.facebook.com/audiencenetwork/ ) anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interesse an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten ersichtlich werden) aufweisen, die wir an Facebook übermitteln (sogenannte “Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, indem wir sehen, ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Webseite weitergeleitet wurden (sogenannte “Konversionsmessung“).`,
		list: [
			"Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Daten, die den Standort des Endgeräts eines Endnutzers angeben).",
			"Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).",
			"Zwecke der Verarbeitung: Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Remarketing, Besuchsaktionsauswertung, Interessenbasiertes und verhaltensbezogenes Marketing, Profiling (Erstellen von Nutzerprofilen), Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen), Zielgruppenbildung (Bestimmung von für Marketingzwecke relevanten Zielgruppen oder sonstige Ausgabe von Inhalten), Cross-Device Tracking (geräteübergreifende Verarbeitung von Nutzerdaten für Marketingzwecke).",
			"Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der IP-Adresse).",
			"Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).",
			"Widerspruchsmöglichkeit (Opt-Out): Wir verweisen auf die Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern angegebenen Widerspruchsmöglichkeiten (sog. “Opt-Out”). Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die Möglichkeit, dass Sie Cookies in den Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet angeboten werden: a) Europa: https://www.youronlinechoices.eu. b) Kanada: https://www.youradchoices.ca/choices. c) USA: https://www.aboutads.info/choices. d) Gebietsübergreifend: http://optout.aboutads.info.",
		],
	},
];

/* eslint-disable max-len */
export const dataEn = [
	{
		title: "1. Data protection at a glance",
		textBigger: "General information",
		text: `The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data are all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our data protection declaration listed below this text.
        `,
	},
	{
		extraSpace: "",
	},
	{
		textBigger: "Data collection on this website",
	},
	{
		title: "Who is responsible for data collection on this website?",
		text: `The data processing on this website is carried out by the website operator. You can find their contact details in the section "Note on the responsible body" in this data protection declaration.`,
	},
	{
		title: "How do we collect your data?",
		text: `On the one hand, your data is collected when you provide it to us. This can be, for. E.g. data that you enter in a contact form. Other data are recorded by our IT systems automatically or with your consent when you visit the website. This is primarily technical data (e.g. internet browser, operating system or time of the page was viewed). This data is collected automatically as soon as you enter this website.`,
	},
	{
		title: "What do we use your data for?",
		text: "Some of the data is collected in order to ensure that the website is error-free. Other data can be used to analyze your user behavior.",
	},
	{
		title: "What rights do you have with regard to your data?",
		text: "You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request that the processing of your personal data be restricted under certain circumstances. You also have the right to lodge a complaint with the competent supervisory authority. You can contact us at any time if you have any further questions about data protection.",
	},
	{
		title: "Analysis tools and third party tools",
		text: "When you visit this website, your surfing behavior can be statistically evaluated. This is done primarily with so-called analysis programs. You can find detailed information on these analysis programs in the following data protection declaration.",
	},
	{
		extraSpace: "",
	},
	{
		title: "2. Hosting and Content Delivery Networks (CDN)",
		textBigger: "External hosting",
		text: `host's servers. This can primarily involve IP addresses, contact requests, meta and communication data, contract data, contact details, names, website accesses and other data generated via a website.
        The hoster is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 Para. 1 lit.b GDPR) and in the interest of a secure, fast and efficient provision of our online offer by a professional provider (Art. 6 Para . 1 lit.f GDPR).
        Our host will only process your data insofar as this is necessary to fulfill its performance obligations and follow our instructions with regard to this data.`,
	},

	{
		rows: [
			`We use the following hosters:`,
			"1 & 1 IONOS SE",
			"The data protection officer",
			"Elgendorfer Straße 57",
			"56410 Montabaur",
		],
	},
	{
		title: "3. General information and mandatory information",
		textBigger: "Privacy",
		text: "The operators of this website take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration. When you use this website, various personal data are collected. Personal data are data with which you can be personally identified. This data protection declaration explains which data we collect and what we use it for. It also explains how and for what purpose this is done. We would like to point out that data transmission over the Internet (e.g. when communicating by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible.",
	},

	{
		textBigger: "Storage period",
		text: "Unless a specific storage period is specified in this data protection declaration, your personal data will remain with us until the purpose for the data processing no longer applies. If you make a legitimate request for deletion or revoke your consent to data processing, your data will be deleted unless we have any other legally permissible reasons for storing your personal data (e.g. tax or commercial retention periods); in the latter case, the deletion takes place after these reasons no longer apply.",
	},
	{
		textBigger: "Note on data transfer to the USA",
		text: `Our website includes tools from companies based in the USA. When these tools are active, your personal data can be passed on to the US servers of the respective companies. We would like to point out that the USA is not a safe third country within the meaning of EU data protection law. US companies are obliged to surrender personal data to security authorities without you as the person concerned being able to take legal action against this. It can therefore not be ruled out that US authorities (e.g. secret services) process, evaluate and permanently store your data on US servers for monitoring purposes. We have no influence on these processing activities.`,
	},
	{
		textBigger: "Revocation of your consent to data processing",
		text: `Many data processing operations are only possible with your express consent. You can revoke your consent at any time. The legality of the data processing carried out before the revocation remains unaffected by the revocation.`,
	},
	{
		textBigger:
			"Right to object to the collection of data in special cases and to direct mail (Art. 21 GDPR)",
		text: `If the data processing takes place on the basis of Art. 6 Para. 1 Lit. E or F GDPR, you have the right to object to the processing of your personal data at any time for reasons that arise from your particular situation; This also applies to profiling based on these provisions. The respective legal basis on which processing is based can be found in this data protection declaration. If you object, we will no longer process your personal data concerned, unless we can prove compelling legitimate reasons for the processing that outweigh your interests, rights and freedoms or the processing serves to assert, exercise or defend legal claims ( Objection according to Art. 21 Paragraph 1 GDPR).
        If your personal data are processed in order to operate direct mail, you have the right to object at any time to the processing of your personal data for the purpose of such advertising; this also applies to profiling insofar as it is associated with such direct mail. If you object, your personal data will no longer be used for direct marketing purposes (objection according to Art. 21 Paragraph 2 GDPR).`,
	},
	{
		textBigger: "Right of appeal to the competent supervisory authority",
		text: `In the event of violations of the GDPR, the data subjects have the right to lodge a complaint with a supervisory authority, in particular in the member state of their habitual residence, their place of work or the place of the alleged violation. The right of appeal exists without prejudice to other administrative or judicial remedies.`,
	},
	{
		textBigger: "Right to data portability",
		text: `You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another person responsible, this will only be done if it is technically feasible.`,
	},
	{
		textBigger: "SSL or TLS encryption",
		text: `For security reasons and to protect the transmission of confidential content, such as orders or inquiries that you send to us as the website operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http: //" to "https: //" and by the lock symbol in your browser line. If the SSL or TLS encryption is activated, the data that you transmit to us cannot be read by third parties.`,
	},
	{
		textBigger: "Information, deletion and correction",
		text: `Within the framework of the applicable legal provisions, you have the right to free information about your stored personal data, their origin and recipient and the purpose of the data processing and, if necessary, a right to correct or delete this data. You can contact us at any time if you have any further questions on the subject of personal data.`,
	},
	{
		textBigger: "Right to restriction of processing",
		text: `You have the right to request that the processing of your personal data be restricted. You can contact us at any time for this purpose. The right to restriction of processing exists in the following cases:`,
		list: [
			"If you dispute the accuracy of your personal data stored by us, we usually need time to check this. For the duration of the test, you have the right to request that the processing of your personal data be restricted.",
			"If the processing of your personal data happened / happens unlawfully, you can request the restriction of the data processing instead of the deletion.",
			"If we no longer need your personal data, but you need them to exercise, defend or assert legal claims, you have the right to request that the processing of your personal data be restricted instead of being deleted.",
			"If you have lodged an objection in accordance with Art. 21 Paragraph 1 GDPR, your interests and ours must be weighed up. As long as it is not yet clear whose interests prevail, you have the right to request that the processing of your personal data be restricted.",
		],
		text2: `If you have restricted the processing of your personal data, these data - apart from their storage - may only be used with your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest processed by the European Union or a member state.`,
	},
	{
		title: "4. Data collection on this website",
		textBigger: "Cookies",
		text: `Our website uses so-called "cookies". Cookies are small text files and do not cause any damage to your device. They are stored either temporarily for the duration of a session (session cookies) or permanently (permanent cookies) on your device. Session cookies are automatically deleted after your visit. Permanent cookies remain stored on your device until you delete them yourself or your web browser automatically deletes them.
        In some cases, cookies from third-party companies can also be stored on your device when you enter our website (third-party cookies). These enable us or you to use certain third-party services (e.g. cookies for processing payment services).
        Cookies have different functions. Numerous cookies are technically necessary because certain website functions would not work without them (e.g. the shopping cart function or the display of videos). Other cookies are used to evaluate user behavior or to display advertising.
        Cookies that are required to carry out the electronic communication process (necessary cookies) or to provide certain functions you require (functional cookies, e.g. for the shopping cart function) or to optimize the website (e.g. cookies to measure the web audience) on the basis of Art. 6 Para. 1 lit.f GDPR, unless a different legal basis is given. The website operator has a legitimate interest in the storage of cookies for the technically error-free and optimized provision of its services. If consent to the storage of cookies has been requested, the storage of the relevant cookies takes place exclusively on the basis of this consent (Art. 6 Para. 1 lit. a GDPR); the consent can be withdrawn at any time.
        You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general, and activate the automatic deletion of cookies when you close the browser. If cookies are deactivated, the functionality of this website may be restricted.
        Insofar as cookies are used by third-party companies or for analysis purposes, we will inform you of this separately within the framework of this data protection declaration and, if necessary, ask for your consent.`,
	},
	{
		textBigger: "Server log files",
		text: `The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:`,
		list: [
			"Browser type and browser version",
			"operating system used",
			"Referrer URL",
			"Host name of the accessing computer",
			"Time of the server request",
			"IP address",
		],
		text2: `This data will not be merged with other data sources.
        This data is recorded on the basis of Art. 6 Paragraph 1 lit. f GDPR. The website operator has a legitimate interest in the technically error-free presentation and optimization of his website - the server log files must be recorded for this.`,
	},
	{
		textBigger: "Contact form",
		text: `If you send us inquiries using the contact form, your details from the inquiry form, including the contact details you provided there, will be stored by us for the purpose of processing the request and in case of follow-up questions. We do not pass on this data without your consent.
        This data is processed on the basis of Article 6 (1) (b) GDPR, provided that your request is related to the performance of a contract or is necessary to carry out pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the inquiries sent to us (Art. 6 Para. 1 lit. f GDPR) or on your consent (Art. 6 Para. 1 lit. a GDPR) if this was queried.
        The data you enter in the contact form will remain with us until you ask us to delete it, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory legal provisions - in particular retention periods - remain unaffected.`,
	},
	{
		textBigger: "Inquiries by email, phone or fax",
		text: `If you contact us by e-mail, telephone or fax, your request including all personal data derived from it (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.
        This data is processed on the basis of Article 6 (1) (b) GDPR, provided that your request is related to the performance of a contract or is necessary to carry out pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the inquiries sent to us (Art. 6 Para. 1 lit. f GDPR) or on your consent (Art. 6 Para. 1 lit. a GDPR) if this was queried.
        The data you send to us via contact requests will remain with us until you ask us to delete it, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.`,
	},
	{
		title: "5. Social media",
		textBigger: "Facebook plugins (like & share button)",
		text: `Plugins of the social network Facebook are integrated on this website. The provider of this service is Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland. According to Facebook, the data collected will also be transferred to the USA and other third countries.
        You can recognize the Facebook plugins by the Facebook logo or the “Like” button on this website. You can find an overview of the Facebook plugins here:  https://developers.facebook.com/docs/plugins/?locale=de_DE .
        When you visit this website, a direct connection is established between your browser and the Facebook server via the plug-in. Facebook receives the information that you have visited this website with your IP address. If you click the Facebook “Like” button while you are logged into your Facebook account, you can link the content of this website to your Facebook profile. This enables Facebook to assign your visit to this website to your user account. We would like to point out that, as the provider of the website, we have no knowledge of the content of the data transmitted or of how it is used by Facebook. You can find more information on this in Facebook's data protection declaration at:  https://de-de.facebook.com/privacy/explanation .
        If you do not want Facebook to be able to assign your visit to this website to your Facebook user account, please log out of your Facebook user account.
        The Facebook plugins are used on the basis of Art. 6 Para. 1 lit.f GDPR. The website operator has a legitimate interest in the widest possible visibility in social media. If a corresponding consent has been requested, the processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a GDPR; the consent can be withdrawn at any time.
        Insofar as personal data is collected on our website with the help of the tool described here and forwarded to Facebook, we and Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbor, Dublin 2, Ireland are jointly responsible for this data processing (Art. 26 GDPR ). The joint responsibility is limited exclusively to the collection of the data and its transfer to Facebook. The processing carried out by Facebook after the forwarding is not part of the shared responsibility. The obligations we have jointly have been set out in an agreement on joint processing. The wording of the agreement can be found at:  https://www.facebook.com/legal/controller_addendum. According to this agreement, we are responsible for providing data protection information when using the Facebook tool and for implementing the tool on our website in a manner that is secure under data protection law. Facebook is responsible for the data security of Facebook products. You can assert rights of data subjects (e.g. requests for information) with regard to the data processed on Facebook directly on Facebook. If you assert your data subject rights with us, we are obliged to forward them to Facebook.
        The data transfer to the USA is based on the standard contractual clauses of the EU Commission. Details can be found here:  https://www.facebook.com/legal/EU_data_transfer_addendum ,  https://de-de.facebook.com/help/566994660333381  and https://www.facebook.com/policy.php .`,
	},
	{
		textBigger: "Instagram Plugin",
		text: `Functions of the Instagram service are integrated on this website. These functions are offered by Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbor, Dublin 2, Ireland.
        If you are logged into your Instagram account, you can link the contents of this website to your Instagram profile by clicking the Instagram button. This enables Instagram to assign your visit to this website to your user account. We would like to point out that, as the provider of the pages, we have no knowledge of the content of the data transmitted or its use by Instagram.
        The storage and analysis of the data takes place on the basis of Art. 6 Para. 1 lit. f GDPR. The website operator has a legitimate interest in the widest possible visibility in social media. If a corresponding consent has been requested, the processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a GDPR; the consent can be withdrawn at any time.
        Insofar as personal data is collected on our website with the help of the tool described here and forwarded to Facebook or Instagram, we and Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbor, Dublin 2, Ireland are jointly responsible for this data processing (Art . 26 GDPR). The joint responsibility is limited exclusively to the collection of the data and its transfer to Facebook or Instagram. The processing by Facebook or Instagram after the forwarding is not part of the joint responsibility. The obligations we have jointly have been set out in an agreement on joint processing. The wording of the agreement can be found at:  https://www.facebook.com/legal/controller_addendum. According to this agreement, we are responsible for providing data protection information when using the Facebook or Instagram tool and for implementing the tool on our website in a manner that is secure under data protection law. Facebook is responsible for the data security of Facebook and Instagram products. You can assert rights of data subjects (e.g. requests for information) with regard to the data processed on Facebook or Instagram directly on Facebook. If you assert your data subject rights with us, we are obliged to forward them to Facebook.
        The data transfer to the USA is based on the standard contractual clauses of the EU Commission. Details can be found here:  https://www.facebook.com/legal/EU_data_transfer_addendum , https://help.instagram.com/519522125107875  and  https://de-de.facebook.com/help/566994660333381 .
        You can find more information on this in Instagram's privacy policy:  https://instagram.com/about/legal/privacy/ .`,
	},
	{
		title: "6. Analysis tools and advertising",
		textBigger: "WordPress statistics",
		text: `This website uses "WordPress Statistics" to statistically evaluate visitor access. The provider is Automattic Inc., 60 29th Street # 343, San Francisco, CA 94110-4929, USA.
        WordPress Statistics uses technologies that enable the user to be recognized for the purpose of analyzing user behavior (e.g. cookies or device fingerprinting). For analysis, WordPress Statistics records log files (referrer, IP address, browser, etc.), the origin of the website visitors (country, city) and what actions they have taken on the site (e.g. clicks, views, downloads). The information collected in this way about the use of this website is stored on servers in the USA. Your IP address will be anonymized after processing and before storage.
        This analysis tool is used on the basis of Article 6 (1) (f) GDPR. The website operator has a legitimate interest in the anonymized analysis of user behavior in order to optimize both its website and its advertising. If a corresponding consent has been requested (e.g. consent to the storage of cookies), processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a GDPR; the consent can be withdrawn at any time.`,
	},
	{
		title: "7. Plugins und Tools",
		textBigger: "Vimeo",
		text: `This website uses plugins from the video portal Vimeo. The provider is Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
        If you visit one of our pages with a Vimeo video, a connection to the Vimeo servers will be established. The Vimeo server is informed which of our pages you have visited. Vimeo also obtains your IP address. This also applies if you are not logged in to Vimeo or do not have a Vimeo account. The information recorded by Vimeo is transmitted to the Vimeo server in the USA.
        If you are logged into your Vimeo account, you enable Vimeo to assign your surfing behavior directly to your personal profile. You can prevent this by logging out of your Vimeo account.
        Vimeo uses cookies or comparable recognition technologies (e.g. device fingerprinting) to recognize website visitors.
        Vimeo is used in the interest of an appealing presentation of our online offers. This represents a legitimate interest within the meaning of Article 6 Paragraph 1 lit. the consent can be withdrawn at any time.
        The data transfer to the USA is based on the standard contractual clauses of the EU Commission and, according to Vimeo, on "legitimate business interests". You can find details here:  https://vimeo.com/privacy .
        Further information on handling user data can be found in Vimeo's data protection declaration at:  https://vimeo.com/privacy .`,
	},
	{
		textBigger: "Google Maps",
		text: `This site uses the Google Maps map service. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
        To use the functions of Google Maps, it is necessary to save your IP address. This information is usually transmitted to and stored by Google on servers in the United States. The provider of this site has no influence on this data transfer.
        The use of Google Maps is in the interest of an appealing presentation of our online offers and to make it easy to find the places we have indicated on the website. This represents a legitimate interest within the meaning of Article 6 Paragraph 1 lit. the consent can be withdrawn at any time.
        The data transfer to the USA is based on the standard contractual clauses of the EU Commission. You can find details here:  https://privacy.google.com/businesses/gdprcontrollerterms/  and  https://privacy.google.com/businesses/gdprcontrollerterms/sccs/ .
        You can find more information on handling user data in Google's data protection declaration:  https://policies.google.com/privacy?hl=de .`,
	},
	{
		textBigger: "Facebook-Pixel",
		text: `With the help of the Facebook pixel, Facebook is on the one hand able to determine the visitors to our online offer as a target group for the presentation of advertisements (so-called “Facebook ads”). Accordingly, we use the Facebook pixel to only send the Facebook ads to those users on Facebook and within the services of the partners cooperating with Facebook (so-called “Audience Network”  https://www.facebook.com/audiencenetwork/ ) who have also shown an interest in our online offer or who have certain characteristics (e.g. interest in certain topics or products that can be seen from the websites visited) that we transmit to Facebook (so-called "Custom Audiences"). With the help of the Facebook pixel, we would also like to ensure that our Facebook ads correspond to the potential interest of the users and are not annoying. With the help of the Facebook pixel, we can also understand the effectiveness of the Facebook advertisements for statistical and market research purposes by seeing whether users were redirected to our website after clicking on a Facebook advertisement (so-called "conversion measurement").`,
		list: [
			"Processed data types:  usage data (e.g. websites visited, interest in content, access times), meta / communication data (e.g. device information, IP addresses), location data (data that indicate the location of the end user's device).",
			"Affected persons:  users (e.g. website visitors, users of online services).",
			"Purposes of processing:  Tracking (e.g. interest- / behavior-related profiling, use of cookies), remarketing, visit action evaluation, interest-based and behavior-related marketing, profiling (creation of user profiles), conversion measurement (measurement of the effectiveness of marketing measures), target group formation (determination of relevant for marketing purposes Target groups or other output of content), cross-device tracking (cross-device processing of user data for marketing purposes).",
			"Security measures:  IP masking (pseudonymization of the IP address).",
			"Legal basis:  Consent (Art. 6 Para. 1 S. 1 lit. a GDPR), legitimate interests (Art. 6 Para. 1 S. 1 lit. f. GDPR).",
			"Opposition option (opt-out):  We refer to the data protection information of the respective provider and the options for objection given to the provider (so-called “opt-out”). Unless an explicit opt-out option has been specified, you have the option of switching off cookies in your browser settings. However, this can restrict the functions of our online offer. We therefore also recommend the following opt-out options, which are offered in summary for the respective areas: a) Europe:  https://www.youronlinechoices.eu . b) Canada:  https://www.youradchoices.ca/choices . c) USA:  https://www.aboutads.info/choices . d) Cross-regional: http://optout.aboutads.info .",
		],
	},
];
