/* eslint-disable react/jsx-max-depth */
import CustomAccordion from "@src/common/CustomAccordion";
import React from "react";
import Switch from "@common/switch/index";
import {useTranslation} from "react-i18next";
import {HexColorPicker, HexColorInput} from "react-colorful";
import InputComponent from "@src/common/input";
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import ButtonComponent from "@src/common/button";
import OrderSettings from "./orderSettings";
const Contracts = (props) => {
	const {
		currentTab,
		checkedPDF,
		switchChangeHandler,
		setLeads,
		leads,
		setLeadsLocation,
		leadsLocation,
		color,
		setColor,
		generateExternalId,
		setGenerateID,
		buttonFn,
		clientId,
	} = props;
	const {t} = useTranslation();
	return (
		<div className="activeTab-container-wrapper" style={{flex: 1}}>
			<p className="activeTab-container-wrapper-title">{currentTab}</p>
			<div className="accordions-wrapper contracts__wrapper">
				<div className="accordions-wrapper-first">
					<CustomAccordion title={t("Settings")} className="contracts__accordion">
						<div className="contracts__wrapper">
							<div className="contracts__wrapper-top">
								<div className="switch-wrapper">
									<div className="client_settings_export_pdf_div-switch">
										<Switch
											label="exportPDF"
											checked={checkedPDF}
											infoText={t("exportPDFInfo")}
											switchChangeHandler={switchChangeHandler}
										/>
									</div>

									<div className="client_settings_export_pdf_div-switch">
										<Switch
											label="Leads"
											infoText={t("LeadsInfo")}
											checked={leads}
											switchChangeHandler={() => setLeads(!leads)}
										/>
									</div>

									<div className="client_settings_export_pdf_div-switch">
										<Switch
											label="LeadsLocation"
											infoText={t("LeadsLocationInfo")}
											checked={leadsLocation}
											switchChangeHandler={() =>
												setLeadsLocation(!leadsLocation)
											}
										/>
									</div>
								</div>
								<div className="client_settings-color_picker">
									<p className="client_settings-color_picker-title">
										{t("ClientColor")}
									</p>
									<p>
										{t("YourCurrentColor")}
										<HexColorInput
											color={color || "#4472C4"}
											onChange={setColor}
										/>

										<div
											className="color_picker-preview"
											style={{backgroundColor: color || "#4472C4"}}
										></div>
									</p>
									<HexColorPicker
										color={color || "#4472C4"}
										onChange={setColor}
									/>
								</div>
								{/* } */}
							</div>
							<div className="external__id__generator">
								<div className="external__id__generator-example">
									<span className="external__id__generator-example-title">
										{t("ExternalID")}
									</span>
									<p>
										<ShowInfoIcon fill="#C1C1C1" />
										{t("Tousethesethreedifferentcharacters")}
									</p>
									<div>
										<ol>
											<li>{t("Usetheverticalbarcharacter")}</li>
											<li>{t("Usethehashcharacter")}</li>
											<li>{t("Usethedollarsigncharacter")}</li>
										</ol>
										<p className="instructions-example">
											{t("Herearetheavailableenums")}
										</p>
									</div>
									<p className="instructions-example">{t("example")}</p>
								</div>
								<div className="input__wrapper">
									<InputComponent
										placeholder={t("GenerateExternalID")}
										values={generateExternalId}
										handleChange={(e) => setGenerateID(e.target.value)}
									/>
								</div>
							</div>
							<div className="contracts__wrapper-actions">
								<ButtonComponent
									buttonText={t("Save")}
									type="button"
									onClick={() => {
										buttonFn({
											color,
											checkedPDF,
											generateExternalId,
											leads,
											leadsLocation,
											clientId,
										});
									}}
								/>
							</div>
						</div>
					</CustomAccordion>
				</div>
			</div>
			<OrderSettings />
		</div>
	);
};

export default Contracts;
