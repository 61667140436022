import React from "react";
import * as yup from "yup";
import HtmlTooltip from "@common/htmlTooltip";
import {SelectColumn} from "react-data-grid";
import moment from "moment";
import {BonusIcon, EuroSign, AllOrdersIcon} from "@src/assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import {formatNumber} from "@src/utils/convertNumber";
import {baseOrderStatusesArray} from "@src/utils/constants";
import i18n from "../../../i18n";

export const tableTabs = [
	{
		icon: <AllOrdersIcon />,
		name: i18n.t("allOrders"),
		type: "all",
	},
	{
		icon: <EuroSign stroke="#979797" width={18} height={18} />,
		name: i18n.t("paidOrders"),
		type: "PAID",
	},
	{
		icon: <BonusIcon stroke="#979797" />,
		name: "Bonus",
		type: "BONUS",
	},
];
export const infoChefColumns = [
	{
		name: i18n.t("month"),
		key: "month",
		formatter: ({row}) => <span>{i18n.t(row?.month)}</span>,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("accessCancellationReserve"),
		key: "accessCancellationReserve",
		formatter: ({row}) => <span>{`${formatNumber(row?.accessCancellationReserve)}€`}</span>,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("standCancellationReserve"),
		key: "standCancellationReserve",
		formatter: ({row}) => <span>{`${formatNumber(row?.standCancellationReserve)}€`}</span>,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("payout"),
		formatter: ({row}) => <span>{`${formatNumber(row?.payout)}€`}</span>,
		key: "payout",
		headerCellClass: "headerStyles",
	},
];

export const updatedStatusesColumns = [
	{
		name: i18n.t("Finished"),
		key: "numOfOrdersProcessing",
		formatter: ({row}) => {
			const total = row?.numOfOrders
				? `${
						row?.numOfOrdersProcessing <= row?.numOfOrders
							? row?.numOfOrdersProcessing
							: row?.numOfOrders
				  } ${i18n.t("Of")} ${row?.numOfOrders}`
				: "";
			const reverted =
				row?.numOfOrders &&
				row?.numOfOrdersProcessing > row?.numOfOrders &&
				row?.numOfOrdersProcessing - row?.numOfOrders;
			return (
				<span style={{fontWeight: "500"}}>
					{total} {reverted && `/ (${reverted}) ${i18n.t("Reverted")}`}
				</span>
			);
		},
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("ProgressStatus"),
		key: "progressStatus",
		formatter: ({row}) => {
			const calcPercentage =
				row?.numOfOrdersProcessing <= row?.numOfOrders
					? (row?.numOfOrdersProcessing / row?.numOfOrders) * 100
					: (row?.numOfOrders / row?.numOfOrders) * 100;
			const percentage = row?.numOfOrders && calcPercentage;
			return (
				<HtmlTooltip
					placement="top"
					title={
						<span style={{fontSize: "14px"}}>
							{percentage && `${percentage?.toFixed(percentage !== 100 ? 2 : 0)}%`}
						</span>
					}
				>
					<span>
						<StatusBox status={row.progressStatus} percentage={percentage} />
					</span>
				</HtmlTooltip>
			);
		},
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("uploadedBy"),
		key: "uploadedBy",
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("startTime"),
		key: "createdAt",
		formatter: ({row}) => <span>{moment(row?.createdAt).format("DD.MM.YYYY, HH:mm")}</span>,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("endTime"),
		key: "updatedAt",
		formatter: ({row}) => (
			<span>
				{row?.updatedAt !== 0 ? moment(row?.updatedAt).format("DD.MM.YYYY, HH:mm") : "-"}
			</span>
		),
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("UpdatedStatus"),
		key: "status",
		formatter: ({row}) => {
			const {color, statusName, orderStatus} = row?.systemStatusView || {};
			return <StatusBox status={orderStatus} name={statusName} color={color} />;
		},
		headerCellClass: "headerStyles",
	},
];
export const columns = [
	SelectColumn,
	{
		name: i18n.t("VPNumber"),
		key: "vpNumber",
		width: 0,
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("VPName"),
		key: "salesPartnerName",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("ID"),
		key: "orderId",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("Client"),
		key: "clientName",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("contractId"),
		key: "clientOrderId",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("TariffName"),
		key: "tariffName",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("ProjectCategory"),
		key: "projectCategoryName",
		sortable: true,
		type: "orders",
		resizable: true,
		headerCellClass: "headerStyles",
	},

	{
		name: i18n.t("ProjectLocation"),
		key: "locationName",
		sortable: true,
		type: "orders",
		resizable: true,
		headerCellClass: "headerStyles",
	},

	{
		name: i18n.t("ContractDate"),
		key: "createdAt1",
		formatter: ({row}) => <span>{moment(row?.createdAt).format("DD.MM.YYYY")}</span>,
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("Status"),
		key: "orderStatus",
		width: 230,
		formatter: ({row}) => {
			const {color, statusName, orderStatus} = row?.systemStatusView || {};
			const isInProgress = orderStatus === "IN_PROGRESS";
			const formattedTime = moment(row?.statusChangeTime).format("DD.MM.YYYY");
			const tooltipText = isInProgress
				? i18n.t("checkStatusHistory")
				: `${i18n.t("ChangedAt")}: ${formattedTime}`;
			const sharedProps = {
				status: orderStatus,
				flag: !!row?.voSettledUUID,
				name: statusName,
				color,
			};
			return (
				<>
					{row?.statusChangeTime !== null ? (
						<HtmlTooltip
							placement="bottom"
							title={
								<span style={{fontSize: "14px"}}>
									{isInProgress ? (
										<span>{tooltipText}</span>
									) : (
										<div style={{display: "flex", flexDirection: "column"}}>
											<div>
												<span style={{fontSize: "14px", fontWeight: 600}}>
													{i18n.t("ChangedAt")}:{" "}
												</span>
												{formattedTime}
											</div>
											{row?.voSettledUUID && (
												<span className="flagData">
													{i18n.t("HasSettlementCreated")}
												</span>
											)}
										</div>
									)}
								</span>
							}
						>
							<span>
								<StatusBox {...sharedProps} />
							</span>
						</HtmlTooltip>
					) : (
						<StatusBox {...sharedProps} />
					)}
				</>
			);
		},
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("OrderProductNumber"),
		key: "orderProductNumber",
		sortable: true,
		type: "orders",
		resizable: true,
		headerCellClass: "headerStyles",
	},
	// {
	// 	name: i18n.t("customerType"),
	// 	key: "customerType",
	// 	sortable: true,
	// },
];

// ADD ARRAY FOR NEW CLIENTS NAME SHOULD BE SAME WITH SELECTEDCLIENT.NAME VALUE

export const columnsObjEXPORT = {
	TNG: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("ZipCode"),
			field: "viewOrderDetails.zip",
		},
		//   { title: i18n.t('ProductName'), field: 'viewOrderDetails.productName' },

		// order details

		{title: i18n.t("customerType"), field: "customerType"},
		{
			title: i18n.t("Salutation"),
			field: "viewOrderDetails.salutation",
		},
		{
			title: i18n.t("UserTitle"),
			field: "userTittle",
		},
		{
			title: i18n.t("subLocation"),
			field: "viewOrderDetails.subLocation",
		},
		{
			title: i18n.t("BirthDay"),
			field: "viewOrderDetails.dayOfBirth1",
		},
		{title: i18n.t("Email"), field: "email", export: true},
		{
			title: i18n.t("Telephone"),
			field: "viewOrderDetails.phone",
		},
		{
			title: i18n.t("MobilePhone"),
			field: "viewOrderDetails.mobileNumber",
		},
		{
			title: i18n.t("OneFamilyHouse"),
			field: "oneFamilyHouse1",
		},
		{
			title: i18n.t("MoreFamilyHouse"),
			field: "apartmentBuilding1",
		},
		{
			title: i18n.t("HomeOwner"),
			field: "underground1",
		},
		{
			title: i18n.t("HomeCoOwner"),
			field: "upstairs1",
		},
		{
			title: i18n.t("Renter"),
			field: "groundFloor1",
		},
		{
			title: i18n.t("NamePropertyOwner"),
			field: "floor",
		},

		// bank info
		{
			title: i18n.t("BankSalutation"),
			field: "viewBankAccount.salutation",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewBankAccount.firstName",
		},
		{
			title: i18n.t("LastNameCompany"),
			field: "viewBankAccount.lastName",
		},
		{
			title: i18n.t("BankStreet"),
			field: "viewBankAccount.street",
		},
		{
			title: i18n.t("BankHouseNumber"),
			field: "viewBankAccount.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewBankAccount.addition",
		},
		{
			title: i18n.t("BankLocation"),
			field: "viewBankAccount.location",
		},
		{
			title: i18n.t("BankZipCode"),
			field: "viewBankAccount.zip",
		},

		{
			title: i18n.t("BankName"),
			field: "viewBankAccount.bankName",
		},

		// {title: i18n.t("BankCode"), field: "viewBankAccount.bankCode"},
		{
			title: i18n.t("BankIban"),
			field: "viewBankAccount.bankIban",
		},
		{
			title: i18n.t("BankBic"),
			field: "viewBankAccount.bankBic",
		},

		{
			title: i18n.t("BankSignatureDate"),
			field: "viewBankAccount.signatureDate1",
		},

		// different ivoiceing adress
		{
			title: i18n.t("AddressSalutation"),
			field: "viewAddressForInvoice.salutation",
		},
		{
			title: i18n.t("AdrressFullName"),
			field: "viewAddressForInvoice.fullName",
		},
		{
			title: i18n.t("ContactPersonName"),
			field: "viewAddressForInvoice.contactPersonFirma",
		},
		{
			title: i18n.t("AddressEmail"),
			field: "viewAddressForInvoice.email",
		},
		{
			title: i18n.t("AddressBirthdate"),
			field: "viewAddressForInvoice.dayOfBirth1",
		},
		{
			title: i18n.t("AddressStreet"),
			field: "viewAddressForInvoice.street",
		},
		{
			title: i18n.t("AddressHousrNr"),
			field: "viewAddressForInvoice.houseNumber",
		},

		{
			title: i18n.t("AddressAddition"),
			field: "viewAddressForInvoice.addition",
		},
		{
			title: i18n.t("AddressCity"),
			field: "viewAddressForInvoice.abwRACity",
		},
		{
			title: i18n.t("AddressZipCode"),
			field: "viewAddressForInvoice.zip",
		},
		{
			title: i18n.t("Telephone"),
			field: "viewAddressForInvoice.phone",
		},
		{
			title: i18n.t("MobilePhone"),
			field: "viewAddressForInvoice.mobileNumber",
		},

		// former provider

		{
			title: i18n.t("TransferNumber"),
			field: "checkFormerProvider1",
		},

		{
			title: i18n.t("FormerProviderName"),
			field: "viewFormerProvider.formerProviderName",
		},

		{
			title: i18n.t("ProviderSalutation"),
			field: "viewFormerProvider.salutation",
		},
		{
			title: i18n.t("ProviderFirstName"),
			field: "viewFormerProvider.firstName",
		},
		{
			title: i18n.t("ProviderLastName"),
			field: "viewFormerProvider.lastName",
		},
		{
			title: i18n.t("ProviderStreet"),
			field: "viewFormerProvider.street",
		},
		{
			title: i18n.t("ProviderHouseNumber"),
			field: "viewFormerProvider.houseNumber",
		},
		{
			title: i18n.t("ProviderAddition"),
			field: "viewFormerProvider.addition",
		},
		{
			title: i18n.t("ProviderZipCode"),
			field: "viewFormerProvider.zip",
		},

		{
			title: i18n.t("ProviderLocation"),
			field: "viewFormerProvider.location",
		},
		{
			title: i18n.t("Prefix"),
			field: "viewFormerProvider.locationPrefix",
		},
		{
			title: i18n.t("Extension"),
			field: "viewFormerProvider.providerExtension",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 1`,
			field: "viewFormerProvider.activePhoneNumber1",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 2`,
			field: "viewFormerProvider.activePhoneNumber2",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 3`,
			field: "viewFormerProvider.activePhoneNumber3",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 4`,
			field: "viewFormerProvider.activePhoneNumber4",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 5`,
			field: "viewFormerProvider.activePhoneNumber5",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 6`,
			field: "viewFormerProvider.activePhoneNumber6",
		},

		{
			title: i18n.t("AdditionalSalutation"),
			field: "additionalContractor.salutation",
		},

		{
			title: i18n.t("AditionalFirstName"),
			field: "additionalContractor.firstName",
		},
		{
			title: i18n.t("AditionalLastName"),
			field: "additionalContractor.lastName",
		},
		{
			title: i18n.t("AdditionalEmail"),
			field: "additionalContractor.userEmail",
		},
		{
			title: i18n.t("AdditionalStreet"),
			field: "additionalContractor.street",
		},
		{
			title: i18n.t("AdditionalHousrNr"),
			field: "additionalContractor.houseNumber",
		},
		{
			title: i18n.t("AdditionalAddition"),
			field: "additionalContractor.addition",
		},
		{
			title: i18n.t("AdditionalPlace"),
			field: "additionalContractor.place",
		},
		{
			title: i18n.t("AdditionalZipCode"),
			field: "additionalContractor.zip",
		},
		{
			title: i18n.t("AdditionalTelephone"),
			field: "additionalContractor.telephone",
		},
		{
			title: i18n.t("AdditionalMobile"),
			field: "additionalContractor.mobileNumber",
		},
		{
			title: i18n.t("AdditionalBirthdate"),
			field: "additionalContractor.dayOfBirth",
		},
		{
			title: i18n.t("SendNewsLetter"),
			field: "sendNewsLetter1",
		},
		{
			title: i18n.t("EmailNewsLetter"),
			field: "emailNewsLetter1",
		},
	],
	GFD: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
		},
		{
			title: i18n.t("ChangedAt"),
			field: "statusChangeTime",
		},
		{
			title: i18n.t("changedBy"),
			field: "statusChangeBy",
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",
		},

		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
	],
	DGN: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},
		{
			title: i18n.t("ID"),
			field: "orderId",
		},
		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},
		{title: i18n.t("contractId"), field: "clientOrderId"},
		{title: i18n.t("TariffName"), field: "tariffName"},
		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},
		{title: i18n.t("ProjectLocation"), field: "locationName"},
		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
		},
		{
			title: i18n.t("ChangedAt"),
			field: "statusChangeTime",
		},
		{
			title: i18n.t("changedBy"),
			field: "statusChangeBy",
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("zip"),
			field: "viewOrderDetails.zip",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",
		},
	],
	TER: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},

		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("zip"),
			field: "viewOrderDetails.zip",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",
		},
		{
			title: i18n.t("subLocation"),
			field: "viewOrderDetails.subLocation",
		},
		{
			title: i18n.t("viaET"),
			field: "viaET",
		},
		{
			title: i18n.t("viaMFH"),
			field: "viaMFH",
		},
		{
			title: i18n.t("viaPapier"),
			field: "viaPapier",
		},
	],
	HNT: [
		{
			title: i18n.t("ID"),
			field: "orderId",
		},
		{
			title: "FirstName",
			field: "viewOrderDetails.firstName",
		},
		{
			title: "LastName",
			field: "viewOrderDetails.lastName",
		},
		{
			title: "Prefix",
			field: "viewOrderDetails.salutation",
		},
		{
			title: "Email",
			field: "email",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},
		{title: "ExternalID", field: "vpNumber"},
		{title: "PhoneDay", field: "viewOrderDetails.phone"},
		{title: "PhoneEvening", field: "viewOrderDetails.phone"},
		{title: "PhoneMobil", field: "viewOrderDetails.mobileNumber"},
		{},
		{title: "DeliveryStreetName", field: "viewOrderDetails.street"},
		{title: "DeliveryStreetNumber", field: "viewOrderDetails.houseNumber"},
		{title: "DeliveryStreetEntrance", field: "viewOrderDetails.addition"},
		{},
		{title: "AccessAddressId", field: "viewOrderDetails.addressId"},
		{title: "AdressDetails", field: "viewOrderDetails.floor"},
		{},
		{},
		{title: "DeliveryZip", field: "viewOrderDetails.zip"},
		{title: "DeliveryCity", field: "viewOrderDetails.place"},
		{title: "InvoiceStreetName", field: "additionalContractor.street"},
		{title: "InvoiceStreetNumber", field: "additionalContractor.houseNumber"},
		{title: "InvoiceEntrance", field: "additionalContractor.addition"},
		{title: "InvoiceZIP", field: "additionalContractor.zip"},
		{title: "InvoiceCity", field: "additionalContractor.place"},
		{
			title: "IBAN",
			field: "viewBankAccount.bankIban",
		},
		{
			title: "Birth date",
			field: "viewOrderDetails.dayOfBirth1",
		},
		{title: "Contract Id", field: "clientOrderId"},
		{title: "Order Date", field: "createdAt1"},
	],
	VFK: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
		},
		{
			title: i18n.t("ChangedAt"),
			field: "statusChangeTime",
		},
		{
			title: i18n.t("changedBy"),
			field: "statusChangeBy",
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("zip"),
			field: "viewOrderDetails.zip",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",
		},
		{
			title: i18n.t("subLocation"),
			field: "viewOrderDetails.subLocation",
		},
	],
	DEFAULT: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
			hideForClient: ["Stadtwerke Wesel GmbH Digital"],
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
		},
		{
			title: i18n.t("ChangedAt"),
			field: "statusChangeTime",
			// hideForClient: ["Stadtwerke Wesel GmbH Digital"],
		},
		{
			title: i18n.t("changedBy"),
			field: "statusChangeBy",
		},
	],
};

export const firstAndLastNameColumns = [
	{
		title: i18n.t("FirstName"),
		field: "viewOrderDetails.firstName",
	},
	{
		title: i18n.t("LastName"),
		field: "viewOrderDetails.lastName",
	},
];

export const settleOrdersValidationSchema = yup.object().shape({
	year: yup.string().when(["isV2CurrentVersion"], {
		is: (val) => val === true,
		then: yup.string().required(i18n.t("yearReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	month: yup.string().when(["isV2CurrentVersion"], {
		is: (val) => val === true,
		then: yup.string().required(i18n.t("monthReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	baugoId: yup.number().when(["tableTab"], {
		is: (value) => value === "BONUS",
		then: yup.number().required(i18n.t("bonusIsReq")).nullable(),
		otherwise: yup.number().optional().nullable(),
	}),
	description: yup
		.string()
		.test("descriptionLength", i18n.t("descriptionMaxLen"), (val) =>
			val?.length > 0 ? val.length <= 70 : true,
		)
		.optional()
		.nullable(),
	settledUuid: yup.mixed().optional().nullable(),
});

export const userRolesDropdown = [
	"AGENCY-SP",
	"SP",
	"EMPLOYEE",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_TL",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_AB",
];

const NON_BASE_STATUSES = ["SETTLED", "CONTRACT_REACTIVATED", "BONUS_SETTLED"];

export const statusInputs = (id, status) => [
	{
		title: i18n.t("FieldNameEn"),
		field: "name",
		inputType: "text",
		required: true,
		disabled: status === "CONTRACT_REACTIVATED",
	},
	{
		title: i18n.t("FieldNameDe"),
		field: "nameDe",
		inputType: "text",
		required: true,
		disabled: status === "CONTRACT_REACTIVATED",
	},
	{
		inputType: "dropdown",
		field: "status",
		options: baseOrderStatusesArray?.filter(({value}) => !NON_BASE_STATUSES.includes(value)),
		title: i18n.t("ChooseStatus"),
		required: true,
		disabled: !!id,
	},
	{
		title: i18n.t("PickAColor"),
		inputType: "colorPicker",
		field: "color",
		required: true,
	},
];

export const settleModalInitialValues = {
	open: false,
	initialValues: {
		year: null,
		month: null,
		description: "",
		billingMonthId: null,
		baugoId: null,
	},
	initialValuesForSalesOrg: {
		year: null,
		month: null,
		description: "",
		billingMonthId: null,
	},
};
export const reactivateModalInitialValues = {
	open: false,
	initialValues: {
		year: null,
		month: null,
		description: "",
		billingMonthId: null,
	},
};

export const baseTabsArray = ["orders", "infoChef", "statusHistory"];

export const statusChangeErrorRender = (message) => {
	const splittedMessage = message?.split("||");
	const messageToCheck = splittedMessage && splittedMessage[1] ? splittedMessage[1] : message;
	switch (messageToCheck) {
		case "status.can.not.change":
			return i18n.t("OrderStatusCantChange");
		case "finished.order.can.only.be.changed.to.cancelled":
			return i18n.t("FinishedOrderError");
		case "status.can.not.change.to.finished":
			return i18n.t("DeliveredOrderError");
		case "status.can.not.be.changed.from.cancelled":
			return i18n.t("CancelledOrderError");
		case "same.status":
			return i18n.t("SameOrdersError");
		case "tariff.commission.not.found":
			return i18n.t("TariffComissionNotFound");
		case "tariff.commission.table.not.found":
			return i18n.t("TariffComissionTableNotFound");
		case "tenant.not.found":
			return i18n.t("TenantNotFound");
		case "user.not.found":
			return i18n.t("UserNotFound");
		case "role.not.found":
			return i18n.t("RoleNotFound");
		case "tariff.not.found":
			return i18n.t("TariffNotFound");
		case "commission.not.found":
			return i18n.t("ComisionNotFound");
		case "tariff.is.deactivated":
			return "Tariff is deactivated";
		case "project.category.not.found":
			return "Project category not found";
		case "project.category.is.deactivated":
			return "Project category is deactivated";
		case "location.not.found":
			return "Location not found";
		case "location.is.deactivated":
			return "Location is deactivated";
		case "tariff.commision.table.not.found":
			return "Tariff commision table not found";
		case " product.is.deleted":
			return `${splittedMessage[0]} is deleted`;
		case " product.is.deactivated":
			return `${splittedMessage[0]} is deactivated`;
		case " product.provision.not.found":
			return `${splittedMessage[0]}"s provision not found`;
		case "order.have.to.revert":
			return i18n.t("orderHaveToRevert");
		default:
			return i18n.t("WrongStatus");
	}
};
