/* eslint-disable no-lonely-if */
import {CloseModalIcon, TickIcon} from "@src/assets/SvgComponents";
import React, {useCallback, useEffect} from "react";
import {useDrag} from "react-dnd";
import {useUrlParams} from "@src/utils/useUrlParams";
import {debounce} from "lodash";
import CustomPopover from "@src/common/Popover";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import {getLabel} from "../generic-pdf/createOrder/multiplecontainers/helper";
import {checkLeftDimensions, isInsideParent, multipleItems, singleItems} from "./staticData";

const DraggableFields = ({
	item,
	moveBox,
	setSelectedItems,
	selectedItems,
	orderFields,
	handleShowDeleteModal,
	openPopover,
	setOpenPopover,
	imgRef,
}) => {
	const {id, x: left, y: top} = item;
	const {setModal} = useUrlParams();
	const focusedItem = selectedItems.find((sItem) => sItem.id === id);
	// eslint-disable-next-line no-empty-pattern
	const [{}, drag] = useDrag({
		type: "box",
		item: {...item},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	const handleKeyDown = (e) => {
		if (e.key === "Escape") {
			setOpenPopover(null);
		}
		let moveStep = 5,
			newItem = {...item};
		const isOptionKeyPressed = e.altKey;
		moveStep = isOptionKeyPressed ? 1 : 5;
		// Handle arrow key movements
		e.preventDefault();
		switch (e.key) {
			case "ArrowLeft":
				newItem = {...newItem, x: left - moveStep, y: top};
				break;
			case "ArrowRight":
				newItem = {...newItem, x: left + moveStep, y: top};
				break;
			case "ArrowUp":
				newItem = {...newItem, x: left, y: top - moveStep};
				break;
			case "ArrowDown":
				newItem = {...newItem, x: left, y: top + moveStep};
				break;
			default:
				break;
		}
		const isWithinBounds = isInsideParent(imgRef, newItem);

		if (isWithinBounds) {
			moveBox(newItem, false);
			debouncedSendRequest(newItem);
		} else {
			ToastErrorComponent(i18n.t("fieldOutsidePdfError"));
		}
	};

	const debouncedSendRequest = useCallback(
		debounce((latestItem) => {
			moveBox(latestItem); // Send request with the latest position
		}, 1000),
		[],
	);
	useEffect(() => () => debouncedSendRequest.cancel(), [debouncedSendRequest]);

	const handleMouseDown = (e) => {
		if (e.button === 0) {
			if (e.nativeEvent.shiftKey) {
				if (!focusedItem) {
					setSelectedItems((prev) => [item, ...prev]);
				} else {
					setSelectedItems((prev) =>
						prev.filter((sItem) => sItem.id !== focusedItem?.id),
					);
				}
			} else {
				setSelectedItems([item]);
			}
		}
	};
	const handleCloneField = () => {
		const updatedItem = checkLeftDimensions(imgRef, item, left, top);
		moveBox(updatedItem);
	};

	const items = selectedItems?.length > 1 ? multipleItems : singleItems;

	const popoverItems = [
		{
			key: "header",
			label: (
				<div className="header_wrapper">
					<span style={{visibility: selectedItems?.length > 1 ? "hidden" : "visible"}}>
						{getLabel(item)}
					</span>
					<span onClick={() => setOpenPopover(null)}>
						<CloseModalIcon fill="#252525" />
					</span>
				</div>
			),
			className: "popover__actions-wrapper-header",
		},
		...items,
	];

	const rightClickHandler = (e) => {
		e.preventDefault();
		const selectedIds = selectedItems?.map((it) => it.id);
		const isFocused = selectedIds?.includes(id);
		if (isFocused) {
			setOpenPopover(id);
		}
	};

	const className = `dragablePdf__right__droppable-${
		item.inputType === "CHECKBOX" || item?.showAs === "CHECKBOX" || item.inputType === null
			? "checkbox"
			: "textbox"
	}`;
	const handleAction = (key) => {
		if (key === "delete") {
			handleShowDeleteModal(item);
		} else if (key === "orderField") {
			const foundedItem = orderFields?.find((field) => field.fieldId === item?.fieldId);
			if (foundedItem) {
				const additionalParams = foundedItem?.dropDownParentId
					? [
							{
								dropDownParentId: foundedItem?.dropDownParentId,
							},
					  ]
					: null;
				setModal("orderField", foundedItem?.id, additionalParams);
				setOpenPopover(null);
			}
		} else if (key === "clone") {
			handleCloneField();
		} else {
			setModal(key, item.id);
		}

		setOpenPopover(null);
	};
	return (
		<>
			<CustomPopover
				menu={{
					items: popoverItems,
					onClick: ({key}) => {
						if (key !== "header") {
							handleAction(key);
						}
					},
				}}
				open={openPopover === id}
				customClass="popover__actions-wrapper"
			>
				<div
					// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
					tabIndex={0}
					ref={drag}
					style={{
						top,
						left,
						borderColor: focusedItem?.id === id ? "red" : "#ccc",
						width: item?.width,
					}}
					onMouseDown={handleMouseDown}
					className={className}
					onKeyDown={handleKeyDown}
					onContextMenu={(e) => rightClickHandler(e)}
				>
					{item.inputType === "CHECKBOX" ||
					item.inputType === null ||
					item?.showAs === "CHECKBOX" ? (
						<TickIcon />
					) : (
						<span
							style={{
								textAlign: item?.alignment ? item?.alignment.toLowerCase() : "left",
								width: "100%",
							}}
						>
							{getLabel(item)}
						</span>
					)}
				</div>
			</CustomPopover>
		</>
	);
};

export default DraggableFields;
