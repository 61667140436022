import React, {useEffect} from "react";
import {Formik} from "formik";
import InputComponent from "@common/input";
import DropDownInput from "@common/dropDown";
import CloseIcon from "@common/closeIcon/CloseIcon";
import ButtonComponent from "@common/button";
import LimitTags from "@common/autoCompleteMultiselect";
import CheckBoxComponent from "@common/checkbox";

const FormikForm = (props) => {
	const {
		elements,
		loading,
		initialValues,
		clientId,
		options,
		setShowModal,
		handleSubmit,
		addText,
		editText,
		setClearedState,
		filteredTariffs,
		setFilteredTariffs,
	} = props;
	const {validationSchema} = elements;

	const hasID = initialValues?.[elements.hasID];

	useEffect(
		() => () => {
			if (setClearedState) {
				setClearedState(false);
			}
		},
		// eslint-disable-next-line
    [],
	);
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) =>
				handleSubmit({values: {...values, clientId}, formActions: actions})
			}
			validationSchema={
				hasID && validationSchema?.edit ? validationSchema?.edit : validationSchema?.add
				// validationSchema.add
			}
		>
			{({values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
				<form
					autoComplete="off"
					noValidate
					onSubmit={handleSubmit}
					className="edit__and__create__form"
				>
					<div className="close__modal__icon__and__name">
						<h1>{`${hasID ? editText : addText}`}</h1>
						<CloseIcon className="close__modal__icon" click={() => setShowModal()} />
					</div>
					{elements?.lists?.[hasID && validationSchema?.edit ? "edit" : "add"]?.map(
						(element, index) => (
							<React.Fragment key={index}>
								{element.type === "input" && (
									<InputComponent
										name={element.field}
										placeholder={element.title}
										type={element.inputType}
										errorClass="errorClass"
										errors={errors?.[element?.field]}
										values={values?.[element?.field]}
										handleBlur={handleBlur}
										handleChange={handleChange}
										touched={touched[element?.field]}
										bottomBreak={element?.bottomBreak}
										min={element?.min}
										disabled={element?.disabled}
									/>
								)}
								{element.type === "checkbox" && !element?.hidden && (
									<CheckBoxComponent
										name={element.field}
										handleChange={handleChange}
										checked={values?.[element?.field]}
										label={element.label}
									/>
								)}
								{element.type === "dropdown" && (
									<div className="form_dropdown">
										<DropDownInput
											title="name"
											className={`${
												element?.currency && "dropown_currency"
											} ${element?.newClass && element.newClass} `}
											errors={errors[element?.field]}
											name={element.name}
											id="currencyId"
											onChange={(event, value) => {
												setFieldValue(element.field, value);
											}}
											options={options[element?.field]}
											touched={touched?.[element?.field]}
											value={values?.[element?.field]}
											placeholder={element.title}
											withClasses={element?.classes}
											disablePortal={element?.disablePortal}
										/>
									</div>
								)}
								{element.type === "multiselect" && (
									<div className="form_dropdown">
										<LimitTags
											handleChange={(event, value, reason, detail) => {
												setFieldValue(element.field, value);
												if (value.length === 0) setClearedState(true);
												if (
													reason === "removeOption" &&
													!value.includes(detail.option) &&
													!filteredTariffs.includes(detail.option)
												) {
													setFilteredTariffs([
														...filteredTariffs,
														detail.option,
													]);
												}
											}}
											options={options[element?.field]}
											errors={errors[element?.field]}
											touched={touched?.[element?.field]}
											defaultValue={values?.[element?.field]}
											placeholder={element.title}
											withClasses={element?.classes}
											disablePortal={element?.disablePortal}
										/>
									</div>
								)}
							</React.Fragment>
						),
					)}
					<ButtonComponent
						disabled={loading}
						loading={loading}
						buttonText={elements.btnText}
						type="submit"
						newClass={
							elements?.modalTitle === "Client" ? "form__button__container" : ""
						}
					/>
				</form>
			)}
		</Formik>
	);
};

export default FormikForm;
