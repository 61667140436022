import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {actions as createImports} from "@sagas/imports/create";
import {actions as clientActions} from "@sagas/client";
import {actions as teamActions} from "@sagas/teams";
import {actions as usersActions} from "@sagas/users/users";
import {CloseUploadModalIcon} from "@assets/SvgComponents";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import {addTypeMap, getSelectOptionsFromDataTeams} from "@utils/select";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Button from "@common/components/button";
import {useUrlParams} from "@src/utils/useUrlParams";
import {checkRoles} from "@src/utils/checkRoles";
import SecondStep from "./secondStep";
import FirstStep from "./firstStep";

const UploadImport = ({
	parseCsv,
	counter,
	setCounter,
	setShowUploadModal,
	teams,
	agencies,
	users,
	importType,
	loggedUser,
}) => {
	const {getUrlParams} = useUrlParams();
	const {clientId} = getUrlParams("clientId");
	const [userOptions, setUserOptions] = useState([]);
	const [selectedFile, setSelectedFile] = useState("");
	const {t} = useTranslation();
	const validationSchema = yup.object().shape({
		client: yup.string().label(t("client")).required("clientReq"),
		users: yup.array().required().min(1, t("PleaseAddUsers")),
		uploadedCsv: yup.string().label(t("uploadedCsv")).required(t("FirstNameIsReq")),
	});
	useEffect(() => {
		const teamsWithType = addTypeMap(teams, "Team");
		const agenciesWithType = addTypeMap(agencies, "Agency");
		const usersWithType = addTypeMap(users, "User");
		const sortUsers = getSelectOptionsFromDataTeams(
			[...teamsWithType, ...agenciesWithType, ...usersWithType],
			"name",
			"id",
		);
		setUserOptions(sortUsers);
	}, [teams, agencies, users]);
	const formik = useFormik({
		validationSchema,
		initialValues: {client: "", projectId: "", uploadedCsv: "", users: []},
		enableReinitialize: true,
		onSubmit: (values) => {
			parseCsv({
				...values,
				users: userOptions?.filter((item) => item.selected),
				fileName: selectedFile,
				fromOrder: importType === "fromOrder",
				clientId,
				params:
					importType !== "fromOrder" && checkRoles("parseCsvAsLeader")
						? ["TEAM_LEADER"]
						: null,
			});
		},
	});

	const {values, handleSubmit, setFieldValue} = formik;

	const checkButton = (values) => {
		if (values?.uploadedCsv !== "" && values?.uploadedCsv?.type === "text/csv") {
			setCounter(2);
		} else {
			ToastErrorComponent(t("FileUploadError"));
			setFieldValue("uploadedCsv", "");
			setSelectedFile("");
		}
	};
	const modalTitle = importType === "fromOrder" ? t("ImportOrder") : t("ImportLead");
	return (
		<div className="upload__container">
			<div className="close__upload__modal">
				<div className="close__upload__modal__title">{modalTitle}</div>
				<div style={{cursor: "pointer"}} onClick={() => setShowUploadModal(false)}>
					<CloseUploadModalIcon width="12" height="12" />
				</div>
			</div>
			{counter === 1 ? (
				<FirstStep
					formik={formik}
					setSelectedFile={setSelectedFile}
					selectedFile={selectedFile}
					title={modalTitle}
				/>
			) : (
				<SecondStep
					userOptions={
						importType === "fromOrder"
							? userOptions?.filter((item) => item?.type === "User")
							: userOptions
					}
					setUserOptions={setUserOptions}
					formik={formik}
					fromOrder={importType === "fromOrder"}
				/>
			)}
			<div className="button-wrapper">
				<div style={{width: "100%"}}>
					{values?.uploadedCsv !== "" && (
						<Button
							width="100%"
							text={counter === 1 ? t("next") : t("Import")}
							handleChange={counter === 1 ? () => checkButton(values) : handleSubmit}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	optionsClients: state.app.client.index.optionsClients,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	users: state.app.users.index.usersOptions,
	totalSize: state.app.users.index.totalSize,
	locations: state.app.locations.index.locations,
	loggedUser: state.app.users.index.loggedUser,
});

const mapDispatchToProps = {
	parseCsv: createImports.parseCsv,
	getClients: clientActions.fetchClientsByDropdown,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getUsers: usersActions.getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadImport));
