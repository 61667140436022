import React from "react";
import {withRouter} from "react-router";
import {actions as createMessageActions} from "@sagas/messages/create";
import {connect} from "react-redux";
import MediaBox from "../MediaComponent/MediaBox";

const UploadFilesContainer = ({files, hasReply, removeFile}) => (
	<div className={`upload__container reply__container${hasReply ? " hasReply" : ""}`}>
		<div className="upload__container-main">
			{files?.length > 0 && (
				<>
					{files?.map((file, index) => (
						<MediaBox
							key={index}
							item={{
								...file,
								fileName: file.filename,
								fileType: file.type,
								fileSize: file.size,
							}}
							isUpload
							removeFile={removeFile}
						/>
					))}
				</>
			)}
		</div>
	</div>
);

const mapDispatchToProps = {
	removeFile: createMessageActions.removeFile,
};
export default connect(null, mapDispatchToProps)(withRouter(UploadFilesContainer));
