import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as locationActions} from "@sagas/locations";
import {actions as deletedLocationActions} from "@sagas/locations/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Location delete");

const PREFIX = "@app/location/delete";
export const DELETE_LOCATION = `${PREFIX}DELETE_LOCATION`;
export const DEACTIVATE_LOCATION = `${PREFIX}DEACTIVATE_LOCATION`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_DELETE_MODAL = `${PREFIX}SHOW_DELETE_MODAL`;

const _state = {
	loading: false,
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_DELETE_MODAL:
				draft.showModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteLocation: (payload) => createAction(DELETE_LOCATION, {payload}),
	deactivateLocation: (payload) => createAction(DEACTIVATE_LOCATION, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowDeleteModal: (payload) => createAction(SHOW_DELETE_MODAL, {payload}),
};

export const sagas = {
	*deactivateLocation({payload}) {
		yield put(actions.setLoading(true));
		try {
			const list = yield select((state) => state.app.locations.index.locations);
			const size = yield select((state) => state.app.locations.index.size);
			const item = list.find((e) => e.id === payload.id);
			yield axios.put(`/location/deactivate/${payload.id}`);
			yield put(locationActions.deactivateLocationOnState(payload.id));
			yield put(deletedLocationActions.add(item));
			ToastSuccesComponent(i18n.t("LocationDeactivateSuccess"));
			payload.handleLocationModal(false);
			yield put(locationActions.totalSizeDecrease());
			if (list.length >= size) {
				yield put(locationActions.fetchLocations(payload.client));
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteLocation({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {list, size} = yield select((state) => state.app.locations.deleted);
			yield axios.put(`/location/delete/${payload?.id}`);
			yield put(deletedLocationActions.delete(payload?.id));
			ToastSuccesComponent(i18n.t("LocationDeleteSuccess"));
			payload.setPermanantDeleteModal(false);
			// yield put(locationActions.totalSizeDecrease());
			if (list.length >= size) {
				yield put(deletedLocationActions.editSize(size));
				yield put(deletedLocationActions.fetch(payload.client));
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DEACTIVATE_LOCATION, sagas.deactivateLocation);
	yield takeLatest(DELETE_LOCATION, sagas.deleteLocation);
};
