import {ShowInfoIcon, ToggleAccordionIcon2} from "@src/assets/SvgComponents";
import {Select} from "antd";
import React, {useState} from "react";
import SelectedTeamMember from "./SelectedTeamMember";

const CustomSelect = ({
	options,
	onSelect,
	disabled = false,
	onClear,
	mode,
	label,
	value,
	touched,
	errors,
	handleBlur,
	onDeselect,
	customValueRender = false,
	placeholder,
	onSetLead,
	optionFilterProp = "label",
	innerModal,
	renderInnerModal,
	datesModal,
	setDatesModal,
	onSetTeamLead,
	onRemoveFromTeam,
	modal,
	width = "100%",
	additionalInfo,
	toggleInfoVisibility,
	allowClear = true,
}) => {
	const [inputValue, setInputValue] = useState("");
	const isMultiple = mode === "multiple";
	const getValue = () => {
		if (modal === "team" && value?.[0]) {
			const trainers = value?.filter((item) => item.trainer);
			const nonTrainerOrIntern = value?.filter((item) => !item.trainer && !item.internship);
			const interns = value?.filter((item) => item.internship);
			const teamLead = nonTrainerOrIntern.find((item) => item.teamLeader || null);
			const nonTrainerOrInternWithoutTeamLeader = nonTrainerOrIntern.filter(
				(item) => !item.teamLeader,
			);
			const orderedUsersFromState = [
				...(teamLead ? [teamLead] : []),
				...trainers.flatMap((trainer) => [
					trainer,
					...interns.filter((intern) => intern.trainerId === trainer.id),
				]),
				...nonTrainerOrInternWithoutTeamLeader,
			];
			return orderedUsersFromState;
		}
		return value;
	};
	const getTrainerExists = () => {
		if (modal === "team" && value?.[0] && value?.some((obj) => obj.trainer === true)) {
			return true;
		}
		return false;
	};
	return (
		<>
			<div
				style={{width}}
				className={`customdropdown__wrapper ${additionalInfo && "relative"} ${
					isMultiple && "multiple"
				}`}
			>
				{label && <span className="select__title">{label}</span>}
				<Select
					options={options}
					onSelect={onSelect}
					searchValue={inputValue}
					onSearch={(value) => setInputValue(value)}
					onClear={onClear}
					mode={mode}
					showSearch
					disabled={disabled}
					allowClear={allowClear}
					value={customValueRender ? null : value}
					onBlur={handleBlur}
					onDeselect={onDeselect}
					suffixIcon={<ToggleAccordionIcon2 />}
					optionFilterProp={optionFilterProp}
					placeholder={placeholder}
					dropdownStyle={{zIndex: 9999}}
				/>
				{additionalInfo && (
					<div
						className="info__toggler"
						onClick={toggleInfoVisibility}
						style={{
							top: touched && errors ? "24px" : "auto",
							height: isMultiple ? 44 : 40,
							width: isMultiple ? 44 : 40,
						}}
					>
						<ShowInfoIcon fill="#ECECEC" fillColor="#979797" height={16} width={16} />
					</div>
				)}
				{touched && errors && <span className="errorClass">*{errors}</span>}
			</div>
			{customValueRender && value && (
				<div className="custom-render-wrapper">
					{Array.isArray(getValue()) ? (
						getValue()?.map((item) => (
							<SelectedTeamMember
								user={item}
								onDeselect={onDeselect}
								onSetLead={onSetLead}
								innerModal={innerModal}
								renderInnerModal={renderInnerModal}
								datesModal={datesModal}
								setDatesModal={setDatesModal}
								onSetTeamLead={onSetTeamLead}
								onRemoveFromTeam={onRemoveFromTeam}
								modal={modal}
								trainerExists={getTrainerExists()}
							/>
						))
					) : (
						<SelectedTeamMember
							user={value}
							isHeadOfSales
							onDeselect={onDeselect}
							onSetLead={onSetLead}
							innerModal={innerModal}
							renderInnerModal={renderInnerModal}
							datesModal={datesModal}
							setDatesModal={setDatesModal}
							onSetTeamLead={onSetTeamLead}
							onRemoveFromTeam={onRemoveFromTeam}
							modal={modal}
							trainerExists={getTrainerExists()}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default CustomSelect;
