import React from "react";
import MerkmaleTabs from "./components/tabsComponent";
import Infos from "./components/childrenTabComponents/infos";
import Merkmale from "./components/childrenTabComponents/merkmale";
import Dokumente from "./components/childrenTabComponents/dokumente";
import Staffein from "./components/childrenTabComponents/staffein";
import MerkmaleHeader from "./components/headerComponent.js";
import MerkmaleCalculation from "./components/calculationSection";
import MerkmaleSection from "./components/merkmaleSection";
import MerkmaleButtons from "./components/button";

const InfoMerkmaleComponent = ({item, nr, handleOrderFormModal}) => {
	const tabNames = ["Infos", "Merkmale", "Dokumente", "Staffein"];
	const tabContent = [<Infos />, <Merkmale />, <Dokumente />, <Staffein />];
	return (
		<div className="merkmale__container">
			<div className="merkmale__container--header">
				<MerkmaleHeader item={item} nr={nr} />
			</div>
			<div className="merkmaleHeader__container--bottomBorder"></div>
			<div className="merkmale__container--calculation">
				<MerkmaleCalculation item={item} />
			</div>
			<div className="merkmaleHeader__container--shortBottomBorder"></div>
			<div className="merkmale__container--thirdSection">
				<MerkmaleSection />
			</div>
			<div className="merkmaleHeader__container--shortBottomBorder"></div>
			<div className="merkmale__container--tabs">
				<MerkmaleTabs tabNames={tabNames} tabContent={tabContent} />
			</div>
			<div className="merkmaleHeader__container--shortBottomBorder"></div>
			<div className="merkmale__container--buttons">
				<MerkmaleButtons handleOrderFormModal={handleOrderFormModal} item={item} />
			</div>
		</div>
	);
};

export default InfoMerkmaleComponent;
