import React from "react";

const Tabs = ({items, handleTabClick, activeTab}) => (
	<div className="filters__tabs">
		{items?.map((item) => (
			<div
				onClick={() => handleTabClick(item)}
				className={`tab ${activeTab === item ? "active" : ""}`}
				key={item}
			>
				{item}
			</div>
		))}
	</div>
);

export default Tabs;
