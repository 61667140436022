import moment from "moment";

export const isInsideRange = (val1, val2) => {
	if (val1?.toDate && val2?.toDate) {
		return (
			moment(val2?.fromDate).isSameOrAfter(val1?.fromDate) &&
			moment(val2?.toDate).isSameOrBefore(val1?.toDate)
		);
	}
	return moment(val2?.fromDate).isSameOrAfter(val1?.fromDate);
};
export const checkInternDates = (users) => {
	const areInternDatesInsideRange = users.every((trainer) => {
		if (trainer.trainer) {
			const internsForTrainer = users.filter((intern) => intern.trainerId === trainer.id);
			if (internsForTrainer?.length > 0) {
				const hasInternsInsideRange = internsForTrainer.every((intern) =>
					isInsideRange(trainer, intern),
				);
				return hasInternsInsideRange;
			}
			return true;
		}
		return true;
	});
	return areInternDatesInsideRange;
};
