export const saveTenantFeatures = (data) => {
	const obj = {};
	// eslint-disable-next-line no-unused-expressions
	data?.forEach((item) => {
		if (item && item.name) {
			obj[item.name] = item.valueWeb;
		}
	});
	localStorage.setItem("features", JSON.stringify(obj));
	const event = new CustomEvent("features", {detail: obj});
	window.dispatchEvent(event);
};
