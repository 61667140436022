import ButtonComponent from "@common/button";
import InputComponent from "@common/input";
import {Formik} from "formik";
import * as yup from "yup";
import React, {useEffect} from "react";
import {actions as createOrderActions} from "@sagas/orders/create.js";
import {actions as productActions} from "@sagas/products/";
import {actions as clientActions} from "@sagas/client/";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import CloseIcon from "@common/closeIcon/CloseIcon";
import DropDownInput from "@common/dropDown";
import {actions as productActios} from "@sagas/products";
import {useTranslation} from "react-i18next";

function EditAndCreateComission(props) {
	const {
		addOrder,
		orderInitialValues,
		setShowModal,
		optionsProducts,
		optionsTariffs,
		optionsProjectCategories,
		chosenProductId,
		filteredTariffs,
		setFilteredTariffs,
		loading,
		optionsClients,
		setSelectedClientId,
		fetchProductsByDropdown,
		selectedClient,
	} = props;

	const hasID = orderInitialValues?.id;

	useEffect(() => {
		setFilteredTariffs(null);
		// eslint-disable-next-line array-callback-return
		optionsTariffs.map((item) => {
			if (item.commissionProducts.includes(chosenProductId)) {
				setFilteredTariffs(item);
			}
		});
		// eslint-disable-next-line
	}, [chosenProductId]);

	useEffect(() => {
		fetchProductsByDropdown();
		// eslint-disable-next-line
	}, [selectedClient]);

	const {t} = useTranslation();

	const validationSchema = yup.object().shape({
		location: yup
			.string()
			.label(t("TheLocation"))
			.required(t("TheLocation") + t("IsRequired")),
		productId: yup
			.object()
			.nullable()
			.label(t("Product"))
			.required(t("Product") + t("IsRequired")),
		tariffId: yup
			.object()
			.nullable()
			.label(t("Tariff"))
			.required(t("Tariff") + t("IsRequired")),
		projectCategoryId: yup
			.object()
			.nullable()
			.label(t("ProjectCategory"))
			.required(t("ProjectCategory") + t("IsRequired")),
		clientId: yup
			.object()
			.nullable()
			.label(t("Client"))
			.required(t("Client") + t("IsRequired")),
	});

	return (
		<Formik
			initialValues={orderInitialValues}
			validationSchema={validationSchema}
			onSubmit={(values, actions) => addOrder({values, formActions: actions})}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				setTouched,
			}) => (
				<form
					className="edit__and__create__form"
					autoComplete="off"
					noValidate
					onSubmit={handleSubmit}
				>
					<div className="close__modal__icon__and__name">
						<h1>{`${hasID ? t("Add") : t("Edit")}  `}</h1>
						<CloseIcon
							className="close__modal__icon"
							click={() => setShowModal(false)}
						/>
					</div>
					<div>
						<InputComponent
							name="location"
							placeholder={t("TheLocation")}
							type="location"
							errorClass="errorClass"
							errors={errors.location}
							touched={touched.location}
							values={values.location}
							handleBlur={handleBlur}
							handleChange={handleChange}
							newClass="orderInput"
						/>
						<DropDownInput
							id="clientId"
							name="value"
							onChange={(event, value) => {
								setSelectedClientId(value.value);
								setFieldValue("clientId", value);
								setFieldValue("productId", null);
								setFieldValue("tariffId", null);
								setFieldValue("projectCategoryId", null);
							}}
							options={optionsClients}
							placeholder={t("Client")}
							title="name"
							touched={touched.clientId}
							value={values.clientId}
							errors={errors.clientId}
							onBlur={() => setTouched(true)}
						/>
						<DropDownInput
							errors={errors.productId}
							id="productId"
							name="value"
							onChange={(event, value) => {
								setFieldValue("productId", value);
								setFieldValue("tariffId", null);
							}}
							options={optionsProducts}
							placeholder={t("Product")}
							title="name"
							value={values.productId}
							disabled={!values.clientId}
							touched={touched.productId}
						/>
						<DropDownInput
							errors={errors.tariffId}
							id="tariffId"
							name="value"
							onChange={(event, value) => setFieldValue("tariffId", value)}
							options={filteredTariffs}
							placeholder={t("Tariff")}
							title="name"
							touched={touched.tariffId}
							value={values.tariffId}
							setTariffsState="asd"
							disabled={!values?.productId}
						/>
						<DropDownInput
							errors={errors.projectCategoryId}
							id="projectCategoryId"
							name="value"
							onChange={(event, value) => setFieldValue("projectCategoryId", value)}
							options={optionsProjectCategories}
							placeholder={t("ProjectCategory")}
							title="name"
							touched={touched.projectCategoryId}
							value={values.projectCategoryId}
							disabled={!values?.tariffId}
						/>
					</div>
					<ButtonComponent
						loading={loading}
						buttonText={t("Send")}
						classNames="primaryButton"
					/>
				</form>
			)}
		</Formik>
	);
}

const mapStateToProps = (state) => ({
	chosenProductId: state.app.products.index.chosenProductId,
	filteredTariffs: state.app.products.index.filteredTariffs,
	optionsProducts: state.app.products.index.optionsProducts,
	optionsTariffs: state.app.tariffs.index.optionsTariffs,
	userAdded: state.app.users.createUser.userAdded,
	orderInitialValues: state.app.orders.create.orderInitialValues,
	loading: state.app.orders.create.loading,
	optionsProjectCategories: state.app.projectCategories.index.optionsProjectCategories,
	optionsClients: state.app.client.index.optionsClients,
	selectedClient: state.app.client.index.selectedClient,
});

const mapDispatchToProps = {
	addOrder: createOrderActions.addOrder,
	setFilteredTariffs: productActions.setFilteredTariffs,
	setSelectedClientId: clientActions.setSelectedClientId,
	fetchProductsByDropdown: productActios.fetchProductsByDropdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditAndCreateComission));
