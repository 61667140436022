import React from "react";
import {connect} from "react-redux";
import ClientsBar from "@src/common/ClientsBar/ClientsBar";
import RoleStats from "@src/common/RoleStats/RoleStats";

const DashboardTwo = (props) => {
	const {
		orders,
		query,
		orderGraphs,
		clients,
		handleActive,
		active,
		setActive,
		datesGraph,
		rangeType,
		setRangeType,
	} = props;

	return (
		<div className="dashboardTwo">
			<div className="dashboardTwo_clients">
				<ClientsBar
					dates={query}
					handleActive={handleActive}
					active={active}
					datesGraph={datesGraph}
					orderGraphs={orderGraphs}
					rangeType={rangeType}
					setRangeType={setRangeType}
					setActive={setActive}
					clients={clients}
					orders={orders}
				/>
			</div>
			<RoleStats dates={query} />
		</div>
	);
};

const mapStateToProps = (state) => ({
	query: state.app.orders.index.query,
	orders: state.app.orders.index.orders,
	orderGraphs: state.app.orders.index.orderGraphs,
	clients: state.app.client.index.clientsByDropdown,
});

export default connect(mapStateToProps, null)(DashboardTwo);
