import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import TableRemote from "@common/table/remote";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import RangePicker from "@common/rangePicker/index";
import moment from "moment";
import LabelButton from "@common/LabelButton";
import InputFile from "@common/fileInput";
import {actions as createImports} from "@sagas/imports/create";
import {actions as importsActions} from "@sagas/imports/";
import {ReactComponent as ImportLogo} from "@assets/img/file_upload_black_24dp.svg";
import Loader from "@common/Loader";
import TabComp from "@common/createUserTabs/createUserTabsComp/index";
import {actions as navigation} from "@sagas/navigation";
import {importedColumns} from "../../columns";
import i18n from "../../../../i18n";

const Orders = (props) => {
	const {
		size,
		filterImports,
		filters,
		page,
		totalSize,
		search,
		parseCsv,
		parseLoading,
		getImports,
		imports,
		importsEditSize,
		importsEditPage,
		navigate,
		fetchLoading,
	} = props;

	const {t} = useTranslation();

	const [dates, setDates] = useState([null, null]);

	const tableRef = useRef();

	useEffect(() => {
		filterImports({name: "fromDate", value: moment(dates[0]).valueOf()});
		filterImports({
			name: "toDate",
			value: moment(dates[1]).endOf("day").valueOf(),
		});
		getImports();
		// eslint-disable-next-line
  }, [dates])

	const handleSearch = (value) => {
		filterImports({name: "search", value: value.target.value});
		const debounced = _.debounce(() => {
			getImports();
		}, 1000);
		debounced();
	};

	const [uploadedCsv, setUploadedCsv] = useState();

	useEffect(() => {
		if (uploadedCsv) {
			parseCsv(uploadedCsv);
		}
		// eslint-disable-next-line
  }, [uploadedCsv])

	return (
		<>
			<div className="dashboard__container orders ordersImports">
				<div className="dashboard__left__text users calc">
					<h1 className="orders__text">{t("Orders")}</h1>
					<div className="searchbar_small">
						<BigSearchBar value={filters.search} handleSearch={handleSearch} />
					</div>
					<div className="orders__date__and__create">
						<div className="orders__daterange__picker">
							<div className="orders__daterange__picker__filters">
								<RangePicker
									setDates={setDates}
									onChange={(newDates) => {
										setDates(newDates);
									}}
									dates={dates}
									errors={{from: ""}}
									newClass="orders-rangepicker"
									newClass2="orders-rangepicker"
								/>
							</div>
							<div className="import__order__container__upload">
								<LabelButton>
									{i18n.t(parseLoading ? "OnlyLoading" : "Upload")}
									{parseLoading ? (
										<div className="loader__browse__container">
											<Loader />
										</div>
									) : (
										<ImportLogo />
									)}
								</LabelButton>
								<InputFile
									name="order__upload__file__input"
									onChange={(e) => setUploadedCsv(e.target.files[0])}
									accept=".csv"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="toggle__orders__imports">
					<TabComp
						onClick={() => navigate("/admin/orders")}
						active={false}
						word={t("Orders")}
						templates
					></TabComp>
					<TabComp active={true} word={t("Imports")} templates></TabComp>
				</div>

				<div style={{padding: "30px"}}>
					<div className="loader__and__table">
						<TableRemote
							refTable={tableRef}
							useCustomRef={true}
							columns={importedColumns}
							data={imports}
							maxBodyHeight="calc(100vh - 290px)"
							minBodyHeight="calc(100vh - 290px)"
							fetchFunction={getImports}
							separatedPagination={{
								page,
								size,
								totalSize,
								search,
							}}
							separatedSetPage={importsEditPage}
							separatedSetSize={importsEditSize}
						/>

						{fetchLoading && (
							<div className="full__width__loader">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	parseLoading: state.app.imports.create.loading,
	imports: state.app.imports.index.imports,
	fetchLoading: state.app.imports.index.loading,
	search: state.app.imports.index.search,
	page: state.app.imports.index.page,
	totalSize: state.app.imports.index.totalSize,
	filters: state.app.imports.index.filters,
	size: state.app.imports.index.size,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	parseCsv: createImports.parseCsv,
	getImports: importsActions.getImports,
	importsEditSize: importsActions.editSize,
	importsEditPage: importsActions.editPage,
	filterImports: importsActions.filterImports,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
