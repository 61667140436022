import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Comission client");

const PREFIX = "@app/store/sagas/app/comission/clientComission";

// clients
export const FETCH_CLIENT_COMISSION = `${PREFIX}FETCH_CLIENT_COMISSION`;
export const FETCH_CLIENT_COMISSION_SUCCESS = `${PREFIX}FETCH_CLIENT_COMISSION_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const ADD_COMISSION_ON_STATE = `${PREFIX}ADD_COMISSION_ON_STATE`;
export const EDIT_COMISSION_ON_STATE = `${PREFIX}EDIT_COMISSION_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const TOTAL_SIZE_DECREASE = `${PREFIX}TOTAL_SIZE_DECREASE`;

const _state = {
	clientComissions: [],
	isLoading: false,
	page: 1,
	size: 10,
	totalSize: 5,
	search: "",
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_CLIENT_COMISSION_SUCCESS:
				draft.clientComissions = payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload + 1;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case LOADING:
				draft.loading = payload;
				break;
			case ADD_COMISSION_ON_STATE:
				draft.clientComissions = [payload].concat(state.clientComissions);

				break;
			case EDIT_COMISSION_ON_STATE:
				const index = state.clientComissions.findIndex((c) => c.id === payload.id);
				draft.clientComissions[index] = payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchClientComissions: (payload) => createAction(FETCH_CLIENT_COMISSION, {payload}),
	fetchClientComissionsSuccess: (payload) =>
		createAction(FETCH_CLIENT_COMISSION_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	addComissionOnState: (payload) => createAction(ADD_COMISSION_ON_STATE, {payload}),
	editComissionOnState: (payload) => createAction(EDIT_COMISSION_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterComissions: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),

	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	totalSizeDecrease: (payload) => createAction(TOTAL_SIZE_DECREASE, {payload}),
};

export const sagas = {
	*fetchClientComissions(payload) {
		yield put(actions.setLoading(true));
		try {
			const {size} = yield select((state) => state.app.comission.clientComission);
			const {page} = yield select((state) => state.app.comission.clientComission);
			const {search} = yield select((state) => state.app.comission.clientComission);

			const response = yield axios.get(
				`/commission/all/${payload?.payload}?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchClientComissionsSuccess(response?.data?.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_CLIENT_COMISSION, sagas.fetchClientComissions);
};
