/* eslint-disable max-len */
import React, {useEffect} from "react";
import ModalComponent from "@common/modal";
import {useTranslation} from "react-i18next";
import {ArrowDownIcon, BackIconModal, Close} from "@src/assets/SvgComponents";
import {useFormik} from "formik";
import * as yup from "yup";
import RangePicker from "@src/common/Range-Picker";
import {withRouter} from "react-router";
import ButtonComponent from "@src/common/button";
import {connect} from "react-redux";
import {actions as createClientActions} from "@sagas/client/create";
import InputComponent from "@common/input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Loader from "@src/common/Loader";
import CustomSelect from "@src/common/Select";
import UploadCard from "../../../../../../../../common/UploadCard/UploadCard";

function UploadDocumentModal({
	showModal,
	setShowModal,
	uploadDocument,
	document,
	match,
	clearClientDocs,
	loadingDocuments,
}) {
	const {t} = useTranslation();
	const bytesToMB = (bytes) => {
		const megabytes = bytes / (1024 * 1024);
		return megabytes.toFixed(2);
	};
	const handleCloseModal = () => {
		setShowModal(false);
		clearClientDocs();
	};
	const validationSchema = yup.object().shape({
		name: yup.string().label(t("name")).required(t("NameIsReqired")),
		type: yup.string().label(t("type")).required(t("TypeIsReq")),
		from: yup.string().label(t("startDate")).required(t("DateIsReq")).nullable(),
		to: yup.string().label(t("endDate")).required(t("DateIsReq")).nullable(),
		file: yup.array().required(t("pdfReq")).nullable().min(1, t("pdfReq")),
	});
	const editValidationSchema = yup.object().shape({
		name: yup.string().label(t("name")).required(t("NameIsReqired")),
		from: yup.string().label(t("startDate")).required(t("DateIsReq")).nullable(),
		to: yup.string().label(t("endDate")).required(t("DateIsReq")).nullable(),
	});
	const formik = useFormik({
		validationSchema: document?.id ? editValidationSchema : validationSchema,
		initialValues: {
			name: document?.fileName || "",
			type: document?.type || "",
			from: document?.from || null,
			to: document?.to || null,
			file: document?.file || "",
		},
		enableReinitialize: true,
		onSubmit: (values, {resetForm}) => {
			const createObj = {
				fileName: values?.name,
				typeStatisticsDocument: values?.type,
				from: values?.from,
				to: values?.to,
				uploadDocumentUrl: values?.file?.[0]?.link,
				clientId: +match.params.clientId,
			};
			const editObj = {
				fileName: values?.name,
				to: values?.to,
				from: values?.from,
				id: document?.id,
			};
			uploadDocument(document?.id ? editObj : createObj);
			resetForm();
			handleCloseModal();
		},
	});
	const {values, handleSubmit, setFieldValue, errors, touched} = formik;

	const options = [
		{label: t("contractDocuments"), value: "CONTRACT_DOCUMENTS"},
		{label: t("additionalAgreements"), value: "ADDITIONAL_DOCUMENTS"},
	];
	return (
		<ModalComponent
			open={showModal === "modal"}
			handleClose={handleCloseModal}
			disableEnforceFocus
			customClassNames="statistics__upload__documents"
			width={600}
			positionModal="right"
		>
			{loadingDocuments ? (
				<div className="loadingContainer">
					<Loader />
				</div>
			) : (
				<>
					<div className="statistics__upload__documents-header">
						<div onClick={handleCloseModal} style={{cursor: "pointer"}}>
							<BackIconModal fill="var(--color-primary)" />
						</div>
						<span className="statistics__upload__documents-header-text">
							{document?.id ? t("Edit") : t("uploadNew")}
						</span>
						<div onClick={handleCloseModal} style={{cursor: "pointer"}}>
							<Close fill="var(--color-primary)" />
						</div>
					</div>
					<div className="statistics__upload__documents-body">
						<InputComponent
							className="custom-input"
							label={t("fileName")}
							values={values?.name}
							errorClass="errorClass"
							handleChange={(value) => setFieldValue("name", value.target.value)}
							errors={errors?.name}
							touched={touched?.name}
						/>
						<RangePicker
							setFieldValue={setFieldValue}
							startDateProps={{
								field: "from",
								value: values?.from,
							}}
							endDateProps={{
								field: "to",
								value: values?.to,
								disabled: values?.from === null,
							}}
							picker="date"
							touched={touched?.from || touched?.to}
							error={errors?.from || errors?.to}
						/>
						{!document?.id && (
							<div className="modal__dropdown__menu">
								<label htmlFor="selectType">{t("ChooseType")}</label>
								<CustomSelect
									options={options}
									onSelect={(value) => setFieldValue("type", value)}
									value={values.type}
									onClear={() => setFieldValue("type", null)}
									touched={touched.type}
									errors={errors.type}
									placeholder={t("ChooseType")}
									optionFilterProp="label"
								/>
							</div>
						)}
						{!document?.id && (
							<UploadCard
								setFieldValue={setFieldValue}
								errors={errors?.file}
								accept=".pdf"
							/>
						)}
						{values?.file?.[0] && !document?.id && (
							<div className="statistics__upload__documents-body-files">
								<div className="statistics__upload__documents-body-files__file">
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: 10,
										}}
									>
										<div className="statistics__upload__documents-body-files__pdfbox">
											<span>PDF</span>
										</div>
										<span>{values?.file?.[0]?.name}</span>
									</div>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: 10,
											marginRight: 10,
										}}
									>
										<span>{bytesToMB(values?.file?.[0]?.size)}Mb</span>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												cursor: "pointer",
											}}
											onClick={() => setFieldValue("file", "")}
										>
											<Close fill="#979797" />
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="statistics__upload__documents-footer client-list-button">
						<ButtonComponent
							type="submit"
							buttonText={t("Upload")}
							onClick={handleSubmit}
						/>
					</div>
				</>
			)}
		</ModalComponent>
	);
}
const mapStateToProps = (state) => ({
	document: state.app.client.create.doc,
	loadingDocuments: state.app.client.create.loadingDocuments,
});

const mapDispatchToProps = {
	uploadDocument: createClientActions.uploadDocument,
	clearClientDocs: createClientActions.clearClientDocs,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadDocumentModal));
