import ButtonComponent from "@src/common/button";
import ModalComponent from "@src/common/modal";
import React from "react";
import {useTranslation} from "react-i18next";

const ConfirmModal = ({showConfirmModal, handleClose, handleEdit, modalType}) => {
	const {t} = useTranslation();
	return (
		<ModalComponent open={showConfirmModal} handleClose={handleClose}>
			<div className="delete__modal__component">
				<h1>{modalType === "copy" ? t("AreUSureCopy") : t("AreYouSureDelete")}</h1>
				<div className="delete__modal__component__button">
					<ButtonComponent
						classNames="cancel__button"
						buttonText={t("Cancel")}
						onClick={handleClose}
					/>
					<ButtonComponent
						classNames="confirm__button"
						buttonText={t("Confirm")}
						onClick={handleEdit}
					/>
				</div>
			</div>
		</ModalComponent>
	);
};

export default ConfirmModal;
