import CustomPopover from "@src/common/Popover";
import React, {useMemo} from "react";
import Button from "@src/common/components/button";
import {useUrlParams} from "@src/utils/useUrlParams";
import viewIcon from "../../../../assets/img/set_view.svg";
import {items} from "./helper";

const LeadGroupView = () => {
	const {getUrlParams, setUrlParams} = useUrlParams();
	const {viewType} = getUrlParams("view-type");
	const handleItemClick = (type) => setUrlParams(type, "view-type");

	const newItems = useMemo(
		() =>
			items.map((it) => {
				if (it.key === viewType) {
					return {
						...it,
						className: "active",
					};
				}
				return it;
			}),
		[viewType],
	);

	return (
		<CustomPopover
			menu={{
				items: newItems,
				onClick: ({key}) => handleItemClick(key),
			}}
			customClass="createuseractions_popover__actions-wrapper"
			placement="bottomRight"
			trigger={["click"]}
		>
			<div className="three-dots-icon">
				<Button
					icon={viewIcon}
					leftRounded={false}
					rightRounded={false}
					smallButtonWithTooltip
					hideTooltip
				/>
			</div>
		</CustomPopover>
	);
};

export default LeadGroupView;
