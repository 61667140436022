import React from "react";
import {ProgressIcon, SuccessIcon, WarningIcon} from "@src/assets/SvgComponents";

export const activityStatuses = {
	PENDING: {icon: <ProgressIcon />, color: "#EECB64"},
	COMPLETED: {icon: <SuccessIcon />, color: "#6DB070"},
	NO_USERS: {icon: <WarningIcon />, color: "#DA6D6D"},
	FAILED: {icon: <WarningIcon />, color: "#DA6D6D"},
	FAILED_AVERAGE: {icon: <WarningIcon />, color: "#DA6D6D"},
	FAILED_STUFE: {icon: <WarningIcon />, color: "#DA6D6D"},
};
