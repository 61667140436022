/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as userActions} from "@sagas/users/users/create";
import {actions as teamActions} from "@sagas/teams";
import {useTranslation} from "react-i18next";
import InputComponent from "@common/input";
import ButtonComponent from "@common/button";
import InputFile from "@common/fileInput";
import DatePicker from "@common/DatePicker";
import * as yup from "yup";
import {Formik} from "formik";
import RadioGroupInput from "@common/radioButton/radioGroupInput";
import DropDownInput from "@common/dropDown";
import RadioInput from "@common/radioButton";
import moment from "moment";
import {actions as usersActions} from "@sagas/users/users";
import {actions as navigation} from "@sagas/navigation";
import BackButtonComponents from "@src/common/BackButton";
import CloseIcon from "@src/common/closeIcon/CloseIcon";
import {actions as structuresActions} from "@sagas/structures/index";
import {Storage} from "@src/utils/local-storage";
import Title from "./Title";
import {companyType, gender, STRUCTURE_ROLES, userRoles} from "./staticData";

const AGENCY_ROLE = ["AGENCY"];

const CreateUser = (props) => {
	const {t} = useTranslation();

	const {
		userInitialValues,
		loading,
		addUser,
		getSingleUser,
		match,
		fillUsers,
		loggedUser,
		fetchAgencies,
		agencies,
		goBack,
		getStructures,
		getStufes,
		structuresState,
		getSupervisors,
		supervisors,
	} = props;
	const tenantFeatures = Storage.get("features");
	const agencyOptions = new Array(agencies.length);
	const {structures, stufes} = structuresState;
	const [selectedStructure, setSelectedStructure] = useState(null);
	const [selectedStufe, setSelectedStufe] = useState(null);

	for (let i = 0; i < agencyOptions.length; i++) {
		agencyOptions[i] = {
			name: agencies[i]?.name,
			value: agencies[i]?.teamLeaderId,
		};
	}
	const [createUserRoleCheck, setCreateUserRoleCheck] = useState(userRoles);
	const handleCreateUserRoleCheck = () => {
		if (loggedUser?.role?.name === "BACK-OFFICE") {
			setCreateUserRoleCheck(
				userRoles.filter((element) => element?.value !== 8 && element?.value !== 9),
			);
		} else if (
			loggedUser?.role?.name !== "TENANT" ||
			loggedUser?.role?.name === "MANAGEMENTINFO"
		) {
			setCreateUserRoleCheck(userRoles.filter((element) => element?.value !== 9));
		} else {
			setCreateUserRoleCheck(userRoles);
		}
	};
	useEffect(() => {
		fetchAgencies({all: true, isTeam: true});
		handleCreateUserRoleCheck();
		// eslint-disable-next-line
	}, [loggedUser])
	useEffect(() => {
		if (userInitialValues?.selectStructureId?.value) {
			setSelectedStructure(userInitialValues?.selectStructureId?.value);
		}
		if (userInitialValues?.selectStufeId?.value) {
			setSelectedStufe(userInitialValues?.selectStufeId?.value);
		}
	}, [userInitialValues?.selectStructureId, userInitialValues?.selectStufeId]);
	useEffect(() => {
		if (selectedStructure && selectedStufe) {
			getSupervisors({structure: selectedStructure, stufe: selectedStufe});
		}
	}, [selectedStructure, selectedStufe]);

	const FILE_SIZE = 3 * 1024 * 1024; // 3MB

	const userValidationSchema = yup.object().shape({
		//
		isCompany: yup.string().label(t("Company")).optional().nullable(),
		firstName: yup.string().label(t("FirstName")).required(t("FirstNameIsReq")),
		lastName: yup.string().label(t("LastName")).optional().nullable(),
		dateOfBirth: yup.string().label(t("Birthdate")).optional().nullable(),
		entryDate: yup.string().label(t("EntryDate")).optional().nullable(),
		exitDate: yup.string().label(t("ExpiryDate")).optional().nullable(),
		postCode: yup.string().label(t("PostCode")).optional().nullable(),
		city: yup.string().label(t("TheLocation")).optional().nullable(),
		street: yup.string().label(t("PostCode")).optional().nullable(),
		houseNumber: yup.string().label(t("HouseNumber")).optional().nullable(),
		company: yup.string().when(["isCompany", "userRoles"], {
			is: (isCompany, userRoles) =>
				isCompany === "yes" || userRoles?.value === 6 || userRoles?.value === 14,
			then: yup.string().label(t("CompanyName")).required().nullable(),
			otherwise: yup.string().label(t("CompanyName")).optional().nullable(),
		}),
		agencyType: yup.string().when(["userRoles"], {
			is: (userRoles) => userRoles?.value === 6,
			then: yup
				.string()
				.label(t("CalculationType"))
				.required(t("CalculationTypeReq"))
				.nullable(),
			otherwise: yup
				.string()
				.label(t("CalculationType"))
				.optional(t("CalculationTypeReq"))
				.nullable(),
		}),
		logo: yup.mixed().when(["agencyType", "userRoles"], {
			is: (agencyType, userRoles) => userRoles?.value === 6 || userRoles?.value === 14,
			then: yup.mixed().label(t("AgencyLogoReq")).required(t("AgencyLogoReq")).nullable(),
			otherwise: yup
				.mixed()
				.label(t("AgencyLogoReq"))
				.optional(t("AgencyLogoReq"))
				.nullable(),
		}),
		taxNumberUrl: yup.mixed().test("fileSize", t("FileSizeError"), (value) => {
			if (value?.size) {
				return value.size <= FILE_SIZE;
			}
			return true; // allow empty files
		}),
		mobilePhone: yup
			.number()
			.typeError(t("mustBeNumbers"))
			.label(t("MobilePhone"))
			.optional()
			.nullable(),
		idNumberUrlFront: yup.mixed().test("fileSize", t("FileSizeError"), (value) => {
			if (value?.size) {
				return value.size <= FILE_SIZE;
			}
			return true; // allow empty files
		}),
		idNumberUrlBack: yup.mixed().test("fileSize", t("FileSizeError"), (value) => {
			if (value?.size) {
				return value.size <= FILE_SIZE;
			}
			return true; // allow empty files
		}),
		businessLicenseUrl: yup.mixed().test("fileSize", t("FileSizeError"), (value) => {
			if (value?.size) {
				return value.size <= FILE_SIZE;
			}
			return true; // allow empty files
		}),
		hrNumberUrl: yup.mixed().test("fileSize", t("FileSizeError"), (value) => {
			if (value?.size) {
				return value.size <= FILE_SIZE;
			}
			return true; // allow empty files
		}),
		vatEntitlementUrl: yup.mixed().test("fileSize", t("FileSizeError"), (value) => {
			if (value?.size) {
				return value.size <= FILE_SIZE;
			}
			return true; // allow empty files
		}),
		vatNumberUrl: yup.mixed().test("fileSize", t("FileSizeError"), (value) => {
			if (value?.size) {
				return value.size <= FILE_SIZE;
			}
			return true; // allow empty files
		}),
		tradeLicenceUrl: yup.mixed().optional().nullable(),
		idNumber: yup.string().label(t("IDNumber")).optional().nullable(),
		taxNumber: yup.string().label(t("TaxNumber")).optional().nullable(),
		passportValidUntil: yup.string().label(t("PassportValidUntil")).optional().nullable(),
		taxOffice: yup.string().label(t("TaxOffice")).optional().nullable(),
		businessLicense: yup.string().label(t("BusinessLicense")).optional().nullable(),
		hrNumber: yup.string().label(t("HrNumber")).optional().nullable(),
		bankName: yup.string().label(t("BankName")).optional().nullable(),
		nameOfBankAccountHolder: yup.string().label(t("BankAccountHolder")).optional().nullable(),
		vatEntitlement: yup.string().label(t("VATEntitlement")).optional().nullable(),
		vatNumber: yup.string().label(t("VATNumber")).optional().nullable(),
		phoneNumber: yup.string().label(t("PhoneNumber")).optional().nullable(),
		email: yup
			.string()
			.lowercase()
			.strict()
			.label(t("Email"))
			.required(t("EmailIsRequred"))
			.email(t("EmailIsRequred")),
		iban: yup.string().label(t("Iban")).optional().nullable(),
		bic: yup.string().label(t("Bic")).optional().nullable(),
		extVpNr: yup.string().label(t("ExtVpNr")).required(t("VPNumberIsReq")),
		userRoles: loggedUser?.role?.name?.includes(AGENCY_ROLE)
			? yup.object().label("UserRoles").optional()
			: yup.object().label("UserRoles").required(t("UserRolesReq")),
		companyType: yup.object().when(["isCompany"], {
			is: (isCompany) => isCompany === "yes",
			then: yup.object().label(t("CompanyTypes")).required(t("CompanyTypesIsReq")).nullable(),
			otherwise: yup.object().label(t("CompanyTypes")).optional().nullable(),
		}),
		gender: yup.object().required(t("GenderReq")),
		agency: yup
			.object()
			.nullable()
			.when(["userRoles"], {
				is: (userRoles) => userRoles?.value === 7 && !match.params.id,
				then: yup.object().label(t("Agency")).nullable().required(),
			}),
		selectStructureId: yup
			.object()
			.nullable()
			.when(["userRoles"], {
				is: (userRoles) =>
					STRUCTURE_ROLES.includes(userRoles?.value) &&
					tenantFeatures?.Energy &&
					!match.params.id,
				then: yup.object().required(t("structureIsReq")).nullable(),
				otherwise: yup.object().nullable().optional(),
			}),
		selectStufeId: yup
			.object()
			.nullable()
			.when(["userRoles"], {
				is: (userRoles) =>
					STRUCTURE_ROLES.includes(userRoles?.value) &&
					tenantFeatures?.Energy &&
					!match.params.id,
				then: yup.object().required(t("stufeIsReq")).nullable(),
				otherwise: yup.object().nullable().optional(),
			}),
		supervisor: yup.object().nullable().optional(),
	});
	useEffect(() => {
		if (match.params.id) {
			getSingleUser(match.params.id);
		}
		return () => fillUsers({});
		// eslint-disable-next-line
	}, [match.params.id])

	useEffect(() => {
		if (tenantFeatures?.Energy) {
			getStructures();
		}
	}, []);
	useEffect(() => {
		if (selectedStructure) getStufes(selectedStructure);
	}, [selectedStructure]);

	return (
		<div style={{paddingTop: "0px"}} className="dashboard__container">
			<div className="crate__user__side__drawer createUser">
				<div className="edit__and__create__user__container">
					<Formik
						enableReinitialize
						initialValues={userInitialValues}
						validationSchema={userValidationSchema}
						onSubmit={(values, actions) => addUser({values, formActions: actions})}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => (
							<form
								className="edit__and__create__form"
								autoComplete="off"
								noValidate
								onSubmit={handleSubmit}
							>
								<div className="back__button__create__user close__user">
									<BackButtonComponents
										onClick={goBack}
										className="back__button"
									/>
									<CloseIcon click={goBack} className="close__button" />
								</div>
								<Title
									title={t("GeneralInformation")}
									customClass="edit_users-class"
								/>
								<div className="create__user__form__ContGeneral">
									<div className="create__user__form__ContGeneral-left">
										<InputComponent
											name="firstName"
											label={t("FirstName")}
											type="text"
											errorClass="errorClass"
											values={values.firstName}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.firstName}
											errors={errors.firstName}
										/>
										<InputComponent
											name="lastName"
											label={t("LastName")}
											type="text"
											errorClass="errorClass"
											values={values.lastName}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.lastName}
											errors={errors.lastName}
										/>
										<DatePicker
											label={t("DateOfBirth")}
											name="dateOfBirth"
											selected={values?.dateOfBirth}
											onChange={(value) => {
												setFieldValue(
													"dateOfBirth",
													moment(value).valueOf(),
												);
											}}
											value={values.dateOfBirth}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.dateOfBirth}
											errors={errors.dateOfBirth}
											maxDate={moment().valueOf()}
											className="dateOfBirth-input"
										/>
										<div className="dropdown-input">
											<DropDownInput
												label={t("Gender")}
												value={values.gender}
												id="gender"
												name="value"
												options={gender}
												onChange={(e, value) => {
													setFieldValue("gender", value);
												}}
												onBlur={handleBlur}
												placeholder={t("Gender")}
												title="name"
												touched={touched.gender}
												errors={errors.gender}
												errorClass="errorClass"
												withTouched={false}
												noTouched={true}
											/>
										</div>
										<InputComponent
											name="street"
											label={t("Street")}
											type="text"
											errorClass="errorClass"
											values={values.street}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.street}
											errors={errors.street}
										/>
										<InputComponent
											name="houseNumber"
											label={t("HouseNumber")}
											type="text"
											errorClass="errorClass"
											values={values.houseNumber}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.houseNumber}
											errors={errors.houseNumber}
										/>
										<InputComponent
											name="postCode"
											label={t("PostCode")}
											type="text"
											errorClass="errorClass"
											values={values.postCode}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.postCode}
											errors={errors.postCode}
										/>
										<InputComponent
											name="city"
											label={t("TheLocation")}
											type="text"
											errorClass="errorClass"
											values={values.city}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.city}
											errors={errors.city}
										/>
									</div>
									<div className="create__user__form__ContGeneral-right mobileEmail">
										<InputComponent
											name="mobilePhone"
											label={t("MobilePhone")}
											type="text"
											errorClass="errorClass"
											values={values.mobilePhone}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.mobilePhone}
											errors={errors.mobilePhone}
										/>

										<InputComponent
											name="email"
											label={t("email")}
											type="text"
											errorClass="errorClass"
											values={values.email}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.email}
											errors={errors.email}
										/>
										<div
											className={
												values?.entryDate == null
													? "dates-picker dates-picker-delete"
													: " dates-picker-delete"
											}
										>
											<DatePicker
												label={t("EntryDate")}
												name="entryDate"
												selected={values?.entryDate}
												onChange={(value) => {
													setFieldValue(
														"entryDate",
														moment(value).valueOf(),
													);
												}}
												onClear={() => {
													setFieldValue("entryDate", null);
												}}
												showClearBtn={true}
												value={values.entryDate}
												handleBlur={handleBlur}
												handleChange={handleChange}
												touched={touched.entryDate}
												errors={errors.entryDate}
												className="dateOfBirth-input"
											/>
										</div>
										<div
											className={
												values?.exitDate == null
													? "dates-picker dates-picker-delete"
													: " dates-picker-delete"
											}
										>
											<DatePicker
												label={t("ExpiryDate")}
												name="exitDate"
												selected={values?.exitDate}
												onChange={(value) => {
													setFieldValue(
														"exitDate",
														moment(value).valueOf(),
													);
												}}
												onClear={() => {
													setFieldValue("exitDate", null);
												}}
												showClearBtn={true}
												value={values.exitDate}
												handleBlur={handleBlur}
												handleChange={handleChange}
												touched={touched.exitDate}
												errors={errors.exitDate}
												className="dateOfBirth-input"
											/>
										</div>
									</div>
								</div>
								{STRUCTURE_ROLES.includes(values?.userRoles?.value) && (
									<div className="create__user__form__ContGeneral">
										<div className="create__user__form__ContGeneral-left">
											<Title title={t("Distributionchannel")} />
											{!values?.id && (
												<div className="dropdown-input">
													<DropDownInput
														label={t("Structure")}
														value={values?.selectStructureId}
														id="id"
														name="id"
														options={structures}
														onChange={(e, value) => {
															setFieldValue(
																"selectStructureId",
																value,
															);
															setSelectedStructure(
																value ? value.value : null,
															);
															setFieldValue("selectStufeId", null);
															setFieldValue("supervisor", null);
														}}
														onBlur={handleBlur}
														placeholder={t("Structure")}
														title="name"
														touched={touched.selectStructureId}
														errors={errors.selectStructureId}
														errorClass="errorClass"
														withTouched={false}
														noTouched={true}
														disabled={false}
													/>
												</div>
											)}
											{values?.selectStructureId?.value && (
												<div className="dropdown-input">
													<DropDownInput
														label={t("Stufe")}
														value={values?.selectStufeId}
														name="id"
														options={stufes}
														onChange={(e, value) => {
															setFieldValue("selectStufeId", value);
															setSelectedStufe(value?.value);
															setFieldValue("supervisor", null);
														}}
														onBlur={handleBlur}
														placeholder={t("Stufe")}
														title="name"
														touched={touched.selectStufeId}
														errors={errors.selectStufeId}
														errorClass="errorClass"
														withTouched={false}
														noTouched
														disabled={false}
													/>
												</div>
											)}
											{values?.selectStufeId?.value && (
												<div className="dropdown-input">
													<DropDownInput
														label={t("supervisor")}
														value={values?.supervisor}
														name="id"
														options={supervisors}
														onChange={(e, value) => {
															setFieldValue("supervisor", value);
														}}
														onBlur={handleBlur}
														placeholder={t("supervisor")}
														title="name"
														touched={touched.supervisor}
														errors={errors.supervisor}
														errorClass="errorClass"
														withTouched={false}
														noTouched
														disabled={false}
													/>
												</div>
											)}
										</div>
										<div className="create__user__form__ContGeneral-right" />
									</div>
								)}
								<Title
									title={t("Distributionchannel")}
									customClass="edit_users-class"
								/>
								<div className="create__user__form__ContGeneral">
									<div className="create__user__form__ContGeneral-left">
										<div className="create__user__form__ContGeneral-left-leftInputs">
											<div className="create__user__form__ContGeneral-left-leftInputs-up">
												<InputComponent
													name="extVpNr"
													label={t("Vp-Nummer")}
													type="text"
													errorClass="errorClass"
													values={values.extVpNr}
													handleBlur={handleBlur}
													handleChange={handleChange}
													touched={touched.extVpNr}
													errors={errors.extVpNr}
												/>
												{!loggedUser?.role?.name?.includes(AGENCY_ROLE) && (
													<div className="dropdown-input">
														<DropDownInput
															label={t("UserRoles")}
															value={values.userRoles}
															id="userRoles"
															name="value"
															onChange={(event, value) => {
																setFieldValue("userRoles", value);
																if (value?.value !== 7) {
																	setFieldValue("agency", "");
																}
																if (
																	value?.value === 6 ||
																	value?.value === 14
																) {
																	setFieldValue(
																		"isCompany",
																		"yes",
																	);
																} else {
																	setFieldValue(
																		"isCompany",
																		"no",
																	);
																}
															}}
															onBlur={handleBlur}
															options={createUserRoleCheck}
															placeholder={t("UserRoles")}
															title="name"
															touched={touched.userRoles}
															errors={errors.userRoles}
															errorClass="errorClass"
															withTouched={false}
															noTouched={true}
															disabled={userInitialValues.id}
														/>
													</div>
												)}
											</div>
											<div className="create__user__form__ContGeneral-left-leftInputs-down">
												{values?.isCompany === "yes" && (
													<div className="create_user_company_inputs">
														<InputComponent
															name="company"
															label={t("CompanyName")}
															type="text"
															errorClass="errorClass"
															values={values.company}
															handleBlur={handleBlur}
															className="form__input__company_name"
															handleChange={handleChange}
															touched={touched.company}
															errors={errors.company}
														/>
														<div className="dropdown-input">
															<DropDownInput
																label={t("CompanyTypes")}
																value={values?.companyType}
																id="companyType"
																name="value"
																options={companyType}
																onChange={(e, value) => {
																	setFieldValue(
																		"companyType",
																		value,
																	);
																}}
																onBlur={handleBlur}
																placeholder={t("CompanyTypes")}
																title="name"
																touched={touched.companyType}
																errors={errors.companyType}
																errorClass="errorClass"
																withTouched={false}
																noTouched={true}
																disabled={userInitialValues.id}
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className="create__user__form__ContGeneral-right">
										{values.userRoles?.value === 7 && (
											<div className="dropdown-input">
												<DropDownInput
													label={t("Agency")}
													value={values?.agency}
													id="agency"
													name="value"
													onChange={(event, value) => {
														setFieldValue("agency", value);
													}}
													options={agencyOptions}
													placeholder={t("Agency")}
													title="name"
													touched={touched.agency}
													errors={errors.agency}
													errorClass="errorClass"
													withTouched={false}
													noTouched={true}
													disableClearable={false}
													disabled={userInitialValues.id}
												/>
											</div>
										)}
										<div>
											{values?.isCompany === "yes" && (
												<>
													{(values?.userRoles?.value === 6 ||
														values?.userRoles?.value === 14) && (
														<div className="create_user_agency_settings">
															{values?.userRoles?.value === 6 && (
																<RadioGroupInput
																	disabled={false}
																	name="agencyType"
																	onChange={handleChange}
																	value={values?.agencyType}
																	title={t("AgencyWith")}
																	defaultValue={
																		values?.agencyType
																	}
																>
																	<div className="form-element-row">
																		<RadioInput
																			checked={
																				values.agencyType
																			}
																			label={t("Simple")}
																			value="SIMPLE"
																			disabled={
																				userInitialValues?.id
																			}
																		/>

																		<RadioInput
																			checked={
																				values.agencyType
																			}
																			label={t("Detailed")}
																			value="DETAILED"
																			disabled={
																				userInitialValues?.id
																			}
																		/>
																	</div>
																	<span className="errorClass">
																		{errors?.agencyType}
																	</span>
																</RadioGroupInput>
															)}
															<InputFile
																label={t("AgencyLogo")}
																name="logo"
																onChange={(e) => {
																	setFieldValue(
																		"logo",
																		e.target.files[0],
																	);
																}}
																labelClassName="form_input__label"
																className="create__user__input__file"
																errorClass="errorClass"
																values={values.logo}
																handleBlur={handleBlur}
																handleChange={handleChange}
																touched={touched.logo}
																errors={errors.logo}
																previewFile={
																	values?.logo?.name
																		? URL.createObjectURL(
																				values?.logo,
																		  )
																		: values?.logo
																}
																fileUploadedName={
																	// eslint-disable-next-line no-nested-ternary
																	values?.logo?.name
																		? values?.logo?.name
																		: values?.logo
																		? t("FileUploaded")
																		: null
																}
																borderedInput
																accept="image/*"
															/>
														</div>
													)}
												</>
											)}
										</div>
									</div>
								</div>
								<Title
									title={t("FinancialInformation")}
									customClass="edit_users-class"
								/>
								<div className="create__user__form__ContGeneral">
									<div className="create__user__form__ContGeneral-left">
										<div className="create__user__form-notShow">
											<div className="create__user__form-notShow-text">
												<div className="spantest">
													{t("BusinessLicense")}
												</div>
											</div>
										</div>
										<InputFile
											label={t("Proof")}
											name="businessLicenseUrl"
											onChange={(e) => {
												setFieldValue(
													"businessLicenseUrl",
													e.target.files[0],
												);
											}}
											labelClassName="form_input__label"
											className="create__user__input__file"
											errorClass="errorClass"
											values={values.businessLicenseUrl}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.businessLicenseUrl}
											errors={errors.businessLicenseUrl}
											fileUploadedName={
												// eslint-disable-next-line no-nested-ternary
												values?.businessLicenseUrl?.name
													? values?.businessLicenseUrl?.name
													: values?.businessLicenseUrl
													? t("FileUploaded")
													: null
											}
											borderedInput
											accept=".pdf"
										/>
										<InputComponent
											name="hrNumber"
											label={t("HrNumber")}
											type="text"
											errorClass="errorClass"
											values={values.hrNumber}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.hrNumber}
											errors={errors.hrNumber}
										/>
										<InputFile
											label={t("HrNumberAuszug")}
											name="hrNumberUrl"
											onChange={(e) => {
												setFieldValue("hrNumberUrl", e.target.files[0]);
											}}
											labelClassName="form_input__label"
											className="create__user__input__file"
											errorClass="errorClass"
											values={values.hrNumberUrl}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.hrNumberUrl}
											errors={errors.hrNumberUrl}
											fileUploadedName={
												// eslint-disable-next-line no-nested-ternary
												values?.hrNumberUrl?.name
													? values?.hrNumberUrl?.name
													: values?.hrNumberUrl
													? t("FileUploaded")
													: null
											}
											borderedInput
											accept=".pdf"
										/>
										<InputComponent
											name="idNumber"
											label={t("IDNumber")}
											type="text"
											errorClass="errorClass"
											values={values.idNumber}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.idNumber}
											errors={errors.idNumber}
										/>
										<InputFile
											label={t("PassportFront")}
											name="idNumberUrlFront"
											onChange={(e) => {
												setFieldValue(
													"idNumberUrlFront",
													e.target.files[0],
												);
											}}
											labelClassName="form_input__label"
											className="create__user__input__file"
											errorClass="errorClass"
											values={values.idNumberUrlFront}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.idNumberUrlFront}
											errors={errors.idNumberUrlFront}
											fileUploadedName={
												// eslint-disable-next-line no-nested-ternary
												values?.idNumberUrlFront?.name
													? values?.idNumberUrlFront?.name
													: values?.idNumberUrlFront
													? t("FileUploaded")
													: null
											}
											borderedInput
											accept=".pdf"
										/>
										<div className="create__user__form-notShow"></div>
										<InputFile
											label={t("PassportBack")}
											name="idNumberUrlBack"
											onChange={(e) => {
												setFieldValue("idNumberUrlBack", e.target.files[0]);
											}}
											labelClassName="form_input__label"
											className="create__user__input__file"
											errorClass="errorClass"
											values={values.idNumberUrlBack}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.idNumberUrlBack}
											errors={errors.idNumberUrlBack}
											fileUploadedName={
												// eslint-disable-next-line no-nested-ternary
												values?.idNumberUrlBack?.name
													? values?.idNumberUrlBack?.name
													: values?.idNumberUrlBack
													? t("FileUploaded")
													: null
											}
											borderedInput
											accept=".pdf"
										/>
										<InputComponent
											name="taxNumber"
											label={t("TaxNumber")}
											type="text"
											errorClass="errorClass"
											values={values.taxNumber}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.taxNumber}
											errors={errors.taxNumber}
										/>
										<InputFile
											onChange={(e) =>
												setFieldValue("taxNumberUrl", e.target.files[0])
											}
											label={t("Proof")}
											name="taxNumberUrl"
											labelClassName="form_input__label"
											className="create__user__input__file"
											values={values.taxNumberUrl}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.taxNumberUrl}
											errors={errors.taxNumberUrl}
											errorClass="errorClass"
											fileUploadedName={
												// eslint-disable-next-line no-nested-ternary
												values?.taxNumberUrl?.name
													? values?.taxNumberUrl?.name
													: values?.taxNumberUrl
													? t("FileUploaded")
													: null
											}
											borderedInput
											accept=".pdf"
										/>
										<RadioGroupInput
											disabled={false}
											name="vatEntitlement"
											onChange={handleChange}
											value={values.vatEntitlement}
											title={t("VATEntitlementQ")}
											defaultValue="no"
										>
											<div className="form-element-row">
												<RadioInput
													checked={values.vatEntitlement}
													label={t("Yes")}
													value="yes"
												/>
												<RadioInput
													checked={values.vatEntitlement}
													label={t("No")}
													value="no"
												/>
											</div>
										</RadioGroupInput>
										<InputFile
											onChange={(e) =>
												setFieldValue(
													"vatEntitlementUrl",
													e.target.files[0],
												)
											}
											label={t("Proof")}
											name="vatEntitlementUrl"
											labelClassName="form_input__label"
											className="create__user__input__file"
											errors={errors.vatEntitlementUrl}
											touched={touched.vatEntitlementUrl}
											errorClass="errorClass"
											fileUploadedName={
												// eslint-disable-next-line no-nested-ternary
												values?.vatEntitlementUrl?.name
													? values?.vatEntitlementUrl?.name
													: values?.vatEntitlementUrl
													? t("FileUploaded")
													: null
											}
											borderedInput
											accept=".pdf"
										/>
										<InputComponent
											name="vatNumber"
											label={t("VATNumberQ")}
											type="text"
											errorClass="errorClass"
											values={values.vatNumber}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.vatNumber}
											errors={errors.vatNumber}
										/>
										<InputFile
											onChange={(e) =>
												setFieldValue("vatNumberUrl", e.target.files[0])
											}
											label={t("Proof")}
											name="vatNumberUrl"
											labelClassName="form_input__label"
											className="create__user__input__file"
											errors={errors.vatNumberUrl}
											touched={touched.vatNumberUrl}
											errorClass="errorClass"
											fileUploadedName={
												// eslint-disable-next-line no-nested-ternary
												values?.vatNumberUrl?.name
													? values?.vatNumberUrl?.name
													: values?.vatNumberUrl
													? t("FileUploaded")
													: null
											}
											borderedInput
											accept=".pdf"
										/>
									</div>
									<div className="create__user__form__ContGeneral-right__down">
										<InputComponent
											name="iban"
											label={t("IBAN")}
											type="text"
											errorClass="errorClass"
											values={values.iban}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.iban}
											errors={errors.iban}
										/>
										<InputComponent
											name="bankName"
											label={t("BankName")}
											type="text"
											errorClass="errorClass"
											values={values.bankName}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.bankName}
											errors={errors.bankName}
										/>
										<InputComponent
											name="taxOffice"
											label={t("TaxOfficeQ")}
											type="text"
											errorClass="errorClass"
											values={values.taxOffice}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched.taxOffice}
											errors={errors.taxOffice}
										/>
									</div>
								</div>
								<div className="create__user__form__button">
									<ButtonComponent
										buttonText={t("Complete")}
										classNames="submit__user"
										loading={loading}
										disabled={loading}
									/>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	userInitialValues: state.app.users.createUser.userInitialValues,
	loading: state.app.users.createUser.loading,
	loggedUser: state.app.users.index.loggedUser,
	agencies: state.app.teams.index.agencies,
	structuresState: state.app.structures.index,
	supervisors: state.app.users.index.availableSupervisors,
});

const mapDispatchToProps = {
	fillUsers: userActions.fillUsers,
	addUser: userActions.addUser,
	getSingleUser: usersActions.getSingleUser,
	fetchAgencies: teamActions.fetchAgencies,
	goBack: navigation.goBack,
	getStructures: structuresActions.getStructures,
	getStufes: structuresActions.getStufesByStructureId,
	getSupervisors: usersActions.getAvailableSupervisors,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateUser));
