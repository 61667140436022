/* eslint-disable no-await-in-loop */
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import axios from "axios";
import {useState} from "react";

const types = ["checkbox", "price", "name"];

export default function useFieldGenerator() {
	const [loading, setLoading] = useState(false);

	const generateProductAndTariffFields = async ({
		type = "product",
		item,
		clientIdString,
		clientId,
		sectionId,
		handleClose,
	}) => {
		setLoading(true);
		try {
			for (let i = 0; i < types.length; i++) {
				const currentType = types[i];
				const upperCasedType = currentType.toUpperCase();
				const payload = {
					name: `${type}_${clientIdString}_${item?.id}_${currentType}`,
					labelEn: `${upperCasedType} ${item.name}`,
					labelDe: `${upperCasedType} ${item.name}`,
					inputType: type.toUpperCase(),
					clientId,
					showField: true,
					showAs: "VALUE",
				};
				const {data} = await axios.post("/client/create/custom_fields", payload);
				const createdFieldId = data?.data?.id;
				const orderFieldPayload = {
					customFieldId: createdFieldId,
					targetSectionId: sectionId,
					targetSortNumber: i + 1,
					[`${type}Id`]: item?.id,
					showAs: upperCasedType,
				};
				await axios.put("order_field/dnd", orderFieldPayload);
			}
		} catch (error) {
			ToastErrorComponent("Fields failed to generate");
		} finally {
			setLoading(false);
			handleClose();
		}
	};
	return {loading, generateProductAndTariffFields};
}
