import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useFormik} from "formik";
import DraftText from "@components/dashboard/Trainings/Add/DraftText";
import {useTranslation} from "react-i18next";
import Button from "@src/common/components/button";
import saveIcon from "@assets/img/save-icon.svg";
import {actions as userSettingsActions} from "../../../../store/sagas/app/userProfile/settings";
import { emailTemplatesElems } from "./helper";

const EmailSettings = (props) => {
	const {emailSettings, setEmailSettings, loading} = props;
	const {t} = useTranslation();
	const formik = useFormik({
		initialValues: emailSettings,
		enableReinitialize: true,
		onSubmit: (values) => setEmailSettings(values),
	});
	const {handleSubmit,values,setFieldValue} = formik;
	return (
				<form onSubmit={handleSubmit} className="userProfile-wrapper">
					{emailTemplatesElems?.map((item) => {
						return (
							<div className="userProfile-section" key={item.value}>
								<span className="title">{item?.label}</span>
								<div className="userProfile-section-content">
								<DraftText
									value={values?.[item?.value]}
									options={[
										"inline",
										"blockType",
										"fontSize",
										"fontFamily",
										"list",
										"textAlign",
										"colorPicker",
										"link",
										"embedded",
										"emoji",
										"remove",
										"history",
									]}
									onEditorStateChange={(editorState) => {
										setFieldValue(item?.value, editorState);
									}}
								/>
								</div>
							</div>
						)
					})}
						<Button
							icon={saveIcon}
							type="submit"
							customClassName="saveButton"
							disabled={loading}
							leftRounded
							text={t("Save")}
							smallButtonWithTooltip
						/>
				</form>
	);
};

const mapStateToProps = (state) => ({
	emailSettings: state.app.userProfile.settings.emailSettings,
});

const mapDispatchToProps = {
	setEmailSettings: userSettingsActions.setEmailSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailSettings));
