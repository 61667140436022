import {t} from "i18next";
import React from "react";

const DeletedMessage = ({myMessage, time, name}) => (
	<div className={`single__message__container ${myMessage ? "myMessage" : ""}`}>
		<div
			className={`single__message__container-threeDotsAndContainer ${
				myMessage ? "myMessage" : ""
			}`}
		>
			<div
				className={`single__message__container-box-wrapper ${myMessage ? "myMessage" : ""}`}
			>
				<div>
					<div className="single__message__image__container">
						<div className="single__message__image">{name?.charAt(0)}</div>
					</div>
				</div>
				<div className={`singleMessageBox ${myMessage ? "myMessage" : ""}`}>
					<div
						className={`name__message__time__container ${myMessage ? "myMessage" : ""}`}
					>
						<div className="name__message__time__container">
							<div className="name__and__time">
								<span className="message__sender__name">
									{myMessage ? t("you") : name}
								</span>
							</div>
						</div>
					</div>

					<div
						className={`deleted__message__wrapper-inner ${
							myMessage ? "myMessage" : ""
						}`}
					>
						<p className="deleted__message__wrapper-inner-text">
							{myMessage ? t("youDeletedMsg") : t("otherDeleteMsg")}
						</p>
					</div>

					<div className="message__content">
						<p className={`message__text ${myMessage ? "myMessage" : ""}`}></p>
					</div>
					<span className={`message__sent__time ${myMessage ? "myMessage" : ""}`}>
						{time}
					</span>
				</div>
			</div>
		</div>
	</div>
);

export default DeletedMessage;
