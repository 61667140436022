/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "../../../../../utils/action-creator";
import axios from "../../../../../utils/axios";
import Logger from "../../../../../utils/logger";

const logger = new Logger("Templates");

const PREFIX = "@app/admin/templates";
export const FETCH = `${PREFIX}FETCH`;
export const FETCH_SUCCESS = `${PREFIX}FETCH_SUCCESS`;
export const FETCH_ONE = `${PREFIX}FETCH_ONE`;
export const FETCH_ONE_SUCCESS = `${PREFIX}FETCH_ONE_SUCCESS`;
export const ADD_ON_LIST = `${PREFIX}ADD_ON_LIST`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const DOWNLOAD_TEMPLATE = `${PREFIX}DOWNLOAD_TEMPLATE`;

const _state = {
	data: [],
	oneData: [],
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case FETCH_SUCCESS:
				draft.data = action.payload;
				break;
			case ADD_ON_LIST:
				draft.data = [...state.data, action.payload];
				break;
			case FETCH_ONE_SUCCESS:
				draft.oneData = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	fetch: (payload) => createAction(FETCH, {payload}),
	fetchSuccess: (payload) => createAction(FETCH_SUCCESS, {payload}),
	fetchOne: (payload) => createAction(FETCH_ONE, {payload}),
	fetchOneSuccess: (payload) => createAction(FETCH_ONE_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addOnList: (payload) => createAction(ADD_ON_LIST, {payload}),
	downloadTemplate: (payload) => createAction(DOWNLOAD_TEMPLATE, {payload}),
};

export const sagas = {
	*fetch() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get("/templates");
			yield put(actions.fetchSuccess(response.data));
		} catch (error) {
			logger.error("templates error", error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchOne({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/template/${payload}`);
			yield put(actions.fetchOneSuccess(response.data.data));
		} catch (error) {
			logger.error("template one error", error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*downloadTemplate({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {name, id} = payload;
			const response = yield axios.get(`/template-file/${id}`);
			const blob = new Blob([response.data], {type: response.headers["content-type"]});
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			link.download = name;
			link.click();
		} catch (error) {
			logger.error("template one error", error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH, sagas.fetch);
	yield takeLatest(FETCH_ONE, sagas.fetchOne);
	yield takeLatest(DOWNLOAD_TEMPLATE, sagas.downloadTemplate);
};
