export const formatOptionsForSelect = (
	array,
	id = "id",
	name = "name",
	group = false,
	groupField = "members",
	type,
	objType,
) => {
	const options = array?.map((item) => {
		if (group) {
			return {
				value: item?.[id],
				label: item?.[name],
				checked: Boolean(item?.checked),
				options: item?.[groupField]?.map((itm) => {
					if (objType === "obj") {
						return {
							...itm,
							value: itm?.[id],
							label: itm?.[name],
							type,
						};
					}
					return {
						value: itm?.[id],
						label: itm?.[name],
						type,
					};
				}),
			};
		}
		if (objType === "obj") {
			return {
				...item,
				value: item?.[id],
				label: item?.[name],
			};
		}
		return {
			...item,
			value: item?.[id],
			label: item?.[name],
		};
	});
	return options;
};

export const groupOptionsForSelect = (array, type, label, objType) => {
	const formattedArray = formatOptionsForSelect(
		array,
		"id",
		"name",
		type !== "USERS",
		"members",
		type,
		objType,
	);
	return [
		{
			label,
			type,
			options: formattedArray,
		},
	];
};
