import UploadButtonComponent from "@src/common/components/uploadFiles/uploadButtonComponent";
import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as uploadFilesRequest} from "@sagas/trainings/upload";
import MappedFiles from "./MappedFiles";

const UploadComponentView = (props) => {
	const {uploadedFiles, setUploadedFiles, match} = props;
	const [copied, setCopied] = useState({});

	const handleFileUpload = async (files) => {
		const base64Files = await Promise.all(
			Array.from(files).map(async (file) => {
				const base64String = await fileToBase64(file);
				return {
					fileName: file.name,
					size: file.size,
					link: `data:${file.type};base64,${base64String}`,
				};
			}),
		);
		const fileObj = {
			templateId: match?.params?.id,
			files: base64Files,
		};
		setUploadedFiles(fileObj);
	};

	const fileToBase64 = (blob) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const base64String = reader.result.split(",")[1];
				resolve(base64String);
			};
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	return (
		<>
			<div className="uploadcomponent__container">
				<UploadButtonComponent margin={15} onFileUpload={handleFileUpload} />
				<MappedFiles files={uploadedFiles} copied={copied} setCopied={setCopied} />
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	uploadedFiles: state.app.trainings.upload.uploadedFiles,
});

const mapDispatchToProps = {
	setUploadedFiles: uploadFilesRequest.uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadComponentView));
