/* eslint-disable max-len */
import DragAndDrop from "@components/dragAndDrop";
import {actions as navigationActions} from "@sagas/navigation";
import React, {useMemo, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import Button from "@src/common/components/button";
import settingsIcon from "@assets/img/settings-icon.svg";
import backLogo from "@assets/img/back__icon.svg";
import horizontalLine from "@assets/img/horizontal__line.svg";
import verticalLine from "@assets/img/vertical__line.svg";
import PageList from "@src/common/PageList";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {useUrlParams} from "@src/utils/useUrlParams";
import AssignModal from "./createPdfComponents/AssignModal";
import EditCustomField from "./createOrder/components/EditCustomField";
import useDragAndDrop from "./useDragAndDrop";

function CreatePdf({match, navigate, loading, editedFields}) {
	const {t} = useTranslation();
	const {
		draggableItems,
		droppableItems,
		pdfData,
		handleDeletePdfField,
		handleShowDeleteModal,
		confirmModal,
		handleMoveBox,
		closeConfirmModal,
		updatePdfFieldOnState,
		orderFields,
	} = useDragAndDrop();
	const {setModal, getUrlParams} = useUrlParams();
	const {modal} = getUrlParams("modal");
	const [searchValue, setSearchValue] = useState("");
	const {clientStringId} = match?.params;
	const clientId = +match?.params?.clientId;
	const [lines, setLines] = useState([]);
	const handleSearch = ({target}) => setSearchValue(target.value);
	const filteredItems = useMemo(() => {
		if (searchValue) {
			const lowerCasedSearch = searchValue?.toLowerCase();
			return draggableItems?.filter(
				(item) =>
					item?.labelEn?.toLowerCase().includes(lowerCasedSearch) ||
					item?.name?.toLowerCase()?.includes(lowerCasedSearch),
			);
		}
		return draggableItems;
	}, [searchValue, draggableItems]);

	const moveBox = (item, sendRequest = true) => {
		if (sendRequest) {
			handleMoveBox(item);
		} else {
			updatePdfFieldOnState(item);
		}
	};
	const handleBack = () => navigate(`/admin/clients/${clientId}/${clientStringId}?tab=uploadPdf`);
	const pageList = [
		{
			name: t("ClientsList"),
			fn: () => navigate(`/admin/clients/${clientId}/${clientStringId}`),
		},
		{
			name: t("Upload PDF"),
			fn: () => handleBack,
		},
		{
			name: `${pdfData?.fileName}.pdf`,
			fn: () => null,
		},
	];

	const addDraggableLine = (yPosition, type) => {
		setLines([...lines, {type, position: yPosition}]);
	};

	const handleLinesDrop = (item, x, y) => {
		const newLine = {
			type: item.type,
			position: item.type === "vertical" ? x : y,
		};
		setLines((lines) => lines.map((line, index) => (index === item.index ? newLine : line)));
	};
	return (
		<>
			<DeleteModal
				open={confirmModal?.open}
				disabled={confirmModal?.loading}
				loading={confirmModal?.loading}
				handleClose={closeConfirmModal}
				setShowDeleteModal={closeConfirmModal}
				confirm={handleDeletePdfField}
				calculationModal
				newTitle="Are you sure you want to delete this field?"
			/>
			<EditCustomField />
			<AssignModal
				showModal={modal === "settings"}
				setShowModal={setModal}
				editedFields={editedFields}
			/>
			<div className="dashboard__container">
				<div className="header-component">
					<div className="dashboard__left__text-header">
						<div className="create-pdf__header-path">
							<div className="create-pdf__back">
								<img src={backLogo} alt="" onClick={handleBack} />
							</div>
							<PageList list={pageList} />
						</div>
					</div>
					<div className="dashboard__left__text-actions">
						<Button
							text={t("pdfSettings")}
							handleChange={() => setModal("settings")}
							smallButtonWithTooltip
							icon={settingsIcon}
						/>
						<Button
							text={t("insertHorizontalLine")}
							smallButtonWithTooltip
							handleChange={() => addDraggableLine(50, "horizontal")}
							disabled={loading}
							icon={horizontalLine}
						/>
						<Button
							text={t("insertVerticalLine")}
							smallButtonWithTooltip
							handleChange={() => addDraggableLine(50, "vertical")}
							disabled={loading}
							icon={verticalLine}
						/>
					</div>
				</div>
				<div style={{width: "100%"}}>
					<DndProvider backend={HTML5Backend}>
						<DragAndDrop
							draggableItems={filteredItems}
							file={pdfData}
							droppableItems={droppableItems}
							moveBox={moveBox}
							handleLinesDrop={handleLinesDrop}
							lines={lines}
							setLines={setLines}
							onSearch={handleSearch}
							searchValue={searchValue}
							handleShowDeleteModal={handleShowDeleteModal}
							orderFields={orderFields}
						/>
					</DndProvider>
				</div>
			</div>
		</>
	);
}
const mapStateToProps = (state) => ({
	editedFields: state.app.client.index.editedFields,
	loading: state.app.client.index.isUploading,
});
const mapDispatchToProps = {
	navigate: navigationActions.navigate,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreatePdf));
