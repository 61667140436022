/* eslint-disable no-nested-ternary */
import i18n from "@src/i18n";
import * as yup from "yup";
import {autoFillInputs, inputTypes} from "../staticData";

export const sectionValidationSchema = yup.object().shape({
	nameEn: yup.string().required(i18n.t("NameIsReqired")),
	nameDe: yup.string().required(i18n.t("NameDeReq")),
});

export const showAsOptions = [
	{value: "NAME", label: "Name"},
	{value: "PRICE", label: i18n.t("Price")},
	{value: "CHECKBOX", label: i18n.t("checkbox")},
	{value: "VALUE", label: i18n.t("Value")},
	{value: "TOTAL", label: i18n.t("Total")},
];
export const requestTypes = [
	{value: "IBAN", label: "Iban"},
	{value: "POSTCODE", label: i18n.t("postCode")},
	{value: "LOCATION", label: i18n.t("location")},
	{value: "STREET", label: i18n.t("street")},
];
export const updateArrayItems = (array, newOrderSortNr, newExportSortNr, currentItem) => {
	const newArr = [];
	const isIncreasingOrderNr = currentItem.orderSortNr < newOrderSortNr;
	const isIncreasingExportNr = currentItem.exportSortNr < newExportSortNr;
	array.forEach((item) => {
		if (item.parentId === currentItem?.parentId) {
			if (item.id === currentItem.id) {
				newArr.push({
					...item,
					orderSortNr: newOrderSortNr,
					exportSortNr: newExportSortNr,
				});
			} else if (!item.orderSortNr && !item.exportSortNr) {
				newArr.push(item);
			} else {
				newArr.push({
					...item,
					orderSortNr:
						isIncreasingOrderNr && newOrderSortNr >= item.orderSortNr
							? item.orderSortNr - 1
							: !isIncreasingOrderNr && newOrderSortNr !== currentItem.orderSortNr
							? item.orderSortNr + 1
							: item.orderSortNr,
					exportSortNr:
						isIncreasingExportNr && newExportSortNr >= item.exportSortNr
							? item.exportSortNr - 1
							: !isIncreasingExportNr && newExportSortNr !== currentItem.exportSortNr
							? item.exportSortNr + 1
							: item.exportSortNr,
				});
			}
		} else {
			newArr.push(item);
		}
	});
	return newArr;
};

export const updateArrayItems1 = (array, newOrderSortNr, newExportSortNr, currentItem) =>
	array.map((item) => {
		if (item.id === currentItem.id) {
			// Update the current item with new order and export sort numbers
			return {
				...item,
				orderSortNr: newOrderSortNr,
				exportSortNr: newExportSortNr,
			};
		}

		if (!item.orderSortNr && !item.exportSortNr) {
			// If item has no order or export sort number, return it unchanged
			return item;
		}

		// Determine if order and export sort numbers should be adjusted
		const shouldDecreaseOrder =
			currentItem.orderSortNr < newOrderSortNr && newOrderSortNr >= item.orderSortNr;
		const shouldIncreaseOrder =
			currentItem.orderSortNr > newOrderSortNr && newOrderSortNr !== currentItem.orderSortNr;

		const shouldDecreaseExport =
			currentItem.exportSortNr < newExportSortNr && newExportSortNr >= item.exportSortNr;
		const shouldIncreaseExport =
			currentItem.exportSortNr > newExportSortNr &&
			newExportSortNr !== currentItem.exportSortNr;

		// Return item with adjusted order and export sort numbers
		return {
			...item,
			orderSortNr: shouldDecreaseOrder
				? item.orderSortNr - 1
				: shouldIncreaseOrder
				? item.orderSortNr + 1
				: item.orderSortNr,
			exportSortNr: shouldDecreaseExport
				? item.exportSortNr - 1
				: shouldIncreaseExport
				? item.exportSortNr + 1
				: item.exportSortNr,
		};
	});

export const TEXT_INPUTS = ["TEXT", "NUMBER", "BIC", "BANK_NAME", "EMAIL"];
export const DATE_INPUTS = ["BIRTHDAY", "DATE"];

export const dateTypes = [
	{value: "DAILY", label: i18n.t("daily")},
	{value: "WEEKLY", label: i18n.t("weekly")},
	{value: "MONTHLY", label: i18n.t("monthly")},
	{value: "YEARLY", label: i18n.t("yearly")},
];

const NON_PRODUCT_TYPES = inputTypes
	?.filter((it) => it.value !== "TARIFF" && it.value !== "PRODUCT")
	?.map((it) => it.value);
const showOnlyOnNonProducts = {
	dependentFrom: "inputType",
	showOnly: NON_PRODUCT_TYPES,
};

export const orderFieldInputs = [
	{
		inputType: "text",
		required: true,
		field: "labelEn",
		title: i18n.t("fieldLabelEn"),
		additionalInfo: i18n.t("fieldLabelEnInfo"),
		dependentField: "objectType",
		disableWhen: "ORDER,CREATE_ORDER_DETAILS",
		prioritizeValidation: true,
	},
	{
		inputType: "text",
		required: true,
		field: "labelDe",
		title: i18n.t("fieldLabelDe"),
		additionalInfo: i18n.t("fieldLabelDeInfo"),
		dependentField: "objectType",
		disableWhen: "ORDER,CREATE_ORDER_DETAILS",
		prioritizeValidation: true,
	},
	{
		inputType: "number",
		required: true,
		field: "exportSortNr",
		title: i18n.t("exportSortNumber"),
		additionalInfo: i18n.t("exportSortNumberInfo"),
		...showOnlyOnNonProducts,
	},
	// {
	// 	inputType: "number",
	// 	required: true,
	// 	field: "orderSortNr",
	// 	title: i18n.t("orderSortNumber"),
	// 	additionalInfo: i18n.t("orderSortNumberInfo"),
	// 	...showOnlyOnNonProducts,
	// },
	{
		inputType: "text",
		field: "customOptionsString",
		title: i18n.t("fieldOptions"),
		dependentFrom: "inputType",
		showOnly: "DROPDOWN",
		additionalInfo: i18n.t("fieldOptionsInfo"),
	},
	{
		inputType: "dropdown",
		required: true,
		field: "showAs",
		options: showAsOptions,
		title: i18n.t("showAs"),
		defaultValue: "VALUE",
		additionalInfo: i18n.t("showAsInfo"),
		dependentFrom: "inputType",
		showOnly: "TARIFF,PRODUCT",
	},
	{
		inputType: "dropdown",
		field: "requestType",
		options: requestTypes,
		title: i18n.t("requestType"),
		additionalInfo: i18n.t("requestTypeInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "dropdown",
		field: "calculationFields",
		options: [],
		multiple: true,
		dependentFrom: "showAs",
		showOnly: "TOTAL",
		title: i18n.t("assignProductsToCalc"),
		additionalInfo: i18n.t("assignProductsToCalcInfo"),
	},
	{
		inputType: "switch",
		field: "includeTariff",
		title: i18n.t("includeTariffInCalculation"),
		dependentFrom: "showAs",
		showOnly: "TOTAL",
		additionalInfo: i18n.t("includeTariffInCalculationInfo"),
	},
	{
		inputType: "dropdown",
		field: "productId",
		title: i18n.t("AssignProduct"),
		dependentFrom: "inputType",
		showOnly: "PRODUCT",
		options: [],
		additionalInfo: i18n.t("AssignProductInfo"),
	},
	{
		inputType: "dropdown",
		field: "tariffId",
		title: i18n.t("assignTariff"),
		dependentFrom: "inputType",
		showOnly: "TARIFF",
		options: [],
		additionalInfo: i18n.t("assignTariffInfo"),
	},
	{
		inputType: "dropdown",
		field: "autoFillInput",
		title: i18n.t("autoFillInput"),
		options: autoFillInputs,
		additionalInfo: i18n.t("autoFillInputInfo"),
		dependentFrom: "inputType",
		showOnly: TEXT_INPUTS,
		dependentFields: ["assignInputTo"],
		clearedBy: "assignInputTo",
	},
	{
		inputType: "dropdown",
		field: "assignInputTo",
		title: i18n.t("assignInputTo"),
		options: [],
		additionalInfo: i18n.t("assignInputToInfo"),
		...showOnlyOnNonProducts,
		dependentFields: ["autoFillInput"],
		clearedBy: "autoFillInput",
	},
	{
		inputType: "text",
		field: "defaultValue",
		title: i18n.t("defaultValue"),
		additionalInfo: i18n.t("defaultValueInfo"),
		dependentFrom: "inputType",
		showOnly: TEXT_INPUTS,
	},
	{
		inputType: "switch",
		field: "isDisabled",
		title: i18n.t("isDisabled"),
		additionalInfo: i18n.t("isDisabledInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "switch",
		field: "requiredOnOfferEmail",
		title: i18n.t("isRequiredOnOfferEmail"),
		additionalInfo: i18n.t("isRequiredOnOfferEmailInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "switch",
		field: "isRequired",
		title: i18n.t("isAReqField"),
		additionalInfo: i18n.t("isAReqFieldInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "switch",
		field: "isMultiple",
		title: i18n.t("isMultiple"),
		dependentFrom: "inputType",
		showOnly: "DROPDOWN",
		additionalInfo: i18n.t("isMultipleInfo"),
	},
	{
		inputType: "switch",
		field: "displayOnlySelectedTariff",
		title: i18n.t("displayOnlySelectedTariff"),
		dependentFrom: "inputType",
		showOnly: "TARIFF",
		additionalInfo: i18n.t("displayOnlySelectedTariffInfo"),
	},
	{
		inputType: "number",
		field: "minCharacters",
		title: i18n.t("minCharacters"),
		additionalInfo: i18n.t("minCharactersInfo"),
		dependentFrom: "inputType",
		showOnly: TEXT_INPUTS,
	},
	{
		inputType: "number",
		field: "maxCharacters",
		title: i18n.t("maxCharacters"),
		dependentField: "minCharacters",
		additionalInfo: i18n.t("maxCharactersInfo"),
		dependentFrom: "inputType",
		showOnly: TEXT_INPUTS,
	},
	{
		inputType: "dropdown",
		field: "dateType",
		title: i18n.t("dateType"),
		options: dateTypes,
		dependentFrom: "inputType",
		showOnly: DATE_INPUTS,
		additionalInfo: i18n.t("dateTypeInfo"),
	},
	{
		inputType: "number",
		field: "minDate",
		title: i18n.t("minDate"),
		additionalInfo: i18n.t("minDateInfo"),
		dependentFrom: "inputType",
		showOnly: DATE_INPUTS,
	},
	{
		inputType: "number",
		field: "maxDate",
		title: i18n.t("maxDate"),
		additionalInfo: i18n.t("maxDateInfo"),
		dependentFrom: "inputType",
		showOnly: "DATE",
	},
	{
		inputType: "dropdown",
		field: "dependencyFields",
		title: i18n.t("dependencyFields"),
		multiple: true,
		options: [],
		additionalInfo: i18n.t("dependencyFieldsInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "switch",
		field: "hideWeb",
		title: i18n.t("hideWeb"),
		additionalInfo: i18n.t("hideWebInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "switch",
		field: "hideMobile",
		title: i18n.t("hideMobile"),
		additionalInfo: i18n.t("hideMobileInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "switch",
		field: "showOnOrderSummary",
		title: i18n.t("showOnOrderSummary"),
		additionalInfo: i18n.t("showOnOrderSummaryInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "switch",
		field: "hideOnPrivateTariffs",
		title: i18n.t("hideOnPrivateTariffs"),
		additionalInfo: i18n.t("hideOnPrivateTariffsInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "textarea",
		field: "fixedNoteEn",
		title: i18n.t("fixedNoteEn"),
		additionalInfo: i18n.t("fixedNoteEnInfo"),
		...showOnlyOnNonProducts,
	},
	{
		inputType: "textarea",
		field: "fixedNoteDe",
		title: i18n.t("fixedNoteDe"),
		additionalInfo: i18n.t("fixedNoteDeInfo"),
		...showOnlyOnNonProducts,
	},
];

export const sectionInputs = [
	{
		inputType: "text",
		required: true,
		field: "nameEn",
		title: i18n.t("sectionNameEn"),
	},
	{
		inputType: "text",
		required: true,
		field: "nameDe",
		title: i18n.t("sectionNameDe"),
	},
	{
		inputType: "dropdown",
		field: "pdfId",
		options: [],
		title: i18n.t("linkPdfToSection"),
	},
	{
		inputType: "switch",
		field: "collapse",
		title: i18n.t("collapse"),
	},
	{
		inputType: "switch",
		field: "showField",
		defaultValue: true,
		title: i18n.t("showSection"),
	},
];
