import React, {useEffect, useState} from "react";
import DataGridTable from "@src/common/table/DataGridTable";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as orderActions} from "@sagas/orders";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import Modal from "@common/modal";
import AddPrice from "@src/components/dashboard/orders/AddPrice";
import {actions as createOrderActions} from "@sagas/orders/create";
import EditAndCreateOrders from "@src/components/dashboard/orders/EditAndCreateOrders";
import CreateEditOrder from "@src/components/dashboard/orders/createEditOrder";
import {actions as userActions} from "@sagas/users/users";
import {actionsFormatter, columns} from "./helper";

const BottomTableStatistik = ({
	size,
	loading,
	page,
	totalPages,
	editSize,
	editPage,
	statistikDataUser,
	toggleEditOrderModal,
	getOrderPreview,
	downloadOrderById,
	showEditModal,
	showModal,
	setShowModal,
	totalSize,
	cleanQuery,
	showTimeline,
}) => {
	const {t} = useTranslation();
	const [selectedPds, setSelectedPds] = useState([]);
	const [previewRow, setPreviewRow] = useState(false);
	const [showPriceModal, setShowPriceModal] = useState(false);
	const [priceRow, setCurrentPriceRow] = useState(null);
	const [reEditPage, setReEditPage] = useState(true); // State for re-editing page
	const handlePreviewEdit = (id, type) => {
		if (type === "preview") {
			setPreviewRow(true);
		} else {
			setPreviewRow(false);
		}
		toggleEditOrderModal(true);
		getOrderPreview(id);
	};
	const handlePrice = (id) => {
		setShowPriceModal(true);
		setCurrentPriceRow(id);
	};
	const downloadCsv = async (data, type) => {
		if (type === "single") {
			if (data.exportPdf && data.fileNames?.[0]) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "multiple") {
			const filteredData = statistikDataUser?.filter(
				(item) => item?.exportPdf && data?.includes(item.id) && item?.fileNames?.[0],
			);
			if (filteredData.length > 0) {
				downloadOrderById({filteredData, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "homeNetMultiplePdf") {
			if (data.exportPdf && data.fileNames && data.fileNames.length > 0) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}

			setSelectedPds([]);
		}
	};
	const actions = {
		key: "actions",
		name: t("Actions"),
		formatter: ({row}) =>
			actionsFormatter(row, handlePreviewEdit, handlePrice, downloadCsv, setSelectedPds),
		sortable: false,
		width: 50,
	};
	useEffect(
		() => () => {
			cleanQuery();
		},
		[],
	);
	return (
		<>
			<Modal
				className="add_edit_user"
				open={showPriceModal}
				handleClose={() => setShowPriceModal(false)}
			>
				<AddPrice setShowPriceModal={setShowPriceModal} currentRow={priceRow} />
			</Modal>
			<Modal
				className="add_edit_user"
				open={showModal}
				handleClose={() => setShowModal(false)}
			>
				<EditAndCreateOrders setShowModal={setShowModal} />
			</Modal>
			<Modal
				className="add_edit_user preview"
				open={showEditModal}
				handleClose={() => toggleEditOrderModal(false)}
				width={showTimeline ? 1000 : 600}
				positionModal="right"
				overflow="hidden"
				border={previewRow && "none"}
				padding={previewRow && 0}
				display={previewRow && "flex"}
			>
				<CreateEditOrder setShowEditModal={toggleEditOrderModal} previewMode={previewRow} />
			</Modal>
			<div className="loader__and__table-orderspage">
				<DataGridTable
					columns={[...columns, actions]?.map((col) => ({
						...col,
						resizable: col.key !== "actions",
						headerCellClass: "headerStyles",
					}))}
					data={statistikDataUser || []}
					rows={statistikDataUser || []}
					enableCellSelect={false}
					loading={loading}
					size={size}
					page={page}
					totalPages={totalPages}
					totalSize={totalSize}
					pagination
					setReEditPage={setReEditPage}
					reEditPage={reEditPage}
					editSize={editSize}
					editPage={editPage}
					showEditModal={false}
					previewRow={false}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.users.index.loading,
	page: state.app.users.index.page,
	size: state.app.users.index.size,
	showModal: state.app.orders.create.showModal,
	showEditModal: state.app.orders.edit.showModal,
	statistikDataUser: state.app.users.index.statistikDataUser,
	totalPages: state.app.users.index.totalPages,
	totalSize: state.app.users.index.totalSize,
	showTimeline: state.app.orders.edit.showTimeline,
});

const mapDispatchToProps = {
	getOrderPreview: orderActions.getOrderPreview,
	downloadOrderById: orderActions.downloadOrderById,
	toggleEditOrderModal: editOrderActions.toggleModal,
	setShowModal: createOrderActions.setShowModal,
	editSize: userActions.editSize,
	editPage: userActions.editPage,
	cleanQuery: userActions.cleanQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BottomTableStatistik));
