import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Bonus client");

const PREFIX = "@app/store/sagas/app/bonus/ClientBonus";

// clients
export const FETCH_CLIENT_BONUS = `${PREFIX}FETCH_CLIENT_BONUS`;
export const FETCH_CLIENT_BONUS_SUCCESS = `${PREFIX}FETCH_CLIENT_BONUS_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const ADD_BONUS_ON_STATE = `${PREFIX}ADD_BONUS_ON_STATE`;
export const EDIT_BONUS_ON_STATE = `${PREFIX}EDIT_BONUS_ON_STATE`;
export const DELETE_BONUS_ON_STATE = `${PREFIX}DELETE_BONUS_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const CLIENT_BONUS_ADD_TOTAL_SIZE_INCREASE = `${PREFIX}CLIENT_BONUS_ADD_TOTAL_SIZE_INCREASE`;
export const CLIENT_BONUS_ADD_TOTAL_SIZE_DECREASE = `${PREFIX}CLIENT_BONUS_ADD_TOTAL_SIZE_DECREASE`;

const _state = {
	clientBonuses: [],
	isLoading: false,
	page: 1,
	size: 10,
	totalSize: 5,
	search: "",
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_CLIENT_BONUS_SUCCESS:
				draft.clientBonuses = payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload + 1;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case LOADING:
				draft.loading = payload;
				break;
			case ADD_BONUS_ON_STATE:
				draft.clientBonuses = [payload].concat(state.clientBonuses);
				break;
			case EDIT_BONUS_ON_STATE:
				const index = state.clientBonuses.findIndex((c) => c.id === payload.id);
				draft.clientBonuses[index] = payload;

				break;
			case DELETE_BONUS_ON_STATE:
				draft.clientBonuses = state.clientBonuses.filter((bonus) => bonus.id !== payload);
				break;
			case CLIENT_BONUS_ADD_TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case CLIENT_BONUS_ADD_TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchClientBonuses: (payload) => createAction(FETCH_CLIENT_BONUS, {payload}),
	fetchClientBonusesSuccess: (payload) => createAction(FETCH_CLIENT_BONUS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	addBonusOnState: (payload) => createAction(ADD_BONUS_ON_STATE, {payload}),
	editBonusOnState: (payload) => createAction(EDIT_BONUS_ON_STATE, {payload}),
	deleteBonusOnState: (payload) => createAction(DELETE_BONUS_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterBonuses: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	clientBonusAddIncreaseTotalSize: (payload) =>
		createAction(CLIENT_BONUS_ADD_TOTAL_SIZE_INCREASE, {payload}),
	clientBonusAddDecreaseTotalSize: (payload) =>
		createAction(CLIENT_BONUS_ADD_TOTAL_SIZE_DECREASE, {payload}),
};

export const sagas = {
	*fetchClientBonuses() {
		yield put(actions.setLoading(true));
		try {
			const {size} = yield select((state) => state.app.bonuses.clientBonus);
			const {page} = yield select((state) => state.app.bonuses.clientBonus);
			const {search} = yield select((state) => state.app.bonuses.clientBonus);

			const response = yield axios.get(
				`/bonuses/all/?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchClientBonusesSuccess(response?.data?.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_CLIENT_BONUS, sagas.fetchClientBonuses);
};
