import {CheckedIcon, FilterIcon} from "@src/assets/SvgComponents";
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import CustomPopover from "@src/common/Popover";
import React, {useMemo, useState} from "react";
import {getLabel} from "../multiplecontainers/helper";

const SectionsWrapper = ({sections, toggleSectionVisibility}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const filteredSections = useMemo(() => {
		if (searchQuery) {
			return sections.filter((sect) =>
				sect.nameDe?.toLowerCase().includes(searchQuery.toLowerCase()),
			);
		}
		return sections;
	}, [searchQuery, sections]);
	return (
		<>
			<div className="filters__popover">
				<div className="filters__popover-header">
					<BigSearchBar
						handleSearch={(e) => setSearchQuery(e.target.value)}
						value={searchQuery}
						widthSearchBar="318px"
					/>
				</div>
				<div className="filters-container">
					{filteredSections?.[0] &&
						filteredSections?.map((item) => (
							<div
								key={item.id}
								className="filters-container-wrapper"
								onClick={() => toggleSectionVisibility(item.id)}
							>
								<div
									className="filters-container-wrapper-icons"
									style={{
										border: item.show
											? "1px solid #6C1EB0"
											: "1px solid #C1C1C1",
									}}
								>
									{item.show && (
										<CheckedIcon
											width="10.8"
											height="10.8"
											fill={item.show ? "#C1C1C1" : "#6C1EB0"}
										/>
									)}
								</div>
								<span className="filters-container-wrapper-name">
									{getLabel(item, "name")}
								</span>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

const SectionToggler = ({sections, toggleSectionVisibility}) => {
	const [showPopover, setShowPopover] = useState(false);
	const items = [
		{
			key: "visibility",
			label: (
				<SectionsWrapper
					sections={sections}
					toggleSectionVisibility={toggleSectionVisibility}
				/>
			),
		},
	];

	return (
		<CustomPopover
			customClass="custom-popover-filters"
			placement="bottomLeft"
			menu={{items}}
			trigger={["click"]}
			open={showPopover}
			handleVisibility={setShowPopover}
		>
			<span className="filters">
				<FilterIcon />
			</span>
		</CustomPopover>
	);
};

export default SectionToggler;
