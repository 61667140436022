/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from "react";
import {BackIcon, ImportsIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {actions as createOrderActions} from "@sagas/orders/create";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation";
import Button from "@src/common/components/button";
import plusLogo from "@assets/img/plusIconBackground.svg";
import moment from "moment";
import {Avatar, Tooltip} from "antd";
import {actions as importActions} from "@sagas/imports/create";
import {useUrlParams} from "@src/utils/useUrlParams";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
function ImportsHeader({
	path,
	navigate,
	setShowModal,
	final,
	custom,
	setShowCustomModal,
	lastModified,
	importsData,
	importActiveUsers,
	updateStatusImport,
	ordersLoading,
}) {
	const {t} = useTranslation();
	const {getUrlParams} = useUrlParams();
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	useEffect(() => {
		const itemToDisableButton = importsData?.find((item) => !item?.same);
		setIsButtonDisabled(!!itemToDisableButton);
	}, [importsData]);
	return (
		<div className="header-component">
			<div className="dashboard__left__text-header">
				{path ? (
					<div className="header-component-wrapper">
						<div className="back" onClick={() => navigate("/admin/imports")}>
							<BackIcon />
						</div>
						{path && <div className="header-path">{path}</div>}
					</div>
				) : (
					<div className="header-component-wrapper">
						<ImportsIcon />
						<span className="header-component-text header-component-text-page">
							{t("Imports")}
						</span>
					</div>
				)}
			</div>
			{path && (
				<div className="dashboard__left__text-actions">
					<Button
						icon={plusLogo}
						leftRounded={false}
						rightRounded={false}
						smallButtonWithTooltip
						text={t("AddImport")}
						handleChange={
							custom ? () => setShowCustomModal(true) : () => setShowModal(true)
						}
					/>
				</div>
			)}
			{final && getUrlParams("status").status === "DRAFT" && (
				<div
					style={{
						alignItems: "center",
					}}
					className="dashboard__left__text-actions"
				>
					{lastModified && lastModified !== null && (
						<span className="header-component_lastModified">
							{t("lastModified")} {lastModified?.firstName} {lastModified?.lastName}{" "}
							{moment(lastModified?.time).format("DD.MM.YYYY hh:mm:ss")}
						</span>
					)}
					<Avatar.Group
						shape="square"
						maxCount={2}
						maxStyle={{
							color: "#f56a00",
							backgroundColor: "#fde3cf",
						}}
					>
						{importActiveUsers.map((item) => (
							<Tooltip title={`${item?.firstName} ${item?.lastName}`} placement="top">
								<Avatar
									style={{
										backgroundColor: "var(--color-primary)",
									}}
								>
									{item?.firstName?.charAt(0)}
									{item?.lastName?.charAt(0)}
								</Avatar>
							</Tooltip>
						))}
					</Avatar.Group>
					<Button
						leftRounded={false}
						rightRounded={false}
						text={t("Proceed")}
						disabled={ordersLoading || !importsData?.[0]}
						handleChange={() => {
							// eslint-disable-next-line no-unused-expressions
							isButtonDisabled
								? () => ToastErrorComponent(t("MatchFieldsError"))
								: updateStatusImport({
										data: importsData.filter((item) => item?.type !== "DETAIL"),
										draftId: +getUrlParams("id").id,
								  });
						}}
					/>
				</div>
			)}
		</div>
	);
}

const mapDispatchToProps = {
	navigate: navigation.navigate,
	updateStatusImport: importActions.updateStatusImport,
};

const mapStateToProps = (state) => ({
	matchedOrders: state.app.imports.create.matchedOrders,
	importChangeStatus: state.app.imports.create.importChangeStatus,
	lastModified: state.app.imports.create.lastModified,
	importsData: state.app.imports.create.importsData,
	importActiveUsers: state.app.imports.create.importActiveUsers,
	ordersLoading: state.app.imports.create.ordersLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImportsHeader));
