import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import {actions as goalActions} from "@sagas/analytics/index";
import i18n from "@src/i18n";
import axios from "axios";
import {ENDPOINT} from "@src/utils/endpoint";

const logger = new Logger("delete goal");
const PREFIX = "@Dixi App/store/sagas/app/goals/delete";

export const DELETE_GOAL = `${PREFIX}DELETE_GOAL`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteGoal: (payload) => createAction(DELETE_GOAL, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*deleteGoal({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put(ENDPOINT.DELETE_GOALS, {goalIds: payload});
			yield put(goalActions.deleteGoalsSuccess(payload));
			ToastSuccesComponent(i18n.t("Card deleted successfully"));
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_GOAL, sagas.deleteGoal);
};
