import {MobileIcon, ShowInfoIconBlack, ShowOnDashboardIcon} from "@src/assets/SvgComponents";
import React from "react";
import {DateTypes1, TypeList2} from "@src/utils/dataTypes";
import i18n from "@src/i18n";
import {baseOrderStatusesObj} from "@src/utils/constants";
import SelectedTargets from "./SelectedTargets";
import {selectTypes} from "../staticData";
import {pritorities} from "../../staticData";

const GoalInformationComponent = ({
	item,
	title = i18n.t("goalInformation"),
	showSelectedTypes = true,
	targets = [
		{
			name: "clientName",
			items: item.targetClientsList,
			title: i18n.t("selectedTargetClient"),
		},
		{
			name: "name",
			items: item.targetTariffsList,
			title: i18n.t("selectedTargetTariff"),
		},
		{
			name: "name",
			items: item.targetProductsList,
			title: i18n.t("selectedTargetProduct"),
		},
		{
			name: "locationName",
			items: item.targetLocationsList,
			title: i18n.t("selectedTargetLocation"),
		},
	],
}) => {
	const {dateType, systemStatuses, typeList, priority, showMobile, showDashboard} = item;
	const founededPriority = pritorities.find((item) => item.value === priority);
	return (
		<div className="goalInfo__container">
			<div className="goalInfo__container--title">
				<span>
					<ShowInfoIconBlack />
				</span>
				<span>{title}</span>
			</div>
			{showSelectedTypes && (
				<>
					<div className="goalInfo__container--selectType">
						<span>
							{i18n.t("selectType")}:
							<span className="bold__values">{selectTypes?.[typeList]?.type}</span>
						</span>
						<span>
							{selectTypes?.[typeList]?.label}
							<span className="bold__values">
								{item[TypeList2[typeList]]
									?.map((item) => item.clientName || item?.name)
									.join(", ")}
							</span>
						</span>
					</div>
					<div className="goalInfo__container--breakLine">
						<span className="goalInfo__container--breakLine-line"></span>
					</div>
					<div className="goalInfo__container--statusType">
						{i18n.t("statusType")}:
						{systemStatuses?.map((item, index) => {
							const status = baseOrderStatusesObj[item?.orderStatus];
							return (
								<div
									key={index}
									className="bold__values"
									style={{color: item?.color}}
								>
									{status?.icon} {item.statusName}
									{index !== systemStatuses?.length - 1 && ", "}
								</div>
							);
						})}
					</div>
					{founededPriority && (
						<>
							<div className="goalInfo__container--breakLine">
								<span className="goalInfo__container--breakLine-line"></span>
							</div>
							<div className="goalInfo__container--priority">
								<span className="title">{i18n.t("priorityType")}:</span>
								<div
									className="priority__container"
									style={{borderColor: founededPriority?.color}}
								>
									<span className="icon">{founededPriority?.icon}</span>
									<span
										className="label"
										style={{color: founededPriority?.color}}
									>
										{founededPriority?.label}
									</span>
								</div>
							</div>
						</>
					)}
					<>
						<div className="goalInfo__container--breakLine">
							<span className="goalInfo__container--breakLine-line"></span>
						</div>
						<div className="goalInfo__container--priority">
							<span className="title">{i18n.t("ShowOn")}</span>
							<div className="show-wrapper">
								<div className="show-wrapper_item">
									{showDashboard && (
										<>
											<span>{i18n.t("Dashboard")}</span>
											<ShowOnDashboardIcon />
										</>
									)}
								</div>
								<div className="show-wrapper_item">
									{showMobile && (
										<>
											<span>{i18n.t("Mobile")}</span>
											<MobileIcon />
										</>
									)}
								</div>
							</div>
						</div>
					</>
					<div className="goalInfo__container--breakLine">
						<span className="goalInfo__container--breakLine-line"></span>
					</div>
					<div className="goalInfo__container--dateType">
						{i18n.t("dateType")}:
						<span
							className="bold__values"
							style={{
								textTransform: "capitalize",
							}}
						>
							{DateTypes1[dateType]}
						</span>
					</div>
				</>
			)}
			<div className="goalInfo__container--breakLine">
				<span className="goalInfo__container--breakLine-line"></span>
			</div>
			<div className="goalInfo__container--selectedTarget">
				{targets.map((target, idx) => (
					<SelectedTargets
						key={`${target.title}_${idx}`}
						name={target.name}
						items={target.items}
						title={target.title}
					/>
				))}
			</div>
		</div>
	);
};

export default GoalInformationComponent;
