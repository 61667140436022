import _ from "lodash";
import { toCamelCase } from "../Energy/OrderForm/helper";

export const getValue = (field, contactlessOrder, language) => {
    if (contactlessOrder) {
        const object = toCamelCase(field.objectType);
        const modifiedObj = object === "createOrderDetails" || object === "order" ? "orderDetailsData" : object;
        const value = _.get(contactlessOrder?.contractView?.[modifiedObj], field.name);
        if (field.inputType === "DROPDOWN" && value) {
            const foundedChildren = contactlessOrder?.getOrderField?.orderFieldViewList?.find((f) => f.name === value?.value);
            if (foundedChildren) {
                return language === "en" ? foundedChildren?.labelEn : foundedChildren?.labelDe;
            }
            return "";
        }
        return value?.value || value || "-";
    }
    return ""
}