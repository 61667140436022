import i18n from "@src/i18n";
import moment from "moment";

export const filterTeams = (teams, id) => teams?.filter((team) => team?.id !== id);

export const isDateRangeValid = ({team, startDate, endDate}) => {
	const isBeforeEndDate = team?.to
		? (endDate ? moment(endDate).isSameOrBefore(team.to, "day") : true) &&
		  moment(startDate).isSameOrBefore(team?.to, "day")
		: true;
	const hasNoEndDate = !team?.to && endDate;
	const hasNoEndDateAndNoValuesEndDate = endDate && !team?.to;
	return isBeforeEndDate || hasNoEndDate || hasNoEndDateAndNoValuesEndDate;
};


export const tabs = [
	{
		title: i18n.t("Overhead"),
		type: "overhead",
	},
	{
		title: i18n.t("SelfProduction"),
		type: "self_production"
	}
]