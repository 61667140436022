/* eslint-disable */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { actions as orderActions } from '@sagas/orders/index'
import { leaderboardColumns , leaderboardEmployeeColumns} from '@components/dashboard/columns'
import DataGridTable from '../table/DataGridTable'
import Loader from '../Loader'

function Leaderboard({
  leaderboard,
  page,
  totalSize,
  size,
  editSize,
  editPage,
  leaderboardEmployees,
  isEmployee = false,
  leaderboardLoading,
}) {
  const { t } = useTranslation()
  const [expandTable, setExpandTable] = useState(true)
  const sortedArr = leaderboard?.slice(0, 20).map((item) => item)

  const sortedEmpolyeArr = leaderboardEmployees?.slice(0, 20).map((item) => item)

  const dataToDisplay = isEmployee
  ? (expandTable ? leaderboardEmployees : sortedEmpolyeArr)
  : (expandTable ? leaderboard : sortedArr)


  return (
    <div className="leaderboard__container">
      <div className="statistics__information__text">
        <span className="statistics__information__text-first">
          {t('Leaderboard')}
        </span>
      </div>
      <div className="loader__and__table-leaderboard">
        {!leaderboardLoading && (
					<DataGridTable
          columns={isEmployee ? leaderboardEmployeeColumns : leaderboardColumns}
          data={dataToDisplay}
          pagination={false}
          editPage={editPage}
          editSize={editSize}
          page={page}
          size={size}
          totalSize={totalSize}
        />
				)}
				{leaderboardLoading && (
					<div className="loader__contianer">
						<Loader />
					</div>
				)}
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  page: state.app.orders.index.page,
  totalSize: state.app.orders.index.totalSize,
  size: state.app.orders.index.size,
  leaderboard: state.app.orders.index.leaderboard,
  leaderboardEmployees: state.app.orders.index.leaderboardEmployees,
  leaderboardLoading: state.app.orders.index.leaderboardLoading
})

const mapDispatchToProps = {
  editSize: orderActions.editSize,
  editPage: orderActions.editPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard)
