/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import createAction from "../../../../utils/action-creator";
import Logger from "../../../../utils/logger";
import axios from "../../../../utils/axios";
import {actions as createActions} from "./create";

const logger = new Logger("Trainings Index");
const PREFIX = "@app/trainings/Index";

export const GET_TRAININGS = `${PREFIX}GET_TRAININGS`;
export const GET_TRAININGS_SUCCESS = `${PREFIX}GET_TRAININGS_SUCCESS`;
export const GET_TRAININGS_BY_CATEGORY = `${PREFIX}GET_TRAININGS_BY_CATEGORY`;
export const GET_TRAININGS_BY_CATEGORY_SUCCESS = `${PREFIX}GET_TRAININGS_BY_CATEGORY_SUCCESS`;
export const GET_CATEGORIES = `${PREFIX}GET_CATEGORIES`;
export const GET_CATEGORIES_SUCCESS = `${PREFIX}GET_CATEGORIES_SUCCESS`;
export const CHANGE_PAGE = `${PREFIX}CHANGE_PAGE`;
export const CLEAR_PAGES = `${PREFIX}CLEAR_PAGES`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FILTERS = `${PREFIX}FILTERS`;
export const GET_TRAININGS_BY_STATUS = `${PREFIX}GET_TRAININGS_BY_STATUS`;
export const GET_TRAININGS_BY_STATUS_SUCCESS = `${PREFIX}GET_TRAININGS_BY_STATUS_SUCCESS`;
export const ADD_CATEGORY_SUCCESS = `${PREFIX}ADD_CATEGORY_SUCCESS`;
export const GET_CATEGORY_BY_ID = `${PREFIX}GET_CATEGORY_BY_ID`;
export const GET_CATEGORY_BY_ID_SUCCESS = `${PREFIX}GET_CATEGORY_BY_ID_SUCCESS`;
export const UPDATE_CATEGORY_BY_ID = `${PREFIX}UPDATE_CATEGORY_BY_ID`;
export const UPDATE_CATEGORY_BY_ID_SUCCESS = `${PREFIX}UPDATE_CATEGORY_BY_ID_SUCCESS`;
export const DELETE_CATEGORY_BY_ID = `${PREFIX}DELETE_CATEGORY_BY_ID`;
export const DELETE_CATEGORY_BY_ID_SUCCESS = `${PREFIX}DELETE_CATEGORY_BY_ID_SUCCESS`;
export const SET_CATEGORY_STATUS_MODAL = `${PREFIX}SET_CATEGORY_STATUS_MODAL`;
export const ADD_SUB_CATEGORY = `${PREFIX}ADD_SUB_CATEGORY`;
export const DELETE_SUB_CATEGORY_ON_STATE = `${PREFIX}DELETE_SUB_CATEGORY_ON_STATE`;
export const SET_FILTERS = `${PREFIX}SET_FILTERS`;
export const CLEAR_FILTERS = `${PREFIX}CLEAR_FILTERS`;

const _state = {
	activePage: 1,
	readPages: 1,
	loading: false,
	page: 1,
	size: 10,
	templates: [],
	filters: {
		fromDate: null,
		toDate: null,
		search: null,
		category: null,
		subCategories: [],
	},
	categories: [],
	category: {
		name: "",
		subCategories: [],
		categoryViewType: "EXTERN",
	},
	leadStatusModal: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case CHANGE_PAGE:
				let active = state.activePage;
				if (payload.type === "prev" && payload.page >= active && active > 1) {
					active -= 1;
				} else if (payload.type === "next" && payload.page > active) {
					active += 1;
				}

				draft.activePage = active;
				draft.readPages = state.readPages < active ? active : state.readPages;
				break;

			case FILTERS:
				draft.filters[payload?.name] = payload.value;
				break;

			case GET_TRAININGS_SUCCESS:
				draft.templates = payload;
				break;
			case GET_TRAININGS_BY_CATEGORY_SUCCESS:
				draft.templates = payload;
				break;
			case SET_CATEGORY_STATUS_MODAL:
				if (!payload) {
					draft.category = {
						name: "",
						subCategories: [],
						categoryViewType: "EXTERN",
					};
				}
				draft.leadStatusModal = payload;
				break;
			case GET_CATEGORIES_SUCCESS:
				draft.categories = payload?.map((e) => ({
					name: e.name,
					value: e.id,
				}));
				break;
			case GET_CATEGORY_BY_ID_SUCCESS:
				draft.category = payload;
				break;
			case UPDATE_CATEGORY_BY_ID:
				draft.category = payload;
				break;
			case DELETE_CATEGORY_BY_ID_SUCCESS:
				if (payload.type === "subCategory") {
					const filteredSubCategories = state?.category?.subCategories?.filter(
						(cat) => cat.id !== payload.id,
					);
					draft.category = {
						...state.category,
						subCategories: filteredSubCategories,
					};
				} else {
					const filteredCateogories = state?.categories?.filter(
						(cat) => cat.value !== payload.id,
					);
					draft.categories = filteredCateogories;
				}
				break;
			case ADD_SUB_CATEGORY:
				draft.category = payload;
				break;
			case DELETE_SUB_CATEGORY_ON_STATE:
				const items = state.category.subCategories;
				draft.category.subCategories = items.filter((item, i) => i !== payload);
				break;
			case GET_TRAININGS_BY_STATUS_SUCCESS:
				draft.templates = payload;
				break;
			case CLEAR_PAGES:
				draft.activePage = 1;
				draft.readPages = 1;
				break;
			case ADD_CATEGORY_SUCCESS:
				// const arr = state.categories.concat(payload);
				const arr = [payload, ...state.categories];
				draft.categories = arr;
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_FILTERS:
				draft.filters = payload;
				break;
			case CLEAR_FILTERS:
				draft.filters = {
					fromDate: null,
					toDate: null,
					search: null,
					category: null,
					subCategories: [],
				};
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getTrainings: (payload) => createAction(GET_TRAININGS, {payload}),
	getTrainingsSuccess: (payload) => createAction(GET_TRAININGS_SUCCESS, {payload}),
	getTrainingsByCat: (payload) => createAction(GET_TRAININGS_BY_CATEGORY, {payload}),
	getTrainingsByCatSuccess: (payload) =>
		createAction(GET_TRAININGS_BY_CATEGORY_SUCCESS, {payload}),
	getCategories: (payload) => createAction(GET_CATEGORIES, {payload}),
	getCategoriesSuccess: (payload) => createAction(GET_CATEGORIES_SUCCESS, {payload}),
	getCategoryById: (payload) => createAction(GET_CATEGORY_BY_ID, {payload}),
	getCategoryByIdSuccess: (payload) => createAction(GET_CATEGORY_BY_ID_SUCCESS, {payload}),
	updateCategoryById: (payload) => createAction(UPDATE_CATEGORY_BY_ID, {payload}),
	updateCategoryByIdSuccess: (payload) => createAction(UPDATE_CATEGORY_BY_ID_SUCCESS, {payload}),
	getTrainingsByStatus: (payload) => createAction(GET_TRAININGS_BY_STATUS, {payload}),
	deleteCategoryById: (payload) => createAction(DELETE_CATEGORY_BY_ID, {payload}),
	deleteCategoryByIdSuccess: (payload) => createAction(DELETE_CATEGORY_BY_ID_SUCCESS, {payload}),
	setCategoryStatusModal: (payload) => createAction(SET_CATEGORY_STATUS_MODAL, {payload}),
	getTrainingsByStatusSuccess: (payload) =>
		createAction(GET_TRAININGS_BY_STATUS_SUCCESS, {payload}),
	changePage: (payload) => createAction(CHANGE_PAGE, {payload}),
	clearPages: (payload) => createAction(CLEAR_PAGES, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	filterTrainings: (payload) => createAction(FILTERS, {payload}),
	addCategorySuccess: (payload) => createAction(ADD_CATEGORY_SUCCESS, {payload}),
	addTemplateSubCategory: (payload) => createAction(ADD_SUB_CATEGORY, {payload}),
	deleteSubCatOnState: (payload) => createAction(DELETE_SUB_CATEGORY_ON_STATE, {payload}),
	setFilters: (payload) => createAction(SET_FILTERS, {payload}),
	clearFilterValues: (payload) => createAction(CLEAR_FILTERS, {payload}),
};

export const sagas = {
	*getTrainings() {
		const {filters} = yield select((state) => state.app.trainings.index);
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post("/template/all?page=1&size=10000000", {
				fromDate: filters.fromDate,
				toDate: filters.toDate,
			});

			yield put(createActions.clearState());
			yield put(actions.getTrainingsSuccess(response.data.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getTrainingsByCat({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/template/category/${payload}?page=1&size=1000000`);
			yield put(createActions.clearState());
			yield put(actions.getTrainingsByCatSuccess(response.data.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getCategories() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get("/template/category/all?page=1&size=1000000");
			yield put(actions.getCategoriesSuccess(response.data.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getCategoryById({payload}) {
		try {
			const response = yield axios.get(`/template/category/get/${payload}`);
			yield put(actions.getCategoryByIdSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
			if (error?.response.data.message[0].name === "REQUESTED_DATA_NOT_FOUND") {
				ToastErrorComponent(i18n.t("thisCategoryIsDeleted"));
			}
		}
	},
	*updateCategoryById({payload}) {
		try {
			const response = yield axios.get(`/template/category/get/${payload}`);
			yield put(actions.updateCategoryByIdSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*deleteCategoryById({payload}) {
		try {
			yield axios.delete(`/template/category/${payload.id}`);
			yield put(actions.deleteCategoryByIdSuccess(payload));
		} catch (error) {
			logger.error(error);
		}
	},
	*getTrainingsByStatus({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post(`/template/all/status?page=1&size=1000000`, {
				status: payload?.status || payload,
				category:
					payload.query?.category?.value || payload?.query?.category === "All"
						? null
						: payload?.category || null,
				subCategories: payload?.query?.subCategories || null,
				fromDate: payload?.query?.fromDate,
				toDate: payload?.query?.toDate,
				search: payload?.query?.search,
			});
			yield put(createActions.clearState());
			yield put(actions.getTrainingsByCatSuccess(response.data.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_TRAININGS, sagas.getTrainings);
	yield takeLatest(GET_CATEGORIES, sagas.getCategories);
	yield takeLatest(GET_TRAININGS_BY_CATEGORY, sagas.getTrainingsByCat);
	yield takeLatest(GET_TRAININGS_BY_STATUS, sagas.getTrainingsByStatus);
	yield takeLatest(GET_CATEGORY_BY_ID, sagas.getCategoryById);
	yield takeLatest(DELETE_CATEGORY_BY_ID, sagas.deleteCategoryById);
	yield takeLatest(UPDATE_CATEGORY_BY_ID, sagas.updateCategoryById);
};
