/* eslint-disable max-len */
import {axiosRequests} from "@src/utils/getAgAxios";
import axios from "@utils/axios";
import Logger from "@src/utils/logger";
import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "../../../../utils/action-creator";

const logger = new Logger("Energy DATA");

const PREFIX = "@app/store/sagas/app/energy/index";
export const GET_ENERGY_TARIFFS = `${PREFIX}GET_ENERGY_TARIFFS`;
export const GET_ENERGY_TARIFFS_SUCCESS = `${PREFIX}GET_ENERGY_TARIFFS_SUCCESS`;
export const CLEAR_ENERGY_TARIFFS = `${PREFIX}CLEAR_ENERGY_TARIFFS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_LOCATIONS_FROM_POSTCODE = `${PREFIX}GET_LOCATIONS_FROM_POSTCODE`;
export const GET_LOCATIONS_FROM_POSTCODE_SUCCESS = `${PREFIX}GET_LOCATIONS_FROM_POSTCODE_SUCCESS`;
export const GET_GET_AG_CLIENTS = `${PREFIX}GET_GET_AG_CLIENTS`;
export const GET_GET_AG_CLIENTS_SUCCESS = `${PREFIX}GET_GET_AG_CLIENTS_SUCCESS`;
export const GET_ENERGY_TARIFFS_DROPDOWN = `${PREFIX}GET_ENERGY_TARIFFS_DROPDOWN`;
export const GET_ENERGY_TARIFFS_DROPDOWN_SUCCESS = `${PREFIX}GET_ENERGY_TARIFFS_DROPDOWN_SUCCESS`;

const _state = {
	loading: false,
	energyTariffs: [],
	locations: [],
	getAgClients: [],
	energyTariffsDropdown: [],
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case GET_ENERGY_TARIFFS_SUCCESS:
				draft.energyTariffs = payload?.data?.[0].ergebnis || [];
				break;
			case GET_LOCATIONS_FROM_POSTCODE_SUCCESS:
				const location = payload?.ort?.[0];
				const newArr = location?.ortsteil?.map((itm) => ({
					name: `${location?.ortsname} | ${itm}`,
					value: `${location?.ortsname} | ${itm}`,
				}));
				draft.locations = newArr;
				break;
			case CLEAR_ENERGY_TARIFFS:
				draft.energyTariffs = [];
				break;
			case GET_GET_AG_CLIENTS_SUCCESS:
				draft.getAgClients =
					payload?.length > 0
						? payload?.map((pl) => ({
								name: pl.versorgerID,
								labelEn: pl.versorgerName,
								labelDe: pl.versorgerName,
								id: pl?.versorgerID,
						  }))
						: [];
				break;
			case GET_ENERGY_TARIFFS_DROPDOWN_SUCCESS:
				draft.energyTariffsDropdown = payload;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	getEnergyTariffs: (payload) => createAction(GET_ENERGY_TARIFFS, {payload}),
	getEnergyTariffsSuccess: (payload) => createAction(GET_ENERGY_TARIFFS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getLocationsFromPostcode: (payload) => createAction(GET_LOCATIONS_FROM_POSTCODE, {payload}),
	getLocationsFromPostcodeSuccess: (payload) =>
		createAction(GET_LOCATIONS_FROM_POSTCODE_SUCCESS, {payload}),
	clearEnergyTariffs: (payload) => createAction(CLEAR_ENERGY_TARIFFS, {payload}),
	getGetAgClients: (payload) => createAction(GET_GET_AG_CLIENTS, {payload}),
	getGetAgClientsSuccess: (payload) => createAction(GET_GET_AG_CLIENTS_SUCCESS, {payload}),
	getEnergyTariffsDropdown: (payload) => createAction(GET_ENERGY_TARIFFS_DROPDOWN, {payload}),
	getEnergyTariffsDropDownSuccess: (payload) =>
		createAction(GET_ENERGY_TARIFFS_DROPDOWN_SUCCESS, {payload}),
};

export const sagas = {
	*getEnergyTariffs({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {values} = payload;
			const gasPayload = {
				plz: values?.zipCode,
				ort: values?.city,
				netzbetreiber: "9870075000009",
				netzgebiet: "",
				grundversorger: "9800009700002",
				verbrauch: values?.kwYearGas,
				leistung: 11,
				kundenart: values?.customer,
				tariftyp: `${values?.calculator}`,
				limit: 0,
				grenzenBeiATignorierenBis: 0,
				tarifeVonGrundversorgern: 3,
				grundversorgungstarife: 3,
				handlingTarife: 3,
				vergleichsTarifID: "",
				vergleichsRichtlinie: 10,
				vergleichsVersorgerID: "",
				stichtag: "",
				oeko: 3,
				fixpreis: 3,
				vergleichsVariantenID: "",
				vorkasse: 3,
				paket: 3,
				portalRichtlinie: 3,
				bonus: 3,
				sachbonus: 3,
				preisgarantie: 3,
				kaution: 3,
				sonderabschlag: 3,
				vertragslaufzeitBis: 0,
				vertragsverlaengerung: 0,
				kuendigungsfrist: 0,
				bestandskundenTarif: 3,
				preisgarantieDauer: 0,
				versorgerIDs: "",
				zertifikate: "",
				seitenGroesse: 10,
				seitenNr: 1,
				markenIDs: "",
				tarifcode: "",
				tarifRootIDsMindestfilter: "",
				tarifRootIDs: "",
				vertriebskanalIDs: "",
				uid: "",
				regionalVersorgerUmkreis: "",
				kennzahl: 3,
				details: 3,
				baiextendedBlackList: "",
				baiextendedWhiteList: "",
			};
			const energyPayload = {
				plz: values?.zipCode,
				ort: values?.city,
				grundversorger: "9903143000005",
				netzbetreiber: "9900750000006",
				netzgebiet: "",
				verbrauchHT: 3500,
				verbrauchNT: values?.kwYearNT,
				kundenart: values?.customer,
				tariftyp: `${values.calculator}`,
				limit: 0,
				grenzenBeiATignorierenBis: 0,
				tarifeVonGrundversorgern: 3,
				grundversorgungstarife: 0,
				handlingTarife: 0,
				vergleichsTarifID: 0,
				vergleichsVariantenID: "",
				vergleichsRichtlinie: 0,
				vergleichsVersorgerID: 0,
				stichtag: "",
				oeko: 0,
				fixpreis: 0,
				vorkasse: 0,
				paket: 0,
				portalRichtlinie: 3,
				bonus: 0,
				sachbonus: 0,
				preisgarantie: 0,
				kaution: 0,
				sonderabschlag: 0,
				smartmeter: 0,
				vertragslaufzeitBis: 0,
				vertragsverlaengerung: 0,
				kuendigungsfrist: 0,
				bestandskundenTarif: 0,
				preisgarantieDauer: 0,
				zertifikate: "",
				seitenGroesse: 0,
				seitenNr: 1,
				lastprofile: "",
				markenIDs: "",
				tarifcode: "",
				tarifRootIDsMindestfilter: "",
				tarifRootIDs: "",
				zaehlerart: "",
				vertriebskanalIDs: "",
				uid: "",
				regionalVersorgerUmkreis: 0,
				kennzahl: 0,
				details: 0,
				baiextendedWhiteList: "",
				baiextendedBlackList: "",
			};
			// const { energyClients } = yield select(state => state.app.client.index);
			// const { energyTariffs } = yield select(state => state.app.energy.energyTariffs);
			const {data} = yield axiosRequests.getAgRequest({
				url: `/jaxrs/${values?.calculatorType}TarifService20x0/getBerechneteTarife`,
				json: {
					parameterObj: [
						values?.calculatorType === "Strom" ? {...energyPayload} : {...gasPayload},
					],
					sortierung: 0,
				},
			});
			if (data?.data?.ergebnisseGesamtAnzahl > 0) {
				yield put(
					actions.getEnergyTariffsSuccess({
						data: data?.data?.ergebnisContainer,
						energyClients: [],
						energyTariffs: [],
					}),
				);
			} else {
				yield put(actions.clearEnergyTariffs());
			}
		} catch (err) {
			logger.error(err);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getGetAgClients({payload}) {
		try {
			const {data} = yield axiosRequests.getAgRequest({
				url: `/jaxrs/${payload}TarifService20x0/getVersorger`,
				method: "get",
			});
			yield put(actions.getGetAgClientsSuccess(data?.data?.versorger));
		} catch (error) {
			return error;
		}
	},
	*getEnergyTariffsDropDown({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {energyClients} = yield select((state) => state.app.client.index);
			if (payload.id !== null) {
				const clientIds = payload?.id ? [payload?.id] : energyClients?.map((cl) => cl.id);
				const {data} = yield axios.post(`/tariff/v2/tariffs-from-clients`, {
					clientIds,
					clientType: payload?.clientType || null,
					energyType: payload?.energyType || null,
				});
				yield put(actions.getEnergyTariffsDropDownSuccess(data?.data));
			} else {
				yield put(actions.getEnergyTariffsDropDownSuccess([]));
			}
		} catch (error) {
			return error;
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_ENERGY_TARIFFS, sagas.getEnergyTariffs);
	yield takeLatest(GET_GET_AG_CLIENTS, sagas.getGetAgClients);
	yield takeLatest(GET_ENERGY_TARIFFS_DROPDOWN, sagas.getEnergyTariffsDropDown);
};
