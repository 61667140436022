import HtmlTooltip from "@src/common/htmlTooltip";
import React from "react";

const LeadUsers = ({userDetails, backgroundColor = "#C1C1C1"}) => (
	<HtmlTooltip
		placement="bottom-end"
		title={userDetails?.map((user, index) => (
			<p key={`${user.id}--${index}`} className="user__tooltip">
				{index + 1}. {user?.firstName} {user?.lastName}
			</p>
		))}
	>
		<div className="lead__users">
			{userDetails?.slice(0, 5).map((user, index) =>
				user?.imgUrl && user?.imgUrl !== null ? (
					<img src={user.imgUrl} alt="" className="user_img_avatar" key={index} />
				) : (
					<div className="lead__users-avatar" key={index} style={{backgroundColor}}>
						{user.firstName?.charAt(0)}
						{user.lastName?.charAt(0)}
					</div>
				),
			)}
			{userDetails?.length > 5 && <span>+{userDetails.length - 5}</span>}
		</div>
	</HtmlTooltip>
);
export default LeadUsers;
