import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";

const HtmlTooltip = withStyles(() => ({
	tooltip: ({
		padding = 12,
		paddingLeft = "",
		paddingRight = "",
		paddingTop = "",
		paddingBottom = "",
		borderRadius = 8,
		border = "1px solid #70707055",
		left = -30,
		fontSize = 12,
		color = "#383838",
		backgroundColor = "#fff",
		fontWeight = 500,
		display = "flex",
		flexDirection = "column",
		boxShadow,
		maxWidth,
		open = true,
	}) => ({
		backgroundColor,
		color,
		maxWidth,
		padding,
		fontSize,
		fontFamily: "Montserrat",
		borderRadius,
		border,
		left,
		fontWeight,
		paddingLeft,
		paddingRight,
		paddingTop,
		paddingBottom,
		display,
		flexDirection,
		boxShadow,
		open,
	}),
}))(Tooltip);

export default HtmlTooltip;
