/* eslint-disable max-len */
import {DeleteRowIcon, EditRowIcon, InfoRowIcon, RestoreRowIcon} from "@src/assets/SvgComponents";
import {FORMAT_DATE} from "@src/common/constants";
import HtmlTooltip from "@src/common/htmlTooltip";
import CustomPopover from "@src/common/Popover";
import i18n from "@src/i18n";
import {checkRoles} from "@src/utils/checkRoles";
import moment from "moment";
import React from "react";
import GoalInformationComponent from "../Analytics/components/AlertsCardComponent.js/GoalInformationComponent";

export const actionsFormatter = (row, navigate, setModal) => (
	<div className="table-actions-wrapper">
		{checkRoles("editUsers") && (
			<HtmlTooltip title={<span>{i18n.t("EditUser")}</span>} placement="bottom-end">
				<span onClick={() => navigate(`/admin/users/edit/${row?.id}`)}>
					<EditRowIcon />
				</span>
			</HtmlTooltip>
		)}
		{checkRoles("deactivateUsers") && (
			<span onClick={() => setModal("deactivate", row?.id, [{userName: `${row.name}`}])}>
				<DeleteRowIcon />
			</span>
		)}
	</div>
);

export const agencyActionsFormatter = (row, navigate, setModal) => (
	<div className="table-actions-wrapper">
		{checkRoles("editUsers") && (
			<HtmlTooltip title={<span>{i18n.t("EditUser")}</span>} placement="bottom-end">
				<span onClick={() => navigate(`/admin/users/edit/${row?.teamLeaderId}`)}>
					<EditRowIcon />
				</span>
			</HtmlTooltip>
		)}
		{checkRoles("deactivateUsers") && (
			<HtmlTooltip title={<span>{i18n.t("DeleteUser")}</span>} placement="bottom-end">
				<span
					onClick={() =>
						setModal("deactivate", row?.teamLeaderId ? row?.teamLeaderId : row?.id)
					}
				>
					<DeleteRowIcon />
				</span>
			</HtmlTooltip>
		)}
	</div>
);
export const teamActionsFormatter = (row, setModal) => {
	const isTeamExpired = row?.to ? moment(row?.to).isBefore(moment()) : false;
	return (
		<div className="table-actions-wrapper" style={{width: "auto"}}>
			{checkRoles("editTeam") && (
				<CustomPopover
					menu={{
						items: [
							{
								key: "menu",
								label: (
									<GoalInformationComponent
										item={row}
										showSelectedTypes={false}
										title={i18n.t("TeamInfo")}
										targets={[
											{title: i18n.t("TeamName"), items: [row], name: "name"},
											{
												title: i18n.t("SelectedUsers"),
												items: row.members?.filter(
													(r) => !r.headOfDepartment,
												),
												name: "name",
											},
											{
												title: i18n.t("HeadOfDepartment"),
												items: row.members.filter(
													(r) => r.headOfDepartment,
												),
												name: "name",
											},
											{
												title: i18n.t("CreatedAt"),
												items: [{...row, from: FORMAT_DATE(row.from)}],
												name: "from",
											},
											{
												title: i18n.t("EndDate"),
												items: [
													{
														...row,
														to: row?.to ? FORMAT_DATE(row?.to) : "-",
													},
												],
												name: "to",
											},
										]}
									/>
								),
							},
						],
					}}
				>
					<span>
						<InfoRowIcon />
					</span>
				</CustomPopover>
			)}
			{checkRoles("editTeam") && (
				<HtmlTooltip title={<span>{i18n.t("EditUser")}</span>} placement="bottom-end">
					<span
						onClick={() => {
							if (!isTeamExpired) {
								setModal("team", row.id);
							}
						}}
					>
						<EditRowIcon stroke={isTeamExpired ? "#E4E4E4" : "#C1C1C1"} />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deleteTeam") && (
				<HtmlTooltip title={<span>{i18n.t("DeleteTeam")}</span>} placement="bottom-end">
					<span onClick={() => setModal("deleteTeam", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
};

export const actionsDeletedFormatter = (row, setModal) => (
	<div className="table-actions-wrapper">
		{checkRoles("activateUsers") && (
			<HtmlTooltip title={<span>{i18n.t("ActivateUser")}</span>} placement="bottom-end">
				<span
					style={{
						pointerEvents: row?.role?.name === "AGENCY-SP" ? "none" : "",
						opacity: row?.role?.name === "AGENCY-SP" ? 0.5 : 1,
					}}
					onClick={() => setModal("restore", row?.id, [{userName: `${row.name}`}])}
				>
					<RestoreRowIcon />
				</span>
			</HtmlTooltip>
		)}
		{checkRoles("deleteUsers") && (
			<HtmlTooltip
				title={<span>{i18n.t("PermanentDeleteUser")}</span>}
				placement="bottom-end"
			>
				<span onClick={() => setModal("delete", row?.id, [{userName: `${row.name}`}])}>
					<DeleteRowIcon />
				</span>
			</HtmlTooltip>
		)}
	</div>
);

export const actionsProperties = {
	key: "actions",
	name: i18n.t("Actions"),
	sortable: false,
	resizable: false,
	width: 50,
};
