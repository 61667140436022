import {put, takeLatest} from "redux-saga/effects";
import produce from "immer";
import Logger from "@src/utils/logger";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import axios from "axios";
import moment from "moment";
import {DateTypes, DateTypes1} from "@src/utils/dataTypes";
import {ENDPOINT} from "@src/utils/endpoint";
import i18n from "@src/i18n";
import createAction from "../../../../../utils/action-creator";
import {actions as goalAction} from "../index";
import {actions as viewAction} from "./views";
import {goalValues} from "../staticData";

const logger = new Logger("Sagas>Analytics>Goals>Create");
const PREFIX = "@app/analytics/goals/create";

export const ADD_GOAL = `${PREFIX}ADD_GOAL`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_INITIAL_VALUES = `${PREFIX}SET_INITIAL_VALUES`;
export const CLEAR_INITIAL_VALUES = `${PREFIX}CLEAR_INITIAL_VALUES`;
const timezone = new Date().getTimezoneOffset();

const _state = {
	initialValues: goalValues,
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_INITIAL_VALUES:
				const {data, usersOptions} = payload;
				const userListWithRoles = usersOptions.filter((user) =>
					data?.userList?.some((item) => item.id === user.id),
				);
				draft.initialValues = {
					...state.initialValues,
					...data,
					typeList: data.typeList,
					dateType: DateTypes1[data.dateType],
					targetClientsList: data.targetClientsList,
					targetClients: data.targetClientsList,
					targetTariffsList: data.targetTariffsList,
					targetProductsList: data.targetProductsList,
					targetLocationsList: data.targetLocationsList,
					userList: userListWithRoles || [],
					clientList: data?.clientList || [],
					teamList: data?.teamList || [],
					agencyList: data?.agencyList || [],
					systemStatusesIds: data?.systemStatusesIds || [],
				};
				break;
			case CLEAR_INITIAL_VALUES:
				draft.initialValues = goalValues;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addGoal: (payload) => createAction(ADD_GOAL, {payload}),
	setInitialValues: (payload) => createAction(SET_INITIAL_VALUES, {payload}),
	clearInitialValues: (payload) => createAction(CLEAR_INITIAL_VALUES, {payload}),
};

export const sagas = {
	*addGoal({payload}) {
		yield put(actions.setLoading(true));
		const {values, handleClose} = payload;

		try {
			const payload = {
				...values,
				dateType: DateTypes[values.dateType],
				goalType: "NEW_CONTRACTS",
				groupTypeId: values?.groupTypeId?.id
					? values?.groupTypeId?.id
					: values?.groupTypeId || null,
				typeList: values.typeList,
				startDate: moment(values?.startDate)
					.startOf("day")
					.subtract(timezone, "minutes")
					.valueOf(),
				endDate: moment(values?.endDate)
					.endOf("day")
					.subtract(timezone, "minutes")
					.valueOf(),
				userList:
					values?.typeList === "USERS"
						? values?.userList.filter((item) => item.id).map((item) => item.id)
						: null,
				clientList: values?.clientList.map((item) => item.id),
				teamList:
					values?.typeList === "TEAMS" ? values?.teamList?.map((item) => item.id) : null,
				agencyList:
					values?.typeList === "AGENCIES"
						? values?.agencyList?.map((item) => item.id)
						: null,
				showDashboard: values.id ? values.showDashboard : false,
				targetLocationsList: values?.targetLocationsList?.map((it) => it?.id),
				targetProductsList: values?.targetProductsList?.map((it) => it?.value || it?.id),
				targetTariffsList: values?.targetTariffsList?.map((it) => it?.value || it?.id),
			};
			delete payload.type;
			if (values?.id) {
				const response = yield axios.put(
					ENDPOINT.EDIT_GOALS.replace(":id", values.id),
					payload,
				);
				yield put(goalAction.editGoalSuccess(response.data));
				yield put(viewAction.editGoalsTypeByIdSuccess(response.data));
				ToastSuccesComponent(i18n.t("GoalEditedSuccessfully"));
			} else {
				const response = yield axios.post(ENDPOINT.CREATE_GOALS, payload);
				ToastSuccesComponent(i18n.t("GoalCreatedSuccessfully"));
				yield put(goalAction.addGoalSuccess(response.data));
			}
			handleClose();
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_GOAL, sagas.addGoal);
};
