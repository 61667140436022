import locale from "date-fns/locale/en-GB";
import {TextField} from "@material-ui/core";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React from "react";
import {IconButton} from "@mui/material";
import {
	BackButton,
	CheckLogo,
	CloseUploadModalIcon,
	DatePickerIcon,
} from "@src/assets/SvgComponents";

const RangePicker = (props) => {
	const {
		value,
		label,
		onChange,
		touched,
		errors,
		errorClass = "errorClass",
		withTouched = true,
		noTouched = false,
		disabled,
		minDate,
		maxDate,
		onClear,
		showClearBtn = false,
	} = props;
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
			<DatePicker
				minDate={minDate}
				maxDate={maxDate}
				disabled={disabled}
				disableClearable={true}
				label={label}
				value={value}
				inputFormat="dd/MM/yyyy"
				onChange={onChange}
				components={{
					OpenPickerIcon: DatePickerIcon,
				}}
				renderInput={(params) =>
					showClearBtn ? (
						<div>
							<TextField {...params} onKeyDown={(e) => e?.preventDefault()} />
							{value && showClearBtn && (
								<IconButton
									style={{
										position: "absolute",
										top: "2.4rem",
										margin: "auto",
										right: "2.2rem",
									}}
									onClick={onClear}
								>
									<CloseUploadModalIcon width="10" height="10" />
								</IconButton>
							)}
						</div>
					) : (
						<TextField {...params} onKeyDown={(e) => e?.preventDefault()} />
					)
				}
			/>
			{withTouched && touched && errors && (
				<span className={errorClass}>{touched && errors}</span>
			)}
			{noTouched && errors && <span className={errorClass}>{errors}</span>}
		</LocalizationProvider>
	);
};

export default RangePicker;
