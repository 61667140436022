/* eslint-disable max-len */
import React from "react";
import ModalComponent from "@src/common/modal";
import ActivityReports from "./activityReports";
import CostumizeAlerts from "./costumizeAlerts";

const AnalyticsModal = (props) => {
	const {showModal, handleClose, type, loading} = props;
	return (
		<ModalComponent
			open={showModal}
			handleClose={handleClose}
			customClassNames="create-status-modal"
			positionModal="right"
		>
			<>{type === "activityReports" && <ActivityReports handleClose={handleClose} />}</>
			<>{type === "costumizeAlerts" && <CostumizeAlerts handleClose={handleClose} />}</>
		</ModalComponent>
	);
};
export default AnalyticsModal;
