/* eslint-disable no-nested-ternary */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as conditionActions} from "@src/store/sagas/app/teamConditions/index";
import {actions as createConditionActions} from "@src/store/sagas/app/teamConditions/create";
import {actions as deleteConditionActions} from "@src/store/sagas/app/teamConditions/delete";
import {actions as teamActions} from "@sagas/teams/index";
import {actions as salesOrgActions} from "@sagas/salesOrganizations/index";
import {actions as structuresActions} from "@sagas/structures/index";
import CustomPopover from "@src/common/Popover";
import {ThreeDotsIcon} from "@src/assets/SvgComponents";
import {useUrlParams} from "@src/utils/useUrlParams";
import Form from "@src/common/Form";
import {formatOptionsForSelect} from "@src/utils/getOptionsForSelect";
import {Storage} from "@src/utils/local-storage";
import TableSkeleton from "@src/common/table/TableSkeleton";
import {Skeleton} from "antd";
import Tabs from "./Tabs";
import ConditionTable from "./ConditionTable";
import ConditionAccordion from "./Accordion/Accordion";
import ConfirmModal from "./ConfirmModal";
import {isDateRangeValid, popoverItems, tabs} from "./utils";
import {columns} from "./columns";
import {conditionElements} from "./conditionElements";

const Conditions = (props) => {
	const {
		conditionState,
		getConditions,
		setTableValues,
		deleteCondition,
		editTable,
		fillTable,
		teams,
		fetchTeams,
		getAllSalesOrganizations,
		allSalesOrganizations,
		loading,
		getAllStructures,
		getStufesByStructureId,
		getValidStuffes,
		structuresState,
	} = props;
	const {t} = useTranslation();
	const {allStructures, validStuffes, stufes} = structuresState;
	const {getUrlParams, setUrlParams, setModal} = useUrlParams();
	const {
		conditionType,
		id,
		modal,
		type: modalType,
		tableId,
		structureId,
		tableStartDate,
		tableEndDate,
	} = getUrlParams(
		"tab",
		"condition-type",
		"id",
		"modal",
		"type",
		"tableId",
		"structureId",
		"tableStartDate",
		"tableEndDate",
	);
	const [filteredTeams, setFilteredTeams] = useState([]);
	const [trainers, setTrainers] = useState([]);
	const [interns, setInterns] = useState([]);
	const [dates, setDates] = useState({
		startDate: null,
		endDate: null,
	});
	const [trainerId, setTrainerId] = useState(null);
	const [teamId, setTeamId] = useState(null);
	const {conditionTables, tableToUpdate, initialValues, loading: isLoading} = conditionState;
	const {lists, btnEditText, btnText, btnTextMultiple, btnEditTextMultiple} = conditionElements(
		getValidStuffes,
		structureId,
		id,
		getStufesByStructureId,
		tableStartDate,
		tableEndDate,
	);
	const tenantFeatures = Storage.get("features");
	const [title, setTitle] = useState({
		title: "",
		tableTitle: "",
	});
	useEffect(() => {
		if (!conditionType) {
			setUrlParams("Conditions", "tab", {
				paramName: "condition-type",
				paramValue: "overhead",
			});
		}
	}, []);
	const [showActionsPopover, setShowActionsPopover] = useState(null);
	const [expandedTab, setExpandedTab] = useState(null);
	const handleVisibility = (open, id) => setShowActionsPopover(open ? id : null);
	const handleModal = (type, id, table, from) => {
		if (type === "edit") {
			setModal(
				lists[conditionType].type === "multiple" ? `${conditionType}-row` : conditionType,
				id,
				[
					{structureId: table?.structure?.id ? `${table?.structure?.id}` : ""},
					{
						tableId: table?.id ? `${table.id}` : "",
					},
					{tableStartDate: table?.startDate ? `${table.startDate}` : ""},
					{tableEndDate: table?.endDate ? `${table.endDate}` : ""},
				],
			);
		} else if (type === "editTable") {
			setModal(conditionType, id);
		} else if (type === "update") {
			editTable({values: tableToUpdate});
		} else if (from === "fromRow" && conditionType === "career_conditions") {
			setModal("deleteRow", id);
		} else {
			setModal(type, id);
		}
		setShowActionsPopover(null);
	};

	const handleEdit = () => {
		if (modal === "copy") {
			setModal("duplicate", id);
		} else {
			deleteCondition({
				type: modal === "deleteRow" ? `${conditionType}-row` : conditionType,
				id,
			});
			closeModal();
		}
	};

	useEffect(() => {
		if (conditionType) {
			getConditions(conditionType);
			setExpandedTab(conditionType);
			const foundedActiveTab = tabs?.find((tab) => tab?.type === conditionType);
			setTitle({
				title: foundedActiveTab?.title || "",
				tableTitle: foundedActiveTab?.tableTitle || "",
			});
		}
		if (conditionType === "induction_bonus_trainers_conditions") {
			fetchTeams({all: true, isTeam: false});
		}
		if (conditionType === "manager_vo") {
			getAllSalesOrganizations("filtered");
		}
		if (conditionType === "career_conditions" || conditionType === "overhead") {
			getAllStructures();
		}
	}, [conditionType]);
	useEffect(() => {
		if (modal === "duplicate" && conditionType && id && conditionTables?.length > 0) {
			setTableValues({id, type: modal, conditionType});
		}
	}, [modal, id, conditionType, conditionTables]);
	const closeModal = () => setModal(false);
	const mergedColumns = useMemo(
		() => (table) => {
			if (conditionType && columns[conditionType]) {
				const actions = {
					key: "",
					cellClass: "borderLess__actions__cell",
					headerCellClass: "borderLess__actions__cell",
					name: t("Actions"),
					width: 50,
					formatter: ({row}) => (
						<CustomPopover
							handleVisibility={(open) => handleVisibility(open, row.id)}
							customClass="popover__actions-wrapper"
							trigger={["click"]}
							open={showActionsPopover === row.id}
							arrow
							menu={{
								items: popoverItems(conditionType),
								onClick: ({key}) => handleModal(key, row.id, table, "fromRow"),
							}}
						>
							<div className="three-dots-icon">
								<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
							</div>
						</CustomPopover>
					),
				};
				return [...columns[conditionType], actions];
			}
			return [];
		},
		[
			conditionType,
			columns,
			showActionsPopover,
			t,
			popoverItems,
			handleModal,
			handleVisibility,
		],
	);

	useEffect(() => {
		const filteredTeams = teams?.filter((team) =>
			isDateRangeValid({
				team,
				startDate: dates?.startDate,
				endDate: dates?.endDate,
			}),
		);
		setFilteredTeams(filteredTeams);
	}, [dates?.startDate, dates?.endDate, teams]);
	useEffect(() => {
		if (teamId) {
			getTrainers(teamId);
		}
	}, [teamId, teams]);
	useEffect(() => {
		if (trainerId) {
			getInterns(trainerId);
		}
	}, [trainerId, teams]);
	if (!conditionType) return null;
	const options = (item) => {
		switch (item.field) {
			case "team":
				return formatOptionsForSelect(filteredTeams);
			case "trainer":
				return formatOptionsForSelect(trainers);
			case "intern":
				return formatOptionsForSelect(interns);
			case "salesOrganizations":
				return allSalesOrganizations;
			case "structureId":
				return allStructures;
			case "stufeId":
			case "fromStufeId":
				return stufes;
			case "toStufeId":
				return validStuffes;
			default:
				return item.options?.filter(
					(opt) => !opt.conditionType || opt.conditionType === conditionType,
				);
		}
	};
	const getTrainers = (value) => {
		const team = teams?.find((team) => team?.id === value);
		const trainers = team?.members?.filter((item) => item?.trainer);
		setTrainers(trainers || []);
	};
	const getInterns = (value) => {
		const members = teams?.flatMap((item) => item.members);
		const interns = members?.filter((member) => member.trainerId === value);
		setInterns(interns || []);
	};
	const handleDates = (field, value) => {
		setDates((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};
	const getTitle = (type) => {
		if (type === "edit") {
			if (modal?.includes("row") || !modal.includes("career")) {
				return btnEditText;
			}
			return btnEditTextMultiple;
		}
		if (type) {
			if (modal?.includes("row") || !modal.includes("career")) {
				return btnText;
			}
			return btnTextMultiple;
		}
		return btnText;
	};
	const formData = {
		inputs:
			lists?.[conditionType]?.rowElements && modal === `${conditionType}-row`
				? lists[conditionType]?.rowElements?.map((input) => {
						const newInput = {
							...input,
							options: options(input),
						};
						if (input.field === "team") {
							return {
								...newInput,
								onChange: getTrainers,
							};
						}
						if (input.field === "trainer") {
							return {
								...newInput,
								onChange: getInterns,
							};
						}
						if (input.inputType === "rangePicker") {
							return {
								...newInput,
								onChange: handleDates,
							};
						}
						return newInput;
				  })
				: lists[conditionType]?.elements?.map((input) => {
						const newInput = {
							...input,
							options: options(input),
						};
						if (input.field === "team") {
							return {
								...newInput,
								onChange: getTrainers,
							};
						}
						if (input.field === "trainer") {
							return {
								...newInput,
								onChange: getInterns,
							};
						}
						if (input.inputType === "rangePicker") {
							return {
								...newInput,
								onChange: handleDates,
							};
						}
						return newInput;
				  }),
		id,
		initialValues: modal === "duplicate" ? initialValues : null,
		title:
			modal &&
			getTitle(id && modal !== "duplicate" && modalType !== "add" ? "edit" : "create"),
		submitFn: (values) => {
			const updatedValues = {
				...values,
			};
			if (conditionType === "career_conditions") {
				const foundedStructure = allStructures.find(
					(structure) => structure.id === values.structureId,
				);
				updatedValues.structure = {...foundedStructure};
			}
			editTable({
				values: updatedValues,
				type: modal !== "duplicate" ? modal : conditionType,
				closeModal,
				tableId,
			});
		},
		getUrl: modalType !== "add" && `/v2/condition/get/:id/${modal}`,
	};
	const initializeValues = ({startDate, endDate, team, trainer}) => {
		setDates({startDate, endDate});
		setTeamId(team.id);
		setTrainerId(trainer.id);
	};
	return (
		<>
			<Form
				open={
					modal === conditionType ||
					modal === "duplicate" ||
					modal === `${conditionType}-row`
				}
				handleClose={closeModal}
				formData={formData}
				loading={loading}
				onMount={
					conditionType === "induction_bonus_trainers_conditions"
						? initializeValues
						: false
				}
			/>
			<ConfirmModal
				handleEdit={handleEdit}
				handleClose={closeModal}
				showConfirmModal={modal === "delete" || modal === "copy" || modal === "deleteRow"}
				modalType={modal}
			/>
			<div className="conditions__container">
				<div className="conditions__container-tabs">
					<Tabs
						setActiveTab={(item) =>
							setUrlParams("Conditions", "tab", {
								paramName: "condition-type",
								paramValue: item.type,
							})
						}
						value={conditionType}
						items={
							tenantFeatures.Sales_Organization
								? tabs.filter((i) => i.show)
								: tabs.filter((item) => item?.type !== "manager_vo" && item.show)
						}
					/>
				</div>
				<div className="conditions__container-tables">
					<p className="conditions__container-tables-title">{title?.title}</p>
					{isLoading ? (
						<>
							{lists[conditionType].type === "multiple" ? (
								Array.from({length: 3}).map((_, rowIndx) => (
									<div key={rowIndx} className="row">
										<Skeleton.Input
											active
											style={{
												height: 20,
												borderRadius: 5,
											}}
										/>
										<Skeleton.Input
											active
											style={{
												height: 20,
												minWidth: 40,
												maxWidth: 40,
												borderRadius: 10,
											}}
										/>
									</div>
								))
							) : (
								<div
									style={{
										background: "#e1e1e1",
										borderRadius: "8px",
									}}
								>
									<TableSkeleton withTitle length={4} />
								</div>
							)}
						</>
					) : lists[conditionType].type === "multiple" ? (
						conditionTables?.[0] ? (
							conditionTables.map((item) => (
								<ConditionAccordion
									title={
										conditionType === "career_conditions"
											? item?.structure?.name
											: title?.tableTitle
									}
									expanded={expandedTab === item.id}
									show
									item={{
										startDate: item?.startDate,
										endDate: item?.endDate,
									}}
									onChange={() =>
										setExpandedTab((prevState) =>
											prevState === item.id ? null : item.id,
										)
									}
									handleModal={handleModal}
									editModalType="editTable"
									id={item?.id}
									deletable={conditionType === "career_conditions"}
									deleteModalType="deleteTable"
								>
									<>
										{lists[conditionType].component(
											item,
											conditionTables,
											mergedColumns(item),
											setModal,
											getStufesByStructureId,
											tableId,
										)}
									</>
									{/* )} */}
								</ConditionAccordion>
							))
						) : null
					) : (
						<ConditionAccordion
							title={title?.tableTitle}
							expanded={expandedTab === conditionType}
							onChange={() =>
								setExpandedTab((prevState) =>
									prevState === conditionType ? null : conditionType,
								)
							}
						>
							<ConditionTable
								rows={conditionTables || []}
								columns={mergedColumns()}
								fillTable={fillTable}
								activeTab={conditionType}
							/>
						</ConditionAccordion>
					)}
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	conditionState: state.app.conditions.index,
	loading: state.app.conditions.create.loading,
	teams: state.app.teams.index.teams,
	allSalesOrganizations: state.app.salesOrganizations.index.allSalesOrganizations,
	structuresState: state.app.structures.index,
});

const mapDispatchToProps = {
	getConditions: conditionActions.getConditions,
	setTableValues: conditionActions.setTableValues,
	fillTable: conditionActions.fillTable,
	deleteCondition: deleteConditionActions.deleteTable,
	editTable: createConditionActions.createCondition,
	fetchTeams: teamActions.fetchTeams,
	getAllSalesOrganizations: salesOrgActions.getAllSalesOrganizations,
	getAllStructures: structuresActions.getAllStructures,
	getStufesByStructureId: structuresActions.getStufesByStructureId,
	getValidStuffes: structuresActions.getValidStuffes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Conditions));
