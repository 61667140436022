/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {CollapseIcon, LogoutIcon, LogoutLogo} from "@src/assets/SvgComponents";
import {actions as logoutAction} from "@sagas/auth/logout";
import CustomPopover from "@src/common/Popover";
import {actions as navigation} from "@sagas/navigation/";
import {useTranslation} from "react-i18next";
import DrawerItems from "./items";
import useDrawerItems from "../json/drawerItems.jsx";
import {version} from "../../../package.json";

const Drawer = ({
	loggedUser,
	imgUrl,
	newClass,
	collapse,
	setCollapse,
	submitLogout,
	navigate,
	logoUrl,
}) => {
	const [tenantLogo, setTenantLogo] = useState(localStorage.getItem("tenantLogo"));
	const [popoverVisible, setPopoverVisible] = useState(false);
	const {filterDrawerItems, filterBottomItems} = useDrawerItems();
	const [zoom, setZoom] = useState(false);
	const {t} = useTranslation();

	const handleVisibility = (visible) => {
		setPopoverVisible(visible);
	};

	useEffect(() => {
		const handleResize = () => {
			setZoom(window.devicePixelRatio && window.innerWidth < 1440 && items?.length > 8);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	useEffect(() => {
		setTenantLogo(localStorage.getItem("tenantLogo"));
	}, [logoUrl]);

	const handleClick = () => {
		navigate("/admin/user-profile?from=account-settings");
		setPopoverVisible(false);
	};

	const handleLogout = () => {
		submitLogout();
		setPopoverVisible(false);
	};

	const items = [
		{
			key: "1",
			label: t("profile"),
			onClick: handleClick,
		},
		{
			key: "2",
			label: t("Logout"),
			icon: <LogoutIcon />,
			onClick: handleLogout,
			className: "logout__user",
		},
		{
			key: "version",
			label: `${t("Version")}: ${version}`,
			className: "profile_version",
		},
	];
	return (
		<div
			// style={{minWidth: !collapse ? "100px" : "220px", width: !collapse ? "100px" : "220px"}}
			// className={`drawer ${!collapse ? "" : "collapse"}`}
			className={`${collapse ? "drawer collapse" : "drawer"}`}
			style={{
				justifyContent: zoom ? "flex-start" : "space-evenly",
				gap: zoom ? 30 : 0,
			}}
		>
			<div
				className="drawer__imgcontent"
				style={{
					marginTop: zoom ? "30px" : 0,
				}}
			>
				{tenantLogo ? (
					<img className="drawer__img" src={tenantLogo} alt="user img" />
				) : (
					<div className="drawer__img char">
						<span>{loggedUser?.firstName?.charAt(0)}</span>
						<span>{loggedUser?.lastName?.charAt(0)}</span>
					</div>
				)}
			</div>
			<div className={`drawer-items ${collapse ? "" : "collapse"}`}>
				{filterDrawerItems.map((element, index) => (
					<DrawerItems collapse={collapse} element={element} index={index} key={index} />
				))}
			</div>
			<div className={`drawer-settings drawer-items ${collapse ? "" : "collapse"}`}>
				{filterBottomItems.map((element, index) => (
					<DrawerItems collapse={collapse} element={element} key={index} />
				))}
			</div>
			<div
				className="user__and__logout"
				style={{justifyContent: !collapse ? "center" : "space-between"}}
			>
				<div className="user__infos">
					<CustomPopover
						trigger={["click"]}
						open={popoverVisible}
						customClass="popover__actions-wrapper"
						handleVisibility={handleVisibility}
						menu={{items}}
						placement="top"
					>
						<div className="user__infos__profile">
							{imgUrl || loggedUser?.imgUrl ? (
								<img alt="" src={imgUrl || loggedUser?.imgUrl} />
							) : (
								<div className="drawer__img char">
									<span>{loggedUser?.firstName?.charAt(0)}</span>
									<span>{loggedUser?.lastName?.charAt(0)}</span>
								</div>
							)}
							{collapse && (
								<span className="user__infos-name">
									{loggedUser?.firstName} {loggedUser?.lastName}
								</span>
							)}
						</div>
					</CustomPopover>
				</div>
				<div
					className={`collapse-sidebar ${!collapse ? "collapse" : "test"}`}
					onClick={() => setCollapse(!collapse)}
					style={{
						transform: !collapse ? "rotate(-180deg)" : "rotate(0deg)",
					}}
				>
					<CollapseIcon />
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
	imgUrl: state.app.userProfile.index.imgUrl,
	logoUrl: state.app.userProfile.settings.logoUrl,
});
const mapDispatchToProps = {
	navigate: navigation.navigate,
	submitLogout: logoutAction.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Drawer));
