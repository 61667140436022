import React from "react";
import {CopyIcon, DeleteRowIcon, EditRowIcon} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import moment from "moment";
import {checkRoles} from "@src/utils/checkRoles";

export const filterTeams = (teams, id) => teams?.filter((team) => team?.id !== id);

export const isDateRangeValid = ({team, startDate, endDate}) => {
	const isBeforeEndDate = team?.to
		? (endDate ? moment(endDate).isSameOrBefore(team.to, "day") : true) &&
		  moment(startDate).isSameOrBefore(team?.to, "day")
		: true;
	const hasNoEndDate = !team?.to && endDate;
	const hasNoEndDateAndNoValuesEndDate = endDate && !team?.to;
	return isBeforeEndDate || hasNoEndDate || hasNoEndDateAndNoValuesEndDate;
};

export const tabs = [
	{
		title: i18n.t("Overhead"),
		type: "overhead",
		tableTitle: i18n.t("Overhead"),
		show: checkRoles("seeOverhead"),
	},
	{
		title: i18n.t("OwnContracts"),
		type: "bonus_own_contracts",
		tableTitle: i18n.t("OwnContracts"),
		show: checkRoles("seeOwnContracts"),
	},
	{
		title: i18n.t("MobilityBonus"),
		type: "mobility_conditions",
		tableTitle: i18n.t("MobilityBonus"),
		show: checkRoles("seeMobilityBonus"),
	},
	{
		title: i18n.t("InductionBonus"),
		type: "induction_bonus_trainers_conditions",
		tableTitle: i18n.t("InductionBonus"),
		show: checkRoles("seeInductionBonus"),
	},
	{
		title: i18n.t("PersonnelDevelopment"),
		type: "overhead_personnel_conditions",
		tableTitle: i18n.t("PersonnelDevelopment"),
		show: checkRoles("seePersonnelDevelopment"),
	},
	{
		title: i18n.t("ManagerVO"),
		type: "manager_vo",
		tableTitle: i18n.t("ConditionsHeadOfSalesOrganisation"),
		show: checkRoles("seeManagerVo"),
	},
	{
		title: i18n.t("CareerConditions"),
		type: "career_conditions",
		tableTitle: i18n.t("CareerConditions"),
		show: checkRoles("seeCareerConditions"),
	},
];

export const popoverItems = (conditionType) => [
	{
		key: "edit",
		icon: <EditRowIcon stroke="#252525" />,
		label: i18n.t("EditRow"),
		show: checkRoles("editCondition"),
	},
	...(conditionType !== "career_conditions"
		? [
				{
					key: "copy",
					icon: <CopyIcon stroke="#252525" width="18" height="20" />,
					label: i18n.t("duplicateRow"),
					show: checkRoles("duplicateCondition"),
				},
		  ]
		: []),
	{
		key: "delete",
		icon: <DeleteRowIcon stroke="#252525" />,
		label: i18n.t("DeleteRow"),
		show: checkRoles("deleteCondition"),
	},
];

export const TYPES = [
	{value: "CONTRACTS", label: i18n.t("Contracts")},
	{value: "POINTS", label: i18n.t("Points")},
	{value: "FIXED_MONTHLY", label: i18n.t("monthlyFix"), conditionType: "bonus_own_contracts"},
];
export const STATUSES = [
	{
		value: "TEAM_LEADER",
		label: i18n.t("TeamLeader"),
	},
	{
		value: "HEAD_OF_DEPARTMENT",
		label: i18n.t("BranchManager"),
	},
	{
		value: "TRAINER",
		label: i18n.t("Trainer"),
	},
	{
		value: "TEAM_MEMBER",
		label: i18n.t("TeamMember"),
		conditionType: "mobility_conditions",
	},
];
export const OVERHEAD_STATUSES = [
	{
		value: "TEAM_LEADER",
		label: i18n.t("TeamLeader"),
	},
	{
		value: "HEAD_OF_DEPARTMENT",
		label: i18n.t("BranchManager"),
	},
];
export const SPECIFICATIONS = [
	{
		value: "WITH_PRODUCTION_TRAINEE",
		label: i18n.t("withProductionTrainee"),
	},
	{
		value: "WITHOUT_PRODUCTION_TRAINEE",
		label: i18n.t("withoutProductionTrainee"),
	},
];
export const PERSONNEL_SPECIFICATIONS = [
	{
		value: "PROMOTION_INSTRUCTOR",
		label: i18n.t("promotionTrainer"),
	},
	{
		value: "PROMOTION_TEAM_LEADER",
		label: i18n.t("promotionLeader"),
	},
];
export const ALL_SPECIFICATIONS = [...SPECIFICATIONS, ...PERSONNEL_SPECIFICATIONS];
export const START_CONDITION_BONUS_OPTIONS = [
	{
		value: "BEGIN_INTERN",
		label: i18n.t("beginIntern"),
	},
	{
		value: "BEGIN_INTERN_PLUS_ONE_MONTH",
		label: i18n.t("beginInternPlus1Month"),
	},
];
export const OVERHEAD_FOR = [
	{
		value: "USER_ROLES",
		label: i18n.t("UserRoles"),
	},
	{
		value: "DISTRIBUTION_CHANNEL",
		label: i18n.t("DistributionChannel"),
	},
];
