import {CancelledIcon, TotalOrdersLogo} from "@src/assets/SvgComponents";
import {formatNumberDe} from "@src/utils/convertNumber";
import React from "react";
import {useTranslation} from "react-i18next";

const OrderStats = ({colors, orders, cancelled, title, totalOrders}) => {
	const {t} = useTranslation();
	return (
		<div className="userstats-left-wrapper">
			<span className="userstats-left-wrapper-title">{title}</span>
			<span className="userstats-left-wrapper-number">{formatNumberDe(totalOrders)}</span>
			<div className="userstats-left-wrapper-orders">
				<div
					className="userstats-left-wrapper-orders-icon"
					style={{backgroundColor: `${colors?.EMPLOYEE_TL}90`}}
				>
					<span className="orders__icon">
						<TotalOrdersLogo />
					</span>
					<span className="orders__title">{t("Orders")}</span>
				</div>
				<span className="orders__count">{formatNumberDe(orders)}</span>
			</div>
			<div className="userstats-left-wrapper-orders cancelled">
				<div className="userstats-left-wrapper-orders-icon cancelled">
					<span className="orders__icon">
						<CancelledIcon stroke="#fff" width="12" height="12" strokeWidth="2" />
					</span>
					<span className="orders__title">{t("Cancelled")}</span>
				</div>
				<span className="orders__count cancelled">{formatNumberDe(cancelled)}</span>
			</div>
		</div>
	);
};

export default OrderStats;
