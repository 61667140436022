/* eslint-disable import/no-cycle */
import React from "react";
import {DatePicker} from "antd";
import moment from "moment";
import {generateConfig, locale} from "@utils/datepicker-config";
import i18n from "../../../i18n";
import HomePage from "./components/HomePage";
import LeadStatistics from "./components/LeadStatistics";

export const RenderStatuses = (props) => {
	const {values, setFieldValue, touched, errors} = props;
	const {leadStatusType} = values;
	switch (leadStatusType) {
		case "DATE":
			return (
				<div className="modal__datepicker">
					<span className="title">{i18n.t("SelectDate")}</span>
					<DatePicker
						showTime={{format: "HH:mm"}}
						onChange={(date) => {
							if (date !== null) {
								setFieldValue("date", moment(date).valueOf());
							} else {
								setFieldValue("date", null);
							}
						}}
						dropdownClassName="datepicker__dropdown"
						format="DD/MM/YYYY HH:mm"
						locale={locale}
						generateConfig={generateConfig}
					/>
					{errors.date && touched.leadStatus && (
						<span className="errorsModal">{errors.date}</span>
					)}
				</div>
			);
		default:
			return null;
	}
};
export const userRolesDropdown = [
	"AGENCY",
	"AGENCY-SP",
	"AGENCY_70",
	"SP",
	"EMPLOYEE",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_TL",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_AB",
];
export const RenderTab = ({
	currentTab,
	openFilters,
	showActionsModal,
	setShowActionsModal,
	setSelectedRows,
	selectedRows,
	setShowModal,
	query,
	viewType,
	scrollingStatus,
	setScrollingStatus,
}) => {
	switch (currentTab) {
		case i18n.t("SalesTracker"):
			return (
				<HomePage
					openFilters={openFilters}
					showActionsModal={showActionsModal}
					setShowActionsModal={setShowActionsModal}
					setSelectedRows={setSelectedRows}
					selectedRows={selectedRows}
					setShowModal={setShowModal}
					query={query}
					viewType={viewType}
					setScrollingStatus={setScrollingStatus}
					scrollingStatus={scrollingStatus}
				/>
			);
		case i18n.t("statistics"):
			return <LeadStatistics />;
		default:
			return (
				<HomePage
					openFilters={openFilters}
					showActionsModal={showActionsModal}
					setShowActionsModal={setShowActionsModal}
					setSelectedRows={setSelectedRows}
					selectedRows={selectedRows}
					setShowModal={setShowModal}
					query={query}
					viewType={viewType}
				/>
			);
	}
};
export const statisticColumns = [
	{
		key: "name",
		name: i18n.t("Username"),
		formatter: ({row}) => (
			<span>
				{row?.firstName} {row?.lastName}
			</span>
		),
	},
	{
		key: "countLeads",
		name: i18n.t("totalLeads"),
		sortable: true,
	},
];
export const statisticColumnsChildren = [
	{
		key: "statusName",
		name: i18n.t("Status"),
	},
	{
		key: "count",
		name: i18n.t("totalLeads"),
	},
];
