import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as contaclessOrderActions} from "@sagas/contactlessOrder/index";
import jwtDecode from "jwt-decode";
import PinInput from "./pinInput";

const PinValidation = (props) => {
	const {match, validateToken, contactlessState, verifyPin} = props;
	const {token, orderId, tokenPin} = match.params;
	const {validToken, loading, isPinCorrect} = contactlessState;

	// make a function to validate token & pin, just need to send them in API;
	// if API response is the token & pin are valid continue to input the pin
	// if API response is the token & pin are not valid show a button to request new pin

	const handlePinComplete = (pin) => {
		const decodedTokenPin = jwtDecode(tokenPin);
		verifyPin({
			orderId: +decodedTokenPin?.id,
			enteredPin: pin,
			token,
			tokenPin,
		});
	};

	useEffect(() => {
		const decodedTokenPin = jwtDecode(tokenPin);
		validateToken({
			orderId: +decodedTokenPin?.id,
			token,
			tokenPin,
		});
	}, []);

	if (loading) {
		return <div>loading validating token!</div>;
	}
	return (
		<div
			className="pinInput__container"
			style={{
				padding: "20px",
				filter: !validToken || loading ? "blur(10px)" : "none",
				transition: "filter 0.3s ease-out",
			}}
		>
			<div className="pinInput__container-text">
				Enter your pin code<span>.</span>
			</div>
			<div className="pinInput__container-pin">
				<span>PIN CODE</span>
				<PinInput length={4} onComplete={handlePinComplete} disabled={loading} />
				{!isPinCorrect && isPinCorrect !== "unknown" && (
					<div className="pinInput__container-pin--errorPin">*Incorrect Pin</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	contactlessState: state.app.contaclessOrder.index,
});

const mapDispatchToProps = {
	validateToken: contaclessOrderActions.validateToken,
	verifyPin: contaclessOrderActions.verifyPin,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PinValidation));
