/* eslint-disable import/no-cycle */
import DataGridTable from "@src/common/table/DataGridTable";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Modal from "@common/modal";
import CloseIcon from "@src/common/closeIcon/CloseIcon";
import Loader from "@src/common/Loader";
import {statisticColumns, statisticColumnsChildren} from "../../helper";

function LeadStatistics({
	getLeadStatistics,
	leadStatistics,
	loading,
	size,
	page,
	totalSize,
	totalPages,
	editSize,
	editPage,
	search,
	getLeadStatisticsById,
	singleLeadStatistic,
	relationLoading,
}) {
	const [reEditPage, setReEditPage] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [user, setUser] = useState(null);
	useEffect(() => {
		getLeadStatistics();
	}, [size, page, search]);
	const handleRowClick = (row) => {
		setShowModal(true);
		setUser(`${row?.firstName} ${row?.lastName}`);
		getLeadStatisticsById(row?.userId);
	};
	return (
		<div className="leadStatistics-container">
			<Modal
				open={showModal}
				handleClose={() => setShowModal(false)}
				disableEnforceFocus
				className="add_edit_user"
				width={800}
				positionModal="right"
				overflow="hidden"
			>
				<div className="modal-container">
					<div className="header">
						<span>{user}</span>
						<CloseIcon click={() => setShowModal(false)} />
					</div>
					{relationLoading ? (
						<Loader />
					) : (
						<DataGridTable
							data={singleLeadStatistic || []}
							columns={statisticColumnsChildren}
							pagination={false}
						/>
					)}
				</div>
			</Modal>
			<div className="loader__and__table">
				<DataGridTable
					enableCellSelect={false}
					data={leadStatistics || []}
					wrapperStyles={{
						width: "100%",
						height: "100%",
					}}
					loading={loading}
					pagination={true}
					size={size}
					page={page}
					totalPages={totalPages}
					totalSize={totalSize}
					editSize={editSize}
					editPage={editPage}
					setReEditPage={setReEditPage}
					reEditPage={reEditPage}
					onRowClick={(index, row) => handleRowClick(row)}
					columns={statisticColumns}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	leadStatistics: state.app.salesTracker.index.leadStatistics,
	loading: state.app.salesTracker.index.loading,
	page: state.app.salesTracker.index.page,
	size: state.app.salesTracker.index.size,
	totalSize: state.app.salesTracker.index.totalSize,
	totalPages: state.app.salesTracker.index.totalPages,
	query: state.app.salesTracker.index.query,
	search: state.app.salesTracker.index.search,
	singleLeadStatistic: state.app.salesTracker.index.singleLeadStatistic,
	relationLoading: state.app.salesTracker.index.relationLoading,
});
const mapDispatchToProps = {
	getLeadStatistics: salesTrackerActions.getLeadStatistics,
	getLeadStatisticsSuccess: salesTrackerActions.getLeadStatisticsSuccess,
	editPage: salesTrackerActions.editPage,
	editSize: salesTrackerActions.editSize,
	getLeadStatisticsById: salesTrackerActions.getLeadStatisticsById,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadStatistics);
