import _ from "lodash";

export const filterOptions = (options, inputValue) => {
	if (inputValue) {
		const filteredState = options?.filter((opt) =>
			opt?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()),
		);
		return filteredState;
	}
	return options;
};

const concatState = (prevValues = [], value) => {
	let state = [];
	if (value?.length > 0) {
		state = [...value, ...prevValues];
	} else {
		state = [value, ...prevValues];
	}
	return _.uniqBy(state, "value");
};
export const filterState = (prevState = [], value, type = "value") => {
	if (value?.length > 0) {
		const childIds = value?.map((child) => child?.[type]);
		const filteredArr = prevState?.filter((val) => !childIds?.includes(val?.[type]));
		return filteredArr;
	}
	return prevState?.filter((val) => val.value !== value?.value);
};

export const handleOptionClick = (
	setFieldValue,
	values,
	target,
	field = "selectedUsers",
	handleSubmit,
	type,
	setStateOptions,
	options,
	groupType,
	setReEdit,
) => {
	const updateOptions = options.map((item) => {
		if (item?.type === groupType) {
			return {
				...item,
				options: item?.options.map((i) => {
					if (i.value === target.value) return {...i, checked: !target?.checked};
					return i;
				}),
			};
		}
		return item;
	});
	if (target?.checked) {
		setReEdit(false);
		const newState = filterState(values, target?.options || target, type);
		setStateOptions(updateOptions);
		setFieldValue(field, newState);
	} else {
		const newState = concatState(values, target?.options || target);
		setReEdit(false);
		setStateOptions(updateOptions);
		setFieldValue(field, newState);
	}
	if (handleSubmit && type === "id") {
		handleSubmit();
	}
};
