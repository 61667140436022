import {GrabIcon} from "@src/assets/SvgComponents";
import React from "react";
import {fieldBasedOnLanguage} from "@src/utils/searchCustomFields";
import CheckBoxComponent from "../checkbox";
import editLogo from "../../assets/img/editIcon.svg";
import closeIcon from "../../assets/img/close-icon.svg";
const CustomFieldItem = (props) => {
	const {
		item,
		fromCustomField,
		checked,
		handleCheckbox,
		handleEdit,
		handleRemove,
		canRemove,
		customClass,
		disabled,
	} = props;
	const currentLanguage = localStorage.getItem("language");

	return (
		<div className={`custom__field__item-container ${customClass}`}>
			{fromCustomField ? (
				<span className="grab-icon">
					<GrabIcon />
				</span>
			) : (
				<div className="custom__field__item-container-checkbox">
					<CheckBoxComponent
						checked={checked}
						handleChange={(val) => handleCheckbox(val, item)}
						disabled={disabled}
					/>
				</div>
			)}
			<span className="field__title">{item[fieldBasedOnLanguage[currentLanguage]]}</span>
			{canRemove && (
				<div className="custom__field__item-container-actions">
					<img alt="" src={editLogo} onClick={handleEdit} />
					<img alt="" src={closeIcon} onClick={handleRemove} />
				</div>
			)}
		</div>
	);
};

export default CustomFieldItem;
