import React, {useState} from "react";
import MessagesLeftSide from "./MessagesLeftSide";
import MessagesRightSide from "./MessagesRightSide";
import RightSideHeader from "../RightSideHeader";
import InsideTopic from "../InsideTopic";

const MessagesContainer = () => {
	const [messageToReply, setMessageToReply] = useState(null);
	const isDeactivated = InsideTopic?.topicMembers?.every((user) => !user?.writeAccess);
	return (
		<div className="messages__bottom__container">
			<RightSideHeader setMessageToReply={setMessageToReply} isDeactivated={isDeactivated} />
			<div className="messages__bottom__container-leftRight">
				<MessagesLeftSide />
				<MessagesRightSide />
			</div>
		</div>
	);
};

export default MessagesContainer;
