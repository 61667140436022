import CalendarIcon from "@assets/img/calendar_icon.svg";

export const fields = [
	{
		title: "PersonalInformation",
		items: [
			{key: "firstName"},
			{key: "lastName"},
			{key: "dateOfBirth", icon: CalendarIcon, type: "date"},
			{key: "gender"},
			{key: "mobilePhone"},
			{key: "email"},
		],
	},
	{
		title: "Address",
		items: [{key: "street"}, {key: "houseNumber"}, {key: "postCode"}, {key: "city"}],
	},
	{
		title: "",
		items: [
			{key: "entryDate", icon: CalendarIcon, type: "date"},
			{key: "exitDate", icon: CalendarIcon, type: "date"},
		],
	},
];
