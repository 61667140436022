import React from "react";
import moment from "moment";
import Loader from "@src/common/Loader";
import {NoDataIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import Card from "./Card";

function UserActivities({userActivities, loading}) {
	const {t} = useTranslation();
	const groupCardsByDate = (cards) => {
		if (!cards?.[0]) return cards;
		const now = moment();

		const grouped = {
			lastWeek: [],
			lastMonth: [],
			last3Months: [],
			last6Months: [],
			lastYear: [],
			older: [],
		};

		cards.forEach((card) => {
			const achievedDate = moment(card.achievedDate);

			if (achievedDate.isAfter(now.clone().subtract(1, "week"))) {
				grouped.lastWeek.push(card);
			} else if (achievedDate.isAfter(now.clone().subtract(1, "month"))) {
				grouped.lastMonth.push(card);
			} else if (achievedDate.isAfter(now.clone().subtract(3, "months"))) {
				grouped.last3Months.push(card);
			} else if (achievedDate.isAfter(now.clone().subtract(6, "months"))) {
				grouped.last6Months.push(card);
			} else if (achievedDate.isAfter(now.clone().subtract(1, "year"))) {
				grouped.lastYear.push(card);
			} else {
				grouped.older.push(card);
			}
		});

		return grouped;
	};

	const groupedCards = groupCardsByDate(userActivities);
	return (
		<div className="useractivities-wrapper">
			<div className="cards">
				{loading ? (
					<div className="empty">
						<Loader />
					</div>
				) : (
					<>
						{userActivities?.[0] ? (
							Object.entries(groupedCards).map(
								([key, cards], groupIndex, groupArray) => {
									const isLastNonEmptyGroup = groupArray
										.slice(groupIndex + 1)
										.every(([_, nextCards]) => !nextCards.length);
									return cards.map((card, cardIndex) => (
										<Card
											key={`${key}-${cardIndex}`}
											title={key}
											cards={cards}
											isLastCard={
												isLastNonEmptyGroup &&
												cardIndex === cards.length - 1
											}
										/>
									));
								},
							)
						) : (
							<div className="empty">
								<div>
									<NoDataIcon width="64" height="79" fill="#979797" />
								</div>
								<span className="empty-text">{t("NoData")}!</span>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default UserActivities;
