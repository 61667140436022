import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import React, {useRef, useState} from "react";
import {useUrlParams} from "@src/utils/useUrlParams";
import {SourceBox} from "./SourceBox";
import {TargetBox} from "./TargetBox";
import EditFieldModal from "./EditFieldModal";
import DraggableFields from "./DraggableFields";

const EDIT_MULTIPLE_TYPES = ["editWidth", "editX", "editY", "editAlign", "editField"];

const DragAndDrop = ({
	draggableItems,
	droppableItems,
	file,
	moveBox,
	handleLinesDrop,
	lines,
	setLines,
	onSearch,
	searchValue,
	handleShowDeleteModal,
	orderFields,
}) => {
	const [selectedItems, setSelectedItems] = useState([]);
	const [openPopover, setOpenPopover] = useState(false);
	const {getUrlParams, setModal} = useUrlParams();
	const {modal, id} = getUrlParams("modal", "id");
	const imgRef = useRef();

	const mapSourceBox = (data) =>
		data.map((item, index) => (
			<SourceBox key={`${index}--${index}`} item={item} insideTarget={false} />
		));

	const handleCloseEditModal = () => setModal(false);

	return (
		<div className="create-pdf__wrapper">
			<EditFieldModal
				open={EDIT_MULTIPLE_TYPES.includes(modal)}
				handleClose={handleCloseEditModal}
				modal={modal}
				selectedItems={selectedItems}
				moveBox={moveBox}
				setSelectedItems={setSelectedItems}
				modalId={id}
				imgRef={imgRef}
			/>
			<div className="dragablePdf">
				<div className="dragablePdf__left">
					<div className="draggableContainer">
						<div className="draggableContainer-search">
							<BigSearchBar handleSearch={onSearch} value={searchValue} />
						</div>
						{mapSourceBox(draggableItems)}
					</div>
				</div>
				<div className="dragablePdf__right">
					<TargetBox
						moveBox={moveBox}
						imgRef={imgRef}
						droppableItems={droppableItems}
						draggableItems={draggableItems}
						handleLinesDrop={handleLinesDrop}
						lines={lines}
						setLines={setLines}
					>
						<div className="dragablePdf__right__droppable">
							{Object?.keys(droppableItems)?.map((key) => (
								<DraggableFields
									key={key}
									item={droppableItems[key][0]}
									moveBox={moveBox}
									droppableItems={droppableItems}
									draggableItems={draggableItems}
									setSelectedItems={setSelectedItems}
									selectedItems={selectedItems}
									handleShowDeleteModal={handleShowDeleteModal}
									orderFields={orderFields}
									openPopover={openPopover}
									setOpenPopover={setOpenPopover}
									imgRef={imgRef}
								/>
							))}
						</div>
						<div ref={imgRef}>
							{file?.imageUrlList?.map((item, index) => {
								if (index === 0) {
									return (
										<img
											key={index}
											className="dragablePdf__right__droppable-image"
											alt="/"
											src={item}
										/>
									);
								}
								return (
									<img
										key={index}
										className="dragablePdf__right__droppable-image"
										alt="/"
										src={item}
									/>
								);
							})}
						</div>
					</TargetBox>
				</div>
			</div>
		</div>
	);
};

export default DragAndDrop;
