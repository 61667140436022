/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import DataGrid, {TextEditor} from "react-data-grid";
import Back from "@assets/img/back__icon.svg";
import {actions as navigation} from "@sagas/navigation";
import {actions as createImports} from "@sagas/imports/create";
import {actions as orderImportAcions} from "@sagas/orders/orderImports";
import Loader from "@common/Loader";
import ButtonComponent from "@common/button";
import Modal from "@common/modal";
import _ from "lodash";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import ColumnHeader from "@components/dashboard/orders/imports/ColumnHeader";
import {checkRoles} from "@src/utils/checkRoles";
import i18n from "../../../../i18n";
import ErrorsModal from "./ErrorsModal";
import {fields, requiredCellsLeads, VSE_FIELDS} from "./HeaderCellsData";

const Orders = (props) => {
	const {
		navigate,
		csvData,
		importCsv,
		loading,
		setShowModal,
		errors,
		showModal,
		clearErrorsData,
		setCsvData,
		setRowsElem,
		importType,
		showImportModal,
		importOrderCsv,
		updateOptionsOnState,
		clientId,
		loggedUser,
	} = props;
	const clientFields = clientId ? fields?.[clientId] : VSE_FIELDS;
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const language = localStorage.getItem("language");
	const customFieldLabel = {
		en: "labelEn",
		de: "labelDe",
	};
	useEffect(() => {
		const columnArr = [];
		const arr = [];
		if (!!csvData?.columns?.length && csvData?.data) {
			setColumns(csvData.columns);
			csvData.columns.map((item) => {
				columnArr.push({...columnObj, ...item});
			});
			setColumns(columnArr);
			csvData.data.map((row) => {
				arr.push(row);
				setRows(arr);
			});
		}
		return () => clearErrorsData();
		// eslint-disable-next-line
	}, []);
	const allCells =
		importType === "fromOrder"
			? clientFields?.map((field) => ({
					name: field?.[customFieldLabel?.[language]],
					value: field?.value,
					isRequired: field?.isRequired,
					fieldId: field?.fieldId,
					labelEn: field?.labelEn,
					labelDe: field?.labelDe,
			  }))
			: requiredCellsLeads;
	useEffect(() => {
		updateOptionsOnState(allCells.filter((cel) => !selectedOptions?.includes(cel.value)));
	}, [selectedOptions, allCells]);
	const columnObj = {
		editor: TextEditor,
		headerRenderer: ({column}) => (
			<ColumnHeader
				column={column}
				handleChange={handleChange}
				setSelectedOptions={setSelectedOptions}
				csvData={csvData}
				setCsvData={setCsvData}
			/>
		),

		editable: true,
		resizable: true,
		minWidth: 220,
	};
	let columnReplica = [].concat(csvData.columns),
		obj = {};
	const {fileName} = csvData;

	const handleChange = (e, value, column) => {
		// Here i index of column, to edit that column | Variables description: key is the key from the column that dropdown is selected, selectedName is value selected from dropdown.
		const {key} = column;
		const index = csvData.columns.findIndex((item) => item.key === key);
		const selectedName = value.value;
		// Checking if column in that index exists and then inserting new column name.
		if (columnReplica[index].name) {
			if (importType === "fromOrder") {
				obj = {
					key: csvData.columns?.[index]?.key,
					name: selectedName,
					isRequired: value?.isRequired,
					isCustomField: !!value?.fieldId,
					labelDe: value.labelDe,
					labelEn: value.labelEn,
					matched: !csvData.columns?.[index]?.matched,
				};
			} else {
				obj = {
					key: csvData.columns?.[index]?.key,
					name: selectedName,
					matched: !csvData.columns?.[index]?.matched,
				};
			}
			columnReplica = _.uniqBy([obj, ...columnReplica], "key");
		}
		// csvData is Data that back-end accept to import CSV, and here i insert concated older columns with new values inserted.
		setCsvData({
			fileName,
			columns: columnReplica,
		});
	};
	const handleUpdateRows = (elem) => {
		setRows(elem);
	};
	useEffect(() => {
		setRowsElem(rows);
		// eslint-disable-next-line
	}, [rows]);

	return (
		<>
			<Modal
				className="add_edit_user"
				open={showModal}
				handleClose={() => setShowModal(false)}
			>
				<ErrorsModal errors={errors} setShowModal={setShowModal} />
			</Modal>
			<div className="import__order__container">
				<img
					className="back__icon__import"
					onClick={() => {
						if (importType === "fromOrder") {
							navigate(`/admin/imports/create-order`);
							showImportModal(false);
						} else {
							navigate(`/admin/imports/create-leads`);
						}
					}}
					src={Back}
					alt=""
				/>
				<ButtonComponent
					classNames={`confirm__uload__csv ${rows.length ? "uploaded" : ""}`}
					onClick={() => {
						if (importType === "fromOrder") {
							if (allCells.every((cel) => selectedOptions?.includes(cel.value))) {
								importOrderCsv();
							} else {
								ToastInfoComponent(i18n.t("SelectAllOptionsInfo"));
							}
						} else {
							importCsv(checkRoles("importAsLeader") ? ["TEAM_LEADER"] : null);
						}
					}}
					buttonText={i18n.t("ConfirmUpload")}
					disabled={loading}
				/>
				{rows.length > 0 && (
					<div className="uploaded__csv__container">
						{!!errors.length && (
							<ButtonComponent
								classNames="show__errors"
								onClick={() => setShowModal(true)}
								buttonText={i18n.t("Show")}
								disabled={loading}
							/>
						)}
						<DataGrid
							className={`rdg-light ${loading ? "blur" : ""}`}
							columns={columns}
							rows={rows}
							enableCellSelect={true}
							onRowsChange={(e) => handleUpdateRows(e)}
							rowHeight={32}
							headerRowHeight={100}
						/>
						{loading && (
							<div className="loader__contianer">
								<Loader />
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	csvData: state.app.imports.create.csvData,
	loading: state.app.imports.create.loading,
	showModal: state.app.imports.create.showModal,
	errors: state.app.imports.create.errorsData,
	rowsElem: state.app.imports.create.rowsElem,
	importType: state.app.imports.create.importType,
	clientId: state.app.imports.create.clientIdName,
	loggedUser: state.app.users.index.loggedUser,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	importCsv: createImports.importCsv,
	setShowModal: createImports.setShowModal,
	setCsvData: createImports.setCsvData,
	clearErrorsData: createImports.clearErrorsData,
	setRowsElem: createImports.setRowsElem,
	showImportModal: orderImportAcions.showModal,
	importOrderCsv: orderImportAcions.importCsv,
	updateOptionsOnState: orderImportAcions.setFilteredOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
