/* eslint-disable max-len */
import React from "react";
import {handleInputShowValue} from "@utils/message-converter";
import moment from "moment";
import i18n from "../../../../i18n";

const MessagePrivateTopic = (props) => {
	const {privateTopic, clicked, topicName, active, color, item} = props;
	const {
		lastMessage,
		notSeenMessages,
		topicMembers,
		lastMessageIsRead,
		type,
		isDeactivated,
		topicId,
	} = item;
	const fullNameInitials = topicName?.split(" ");
	const splittedMessage = lastMessage?.text?.split("|")[i18n.language === "en" ? 0 : 1];
	return (
		<div className={`messages__left__private__topic ${active && "active"}`} onClick={clicked}>
			<>
				{privateTopic ? (
					<>
						{type !== "GROUP" ? (
							<div
								className={`messages__left__private__topic__color ${
									active && "active"
								}`}
								style={{backgroundColor: color}}
							>
								{`${fullNameInitials?.[0]?.charAt(
									0,
								)}${fullNameInitials?.[1]?.charAt(0)}`}
							</div>
						) : (
							<>
								{topicMembers && (
									<div
										className="messages__left__private__topic__color message-group-color"
										style={{bacgroundColor: color}}
									>
										{topicMembers}
									</div>
								)}
							</>
						)}
					</>
				) : (
					<p style={{color: active ? "#fff" : "var(--color-primary)"}}>#&nbsp;&nbsp;</p>
				)}
				<div
					className={`messages__topic__name ${
						lastMessageIsRead === false && "privateUnread"
					}`}
				>
					<p className={`messages__topic__name-topic ${active && "active"}`}>
						{type === "MAINCHANNEL" ? i18n.t("allUsersChannel") : topicName}
						{type === "PRIVATE" && isDeactivated && (
							<span>({i18n.t("Deactivated")})</span>
						)}
					</p>
					{lastMessage && (
						<span className={`last-message ${active && "active"}`}>
							{lastMessage?.type === null
								? handleInputShowValue(lastMessage?.text)
								: splittedMessage}
						</span>
					)}
				</div>
			</>
			<div className={`messages__left__private__topic-right ${active && "active"}`}>
				{lastMessage?.id && (
					<span className="last-message-time">
						{lastMessage?.createdAtTimestamp
							? moment(lastMessage?.createdAtTimestamp).format("HH:mm")
							: ""}
					</span>
				)}
				{notSeenMessages > 0 && (
					<div className="messages__topic__name__unseen">{notSeenMessages}</div>
				)}
			</div>
			{/* {privateUnread && <div className="messages__topic__name__unread">{numOfUnread}</div>} */}
		</div>
	);
};

export default MessagePrivateTopic;
