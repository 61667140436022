import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as tenantActions} from "@sagas/tenants";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";
const logger = new Logger("Tenant create");

const PREFIX = "@app/tenant/create";
export const ADD_TENANT = `${PREFIX}ADD_TENANT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_TENANT_SUCCESS = `${PREFIX}ADD_TENANT_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_TENANT = `${PREFIX}FILL_TENANT`;

const _state = {
	loading: false,
	tenantAdded: false,
	tenantInitialValues: {
		name: "",
		firstName: "",
		lastName: "",
		email: "",
		domain: "",
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_TENANT_SUCCESS:
				draft.clientAdded = action.payload;
				break;
			case FILL_TENANT:
				draft.tenantInitialValues = action.payload;
				break;
			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) {
					draft.tenantInitialValues = {
						name: "",
						firstName: "",
						lastName: "",
						email: "",
						domain: "",
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addTenant: (payload) => createAction(ADD_TENANT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addTenantSuccess: (payload) => createAction(ADD_TENANT_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillTenant: (payload) => createAction(FILL_TENANT, {payload}),
};

export const sagas = {
	*addTenant({payload}) {
		yield put(actions.setLoading(true));
		const {values} = payload;

		try {
			let response;
			if (values.tableData) {
				const obj = {
					id: values?.id,
					name: values?.name,
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					domain: values.domain,
				};
				response = yield axios.put(`/tenant/update`, obj);
				yield put(tenantActions.editTenantOnState(values));
				ToastSuccesComponent(i18n.t("TenantUpdateSuccess"));
			} else {
				response = yield axios.post(`/tenant/create/`, values);
				yield put(actions.addTenantSuccess({...values, id: response.data.data}));
				yield put(tenantActions.addTenantOnState({...values, id: response.data.data}));
				yield put(tenantActions.tenantAddIncreaseTotalSize());

				ToastSuccesComponent(i18n.t("TenantCreateSuccess"));
			}
			yield put(actions.setShowModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_TENANT, sagas.addTenant);
};
