import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {ToggleAccordionIcon} from "@src/assets/SvgComponents";
import React from "react";

const CustomAccordion = (props) => {
	const {
		title,
		children,
		isDefaultExpanded = false,
		colored = false,
		color,
		titleClass,
		className,
	} = props;
	return (
		<Accordion defaultExpanded={isDefaultExpanded} className={className}>
			<AccordionSummary expandIcon={<ToggleAccordionIcon />}>
				<div className="accordion-childs">
					{colored && (
						<div
							style={{
								backgroundColor: color,
								width: "20px",
								height: "20px",
								borderRadius: "10px",
							}}
						></div>
					)}
					<p className={`accordion-title ${titleClass || ""}`}>{title}</p>
				</div>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default CustomAccordion;
