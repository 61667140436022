import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation";
import {actions as clientActions} from "@sagas/client/index";
import {useTranslation} from "react-i18next";
import {actions as userActions} from "@sagas/users/users";
import {UsersIcon} from "@assets/SvgComponents";
import moment from "moment";
import _ from "lodash";
import Tabs from "@common/components/tabs";
import editIcon from "@assets/img/edit_icon.svg";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import Button from "@common/components/button";
import {checkRoles} from "@src/utils/checkRoles";
import {useUrlParams} from "@src/utils/useUrlParams";
import {DatePicker} from "antd";
import UserProfile from "./userDetailsTabs/userProfile/userProfile";
import BenutzerDetails from "./userDetailsTabs/benutzerDetails/benutzerDetails";
import Statistik from "./userDetailsTabs/statistik/statistik";
import "./styles.scss";
import UserActivities from "./userDetailsTabs/UserActivities";

const UsersDetails = (props) => {
	// Destructure props for easier access
	const {
		match,
		getSingleUser,
		getMonthlyDataUser,
		getStatistikDataUser,
		filterUsers,
		filterUsersWithStatus,
		getStatistikStatusCount,
		navigate,
		optionsClients,
		getClients,
		setStatisticQuery,
		cleanQuery,
		setDetailsQuery,
		getUserActivities,
		usersState,
	} = props;
	const {
		search,
		status,
		page,
		size,
		singleUser,
		statisticsQuery,
		detailsQuery,
		loggedUser,
		monthlyDataUser,
		statistikStatusCount,
		userActivities,
		loading,
	} = usersState;
	const {t} = useTranslation();
	const {setUrlParams, getUrlParams} = useUrlParams();
	const {tab} = getUrlParams("tab");
	const {id} = match.params;
	const filterItems = [
		{
			inputType: "antd-dropdown",
			label: t("Clients"),
			options: optionsClients || [],
			field: "clientId",
			isMultiple: false,
			show: true,
			name: "name",
			value: "value",
			key: "name",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "rangePicker",
			placeholder: [t("from"), t("to")],
			field: ["fromDate", "toDate"],
			show: true,
			maxDate: moment().endOf("day").valueOf(),
		},
	];

	useEffect(() => {
		if (!tab) {
			setUrlParams("userProfile");
		}
	}, [tab]);

	// Fetch user data on component mount and when match.params.id or loggedUser changes
	useEffect(() => {
		if (id && loggedUser && tab === "userProfile") {
			getSingleUser(id);
		}
		return () => {
			filterUsers({name: "search", value: ""});
		};
	}, [match.params.id, loggedUser, tab, detailsQuery]);

	useEffect(() => {
		if (id && tab === "userDetails") {
			getMonthlyDataUser(id);
		}
		if (id && tab === "activities") {
			getUserActivities(id);
		}
	}, [detailsQuery, tab]);

	useEffect(() => {
		if (id && tab === "statistic") {
			getClients();
			getStatistikDataUser({id});
			getStatistikStatusCount({id});
		}
	}, [page, size, status, statisticsQuery, tab]);

	// Filter tabs based on user roles
	function filterTabsBasedOnRoles() {
		const baseTabsArray = ["userProfile", "userDetails", "statistic", "activities"];
		return checkRoles("userProfileStatistic")
			? baseTabsArray
			: baseTabsArray.filter((tab) => tab !== "statistic");
	}

	// Function for handle serach
	const handleSearch = (value) => {
		filterUsers({name: "search", value: value.target.value});

		const debounced = _.debounce(() => {
			getStatistikDataUser({id: match.params.id});
		}, 1000);
		debounced();
	};

	const renderTabs = () => {
		switch (tab) {
			case "userProfile":
				return <UserProfile singleUser={singleUser} />;
			case "userDetails":
				return <BenutzerDetails monthlyDataUser={monthlyDataUser} year={detailsQuery} />;
			case "statistic":
				return (
					<Statistik
						filterUsersWithStatus={filterUsersWithStatus}
						statistikStatusCount={statistikStatusCount}
					/>
				);
			case "activities":
				return <UserActivities userActivities={userActivities} loading={loading} />;
			default:
				return <div></div>;
		}
	};

	// Render header actions based on selected tab
	const renderHeaderActions = () => {
		switch (tab) {
			case "userProfile":
				return (
					<Button
						icon={editIcon}
						leftRounded={false}
						rightRounded={false}
						hideTooltip={true}
						handleChange={() => navigate(`/admin/users/edit/${match.params.id}`)}
						smallButtonWithTooltip
					/>
				);
			case "statistic":
				return (
					<div className="user-details--header--right--container">
						<BigSearchBar
							withFilters={true}
							value={search}
							handleSearch={handleSearch}
							style={{flexDirection: "row"}}
							filterItems={filterItems}
							filterValues={statisticsQuery}
							setFilterValues={setStatisticQuery}
							clearFilterValues={cleanQuery}
						/>
					</div>
				);
			case "userDetails":
				return (
					<div className="user-details--header--right--container">
						<DatePicker
							picker="year"
							value={moment(detailsQuery)}
							placeholder={t("selectYear")}
							onChange={(_, dateString) => {
								setDetailsQuery(dateString);
							}}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (tab !== "userDetails") {
			setDetailsQuery("");
		}
	}, [tab]);

	const theme = localStorage.getItem("theme");
	const parsedTheme = JSON.parse(theme);

	return (
		<div className="dashboard__container">
			<div className="header-component">
				<div className="dashboard__left__text-header">
					<div className="header-component-wrapper">
						<UsersIcon fill={parsedTheme?.primary} />
						<span className="header-component-text">{t("Users")}</span>
					</div>
					<Tabs
						items={filterTabsBasedOnRoles()}
						onClick={(_, paramValue) => setUrlParams(paramValue)}
						value={t(tab)}
					/>
				</div>
				<div className="user-details--header--right">{renderHeaderActions()}</div>
			</div>
			<div
				className={`user-details--body ${tab !== "statistic" && "user-details--body--gap"}`}
			>
				{renderTabs()}
			</div>{" "}
		</div>
	);
};

const mapStateToProps = (state) => ({
	usersState: state.app.users.index,
	optionsClients: state.app.client.index.optionsClients,
});

const mapDispatchToProps = {
	filterUsers: userActions.filterUsers,
	getSingleUser: userActions.getSingleUser,
	setStatisticQuery: userActions.setStatisticQuery,
	cleanQuery: userActions.cleanQuery,
	getMonthlyDataUser: userActions.getMonthlyDataUser,
	getStatistikDataUser: userActions.getStatistikDataUser,
	filterUsersWithStatus: userActions.filterUsersWithStatus,
	getStatistikStatusCount: userActions.getStatistikStatusCount,
	navigate: navigation.navigate,
	getClients: clientActions.fetchClientsByDropdown,
	setDetailsQuery: userActions.setDetailsQuery,
	getUserActivities: userActions.getUserActivities,
};

// Export the component connected to Redux store and with router
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersDetails));
