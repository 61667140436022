import * as XLSX from "xlsx";
import i18n from "@src/i18n";
import moment from "moment";
import axios from "axios";
import {getNestedValue} from "../orders";

export const exportCsvHandler = async (columns, page, totalSize, search) => {
	const columnTitles = columns.map((columnDef) => columnDef.title);
	const extraColumns = columns.map((columnDef) => columnDef);
	const response = await axios.get(`/user/all/?page=${page}&size=${totalSize}&search=${search}`);
	const data = response?.data?.data?.data;
	const newData = data?.map((obj) => ({
		...obj,
		dateOfBirth: moment(obj?.dateOfBirth).format("DD/MM/YYYY"),
		vatEntitlementUrl:
			obj?.vatEntitlementUrl === null || obj?.vatEntitlementUrl === ""
				? i18n.t("No")
				: i18n.t("Yes"),
		taxNumberUrl:
			obj?.taxNumberUrl === null || obj?.taxNumberUrl === "" ? i18n.t("No") : i18n.t("Yes"),
		businessLicenseUrl:
			obj?.businessLicenseUrl === null || obj?.businessLicenseUrl === ""
				? i18n.t("No")
				: i18n.t("Yes"),
		hrNumberUrl:
			obj?.hrNumberUrl === null || obj?.hrNumberUrl === "" ? i18n.t("No") : i18n.t("Yes"),
		idNumberUrlBack:
			obj?.idNumberUrlBack === null || obj?.idNumberUrlBack === ""
				? i18n.t("No")
				: i18n.t("Yes"),
		idNumberUrlFront:
			obj?.idNumberUrlFront === null || obj?.idNumberUrlFront === ""
				? i18n.t("No")
				: i18n.t("Yes"),
		vatNumberUrl:
			obj?.vatNumberUrl === null || obj?.vatNumberUrl === "" ? i18n.t("No") : i18n.t("Yes"),
	}));
	const rowsData = newData?.map((rowData) =>
		extraColumns.map((columnDef) => getNestedValue(rowData, columnDef.field)),
	);
	const exportData = [columnTitles, ...rowsData];
	const wb = XLSX.utils.book_new();
	const ws = XLSX.utils.aoa_to_sheet(exportData);
	// to do
	// const wscols = columnTitles?.map((item) => ({wch: 30}));
	// ws["!cols"] = wscols;
	XLSX.utils.book_append_sheet(wb, ws, "Benutzer");
	XLSX.writeFileXLSX(wb, "Benutzerliste.xlsx");
	// COMMENTED EXPORT CSV
	// new CsvBuilder("Benutzerliste.csv").setColumns(columnTitles).addRows(rowsData).exportFile();
};
export const userRolesDropdown = [
	"AGENCY",
	"AGENCY-SP",
	"AGENCY_70",
	"SP",
	"EMPLOYEE",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_TL",
	"EMPLOYEE_AB",
];
export const allRoles = [
	{name: "SP", id: 3},
	{name: "EMPLOYEE", id: 5},
	{name: "AGENCY", id: 6},
	{name: "AGENCY-SP", id: 7},
	{name: "BACK-OFFICE", id: 8},
	{name: "MANAGEMENTINFO", id: 9},
	{name: "EMPLOYEE_SHOP", id: 11},
	{name: "EMPLOYEE_TL", id: 12},
	{name: "EMPLOYEE_AB", id: 13},
	{name: "AGENCY_70", id: 14},
];
