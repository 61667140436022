import React, {useEffect, useRef} from "react";
import Button from "@src/common/components/button";
import {useFormik} from "formik";
import {connect} from "react-redux";
import {actions as createGoalAction} from "@sagas/analytics/goals/create";
import i18n from "@src/i18n";
import {useTranslation} from "react-i18next";
import AnalyticsStepper from "../SteperCommon";
import FirstStep from "../../components/FirstStep";
import AnalyticHeaderComponent from "../../components/AnalyticHeaderComponent";
import AlertsSecondStep from "./secondStep";
import ActivityThirdStep from "./thirdStep";
import {validationSchema} from "../../staticData";

function CostumizeAlerts({handleClose, initialValues, createGoal, loading}) {
	const {t} = useTranslation();
	const activityContentRef = useRef(null);
	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			if (values?.step + 1 < 3) {
				setFieldValue("step", values?.step + 1);
			} else {
				createGoal({values, handleClose});
			}
		},
	});
	const {handleSubmit, values, setFieldValue} = formik;
	useEffect(() => {
		if (activityContentRef.current) {
			activityContentRef.current.scrollTop = 0;
		}
	}, [values?.step]);
	const isLastStep = values?.step === 2;

	const handlePrevious = () => {
		if (values?.step > 0) {
			setFieldValue("step", values?.step - 1);
		}
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<div className="activityReports__container--navigation">
					<AnalyticHeaderComponent
						title={i18n.t("costumizeAlerts")}
						handlePrevious={handlePrevious}
						current={values?.step}
						handleClose={handleClose}
					/>
				</div>
				<div>
					<AnalyticsStepper
						current={values?.step}
						steps={3}
						className="activityReports__container--stepper"
						setFieldValue={setFieldValue}
						disabled={!values.id}
					/>
				</div>
				<div className="activityContent__button" ref={activityContentRef}>
					{values?.step === 0 && <FirstStep formik={formik} />}
					{values?.step === 1 && <AlertsSecondStep formik={formik} />}
					{values?.step === 2 && <ActivityThirdStep formik={formik} />}
					<div className="costumizeAlert__button">
						<Button
							disabled={loading}
							minWidth="100%"
							height="50px"
							fontSize="16px"
							fontWeight="600"
							type="submit"
							text={isLastStep ? t("Submit") : t("Next")}
						/>
					</div>
				</div>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	initialValues: state.app.analytics.createGoals.initialValues,
	loading: state.app.analytics.createGoals.loading,
});

const mapDispatchToProps = {
	createGoal: createGoalAction.addGoal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CostumizeAlerts);
