/* eslint-disable max-len */
export const singleLeadIcon = ({
	fill,
	stroke,
}) => `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="${fill}"/>
<rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="${stroke}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.655 22.7632L14.7075 22.7933L14.7285 22.8052C14.8118 22.8503 14.905 22.8739 14.9996 22.8739C15.0943 22.8739 15.1875 22.8503 15.2708 22.8052L15.2917 22.794L15.345 22.7632C15.6383 22.5893 15.9246 22.4037 16.203 22.2067C16.9238 21.6979 17.5973 21.125 18.2153 20.4952C19.6733 19.0027 21.1875 16.7602 21.1875 13.875C21.1875 12.234 20.5356 10.6602 19.3752 9.49978C18.2148 8.3394 16.641 7.6875 15 7.6875C13.359 7.6875 11.7852 8.3394 10.6248 9.49978C9.4644 10.6602 8.8125 12.234 8.8125 13.875C8.8125 16.7595 10.3275 19.0027 11.7847 20.4952C12.4025 21.125 13.0757 21.6978 13.7963 22.2067C14.0749 22.4037 14.3614 22.5893 14.655 22.7632ZM15 16.125C15.5967 16.125 16.169 15.8879 16.591 15.466C17.0129 15.044 17.25 14.4717 17.25 13.875C17.25 13.2783 17.0129 12.706 16.591 12.284C16.169 11.8621 15.5967 11.625 15 11.625C14.4033 11.625 13.831 11.8621 13.409 12.284C12.9871 12.706 12.75 13.2783 12.75 13.875C12.75 14.4717 12.9871 15.044 13.409 15.466C13.831 15.8879 14.4033 16.125 15 16.125Z" fill="${stroke}"/>
</svg>
`;

export const multipleLeadsIcon = `<svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="5.5" width="29" height="29" rx="14.5" fill="#6C1EB0"/>
<rect x="0.5" y="5.5" width="29" height="29" rx="14.5" stroke="white"/>
<rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="#6C1EB0"/>
<rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="white"/>
</svg>
`;

export const circleOptions = {
	fillColor: "#f00",
	fillOpacity: 0.2,
	strokeColor: "#f00",
	strokeOpacity: 1,
	strokeWeight: 1,
};

export const svgToBase64 = (svgString) => `data:image/svg+xml;base64,${btoa(svgString)}`;
