import {ThreeDotsIcon} from "@src/assets/SvgComponents";
import CustomPopover from "@src/common/Popover";
import React from "react";
import {checkRoles} from "@src/utils/checkRoles";
import {popoverItems} from "./helper";

const TableActions = ({handleAction, row}) => {
	const items =
		row?.statusType === "SUCCESS" || !checkRoles("deleteLeads")
			? popoverItems?.filter((item) => item.key !== "delete")
			: popoverItems;
	return (
		<CustomPopover
			customClass="popover__actions-wrapper"
			arrow
			trigger={["click"]}
			menu={{
				items,
				onClick: ({key}) => handleAction(key, row),
			}}
		>
			<div className="three-dots-icon">
				<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
			</div>
		</CustomPopover>
	);
};

export default TableActions;
