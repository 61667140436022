/* eslint-disable max-len */
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as createMessagesActions} from "@sagas/messages/create";
import MultiSelectComp from "@common/autoCompleteMultiselect";
import {MessagesIcon, SearchIcon} from "@assets/SvgComponents";
import {t} from "i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import ChatInfoModal from "../ChatInfoModal";
import i18n from "../../../i18n";
import SearchMessagesComponent from "../MessagesContainer/SearchMessages";

const MessagesRightSide = (props) => {
	const {
		topicName,
		numOfMembers,
		addingNewMessage,
		usersByTenant,
		setList,
		setNames,
		listOfIds,
		insideTopic,
		setMessageToReply,
	} = props;
	let usersOptions = [];

	usersOptions = usersByTenant?.map((user) => ({
		...user,
		name: `${user?.firstName} ${user?.lastName}`,
		value: user?.id,
		image: user?.image,
	}));
	const {setModal, getUrlParams} = useUrlParams();
	const {modal} = getUrlParams("modal");
	usersOptions = usersOptions?.filter((user) => !listOfIds?.includes(user?.id));
	const {name, topicType, topicMembers} = insideTopic;
	const getDeactivated = () => {
		if (topicType === "PRIVATE") {
			const find = topicMembers.find((i) => i.deactivatedAt !== 0);
			if (find) return true;
		}
		return false;
	};
	const alleUserChannelName = i18n.t("allUsersChannel");
	const groupTopicName = name?.split(",");
	const correctGroupName =
		groupTopicName?.length > 2
			? `${groupTopicName[0]}, ${groupTopicName[1]} ${i18n.t("and")} ${
					groupTopicName?.length - 1
			  } ${i18n.t("others")}`
			: groupTopicName?.join(",");
	const correctTopicName = topicType === "MAINCHANNEL" ? alleUserChannelName : topicName;

	return (
		<>
			<ChatInfoModal
				open={modal === "chat"}
				setShowChatInfoModal={setModal}
				correctGroupName={correctGroupName}
				correctTopicName={correctTopicName}
			/>
			<SearchMessagesComponent
				showSearchModal={modal === "search"}
				setShowSearchModal={setModal}
				setMessageToReply={setMessageToReply}
			/>
			<div className="messages__right__side__header__container">
				<div
					className={
						!addingNewMessage
							? `messages__right__side__header__container-name`
							: `messages__right__side__header__container-name messages__right__side__header__container-name-second `
					}
				>
					<MessagesIcon />
					<span className="messages__right__side__header__container-name-title">
						{t("Messages")}
					</span>
				</div>
				{!addingNewMessage ? (
					<div className="messages__right__side__header__name__and__options">
						<div className="header__name__and__members">
							<div className="header__name__and__members-charName">
								<div className="messages__left__side__container-header-user">
									{correctTopicName && (
										<div className="header__name__and__members-imageName">
											<span>{correctTopicName.charAt(0)}</span>
										</div>
									)}
								</div>
								<div className="header__name__and__members-charName-nameMember">
									<span
										className="messages__right__side__header__name"
										onClick={() => setModal("chat")}
									>
										{insideTopic.topicType === "GROUP"
											? correctGroupName
											: correctTopicName}
										{insideTopic.topicType === "PRIVATE" &&
											getDeactivated() && (
												<span className="messages__right__side__header__name-status">
													({i18n.t("Deactivated")})
												</span>
											)}
									</span>
									{numOfMembers > 0 && (
										<span
											className="messages__right__side__header__number__of__members"
											onClick={() => setModal("chat")}
										>
											{`${numOfMembers} `}
											{numOfMembers > 1
												? i18n.t("Members")
												: i18n.t("Member")}
											<span> +</span>
										</span>
									)}
								</div>
							</div>
						</div>
						{topicName && (
							<div className="search--icon" onClick={() => setModal("search")}>
								<SearchIcon fill="#979797" />
							</div>
						)}
					</div>
				) : (
					<div className="messages__right__side__header__container-multiSelect">
						<MultiSelectComp
							handleChange={(event, value) => {
								setList(value.map((item) => item?.id));
								setNames(value.map((item) => item?.name));
							}}
							options={usersOptions}
							placeholder={i18n.t("SendMessageTo")}
							isMessage={true}
						/>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	topicName: state.app.messages.index.topicName,
	numOfMembers: state.app.messages.index.numOfMembers,
	addingNewMessage: state.app.messages.create.addingNewMessage,
	usersByTenant: state.app.users.index.usersByTenant,
	listOfIds: state.app.messages.create.listOfIds,
	insideTopic: state.app.messages.index.insideTopic,
});

const mapDispatchToProps = {
	setList: createMessagesActions.setList,
	setNames: createMessagesActions.setNames,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessagesRightSide));
