import {ThreeDots} from "@src/assets/SvgComponents";
import CustomPopover from "@src/common/Popover";
import _ from "lodash";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

function TabPopover({items, visibleItems, onClick, value}) {
	const {t} = useTranslation();
	const [openPopover, setOpenPopover] = useState(false);
	const handleVisibility = (visible) => setOpenPopover(visible);
	return (
		<>
			{_.difference(items, visibleItems)?.[0] && (
				<CustomPopover
					menu={{
						items: _.difference(items, visibleItems).map((item, index) => ({
							key: item,
							label: (
								<div className="border-wrapper">
									<span>{t(item)}</span>
									<span
										style={{
											backgroundColor:
												value === t(item)
													? "var(--color-primary)"
													: "transparent",
										}}
										className="border"
									></span>
								</div>
							),
							id: index + 1,
							className: `${value === t(item) ? "active" : ""}`,
						})),
						onClick: ({e, key}) => onClick(e, key),
					}}
					trigger={["click"]}
					customClass="tab-popover"
					open={openPopover}
					placement="bottomRight"
					handleVisibility={handleVisibility}
				>
					<span onClick={(e) => e.stopPropagation()}>
						<ThreeDots stroke="var(--color-primary)" />
					</span>
				</CustomPopover>
			)}
		</>
	);
}

export default TabPopover;
