import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import InputComponent from "@common/input";
import {useTranslation} from "react-i18next";
import Button from "@src/common/components/button";
import saveIcon from "@assets/img/save-icon.svg";
import {actions as userEditAction} from "../../../../store/sagas/app/userProfile/index";
import { useFormik } from "formik";
import { groupedInputs, publicProfileSchema as validationSchema } from "./helper";

const PublicProfile = ({userInitialValues, loading, updateUser}) => {
	const {t} = useTranslation();
	const formik = useFormik({
		initialValues: userInitialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values, actions) => updateUser({values, formActions: actions}),
	})

	const {values,touched,errors,handleBlur,handleChange,handleSubmit} = formik;

	return (
			<form onSubmit={handleSubmit} className="userProfile-wrapper">
				{Object.entries(groupedInputs)?.map((item) => {
					const [key,inputs] = item;
					return (
						<div className="userProfile-section" key={key}>
						<span className="title">{t(key)}</span>
						<div className="userProfile-section-content">
							<div className="userProfile-section-content-inner">
								{inputs?.map((input,index) => {
									return (
										<InputComponent 
											key={`${input.field}--${index}`}
											values={values?.[input?.field]}
											errors={errors?.[input?.field]}
											touched={touched?.[input?.field]}
											label={input?.label}
											name={input?.field}
											handleChange={handleChange}
											handleBlur={handleBlur}
											errorClass="errorClass"
											type={input?.inputType || "text"}
											newClass={input?.disabled ? "disabled__input" : "custom__input"}
											disabled={input?.disabled}
										/>
									)
								})}
							</div>
						</div>
					</div>
					)
				})}
					<Button
							icon={saveIcon}
							type="submit"
							customClassName="saveButton"
							disabled={loading}
							leftRounded={true}
							text={t("Save")}
							smallButtonWithTooltip
						/>
			</form>

	);
};

const mapStateToProps = (state) => ({
	userInitialValues: state.app.users.index.userInitialValues,
	loading: state.app.userProfile.index.loading,
});

const mapDispatchToProps = {
	updateUser: userEditAction.updateUser,
	fillUser: userEditAction.fillUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PublicProfile));