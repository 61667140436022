import React, {useState} from "react";
import {Select} from "antd";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as createImports} from "@sagas/imports/create";
import {getCurrentUser} from "@src/utils/currentUser";
import {useUrlParams} from "@src/utils/useUrlParams";
const {Option} = Select;
const DropDownComp = ({
	row,
	options,
	val,
	matchOrders,
	selectedOrderIds,
	setSelectedOrderIds,
	fromStatus = false,
	changeRowStatus,
	matchingField,
	orderStatus,
}) => {
	const {getUrlParams} = useUrlParams();
	const handleSelectChange = (value, item) => {
		if (fromStatus) {
			changeRowStatus({oldContract: row, status: value});
		} else {
			const params = getUrlParams("id", "fromDate", "toDate", "clientId");
			matchOrders({
				row,
				oldContract: val,
				newContract: value,
				clientId: params?.clientId,
				draftId: params?.id,
				matchingField,
				dates: {fromDate: params?.fromDate, toDate: params?.toDate},
				user: getCurrentUser(),
				id: item?.key,
				orderStatus,
			});
			if (!selectedOrderIds.includes(value)) {
				setSelectedOrderIds([...selectedOrderIds, value]);
			}
		}
	};
	function onClick(e) {
		e.stopPropagation();
		e.preventDefault();
	}
	return (
		<Select
			defaultValue={val}
			showSearch
			style={{width: 200}}
			placeholder="Select an option"
			optionFilterProp="children"
			onChange={handleSelectChange}
			filterOption={(input, option) =>
				String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			onClick={onClick}
		>
			{options
				?.filter((opt) => !selectedOrderIds?.includes(opt.value))
				.map((option) => (
					<Option key={option?.id || option?.value} value={option.value}>
						{option.name}
					</Option>
				))}
		</Select>
	);
};

const mapStateToProps = (state) => ({
	selectedOrderIds: state.app.imports.create.selectedOrderIds,
	matchingField: state.app.imports.create.matchingField,
	query: state.app.imports.create.query,
});

const mapDispatchToProps = {
	setSelectedOrderIds: createImports.setSelectedOrderIds,
	matchOrders: createImports.matchOrders,
	changeRowStatus: createImports.changeRowStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DropDownComp));
