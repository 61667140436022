import React, {useMemo} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Form from "@src/common/Form";
import {ENDPOINT} from "@src/utils/endpoint";
import {useTranslation} from "react-i18next";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import {editFieldInputs, isInsideParent} from "./staticData";

const EditFieldModal = (props) => {
	const {
		handleClose,
		open,
		loading,
		selectedItems,
		moveBox,
		setSelectedItems,
		modal,
		modalId,
		imgRef,
	} = props;
	const justOneField = selectedItems.length === 1;
	const {t} = useTranslation();
	const handleUpdate = (values) => {
		if (!justOneField) {
			selectedItems.forEach((item) => {
				const isWithinBounds = isInsideParent(imgRef, {...item, ...values});
				if (isWithinBounds) {
					moveBox({...item, ...values});
				} else {
					ToastErrorComponent(t("fieldOutsidePdfError"));
				}
			});
		} else {
			const isWithinBounds = isInsideParent(imgRef, values);
			if (isWithinBounds) {
				moveBox(values);
			} else {
				ToastErrorComponent(t("fieldOutsidePdfError"));
			}
		}
		handleClose();
		setSelectedItems([]);
	};
	const inputs = useMemo(() => {
		if (modal === "editField") {
			return editFieldInputs;
		}
		return editFieldInputs?.filter((item) => item.showField === modal);
	}, [modal]);
	const formData = {
		inputs,
		id: justOneField ? modalId : null,
		getUrl: ENDPOINT.GET_PDF_FIELD_BY_ID,
		title: `${t(`editField${justOneField ? "" : "s"}`)}`,
		submitFn: (values) => handleUpdate(values),
	};
	return <Form formData={formData} loading={loading} handleClose={handleClose} open={open} />;
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isUploading,
});
export default connect(mapStateToProps, null)(withRouter(EditFieldModal));
