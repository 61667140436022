import React from "react";
import {ToastSuccessIcon} from "@src/assets/SvgComponents";
import {getStore} from "@src/store";
import {toast} from "react-toastify";
import i18n from "../../i18n";

const toastStyles = {
	color: "var(--color-primary)",
	fontFamily: "Montserrat",
	fontSize: 14,
	fontWeight: 500,
	boxShadow: "0px 2px 4px 4px rgba(60, 60, 60, 0.2)",
	borderRadius: "8px",
};

export const ToastSuccesComponent = (message) =>
	toast.success(message, {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		icon: <ToastSuccessIcon />,
		style: {...toastStyles},
	});

export const ToastErrorComponent = (message) =>
	toast.error(message, {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		style: {...toastStyles},
	});
export const ToastInfoComponent = (message, navigateActions, notificationActions, url) => {
	const {dispatch} = getStore();
	return toast.info(message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		style: {...toastStyles},
		onClick: () => {
			if (navigateActions && url !== "admin/messages?notifications=true") {
				dispatch(navigateActions.navigate(`/admin/messages?notifications=true`));
			}
		},
	});
};
export const ToastInfoStatus = (message, ordersActions, navigateActions) => {
	const {dispatch} = getStore();
	return toast.info(message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		style: {...toastStyles},
		onClick: () => {
			dispatch(ordersActions.setCurrentTab(i18n.t("statusHistory")));
			if (navigateActions) {
				dispatch(navigateActions.navigate(`/admin/orders`));
			}
		},
	});
};
export const ToastComponent = (message, type, ordersActions, tabType) => {
	const {dispatch} = getStore();
	return toast[type](message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		style: {...toastStyles},
		onClick: () => {
			dispatch(ordersActions.setCurrentTab(i18n.t(tabType)));
		},
	});
};
