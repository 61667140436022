import React from "react";
import {handleInputShowValue} from "@src/utils/message-converter";
import {ReactComponent as CloseIconPNG} from "../../../assets/img/close_black_24dp.svg";
import MediaBox from "../MediaComponent/MediaBox";

const ReplyMessageComponent = ({item, setValue}) => {
	const {fullName, text, files} = item;

	return (
		<div className="replyMessage__container">
			<div className="replyMessage__container-main">
				<div className="replyMessage__container-main-nameAndIcon">
					<span className="replyMessage__container-main-nameAndIcon-name">
						{fullName}
					</span>
					<span
						className="replyMessage__container-main-nameAndIcon-icon"
						onClick={() => setValue(null)}
					>
						<CloseIconPNG />
					</span>
				</div>
				<div className="replyMessage__container-main-descAndFile">
					<div className="replyMessage__container-main-descAndFile-text">
						{text && (
							<span className="replyMessage__container-main-descAndFile-text-txt">
								{handleInputShowValue(text)}
							</span>
						)}
						{files?.length > 0 && (
							<>
								<div className="replyMessage__container-main-descAndFile-text-file">
									{files?.map((file, index) => (
										<MediaBox key={index} item={file} isReply />
									))}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReplyMessageComponent;
