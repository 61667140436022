import React, {useEffect, useRef} from "react";
import {actions as teamActions} from "@sagas/teams";
import {actions as userActions} from "@sagas/users/users";
import {connect} from "react-redux";
import {actions as goalActions} from "@sagas/analytics/index";
import {actions as viewGoalsActions} from "@sagas/analytics/goals/views.js";
import Loader from "@src/common/Loader/index.js";
import {EditRowIcon, NoDataIcon} from "@src/assets/SvgComponents.jsx";
import {actions as orderStatusAction} from "@sagas/orders/statuses";
import {useTranslation} from "react-i18next";
import CustomCollapse from "@src/common/CustomCollapse/index.js";
import HtmlTooltip from "@src/common/htmlTooltip.js";
import CustomCollapseSkeleton from "@src/common/CustomCollapse/CustomCollapseSkeleton.js";
import AlertsCards from "./components/AlertsCardComponent.js";
import {columns, userRolesDropdown} from "./components/staticData.js";
import {getGoalsPayload} from "./staticData.js";
import GridSkeleton from "./components/GridSkeleton/index.jsx";

const Analytics = ({
	getUsers,
	getTeams,
	getAgencies,
	getGoals,
	setPage,
	setModal,
	modalState,
	getGoalById,
	getViews,
	query,
	getGoalsByType,
	groupViewType,
	selected,
	setSelected,
	getGoalsTypeById,
	setActiveKey,
	activeKey,
	editSizeGoalTypeById,
	editPageGoalTypeById,
	resetPaginationType,
	resetPagination,
	usersOptions,
	goalsState,
	viewState,
	getOrderStatuses,
}) => {
	const {goals, page, totalPages, scrollLoading, loading: gridLoading} = goalsState;
	const {loading, goalsByType: goalsByTypeState, goalsTypeById: goalsTypeByIdState} = viewState;
	const {t} = useTranslation();
	const {data: goalsByType} = goalsByTypeState;
	const listRef = useRef();
	const {modal, id} = modalState;
	useEffect(() => {
		if (page > 1) {
			resetPagination();
		}
	}, [JSON.stringify(query)]);
	useEffect(() => {
		if (groupViewType === "GridView") {
			getGoals(getGoalsPayload(query));
		} else if (groupViewType !== 0) {
			getGoalsByType({
				groupViewType,
				query: getGoalsPayload(query),
			});
		}
	}, [JSON.stringify(query), groupViewType, page]);
	useEffect(() => {
		getViews();
		getUsers(userRolesDropdown);
		getTeams({isTeam: false, all: true});
		getAgencies({isTeam: true, all: true});
		getOrderStatuses();
	}, []);
	async function handleScroll(event) {
		const {scrollTop, scrollHeight, clientHeight} = event?.currentTarget;
		const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
		if (isAtBottom && page < totalPages && !scrollLoading) {
			setPage();
		}
	}
	useEffect(() => {
		if (modal && id && usersOptions?.[0] && modal === "costumizeAlerts") {
			getGoalById(id);
		}
	}, [modal, id, usersOptions?.length]);
	const actions = {
		key: "actions",
		name: "",
		sortable: false,
		resizable: false,
		width: 50,
		cellClass: "status-cell",
		formatter: ({row}) => (
			<HtmlTooltip
				title={<span>{row?.hasExpired ? t("GoalExpired") : t("editCard")}</span>}
				placement="bottom-end"
			>
				<span
					style={{cursor: "pointer"}}
					onClick={
						row?.hasExpired
							? () => {}
							: () => {
									setActiveKey(null);
									setModal("costumizeAlerts", row.id);
							  }
					}
				>
					<EditRowIcon stroke={row?.hasExpired ? "#C1C1C155" : "#C1C1C1"} />
				</span>
			</HtmlTooltip>
		),
		headerFormatter: () => {},
	};
	useEffect(() => {
		if (goalsByType?.[activeKey]) {
			getGoalsTypeById({
				groupType: groupViewType,
				id: goalsByType[activeKey]?.id || goalsByType[activeKey]?.name,
				query: getGoalsPayload(query),
			});
		}
	}, [goalsTypeByIdState?.page, goalsTypeByIdState?.size, query, activeKey]);
	return (
		<>
			<div
				style={{
					height: groupViewType !== "GridView" && "auto",
					padding: gridLoading ? 0 : 20,
				}}
				className="analytics__container"
				onScroll={handleScroll}
				ref={listRef}
			>
				{goals?.[0] &&
					groupViewType === "GridView" &&
					!gridLoading &&
					goals.map((item, index) => (
						<AlertsCards
							key={index}
							item={item}
							setModal={setModal}
							modalState={{modal, id}}
							setSelected={setSelected}
							selected={selected}
							isSelected={selected.includes(item.id)}
						/>
					))}
			</div>
			<div>
				{scrollLoading && (
					<div className="goals__loading">
						<Loader color="#6C1EB0" />
					</div>
				)}
			</div>
			{groupViewType === "GridView" && gridLoading && <GridSkeleton />}
			<div className="analytics__container-grouped">
				{goalsByType?.[0] &&
					groupViewType !== "GridView" &&
					!loading &&
					goalsByType.map((item, index) => (
						<>
							<CustomCollapse
								index={index}
								item={item}
								activeKey={activeKey}
								setActiveKey={setActiveKey}
								groupViewType={groupViewType}
								state={goalsTypeByIdState}
								editSize={editSizeGoalTypeById}
								editPage={editPageGoalTypeById}
								resetPaginationType={resetPaginationType}
								actions={actions}
								columns={columns(item)}
							/>
						</>
					))}
				{loading && groupViewType !== "GridView" && (
					<div>
						<CustomCollapseSkeleton />
					</div>
				)}
				{!loading && !goalsByType?.[0] && groupViewType === "ListView" && (
					<span className="empty-list-view">
						<NoDataIcon width={70} height={70} fill="#C1C1C1" stroke="#979797" />
						<span style={{color: "#979797"}}>{t("NoData")}</span>
					</span>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	goalsState: state.app.analytics.index,
	viewState: state.app.analytics.view,
	usersOptions: state.app.users.index.usersOptions,
});
const mapDispatchToProps = {
	getUsers: userActions.getUserDropdown,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getGoals: goalActions.fetchGoals,
	getGoalById: goalActions.getGoalById,
	setPage: goalActions.setPage,
	getViews: viewGoalsActions.getViews,
	getGoalsByType: viewGoalsActions.getGoalsByType,
	getGoalsTypeById: viewGoalsActions.getGoalsTypeById,
	editSizeGoalTypeById: viewGoalsActions.editSizeType,
	editPageGoalTypeById: viewGoalsActions.editPageType,
	resetPaginationType: viewGoalsActions.resetPaginationType,
	resetPagination: goalActions.resetPagination,
	getOrderStatuses: orderStatusAction.getOrderStatuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
