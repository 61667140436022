import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {changeOrderFn} from "@utils/functions";
import {actions as deleteTariffActions} from "@sagas/tariff/deleteClientTariff";
import {ENDPOINT} from "@src/utils/endpoint";

const logger = new Logger("Tariff client");
const PREFIX = "@Dixi App/store/sagas/app/tariff/clientTariff";
// clients
export const FETCH_CLIENT_TARIFF = `${PREFIX}FETCH_CLIENT_TARIFF`;
export const FETCH_CLIENT_TARIFF_SUCCESS = `${PREFIX}FETCH_CLIENT_TARIFF_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const ADD_TARIFF_ON_STATE = `${PREFIX}ADD_TARIFF_ON_STATE`;
export const EDIT_TARIFF_ON_STATE = `${PREFIX}EDIT_TARIFF_ON_STATE`;
export const DELETE_TARIFF_ON_STATE = `${PREFIX}DELETE_TARIFF_ON_STATE`;
export const DEACTIVATE_TARIFF_ON_STATE = `${PREFIX}DEACTIVATE_TARIFF_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const TOTAL_SIZE_DECREASE = `${PREFIX}TOTAL_SIZE_DECREASE`;
export const FETCH_TARIFFS_BY_DROPDOWN_ID = `${PREFIX}FETCH_TARIFFS_BY_DROPDOWN_ID`;
export const FETCH_TARIFFS_BY_DROPDOWN_ID_SUCCESS = `${PREFIX}FETCH_TARIFFS_BY_DROPDOWN_ID_SUCCESS`;
export const CHANGE_TARIFF_ORDER = `${PREFIX}CHANGE_TARIFF_ORDER`;
// eslint-disable-next-line max-len
export const CHANGE_TARIFF_ORDER_SUCCESS = `${PREFIX}CHANGE_TARIFF_ORDER_SUCCESS`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const TOGGLE_TARIFF_VISIBILITY = `${PREFIX}TOGGLE_TARIFF_VISIBILITY`;
export const TOGGLE_TARIFF_VISIBILITY_SUCCESS = `${PREFIX}TOGGLE_TARIFF_VISIBILITY_SUCCESS`;
export const FETCH_TARIFF_BY_ID = `${PREFIX}FETCH_TARIFF_BY_ID`;
export const FETCH_TARIFF_BY_ID_SUCCESS = `${PREFIX}FETCH_TARIFF_BY_ID_SUCCESS`;
export const CREATE_TARIFF_SUCCESS = `${PREFIX}CREATE_TARIFF_SUCCESS`;

const _state = {
	clientTariffs: [],
	dropdownTariffs: [],
	isLoading: false,
	page: 1,
	size: 30,
	totalPages: 1,
	totalSize: 5,
	search: "",
	singleTariff: {},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_CLIENT_TARIFF_SUCCESS:
				draft.clientTariffs =
					state?.page > 1 ? [...state?.clientTariffs, ...payload] : payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case LOADING:
				draft.loading = payload;
				break;
			case ADD_TARIFF_ON_STATE:
				draft.clientTariffs = [payload].concat(state.clientTariffs);
				break;
			case EDIT_TARIFF_ON_STATE:
				const index = state.clientTariffs.findIndex((c) => c.id === payload.id);
				draft.clientTariffs[index] = payload;
				break;
			case DEACTIVATE_TARIFF_ON_STATE:
				const clientTariffs = state.clientTariffs.filter((tariff) => tariff.id !== payload);
				if (state.page !== 1 && clientTariffs.length === 0) {
					draft.page = 1;
				} else {
					draft.clientTariffs = clientTariffs;
				}
				break;
			case DELETE_TARIFF_ON_STATE:
				draft.clientTariffs = state.clientTariffs.filter((tariff) => tariff.id !== payload);
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case FETCH_TARIFFS_BY_DROPDOWN_ID_SUCCESS:
				draft.dropdownTariffs = payload?.map((e) => ({
					name: e.name,
					value: e.id,
					label: e.name,
				}));
				break;
			case CHANGE_TARIFF_ORDER_SUCCESS:
				const itemIndex = state.clientTariffs.findIndex((item) => item?.id === payload?.id);
				const newValue = changeOrderFn(state?.clientTariffs, itemIndex, payload?.action);
				draft.clientTariffs = newValue;
				break;
			case TOGGLE_TARIFF_VISIBILITY_SUCCESS:
				draft.clientTariffs = state?.clientTariffs?.map((item) =>
					item.id === payload ? {...item, isHidden: !item.isHidden} : item,
				);
				break;
			case FETCH_TARIFF_BY_ID_SUCCESS:
				draft.singleTariff = payload;
				break;
			case CREATE_TARIFF_SUCCESS:
				draft.clientTariffs = [...state.clientTariffs, payload];
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchClientTariffs: (payload) => createAction(FETCH_CLIENT_TARIFF, {payload}),
	fetchClientTariffsSuccess: (payload) => createAction(FETCH_CLIENT_TARIFF_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	addTarrifOnState: (payload) => createAction(ADD_TARIFF_ON_STATE, {payload}),
	editTarrifOnState: (payload) => createAction(EDIT_TARIFF_ON_STATE, {payload}),
	deleteTarrifOnState: (payload) => createAction(DELETE_TARIFF_ON_STATE, {payload}),
	deactivateTarrifOnState: (payload) => createAction(DEACTIVATE_TARIFF_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterTariffs: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	totalSizeDecrease: (payload) => createAction(TOTAL_SIZE_DECREASE, {payload}),
	fetchTariffsByDropdownId: (payload) => createAction(FETCH_TARIFFS_BY_DROPDOWN_ID, {payload}),
	fetchTariffsByDropdownSuccessId: (payload) =>
		createAction(FETCH_TARIFFS_BY_DROPDOWN_ID_SUCCESS, {payload}),
	changeTariffOrder: (payload) => createAction(CHANGE_TARIFF_ORDER, {payload}),
	changeTariffOrderSuccess: (payload) => createAction(CHANGE_TARIFF_ORDER_SUCCESS, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	toggleTariffVisibility: (payload) => createAction(TOGGLE_TARIFF_VISIBILITY, {payload}),
	toggleTariffVisibilitySuccess: (payload) =>
		createAction(TOGGLE_TARIFF_VISIBILITY_SUCCESS, {payload}),
	fetchTariffById: (payload) => createAction(FETCH_TARIFF_BY_ID, {payload}),
	fetchTariffByIdSuccess: (payload) => createAction(FETCH_TARIFF_BY_ID_SUCCESS, {payload}),
	createTariffSuccess: (payload) => createAction(CREATE_TARIFF_SUCCESS, {payload}),
};

export const sagas = {
	*fetchClientTariffs({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size, page} = yield select((state) => state.app.tariffs.clientTariff);
			const searchType = typeof payload?.search;
			const search = searchType === "string" ? payload?.search : "";
			const response = yield axios.get(
				`/tariff/all/${
					payload?.clientId || payload
				}?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchClientTariffsSuccess(response?.data?.data.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchTariffsByDropdownId(id) {
		yield put(actions.setLoading(true));

		try {
			let response;

			if (id.payload !== undefined) {
				response = yield axios.get(`/tariff/drop-down/${id.payload}`);
			} else {
				response = yield axios.get(`/tariff/drop-down/`);
			}

			yield put(actions.fetchTariffsByDropdownSuccessId(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeTariffOrder({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put("/tariff/change", {
				id: payload?.id,
				newRow: payload?.newRow,
			});
			yield put(actions.changeTariffOrderSuccess(payload));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*toggleTariffVisibility({payload}) {
		yield put(deleteTariffActions.setLoading(true));
		try {
			const {id, closeModal, isHidden} = payload;
			yield axios.post(ENDPOINT.TOGGLE_TARIFF, {
				id,
				isHidden,
			});
			yield put(actions.toggleTariffVisibilitySuccess(payload.id));
			closeModal();
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(deleteTariffActions.setLoading(false));
		}
	},
	*fetchTariffById({payload}) {
		try {
			const response = yield axios.get(`/tariff/${payload}`);
			yield put(actions.fetchTariffByIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_CLIENT_TARIFF, sagas.fetchClientTariffs);
	yield takeLatest(FETCH_TARIFFS_BY_DROPDOWN_ID, sagas.fetchTariffsByDropdownId);
	yield takeLatest(CHANGE_TARIFF_ORDER, sagas.changeTariffOrder);
	yield takeLatest(TOGGLE_TARIFF_VISIBILITY, sagas.toggleTariffVisibility);
	yield takeLatest(FETCH_TARIFF_BY_ID, sagas.fetchTariffById);
};
