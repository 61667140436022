import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import {EditorState, convertToRaw, convertFromRaw} from "draft-js";
import axios from "@utils/axios";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import {ToastErrorComponent, ToastSuccesComponent} from "@common/ToastComponent/ToastComponent";
import {actions as uploadActions} from "@sagas/trainings/upload";

import i18n from "../../../../i18n";
import {actions as navigateActions} from "../navigation";
// eslint-disable-next-line import/no-cycle
import {actions as templateActions} from "./index";
const logger = new Logger("Trainings Create");
const PREFIX = "@app/trainings/create";
export const ADD_TRAININGS = `${PREFIX}ADD_TRAININGS`;
export const ADD_TRAININGS_SUCCESS = `${PREFIX}ADD_TRAININGS_SUCCESS`;
export const ADD_PAGE = `${PREFIX}ADD_PAGE`;
export const SET_VALUE_ON_CHANGE = `${PREFIX}SET_VALUE_ON_CHANGE`;
export const SET_ACTIVE_PAGE = `${PREFIX}SET_ACTIVE_PAGE`;
export const SELECT_PAGE = `${PREFIX}SELECT_PAGE`;
export const CLEAR_STATE = `${PREFIX}CLEAR_STATE`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_SINGLE_TEMPLATE = `${PREFIX}GET_SINGLE_TEMPLATE`;
export const GET_SINGLE_TEMPLATE_SUCCESS = `${PREFIX}GET_SINGLE_TEMPLATE_SUCCESS`;
export const SET_TEMPLATE_SAVED = `${PREFIX}SET_TEMPLATE_SAVED`;
export const DELETE_PAGE = `${PREFIX}DELETE_PAGE`;
export const SET_CATEGORY_VALUE = `${PREFIX}SET_CATEGORY_VALUE`;
export const ADD_CATEGORY = `${PREFIX}ADD_CATEGORY`;
export const UPDATE_CATEGORY = `${PREFIX}UPDATE_CATEGORY`;
export const ADD_CATEGORY_LOADING = `${PREFIX}ADD_CATEGORY_LOADING`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const SET_TRAININGS_IMG = `${PREFIX}SET_TRAININGS_IMG`;
export const DELETE_TRAINING = `${PREFIX}DELETE_TRAINING`;
export const DRAFT_TRAINING = `${PREFIX}DRAFT_TRAINING`;
export const SET_SUB_CATEGORY_VALUE = `${PREFIX}SET_SUB_CATEGORY_VALUE`;

const _state = {
	pages: [
		{
			title: "",
			content: EditorState.createEmpty(),
			photo: null,
		},
	],
	selectedPage: {},
	activePage: 1,
	loading: false,
	templateSaved: false,
	categoryValue: {},
	subCategoryValue: {},
	showModal: false,
	trainingImg: null,
	categoryLoading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_TEMPLATE_SAVED:
				draft.templateSaved = true;
				break;
			case ADD_PAGE:
				const newTraining = {
					title: "",
					content: EditorState.createEmpty(),
					photo: null,
				};
				draft.pages = [...state.pages, newTraining];
				break;
			case SET_ACTIVE_PAGE:
				draft.activePage = payload;
				break;
			case DELETE_PAGE:
				const arr = state.pages.filter((c) => c !== state.pages[payload]);
				draft.activePage = arr.length;
				draft.pages = arr;
				break;
			case SET_VALUE_ON_CHANGE:
				const clonedPages = [...state.pages];
				let currentPage = {...state.pages[state.activePage - 1]};
				currentPage = {
					...currentPage,
					[payload.name]: payload.value,
				};
				clonedPages[state.activePage - 1] = currentPage;
				draft.pages = clonedPages;
				break;
			case SELECT_PAGE:
				const selectedPage = state.pages[state.activePage - 1];
				draft.selectedPage = selectedPage;
				break;
			case GET_SINGLE_TEMPLATE_SUCCESS:
				const pages = JSON.parse(payload?.content);
				draft.activePage = 1;
				if (payload.subCategories?.length > 0) {
					const [subCategory] = payload.subCategories;
					draft.subCategoryValue = subCategory;
				}

				// eslint-disable-next-line one-var
				let templates = [...pages];
				templates = pages.map((te) => {
					const rawContentFromStore = convertFromRaw(JSON.parse(te.content));
					const initialEditorState = EditorState.createWithContent(rawContentFromStore);

					return {
						...te,
						content: initialEditorState,
						published: payload?.published,
					};
				});
				draft.pages = templates;
				draft.trainingImg = payload?.cover;
				draft.categoryValue = {
					id: payload?.category?.id,
					name: payload?.category?.name,
					value: payload?.category?.id,
				};
				break;
			case SET_CATEGORY_VALUE:
				draft.categoryValue = payload;
				break;
			case SET_SUB_CATEGORY_VALUE:
				draft.subCategoryValue = payload;
				break;
			case CLEAR_STATE:
				draft.pages = [
					{
						title: "",
						content: EditorState.createEmpty(),
					},
				];
				draft.selectedPage = {};
				draft.activePage = 1;
				draft.categoryValue = {};
				break;
			case SHOW_MODAL:
				draft.showModal = !state.showModal;
				break;
			case ADD_CATEGORY_LOADING:
				draft.categoryLoading = payload;
				break;
			case SET_TRAININGS_IMG:
				draft.trainingImg = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addPages: (payload) => createAction(ADD_TRAININGS, {payload}),
	addPagesSuccess: (payload) => createAction(ADD_TRAININGS_SUCCESS, {payload}),
	addPage: (payload) => createAction(ADD_PAGE, {payload}),
	setValueOnChange: (payload) => createAction(SET_VALUE_ON_CHANGE, {payload}),
	setActivePage: (payload) => createAction(SET_ACTIVE_PAGE, {payload}),
	selectPage: (payload) => createAction(SELECT_PAGE, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	clearState: (payload) => createAction(CLEAR_STATE, {payload}),
	getSingleTemplate: (payload) => createAction(GET_SINGLE_TEMPLATE, {payload}),
	getSingleTemplateSuccess: (payload) => createAction(GET_SINGLE_TEMPLATE_SUCCESS, {payload}),
	setSavedTemplate: (payload) => createAction(SET_TEMPLATE_SAVED, {payload}),
	deletePage: (payload) => createAction(DELETE_PAGE, {payload}),
	setCategoryValue: (payload) => createAction(SET_CATEGORY_VALUE, {payload}),
	addTemplateCategory: (payload) => createAction(ADD_CATEGORY, {payload}),
	updateTemplateCategory: (payload) => createAction(UPDATE_CATEGORY, {payload}),
	addTemplateCategoryLoading: (payload) => createAction(ADD_CATEGORY_LOADING, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	setTrainingsImg: (payload) => createAction(SET_TRAININGS_IMG, {payload}),
	deleteTraining: (payload) => createAction(DELETE_TRAINING, {payload}),
	draftTraining: (payload) => createAction(DRAFT_TRAINING, {payload}),
	setSubCategoryValue: (payload) => createAction(SET_SUB_CATEGORY_VALUE, {payload}),
};

export const sagas = {
	*addPages({payload}) {
		yield put(actions.setLoading(true));
		try {
			let values = [...payload.values];
			values = values.map((value) => ({
				...value,
				content: JSON.stringify(convertToRaw(value.content.getCurrentContent())),
			}));
			if (payload.id) {
				let isPublished = false;
				if (!payload?.published && payload?.status === "edit") {
					isPublished = false;
				} else if (!payload?.published && payload?.status === "publish") {
					isPublished = true;
				} else if (payload?.published && payload?.status === "publish") {
					isPublished = true;
				}

				yield axios.put("/template/update", {
					templateId: +payload.id,
					title: values?.[0]?.title,
					category: payload?.category || 1,
					subCategories: [payload?.subCategory] || [],
					updateTemplate: values,
					published: isPublished,
					cover: payload?.image,
				});
				ToastSuccesComponent(i18n.t("TrainingUpdateSuccess"));
			} else {
				yield axios.post("/template/create", {
					title: values?.[0]?.title,
					category: payload?.category || null,
					subCategories: [payload?.subCategory],
					createTemplate: values,
					cover: payload?.image,
				});
				ToastSuccesComponent(i18n.t("TrainingCreateSuccess"));
			}

			yield put(actions.setTrainingsImg(null));
			yield put(templateActions.clearFilterValues());
			yield put(navigateActions.navigate("/admin/trainings"));
			yield put(actions.setSavedTemplate());
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getSingleTemplate(id) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/template/${id?.payload}`);

			yield put(actions.getSingleTemplateSuccess(response.data.data));
			const {files, category} = response?.data?.data;
			yield put(
				uploadActions.uploadFileSuccess({
					files,
					method: "get",
				}),
			);
			if (category) {
				yield put(
					templateActions.filterTrainings({
						name: "category",
						value: {
							value: category?.id,
							name: category?.name,
						},
					}),
				);
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*addCategory({payload}) {
		yield put(actions.addTemplateCategoryLoading(true));
		const {name} = payload;
		try {
			const response = yield axios.post(`/template/category/create/`, payload);
			ToastSuccesComponent(i18n.t("CategoryCreateSuccess"));
			yield put(
				templateActions.addCategorySuccess({
					name,
					value: response.data.data,
				}),
			);
			yield put(templateActions.setCategoryStatusModal(false));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.addTemplateCategoryLoading(false));
		}
	},
	*updateTemplateCategory({payload}) {
		yield put(actions.addTemplateCategoryLoading(true));
		const {name} = payload.name;
		try {
			const response = yield axios.put(`/template/category/update`, payload);
			yield put(templateActions.setCategoryStatusModal(false));
			ToastSuccesComponent(i18n.t("CategoryCreateSuccess"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.addTemplateCategoryLoading(false));
		}
	},
	*deleteTraining({payload}) {
		try {
			const response = yield axios.put(`/template/delete/${payload.id}`);
			ToastSuccesComponent(i18n.t("TrainingDeletedSuccessfully"));
			if (response.status === 200) {
				if (payload.isDraft === "draft" || payload.isDraft === "Draft") {
					yield put(templateActions.getTrainingsByStatus("draft"));
				} else {
					yield put(templateActions.getTrainingsByStatus("published"));
				}
			}
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(i18n.t("Couldnotdeletetraining!"));
		}
	},
	*draftTraining(id) {
		try {
			const response = yield axios.put(`/template/draft/${id.payload}`);
			ToastSuccesComponent(i18n.t("TrainingDraftedSuccessfully"));
			if (response.status === 200) {
				yield put(templateActions.getTrainingsByStatus("published"));
			}
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(i18n.t("Couldnotdrafttraining!"));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_TRAININGS, sagas.addPages);
	yield takeLatest(GET_SINGLE_TEMPLATE, sagas.getSingleTemplate);
	yield takeLatest(ADD_CATEGORY, sagas.addCategory);
	yield takeLatest(UPDATE_CATEGORY, sagas.updateTemplateCategory);
	yield takeLatest(DELETE_TRAINING, sagas.deleteTraining);
	yield takeLatest(DRAFT_TRAINING, sagas.draftTraining);
};
