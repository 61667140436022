import DataGridTable from "@src/common/table/DataGridTable";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as salesOrgActions} from "@sagas/salesOrganizations/index";
import {salesOrgActionsFormatter, salesorgColumns, salesorgMembersColumns} from "./helper";
import {getExpandRow} from "../../Imports/components/helper";
import {actionsProperties} from "../actionFormatters";

function SalesOrganizations({
	salesOrganizationsState,
	getSalesOrganizations,
	getSalesOrganizationsSuccess,
	getSalesOrganizationsById,
	setModal,
	editPage,
	editSize,
	search,
	clearSalesOrganizations,
}) {
	const {loading, page, size, totalPages, totalSize, salesOrganizations} =
		salesOrganizationsState;
	const [reEditPage, setReEditPage] = useState(true);
	const [data, setData] = useState([]);
	useEffect(() => {
		getSalesOrganizations({search});
		if (search !== "") {
			editPage(1);
		}
		return () => {
			clearSalesOrganizations([]);
			getSalesOrganizationsSuccess({data: [], type: "update"});
		};
	}, [size, page, search]);
	useEffect(() => {
		setData(salesOrganizations);
	}, [salesOrganizations]);
	const actions = {
		...actionsProperties,
		width: 150,
		formatter: ({row}) => salesOrgActionsFormatter(row, getSalesOrganizationsById, setModal),
	};
	return (
		<div className="salesorg-container">
			<div className="loader__and__table">
				<DataGridTable
					enableCellSelect={false}
					data={data}
					rows={data}
					wrapperStyles={{
						width: "100%",
						height: "100%",
					}}
					loading={loading}
					pagination={true}
					size={size}
					page={page}
					totalPages={totalPages}
					totalSize={totalSize}
					editSize={editSize}
					editPage={editPage}
					setReEditPage={setReEditPage}
					reEditPage={reEditPage}
					rowHeight={({row}) =>
						row?.type === "DETAIL"
							? row?.salesOrganizationUserViewList?.length * 44 + 76
							: 44
					}
					columns={[
						getExpandRow(salesorgMembersColumns, "salesOrganizationUserViewList"),
						...salesorgColumns,
						actions,
					]}
					onRowsChange={(rows, {indexes}) => {
						setData([]);
						const updatedRows = [...rows];
						const row = updatedRows[indexes[0]];
						const detailRowIndex = updatedRows.findIndex(
							(r) => r.type === "DETAIL" && r?.parentId === row?.id,
						);
						if (row.type === "MASTER") {
							if (row.expanded) {
								if (detailRowIndex === -1) {
									const newDetailRow = {
										...row,
										type: "DETAIL",
										parentId: row?.id,
									};
									updatedRows.splice(indexes[0] + 1, 0, newDetailRow);
								}
							} else if (detailRowIndex !== -1) {
								updatedRows.splice(detailRowIndex, 1);
							}
							clearSalesOrganizations(updatedRows);
							getSalesOrganizationsSuccess({data: updatedRows, type: "update"});
						}
					}}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	salesOrganizationsState: state.app.salesOrganizations.index,
});
const mapDispatchToProps = {
	getSalesOrganizations: salesOrgActions.getSalesOrganizations,
	getSalesOrganizationsSuccess: salesOrgActions.getSalesOrganizationsSuccess,
	setShowModal: salesOrgActions.setShowModal,
	getSalesOrganizationsById: salesOrgActions.getSalesOrganizationsById,
	editPage: salesOrgActions.editPage,
	editSize: salesOrgActions.editSize,
	clearSalesOrganizations: salesOrgActions.clearSalesOrganizations,
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesOrganizations);
