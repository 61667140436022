import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/users/users/index";
import {saveTenantFeatures} from "./utils";

const logger = new Logger("Tenants index");

const PREFIX = "@app/tenants/tenants/index";
export const GET_TENANTS = `${PREFIX}GET_TENANTS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_TENANTS_SUCCESS = `${PREFIX}FETCH_TENANTS_SUCCESS`;
export const FETCH_SINGLE_TENANT = `${PREFIX}FETCH_SINGLE_TENANT`;
export const FETCH_SINGLE_TENANT_SUCCESS = `${PREFIX}FETCH_SINGLE_TENANT_SUCCESS`;
export const ADD_TENANT_ON_STATE = `${PREFIX}ADD_TENANT_ON_STATE`;
export const EDIT_TENANT_ON_STATE = `${PREFIX}EDIT_TENANT_ON_STATE`;
export const DELETE_TENANT_ON_STATE = `${PREFIX}DELETE_TENANT_ON_STATE`;
export const LOCK_TENANT_ON_STATE = `${PREFIX}LOCK_TENANT_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TENANT_ADD_TOTAL_SIZE_INCREASE = `${PREFIX}TENANT_ADD_TOTAL_SIZE_INCREASE`;
export const TENANT_ADD_TOTAL_SIZE_DECREASE = `${PREFIX}TENANT_ADD_TOTAL_SIZE_DECREASE`;
export const SET_TENANT_FEATURES = `${PREFIX}SET_TENANT_FEATURES`;

const _state = {
	tenants: [],
	singleTenant: {},
	tenantInitialValues: {},
	loading: false,
	page: 1,
	size: 10,
	totalSize: 5,
	search: "",
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload + 1;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = action.payload.value;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case FETCH_TENANTS_SUCCESS:
				draft.tenants = action.payload;
				break;
			case FETCH_SINGLE_TENANT_SUCCESS:
				draft.singleUser = action.payload;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;

				break;
			case ADD_TENANT_ON_STATE:
				draft.tenants = [action.payload].concat(state.tenants);
				break;
			case EDIT_TENANT_ON_STATE:
				const index = state.tenants.findIndex((c) => c.id === action.payload.id);
				draft.tenants[index] = action.payload;

				break;
			case DELETE_TENANT_ON_STATE:
				draft.tenants = state.tenants.filter((tenant) => tenant.id !== action.payload);
				break;
			case LOCK_TENANT_ON_STATE:
				const newTenantsArray = state.tenants.map((tenant) => {
					if (tenant.id === action.payload) {
						return {
							...tenant,
							locked: !tenant.locked,
						};
					}
					return tenant;
				});
				draft.tenants = newTenantsArray;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;

			case TENANT_ADD_TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case TENANT_ADD_TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getTenants: (payload) => createAction(GET_TENANTS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchTenantsSuccess: (payload) => createAction(FETCH_TENANTS_SUCCESS, {payload}),
	getSingleTenant: (payload) => createAction(FETCH_SINGLE_TENANT, {payload}),
	getSingleTenantSuccess: (payload) => createAction(FETCH_SINGLE_TENANT_SUCCESS, {payload}),
	addTenantOnState: (payload) => createAction(ADD_TENANT_ON_STATE, {payload}),
	editTenantOnState: (payload) => createAction(EDIT_TENANT_ON_STATE, {payload}),
	deleteTenantOnState: (payload) => createAction(DELETE_TENANT_ON_STATE, {payload}),
	lockTenantOnState: (payload) => createAction(LOCK_TENANT_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterTenants: (payload) => createAction(FILTERS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	tenantAddIncreaseTotalSize: (payload) =>
		createAction(TENANT_ADD_TOTAL_SIZE_INCREASE, {payload}),
	tenanttAddDecreaseTotalSize: (payload) =>
		createAction(TENANT_ADD_TOTAL_SIZE_DECREASE, {payload}),
	setTenantFeatures: (payload) => createAction(SET_TENANT_FEATURES, {payload}),
};

export const sagas = {
	*getTenants() {
		yield put(actions.setLoading(true));
		const {size} = yield select((state) => state.app.tenants.index);
		const {search} = yield select((state) => state.app.tenants.index);
		const {page} = yield select((state) => state.app.tenants.index);
		try {
			const response = yield axios.get(
				`/tenant/all?page=${page}&size=${size}&search=${search}`,
			);

			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchTenantsSuccess(response?.data?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getSingleTenant(id) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/tenant/${id.payload}`);
			yield put(actions.getSingleTenantSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*setTenantFeatures({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put("/tenant/tenant_features", payload);
			yield put(userActions.setTenantFeaturesSuccess(payload));
			saveTenantFeatures(payload);
			window.location.reload();
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_TENANTS, sagas.getTenants);
	yield takeLatest(FETCH_SINGLE_TENANT, sagas.getSingleTenant);
	yield takeLatest(SET_TENANT_FEATURES, sagas.setTenantFeatures);
};
