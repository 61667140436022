import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Sagas>imports>Index");

const PREFIX = "@app/imports/index";
export const GET_IMPORTS = `${PREFIX}GET_IMPORTS`;
export const GET_IMPORTS_SUCCESS = `${PREFIX}GET_IMPORTS_SUCCESS`;
export const GET_SINGLE_IMPORT = `${PREFIX}GET_SINGLE_IMPORT`;
export const GET_SINGLE_IMPORT_SUCCESS = `${PREFIX}GET_SINGLE_IMPORT_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const GET_SINGLE_IMPORT_ST = `${PREFIX}GET_SINGLE_IMPORT_ST`;
export const GET_SINGLE_IMPORT_ST_SUCCESS = `${PREFIX}GET_SINGLE_IMPORT_ST_SUCCESS`;

const _state = {
	imports: [],
	loading: false,
	singleImport: {},
	singleImportSalesTracker: {},
	page: 1,
	size: 30,
	totalSize: 5,
	filters: {
		fromDate: null,
		toDate: null,
		search: "",
	},
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload + 1;
				break;

			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;

				break;
			case GET_IMPORTS_SUCCESS:
				draft.imports = action.payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.filters[action.payload?.name] = action.payload.value;
				break;

			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case GET_SINGLE_IMPORT_SUCCESS:
				draft.singleImport = action.payload;
				break;
			case GET_SINGLE_IMPORT_ST_SUCCESS:
				draft.singleImportSalesTracker = action.payload;
				break;

			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getImportById: (payload) => createAction(GET_SINGLE_IMPORT, {payload}),
	getImportByIdSalesTracker: (payload) => createAction(GET_SINGLE_IMPORT_ST, {payload}),
	getImportByIdSalesTrackerSuccess: (payload) =>
		createAction(GET_SINGLE_IMPORT_ST_SUCCESS, {payload}),

	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	getImports: (payload) => createAction(GET_IMPORTS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchImportsSuccess: (payload) => createAction(GET_IMPORTS_SUCCESS, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterImports: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	getSingleImportSuccess: (payload) => createAction(GET_SINGLE_IMPORT_SUCCESS, {payload}),
};

export const sagas = {
	*getImports() {
		yield put(actions.setLoading(true));
		const {size, filters, page} = yield select((state) => state.app.imports.index);
		const obj = {
			search: filters.search || "",
			from: filters?.fromDate || "",
			to: filters?.toDate || "",
		};
		try {
			const response = yield axios.get(
				`/csv?page=${page}&size=${size}&from=${obj.from}&to=${obj.to}&search=${obj.search}`,
			);

			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchImportsSuccess(response?.data?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getImportById(id) {
		yield put(actions.setLoading(true));

		try {
			const response = yield axios.get(`/csv/${id.payload}`);

			yield put(actions.getSingleImportSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getImportByIdSalesTracker(id) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/csv/lead_csv_data/${id.payload}`);
			yield put(actions.getImportByIdSalesTrackerSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_IMPORTS, sagas.getImports);
	yield takeLatest(GET_SINGLE_IMPORT, sagas.getImportById);
	yield takeLatest(GET_SINGLE_IMPORT_ST, sagas.getImportByIdSalesTracker);
};
