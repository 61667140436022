import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import login from "@sagas/auth/login";
import language from "@sagas/language/index";
import register from "@sagas/auth/register";
import forgot from "@sagas/auth/forgot";
import templates from "@sagas/admin/templates";
import createTemplates from "@sagas/admin/templates/create";
import drawer from "@sagas/drawer";
import users from "@sagas/users/users";
import usersDeleted from "@sagas/users/users/deleted";
import usersRestore from "@sagas/users/users/restore";
import createUser from "@sagas/users/users/create";
import deleteUser from "@sagas/users/users/delete";
import products from "@sagas/products/index";
import createProduct from "@sagas/products/create";
import deleteProduct from "@sagas/products/delete";
import tariffs from "@sagas/tariff";
import createTariff from "@sagas/tariff/create";
import deleteTariff from "@sagas/tariff/delete";
import comission from "@sagas/comission";
import createComission from "@sagas/comission/create";
import projectCategories from "@sagas/projectCategories";
import createProjectCategories from "@sagas/projectCategories/create";
import deleteProjectCategories from "@sagas/projectCategories/delete";
import orders from "@sagas/orders";
import createOrders from "@sagas/orders/create";
import editOrders from "@sagas/orders/editOrder";
import userProfile from "@sagas/userProfile/index";
import userSettings from "@sagas/userProfile/settings";
import client from "@sagas/client";
import createClient from "@sagas/client/create";
import deleteClient from "@sagas/client/delete";
import clientCategory from "@sagas/projectCategories/clientCategory";
import createClientCategory from "@sagas/projectCategories/createClientCategory";
import deleteClientCategory from "@sagas/projectCategories/deleteClientCategory";
import clientTariff from "@sagas/tariff/clientTariff";
import createClientTariff from "@sagas/tariff/createClientTariff";
import deleteClientTariff from "@sagas/tariff/deleteClientTariff";
import clientProduct from "@sagas/products/clientProduct";
import createClientProduct from "@sagas/products/createClientProduct";
import deleteClientProduct from "@sagas/products/deleteClientProduct";
import clientComission from "@sagas/comission/clientCommision";
import createClientComission from "@sagas/comission/createClientCommision";
import clientBonus from "@sagas/bonuses/clientBonus";
import createClientBonus from "@sagas/bonuses/createClientBonus";
import tenants from "@sagas/tenants";
import createTenant from "@sagas/tenants/create";
import deleteTenant from "@sagas/tenants/delete";
import userOrders from "@sagas/orders/userOrders";
import calculations from "@sagas/calculations/index";
import projectCategoriesDeleted from "@sagas/projectCategories/deleted";
import projectCategoriesRestore from "@sagas/projectCategories/restore";
import tariffDeleted from "@sagas/tariff/deleted";
import tariffRestore from "@sagas/tariff/restore";
import productsDeleted from "@sagas/products/deleted";
import productsRestore from "@sagas/products/restore";
import clientDeleted from "@sagas/client/deleted";
import clientRestore from "@sagas/client/restore";
import trainings from "@sagas/trainings";
import createTrainings from "@sagas/trainings/create";
import messages from "@sagas/messages";
import createMessages from "@sagas/messages/create";
import deleteMessages from "@sagas/messages/delete";
import createTeams from "@sagas/teams/create";
import deleteTeams from "@sagas/teams/delete";
import teams from "@sagas/teams/";
import locations from "@sagas/locations/";
import createLocations from "@sagas/locations/create";
import deleteLocations from "@sagas/locations/delete";
import deletedLocations from "@sagas/locations/deleted";
import restoreLocations from "@sagas/locations/restore";
import tariffByLocations from "@sagas/tariff/tariffByLocations/index";
import tariffHistory from "@sagas/tariff/tariffByLocations/history";
import createTariffByLocations from "@sagas/tariff/tariffByLocations/create";
import createImport from "@sagas/imports/create";
import imports from "@sagas/imports/index";
import salesTracker from "@sagas/salesTracker";
import createSalesTracker from "@sagas/salesTracker/create";
import deleteSalesTracker from "@sagas/salesTracker/delete";
import leadStatus from "@sagas/salesTracker/leadStatus";
import statuses from "@sagas/salesTracker/statuses";
import salesTrackerImports from "@sagas/salesTracker/imports";
import teamConditions from "@src/store/sagas/app/teamConditions/index";
import createConditions from "@src/store/sagas/app/teamConditions/create";
import deleteConditions from "@src/store/sagas/app/teamConditions/delete";
import orderImports from "@sagas/orders/orderImports";
import analytics from "@sagas/analytics/index";
import createGoals from "@sagas/analytics/goals/create";
import deleteGoals from "@sagas/analytics/goals/delete";
import goalViews from "@sagas/analytics/goals/views";
import contaclessOrder from "@sagas/contactlessOrder/index";
import logout from "@sagas/auth/logout";
import monitoring from "@sagas/monitoring/index";
import conditionsV1 from "@sagas/teamConditionsV1/index";
import deleteConditionsV1 from "@sagas/teamConditionsV1/delete";
import createConditionsV1 from "@sagas/teamConditionsV1/create";
import uploadTrainings from "@sagas/trainings/upload";
import marketPlace from "@sagas/marketplace/index";
import billingMonths from "@sagas/calculations/billingMonths";
import lockedTenants from "@sagas/tenants/locked";
import bonuses from "@sagas/projectCategories/bonuses";
import salesOrganizations from "@sagas/salesOrganizations/index";
import energy from "@sagas/energy";
import createStructures from "@sagas/structures/create";
import deleteStructures from "@sagas/structures/delete";
import structures from "@sagas/structures/index";
import orderStatuses from "@sagas/orders/statuses";
import notifications from "@sagas/notifications/index";
import leadAddresses from "@sagas/salesTracker/addresses";
import leadsByMap from "@sagas/salesTracker/mapView";

export default function createReducer(injectedReducers, history) {
	return combineReducers({
		app: combineReducers({
			auth: combineReducers({
				login,
				register,
				forgot,
				logout,
			}),
			language: combineReducers({
				index: language,
			}),
			templates: combineReducers({
				index: templates,
				create: createTemplates,
			}),
			users: combineReducers({
				index: users,
				createUser,
				deleteUser,
				deleted: usersDeleted,
				restore: usersRestore,
			}),
			products: combineReducers({
				index: products,
				createProduct,
				deleteProduct,
				clientProduct,
				createClientProduct,
				deleteClientProduct,
				deleted: productsDeleted,
				restore: productsRestore,
			}),
			tariffs: combineReducers({
				index: tariffs,
				create: createTariff,
				delete: deleteTariff,
				clientTariff,
				createClientTariff,
				deleteClientTariff,
				deleted: tariffDeleted,
				restore: tariffRestore,
			}),
			comission: combineReducers({
				index: comission,
				createComission,
				clientComission,
				createClientComission,
			}),
			projectCategories: combineReducers({
				index: projectCategories,
				create: createProjectCategories,
				delete: deleteProjectCategories,
				deleted: projectCategoriesDeleted,
				restore: projectCategoriesRestore,
				clientCategory,
				createClientCategory,
				deleteClientCategory,
				bonuses,
			}),
			orders: combineReducers({
				index: orders,
				create: createOrders,
				edit: editOrders,
				userOrders,
				import: orderImports,
				statuses: orderStatuses,
			}),
			userProfile: combineReducers({
				index: userProfile,
				settings: userSettings,
			}),
			client: combineReducers({
				index: client,
				create: createClient,
				delete: deleteClient,
				deleted: clientDeleted,
				restore: clientRestore,
			}),
			bonuses: combineReducers({
				clientBonus,
				createClientBonus,
			}),
			tenants: combineReducers({
				index: tenants,
				create: createTenant,
				delete: deleteTenant,
				locked: lockedTenants,
			}),
			calculations: combineReducers({
				index: calculations,
				billingMonths,
			}),
			trainings: combineReducers({
				index: trainings,
				create: createTrainings,
				upload: uploadTrainings,
			}),
			messages: combineReducers({
				index: messages,
				create: createMessages,
				delete: deleteMessages,
			}),
			teams: combineReducers({
				index: teams,
				create: createTeams,
				delete: deleteTeams,
			}),
			conditions: combineReducers({
				index: teamConditions,
				create: createConditions,
				delete: deleteConditions,
			}),
			conditionsV1: combineReducers({
				index: conditionsV1,
				create: createConditionsV1,
				delete: deleteConditionsV1,
			}),
			locations: combineReducers({
				index: locations,
				create: createLocations,
				delete: deleteLocations,
				deleted: deletedLocations,
				restore: restoreLocations,
			}),
			tariffByLocations: combineReducers({
				index: tariffByLocations,
				create: createTariffByLocations,
				history: tariffHistory,
			}),
			imports: combineReducers({
				index: imports,
				create: createImport,
			}),
			salesTracker: combineReducers({
				index: salesTracker,
				create: createSalesTracker,
				delete: deleteSalesTracker,
				leadStatus,
				statuses,
				imports: salesTrackerImports,
				addresses: leadAddresses,
				mapView: leadsByMap,
			}),
			analytics: combineReducers({
				index: analytics,
				createGoals,
				delete: deleteGoals,
				view: goalViews,
			}),
			contaclessOrder: combineReducers({
				index: contaclessOrder,
			}),
			monitoring: combineReducers({
				index: monitoring,
			}),
			marketPlace: combineReducers({
				index: marketPlace,
			}),
			salesOrganizations: combineReducers({
				index: salesOrganizations,
			}),
			drawer,
			energy: combineReducers({
				index: energy,
			}),
			structures: combineReducers({
				index: structures,
				delete: deleteStructures,
				create: createStructures,
			}),
			notifications: combineReducers({
				index: notifications,
			}),
		}),
		...injectedReducers,
		router: connectRouter(history),
	});
}
