import React, {useState} from "react";
import {connect, useSelector} from "react-redux";
import {actions as leadAddressesActions} from "@sagas/salesTracker/addresses";
import Loader from "@src/common/Loader";
import AddressItem from "./AddressItem";

const LeadsStreetView = ({setPage, selectedRows, setSelectedRows}) => {
	const [selectedAddressData, setSelectedAddressData] = useState({});
	const [expandedItem, setExpandedItem] = useState(null);
	const {addresses, totalPages, page, loading, refetchLoading, counting} = useSelector(
		(state) => state.app.salesTracker.addresses,
	);

	const handleScroll = (event) => {
		const {currentTarget} = event;
		const isAtBottom =
			currentTarget.scrollTop >= currentTarget.scrollHeight - currentTarget.clientHeight - 1;
		if (loading || refetchLoading || !isAtBottom || page >= totalPages || counting) return;
		setPage(page + 1);
	};
	return (
		<div className="address-view__wrapper" onScroll={handleScroll}>
			{addresses?.map((item, index) => (
				<div className="address-view__wrapper-item">
					<AddressItem
						index={index}
						key={`${index}--${item.street}`}
						setExpandedItem={setExpandedItem}
						isExpanded={expandedItem === index}
						expandedItem={expandedItem}
						item={item}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						setSelectedAddressData={setSelectedAddressData}
						selectedAddressData={selectedAddressData}
					/>
				</div>
			))}
			{refetchLoading && (
				<div className="address-view__wrapper-bottomLoader">
					<Loader />
				</div>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	setPage: leadAddressesActions.setPage,
};
export default connect(null, mapDispatchToProps)(LeadsStreetView);
