export const paginationItems = (
	editSize,
	editPage,
	t,
	totalSize,
	setDropdownVisible,
	setReEditPage,
) => [
	{
		key: "30",
		label: "30",
		onClick: () => {
			editPage(1);
			editSize(30);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "50",
		label: "50",
		onClick: () => {
			editPage(1);
			editSize(50);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "100",
		label: "100",
		onClick: () => {
			editPage(1);
			editSize(100);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "200",
		label: "200",
		onClick: () => {
			editPage(1);
			editSize(200);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "500",
		label: "500",
		onClick: () => {
			editPage(1);
			editSize(500);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "1000",
		label: "1000",
		onClick: () => {
			editPage(1);
			editSize(1000);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "2000",
		label: "2000",
		onClick: () => {
			editPage(1);
			editSize(2000);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "3000",
		label: "3000",
		onClick: () => {
			editPage(1);
			editSize(3000);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
	{
		key: "All",
		label: t("All"),
		onClick: () => {
			editPage(1);
			editSize(totalSize);
			setDropdownVisible(false);
			setReEditPage(false);
		},
	},
];
