/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useTranslation} from "react-i18next";
import {handleDownloadAnyType} from "@src/utils/download-file";
import {fields} from "./helper";
import "./styles.scss";

const keyWithoutUrl = [
	"iban",
	"bankName",
	"taxOffice",
	"businessLicense",
	"hrNumber",
	"taxNumber",
	"vatNumber",
];
const isBoolean = ["vatEntitlement"];

const DistributionChannel = ({singleUser}) => {
	const {t} = useTranslation();

	// Function to render individual items
	const renderItems = (items) =>
		items.map(({key}, index) => (
			<div
				className="distribution-channel-container--body--item--field"
				key={`${index + key}`}
			>
				<div className="distribution-channel-container--body--item--field-key">
					<span>{t(key)} </span>
					<span>:</span>{" "}
				</div>
				<div className="distribution-channel-container--body--item--field-value">
					<span>
						{singleUser[key] ? (
							<>
								{keyWithoutUrl.includes(key) || isBoolean.includes(key) ? (
									<>
										<span>
											{isBoolean.includes(key)
												? singleUser[key]
													? t("True")
													: t("False")
												: singleUser[key]}
										</span>
									</>
								) : (
									<>
										<a
											href="#"
											rel="noopener noreferrer"
											onClick={() =>
												handleDownloadAnyType({
													fileUrl: singleUser[key],
													fileName: singleUser[key].slice(0, 20),
												})
											}
										>
											{singleUser[key].slice(0, 20)}
										</a>
										{singleUser[key].lastIndexOf(".") !== -1 && (
											<>
												<a
													href="#"
													rel="noopener noreferrer"
													onClick={() =>
														handleDownloadAnyType({
															fileUrl: singleUser[key],
															fileName: singleUser[key].slice(
																singleUser[key].lastIndexOf("."),
															),
														})
													}
												>
													{singleUser[key].slice(
														singleUser[key].lastIndexOf("."),
													)}
												</a>
											</>
										)}
									</>
								)}
							</>
						) : (
							"-"
						)}
					</span>
				</div>
			</div>
		));

	// Mapping through 'fields' to render each field set
	const renderFields = fields.map(({title, items}, index) => (
		// Each field set has a unique key using 'title'
		<div className="distribution-channel-container--body--item" key={`${index + title}`}>
			<p className="distribution-channel-container--body--item--title">{title}</p>
			{/* Render the items for each field set */}
			{renderItems(items)}
		</div>
	));

	// Returning the JSX for the component
	return (
		<div className="distribution-channel-container">
			<h3>{t("financialInformation")}</h3>
			<div className="distribution-channel-container--body">{renderFields}</div>
		</div>
	);
};

export default DistributionChannel;
