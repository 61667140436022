/* eslint-disable import/no-cycle */
import {all} from "redux-saga/effects";

import {watcher as language} from "./app/language";
import {watcher as login} from "./app/auth/login";
import {watcher as logout} from "./app/auth/logout";
import {watcher as register} from "./app/auth/register";
import {watcher as forgot} from "./app/auth/forgot";
import {watcher as navigation} from "./app/navigation";
import {watcher as templates} from "./app/admin/templates";
import {watcher as createTemplate} from "./app/admin/templates/create";
import {watcher as drawer} from "./app/drawer";
import {watcher as users} from "./app/users/users";
import {watcher as usersDeleted} from "./app/users/users/deleted";
import {watcher as usersRestore} from "./app/users/users/restore";
import {watcher as createUser} from "./app/users/users/create";
import {watcher as updateUser} from "./app/users/users/update";
import {watcher as deleteUser} from "./app/users/users/delete";
import {watcher as products} from "./app/products";
import {watcher as createProduct} from "./app/products/create";
import {watcher as deleteProduct} from "./app/products/delete";
import {watcher as tariffs} from "./app/tariff";
import {watcher as createTariff} from "./app/tariff/create";
import {watcher as deleteTariff} from "./app/tariff/delete";
import {watcher as comission} from "./app/comission/index";
import {watcher as createComission} from "./app/comission/create";
import {watcher as projectCategories} from "./app/projectCategories";
import {watcher as projectCategoriesDeleted} from "./app/projectCategories/deleted";
import {watcher as projectCategoriesRestore} from "./app/projectCategories/restore";
import {watcher as tariffDeleted} from "./app/tariff/deleted";
import {watcher as tariffRestore} from "./app/tariff/restore";
import {watcher as productsDeleted} from "./app/products/deleted";
import {watcher as productsRestore} from "./app/products/restore";
import {watcher as clientDeleted} from "./app/client/deleted";
import {watcher as clientRestore} from "./app/client/restore";
import {watcher as createProjectCategories} from "./app/projectCategories/create";
import {watcher as deleteProjectCategories} from "./app/projectCategories/delete";
import {watcher as orders} from "./app/orders/index";
import {watcher as userOrders} from "./app/orders/userOrders";
import {watcher as createOrder} from "./app/orders/create";
import {watcher as editOrder} from "./app/orders/editOrder";
import {watcher as userProfile} from "./app/userProfile/index";
import {watcher as userSettings} from "./app/userProfile/settings";
import {watcher as client} from "./app/client";
import {watcher as createClient} from "./app/client/create";
import {watcher as deleteClient} from "./app/client/delete";
import {watcher as clientCategory} from "./app/projectCategories/clientCategory";
import {watcher as createClientCategory} from "./app/projectCategories/createClientCategory";
import {watcher as deleteClientCategory} from "./app/projectCategories/deleteClientCategory";
import {watcher as clientTariff} from "./app/tariff/clientTariff";
import {watcher as createClientTariff} from "./app/tariff/createClientTariff";
import {watcher as deleteClientTariff} from "./app/tariff/deleteClientTariff";
import {watcher as clientProduct} from "./app/products/clientProduct";
import {watcher as createClientProduct} from "./app/products/createClientProduct";
import {watcher as deleteClientProduct} from "./app/products/deleteClientProduct";
import {watcher as clientComission} from "./app/comission/clientCommision";
import {watcher as createClientComission} from "./app/comission/createClientCommision";
import {watcher as clientBonus} from "./app/bonuses/clientBonus";
import {watcher as createClientBonus} from "./app/bonuses/createClientBonus";
import {watcher as tenants} from "./app/tenants";
import {watcher as createTenants} from "./app/tenants/create";
import {watcher as deleteTenants} from "./app/tenants/delete";
import {watcher as calculations} from "./app/calculations/index";
import {watcher as trainings} from "./app/trainings";
import {watcher as messages} from "./app/messages";
import {watcher as createMessages} from "./app/messages/create";
import {watcher as deleteMessages} from "./app/messages/delete";
import {watcher as createTrainings} from "./app/trainings/create";
import {watcher as createTeams} from "./app/teams/create";
import {watcher as teams} from "./app/teams";
import {watcher as locations} from "./app/locations";
import {watcher as createLocations} from "./app/locations/create";
import {watcher as deleteLocations} from "./app/locations/delete";
import {watcher as locationDeleted} from "./app/locations/deleted";
import {watcher as locationRestore} from "./app/locations/restore";
import {watcher as createTariffLocation} from "./app/tariff/tariffByLocations/create";
import {watcher as tariffLocation} from "./app/tariff/tariffByLocations";
import {watcher as deleteTeam} from "./app/teams/delete";
import {watcher as createImports} from "./app/imports/create";
import {watcher as imports} from "./app/imports/index";
import {watcher as salesTracker} from "./app/salesTracker";
import {watcher as createSalesTracker} from "./app/salesTracker/create";
import {watcher as deleteSalesTracker} from "./app/salesTracker/delete";
import {watcher as leadStatus} from "./app/salesTracker/leadStatus";
import {watcher as statuses} from "./app/salesTracker/statuses";
import {watcher as salesTrackerImports} from "./app/salesTracker/imports";
import {watcher as teamConditions} from "./app/teamConditions/index";
import {watcher as createConditions} from "./app/teamConditions/create";
import {watcher as deleteConditions} from "./app/teamConditions/delete";
import {watcher as orderImports} from "./app/orders/orderImports";
import {watcher as analytics} from "./app/analytics/index";
import {watcher as createGoals} from "./app/analytics/goals/create";
import {watcher as deleteGoals} from "./app/analytics/goals/delete";
import {watcher as goalViews} from "./app/analytics/goals/views";
import {watcher as contactlessOrder} from "./app/contactlessOrder/index";
import {watcher as monitoring} from "./app/monitoring/index";
import {watcher as conditionsV1} from "./app/teamConditionsV1/index";
import {watcher as deleteConditionsV1} from "./app/teamConditionsV1/delete";
import {watcher as createConditionsV1} from "./app/teamConditionsV1/create";
import {watcher as billingMonths} from "./app/calculations/billingMonths";
import {watcher as uploadTrainings} from "./app/trainings/upload";
import {watcher as marketPlace} from "./app/marketplace/index";
import {watcher as lockTenants} from "./app/tenants/locked";
import {watcher as categoryBonuses} from "./app/projectCategories/bonuses";
import {watcher as salesOrganizations} from "./app/salesOrganizations/index";
import {watcher as energy} from "./app/energy";
import {watcher as structures} from "./app/structures/index";
import {watcher as deleteStructures} from "./app/structures/delete";
import {watcher as createStructures} from "./app/structures/create";
import {watcher as tariffHistory} from "./app/tariff/tariffByLocations/history";
import {watcher as statusOrders} from "./app/orders/statuses";
import {watcher as notifications} from "./app/notifications";
import {watcher as leadAddresses} from "./app/salesTracker/addresses";
import {watcher as leadsByMap} from "./app/salesTracker/mapView";

export default function* root() {
	yield all([
		login(),
		logout(),
		register(),
		forgot(),
		navigation(),
		users(),
		usersDeleted(),
		usersRestore(),
		language(),
		templates(),
		createTemplate(),
		drawer(),
		createUser(),
		updateUser(),
		deleteUser(),
		products(),
		createProduct(),
		deleteProduct(),
		tariffs(),
		createTariff(),
		deleteTariff(),
		comission(),
		createComission(),
		projectCategories(),
		createProjectCategories(),
		deleteProjectCategories(),
		orders(),
		userOrders(),
		createOrder(),
		editOrder(),
		userProfile(),
		userSettings(),
		client(),
		createClient(),
		deleteClient(),
		clientCategory(),
		createClientCategory(),
		deleteClientCategory(),
		clientTariff(),
		createClientTariff(),
		deleteClientTariff(),
		clientProduct(),
		createClientProduct(),
		deleteClientProduct(),
		clientComission(),
		createClientComission(),
		clientBonus(),
		createClientBonus(),
		tenants(),
		createTenants(),
		deleteTenants(),
		calculations(),
		projectCategoriesDeleted(),
		projectCategoriesRestore(),
		tariffDeleted(),
		tariffRestore(),
		productsDeleted(),
		productsRestore(),
		clientDeleted(),
		clientRestore(),
		trainings(),
		createTrainings(),
		messages(),
		createMessages(),
		deleteMessages(),
		createTeams(),
		teams(),
		locations(),
		createLocations(),
		deleteLocations(),
		locationDeleted(),
		locationRestore(),
		createTariffLocation(),
		tariffLocation(),
		deleteTeam(),
		imports(),
		createImports(),
		salesTracker(),
		createSalesTracker(),
		deleteSalesTracker(),
		leadStatus(),
		statuses(),
		salesTrackerImports(),
		teamConditions(),
		createConditions(),
		deleteConditions(),
		orderImports(),
		analytics(),
		createGoals(),
		deleteGoals(),
		contactlessOrder(),
		monitoring(),
		conditionsV1(),
		deleteConditionsV1(),
		createConditionsV1(),
		billingMonths(),
		uploadTrainings(),
		marketPlace(),
		lockTenants(),
		categoryBonuses(),
		salesOrganizations(),
		energy(),
		structures(),
		deleteStructures(),
		createStructures(),
		tariffHistory(),
		statusOrders(),
		goalViews(),
		notifications(),
		leadAddresses(),
		leadsByMap(),
	]);
}
