import React from "react";
import CheckBoxComponent from "@src/common/checkbox";
import i18n from "@src/i18n";
import * as yup from "yup";

export const clientTypes = [
	{
		label: "Private",
		value: "Privat",
	},
	{
		label: "Gewerbe",
		value: "Gewerbe",
	},
	{
		label: "WEG",
		value: "Gewerbe_WEG",
	},
];

export const energyTypes = [
	{
		label: "Strom",
		value: "Strom",
	},
	{
		label: "Gas",
		value: "Gas",
	},
	{
		label: "Wärme",
		value: "Wärme",
	},
];

export const cancellationType = [
	{
		label: i18n.t("0%"),
		value: "0",
	},
	{
		label: i18n.t("5%"),
		value: "5",
	},
	{
		label: i18n.t("10%"),
		value: "10",
	},
	{
		label: i18n.t("15%"),
		value: "15",
	},
];

export const validationSchema = yup.object().shape({
	name: yup.string().nullable().label("name").required(i18n.t("NameIsReqired")),
	cancellationReserve: yup
		.object()
		.nullable()
		.label(i18n.t("cancellationReserve"))
		.required(i18n.t("CancellationReserveIsReq")),
	tariffPrice: yup
		.number()
		.min(0, i18n.t("TariffPriceNoNegatives"))
		.required(i18n.t("TariffPriceIsReq"))
		.nullable(),
	points: yup.number().nullable().min(0, i18n.t("NoNegativePoints")),
});

export const tableInputs = [
	{
		title: i18n.t("TableName"),
		inputType: "text",
		field: "tableName",
		required: true,
	},
	{
		title: [i18n.t("StartDate"), i18n.t("EndDate")],
		field: null,
		fields: ["from", "to"],
		inputType: "rangePicker",
		required: [true, false],
		picker: "date",
		type: "day",
	},
	{
		title: i18n.t("Locations"),
		field: "locationsOptions",
		inputType: "dropdown",
		required: true,
		multiple: true,
		inputValue: "obj",
	},
	{
		title: i18n.t("receivedCommission"),
		inputType: "number",
		field: "receivedCommission",
		defaultValue: 0,
		required: true,
	},
];
export const tariffHistoryColumns = [
	{
		key: "dates",
		name: "Consumption",
		editable: false,
		formatter: ({row}) => (
			<span>
				{row?.consumptionMin}-{row?.consumptionMax} kWn
			</span>
		),
	},
	{
		key: "price",
		name: "Price",
		formatter: ({row}) => <span>{row?.price}€</span>,
		editable: false,
	},
	{
		key: "isBestand",
		name: "Bestand",
		formatter: ({row}) => <CheckBoxComponent disabled checked={row?.isBestand} />,
	},
];

export const consumptionInputs = [
	{
		title: [i18n.t("StartDate"), i18n.t("EndDate")],
		fields: ["from", "to"],
		inputType: "rangePicker",
		required: [true, true],
	},
	{
		title: "Min",
		field: "consumptionMin",
		inputType: "number",
		required: true,
	},
	{
		title: "Max",
		field: "consumptionMax",
		inputType: "number",
		required: true,
		dependentField: "consumptionMin",
	},
	{
		title: "Price",
		field: "price",
		inputType: "number",
		required: true,
	},
	{
		title: "Bestand",
		field: "isBestand",
		inputType: "checkbox",
	},
];
