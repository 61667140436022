/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import * as yup from "yup";
import React from "react";
import moment from "moment";
import i18n from "../../../../i18n";
import {
	OVERHEAD_FOR,
	OVERHEAD_STATUSES,
	PERSONNEL_SPECIFICATIONS,
	SPECIFICATIONS,
	START_CONDITION_BONUS_OPTIONS,
	STATUSES,
	TYPES,
} from "./utils";
import StructureTable from "../../Structures/components/Table";
import ManagerVO from "./ManagerVO";
const baseConditionFields = [
	{
		title: i18n.t("Produktion"),
		field: "countType",
		inputType: "dropdown",
		options: TYPES,
		multiple: false,
		required: true,
		dependentFields: ["conditionOrders"],
	},
	{
		title: [i18n.t("StartDate"), i18n.t("EndDate")],
		fields: ["startDate", "endDate"],
		inputType: "rangePicker",
		dependentFields: ["team"],
		bottomBreak: true,
		required: [true, false],
	},
];

export const conditionElements = (
	getValidStuffes,
	structureId,
	id,
	getStufesByStructureId,
	tableStartDate,
	tableEndDate,
) => ({
	btnText: i18n.t("AddNewRow"),
	btnEditText: i18n.t("EditRow"),
	btnTextMultiple: i18n.t("CreateConditionTable"),
	btnEditTextMultiple: i18n.t("EditConditionTable"),
	lists: {
		overhead: {
			type: "single",
			elements: [
				...baseConditionFields,
				{
					field: "overheadType",
					inputType: "dropdown",
					options: OVERHEAD_FOR,
					title: i18n.t("OverheadFor"),
					multiple: false,
					required: true,
					dependentFields: ["structureId", "stufeId"],
				},
				{
					field: "structureId",
					inputType: "dropdown",
					title: i18n.t("Structure"),
					multiple: false,
					required: true,
					dependentFields: ["stufeId"],
					fetchOnChange: (id) => getStufesByStructureId(id),
					dependentFrom: "overheadType",
					dependentFromValue: ["DISTRIBUTION_CHANNEL"],
				},
				{
					field: "stufeId",
					inputType: "dropdown",
					title: i18n.t("Stufe"),
					multiple: false,
					required: true,
					dependentFrom: "overheadType",
					dependentFromValue: ["DISTRIBUTION_CHANNEL"],
				},
				{
					field: "roleOfTeam",
					inputType: "dropdown",
					options: OVERHEAD_STATUSES,
					title: i18n.t("Status"),
					multiple: false,
					required: true,
				},
				{
					title: i18n.t("Specification"),
					field: "specification",
					inputType: "dropdown",
					options: SPECIFICATIONS,
					multiple: false,
					required: true,
				},
				{
					field: "minTeamSize",
					title: i18n.t("MinNrOfTP"),
					inputType: "number",
					required: true,
				},
				{
					field: "amount",
					title: i18n.t("amountTeam"),
					inputType: "number",
					required: true,
				},
			],
		},
		bonus_own_contracts: {
			type: "single",
			elements: [
				...baseConditionFields,
				{
					field: "roleOfTeam",
					inputType: "dropdown",
					options: STATUSES,
					title: i18n.t("Status"),
					multiple: false,
					required: true,
				},
				{
					field: "value",
					title: i18n.t("amountPointTeam"),
					inputType: "number",
					required: true,
					dynamicLabel: true,
					changeLabelFrom: "FIXED_MONTHLY",
					staticLabel: i18n.t("amountPointTeam"),
					newLabel: i18n.t("monthlyFixedFee"),
				},
				{
					field: "conditionOrders",
					title: i18n.t("minNrTeamPoints"),
					inputType: "number",
					required: true,
					dependentField: "countType",
					disableWhen: "FIXED_MONTHLY",
				},
			],
		},
		mobility_conditions: {
			type: "single",
			elements: [
				...baseConditionFields,
				{
					field: "roleOfTeam",
					inputType: "dropdown",
					options: STATUSES,
					title: i18n.t("Status"),
					multiple: false,
					required: true,
				},
				{
					field: "countFrom",
					title: i18n.t("from"),
					inputType: "number",
					required: true,
				},
				{
					field: "countTo",
					title: i18n.t("to"),
					inputType: "number",
					required: true,
					dependentField: "countFrom",
				},
				{
					field: "amount",
					title: i18n.t("paymentAmountOnce"),
					inputType: "number",
					required: true,
				},
			],
		},
		induction_bonus_trainers_conditions: {
			type: "single",
			elements: [
				...baseConditionFields.map((field) => {
					if (field.inputType === "rangePicker") {
						return {
							...field,
							picker: "date",
							type: "day",
							dependentFields: ["team", "trainer", "intern"],
						};
					}
					return field;
				}),
				{
					title: "Add Teams",
					field: "team",
					inputType: "dropdown",
					name: "teams",
					newClass: "userName__dropdown",
					multiple: false,
					required: true,
					dependentFrom: "startDate",
					dependentFields: ["trainer", "intern"],
				},
				{
					title: "Add Trainer",
					field: "trainer",
					inputType: "dropdown",
					newClass: "userName__dropdown",
					multiple: false,
					options: [],
					generic: true,
					required: true,
					dependentFrom: "team",
					dependentFields: ["intern"],
				},
				{
					title: "Add Intern",
					field: "intern",
					inputType: "dropdown",
					newClass: "userName__dropdown",
					multiple: false,
					options: [],
					generic: true,
					required: true,
					dependentFrom: "trainer",
				},
				{
					field: "startConditionBonus",
					title: i18n.t("startConditionBonus"),
					inputType: "dropdown",
					required: true,
					options: START_CONDITION_BONUS_OPTIONS,
				},
				{
					field: "countInternFrom",
					title: i18n.t("countInternFrom"),
					inputType: "number",
					required: true,
				},
				{
					field: "countInternTo",
					title: i18n.t("countInternTo"),
					inputType: "number",
					required: true,
					// this key will be used to make disabled input if its dependentField doesn't have a value
					// and to add validation on yup
					dependentField: "countInternFrom",
				},
				{
					field: "amount",
					title: i18n.t("oneTimeAmount"),
					inputType: "number",
					required: true,
				},
			],
		},
		overhead_personnel_conditions: {
			type: "single",
			elements: [
				...baseConditionFields,
				{
					field: "roleOfTeam",
					inputType: "dropdown",
					options: OVERHEAD_STATUSES,
					title: i18n.t("Status"),
					multiple: false,
					required: true,
				},
				{
					title: i18n.t("Specification"),
					field: "specification",
					inputType: "dropdown",
					options: PERSONNEL_SPECIFICATIONS,
					multiple: false,
					required: true,
				},
				{
					field: "minTeamSize",
					title: i18n.t("MinNrOfTP"),
					inputType: "number",
					required: true,
				},
				{
					field: "amount",
					title: i18n.t("amountPointTeam"),
					inputType: "number",
					required: true,
					clearField: "oneTimeAmount",
				},
				{
					field: "oneTimeAmount",
					title: i18n.t("oneTimeAmount"),
					inputType: "number",
					required: true,
					clearField: "amount",
				},
			],
		},
		manager_vo: {
			type: "multiple",
			elements: [
				{
					field: "salesOrganizations",
					inputType: "dropdown",
					title: i18n.t("SalesOrganizations"),
					multiple: true,
					required: true,
					inputValue: "obj",
				},
				{
					title: [i18n.t("StartDate"), i18n.t("EndDate")],
					fields: ["startDate", "endDate"],
					inputType: "rangePicker",
					dependentFields: ["team"],
					bottomBreak: true,
					required: [true, false],
				},
				{
					title: i18n.t("CompensationIncomingCommission"),
					field: "compensationIncomingCommission",
					inputType: "number",
					required: true,
				},
				{
					field: "deduction2",
					title: i18n.t("Deduction2"),
					inputType: "number",
					required: true,
				},
				{
					field: "deduction1",
					title: i18n.t("Deduction1"),
					inputType: "number",
					required: true,
				},
			],
			component: (item) => <ManagerVO conditionTable={item} />,
		},
		career_conditions: {
			type: "multiple",
			elements: [
				{field: "name", inputType: "text", title: i18n.t("name"), required: true},
				{
					title: [i18n.t("StartDate"), i18n.t("EndDate")],
					fields: ["startDate", "endDate"],
					inputType: "rangePicker",
					dependentFields: ["team"],
					bottomBreak: true,
					required: [true, true],
					picker: "date",
					type: "day",
				},
				{
					field: "structureId",
					inputType: "dropdown",
					title: i18n.t("Structure"),
					multiple: false,
					required: true,
				},
			],
			component: (
				item,
				conditionTables,
				mergedColumns,
				setModal,
				getStufesByStructureId,
				tableId,
			) => (
				<div className="wrapper-structure">
					<StructureTable
						columns={mergedColumns}
						rows={item?.rows || []}
						id={item?.id}
						structureId={item?.structure?.id}
						setModal={setModal}
						addRow="career_conditions-row"
						fetchOnAddRow={getStufesByStructureId}
					/>
				</div>
			),
			rowElements: [
				{
					field: "fromStufeId",
					inputType: "dropdown",
					title: i18n.t("fromStufe"),
					multiple: false,
					required: true,
					fetchOnChange: (id) => getValidStuffes({id, structureId}),
					dependentFields: ["toStufeId"],
					disabled: id,
				},
				{
					field: "toStufeId",
					inputType: "dropdown",
					title: i18n.t("toStufe"),
					multiple: false,
					required: true,
					disabled: id,
				},
				{
					field: "targetOrders",
					inputType: "number",
					title: i18n.t("TargetOrders"),
					required: true,
				},
				{
					field: "targetDays",
					inputType: "number",
					title: i18n.t("targetDays"),
					required: true,
					max: moment(tableEndDate).diff(moment(tableStartDate), "days"),
				},
				{
					field: "cycle",
					inputType: "number",
					title: i18n.t("cycle"),
					required: true,
				},
				{
					field: "averagePerDay",
					inputType: "number",
					title: i18n.t("averagePerDay"),
				},
				{
					field: "conditionFor",
					inputType: "dropdown",
					title: i18n.t("conditionFor"),
					multiple: false,
					required: true,
					options: [
						{
							value: "TEAM",
							label: i18n.t("Team"),
						},
						{
							value: "OWN",
							label: i18n.t("Own"),
						},
					],
				},
			],
		},
	},
	hasID: "name",
	modalTitle: "User",
});

export const generateYupSchema = (inputs) => {
	const schemaFields = {};
	inputs.forEach((item) => {
		const {
			field,
			inputType,
			required,
			title,
			multiple,
			generic,
			dependentField,
			disableWhen,
			clearField,
		} = item;

		let fieldSchema;

		switch (inputType) {
			case "text":
				fieldSchema = yup.string().nullable();
				break;
			case "dropdown":
				fieldSchema = multiple
					? yup.array().nullable()
					: generic
					? yup.mixed().nullable()
					: yup.string().nullable();
				break;
			case "number":
			case "date":
				fieldSchema = yup.number().nullable();
				break;
			default:
				fieldSchema = yup.mixed().nullable();
		}
		if (required && !disableWhen && !clearField) {
			fieldSchema = fieldSchema.required(`${title} ${i18n.t("isRequired")}`);
		}
		schemaFields[field] = fieldSchema.label(title);
		if (dependentField && inputType === "number") {
			const parentField = inputs?.find((input) => input.field === dependentField);
			if (schemaFields?.[field] && schemaFields?.[parentField?.field]) {
				if (disableWhen) {
					schemaFields[field] = schemaFields[field].when([parentField.field], {
						is: (value) => value === disableWhen,
						then: yup.number().optional().nullable(),
						otherwise: yup
							.number()
							.required(`${title} ${i18n.t("isRequired")}`)
							.nullable(),
					});
				} else {
					schemaFields[field] = schemaFields[field]?.test(
						"dependentFieldMustBeGreaterThanParent",
						`${title} ${i18n.t("mustBeGreaterThan")} ${parentField.title}`,
						function (value) {
							const fromValue = this.resolve(yup.ref(parentField.field));
							return value > fromValue;
						},
					);
				}
			}
		} else if (clearField) {
			if (schemaFields?.[clearField] && schemaFields?.[field]) {
				schemaFields[field] = schemaFields[field]?.when([clearField], {
					is: (value) => !!value,
					then: yup.number().optional().nullable(),
					otherwise: yup
						.number()
						.required(`${title} ${i18n.t("isRequired")}`)
						.nullable(),
				});
			}
		}
	});
	return yup.object().shape(schemaFields);
};
