import React from "react";

const PrintTable = ({
	printTableRef,
	data,
	columns,
	childrenColumns,
	childrenField = "salesOrganizationUserViewList",
	renderFooter,
}) => {
	const renderChildren = (childrenData) => (
		<table className="custom-table">
			<thead>
				<tr>
					{childrenColumns?.map((i) => (
						<>
							<th>{i.name}</th>
						</>
					))}
				</tr>
			</thead>
			<tbody>
				{childrenData?.map((child, index) => (
					<tr key={`child-${index}`} className="detailed-row">
						{childrenColumns.map((column) => (
							<td key={column.key}>
								{column.formatter
									? column.printFormatter({row: child})
									: child[column.key] || "-"}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);

	const renderRow = (item, index) => (
		<React.Fragment key={index}>
			<tr className="parent-row">
				{columns.map((column) => (
					<td key={column.key}>
						{column.printFormatter
							? column.printFormatter({row: item})
							: item[column.key] || "-"}
					</td>
				))}
			</tr>
			{childrenColumns && item?.[childrenField] && (
				<tr className="child-table-row">
					<td colSpan={columns.length}>{renderChildren(item?.[childrenField])}</td>
				</tr>
			)}
		</React.Fragment>
	);

	return (
		<div className="wrapper" ref={printTableRef}>
			<table className="custom-table">
				<thead>
					<tr>
						{columns?.map((i) => (
							<>
								<th>{i.name}</th>
							</>
						))}
					</tr>
				</thead>
				<tbody>{data?.map((item, index) => renderRow(item, index))}</tbody>
			</table>
			{renderFooter && renderFooter()}
		</div>
	);
};

export default PrintTable;
