import React from "react";
import {Formik} from "formik";
import * as yup from "yup";
import InputComponent from "@common/input";
import ButtonComponent from "@common/button";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import CloseIcon from "@common/closeIcon/CloseIcon";
import {useTranslation} from "react-i18next";
import {actions as navigation} from "../../../store/sagas/app/navigation";
import {actions as forgotAction} from "../../../store/sagas/app/auth/forgot";

const ForgotPassword = (props) => {
	const {classNames, requestSent, forgotPassword, navigate, loading} = props;
	const {t} = useTranslation();

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.lowercase()
			.strict()
			.label("Email")
			.email(t("EmailIsRequred"))
			.required(t("EnterRefisteredEmail")),
	});

	return (
		<div className={`${classNames} forgot-password`}>
			<Formik
				initialValues={{email: ""}}
				validationSchema={validationSchema}
				onSubmit={(values, actions) => forgotPassword({values, formActions: actions})}
			>
				{({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
					<form autoComplete="off" noValidate onSubmit={handleSubmit}>
						<div className="forgot-password__div">
							<span className="forgot-password__title">{t("PasswordRecovery")}</span>
							<CloseIcon click={() => navigate(`../login`)} />
						</div>
						<div className="forgot-password__text">{t("EnterRecoveryEmail")}</div>
						<InputComponent
							name="email"
							placeholder={t("RecoveryEmail")}
							type="email"
							errorClass="errorClass"
							errors={errors.email}
							values={values.email}
							handleBlur={handleBlur}
							handleChange={handleChange}
							touched={touched.email}
						/>
						<ButtonComponent
							disabled={loading}
							buttonText={requestSent ? t("EmailSent") : t("Send")}
							classNames={`authBtn ${requestSent && "disabled"}`}
						/>
					</form>
				)}
			</Formik>
		</div>
	);
};

const mapStateToProps = (state) => ({
	requestSent: state.app.auth.forgot.requestSent,
	loading: state.app.auth.forgot.loading,
});
const mapDispatchToProps = {
	forgotPassword: forgotAction.forgotPassword,
	navigate: navigation.navigate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
