import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ENDPOINT} from "@src/utils/endpoint";

const logger = new Logger("Category bonuses");

const PREFIX = "@app/store/sagas/app/categories/bonuses";
export const FETCH_BONUSES = `${PREFIX}FETCH_BONUSES`;
export const FETCH_BONUSES_SUCCESS = `${PREFIX}FETCH_BONUSES_SUCCESS`;

const _state = {
	bonuses: [],
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_BONUSES_SUCCESS:
				draft.bonuses = payload;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchBonuses: (payload) => createAction(FETCH_BONUSES, {payload}),
	fetchBonusesSuccess: (payload) => createAction(FETCH_BONUSES_SUCCESS, {payload}),
};

export const sagas = {
	*fetchBonuses(payload) {
		try {
			const response = yield axios.get(
				ENDPOINT.GET_CATEGORY_BONUSES.replace(":id", payload.payload),
			);
			yield put(actions.fetchBonusesSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_BONUSES, sagas.fetchBonuses);
};
