import CustomPopover from "@src/common/Popover";
import React from "react";
import {userActionsItems} from "./popoverItems";

const LeadUserActionsPopover = (props) => {
	const {
		selectedRows,
		setShowActionsModal,
		query,
		setShowConfirmModal,
		loggedUser,
		selectAllStatuses,
		selectedStatuses,
	} = props;
	const handleActionsModal = (type, title) => {
		setShowActionsModal({
			open: true,
			type,
			title,
		});
	};
	const items = userActionsItems(
		selectedRows,
		handleActionsModal,
		query,
		setShowConfirmModal,
		loggedUser,
		selectAllStatuses,
		selectedStatuses,
	);
	return (
		<CustomPopover menu={{items}} customClass="popover__actions-wrapper">
			{props.children}
		</CustomPopover>
	);
};
export default LeadUserActionsPopover;
