import {CollapseIcon, ExpandIcon, MoveLeftIcon, MoveRightIcon} from "@src/assets/SvgComponents";
import React from "react";

function ExpandTables({expanded, setExpanded}) {
	const toggleExpand = (side) => {
		if (expanded === side) {
			setExpanded(null);
		} else {
			setExpanded(side);
		}
	};
	return (
		<div className="importsPage-tables_expand-container">
			<div
				className="left"
				onClick={() => toggleExpand("right")}
				style={{
					transform: expanded === "right" ? "rotate(-180deg)" : "rotate(0deg)",
				}}
			>
				<ExpandIcon />
			</div>
			<div
				className="right"
				onClick={() => toggleExpand("left")}
				style={{
					transform: expanded === "left" ? "rotate(0deg)" : "rotate(-180deg)",
				}}
			>
				<ExpandIcon />
			</div>
		</div>
	);
}

export default ExpandTables;
