import React from 'react'

const Item = ({ title, subTitle, onClick }) => {
    return (
        <div className='settings__item' onClick={onClick}>
            <p className='settings__item-title'>{title}</p>
            <p className='settings__item-subTitle'>{subTitle}</p>
        </div>
    )
}

export default Item