import {useEffect, useState} from "react";

function calculateBounds(latitude, longitude, radiusInKm = 10) {
	const earthRadius = 111; // Approx. radius of Earth in km per degree of latitude

	// Convert latitude to radians
	const latInRadians = (latitude * Math.PI) / 180;

	// Calculate latitude bounds
	const deltaLat = radiusInKm / earthRadius;
	const northLat = latitude + deltaLat;
	const southLat = latitude - deltaLat;

	// Calculate longitude bounds
	const deltaLng = radiusInKm / (earthRadius * Math.cos(latInRadians));
	const eastLng = longitude + deltaLng;
	const westLng = longitude - deltaLng;

	return {
		northLat,
		southLat,
		eastLng,
		westLng,
	};
}

export default function useGeoLocation({setQuery, query}) {
	const [coordinates, setCoordinates] = useState({lat: null, lng: null});
	const handleGetLocation = () => {
		navigator.geolocation.getCurrentPosition((position) => {
			const {latitude, longitude} = position.coords;
			const bounds = calculateBounds(latitude, longitude);
			setQuery({...query, bounds});
			setCoordinates({lat: latitude, lng: longitude});
		});
	};
	useEffect(() => {
		handleGetLocation();
	}, []);

	return {coordinates};
}
