import React from "react";
import i18n from "../../i18n";
import CustomAccordion from "../CustomAccordion";

const InputFile = ({
	onChange,
	label,
	labelClassName,
	className,
	errors,
	errorClass,
	touched,
	borderedInput,
	fileUploadedName,
	name,
	accept,
	generic = false,
	description,
	multiple = false,
	previewFile = null,
}) => (
	<div className={className}>
		<label className={labelClassName} htmlFor="upload-photo">
			{label}
		</label>
		{borderedInput && (
			<>
				<div htmlFor="upload-photo" className="input__type__file__bordered">
					<label htmlFor={`${name}upload-photo`}>
						{generic ? i18n.t("uploadPdf") : i18n.t("SelectFile")}
					</label>
				</div>
				{fileUploadedName && (
					<div
						className="input__type__file__bordered__name"
						style={{padding: previewFile && "0"}}
					>
						{previewFile ? (
							<CustomAccordion title={fileUploadedName}>
								<img src={previewFile} alt="" className="preview__uploaded__logo" />
							</CustomAccordion>
						) : (
							<p>{fileUploadedName}</p>
						)}
					</div>
				)}
			</>
		)}
		{description !== "" && <span className="input-description">{description}</span>}
		<input
			type="file"
			style={borderedInput && {display: "none"}}
			onChange={onChange}
			multiple={multiple}
			id={name ? `${name}upload-photo` : "upload-photo"}
			accept={accept}
		></input>
		{errors && touched && <span className={errorClass}>{errors}</span>}
	</div>
);

export default InputFile;
