import React from "react";
import {ReactComponent as CloseIconPNG} from "../../assets/img/close_black_24dp.svg";

function CloseIcon(props) {
	const {className, click} = props;
	return (
		<div className="close__icon__div ">
			<CloseIconPNG onClick={click} className={className} />
		</div>
	);
}

export default CloseIcon;
