import React from "react";
import {useTranslation} from "react-i18next";

const PersonalInfoComponent = ({userToDisplay}) => {
	const {mobilePhone, email, street, houseNumber, city, extVpNr} = userToDisplay;
	const {t} = useTranslation();
	return (
		<div className="personal__info">
			<div className="informations">
				<div className="informations-details">
					<p>{t("PhoneNumber")}</p>
					<span>{mobilePhone ? `${mobilePhone}` : "-"}</span>
				</div>
				<div className="informations-details">
					<p>{t("email")}</p>
					<span>{email}</span>
				</div>
				<div className="informations-details">
					<p>{t("Address")}</p>
					<span>
						{street}
						{houseNumber ? `${houseNumber} ,` : "-"}
						{city}
					</span>
				</div>
				<div className="informations-details">
					<p>{t("VPNumber")}</p>
					<span>{extVpNr}</span>
				</div>
			</div>
		</div>
	);
};

export default PersonalInfoComponent;
