import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/users/users";
import {actions as userDeletedActions} from "@sagas/users/users/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../../i18n";

const logger = new Logger("Users index");

const PREFIX = "@app/users/user/restore";
export const RESTORE = `${PREFIX}RESTORE`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	showModal: false,
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	restoreUser: (payload) => createAction(RESTORE, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*restoreUser({payload}) {
		yield put(actions.setLoading(true));
		const {id, setModal} = payload;
		try {
			const {totalSize} = yield select((state) => state.app.users.index);
			// const users = yield select((state) => state.app.users.deleted.users);
			// const user = users.find((e) => e.id === payload);
			yield axios.put(`/user/restore/${id}`);
			yield put(userDeletedActions.fetch());
			yield put(userActions.getUsers());
			yield put(userActions.editTotalSize(totalSize + 1));
			yield put(userDeletedActions.delete(id));
			ToastSuccesComponent(i18n.t("UserRestoreSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
			setModal(false);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(RESTORE, sagas.restoreUser);
};
