import DeleteModal from "@common/modal/deleteModal/deleteModal";
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as createLocationActions} from "@sagas/locations/create";
import {actions as locationActions} from "@sagas/locations";
import {actions as deleteLocationActions} from "@sagas/locations/delete";
import {actions as deletedLocationActions} from "@sagas/locations/deleted";
import {actions as restoreLocationActions} from "@sagas/locations/restore";
import {useTranslation} from "react-i18next";
import ArrowUp from "@assets/img/arrow_upward.svg";
import ArrowDown from "@assets/img/arrow_downward.svg";
import {checkRoles} from "@utils/checkRoles";
import {
	DeleteRowIcon,
	EditRowIcon,
	PreviewRowIcon,
	RestoreRowIcon,
} from "@src/assets/SvgComponents";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import Form from "@src/common/Form";
import {useUrlParams} from "@src/utils/useUrlParams";
import {locationElements} from "./locationsElements";

const ProjectCategories = (props) => {
	const {
		size,
		editSize,
		search,
		page,
		editPage,
		totalSize,
		fetchLocations,
		addLocation,
		locations,
		deleteLocation,
		loadingDelete,
		fetchLoading,
		createLoading,
		fetchDeleted,
		editPageDeleted,
		editSizeDeleted,
		deletedLocationsData,
		changeLocationOrder,
		restoreLocation,
		deactivateLocation,
		totalPages,
		toggleLocation,
		modals,
		singleLocation,
	} = props;
	const {closeToggleModal, openToggleModal, currentTab, setCurrentTab} = modals;
	const [reEditPage, setReEditPage] = useState(true);
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id: locationId} = getUrlParams("modal", "id");
	const {columns, lists} = locationElements;

	const {t} = useTranslation();
	const id = props.match.params.clientId;

	const changeLocOrder = (data) => {
		const itemIndex = locations.findIndex((item) => item?.id === data?.id);
		if (
			(itemIndex !== 0 && data?.action === "up") ||
			(itemIndex + 1 < locations?.length && data?.action === "down")
		) {
			changeLocationOrder({
				id: data?.id,
				newRow:
					data?.action === "up"
						? locations[itemIndex - 1]?.nr
						: locations[itemIndex + 1]?.nr,
				action: data?.action,
			});
		}
	};
	const handleLocationModal = (open, id) => setModal(open, id);
	const actionsFormatter = (row) => (
		<div className="table-actions-wrapper" style={{width: "180px", paddingRight: "20px"}}>
			{checkRoles("deactivateLocation") && (
				<HtmlTooltip
					title={<span>{!row?.isHidden ? t("SecondShow") : t("Hide")}</span>}
					placement="bottom-end"
					onClick={() => openToggleModal(row?.id)}
				>
					<span className={`preview__row ${!row?.isHidden && "hide"}`}>
						<PreviewRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderUpLocation") && (
				<HtmlTooltip title={<span>{t("Up")}</span>} placement="bottom-end">
					<img
						src={ArrowUp}
						alt=""
						style={{pointerEvents: locations?.[0]?.id === row?.id && "none"}}
						onClick={() => changeLocOrder({id: row?.id, action: "up"})}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderDownLocation") && (
				<HtmlTooltip title={<span>{t("Down")}</span>} placement="bottom-end">
					<img
						src={ArrowDown}
						alt=""
						style={{pointerEvents: locations?.[locations?.length - 1]?.id === row?.id}}
						onClick={() => changeLocOrder({id: row?.id, action: "down"})}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("editLocation") && (
				<HtmlTooltip title={<span>{t("editLocation")}</span>} placement="bottom-end">
					<span onClick={() => handleLocationModal("locations", row?.id)}>
						<EditRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deactivateLocation") && (
				<HtmlTooltip title={<span>{t("deactivateLocation")}</span>} placement="bottom-end">
					<span onClick={() => handleLocationModal("deactivate", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actionsDeletedFormatter = (row) => (
		<div className="table-actions-wrapper">
			{checkRoles("activateLocation") && (
				<HtmlTooltip title={<span>{t("ActivateLocation")}</span>} placement="bottom-end">
					<span onClick={() => handleLocationModal("restore", row?.id)}>
						<RestoreRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deleteLocation") && (
				<HtmlTooltip
					title={<span>{t("PermanentDeleteLocation")}</span>}
					placement="bottom-end"
				>
					<span onClick={() => handleLocationModal("delete", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const baseActionsProps = {
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: 50,
		headerCellClass: "headerStyles",
	};
	const actions = {
		...baseActionsProps,
		formatter: ({row}) => actionsFormatter(row),
		width: 180,
	};
	const actionsDeleted = {
		...baseActionsProps,
		formatter: ({row}) => actionsDeletedFormatter(row),
	};
	useEffect(() => {
		if (currentTab === "active") {
			fetchLocations({clientId: id, search});
		}
	}, [page, size, search, currentTab]);
	useEffect(() => {
		if (currentTab === "inactive") {
			fetchDeleted({clientId: id, search});
		}
	}, [deletedLocationsData?.size, deletedLocationsData?.page, search, currentTab]);

	const tableProps = {
		active: {
			data: locations,
			page,
			columns: [...columns, actions],
			size,
			totalSize,
			totalPages,
			editPage,
			editSize,
			loading: fetchLoading,
		},
		inactive: {
			columns: [...columns, actionsDeleted],
			data: deletedLocationsData.list,
			editPage: editPageDeleted,
			editSize: editSizeDeleted,
			size: deletedLocationsData.size,
			page: deletedLocationsData.page,
			totalPages: deletedLocationsData.totalPages,
			totalSize: deletedLocationsData.totalSize,
			loading: deletedLocationsData.loading,
		},
	};
	const formData = {
		inputs: lists,
		id: locationId,
		title: locationId ? t("editLocation") : t("AddLocation"),
		submitFn: (values, actions) =>
			addLocation({
				values: {...values, clientId: id},
				formActions: actions,
				handleLocationModal,
			}),
		getUrl: "/location/:id",
	};
	const modalConfig = {
		deactivate: {
			confirm: () => deactivateLocation({id: locationId, client: id, handleLocationModal}),
			type: "deactivate",
			word: t("thisLocation"),
			disabled: loadingDelete,
		},
		restore: {
			confirm: () => restoreLocation({locationId, handleLocationModal}),
			type: "restore",
			word: t("thisLocation"),
			modalType: "activate",
		},
		delete: {
			confirm: () =>
				deleteLocation({
					id: locationId,
					client: id,
					setPermanantDeleteModal: handleLocationModal,
				}),
			type: "delete",
			word: t("thisLocation"),
			modalType: "permanantDelete",
			disabled: loadingDelete,
		},
		visibility: {
			confirm: () =>
				toggleLocation({
					id: singleLocation?.id,
					closeModal: closeToggleModal,
					isHidden: !singleLocation?.isHidden,
				}),
			type: "visibility",
			newTitle: `${t(`AreYouSureTo${singleLocation?.isHidden ? "Show" : "Hide"}`)} "${
				singleLocation?.locationName
			}"?`,
			disabled: loadingDelete,
			calculationModal: true,
		},
	};
	const activeModal = modal ? modalConfig[modal] : null;
	return (
		<>
			{activeModal && (
				<DeleteModal
					calculationModal={activeModal?.calculationModal}
					setShowDeleteModal={() => handleLocationModal(false)}
					confirm={activeModal.confirm}
					newTitle={activeModal?.newTitle}
					open={activeModal.type === modal}
					handleClose={() => handleLocationModal(false)}
					disabled={activeModal.disabled}
					modalType={activeModal.modalType}
					word={activeModal.word}
				/>
			)}
			<Form
				open={modal === "locations"}
				handleClose={() => handleLocationModal(false)}
				loading={createLoading}
				formData={formData}
			/>
			<div className="loader__and__table">
				<DataGridTable
					withTabs
					currentTab={currentTab}
					onTabClick={setCurrentTab}
					pagination
					reEditPage={reEditPage}
					setReEditPage={setReEditPage}
					{...tableProps[currentTab]}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.projectCategories.createClientCategory.loading,
	locationInitialValues: state.app.locations.create.locationInitialValues,
	locations: state.app.locations.index.locations,
	singleLocation: state.app.locations.index.singleLocation,
	fetchLoading: state.app.locations.index.isLoading,
	showModal: state.app.locations.create.showModal,
	totalPages: state.app.locations.index.totalPages,
	showModalDelete: state.app.locations.delete.showModal,
	loadingDelete: state.app.locations.delete.loading,
	totalSize: state.app.locations.index.totalSize,
	size: state.app.locations.index.size,
	page: state.app.locations.index.page,
	createLoading: state.app.locations.create.loading,
	deletedLocationsData: state.app.locations.deleted,
	restoreLocationsData: state.app.locations.restore,
});

const mapDispatchToProps = {
	fetchLocations: locationActions.fetchLocations,
	addLocation: createLocationActions.addLocation,
	fillLocation: createLocationActions.fillLocation,
	setShowModal: createLocationActions.setShowModal,
	editPage: locationActions.editPage,
	editSize: locationActions.editSize,
	deleteLocation: deleteLocationActions.deleteLocation,
	deactivateLocation: deleteLocationActions.deactivateLocation,
	changeLocationOrder: locationActions.changeLocationOrder,
	fetchDeleted: deletedLocationActions.fetch,
	editPageDeleted: deletedLocationActions.editPage,
	editSizeDeleted: deletedLocationActions.editSize,
	restoreLocation: restoreLocationActions.restore,
	toggleLocation: locationActions.toggleLocationVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectCategories));
