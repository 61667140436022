import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ENDPOINT} from "@src/utils/endpoint";
import {filterItemAndUpdateState, findItemAndUpdateState} from "./helper";

const logger = new Logger("Structure index");
const PREFIX = "@app/structures/index";

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_STRUCTURES = `${PREFIX}GET_STRUCTURES`;
export const GET_STRUCTURES_SUCCESS = `${PREFIX}GET_STRUCTURES_SUCCESS`;
export const ADD_TABLE_ON_STATE = `${PREFIX}ADD_TABLE_ON_STATE`;
export const REMOVE_TABLE_FROM_STATE = `${PREFIX}REMOVE_TABLE_FROM_STATE`;
export const ADD_STUFE_ON_STATE = `${PREFIX}ADD_STUFE_ON_STATE`;
export const GET_STUFES_BY_STRUCTURE_ID = `${PREFIX}GET_STUFES_BY_STRUCTURE_ID`;
export const GET_STUFES_BY_STRUCTURE_ID_SUCCESS = `${PREFIX}GET_STUFES_BY_STRUCTURE_ID_SUCCESS`;
export const SET_STUFE_LOADER = `${PREFIX}SET_STUFE_LOADER`;
export const UPDATE_STUFE_ON_STATE = `${PREFIX}UPDATE_STUFE_ON_STATE`;
export const UPDATE_STRUCTURE_ON_STATE = `${PREFIX}UPDATE_STRUCTURE_ON_STATE`;
export const DELETE_STUFE_ON_STATE = `${PREFIX}DELETE_STUFE_ON_STATE`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const GET_ALL_STRUCTURES = `${PREFIX}GET_ALL_STRUCTURES`;
export const GET_ALL_STRUCTURES_SUCCESS = `${PREFIX}GET_ALL_STRUCTURES_SUCCESS`;
export const GET_VALID_STUFFES = `${PREFIX}GET_VALID_STUFFES`;
export const GET_VALID_STUFFES_SUCCESS = `${PREFIX}GET_VALID_STUFFES_SUCCESS`;

const _state = {
	loading: false,
	structures: [],
	stufes: [],
	stufeLoading: false,
	paginationData: {
		page: 1,
		size: 30,
		totalSize: 0,
		totalPages: 0,
	},
	allStructures: [],
	validStuffes: [],
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case ADD_TABLE_ON_STATE:
				draft.structures = [payload, ...state.structures];
				break;
			case REMOVE_TABLE_FROM_STATE:
				draft.structures = filterItemAndUpdateState(state.structures, payload);
				break;
			case DELETE_STUFE_ON_STATE:
				draft.stufes = filterItemAndUpdateState(state.stufes, payload);
				break;
			case ADD_STUFE_ON_STATE:
				draft.stufes = [payload, ...state.stufes];
				break;
			case GET_STRUCTURES_SUCCESS:
				draft.structures =
					state?.paginationData?.page > 1
						? [...state.structures, ...payload.data]
						: payload.data;
				draft.paginationData = {
					...state.paginationData,
					totalPages: payload.totalPages,
					totalSize: payload.totalSize,
				};
				break;
			case GET_STUFES_BY_STRUCTURE_ID_SUCCESS:
				draft.stufes = payload.map((i) => ({...i, label: i.name, value: i.id}));
				break;
			case SET_STUFE_LOADER:
				draft.stufeLoading = payload;
				break;
			case UPDATE_STUFE_ON_STATE:
				draft.stufes = findItemAndUpdateState(state.stufes, payload);
				break;
			case UPDATE_STRUCTURE_ON_STATE:
				draft.structures = findItemAndUpdateState(state.structures, payload);
				break;
			case EDIT_PAGE:
				draft.paginationData = {
					...state.paginationData,
					page: payload,
				};
				break;
			case GET_ALL_STRUCTURES_SUCCESS:
				draft.allStructures = payload.map((i) => ({...i, label: i.name, value: i.id}));
				break;
			case GET_VALID_STUFFES_SUCCESS:
				draft.validStuffes = payload.map((i) => ({...i, label: i.name, value: i.id}));
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getStructures: (payload) => createAction(GET_STRUCTURES, {payload}),
	getStructuresSuccess: (payload) => createAction(GET_STRUCTURES_SUCCESS, {payload}),
	addTableOnState: (payload) => createAction(ADD_TABLE_ON_STATE, {payload}),
	removeTableFromState: (payload) => createAction(REMOVE_TABLE_FROM_STATE, {payload}),
	addStufeOnState: (payload) => createAction(ADD_STUFE_ON_STATE, {payload}),
	getStufesByStructureId: (payload) => createAction(GET_STUFES_BY_STRUCTURE_ID, {payload}),
	getStufesByStructureIdSuccess: (payload) =>
		createAction(GET_STUFES_BY_STRUCTURE_ID_SUCCESS, {payload}),
	setStufeLoader: (payload) => createAction(SET_STUFE_LOADER, {payload}),
	updateStuffeOnState: (payload) => createAction(UPDATE_STUFE_ON_STATE, {payload}),
	updateStructureOnState: (payload) => createAction(UPDATE_STRUCTURE_ON_STATE, {payload}),
	deleteStufeOnState: (payload) => createAction(DELETE_STUFE_ON_STATE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	getAllStructures: (payload) => createAction(GET_ALL_STRUCTURES, {payload}),
	getAllStructuresSuccess: (payload) => createAction(GET_ALL_STRUCTURES_SUCCESS, {payload}),
	getValidStuffes: (payload) => createAction(GET_VALID_STUFFES, {payload}),
	getValidStuffesSuccess: (payload) => createAction(GET_VALID_STUFFES_SUCCESS, {payload}),
};

export const sagas = {
	*getStructures() {
		yield put(actions.setLoading(true));
		try {
			const {page, size} = yield select((state) => state.app.structures.index.paginationData);
			const response = yield axios.get(
				ENDPOINT.GET_STRUCTURE_TABLES.replace(":size", size).replace(":page", page),
			);
			yield put(actions.getStructuresSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getStufesByStructureId({payload}) {
		yield put(actions.setStufeLoader(true));
		try {
			const response = yield axios.get(ENDPOINT.FETCH_STUFES.replace(":id", payload));
			yield put(actions.getStufesByStructureIdSuccess(response?.data?.data || []));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setStufeLoader(false));
		}
	},
	*getAllStructures() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(ENDPOINT.GET_ALL_STRUCTURES);
			yield put(actions.getAllStructuresSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getValidStuffes({payload}) {
		yield put(actions.setStufeLoader(true));
		const {id, structureId} = payload;
		try {
			const response = yield axios.get(
				ENDPOINT.FETCH_VALID_STUFFES.replace(":id", id).replace(
					":structureId",
					structureId,
				),
			);
			yield put(actions.getValidStuffesSuccess(response?.data?.data || []));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setStufeLoader(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_STRUCTURES, sagas.getStructures);
	yield takeLatest(GET_STUFES_BY_STRUCTURE_ID, sagas.getStufesByStructureId);
	yield takeLatest(GET_ALL_STRUCTURES, sagas.getAllStructures);
	yield takeLatest(GET_VALID_STUFFES, sagas.getValidStuffes);
};
