import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as logoutAction} from "@sagas/auth/logout";
import {checkRoles} from "@src/utils/checkRoles";
import {actions as navigation} from "../../store/sagas/app/navigation";
import {actions as drawer} from "../../store/sagas/app/drawer";

const DrawerItems = (props) => {
	const {navigate, setDrawer, element, submitLogout, collapse, privateTopics, channel} = props;
	const handleNavigation = (element) => {
		if (element.link === null) {
			submitLogout();
		} else {
			navigate(element.link);
			setDrawer(element.drawerName);
		}
	};

	return (
		<>
			{checkRoles(element.show) && (
				<div
					className={`drawer-items_ ${
						props.location.pathname.includes(element.drawerName) && "active"
					}`}
					onClick={() => handleNavigation(element)}
				>
					<div
						style={{
							width: !collapse ? "100%" : "",
							// justifyContent: !collapse ? "center" : "flex-start",
						}}
						className="drawer-items__wrapper"
					>
						<div className="drawer-items__icon">{element?.icon}</div>
						<div className="drawer-items__text">
							<span>{element.name}</span>
						</div>
						{/* {collapse && (
						)} */}
						{element.drawerName === "messages" &&
						privateTopics?.length > 0 &&
						channel?.length > 0
							? [...privateTopics, ...channel].some(
									(msg) => msg.notSeenMessages > 0,
							  ) && (
									<div
										className="messages__topic__name__unseen"
										style={{
											backgroundColor: props.location.pathname.includes(
												element.drawerName,
											)
												? "#fff"
												: "#d13532",
										}}
									></div>
							  )
							: null}
					</div>
				</div>
			)}
		</>
	);
};
const mapStateToProps = (state) => ({
	drawer: state.app.drawer?.activeDrawer,
	privateTopics: state.app.messages.index.private,
	channel: state.app.messages.index.channel,
});
const mapDispatchToProps = {
	navigate: navigation.navigate,
	setDrawer: drawer.setDrawer,
	submitLogout: logoutAction.logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DrawerItems));
