/* eslint-disable max-len */
import {CalendarIcon} from "@src/assets/SvgComponents";
import {DatePicker} from "antd";
import moment from "moment";
import React, {useState} from "react";
import {convertDateType} from "@src/components/generic-pdf/createOrder/components/PreviewOrderForm/helpers";
import {getFocusedState} from "./helper";
import useDatePicker from "./useDatePicker";

const GenericDatePicker = ({setFieldValue, element, error, value, label, disabled}) => {
	const {inputType, name, dateType, minDate, maxDate} = element.item;
	const isBirthdayInput = inputType === "BIRTHDAY";
	const convertedType = convertDateType(dateType);
	const {disabledDate, fieldError, inputs} = useDatePicker({
		value,
		isBirthdayInput,
		setFieldValue,
		convertedType,
		minDate,
		maxDate,
		name,
	});
	const [isOpen, setIsOpen] = useState(false);
	const handleDate = (date) => {
		if (date) {
			setIsOpen(false);
			setFieldValue(element?.name, moment(date).format("DD/MM/YYYY"));
		}
	};
	return (
		<>
			{label && <span className="label">{label}</span>}
			<div
				className={`picker__wrapper ${fieldError ? "withError" : ""} ${
					disabled && "disabled"
				}`}
			>
				<div className="picker__wrapper-inputWrapper">
					{inputs.map(
						({ref, value, placeholder, onChangeText, maxLength, name}, index) => (
							<React.Fragment key={`${ref}--${index}`}>
								<input
									type="number"
									onChange={(e) => onChangeText(e.target.value)}
									value={value}
									maxLength={maxLength || 2}
									name={name}
									ref={ref}
									min={0}
									className={`singleInput ${
										getFocusedState(ref) ? "focused" : ""
									} ${maxLength === 4 ? "big" : "small"} `}
									placeholder={placeholder}
									onFocus={() => ref?.current?.focus()}
									disabled={disabled || (fieldError && !getFocusedState(ref))}
								/>
								{index < inputs.length - 1 && <span className="slash">/</span>}
							</React.Fragment>
						),
					)}
				</div>
				{!disabled && (
					<span onClick={() => setIsOpen(true)} className="calendarIcon">
						<CalendarIcon width="15px" height="40px" fill="#979797" />
					</span>
				)}
			</div>
			{isOpen && (
				<DatePicker
					open
					style={{visibility: "hidden", width: 0}}
					date={moment().toDate()}
					disabledDate={disabledDate}
					onChange={handleDate}
					onOpenChange={setIsOpen}
				/>
			)}
			{(error || fieldError) && <span className="errorText">{fieldError || error}</span>}
		</>
	);
};

export default GenericDatePicker;
