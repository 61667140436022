import React from "react";

const ButtonInput = ({options, title, onChange, value}) => (
	<div className="button-input-wrapper">
		<span className="button-input-wrapper-title">{title}</span>
		<div className="button-input-wrapper-options">
			{options?.map((opt, index) => (
				<div
					key={`${opt.id * index + 22}`}
					className="single-option"
					style={{backgroundColor: `${opt?.color}33`}}
					onClick={() => onChange(opt.id, opt)}
				>
					<div className="radio-wrapper" style={{borderColor: opt.color}}>
						{value === opt.id && (
							<div className="checked" style={{backgroundColor: opt.color}} />
						)}
					</div>
					<span style={{color: opt.color}}>{opt.label}</span>
				</div>
			))}
		</div>
	</div>
);
export default ButtonInput;
