import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as messagesActions} from "@sagas/messages";
import {actions as createMessagesActions} from "@sagas/messages/create";
import {actions as usersActions} from "@sagas/users/users";
import {actions as navigation} from "@sagas/navigation";
import {NotificationsIcon, SearchIcon} from "@assets/SvgComponents";
import Loader from "@common/Loader";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import {useUrlParams} from "@src/utils/useUrlParams";
import {t} from "i18next";
import {actions as notificationsActions} from "@sagas/notifications/index";
import MessagesLeftTopics from "../MessagesLeftTopics";
import CreateChannel from "../CreateChannel";
import i18n from "../../../i18n";
import CreateDirectMessage from "../CreateDirectMessage";
const MessagesLeftSide = (props) => {
	const [channelsOpen, setChannelsOpen] = useState(true);
	const [privateOpen, setPrivateOpen] = useState(true);
	const {
		fetchAllTopics,
		privateTopics,
		getUsersByTenant,
		usersByTenant,
		match,
		fetchTopicsByTopicId,
		channel,
		setAddNewMessage,
		loggedUser,
		insideTopic,
		fetchLoading,
		messageId,
		page,
		fetchedPages,
		navigate,
		getSystemNotifications,
		systemNotificationsState,
		getUnreadNotificationsCount,
	} = props;
	const {page: notificationsPage, count} = systemNotificationsState;
	const [showSearchInput, setShowSearchInput] = useState(false);
	const {setModal, getUrlParams, setUrlParams} = useUrlParams();
	const {modal, notifications} = getUrlParams("modal", "notifications");
	const [searchValue, setSearchValue] = useState("");
	const handleClose = () => {
		setSearchValue("");
		setShowSearchInput(false);
	};
	useEffect(() => {
		fetchAllTopics("private");
		fetchAllTopics("channel");
		getUsersByTenant();
		// eslint-disable-next-line
	}, [])
	useEffect(() => {
		if (notificationsPage === 1) {
			getUnreadNotificationsCount();
		}
		if (notifications === "true") {
			getSystemNotifications();
		}
	}, [notificationsPage, notifications]);

	useEffect(() => {
		if (
			match.params.id &&
			(!fetchedPages.includes(page + 1) || !fetchedPages.includes(page - 1))
		) {
			fetchTopicsByTopicId(match.params.id);
		}
		// eslint-disable-next-line
  }, [page, match.params.id])

	let usersOptions = usersByTenant;
	const handleNewMessage = () => {
		navigate("/admin/messages");
		setModal("message");
	};

	useEffect(() => {
		if (insideTopic?.messages?.length === 0 && match.params.id) {
			fetchTopicsByTopicId(match.params.id);
		}
	}, [messageId, insideTopic?.messages?.length]);

	usersOptions = usersOptions?.map((user) => ({
		...user,
		name: `${user?.firstName} ${user?.lastName}`,
		value: `${user?.firstName} ${user?.lastName}`,
	}));
	const handleOpenNotifications = () => {
		navigate(`/admin/messages`);
		setUrlParams(true, "notifications");
		getSystemNotifications();
	};
	return (
		<>
			<CreateChannel show={modal === "channel"} close={() => setModal(false)} />
			<CreateDirectMessage show={modal === "message"} close={() => setModal(false)} />
			<div className="messages__left__side__container">
				<div
					className="messages__left__side__container-header 
		                messages__left__side__container-search "
				>
					{!showSearchInput ? (
						<>
							<div className="messages__left__side__container-header-user">
								{loggedUser?.imgUrl && (
									<div className="user-photo">
										<img src={loggedUser?.imgUrl} alt="" />
									</div>
								)}
								<span className="user-name">
									{loggedUser?.firstName} {loggedUser?.lastName}
								</span>
							</div>
							<div className="messages__left__side__container-header-actions">
								<span
									className="search-icon"
									onClick={() => setShowSearchInput(true)}
								>
									<SearchIcon fill="#979797" />
								</span>
							</div>
						</>
					) : (
						<BigSearchBar
							hasCloseIcon={true}
							handleClose={handleClose}
							value={searchValue}
							handleSearch={(e) => {
								setSearchValue(e.target.value);
							}}
							customClass="search-input-messages"
						/>
					)}
				</div>
				<div
					onClick={() => handleOpenNotifications()}
					className={`system-notifications-wrapper ${
						notifications === "true" ? "active" : ""
					}`}
				>
					<span className="system-notifications-wrapper-title">
						{t("SystemMessages")}
					</span>
					<span className="system-notifications-wrapper-icons">
						<NotificationsIcon
							fill={notifications === "true" ? "#fff" : "var(--color-primary)"}
						/>
						{count > 0 && <div className="count">{count < 100 ? count : "99+"}</div>}
					</span>
				</div>
				<div className="messages__topic__wrapper">
					<MessagesLeftTopics
						topicContainerName={i18n.t("Channels")}
						id={1}
						clicked={() => setChannelsOpen(!channelsOpen)}
						closed={channelsOpen}
						clickAdd={() => setModal("channel")}
					/>
					{fetchLoading && <Loader />}
					{channelsOpen && (
						<>
							{searchValue !== ""
								? channel &&
								  channel
										.filter((item) =>
											item?.name
												?.toLowerCase()
												.includes(searchValue.toLowerCase()),
										)
										.map((item, index) => (
											<MessagesLeftTopics
												key={index}
												topicName={item?.name}
												id={item?.topicId}
												clickOption={() => setAddNewMessage(false)}
												item={item}
												lastMessage={item?.lastMessage}
											/>
										))
								: channel &&
								  channel.map((item, index) => (
										<MessagesLeftTopics
											key={index}
											topicName={item?.name}
											id={item?.topicId}
											clickOption={() => setAddNewMessage(false)}
											item={item}
											lastMessage={item?.lastMessage}
										/>
								  ))}
						</>
					)}
				</div>
				<div className="messages__topic__wrapper">
					<MessagesLeftTopics
						topicContainerName={i18n.t("DirectMessage")}
						id={5}
						clicked={() => setPrivateOpen(!privateOpen)}
						closed={privateOpen}
						clickAdd={handleNewMessage}
					/>
					{fetchLoading && <Loader />}
					{privateOpen && (
						<>
							{searchValue !== ""
								? privateTopics &&
								  privateTopics
										.filter((item) =>
											item?.name
												?.toLowerCase()
												.includes(searchValue.toLowerCase()),
										)
										.map((item, index) => (
											<MessagesLeftTopics
												key={index}
												topicName={item?.name}
												privateUnread
												color="#ede8c3"
												numOfUnread={item?.topicId}
												privateTopic
												item={item}
												id={item?.topicId}
												clickOption={() => setAddNewMessage(false)}
											/>
										))
								: privateTopics &&
								  privateTopics.map((item, index) => (
										<MessagesLeftTopics
											key={index}
											topicName={item?.name}
											privateUnread
											color="#ede8c3"
											numOfUnread={item?.topicId}
											privateTopic
											item={item}
											id={item?.topicId}
											clickOption={() => setAddNewMessage(false)}
										/>
								  ))}
						</>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	insideTopic: state.app.messages.index.insideTopic,
	fetchLoading: state.app.messages.index.fetchLoading,
	privateTopics: state.app.messages.index.private,
	topicId: state.app.messages.index.topicId,
	channel: state.app.messages.index.channel,
	usersByTenant: state.app.users.index.usersByTenant,
	loggedUser: state.app.users.index.loggedUser,
	messageId: state.app.messages.index.messageId,
	page: state.app.messages.index.page,
	fetchedPages: state.app.messages.index.fetchedPages,
	systemNotificationsState: state.app.notifications.index,
});

const mapDispatchToProps = {
	fetchAllTopics: messagesActions.fetchAllTopics,
	getUsersByTenant: usersActions.getUsersByTenant,
	fetchTopicsByTopicId: messagesActions.fetchTopicsByTopicId,
	setAddNewMessage: createMessagesActions.setAddNewMessage,
	navigate: navigation.navigate,
	getSystemNotifications: notificationsActions.getSystemNotifications,
	getUnreadNotificationsCount: notificationsActions.getUnreadNotificationsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessagesLeftSide));
