import React from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import i18n from "../../i18n";

const CalculationTablePdf = ({data}) => (
	<>
		<View style={styles.topContentTable}>
			<View style={styles.topContentTableHeader}>
				<Text style={{fontWeight: "bold", fontFamily: "Helvetica-Bold"}}>
					{i18n.t("HotlineQueries")}{" "}
				</Text>
			</View>
			<View style={styles.topContentTableRows}>
				<Text
					style={{
						marginLeft: 4,
						width: 80,
						borderRight: "1px #080808 solid",
						padding: 2,
						fontFamily: "Helvetica",
					}}
				>
					Whatsapp
				</Text>
				<Text
					style={{
						marginLeft: 4,
						padding: 2,
						fontFamily: "Helvetica",
						textAlign: "right",
						width: 120,
					}}
				>
					{data?.phoneNumber}
				</Text>
			</View>
			<View style={{...styles.topContentTableRows, borderBottom: "none"}}>
				<Text
					style={{
						marginLeft: 4,
						width: 80,
						borderRight: "1px #080808 solid",
						padding: 2,
						fontFamily: "Helvetica",
					}}
				>
					Email
				</Text>
				<Text
					style={{
						marginLeft: 4,
						padding: 2,
						fontFamily: "Helvetica",
						textAlign: "right",
						width: 120,
					}}
				>
					{data?.email}
				</Text>
			</View>
		</View>
	</>
);

export default CalculationTablePdf;

const styles = StyleSheet.create({
	topContent: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: "20px",
	},
	topContentName: {
		display: "flex",
		flexDirection: "column",
		fontSize: "8px",
	},
	topContentTable: {
		display: "flex",
		flexDirection: "column",
		fontSize: "8px",
		border: "1px #000 solid",
		width: "200px",
	},
	topContentTableHeader: {
		borderBottom: "1px #000 solid",
		fontWeight: "bold",
		backgroundColor: "#EFEFEF",
		padding: 5,
	},
	topContentTableRows: {
		display: "flex",
		flexDirection: "row",
		fontSize: 7,
		borderBottom: "1px #000 solid",
	},
	topContentTableRow: {
		display: "flex",
		flexDirection: "row",
		fontSize: 7,
		fontFamily: "Helvetica",
	},
});
