/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export const changeStatusOrder = (list, itemIndex, action) => {
	const newList = [...list];
	const clickedItem = newList.splice(itemIndex, 1);
	newList.splice(action === "decrement" ? itemIndex - 1 : itemIndex + 1, 0, {
		...clickedItem?.[0],
		orderNumber: newList[action === "decrement" ? itemIndex - 1 : itemIndex]?.orderNumber,
	});
	newList[itemIndex] = {
		...newList[itemIndex],
		orderNumber: clickedItem?.[0]?.orderNumber,
	};
	return newList;
};

export const updateLeadsOnState = (state, item, statuses) => {
	const data = {};
	for (const key in state) {
		const currentStatus = state[key];
		const foundLead = currentStatus.data.findIndex(
			(lead) => lead.id === item.id && lead.statusId === item.statusId,
		);
		if (foundLead !== -1) {
			// Update the item in the existing array with new data
			const copiedLeads = [...currentStatus.data];
			copiedLeads[foundLead] = item;
			data[key] = {...currentStatus, data: copiedLeads};
		} else {
			// Exclude the item from the data of the current status
			data[key] = {
				...currentStatus,
				data: currentStatus.data.filter((lead) => lead.id !== item.id),
			};
		}
	}
	// Find the newly added status name
	const newlyAddedStatus = statuses.find((status) => status.id === item.statusId);
	if (newlyAddedStatus?.id !== item?.statusId) {
		const {name} = newlyAddedStatus;
		// Add the item to the data of the newly added status
		data[name] = {
			...state[name],
			data: [item, ...(data[name] ? data[name].data : [])],
		};
	}
	return data;
};

export const deleteLeadsOnState = (state, payload) => {
	const obj = {};
	const {data, statuses} = payload;
	const {statusIds, ids} = data;
	if (statusIds?.[0]) {
		statuses.forEach((item) => {
			if (statusIds.includes(item.id)) {
				obj[item.name] = {
					...state[item.name],
					data: [],
				};
			} else {
				obj[item.name] = {
					...state[item.name],
				};
			}
		});
	} else if (ids?.[0]) {
		for (const [key, value] of Object.entries(state)) {
			obj[key] = {
				...value,
				data: value?.data?.filter((item) => !ids.includes(item.id)),
			};
		}
	}
	return obj;
};

export function mergeDataForAllStatuses(oldData, newData) {
	const finalObject = {};
	for (const key in oldData) {
		if (oldData.hasOwnProperty(key) && newData.hasOwnProperty(key)) {
			// Merge the 'data' arrays from oldData and newData
			finalObject[key] = {
				...newData[key],
				totalSize: oldData[key]?.totalSize,
				totalPages: oldData[key]?.totalPages,
				data: [...oldData[key].data, ...newData[key].data],
			};
		} else {
			// If a key is missing in either object, just copy it from the existing object
			finalObject[key] = oldData[key] || newData[key];
		}
	}
	return finalObject;
}
const updateItemIds = (item, addId, removeId) => {
	if (addId === null && removeId === null) return [];
	const filteredIds = item?.userDetails?.filter((user) => user.id !== removeId) || [];
	if (addId) {
		const uniqueAddIds = addId.filter((id) => !filteredIds.includes(id));
		return [...uniqueAddIds, ...filteredIds];
	}
	return filteredIds;
};
export const updateUserIdsInLeads = (state, payload) => {
	const newObj = {};
	const {statusIds, addId, removeId, leadsIds} = payload;
	for (const [key, value] of Object.entries(state)) {
		let allLeads = value?.data;
		if (statusIds?.[0]) {
			allLeads = allLeads.map((item) => {
				if (statusIds.includes(item.statusId)) {
					return {
						...item,
						userDetails: updateItemIds(item, addId, removeId, payload?.type),
					};
				}
				return item;
			});
		}
		if (leadsIds?.[0]) {
			allLeads = allLeads?.map((item) => {
				if (leadsIds?.includes(item?.id)) {
					return {
						...item,
						userDetails:
							payload?.type !== "SINGLE"
								? updateItemIds(item, addId, removeId)
								: payload?.addId,
					};
				}
				return {
					...item,
				};
			});
		}
		newObj[key] = {
			...value,
			data: allLeads,
		};
	}
	return newObj;
};

export const leadInitialValue = {
	firstName: "",
	lastName: "",
	status: "",
	description: "",
	client: null,
	selectedUsers: "",
	date: "",
	projectId: null,
	createAddress: {
		zipCode: "",
		houseNumber: "",
		street: "",
		partLocation: "",
		location: "",
	},
};
export const queryInitialValue = {
	from: null,
	to: null,
	statuses: null,
	teamId: null,
	agencyId: null,
	projectId: null,
	clientId: null,
	search: null,
	userId: null,
	salesOrganizationId: null,
	leadImportStatus: null,
	statusesIds: [],
	northLat: null,
	southLat: null,
	eastLng: null,
	westLng: null,
};
export const leadDataInitialValues = {
	page: 1,
	size: 30,
	data: [],
	totalPages: 1,
	totalSize: 1,
	loading: false,
	fetchedPages: [],
};
