import React from "react";
import eprimoLogo from "../../../../assets/img/eprimoLogo.png";

const MerkmaleHeader = ({item, nr}) => (
	<div>
		<div className="merkmaleHeader__container">
			<div className="merkmaleHeader__container--number">
				<span>{nr}</span>
			</div>
			<div className="merkmaleHeader__container--logo">
				<img src={eprimoLogo} alt="" />
			</div>
			<div className="merkmaleHeader__container--title">
				<span className="merkmaleHeader__container--title-up">{item?.tarifName}</span>
				<span className="merkmaleHeader__container--title-down">{item?.versorgerName}</span>
			</div>
		</div>
	</div>
);

export default MerkmaleHeader;
