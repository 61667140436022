import React from "react";
import Loader from "@common/Loader";

const ButtonComponent = (props) => {
	const {
		classNames = "primaryButton",
		disabled,
		onClick,
		type = "submit",
		buttonText = "Button",
		loading,
		newClass,
		hasIcon = false,
		Icon,
		iconProps,
	} = props;
	return (
		// eslint-disable-next-line react/button-has-type
		<button
			// className={`${classNames} ${newClass || ""}`}
			className={`${classNames} ${newClass || ""}`}
			disabled={disabled}
			onClick={onClick}
			// eslint-disable-next-line react/button-has-type
			type={type}
			style={{background: disabled && "var(--color-hover)"}}
		>
			{!loading && hasIcon && <Icon {...iconProps} />}
			{!loading && buttonText}

			{loading && <Loader color="white" />}
		</button>
	);
};

export default ButtonComponent;
