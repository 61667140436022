import {CloseModalIcon, PlusIcon} from "@src/assets/SvgComponents";
import React from "react";
import eprimoLogo from "@assets/img/eprimoLogo.svg";

const Header = (props) => {
	const {handleClose, handleModal, data} = props;
	return (
		<div className="orderForm__modal-header">
			<span onClick={handleClose} className="orderForm__modal-header-close">
				<CloseModalIcon fill="#6C1EB0" />
			</span>
			<div className="orderForm__modal-header-tariff">
				<img src={eprimoLogo} alt="" />
				<div className="orderForm__modal-header-tariff-name">
					<b>{data?.tarifName}</b>
					<p>{data?.versorgerName}</p>
				</div>
				<div className="orderForm__modal-header-tariff-prices">
					<div className="priceYear">
						<p>Preis im 1. Jahr</p>
						<p>{data?.gesamtpreisOhneBoniBrutto} €</p>
					</div>
					<div className="laborPrice">
						<p>Arbeitspreis</p>
						<p>{data?.arbeitspreisHTCtKWhBrutto?.toFixed(2)} ct/kWh</p>
					</div>
					<div className="basicPrice">
						<p>Grundpreis / Jahr</p>
						<p>{data?.grundpreisBrutto} €</p>
					</div>
				</div>
			</div>
			<div className="orderForm__modal-header-newTariff" onClick={handleModal}>
				<span>
					<PlusIcon />
				</span>
				<span>Tarif neu wählen</span>
			</div>
		</div>
	);
};

export default Header;
