export const findIndexFunc = (data, id, field) => {
	const findIndexData = data.findIndex((topic) => topic?.[field] === id);
	return findIndexData;
};

export const findTopicFunc = (data, id, field) => {
	const findIndexData = data.find((topic) => topic?.[field] === id);
	return findIndexData;
};

export const changeStateValue = ({state, position, value, changeValue}) => {
	const data = [...state];
	data[position] = {
		...data[position],
		[changeValue]: value,
	};
	return data;
};
