import DropDown from "@common/dropDown/DropDownSp";
import {CheckIcon, CloseUploadModalIcon} from "@src/assets/SvgComponents";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";

const ColumnHeader = ({
	column,
	setSelectedOptions,
	newOptions,
	setCsvData,
	csvData,
	filterColumns = false,
}) => {
	const {t} = useTranslation();
	const [inputValue, setInputValue] = useState("");
	const [selectedValue, setSelectedValue] = useState({});
	const findCurrent = csvData?.columns?.find((item) => item?.key === column.key);
	const setShowColumns = (column) => {
		if (!findCurrent?.matched) {
			const updatedCsvColumns = csvData.columns.map((it) => {
				if (it.key === column?.key) {
					return {
						...it,
						show: !it?.show,
					};
				}
				return it;
			});
			setCsvData({...csvData, columns: updatedCsvColumns});
		}
	};
	const removeSelected = (column) => {
		if (findCurrent?.matched) {
			const updatedCsvColumns = csvData.columns.map((it) => {
				if (it.key === column?.key) {
					setSelectedOptions((prevOptions) => {
						const updated = prevOptions.filter((i) => i !== findCurrent?.name);
						return updated;
					});
					return {
						...it,
						matched: false,
						show: false,
					};
				}
				return it;
			});
			setCsvData({...csvData, columns: updatedCsvColumns});
		}
	};
	const setMatched = (column, value) => {
		if (!findCurrent?.matched) {
			const updatedCsvColumns = csvData.columns.map((it) => {
				if (it.key === column?.key) {
					return {
						...it,
						...value,
						name: value.value,
						matched: true,
						show: true,
						isCustomField: !!value?.fieldId,
					};
				}
				return it;
			});
			setCsvData({...csvData, columns: updatedCsvColumns});
		}
	};
	return (
		<div className="row__header__renderer">
			<div className="row__header__renderer-top">
				<span>{column.name}</span>
				{filterColumns && (
					<div
						onClick={() => setShowColumns(column)}
						className="row__header__renderer-top-checkbox"
					>
						{findCurrent?.show && <CheckIcon />}
					</div>
				)}
			</div>

			{findCurrent?.matched ? (
				<div className="row__header__renderer-selected">
					<span className="row__header__renderer-selected-name">
						{t(findCurrent?.name)}
					</span>
					<span
						className="row__header__renderer-selected-clear"
						onClick={() => {
							removeSelected(column);
							setSelectedValue({});
						}}
					>
						<CloseUploadModalIcon width="10" height="10" />
					</span>
				</div>
			) : (
				<DropDown
					name="value"
					title="name"
					options={newOptions || []}
					filterSelectedOptions={false}
					onChange={(e, v) => {
						setSelectedValue(v);
						if (selectedValue?.value) {
							setSelectedOptions((prevState) => {
								const filteredValue = prevState.filter(
									(state) => state !== selectedValue?.value,
								);
								return [...filteredValue, v.value];
							});
						} else {
							setSelectedOptions((prevState) => [...prevState, v.value]);
						}
						setMatched(column, v);
					}}
					inputValue={inputValue}
					setInputValue={setInputValue}
					limitTags={-1}
					value={selectedValue}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	newOptions: state.app.orders.import.options,
	csvData: state.app.imports.create.csvData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ColumnHeader));
