import React from "react";
import {Tabs} from "antd";

const MerkmaleTabs = ({tabNames, tabContent}) => (
	<Tabs
		className="tabs__style"
		type="card"
		items={tabNames.map((name, index) => ({
			key: index,
			label: name,
			children: tabContent[index],
		}))}
	/>
);

export default MerkmaleTabs;
