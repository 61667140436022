import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/products";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";
const logger = new Logger("Sagas>Products>Index");

const PREFIX = "@app/products/create";
export const ADD_PRODUCT = `${PREFIX}ADD_PRODUCT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_PRODUCT_SUCCESS = `${PREFIX}ADD_PRODUCT_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_PRODUCTS = `${PREFIX}FILL_PRODUCTS`;

const _state = {
	loading: false,
	productAdded: false,
	productsInitialValues: {
		name: "",
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_PRODUCT_SUCCESS:
				draft.productAdded = action.payload;
				break;
			case FILL_PRODUCTS:
				draft.productsInitialValues = action.payload;
				break;
			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) {
					draft.productsInitialValues = {
						name: "",
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addProduct: (payload) => createAction(ADD_PRODUCT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addProductSucces: (payload) => createAction(ADD_PRODUCT_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillProducts: (payload) => createAction(FILL_PRODUCTS, {payload}),
};

export const sagas = {
	*addProduct({payload}) {
		yield put(actions.setLoading(true));
		const {values} = payload;
		try {
			let response;
			if (values?.id) {
				response = yield axios.put(`/product/update/`, values);
				yield put(userActions.editProductOnState(values));
				ToastSuccesComponent(i18n.t("ProductUpdateSuccess"));
			} else {
				response = yield axios.post(`/product/create/`, values);
				const userObj = {
					...values,
					id: response?.data?.data,
				};
				yield put(userActions.addProductOnState(userObj));
				yield put(userActions.totalSizeIncrease());
				ToastSuccesComponent(i18n.t("ProductCreateSuccess"));
			}
			yield put(actions.setShowModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_PRODUCT, sagas.addProduct);
};
