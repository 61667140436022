/* eslint-disable no-restricted-syntax */
import {put, select, takeLatest} from "redux-saga/effects";
import produce from "immer";
import axios from "axios";
import Logger from "@utils/logger";
import {ToastErrorComponent, ToastSuccesComponent} from "@common/ToastComponent/ToastComponent";
import {actions as leadActions} from "@sagas/salesTracker/index";
import {actions as leadStatusActions} from "@sagas/salesTracker/leadStatus";
import i18n from "../../../../i18n";
import createAction from "../../../../utils/action-creator";
import {findIndexFunc} from "../messages/utils";
import {changeStatusOrder} from "./utils";

const logger = new Logger("Sagas>salesTracker>Index");
const currentLanguage = localStorage.getItem("language");

const PREFIX = "@app/salesTracker/";
export const SET_STATUS_MODAL = `${PREFIX}CREATE_STATUS_MODAL`;
export const CREATE_STATUS = `${PREFIX}CREATE_STATUS`;
export const CREATE_STATUS_SUCCESS = `${PREFIX}CREATE_STATUS_SUCCESS`;
export const GET_ALL_STATUSES = `${PREFIX}GET_ALL_STATUSES`;
export const GET_ALL_STATUSES_SUCCESS = `${PREFIX}GET_ALL_STATUSES_SUCCESS`;
export const GET_STATUS_BY_ID = `${PREFIX}GET_STATUS_BY_ID`;
export const GET_STATUS_BY_ID_SUCCESS = `${PREFIX}GET_STATUS_BY_ID_SUCCESS`;
export const DELETE_STATUS = `${PREFIX}DELETE_STATUS`;
export const CHANGE_STATUS_ORDER = `${PREFIX}CHANGE_STATUS_ORDER`;
export const CHANGE_STATUS_ORDER_SUCCESS = `${PREFIX}CHANGE_STATUS_ORDER_SUCCESS`;
export const SET_STATUS_LOADER = `${PREFIX}SET_STATUS_LOADER`;
export const CLEAR_STATUS = `${PREFIX}CLEAR_STATUS`;
export const UPDATE_STATUS_ON_STATE = `${PREFIX}UPDATE_STATUS_ON_STATE`;

const _state = {
	statuses: [],
	status: {name: "", nameDe: "", color: ""},
	statusModal: false,
	statusLoader: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_STATUS_MODAL:
				draft.statusModal = action.payload;
				break;
			case CREATE_STATUS_SUCCESS:
				if (action.payload) {
					const {id, name, nameDe} = action.payload;
					const insertObj = {
						...action.payload,
						value: id,
						label: currentLanguage === "en" ? name : nameDe,
					};
					draft.statuses = [...state?.statuses, insertObj];
				}
				break;
			case GET_ALL_STATUSES_SUCCESS:
				const sortedStatuses = action.payload?.sort(
					(a, b) => a.orderNumber - b.orderNumber,
				);
				draft.statuses = sortedStatuses.map((st) => ({
					...st,
					value: st.id,
					label: currentLanguage === "en" ? st.name : st.nameDe,
				}));
				break;
			case GET_STATUS_BY_ID_SUCCESS:
				draft.status = action.payload;
				break;
			case SET_STATUS_LOADER:
				draft.statusLoader = action.payload;
				break;
			case CHANGE_STATUS_ORDER_SUCCESS:
				const statusIndex = findIndexFunc(state?.statuses, action.payload.id, "id");
				const updatedStatuses = changeStatusOrder(
					state.statuses,
					statusIndex,
					action.payload.value,
				);
				draft.statuses = updatedStatuses;
				break;
			case CLEAR_STATUS:
				draft.status = {name: "", nameDe: "", color: ""};
				break;
			case UPDATE_STATUS_ON_STATE:
				const statusToUpdateIndex = findIndexFunc(
					state?.statuses,
					action.payload?.id,
					"id",
				);
				const allStatuses = [...state.statuses];
				allStatuses[statusToUpdateIndex] = action.payload;
				draft.statuses = allStatuses;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setStatusModal: (payload) => createAction(SET_STATUS_MODAL, {payload}),
	createStatus: (payload) => createAction(CREATE_STATUS, {payload}),
	createStatusSuccess: (payload) => createAction(CREATE_STATUS_SUCCESS, {payload}),
	getAllStatuses: (payload) => createAction(GET_ALL_STATUSES, {payload}),
	getStatusById: (payload) => createAction(GET_STATUS_BY_ID, {payload}),
	getAllStatusesSuccess: (payload) => createAction(GET_ALL_STATUSES_SUCCESS, {payload}),
	getStatusByIdSuccess: (payload) => createAction(GET_STATUS_BY_ID_SUCCESS, {payload}),
	deleteStatus: (payload) => createAction(DELETE_STATUS, {payload}),
	changeStatusOrder: (payload) => createAction(CHANGE_STATUS_ORDER, {payload}),
	changeStatusOrderSuccess: (payload) => createAction(CHANGE_STATUS_ORDER_SUCCESS, {payload}),
	setStatusLoading: (payload) => createAction(SET_STATUS_LOADER, {payload}),
	clearStatus: (payload) => createAction(CLEAR_STATUS, {payload}),
	updateStatusOneState: (payload) => createAction(UPDATE_STATUS_ON_STATE, {payload}),
};

export const sagas = {
	*getAllStatuses() {
		try {
			const response = yield axios.get("sales_tracker/statuses");
			yield put(actions.getAllStatusesSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getStatusById({payload}) {
		yield put(actions.setStatusLoading(true));
		try {
			const response = yield axios.get(`/sales_tracker/statuses/${payload}`);
			yield put(actions.getStatusByIdSuccess(response?.data?.data));
			yield put(actions.setStatusModal(true));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setStatusLoading(false));
		}
	},
	*createStatus({payload}) {
		yield put(actions.setStatusLoading(true));
		try {
			const leads = yield select((state) => state?.app?.salesTracker?.index?.leads);
			const statuses = yield select((state) => state?.app?.salesTracker?.statuses?.statuses);
			const statusToUpdate = statuses?.find((status) => status?.id === payload?.values?.id);
			const response = yield axios.post(`/sales_tracker/statuses`, payload?.values);
			const newLeadsObject = {};
			if (payload?.values?.id) {
				ToastSuccesComponent(i18n.t("StatusEdited"));
				for (const [key, value] of Object.entries(leads)) {
					if (key === statusToUpdate?.name) {
						newLeadsObject[payload?.values?.name] = value;
					} else {
						newLeadsObject[key] = value;
					}
				}
				yield put(actions.updateStatusOneState(response?.data?.data));
				yield put(leadActions.updateLeadsOnState(newLeadsObject));
			} else {
				for (const [key, value] of Object.entries(leads)) {
					newLeadsObject[key] = value;
				}
				newLeadsObject[payload?.values?.name] = {
					data: [],
					page: 1,
					totalPages: 1,
					totalSize: 0,
					size: 30,
				};
				ToastSuccesComponent(i18n.t("StatusCreated"));
				yield put(leadActions.updateLeadsOnState(newLeadsObject));
				yield put(actions.createStatusSuccess(response?.data?.data));
			}
			yield put(actions.setStatusModal(false));
			yield put(leadActions.clearSelectedStatuses());
			payload.handleClose();
			yield put(actions.clearStatus());
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.code);
		} finally {
			yield put(actions.setStatusLoading(false));
		}
	},
	*deleteStatus({payload}) {
		yield put(actions.setStatusLoading(true));
		try {
			yield axios.delete(`/sales_tracker/statuses/${payload}`);
			yield put(actions.getAllStatuses());
			yield put(leadActions.changeLeadStatus({id: payload, statusId: 1, field: "statusId"}));
			yield put(leadStatusActions.moveLeadStatusesInPending(payload));
			ToastSuccesComponent(i18n.t("StatusDeleted"));
			yield put(leadActions.clearSelectedStatuses());
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setStatusLoading(false));
		}
	},
	*changeStatusOrder({payload}) {
		try {
			yield axios.put("sales_tracker/statuses/change", payload);
			yield put(actions.changeStatusOrderSuccess(payload));
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_STATUS, sagas.createStatus);
	yield takeLatest(GET_ALL_STATUSES, sagas.getAllStatuses);
	yield takeLatest(GET_STATUS_BY_ID, sagas.getStatusById);
	yield takeLatest(DELETE_STATUS, sagas.deleteStatus);
	yield takeLatest(CHANGE_STATUS_ORDER, sagas.changeStatusOrder);
};
