import i18n from "../../i18n";

export const colors = {
	DGN: "#3498DB",
	TNG: "#2A911B",
	GFD: "#C40000",
	TER: "#d871f5",
	TFR: "#d871f5",
	DGF: "#f2de05",
	HNT: "#42c8f5",
	VFK: "#8c3b42",
	SBK: "#9c27b0",
	DTG: "#44228B",
	DGG: "#B42518",
	SOT: "#F89A2C",
};
export const genericLabels = {
	datesYearly: [
		i18n.t("January"),
		i18n.t("February"),
		i18n.t("March"),
		i18n.t("April"),
		i18n.t("May"),
		i18n.t("June"),
		i18n.t("July"),
		i18n.t("August"),
		i18n.t("September"),
		i18n.t("October"),
		i18n.t("November"),
		i18n.t("December"),
	],
	datesSixMonth: ["01", "02", "03"],
	datesMonthly: [
		"01",
		"02",
		"03",
		"04",
		"05",
		"06",
		"07",
		"08",
		"09",
		"10",
		"11",
		"12",
		"13",
		"14",
		"15",
		"16",
		"17",
		"18",
		"19",
		"20",
		"21",
		"22",
		"23",
		"24",
		"25",
		"26",
		"27",
		"28",
		"29",
		"30",
		"31",
	],
	datesDaily: [
		"00:00 - 02:00",
		"02:00 - 04:00",
		"04:00 - 06:00",
		"06:00 - 08:00",
		"08:00 - 10:00",
		"10:00 - 12:00",
		"12:00 - 14:00",
		"14:00 - 16:00",
		"16:00 - 18:00",
		"18:00 - 20:00",
		"20:00 - 22:00",
		"22:00 - 24:00",
	],
	datesWeekly: [
		i18n.t("Monday"),
		i18n.t("Tuesday"),
		i18n.t("Wednesday"),
		i18n.t("Thursday"),
		i18n.t("Friday"),
		i18n.t("Saturday"),
		i18n.t("Sunday"),
	],
};
export const options = {
	layout: {
		padding: {
			top: 20,
		},
	},
	scales: {
		yAxes: {
			stacked: false,
			ticks: {
				beginAtZero: true,
				callback(value) {
					if (value % 1 === 0) {
						return value;
					}
				},
			},
		},
		xAxes: {
			stacked: false,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			backgroundColor: "#f5f5f5",
			titleColor: "#3C515E",
			titleFont: {
				size: 12,
				weight: 700,
			},
			bodyColor: "#3C515E",
			displayColors: true,
			borderColor: "#3C515E",
			borderWidth: 0.5,
			bodyFont: {
				size: 11,
				weight: 600,
			},
		},
		datalabels: {
			display: false,
			color: "#6C1EB0",
			font: {
				weight: "500",
				size: 12,
			},
			formatter: (value, ctx) => {
				const {datasets} = ctx.chart.data;
				if (ctx.datasetIndex === datasets.length - 1) {
					let sum = 0;
					// eslint-disable-next-line array-callback-return
					datasets.map((dataset) => {
						sum += dataset.data[ctx.dataIndex];
					});
					return sum !== 0 ? sum.toLocaleString(/* ... */) : "";
				}
				return "";
			},
			anchor: "end",
			align: "end",
		},
	},
};
const projectCategoriesColors = [
	"#86C5A7",
	"#EDB26D",
	"#D57276",
	"#808080",
	"#EDDC9A",
	"#824887",
	"#A3D0E5",
	"#BCC586",
	"#C0C0C0",
	"#A37CC5",
	"#7B93D5",
];

export const getRandomColor = (items) => {
	const obj = {};
	items.forEach((item) => {
		const randomIndex = Math.floor(Math.random() * projectCategoriesColors.length);
		obj[item.name] = projectCategoriesColors[randomIndex];
	});
	return obj;
};

export const clientStyles = (selected, clientColor) => ({
	backgroundColor: selected ? clientColor : "#fff",
	borderColor: selected ? "none" : clientColor,
	color: selected ? "#fff" : clientColor,
});

export const tabItems = [
	{
		type: "day",
		word: "day",
	},
	{
		type: "week",
		word: "Week",
	},
	{
		type: "month",
		word: "month",
	},
	{
		type: "quarter",
		word: "month",
	},
	{
		type: "year",
		word: "year",
	},
	{
		type: "custom",
		word: "custom",
	},
];
