import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as tariffActions} from "@sagas/tariff";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Sagas>Tariffa>Create");

const PREFIX = "@app/tariffa/create";
export const ADD_TARIFFA = `${PREFIX}ADD_TARIFFA`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_TARIFFA = `${PREFIX}FILL_TARIFFA`;

const _state = {
	loading: false,
	tariffaValues: {
		name: "",
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case FILL_TARIFFA:
				draft.tariffaValues = action.payload;
				break;
			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) {
					draft.tariffaValues = {
						name: "",
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addTariffa: (payload) => createAction(ADD_TARIFFA, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillTariffs: (payload) => createAction(FILL_TARIFFA, {payload}),
};

export const sagas = {
	*addTariffa({payload}) {
		yield put(actions.setLoading(true));
		const {values} = payload;

		try {
			let response;
			if (values?.id) {
				response = yield axios.put(`/tariff/update/`, values);
				yield put(tariffActions.editTarrifOnState(values));
				ToastSuccesComponent(i18n.t("TariffUpdateSuccess"));
			} else {
				response = yield axios.post(`/tariff/create/`, values);
				const obj = {
					...values,
					id: response?.data?.data,
				};
				yield put(tariffActions.addTarrifOnState(obj));
				ToastSuccesComponent(i18n.t("TariffCreateSuccess"));
			}
			yield put(actions.setShowModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_TARIFFA, sagas.addTariffa);
};
