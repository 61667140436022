import { Autocomplete, TextField } from '@mui/material'
import { CloseModalIcon, ToggleAccordionIcon2 } from '@src/assets/SvgComponents'
import ButtonComponent from '@src/common/button'
import InputComponent from '@src/common/input'
import ModalComponent from '@src/common/modal'
import { actions as energyActions } from '@sagas/energy'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import DatePickerItem from '@common/DatePicker'
import moment from 'moment'
import CheckBoxComponent from '@src/common/checkbox'
import { connect } from 'react-redux'
import SelectMembers from './components/SelectMembers'
import Tabs from './components/Tabs'
import {
  customerTypes,
  energyTypes,
  filterInputs,
  filterOptions,
  generateInitialValues,
  generateYupSchema,
  tabs,
} from './staticData'
import useGenericLocations from '@src/utils/useGenericLocations'
import SelectHouse from './components/SelectHouse'

const FiltersModal = (props) => {
  const {
    open,
    handleClose,
    setFilter,
    locations,
    loading,
    getEnergyTariffs,
    getLocations,
  } = props
  const [activeTab, setActiveTab] = useState('Calculation')
  const { options, retrieveDataFromGetAg } = useGenericLocations()
  const closeModal = () => {
    setActiveTab('Calculation')
    handleClose()
  }
  const initialValues = generateInitialValues(filterInputs)
  const validationSchema = generateYupSchema(filterInputs)
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      closeModal()
      setFilter(values)
      const payloadObj = {
        ...values,
        calculator: energyTypes?.find(
          (type) => type.name === values?.calculatorType,
        )?.type,
        customer: customerTypes?.find(
          (type) => type.name === values?.customerType,
        )?.type,
        zipCode: values?.postalCode,
        primeTimeConsumption: values?.kwYear,
      }
      getEnergyTariffs({ values: payloadObj })
    },
  })
  const handleTabClick = (item) => {
    setActiveTab(item)
  }
  const inputs = useMemo(() => {
    if (activeTab) {
      return filterInputs?.filter((input) => input.parentName === activeTab)
    }
    return []
  }, [activeTab])
  const { values, touched, errors, handleChange, setFieldValue, handleSubmit } =
    formik
  const handleSelectFamilyMember = (inputName, item) => {
    setFieldValue(inputName, item.nr)
    setFieldValue('kwYear', `${item?.countValue}`)
  }
  const handleHouseChange = (field, item) => {
    setFieldValue(field, item?.nr)
    setFieldValue('kwYearGas', `${item?.countValue}`)
  }

  useEffect(() => {
    if (values?.postalCode?.length > 4) {
      const postalCodeInput = filterInputs?.find(
        (itm) => itm.field === 'postalCode',
      )
      postalCodeInput.dependencyFields.forEach((field) =>
        setFieldValue(field, null),
      )
      retrieveDataFromGetAg(
        { ...postalCodeInput, type: 'postCode' },
        values?.postalCode,
      )
    }
  }, [values?.postalCode])

  const setValue = (field, value) => {
    if (field?.generic) {
      retrieveDataFromGetAg(
        { ...field, type: 'location' },
        value?.name,
        values?.postalCode,
      )
    }
    if (field?.field === 'calculatorType') {
      const allDependentFields = filterInputs?.filter(
        (input) => input.dependentFrom === 'calculatorType',
      )
      allDependentFields.forEach((field) =>
        setFieldValue(field.field, field.defaultValue || null),
      )
    }
    setFieldValue(field?.field, value?.name)
  }
  const isDisabled = (dependentFrom) =>
    dependentFrom && !values?.[dependentFrom]

  return (
    <ModalComponent
      open={open}
      handleClose={closeModal}
      customClassNames="filters__modal"
      positionModal="right"
    >
      <div className="filters__modal-header">
        <Tabs
          items={tabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        />
        <span className="filters__modal-header-close" onClick={closeModal}>
          <CloseModalIcon fill="#6C1EB0" />
        </span>
      </div>
      <form className="filters__modal-form" onSubmit={handleSubmit}>
        <div className="filters__modal-form-inputs">
          {inputs?.map((item, index) => {
            const {
              type,
              dependentFrom,
              inputType,
              field,
              value,
              generic,
              placeholder,
              half,
            } = item
            if (type && dependentFrom) {
              if (values?.[dependentFrom] !== type) return null
            }
            return (
              <div
                key={`${field}--${index}`}
                className="filters__modal-form-inputs-singleInput"
                style={{
                  width: half && '49%',
                  minWidth: !half && '100%',
                }}
              >
                {inputType === 'title' && (
                  <p className="custom__title">{value}</p>
                )}
                {inputType === 'dropdown' && (
                  <Autocomplete
                    popupIcon={<ToggleAccordionIcon2 />}
                    options={
                      filterOptions(
                        item?.options,
                        item?.dependentFrom,
                        values,
                      ) ||
                      options?.[field] ||
                      []
                    }
                    getOptionLabel={(option) => option?.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    onChange={(_, value) => setValue(item, value)}
                    value={item?.options?.find(
                      (opt) => opt.name === values?.[field],
                    )}
                    disabled={isDisabled(dependentFrom)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="dropdown-option"
                        variant="outlined"
                        placeholder={placeholder}
                      />
                    )}
                  />
                )}
                {(inputType === 'input' || inputType === 'number') && (
                  <InputComponent
                    name={item.field}
                    handleChange={handleChange}
                    values={values[item.field]}
                    errors={errors[item.field]}
                    touched={touched[item.field]}
                    errorClass="errorClass"
                    onlyPlaceholder={placeholder}
                    disabled={
                      item?.dependentFrom && !values?.[item?.dependentFrom]
                    }
                  />
                )}
                {inputType === 'date' && (
                  <div className="custom__datepicker">
                    <DatePickerItem
                      value={values?.[field]}
                      label={placeholder}
                      onChange={(newValue) => {
                        if (newValue) {
                          setFieldValue(
                            field,
                            moment(newValue)
                              .startOf('day')
                              .add(2, 'hours')
                              .valueOf(),
                          )
                        }
                      }}
                      errors={errors?.[field]}
                      touched={touched?.[field]}
                    />
                  </div>
                )}
                {inputType === 'select' && (
                  <SelectMembers
                    item={item}
                    onClick={handleSelectFamilyMember}
                    value={values[item?.field]}
                  />
                )}
                {inputType === 'selectHouse' && (
                  <SelectHouse
                    value={values?.[field]}
                    item={item}
                    onClick={handleHouseChange}
                  />
                )}
                {inputType === 'checkbox' && (
                  <div className="custom__checkbox">
                    <CheckBoxComponent
                      name={field}
                      label={placeholder}
                      touched={touched?.[field]}
                      errors={errors?.[field]}
                      placeholder={placeholder}
                      handleChange={handleChange}
                      checked={values?.[field]}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <ButtonComponent buttonText="jetzt Tarife berechnen" />
      </form>
    </ModalComponent>
  )
}

const mapStateToProps = (state) => ({
  locations: state.app.energy.index.locations,
  loading: state.app.energy.index.loading,
})

const mapDispatchToProps = {
  getEnergyTariffs: energyActions.getEnergyTariffs,
  getLocations: energyActions.getLocationsFromPostcode,
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersModal)
