/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import InputFile from "@common/fileInput";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {actions as navigationActions} from "@sagas/navigation";
import {actions as clientActions} from "@sagas/client";
import {withRouter} from "react-router";
import Loader from "@common/Loader";
import {DeleteRowIcon, EditRowIcon, ReplaceIcon} from "@src/assets/SvgComponents";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import {checkRoles} from "@src/utils/checkRoles";
import inputFileLogo from "../../assets/img/Upload icon.svg";
import ReplacePdf from "./ReplacePdf";

const convertBase64 = (file) =>
	new Promise((resolve, reject) => {
		if (file) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		}
	});

const handleFileRead = async (event) => {
	const file = event;
	const base64 = await convertBase64(file);
	return base64;
};
const columns = [
	{
		name: "Name",
		key: "fileName",
	},
];
function UploadPdf({
	navigate,
	createGenericPdf,
	genericPdfs,
	fetchGenericPdf,
	deleteGenericPdf,
	fetchGenericPdfByID,
	match,
	isUploading,
	replaceGenericPdf,
	loggedUser,
}) {
	const {t} = useTranslation();
	const isAdmin = loggedUser?.role?.name === "ADMIN";
	const [uploadedFileName, setUploadedFileName] = useState("");
	const [selectedPdf, setSelectedPdf] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const handleChange = async (e) => {
		const convertedFile = await handleFileRead(e.target.files?.[0]);
		const fileName = e.target.files?.[0]?.name.split(".");
		const obj = {
			clientId: Number(match.params.clientId),
			base64: convertedFile,
			fileName: fileName[0],
		};
		setUploadedFileName(obj?.fileName);
		createGenericPdf(obj);
	};

	const handleEdit = (rowData) => {
		fetchGenericPdfByID({
			client: match?.params?.clientId,
			id: rowData?.id,
		});
		navigate(
			`/admin/clients/${match?.params?.clientId}/${match?.params?.id}/createPdf/${rowData?.id}`,
		);
	};
	const handleReplace = (row) => {
		setShowModal(true);
		setSelectedPdf(row);
	};
	const actionsFormatter = (row) => (
		<div
			className="table-actions-wrapper"
			style={{width: isAdmin ? "150px" : "100px", paddingRight: "20px"}}
		>
			{checkRoles("replaceOnUploadPdf") && (
				<HtmlTooltip title={<span>{t("replacePdf")}</span>} placement="bottom-end">
					<span onClick={() => handleReplace(row)}>
						<ReplaceIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("editOnUploadPdf") && (
				<HtmlTooltip title={<span>{t("editPdf")}</span>} placement="bottom-end">
					<span onClick={() => handleEdit(row)}>
						<EditRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deleteOnUploadPdf") && (
				<HtmlTooltip title={<span>{t("Delete")}</span>} placement="bottom-end">
					<span onClick={() => deleteGenericPdf(row)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	useEffect(() => {
		const id = Number(match?.params?.clientId);
		fetchGenericPdf(id);
	}, [match?.params?.clientId, genericPdfs.length]);
	const baseActionsProps = {
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: isAdmin ? 150 : 100,
		headerCellClass: "headerStyles",
	};
	const actions = {
		...baseActionsProps,
		formatter: ({row}) => actionsFormatter(row),
	};
	const handleClose = () => {
		setShowModal(false);
		setSelectedPdf(null);
	};

	return (
		<>
			<ReplacePdf
				open={showModal}
				selectedPdf={selectedPdf}
				handleClose={handleClose}
				clientId={Number(match?.params?.clientId)}
				loading={isUploading}
				replaceGenericPdf={replaceGenericPdf}
			/>
			<div className="generic-pdf-wrapper__uploader-content">
				<div className="generic-pdf-wrapper__uploader-content-input">
					<img src={inputFileLogo} alt="." />
					{!isUploading ? (
						<>
							<span>{uploadedFileName !== "" && `${uploadedFileName}.pdf`}</span>
							<InputFile
								className="create__user__input__file"
								labelClassName="form_input__label"
								borderedInput
								onChange={(e) => handleChange(e)}
								accept=".pdf"
								name="singleGenericPdf"
								generic={true}
							/>
						</>
					) : (
						<Loader />
					)}
				</div>
				<DataGridTable data={genericPdfs} columns={[...columns, actions]} />
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	genericPdfs: state.app.client.index.genericPdfs,
	singleGenericPdf: state.app.client.index.singleGenericPdf,
	isUploading: state.app.client.index.isUploading,
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	navigate: navigationActions.navigate,
	createGenericPdf: clientActions.createGenericPdf,
	replaceGenericPdf: clientActions.replaceGenericPdf,
	fetchGenericPdf: clientActions.fetchGenericPdf,
	fetchGenericPdfByID: clientActions.fetchGenericPdfByID,
	deleteGenericPdf: clientActions.deleteGenericPdf,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadPdf));
