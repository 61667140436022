import React from "react";
import {DATE_FORMAT} from "@src/common/constants";
import i18n from "@src/i18n";
import moment from "moment";
import {checkRoles} from "@src/utils/checkRoles";
import {DeleteRowIcon, EditRowIcon, HeadSalesIcon} from "@src/assets/SvgComponents";
import HtmlTooltip from "@src/common/htmlTooltip";
export const salesorgColumns = [
	{
		key: "name",
		name: i18n.t("Name"),
		resizable: true,
		sortable: true,
	},
	{
		key: "startDate",
		name: i18n.t("from"),
		sortable: true,
		formatter: ({row}) => (
			<span>{row?.startDate ? moment(row?.startDate).format(DATE_FORMAT) : ""}</span>
		),
		printFormatter: ({row}) => (
			<span>{row?.startDate ? moment(row?.startDate).format(DATE_FORMAT) : ""}</span>
		),
	},
	{
		key: "endDate",
		name: i18n.t("to"),
		sortable: true,
		formatter: ({row}) => (
			<span>{row?.endDate ? moment(row?.endDate).format(DATE_FORMAT) : ""}</span>
		),
		printFormatter: ({row}) => (
			<span>{row?.endDate ? moment(row?.endDate).format(DATE_FORMAT) : ""}</span>
		),
	},
];
export const salesorgMembersColumns = [
	{
		key: "firstName",
		name: i18n.t("FirstName"),
		resizable: true,
		sortable: true,
	},
	{
		key: "lastName",
		name: i18n.t("LastName"),
		resizable: true,
		sortable: true,
	},
	{
		key: "role",
		name: i18n.t("Role"),
		resizable: true,
		sortable: true,
		cellClass: "role-cell",
		printFormatter: ({row}) =>
			row?.isHeadOfSales ? (
				<div className="role">
					<span className="role-head">{i18n.t("HeadOfSales")}</span>
				</div>
			) : (
				<span className="roleName">{row?.role}</span>
			),
		formatter: ({row}) =>
			row?.isHeadOfSales ? (
				<div className="role">
					<HeadSalesIcon stroke="#FFF" />
					<span className="role-head">{i18n.t("HeadOfSales")}</span>
				</div>
			) : (
				<span className="roleName">{row?.role}</span>
			),
	},
];

export const salesOrgActionsFormatter = (row, editFunction, setModal) => (
	<div className="table-actions-wrapper" style={{width: "auto"}}>
		{checkRoles("editSalesOrg") && (
			<HtmlTooltip
				title={<span>{i18n.t("EditSalesOrganization")}</span>}
				placement="bottom-end"
			>
				<span
					onClick={() => {
						editFunction(row?.id);
						setModal("salesOrg", row?.id);
					}}
				>
					<EditRowIcon stroke="#C1C1C1" />
				</span>
			</HtmlTooltip>
		)}
		{checkRoles("deleteSalesOrg") && (
			<HtmlTooltip
				title={<span>{i18n.t("DeleteSalesOrganization")}</span>}
				placement="bottom-end"
			>
				<span
					onClick={() => {
						setModal("deleteSalesOrganization", row?.id);
					}}
				>
					<DeleteRowIcon />
				</span>
			</HtmlTooltip>
		)}
	</div>
);
