import CloseIcon from "@common/closeIcon/CloseIcon";
import Button from "@common/components/button";
import InputComponent from "@common/input";
import TextAreaComponent from "@common/TextArea/TextAreaComponent";
import {Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {actions as orderActions} from "@sagas/orders";
import {connect} from "react-redux";
import * as yup from "yup";
function AddPrice(props) {
	const {t} = useTranslation();
	const {
		setShowPriceModal,
		currentRow,
		setManualCorrection,
		currentSettlement,
		manualCorrection,
		initialValues = null,
		updateManualCorrection,
		counter = null,
		setCounter,
		fileId = null,
	} = props;
	const validationSchema = yup.object().shape({
		price: yup.string().label(t("Price")).required(t("PriceReq")),
		description: yup.string().label(t("Description")).optional(t("DescriptionIsReq")),
	});
	return (
		<div>
			<Formik
				validationSchema={validationSchema}
				enableReinitialize={true}
				initialValues={initialValues !== null ? initialValues : manualCorrection}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true);
					if (!values?.manualCorrectionId) {
						setManualCorrection({
							price: values?.price,
							description: values?.description,
							orderId: currentRow && currentRow,
							settledUuid: currentSettlement && currentSettlement,
							fileId,
						});
					} else {
						updateManualCorrection({
							values: {
								price: values?.price,
								description: values?.description,
								fileId: values?.fileId,
							},
							uuid: currentSettlement,
							manualCorrectionId: values?.manualCorrectionId,
						});
					}
					if (counter !== null) {
						setCounter((prevState) => prevState + 1);
					}
					actions.resetForm();
					setShowPriceModal(false);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
				}) => (
					<div className="addPrice__container">
						<div className="addPrice__container_closeIcon">
							<span>
								{values?.manualCorrectionId ? t("EditPrice") : t("AddPrice")}
							</span>
							<CloseIcon
								className="close__modal__icon"
								click={() => setShowPriceModal(false)}
							/>
						</div>
						<div className="addPrice__container_inputs">
							<InputComponent
								handleChange={handleChange}
								label={t("Price")}
								placeholder={t("Price")}
								values={values?.price}
								name="price"
								type="number"
								setFieldValue={setFieldValue}
								handleBlur={handleBlur}
								errors={errors?.price}
								touched={touched?.price}
								errorClass="errorClass"
							/>
							<TextAreaComponent
								maxLength={70}
								displayRandomText={false}
								value={values?.description}
								setFieldValue={setFieldValue}
								width="100%"
								errors={errors?.description}
								touched={touched?.description}
								errorClass="errorClass"
								classContainer="addPrice__container_inputs-textArea"
							/>
						</div>
						<div className="addPrice__container_buttons">
							<Button
								text={t("Cancel")}
								handleChange={() => setShowPriceModal(false)}
								customClassName="addPrice__container_buttons-button"
							/>
							<Button
								text={values?.manualCorrectionId ? t("Update") : t("Create")}
								handleChange={handleSubmit}
								customClassName="addPrice__container_buttons-button"
							/>
						</div>
					</div>
				)}
			</Formik>
		</div>
	);
}

const mapStateToProps = (state) => ({
	manualCorrection: state.app.orders.index.manualCorrection,
});

const mapDispatchToProps = {
	setManualCorrection: orderActions.setManualCorrection,
	updateManualCorrection: orderActions.editManualCorrection,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPrice));
