/* eslint-disable brace-style */
/* eslint-disable max-len */
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {withRouter} from "react-router";
import {actions as teamActions} from "@sagas/teams/index";
import {connect} from "react-redux";
import {actions as conditionActions} from "@src/store/sagas/app/teamConditionsV1/create";
import Form from "@src/common/Form";
import {useUrlParams} from "@src/utils/useUrlParams";
import {conditionElements} from "../conditionElements";
import {filterTeams, isDateRangeValid} from "../utils";

const CreateTable = (props) => {
	const {
		handleClose,
		fetchTeams,
		teams,
		initialValues,
		createCondition,
		loading,
		tables,
		open,
		conditionType,
	} = props;
	const {t} = useTranslation();
	const {btnEditText, btnText, lists} = conditionElements;
	const {getUrlParams} = useUrlParams();
	const {id, modal} = getUrlParams("id", "modal");
	const [filteredTeams, setFilteredTeams] = useState([]);
	const [dates, setDates] = useState({
		startDate: null,
		endDate: null,
	});
	useEffect(() => {
		fetchTeams({all: true, isTeam: false});
	}, []);
	useEffect(() => {
		setDates({
			startDate: initialValues?.startDate,
			endDate: initialValues?.endDate,
		});
	}, [initialValues?.startDate, initialValues?.endDate]);
	useEffect(() => {
		// filtering out teams whose dates aren't inside chosen date range
		const filteredTeams = teams.filter((team) =>
			isDateRangeValid({team, startDate: dates?.startDate, endDate: dates?.endDate}),
		);
		let freeTeams = filteredTeams;
		// filtering out previously assigned teams to tables
		for (let tableIdx = 0; tableIdx < tables?.length; tableIdx++) {
			const currentTable =
				tables[tableIdx]?.id === initialValues.id ? initialValues : tables[tableIdx];
			const {startDate, endDate, teamIds} = currentTable;
			// teams that are assigned to currentTable;
			const currentTableTeamIds = teamIds !== null ? teamIds.map((t) => t?.id) : [];
			// eslint-disable-next-line no-loop-func
			freeTeams.forEach((team) => {
				// Skip the current table being edited
				if (currentTable.id === initialValues.id) {
					if (startDate === dates?.startDate && endDate === dates?.endDate) return;
				}
				// we need to check dates only if a team is included on table's teams
				if (currentTableTeamIds?.includes(team?.id)) {
					// first check if there's no endDate and if start date of current table isn't before table's startDate --> then filter that team out
					if (
						moment(startDate).isSame(dates?.startDate) ||
						moment(startDate).isAfter(dates?.startDate)
					) {
						freeTeams = filterTeams(freeTeams, team?.id);
					}
					//
					// if (moment(values?.startDate).isBefore(startDate)) {
					// 	console.log("second iff");
					// 	freeTeams = filterTeams(freeTeams, team?.id);
					// }
					// first check if there's no endDate and if start date of current table isn't before table's startDate --> then filter that team out
					if (endDate === null && moment(dates?.startDate).isAfter(startDate)) {
						freeTeams = filterTeams(freeTeams, team?.id);
					}
					// then if comparing table doesn't have endDate and current table doesn't have end date
					// we filter out teams of that table (because if a team is on a table which doesn't have endDate ,
					// we cannot assing that team on another table who doesn't have endDate)
					else if (endDate === null && dates?.endDate === null) {
						freeTeams = filterTeams(freeTeams, team?.id);
					}
					// we check if table has endDate , and if current table has end date
					// only then , we should check if table's date have changed
					// then we need to check if current start date or current end date is between table's range
					// and if true then we should filter out those teams
					else if (
						endDate &&
						dates?.endDate &&
						(initialValues?.startDate !== dates?.startDate ||
							initialValues?.endDate !== dates?.endDate) &&
						(moment(dates?.startDate).isBetween(startDate, endDate) ||
							moment(dates?.endDate).isBetween(startDate, endDate))
					) {
						freeTeams = filterTeams(freeTeams, team?.id);
					}
				}
			});
		}
		setFilteredTeams(
			freeTeams?.map((t) => ({
				value: t.id,
				label: t.name,
			})),
		);
	}, [dates?.startDate, dates?.endDate, teams]);
	const handleDates = (field, value) => {
		setDates((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};
	const inputs = useMemo(() => {
		const filteredList = lists?.filter((list) => !list?.type || list.type === conditionType);
		return filteredList?.map((item) => {
			if (item.field === "teamIds") {
				return {
					...item,
					options: filteredTeams,
				};
			}
			if (item.inputType === "rangePicker") {
				return {
					...item,
					onChange: handleDates,
				};
			}
			return item;
		});
	}, [conditionType, filteredTeams]);
	const formData = {
		inputs,
		id,
		initialValues: modal === "duplicate" ? initialValues : null,
		title: id && modal !== "duplicate" ? btnEditText : btnText,
		submitFn: (values) => createCondition({values, type: conditionType, handleClose}),
		getUrl: `/condition/get/:id/${conditionType}`,
	};
	return <Form open={open} formData={formData} handleClose={handleClose} loading={loading} />;
};

const mapStateToProps = (state) => ({
	teams: state.app.teams.index.teams,
	loading: state.app.conditionsV1.create.loading,
});

const mapDispatchToProps = {
	fetchTeams: teamActions.fetchTeams,
	createCondition: conditionActions.createCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateTable));
