import CustomCollapse from "@src/common/CustomCollapse";
import React, {useEffect, useState} from "react";
import {useUrlParams} from "@src/utils/useUrlParams";
import {actions as leadAddressesActions} from "@sagas/salesTracker/addresses";
import {actions as createSalesTrackerActions} from "@sagas/salesTracker/create";
import {connect, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {actions as deleteLeadsActions} from "@sagas/salesTracker/delete";
import {getCurrentUser} from "@src/utils/currentUser";
import CheckBoxComponent from "@src/common/checkbox";
import _ from "lodash";
import {headerColumns, leadTableColumns} from "./helper";
import TableActions from "./TableActions";

const AddressItem = ({
	index,
	setExpandedItem,
	isExpanded,
	expandedItem,
	item,
	editSize,
	editPage,
	getLeadsByAddress,
	duplicateLead,
	deleteLeads,
	selectedRows,
	setSelectedRows,
	setSelectedAddressData,
	selectedAddressData,
}) => {
	const {getUrlParams, setModal} = useUrlParams();
	const {t} = useTranslation();
	const currentUser = getCurrentUser();
	const [duplicateModal, setDuplicateModal] = useState({
		open: false,
		item: null,
	});
	const [deleteModal, setDeleteModal] = useState({
		open: false,
		item: null,
	});
	const {leadsData} = useSelector((state) => state.app.salesTracker.addresses);
	const state = leadsData?.[item.id] || {};
	const {data: leads, fetchedPages, page, size} = state;
	const {loading} = useSelector((state) => state.app.salesTracker.delete);
	const {query: leadsFilter} = getUrlParams("tab", "query");
	useEffect(() => {
		if (isExpanded && !fetchedPages?.includes(page)) {
			getLeadsByAddress({leadsFilter, addressRequest: item});
		}
	}, [
		isExpanded,
		page,
		size,
		leadsFilter?.from,
		leadsFilter?.to,
		leadsFilter?.statuses?.length,
		leadsFilter?.teamId,
		leadsFilter?.agencyId,
		leadsFilter?.projectId,
		leadsFilter?.clientId,
		leadsFilter?.search,
		leadsFilter?.userId,
		leadsFilter?.salesOrganizationId,
		leadsFilter?.leadImportStatus,
	]);
	const handleAction = (key, item) => {
		setExpandedItem(null);
		if (key === "duplicate") {
			setDuplicateModal({open: true, item});
		} else if (key === "edit") {
			setModal("lead", item.id);
		} else if (key === "delete") {
			setDeleteModal({open: true, item});
		}
	};
	const editPageHandler = (newPage) => {
		editPage({newPage, addressid: item.id});
	};
	const isChecked = (id) => _.some(selectedRows, {id});
	const areAllChecked = () => {
		const selectedRowsIds = selectedRows?.map((item) => item.id) || [];
		const dataIds = leads?.map((item) => item.id) || [];
		return dataIds.every((id) => selectedRowsIds.includes(id));
	};

	const selectColumn = {
		frozen: true,
		cellClass: "status-cell",
		width: 30,
		formatter: ({row}) => (
			<CheckBoxComponent
				checked={isChecked(row?.id)}
				handleChange={(e) => handleSelect(e, row)}
			/>
		),
		headerRenderer: () => (
			<CheckBoxComponent
				handleChange={(e) => {
					handleSelectAll(e);
				}}
				checked={areAllChecked()}
			/>
		),
	};
	const actions = {
		key: "",
		cellClass: "borderLess__actions__cell",
		headerCellClass: "borderLess__actions__cell",
		name: t("Actions"),
		width: 50,
		formatter: ({row}) => <TableActions row={row} handleAction={handleAction} />,
	};
	const closeDuplicateModal = () => setDuplicateModal({open: false, item: null});
	const handleConfirm = () => {
		duplicateLead({
			lead: duplicateModal.item,
			handleCloseModal: closeDuplicateModal,
			streetView: true,
			leadsFilter,
			addressId: item.id,
		});
	};
	const closeDeleteModal = () => setDeleteModal({open: false, item: null});
	const handleDeleteLeads = () => {
		deleteLeads({
			ids: [deleteModal?.item],
			currentUser: currentUser.id,
			query: leadsFilter,
			streetView: true,
			addressId: item.id,
		});
		closeDeleteModal();
		setSelectedRows([]);
	};
	const handleSelect = (e, item) => {
		e.stopPropagation();
		const {checked} = e.target;
		if (checked) {
			setSelectedRows((prev) => {
				const mergedState = [...prev, item];
				handleUpdateSelectedState(mergedState);
				return mergedState;
			});
		} else {
			setSelectedRows((prev) => {
				const filteredArr = prev.filter((lead) => lead.id !== item.id);
				handleUpdateSelectedState(filteredArr);
				return filteredArr;
			});
		}
	};

	const handleUpdateSelectedState = (newArray) => {
		const newArrayIds = newArray?.map((it) => it.id);
		const leadIds = leads?.map((it) => it.id);
		const filteredArr = newArrayIds.filter((id) => leadIds.includes(id));
		setSelectedAddressData((prev) => {
			if (filteredArr.length === 0) {
				delete prev[item.id];
				return prev;
			}
			return {
				...prev,
				[item.id]: {
					selectedCount: filteredArr?.length,
					totalCount: item.countLead,
				},
			};
		});
	};
	const handleSelectAll = (e) => {
		e.stopPropagation();
		const {checked} = e.target;
		if (checked) {
			setSelectedRows((prev) => {
				const mergedState = [...prev, ...leads];
				const uniqueState = _.uniqBy(mergedState, "id");
				handleUpdateSelectedState(uniqueState);
				return uniqueState;
			});
		} else {
			setSelectedRows((prev) => {
				const leadIds = new Set(leads.map((item) => item.id));
				const filteredArr = prev.filter((item) => !leadIds.has(item.id));
				handleUpdateSelectedState(filteredArr);
				return filteredArr;
			});
		}
	};
	return (
		<>
			<DeleteModal
				calculationModal
				newTitle={t("AreuSureDuplicate")}
				setShowDeleteModal={closeDuplicateModal}
				open={duplicateModal?.open}
				confirm={handleConfirm}
				handleClose={closeDuplicateModal}
			/>
			<DeleteModal
				setShowDeleteModal={closeDeleteModal}
				open={deleteModal?.open}
				disabled={loading}
				handleClose={closeDeleteModal}
				confirm={handleDeleteLeads}
				modalType="permanantDelete"
			/>
			<CustomCollapse
				index={index}
				item={{...item, color: "var(--color-primary)"}}
				activeKey={expandedItem}
				setActiveKey={setExpandedItem}
				state={state}
				editSize={(newSize) => editSize({newSize, addressID: item.id})}
				editPage={editPageHandler}
				resetPaginationType={() => {}}
				actions={actions}
				columns={headerColumns}
				tableColumns={[selectColumn, ...leadTableColumns]}
				title="countLead"
				name="title"
				borderLess
				mainTitle={
					!isExpanded
						? item.street
						: `${item.street}, ${item.zipCode}, ${item.location}, ${item.partLocation}`
				}
				subTitle={
					selectedAddressData?.[item.id]
						? `${t("Selected")} ${selectedAddressData?.[item.id]?.selectedCount} ${t(
								"leadsFrom",
						  )} ${selectedAddressData?.[item.id]?.totalCount}`
						: ""
				}
			/>
		</>
	);
};
const mapDispatchToProps = {
	getLeadsByAddress: leadAddressesActions.getLeadsByAddress,
	editPage: leadAddressesActions.editLeadsPage,
	editSize: leadAddressesActions.editLeadsSize,
	duplicateLead: createSalesTrackerActions.duplicateLead,
	deleteLeads: deleteLeadsActions.deleteLeads,
};
export default connect(null, mapDispatchToProps)(AddressItem);
