/* eslint-disable max-len */
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders/index";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {checkRoles} from "@src/utils/checkRoles";
import TabComp from "@common/createUserTabs/createUserTabsComp/index";
import {Skeleton} from "antd";
import {optionsPercentageGraph} from "./staticData";
import OrderStats from "./OrderStats";

let tempArrLabels = [],
	tempArrDataset = [];

function UserStats({
	userStats,
	dates,
	handleActive,
	active,
	datesGraph,
	setRangeType,
	setActive,
	getClientStatistics,
	clientStatistics,
	clientStatisticsLoading,
}) {
	const {t} = useTranslation();

	useEffect(() => {
		if (dates?.fromDate && dates?.toDate) {
			getClientStatistics({fromDate: dates.fromDate, toDate: dates.toDate});
		}
	}, [dates?.fromDate, dates?.toDate]);

	const [range, setRange] = useState(0);
	const [daysInTwooMonths, setDaysInTwooMonths] = useState(92);
	const [daysInThreeMonths, setDaysInThreeMonths] = useState(92);
	const [daysInMonth, setDaysInMonth] = useState(30);

	if (!_.isEmpty(userStats)) {
		tempArrDataset = [];
		tempArrLabels = [];
		Object.keys(userStats?.percentageMap).map((item) => {
			tempArrLabels.push(t(item));
			tempArrDataset.push(userStats?.percentageMap[item]);
			return null;
		});
	}

	const allZeros = tempArrDataset.every((element) => element === 0);

	useEffect(() => {
		switch (true) {
			case range === 1:
				setRangeType("day");
				setActive("custom");
				break;
			case range > 1 && range <= 7:
				setRangeType("week");
				setActive("custom");
				break;
			case range > 7 && range <= daysInMonth:
				setRangeType("month");
				setActive("custom");
				break;
			case range > daysInMonth && range <= daysInThreeMonths:
				setRangeType("quarter");
				setActive("custom");
				break;
			case range > daysInThreeMonths:
				setRangeType("year");
				setActive("custom");
				break;
			default:
				setRangeType("");
		}
	}, [range]);

	const DoughnutLegend = ({labels, colors}) => (
		<div className="legend-scrollable">
			{labels.map((label, index) => (
				<div key={index} className="legend-item">
					<div
						className="legend-item-round"
						style={{backgroundColor: colors[index], width: "10px", height: "10px"}}
					></div>
					<span className="legend-item-text"></span>
					{label}
				</div>
			))}
		</div>
	);

	return (
		<div
			style={{
				backgroundColor: clientStatisticsLoading ? "#e1e1e1" : "#ffffff",
			}}
			className="dashboardOne"
		>
			{clientStatisticsLoading ? (
				<Skeleton.Input
					active
					style={{height: 40, width: "100%", borderRadius: 5, marginBottom: 10}}
				/>
			) : (
				<>
					<div className="dashboardOne__second">
						<div className="dashboardOne__bottom">
							<TabComp
								onClick={() => handleActive({type: "day"})}
								active={active === "day"}
								word={t("day")}
								templates
							></TabComp>

							<TabComp
								onClick={() => handleActive({type: "week"})}
								active={active === "week"}
								word={t("Week")}
								templates
							></TabComp>

							<TabComp
								onClick={() => handleActive({type: "month"})}
								active={active === "month"}
								word={t("month")}
								templates
							></TabComp>

							<TabComp
								onClick={() => handleActive({type: "quarter"})}
								active={active === "quarter"}
								word={`3 ${t("month")}`}
								templates
							></TabComp>

							<TabComp
								onClick={() => handleActive({type: "year"})}
								active={active === "year"}
								word={t("year")}
								templates
							></TabComp>
							<TabComp
								onClick={() => handleActive({type: "custom"})}
								active={active === "custom"}
								word={t("custom")}
								templates
							></TabComp>
						</div>
						<div className="dashboardOne__second__selectedDate">
							<span>
								{Number.isNaN(datesGraph.from)
									? ""
									: moment(datesGraph.from).format("DD-MM-YYYY")}
							</span>
							<span>-</span>
							<span>
								{Number.isNaN(datesGraph.to)
									? ""
									: moment(datesGraph.to).format("DD-MM-YYYY")}
							</span>
						</div>
					</div>
					<div className="dashboardOne__contanier">
						<span>{t("OrdersperProvider")}</span>
						<div className="dashboardOne__contanier__content">
							{clientStatistics?.map((item) => (
								<div
									className="dashboardOne__contanier__content__item"
									style={{
										backgroundColor: `${item.clientColor}`,
									}}
								>
									{item?.clientId}
								</div>
							))}
						</div>
					</div>
				</>
			)}
			{clientStatisticsLoading ? (
				<Skeleton.Input active style={{height: 300, width: "100%", borderRadius: 5}} />
			) : (
				<>
					{checkRoles("seeUsersStats") && (
						<div className="dashboardOne__userStats">
							{!allZeros ? (
								clientStatistics.map((item, index) => (
									<div className="dashboardOne__userStats__content">
										<div
											className="userstats-container--middle--top"
											style={{display: "flex", justifyContent: "center"}}
										>
											<OrderStats
												title={t("TotalOrders")}
												clientName={item?.clientName}
												totalOrders={item?.totalOrder}
												orders={item?.totalOrdersWithoutCancellation}
												cancelled={item?.totalCancelled}
												colors={item?.clientColor}
												isTitle={true}
											/>
										</div>
										<div className="dashboardOne__userStats__content__box">
											<div className="userstats-container--middle">
												{item.projectCategories
													.slice(0, 4)
													.map((project) => (
														<OrderStats
															key={project.name}
															title={project?.name}
															totalOrders={project?.totalOrder}
															orders={
																project?.totalOrdersWithoutCancellation
															}
															cancelled={project?.totalCancelled}
															colors={item.clientColor}
														/>
													))}
											</div>
											<div className="dashboardOne__userStats__content__box__chart">
												<div>
													<Doughnut
														key={index}
														data={{
															labels: item.projectCategories.map(
																(project) => project.name,
															),
															datasets: [
																{
																	data: item.projectCategories.map(
																		(project) =>
																			project.totalPercentage,
																	),
																	backgroundColor:
																		item.projectCategories.map(
																			(project) =>
																				project.color,
																		),
																	borderWidth: 3,
																},
															],
														}}
														options={{
															...optionsPercentageGraph,
															plugins: {
																legend: {
																	display: true,
																	position: "bottom",
																	labels: {
																		font: {
																			size: 9,
																		},
																		boxWidth: 10,
																		boxHeight: 10,
																	},
																},
															},
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<div className="noDataMsg">{t("NoData")}</div>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
}
const mapStateToProps = (state) => ({
	userStats: state.app.orders.index.userStats,
	clientStatistics: state.app.orders.index.clientStatistics,
	clientStatisticsLoading: state.app.orders.index.loadingChart,
	query: state.app.orders.index.query,
	billingDetails: state.app.userProfile.settings.billingDetails,
});

const mapDispatchToProps = {
	getUserStats: orderActions.getUserStats,
	getLeaderboard: orderActions.getLeaderboard,
	getClientStatistics: orderActions.getClientStatistics,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStats);
