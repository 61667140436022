import React, {useEffect} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as trainingActions} from "@sagas/trainings";
import {actions as createTrainingActions} from "@sagas/trainings/create";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {actions as navigation} from "@sagas/navigation";
import ReactDOMServer from "react-dom/server";
import {renderPreviewFiles} from "@common/messagesComponents/MessagesContainer/helpers";
import TrainingSteps from "./Steps";
import RightPages from "./RightPages";
import FooterTraining from "./Footer";

function MyFragmentComponent({file}) {
	return <>{renderPreviewFiles(file, true)}</>;
}

const SingleTraining = (props) => {
	const {
		changePage,
		activePage,
		readPages,
		pages,
		getSingleTemplate,
		match,
		navigate,
		clearPages,
		uploadedFiles,
		clearFilterValues,
	} = props;
	const navigateBack = () => {
		clearFilterValues();
		navigate("/admin/trainings");
	};

	useEffect(() => {
		if (match.params.id) getSingleTemplate(match.params.id);
		// eslint-disable-next-line
		return () => {
			clearPages();
		};
	}, [match.params.id]);

	let content = draftToHtml(convertToRaw(pages[activePage - 1]?.content.getCurrentContent()));
	content = content.replace(/\[#(.*?)#\]/g, (_, url) =>
		ReactDOMServer.renderToString(
			<MyFragmentComponent file={uploadedFiles.find((e) => e?.fileUrl === url)} />,
		),
	);

	return (
		<div className="singleTraining">
			<div>
				<div className="singleTraining__content">
					<TrainingSteps
						readPages={readPages}
						length={pages?.length}
						navigateBack={navigateBack}
					/>
					<div className="training__page__container">
						<h1>{pages[activePage - 1]?.title}</h1>

						<span
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: content,
							}}
							style={{
								overflowWrap: "break-word",
							}}
						/>
					</div>
				</div>
				<RightPages activePage={activePage} length={pages?.length} />
				<FooterTraining
					activePage={activePage}
					changePage={changePage}
					pages={pages}
					navigate={navigate}
					clearFilterValues={clearFilterValues}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	activePage: state.app.trainings.index.activePage,
	readPages: state.app.trainings.index.readPages,
	pages: state.app.trainings.create.pages,
	uploadedFiles: state.app.trainings.upload.uploadedFiles,
});
const mapDispatchToProps = {
	changePage: trainingActions.changePage,
	clearPages: trainingActions.clearPages,
	getTrainings: trainingActions.getTrainings,
	getSingleTemplate: createTrainingActions.getSingleTemplate,
	navigate: navigation.navigate,
	clearFilterValues: trainingActions.clearFilterValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleTraining));
