import { checkRoles } from "@src/utils/checkRoles";
import { getCurrentUser } from "@src/utils/currentUser";
import i18n from "../../../i18n";

const currentUser = getCurrentUser();

export const settingsItems = [
	{
		title: i18n.t("Accountsettings"),
		subTitle: i18n.t("PersonalInformation"),
		path: "account-settings",
		show: true,
	},
	{
		title: i18n.t("PublicPofile"),
		subTitle: i18n.t("companyInfo"),
		path: "public-profile",
		show: true,
	},
	{
		title: i18n.t("BillingDetails"),
		subTitle: i18n.t("billingDetailsInfo"),
		path: "billing-details",
		show: checkRoles("billingDetails"),
	},
	{
		title: i18n.t("EmailSettings"),
		subTitle: i18n.t("emailTemplate"),
		path: "email-templates",
		show: checkRoles("billingDetails"),
	},
	{
		title: i18n.t("Marketplace"),
		path: "marketplace",
		show: currentUser?.role === "ADMIN",
	}
]