import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Products index");

const PREFIX = "@app/products/index";
export const GET_PRODUCTS = `${PREFIX}GET_PRODUCTS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_PRODUCTS_SUCCESS = `${PREFIX}FETCH_PRODUCTS_SUCCESS`;
export const ADD_PRODUCT_ON_STATE = `${PREFIX}ADD_PRODUCT_ON_STATE`;
export const EDIT_PRODUCT_ON_STATE = `${PREFIX}EDIT_PRODUCT_ON_STATE`;
export const DELETE_PRODUCT_ON_STATE = `${PREFIX}DELETE_PRODCT_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const FILTERS = `${PREFIX}FILTERS`;
export const FETCH_PRODUCTS_BY_DROPDOWN = `${PREFIX}FETCH_PRODUCTS_BY_DROPDOWN`;
export const FETCH_PRODUCTS_BY_DROPDOWN_SUCCESS = `${PREFIX}FETCH_PRODUCTS_BY_DROPDOWN_SUCCESS`;
export const EDIT_ID = `${PREFIX}EDIT_ID`;
export const FILTER_TARIFFS = `${PREFIX}FILTER_TARIFFS`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const TOTAL_SIZE_DECREASE = `${PREFIX}TOTAL_SIZE_DECREASE`;
export const FETCH_PRODUCTS_BY_TARIFF = `${PREFIX}FETCH_PRODUCTS_BY_TARIFF`;
export const FETCH_PRODUCTS_BY_TARIFF_SUCCESS = `${PREFIX}FETCH_PRODUCTS_BY_TARIFF_SUCCESS`;

const _state = {
	products: [],
	optionsProducts: [],
	loading: false,
	page: 1,
	size: 10,
	totalSize: 5,
	search: "",
	chosenProductId: 0,
	filteredTariffs: [],
	productsByTariff: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload + 1;
				break;
			case EDIT_ID:
				actions.setFilteredTariffs(null);
				draft.chosenProductId = action.payload;

				break;

			case FILTER_TARIFFS:
				if (action.payload === null) {
					draft.filteredTariffs = [];
				} else {
					draft.filteredTariffs = state.filteredTariffs.concat(action.payload);
				}

				break;
			case SIZE_EDIT:
				draft.size = action.payload;

				break;

			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = action.payload.value;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case FETCH_PRODUCTS_SUCCESS:
				draft.products = action.payload;

				break;
			case FETCH_PRODUCTS_BY_DROPDOWN_SUCCESS:
				draft.optionsProducts = action?.payload?.map((e) => ({
					name: e.name,
					value: e.id,
					product: true,
				}));
				break;
			case ADD_PRODUCT_ON_STATE:
				draft.products = [action.payload].concat(state.products);
				break;
			case EDIT_PRODUCT_ON_STATE:
				const index = state.products.findIndex((c) => c.id === action.payload.id);
				draft.products[index] = action.payload;

				break;
			case DELETE_PRODUCT_ON_STATE:
				draft.products = state.products.filter((product) => product.id !== action.payload);
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			case FETCH_PRODUCTS_BY_TARIFF_SUCCESS:
				draft.productsByTariff = action.payload.map((item) => ({
					label: item.name,
					value: item.id,
				}));
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getProducts: (payload) => createAction(GET_PRODUCTS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchProductsSuccess: (payload) => createAction(FETCH_PRODUCTS_SUCCESS, {payload}),
	addProductOnState: (payload) => createAction(ADD_PRODUCT_ON_STATE, {payload}),
	editProductOnState: (payload) => createAction(EDIT_PRODUCT_ON_STATE, {payload}),
	deleteProductOnState: (payload) => createAction(DELETE_PRODUCT_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	filterProducts: (payload) => createAction(FILTERS, {payload}),
	fetchProductsByDropdown: (payload) => createAction(FETCH_PRODUCTS_BY_DROPDOWN, {payload}),
	fetchProductsByDropdownSuccess: (payload) =>
		createAction(FETCH_PRODUCTS_BY_DROPDOWN_SUCCESS, {payload}),
	editProductId: (payload) => createAction(EDIT_ID, {payload}),
	setFilteredTariffs: (payload) => createAction(FILTER_TARIFFS, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	totalSizeDecrease: (payload) => createAction(TOTAL_SIZE_DECREASE, {payload}),
	fetchProductsByTariff: (payload) => createAction(FETCH_PRODUCTS_BY_TARIFF, {payload}),
	fetchProductsByTariffSuccess: (payload) =>
		createAction(FETCH_PRODUCTS_BY_TARIFF_SUCCESS, {payload}),
};

export const sagas = {
	*getProducts() {
		yield put(actions.setLoading(true));
		const {size} = yield select((state) => state.app.products.index);
		const {search} = yield select((state) => state.app.products.index);
		const {page} = yield select((state) => state.app.products.index);
		try {
			const response = yield axios.get(
				`/product/all?page=${page}&size=${size}&search=${search}`,
			);

			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchProductsSuccess(response?.data?.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchProductsByDropdown({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {selectedClient} = yield select((state) => state.app.client.index);
			const response = yield axios.get(`/product/drop-down/${payload || selectedClient}`);

			yield put(actions.fetchProductsByDropdownSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchProductsByTariff({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/product/tariff/${payload}`);
			yield put(actions.fetchProductsByTariffSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_PRODUCTS, sagas.getProducts);
	yield takeLatest(FETCH_PRODUCTS_BY_DROPDOWN, sagas.fetchProductsByDropdown);
	yield takeLatest(FETCH_PRODUCTS_BY_TARIFF, sagas.fetchProductsByTariff);
};
