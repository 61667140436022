import axios from "axios";
export const handleDownloadNoToken = (file) => {
	axios
		.get(`${file.fileUrl}?dummy=dummy`, {
			headers: {
				"Access-Control-Allow-Origin": "*",
			},
			responseType: "blob",
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const a = document.createElement("a");
			a.href = url;
			a.download = file.fileName || file.fileUrl;
			a.click();
		});
};
