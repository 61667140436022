import {useEffect, useState} from "react";
import {Storage} from "./local-storage";

export const useFeatures = () => {
	const [features, setFeatures] = useState(Storage.get("features"));

	useEffect(() => {
		const handler = (event) => {
			setFeatures(event.detail);
		};
		const listener = Storage.subscribe("features", handler);

		return () => listener();
	}, []);

	const checkFeature = (targetFeature) => !!features?.[targetFeature];

	return {features, setFeatures, checkFeature};
};
