import DataGridTable from "@src/common/table/DataGridTable";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as usersActions} from "@sagas/users/users";
import {actions as deletedUsersActions} from "@sagas/users/users/deleted";
import {actions as teamActions} from "@sagas/teams";
import {actions as navigation} from "@sagas/navigation";
import {actions as createTeamActions} from "@sagas/teams/create";
import {useUrlParams} from "@src/utils/useUrlParams";
import {
	actionsDeletedFormatter,
	actionsFormatter,
	actionsProperties,
	agencyActionsFormatter,
	teamActionsFormatter,
} from "./actionFormatters";
import {clientColumnsRemote, clientColumnsRemoteAgency} from "./teamElements";
import {deletedColumns, usersColumns} from "../columns";

const Table = (props) => {
	const {
		navigate,
		users,
		page,
		totalPages,
		totalSize,
		loading,
		editSize,
		editPage,
		size,
		deletedUsersData,
		editPageDeleted,
		editSizeDeleted,
		rows,
		teamsPage,
		teamsTotalPages,
		teamsTotalSize,
		teamsLoading,
		teamsEditPage,
		teamsEditSize,
		teamsSize,
		setReEditPage,
		reEditPage,
		tableRef,
		setRows,
		fillTeam,
		teamSetShowModal,
	} = props;
	const {setUrlParams, getUrlParams, setQuery, setModal} = useUrlParams();
	const {tab, tableTab} = getUrlParams("tab", "table-tab");
	const actions = {
		...actionsProperties,
		formatter: ({row}) => actionsFormatter(row, navigate, setModal),
	};
	const openTeamModal = (row) => {
		fillTeam(row);
		teamSetShowModal(true);
	};
	const teamsActions = {
		...actionsProperties,
		width: 150,
		formatter: ({row}) => teamActionsFormatter(row, setModal),
	};
	const agencyActions = {
		...actionsProperties,
		formatter: ({row}) => agencyActionsFormatter(row, navigate, setModal),
	};
	const deletedActions = {
		...actionsProperties,
		formatter: ({row}) => actionsDeletedFormatter(row, setModal),
	};
	const returnTableCells = () => {
		if (tab === "agency") {
			return [...clientColumnsRemoteAgency, agencyActions];
		}
		if (tab === "teams") {
			return [...clientColumnsRemote, teamsActions];
		}
	};

	const tableProps = {
		active: {
			data: users,
			page,
			totalPages,
			totalSize,
			loading,
			editSize,
			editPage,
			size,
			rows: users,
			columns: [...usersColumns, actions],
		},
		inactive: {
			columns: [...deletedColumns, deletedActions],
			data: deletedUsersData?.users,
			rows: deletedUsersData?.users,
			totalPages: deletedUsersData?.totalPages,
			editPage: editPageDeleted,
			editSize: editSizeDeleted,
			size: deletedUsersData?.size,
			page: deletedUsersData.page,
			totalSize: deletedUsersData?.totalSize,
			loading: deletedUsersData?.loading,
		},
		teams: {
			rows,
			data: rows,
			columns: returnTableCells(),
			page: teamsPage,
			totalPages: teamsTotalPages,
			totalSize: teamsTotalSize,
			loading: teamsLoading,
			editSize: teamsEditSize,
			editPage: teamsEditPage,
			size: teamsSize,
		},
	};
	const tablePropsBasedOnTab = tab === "users" ? tableProps[tableTab] : tableProps.teams;
	const tabHandler = (paramValue) => {
		if (paramValue !== tableTab) {
			setUrlParams("users", "tab", {paramName: "table-tab", paramValue});
			setQuery({search: ""});
		}
	};
	return (
		<div
			style={{
				padding: "20px",
				height: tab === "users" ? "calc(100vh - 100px)" : "calc(100vh - 48px",
			}}
		>
			<div className="loader__and__table-userstable">
				<DataGridTable
					pagination
					setReEditPage={setReEditPage}
					reEditPage={reEditPage}
					tableRef={tableRef}
					activeTab={tab}
					rowHeight={({row}) =>
						row?.type === "DETAIL" ? row?.members.length * 44 + 76 : 44
					}
					{...tablePropsBasedOnTab}
					currentTab={tableTab}
					onTabClick={tabHandler}
					withTabs={tab === "users"}
					onRowsChange={(rows, {indexes}) => {
						const updatedRows = [...rows];
						const row = updatedRows[indexes[0]];
						const detailRowIndex = updatedRows.findIndex(
							(r) => r.type === "DETAIL" && r.parentId === row.id,
						);
						if (row.type === "MASTER") {
							if (row.expanded) {
								if (detailRowIndex === -1) {
									const newDetailRow = {
										type: "DETAIL",
										members: row?.members,
										parentId: row?.id,
										id: row?.teamLeaderId,
										teamLeaderId: row?.teamLeaderId,
									};
									updatedRows.splice(indexes[0] + 1, 0, newDetailRow);
								}
							} else if (detailRowIndex !== -1) {
								updatedRows.splice(detailRowIndex, 1);
							}
							setRows(updatedRows);
						}
					}}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.users.index.fetchLoader,
	page: state.app.users.index.page,
	size: state.app.users.index.size,
	totalSize: state.app.users.index.totalSize,
	totalPages: state.app.users.index.totalPages,
	users: state.app.users.index.users,
	deletedUsersData: state.app.users.deleted,
	teamsLoading: state.app.teams.index.isLoading,
	teamsPage: state.app.teams.index.page,
	teamsSize: state.app.teams.index.size,
	teamsTotalSize: state.app.teams.index.totalSize,
	teamsTotalPages: state.app.teams.index.totalPages,
	rows: state.app.teams.index.rows,
});

const mapDispatchToProps = {
	editSize: usersActions.editSize,
	editPage: usersActions.editPage,
	editSizeDeleted: deletedUsersActions.editSize,
	editPageDeleted: deletedUsersActions.editPage,
	teamsEditPage: teamActions.editPage,
	teamsEditSize: teamActions.editSize,
	navigate: navigation.navigate,
	setRows: teamActions.setRows,
	fillTeam: createTeamActions.fillTeam,
	teamSetShowModal: createTeamActions.setShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Table));
