import _ from "lodash";

export const groupItemsByDates = (payload) => {
	const groupedItems = _.groupBy(payload, (item) => `${item.from}, ${item?.to}`);
	const modifiedItems = Object.values(groupedItems).map((values) => {
		const {from, to} = values[0];
		return {startDate: from, endDate: to, data: values};
	});
	return modifiedItems;
};

export const updateArrayBasedOnType = (array, type, values) => {
	switch (type) {
		case "edit":
			return array?.map((item) => {
				if (item.id === values.id) {
					return values;
				}
				return item;
			});
		case "delete":
			return array?.filter((item) => item.id !== values);
		case "add":
			return [...array, values];
		default:
			return [];
	}
};
