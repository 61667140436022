/* eslint-disable no-nested-ternary */
import NotificationBox from "@src/common/components/notificationBox";
import React, {useRef} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as notificationsActions} from "@sagas/notifications/index";
import Loader from "@src/common/Loader";
import {NoDataIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";

function NotificationsWrapper({systemNotificationsState, setPage, readNotification}) {
	const listRef = useRef();
	const {t} = useTranslation();
	const {page, totalPages, loading, systemNotifications, scrollLoading} =
		systemNotificationsState;

	async function handleScroll(event) {
		const {scrollTop} = event?.currentTarget;
		const {scrollHeight} = event?.currentTarget;
		const {clientHeight} = event?.currentTarget;
		const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
		if (isAtBottom && page < totalPages && !scrollLoading) {
			setPage();
		}
	}
	return (
		<div ref={listRef} onScroll={handleScroll} className="notifications-right-container">
			<div>
				{loading ? (
					<div className="empty">
						<Loader color="#6C1EB0" />
					</div>
				) : systemNotifications?.[0] ? (
					systemNotifications?.map((notification) => (
						<NotificationBox item={notification} readNotification={readNotification} />
					))
				) : (
					<div className="empty">
						<div>
							<NoDataIcon width="64" height="79" fill="#979797" />
						</div>
						<span className="empty-text">{t("NoData")}!</span>
					</div>
				)}
			</div>
			<div>
				{scrollLoading && (
					<div className="notifications__loading">
						<Loader color="#6C1EB0" />
					</div>
				)}
			</div>
		</div>
	);
}
const mapStateToProps = (state) => ({
	addingNewMessage: state.app.messages.create.addingNewMessage,
	insideTopic: state.app.messages.index.insideTopic,
	systemNotificationsState: state.app.notifications.index,
});
const mapDispatchToProps = {
	setPage: notificationsActions.setPage,
	readNotification: notificationsActions.readNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationsWrapper));
