import { HouseIcon } from '@src/assets/SvgComponents'
import React from 'react'

const SelectHouse = ({ item, onClick, value }) => {
  const isSelected = (nr) => value === nr
  return (
    <div className="options-container">
      {item?.options.map((opt, index) => (
        <div
          className={`single__option ${isSelected(opt?.nr) ? 'active' : ''}`}
          key={`${index}++${opt?.countValue}`}
          onClick={() => onClick(item.field, opt)}
        >
          <div className="single__option__wrapper">
            <HouseIcon fill={isSelected(opt?.nr) ? '#fff' : undefined} />
            <div className="single__option__wrapper-item">
              <span className={`countValue ${isSelected(opt?.nr) && 'active'}`}>
                {opt?.nr}
                <sup>m2</sup>
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SelectHouse
