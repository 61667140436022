/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, {useState, useEffect} from "react";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {actions as clientProduct} from "@sagas/products/clientProduct";
import {actions as createClientProduct} from "@sagas/products/createClientProduct";
import {actions as deleteClientProduct} from "@sagas/products/deleteClientProduct";
import {actions as deletedProductActions} from "@sagas/products/deleted";
import {actions as restoreProductActions} from "@sagas/products/restore";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import {actions as clientTariff} from "@sagas/tariff/clientTariff";
import ArrowUp from "@assets/img/arrow_upward.svg";
import ArrowDown from "@assets/img/arrow_downward.svg";
import {checkRoles} from "@utils/checkRoles";
import {
	DeleteRowIcon,
	EditRowIcon,
	PreviewRowIcon,
	RestoreRowIcon,
} from "@src/assets/SvgComponents";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import Form from "@src/common/Form";
import {useUrlParams} from "@src/utils/useUrlParams";
import {productElements} from "./productElements";

function ProductsComp(props) {
	const {
		getProducts,
		products,
		deleteProduct,
		size,
		page,
		editSize,
		totalSize,
		search,
		editPage,
		modals,
		fetchTariffsByDropdownId,
		clientProductsInitialValues,
		loading,
		dropdownTariffs,
		addProduct,
		fetchDeleted,
		deletedProductData,
		editPageDeleted,
		editSizeDeleted,
		restoreProduct,
		fetchLoading,
		changeProductOrder,
		deactivateProduct,
		deleteLoading,
		restoreLoading,
		totalPages,
		toggleProduct,
		match,
		singleProduct,
		client,
		loggedUser,
	} = props;
	const {closeToggleModal, openToggleModal, currentTab, setCurrentTab, openFieldsModal} = modals;
	const isAdmin = loggedUser?.role?.name === "ADMIN";
	const {getUrlParams, setModal} = useUrlParams();
	const handleProductModal = (show, id) => setModal(show, id);
	const {modal, id} = getUrlParams("modal", "id");
	const [reEditPage, setReEditPage] = useState(true);
	const [filteredTariffs, setFilteredTariffs] = useState([]);
	const {clientId} = match.params;
	const {columns, lists} = productElements(client);

	const {t} = useTranslation();

	useEffect(() => {
		fetchTariffsByDropdownId(clientId);
		// eslint-disable-next-line
	}, []);

	const changeProdOrder = (data) => {
		const itemIndex = products.findIndex((item) => item?.id === data?.id);
		if (
			(itemIndex !== 0 && data?.action === "up") ||
			(itemIndex + 1 < products?.length && data?.action === "down")
		) {
			changeProductOrder({
				id: data?.id,
				newRow:
					data?.action === "up"
						? products[itemIndex - 1]?.nr
						: products[itemIndex + 1]?.nr,
				action: data?.action,
			});
		}
	};
	const actionsFormatter = (row) => (
		<div
			className="table-actions-wrapper"
			style={{width: isAdmin ? "200px" : "180px", paddingRight: "20px"}}
		>
			{isAdmin && (
				<HtmlTooltip title={<span>Generate fields</span>} placement="bottom-end">
					<span
						className={`preview__row ${!row?.isHidden && "hide"}`}
						onClick={() => openFieldsModal(row)}
					>
						<PreviewRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deactivateProducts") && (
				<HtmlTooltip
					title={<span>{!row?.isHidden ? t("SecondShow") : t("Hide")}</span>}
					placement="bottom-end"
				>
					<span
						className={`preview__row ${!row?.isHidden && "hide"}`}
						onClick={() => openToggleModal(row?.id)}
					>
						<PreviewRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderUpProducts") && (
				<HtmlTooltip title={<span>{t("Up")}</span>} placement="bottom-end">
					<img
						src={ArrowUp}
						alt=""
						style={{pointerEvents: products?.[0]?.id === row?.id ? "none" : ""}}
						onClick={() => changeProdOrder({id: row?.id, action: "up"})}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderDownProducts") && (
				<HtmlTooltip title={<span>{t("Down")}</span>} placement="bottom-end">
					<img
						src={ArrowDown}
						alt=""
						style={{pointerEvents: products?.[products?.length - 1]?.id === row?.id}}
						onClick={() => changeProdOrder({id: row?.id, action: "down"})}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("editProducts") && (
				<HtmlTooltip title={<span>{t("editProduct")}</span>} placement="bottom-end">
					<span onClick={() => handleProductModal("product", row?.id)}>
						<EditRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deactivateProducts") && (
				<HtmlTooltip title={<span>{t("deleteProduct")}</span>} placement="bottom-end">
					<span onClick={() => handleProductModal("deactivate", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actions = {
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: isAdmin ? 220 : 180,
		formatter: ({row}) => actionsFormatter(row),
		headerCellClass: "headerStyles",
	};
	const actionsDeletedFormatter = (row) => (
		<div className="table-actions-wrapper">
			{checkRoles("activateProducts") && (
				<HtmlTooltip title={<span>{t("ActivateProduct")}</span>} placement="bottom-end">
					<span onClick={() => handleProductModal("restore", row?.id)}>
						<RestoreRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deleteProducts") && (
				<HtmlTooltip
					title={<span>{t("PermanentDeleteProduct")}</span>}
					placement="bottom-end"
				>
					<span onClick={() => handleProductModal("delete", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);

	const actionsDeleted = {
		resizable: false,
		sortable: false,
		key: "actions",
		name: t("Actions"),
		width: 50,
		formatter: ({row}) => actionsDeletedFormatter(row),
		headerCellClass: "headerStyles",
	};

	useEffect(() => {
		if (currentTab === "active") {
			getProducts({clientId, search});
		}
	}, [page, size, search, currentTab]);
	useEffect(() => {
		if (currentTab === "inactive") {
			fetchDeleted({clientId, search});
		}
	}, [deletedProductData?.size, deletedProductData?.page, search, currentTab]);

	useEffect(() => {
		let freshArr = dropdownTariffs;

		// When editing a product, you still have the option to select an already selected tariff.
		// This function will handle this problem and filter out the selected tariffs.
		if (clientProductsInitialValues?.tariffIds?.length === 0) {
			setFilteredTariffs(dropdownTariffs);
		} else if (clientProductsInitialValues?.tariffIds?.length > 0) {
			clientProductsInitialValues?.tariffIds?.map((item) => {
				freshArr = freshArr.filter((item2) => item.id !== item2.value);
			});
			setFilteredTariffs([...new Set(freshArr)]);
		}
		// eslint-disable-next-line
	}, [clientProductsInitialValues, dropdownTariffs]);

	const tableProps = {
		active: {
			columns: [...columns, actions],
			data: products,
			editSize,
			editPage,
			size,
			totalSize,
			page,
			totalPages,
			loading: fetchLoading,
		},
		inactive: {
			columns: [...columns, actionsDeleted],
			data: deletedProductData?.list,
			editPage: editPageDeleted,
			editSize: editSizeDeleted,
			size: deletedProductData?.size,
			page: deletedProductData?.page,
			totalSize: deletedProductData?.totalSize,
			totalPages: deletedProductData?.totalPages,
			loading: deletedProductData?.loading,
		},
	};
	const formData = {
		inputs: lists?.map((list) => ({
			...list,
			options: list?.field === "tariffIds" ? filteredTariffs : list?.options,
		})),
		id,
		title: id ? t("EditProduct") : t("AddProduct"),
		submitFn: (values, actions) =>
			addProduct({values: {...values, clientId}, formActions: actions, setModal}),
		getUrl: "/product/:id",
	};
	const modalConfig = {
		deactivate: {
			confirm: () => deactivateProduct({id, client: clientId, handleProductModal}),
			type: "deactivate",
			disabled: deleteLoading,
		},
		restore: {
			confirm: () => restoreProduct({id, handleProductModal}),
			type: "restore",
			modalType: "activate",
			disabled: restoreLoading,
		},
		delete: {
			confirm: () => deleteProduct({id, client: clientId, handleProductModal}),
			type: "delete",
			modalType: "permanantDelete",
			disabled: deleteLoading,
		},
		visibility: {
			confirm: () =>
				toggleProduct({
					id: singleProduct?.id,
					closeModal: closeToggleModal,
					isHidden: !singleProduct?.isHidden,
				}),
			type: "visibility",
			newTitle: `${t(`AreYouSureTo${singleProduct?.isHidden ? "Show" : "Hide"}`)} "${
				singleProduct?.name
			}"?`,
			disabled: deleteLoading,
			calculationModal: true,
		},
	};

	const activeModal = modal ? modalConfig[modal] : null;
	return (
		<>
			{activeModal && (
				<DeleteModal
					calculationModal={activeModal?.calculationModal}
					setShowDeleteModal={() => handleProductModal(false)}
					confirm={activeModal.confirm}
					newTitle={activeModal?.newTitle}
					open={activeModal.type === modal}
					handleClose={() => handleProductModal(false)}
					disabled={activeModal.disabled}
					modalType={activeModal.modalType}
					word={t("thisProduct")}
				/>
			)}
			<Form
				open={modal === "product"}
				handleClose={() => handleProductModal(false)}
				formData={formData}
				loading={loading}
			/>
			<div className="loader__and__table">
				<DataGridTable
					{...tableProps[currentTab]}
					reEditPage={reEditPage}
					setReEditPage={setReEditPage}
					pagination
					withTabs
					currentTab={currentTab}
					onTabClick={setCurrentTab}
				/>
			</div>
		</>
	);
}
const mapStateToProps = (state) => ({
	products: state.app.products.clientProduct.clientProducts,
	page: state.app.products.clientProduct.page,
	size: state.app.products.clientProduct.size,
	fetchLoading: state.app.products.clientProduct.isLoading,
	totalSize: state.app.products.clientProduct.totalSize,
	showModal: state.app.products.createClientProduct.showModal,
	deleteModal: state.app.products.deleteClientProduct.deleteShowModal,
	clientTariffs: state.app.tariffs.clientTariff.clientTariffs,
	clientProductsInitialValues: state.app.products.createClientProduct.productsInitialValues,
	loading: state.app.products.createClientProduct.loading,
	deleteLoading: state.app.products.deleteClientProduct.loading,
	restoreLoading: state.app.products.restore.loading,
	dropdownTariffs: state.app.tariffs.clientTariff.dropdownTariffs,
	deletedProductData: state.app.products.deleted,
	restoreProductData: state.app.products.restore,
	totalPages: state.app.products.clientProduct.totalPages,
	singleProduct: state.app.products.clientProduct.singleProduct,
	client: state.app.client.index.client,
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	editPage: clientProduct.editPage,
	getProducts: clientProduct.fetchClientProducts,
	editSize: clientProduct.editSize,
	deleteProduct: deleteClientProduct.deleteProduct,
	deactivateProduct: deleteClientProduct.deactivateProduct,
	fetchTariffsByDropdownId: clientTariff.fetchTariffsByDropdownId,
	addProduct: createClientProduct.addProduct,
	fetchDeleted: deletedProductActions.fetch,
	editPageDeleted: deletedProductActions.editPage,
	editSizeDeleted: deletedProductActions.editSize,
	restoreProduct: restoreProductActions.restore,
	changeProductOrder: clientProduct.changeProductOrder,
	toggleProduct: clientProduct.toggleProductVisibility,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductsComp));
