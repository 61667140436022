import React, {useEffect, useState} from "react";
import {Image} from "@react-pdf/renderer";
import {fetchSvgContent, svgToDataUri} from "./helper";
const SVGImage = ({uri, styles}) => {
	const [svgDataUri, setSvgDataUri] = useState("");
	useEffect(() => {
		let isMounted = true;
		async function convertSvgToDataUri() {
			const svg = await fetchSvgContent(uri);
			if (svg) {
				const dataUri = await svgToDataUri(svg);
				if (isMounted) setSvgDataUri(dataUri || "");
			}
		}
		convertSvgToDataUri();
		return () => {
			isMounted = false;
		};
	}, [uri]);
	// this handles svg and png cases, for svg: svgDataUri & for png: uri
	return <Image src={svgDataUri || uri} style={styles} />;
};
export default SVGImage;
