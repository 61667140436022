/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useRef, useState} from "react";
import {useDrag} from "react-dnd";
import HtmlTooltip from "@common/htmlTooltip";
import moment from "moment";
import {LayersIcon} from "@src/assets/SvgComponents";
import grabLogo from "../../assets/img/grabLogo.svg";
import closeIcon from "../../assets/img/close-icon.svg";
import cloneIcon from "../../assets/img/calendar-icon.svg";
import {getLabel} from "../generic-pdf/createOrder/multiplecontainers/helper";

export const SourceBox = ({
	item,
	insideTarget,
	droppableItems,
	draggableItems,
	setDragableItems,
	setDroppableItems,
}) => {
	const {id, x: left, y: top, name, inputType} = item;
	const [{isDragging, opacity}, drag] = useDrag(
		() => ({
			type: "box",
			item,
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.4 : 1,
			}),
		}),
		[id, top, left, name],
	);
	if (isDragging) {
		return <div ref={drag} />;
	}
	const handleRemoveDropped = () => {
		delete droppableItems[item.id];
		draggableItems[item.id] = {
			id: item.id,
			labelEn: getLabel(item),
		};
		setDragableItems({...draggableItems});
		setDroppableItems({...droppableItems});
	};
	const handleCloneField = () => {
		const newID = moment().valueOf();
		let parentId;
		if ("parentId" in item && item.parentId === null) {
			parentId = item.fieldId;
		} else if ("clone" in item && item.clone) {
			parentId = item.parentId;
		} else {
			parentId = item.id;
		}
		setDroppableItems({
			...droppableItems,
			[newID]: {
				...item,
				id: newID,
				x: item.x + 20,
				y: item.y + 20,
				parentId,
				clone: true,
			},
		});
	};

	const resizerRef = useRef();

	const [dimensions, setDimensions] = useState({width: 100, height: 30});

	useEffect(() => {
		if (insideTarget) {
			setDimensions({
				width: item?.width,
				height: item?.height,
				fontSize: item?.fontSize,
			});
		}
	}, []);

	const handleResize = () => {
		const element = resizerRef?.current;
		const fontSizeNew = 10;
		setDimensions({
			width: element?.clientWidth,
			height: element?.clientHeight,
			fontSize: fontSizeNew,
		});
		droppableItems[id] = {
			...item,
			width: element?.clientWidth,
			height: element?.clientHeight,
			fontSize: fontSizeNew,
		};
	};

	return (
		<>
			{item.static ? (
				<div className="parent__wrapper">
					<span className="parent__wrapper-icon">
						<LayersIcon />
					</span>
					<span className="parent__wrapper-label">{getLabel(item)}</span>
				</div>
			) : (
				<>
					{inputType !== "CHECKBOX" ? (
						<div
							ref={drag}
							className={
								insideTarget
									? "dragablePdf__right__droppable-input"
									: "dragablePdf__left__droppable-input"
							}
							style={{
								...(insideTarget ? {position: "absolute"} : {}),
								opacity,
								top,
								left,
							}}
						>
							<div
								ref={resizerRef}
								onMouseDown={() => (insideTarget ? handleResize() : null)}
								onMouseMove={() => (insideTarget ? handleResize() : null)}
								id={insideTarget ? "text-element" : ""}
								className={
									insideTarget ? "dragablePdf__right__droppable-input-text" : ""
								}
								style={{
									fontSize: insideTarget ? dimensions?.fontSize : "14px",
									width: insideTarget ? dimensions.width : "",
									height: insideTarget ? dimensions.height : 20,
									display: insideTarget ? "" : "flex",
									gap: insideTarget ? "" : "10px",
								}}
							>
								{!insideTarget && <img src={grabLogo} alt="" />}
								{dimensions.width > 30 ? (
									<span>{getLabel(item)}</span>
								) : (
									<HtmlTooltip
										placement="bottom"
										title={<span>{getLabel(item)}</span>}
									>
										<span>...</span>
									</HtmlTooltip>
								)}
								{insideTarget && (
									<>
										<img
											onClick={() => handleCloneField()}
											className="dragablePdf__right__droppable-input-cloneField"
											src={cloneIcon}
											alt=""
										/>
										<img
											onClick={() => handleRemoveDropped()}
											className="dragablePdf__right__droppable-input-removeLogo"
											src={closeIcon}
											alt=""
										/>
									</>
								)}
							</div>
						</div>
					) : (
						<div
							ref={drag}
							className={`${
								insideTarget
									? "dragablePdf__right__droppable-checkbox"
									: "dragablePdf__left__droppable-input"
							} ${item?.dropDownParentId && "child"}`}
							style={{
								...(insideTarget ? {position: "absolute"} : {}),
								opacity,
								top,
								left,
							}}
						>
							<div
								ref={resizerRef}
								id={insideTarget ? "text-element" : ""}
								onMouseDown={() => (insideTarget ? handleResize() : null)}
								onMouseMove={() => (insideTarget ? handleResize() : null)}
								className={
									insideTarget
										? "dragablePdf__right__droppable-input-checkbox"
										: ""
								}
								style={{
									fontSize: insideTarget ? dimensions?.fontSize : "14px",
									width: !insideTarget && dimensions.width !== 20 ? "unset" : 20,
									height:
										!insideTarget && dimensions.height !== 20 ? "unset" : 20,
									display: insideTarget ? "" : "flex",
									gap: insideTarget ? "" : "10px",
								}}
							>
								{!insideTarget ? (
									<>
										<img src={grabLogo} alt="" />
										<span>{getLabel(item)}</span>
									</>
								) : (
									<img
										onClick={() => handleRemoveDropped()}
										className="dragablePdf__right__droppable-input-removeLogo"
										src={closeIcon}
										alt=""
									/>
								)}
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};
