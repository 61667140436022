import React from "react";
import {withRouter} from "react-router";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {checkRoles} from "@src/utils/checkRoles";
import {useFeatures} from "@src/utils/useFeatures";
import ProtectedRoute from "../../common/protected-route";
import history from "../../utils/history";
import {AUTH_ROUTES} from "./authRoutes";
import {PROTECED_ROUTES} from "./protectedRoutes";

const Routes = () => {
	const {checkFeature} = useFeatures();
	return (
		<Router history={history}>
			<Switch>
				{PROTECED_ROUTES.map(({path, component, permission, feature}) => (
					<ProtectedRoute
						exact
						key={path}
						component={component}
						path={path}
						show={
							feature
								? checkFeature(feature) && checkRoles(permission)
								: checkRoles(permission)
						}
					/>
				))}
				{AUTH_ROUTES.map(({path, component}) => (
					<Route key={path} exact component={component} path={path} />
				))}
				<Redirect to="/" />
			</Switch>
		</Router>
	);
};

export default withRouter(Routes);
