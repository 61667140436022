import React, { useState } from "react";
import { Popover } from "antd";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { ArrowDownIcon, ArrowUpIcon } from "@src/assets/SvgComponents";

const ColorPickerPopOver = ({ values, onChange, color, textColor, name, showArrow = false, label, touched , error }) => {
	const [open, setOpen] = useState(false);

	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	const renderColorPicker = () => (
		<div className="color-picker-popover">
			<div className="activity__modal-color_picker">
				<HexColorPicker color={color} onChange={onChange} />
				<p>
					<HexColorInput color={color} onChange={onChange} />
					<div className="color_picker-preview" style={{ backgroundColor: color }}></div>
				</p>
			</div>
		</div>
	);

	return (
		<div className="color-picker-with-popover">
			<Popover
				content={renderColorPicker()}
				trigger="click"
				open={open}
				placement="bottomLeft"
				showArrow={true}
				onOpenChange={handleOpenChange}
			>
				{label && <span className="colorPicker__label">{label}</span>}
				<div
					onClick={() => handleOpenChange()}
					className={
						showArrow
							? "color-picker-with-popover__input color-picker-space"
							: "color-picker-with-popover__input"
					}
				>
					<div className="color-picker-with-popover__input-condition">
						<div
							className="color-picker-with-popover__input-condition-value"
							style={{
								backgroundColor: values?.[name] || textColor,
							}}
						></div>
						<span>{values?.[name] || textColor}</span>
					</div>
					{showArrow && (
						<div className="color-picke-arrow">
							{open ? <ArrowUpIcon fill="#979797" /> : <ArrowDownIcon />}
						</div>
					)}
				</div>
				{touched && error && <span className="errorClass">*{error}</span>}
			</Popover>
		</div>
	);
};

export default ColorPickerPopOver;
