import React from "react";
import i18n from "@src/i18n";
export const renderCounterItems = (statuses, selectedLength, areAllSelected, totalSize) => {
	const items = [
		{
			key: "selected leads",
			className: "first-child",
			label: (
				<div className="ant-dropdown-menu-title-content-item">
					<span>
						{i18n.t("Selected")} {i18n.t("All")} {i18n.t("Leads")}-<b>{totalSize}</b>
					</span>
				</div>
			),
			show: areAllSelected,
		},
		{
			key: "all leads selected",
			className: "first-child",
			label: (
				<div className="ant-dropdown-menu-title-content-item">
					<span>
						{i18n.t("Selected")} {i18n.t("Leads")} -<b>{selectedLength}</b>
					</span>
				</div>
			),
			show: selectedLength > 0 && !areAllSelected,
		},
		{
			key: "all leads",
			label: (
				<div className="ant-dropdown-menu-title-content-item">
					<span>
						{i18n.t("Total")} {i18n.t("Leads")} -
						<b>
							{statuses?.length} of {totalSize}
						</b>
					</span>
				</div>
			),
			show: !areAllSelected,
		},
	];
	return items?.filter((item) => item?.show);
};
