/* eslint-disable max-len */
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import Button from "@src/common/components/button";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import HtmlTooltip from "@src/common/htmlTooltip";
import Loader from "@src/common/Loader";
import {OpenActionsIcon} from "@src/assets/SvgComponents";
import {connect} from "react-redux";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {actions as deleteLeadActions} from "@sagas/salesTracker/delete";
import {actions as statusesActions} from "@sagas/salesTracker/statuses";
import {checkRoles} from "@src/utils/checkRoles";
import CustomPopover from "@src/common/Popover";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {getCurrentUser} from "@src/utils/currentUser";
import leadsIcon from "@assets/img/create_leads_icon.svg";
import {actions as importActions} from "@sagas/salesTracker/imports";
import {getSelectOptionsFromDataTeams} from "@src/utils/select";
import {actions as locationActions} from "@sagas/locations";
import {useUrlParams} from "@src/utils/useUrlParams";
import {Storage} from "@src/utils/local-storage";
import LeadUserActionsPopover from "./LeadUsersActions/LeadUserActionsPopover";
import plusLogo from "../../../../assets/img/plusIconBackground.svg";
import iconStatus from "../../../../assets/img/trello.svg";
import importIcon from "../../../../assets/img/icon-import.svg";
import downloadIcon from "../../../../assets/img/downloadicon.svg";
import {salesTrackerButtonProps} from "../staticData";
import CreateStatusActions from "./leadStatuses/CreateStatusActions";
import LeadGroupView from "./LeadGroupView";

const HeaderActions = (props) => {
	const {
		openFilters,
		selectedRows,
		setOpenFilters,
		exportLeads,
		filterImports,
		setShowActionsModal,
		setShowUploadModal,
		deleteLeads,
		salesTrackerState,
		setSelectedRows,
		optionsClients,
		locations,
		fetchLocations,
		usersOptions,
		teams,
		agencies,
		leadStatuses,
		query,
		setQuery,
		loggedUser,
		allSalesOrganizations,
		setSelectedStatuses,
		statuses,
	} = props;
	const {
		activeTab,
		filters,
		selectedStatuses,
		exportsLoading,
		loading,
		query: initialQuery,
		leads,
	} = salesTrackerState;
	const language = localStorage?.getItem("language");
	const getFieldByLanguage = () => {
		if (language === "en") {
			return "name";
		}
		return "nameDe";
	};
	const tenantFeatures = Storage.get("features");
	const clearQuery = () => setQuery(initialQuery);
	const {setModal, getUrlParams} = useUrlParams();
	const {viewType} = getUrlParams("view-type");
	const isMapView = viewType === "mapView";
	const handleModal = (type) => setModal(type);
	const lengthToDisplay = selectedStatuses?.ids?.[0]
		? selectedStatuses.totalSize +
		  selectedRows?.filter((row) => !selectedStatuses?.ids?.includes(row.statusId))?.length
		: selectedRows.length;

	const {t} = useTranslation();
	const [showPopover, setShowPopover] = useState(false);
	const currentUser = getCurrentUser();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const handleVisibility = (visibility) => setShowPopover(visibility);
	const items = [
		{
			key: "menu",
			label: <CreateStatusActions handleVisibility={handleVisibility} />,
		},
	];
	const handleConfirm = () => {
		deleteLeads({
			ids: selectedRows,
			currentUser,
			query,
		});
		setShowConfirmModal(false);
		setSelectedRows([]);
	};
	const filterItems = [
		{
			inputType: "antd-dropdown",
			label: t("ChooseClient"),
			name: "name",
			field: "clientId",
			isMultiple: false,
			options: optionsClients.filter((item) => item.leads) || [],
			dependentField: "projectId",
			sagaFunction: fetchLocations,
			show: true,
			value: "value",
			key: "name",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			field: "projectId",
			name: "label",
			isMultiple: false,
			dependendFrom: "clientId",
			options: getSelectOptionsFromDataTeams(locations, "locationName", "id"),
			label: t("Locations"),
			show: true,
			value: "value",
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "rangePicker",
			placeholder: [t("from"), t("to")],
			field: ["from", "to"],
			show: true,
		},
		{
			inputType: "antd-dropdown",
			field: "userId",
			label: t("Users"),
			options: getSelectOptionsFromDataTeams(usersOptions, "name", "id"),
			show:
				checkRoles("filterWithUser") ||
				(checkRoles("getUsersByHeadOfSalesId") && !isMapView),
			isMultiple: false,
			name: "label",
			value: "value",
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			field: "teamId",
			show: checkRoles("filterWithUser") && !isMapView,
			isMultiple: false,
			options: teams,
			label: t("chooseTeam"),
			name: "name",
			value: "id",
			key: "name",
			optionVal: "id",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			field: "agencyId",
			name: "name",
			label: t("ChooseAgency"),
			value: "teamLeaderId",
			options: agencies,
			isMultiple: false,
			show: checkRoles("filterWithUser") && !isMapView,
			key: "name",
			optionVal: "teamLeaderId",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			field: "salesOrganizationId",
			show: tenantFeatures.Sales_Organization && !isMapView,
			isMultiple: false,
			options: allSalesOrganizations,
			label: t("SalesOrganizations"),
			name: "name",
			value: "id",
			key: "name",
			optionVal: "id",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			field: "statuses",
			value: "id",
			name: getFieldByLanguage(),
			isMultiple: true,
			options: leadStatuses,
			show: !isMapView,
			label: t("ChooseStatus"),
			key: "statusName",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			field: "leadImportStatus",
			value: "id",
			name: "name",
			isMultiple: false,
			options: [
				{name: t("StatusOne"), id: "STATUS_ONE"},
				{name: t("StatusTwo"), id: "STATUS_TWO"},
			],
			show: !isMapView,
			label: t("ChooseImportStatus"),
			key: "name",
			optionVal: "id",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			field: "statusesIds",
			value: "id",
			name: getFieldByLanguage(),
			isMultiple: true,
			options: statuses,
			show: isMapView,
			label: t("ChooseStatus"),
			key: "statusName",
			optionVal: "value",
			optionLabel: "name",
		},
	];
	const handleSelectAllStatuses = () => {
		const filterdStatuses = statuses?.filter(
			(i) => leads[i?.[getFieldByLanguage()]]?.totalSize > 0,
		);
		const totalSize = statuses
			?.map((i) => leads[i?.[getFieldByLanguage()]]?.totalSize || 0)
			.reduce((acc, size) => acc + size, 0);
		setSelectedRows(
			filterdStatuses?.flatMap((filt) => leads[filt?.[getFieldByLanguage()]]?.data || []),
		);
		setSelectedStatuses({ids: filterdStatuses?.map((it) => it?.id), totalSize});
	};
	return activeTab === t("SalesTracker") ? (
		<>
			<DeleteModal
				open={showConfirmModal}
				setShowDeleteModal={setShowConfirmModal}
				handleClose={() => setShowConfirmModal(false)}
				confirm={handleConfirm}
				disabled={loading}
			/>
			<LeadGroupView />
			{checkRoles("createStatus") && (
				<HtmlTooltip title={<span>{t("CreateStatus")}</span>} placement="bottom">
					<div>
						<Button
							{...salesTrackerButtonProps}
							icon={iconStatus}
							leftRounded={false}
							rightRounded={false}
							smallButtonWithTooltip
							hideTooltip={true}
							handleChange={() => handleModal("status")}
						/>
					</div>
				</HtmlTooltip>
			)}
			<HtmlTooltip title={<span>{t("AddACard")}</span>} placement="bottom">
				<div>
					<Button
						{...salesTrackerButtonProps}
						icon={leadsIcon}
						leftRounded={false}
						rightRounded={false}
						hideTooltip={true}
						smallButtonWithTooltip
						handleChange={() => handleModal("lead")}
					/>
				</div>
			</HtmlTooltip>
			{checkRoles("createLeadStatus") && (
				<CustomPopover
					customClass="custom-popover-leadstatuses"
					menu={{items}}
					open={showPopover}
					handleVisibility={handleVisibility}
					placement="bottomRight"
				>
					<div>
						<Button
							{...salesTrackerButtonProps}
							icon={plusLogo}
							leftRounded={false}
							rightRounded={false}
							hideTooltip
							smallButtonWithTooltip
						/>
					</div>
				</CustomPopover>
			)}
			<BigSearchBar
				value={query?.search}
				handleSearch={(e) => setQuery({...query, search: e.target.value})}
				withFilters
				openFilters={openFilters}
				customClass="sales_tracker-searchBar"
				filterItems={filterItems}
				setFilterValues={setQuery}
				filterValues={query}
				headerSearch
				displaySearch={!isMapView}
				clearFilterValues={clearQuery}
				handleFilters={() => {
					if (openFilters) {
						clearQuery();
					}
					setOpenFilters(!openFilters);
				}}
			/>
			{!isMapView && (
				<div className="createuserExport" onClick={() => exportLeads(query)}>
					{!exportsLoading ? (
						<HtmlTooltip placement="top" title={<div>{t("Download")}</div>}>
							<img className="download_button" alt="download" src={downloadIcon} />
						</HtmlTooltip>
					) : (
						<Loader newClass="createuserExport_loader" />
					)}
				</div>
			)}
			{checkRoles("deleteLeads") && !isMapView ? (
				<LeadUserActionsPopover
					setShowActionsModal={setShowActionsModal}
					query={query}
					selectedRows={selectedRows}
					setShowConfirmModal={setShowConfirmModal}
					loggedUser={loggedUser}
					selectAllStatuses={handleSelectAllStatuses}
					selectedStatuses={selectedStatuses}
				>
					<div className="userActions-popper">
						{lengthToDisplay > 0 && (
							<span className="userActions-popper-length">
								{lengthToDisplay > 99 ? `99+` : lengthToDisplay}
							</span>
						)}
						<OpenActionsIcon />
					</div>
				</LeadUserActionsPopover>
			) : (
				""
			)}
		</>
	) : (
		<>
			<BigSearchBar
				value={filters?.search}
				handleSearch={(e) => {
					filterImports({name: "search", value: e.target.value});
				}}
			/>

			{activeTab === t("Import") && (
				<Button
					background="linear-gradient(94.98deg, rgb(108, 30, 176) 0%, rgb(64, 0, 119) 100%)"
					leftRounded={true}
					text={t("Import")}
					icon={importIcon}
					handleChange={() => setShowUploadModal(true)}
					smallButtonWithTooltip
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	salesTrackerState: state.app.salesTracker.index,
	statuses: state.app.salesTracker.statuses.statuses,
	optionsClients: state.app.client.index.optionsClients,
	locations: state.app.locations.index.allLocations,
	usersOptions: state.app.users.index.usersOptions,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	leadStatuses: state.app.salesTracker.leadStatus.leadStatuses,
	loggedUser: state.app.users.index.loggedUser,
	allSalesOrganizations: state.app.salesOrganizations.index.allSalesOrganizations,
});
const mapDispatchToProps = {
	exportLeads: salesTrackerActions.exportLeads,
	filterImports: importActions.filterImports,
	setStatusModal: statusesActions.setStatusModal,
	clearStatus: statusesActions.clearStatus,
	deleteLeads: deleteLeadActions.deleteLeads,
	fetchLocations: locationActions.fetchAllLocations,
	setSelectedStatuses: salesTrackerActions.setSelectedStatuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderActions);
