import React from "react";
import {EuroSign} from "@src/assets/SvgComponents";
import {FORMAT_DATE_MONTH_NAME, FORMAT_DATE_YEAR} from "@src/common/constants";
import i18n from "@src/i18n";
import {formatNumber} from "@src/utils/convertNumber";

export const columns = [
	{
		key: "year",
		name: i18n.t("year"),
		formatter: ({row}) => FORMAT_DATE_YEAR(row.year),
		printFormatter: ({row}) => FORMAT_DATE_YEAR(row.year),
		editable: false,
		cellClass: "gray-cell-class",
	},
	{
		key: "month",
		name: i18n.t("month"),
		formatter: ({row}) => FORMAT_DATE_MONTH_NAME(row.month),
		printFormatter: ({row}) => FORMAT_DATE_MONTH_NAME(row.month),
		editable: false,
		cellClass: "gray-cell-class",
	},
	{
		key: "receiveCommisionStatistics",
		name: i18n.t("revenueComissions"),
		formatter: ({row}) => <span>{`${formatNumber(row?.receiveCommisionStatistics)}`} €</span>,
		printFormatter: ({row}) => (
			<span>{`${formatNumber(row?.receiveCommisionStatistics)}`} €</span>
		),

		editable: false,
		cellClass: "gray-cell-class",
	},
	{
		key: "additionalRewards",
		name: i18n.t("additionalRewards"),
		editable: true,
		formatter: ({row}) => <span>{`${formatNumber(row?.additionalRewards)}`} €</span>,
		printFormatter: ({row}) => <span>{`${formatNumber(row?.additionalRewards)}`} €</span>,
	},
	{
		key: "total",
		name: i18n.t("totalPerMonth"),
		editable: false,
		cellClass: "gray-cell-class",
		formatter: ({row}) => <span>{`${formatNumber(row?.total)}`} €</span>,
		printFormatter: ({row}) => <span>{`${formatNumber(row?.total)}`} €</span>,
	},
];
