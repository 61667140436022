import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {changeOrderFn} from "@utils/functions";
import {actions as deleteLocationActions} from "@sagas/locations/delete";
import {ENDPOINT} from "@src/utils/endpoint";

const logger = new Logger("teams client");
const PREFIX = "@Dixi App/store/sagas/app/teams/";
// clients
export const FETCH_LOCATIONS = `${PREFIX}FETCH_LOCATIONS`;
export const FETCH_LOCATIONS_SUCCESS = `${PREFIX}FETCH_LOCATIONS_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const ADD_LOCATION_ON_STATE = `${PREFIX}ADD_LOCATION_ON_STATE`;
export const EDIT_LOCATION = `${PREFIX}EDIT_LOCATION`;
export const DELETE_LOCATION = `${PREFIX}DELETE_LOCATION`;
export const DEACTIVATE_LOCATION_ON_STATE = `${PREFIX}DEACTIVATE_LOCATION_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const TOTAL_SIZE_DECREASE = `${PREFIX}TOTAL_SIZE_DECREASE`;
export const SET_LOCATION_ID = `${PREFIX}SET_LOCATION_ID`;
export const CHANGE_LOCATION_ORDER = `${PREFIX}CHANGE_LOCATION_ORDER`;
// eslint-disable-next-line max-len
export const CHANGE_LOCATION_ORDER_SUCCESS = `${PREFIX}CHANGE_LOCATION_ORDER_SUCCESS`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const TOGGLE_LOCATION_VISIBILITY = `${PREFIX}TOGGLE_LOCATION_VISIBILITY`;
export const TOGGLE_LOCATION_VISIBILITY_SUCCESS = `${PREFIX}TOGGLE_LOCATION_VISIBILITY_SUCCESS`;
export const FETCH_ALL_LOCATIONS = `${PREFIX}FETCH_ALL_LOCATIONS`;
export const FETCH_ALL_LOCATIONS_SUCCESS = `${PREFIX}FETCH_ALL_LOCATIONS_SUCCESS`;
export const FETCH_LOCATION_BY_ID = `${PREFIX}FETCH_LOCATION_BY_ID`;
export const FETCH_LOCATION_BY_ID_SUCCESS = `${PREFIX}FETCH_LOCATION_BY_ID_SUCCESS`;

const _state = {
	locations: [],
	isLoading: false,
	page: 1,
	size: 30,
	totalSize: 5,
	search: "",
	locationId: [],
	totalPages: 1,
	allLocations: [],
	singleLocation: {},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_LOCATIONS_SUCCESS:
				draft.locations = state?.page > 1 ? [...state?.locations, ...payload] : payload;
				break;
			case ADD_LOCATION_ON_STATE:
				draft.locations = [payload].concat(state.locations);
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case EDIT_LOCATION:
				draft.locations = state.locations.map((e) =>
					e.id === payload.id ? {...e, ...payload} : e,
				);
				break;
			case DEACTIVATE_LOCATION_ON_STATE:
				const locations = state.locations.filter((e) => e.id !== payload);
				if (state.page !== 1 && locations.length === 0) {
					draft.page = 1;
				} else {
					draft.locations = locations;
				}
				break;
			case DELETE_LOCATION:
				draft.locations = state.locations.filter((e) => e.id !== payload);
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			case SET_LOCATION_ID:
				const [arr] = payload[0];
				draft.locationId = arr;
				break;
			case CHANGE_LOCATION_ORDER_SUCCESS:
				const itemIndex = state.locations.findIndex((item) => item?.id === payload?.id);
				const newValue = changeOrderFn(state?.locations, itemIndex, payload?.action);
				draft.locations = newValue;
				break;
			case LOADING:
				draft.isLoading = payload;
				break;
			case TOGGLE_LOCATION_VISIBILITY_SUCCESS:
				draft.locations = state?.locations?.map((item) =>
					item.id === payload ? {...item, isHidden: !item.isHidden} : item,
				);
				break;
			case FETCH_ALL_LOCATIONS_SUCCESS:
				draft.allLocations = payload?.map((itm) => ({
					...itm,
					value: itm.id,
					label: itm.locationName,
				}));
				break;
			case FETCH_LOCATION_BY_ID_SUCCESS:
				draft.singleLocation = payload;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchLocations: (payload) => createAction(FETCH_LOCATIONS, {payload}),
	fetchLocationsSuccess: (payload) => createAction(FETCH_LOCATIONS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	addLocationOnState: (payload) => createAction(ADD_LOCATION_ON_STATE, {payload}),
	editLocation: (payload) => createAction(EDIT_LOCATION, {payload}),
	deleteLocation: (payload) => createAction(DELETE_LOCATION, {payload}),
	deactivateLocationOnState: (payload) => createAction(DEACTIVATE_LOCATION_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterLocations: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	totalSizeDecrease: (payload) => createAction(TOTAL_SIZE_DECREASE, {payload}),
	setLocationId: (payload) => createAction(SET_LOCATION_ID, {payload}),
	changeLocationOrder: (payload) => createAction(CHANGE_LOCATION_ORDER, {payload}),
	changeLocationOrderSuccess: (payload) => createAction(CHANGE_LOCATION_ORDER_SUCCESS, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	toggleLocationVisibility: (payload) => createAction(TOGGLE_LOCATION_VISIBILITY, {payload}),
	toggleLocationVisibilitySuccess: (payload) =>
		createAction(TOGGLE_LOCATION_VISIBILITY_SUCCESS, {payload}),
	fetchAllLocations: (payload) => createAction(FETCH_ALL_LOCATIONS, {payload}),
	fetchAllLocationsSuccess: (payload) => createAction(FETCH_ALL_LOCATIONS_SUCCESS, {payload}),
	fetchLocationById: (payload) => createAction(FETCH_LOCATION_BY_ID, {payload}),
	fetchLocationByIdSuccess: (payload) => createAction(FETCH_LOCATION_BY_ID_SUCCESS, {payload}),
};

export const sagas = {
	*fetchLocations({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size, page} = yield select((state) => state.app.locations.index);
			const searchType = typeof payload?.search;
			const search = searchType === "string" ? payload?.search : "";
			const response = yield axios.get(
				`/location/all/?page=${page}&size=${size}&search=${search}&clientId=${
					payload?.clientId || payload
				}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchLocationsSuccess(response?.data?.data.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchAllLocations({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/location/all/${payload}`);
			yield put(actions.fetchAllLocationsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeLocationOrder({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put("/location/change", {
				id: payload?.id,
				newRow: payload?.newRow,
			});
			yield put(actions.changeLocationOrderSuccess(payload));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*toggleLocationVisibility({payload}) {
		yield put(deleteLocationActions.setLoading(true));
		try {
			const {id, closeModal, isHidden} = payload;
			yield axios.post(ENDPOINT.TOGGLE_LOCATION, {
				id,
				isHidden,
			});
			yield put(actions.toggleLocationVisibilitySuccess(payload.id));
			closeModal();
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(deleteLocationActions.setLoading(false));
		}
	},
	*fetchLocationById({payload}) {
		try {
			const response = yield axios.get(`/location/${payload}`);
			yield put(actions.fetchLocationByIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_LOCATIONS, sagas.fetchLocations);
	yield takeLatest(CHANGE_LOCATION_ORDER, sagas.changeLocationOrder);
	yield takeLatest(TOGGLE_LOCATION_VISIBILITY, sagas.toggleLocationVisibility);
	yield takeLatest(FETCH_ALL_LOCATIONS, sagas.fetchAllLocations);
	yield takeLatest(FETCH_LOCATION_BY_ID, sagas.fetchLocationById);
};
