import {ReplyMessageIcon, CopyIcon, DeleteRowIcon} from "@src/assets/SvgComponents";
import React from "react";
import i18n from "@src/i18n";
const baseIconProps = {
	width: "16",
	height: "14",
};

export const items = [
	{
		key: "reply",
		label: i18n.t("reply"),
		icon: <ReplyMessageIcon />,
	},
	{
		key: "copy",
		label: i18n.t("copy"),
		icon: <CopyIcon stroke="#252525" {...baseIconProps} />,
	},
	{
		key: "delete",
		label: i18n.t("delete"),
		icon: <DeleteRowIcon stroke="#D00A12" {...baseIconProps} />,
		className: "delete-message",
	},
];

export const isHyperLink = (text) => {
	const linkRegex = /\b(?:https?:\/\/|www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?\b/i;
	return linkRegex.test(text);
};
