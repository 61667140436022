import React, {useEffect, useRef, useState} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import InputComponent from "@common/input";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as createTrainingActions} from "@sagas/trainings/create";
import {actions as navigateActions} from "@sagas/navigation";
import _lodash from "lodash";
import NavigationPrompt from "react-router-navigation-prompt";
import {actions as trainingActions} from "@sagas/trainings";
import {useTranslation} from "react-i18next";
import AddTopContent from "./TopContent";
import AddPage from "./Pages";
import DraftText from "./DraftText";
import LeavePageAlert from "./LeavePageAlert";
import UploadComponentView from "./UploadComponent/UploadComponent";
// import i18n from "../../../i18n";

const AddTraining = (props) => {
	const {
		navigate,
		submitPages,
		selectPage,
		addPage,
		setValueOnChange,
		pages,
		activePage,
		setActivePage,
		match,
		getSingleTemplate,
		templateSaved,
		getCategories,
		categoriesOptions,
		deletePage,
		categoryValue,
		setCategoryValue,
		trainingImg,
		loading,
		getCategoryById,
		subCategoryValue,
		setSubCategoryValue,
		filters,
		clearFilterValues,
	} = props;
	const formikRef = useRef();

	const {t} = useTranslation();
	const validationSchema = yup.object().shape({
		title: yup.string().trim().label("Title").required(t("TitleIsReq")),

		content: yup
			.object()
			.test("has text", t("CannotSave"), (value) => value?.getCurrentContent().hasText()),
		photo: yup.mixed().required("This field is required").nullable(),
	});

	const [categories, setCategories] = useState([]);
	const [canNav, setCanNav] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [selectedFileName, setSelectedFileName] = useState(null);
	useEffect(
		() => () => {
			clearFilterValues();
		},
		[],
	);

	useEffect(() => {
		setCanNav(true);
		if (match.params.id) {
			getSingleTemplate(match.params.id);
			setCanNav(false);
		}
		getCategories();
		// eslint-disable-next-line
  }, [match.params.id])

	useEffect(() => {
		setSelectedFileName(trainingImg);
	}, [trainingImg]);

	const handleChangeFn = (value, name, setFieldValue) => {
		setValueOnChange({name, value});
		setFieldValue(name, value);
	};

	const handleAddPage = (values, resetForm) => {
		const {validateForm, errors, setTouched} = formikRef.current;
		validateForm();
		if (_lodash.isEmpty(errors)) {
			addPage(values);
			setActivePage(pages.length + 1);
			resetForm({title: "", content: "", photo: values?.photo || trainingImg});
		} else {
			setTouched({title: true, content: true, photo: true});
		}
	};

	const handleSelectPage = (page) => {
		const {errors, setTouched} = formikRef.current;

		if (_lodash.isEmpty(errors)) {
			setActivePage(page);
			selectPage(page);
		} else {
			setTouched({title: true, content: true});
		}
	};

	const navigateBack = () => {
		clearFilterValues();
		navigate("/admin/trainings");
	};

	const handleFileRead = async (event, setFieldValue) => {
		const file = event;
		const base64 = await convertBase64(file);
		setSelectedFileName(event?.name);
		setSelectedFile(base64);
		setValueOnChange({name: "photo", value: file});
		setFieldValue("photo", file);
	};
	const convertBase64 = (file) =>
		new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	const handleSubmitForm = ({values: pages, status, formActions: actions}) => {
		setCanNav(false);
		submitPages({
			values: pages,
			formActions: actions,
			id: match.params.id,
			published: pages?.[0].published,
			image: selectedFile,
			category: filters?.category.value,
			subCategory: subCategoryValue.value,
			status,
		});
	};

	// const handleFileUpload = (event) => {
	// 	const fileList = event.target.files;
	// 	if (files.length + fileList.length > 5) {
	// 		ToastInfoComponent(i18n.t("UploadLengthInfo"));
	// 		event.preventDefault();
	// 		return;
	// 	}
	// 	const fileDataArray = Array.from(fileList).map(
	// 		(file) =>
	// 			new Promise((resolve, reject) => {
	// 				const reader = new FileReader();
	// 				reader.readAsDataURL(file);
	// 				reader.onloadend = () => {
	// 					resolve({
	// 						filename: file.name,
	// 						base64: reader.result.split(",")[1],
	// 						type: file.type,
	// 						extension: file.name.split(".").pop()?.toLowerCase(),
	// 						size: file.size,
	// 					});
	// 				};
	// 				reader.onerror = () => {
	// 					reader.abort();
	// 					reject(new DOMException("Problem parsing input file."));
	// 				};
	// 			}),
	// 	);
	// 	Promise.all(fileDataArray).then(uploadFile);
	// };

	const handleCategoryChange = (item) => {
		setCategoryValue(item.value);
		getCategoryById(item.value);
	};

	return (
		<Formik
			innerRef={formikRef}
			enableReinitialize
			validateOnMount
			initialValues={
				match.params.id
					? {...pages[activePage - 1], status: "add", photo: trainingImg}
					: {...pages[activePage - 1], status: "add", photo: selectedFile || null}
			}
			onSubmit={(values, actions) =>
				handleSubmitForm({
					values: pages,
					status: values?.status,
					formActions: actions,
				})
			}
			validationSchema={validationSchema}
		>
			{({values, errors, touched, handleBlur, handleSubmit, setFieldValue, resetForm}) => (
				<>
					<div className="addTraining">
						<div>
							<form className="addTraining__form">
								<AddTopContent
									setdropDownValue={handleCategoryChange}
									setSubCategoryValue={setSubCategoryValue}
									subCategoryValue={subCategoryValue}
									dropDownValue={categoryValue}
									categories={categories}
									setCategories={setCategories}
									handleSubmit={handleSubmit}
									navigateBack={navigateBack}
									templateSaved={templateSaved}
									handleFileRead={handleFileRead}
									selectedFileName={selectedFileName}
									categoriesOptions={categoriesOptions}
									published={pages?.[0].published}
									pages={pages}
									paramId={match?.params?.id}
									setFieldValue={setFieldValue}
									loading={loading}
									setCategoryValue={setCategoryValue}
									errors={errors}
									touched={touched}
								/>
								<AddPage
									pages={pages}
									handleAddPage={() => handleAddPage(values, resetForm)}
									activePage={activePage}
									handleSelectPage={handleSelectPage}
									deletePage={deletePage}
								/>
								{match?.params?.id && <UploadComponentView />}
								<div className="addTraining__form-content">
									<InputComponent
										name="title"
										placeholder="Title"
										label={t("AddTitle")}
										type="text"
										errorClass="errorClass"
										errors={errors?.title}
										values={values?.title || ""}
										handleBlur={handleBlur}
										handleChange={(e) => {
											handleChangeFn(e.target.value, "title", setFieldValue);
											setCanNav(true);
										}}
										touched={touched.title}
										// newClass="addTraining__form-input"
										className="addTraining__form-input"
									/>
									<DraftText
										value={values?.content}
										onEditorStateChange={(editorState, props) => {
											handleChangeFn(editorState, "content", setFieldValue);
											setCanNav(true);
										}}
										errorClass="errorClass"
										errors={errors.content}
										touched={touched.content}
									/>
								</div>
							</form>
						</div>
					</div>
					<NavigationPrompt
						renderIfNotActive={true}
						when={
							canNav &&
							(pages[0]?.title?.trim() !== "" ||
								pages[0]?.content?.getCurrentContent().hasText())
						}
					>
						{({isActive, onCancel, onConfirm}) =>
							isActive && (
								<LeavePageAlert
									open={true}
									handleClose={onCancel}
									handleAgree={onConfirm}
								/>
							)
						}
					</NavigationPrompt>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state) => ({
	pages: state.app.trainings.create.pages,
	trainingImg: state.app.trainings.create.trainingImg,
	categoryValue: state.app.trainings.create.categoryValue,
	subCategoryValue: state.app.trainings.create.subCategoryValue,
	activePage: state.app.trainings.create.activePage,
	templateSaved: state.app.trainings.create.templateSaved,
	categoriesOptions: state.app.trainings.index.categories,
	loading: state.app.trainings.create.loading,
	filters: state.app.trainings.index.filters,
});
const mapDispatchToProps = {
	submitPages: createTrainingActions.addPages,
	getSingleTemplate: createTrainingActions.getSingleTemplate,
	setValueOnChange: createTrainingActions.setValueOnChange,
	setActivePage: createTrainingActions.setActivePage,
	deletePage: createTrainingActions.deletePage,
	selectPage: createTrainingActions.selectPage,
	addPage: createTrainingActions.addPage,
	setCategoryValue: createTrainingActions.setCategoryValue,
	setSubCategoryValue: createTrainingActions.setSubCategoryValue,
	navigate: navigateActions.navigate,
	getCategories: trainingActions.getCategories,
	getCategoryById: trainingActions.getCategoryById,
	clearFilterValues: trainingActions.clearFilterValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddTraining));
