import {ArrowDownIcon, RightIcon} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import React from "react";
import Lottie from "react-lottie";
import loadingLottie from "../../assets/loadingLottie2.json";

export const renderOpenedHeader = (item, activeKey, index, title, subTitle) => (
	<div className={`header-wrapper ${activeKey === index ? "opened" : ""}`}>
		<div className="color" />
		<div className="header-wrapper-nameSection">
			<div className="header-wrapper-nameSection-container">
				<div className="header-wrapper-nameSectionLeft">
					<ArrowDownIcon width={15} height={15} fill={item?.color} />
				</div>
				<div
					className="header-wrapper-nameSectionRight"
					style={{flexDirection: subTitle ? "row" : "column"}}
				>
					<span style={{color: item?.color}} className="header-wrapper-nameSection-name">
						{i18n.t(title)}
					</span>
					<span
						style={{color: item?.color}}
						className="header-wrapper-nameSection-subTitle"
					>
						{subTitle}
					</span>
				</div>
			</div>
		</div>
	</div>
);
export const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingLottie,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
export const renderClosedHeader = (
	item,
	borderLess,
	title,
	mainTitle,
	columns,
	groupViewType,
	name,
	subTitle,
) => (
	<div className="header-wrapper">
		<div style={{background: item?.color}} className={`color ${borderLess && "hide"}`} />
		<div className="header-wrapper-nameSection">
			<div className="header-wrapper-nameSection-container">
				<div className="header-wrapper-nameSectionLeft">
					<RightIcon
						width={22}
						height={22}
						stroke={
							item?.[title] > 0 || item?.[title] === "counting"
								? item?.color
								: "#f9f9f9"
						}
					/>
				</div>
				<div className="header-wrapper-nameSectionRight">
					<span style={{color: item?.color}} className="header-wrapper-nameSection-name">
						{i18n.t(mainTitle)}
					</span>
					{item?.[title] === "counting" ? (
						<span>
							<Lottie options={defaultOptions} height={40} width={40} />
						</span>
					) : (
						<span
							className="header-wrapper-nameSection-name"
							style={{color: subTitle && item?.color}}
						>
							{subTitle ? (
								<>{subTitle}</>
							) : (
								<>
									{item?.[title] || 0}{" "}
									{borderLess ? i18n.t("Leads") : i18n.t("Elements")}
								</>
							)}
						</span>
					)}
				</div>
			</div>
		</div>
		<div className="header-wrapper-box">
			{columns.map((i) => {
				if (i.hide !== groupViewType && i.hide !== "header") {
					return (
						<div className="header-wrapper-box-item">
							<span className="header-wrapper-box-item-title">
								{i18n.t(i?.[name])}
							</span>
							<span className="header-wrapper-box-item-content">
								{i?.headerFormatter()}
							</span>
						</div>
					);
				}
				if (i.customRender) {
					return (
						<div className="header-wrapper-box-item">
							<span className="header-wrapper-box-item-title">
								{i18n.t(i?.[name])}
							</span>
							<span className="header-wrapper-box-item-content">
								{item?.[i.field]}
							</span>
						</div>
					);
				}
				return null;
			})}
		</div>
	</div>
);
