import React from "react";
import {actions as leadStatusActions} from "@sagas/salesTracker/leadStatus";
import {connect} from "react-redux";
import Form from "@src/common/Form";
import i18n from "@src/i18n";
import {leadStatusTypes} from "../../staticData";

const CreateStatus = (props) => {
	const {showModal, setShowModal, statuses, createLeadStatus, loading, clearLeadStatus, id} =
		props;
	const handleClose = () => {
		setShowModal(false);
		clearLeadStatus();
	};
	const inputs = [
		{
			title: i18n.t("StatusName"),
			inputType: "text",
			field: "name",
			required: true,
		},
		{
			title: i18n.t("StatusNameDe"),
			inputType: "text",
			field: "nameDe",
			required: true,
		},
		{
			title: i18n.t("ChooseStatus"),
			inputType: "dropdown",
			options: statuses?.filter((status) => status?.statusType !== "SUCCESS"),
			field: "statusId",
			required: true,
		},
		{
			title: i18n.t("ChooseLeadStatusType"),
			inputType: "dropdown",
			options: leadStatusTypes,
			field: "leadStatusType",
			required: true,
		},
	];
	const formData = {
		inputs,
		id,
		title: id ? i18n.t("EditLeadStatus") : i18n.t("CreateLeadStatus"),
		submitFn: (values) =>
			createLeadStatus({values: {...values, leadStatusId: values?.id}, handleClose}),
		getUrl: "/sales_tracker/lead_status/:id",
	};
	return (
		<Form open={showModal} formData={formData} handleClose={handleClose} loading={loading} />
	);
};

const mapStateToProps = (state) => ({
	statuses: state.app.salesTracker.statuses.statuses,
	leadStatusValues: state.app.salesTracker.leadStatus.leadStatus,
	loading: state.app.salesTracker.leadStatus.loading,
});
const mapDispatchToProps = {
	createLeadStatus: leadStatusActions.createLeadStatus,
	clearLeadStatus: leadStatusActions.clearLeadStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStatus);
