import produce from "immer";
import createAction from "../../../../utils/action-creator";

const PREFIX = "@app/drawer/Index";
export const SET_DRAWER = `${PREFIX}SET_DRAWER`;
export const CHANGE_DRAWER_VALUE = `${PREFIX}CHANGE_DRAWER_VALUE`;

const _state = {
	activeDrawer: "dashboard",
	showDrawer: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_DRAWER:
				draft.activeDrawer = action.payload;
				break;
			case CHANGE_DRAWER_VALUE:
				draft.showDrawer = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setDrawer: (payload) => createAction(SET_DRAWER, {payload}),
	changeDrawer: (payload) => createAction(CHANGE_DRAWER_VALUE, {payload}),
};

export const sagas = {};

// eslint-disable-next-line no-empty-function
export const watcher = function* w() {};
