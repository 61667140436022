import * as yup from "yup";
import i18n from "@src/i18n";

export const emailSchema = yup.object().shape({
	clientSubject: yup.string().required(i18n.t("SubjectIsReq")).nullable(),
	clientDescription: yup.string().required(i18n.t("DescriptionIsReq")).nullable(),
});

export const serverSchema = yup.object().shape({
	clientUsername: yup.string().required(i18n.t("NameIsReqired")).nullable(),
	clientPassword: yup.string().required(i18n.t("PasswordRequired")).nullable(),
	clientUrl: yup.string().required(i18n.t("URLReq")).nullable(),
});

export const pdfDeleteSchema = yup.object().shape({
	date: yup.number().required(i18n.t("NumOfDaysReq")).min(0),
	systemStatusView: yup.object().required(i18n.t("StatusReq")),
});

// this function serves to divide fields based on their selection status
export const filterFieldsBasedOnSelection = (state, fields) => ({
	selected: state.filter((item) => fields?.includes(item.name)),
	unselected: state.filter((item) => !fields?.includes(item.name)),
});

export const serverFields = [
	{
		key: "clientUsername",
		label: i18n.t("ClientUserName"),
		inputType: "text",
	},
	{
		key: "clientPassword",
		label: i18n.t("ClientPassword"),
		inputType: "password",
	},
	{
		key: "clientUrl",
		label: i18n.t("ClientURL"),
		inputType: "text",
	},
	{
		key: "port",
		label: i18n.t("ClientPort"),
		disabled: true,
		inputType: "text",
	},
	{
		key: "isConnected",
		label: i18n.t("Connect"),
		inputType: "switch",
	},
];
