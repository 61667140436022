import {useUrlParams} from "@src/utils/useUrlParams";
import React from "react";
import {useTranslation} from "react-i18next";
import {actions as orderStatusAction} from "@sagas/orders/statuses";
import {ENDPOINT} from "@src/utils/endpoint";
import Form from "@src/common/Form";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {statusInputs} from "./staticData";

const EditAndCreateOrderStatus = ({createStatus, editStatus, orderStatusLoading}) => {
	const {t} = useTranslation();
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id, status} = getUrlParams("modal", "id", "status");
	const formData = {
		inputs: statusInputs(id, status),
		id,
		title: id ? t("EditStatus") : t("CreateStatus"),
		submitFn: (values) =>
			id ? editStatus({values, setModal}) : createStatus({values, setModal}),
		getUrl: ENDPOINT.GET_BY_ID_STATUS.replace(":id", id),
	};
	return (
		<Form
			open={modal === "orderStatuses"}
			handleClose={() => setModal(false)}
			formData={formData}
			loading={orderStatusLoading}
		/>
	);
};

const mapStateToProps = (state) => ({
	orderStatusLoading: state.app.orders.statuses.loading,
});

const mapDispatchToProps = {
	createStatus: orderStatusAction.createStatus,
	editStatus: orderStatusAction.editStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditAndCreateOrderStatus));
