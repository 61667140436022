import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";

const CheckBoxComponent = (props) => {
	const {
		label,
		touched,
		errors,
		errorClass = "errorClass",
		withTouched = true,
		noTouched = false,
		name,
		color = "primary",
		placeholder,
		handleChange,
		checked,
		disabled,
	} = props;
	return (
		<div>
			<FormControlLabel
				disabled={disabled}
				control={
					<Checkbox
						type="checkbox"
						name={name}
						color={color}
						placeholder={placeholder}
						onClick={handleChange}
						checked={checked}
					/>
				}
				label={label}
			/>
			{withTouched && touched && errors && (
				<span className={errorClass}>{touched && errors}</span>
			)}
			{noTouched && errors && <span className={errorClass}>{errors}</span>}
		</div>
	);
};

export default CheckBoxComponent;
