import React from "react";
import {HeadOfDepartmentIcon} from "@src/assets/SvgComponents";

export const userRoleData = {
	SP: {icon: <HeadOfDepartmentIcon />, color: "#68ACE8"},
	EMPLOYEE_AB: {icon: <HeadOfDepartmentIcon />, color: "#6DB070"},
	EMPLOYEE: {icon: <HeadOfDepartmentIcon />, color: "#9A729E"},
	AGENCY: {icon: <HeadOfDepartmentIcon />, color: "#5b6bc6"},
	"AGENCY-SP": {icon: <HeadOfDepartmentIcon />, color: "#32b2a6"},
	EMPLOYEE_SHOP: {icon: <HeadOfDepartmentIcon />, color: "#32b2a6"},
	EMPLOYEE_TL: {icon: <HeadOfDepartmentIcon />, color: "#32b2a6"},
};
