import {DatePicker} from "antd";
import React from "react";
import locale from "date-fns/locale/en-GB";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {DATE_FORMAT} from "../constants";

const RangePicker = ({
	startDateProps,
	endDateProps,
	picker = "month",
	touched,
	error,
	setFieldValue,
	// added this prop (type) bc picker type "date" doesnt match moment's "startOf("day") attribute.
	type = "month",
	disableOldDates = false,
	customDisabledStartDate,
	customDisabledEndDate,
	innerModal = false,
}) => {
	const {t} = useTranslation();
	const disabledEndDate = (current) => {
		if (!startDateProps.value) {
			// No startDate selected yet, disable all times
			return true;
		}
		return current && moment(current).valueOf() < moment(startDateProps.value).valueOf();
	};
	const disabledStartDate = (current) =>
		disableOldDates && current && moment(current).isBefore(moment().startOf("day"));
	return (
		<div className="range__picker-wrapper">
			<div className="range__picker-wrapper-container">
				<DatePicker
					disabled={startDateProps.disabled}
					locale={locale}
					picker={picker}
					placeholder={startDateProps.label || t("StartDate")}
					disabledDate={customDisabledStartDate || disabledStartDate}
					value={
						startDateProps.value
							? moment(startDateProps.value).utcOffset(0, true)
							: null
					}
					format={DATE_FORMAT}
					onChange={(value) => {
						const newValue = value
							? moment(value).startOf(type).utcOffset(0, true).valueOf()
							: null;
						setFieldValue(startDateProps.field, newValue);
						if (!innerModal) {
							setFieldValue(endDateProps?.field, null);
						}
					}}
				/>
				<DatePicker
					disabled={endDateProps.disabled}
					locale={locale}
					picker={picker}
					value={endDateProps.value ? moment(endDateProps.value).utc() : null}
					placeholder={endDateProps.label || t("EndDate")}
					disabledDate={customDisabledEndDate || disabledEndDate}
					format={DATE_FORMAT}
					onChange={(value) => {
						const newValue = value ? moment(value).utc().endOf(type).valueOf() : null;
						setFieldValue(endDateProps.field, newValue);
					}}
				/>
			</div>
			{touched && error && <span className="errorClass">{error}</span>}
		</div>
	);
};

export default RangePicker;
