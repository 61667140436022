import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {ToggleAccordionIcon2} from "@src/assets/SvgComponents";

const useStyles = makeStyles({
	paperFullWidth: {
		overflowY: "visible",
	},
	dialogContentRoot: {
		overflowY: "visible",
	},
	container: {
		maxWidth: "100%",
		overflowX: "auto",
		display: "flex",
		flexWrap: "nowrap",
	},
	autocomplete: {
		flexShrink: 0,
	},
});

export default function LimitTags(props) {
	const {
		name,
		options,
		defaultValue,
		handleChange,
		value,
		touched,
		errors,
		placeholder,
		disablePortal = false,
		withClasses = false,
		handleBlur,
		isMessage = false,
	} = props;

	const classes = useStyles();

	return (
		<>
			<div className={isMessage ? classes.container : ""}>
				<Autocomplete
					popupIcon={<ToggleAccordionIcon2 />}
					name={name}
					multiple
					id="multiple-limit-tags"
					className={isMessage ? classes.autocomplete : "autocompleteClass"}
					options={options}
					getOptionLabel={(option) => option.name}
					defaultValue={defaultValue}
					value={value}
					renderOption={(props, option) => (
						<div {...props} key={option.value}>
							{option.image ? (
								<img className="dropdown__user__imagee" src={option.image} alt="" />
							) : (
								<div className="dropdown__first__char">
									{" "}
									{option?.name.charAt(0)}
								</div>
							)}
							{option.name}
						</div>
					)}
					renderInput={(ad) => <TextField {...ad} placeholder={placeholder} />}
					onChange={handleChange}
					onBlur={handleBlur}
					classes={withClasses ? classes : null}
					disablePortal={disablePortal}
				/>
			</div>
			{touched && errors && <span className="validation-error">{touched && errors}</span>}
		</>
	);
}
