import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as teamActions} from "@sagas/teams";
import {actions as userActions} from "@sagas/users/users";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Sagas>Delete>Index");

const PREFIX = "@app/teams/delete";
export const DELETE_TEAM = `${PREFIX}DELETE_TEAM`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_DELETE_MODAL = `${PREFIX}SHOW_DELETE_MODAL`;

const _state = {
	loading: false,
	deleteShowModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_DELETE_MODAL:
				draft.deleteShowModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteTeam: (payload) => createAction(DELETE_TEAM, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowDeleteModal: (payload) => createAction(SHOW_DELETE_MODAL, {payload}),
};

export const sagas = {
	*deleteTeam({payload}) {
		yield put(actions.setLoading(true));
		const {id, setModal} = payload;
		try {
			yield axios.put(`/team/delete/${id}`);
			yield put(teamActions.deleteTeamOnState(id));
			ToastSuccesComponent(i18n.t("TeamDeletedSuccesfully"));
			yield put(actions.setShowDeleteModal(false));
			yield put(userActions.getUsersNoTeam());
			yield put(teamActions.totalSizeDecrease());
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
			setModal(false);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_TEAM, sagas.deleteTeam);
};
