import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/comission/clientCommision";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";
const logger = new Logger("Comissions create");

const PREFIX = "@app/comission/createClientCommission";
export const ADD_COMISSION = `${PREFIX}ADD_COMISSION`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_COMISSION_SUCCESS = `${PREFIX}ADD_COMISSION_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_COMMISION = `${PREFIX}FILL_COMMISION`;

const _state = {
	loading: false,
	comissionAdded: false,
	comissionInitialValues: {
		productId: "",
		tariffId: "",
		provision: "",
		cancellationReserve: "",
		cancellationReserveType: "",
		commission: "",
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_COMISSION_SUCCESS:
				draft.comissionAdded = action.payload;
				draft.showModal = false;
				break;
			case FILL_COMMISION:
				draft.comissionInitialValues = {
					...action.payload,
					cancellationReserveType: {
						name: action?.payload?.cancellationReserveType,
						value: action?.payload?.cancellationReserveType,
					},
				};
				break;
			case SHOW_MODAL:
				draft.showModal = !state.showModal;
				if (!action.payload) {
					draft.comissionInitialValues = {
						productId: "",
						tariffId: "",
						provision: "",
						cancellationReserve: "",
						cancellationReserveType: "",
						commission: "",
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addComission: (payload) => createAction(ADD_COMISSION, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addComissionSucces: (payload) => createAction(ADD_COMISSION_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillComission: (payload) => createAction(FILL_COMMISION, {payload}),
};

export const sagas = {
	*addComission({payload}) {
		yield put(actions.setLoading(true));
		const {values} = payload;

		try {
			if (values?.tableData) {
				const obj = {
					cancellationReserve: values?.cancellationReserve,
					productId: values?.productId.value
						? values?.productId.value
						: values?.productId,
					commission: values?.commission,
					provision: +values?.provision,
					tariffId: values?.tariffId.value ? +values?.tariffId.value : values?.tariffId,
					cancellationReserveType: values?.cancellationReserveType?.value
						? values?.cancellationReserveType?.value
						: values?.cancellationReserveType,
				};
				yield axios.put(`/commission/update/`, obj);

				yield put(userActions.editComissionOnState(values));
				ToastSuccesComponent(i18n.t("ComisionUpdateSuccess"));
			} else {
				const obj = {
					...values,
					productId: values?.productId?.value,
					tariffId: values?.tariffId?.value,
					cancellationReserveType: values?.cancellationReserveType?.value,
				};
				yield axios.post(`/commission/create/`, obj);

				const comissionObj = {
					...values,
					productName: values?.productId?.name,
					tariffName: values?.tariffId?.name,
				};
				yield put(userActions.addComissionOnState(comissionObj));
				yield put(userActions.totalSizeIncrease());
				ToastSuccesComponent(i18n.t("ComisionCreateSucces"));
			}
			yield put(actions.setShowModal());
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);

			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_COMISSION, sagas.addComission);
};
