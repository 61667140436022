/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {actions as clientCategory} from "@sagas/projectCategories/clientCategory";
import {actions as deletedCategoryActions} from "@sagas/projectCategories/deleted";
import {actions as deletedTariffActions} from "@sagas/tariff/deleted";
import {actions as deletedProductActions} from "@sagas/products/deleted";
import {actions as clientTariff} from "@sagas/tariff/clientTariff";
import {actions as clientProduct} from "@sagas/products/clientProduct";
import {actions as deletedLocationActions} from "@sagas/locations/deleted";
import {actions as createTariff} from "@sagas/tariff/tariffByLocations/create";
import CreateDraggableOrder from "@components/generic-pdf/CreateOrder";
import {actions as navigation} from "@sagas/navigation";
import {actions as locationActions} from "@sagas/locations";
import {useTranslation} from "react-i18next";
import PageList from "@common/PageList";
import {checkRoles} from "@utils/checkRoles";
import Button from "@common/components/button";
import BackButtonComponents from "@src/common/BackButton";
import {useUrlParams} from "@src/utils/useUrlParams";
import UploadPdf from "@src/components/generic-pdf/UploadPdf";
import Form from "@src/common/Form";
import _ from "lodash";
import {ThreeDots} from "@src/assets/SvgComponents";
import CustomPopover from "@src/common/Popover";
import {checkWidth} from "@src/utils/check-width";
import {clientData} from "./staticData";
import CategoryComp from "./components/projectCategories";
import LocationComp from "./components/locations";
import TariffComp from "./components/tariffs";
import ProductComp from "./components/products";
import SettingsComp from "./components/clientSettings";
import plusLogoColor from "../../../../assets/img/plusIconBackground.svg";
import StatisticAndDocuments from "./components/statisticAndDocuments";
import GenerateFieldsModal from "./GenerateFieldsModal";
import {tariffInputs} from "./components/tariffs/staticData";

const Client = (props) => {
	const {
		getClient,
		client,
		match,
		clearClientObj,
		navigate,
		editLocationsPage,
		editLocationsPageDeleted,
		editCategoryPage,
		editCategoryPageDeleted,
		editTariffPage,
		editTariffPageDeleted,
		editProductPage,
		editProductPageDeleted,
		fetchLocationById,
		fetchCategoryById,
		fetchTariffById,
		fetchProductById,
		addTariff,
		loading,
	} = props;
	const wrapperRef = useRef();
	const [visibleItems, setVisibleItems] = useState([]);
	const [openPopover, setOpenPopover] = useState(false);
	const handleVisibility = (visible) => setOpenPopover(visible);
	const resetPage = () => {
		clientPages[tab].resetPage();
	};
	const [generateFieldsModal, setGenerateFieldsModal] = useState({
		open: false,
		item: null,
		type: "",
	});
	useEffect(() => {
		const wrapper = wrapperRef.current;
		if (!wrapper) return;
		const resizeObserver = new ResizeObserver(() => {
			checkWidth(wrapper, setVisibleItems, clientData);
		});
		resizeObserver.observe(wrapper);
		checkWidth(wrapper, setVisibleItems, clientData);
		return () => resizeObserver.disconnect();
	}, [clientData, client?.id]);
	const closeFieldsModal = () => setGenerateFieldsModal({open: false, item: null});
	const openFieldsModal = (item, type = "product") =>
		setGenerateFieldsModal({open: true, item, type});
	const {getUrlParams, setUrlParams, setQuery, removeParams, setModal} = useUrlParams(resetPage);
	const {tab, tableTab, query, id, modal} = getUrlParams(
		"tab",
		"table-tab",
		"query",
		"id",
		"modal",
	);
	const initializeParams = () => {
		setUrlParams(
			tab || "locations",
			"tab",
			{paramValue: tableTab || "active", paramName: "table-tab"},
			{paramValue: {search: ""}, paramName: "query"},
		);
	};
	const clearQuery = () => {
		setQuery({search: ""});
	};
	const setCurrentTab = (paramValue) => {
		if (paramValue !== tableTab) {
			setUrlParams(paramValue, "table-tab");
			clearQuery();
		}
	};
	const {t} = useTranslation();
	const closeToggleModal = () => setModal(false);
	const openToggleModal = (id) => setModal("visibility", id);

	useEffect(() => {
		if (match?.params?.clientId) {
			getClient(+match?.params?.clientId);
			initializeParams();
		}
		return () => clearClientObj();
		// eslint-disable-next-line
	}, [match?.params?.clientId])
	useEffect(() => {
		if (tab === "settings") {
			getClient(+match?.params?.clientId);
		}
	}, [tab]);
	const createResetPage = (editPage, editPageDeleted) => () => {
		if (tableTab === "active") {
			editPage(1);
		} else {
			editPageDeleted(1);
		}
	};

	const clientPages = {
		locations: {
			setShowModal: setModal,
			closeToggleModal,
			openToggleModal,
			currentTab: tableTab,
			setCurrentTab,
			resetPage: createResetPage(editLocationsPage, editLocationsPageDeleted),
			getById: () => fetchLocationById(id),
		},
		category: {
			setShowModal: setModal,
			closeToggleModal,
			openToggleModal,
			currentTab: tableTab,
			setCurrentTab,
			resetPage: createResetPage(editCategoryPage, editCategoryPageDeleted),
			getById: () => fetchCategoryById(id),
		},
		tariff: {
			setShowModal: setModal,
			closeToggleModal,
			openToggleModal,
			currentTab: tableTab,
			setCurrentTab,
			resetPage: createResetPage(editTariffPage, editTariffPageDeleted),
			getById: () => fetchTariffById(id),
			openFieldsModal,
		},
		product: {
			setShowModal: setModal,
			closeToggleModal,
			openToggleModal,
			currentTab: tableTab,
			setCurrentTab,
			resetPage: createResetPage(editProductPage, editProductPageDeleted),
			getById: () => fetchProductById(id),
			openFieldsModal,
		},
		settings: {
			setShowModal: () => {},
			resetPage: () => {},
		},
	};

	const changeSearchVal = (e) => setQuery({search: e.target.value});

	const handleTab = (tab) => {
		setUrlParams(tab, "tab");
		setCurrentTab("active");
		if (tab !== "settings") {
			removeParams("current-tab", "modal", "id");
		}
	};
	useEffect(() => {
		if (tab) {
			if (modal === "visibility") {
				clientPages[tab].getById();
			}
		}
	}, [modal, id, tab]);

	const pageList = [
		{
			name: client?.stringClientId || client?.clientId,
			fn: () => null,
		},
	];
	const NON_SEARCHABLE_TABS = ["statisticAndDocuments", "uploadPdf", "orderForm"];
	const formData = {
		inputs: tariffInputs(client?.clientPayoutModel),
		title: t("AddTariff"),
		submitFn: (values, actions) =>
			addTariff({
				values: {...values, client: client?.clientId, clientId: client?.id, tables: []},
				formActions: actions,
				setModal,
			}),
	};

	if (!tab) return null;
	return (
		<>
			<GenerateFieldsModal
				open={generateFieldsModal.open}
				item={generateFieldsModal.item}
				handleClose={closeFieldsModal}
				clientId={client?.id}
				clientIdString={client?.clientId}
			/>
			<div className="clientPage">
				<div className="dashboard__container">
					<div>
						<div ref={wrapperRef} style={{padding: "0px"}} className="header-component">
							<div className="dashboard__left__text-header dashboard__left__text-header-clientList">
								<div
									style={{minWidth: "70px"}}
									className="header-component-wrapper"
								>
									<BackButtonComponents
										onClick={() => navigate("/admin/clients")}
										className="back__button_client"
									/>
									<PageList list={pageList} />
								</div>
								<div className="tab-wrapper">
									{visibleItems.map((item, index) => (
										<div
											className={`tab-wrapper-box ${
												_.differenceWith(
													clientData,
													visibleItems,
													(a, b) => a.id === b.id && a.key === b.key,
												)?.[0] && index + 1 === visibleItems?.length
													? "wrapped"
													: ""
											}`}
											key={item.key}
										>
											<div
												className={`tab ${
													tab === item.key ? "active" : ""
												}`}
												onClick={() => handleTab(item.key)}
											>
												{item.label}
											</div>
											<div
												className={`bar ${
													tab === item.key ? "active" : ""
												}`}
											></div>
										</div>
									))}
									{visibleItems?.[0] &&
										_.differenceWith(
											clientData,
											visibleItems,
											(a, b) => a.id === b.id && a.key === b.key,
										)?.[0] && (
											<CustomPopover
												menu={{
													items: _.differenceWith(
														clientData,
														visibleItems,
														(a, b) => a.id === b.id && a.key === b.key,
													).map((item) => ({
														...item,
														label: (
															<div className="border-wrapper">
																{item?.label}
																<span
																	style={{
																		backgroundColor:
																			tab === item.key
																				? "var(--color-primary)"
																				: "transparent",
																	}}
																	className="border"
																></span>
															</div>
														),
														className: `${
															tab === item.key ? "active" : ""
														}`,
													})),
													onClick: ({key}) => handleTab(key),
												}}
												trigger={["click"]}
												customClass="tab-popover"
												open={openPopover}
												placement="bottomRight"
												handleVisibility={handleVisibility}
											>
												<span onClick={(e) => e.stopPropagation()}>
													<ThreeDots stroke="var(--color-primary)" />
												</span>
											</CustomPopover>
										)}
								</div>
							</div>
							<div className="dashboard__left__text-actions">
								{tab !== "settings" && (
									<>
										{checkRoles(`add${tab}`) && (
											<>
												<Button
													leftRounded={true}
													handleChange={() =>
														clientPages[tab].setShowModal(tab)
													}
													icon={plusLogoColor}
													backgroundColor="#6C1EB0"
													text={t(
														`Add${
															tab.charAt(0).toUpperCase() +
															tab.slice(1)
														}`,
													)}
													smallButtonWithTooltip
												/>
											</>
										)}
										{!NON_SEARCHABLE_TABS.includes(tab) && (
											<BigSearchBar
												value={query?.search}
												handleSearch={changeSearchVal}
												headerSearch
											/>
										)}
									</>
								)}
							</div>
						</div>

						<div className={`clientPage__table ${tab !== "orderForm" && "pl30 pr30"}`}>
							{tab === "locations" && (
								<LocationComp modals={clientPages[tab]} search={query?.search} />
							)}
							{tab === "category" && (
								<CategoryComp
									search={query?.search}
									clientId={+match?.params?.clientId}
									modals={clientPages[tab]}
								/>
							)}
							{tab === "tariff" && (
								<TariffComp
									search={query?.search}
									clientId={+match?.params?.clientId}
									modals={clientPages[tab]}
								/>
							)}
							{tab === "product" && (
								<ProductComp
									search={query?.search}
									clientId={+match?.params?.clientId}
									modals={clientPages[tab]}
								/>
							)}
							{tab === "settings" && (
								<SettingsComp clientId={+match?.params?.clientId} />
							)}
							{tab === "statisticAndDocuments" && (
								<StatisticAndDocuments
									clientId={+match?.params?.clientId}
									navigate={navigate}
								/>
							)}
							{tab === "uploadPdf" && <UploadPdf />}
							{tab === "orderForm" && <CreateDraggableOrder />}
							<Form
								open={modal === "tariff"}
								handleClose={() => setModal(false)}
								formData={formData}
								loading={loading}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	client: state.app.client.index.client,
	activeTab: state.app.client.index.activeTab,
	loading: state.app.tariffByLocations.create.loading,
});
const mapDispatchToProps = {
	getClient: clientActions.fetchClient,
	clearClientObj: clientActions.clearClientObj,
	navigate: navigation.navigate,
	editLocationsPage: locationActions.editPage,
	editLocationsPageDeleted: deletedLocationActions.editPage,
	editCategoryPage: clientCategory.editPage,
	editCategoryPageDeleted: deletedCategoryActions.editPage,
	editTariffPage: clientTariff.editPage,
	editTariffPageDeleted: deletedTariffActions.editPage,
	editProductPage: clientProduct.editPage,
	editProductPageDeleted: deletedProductActions.editPage,
	fetchLocationById: locationActions.fetchLocationById,
	fetchCategoryById: clientCategory.fetchCategoryById,
	fetchTariffById: clientTariff.fetchTariffById,
	fetchProductById: clientProduct.fetchProductById,
	addTariff: createTariff.createTariff,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Client));
