import React from "react";
import {defaultAnimateLayoutChanges, useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {Container} from "./Container";

const animateLayoutChanges = (args) => defaultAnimateLayoutChanges({...args, wasDragging: true});

export default function DroppableContainer({
	children,
	disabled,
	id,
	items,
	style,
	section,
	...props
}) {
	const {active, attributes, isDragging, listeners, over, setNodeRef, transition, transform} =
		useSortable({
			id,
			data: {
				type: "container",
				children: items,
			},
			animateLayoutChanges,
		});
	const isOverContainer = over
		? (id === over.id && active?.data.current?.type !== "container") || items.includes(over.id)
		: false;

	return (
		<Container
			ref={disabled ? undefined : setNodeRef}
			style={{
				...style,
				transition,
				transform: CSS.Translate.toString(transform),
				opacity: isDragging ? 0.5 : undefined,
			}}
			hover={isOverContainer}
			handleProps={{
				...attributes,
				...listeners,
			}}
			disabled={disabled}
			section={section}
			{...props}
		>
			{children}
		</Container>
	);
}
