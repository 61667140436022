import React, {useState} from "react";
import {HeadSalesIcon, ThreeDotsIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import {Dropdown, Popover} from "antd";
import moment from "moment";
import Star from "@assets/img/star_black_24dp.svg";
import {useUrlParams} from "@src/utils/useUrlParams";
import {DATE_FORMAT} from "../constants";
function SelectedTeamMember({
	user,
	isHeadOfSales = false,
	onDeselect,
	onSetLead,
	renderInnerModal,
	innerModal,
	setDatesModal,
	datesModal,
	onSetTeamLead,
	modal = "salesOrg",
	onRemoveFromTeam,
	trainerExists,
}) {
	const {getUrlParams} = useUrlParams();
	const {id} = getUrlParams("id");
	const {t} = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const getRemoveLabel = () => {
		if (user?.trainer) {
			return t("RemoveAsTrainer");
		}
		if (user?.internship) {
			return t("RemoveAsIntern");
		}
		return t("Remove");
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleRemoveFromTeam = (user) => {
		setAnchorEl(null);
		onRemoveFromTeam(user);
	};
	const handleRemove = (user) => {
		setAnchorEl(null);
		onDeselect(user);
	};
	const handleSalesLead = (user) => {
		setAnchorEl(null);
		onSetLead(user);
	};
	const handleSetDates = (user) => {
		setAnchorEl(null);
		setDatesModal({open: true, obj: user, type: "dates"});
	};
	const handleTeamLead = (user) => {
		setAnchorEl(null);
		onSetTeamLead(user);
	};
	const handleTrainer = (user) => {
		setAnchorEl(null);
		if (user?.trainer) {
			// onSetTrainer(user?.id);
			setDatesModal({open: true, obj: user, type: "trainer"});
		} else {
			setDatesModal({open: true, obj: user, type: "trainer"});
		}
	};
	const handleIntern = (user) => {
		setAnchorEl(null);
		setDatesModal({open: true, obj: user, type: "intern"});
	};
	const items =
		modal === "team"
			? [
					...(!user?.trainer && !user?.internship && !id
						? [
								{
									key: "teamleader",
									label: t("MakeTeamLead"),
									onClick: () => handleTeamLead(user?.id),
								},
						  ]
						: []),
					...(!user?.teamLeader && !user?.internship
						? [
								{
									key: "trainer",
									label: user?.trainer ? t("EditTrainerDates") : t("Trainer"),
									onClick: () => handleTrainer(user),
								},
						  ]
						: []),
					...(user?.trainer && !user?.startDate
						? [
								{
									key: "SetDates",
									label: t("SetDate"),
									onClick: () => handleSetDates(user),
								},
						  ]
						: []),
					...(!user?.trainer && !user?.teamLeader && trainerExists
						? [
								{
									key: "internship",
									label: user?.internship ? t("EditInernDates") : t("Internship"),
									onClick: () => handleIntern(user),
								},
						  ]
						: []),

					{
						key: "remove",
						label: getRemoveLabel(),
						onClick: () => handleRemoveFromTeam(user),
					},
			  ]
			: [
					{
						key: "SetDates",
						label: t("SetDate"),
						onClick: () => handleSetDates(user),
					},
					...(user?.role?.name !== "AGENCY-SP"
						? [
								{
									key: "preview",
									label: t("Make sales lead"),
									onClick: () => handleSalesLead(user),
								},
						  ]
						: []),
					{
						key: "remove",
						label: t("Remove"),
						onClick: () => handleRemove(user?.value),
					},
			  ];
	return (
		<div
			style={{width: modal === "team" && user?.internship && "90%"}}
			className="selected__teamMember"
		>
			<div className="name-wrapper">
				<span className="name">{`${user?.firstName} ${user?.lastName}`}</span>
				{!isHeadOfSales && (
					<span className="date">{`${
						user.startDate && user.startDate !== null
							? moment(user?.startDate).utc().format(DATE_FORMAT)
							: ""
					}-${
						user.endDate && user.endDate !== null
							? moment(user?.endDate).utc().format(DATE_FORMAT)
							: ""
					}`}</span>
				)}
			</div>
			<div className="role">
				{isHeadOfSales ? (
					<>
						<HeadSalesIcon />
						<span className="role-head">
							{modal === "salesOrg" ? t("HeadOfSales") : t("HeadOfDepartment")}
						</span>
					</>
				) : (
					<>
						{modal === "salesOrg" && (
							<span className="role-name">{user?.role?.name || user?.role}</span>
						)}
						{modal === "team" && user?.teamLeader && (
							<span className="role-member">
								<img src={Star} alt="" /> {t("TeamLeader")}
							</span>
						)}
						{modal === "team" && user?.trainer && (
							<span className="role-member">{t("Trainer")}</span>
						)}
						{modal === "team" && user?.internship && (
							<span className="role-member">{t("Intern")}</span>
						)}
						{!user.teamLeader && (
							<Dropdown
								menu={{items}}
								overlayClassName="popover__actions-wrapper"
								placement="bottomLeft"
								trigger={["click"]}
							>
								<div onClick={handleClick} className="menu-icon">
									<ThreeDotsIcon
										stroke="#979797"
										extraStyle={{cursor: "pointer"}}
									/>
								</div>
							</Dropdown>
						)}
						{innerModal !== null && (
							<Popover
								trigger={["click"]}
								open={datesModal?.open && user?.id === datesModal?.obj?.id}
								overlayClassName="modal__component-dates"
								placement="bottomRight"
								content={renderInnerModal}
								onOpenChange={(visible) => {
									if (!visible) {
										setDatesModal({obj: null, open: false, type: ""});
									}
								}}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default SelectedTeamMember;
