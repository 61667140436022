/* eslint-disable */
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TabComp from '@common/createUserTabs/createUserTabsComp/index'
import { actions as orderActions } from '@sagas/orders/index'
import { clientStyles, genericLabels, getRandomColor, options, tabItems } from './staticData'
import 'antd/dist/antd.min.css'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Loader from '../Loader'
import { Skeleton } from 'antd'

const datesTypes = {
  year: 'datesYearly',
  quarter: 'datesSixMonth',
  month: 'datesMonthly',
  week: 'datesWeekly',
  day: 'datesDaily',
}

function ClientBar(props) {
  const {
    orderGraphs,
    handleActive,
    active,
    datesGraph,
    rangeType,
    setRangeType,
    setActive,
    clients,
    setSelectedClient,
    selectedClient,
    projectCategories,
    projectCategoriesLoading,
    graphLoading = false
  } = props
  const { t } = useTranslation()
  const [data, setData] = useState({ labels: [], datasets: [] })
  const [range, setRange] = useState(0)
  const [daysInMonth, setDaysInMonth] = useState(30)
  const [daysInTwooMonths, setDaysInTwooMonths] = useState(92)
  const [daysInThreeMonths, setDaysInThreeMonths] = useState(92)

  const tempArr = data?.datasets?.map((e) => e.data)
  const flattenedArray = [].concat(...tempArr)
  const allZeros = flattenedArray?.every((element) => element === 0)
  const maxValue = Math.max(...flattenedArray)
  const isOdd = maxValue % 2 !== 0
  const monthOptions = {
    ...options,
    scales: {
      ...options.scales,
      xAxes: {
        ...options.scales.xAxes,
        stacked: true,
      },
      yAxes: {
        ...options.scales.yAxes,
        stacked: true,
        ticks: {
          ...options.scales.yAxes.ticks,
          stepSize: isOdd ? 20 : 15,
        },
      },
    },
    plugins: {
      ...options.plugins,
      datalabels: {
        ...options.plugins.datalabels,
        display: true,
      },
    },
  }
  useEffect(() => {
    const chartDates = []
    const clientsData = []
    const orderStats = []
    const keyArr = []
    let obj = {
      label: '',
      data: [],
      backgroundColor: '',
      borderWidth: 1,
      barThickness: active === 'year' ? 8 : 20 
    }
    if (active === 'custom') {
      const to = moment(datesGraph?.to)
      const from = moment(datesGraph?.from)
      setRange(to?.diff(from, 'days') + 1)
      setDaysInMonth(moment(from).daysInMonth())
      const helper = moment(from).add(1, 'month')
      const secondHelper = moment(from).add(2, 'month')
      setDaysInTwooMonths(moment(helper).daysInMonth() + daysInMonth)
      setDaysInThreeMonths(
        moment(secondHelper).daysInMonth() + daysInTwooMonths,
      )
    }
    for (let index = 0; index < orderGraphs.length; index++) {
      const element = orderGraphs[index]
      const dateString = moment(element?.date).format('MM-DD')
      chartDates.push(dateString)
      const obj = {};
      if(element?.projectCategories) {
        projectCategories?.forEach((cat) => {
          obj[cat.name] = element?.projectCategories?.[cat.name] || 0; 
        })
      }
      clientsData.push(element?.projectCategories ? obj : element?.clients)
    }
    clientsData.map((client) =>
      Object.keys(client)?.map((key) => keyArr.push(key)),
    )
    const uniqueChars = [...new Set(keyArr)]
    uniqueChars.filter((item) => {
      const keys = []
      let label
      const color = []
      clientsData.map((cli) =>
        Object.entries(cli)?.map(([key, value]) => {
          if (item === key) {
            const foundedClient = clients?.find((client) => client?.clientId === item);
            label = item
            color.push(foundedClient ? foundedClient?.pdfColor : projectCategoriesColors?.[key] ? projectCategoriesColors[key] : "")
            keys.push(value)
          }
        }),
      )
      obj = { ...obj, label, backgroundColor: color, data: keys }
      orderStats.push(obj)
      const genericLabelsType =
        active !== 'custom' ? datesTypes[active] : datesTypes[rangeType]
      setData({
        labels: genericLabels[genericLabelsType],
        datasets: orderStats,
      })
    })
  }, [
    orderGraphs,
    active,
  ])
  const projectCategoriesColors = useMemo(() => {
     if(projectCategories?.length) {
        return getRandomColor(projectCategories);
     }
  },[projectCategories])

  useEffect(() => {
    switch (true) {
      case range === 1:
        setRangeType('day')
        setActive('custom')
        break
      case range > 1 && range <= 7:
        setRangeType('week')
        setActive('custom')
        break
      case range > 7 && range <= daysInMonth:
        setRangeType('month')
        setActive('custom')
        break
      case range > daysInMonth && range <= daysInThreeMonths:
        setRangeType('quarter')
        setActive('custom')
        break
      case range > daysInThreeMonths:
        setRangeType('year')
        setActive('custom')
        break
      default:
        setRangeType('')
    }
  }, [range])

  const handleClientClick = (event, id, clientId) => {
    event.stopPropagation()
    setSelectedClient({id, clientId})
  }
  useEffect(() => {
      return () => setSelectedClient({id: null, clientId: null})
  },[])
 
  return (
    <div>
      {graphLoading ? 
       <div className='graph-skeleton'>
        <Skeleton.Input active style={{ height: 30, borderRadius: 8, width: '100%' }} />
        <Skeleton.Input active style={{ height: 520, width: '100%' }} />
       </div> : 
      <div className="statistics__container">
        <div
          className="statistics__information">
          <div className="statistics__information__contentCategory">
            <span
              className={`statistics__information__category ${
                selectedClient.id === null && 'active'
              }`}
              onClick={(event) => handleClientClick(event, null,null)}
            >
              {t('All')}
            </span>
            <div className="statistics__information__upLegend">
              {clients?.map((item) => (
                <div className={`statistics__information__legend clickable`}>
                  <div
                    className="statistics__information__legend-color"
                    style={clientStyles(
                      selectedClient.id === item?.id,
                      item.pdfColor,
                    )}
                    onClick={(event) => handleClientClick(event, item?.id, item?.clientId)}
                  >
                    {item?.clientId}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="statistics__information__selectedDate">
            <span>
              {Number.isNaN(datesGraph.from)
                ? ''
                : moment(datesGraph.from).format('DD-MM-YYYY')}
            </span>
            <span>-</span>
            <span>
              {Number.isNaN(datesGraph.to)
                ? ''
                : moment(datesGraph.to).format('DD-MM-YYYY')}
            </span>
          </div>
        </div>
        <div className="statistics__information-second">
          <span className="statistics__information__text">
            {t('SaleStatistics')}
          </span>
        </div>
        <div className="project_categories_wrapper">
          {selectedClient?.id &&
            (projectCategoriesLoading ? (
              <Loader />
            ) : (
              <div className="statistics-nav">
                {selectedClient?.id && projectCategories.map((item, index) => (
                  <span key={`${index}__${index}`} className="statistics-link" style={{color: projectCategoriesColors?.[item.name] ? projectCategoriesColors[item.name] : "#fff"}} >
                    {item?.name}
                  </span>
                ))}
              </div>
            ))}
        </div>

        <div className="statistics__information-second">
          {!allZeros ? (
            <Bar
              data={data}
              options={
                active === 'month' || rangeType === 'month'
                  ? monthOptions
                  : options
              }
              type="bar"
              plugins={[ChartDataLabels]}
            />
          ) : (
            <span className="noDataMsg">{t('NoData')}</span>
          )}
        </div>
        <div className="statistics__information-second">
          <div className="statistics__information-bottom">
             {tabItems?.map((item) => {
               return (
                <TabComp 
                key={item.type}
                onClick={() => handleActive({type: item.type})}
                active={active === item.type}
                word={item.type === "quarter" ? `3 ${t(item.word)}` : t(item.word)}
                templates
                />
               )
             })}
          </div>
        </div>
      </div>
      }
    </div> 
  )
}
const mapStateToProps = (state) => ({
  selectedClient: state.app.orders.index.selectedClient,
  orderGraphs: state.app.orders.index.orderGraphs,
  projectCategories: state.app.projectCategories.index.optionsProjectCategories,
  projectCategoriesLoading: state.app.projectCategories.index.loading,
})

const mapDispatchToProps = {
  setSelectedClient: orderActions.setSelectedClient,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientBar)
