import {
	AddUserIcon,
	DeleteRowIcon,
	MultipleUsersRemove,
	RemoveUserFromLeadIcon,
	ReplaceUserIcon,
	SelectIcon,
} from "@src/assets/SvgComponents";
import React from "react";
import i18n from "@src/i18n";
import {checkRoles} from "@src/utils/checkRoles";
export const userActionsItems = (
	selectedRows,
	handleActionsModal,
	query,
	setShowConfirmModal,
	loggedUser,
	selectAllStatuses,
	selectedStatuses,
) => {
	const areAllSelectedRowsSuccess =
		selectedRows?.length > 0 && selectedRows?.every((row) => row?.statusId === 4);
	const queryParams = new URLSearchParams(window.location.search);
	const viewType = queryParams.get("view-type");
	const isStatusView = viewType === "statusView";
	const items = [
		{
			key: "add",
			label: i18n.t("AddUser"),
			onClick: () => handleActionsModal(i18n.t("Add"), i18n.t("AddUser")),
			show: (selectedRows?.[0] || selectedStatuses?.ids?.[0]) && checkRoles("filterAddUser"),
			icon: <AddUserIcon width={22} />,
		},
		{
			key: "noActions",
			label: isStatusView ? i18n.t("SelectAllStatuses") : i18n.t("SelectLeadsToViewActions"),
			onClick: () => {
				if (isStatusView) {
					selectAllStatuses();
				}
			},
			icon: isStatusView ? <SelectIcon stroke="var(--color-primary)" /> : null,
			className: "no-actions",
			show: selectedRows?.length === 0 && selectedStatuses?.ids?.length === 0,
		},
		{
			key: "replace",
			label: i18n.t("ReplaceUsers"),
			onClick: () => handleActionsModal(i18n.t("Replace"), i18n.t("ReplaceUsers")),
			show:
				(selectedRows?.[0] || selectedStatuses?.ids?.[0]) &&
				query?.userId !== null &&
				query?.userId,
			icon: <ReplaceUserIcon width={22} />,
		},
		{
			key: "remove",
			label: i18n.t("RemoveUsers"),
			onClick: () => handleActionsModal(i18n.t("Remove"), i18n.t("RemoveUsers")),
			show:
				(selectedRows?.[0] || selectedStatuses?.ids?.[0]) &&
				query?.userId !== null &&
				query?.userId,
			icon: <RemoveUserFromLeadIcon width={22} />,
		},
		{
			key: "removeAllUsers",
			label: i18n.t("RemoveAllUsers"),
			onClick: () => handleActionsModal(i18n.t("RemoveAll"), i18n.t("RemoveAllUsers")),
			show: selectedRows?.[0] || selectedStatuses?.ids?.[0],
			icon: <MultipleUsersRemove />,
		},
		{
			key: "deleteLeads",
			label: i18n.t("PermanentDeleteLeads"),
			show:
				(selectedRows?.[0] || selectedStatuses?.ids?.[0]) &&
				!areAllSelectedRowsSuccess &&
				checkRoles("deleteLeads"),
			icon: <DeleteRowIcon stroke="#252525" width={22} />,
			onClick: () => setShowConfirmModal(true),
		},
	];
	return items?.filter((item) => item?.show);
};
