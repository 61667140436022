/* eslint-disable max-len */
import Dashboard from "@components/dashboard/Dashboard";
import Users from "@components/dashboard/Users/Users";
import UserDetails from "@components/dashboard/UserDetails/UserDetails";
import Orders from "@components/dashboard/orders";
import Imports from "@components/dashboard/orders/imports/imports";
import UserProfile from "@components/dashboard/userProfile";
import Clients from "@components/dashboard/clients";
import Client from "@components/dashboard/clients/Client";
import Tenants from "@components/dashboard/Tenants";
import TrainingsComp from "@components/dashboard/Trainings/All";
import SingleTraining from "@components/dashboard/Trainings/Single";
import AddTraining from "@components/dashboard/Trainings/Add";
import Messages from "@components/messages";
import CreateUser from "@components/dashboard/Users/CreateUser";
import Calculations from "@components/dashboard/calculations";
import SalesTracker from "@components/dashboard/sales-tracker";
import CalculationDetails from "@components/dashboard/calculations/CalculationDetails/CalculationDetails";
import ImportCsv from "@components/dashboard/orders/imports/index";
import InsideImport from "@components/dashboard/orders/imports/InsideImport";
import CreatePdf from "@components/generic-pdf/CreatePdf";
import SalesTrackerInsideImport from "@components/dashboard/sales-tracker/components/SalesTrackerInsideImport";
import UpdatedStatuses from "../dashboard/orders/UpdatedStatuses";
import TenantSettings from "../dashboard/Tenants/TenantSettings";
import CalculationImportTable from "../dashboard/calculations/CalculationDetails/CalculationImportTable";
import InsideOrderImport from "../dashboard/orders/OrderImports/InsideOrderImport";
import Energy from "../dashboard/Energy";
import ImportsPage from "../dashboard/Imports";
import OrderTables from "../dashboard/Imports/components/OrderTables";
import PreviewCsv from "../dashboard/Imports/components/PreviewCsv";
import PreviewCsvById from "../dashboard/Imports/components/PreviewCsvById";
import CreateImport from "../dashboard/Imports/components/CreateImport";
import Support from "../dashboard/Support";
import LockedComponent from "../dashboard/Users/LockedUsersComponent/LockedComponent";

export const PROTECED_ROUTES = [
	{
		path: "/admin/dashboard/",
		component: Dashboard,
		permission: "seeDashboard",
	},
	{
		path: "/admin/tenants/",
		component: Tenants,
		permission: "seeTenants",
	},
	{
		path: "/admin/tenants/client/:id",
		component: TenantSettings,
		permission: "seeTenantSettings",
	},
	{
		path: "/admin/users/",
		component: Users,
		permission: "seeUsers",
	},
	{
		path: "/admin/users/create",
		component: CreateUser,
		permission: "seeCreateEditUsers",
	},
	{
		path: "/admin/users/edit/:id",
		component: CreateUser,
		permission: "seeCreateEditUsers",
	},
	{
		path: "/admin/user/SP/",
		component: UserDetails,
		permission: "seeSPUserDetails",
	},
	{
		path: "/admin/user/EMPLOYEE/",
		component: UserDetails,
		permission: "seeEmployeeUserDetails",
	},
	{
		path: "/admin/user/AGENCY-SP/",
		component: UserDetails,
		permission: "seeAgencyUserDetails",
	},
	{
		path: "/admin/users/:id",
		component: UserDetails,
		permission: "seeUserDetails",
	},
	{
		path: "/admin/user-profile/",
		component: UserProfile,
		permission: "seeUserProfile",
	},
	{
		path: "/admin/clients/",
		component: Clients,
		permission: "seeClients",
	},
	{
		path: "/admin/clients/:clientId/:id",
		component: Client,
		permission: "seeClient",
	},
	{
		path: "/admin/calculations/",
		component: Calculations,
		permission: "seeCalculations",
	},
	{
		path: "/admin/calculations/:id/:userId",
		component: CalculationDetails,
		permission: "seeCalculationDetails",
	},
	{
		path: "/admin/orders/",
		component: Orders,
		permission: "seeOrders",
	},
	{
		path: "/admin/orders/imported",
		component: Imports,
		permission: "seeImports",
	},
	{
		path: "/admin/orders/imported/:id",
		component: InsideImport,
		permission: "seeInsideImport",
	},
	{
		path: "/admin/orders/:id",
		component: UpdatedStatuses,
		permission: "seeUpdatedStatuses",
	},
	{
		path: "/admin/imports/order/:id",
		component: UpdatedStatuses,
		permission: "seeUpdatedStatuses",
	},
	{
		path: "/orders/import",
		component: ImportCsv,
		permission: "seeImportCsv",
	},
	{
		path: "/admin/imports/create-order/:id",
		component: InsideOrderImport,
		permission: "seeInsideOrderImport",
	},
	{
		path: "/admin/trainings",
		component: TrainingsComp,
		permission: "seeTrainings",
		feature: "Trainings",
	},
	{
		path: "/admin/trainings/:id",
		component: SingleTraining,
		permission: "seeSingleTraining",
		feature: "Trainings",
	},
	{
		path: "/admin/trainings/add/training",
		component: AddTraining,
		permission: "seeAddEditTraining",
		feature: "Trainings",
	},
	{
		path: "/admin/trainings/edit/training/:id",
		component: AddTraining,
		permission: "seeAddEditTraining",
		feature: "Trainings",
	},
	{
		path: "/admin/messages",
		component: Messages,
		permission: "seeMessages",
		feature: "Messages",
	},
	{
		path: "/admin/messages/:id",
		component: Messages,
		permission: "seeMessages",
		feature: "Messages",
	},
	{
		path: "/admin/salestracker",
		component: SalesTracker,
		permission: "seeSalesTracker",
		feature: "SalesTracker",
	},
	{
		path: "/admin/energy",
		component: Energy,
		permission: "seeEnergy",
		feature: "Energy",
	},
	{
		path: "/admin/imports",
		component: ImportsPage,
		permission: "seeImportsPage",
		feature: "Imports",
	},
	{
		path: "/admin/imports/:id",
		component: CreateImport,
		permission: "seeCreateImport",
		feature: "Imports",
	},
	{
		path: "/admin/imports/change-order-status/tables",
		component: OrderTables,
		permission: "seeOrderTables",
		feature: "Imports",
	},
	{
		path: "/admin/imported/previewCsv",
		component: PreviewCsv,
		permission: "seePreviewCsv",
		feature: "Imports",
	},
	{
		path: "/admin/imports/change-order-status/:id",
		component: PreviewCsvById,
		permission: "seePreviewCsvById",
		feature: "Imports",
	},
	{
		path: "/admin/imports/create-leads/:id",
		component: SalesTrackerInsideImport,
		permission: "seeSalesTrackerInsideImport",
		feature: "SalesTracker",
	},
	{
		path: "/admin/clients/:clientId/:clientStringId/createPdf/:id",
		component: CreatePdf,
		permission: "seeCreatePdf",
	},
	{
		path: "/admin/calculations-file/:id/import/:userId",
		component: CalculationImportTable,
		permission: "seeCalculationImportTable",
	},
	{
		path: "/admin/support",
		component: Support,
		permission: "seeSupport",
		feature: "Tickets",
	},
	{
		path: "/locked",
		component: LockedComponent,
		permission: "seeLockedComponent",
	},
];
