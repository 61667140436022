import React from "react";
import {Select} from "antd";
import {HeadOfDepartmentIcon} from "@src/assets/SvgComponents";
import StatusBox from "../components/status-box";
const {Option} = Select;
const FiltersSelectComponent = ({
	options,
	onSelect,
	key = "id",
	optionVal = "value",
	mode,
	onDeselect,
	value,
	optionLabel = "name",
	placeholder,
	onClear,
	allowClear = true,
	label,
	secondLabel,
}) => (
	<div className="customdropdown__wrapper">
		{label && <span className="customdropdown__wrapper-label">{label}</span>}
		<Select
			showSearch
			placeholder={placeholder}
			optionFilterProp="children"
			onSelect={onSelect}
			filterOption={(input, option) =>
				String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			mode={mode}
			onDeselect={onDeselect}
			onClear={onClear}
			value={value?.name ? value?.name : value}
			allowClear={allowClear}
			dropdownStyle={{zIndex: 9999}}
		>
			{options &&
				options.map((option) => (
					<Option key={option?.[key]} value={option?.[optionVal]}>
						{secondLabel &&
						option?.[secondLabel] !== null &&
						option?.[secondLabel] !== "" ? (
							<StatusBox
								row="Agency"
								name={option?.[secondLabel]}
								height="25px"
								statusData={{
									Agency: {icon: <HeadOfDepartmentIcon />, color: "#5b6bc6"},
								}}
							/>
						) : (
							option?.[optionLabel]
						)}
					</Option>
				))}
		</Select>
	</div>
);

export default FiltersSelectComponent;
