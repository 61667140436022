import React from "react";
import {groupOptionsForSelect} from "@src/utils/getOptionsForSelect";
import {MapIcon, SalesTrackerIcon, StatusViewIcon, StreetViewIcon} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";

export const WIDTH_VALUES = {
	310: "310px",
	140: "140px",
	116: "116px",
	344: "344px",
	480: "480px",
};

export const formatGroupOptions = (teams, agencies, usersOptions, type, values) => {
	const getUserOptions = (group, type) => {
		if (values) {
			const valueIds = values?.map((val) => val?.value);
			if (type === "USERS") {
				const checkedUsers = group?.map((item) => {
					if (valueIds?.includes(item?.id)) {
						return {
							...item,
							checked: true,
						};
					}
					return item;
				});
				return checkedUsers;
			}
			const checkedTeams = group?.map((item) => {
				const memberIds = item?.members.map((item) => item?.id);
				const arraysAreEqual = (arr1, arr2) => arr1.every((item) => arr2.includes(item));
				const isEqual = arraysAreEqual(memberIds, valueIds);
				if (isEqual) {
					return {
						...item,
						checked: true,
					};
				}
				return item;
			});
			return checkedTeams;
		}
		return group;
	};
	const formattedTeams = groupOptionsForSelect(
		getUserOptions(teams, "TEAM"),
		"TEAM",
		"Team",
		type,
	);
	const formattedAgencies = groupOptionsForSelect(
		getUserOptions(agencies, "AGENCY"),
		"AGENCY",
		"Agency",
		type,
	);

	const formattedUsers = groupOptionsForSelect(
		getUserOptions(usersOptions, "USERS"),
		"USERS",
		"User",
		type,
	);
	return [...formattedTeams, ...formattedAgencies, ...formattedUsers];
};

export const renderModalTitle = (lead) => (
	<div className="lead__id">
		<span className="lead__icon">
			<SalesTrackerIcon />
		</span>
		<p className="lead__text">{lead?.id ? `${i18n.t("LEADID")} |` : i18n.t("LEAD")}</p>
		{lead?.id && <p className="lead__id__value">{lead?.generateLeadId}</p>}
	</div>
);

export const items = [
	{
		key: "statusView",
		label: i18n.t("statusView"),
		icon: <StatusViewIcon />,
	},
	{
		key: "streetView",
		label: i18n.t("streetView"),
		icon: <StreetViewIcon />,
	},
	{
		key: "mapView",
		label: i18n.t("mapView"),
		icon: <MapIcon />,
	},
];

export const VIEWS = {
	edit: "edit",
	preview: "preview",
};
