import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Users index");

const PREFIX = "@app/users/user/deleted";
export const FETCH = `${PREFIX}FETCH`;
export const FETCH_SUCCESS = `${PREFIX}FETCH_SUCCESS`;
export const SET_FILTERS = `${PREFIX}SET_FILTERS`;
export const EDIT_SIZE = `${PREFIX}EDIT_SIZE`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const ADD = `${PREFIX}ADD`;
export const DELETE = `${PREFIX}DELETE`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	users: [],
	page: 1,
	size: 30,
	totalSize: 5,
	search: "",
	totalPages: 1,
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case FETCH_SUCCESS:
				const users =
					state?.page > 1 ? [...state?.users, ...action?.payload] : action.payload;
				draft.users = users;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = action.payload;
				break;
			case EDIT_PAGE:
				draft.page = action.payload;
				break;
			case SET_FILTERS:
				draft.page = 1;
				draft.search = action.payload.value;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case EDIT_SIZE:
				draft.size = action.payload;
				break;
			case ADD:
				draft.users = [action.payload].concat(state.users);
				draft.totalSize = state?.totalSize + 1;
				break;
			case DELETE:
				const filterUsers = state.users.filter((user) => user.id !== action.payload);
				if (
					draft?.page > 1 &&
					(filterUsers?.length === 0 ||
						filterUsers.every((user) => user.role.name === "AGENCY-SP"))
				) {
					draft.page = state?.page - 1;
				} else {
					draft.users = filterUsers;
					draft.page = 1;
					draft.size = draft.users?.[0] ? draft.users.length : 30;
					draft.totalSize = draft.users?.[0] ? state.totalSize : 0;
				}
				break;
			case SET_LOADING:
				draft.loading = action?.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	fetch: (payload) => createAction(FETCH, {payload}),
	fetchSuccess: (payload) => createAction(FETCH_SUCCESS, {payload}),
	editSize: (payload) => createAction(EDIT_SIZE, {payload}),
	filter: (payload) => createAction(SET_FILTERS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	add: (payload) => createAction(ADD, {payload}),
	delete: (payload) => createAction(DELETE, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*fetch({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size, page} = yield select((state) => state.app.users.deleted);
			const {search} = payload;
			const response = yield axios.get(
				`/user/all/deleted?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchSuccess(response?.data?.data?.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH, sagas.fetch);
};
