import React, {useEffect, useState} from "react";
import {dataDe, dataEn} from "../data";

const PrivacyPolicyHeader = (props) => {
	const {match} = props;
	const [langData, setLangData] = useState(dataDe);

	useEffect(() => {
		if (match.params?.lang === "en") {
			setLangData(dataEn);
		} else if (match.params.lang === "en") {
			setLangData(dataDe);
		}
	}, [match.params]);

	return (
		<div className="privacy__policy__text__container">
			{langData?.map((item) => (
				<div className="privacy__policy__rule__container">
					{item?.title && <p className="privacy__policy__rule__header">{item?.title}</p>}
					{item?.textBigger && (
						<p className="privacy__policy__rule__bigger__text">{item?.textBigger}</p>
					)}
					{item?.rows && (
						<>
							{item.rows.map((item2) => (
								<p className="privacy__policy__rule__text">{item2}</p>
							))}
						</>
					)}
					{item?.text && <p className="privacy__policy__rule__text">{item?.text}</p>}
					{item?.list && (
						<>
							{item.list.map((item2) => (
								<ul>
									<li className="privacy__policy__rule__text list">{item2}</li>
								</ul>
							))}
						</>
					)}
					{item?.text2 && <p className="privacy__policy__rule__text">{item?.text2}</p>}
				</div>
			))}
		</div>
	);
};

export default PrivacyPolicyHeader;
