import React from "react";
import * as yup from "yup";

import i18n from "../../../../../../i18n";

const tariffElements = {
	columns: [
		{name: i18n.t("ID"), key: "id", sortable: true},
		{
			name: i18n.t("Tariff"),
			key: "name",
			image: "imageField",
			headerCellClass: "headerStyles",
			sortable: true,
			cellClass: "category-custom__cell",
			headerStyle: {
				fontFamily: "Montserrat",
				fontSize: "1.3rem",
			},
			cellStyle: {
				backgroundColor: "#44228b0d",
				color: "#6C1EB0",
				marginRight: "190px",
				borderRightStyle: "solid",
				borderRightColor: "#f5f5f5",
				borderRightWidth: "1px",
			},
		},

		{
			name: i18n.t("cancellationReserve"),
			key: "cancellationReserve",
			headerCellClass: "headerStyles",
			sortable: true,
			formatter: ({row}) => (
				<span>
					{row.cancellationReserveType === "VALUE"
						? `${row.cancellationReserve}€`
						: `${row.cancellationReserve}%`}
				</span>
			),
		},
	],
	btnText: i18n.t("Submit"),
	validationSchema: {
		add: yup.object().shape({
			name: yup.string().label(i18n.t("name")).required(i18n.t("NameIsReqired")),
			description: yup.string().label("description").required(i18n.t("DescriptionIsReq")),
			provision: yup
				.number()
				.nullable()
				.label(i18n.t("Provision"))
				.required(i18n.t("ProvisionIsReq")),
			commission: yup
				.number()
				.nullable()
				.label(i18n.t("comission"))
				.required(i18n.t("ComissionIsReq")),
			cancellationReserve: yup
				.number()
				.nullable()
				.label(i18n.t("cancellationReserve"))
				.required(i18n.t("CancellationReserveIsReq")),
			totalPrice: yup
				.number()
				.nullable()
				.label(i18n.t("TotalPrice"))
				.required(i18n.t("TotalPriceReq")),
			cancellationReserveType: yup
				.object()
				.nullable()
				.label(i18n.t("cancellationReserveType"))
				.required(i18n.t("CancellationReserveTypeISReq")),
			defaultProducts: yup.array().of(
				yup.object().shape({
					name: yup.string().required(i18n.t("ProductNameReq")),
					description: yup.string().required(i18n.t("ProductDescriptionReq")),
				}),
			),
		}),
	},
	lists: {
		add: [
			{
				title: i18n.t("TariffName"),
				type: "input",
				field: "name",
				name: "name",
				inputType: "text",
			},
			{
				title: i18n.t("Description"),
				type: "input",
				field: "description",
				inputType: "text",
			},
			{
				title: i18n.t("Provision"),
				type: "input",
				field: "provision",
				inputType: "number",
			},
			{
				title: i18n.t("comission"),
				type: "input",
				field: "commission",
				inputType: "number",
			},
			{
				title: i18n.t("cancellationReserve"),
				type: "input",
				field: "cancellationReserve",
				inputType: "number",
				value: 0,
			},
			{
				title: i18n.t("cancellationReserveType"),
				type: "dropdown",
				field: "cancellationReserveType",
				inputType: "number",
				name: "value",
				newClass: "dropdown-commission",
			},
			{
				title: i18n.t("TotalPrice"),
				type: "input",
				field: "totalPrice",
				inputType: "number",
				bottomBreak: true,
				addFields: true,
			},
		],
	},
	hasID: "id",
	modalTitle: i18n.t("Tariff"),
};

export default tariffElements;
