/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import {put, select, takeLatest} from "redux-saga/effects";
import produce from "immer";
import axios from "axios";
import Logger from "@utils/logger";
import _ from "lodash";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import createAction from "../../../../utils/action-creator";

const logger = new Logger("Sagas>salesOrganizations>Index");

const PREFIX = "@app/salesOrganizations";
export const GET_SALES_ORGANIZATIONS = `${PREFIX}GET_SALES_ORGANIZATIONS`;
export const GET_SALES_ORGANIZATIONS_SUCCESS = `${PREFIX}GET_SALES_ORGANIZATIONS_SUCCESS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const CREATE_SALES_ORGANIZATION = `${PREFIX}CREATE_SALES_ORGANIZATION`;
export const CREATE_SALES_ORGANIZATION_SUCCESS = `${PREFIX}CREATE_SALES_ORGANIZATION_SUCCESS`;
export const SET_MODAL = `${PREFIX}SET_MODAL`;
export const DELETE_SALES_ORGANIZATION = `${PREFIX}DELETE_SALES_ORGANIZATION`;
export const DELETE_SALES_ORGANIZATION_SUCCESS = `${PREFIX}DELETE_SALES_ORGANIZATION_SUCCESS`;
export const GET_SALES_ORGANIZATIONS_BY_ID = `${PREFIX}GET_SALES_ORGANIZATIONS_BY_ID`;
export const GET_SALES_ORGANIZATIONS_BY_ID_SUCCESS = `${PREFIX}GET_SALES_ORGANIZATIONS_BY_ID_SUCCESS`;
export const EDIT_SALES_ORGANIZATION = `${PREFIX}EDIT_SALES_ORGANIZATION`;
export const EDIT_SALES_ORGANIZATION_SUCCESS = `${PREFIX}EDIT_SALES_ORGANIZATION_SUCCESS`;
export const GET_ALL_SALES_ORGANIZATIONS = `${PREFIX}GET_ALL_SALES_ORGANIZATIONS`;
export const GET_ALL_SALES_ORGANIZATIONS_SUCCESS = `${PREFIX}GET_ALL_SALES_ORGANIZATIONS_SUCCESS`;
export const SET_MODAL_LOADING = `${PREFIX}SET_MODAL_LOADING`;
export const CLEAR_SALES_ORGANIZATIONS = `${PREFIX}CLEAR_SALES_ORGANIZATIONS`;

const _state = {
	salesOrganizations: [],
	singlesalesOrganization: {
		name: "",
		startDate: "",
		endDate: "",
		salesOrganizationUsers: [],
		headOfSales: null,
	},
	page: 1,
	size: 30,
	totalSize: 0,
	totalPages: 1,
	loading: false,
	showModal: false,
	modalLoading: false,
	allSalesOrganizations: [],
	clone: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case GET_SALES_ORGANIZATIONS_SUCCESS:
				if (action.payload.type === "get") {
					const arr = action.payload.data.map((item) => ({
						...item,
						type: "MASTER",
						expanded: false,
					}));
					draft.salesOrganizations =
						state?.page === 1 ? [...arr] : [...state.salesOrganizations, ...arr];
				} else {
					const clonedState = [...state.clone];
					draft.salesOrganizations = clonedState;
				}
				break;
			case EDIT_PAGE:
				draft.page = action.payload;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = action.payload;
				break;
			case SET_MODAL:
				draft.showModal = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case CREATE_SALES_ORGANIZATION_SUCCESS:
				const updatedPayload = {
					...action.payload,
					type: "MASTER",
					expanded: false,
				};
				draft.totalSize = state.totalSize + 1;
				draft.salesOrganizations = [updatedPayload, ...state.salesOrganizations];
				break;
			case CLEAR_SALES_ORGANIZATIONS:
				if (action.payload) {
					draft.clone = [...action.payload];
				} else {
					draft.clone = [...state.salesOrganizations];
				}
				draft.salesOrganizations = [];
				break;
			case DELETE_SALES_ORGANIZATION_SUCCESS:
				const filteredArray = state.clone.filter((item) => item?.id !== action.payload);
				draft.salesOrganizations = filteredArray;
				draft.totalSize = state.totalSize - 1;
				break;
			case GET_SALES_ORGANIZATIONS_BY_ID_SUCCESS:
				const single = action.payload?.salesOrganizationUserViewList?.find(
					(item) => item?.isHeadOfSales,
				);
				const salesOrganizationUsers = action.payload?.salesOrganizationUserViewList
					?.filter((item) => item?.isHeadOfSales === false)
					.map((item) => ({
						...item,
						key: item?.userId,
						value: `${item.firstName} ${item.lastName}`,
						name: `${item.firstName} ${item.lastName}`,
						id: item?.userId,
					}));

				const headOfSales = {
					...single,
					key: single?.id,
					value: `${single.firstName} ${single.lastName}`,
					name: `${single.firstName} ${single.lastName}`,
					id: single?.userId,
				};
				draft.singlesalesOrganization = {
					...action.payload,
					headOfSales,
					salesOrganizationUsers,
				};
				break;
			case EDIT_SALES_ORGANIZATION_SUCCESS:
				const updatedSalesOrganizations = state.salesOrganizations.map((item) => {
					if (item?.id === action.payload?.id && item?.type === "MASTER") {
						return {
							...action.payload,
							expanded: item?.expanded,
							type: item?.type,
						};
					}
					if (item?.id === action.payload?.id && item?.type === "DETAIL") {
						return {
							...item,
							salesOrganizationUserViewList:
								action.payload?.salesOrganizationUserViewList,
						};
					}
					return {...item};
				});
				draft.salesOrganizations = updatedSalesOrganizations;
				break;
			case GET_ALL_SALES_ORGANIZATIONS_SUCCESS:
				draft.allSalesOrganizations =
					(action?.payload?.[0] &&
						action?.payload?.map((item) => ({
							...item,
							value: item?.id,
							label: item?.name,
						}))) ||
					[];
				break;
			case SET_MODAL_LOADING:
				draft.modalLoading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getSalesOrganizations: (payload) => createAction(GET_SALES_ORGANIZATIONS, {payload}),
	getSalesOrganizationsSuccess: (payload) =>
		createAction(GET_SALES_ORGANIZATIONS_SUCCESS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	createSalesOrganization: (payload) => createAction(CREATE_SALES_ORGANIZATION, {payload}),
	createSalesOrganizationSuccess: (payload) =>
		createAction(CREATE_SALES_ORGANIZATION_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SET_MODAL, {payload}),
	deleteSalesOrganization: (payload) => createAction(DELETE_SALES_ORGANIZATION, {payload}),
	deleteSalesOrganizationSuccess: (payload) =>
		createAction(DELETE_SALES_ORGANIZATION_SUCCESS, {payload}),
	getSalesOrganizationsById: (payload) => createAction(GET_SALES_ORGANIZATIONS_BY_ID, {payload}),
	getSalesOrganizationsByIdSuccess: (payload) =>
		createAction(GET_SALES_ORGANIZATIONS_BY_ID_SUCCESS, {payload}),
	editSalesOrganization: (payload) => createAction(EDIT_SALES_ORGANIZATION, {payload}),
	editSalesOrganizationSuccess: (payload) =>
		createAction(EDIT_SALES_ORGANIZATION_SUCCESS, {payload}),
	getAllSalesOrganizations: (payload) => createAction(GET_ALL_SALES_ORGANIZATIONS, {payload}),
	getAllSalesOrganizationsSuccess: (payload) =>
		createAction(GET_ALL_SALES_ORGANIZATIONS_SUCCESS, {payload}),
	setModalLoading: (payload) => createAction(SET_MODAL_LOADING, {payload}),
	clearSalesOrganizations: (payload) => createAction(CLEAR_SALES_ORGANIZATIONS, {payload}),
};

export const sagas = {
	*getSalesOrganizations({payload}) {
		yield put(actions.setLoading(true));
		const {page, size} = yield select((state) => state.app.salesOrganizations.index);
		try {
			const response = yield axios.get(
				`/sales-organization/all/?page=${page}&size=${size}&search=${payload.search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
			yield put(
				actions.getSalesOrganizationsSuccess({
					data: response.data.data.data,
					type: "get",
				}),
			);
			yield put(actions.setLoading(false));
		} catch (error) {
			yield put(actions.setLoading(false));
			logger.error(error);
		}
	},
	*createSalesOrganization({payload}) {
		const {values, setModal} = payload;
		yield put(actions.setModalLoading(true));
		const payloadObj = {
			...values,
			salesOrganizationUsers: [
				{
					userId: values.headOfSales?.id,
					isHeadOfSales: true,
					startDate: values?.startDate || null,
					endDate: values?.endDate || null,
				},
				...values?.salesOrganizationUsers?.map((item) => ({
					userId: item?.id,
					isHeadOfSales: false,
					startDate: item?.startDate || null,
					endDate: item?.endDate || null,
				})),
			],
		};
		delete payloadObj.headOfSales;
		try {
			const response = yield axios.post(`/sales-organization/create`, payloadObj);
			setModal(false);
			yield put(actions.createSalesOrganizationSuccess(response.data?.data?.data));
			yield put(actions.setModalLoading(false));
			ToastSuccesComponent(i18n.t("SalesCreated"));
		} catch (error) {
			yield put(actions.setModalLoading(false));
			ToastErrorComponent(
				error?.response?.data?.message?.[0]?.message
					? error?.response?.data?.message?.[0]?.message
					: i18n.t("CantCreateSales"),
			);
			logger.error(error);
		}
	},
	*deleteSalesOrganization({payload}) {
		const {id, setModal} = payload;
		yield put(actions.setModalLoading(true));
		try {
			yield axios.put(`/sales-organization/${id}`);
			setModal(false);
			yield put(actions.clearSalesOrganizations());
			yield put(actions.deleteSalesOrganizationSuccess(id));
			ToastSuccesComponent(i18n.t("SalesDeleted"));
			yield put(actions.setModalLoading(false));
		} catch (error) {
			yield put(actions.setModalLoading(false));
			logger.error(error);
		}
	},
	*getSalesOrganizationsById({payload}) {
		yield put(actions.setShowModal(true));
		yield put(actions.setModalLoading(true));
		try {
			const response = yield axios.get(`/sales-organization/${payload}`);
			yield put(actions.getSalesOrganizationsByIdSuccess(response.data.data));
			yield put(actions.setShowModal(false));
			yield put(actions.setModalLoading(false));
		} catch (error) {
			yield put(actions.setModalLoading(false));
			yield put(actions.setShowModal(false));
			logger.error(error);
		}
	},
	*editSalesOrganization({payload}) {
		yield put(actions.setModalLoading(true));
		const {values, setModal} = payload;
		const payloadObj = {
			...values,
			salesOrganizationUsers: [
				{
					userId: values.headOfSales?.id || values.headOfSales?.userId,
					isHeadOfSales: true,
					startDate: values?.startDate || null,
					endDate: values?.endDate || null,
				},
				...values?.salesOrganizationUsers?.map((item) => ({
					userId: item?.id || item.userId,
					isHeadOfSales: false,
					startDate: item?.startDate || null,
					endDate: item?.endDate || null,
				})),
			],
		};
		delete payloadObj.salesOrganizationUserViewList;
		delete payloadObj.headOfSales;
		try {
			const response = yield axios.put(`/sales-organization/update`, payloadObj);
			setModal(false);
			yield put(actions.editSalesOrganizationSuccess(response.data?.data?.data));
			ToastSuccesComponent(i18n.t("SalesEdited"));
			yield put(actions.setModalLoading(false));
		} catch (error) {
			ToastErrorComponent(
				error?.response?.data?.message?.[0]?.message
					? error?.response?.data?.message?.[0]?.message
					: i18n.t("CantEditSales"),
			);
			yield put(actions.setModalLoading(false));
			logger.error(error);
		}
	},
	*getAllSalesOrganizations({payload}) {
		try {
			const response = yield axios.get(`/sales-organization/dropdown?type=${payload}`);
			yield put(actions.getAllSalesOrganizationsSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_SALES_ORGANIZATIONS, sagas.getSalesOrganizations);
	yield takeLatest(CREATE_SALES_ORGANIZATION, sagas.createSalesOrganization);
	yield takeLatest(GET_SALES_ORGANIZATIONS_BY_ID, sagas.getSalesOrganizationsById);
	yield takeLatest(DELETE_SALES_ORGANIZATION, sagas.deleteSalesOrganization);
	yield takeLatest(EDIT_SALES_ORGANIZATION, sagas.editSalesOrganization);
	yield takeLatest(GET_ALL_SALES_ORGANIZATIONS, sagas.getAllSalesOrganizations);
};
