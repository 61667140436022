import * as yup from "yup";
import React from "react";
import {Storage} from "@src/utils/local-storage";
import ClientCustomColumn from "./ClientCustomColumn";
import ClientCustomName from "./ClientCustomName";
import i18n from "../../../i18n";

const tenantFeatures = Storage.get("features");

export const PAYOUT_MODELS = [
	{
		value: "TARIFF_MODEL",
		label: i18n.t("TariffModel"),
	},
	{
		value: "DISTRIBUTION_MODEL",
		label: i18n.t("DistributionModel"),
	},

	{
		value: "ENERGY_DISTRIBUTION_MODEL",
		label: i18n.t("EnergyDistributionModel"),
	},
];

export const clientElements = (props) => {
	const {hasId} = props;
	return {
		columns: [
			{
				title: i18n.t("clientName"),
				field: "clientName",
				render: (row) => (
					<ClientCustomColumn
						row={row}
						link="client"
						navLink={`${row.firstName} ${row.lastName}`}
					/>
				),
				headerStyle: {
					fontSize: "1.3rem",
					fontWeight: "600",
				},
				cellStyle: {
					backgroundColor: "#fbfbfb",
					marginRight: "190px",
					borderRightStyle: "solid",
					borderRightColor: "#f5f5f5",
					borderRightWidth: "1px",
					color: "#1e8659",
				},
			},
			{title: i18n.t("name"), field: "name", render: (row) => <ClientCustomName row={row} />},
			{title: i18n.t("email"), field: "email"},
		],
		btnText: i18n.t("Save"),
		validationSchema: {
			add: yup.object().shape({
				firstName: yup
					.string()
					.label(i18n.t("FirstName"))
					.required(i18n.t("FirstNameIsReq")),
				lastName: yup.string().label(i18n.t("LastName")).required(i18n.t("LastNameIsReq")),
				clientName: yup
					.string()
					.label(i18n.t("clientName"))
					.required(i18n.t("ClientNameIsReq")),
				email: yup
					.string()
					.lowercase()
					.strict()
					.label(i18n.t("email"))
					.email(i18n.t("EmailIsRequred"))
					.required(i18n.t("EmailIsRequred")),
				clientOrderId: yup
					.string()
					.matches(/^[a-zA-Z@]+$/, i18n.t("PleaseWriteThreeChars"))
					.label(i18n.t("ClientOrderID"))
					.length(3, i18n.t("PleaseWriteThreeChars"))
					.required(i18n.t("ClientOrderIDIsReq")),
			}),
		},
		lists: {
			add: [
				{
					title: i18n.t("clientName"),
					type: "input",
					field: "clientName",
					inputType: "text",
					required: true,
				},
				{
					title: i18n.t("ClientOrderID"),
					type: "input",
					field: "clientId",
					inputType: "text",
					bottomBreak: true,
					required: true,
				},
				{
					title: i18n.t("ContactPersonFirstName"),
					type: "input",
					field: "firstName",
					inputType: "text",
					required: true,
				},
				{
					title: i18n.t("ContactPersonLasttName"),
					type: "input",
					field: "lastName",
					inputType: "text",
					required: true,
				},
				{
					title: i18n.t("ContactPersonEmail"),
					type: "input",
					field: "email",
					inputType: "email",
					dependentField: hasId ? "createClientAccount" : null,
					disableWhen: hasId ? true : null,
					required: true,
				},
				{
					inputType: "checkbox",
					field: "createClientAccount",
					title: i18n.t("DoYouWantToCreateAccount"),
					bottomBreak: true,
					hidden: hasId,
				},
				{
					inputType: "dropdown",
					field: "clientPayoutModel",
					options: PAYOUT_MODELS,
					title: i18n.t("chooseClientType"),
					disabled: hasId,
					defaultValue: "TARIFF_MODEL",
					required: true,
				},
			],
		},
		hasID: "firstName",
		modalTitle: "Client",
	};
};
