import {
	AZSortIcon,
	DeleteStatusIcon,
	EditStatusIcon,
	MoveLeftIcon,
	MoveRightIcon,
	SortIcon,
	TickIcon,
	ZASortIcon,
} from "@src/assets/SvgComponents";
import React from "react";
import i18n from "@src/i18n";
import {checkRoles} from "@src/utils/checkRoles";
export const statusesActionsItems = (
	statusType,
	changeStatusOrder,
	orderNumber,
	allLength,
	id,
	handleSortType,
	sort,
	setModal,
) => {
	const items = [
		{
			key: "edit",
			label: i18n.t("EditStatus"),
			icon: <EditStatusIcon />,
			onClick: () => setModal("status", id),
			show: checkRoles("createStatus"),
		},

		{
			key: "delete",
			icon: <DeleteStatusIcon />,
			label: i18n.t("DeleteStatus"),
			onClick: () => setModal("deleteStatus", id),
			show:
				statusType !== "SUCCESS" && statusType !== "PENDING" && checkRoles("createStatus"),
		},
		{
			key: "moveRight",
			icon: <MoveRightIcon />,
			label: i18n.t("MoveRight"),
			onClick: () => {
				changeStatusOrder({
					id,
					newRow: orderNumber + 1,
					value: "increment",
				});
			},
			show: orderNumber < allLength && checkRoles("moveStatuses"),
		},

		{
			key: "moveLeft",
			icon: <MoveLeftIcon />,
			label: i18n.t("MoveLeft"),
			onClick: () => {
				changeStatusOrder({
					id,
					newRow: orderNumber - 1,
					value: "decrement",
				});
			},
			show: orderNumber > 1 && checkRoles("moveStatuses"),
		},
		{
			key: "sortBy",
			icon: <SortIcon />,
			label: i18n.t("SortBy"),
			show: checkRoles("sortLeads"),
			children: [
				{
					key: "az",
					label: (
						<div className="sort-custom-row">
							<span>{i18n.t("SortByStreet")}</span>
							<span
								className="tick--icon"
								style={{
									visibility: sort && sort[id] === "ASC" ? "visible" : "hidden",
								}}
							>
								<TickIcon />
							</span>
						</div>
					),
					icon: <AZSortIcon />,
					onClick: () => handleSortType("ASC", id),
				},
				{
					key: "za",
					label: (
						<div className="sort-custom-row">
							<span>{i18n.t("SortByStreet")}</span>
							<span
								className="tick--icon"
								style={{
									visibility: sort && sort[id] === "DESC" ? "visible" : "hidden",
								}}
							>
								<TickIcon />
							</span>
						</div>
					),
					icon: <ZASortIcon />,
					onClick: () => handleSortType("DESC", id),
				},
			],
		},
	];
	return items?.filter((item) => item?.show);
};

export const inputs = [
	{
		title: i18n.t("StatusName"),
		inputType: "text",
		field: "name",
		required: true,
	},
	{
		title: i18n.t("StatusNameDe"),
		inputType: "text",
		field: "nameDe",
		required: true,
	},
	{
		title: i18n.t("PickAColor"),
		inputType: "colorPicker",
		field: "color",
		required: true,
	},
];
