import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation/";
import {actions as orderActions} from "@sagas/orders/index";
import DataGrid from "react-data-grid";
import {withRouter} from "react-router";
import Back from "@assets/img/back__icon.svg";
import Loader from "@common/Loader";
import {useTranslation} from "react-i18next";
import HtmlTooltip from "@src/common/htmlTooltip";
import StatusBox from "@src/common/components/status-box";
import {EditRowIcon, MoveRightIcon, PreviewRowIcon} from "@src/assets/SvgComponents";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import Modal from "@common/modal";
import CreateEditOrder from "./createEditOrder";
import {statusChangeErrorRender} from "./staticData";

const UpdatedStatuses = (props) => {
	const {
		getUpdatedStatusesById,
		updatedStatus,
		match,
		navigate,
		loading,
		toggleEditOrderModal,
		getOrderPreview,
		showEditModal,
		showTimeline,
	} = props;
	const {t} = useTranslation();
	const [previewRow, setPreviewRow] = useState(false);
	const {location} = window;
	useEffect(() => {
		getUpdatedStatusesById(match.params.id);
	}, [match.params.id]);

	const hanldeBack = () => {
		if (location.href.includes("/admin/imports/order")) {
			navigate("/admin/imports/change-order-status");
		} else {
			navigate("/admin/orders?tab=statusHistory");
		}
	};

	const handlePreviewEdit = (id, type) => {
		if (type === "preview") {
			setPreviewRow(true);
		} else {
			setPreviewRow(false);
		}
		toggleEditOrderModal(true);
		getOrderPreview(id);
	};
	const statusFormater = ({row}) =>
		row?.errors !== null ? (
			<HtmlTooltip
				placement="top"
				title={
					<span style={{fontSize: "14px"}}>
						{statusChangeErrorRender(row?.errors?.message)}
					</span>
				}
			>
				<span>
					<StatusBox status={t("Failed")} />
				</span>
			</HtmlTooltip>
		) : (
			<StatusBox status={t("Success")} />
		);
	const idRowFormatter = ({row}) => (
		<div className="custom__row-formatter">
			<span>{row?.orderId ? row?.orderId : ""}</span>
			<div className="custom__row-formatter-actions">
				{row?.errors !== null && (
					<HtmlTooltip placement="top" title={<span>{t("EditOrder")}</span>}>
						<span onClick={() => handlePreviewEdit(row?.orderId, "edit")}>
							<EditRowIcon height="14" />
						</span>
					</HtmlTooltip>
				)}
				<HtmlTooltip placement="top" title={<span>{t("PreviewOrder")}</span>}>
					<span onClick={() => handlePreviewEdit(row?.orderId, "preview")}>
						<PreviewRowIcon height="14" />
					</span>
				</HtmlTooltip>
			</div>
		</div>
	);
	const columns = [
		{
			name: t("Nr"),
			key: "index",
			formatter: ({rowIdx}) => <span>{rowIdx + 1}</span>,
			width: 50,
		},
		{
			name: t("ProgressStatus"),
			key: "errors",
			formatter: statusFormater,
		},
		{
			name: t("orderId"),
			key: "orderId",
			formatter: idRowFormatter,
		},
		{
			name: t("from"),
			key: "previousStatus",
			formatter: ({row}) => (
				<div
					style={{
						height: "100%",
						display: "flex",
						alignItems: "center",
					}}
				>
					<StatusBox
						status={row?.previousSystemStatus?.previousStatus}
						name={row?.previousSystemStatus?.previousStatusName}
						color={row?.previousSystemStatus?.color}
					/>
					<MoveRightIcon />
				</div>
			),
		},
		{
			name: t("to"),
			key: "currentStatus",
			formatter: ({row}) => (
				<StatusBox
					status={row?.updatedSystemStatus?.orderStatus}
					name={row?.updatedSystemStatus?.statusName}
					color={row?.updatedSystemStatus?.color}
				/>
			),
		},
	];

	return (
		<div className="imports__details__container">
			<div className="imports__details__top" style={{marginBottom: "10px"}}>
				<img onClick={hanldeBack} src={Back} alt="" />
			</div>

			<div className="loader__and__table-orderspage">
				{!loading ? (
					<DataGrid className="rdg-light" columns={columns} rows={updatedStatus} />
				) : (
					<div className="loader__contianer">
						<Loader />
					</div>
				)}
			</div>
			<Modal
				className="add_edit_user preview"
				open={showEditModal}
				handleClose={() => toggleEditOrderModal(false)}
				width={showTimeline ? 1000 : 600}
				positionModal="right"
				overflow="hidden"
				border={previewRow && "none"}
				padding={previewRow && 0}
				display={previewRow && "flex"}
			>
				<CreateEditOrder setShowEditModal={toggleEditOrderModal} previewMode={previewRow} />
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	updatedStatus: state.app.orders.index.updatedStatus,
	loading: state.app.imports.index.loading,
	showEditModal: state.app.orders.edit.showModal,
	showTimeline: state.app.orders.edit.showTimeline,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	getUpdatedStatusesById: orderActions.getUpdatedStatusesById,
	toggleEditOrderModal: editOrderActions.toggleModal,
	getOrderPreview: orderActions.getOrderPreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdatedStatuses));
