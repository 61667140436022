import React, {useEffect, useState} from "react";
import DataGridTable from "@src/common/table/DataGridTable";
import "./styles.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as createClientActions} from "@sagas/client/create";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import PreviewFile from "@src/common/messagesComponents/PreviewFileComponent";
import {useTranslation} from "react-i18next";
import Loader from "@src/common/Loader";
import {
	columns,
	columnsSecond,
	actionsProperties,
	ContactInfoItem,
	ActionsFormatter,
} from "./helper";

const UploadDocument = ({
	navigate,
	contractDocuments,
	additionalDocuments,
	fetchData,
	fetchDataById,
	document,
	setShowModal,
	fetchContactData,
	match,
	contact,
	deleteUploadedDocument,
	loadingDocuments,
}) => {
	const {t} = useTranslation();
	const [deleteUserId, setDeleteUserId] = useState();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [previewModal, setPreviewModal] = useState({
		previewFile: "",
		showModal: false,
	});
	const handleEdit = (id) => {
		fetchDataById(id);
		setShowModal("modal");
	};
	const handleDelete = (id) => {
		setDeleteUserId(id);
		setShowDeleteModal(true);
	};
	const confirmDelete = () => {
		deleteUploadedDocument({
			id: deleteUserId?.id,
			type: deleteUserId?.typeStatisticsDocument,
		});
		setShowDeleteModal(false);
	};
	useEffect(() => {
		fetchData(+match.params.clientId);
		fetchContactData(+match.params.clientId);
	}, []);

	const actions = {
		...actionsProperties,
		formatter: ({row}) => (
			<ActionsFormatter
				row={row}
				navigate={navigate}
				handleDelete={handleDelete}
				handleEdit={handleEdit}
				setPreviewModal={setPreviewModal}
			/>
		),
	};
	const tableColumns = [...columns, actions];
	const columnsAdditional = [...columnsSecond, actions];
	return (
		<div className="upload-document-table">
			<div className="upload-document-table--body">
				<div className="upload-document-table--body--info">
					<div className="upload-document-table--body--info--container">
						<ContactInfoItem
							label={t("ContactName")}
							value={contact?.contactForDocument}
						/>
						<ContactInfoItem
							label={t("ContactEmail")}
							value={contact?.emailForDocument}
						/>
						<ContactInfoItem
							label={t("ContactPhone")}
							value={contact?.telephoneForDocument}
						/>
					</div>
				</div>
				<div className="upload-document-table--body--table">
					{loadingDocuments ? (
						<div className="loadingContainer">
							<Loader />
						</div>
					) : (
						<>
							<DataGridTable
								columns={tableColumns}
								data={contractDocuments}
								pagination={false}
								customClassName="table__wrapper__uploads"
							/>
							<DataGridTable
								columns={columnsAdditional}
								data={additionalDocuments}
								pagination={false}
								customClassName="table__wrapper__uploads"
							/>
						</>
					)}
				</div>
			</div>
			<DeleteModal
				setShowDeleteModal={setShowDeleteModal}
				confirm={() => confirmDelete()}
				open={showDeleteModal}
				handleClose={() => setShowDeleteModal(false)}
			/>
			<PreviewFile
				setPreviewPdf={setPreviewModal}
				showModal={previewModal.showModal}
				previewFile={{
					fileUrl: previewModal.previewFile,
					fileType: "pdf",
				}}
				canDownload={false}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	contractDocuments: state.app.client.create.contractDocuments,
	additionalDocuments: state.app.client.create.additionalDocuments,
	document: state.app.client.create.doc,
	contact: state.app.client.create.contact,
	loadingDocuments: state.app.client.create.loadingDocuments,
});

const mapDispatchToProps = {
	fetchData: createClientActions.getClientDocs,
	fetchDataById: createClientActions.getClientDocsById,
	fetchContactData: createClientActions.getContactData,
	deleteUploadedDocument: createClientActions.deleteUploadedDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadDocument));
