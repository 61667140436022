import {ActiveIcon, InactiveIcon} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import React from "react";

export const tabs = [
	{
		icon: <ActiveIcon />,
		name: i18n.t("Active"),
		type: "active",
	},
	{
		icon: <InactiveIcon />,
		name: i18n.t("Deactivated"),
		type: "inactive",
	},
];
