import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {actions as leadAddressesActions} from "@sagas/salesTracker/addresses";
import {actions as statusesActions} from "@sagas/salesTracker/statuses";
import {getCurrentUser} from "@utils/currentUser";
import {Skeleton} from "antd";
import CustomCollapseSkeleton from "@src/common/CustomCollapse/CustomCollapseSkeleton";
import WrapperBox from "./WrapperBox";
// eslint-disable-next-line import/no-cycle
import LeadUserActionsModal from "./LeadUsersActions/LeadUserActionsModal";
import LeadsStreetView from "./LeadsStreetView";
import LeadsMapView from "./LeadsMapView";

const HomePage = ({
	getLeads,
	getAllStatuses,
	editLeadUsers,
	users,
	setShowActionsModal,
	showActionsModal,
	selectedRows,
	setSelectedRows,
	setShowModal,
	salesTrackerStore,
	statuses,
	clearSelectedState,
	query,
	viewType,
	getAddresses,
	addressPage,
	scrollingStatus,
	setScrollingStatus,
}) => {
	const {leads, loading, page, selectedStatuses} = salesTrackerStore;
	const [filteredUser, setFilteredUser] = useState(null);
	const [initialRender, setInitialRender] = useState(true);
	const isStatusView = viewType === "statusView";
	useEffect(() => {
		if (viewType) {
			if (isStatusView) {
				const user = getCurrentUser();
				if (page === 1 || scrollingStatus?.id !== null) {
					getLeads({
						id: user?.id,
						query,
						showLoader: initialRender,
						scrollingStatus,
					});
				}
			} else if (viewType === "streetView") {
				getAddresses({query});
			}
			const filteredUser = users?.find((i) => i.id === query?.userId);
			setFilteredUser(filteredUser);
		}
	}, [
		query?.from,
		query?.to,
		query?.statuses?.length,
		query?.teamId,
		query?.agencyId,
		query?.projectId,
		query?.clientId,
		query?.search,
		query?.userId,
		query?.salesOrganizationId,
		query?.leadImportStatus,
		page,
		viewType,
		addressPage,
	]);
	const handleModal = (id) => {
		setShowModal("lead", id);
	};
	useEffect(() => {
		getAllStatuses();
		setInitialRender(false);
	}, []);
	useEffect(() => {
		clearSelectedState();
		setSelectedRows([]);
	}, [
		query?.from,
		query?.to,
		query?.statuses?.length,
		query?.teamId,
		query?.agencyId,
		query?.projectId,
		query?.clientID,
		query?.search,
		query?.userId,
		query?.salesOrganizationId,
		query?.leadImportStatus,
		isStatusView,
	]);
	return (
		<>
			<div className="sales_tracker_content">
				{!loading ? (
					<>
						{isStatusView && (
							<>
								{statuses?.map((item, index) => (
									<WrapperBox
										key={index}
										statuses={
											leads?.[item.name]?.data ? leads?.[item.name]?.data : []
										}
										handleModal={handleModal}
										totalSize={leads?.[item?.name]?.totalSize}
										status={item}
										setSelectedRows={setSelectedRows}
										selectedRows={selectedRows}
										allLength={statuses?.length}
										page={leads?.[item.name]?.page}
										totalPages={leads?.[item.name]?.totalPages}
										setScrollingStatus={setScrollingStatus}
										setShowModal={setShowModal}
									/>
								))}
							</>
						)}
						{viewType === "streetView" && (
							<LeadsStreetView
								setSelectedRows={setSelectedRows}
								selectedRows={selectedRows}
							/>
						)}
						{viewType === "mapView" && <LeadsMapView query={query} />}
					</>
				) : (
					<div className="sales_tracker_content-loader">
						{viewType === "streetView" ? (
							<CustomCollapseSkeleton />
						) : (
							Array.from({length: 7}).map((_, index) => (
								<div className="sales_tracker_content-loader-skeleton">
									<Skeleton.Input
										key={index}
										active
										style={{
											height: 35,
											borderRadius: 4,
											width: "100%",
										}}
									/>
									{Array.from({length: 5}).map((_, rowIndx) => (
										<div>
											<Skeleton.Input
												key={rowIndx}
												active
												style={{
													height: "calc(100vh / 5 - 30px)",
													borderRadius: 4,
													width: 230,
												}}
											/>
										</div>
									))}
								</div>
							))
						)}
					</div>
				)}
				<LeadUserActionsModal
					showActionsModal={showActionsModal}
					setShowActionsModal={setShowActionsModal}
					editLeadUsers={editLeadUsers}
					selectedRows={selectedRows}
					query={query}
					filteredUser={filteredUser}
					users={users}
					setSelectedRows={setSelectedRows}
					selectedStatuses={selectedStatuses}
				/>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	salesTrackerStore: state.app.salesTracker.index,
	users: state.app.users.index.usersOptions,
	statuses: state.app.salesTracker.statuses.statuses,
	addressPage: state.app.salesTracker.addresses.page,
});
const mapDispatchToProps = {
	getLeadById: salesTrackerActions.getLeadById,
	getLeads: salesTrackerActions.getLeads,
	getAddresses: leadAddressesActions.getAddresses,
	getAllStatuses: statusesActions.getAllStatuses,
	editLeadUsers: salesTrackerActions.editLeadUsers,
	editPage: salesTrackerActions.editPage,
	clearSelectedState: salesTrackerActions.clearSelectedStatuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
