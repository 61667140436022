import {ThreeDotsIcon} from "@src/assets/SvgComponents";
import CustomPopover from "@src/common/Popover";
import React, {useMemo} from "react";
import {items} from "./helper";

const SingleMessagePopover = ({
	handlePopover,
	myMessage,
	topicType,
	currentUserId,
	admin,
	open,
	setOpen,
	isMessageEmpty,
}) => {
	const filteredItems = useMemo(() => {
		let newItems = [...items];
		if (isMessageEmpty) {
			newItems = items?.filter((item) => item.key !== "copy");
		}
		// for private topics don't show delete if its not current user's message
		if (topicType === "PRIVATE") {
			if (!myMessage) {
				return newItems?.filter((item) => item.key !== "delete");
			}
			// for channels and groups don't show delete if user isn't topic's admin
		} else if (admin !== currentUserId && !myMessage) {
			return newItems?.filter((item) => item.key !== "delete");
		}
		return newItems;
	}, [admin, currentUserId, topicType, myMessage, isMessageEmpty]);
	return (
		<CustomPopover
			menu={{items: filteredItems, onClick: ({key}) => handlePopover(key)}}
			customClass="popover__actions-wrapper"
			open={open}
			handleVisibility={(visible) => setOpen(visible)}
			trigger={["click"]}
		>
			<span className="message__popover-icon">
				<ThreeDotsIcon fill="#979797" />
			</span>
		</CustomPopover>
	);
};

export default SingleMessagePopover;
