import React from "react";
import StatusBox from "@src/common/components/status-box";
import i18n from "@src/i18n";
import {
	DownloadRowIcon,
	EditRowIcon,
	EuroSign,
	PreviewRowIcon,
	ThreeDotsIcon,
} from "@src/assets/SvgComponents";
import {checkRoles} from "@src/utils/checkRoles";
import {Dropdown} from "antd";
import moment from "moment";
import {DATE_FORMAT} from "@src/common/constants";

export const columns = [
	{
		key: "vpNr",
		name: i18n.t("VPNumber"),
	},
	{
		key: "vpName",
		name: i18n.t("VPName"),
	},
	{
		key: "id",
		name: i18n.t("ID"),
	},
	{
		key: "clientName",
		name: i18n.t("Client"),
	},
	{
		key: "contractId",
		name: i18n.t("contractId"),
	},
	{
		key: "tariffName",
		name: i18n.t("TariffName"),
	},
	{
		key: "projectCategories",
		name: i18n.t("ProjectCategory"),
	},
	{
		key: "projectLocation",
		name: i18n.t("ProjectLocation"),
	},
	{
		key: "dateOfContract",
		name: i18n.t("ContractDate"),
		formatter: ({row}) => <span>{moment(row?.dateOfContract).format(DATE_FORMAT)}</span>,
	},
	{
		key: "status",
		name: i18n.t("Status"),
		formatter: ({row}) => {
			const {orderStatus, statusName, color} = row?.systemStatusView || {};
			return (
				<StatusBox
					orderStatus={orderStatus}
					flag={row?.voSettledUUID}
					name={statusName}
					color={color}
				/>
			);
		},
	},
	{
		key: "orderProductNumber",
		name: i18n.t("OrderProductNumber"),
	},
];

export const actionsFormatter = (
	row,
	handlePreviewEdit,
	handlePrice,
	downloadCsv,
	setSelectedPds,
) => {
	const items = [
		{
			key: "preview",
			label: i18n.t("PreviewOrder"),
			icon: (
				<div
					style={{
						width: "20px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<PreviewRowIcon strokeWidth={2} color="#252525" />
				</div>
			),
			onClick: () => {
				handlePreviewEdit(row.id, "preview");
			},
		},
		row?.status === i18n.t("PAIDUPPERCASE") &&
			checkRoles("editManualCorr") && {
				key: "preview",
				label: i18n.t("SetPrice"),
				icon: (
					<div
						style={{
							width: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<EuroSign />
					</div>
				),
				onClick: () => {
					handlePrice(row.id);
				},
			},
		checkRoles("editOrder") && {
			key: "edit",
			label: i18n.t("EditOrder"),
			icon: (
				<div
					style={{
						width: "20px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<EditRowIcon strokeWidth={2} stroke="#252525" />
				</div>
			),
			onClick: () => {
				handlePreviewEdit(row.id, "edit");
			},
		},
		checkRoles("downloadOrder") &&
			row?.exportPdf && {
				key: "download",
				label: i18n.t("Download"),
				icon: (
					<div
						style={{
							width: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<DownloadRowIcon strokeWidth={1.7} />
					</div>
				),
				onClick: () => {
					if (row?.clientName !== "HomeNet") {
						downloadCsv(row, "single");
					} else {
						setSelectedPds(row.fileNames?.map((file) => file));
						downloadCsv(row, "homeNetMultiplePdf");
					}
				},
			},
	];
	return (
		<Dropdown
			menu={{items}}
			overlayClassName="popover__actions-wrapper"
			placement="bottomLeft"
			trigger={["click"]}
		>
			<div className="three-dots-icon">
				<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
			</div>
		</Dropdown>
	);
};
