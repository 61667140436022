import React from "react";
import {handleDownload} from "@utils/download-file";
import {convertBytes} from "@utils/convert-bytes";
import {CloseReplayMessage} from "@src/assets/SvgComponents";
import {renderMessagesFileIcons} from "../MessagesContainer/helpers";
const MediaBox = (props) => {
	const {
		myMessage = true,
		handleOpen = () => {},
		item,
		isReply,
		isUpload,
		removeFile,
		type = "default",
	} = props;
	const shortenFileName = (fileName) =>
		fileName ? fileName.replace(/(.{7}).*(\..*)$/, "$1...$2") : "";

	const fileType =
		item?.fileType === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			? "xlsx"
			: item?.fileType;

	return (
		<div
			className={`image__and__imageName ${myMessage ? "myMessage" : ""} ${
				isReply ? "imageAndName" : ""
			}`}
			style={{
				height: isUpload && "auto",
			}}
			onClick={(e) => {
				e.stopPropagation();
				handleOpen(item);
			}}
		>
			<div className={`uploaded__image__info ${isReply ? "replyClass" : ""}`}>
				<div className="uploaded__image__info-upload">
					<span className="file_type-icon">{renderMessagesFileIcons(fileType)}</span>
					<div
						className={`uploaded__image__info-textAndKb ${
							myMessage ? "myMessage" : ""
						}`}
					>
						<span
							className={`uploaded__image__info-name ${
								isReply ? "replyInfoName" : ""
							}`}
							style={{
								color: type === "changed" && "var(--color-primary)",
							}}
						>
							{shortenFileName(item?.fileName)}
						</span>
						<span
							className={`uploaded__image__info-size ${
								myMessage ? "myMessage" : ""
							} ${isReply ? "replyFileSize" : ""}`}
						>
							{convertBytes(item?.fileSize)}
						</span>
					</div>
				</div>
				{isUpload && item?.id && (
					<div onClick={() => removeFile(item?.id)} className="upload__container-close">
						<CloseReplayMessage width={9} height={9} stroke="#979797" />
					</div>
				)}
			</div>
			{isUpload && (
				<div className="upload__container-loader">
					<span className="upload__container-loader-text">
						{item?.percentCompleted && `${item?.percentCompleted}%`}
					</span>
					<div className="upload__container-loader-box">
						<div
							className="upload__container-loader-box-progress"
							style={{width: `${item?.percentCompleted}%`}}
						></div>
					</div>
				</div>
			)}
		</div>
	);
};

export default MediaBox;
