/* eslint-disable max-len */
import React, {useEffect, useMemo, useRef, useState} from "react";
import {AllCheckedIcon, ShowInfoIcon} from "@assets/SvgComponents";
import {actions as deleteLeads} from "@sagas/salesTracker/delete";
import {connect} from "react-redux";
import {Checkbox} from "@mui/material";
import HtmlTooltip from "@src/common/htmlTooltip";
import CustomPopover from "@src/common/Popover";
import {getCurrentUser} from "@src/utils/currentUser";
import {actions as salesTrackerActions} from "@sagas/salesTracker";
import Loader from "@src/common/Loader";
import Box from "./Box";
import StatusActionsComponent from "./statuses/StatusActionsComponent";
import {renderCounterItems} from "../utils/counterItems";

const WrapperBox = ({
	status,
	statuses,
	handleModal,
	deleteLeads,
	selectedRows,
	setSelectedRows,
	allLength,
	editPage,
	totalSize,
	setScrollingStatus,
	scrollLoading,
	totalPages,
	setSelectedStatuses,
	salesTrackerStore,
	setShowModal,
	page,
}) => {
	const {query, page: sagaPage, selectedStatuses} = salesTrackerStore;
	const {ids, totalSize: selectedTotalSize} = selectedStatuses;
	const listRef = useRef();
	const {name, color, nameDe, statusType, id} = status;
	const language = localStorage.getItem("language");
	const currentUser = getCurrentUser();
	const [isChecked, setIsChecked] = useState(false);
	const handleSelectALl = (e) => {
		const isChecked = e.target.checked;
		if (!isChecked) {
			const filteredArray = selectedRows?.filter(
				(item) => !statuses.some((secItem) => secItem.statusId === item.statusId),
			);
			setSelectedRows(filteredArray);
			// filtering out deselected status
			const filteredStatuses = ids?.filter((item) => item !== id);
			setSelectedStatuses({ids: filteredStatuses, totalSize: selectedTotalSize - totalSize});
		} else {
			// adding to array selected status
			setSelectedStatuses({ids: [...ids, id], totalSize: selectedTotalSize + totalSize});
			setSelectedRows((prevSelectedRows) => {
				const existingStatusIds = prevSelectedRows.map((item) => item.id);
				const newStatuses = statuses.filter((item) => !existingStatusIds.includes(item.id));
				return [...prevSelectedRows, ...newStatuses];
			});
		}
	};
	useEffect(() => {
		setSelectedRows([]);
	}, [query]);
	const filteredLength = useMemo(
		() => selectedRows?.filter((it) => it?.statusId === id)?.length,
		[selectedRows, id],
	);
	const items = renderCounterItems(statuses, filteredLength, isChecked, totalSize);
	// eslint-disable-next-line no-nested-ternary

	const handleScroll = () => {
		if (listRef.current === undefined) return null;
		const {scrollHeight, clientHeight, scrollTop, id} = listRef.current;
		const roundedScrollTop = Math.round(scrollTop);
		const roundedClientHeight = Math.round(clientHeight);
		const roundedScrollHeight = Math.round(scrollHeight);
		if (
			roundedScrollTop + roundedClientHeight >= roundedScrollHeight - 1 &&
			page < totalPages
		) {
			setScrollingStatus({id: +id, name});
			editPage(sagaPage + 1);
		}
	};
	// if you previously selected a status , this useEffect will make selected fetched leads on scroll
	useEffect(() => {
		if (sagaPage > 1) {
			if (isChecked) {
				setSelectedRows(statuses);
			}
		}
	}, [sagaPage, statuses?.length, isChecked]);
	// this useEffect will set to true/false isChecked which is used to make checkbox on status name checked
	useEffect(() => {
		const isChecked = ids?.includes(id);
		setIsChecked(isChecked);
	}, [ids, id]);
	return (
		<>
			<div className="sales_tracker_box_container">
				<div className="sales_tracker_title" style={{backgroundColor: color}}>
					<div className="sales_tracker_title-left">
						{statuses?.[0] && (
							<div className="sales_tracker_checkbox">
								<Checkbox
									type="checkbox"
									name="selectAll"
									id="selectAll"
									onClick={(e) => handleSelectALl(e)}
									checked={isChecked}
									checkedIcon={<AllCheckedIcon />}
								/>
							</div>
						)}
						<HtmlTooltip
							placement="bottom"
							title={<span>{language === "en" ? name : nameDe}</span>}
						>
							<p className="sales_tracker_status">
								{language === "en" ? name : nameDe}
							</p>
						</HtmlTooltip>
					</div>
					<div className="sales_tracker_title-right">
						{statuses?.length > 0 && (
							<CustomPopover
								menu={{items}}
								customClass={`counter-preview-popover ${
									isChecked || filteredLength > 0 ? "all-selected" : ""
								}`}
								arrow={true}
								minWidth="100px"
							>
								<span
									className={`sales_tracker_title-right-icon ${
										filteredLength > 0 ? "active" : ""
									}`}
								>
									<ShowInfoIcon />
								</span>
							</CustomPopover>
						)}
						{(currentUser?.role === "TENANT" ||
							currentUser?.role === "BACK-OFFICE") && (
							<StatusActionsComponent
								selectedRows={selectedRows}
								status={status}
								allLength={allLength}
								setShowModal={setShowModal}
							/>
						)}
					</div>
				</div>
				<div
					ref={listRef}
					// style={{backgroundColor: `${color}55`}}
					className="sales_tracker_boxes"
					id={id}
					onScroll={handleScroll}
				>
					{statuses.map((item) => (
						<div key={item?.id}>
							<Box
								statusId={id}
								totalSize={totalSize}
								item={item}
								handleModal={handleModal}
								boxBackground={color}
								deleteLeads={deleteLeads}
								setSelectedRows={setSelectedRows}
								selectedRows={selectedRows}
								statusType={statusType}
								setSelectedStatuses={setSelectedStatuses}
								selectedStatuses={selectedStatuses}
								leads={statuses}
								setShowModal={setShowModal}
							/>
						</div>
					))}
					{scrollLoading?.loading &&
						scrollLoading?.name === name &&
						sagaPage <= totalPages && (
							<div className="add_a_card_btn">
								<Loader color="#6C1EB0" />
							</div>
						)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	salesTrackerStore: state.app.salesTracker.index,
	scrollLoading: state.app.salesTracker.index.scrollLoading,
});

const mapDispatchToProps = {
	editPage: salesTrackerActions.editPage,
	deleteLeads: deleteLeads.deleteLeads,
	setSelectedStatuses: salesTrackerActions.setSelectedStatuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(WrapperBox);
