/* eslint-disable no-restricted-syntax */
import ModalComponent from "@src/common/modal";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client/index";
import _ from "lodash";
import InputComponent from "@src/common/input";
import {Autocomplete, TextField} from "@mui/material";
import CheckBoxComponent from "@src/common/checkbox";
import moment from "moment";
import DatePickerItem from "@common/DatePicker";
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import CardInfo from "@src/common/styles/common/energy";
import ButtonComponent from "@src/common/button";
import {actions as tariffActions} from "@sagas/tariff/index";
import {actions as locationActions} from "@sagas/locations/index";
import {actions as clientCategoryActions} from "@sagas/projectCategories/clientCategory";
import {actions as createOrderActions} from "@sagas/orders/create";
import {actions as energyActions} from "@sagas/energy";
import useGenericLocations from "@src/utils/useGenericLocations";
import {useTranslation} from "react-i18next";
import Header from "./Header";
import {
	createOptionsForDropDowns,
	generateInitialValues,
	groupInputs,
	generateYupSchema,
	groupedInputs,
	toCamelCase,
	shouldHideField,
	validateBaseFields,
	baseFields,
} from "./helper";
import IbanValidator from "../components/IbanValidator";

const OrderForm = (props) => {
	const {
		open,
		handleClose,
		handleModal,
		editedOrderFields,
		fetchGenericOrderByID,
		sections,
		filter,
		makeOrder,
		data,
		loading,
		client,
		retrieveClientsFromGetAg,
		energyState,
		usersOptions,
		getEnergyTariffsDropDown,
		clients,
		fetchClientsByDropdown,
		fetchClient,
	} = props;
	const {getAgClients, energyTariffsDropdown} = energyState;
	const optionsBaseFields = {
		energyTariffsDropdown,
		clients,
	};
	const [fieldInfo, setFieldInfo] = useState({
		title: "",
		content: "",
	});
	const {options, retrieveDataFromGetAg} = useGenericLocations();
	const currentLanguage = localStorage.getItem("language");
	const {t} = useTranslation();
	const filteredFields = editedOrderFields?.filter((field) => !field.hideWeb);
	const groupedFields = groupInputs(filteredFields, sections);
	const initialValues = generateInitialValues(filteredFields, filter, data);
	const groupedByDropDowns = createOptionsForDropDowns(filteredFields);
	const groupedByObject = groupedInputs(editedOrderFields, "objectType");
	const schemaObject = !_.isEmpty(groupedFields)
		? generateYupSchema([...validateBaseFields, ...filteredFields], currentLanguage)
		: {schemaObject: {}};
	const yupSchema = !_.isEmpty(groupedFields) ? schemaObject : {};
	const handleInfoToggle = (item) => {
		const {fixedNoteDe, fixedNoteEn, name} = item;
		const titleMap = {
			meloid_ENG: "Messtlokation",
			maloid_ENG: "Marktlokation",
		};
		setFieldInfo((prev) => {
			if (prev?.[name]) {
				const updatedInfo = {...prev};
				delete updatedInfo[name];
				return updatedInfo;
			}
			return {
				...prev,
				[name]: {
					content: currentLanguage === "en" ? fixedNoteEn : fixedNoteDe,
					title: titleMap?.[name],
				},
			};
		});
	};
	const handleSubmitOrder = (values) => {
		const formattedObject = {};
		for (const key in values) {
			if (key) {
				for (let index = 0; index < editedOrderFields.length; index++) {
					const field = editedOrderFields[index];
					if (field.name === key) {
						// Check if the value is a date
						if (field.inputType === "DATE") {
							const formattedValue = values[key]
								? moment(values[key]).format("DD/MM/YYYY")
								: "";
							formattedObject[key] = formattedValue;
						} else {
							formattedObject[key] = values[key];
						}
					}
				}
			}
		}
		const filteredArray = Object.entries(formattedObject).filter(
			(item) => !editedOrderFields.some((object) => object.name === item[0]),
		);
		const obj = filteredArray.reduce((acc, [key, value]) => {
			acc[key] = value;
			return acc;
		}, {});
		const helpApp = [];
		for (const key in formattedObject) {
			if (formattedObject.hasOwnProperty(key)) {
				const value = formattedObject[key];
				for (const arrKey in groupedByObject) {
					if (groupedByObject.hasOwnProperty(arrKey)) {
						const arrValue = groupedByObject[arrKey];
						for (let i = 0; i < arrValue.length; i++) {
							if (arrValue[i].name === key) {
								const camelArrKey = toCamelCase(arrKey);
								helpApp.push({camelArrKey, key, value});
								break;
							}
						}
					}
				}
			}
		}
		const result = new Map();
		for (const element of helpApp) {
			const {camelArrKey, key, value} = element;
			if (!result.has(camelArrKey)) {
				result.set(camelArrKey, {});
			}
			result.get(camelArrKey)[key] = value;
		}
		const finalResult = Array.from(result.entries()).reduce((acc, [key, value]) => {
			acc[key] = value;
			return acc;
		}, {});
		const objNew = {
			...obj,
			projectCategoryId: null,
			tariffId: values?.tariffId,
			clientId: values?.clientId,
			products: [],
			locationId: null,
			contractId: `${client?.clientId}${moment().format("YYYYMMDD")}${
				client?.clientContractId ? client?.clientContractId + 1 : ""
			}`,
			leadId: null,
			consumption: filter?.kwYear || 0,
			userIdFromBackOffice: values?.userIdFromBackOffice,
		};
		const finalResultWithDates = {
			...finalResult.customFieldValue,
			currentDate: moment().format("DD/MM/YYYY"),
			currentYear: moment().format("YYYY"),
		};
		const mergeFinalResults = {
			...finalResult,
			customFieldValue: finalResultWithDates,
		};
		const requestObj = {...mergeFinalResults, ...objNew};
		delete requestObj.tariffValue;
		delete requestObj.categoryValue;
		delete requestObj.productValue;
		delete requestObj.locationValue;
		const {order, ...rest} = requestObj;
		const newObj = {...rest, ...order};
		delete newObj.order;
		makeOrder({
			data: {
				...newObj,
				createOrderDetails: {...newObj.createOrderDetails, userEmail: values?.userEmail},
			},
			handleClose,
		});
		resetForm();
	};
	const formik = useFormik({
		initialValues: {
			...initialValues,
			userIdFromBackOffice: null,
			clientId: null,
			tariffId: null,
		},
		validationSchema: yupSchema,
		enableReinitialize: true,
		onSubmit: (values) => handleSubmitOrder(values),
	});

	useEffect(() => {
		fetchGenericOrderByID({clientId: 0, clientContractType: "ENERGY"});
		fetchClientsByDropdown();
	}, []);
	useEffect(() => {
		if (filter?.calculatorType) {
			retrieveClientsFromGetAg(filter?.calculatorType);
		}
	}, [filter]);
	const {values, touched, errors, handleChange, setFieldValue, handleSubmit, resetForm} = formik;
	useEffect(() => {
		getEnergyTariffsDropDown({
			id: values?.clientId,
			energyType: filter?.calculatorType,
			clientType: filter?.customerType,
		});
		if (!client?.id && values?.clientId !== null) {
			fetchClient(values?.clientId);
		}
	}, [values?.clientId, filter]);

	const isDisabledFunc = (dependentFrom) => dependentFrom && !values?.[dependentFrom];
	return (
		<ModalComponent
			open={open}
			handleClose={handleClose}
			positionModal="right"
			width="700"
			customClassNames="orderForm__modal"
		>
			<Header handleClose={handleClose} handleModal={handleModal} data={data} />
			<form onSubmit={handleSubmit} className="orderForm__modal-form">
				<div className="orderForm__modal-form__inputs">
					<span className="label">{t("SelectUser")}</span>
					<Autocomplete
						options={usersOptions}
						className="custom__autocomplete"
						onChange={(_, val) => {
							setFieldValue("userIdFromBackOffice", val?.id);
						}}
						getOptionSelected={(option, val) => option?.id === val?.id}
						value={usersOptions?.find(
							(opt) => opt?.id === values?.userIdFromBackOffice,
						)}
						getOptionLabel={(value) => value?.name}
						renderOption={(props, item) => (
							<li {...props} key={item.value}>
								{item?.name}
							</li>
						)}
						renderInput={(params) => (
							<TextField {...params} className="dropdown-option" variant="outlined" />
						)}
					/>
					<div className="input-required" style={{marginBottom: 10}}>
						<div>
							{errors?.userIdFromBackOffice && touched?.userIdFromBackOffice && (
								<span className="errorClass">*{errors?.userIdFromBackOffice}</span>
							)}
						</div>
					</div>
					{baseFields?.map((item) => (
						<>
							<span className="label">{item.title}</span>
							<Autocomplete
								options={optionsBaseFields?.[item.options]}
								className="custom__autocomplete"
								onChange={(_, val) => {
									if (val !== null) {
										setFieldValue(item?.name, val?.id);
									} else if (item?.name === "clientId") {
										setFieldValue("clientId", null);
										setFieldValue("tariffId", null);
									} else {
										setFieldValue("tariffId", null);
									}
								}}
								value={
									values?.[item?.name] !== null
										? optionsBaseFields?.[item.options]?.find(
												(opt) => opt?.id === values?.[item?.name],
										  )
										: null
								}
								getOptionLabel={(value) => value?.name}
								renderOption={(props, item) => (
									<li {...props} key={item.id}>
										{item?.name}
									</li>
								)}
								disabled={item?.name === "tariffId" && values.clientId === null}
								renderInput={(params) => (
									<TextField
										{...params}
										className="dropdown-option"
										variant="outlined"
									/>
								)}
							/>
							<div className="input-required" style={{marginBottom: 10}}>
								<div>
									{errors?.[item?.name] && touched?.[item?.name] && (
										<span className="errorClass">*{errors?.[item.name]}</span>
									)}
								</div>
							</div>
						</>
					))}
					{filteredFields?.map((item, index) => {
						const {
							fixedNoteDe,
							fixedNoteEn,
							inputType,
							labelDe,
							isDisabled,
							name,
							fieldId,
							dependentFrom,
						} = item;
						const isGeneric = fixedNoteEn === "generic";
						const hideField = shouldHideField({
							item,
							filter,
							skipCollapse: true,
							values,
						});
						if (hideField) return null;
						return (
							<div className="orderForm__modal-form__inputs-singleInput" key={index}>
								<div>
									{(inputType === "TEXT" || inputType === "NUMBER") && (
										<>
											<InputComponent
												onlyPlaceholder={labelDe}
												disabled={isDisabled}
												values={values?.[name]}
												errors={errors?.[name]}
												touched={touched?.[name]}
												name={name}
												errorClass="errorClass"
												handleChange={(e) => {
													setFieldValue(name, e.target.value);
													if (name === "iban_ENG") {
														setFieldValue("bankname_ENG", "");
														setFieldValue("bic_ENG", "");
													}
													if (isGeneric) {
														retrieveDataFromGetAg(
															{...item, type: "postCode"},
															e.target.value,
														);
													}
												}}
												className={isDisabled && "disabled__input"}
											/>
											{name === "iban_ENG" && (
												<>
													<IbanValidator
														item={item}
														value={values?.[name]}
														setFieldValue={setFieldValue}
													/>
												</>
											)}
											{(fixedNoteEn || fixedNoteDe) && !isGeneric && (
												<span
													className="input__info__toggler"
													onClick={() => handleInfoToggle(item)}
												>
													<ShowInfoIcon fill="#979797" />
												</span>
											)}
											{fieldInfo?.[name] && (
												<CardInfo
													title={fieldInfo?.[name]?.title}
													content={fieldInfo?.[name]?.content}
												/>
											)}
										</>
									)}
									{inputType === "DROPDOWN" && (
										<>
											<Autocomplete
												className={
													isDisabledFunc(dependentFrom)
														? "disabled__input"
														: ""
												}
												options={
													name === "previousclient_ENG"
														? getAgClients
														: options?.[name] ||
														  groupedByDropDowns?.[fieldId] ||
														  []
												}
												value={
													values?.[item?.name] !== null
														? groupedByDropDowns?.[fieldId]?.find(
																(opt) =>
																	opt?.name === values?.[name],
														  )
														: null
												}
												onChange={(_, value) => {
													setFieldValue(name, value ? value.name : "");
													if (isGeneric && dependentFrom) {
														retrieveDataFromGetAg(
															{...item, type: "location"},
															value?.name,
															values?.[dependentFrom],
														);
													}
												}}
												disabled={isDisabledFunc(dependentFrom)}
												getOptionSelected={(option, value) =>
													option.name === value.name
												}
												getOptionLabel={(option) => option.labelDe}
												renderInput={(params) => (
													<TextField
														{...params}
														className="dropdown-option"
														variant="outlined"
														placeholder={labelDe}
													/>
												)}
											/>
											<div
												className="input-required"
												style={{marginBottom: 10}}
											>
												<div>
													{errors?.[name] && (
														<span className="errorClass">
															*{errors?.[name]}
														</span>
													)}
												</div>
											</div>
										</>
									)}
									{inputType === "CHECKBOX" && (
										<div className="custom__checkbox">
											<CheckBoxComponent
												name={name}
												label={fixedNoteDe || labelDe}
												touched={touched?.[name]}
												errors={errors?.[name]}
												placeholder={labelDe}
												handleChange={handleChange}
												checked={values?.[name]}
											/>
										</div>
									)}
									{inputType === "DATE" && (
										<div className="custom__datepicker">
											<DatePickerItem
												value={values?.[name]}
												label={labelDe}
												onChange={(newValue) => {
													if (newValue) {
														setFieldValue(
															name,
															moment(newValue)
																.startOf("day")
																.add(2, "hours")
																.valueOf(),
														);
													}
												}}
												errors={errors?.[name]}
												touched={touched?.[name]}
											/>
										</div>
									)}
								</div>
							</div>
						);
					})}
					<ButtonComponent
						buttonText="Auftrag erfassen"
						onClick={handleSubmit}
						disabled={loading}
						loading={loading}
					/>
					<CardInfo content={t("orderInformation")} />
				</div>
			</form>
		</ModalComponent>
	);
};

const mapStateToProps = (state) => ({
	editedOrderFields: state.app.client.index.editedOrderFields,
	sections: state?.app?.client?.index?.sections,
	locations: state.app.locations.index.locations,
	projectCategories: state.app.projectCategories.clientCategory.clientCategories,
	loading: state.app.orders.create.loading,
	client: state.app.client.index.client,
	energyState: state.app.energy.index,
	usersOptions: state.app.users.index.usersOptions,
	clients: state?.app?.client?.index?.energyClientsDropdown,
});
const mapDispatchToProps = {
	fetchGenericOrderByID: clientActions.fetchGenericOrderByID,
	fetchClient: clientActions.fetchClient,
	getTariffs: tariffActions.fetchTariffsByDropdown,
	fetchLocations: locationActions.fetchLocations,
	getProjectCategories: clientCategoryActions.fetchClientCategories,
	makeOrder: createOrderActions.addOrder,
	fetchClientsByDropdown: clientActions.fetchClientsByDropdown,
	retrieveClientsFromGetAg: energyActions.getGetAgClients,
	getEnergyTariffsDropDown: energyActions.getEnergyTariffsDropdown,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
