import React from "react";
import {Formik} from "formik";
import InputComponent from "@common/input";
import CloseIcon from "@common/closeIcon/CloseIcon";
import ButtonComponent from "@common/button";
import * as yup from "yup";
import i18n from "../../i18n";

const FormikForm = (props) => {
	const {loading, clientId, setShowModal, handleSubmit} = props;

	const validationSchema = yup.object().shape({
		name: yup.string().label(i18n.t("name")).required(i18n.t("CategoryNameIsReq")),
	});
	return (
		<Formik
			initialValues={{name: ""}}
			onSubmit={(values, actions) =>
				handleSubmit({values: {...values, clientId}, formActions: actions})
			}
			validationSchema={validationSchema}
		>
			{({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
				<form
					autoComplete="off"
					noValidate
					onSubmit={handleSubmit}
					className="edit__and__create__form"
				>
					<div className="close__modal__icon__and__name">
						<h1>{i18n.t("AddCategory")}</h1>
						<CloseIcon className="close__modal__icon" click={() => setShowModal()} />
					</div>
					<InputComponent
						name="name"
						placeholder={i18n.t("category")}
						type="text"
						errorClass="errorClass"
						errors={errors?.name}
						values={values?.name}
						handleBlur={handleBlur}
						handleChange={handleChange}
						touched={touched.name}
					/>
					<ButtonComponent
						disabled={loading}
						loading={loading}
						buttonText={i18n.t("CreateCategory")}
						type="submit"
					/>
				</form>
			)}
		</Formik>
	);
};

export default FormikForm;
