import React from "react";
import Form from "../Form";

const MobileView = (props) => (
	<div className="device smartphone">
		<div className="content">
			<Form {...props} />
		</div>
	</div>
);

export default MobileView;
