/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {useCallback, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {
	closestCenter,
	pointerWithin,
	rectIntersection,
	DndContext,
	DragOverlay,
	getFirstCollision,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensors,
	useSensor,
	MeasuringStrategy,
} from "@dnd-kit/core";
import {actions as clientActions} from "@sagas/client";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
	SortableContext,
	arrayMove,
	verticalListSortingStrategy,
	horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import {coordinateGetter as multipleContainersCoordinateGetter} from "./multipleContainersKeyboardCoordinates";
import {Item} from "./Item";
import {Container} from "./Container";
import StaticSection from "../components/StaticSection";
import Header from "../components/Header";
import DroppableContainer from "./DroppableContainer";
import SortableItem from "./SortableItem";
import {dropAnimation} from "./helper";

function MultipleContainers({
	handle = false,
	coordinateGetter = multipleContainersCoordinateGetter,
	getItemStyles = () => ({}),
	wrapperStyle = () => ({}),
	strategy = verticalListSortingStrategy,
	containers,
	setContainers,
	fields,
	setFields,
	toggleSectionVisibility,
	sections,
	moveOrderField,
	moveSection,
	searchValue,
	handleSearch,
}) {
	const {t} = useTranslation();
	const [activeId, setActiveId] = useState(null);
	const [activeContainerId, setActiveContainerId] = useState(null);
	const [activeIdx, setActiveIdx] = useState(null);
	const [overIdx, setOverIdx] = useState(null);
	const lastOverId = useRef(null);
	const recentlyMovedToNewContainer = useRef(false);
	const isSortingContainer = activeId ? containers?.right?.includes(activeId) : false;

	const collisionDetectionStrategy = useCallback(
		(args) => {
			if (activeId && activeId in fields) {
				return closestCenter({
					...args,
					droppableContainers: args.droppableContainers.filter(
						(container) => container.id in fields,
					),
				});
			}

			// Start by finding any intersecting droppable
			const pointerIntersections = pointerWithin(args);
			const intersections =
				pointerIntersections.length > 0
					? // If there are droppables intersecting with the pointer, return those
					  pointerIntersections
					: rectIntersection(args);
			let overId = getFirstCollision(intersections, "id");

			if (overId != null) {
				if (overId in fields) {
					const containerItems = fields[overId];
					// If a container is matched and it contains items (columns 'A', 'B', 'C')
					if (containerItems.length > 0) {
						// Return the closest droppable within that container
						overId = closestCenter({
							...args,
							droppableContainers: args.droppableContainers.filter(
								(container) =>
									container.id !== overId &&
									containerItems.includes(container.id),
							),
						})[0]?.id;
					}
				}

				lastOverId.current = overId;

				return [{id: overId}];
			}

			// When a draggable item moves to a new container, the layout may shift
			// and the `overId` may become `null`. We manually set the cached `lastOverId`
			// to the id of the draggable item that was moved to the new container, otherwise
			// the previous `overId` will be returned which can cause items to incorrectly shift positions
			if (recentlyMovedToNewContainer.current) {
				lastOverId.current = activeId;
			}

			// If no droppable is matched, return the last match
			return lastOverId.current ? [{id: lastOverId.current}] : [];
		},
		[activeId, fields],
	);
	const [clonedItems, setClonedItems] = useState(null);
	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter,
		}),
	);
	const findContainer = (id) => {
		if (id in fields) {
			return id;
		}
		const allFields = _.flatMap(Object.values(fields));
		const foundedField = allFields?.find((f) => f?.id === id);
		return foundedField?.parentId;
	};
	const getIndex = (id) => {
		const container = findContainer(id);

		if (!container) {
			return -1;
		}
		const index = fields[container].findIndex((item) => item.id === id);

		return index;
	};

	const onDragCancel = () => {
		if (clonedItems) {
			setFields(clonedItems);
		}
		setActiveId(null);
		setClonedItems(null);
	};

	useEffect(() => {
		requestAnimationFrame(() => {
			recentlyMovedToNewContainer.current = false;
		});
	}, [fields]);
	const handleDragStart = ({active}) => {
		setActiveContainerId(active.data.current.sortable.containerId);
		setActiveId(active.id);
		setClonedItems(fields);
	};
	return (
		<DndContext
			sensors={sensors}
			collisionDetection={collisionDetectionStrategy}
			measuring={{
				droppable: {
					strategy: MeasuringStrategy.Always,
				},
			}}
			onDragStart={handleDragStart}
			onDragOver={({active, over}) => {
				const overId = over?.id;
				if (overId === null || active.id in fields) {
					return;
				}
				if (overId === active.id) {
					return;
				}
				const overContainer = findContainer(overId);
				const activeContainer = findContainer(active.id);
				if (!overContainer || !activeContainer) {
					return;
				}

				if (overContainer === "customFields") {
					return;
				}
				if (activeContainer !== overContainer) {
					const activeItems = fields[activeContainer];
					const overItems = fields[overContainer];
					const overIndex = overItems.findIndex((item) => item?.id === overId);
					const activeIndex = activeItems.findIndex((item) => item?.id === active.id);
					setOverIdx(overIndex !== -1 ? overIndex : 0);
					setActiveIdx(activeIndex);
					setFields((items) => {
						let newIndex;
						if (overId in items) {
							newIndex = overItems.length + 1;
						} else {
							const isBelowOverItem =
								over &&
								active.rect.current.translated &&
								active.rect.current.translated.top >
									over.rect.top + over.rect.height;

							const modifier = isBelowOverItem ? 1 : 0;

							newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
						}
						recentlyMovedToNewContainer.current = true;
						return {
							...items,
							[activeContainer]: items[activeContainer].filter(
								(item) => item?.id !== active.id,
							),
							[overContainer]: [
								...items[overContainer].slice(0, newIndex),
								{...items[activeContainer][activeIndex], parentId: overContainer},
								...items[overContainer].slice(
									newIndex,
									items[overContainer].length,
								),
							].filter(Boolean),
						};
					});
				} else {
					const activeItems = fields[overContainer];
					const overIndex = activeItems.findIndex((item) => item?.id === overId);
					const activeIndex = activeItems.findIndex((item) => item?.id === active.id);
					setOverIdx(overIndex !== -1 ? overIndex : 0);
					setActiveIdx(activeIndex);
					setFields((fields) => ({
						...fields,
						[overContainer]: arrayMove(activeItems, activeIndex, overIndex),
					}));
				}
			}}
			onDragEnd={({active, over}) => {
				const overId = over?.id;
				const activeContainer = findContainer(active.id);
				const overContainer = findContainer(overId);
				if (overContainer === "customFields") return;
				if (active.id in fields && overId) {
					const activeIndex = containers?.right?.findIndex(
						(cont) => cont.id === active.id,
					);
					const overIndex = containers?.right?.findIndex((cont) => cont.id === overId);
					const data = {
						sectionId: activeContainer,
						targetSortNr: overIndex,
						previousSortNr: activeIndex,
					};
					const onSuccess = () => {
						setContainers((containers) => ({
							...containers,
							right: arrayMove(containers.right, activeIndex, overIndex),
						}));
					};
					moveSection({data, onSuccess});
				}
				if (!activeContainer || overId === null) {
					setActiveId(null);
					return;
				}

				const onSuccess = () => {
					setFields((items) => {
						const activeItems = items[activeContainer];
						const overItems = items[overContainer];
						const overIndex = overItems.findIndex((item) => item?.id === overId);
						const activeIndex = activeItems.findIndex((item) => item?.id === active.id);
						if (activeIndex === overIndex) {
							return items;
						}
						return {
							...items,
							[overContainer]: arrayMove(
								items[overContainer],
								activeIndex,
								overIndex,
							),
						};
					});

					setActiveContainerId(null);
					setActiveIdx(null);
					setOverIdx(null);
				};
				let data = {
					customFieldId: null,
					orderFieldId: active.id,
					targetSectionId: overContainer,
					targetSortNumber: overIdx + 1,
					previousSectionId: activeContainerId,
					previousSortNumber: activeIdx + 1,
				};
				if (activeContainerId === "customFields") {
					data = {
						...data,
						customFieldId: active.id,
						orderFieldId: null,
						previousSectionId: null,
						previousSortNumber: null,
					};
				}
				if (
					over?.data?.current?.type !== "container" &&
					active?.data?.current?.type !== "container"
				) {
					if (overContainer) {
						moveOrderField({onSuccess, data});
					} else {
						onDragCancel();
						ToastErrorComponent(t("dndTargetError"));
					}
				}
				setActiveId(null);
			}}
			onDragCancel={onDragCancel}
		>
			<div className="main__wrapper">
				<SortableContext items={containers.left} strategy={horizontalListSortingStrategy}>
					<div className="left__wrapper">
						<Header title={t("Fields")} />
						{containers?.left?.map((section) => (
							<DroppableContainer
								key={section?.id || section}
								id={section?.id || section}
								items={fields?.[section?.id || section] || []}
								disabled
								section={section}
								handleSearch={handleSearch}
								searchValue={searchValue}
							>
								<SortableContext
									items={fields?.[section?.id || section] || []}
									strategy={strategy}
									id={section?.id || section}
								>
									{fields?.[section?.id || section]?.map((item, index) => (
										<SortableItem
											disabled={isSortingContainer}
											key={`${item?.name}--${section?.id}${index + 11111}`}
											id={item?.id}
											index={index}
											handle={handle}
											style={getItemStyles}
											wrapperStyle={wrapperStyle}
											containerId={section?.id || section}
											getIndex={getIndex}
											item={item}
										/>
									))}
								</SortableContext>
							</DroppableContainer>
						))}
					</div>
				</SortableContext>
				<SortableContext items={containers.right} strategy={horizontalListSortingStrategy}>
					<div className="right__wrapper">
						<Header
							title={t("orderForm")}
							withActions
							sections={sections}
							toggleSectionVisibility={toggleSectionVisibility}
						/>
						<div className="right__wrapper-items">
							{containers?.right?.map((section) => {
								if (section.show === false) return null;
								return (
									<>
										{section === "staticSection" ? (
											<StaticSection />
										) : (
											<DroppableContainer
												key={section?.id || section}
												id={section?.id || section}
												items={fields?.[section?.id || section] || []}
												disabled={false}
												section={section}
											>
												<SortableContext
													items={fields?.[section?.id || section] || []}
													strategy={strategy}
													id={section?.id || section}
												>
													{fields?.[section?.id || section]?.map(
														(item, index) => (
															<SortableItem
																disabled={isSortingContainer}
																key={`${item?.name}--${
																	section?.id
																}${index + 11111}`}
																id={item?.id}
																index={index}
																handle={handle}
																style={getItemStyles}
																wrapperStyle={wrapperStyle}
																containerId={section?.id || section}
																getIndex={getIndex}
																item={item}
																orderField
															/>
														),
													)}
												</SortableContext>
											</DroppableContainer>
										)}
									</>
								);
							})}
						</div>
					</div>
				</SortableContext>
			</div>
			{createPortal(
				<DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
					{activeId
						? containers?.right?.includes(activeId)
							? renderContainerDragOverlay(activeId)
							: renderSortableItemDragOverlay(activeId)
						: null}
				</DragOverlay>,
				document.body,
			)}
		</DndContext>
	);

	function renderSortableItemDragOverlay(id) {
		return (
			<Item
				value={id}
				handle={handle}
				style={getItemStyles({
					containerId: findContainer(id),
					overIndex: -1,
					index: getIndex(id),
					value: id,
					isSorting: true,
					isDragging: true,
					isDragOverlay: true,
				})}
				wrapperStyle={wrapperStyle({index: 0})}
				dragOverlay
			/>
		);
	}

	function renderContainerDragOverlay(containerId) {
		return (
			<Container
				style={{
					height: "100%",
				}}
				shadow
				unstyled={false}
			>
				{fields?.[containerId?.id || containerId]?.map((item, index) => (
					<Item
						key={item}
						value={item}
						handle={handle}
						item={item}
						style={getItemStyles({
							containerId,
							overIndex: -1,
							index: getIndex(item.id),
							value: item,
							isDragging: false,
							isSorting: false,
							isDragOverlay: false,
						})}
						wrapperStyle={wrapperStyle({index})}
					/>
				))}
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	sections: state.app.client.index.sections,
});
const mapDispatchToProps = {
	toggleSectionVisibility: clientActions.toggleSectionVisibility,
	moveOrderField: clientActions.moveOrderField,
	moveSection: clientActions.moveSection,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MultipleContainers));
