import React from "react";
import {
	ApprovedIcon,
	BonusIcon,
	BonusSettledIcon,
	CancelledIcon,
	DeliveredIcon,
	DraftIcon,
	EuroSign,
	FinishedIcon,
	IconBonus,
	IconDelivered,
	IconFinished,
	InProgressIcon,
	PaidIcon,
	PendingIcon,
	ProgressIcon,
	PublishedIcon,
	ReactivatedIcon,
	ReactivatedStatus,
	ScheduleIcon,
	SettledIcon,
	SignatureIcon,
	SingatureMissIcon,
	SuccessIcon,
	WarningIcon,
} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";

export const CONDITION_ENUMS_FOR_CALCULATIONS = [
	"PersonnelDevelopment",
	"OwnContracts",
	"InductionBonus",
	"MobilityBonus",
	"Overhead",
];

export const baseOrderStatusesObj = {
	PENDING: {
		icon: <ProgressIcon />,
		color: "#EECB64",
		label: i18n.t("Pending"),
		dropdownIcon: <PendingIcon />,
	},
	PAID: {
		icon: <EuroSign stroke="#fff" />,
		color: "#6DB070",
		label: i18n.t("Paid"),
		dropdownIcon: <PaidIcon />,
	},
	SETTLED: {
		icon: <SettledIcon />,
		color: "#5D7E93",
		label: i18n.t("Settled"),
		dropdownIcon: <></>,
	},
	CANCELLED: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("cancelled"),
		dropdownIcon: <CancelledIcon />,
	},
	FINISHED: {
		icon: <FinishedIcon />,
		color: "#9A729E",
		label: i18n.t("Finished"),
		dropdownIcon: <IconFinished />,
	},
	CONTRACT_REACTIVATED: {
		icon: <ReactivatedStatus />,
		color: "#2A3CAE",
		label: i18n.t("ContractReactivated"),
		dropdownIcon: <ReactivatedIcon />,
	},
	NOSIGNATURE: {
		icon: <SingatureMissIcon />,
		color: "#B6B6B6",
		label: i18n.t("NoSignature"),
		dropdownIcon: <SignatureIcon />,
	},
	DELIVERED: {
		icon: <DeliveredIcon />,
		color: "#68ACE8",
		label: i18n.t("Delivered"),
		dropdownIcon: <IconDelivered />,
	},
	APPROVED: {
		icon: <SuccessIcon />,
		color: "#5673C2",
		label: i18n.t("approved"),
		dropdownIcon: <ApprovedIcon />,
	},
	BONUS: {
		icon: <BonusIcon />,
		color: "#C57BC4",
		label: i18n.t("Bonus"),
		dropdownIcon: <IconBonus />,
	},
	BONUS_SETTLED: {
		icon: <BonusSettledIcon />,
		color: "#008C8C",
		label: i18n.t("bonusSettled"),
		dropdownIcon: <BonusSettledIcon stroke="#252525" />,
	},
	EXCLUDE_SETTLEMENT: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("cancelled"),
		dropdownIcon: <CancelledIcon />,
	},
	[i18n.t("Warning")]: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("Warning"),
		orderStatus: false,
	},
	[i18n.t("Success")]: {
		icon: <SuccessIcon />,
		color: "#6DB070",
		label: i18n.t("Success"),
		orderStatus: false,
	},
	[i18n.t("Imported")]: {
		icon: <SuccessIcon />,
		color: "#6DB070",
		label: i18n.t("Imported"),
		orderStatus: false,
	},
	[i18n.t("Failed")]: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("Failed"),
		orderStatus: false,
	},
	[i18n.t("In Process")]: {
		icon: <ProgressIcon />,
		color: "#EECB64",
		label: i18n.t("In Process"),
		orderStatus: false,
	},
	[i18n.t("Revert Success")]: {
		icon: <SuccessIcon />,
		color: "#6DB070",
		label: i18n.t("Revert Success"),
		orderStatus: false,
	},
	[i18n.t("Revert In Process")]: {
		icon: <ProgressIcon />,
		color: "#EECB64",
		label: i18n.t("Revert In Process"),
		orderStatus: false,
	},
	[i18n.t("InProgress")]: {
		icon: <InProgressIcon />,
		color: "#FF8A00",
		label: i18n.t("InProgress"),
		orderStatus: false,
	},
	[i18n.t("PUBLISHED")]: {
		icon: <PublishedIcon />,
		color: "#157AE5",
		label: i18n.t("Published"),
		orderStatus: false,
	},
	[i18n.t("DRAFT")]: {
		icon: <DraftIcon />,
		color: "#EC8C3A",
		label: i18n.t("Draft"),
		orderStatus: false,
	},
	[i18n.t("Scheduled")]: {
		icon: <ScheduleIcon fill="#fff" />,
		color: "#3B505D",
		label: i18n.t("Scheduled"),
		orderStatus: false,
	},
};

export const baseOrderStatusesArray = Object.keys(baseOrderStatusesObj)
	?.filter((key) => {
		const status = baseOrderStatusesObj[key];
		return status.orderStatus !== false;
	})
	?.map((key) => {
		const status = baseOrderStatusesObj[key];
		return {
			label: status.label,
			value: key,
		};
	});

export const SETTLED_ACTION_STATUSES = ["SETTLED", "BONUS_SETTLED", "CONTRACT_REACTIVATED"];
