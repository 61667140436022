import {Box} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";

const centerStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};
const rightStyle = {
	position: "absolute",
	top: 0,
	right: 0,
	height: "100vh",
	maxHeight: "100vh",
};
const positionStyles = {
	center: centerStyle,
	right: rightStyle,
};

const ModalComponent = (props) => {
	const {
		open,
		handleClose,
		className,
		width = "500",
		onScroll,
		customClassNames = "",
		positionModal = "center",
		hideBackdrop = false,
		overflow = "auto",
		borderRadius = "10px 0px 0px 10px",
		padding = "20px",
		display = "block",
		border = "solid 1px #ececec",
	} = props;
	let styles = {
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		p: 4,
		outline: "none",
		overflow,
		maxHeight: "85%",
		display: "block",
	};

	styles = {...styles, ...positionStyles[positionModal]};
	return (
		<Modal
			hideBackdrop={hideBackdrop}
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			disableEnforceFocus={props.disableEnforceFocus}
		>
			<Box style={styles} onScroll={onScroll}>
				<div
					style={{
						border,
						padding,
						backgroundColor: "white",
						width: `${width}px`,
						borderRadius,
						display,
					}}
					className={`popup_${className} ${customClassNames}`}
				>
					{props.children}
				</div>
			</Box>
		</Modal>
	);
};

export default ModalComponent;
