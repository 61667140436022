/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import produce from "immer";
import _ from "lodash";
import {put, select, takeLatest} from "redux-saga/effects";
import axios from "axios";
import Logger from "@utils/logger";
import {actions as navActions} from "@sagas/navigation/index";
import createAction from "../../../../utils/action-creator";
const PREFIX = "@app/notifications/";
const logger = new Logger("Notifications");

export const GET_SYSTEM_NOTIFICATIONS = `${PREFIX}GET_SYSTEM_NOTIFICATIONS`;
export const GET_SYSTEM_NOTIFICATIONS_SUCCESS = `${PREFIX}GET_SYSTEM_NOTIFICATIONS_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const EDIT_TOTAL_PAGES = `${PREFIX}EDIT_TOTAL_PAGES`;
export const SET_PAGE = `${PREFIX}SET_PAGE`;
export const SET_SCROLL_LOADING = `${PREFIX}SET_SCROLL_LOADING`;
export const GET_UNREAD_NOTIFICATIONS = `${PREFIX}GET_UNREAD_NOTIFICATIONS`;
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = `${PREFIX}GET_UNREAD_NOTIFICATIONS_SUCCESS`;
export const READ_NOTIFICATION = `${PREFIX}READ_NOTIFICATION`;
export const READ_NOTIFICATION_SUCCESS = `${PREFIX}READ_NOTIFICATION_SUCCESS`;
export const RESET_STATE = `${PREFIX}RESET_STATE`;

const _state = {
	systemNotifications: [],
	loading: false,
	page: 1,
	size: 10,
	totalPages: 1,
	scrollLoading: false,
	count: 0,
	unSeenIds: [],
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case GET_SYSTEM_NOTIFICATIONS_SUCCESS:
				draft.count = 0;
				draft.systemNotifications =
					state?.page > 1
						? [...state?.systemNotifications, ...payload?.data]
						: payload?.data?.map((notif) => {
								if (state?.unSeenIds?.includes(notif?.id)) {
									return {
										...notif,
										highlighted: true,
									};
								}
								return notif;
						  });
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;

			case EDIT_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case SET_PAGE:
				draft.page = state?.page + 1;
				break;
			case SET_SCROLL_LOADING:
				draft.scrollLoading = payload;
				break;
			case GET_UNREAD_NOTIFICATIONS_SUCCESS:
				if (payload === "increment") {
					draft.count = state?.count + 1;
				} else {
					draft.count = payload?.count;
					draft.unSeenIds = payload?.ids;
				}
				break;
			case READ_NOTIFICATION_SUCCESS:
				draft.systemNotifications = state.systemNotifications.map((i) => {
					if (i?.id === payload) {
						return {
							...i,
							isRead: true,
						};
					}
					return i;
				});
				break;
			case RESET_STATE:
				draft.systemNotifications = [];
				draft.page = 1;
				draft.size = 10;
				draft.totalPages = 1;
				break;
			default:
				return state;
		}
	});
export default reducer;
export const actions = {
	getSystemNotifications: (payload) => createAction(GET_SYSTEM_NOTIFICATIONS, {payload}),
	getSystemNotificationsSuccess: (payload) =>
		createAction(GET_SYSTEM_NOTIFICATIONS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	editTotalPages: (payload) => createAction(EDIT_TOTAL_PAGES, {payload}),
	setPage: (payload) => createAction(SET_PAGE, {payload}),
	scrollLoading: (payload) => createAction(SET_SCROLL_LOADING, {payload}),
	getUnreadNotificationsCount: (payload) => createAction(GET_UNREAD_NOTIFICATIONS, {payload}),
	getUnreadNotificationsCountSuccess: (payload) =>
		createAction(GET_UNREAD_NOTIFICATIONS_SUCCESS, {payload}),
	readNotification: (payload) => createAction(READ_NOTIFICATION, {payload}),
	readNotificationSuccess: (payload) => createAction(READ_NOTIFICATION_SUCCESS, {payload}),
	resetState: (payload) => createAction(RESET_STATE, {payload}),
};

export const sagas = {
	*getSystemNotifications() {
		const {size, page} = yield select((state) => state.app.notifications.index);
		if (page > 1) {
			yield put(actions.scrollLoading(true));
		} else {
			yield put(actions.setLoading(true));
		}
		try {
			const {data} = yield axios.get(`/user-notification?page=${page}&size=${size}`);
			yield put(actions.getSystemNotificationsSuccess(data?.data));
			yield put(actions.editTotalPages(data.data.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
			yield put(actions.scrollLoading(false));
		}
	},
	*getUnreadNotificationsCount() {
		try {
			const {data} = yield axios.get(`/user-notification/unread`);
			yield put(actions.getUnreadNotificationsCountSuccess(data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*readNotification({payload}) {
		const {id, leadId} = payload;
		try {
			const {data} = yield axios.get(`/user-notification/read/${id}`);
			yield put(actions.readNotificationSuccess(data?.data));
			yield put(
				navActions.navigate(
					// eslint-disable-next-line max-len
					`/admin/salestracker?tab=salesTracker&query=%7B%22from%22%3Anull%2C%22to%22%3Anull%2C%22statuses%22%3Anull%2C%22teamId%22%3Anull%2C%22agencyId%22%3Anull%2C%22projectId%22%3Anull%2C%22clientId%22%3Anull%2C%22search%22%3Anull%2C%22userId%22%3Anull%2C%22salesOrganizationId%22%3Anull%2C%22leadImportStatus%22%3Anull%7D&view-type=statusView&modal=lead&id=${leadId}`,
				),
			);
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_SYSTEM_NOTIFICATIONS, sagas.getSystemNotifications);
	yield takeLatest(GET_UNREAD_NOTIFICATIONS, sagas.getUnreadNotificationsCount);
	yield takeLatest(READ_NOTIFICATION, sagas.readNotification);
};
