import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as locationActions} from "@sagas/locations";
import {actions as locationDeletedActions} from "@sagas/locations/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Users index");

const PREFIX = "@app/locations/restore";
export const RESTORE = `${PREFIX}RESTORE`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;

const _state = {
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	restore: (payload) => createAction(RESTORE, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
};

export const sagas = {
	*restore({payload}) {
		try {
			const {locationId} = payload;
			const locations = yield select((state) => state.app.locations.deleted.list);
			const location = locations.find((e) => e.id === locationId);
			yield axios.put(`/location/restore/${locationId}`);
			yield put(locationDeletedActions.delete(locationId));
			yield put(locationActions.addLocationOnState(location));
			yield put(locationActions.totalSizeIncrease());
			ToastSuccesComponent(i18n.t("CategoryRestoreSuccess"));
			payload.handleLocationModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(RESTORE, sagas.restore);
};
