import React from "react";
import {actions as createActions, actions as createMessagesActions} from "@sagas/messages/create";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import Form from "@src/common/Form";

const CreateDirectMessage = ({
	show,
	close,
	loading,
	usersByTenant,
	setNames,
	setList,
	listOfIds,
	listOfNames,
	createGroupOrPrivateMessage,
	createTopicLoader,
}) => {
	const {t} = useTranslation();
	const usersOptions = usersByTenant?.map((user) => ({
		...user,
		name: `${user?.firstName} ${user?.lastName}`,
		value: user?.id,
		image: user?.image,
	}));
	const inputs = [
		{
			inputType: "dropdown",
			multiple: true,
			title: t("ChooseUsers"),
			options: usersOptions,
			required: true,
			field: "users",
			inputValue: "obj",
			onChange: (value) => {
				setList([...listOfIds, value.id]);
				setNames([...listOfNames, value.name]);
			},
		},
	];
	const formData = {
		inputs,
		title: t("startConversation"),
		submitFn: (values, actions) => createGroupOrPrivateMessage(),
	};
	return <Form open={show} handleClose={close} loading={createTopicLoader} formData={formData} />;
};

const mapStateToProps = (state) => ({
	loading: state.app.messages.create.channelLoading,
	createTopicLoader: state.app.messages.create.createTopicLoader,
	usersByTenant: state.app.users.index.usersByTenant,
	listOfIds: state.app.messages.create.listOfIds,
	listOfNames: state.app.messages.create.listOfNames,
});

const mapDispatchToProps = {
	createChannel: createActions.createChannel,
	setList: createMessagesActions.setList,
	setNames: createMessagesActions.setNames,
	createGroupOrPrivateMessage: createMessagesActions.createGroupOrPrivateMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateDirectMessage));
