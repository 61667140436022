/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from "react";
import DataGrid, {Row} from "react-data-grid";
import {actions as projectCategoryACtions} from "@sagas/projectCategories";
import {
	actions as addTariffForLocationActions,
	filterData,
} from "@sagas/tariff/tariffByLocations/create";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import moment from "moment";
import i18n from "../../i18n";

const autoFocusAndSelect = (input) => {
	if (input?.focus && input?.select) {
		input.focus();
		input.select();
	}
};

const EditableTable = (props) => {
	const {
		projectCategories,
		locationId,
		fillLocationTariff,
		singleTariff,
		data = [],
		cleanLocationTariff,
		match,
		index,
		disable,
	} = props;
	const columnObj = {
		editor: ({row, column, onRowChange, onClose}) => (
			<>
				{disable ? (
					<span>{row[column.key]}</span>
				) : (
					<input
						ref={autoFocusAndSelect}
						className="rdg-text-editor"
						value={row[column.key]}
						onChange={(event) => {
							onRowChange({
								...row,
								[column.key]: event.target.value,
							});
						}}
						onBlur={() => onClose(true)}
						type="number"
						min={0}
					/>
				)}
			</>
		),
		formatter: ({row, column}) => (
			<>
				<div className="commission-formatter commission-formatter-border">
					{" "}
					{row[column?.key] || 0}€
				</div>
			</>
		),
		headerRenderer: ({column}) => (
			<div className="editable-formatter">
				<div className="editable-formatter-header">
					<div className="editable-formatter-header-element first">
						{column.parentId ? i18n.t("Bonus") : i18n.t("ProvisionCategory")}
					</div>
					<div className="editable-formatter-header-element editable-formatter-breakText">
						{column?.name}
					</div>
				</div>
			</div>
		),
	};
	const rowsExample = [
		// {id: 1, roles: i18n.t("TariffComission")},
		{id: 5, roles: i18n.t("TariffEmployee")},
		{id: 5, roles: i18n.t("TariffLeader"), leader: true, team: true},
		{id: 3, roles: i18n.t("TariffSP")},
		{id: 6, roles: i18n.t("TariffAgency")},
		{id: 7, roles: i18n.t("TariffAgencySP")},
		{id: 11, roles: i18n.t("TariffEmployeeShop")},
		{id: 12, roles: i18n.t("EmployeeTL").toUpperCase()},
		{id: 13, roles: i18n.t("EmployeeAB").toUpperCase()},
		{id: 14, roles: i18n.t("Agency70").toUpperCase()},
		// {id: 3, roles: "TEAM-SP", team: true},
		// {id: 5, roles: "TEAM-EMPLOYEE", team: true},
	];
	const [rows, setRows] = useState(rowsExample);
	const [newRows, setNewRows] = useState([]);
	const [columnsChanged, setColumnsChanged] = useState(false);
	const [columns, setColumns] = useState([
		{
			key: "roles",
			name: i18n.t("UserRoles"),
		},
	]);
	useEffect(() => {
		if (data?.data) {
			setColumnsChanged(true);
		}
	}, [data]);

	useEffect(() => {
		const array = [];
		let filteredRows = [...rows];
		if (projectCategories?.length > 0) {
			projectCategories.map((item) => {
				array.push({
					...columnObj,
					key: item.id,
					name: item.name,
					getRowMetaData: (row) => row,
					parentId: item?.parentId,
				});
				// set [key.value] to 0 first time
				if ((!match?.params?.tariffId || data?.isNew) && !disable) {
					filteredRows = filteredRows?.map((row) => ({
						...row,
						[item?.id]: row?.[item?.id] ? row?.[item?.id] : 0,
					}));
					fillLocationTariff([
						{
							locationIds: locationId,
							roleProvision: filterData([
								{
									locationId,
									data: filteredRows,
									tableName: data.tableName,
									from: moment(data?.from).add(12, "hours").valueOf(),
									to: moment(data?.to).add(12, "hours").valueOf(),
								},
							]),
							tableName: data.tableName,
							tableUUID: data?.tableUUID,
							from: moment(data?.from).add(12, "hours").valueOf(),
							to: moment(data?.to).add(12, "hours").valueOf(),
							index,
						},
					]);
				}
			});
			setColumns([].concat({key: "roles", name: i18n.t("UserRoles")}, array));
			setRows([...filteredRows]);
		}
		return () => {
			cleanLocationTariff();
			// clearOptionsProjectCategories();
		};
		// eslint-disable-next-line
	}, [projectCategories?.length])

	useEffect(() => {
		if (data?.data?.[0]) {
			setNewRows(data?.data);
			setRows(data?.data);
		}
		// eslint-disable-next-line
	}, [singleTariff])

	const rowRenderer = (row) => (
		<div key={row.rowIdx}>
			<Row
				{...row}
				key={row.rowIdx}
				className={`${row?.row?.errors?.length ? "error" : ""} ${
					row?.row?.child ? "data__grid__row__child" : ""
				}`}
			/>
		</div>
	);

	useEffect(() => {
		if (match.params.tariffId && data?.tableUUID && !data.isNew && !disable) {
			fillLocationTariff([
				{
					locationIds: locationId,
					roleProvision: filterData([
						{
							locationId,
							data: data?.data,
							tableName: data?.tableName,
							from: moment(data?.from).add(12, "hours").valueOf(),
							to: moment(data?.to).add(12, "hours").valueOf(),
						},
					]),
					tableName: data?.tableName,
					from: moment(data?.from).add(12, "hours").valueOf(),
					to: moment(data?.to).add(12, "hours").valueOf(),
					tableUUID: data?.tableUUID,
					index,
				},
			]);
		}
	}, [match.params.tariffId]);

	const handleUpdateRows = (elem) => {
		setRows(elem);
		setNewRows(elem);
		fillLocationTariff([
			{
				locationIds: locationId,
				roleProvision: filterData([
					{
						locationId,
						data: elem,
						tableName: data.tableName,
						from: moment(data?.from).add(12, "hours").valueOf(),
						to: moment(data?.to).add(12, "hours").valueOf(),
					},
				]),
				tableName: data.tableName,
				from: moment(data?.from).add(12, "hours").valueOf(),
				to: moment(data?.to).add(12, "hours").valueOf(),
				tableUUID: data?.tableUUID,
				index,
			},
		]);
	};

	const updateRows = newRows.filter((element) => element?.roles !== "COMMISSION");
	return (
		<>
			<DataGrid
				className="rdg-light"
				columns={columns}
				// CHANGED NEWROWS TO UPDATEDROWS (REMOVED COMMISSIONS ROW)
				// AS REQUIRED IT MAY BE RETURNED BASED ON CLIENTS REQUEST
				rows={updateRows.length > 0 ? updateRows : rows}
				// rows={newRows.length > 0 ? newRows : rows}
				enableCellSelect={true}
				onRowsChange={(e) => handleUpdateRows(e)}
				rowRenderer={rowRenderer}
				rowHeight={44}
				headerRowHeight={60}
			/>
		</>
	);
};
const mapStateToProps = (state) => ({
	projectCategories: state.app.projectCategories.index.optionsProjectCategories,
	locationTariffsArray: state.app.tariffByLocations.create.locationTariffsArray,
	singleTariff: state.app.tariffByLocations.index.singleTariff,
});
const mapDispatchToProps = {
	fetchProjectCategories: projectCategoryACtions.fetchProjectCategoriesByDropdown,
	clearOptionsProjectCategories: projectCategoryACtions.clearOptionsProjectCategories,
	fillLocationTariff: addTariffForLocationActions.fillLocationTariff,
	cleanLocationTariff: addTariffForLocationActions.cleanLocationTariff,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditableTable));
