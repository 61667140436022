import Logger from "@src/utils/logger";
import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {ENDPOINT} from "@src/utils/endpoint";
import createAction from "../../../../utils/action-creator";
import {leadDataInitialValues} from "./utils";

const logger = new Logger("Sagas>salesTracker>Addresses");

const PREFIX = "@app/salesTracker/";

export const GET_ADDRESSES = `${PREFIX}GET_ADDRESSES`;
export const GET_ADDRESSES_SUCCESS = `${PREFIX}GET_ADDRESSES_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_REFETCH_LOADING = `${PREFIX}SET_REFETCH_LOADING`;
export const SET_PAGE = `${PREFIX}SET_PAGE`;
export const GET_LEADS_BY_ADDRESS = `${PREFIX}GET_LEADS_BY_ADDRESS`;
export const GET_LEADS_BY_ADDRESS_SUCCESS = `${PREFIX}GET_LEADS_BY_ADDRESS_SUCCESS`;
export const SET_LEADS_LOADING = `${PREFIX}SET_LEADS_LOADING`;
export const EDIT_LEADS_PAGE = `${PREFIX}EDIT_LEADS_PAGE`;
export const EDIT_LEADS_SIZE = `${PREFIX}EDIT_LEADS_SIZE`;
export const RESET_PAGINATION = `${PREFIX}RESET_PAGINATION`;
export const CLEAR_FETCHED_PAGES = `${PREFIX}CLEAR_FETCHED_PAGES`;
export const DELETE_LEAD_ON_STATE = `${PREFIX}DELETE_LEAD_ON_STATE`;
export const SET_IS_COUNTING = `${PREFIX}SET_IS_COUNTING`;

const _state = {
	addresses: [],
	page: 1,
	totalPages: 1,
	loading: false,
	size: 30,
	totalSize: 1,
	refetchLoading: false,
	leadsData: {},
	counting: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_PAGE:
				draft.page = payload;
				break;
			case GET_ADDRESSES_SUCCESS:
				if (payload.type === "update") {
					const updatedData = payload.data.map((item) => ({
						...item,
						countLead: "counting",
					}));
					draft.addresses =
						state.page > 1 ? [...state.addresses, ...updatedData] : updatedData;
				} else {
					draft.addresses =
						state.page > 1
							? state.addresses.map((addr) => {
									const matchingPayload = payload.data.find(
										(pay) => addr.id === pay.id,
									);
									return matchingPayload ? {...matchingPayload} : addr;
							  })
							: payload.data;
				}
				draft.totalPages = Math.ceil(payload.totalSize / state.size);
				draft.totalSize = payload.totalSize;
				break;
			case SET_REFETCH_LOADING:
				draft.refetchLoading = payload;
				break;
			case GET_LEADS_BY_ADDRESS_SUCCESS:
				const {data, address} = payload;
				const previusAddressData = state.leadsData[address.id] || leadDataInitialValues;
				draft.leadsData = {
					...state.leadsData,
					[address.id]: {
						...previusAddressData,
						...data,
						data:
							previusAddressData?.page > 1
								? [...(previusAddressData.data || []), ...data.data]
								: data.data,
						totalPages: data.totalPages,
						fetchedPages: [...previusAddressData.fetchedPages, data.page],
					},
				};
				break;
			case EDIT_LEADS_PAGE:
				const {addressid, newPage} = payload;
				const previousAddressState = state.leadsData[addressid] || leadDataInitialValues;
				draft.leadsData = {
					...state.leadsData,
					[addressid]: {
						...previousAddressState,
						page: newPage,
					},
				};
				break;
			case EDIT_LEADS_SIZE:
				const {addressID, newSize} = payload;
				const previousAddress = state.leadsData[addressID] || leadDataInitialValues;
				draft.leadsData = {
					...state.leadsData,
					[addressID]: {
						...previousAddress,
						size: newSize,
						fetchedPages: [],
					},
				};
				break;
			case SET_LEADS_LOADING:
				const {addressId, value} = payload;
				const previousAddressInfo = state.leadsData[addressId] || leadDataInitialValues;
				draft.leadsData = {
					...state.leadsData,
					[addressId]: {
						...previousAddressInfo,
						loading: value,
					},
				};
				break;
			case RESET_PAGINATION:
				draft.page = 1;
				draft.size = 30;
				draft.leadsData = {};
				break;
			case CLEAR_FETCHED_PAGES:
				const leadAddressData =
					state.leadsData[payload?.addressId] || leadDataInitialValues;
				draft.leadsData = {
					...state.leadsData,
					[payload.addressId]: {
						...leadAddressData,
						fetchedPages: [],
					},
				};
				break;
			case SET_IS_COUNTING:
				draft.counting = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getAddresses: (payload) => createAction(GET_ADDRESSES, {payload}),
	getAddressesSuccess: (payload) => createAction(GET_ADDRESSES_SUCCESS, {payload}),
	setPage: (payload) => createAction(SET_PAGE, {payload}),
	setRefetchLoading: (payload) => createAction(SET_REFETCH_LOADING, {payload}),
	getLeadsByAddress: (payload) => createAction(GET_LEADS_BY_ADDRESS, {payload}),
	getLeadsByAddressSuccess: (payload) => createAction(GET_LEADS_BY_ADDRESS_SUCCESS, {payload}),
	setLeadsLoading: (payload) => createAction(SET_LEADS_LOADING, {payload}),
	editLeadsPage: (payload) => createAction(EDIT_LEADS_PAGE, {payload}),
	editLeadsSize: (payload) => createAction(EDIT_LEADS_SIZE, {payload}),
	resetPagination: (payload) => createAction(RESET_PAGINATION, {payload}),
	clearFetchedPages: (payload) => createAction(CLEAR_FETCHED_PAGES, {payload}),
	isCounting: (payload) => createAction(SET_IS_COUNTING, {payload}),
};

export const sagas = {
	*getAddresses({payload}) {
		const {page, size} = yield select((state) => state.app.salesTracker.addresses);
		if (page === 1) {
			yield put(actions.setLoading(true));
		} else {
			yield put(actions.setRefetchLoading(true));
		}
		const {query, resetPage} = payload;
		try {
			const {data} = yield axios.post(
				ENDPOINT.GET_LEAD_ADDRESSES.replace(":page", resetPage ? 1 : page).replace(
					":size",
					size,
				),
				query,
			);
			yield put(
				actions.getAddressesSuccess({
					data: data.data.data,
					totalSize: data.data.totalSize,
					type: "update",
				}),
			);
			yield put(actions.setLoading(false));
			yield put(actions.setRefetchLoading(false));
			const countPayload = {
				leadsFilter: query,
				addressViewList: data?.data?.data,
			};
			yield put(actions.isCounting(true));
			const {data: countResponse} = yield axios.post(
				ENDPOINT.COUNT_LEAD_ADDRESSES,
				countPayload,
			);
			yield put(
				actions.getAddressesSuccess({
					data: countResponse.data.addressViewList,
					totalSize: countResponse.data.totalSize,
					type: "override",
				}),
			);
			yield put(actions.isCounting(false));
		} catch (error) {
			logger.error(error);
			yield put(actions.isCounting(false));
			yield put(actions.setLoading(false));
			yield put(actions.setRefetchLoading(false));
		}
	},
	*getLeadsByAddress({payload}) {
		const {addressRequest} = payload;
		yield put(actions.setLeadsLoading({addressId: addressRequest.id, value: true}));
		try {
			const {leadsData} = yield select((state) => state.app.salesTracker.addresses);
			const page = leadsData?.[addressRequest.id]?.page || 1;
			const size = leadsData?.[addressRequest.id]?.size || 30;
			const {data} = yield axios.post(
				ENDPOINT.GET_LEADS_BY_ADDRESS.replace(":page", page).replace(":size", size),
				payload,
			);
			yield put(
				actions.getLeadsByAddressSuccess({
					data: data?.data,
					address: addressRequest,
				}),
			);
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLeadsLoading({addressId: addressRequest.id, value: false}));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_ADDRESSES, sagas.getAddresses);
	yield takeLatest(GET_LEADS_BY_ADDRESS, sagas.getLeadsByAddress);
};
