/* eslint-disable max-len */
import React from "react";
import ButtonComponent from "@common/button";

const MerkmaleButtons = ({handleOrderFormModal, item}) => (
	<div className="markmaleButtons__container">
		{/* <ButtonComponent buttonText="zum Angebot hinzufügen" classNames="markmaleButton__first" />
		<ButtonComponent buttonText="Formular" classNames="markmaleButton__second" /> */}
		<ButtonComponent
			buttonText="Auftrag erfassen"
			classNames="markmaleButton__third"
			onClick={() => handleOrderFormModal(item)}
		/>
	</div>
);

export default MerkmaleButtons;
