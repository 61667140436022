import i18n from "@src/i18n";

const switchText = i18n.t("pdfDefaultSwitchInfo");

export const pdfInputs = [
	{
		field: "tariffId",
		title: i18n.t("linkTariffToPdf"),
		inputType: "dropdown",
		options: [],
	},
	{
		noInput: true,
		inputType: "pdfNameInfo",
	},
	{
		field: "fileName",
		title: i18n.t("pdfName"),
		inputType: "textarea",
		noSpaces: true,
		required: true,
		hideLabel: true,
	},
	{
		field: "sendEmail",
		title: i18n.t("sendEmail"),
		inputType: "switch",
		additionalInfo: `${switchText} ${i18n.t("sendEmailInfo")}`,
	},
	{
		field: "sendOfferEmail",
		title: i18n.t("sendOfferEmail"),
		inputType: "switch",
		additionalInfo: `${switchText} ${i18n.t("sendOfferEmailInfo")}`,
	},
	{
		field: "saveOnServer",
		title: i18n.t("saveOnServer"),
		inputType: "switch",
		additionalInfo: `${switchText} ${i18n.t("saveOnServerInfo")}`,
	},
	{
		field: "saveOnClientServer",
		title: i18n.t("saveOnClientServer"),
		inputType: "switch",
		additionalInfo: `${switchText} ${i18n.t("saveOnClientServerInfo")}`,
	},
];
