import {actions as importActions} from "@sagas/imports/create";

// eslint-disable-next-line import/no-cycle
import {getStore} from "../../store";
class OrderStatusImportEntity {
	UPDATE = (data) => {
		const {dispatch} = getStore();
		const parsedData = JSON.parse(data);
		switch (parsedData?.type) {
			case "MATCH":
				return dispatch(importActions.matchOrdersOnState(parsedData));
			case "UNMATCH":
				return dispatch(importActions.unMatchOrdersOnState(parsedData));
			case "DELETE":
				return dispatch(importActions.deleteOrdersOnState(parsedData));
			default:
				break;
		}
	};

	USER_ACTIVE = (data) => {
		const {dispatch} = getStore();
		dispatch(importActions.importActiveUsersAdd(data));
	};

	USER_DEACTIVE = (data) => {
		const {dispatch} = getStore();
		dispatch(importActions.importActiveUsersRemove(data));
	};
}

export default new OrderStatusImportEntity();
