import React, {useState} from "react";
import ModalComponent from "@common/modal";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import moment from "moment";
import {scrollToMessage} from "@utils/scroll-to-message";
import Loader from "@common/Loader";
import {handleInputShowValue} from "@utils/message-converter";
import {CloseUploadModalIcon, ReplyMessageIcon} from "@assets/SvgComponents";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as messagesActions} from "@sagas/messages";
import _ from "lodash";

const SearchMessagesComponent = (props) => {
	const {
		showSearchModal,
		setShowSearchModal,
		query,
		clearQuery,
		setQuery,
		searchMessagesInsideTopic,
		insideTopic,
		searchResults,
		getMessagePage,
		setMessageToReply,
		searchLoading,
		searchTotalPages,
		loggedUser,
	} = props;
	const {t} = useTranslation();
	const [fetchedPages, setFetchedPages] = useState([1]);
	const hasWriteAccess = insideTopic?.topicMembers?.find(
		(member) => member?.id === loggedUser.id,
	)?.writeAccess;
	const closeSearchModal = () => {
		setShowSearchModal(false);
		clearQuery();
	};
	const handleScroll = _.debounce((event) => {
		const container = event.target;
		const scrollPosition = Math.abs(container.scrollTop);
		const maxScrollPosition = container.scrollHeight - container.clientHeight;
		if (
			Math.round(scrollPosition) === maxScrollPosition &&
			(scrollPosition !== 0 || maxScrollPosition !== 0) &&
			!fetchedPages.includes(query.page + 1) &&
			query.page < searchTotalPages
		) {
			setFetchedPages((prevState) => [...prevState, query.page + 1]);
			setQuery({
				...query,
				page: query.page + 1,
			});
			searchMessagesInsideTopic();
		}
	}, 500);
	return (
		<ModalComponent
			open={showSearchModal}
			positionModal="right"
			handleClose={closeSearchModal}
			customClassNames="search__messages-modal"
			onScroll={handleScroll}
		>
			<div className="search__messages-modal-header">
				<span className="modal-title">{t("SearchMessages")}</span>
				<span className="close_modal-icon" onClick={closeSearchModal}>
					<CloseUploadModalIcon fill="#979797" height="10" width="10" />
				</span>
			</div>
			<div className="search-bar">
				<BigSearchBar
					style={{width: "100%"}}
					replaceSearchIcon={true}
					value={query.search}
					handleClose={clearQuery}
					handleSearch={(e) => {
						setQuery({
							...query,
							topicId: insideTopic?.topicId,
							search: e.target.value,
						});
						searchMessagesInsideTopic();
					}}
				/>
			</div>
			<div className="search_results">
				{searchResults?.length > 0 &&
					searchResults?.map((item, index) => (
						<div className="search_results-wrapper" key={index}>
							<div className="search_results-wrapper-header">
								<span className="result-date">
									{moment(item?.createdAtTimestamp).format("M/D/YYYY")}
								</span>
								<div
									className="reply_searched_message"
									onClick={() => {
										closeSearchModal();
										setMessageToReply(item);
									}}
								>
									{hasWriteAccess && (
										<span className="icon">
											<ReplyMessageIcon />
										</span>
									)}
								</div>
							</div>
							<div
								className="result-message"
								onClick={() => {
									closeSearchModal();
									scrollToMessage(item?.id, insideTopic?.topicId, getMessagePage);
								}}
							>
								<span className="result-message-user">@{item?.fullName}</span>
								<span className="result-message-message">
									{handleInputShowValue(item?.text)
										.split(" ")
										.map((word, index) => {
											if (
												word?.toLowerCase() === query?.search?.toLowerCase()
											) {
												return (
													<span className="matched-word" key={index}>
														{word}
													</span>
												);
											}
											return <> {word} </>;
										})}
								</span>
							</div>
						</div>
					))}
			</div>
			{searchLoading && <Loader />}
		</ModalComponent>
	);
};
const mapStateToProps = (state) => ({
	insideTopic: state.app.messages.index.insideTopic,
	searchResults: state.app.messages.index.searchResults,
	query: state.app.messages.index.query,
	searchTotalPages: state.app.messages.index.searchTotalPages,
	searchLoading: state.app.messages.index.searchLoading,
	loggedUser: state.app.users.index.loggedUser,
});

const mapDispatchToProps = {
	searchMessagesInsideTopic: messagesActions.searchMessagesInsideTopic,
	getMessagePage: messagesActions.getMessagePage,
	setQuery: messagesActions.setQuery,
	clearQuery: messagesActions.clearQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchMessagesComponent));
