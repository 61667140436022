import InputComponent from "@common/input";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import Button from "@src/common/components/button";
import saveIcon from "@assets/img/save-icon.svg";
import ColorPickerPopOver from "@src/common/ColorPickerComponent";
import CardInfo from "@src/common/styles/common/energy";
import {InfoIcon, ShowInfoIcon} from "@src/assets/SvgComponents";
import {actions as userSettingsActions} from "../../../../store/sagas/app/userProfile/settings";
import {billingDetailsSchema, colorPickers, groupedBillingDetailsInputs} from "./helper";
import Upload from "../components/Upload";

const BillingDetails = (props) => {
	const {
		billingDetails,
		setBillingDetails,
		loading,
		changeLogo,
		logo,
		loggedUser,
		changeLogoSuccess,
		changeQRCodeSuccess,
		qrCode,
		updateGeneratedSettlementId,
	} = props;
	const {t} = useTranslation();
	const [selectedFile, setSelectedFile] = useState(null);
	const handleQRCodeChange = (e, setFieldValue) => {
		const file = e.target.files[0];
		if (file) {
			const newFileURL = URL.createObjectURL(file);
			changeQRCodeSuccess(newFileURL);
			setFieldValue("qrCode", file);
		}
	};
	const handleLogoChange = (e, setFieldValue) => {
		const file = e.target.files[0];
		billingDetailsSchema
			.validate({tenantLogo: file})
			.then(() => {
				setFieldValue("tenantLogo", file);
				setSelectedFile(file);
				changeLogoSuccess(URL.createObjectURL(file));
			})
			.catch((error) => {
				ToastErrorComponent(error.message);
			});
	};
	const formik = useFormik({
		initialValues: billingDetails,
		enableReinitialize: true,
		onSubmit: (values) => {
			if (selectedFile) {
				changeLogo({file: selectedFile, userId: loggedUser?.id});
			}
			const valuesObj = {...values};
			delete valuesObj.generateSettlementId;
			setBillingDetails(valuesObj);
			// send request to update generateSettlementId only if it has changed
			if (values.generateSettlementId !== billingDetails.generateSettlementId) {
				updateGeneratedSettlementId(values.generateSettlementId);
			}
		},
	});
	const {values, handleSubmit, handleChange, setFieldValue} = formik;
	useEffect(() => () => changeLogoSuccess(null), []);
	useEffect(
		() => () => {
			if (qrCode) URL.revokeObjectURL(qrCode);
		},
		[qrCode],
	);
	return (
		<form onSubmit={handleSubmit} className="userProfile-wrapper">
			<div className="userProfile-section">
				<span className="title">{t("TenantLogo")}</span>
				<div className="userProfile-section-content">
					<Upload
						accept="image/*"
						onUpload={(e) => handleLogoChange(e, setFieldValue)}
						img={logo || values?.tenantLogo}
					/>
				</div>
			</div>
			<div className="userProfile-section">
				<span className="title">{t("UploadQRCode")}</span>
				<div className="userProfile-section-content">
					<CardInfo icon={<InfoIcon />} content={t("UploadQRCodeInfo")} color="#979797" />
					<Upload
						img={qrCode || values?.qrCode}
						accept="image/svg+xml, image/png"
						onUpload={(e) => handleQRCodeChange(e, setFieldValue)}
						id="qrCode"
					/>
				</div>
			</div>
			<div className="userProfile-section">
				<span className="title">{t("companyInfo")}</span>
				<div className="userProfile-section-content">
					{Object.entries(groupedBillingDetailsInputs)?.map((item) => {
						const [key, inputs] = item;
						return (
							<div className="groupedInputs" key={`${key}--${key}`}>
								{inputs?.map((input) => (
									<InputComponent
										key={input.field}
										label={input.label}
										name={input.field}
										handleChange={handleChange}
										values={values?.[input.field]}
										style={{width: input.width}}
										newClass={`custom__input ${input.hideLabel && "hideLabel"}`}
									/>
								))}
							</div>
						);
					})}
				</div>
			</div>
			<div className="userProfile-section">
				<span className="title">{t("changeColorThemes")}</span>
				<div className="userProfile-section-content colorPickers">
					{colorPickers?.map((picker) => (
						<div key={picker?.field} className="picker__wrapper">
							<p className="picker__title">{picker?.title}</p>
							<ColorPickerPopOver
								values={values}
								onChange={(value) => setFieldValue(picker?.field, value)}
								color={values?.[picker?.field] || picker?.defaultValue}
								name={picker?.field}
							/>
						</div>
					))}
				</div>
			</div>
			<div className="userProfile-section">
				<span className="title">{t("GenerateSettlementID")}</span>
				<div className="userProfile-section-content settlementID">
					<div className="external__id__generator">
						<div className="external__id__generator-example">
							<span className="external__id__generator-example-title">
								{t("SettlementId")}
							</span>
							<p>
								<ShowInfoIcon fill="#C1C1C1" />
								{t("Tousethesethreedifferentcharacters")}
							</p>
							<div>
								<ol>
									<li>{t("Usetheverticalbarcharacter")}</li>
									<li>{t("Usethehashcharacter")}</li>
									<li>{t("Usethedollarsigncharacter")}</li>
								</ol>
								<p className="instructions-example">
									{t("Herearetheavailableenums")}
								</p>
							</div>
							<p className="instructions-example">{t("example")}</p>
						</div>
						<div className="input__wrapper">
							<InputComponent
								label={t("GenerateSettlementID")}
								placeholder={t("SettlementId")}
								values={values?.generateSettlementId}
								handleChange={(e) =>
									setFieldValue("generateSettlementId", e.target.value)
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<Button
				icon={saveIcon}
				type="submit"
				customClassName="saveButton"
				disabled={loading}
				leftRounded={true}
				text={t("Save")}
				smallButtonWithTooltip
			/>
		</form>
	);
};

const mapStateToProps = (state) => ({
	billingDetails: state.app.userProfile.settings.billingDetails,
	loading: state.app.userProfile.settings.loading,
	logo: state.app.userProfile.settings.logoUrl,
	loggedUser: state.app.users.index.loggedUser,
	qrCode: state.app.userProfile.settings.qrCode,
});

const mapDispatchToProps = {
	setBillingDetails: userSettingsActions.setBillingDetails,
	updateGeneratedSettlementId: userSettingsActions.updateGeneratedSettlementId,
	changeLogo: userSettingsActions.changeLogo,
	changeLogoSuccess: userSettingsActions.changeLogoSuccess,
	changeQRCodeSuccess: userSettingsActions.changeQRCodeSuccess,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BillingDetails));
