import i18n from "@src/i18n";

export const categoryInputs = [
	{
		inputType: "radioGroup",
		field: "categoryViewType",
		defaultValue: "EXTERN",
		options: [
			{
				title: i18n.t("Extern"),
				value: "EXTERN",
			},
			{
				title: i18n.t("Intern"),
				value: "INTERN",
			},
		],
	},
	{
		inputType: "text",
		field: "name",
		title: i18n.t("categoryName"),
		required: true,
	},
	{
		inputType: "text",
		field: "subCategory",
		additionalField: "subCategories",
		title: i18n.t("subCategoryName"),
		onKeyDown: true,
	},
	{
		inputType: "subCategoriesRender",
		field: "subCategories",
		noInput: true,
	},
];
