import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Formik} from "formik";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {BackButton, EmailLoginIcon, OneriLogo, PasswordIcon} from "@assets/SvgComponents";
import InputComponent from "../../../common/input";
import ButtonComponent from "../../../common/button";
import {actions as loginAction} from "../../../store/sagas/app/auth/login";
import {langVar} from "../../../i18n";

const Login = (props) => {
	const {t} = useTranslation();
	const {navigation, classNames, submitLogin, login} = props;
	const {data} = login;
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.lowercase()
			.strict()
			.label("Email")
			.email(t("EmailIsRequred"))
			.required(t("EnterRefisteredEmail")),
		password: yup
			.string()
			.label("Password")
			.required(t("PasswordRequired"))
			.min(0, t("PasswordMustBe")),
	});
	return (
		<div className={`${classNames}`}>
			<Formik
				initialValues={data}
				onSubmit={(values, actions) => submitLogin({values, formActions: actions})}
				validationSchema={validationSchema}
			>
				{({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
					<form autoComplete="off" noValidate onSubmit={handleSubmit}>
						<div className="company__logo">
							<OneriLogo />
						</div>
						<InputComponent
							name="email"
							placeholder={t("Email")}
							type="email"
							errorClass="errorClass"
							errors={errors.email}
							values={values.email}
							handleBlur={handleBlur}
							handleChange={handleChange}
							touched={touched.email}
							icon={<EmailLoginIcon />}
						/>
						<InputComponent
							name="password"
							placeholder={t("Password")}
							type="password"
							errorClass="errorClass"
							errors={errors.password}
							values={values.password}
							handleBlur={handleBlur}
							handleChange={handleChange}
							touched={touched.password}
							icon={<PasswordIcon />}
						/>
						<ButtonComponent
							buttonText={t("Continue")}
							classNames="authBtn"
							type="submit"
						/>
						<div className="login-bottom">
							<span
								className="forgot"
								onClick={() => navigation(`/privacy-policy/${langVar || "de"}`)}
							>
								{t("PrivacyPolicy")}
							</span>
							<span
								className="forgot"
								onClick={() => navigation("/auth/forgot-password")}
							>
								{t("ForgotPassowrd")}
							</span>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

const mapStateToProps = (state) => ({
	login: state.app.auth.login,
});
const mapDispatchToProps = {
	submitLogin: loginAction.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
