import {ThreeDotsIcon} from "@src/assets/SvgComponents";
import React, {useState} from "react";
import {connect} from "react-redux";
import {actions as statusesActions} from "@sagas/salesTracker/statuses";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import CustomPopover from "@src/common/Popover";
import {actions as leadsActions} from "@sagas/salesTracker/index";
import {useUrlParams} from "@src/utils/useUrlParams";
import {statusesActionsItems} from "./popoverItems";

const StatusActionsComponent = (props) => {
	const {
		selectedRows,
		status,
		allLength,
		changeStatusOrder,
		deleteStatus,
		loading,
		getLeads,
		editPage,
		setShowModal,
	} = props;
	const {id, statusType, orderNumber} = status;
	const [sortType, setSortType] = useState(localStorage.getItem("sort"));
	const {getUrlParams} = useUrlParams();
	const {modal, id: statusId} = getUrlParams("modal", "id");
	const handleSortType = (type, id) => {
		const parsedSortType = JSON.parse(localStorage.getItem("sort"));
		const sortToInsert = {
			...parsedSortType,
			[id]: type,
		};
		editPage(1);
		getLeads({sort: sortToInsert});
		setSortType(JSON.stringify(sortToInsert));
		localStorage.setItem("sort", JSON.stringify(sortToInsert));
	};
	const items = statusesActionsItems(
		statusType,
		changeStatusOrder,
		orderNumber,
		allLength,
		id,
		handleSortType,
		JSON.parse(sortType),
		setShowModal,
	);
	return (
		<>
			<CustomPopover
				menu={{
					items,
				}}
				customClass="popover__actions-wrapper"
				placement="bottomLeft"
			>
				<div
					className="three-dots-icon"
					style={{
						pointerEvents: selectedRows?.[0] ? "none" : "",
					}}
				>
					<ThreeDotsIcon />
				</div>
			</CustomPopover>
			<DeleteModal
				confirm={() => {
					deleteStatus(statusId);
					setShowModal(false);
				}}
				modalType="permanantDelete"
				setShowDeleteModal={setShowModal}
				open={modal === "deleteStatus" && statusId === id}
				disabled={loading}
				handleClose={() => setShowModal(false)}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.salesTracker.statuses.statusLoader,
});

const mapDispatchToProps = {
	getStatusById: statusesActions.getStatusById,
	deleteStatus: statusesActions.deleteStatus,
	getLeads: leadsActions.getLeads,
	editPage: leadsActions.editPage,
	changeStatusOrder: statusesActions.changeStatusOrder,
	clearStatus: statusesActions.clearStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(StatusActionsComponent);
