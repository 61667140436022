/* eslint-disable arrow-body-style */
import React from "react";
import {withGoogleMap, GoogleMap, Circle, Marker} from "react-google-maps";

const MapComponent = withGoogleMap((props) => (
	<GoogleMap defaultZoom={16} defaultCenter={{lat: props.lat, lng: props.lng}}>
		<Circle
			center={{lat: props.lat, lng: props.lng}}
			radius={100}
			options={{
				fillColor: "#f00",
				fillOpacity: 0.2,
				strokeColor: "#f00",
				strokeOpacity: 1,
				strokeWeight: 1,
			}}
		/>
		<Marker position={{lat: props.lat, lng: props.lng}} />
	</GoogleMap>
));

const Map = ({lat, lng}) => {
	return (
		<>
			<div className="breakLine"></div>
			<MapComponent
				lat={lat}
				lng={lng}
				containerElement={<div style={{height: "400px", width: "100%"}} />}
				mapElement={<div style={{height: "100%"}} />}
			/>
			<div className="breakLine"></div>
		</>
	);
};

export default Map;
