import {
	cancellationType,
	clientTypes,
	energyTypes,
} from "@src/components/dashboard/CreateTariff/helper";
import i18n from "@src/i18n";

export const tariffColumns = [
	{title: "Tariff ID", field: "id"},
	{
		title: "Tariff Name",
		field: "name",
		image: "imageField",
		headerStyle: {
			backgroundColor: "#fbfbfb",
			fontFamily: "Montserrat",
			fontSize: "1.3rem",
		},
		cellStyle: {
			backgroundColor: "#fbfbfb",
			marginRight: "190px",
			borderRightStyle: "solid",
			borderRightColor: "#f5f5f5",
			borderRightWidth: "1px",
		},
	},
];
export const tariffInputs = (payoutModel) => [
	{
		title: i18n.t("name"),
		field: "name",
		inputType: "text",
		required: true,
	},
	{
		title: i18n.t("cancellationReserve"),
		field: "cancellationReserve",
		hidden: payoutModel === "ENERGY_DISTRIBUTION_MODEL",
		inputType: "dropdown",
		options: cancellationType,
		required: true,
	},
	{
		title: i18n.t("Price"),
		field: "tariffPrice",
		inputType: "number",
		min: 0,
		required: true,
	},
	{
		title: i18n.t("Points"),
		field: "points",
		inputType: "number",
		min: 0,
	},
	{
		title: i18n.t("ClientType"),
		field: "clientType",
		hidden: payoutModel !== "ENERGY_DISTRIBUTION_MODEL",
		inputType: "dropdown",
		options: clientTypes,
		required: true,
	},
	{
		title: i18n.t("EnergyType"),
		field: "energyType",
		hidden: payoutModel !== "ENERGY_DISTRIBUTION_MODEL",
		inputType: "dropdown",
		options: energyTypes,
		required: true,
	},
];
