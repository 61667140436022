import React from "react";

const CardInfo = ({title, content, color = "#6C1EB0", icon = null}) => (
	<div
		className="card__info__container"
		style={{
			backgroundColor: `${color}22`,
			borderColor: color,
		}}
	>
		{title && <h3 style={{color}}>{title}</h3>}
		<p>
			{icon && <span style={{marginRight: 3}}>{icon}</span>}
			{content}
		</p>
	</div>
);

export default CardInfo;
