import ModalComponent from "@src/common/modal";
import {useFormik} from "formik";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as marketPlaceActions} from "@sagas/marketplace/index";
import {CloseUploadModalIcon} from "@src/assets/SvgComponents";
import InputComponent from "@src/common/input";
import ButtonComponent from "@src/common/button";
import {useTranslation} from "react-i18next";

const SetApiKeyComponent = ({modalState, setModal, setApiKey}) => {
	const {t} = useTranslation();
	const formik = useFormik({
		initialValues: {apiKey: modalState?.apiKey, id: modalState.id},
		enableReinitialize: true,
		onSubmit: (values) => {
			setApiKey(values);
		},
	});
	const {values, handleSubmit, handleChange, handleBlur} = formik;
	const handleClose = () => setModal({show: false});
	return (
		<ModalComponent
			positionModal="right"
			handleClose={handleClose}
			open={modalState?.open}
			customClassNames="apiKey__modal"
		>
			<form className="apiKey__modal-form" onSubmit={handleSubmit}>
				<div className="apiKey__modal-form-header">
					<span className="title">{modalState?.title}</span>
					<span className="icon" onClick={handleClose}>
						<CloseUploadModalIcon />
					</span>
				</div>
				<div className="apiKey__modal-form-content">
					<div className="apiKey__modal-form-content-inputs">
						<InputComponent
							handleBlur={handleBlur}
							handleChange={handleChange}
							newClass="edit__and__create__form-custom_input"
							values={values?.apiKey}
							name="apiKey"
						/>
					</div>
					<div className="apiKey__modal-form-content-actions">
						<ButtonComponent buttonText={t("Save")} type="submit" />
					</div>
				</div>
			</form>
		</ModalComponent>
	);
};

const mapStateToProps = (state) => ({
	modalState: state.app.marketPlace.index.modalState,
});
const mapDispatchToProps = {
	setModal: marketPlaceActions.setModal,
	setApiKey: marketPlaceActions.setApiKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetApiKeyComponent));
