import loadingLottie from "../ordersLoader.json";

export const ordersLoadingOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingLottie,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
