/* eslint-disable max-len */
import CustomAccordion from "@src/common/CustomAccordion";
import InputComponent from "@src/common/input";
import TextAreaComponent from "@src/common/TextArea/TextAreaComponent";
import {useFormik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import ButtonComponent from "@src/common/button";
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {emailSchema as validationSchema} from "./utils";

const EmailSettingsComponent = ({client, updateSettings, match, loading}) => {
	const {t} = useTranslation();
	const formik = useFormik({
		initialValues: {
			...client.emailData,
		},
		validationSchema,
		onSubmit: (values) => {
			updateSettings({clientId: match.params.clientId, emailData: values});
		},
	});
	const {values, handleChange, setFieldValue, handleSubmit, touched, errors} = formik;
	return (
		<CustomAccordion title="Email Settings" className="contracts__accordion">
			<form onSubmit={handleSubmit}>
				<div className="email__container">
					<div className="email__container-title">
						<ShowInfoIcon fill="#C1C1C1" />
						<span>
							Those infos will be sended in the email which is sent on mobile app
						</span>
					</div>
					<div className="email__container-inputs">
						<InputComponent
							name="clientSubject"
							handleChange={handleChange}
							label={t("EmailSubject")}
							touched={touched?.clientSubject}
							errors={errors?.clientSubject}
							values={values?.clientSubject}
							errorClass="errorClass"
						/>
						<TextAreaComponent
							breakLine={false}
							width="100%"
							value={values.clientDescription}
							setFieldValue={setFieldValue}
							touched={touched?.clientDescription}
							errors={errors?.clientDescription}
							errorClass="errorClass"
							inputName="clientDescription"
						/>
					</div>
					<div className="email__container-actions">
						<ButtonComponent
							buttonText={t("Save")}
							onClick={handleSubmit}
							disabled={loading}
							loading={loading}
						/>
					</div>
				</div>
			</form>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	client: state.app.client.index.client,
	loading: state.app.client.index.isLoading,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailSettingsComponent));
