import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as logoutAction} from "../../../store/sagas/app/auth/logout";

const Logout = ({submitLogout}) => {
	submitLogout();
	return null;
};

const mapDispatchToProps = {
	submitLogout: logoutAction.logout,
};
export default connect(null, mapDispatchToProps)(withRouter(Logout));
