import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import React from "react";
import {useTranslation} from "react-i18next";
import plusLogo from "@assets/img/plusIconBackground.svg";
import Button from "@src/common/components/button";
import {useUrlParams} from "@src/utils/useUrlParams";

const CustomFieldsHeader = ({searchValue, handleSearch}) => {
	const {t} = useTranslation();
	const {setModal} = useUrlParams();
	return (
		<div className="Container-search">
			<BigSearchBar value={searchValue} handleSearch={handleSearch} />
			<Button
				smallButtonWithTooltip
				text={t("AddFields")}
				handleChange={() => setModal("customField")}
				icon={plusLogo}
				leftRounded
			/>
		</div>
	);
};

export default CustomFieldsHeader;
