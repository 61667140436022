import jwtDecode from "jwt-decode";
import {decryptData} from "./encode-role-data";

export const getCurrentUser = () => {
	try {
		if (localStorage.getItem("token")) {
			const token = localStorage.getItem("token");
			const decoded = jwtDecode(token);
			if (new Date().getTime() / 1000 > decoded.exp) {
				localStorage.removeItem("token");
				return null;
			}
			return decoded;
		}
		return null;
	} catch (error) {
		if (!window.location.pathname.includes("/auth")) {
			localStorage.removeItem("token");
		}
		return null;
	}
};

export const getCurrentUsersRole = () => {
	const encryptedRole = localStorage.getItem("roleData");
	if (encryptedRole) {
		try {
			return decryptData(encryptedRole);
		} catch (error) {
			if (!window.location.pathname.includes("/auth")) {
				localStorage.removeItem("roleData");
			}
			return null;
		}
	}
};
