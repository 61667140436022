import CustomAccordion from "@src/common/CustomAccordion";
import InputComponent from "@src/common/input";
import {Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import Switch from "@common/switch/index";
import OrderEmails from "./orderEmail";

const EmailSettings = (props) => {
	const {
		currentTab,
		handleUpdate,
		email,
		emailName,
		setEmail,
		setEmailName,
		sendEmailForOrder,
		setSendEmailForOrder,
		setIncludeGeneratedPdf,
		includeGeneratedPdf,
		checkedOfferEmail,
		setCheckedOfferEmail,
		selectedPDFs,
		setSelectedPDFs,
		removeAttachment,
		setDescription,
		description,
		subject,
		setSubject,
		checkedOrderPdfEmail,
		setCheckedOrderPdfEmail,
		setUploadedPDFs,
		uploadedPDFs,
	} = props;
	const {t} = useTranslation();
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(t("EmailReq"))
			.lowercase()
			.strict()
			.label("Email")
			.email(t("EmailIsRequred")),
	});
	const toggleOrderEmail = () => {
		if (sendEmailForOrder) {
			setSendEmailForOrder(false);
			setIncludeGeneratedPdf(false);
		} else {
			setSendEmailForOrder(true);
		}
	};
	return (
		<div className="activeTab-container-wrapper">
			<p className="activeTab-container-wrapper-title">{currentTab}</p>
			<CustomAccordion title="Email">
				<div
					className="client_settings-color_picker second_client_settings-color_picker"
					style={{height: "auto"}}
				>
					<Formik
						validationSchema={validationSchema}
						onSubmit={(actions) => handleUpdate({formActions: actions})}
					>
						{({handleBlur}) => (
							<form
								className="edit__and__create__form client_settings_form"
								autoComplete="off"
								noValidate
							>
								<div className="client_settings_form_input">
									<InputComponent
										values={email}
										name="email"
										placeholder={t("EmailAddress")}
										type="email"
										handleBlur={handleBlur}
										handleChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<div className="client_settings_form_input">
									<InputComponent
										values={emailName}
										name="emailName"
										placeholder={t("Alias")}
										type="text"
										handleBlur={handleBlur}
										handleChange={(e) => setEmailName(e.target.value)}
									/>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</CustomAccordion>
			<div className="switch-wrapper">
				<div className="client_settings_export_pdf_div-switch">
					<Switch
						label="SendEmailForOrder"
						checked={sendEmailForOrder}
						switchChangeHandler={toggleOrderEmail}
						infoText={t("SendEmailForOrderInfo")}
					/>
				</div>
				{sendEmailForOrder && (
					<div className="client_settings_export_pdf_div-switch">
						<Switch
							label="IncludeGeneratedPDF"
							infoText={t("IncludeGeneratedPDFInfo")}
							checked={includeGeneratedPdf}
							switchChangeHandler={() => setIncludeGeneratedPdf(!includeGeneratedPdf)}
						/>
					</div>
				)}
				<div className="client_settings_export_pdf_div-switch">
					<Switch
						label="firstEmail"
						checked={checkedOfferEmail}
						infoText={t("firstEmailInfo")}
						switchChangeHandler={() => setCheckedOfferEmail(!checkedOfferEmail)}
					/>
				</div>
				<div className="client_settings_export_pdf_div-switch">
					<Switch
						label="secondEmail"
						checked={checkedOrderPdfEmail}
						infoText={t("secondEmailInfo")}
						switchChangeHandler={() => setCheckedOrderPdfEmail(!checkedOrderPdfEmail)}
					/>
				</div>
			</div>
			{sendEmailForOrder && (
				<CustomAccordion title={t("secondEmail")} isDefaultExpanded={sendEmailForOrder}>
					<OrderEmails
						setUploadedPDFs={setUploadedPDFs}
						uploadedPDFs={uploadedPDFs}
						subject={subject}
						setSubject={setSubject}
						description={description}
						setDescription={setDescription}
						removeAttachment={removeAttachment}
						setSelectedPDFs={setSelectedPDFs}
						selectedPDFs={selectedPDFs}
					/>
				</CustomAccordion>
			)}
		</div>
	);
};

export default EmailSettings;
