import React from "react";
import {
	TickIconSuccess,
	WarningIconYellow,
	DangerIconRed,
	ExceletIconViolet,
	GoodIconBlue,
	GridViewIcon,
	ListViewIcon,
	PriorityViewIcon,
	StatusViewIcon,
} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import {checkRoles} from "@src/utils/checkRoles";
import moment from "moment";
import {DATE_FORMAT} from "@src/common/constants";
import CreateStatusActions from "../../sales-tracker/components/leadStatuses/CreateStatusActions";
import PriorityBox from "./PriorityBox";
import ColorPercentage from "./ColorPercentage";
// this object will serve to render dynamically labels of selected
// type on goal information
export const selectTypes = {
	CLIENTS: {label: `${i18n.t("SelectedClients")}: `, type: "Clients"},
	AGENCIES: {label: `${i18n.t("SelectedAgencies")}: `, type: "Agency"},
	TEAMS: {label: `${i18n.t("SelectedTeams")}: `, type: "Teams"},
	USERS: {label: `${i18n.t("SelectedUsers")}: `, type: "Users"},
};

// this object will return status name icon and color dynamically
export const findStatus = (percentage, textColor) => {
	const baseIconProps = {
		width: "14px",
		height: "14px",
		strokeWidth: 2,
	};
	if (textColor) {
		baseIconProps.stroke = textColor;
		delete baseIconProps.strokeWidth;
	}
	const status = {
		Danger: {
			threshold: 0,
			color: "#D00A12",
			icon: <DangerIconRed {...baseIconProps} />,
			description: i18n.t("DangerBehindTheTarget"),
		},
		Warning: {
			threshold: 30,
			color: "#E4A11B",
			icon: <WarningIconYellow {...baseIconProps} />,
			description: i18n.t("WarningFallingShortOfTarget"),
		},
		Good: {
			threshold: 50,
			width: "16px",
			height: "16px",
			color: "#3B71CA",
			icon: <GoodIconBlue {...baseIconProps} />,
			description: i18n.t("GoodProgressingWellToWardsTheTarget"),
		},
		Excellent: {
			threshold: 70,
			color: "#5B0963",
			icon: <ExceletIconViolet {...baseIconProps} />,
			description: i18n.t("ExcellentOnTrackToMeetOrExceedTheTarget"),
		},
		Success: {
			threshold: 100,
			color: "#14A44D",
			icon: <TickIconSuccess {...baseIconProps} />,
			description: i18n.t("SuccessGoalAchievedOrSurpassed"),
		},
	};

	let statusName, icon, color, description;

	switch (true) {
		case percentage <= status.Danger.threshold:
			statusName = i18n.t("danger");
			icon = status.Danger.icon;
			color = status.Danger.color;
			description = status.Danger.description;
			break;
		case percentage <= status.Warning.threshold:
			statusName = i18n.t("warning");
			icon = status.Warning.icon;
			color = status.Warning.color;
			break;
		case percentage <= status.Good.threshold:
			statusName = i18n.t("Good");
			icon = status.Good.icon;
			color = status.Good.color;
			break;
		case percentage <= status.Excellent.threshold:
			statusName = i18n.t("excellent");
			icon = status.Excellent.icon;
			color = status.Excellent.color;
			break;
		default:
			statusName = i18n.t("success");
			icon = status.Success.icon;
			color = status.Success.color;
	}
	return {statusName, icon, color, description};
};

// this object is reused as initial value of modal
export const modalInitialValue = {open: false, type: "", title: ""};

// This object will serve to render dynamic data
// based on which checkbox was selected
export const typeDetails = {
	USERS: {
		options: "usersOptions",
		placeholder: i18n.t("addUsers"),
		title: i18n.t("userList"),
		dropDowntitle: "name",
		formikValueName: "userList",
	},
	CLIENTS: {
		options: "clientList",
		placeholder: i18n.t("addClients"),
		title: i18n.t("clientList"),
		dropDowntitle: "clientName",
		formikValueName: "clientList",
	},
	AGENCIES: {
		options: "agencies",
		placeholder: i18n.t("addAgencies"),
		title: i18n.t("agenciesList"),
		dropDowntitle: "name",
		formikValueName: "agencyList",
	},
	TEAMS: {
		options: "teams",
		placeholder: i18n.t("addTeams"),
		title: i18n.t("teamList"),
		dropDowntitle: "name",
		formikValueName: "teamList",
	},
};

// thiS array will serve to render checkboxes on first step
export const radioInputs = [
	{
		label: i18n.t("users"),
		value: "USERS",
	},
	{
		label: i18n.t("Clients"),
		value: "CLIENTS",
	},
	{
		label: i18n.t("Teams"),
		value: "TEAMS",
	},
	{
		label: i18n.t("agency"),
		value: "AGENCIES",
	},
];
export const userRolesDropdown = [
	"AGENCY-SP",
	"SP",
	"EMPLOYEE",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_TL",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_AB",
];

const dateStatusOptions = [
	{
		name: i18n.t("All"),
		id: "ALL",
	},
	{
		name: i18n.t("Active"),
		id: "ACTIVE",
	},
	{
		name: i18n.t("InActive"),
		id: "INACTIVE",
	},
];
export const filterItems = (
	optionsClients,
	getSelectOptionsFromDataTeams,
	locations,
	pritorities,
	filteringStatusOptions,
	views,
	teams,
	agencies,
	usersOptions,
	optionsProducts,
	optionsTariffs,
	groupViewType,
) => [
	{
		inputType: "rangePickerWithTypes",
		field: ["from", "to", "dateType"],
		show: true,
	},
	{
		inputType: "antd-dropdown",
		name: "name",
		value: "id",
		options: dateStatusOptions,
		isMultiple: false,
		label: i18n.t("dateStatus"),
		field: "dateStatus",
		key: "name",
		optionVal: "id",
		optionLabel: "name",
		show: true,
	},
	{
		inputType: "antd-dropdown",
		name: "name",
		value: "id",
		options: views,
		isMultiple: false,
		label: i18n.t("Groups"),
		field: "groupTypeId",
		key: "name",
		optionVal: "id",
		optionLabel: "name",
		show: groupViewType !== "ListView",
	},
	{
		inputType: "antd-dropdown",
		value: "value",
		name: "label",
		field: "priority",
		label: i18n.t("priority"),
		options: pritorities,
		isMultiple: false,
		show: groupViewType !== "PriorityView",
		key: "label",
		optionVal: "value",
		optionLabel: "label",
	},
	{
		inputType: "antd-dropdown",
		name: "name",
		value: "value",
		options: filteringStatusOptions,
		isMultiple: false,
		label: i18n.t("Statuses"),
		show: checkRoles("filterStatus") && groupViewType !== "StatusView",
		field: "systemStatusId",
		key: "label",
		optionVal: "value",
		optionLabel: "name",
	},
	{
		inputType: "antd-dropdown",
		name: "name",
		value: "id",
		options: usersOptions,
		label: i18n.t("Users"),
		field: "userId",
		show: true,
		key: "name",
		optionVal: "id",
		optionLabel: "name",
	},
	{
		inputType: "antd-dropdown",
		name: "label",
		value: "value",
		options: getSelectOptionsFromDataTeams(teams, "name", "id"),
		isMultiple: false,
		label: i18n.t("AllTeams"),
		field: "teamId",
		show: checkRoles("filterTeams"),
		key: "label",
		optionVal: "value",
		optionLabel: "label",
	},
	{
		inputType: "antd-dropdown",
		name: "label",
		value: "value",
		field: "agencyId",
		options: getSelectOptionsFromDataTeams(agencies, "name", "id"),
		isMultiple: false,
		label: i18n.t("AllAgencies"),
		show: checkRoles("filterAgencies"),
		key: "label",
		optionVal: "value",
		optionLabel: "label",
	},
	{
		inputType: "antd-dropdown",
		dependentFields: ["locationId", "tariffId", "productId"],
		label: i18n.t("Clients"),
		name: "name",
		value: "value",
		field: "clientId",
		isMultiple: false,
		options: optionsClients || [],
		show: checkRoles("changeClient"),
		key: "name",
		optionVal: "value",
		optionLabel: "name",
	},
	{
		inputType: "antd-dropdown",
		value: "value",
		name: "label",
		dependendFrom: "clientId",
		field: "locationId",
		label: i18n.t("Locations"),
		options: getSelectOptionsFromDataTeams(locations, "locationName", "id"),
		isMultiple: false,
		show: checkRoles("changeClient"),
		key: "label",
		optionVal: "value",
		optionLabel: "label",
	},
	{
		inputType: "antd-dropdown",
		value: "value",
		name: "name",
		dependendFrom: "clientId",
		dependentField: "productId",
		field: "tariffId",
		label: i18n.t("Tariff"),
		options: optionsTariffs,
		isMultiple: false,
		show: checkRoles("changeClient"),
		key: "name",
		optionVal: "value",
		optionLabel: "name",
	},
	{
		inputType: "antd-dropdown",
		value: "value",
		name: "name",
		dependendFrom: "tariffId",
		field: "productId",
		label: i18n.t("Product"),
		options: optionsProducts,
		isMultiple: false,
		show: checkRoles("changeClient"),
		key: "name",
		optionVal: "value",
		optionLabel: "name",
	},
];
export const listViewItems = (groupViewType, handleOptionClick) => [
	{
		key: "GridView",
		label: i18n.t("GridView"),
		onClick: () => handleOptionClick("GridView"),
		icon: <GridViewIcon />,
		className: groupViewType === "GridView" && "active",
	},
	{
		key: "ListView",
		label: i18n.t("ListView"),
		onClick: () => handleOptionClick("ListView"),
		icon: <ListViewIcon />,
		className: groupViewType === "ListView" && "active",
	},

	{
		key: "StatusView",
		label: i18n.t("PriorityView"),
		onClick: () => handleOptionClick("PriorityView"),
		icon: <PriorityViewIcon stroke="#000" />,
		className: groupViewType === "PriorityView" && "active",
	},
	{
		key: "PriorityView",
		label: i18n.t("StatusView"),
		onClick: () => handleOptionClick("StatusView"),
		icon: <StatusViewIcon stroke="#000" />,
		className: groupViewType === "StatusView" && "active",
	},
];
export const items = (handleVisibility) => [
	{
		key: "menu",
		label: <CreateStatusActions type="goalsViews" handleVisibility={handleVisibility} />,
	},
];
export const inputs = [
	{
		inputType: "text",
		field: "name",
		title: i18n.t("GroupName"),
		required: true,
	},
	{
		title: i18n.t("PickAColor"),
		inputType: "colorPicker",
		field: "color",
		required: true,
	},
];
export const columns = (item) => [
	{
		name: i18n.t("Element"),
		key: "name",
		hide: "header",
		width: 350,
		headerFormatter: () => (
			<div style={{height: 30}}>{item?.totalGoals > 0 ? item.name : "-"}</div>
		),
		resizable: true,
	},
	{
		name: i18n.t("Clients"),
		key: "client",
		cellClass: "client-cell",
		resizable: true,
		formatter: ({row}) => (
			<span
				style={{
					display: "flex",
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: row?.client?.color,
					color: row?.client?.name ? "#fff" : "#c1c1c1",
					fontWeight: "semi-bold",
				}}
			>
				{row?.client?.name ? row?.client?.name : "-"}
			</span>
		),
		headerFormatter: () =>
			item?.totalGoals > 0 && item?.clientViewList?.[0] ? (
				<ColorPercentage row={item?.clientViewList} />
			) : (
				"-"
			),
	},
	{
		name: i18n.t("StartDate"),
		resizable: true,
		key: "startDate",
		cellClass: "status-cell",
		formatter: ({row}) => <span>{moment(row?.startDate).format(DATE_FORMAT)}</span>,
		headerFormatter: () => (
			<div style={{height: 30}}>
				{item?.totalGoals > 0 ? moment(item.startDate).format(DATE_FORMAT) : "-"}
			</div>
		),
	},
	{
		name: i18n.t("EndDate"),
		resizable: true,
		key: "endDate",
		cellClass: "status-cell",
		formatter: ({row}) => <span>{moment(row?.endDate).format(DATE_FORMAT)}</span>,
		headerFormatter: () => (
			<div style={{height: 30}}>
				{item?.totalGoals > 0 ? moment(item.endDate).format(DATE_FORMAT) : "-"}
			</div>
		),
	},
	{
		name: i18n.t("Statuses"),
		resizable: true,
		hide: "StatusView",
		key: "status",
		cellClass: "status-cell",
		formatter: ({row}) => (row.status?.[0] ? <ColorPercentage row={row.status} /> : "-"),
		headerFormatter: () =>
			item?.totalGoals > 0 ? <ColorPercentage row={item?.statusViewList} /> : "-",
	},
	{
		hide: "PriorityView",
		resizable: true,
		name: i18n.t("priority"),
		key: "priority",
		cellClass: "status-cell",
		formatter: ({row}) => <PriorityBox row={row.priority} />,
		headerFormatter: () =>
			item?.totalGoals > 0 ? <ColorPercentage row={item?.priorityList} /> : "-",
	},
	{
		resizable: true,
		name: i18n.t("TargetOrders"),
		key: "totalOrdersTarget",
		cellClass: "status-cell",
		headerFormatter: () => (
			<div
				style={{height: 30, display: "flex", alignItems: "center", flexDirection: "column"}}
			>
				<span>{item?.totalGoals > 0 ? item?.totalOrdersTarget.toLocaleString() : "-"}</span>
				{item?.totalGoals > 0 && <span style={{fontSize: 11}}>{i18n.t("total")}</span>}
			</div>
		),
	},
	{
		resizable: true,
		name: i18n.t("DateBased"),
		cellClass: "status-cell",
		formatter: ({row}) => (
			<ColorPercentage
				row={{
					completed: row?.totalOrders,
					total: row?.totalOrdersTarget,
					date: row?.endDate,
				}}
				type="single"
				color={item?.color}
			/>
		),
		headerFormatter: () =>
			item?.totalGoals > 0 ? (
				<ColorPercentage
					row={{
						completed: item?.totalOrders,
						total: item?.totalOrdersTarget,
						date: item?.endDate,
					}}
					type="single"
					color={item?.color}
				/>
			) : (
				"-"
			),
	},
];
export const showMobile = (type, item) => {
	switch (type) {
		case "addCardToMobile":
			return !item?.showMobile;
		case "removeCardToMobile":
			return false;
		default:
			return false;
	}
};
export const cardWrapperStyles = (isSelected) => ({
	border: `${isSelected ? `10px solid #fff` : ``}`,
	boxShadow: `${isSelected ? "-3px -3px 12px 0px rgba(0,0,0,0.15)" : ""}`,
	background: `${isSelected ? "#fff" : ""}`,
	width: `${isSelected ? "calc(100% + 8px" : "100%"}`,
	height: `${isSelected ? "245px" : "240px"}`,
});

export const getDisplayingDate = ({startDate, endDate}) =>
	`${moment(startDate).format("DD.MM.YY")} - ${moment.utc(endDate).format("DD.MM.YY")}`;
