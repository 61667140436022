/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation/";
import {actions as importsActions} from "@sagas/imports";
import DataGrid from "react-data-grid";
import {withRouter} from "react-router";
import Back from "@assets/img/back__icon.svg";
import Loader from "@common/Loader";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {useTranslation} from "react-i18next";
import BackButtonComponents from "@src/common/BackButton";
import {rowRenderer, rowHeight} from "../../orders/imports/rowRenderer";

const SalesTrackerInsideImport = (props) => {
	const {getSingleImport, singleImport, match, navigate, loading, setActiveTab} = props;
	const {t} = useTranslation();
	useEffect(() => {
		getSingleImport(match.params.id);
		// eslint-disable-next-line
	}, [match.params.id]);

	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	useEffect(() => {
		const arr = [];
		if (!!singleImport?.headers?.length && singleImport?.rows) {
			setColumns(singleImport.headers);
			singleImport.rows?.map((row) => {
				arr.push({
					...row.payload,
					...(row.errors.length > 0 && {errors: row.errors}),
				});
				setRows(arr);
				return arr;
			});
		}
	}, [singleImport]);

	const hanldeBack = () => {
		setActiveTab(t("Import"));
		navigate("/admin/imports/create-leads");
	};

	return (
		<div className="imports__details__container">
			<div className="imports__details__top">
				<BackButtonComponents onClick={() => hanldeBack()} className="back_icon" />
				<h1>{singleImport.fileName} </h1>
			</div>

			<div className="uploaded__csv__container">
				{!loading && (
					<DataGrid
						className="rdg-light"
						columns={columns.map((col) => ({
							...col,
							resizable: true,
						}))}
						rows={rows}
						headerRowHeight={40}
						rowHeight={rowHeight}
						rowRenderer={rowRenderer}
						editable={true}
					/>
				)}

				{loading && (
					<div className="loader__contianer">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	singleImport: state.app.imports.index.singleImportSalesTracker,
	loading: state.app.imports.index.loading,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	getSingleImport: importsActions.getImportByIdSalesTracker,
	setActiveTab: salesTrackerActions.setActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SalesTrackerInsideImport));
