import {GenerateTokenTick} from "@src/assets/SvgComponents";
import React, {useState} from "react";
import Loader from "@src/common/Loader/index.js";

const GenerateTokenButton = ({buttonPhase, handleClick}) => (
	<div style={{marginTop: "33px"}}>
		{buttonPhase === "initial" && (
			<div className="generateToken__button1" onClick={handleClick}>
				<span className="generateToken__button1-text">
					Click here to request a new token
				</span>
			</div>
		)}

		{buttonPhase === "loading" && (
			<div className="generateToken__button2">
				<span className="generateToken__button2-text2">
					<Loader color="#FFFFFF" />
				</span>
			</div>
		)}

		{buttonPhase === "success" && (
			<div className="generateToken__button3">
				<span className="generateToken__button3-text3">
					The new token has been generated | Check your email
				</span>
				<GenerateTokenTick />
			</div>
		)}
		{buttonPhase === "failed" && (
			<div className="generateToken__button3">
				<span className="generateToken__button3-text3">Failed to generate new token</span>
				<GenerateTokenTick />
			</div>
		)}
	</div>
);

export default GenerateTokenButton;
