import React from "react";

const currentLanguage = localStorage.getItem("language");

const fieldLabel = {
	en: "labelEn",
	de: "labelDe",
};

const autoFocusAndSelect = (input) => {
	if (input?.focus && input?.select) {
		input.focus();
		input.select();
	}
};

export const columnObj = {
	editor: ({row, column, onRowChange, onClose}) => (
		<input
			ref={autoFocusAndSelect}
			className="rdg-text-editor"
			value={row[column.key]}
			onChange={(event) => {
				onRowChange({
					...row,
					[column.key]: event.target.value,
				});
			}}
			onBlur={() => onClose(true)}
		/>
	),
	headerRenderer: ({column}) => (
		<div className="editable-formatter">
			<div className="editable-formatter-header">
				<div className="editable-formatter-header-element first">
					{column?.[fieldLabel[currentLanguage]]
						? column?.[fieldLabel[currentLanguage]]
						: column.name}
				</div>
			</div>
		</div>
	),
};
