import React from "react";
import i18n from "@src/i18n";
import {DATE_FORMAT} from "@src/common/constants";
import moment from "moment";
import {ALL_SPECIFICATIONS, START_CONDITION_BONUS_OPTIONS, STATUSES, TYPES} from "./utils";

const findSelectedItem = (array, field) => {
	const foundedItem = array.find((item) => item.value === field);
	return foundedItem?.label || "";
};

const firstCommonColumns = [
	{
		key: "roleOfTeam",
		name: i18n.t("selectionWho"),
		resizable: true,
		formatter: ({row}) => {
			const foundedRole = findSelectedItem(STATUSES, row?.roleOfTeam);
			return <span>{foundedRole}</span>;
		},
	},
	{
		key: "countType",
		name: i18n.t("selectionWhat"),
		resizable: true,
		formatter: ({row}) => {
			const foundedCountType = findSelectedItem(TYPES, row?.countType);
			return <span>{foundedCountType}</span>;
		},
	},
];

const updatedFirstColumns = firstCommonColumns?.map((col) => {
	if (col.key === "roleOfTeam") {
		return {
			...col,
			name: i18n.t("Status"),
		};
	}
	if (col.key === "countType") {
		return {
			...col,
			name: i18n.t("Produktion"),
		};
	}
	return col;
});

const lastCommonColumns = [
	{
		key: "startDate",
		name: i18n.t("startTime2"),
		width: 100,
		formatter: ({row}) => (
			<span>{row?.startDate ? moment(row?.startDate).utc().format(DATE_FORMAT) : "-"}</span>
		),
	},
	{
		key: "endDate",
		name: i18n.t("EndTime"),
		width: 100,
		formatter: ({row}) => (
			<span>{row?.endDate ? moment(row?.endDate).utc().format(DATE_FORMAT) : "-"}</span>
		),
	},
];

export const columns = {
	overhead: [
		...updatedFirstColumns,
		{
			key: "specification",
			name: i18n.t("Specification"),
			resizable: true,
			formatter: ({row}) => {
				const foundedSpecification = findSelectedItem(
					ALL_SPECIFICATIONS,
					row?.specification,
				);
				return <span>{foundedSpecification}</span>;
			},
		},
		{
			key: "overheadType",
			name: i18n.t("OverheadFor"),
			resizable: true,
			formatter: ({row}) => (
				<span>{row?.overheadType ? i18n.t(row?.overheadType) : "-"}</span>
			),
		},
		{
			key: "minTeamSize",
			name: i18n.t("MinNrOfTP"),
			resizable: true,
		},
		{
			key: "amount",
			name: i18n.t("amountTeam"),
			resizable: true,
		},
		...lastCommonColumns,
	],
	bonus_own_contracts: [
		...firstCommonColumns,
		{
			key: "value",
			name: i18n.t("Value"),
			resizable: true,
			formatter: ({row}) => <span>{row?.value ? `${row?.value.toFixed(2)} €` : ""}</span>,
		},
		{
			key: "conditionOrders",
			name: i18n.t("minInHouseProduction"),
			resizable: true,
		},
		...lastCommonColumns,
	],
	mobility_conditions: [
		...firstCommonColumns.map((col) => {
			if (col.key === "countType") {
				return {
					...col,
					name: i18n.t("inHouseProduction"),
				};
			}
			return col;
		}),
		{
			key: "countFrom",
			name: i18n.t("from"),
		},
		{
			key: "countTo",
			name: i18n.t("until"),
		},
		{
			key: "amount",
			name: i18n.t("paymentAmount"),
		},
		...lastCommonColumns,
	],
	induction_bonus_trainers_conditions: [
		{
			key: "teamIds",
			name: i18n.t("selectionTeam"),
			formatter: ({row}) => <span>{row?.team?.name}</span>,
			resizable: true,
		},
		{
			key: "selectionInstructors",
			name: i18n.t("selectionInstructors"),
			formatter: ({row}) => <span>{row?.trainer?.name}</span>,
			resizable: true,
		},
		{
			key: "selectionTrainees",
			name: i18n.t("selectionTrainees"),
			formatter: ({row}) => <span>{row?.intern?.name}</span>,
			resizable: true,
		},
		{
			key: "countType",
			name: i18n.t("Produktion"),
			resizable: true,
			formatter: ({row}) => {
				const foundedCountType = findSelectedItem(TYPES, row?.countType);
				return <span>{foundedCountType}</span>;
			},
		},
		{
			key: "startConditionBonus",
			name: i18n.t("startConditionBonus"),
			resizable: true,
			formatter: ({row}) => {
				const foundedStartCondition = findSelectedItem(
					START_CONDITION_BONUS_OPTIONS,
					row?.startConditionBonus,
				);
				return <span>{foundedStartCondition}</span>;
			},
		},
		{
			key: "countInternFrom",
			name: i18n.t("countInternFrom"),
			resizable: true,
		},
		{
			key: "countInternTo",
			name: i18n.t("countInternTo"),
			resizable: true,
		},
		{
			key: "amount",
			name: i18n.t("oneTimeAmount"),
			formatter: ({row}) => <span>{row?.amount ? `${row?.amount.toFixed(2)} €` : ""}</span>,
			resizable: true,
		},
		...lastCommonColumns,
	],
	overhead_personnel_conditions: [
		...updatedFirstColumns,
		{
			key: "specification",
			name: i18n.t("Specification"),
			resizable: true,
			formatter: ({row}) => {
				const foundedSpecification = findSelectedItem(
					ALL_SPECIFICATIONS,
					row?.specification,
				);
				return <span>{foundedSpecification}</span>;
			},
		},
		{
			key: "minTeamSize",
			name: i18n.t("MinNrOfTP"),
		},
		{
			key: "amount",
			name: i18n.t("amountPointTeam"),
		},
		{
			key: "oneTimeAmount",
			name: i18n.t("oneTimeAmount"),
		},
		...lastCommonColumns,
	],
	career_conditions: [
		{
			key: "fromStufeName",
			name: i18n.t("fromStufe"),
		},
		{
			key: "toStufeName",
			name: i18n.t("toStufe"),
		},
		{
			key: "targetOrders",
			name: i18n.t("TargetOrders"),
		},
		{
			key: "targetDays",
			name: i18n.t("targetDays"),
		},
		{
			key: "cycle",
			name: i18n.t("cycle"),
		},
		{
			key: "averagePerDay",
			name: i18n.t("averagePerDay"),
			formatter: ({row}) => <span>{row?.averagePerDay ? row?.averagePerDay : "-"}</span>,
		},
		{
			key: "conditionFor",
			name: i18n.t("conditionFor"),
			formatter: ({row}) => (
				<span>{row?.conditionFor ? i18n.t(row?.conditionFor) : "-"}</span>
			),
		},
	],
};
