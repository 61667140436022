import {TextField} from "@material-ui/core";
import {DateRangePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React from "react";
import locale from "date-fns/locale/en-GB";
import i18n from "../../i18n";

const RangePicker = (props) => {
	const {dates, errors, onChange, newClass, newClass2, maxDate, minDate} = props;
	const today = new Date();
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
			<DateRangePicker
				startText={i18n.t("from")}
				endText={i18n.t("to")}
				value={dates}
				onChange={onChange}
				minDate={minDate}
				maxDate={maxDate || today}
				inputFormat="dd/MM/yyyy"
				renderInput={(startProps, endProps) => (
					<>
						<div className={`datepicker__input__and__error ${newClass || ""}`}>
							<TextField
								onKeyDown={(e) => e?.preventDefault()}
								className={`date__input ${errors.from && "error"}`}
								error={errors?.from}
								{...startProps}
							/>
							{errors?.from && <p className="errorClass"> {i18n.t("SelectDate")} </p>}
						</div>

						<div className={`datepicker__input__and__error ${newClass2 || ""}`}>
							<TextField
								className="date__input"
								error={errors?.to}
								{...endProps}
								onKeyDown={(e) => e?.preventDefault()}
							/>
							{errors?.to && <p className="errorClass">{i18n.t("SelectDate")} </p>}
						</div>
					</>
				)}
			/>
		</LocalizationProvider>
	);
};

export default RangePicker;
