const scrollMessage = (messageElement) => {
	const computedStyle = window.getComputedStyle(messageElement);
	const backgroundValue = computedStyle.getPropertyValue("background");
	const secondaryBackground = computedStyle.getPropertyValue("--color-secondary");
	messageElement.style.opacity = 0.6;
	messageElement.style.background = secondaryBackground;
	messageElement.style.transition = "0.4s ease-in";
	messageElement.scrollIntoView({behavior: "smooth"});
	setTimeout(() => {
		messageElement.style.opacity = 1;
		messageElement.style.background = backgroundValue;
	}, 1000);
};

export const scrollToMessage = (id, topicId, getMessagePage) => {
	const messageElement = document.getElementById(id);
	if (messageElement) {
		scrollMessage(messageElement);
	} else {
		getMessagePage({topicId, replyId: id});

		const observer = new MutationObserver((mutations) => {
			const newMessageElement = document.getElementById(id);
			if (newMessageElement) {
				scrollMessage(newMessageElement);
				observer.disconnect();
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});
	}
};
