import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as locationActions} from "@sagas/locations";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Team create");

const PREFIX = "@app/team/create";
export const ADD_LOCATION = `${PREFIX}ADD_LOCATION`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_LOCATION_SUCCESS = `${PREFIX}ADD_LOCATION_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_LOCATION = `${PREFIX}FILL_LOCATION`;

const _state = {
	loading: false,
	locationAdded: false,
	locationInitialValues: {
		id: null,
		locationName: "",
		zipCode: "",
		clientId: null,
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_LOCATION_SUCCESS:
				draft.locationAdded = action.payload;
				break;
			case FILL_LOCATION:
				draft.locationInitialValues = action.payload;
				break;
			case SHOW_MODAL:
				draft.showModal = !!action.payload;
				if (!action.payload) {
					draft.locationInitialValues = _state.locationInitialValues;
				}
				break;

			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addLocation: (payload) => createAction(ADD_LOCATION, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addLocationSuccess: (payload) => createAction(ADD_LOCATION_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillLocation: (payload) => createAction(FILL_LOCATION, {payload}),
};

export const sagas = {
	*addLocation({payload}) {
		let {values} = payload;

		yield put(actions.setLoading(true));

		try {
			if (values.id) {
				values = {...values, locationId: values.id};
				yield axios.put(`/location/update`, values);
				yield put(locationActions.editLocation(values));
				ToastSuccesComponent(i18n.t("LocationEdited"));
			} else {
				const response = yield axios.post(`/location/create`, values);
				yield put(
					locationActions.addLocationOnState({
						...values,
						id: response?.data?.data?.id,
						nr: response?.data?.data?.nr,
						isHidden: false,
					}),
				);
				yield put(locationActions.totalSizeIncrease());
				ToastSuccesComponent(i18n.t("LocationAdded"));

			}
			payload.handleLocationModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_LOCATION, sagas.addLocation);
};
