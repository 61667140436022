export const sortWithDetailedRows = (sortedRows, rowsToSort, direction) => {
	const filteredRows = sortedRows?.filter((item) => item?.type !== "DETAIL");
	const rowsToIterate = direction === "DESC" ? [...filteredRows?.reverse()] : [...filteredRows];
	const updatedRows = [];
	rowsToIterate.forEach((item) => {
		if (item.expanded) {
			const detailRow = rowsToSort?.find((r) => r.parentId === item.id);
			updatedRows.push(item);
			updatedRows.push(detailRow);
		} else {
			updatedRows.push(item);
		}
	});
	return updatedRows;
};
