import {ErrorButtonIcon, PublishedIcon, ShowInfoIcon} from "@src/assets/SvgComponents";
import ButtonComponent from "@src/common/button";
import CustomAccordion from "@src/common/CustomAccordion";
import InputComponent from "@src/common/input";
import {useFormik} from "formik";
import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Switch from "@common/switch/index";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import _ from "lodash";
import {serverFields, serverSchema as validationSchema} from "./utils";

const SFTPConnection = (props) => {
	const {clientConfig, testConnection, testError, connectSftpServer, loading, match} = props;
	const {clientId} = match.params;
	const {t} = useTranslation();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const formik = useFormik({
		validationSchema,
		initialValues: {
			...clientConfig,
			port: 22,
		},
		onSubmit: (values) => {
			if (values.buttonClicked === "testConnect") {
				delete values.buttonClicked;
				testConnection({
					clientConfig: {
						clientUrl: values?.clientUrl,
						clientUsername: values?.clientUsername,
						clientPassword: values?.clientPassword,
					},
				});
			} else {
				delete values.buttonClicked;
				connectSftpServer({
					...values,
					id: +clientId,
				});
				setFieldValue("clientPassword", "");
				setFieldTouched("clientPassword", false);
			}
		},
	});
	const {
		values,
		handleChange,
		errors,
		touched,
		handleSubmit,
		setFieldValue,
		initialValues,
		setFieldTouched,
	} = formik;
	const handleSwitch = (_, value) => {
		if (!value) {
			setShowConfirmModal(true);
		} else {
			setFieldValue("isConnected", true);
		}
	};
	const areValuesSame = useMemo(() => _.isEqual(initialValues, values), [values, initialValues]);
	const handleConfirm = () => {
		setFieldValue("isConnected", false);
		setShowConfirmModal(false);
	};
	return (
		<CustomAccordion title={t("SFTPServerSettings")} className="contracts__accordion">
			<DeleteModal
				setShowDeleteModal={setShowConfirmModal}
				confirm={handleConfirm}
				calculationModal
				open={showConfirmModal}
				newTitle="Are you sure you want to disconnect server?"
				handleClose={() => setShowConfirmModal(false)}
			/>
			<form className="server__form" onSubmit={handleSubmit} autoComplete="off">
				<div className="server__form-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("SFTPServerInfo")}</span>
				</div>
				<div className="server__form-content">
					{serverFields?.map(({inputType, key, label, disabled}, index) => (
						<>
							{(inputType === "text" || inputType === "password") && (
								<InputComponent
									key={index}
									errorClass="errorClass"
									handleChange={handleChange}
									name={key}
									errors={errors[key]}
									touched={touched[key]}
									values={values[key]}
									disabled={disabled}
									type={inputType}
									label={label}
									newClass={`server__form-content-${
										disabled ? "disabled__input" : "input"
									}`}
								/>
							)}
							{inputType === "switch" && (
								<div className="server__form-content-switch">
									<Switch
										key={index}
										label={label}
										checked={values[key]}
										switchChangeHandler={handleSwitch}
									/>
								</div>
							)}
						</>
					))}
				</div>
				<div className="server__form-actions">
					<ButtonComponent
						hasIcon={values.isConnected && !areValuesSame && testError !== "null"}
						disabled={!values?.isConnected || areValuesSame || loading}
						buttonText={t("TestConnection")}
						onClick={() => {
							setFieldValue("buttonClicked", "testConnect");
						}}
						classNames={`server__form-actions-${
							!values?.isConnected || areValuesSame || loading
								? "disabled__button"
								: "button"
						}`}
						Icon={
							testError === true && testError !== "null"
								? ErrorButtonIcon
								: PublishedIcon
						}
					/>
					<ButtonComponent
						buttonText={t("Connect")}
						disabled={loading || areValuesSame || testError === "null" || testError}
						onClick={() => {
							setFieldValue("buttonClicked", "connect");
						}}
						classNames={`server__form-actions-${
							loading || areValuesSame || testError === "null" || testError
								? "disabled__button"
								: "button"
						}`}
					/>
				</div>
			</form>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	clientConfig: state.app.client.index.client.clientConfig,
	loading: state.app.client.index.isLoading,
	testError: state.app.client.index.testError,
});
const mapDispatchToProps = {
	connectSftpServer: clientActions.connectSftpServer,
	setFieldsSettingsData: clientActions.setFieldsSettingsData,
	testConnection: clientActions.testConnection,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SFTPConnection));
