/* eslint-disable max-len */
import {put, select, takeLatest} from "redux-saga/effects";
import produce from "immer";
import axios from "axios";
import Logger from "@utils/logger";
import {actions as leadActions} from "@sagas/salesTracker";
import {actions as leadMapActions} from "@sagas/salesTracker/mapView";
import {actions as leadAddressActions} from "@sagas/salesTracker/addresses";
import moment from "moment";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import createAction from "../../../../utils/action-creator";
const logger = new Logger("SalesTracker create");

const PREFIX = "@app/salesTracker/create";

export const CREATE_LEAD = `${PREFIX}CREATE_LEAD`;
export const CREATE_LEAD_SUCCESS = `${PREFIX}CREATE_LEAD_SUCCESS`;
export const DUPLICATE_LEAD = `${PREFIX}DUPLICATE_LEAD`;
export const SET_LEAD_CHILDREN = `${PREFIX}SET_LEAD_CHILDREN`;
export const SET_LEAD_CHILDREN_DUPLICATE = `${PREFIX}SET_LEAD_CHILDREN_DUPLICATE`;

const _state = {
	lead: {},
	children: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case CREATE_LEAD_SUCCESS:
				draft.lead = action.payload;
				break;
			case SET_LEAD_CHILDREN:
				draft.children = [action.payload?.parent, ...action.payload?.children];
				break;
			case SET_LEAD_CHILDREN_DUPLICATE:
				if (state.children?.[0]) {
					draft.children = [...state.children, action.payload.children];
				} else {
					draft.children = [action.payload.parent, action.payload.children];
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	createLead: (payload) => createAction(CREATE_LEAD, {payload}),
	createLeadSuccess: (payload) => createAction(CREATE_LEAD_SUCCESS, {payload}),
	duplicateLead: (payload) => createAction(DUPLICATE_LEAD, {payload}),
	setLeadChildren: (payload) => createAction(SET_LEAD_CHILDREN, {payload}),
	setLeadChildrenDuplicate: (payload) => createAction(SET_LEAD_CHILDREN_DUPLICATE, {payload}),
};
const timezone = new Date().getTimezoneOffset();
export const sagas = {
	*createLead({payload}) {
		yield put(leadActions.setModalLoading(true));
		const {leadStatuses} = yield select((state) => state?.app?.salesTracker?.leadStatus);
		const {statuses} = yield select((state) => state.app.salesTracker.statuses);
		const currentLeadStatus = leadStatuses?.find(
			(status) => status?.id === payload?.leadStatusId,
		);
		const {values, handleCloseModal, viewType, query} = payload;
		const obj = {
			clientId: values.client,
			firstName: values?.firstName || null,
			lastName: values?.lastName || null,
			statusId: values?.statusId,
			description: values?.description || null,
			leadStatusType: values?.leadStatusType || currentLeadStatus?.leadStatusType,
			date: values.date ? moment(values?.date).subtract(timezone, "minutes").valueOf() : null,
			updateLeadId: values?.updateLeadId,
			statusLead: values.leadStatus || payload?.leadStatusId,
			userIds: values?.userIds,
			projectId: values?.projectId || null,
			createAddress: {
				street: values?.createAddress?.street,
				zipCode: values?.createAddress?.zipCode,
				location: values?.createAddress?.location,
				houseNumber: values?.createAddress?.houseNumber,
				partLocation: values?.createAddress?.partLocation,
				addition: values?.createAddress?.addition,
				latitude: values?.createAddress?.latitude || null,
				longitude: values?.createAddress?.longitude || null,
			},
		};
		yield put(leadActions.setModalLoading(true));
		try {
			if (values?.id && values?.userIds === null) {
				ToastErrorComponent(i18n.t("CantEditWithoutUsers"));
			} else {
				const response = yield axios.post(`/lead/create`, obj);
				const item = {
					...response?.data?.data,
					userIds: response?.data?.data?.userId,
				};
				delete item.userId;
				if (viewType === "mapView") {
					yield put(leadMapActions.getLeadsByMap(query));
				} else {
					yield put(leadActions.editLeadOnState({item, statuses}));
					yield put(actions.createLeadSuccess(response?.data.data));
					yield put(leadActions.getLeads({id: values?.currentUser, query}));
					yield put(leadActions.clearSelectedStatuses());
				}
				handleCloseModal();
			}
			ToastSuccesComponent(i18n.t("LeadCreated"));
		} catch (error) {
			logger.error("generate error", error);
		} finally {
			yield put(leadActions.setModalLoading(false));
		}
	},
	*duplicateLead({payload}) {
		yield put(leadActions.setLoading(true));
		try {
			const {lead, handleCloseModal, streetView, leadsFilter, addressId} = payload;
			const {query} = yield select((state) => state.app.salesTracker.index);
			const response = yield axios.get(`/lead/duplicate/${lead.id}`);
			yield put(actions.createLeadSuccess(response?.data?.data));
			handleCloseModal();
			if (streetView) {
				yield put(leadAddressActions.getAddresses({query: leadsFilter, resetPage: true}));
				yield put(leadAddressActions.clearFetchedPages({addressId}));
			} else {
				yield put(leadActions.getLeads({query}));
			}
			ToastSuccesComponent(i18n.t("LeadDuplicated"));
		} catch (error) {
			logger.error("generate error", error);
		} finally {
			yield put(leadActions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_LEAD, sagas.createLead);
	yield takeLatest(DUPLICATE_LEAD, sagas.duplicateLead);
};
