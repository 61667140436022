import {put, takeLatest} from "redux-saga/effects";
import produce from "immer";
import createAction from "../../../../utils/action-creator";

const PREFIX = "@app/language/";
export const LANGUAGE = `${PREFIX}LANGUAGE`;
export const EDIT_LANGUAGE_SUCCES = `${PREFIX}EDIT_LANGUAGE_SUCCES`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _language = localStorage.getItem("language");

const _state = {
	language: _language || "de",
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_LANGUAGE_SUCCES:
				draft.language = action.payload.payload;
				break;

			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	toggleLanguage: (payload) => createAction(LANGUAGE, {payload}),
	editLanguageSucces: (payload) => createAction(EDIT_LANGUAGE_SUCCES, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*toggleLanguage(lang) {
		try {
			yield put(actions.editLanguageSucces(lang));
			yield localStorage.setItem("language", lang.payload);
			// eslint-disable-next-line no-empty
		} catch (error) {
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(LANGUAGE, sagas.toggleLanguage);
};
