import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import {ENDPOINT} from "@src/utils/endpoint";
import i18n from "../../../../i18n";
import {modalInitialValues} from "./helper";

const logger = new Logger("Marketplace index");
const PREFIX = "@app/marketplace/index";

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_MODAL = `${PREFIX}SET_MODAL`;
export const GET_MARKETPLACE_APPS = `${PREFIX}GET_MARKETPLACE_APPS`;
export const GET_MARKETPLACE_APPS_SUCCESS = `${PREFIX}GET_MARKETPLACE_APPS_SUCCESS`;
export const SET_API_KEY = `${PREFIX}SET_API_KEY`;
export const SET_API_KEY_SUCCESS = `${PREFIX}SET_API_KEY_SUCCESS`;

const _state = {
	marketPlaceApps: [],
	loading: false,
	modalState: modalInitialValues,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_MODAL:
				if (payload.show) {
					draft.modalState = {
						open: true,
						title: payload.name,
						id: payload.id,
						apiKey: payload.apiKey,
					};
				} else {
					draft.modalState = modalInitialValues;
				}
				break;
			case GET_MARKETPLACE_APPS_SUCCESS:
				draft.marketPlaceApps = payload;
				break;
			case SET_API_KEY_SUCCESS:
				const copiedState = [...state.marketPlaceApps];
				const appIdx = copiedState?.findIndex((app) => app.id === payload.id);
				const app = copiedState[appIdx];
				copiedState[appIdx] = {
					...app,
					apiKey: payload.apiKey || null,
				};
				draft.marketPlaceApps = copiedState;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setModal: (payload) => createAction(SET_MODAL, {payload}),
	getMarketPlaceApps: (payload) => createAction(GET_MARKETPLACE_APPS, {payload}),
	getMarketPlaceAppsSuccess: (payload) => createAction(GET_MARKETPLACE_APPS_SUCCESS, {payload}),
	setApiKey: (payload) => createAction(SET_API_KEY, {payload}),
	setApiKeySuccess: (payload) => createAction(SET_API_KEY_SUCCESS, {payload}),
};

export const sagas = {
	*getMarketPlaceApps() {
		try {
			const response = yield axios.get(ENDPOINT.GET_MARKETPLACE_APPS);
			yield put(actions.getMarketPlaceAppsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*setApiKey({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put(ENDPOINT.SET_API_KEY.replace(":id", payload.id), {
				apiKey: payload.apiKey,
			});
			yield put(actions.setApiKeySuccess(payload));
			yield put(actions.setModal({show: false}));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(true));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_MARKETPLACE_APPS, sagas.getMarketPlaceApps);
	yield takeLatest(SET_API_KEY, sagas.setApiKey);
};
