import DataGridTable from "@src/common/table/DataGridTable";
import React from "react";
import {useTranslation} from "react-i18next";

function ManagerVO({conditionTable}) {
	const {t} = useTranslation();
	const data = [
		{
			price: conditionTable?.compensationIncomingCommission,
			name: t("CompensationIncomingCommission"),
			type: t("AmountPerContract"),
		},
		{
			price: conditionTable?.deduction2,
			name: t("Deduction2"),
			type: t("AmountPerContract"),
		},
		{
			price: conditionTable?.deduction1,
			name: t("Deduction1"),
			type: t("LumpSumAmount"),
		},
	];
	const cols = [
		{
			name: t("name"),
			key: "name",
		},
		{
			name: t("Type"),
			key: "type",
		},
		{
			name: t("type"),
			key: "price",
		},
	];
	return (
		<div className="managerVO-container">
			<div className="managerVO-container_list">
				<div className="managerVO-container_list-title">{t("ToBeUsed")}</div>
				<div className="managerVO-container_list-items">
					{conditionTable?.salesOrganizations?.map((item, index) => (
						<span>
							{item.label}
							{index + 1 !== conditionTable?.salesOrganizations?.length && ","}
						</span>
					))}
				</div>
			</div>
			<DataGridTable
				className="rdg-light"
				columns={cols}
				rows={data || []}
				rowHeight={47}
				style={{height: data?.length > 0 ? data?.length * 50 : 300}}
				headerRowHeight={0}
			/>
		</div>
	);
}

export default ManagerVO;
