import HtmlTooltip from "@common/htmlTooltip";
import {formatNumber} from "@src/utils/convertNumber";
import {ENDPOINT} from "@src/utils/endpoint";
import {getEndpointBasedOnVersion} from "@src/utils/getEndpointBasedOnVersion";
import moment from "moment";
import React from "react";
import i18n from "../../../../i18n";
export const {isV2CurrentVersion} = getEndpointBasedOnVersion(
	"Change_order_status_v2",
	ENDPOINT.GET_CALCULATION_BY_ID,
);
export const calculationDetailsColumns = [
	{
		name: i18n.t("SettlementId"),
		key: "clientOrderId",
		resizable: true,
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<span>{`${row?.clientOrderId ? row?.clientOrderId || "-" : row?.id || "-"}`}</span>
		),
	},
	{
		name: i18n.t("SettlementDate"),
		key: "createdAt1",
		headerCellClass: "headerStyles",
		resizable: true,
		formatter: ({row}) => <span>{`${moment(row.createdAt).format("DD.MM.YYYY")} `}</span>,
		csvExport: true,
		sortable: true,
	},
	{
		name: i18n.t("SettlementTarif"),
		key: "tariffName",
		resizable: true,
		sortable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => <span>{i18n.t(row?.tariffName)}</span>,
	},
	{
		name: i18n.t("SettlementStatus"),
		key: "projectCategoryName",
		resizable: true,
		sortable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => (
			<span>
				{row?.projectCategoryName === "BONUS_SETTLED"
					? i18n.t(row?.projectCategoryName)
					: row?.projectCategoryName}
			</span>
		),
	},

	{
		name: i18n.t("UserSP"),
		key: "salesPartnerName",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => (
			<span>{`${row?.writerName ? row?.salesPartnerName || "-" : "-"}`}</span>
		),
		export: false,
		showOnV2: false,
	},
	{
		name: i18n.t("Stufe"),
		key: "stufe",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => <span>{`${row?.stufe ? row?.stufe?.name || "-" : "-"}`}</span>,
		export: false,
	},
	{
		name: i18n.t("Structure"),
		key: "structure",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => (
			<span>{`${row?.structure ? row?.structure?.name || "-" : "-"}`}</span>
		),
		export: false,
	},
	{
		name: i18n.t("Schreiber"),
		key: "writerName",
		export: false,
		sortable: true,
		headerCellClass: "headerStyles",
		resizable: true,
		formatter: ({row}) =>
			row.orderId !== null ? (
				<span>{`${row?.writerName ? row?.writerName : row.salesPartnerName}`}</span>
			) : (
				<span>{isV2CurrentVersion ? row?.writerName : "-"}</span>
			),
	},
	{
		name: i18n.t("SettlmentProvision"),
		key: "provision",
		resizable: true,
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<span>
				{`${
					row?.orderStatus === "CANCELLED"
						? `${row?.provision1}${row?.provision1?.includes("€") ? "" : "€"}`
						: `${formatNumber(row?.provision1 ? row?.provision : row?.price)}`
				}`}
			</span>
		),
	},
	{
		name: i18n.t("Points"),
		key: "points",
		resizable: true,
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => <span>{row.points || "-"}</span>,
	},
	{
		name: i18n.t("CancelationReserve"),
		key: "cancellationReserve",
		headerCellClass: "headerStyles",
		sortable: true,
		resizable: true,
		formatter: ({row}) =>
			row.orderId !== null ? (
				<span>
					{row?.orderStatus !== "BONUS" && row?.cancellationReserve
						? `${row?.cancellationReserve}% / ${formatNumber(
								(row?.cancellationReserve / 100) * row?.provision,
						  )} €`
						: "-"}
				</span>
			) : (
				<span>-</span>
			),
	},
	{
		name: i18n.t("CommissionType"),
		key: "isSupervisor",
		headerCellClass: "headerStyles",
		sortable: true,
		resizable: true,
		showOnV2: true,
		formatter: ({row}) =>
			row.isSupervisor ? (
				<span>{i18n.t("DifferenceProvision")}</span>
			) : (
				<span>{i18n.t("Own")}</span>
			),
	},
	{
		name: i18n.t("Desciption"),
		key: "description",
		headerCellClass: "headerStyles",
		sortable: true,
		width: 0,
		resizable: true,
		formatter: ({row}) => (
			<HtmlTooltip
				placement="top"
				title={
					<span style={{fontSize: "14px"}}>
						{row.description ? row.description : "-"}
					</span>
				}
			>
				<span>{row.description ? row.description : "-"}</span>
			</HtmlTooltip>
		),
		headerStyle: {
			width: "100px",
			maxWidth: "100px",
			minWidth: "100px",
		},
		cellStyle: {
			width: "100px",
			maxWidth: "100px",
			minWidth: "100px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	},
];
export const calculationDetailsColumnsSP = [
	{
		title: i18n.t("SettlementDate"),
		field: "createdAt1",
		render: (row) => <span>{`${moment(row.createdAt).format("DD.MM.YYYY")} `}</span>,
		csvExport: true,
	},
	{title: i18n.t("SettlementTarif"), field: "tariffName"},
	{title: i18n.t("SettlementStatus"), field: "projectCategoryName"},
	{
		title: i18n.t("UserSP"),
		field: "salesPartnerName",
		export: false,
		headerStyle: {
			borderRadius: "0px 8px 0px 0px",
		},
	},
];
export const calculationDetailsColumnsExport = [
	{
		title: i18n.t("SettlementId"),
		field: "clientOrderId",
		render: (row) => (
			<span>{`${row?.clientOrderId ? row?.clientOrderId || "-" : row?.id || "-"}`}</span>
		),
	},
	{
		title: i18n.t("SettlementDate"),
		field: "createdAt1",
		render: (row) => <span>{`${moment(row.createdAt).format("DD.MM.YYYY")} `}</span>,
		csvExport: true,
	},
	{
		title: i18n.t("SettlementTarif"),
		field: "tariffName",
		render: (row) => <span>{i18n.t(row?.tariffName)}</span>,
	},
	{title: i18n.t("SettlementStatus"), field: "projectCategoryName"},
	{title: i18n.t("FirstName"), field: "firstName"},
	{title: i18n.t("LastName"), field: "lastName"},
	{title: i18n.t("CompanyName"), field: "company"},
	{title: i18n.t("ProjectLocation"), field: "locationName"},
	{
		title: i18n.t("UserSP"),
		field: "salesPartnerName",
		render: (row) => <span>{`${row?.writerName ? row?.salesPartnerName : "-"}`}</span>,
		export: false,
	},
	{
		title: i18n.t("Schreiber"),
		field: "writerName",
		export: false,
		render: (row) =>
			row.orderId !== null ? (
				<span>{`${row?.writerName ? row?.writerName : row.salesPartnerName}`}</span>
			) : (
				<span>-</span>
			),
	},
	{
		title: i18n.t("SettlmentProvision"),
		field: "provision1",
		render: (row) => <span>{`${row?.provision1 ? row?.provision1 : row?.price}`}</span>,
	},
	{
		title: i18n.t("Points"),
		field: "points",
	},
	{
		title: i18n.t("CancelationReserve"),
		field: "cancellationReserve",
		render: (row) =>
			row.orderId !== null ? (
				<span>
					{row?.orderStatus !== "BONUS" && row?.cancellationReserve
						? `${row?.cancellationReserve}% / ${(
								(row?.cancellationReserve / 100) *
								row?.provision
						  ).toFixed(2)} €`
						: "-"}
				</span>
			) : (
				<span>-</span>
			),
	},
	{
		title: i18n.t("Desciption"),
		field: "description",
		width: 0,
		render: (rowData) => (
			<HtmlTooltip
				placement="top"
				title={
					<span style={{fontSize: "14px"}}>
						{rowData.description ? rowData.description : "-"}
					</span>
				}
			>
				<span>{rowData.description ? rowData.description : "-"}</span>
			</HtmlTooltip>
		),
		headerStyle: {
			width: "100px",
			maxWidth: "100px",
			minWidth: "100px",
		},
		cellStyle: {
			width: "100px",
			maxWidth: "100px",
			minWidth: "100px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	},
];
