import React, {useEffect} from "react";
import {connect} from "react-redux";
import RadioInput from "@common/radioButton";
import RadioGroupInput from "@src/common/radioButton/radioGroupInput";
import CustomSelectOptions from "@src/common/components/customSelectOptions";
import {radioInputs, typeDetails} from "./staticData";

const FirstStep = (props) => {
	const {loggedUser, formik} = props;
	const {setFieldValue, values, touched, errors, handleChange, initialValues} = formik;
	// useEffect(() => {
	// 	if (values.typeList && !values.id) {
	// 		setFieldValue("userList", []);
	// 		setFieldValue("clientList", []);
	// 		setFieldValue("teamList", []);
	// 	}
	// 	if (values.id) {
	// 		setFieldValue("userList", initialValues.userList);
	// 		setFieldValue("clientList", initialValues.clientList);
	// 		setFieldValue("teamList", initialValues.teamList);
	// 	}
	// }, [values.typeList]);

	// useEffect(() => {
	// 	if (values.typeList && !values.id) {
	// 		setFieldValue("userList", []);
	// 		setFieldValue("clientList", []);
	// 		setFieldValue("teamList", []);
	// 	} else if (values.id && values.typeList !== initialValues.typeList) {
	// 		// Restore initial values if id is present
	// 		setFieldValue("userList", initialValues.userList);
	// 		setFieldValue("clientList", initialValues.clientList);
	// 		setFieldValue("teamList", initialValues.teamList);
	// 	}
	// }, [values.typeList, values.id]);

	const {options, placeholder, title, dropDowntitle, formikValueName} =
		typeDetails[values?.typeList];

	return (
		<div className="firstStep__container">
			<div className="firstStep__container--items">
				<div className="firstStep__container--items-radioButtons">
					<div className="flex__radioButtons">
						<RadioGroupInput
							disabled={false}
							name="vatEntitlement"
							onChange={handleChange}
							value={values.typeList}
							title="Select"
							defaultValue="no"
						>
							<div className="radio__inputs">
								{radioInputs.map(({value, label}, index) => (
									<RadioInput
										key={index}
										checked={values.typeList === value}
										label={label}
										value={value}
										onChange={(event) =>
											setFieldValue("typeList", event.target.value)
										}
										name="typeList"
									/>
								))}
							</div>
						</RadioGroupInput>
					</div>
				</div>
				{touched.type && errors.type && <div>*{errors.type}</div>}
			</div>
			<CustomSelectOptions
				formik={formik}
				options={props[options] || []}
				formikName={typeDetails[values?.typeList].formikValueName}
				placeholder={placeholder}
				loggedUser={loggedUser}
				title={title}
				dropDowntitle={dropDowntitle}
				array={values[formikValueName] || []}
				isAnalytics
			/>
		</div>
	);
};
const mapStateToProps = (state) => ({
	clientList: state.app.client.index.clientsByDropdown,
	usersOptions: state.app.users.index.usersOptions,
	loggedUser: state.app.users.index.loggedUser,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
});
export default connect(mapStateToProps, null)(FirstStep);
