import React from "react";

const PageList = ({list, newClass}) => (
	<div className={newClass || ""}>
		<div className="clientList">
			{list?.map((item, i) => (
				<span
					key={i}
					className={`clientList-text1 ${
						list?.length - 1 === i ? "clientList-text2" : ""
					}`}
					onClick={item?.fn}
				>
					{item?.name} {list?.length - 1 !== i && " / "}
				</span>
			))}
		</div>
	</div>
);

export default PageList;
