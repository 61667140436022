import {defaultDropAnimationSideEffects} from "@dnd-kit/core";

const currentLanguage = localStorage.getItem("language");

export const getLabel = (item, field = "label") =>
	currentLanguage === "en" ? item?.[`${field}En`] : item?.[`${field}De`];

export const dropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: "0.5",
			},
		},
	}),
};
