/* eslint-disable */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TabComp from '@common/createUserTabs/createUserTabsComp/index'
import { actions as orderActions } from '@sagas/orders/index'
import { Skeleton } from 'antd'
import { genericLabels, options } from './staticData'
import 'antd/dist/antd.min.css'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const datesTypes = {
  year: 'datesYearly',
  quarter: 'datesSixMonth',
  month: 'datesMonthly',
  week: 'datesWeekly',
  day: 'datesDaily',
}

function StatisticBars(props) {
  const {
    orderGraphs,
    handleActive,
    active,
    datesGraph,
    rangeType,
    setRangeType,
    setActive,
    clients,
    graphLoading,
  } = props
  const { t } = useTranslation()
  const [data, setData] = useState({ labels: [], datasets: [] })
  const [range, setRange] = useState(0)
  const [daysInMonth, setDaysInMonth] = useState(30)
  const [daysInTwooMonths, setDaysInTwooMonths] = useState(92)
  const [daysInThreeMonths, setDaysInThreeMonths] = useState(92)

  const tempArr = data?.datasets?.map((e) => e.data)
  const flattenedArray = [].concat(...tempArr)
  const allZeros = flattenedArray?.every((element) => element === 0)
  const maxValue = Math.max(...flattenedArray)
  const isOdd = maxValue % 2 !== 0
  const monthOptions = {
    ...options,
    scales: {
      ...options.scales,
      xAxes: {
        ...options.scales.xAxes,
        stacked: true,
      },
      yAxes: {
        ...options.scales.yAxes,
        stacked: true,
        ticks: {
          ...options.scales.yAxes.ticks,
          stepSize: isOdd ? 20 : 15,
        },
      },
    },
    plugins: {
      ...options.plugins,
      datalabels: {
        ...options.plugins.datalabels,
        display: true,
      },
    },
  }
  useEffect(() => {
    const chartDates = []
    const clientsData = []
    const orderStats = []
    const keyArr = []
    let obj = {
      label: '',
      data: [],
      backgroundColor: '',
      borderWidth: 1,
      barThickness: active === 'year' ? 8 : 30,
    }
    if (active === 'custom') {
      const to = moment(datesGraph?.to)
      const from = moment(datesGraph?.from)
      setRange(to?.diff(from, 'days') + 1)
      setDaysInMonth(moment(from).daysInMonth())
      const helper = moment(from).add(1, 'month')
      const secondHelper = moment(from).add(2, 'month')
      setDaysInTwooMonths(moment(helper).daysInMonth() + daysInMonth)
      setDaysInThreeMonths(
        moment(secondHelper).daysInMonth() + daysInTwooMonths,
      )
    }
    for (let index = 0; index < orderGraphs.length; index++) {
      const element = orderGraphs[index]
      const dateString = moment(element?.date).format('MM-DD')
      chartDates.push(dateString)
      clientsData.push(element.clients)
    }
    if (clientsData?.some((value) => value === undefined)) return
    clientsData?.map((client) =>
      Object.keys(client)?.map((key) => keyArr.push(key)),
    )
    const uniqueChars = [...new Set(keyArr)]
    uniqueChars.filter((item) => {
      const keys = []
      let label
      const color = []
      clientsData.map((cli) =>
        Object.entries(cli)?.map(([key, value]) => {
          if (item === key) {
            const foundedClient = clients?.find(
              (client) => client?.clientId === item,
            )
            label = item
            color.push(foundedClient?.pdfColor)
            keys.push(value)
          }
        }),
      )
      obj = { ...obj, label, backgroundColor: color, data: keys }
      orderStats.push(obj)
      const genericLabelsType =
        active !== 'custom' ? datesTypes[active] : datesTypes[rangeType]
      setData({
        labels: genericLabels[genericLabelsType],
        datasets: orderStats,
      })
    })
  }, [orderGraphs, active, datesGraph, rangeType])
  useEffect(() => {
    switch (true) {
      case range === 1:
        setRangeType('day')
        setActive('custom')
        break
      case range > 1 && range <= 7:
        setRangeType('week')
        setActive('custom')
        break
      case range > 7 && range <= daysInMonth:
        setRangeType('month')
        setActive('custom')
        break
      case range > daysInMonth && range <= daysInThreeMonths:
        setRangeType('quarter')
        setActive('custom')
        break
      case range > daysInThreeMonths:
        setRangeType('year')
        setActive('custom')
        break
      default:
        setRangeType('')
    }
  }, [range])

  return (
    <div>
      {graphLoading ? (
        <div className='graph-skeleton'>
        <Skeleton.Input active style={{ height: 30, borderRadius: 8, width: '100%' }} />
        <Skeleton.Input active style={{ height: 520, width: '100%' }} />
        </div>
      ) : (
        <div className="statistics__container">
          <div className="statistics__information">
            <div className="statistics__information__upLegend">
              {clients?.map((item) => (
                <div className="statistics__information__legend">
                  <div
                    className="statistics__information__legend-color"
                    style={{ backgroundColor: `${item.pdfColor}` }}
                  >
                    {item?.clientId}
                  </div>
                  {/* <span className="statistics__information__legend-text">
              {item.label}
            </span> */}
                </div>
              ))}
            </div>
            <div className="statistics__information__selectedDate">
              <span>
                {Number.isNaN(datesGraph.from)
                  ? ''
                  : moment(datesGraph.from).format('DD-MM-YYYY')}
              </span>
              <span>-</span>
              <span>
                {Number.isNaN(datesGraph.to)
                  ? ''
                  : moment(datesGraph.to).format('DD-MM-YYYY')}
              </span>
            </div>
          </div>
          <div className="statistics__information-second">
            <span className="statistics__information__text">
              {t('SaleStatistics')}
            </span>
          </div>
          <div className="statistics__information-second">
            {!allZeros ? (
              <Bar
                data={data}
                options={
                  active === 'month' || rangeType === 'month'
                    ? monthOptions
                    : options
                }
                type="bar"
                plugins={[ChartDataLabels]}
              />
            ) : (
              <span className="noDataMsg">{t('NoData')}</span>
            )}
          </div>
          <div className="statistics__information-second">
            <div className="statistics__information-bottom">
              <TabComp
                onClick={() => handleActive({ type: 'day' })}
                active={active === 'day'}
                word={t('day')}
                templates
              ></TabComp>

              <TabComp
                onClick={() => handleActive({ type: 'week' })}
                active={active === 'week'}
                word={t('Week')}
                templates
              ></TabComp>

              <TabComp
                onClick={() => handleActive({ type: 'month' })}
                active={active === 'month'}
                word={t('month')}
                templates
              ></TabComp>

              <TabComp
                onClick={() => handleActive({ type: 'quarter' })}
                active={active === 'quarter'}
                word={`3 ${t('month')}`}
                templates
              ></TabComp>

              <TabComp
                onClick={() => handleActive({ type: 'year' })}
                active={active === 'year'}
                word={t('year')}
                templates
              ></TabComp>
              <TabComp
                onClick={() => handleActive({ type: 'custom' })}
                active={active === 'custom'}
                word={t('custom')}
                templates
              ></TabComp>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  // orderGraphs: state.app.orders.index.orderGraphs,
})

const mapDispatchToProps = {
  getOrderGraphs: orderActions.getOrdersGraphs,
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticBars)
