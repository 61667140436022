/* eslint-disable no-console */
/* eslint-disable max-len */
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {actions as notificationActions} from "@sagas/notifications/index";
import {actions as navigationActions} from "@sagas/navigation/index";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
export const firebaseConfig = {
	apiKey: "AIzaSyCII0YRTOeQs5fwZSXtqg194aV1sFYh-ro",
	authDomain: "oneri-7407c.firebaseapp.com",
	projectId: "oneri-7407c",
	storageBucket: "oneri-7407c.appspot.com",
	messagingSenderId: "384659979035",
	appId: "1:384659979035:web:af6164bcedd9be1a39f1be",
	measurementId: "G-BS2VVW7RQ3",
};
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const onMessageListener = (history, dispatch) =>
	onMessage(messaging, (payload) => {
		if (payload.data.type === "APPOINTMENT_REMINDER") {
			dispatch(notificationActions.getUnreadNotificationsCountSuccess("increment"));
			ToastInfoComponent(
				`${i18n.t("NewNotification")}`,
				navigationActions,
				history.location.pathname,
			);
		}
	});
export const requestForToken = () =>
	getToken(messaging, {
		vapidKey:
			// eslint-disable-next-line max-len
			"BGQhHYgHSp3vlwVQ_SJrk17XRC9wcBYVYWUqoZXePOtZXIqs5szrnk_Bir7AUMnbU5a3b0t-0q_L1lDTpKy-yfU",
	})
		.then((currentToken) => {
			if (currentToken) {
				return currentToken;
				// return currentToken;
				// console.log("current token for client: ", currentToken);
				// Perform any other neccessary action with the token
			}
			// Show permission request UI
			console.log("No registration token available. Request permission to generate one.");
		})
		.catch((err) => {
			console.log("An error occurred while retrieving token. ", err);
		});
