import {CloseModalIcon} from "@src/assets/SvgComponents";
import React, {useState, useEffect} from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField, Box, Modal} from "@mui/material";
import Button from "@src/common/components/button";
import {makeStyles} from "@material-ui/core/styles";
import {getSelectOptionsFromDataTeams} from "@src/utils/select";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import CustomSelect from "@src/common/Select";
// eslint-disable-next-line import/no-cycle
import Userstab from "../Userstab";

const LeadUserActionsModal = (props) => {
	const {
		showActionsModal,
		editLeadUsers,
		selectedRows,
		setShowActionsModal,
		filteredUser,
		users,
		setSelectedRows,
		selectedStatuses,
		fromModal,
	} = props;
	const {t} = useTranslation();
	const {getUrlParams} = useUrlParams();
	const {query} = getUrlParams("tab", "query");
	const [selectedUser, setSelectedUser] = useState(null);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const useStyles = makeStyles({
		popper: {
			width: "100%",
		},
	});
	const {type, open, title} = showActionsModal;
	const classes = useStyles();
	const handleSubmit = () => {
		const data = {
			leadsIds: fromModal
				? [selectedRows?.[0]?.id]
				: selectedRows
						?.filter((item) => !selectedStatuses?.ids?.includes(item?.statusId))
						?.map((it) => it?.id),
			removeId: type === t("Add") || type === t("RemoveAll") ? null : query?.userId,
			addId: selectedUser && type !== t("RemoveAll") ? [selectedUser] : null,
			statusIds: selectedStatuses?.ids,
		};
		editLeadUsers({
			data,
			query,
		});
		if (!fromModal) {
			setSelectedRows([]);
		}
		handleCloseActionsModal();
	};
	const handleCloseActionsModal = () => {
		setShowActionsModal({open: false, type: "", title: ""});
		setSelectedUser(null);
	};
	useEffect(() => {
		if (query?.userId) {
			const filteredArray = users?.filter((user) => user?.id !== query?.userId);
			setFilteredUsers(filteredArray);
		} else {
			setFilteredUsers(users);
		}
	}, [query?.userId, users]);
	return (
		<Modal open={open} onClose={handleCloseActionsModal} disableEnforceFocus>
			<Box className="sales__tracker__box">
				<div className={`sales__tracker__actions__modal${type === t("Add") ? "-add" : ""}`}>
					<div className="actionsModal-wrapper">
						<div className="actionsModal-header">
							<span className="actionsModal-header-title">{title}</span>
							<div
								style={{cursor: "pointer"}}
								onClick={() => handleCloseActionsModal()}
							>
								<CloseModalIcon fill="#6c1eb075" />
							</div>
						</div>
						<div className="actionsModal-body">
							{type === t("Add") && (
								<Userstab
									selectedRows={selectedRows}
									type="selected"
									closeModal={handleCloseActionsModal}
								/>
							)}
							{type === t("Remove") && (
								<span className="actionsModal-body-text">
									{t("AreYouSureRemove")} {filteredUser?.name}
									{t("fromSelectedLeads")}
								</span>
							)}
							{type === t("RemoveAll") && (
								<span className="actionsModal-body-text">
									{t("AreYouSureRemoveAll")}
									{t("fromSelectedLeads")}
								</span>
							)}
							{type === t("Replace") && (
								<>
									<span className="actionsModal-body-text">
										{`${t("ReplaceLeadFrom")} ${filteredUser?.name} ${t("to")}`}
									</span>
									<div className="users__dropdown">
										<CustomSelect
											options={getSelectOptionsFromDataTeams(
												filteredUsers,
												"name",
												"id",
											)}
											onSelect={(value) => setSelectedUser(value)}
											onClear={() => setSelectedUser(null)}
											value={selectedUser}
											placeholder={t("SelectUser")}
											label={t("User")}
										/>
									</div>
								</>
							)}
						</div>
						<div className="actionsModal-footer">
							<div>
								{type === t("Remove") && (
									<Button
										text={t("Cancel")}
										borderRadius="8px"
										border="1px solid #979797"
										color="#979797"
										background="transparent"
										width="140px"
										height="42px"
										leftRounded={true}
										handleChange={() => handleCloseActionsModal()}
									/>
								)}
							</div>
							{(type === t("Replace") ||
								type === t("Remove") ||
								type === t("RemoveAll")) && (
								<div style={{marginTop: type === t("Replace") && "30px"}}>
									<Button
										text={type === t("RemoveAll") ? t("Remove") : type}
										borderRadius="8px"
										width="140px"
										height="42px"
										disabled={
											type !== t("Remove") &&
											type !== t("RemoveAll") &&
											selectedUser === null
										}
										leftRounded={true}
										handleChange={() => handleSubmit()}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default LeadUserActionsModal;
