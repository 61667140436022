import i18n from "@src/i18n";
import {t} from "i18next";
import moment from "moment";
const language = localStorage.getItem("language");
export const convertTypes = (item) => {
	if (item?.dropDownParentId) {
		return "boolean";
	}
	switch (item?.inputType) {
		case "DROPDOWN":
			return item?.customOptionsString !== null || item?.dropDownChilds !== null
				? "customDropdown"
				: "dropdown";
		case "TEXT":
		case "TEXTAREA":
		case "NUMBER":
			return "input";
		case "EMAIL":
			return "email";
		case "CHECKBOXES":
		case "RADIOBUTTON":
		case "TARIFF":
		case "PRODUCT":
			return "boolean";
		case "UPLOAD":
			return "upload";
		case "IMAGE":
			return "signature";
		case "TITLE":
			return "title";
		case "DATE":
		case "BIRTHDAY":
			return "date";
		case "CHECKBOX":
			return "checkbox";
		default:
			return "input";
	}
};

export const convertCustomFieldsToForm = (data) => {
	const arr = [];
	for (let index = 0; index < Object.keys(data).length; index++) {
		const item = Object.keys(data)[index];
		const obj = {
			inputType: convertTypes(data[item]),
			name: `customFieldsValue.${item}.value`,
			placeholder: language === "en" ? data[item]?.labelEn : data[item]?.labelDe,
			item: data[item],
		};
		arr.push(obj);
	}
	const concatArray = DEFAULT.concat(arr);
	return concatArray;
};

// did this checks, for the old orders;
// every new client create new custom fields don't use existing fields;
const viewOrderDetails = [
	"firstName",
	"lastName",
	"street",
	"addition",
	"houseNumber",
	"userEmail",
	"zip",
	"signatureDate",
	"subLocation",
	"place",
	"company",
];
const OPN = ["orderProductNumber"];
export const convertCustomFieldsToForm1 = (data) => {
	const arr = [];
	for (let index = 0; index < data.length; index++) {
		const item = data[index];
		let nameValue = `customFieldsValue.${item?.name}.value`;
		if (viewOrderDetails.includes(item.name)) {
			nameValue = `createOrderDetails.${item?.name}`;
		}
		if (OPN.includes(item.name)) {
			nameValue = `orderProductNumber`;
		}

		const obj = {
			inputType: convertTypes(item),
			name: nameValue,
			placeholder: language === "en" ? item?.labelEn : item?.labelDe,
			item,
		};
		arr.push(obj);
	}
	const concatArray = DEFAULT1.concat(arr);
	return concatArray;
};
export const convertCustomFieldsToForm2 = (data) => {
	const arr = [];
	for (let index = 0; index < data.length; index++) {
		const item = data[index];
		let nameValue = `customFieldsValue.${item?.name}.value`;
		if (viewOrderDetails.includes(item.name)) {
			nameValue = `createOrderDetails.${item?.name}`;
		}
		if (OPN.includes(item.name)) {
			nameValue = `orderProductNumber`;
		}

		const obj = {
			inputType: convertTypes(item),
			name: nameValue,
			placeholder: language === "en" ? item?.labelEn : item?.labelDe,
			item,
		};
		arr.push(obj);
	}
	return arr;
};

export const customerTypeData = [
	{
		name: "PrivateClient",
		value: "PRIVATE",
	},
	{
		name: "Business",
		value: "BUSINESS",
	},
];

export const personTitleData = [
	{
		name: t("Mr"),
		value: "Mr.",
	},
	{
		name: t("Mrs"),
		value: "Mrs.",
	},
];

export const TNG = [
	{
		inputType: "title",
		title: "OrderInfo",
	},
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderClip",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "ProjectCategories",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "dropdown",
		name: "costumerType",
		placeholder: "customerType",
		options: customerTypeData,
	},
	{
		inputType: "dropdown",
		name: "createOrderDetails.salutation",
		placeholder: "Salutation",
		options: personTitleData,
	},
	{
		inputType: "input",
		name: "userTitle",
		placeholder: "Title",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "firstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "lastName",
	},
	{
		inputType: "input",
		name: "company",
		placeholder: "CompanyName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "createOrderDetails.houseNumber",
		placeholder: "HouseNumber",
	},
	{
		inputType: "input",
		name: "createOrderDetails.addition",
		placeholder: "addition",
	},
	{
		inputType: "input",
		name: "createOrderDetails.place",
		placeholder: "Place",
	},
	{
		inputType: "input",
		name: "createOrderDetails.subLocation",
		placeholder: "subLocation",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.dayOfBirth",
		placeholder: "dayOfBirth",
		maxDate: moment().valueOf(),
	},
	{
		inputType: "input",
		name: "createOrderDetails.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "input",
		name: "createOrderDetails.userEmail",
		placeholder: "email",
	},
	{
		inputType: "input",
		name: "createOrderDetails.phone",
		placeholder: "Telephone",
	},
	{
		inputType: "input",
		name: "createOrderDetails.mobileNumber",
		placeholder: "mobileNumber",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.signatureDate",
		placeholder: "signatureDate",
	},
	{
		inputType: "radioGroup",
		hiddenOnEdit: false,
		name: "createOrderDetails.homeOwner",
		placeholder: "oneFamilyHouse",
		options: [
			{label: "oneFamilyHouse", value: "oneFamilyHouse"},
			{label: "apartmentBuilding", value: "apartmentBuilding"},
		],
	},
	{
		inputType: "radioGroup",
		hiddenOnEdit: false,
		name: "createOrderDetails.apartmentFloor",
		placeholder: "oneFamilyHouse",
		options: [
			{label: "owner", value: "underground"},
			{label: "coOwner", value: "upstairs"},
			{label: "renter", value: "groundFloor"},
		],
	},
	{
		inputType: "input",
		name: "floor",
		placeholder: "Name Of Prop Owner",
		width: "65%",
		dependencies: "createOrderDetails.apartmentFloor",
		match: "groundFloor",
	},
	{
		inputType: "signature",
		name: "createOrderDetails.signature",
		placeholder: "Signature",
		width: "100%",
	},
	{
		inputType: "title",
		title: "BankInformation",
	},
	{
		inputType: "dropdown",
		name: "createBankAccount.salutation",
		placeholder: "BankSalutation",
		options: personTitleData,
	},
	{
		inputType: "input",
		name: "createBankAccount.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createBankAccount.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "createBankAccount.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "createBankAccount.houseNumber",
		placeholder: "houseNr",
	},
	{
		inputType: "input",
		name: "createBankAccount.addition",
		placeholder: "addition",
	},
	{
		inputType: "input",
		name: "createBankAccount.location",
		placeholder: "Location",
	},
	{
		inputType: "input",
		name: "createBankAccount.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "input",
		name: "createBankAccount.bankName",
		placeholder: "bankName",
	},
	{
		inputType: "input",
		name: "createBankAccount.bankIban",
		placeholder: "Iban",
		noTouched: true, // validate without touched field
	},
	// {
	// 	inputType: "input",
	// 	name: "createBankAccount.bankBic",
	// 	placeholder: "Bic",
	// },
	{
		inputType: "signature",
		name: "createBankAccount.signature",
		placeholder: "Signature",
		width: "100%",
	},
	{
		inputType: "title",
		title: "addressInfo",
	},
	{
		inputType: "dropdown",
		name: "createAddressForInvoice.salutation",
		placeholder: "AddressSalutation",
		options: personTitleData,
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.fullName",
		placeholder: "NameOrCompany",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.contactPersonFirma",
		placeholder: "contactPerson",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.email",
		placeholder: "Email",
	},
	{
		inputType: "datePicker",
		name: "createAddressForInvoice.dayOfBirth",
		placeholder: "dayOfBirth",
		maxDate: moment().valueOf(),
	},
	// {datepicker: true},
	{
		inputType: "input",
		name: "createAddressForInvoice.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.houseNumber",
		placeholder: "houseNr",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.addition",
		placeholder: "addition",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.abwRACity",
		placeholder: "City",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.phone",
		placeholder: "telephoneNumber",
	},
	{
		inputType: "input",
		name: "createAddressForInvoice.mobileNumber",
		placeholder: "mobileNr",
	},
	{
		inputType: "radioGroup",
		hiddenOnEdit: false,
		name: "checkFormerProvider",
		placeholder: "Check Former Provider",
		options: [
			{label: "TransferNumber", value: "true"},
			{label: "notTransferNum", value: "false"},
		],
	},
	{
		inputType: "title",
		title: "formerInfo",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.formerProviderName",
		placeholder: "providerName",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "dropdown",
		name: "createFormerProvider.salutation",
		placeholder: "ProviderSalutation",
		options: personTitleData,
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.firstName",
		placeholder: "providerFirstName",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.lastName",
		placeholder: "providerLastName",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.street",
		placeholder: "Street",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.houseNumber",
		placeholder: "houseNr",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.addition",
		placeholder: "addition",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.zip",
		placeholder: "Zipcode",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.location",
		placeholder: "Location",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.locationPrefix",
		placeholder: "prefix",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.providerExtension",
		placeholder: "extension",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.activePhoneNumber1",
		placeholder: "telephoneNumber",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.activePhoneNumber2",
		placeholder: "telephoneNumber",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.activePhoneNumber3",
		placeholder: "telephoneNumber",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.activePhoneNumber4",
		placeholder: "telephoneNumber",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.activePhoneNumber5",
		placeholder: "telephoneNumber",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "input",
		name: "createFormerProvider.activePhoneNumber6",
		placeholder: "telephoneNumber",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "signature",
		name: "createFormerProvider.signature",
		placeholder: "Signature",
		width: "100%",
		dependencies: "checkFormerProvider",
		match: "true",
	},
	{
		inputType: "title",
		title: "additionalContractor",
	},
	{
		inputType: "dropdown",
		name: "additionalContractor.salutation",
		placeholder: "AdditionalSalutation",
		options: personTitleData,
	},
	{
		inputType: "input",
		name: "additionalContractor.firstName",
		placeholder: "firstName",
	},
	{
		inputType: "input",
		name: "additionalContractor.lastName",
		placeholder: "lastName",
	},
	{
		inputType: "input",
		name: "additionalContractor.userEmail",
		placeholder: "Email",
	},
	{
		inputType: "input",
		name: "additionalContractor.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "additionalContractor.place",
		placeholder: "place",
	},
	{
		inputType: "input",
		name: "additionalContractor.houseNumber",
		placeholder: "houseNr",
	},
	{
		inputType: "input",
		name: "additionalContractor.addition",
		placeholder: "addition",
	},
	{
		inputType: "input",
		name: "additionalContractor.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "input",
		name: "additionalContractor.telephone",
		placeholder: "telephoneNumber",
	},
	{
		inputType: "input",
		name: "additionalContractor.mobileNumber",
		placeholder: "mobileNr",
	},
	{
		inputType: "datePicker",
		name: "additionalContractor.dayOfBirth",
		placeholder: "dayOfBirth",
		maxDate: moment().valueOf(),
	},
	// {datepicker: "true"},
	// {
	// 	inputType: "title",
	// 	title: "entryOnThePhone",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.firma",
	// 	placeholder: "Company",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.activity",
	// 	placeholder: "activity",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.firstName",
	// 	placeholder: "firstName",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.lastName",
	// 	placeholder: "lastName",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.street",
	// 	placeholder: "Street",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.houseNumber",
	// 	placeholder: "houseNr",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.addition",
	// 	placeholder: "addition",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.zip",
	// 	placeholder: "Zipcode",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.place",
	// 	placeholder: "place",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.mobileNumber",
	// 	placeholder: "mobileNr",
	// },
	// {
	// 	inputType: "input",
	// 	name: "onThePhone.fax",
	// 	placeholder: "fax",
	// },
	// {datepicker: true},
	{
		inputType: "title",
		title: "Signature",
	},
	{
		inputType: "signature",
		name: "signature",
		placeholder: "Signature",
		width: "100%",
	},
	// {
	// 	inputType: "checkbox",
	// 	name: "termsAndConditions",
	// 	placeholder: "termsAndConditions",
	// 	width: "100%",
	// },
	{
		inputType: "checkbox",
		name: "sendNewsLetter",
		placeholder: "DoYouWantToSubscribeToNewsletter",
		width: "100%",
	},
];
// export const SBK = [
// 	{
// 		inputType: "title",
// 		title: "OrderInfo",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "tariffId",
// 		placeholder: "Tariff",
// 		url: `/tariff/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 		hiddenOnEdit: true,
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "products",
// 		disableClearable: false,
// 		multiple: true,
// 		placeholder: "Product",
// 		url: "/product/tariff/:tariffId:",
// 		dependencies: "tariffId",
// 		hiddenOnPreview: true,
// 		hiddenOnEdit: true,
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "projectCategoryId",
// 		placeholder: "projectCategoryId",
// 		url: `/projectCategory/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 		hiddenOnEdit: true,
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "locationId",
// 		placeholder: "Locations",
// 		url: `/location/all/:clientId:`,
// 		dependencies: "clientId",
// 		hiddenOnEdit: true,
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.firstName",
// 		placeholder: "FirstName",
// 		hiddenOnEdit: true,
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.lastName",
// 		placeholder: "LastName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.userEmail",
// 		placeholder: "Email",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvBirthName",
// 		placeholder: "BirthName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvCountryOfBirth",
// 		placeholder: "CountryOfBirth",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvDateOfBirth",
// 		placeholder: "dayOfBirth",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvMaritalStatus",
// 		placeholder: "MaritalStatus",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvNumber",
// 		placeholder: "Number",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvPlace",
// 		placeholder: "Place",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvPlaceOfBirth",
// 		placeholder: "PlaceOfBirth",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvPreviousHealthCompany",
// 		placeholder: "PreviousHealthCompany",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvPreviousHealthCompanyNumber",
// 		placeholder: "PreviousHealthCompanyNumber",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvStreet",
// 		placeholder: "Street",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.hvZIP",
// 		placeholder: "Zip",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.nameOfEmployer",
// 		placeholder: "NameOfEmployer",
// 	},
// 	{
// 		inputType: "title",
// 		title: "HVSignature",
// 	},
// 	{
// 		inputType: "signature",
// 		name: "hvSignature",
// 		placeholder: "Signature",
// 		width: "100%",
// 	},
// ];
export const DGN = [
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderProductNumber",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.signatureDate",
		placeholder: "signatureDate",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "projectCategories",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "company",
		placeholder: "Company",
	},
];
export const GFD = [
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderProductNumber",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.signatureDate",
		placeholder: "signatureDate",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "projectCategoryId",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "company",
		placeholder: "Company",
	},
];
export const DGF = [
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderProductNumber",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.signatureDate",
		placeholder: "signatureDate",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "projectCategories",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "company",
		placeholder: "Company",
	},
];
export const EON = [
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderProductNumber",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.signatureDate",
		placeholder: "signatureDate",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "projectCategoryId",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "company",
		placeholder: "Company",
	},
];
export const TER = [
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderProductNumber",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.signatureDate",
		placeholder: "signatureDate",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "projectCategoryId",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "company",
		placeholder: "Company",
	},
	{
		inputType: "input",
		name: "createOrderDetails.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "createOrderDetails.houseNumber",
		placeholder: "HouseNumber",
	},
	{
		inputType: "input",
		name: "createOrderDetails.addition",
		placeholder: "Addition",
	},
	{
		inputType: "input",
		name: "createOrderDetails.place",
		placeholder: "Place",
	},
	{
		inputType: "input",
		name: "createOrderDetails.subLocation",
		placeholder: "SubLocation",
	},
	{
		inputType: "input",
		name: "createOrderDetails.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "radioGroup",
		hiddenOnEdit: true,
		name: "contractImageView",
		placeholder: "contractImageView",
		options: [
			{label: "viaET", value: "viaET"},
			{
				label: "viaMFH",
				value: "viaMFH",
			},
			{
				label: "viaPapier",
				value: "viaPapier",
			},
		],
	},
	{
		inputType: "image",
		name: "contractImage",
		placeholder: "contractImage",
	},
];

// export const DGG = [
// 	{
// 		inputType: "input",
// 		name: "orderProductNumber",
// 		placeholder: "orderProductNumber",
// 	},
// 	{
// 		inputType: "datePicker",
// 		name: "createOrderDetails.signatureDate",
// 		placeholder: "signatureDate",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "tariffId",
// 		placeholder: "Tariff",
// 		url: `/tariff/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "products",
// 		disableClearable: false,
// 		multiple: true,
// 		placeholder: "Product",
// 		url: "/product/tariff/:tariffId:",
// 		dependencies: "tariffId",
// 		hiddenOnPreview: true,
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "projectCategoryId",
// 		placeholder: "projectCategoryId",
// 		url: `/projectCategory/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "locationId",
// 		placeholder: "Locations",
// 		url: `/location/all/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.firstName",
// 		placeholder: "FirstName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.lastName",
// 		placeholder: "lastName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.interneHinweise",
// 		placeholder: "interneHinweise",
// 	},
// ];
export const VFK = [
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderProductNumber",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.signatureDate",
		placeholder: "signatureDate",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "projectCategoryId",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "company",
		placeholder: "Company",
	},
	{
		inputType: "input",
		name: "createOrderDetails.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "createOrderDetails.houseNumber",
		placeholder: "HouseNumber",
	},
	{
		inputType: "input",
		name: "createOrderDetails.addition",
		placeholder: "Addition",
	},
	{
		inputType: "input",
		name: "createOrderDetails.place",
		placeholder: "Place",
	},
	{
		inputType: "input",
		name: "createOrderDetails.subLocation",
		placeholder: "SubLocation",
	},
	{
		inputType: "input",
		name: "createOrderDetails.zip",
		placeholder: "Zipcode",
	},
];
export const HNT = [
	{
		inputType: "title",
		title: "OrderInfo",
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "ProjectCategories",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "dropdown",
		name: "createOrderDetails.salutation",
		placeholder: "Salutation",
		options: personTitleData,
	},
	{
		inputType: "input",
		name: "orderProductNumber",
		placeholder: "orderProductNumber",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "firstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "lastName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "createOrderDetails.houseNumber",
		placeholder: "HouseNumber",
	},
	{
		inputType: "input",
		name: "createOrderDetails.floor",
		placeholder: "StockWerk",
	},
	{
		inputType: "input",
		name: "createOrderDetails.addressId",
		placeholder: "AddressID",
	},
	{
		inputType: "input",
		name: "createOrderDetails.addition",
		placeholder: "addition",
	},
	{
		inputType: "input",
		name: "createOrderDetails.place",
		placeholder: "Place",
	},
	{
		inputType: "datePicker",
		name: "createOrderDetails.dayOfBirth",
		placeholder: "dayOfBirth",
		maxDate: moment().valueOf(),
	},
	{
		inputType: "input",
		name: "createOrderDetails.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "input",
		name: "createOrderDetails.userEmail",
		placeholder: "email",
	},
	{
		inputType: "input",
		name: "createOrderDetails.phone",
		placeholder: "Telephone",
	},
	{
		inputType: "input",
		name: "createOrderDetails.mobileNumber",
		placeholder: "mobileNumber",
	},
	{
		inputType: "title",
		title: "",
	},
	{
		inputType: "checkbox",
		name: "keepPhoneNumber",
		placeholder: "keepPhoneNumber",
		width: "100%",
	},
	{
		inputType: "checkbox",
		name: "newNumber",
		placeholder: "newNumber",
		width: "100%",
	},
	{
		inputType: "checkbox",
		name: "secondVoiceChannel",
		placeholder: "secondVoiceChannel",
		width: "100%",
	},
	{
		inputType: "title",
		title: "",
	},
	{
		inputType: "checkbox",
		name: "itemizedBillNone",
		placeholder: "none",
		width: "100%",
	},
	{
		inputType: "checkbox",
		name: "itemizedBillShortened",
		placeholder: "shortened",
		width: "100%",
	},
	{
		inputType: "checkbox",
		name: "itemizedBillCompletely",
		placeholder: "completly",
		width: "100%",
	},
	{
		inputType: "title",
		title: "additionalContractor",
	},
	{
		inputType: "dropdown",
		name: "additionalContractor.salutation",
		placeholder: "AdditionalSalutation",
		options: personTitleData,
	},
	{
		inputType: "input",
		name: "additionalContractor.firstName",
		placeholder: "firstName",
	},
	{
		inputType: "input",
		name: "additionalContractor.lastName",
		placeholder: "lastName",
	},
	{
		inputType: "input",
		name: "additionalContractor.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "additionalContractor.place",
		placeholder: "place",
	},
	{
		inputType: "input",
		name: "additionalContractor.houseNumber",
		placeholder: "houseNr",
	},
	{
		inputType: "input",
		name: "additionalContractor.addition",
		placeholder: "addition",
	},
	{
		inputType: "input",
		name: "additionalContractor.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "title",
		title: "BankInformation",
	},
	{
		inputType: "input",
		name: "createBankAccount.firstName",
		placeholder: "FirstName",
	},
	{
		inputType: "input",
		name: "createBankAccount.lastName",
		placeholder: "LastName",
	},
	{
		inputType: "input",
		name: "createBankAccount.street",
		placeholder: "Street",
	},
	{
		inputType: "input",
		name: "createBankAccount.houseNumber",
		placeholder: "houseNr",
	},
	{
		inputType: "input",
		name: "createBankAccount.addition",
		placeholder: "addition",
	},
	{
		inputType: "input",
		name: "createBankAccount.location",
		placeholder: "Location",
	},
	{
		inputType: "input",
		name: "createBankAccount.zip",
		placeholder: "Zipcode",
	},
	{
		inputType: "input",
		name: "createBankAccount.bankName",
		placeholder: "bankName",
	},
	{
		inputType: "input",
		name: "createBankAccount.bankIban",
		placeholder: "Iban",
		noTouched: true, // validate without touched field
	},
	{
		inputType: "input",
		name: "createBankAccount.bankBic",
		placeholder: "Bic",
	},
	{
		inputType: "signature",
		name: "createBankAccount.signature",
		placeholder: "Signature",
		width: "100%",
	},
	{
		inputType: "radioGroup",
		hiddenOnEdit: false,
		name: "homeOwner",
		placeholder: "homeOwner",
		options: [
			{label: "iAmHomeOwner", value: "homeOwner"},
			{
				label: "notHomeOwner",
				value: "ownerAssociation",
			},
		],
	},
	{
		inputType: "title",
		title: "homeOwnerForm",
		dependencies: "homeOwner",
		match: "homeOwner",
	},
	{
		inputType: "input",
		name: "createOwnerInfo.homeOwnerFirstName",
		placeholder: "homeOwnerFirstName",
		dependencies: "homeOwner",
		match: "homeOwner",
	},
	{
		inputType: "input",
		name: "createOwnerInfo.homeOwnerLastName",
		placeholder: "homeOwnerLastName",
		dependencies: "homeOwner",
		match: "homeOwner",
	},
	{
		inputType: "input",
		name: "createOwnerInfo.homeOwnerPlace",
		placeholder: "homeOwnerPlace",
		dependencies: "homeOwner",
		match: "homeOwner",
	},
	{
		inputType: "signature",
		name: "createOwnerInfo.homeOwnerSignature",
		placeholder: "Signature",
		width: "100%",
		dependencies: "homeOwner",
		match: "homeOwner",
	},
	{
		inputType: "title",
		title: "formerInfo",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.name",
		placeholder: "providerName",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.firstName",
		placeholder: "providerFirstName",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.lastName",
		placeholder: "providerLastName",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.street",
		placeholder: "Street",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.houseNumber",
		placeholder: "houseNr",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.zip",
		placeholder: "Zipcode",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.place",
		placeholder: "Location",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.portsAreaCode",
		placeholder: "prefix",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	{
		inputType: "input",
		name: "providerChange.portsAreaPhone",
		placeholder: "extension",
		dependencies: "keepPhoneNumber",
		match: true,
	},
	// TOO DO
	// {
	// 	inputType: "input",
	// 	name: "providerChange.activePhoneNumber1",
	// 	placeholder: "telephoneNumber",
	// 	dependencies: "keepPhoneNumber",
	// 	match: true,
	// },
	// {
	// 	inputType: "input",
	// 	name: "providerChange.activePhoneNumber2",
	// 	placeholder: "telephoneNumber",
	// 	dependencies: "keepPhoneNumber",
	// 	match: true,
	// },
	// {
	// 	inputType: "input",
	// 	name: "providerChange.activePhoneNumber3",
	// 	placeholder: "telephoneNumber",
	// 	dependencies: "keepPhoneNumber",
	// 	match: true,
	// },
	// {
	// 	inputType: "input",
	// 	name: "providerChange.activePhoneNumber4",
	// 	placeholder: "telephoneNumber",
	// 	dependencies: "keepPhoneNumber",
	// 	match: true,
	// },
	// {
	// 	inputType: "input",
	// 	name: "providerChange.activePhoneNumber5",
	// 	placeholder: "telephoneNumber",
	// 	dependencies: "keepPhoneNumber",
	// 	match: true,
	// },
	// {
	// 	inputType: "input",
	// 	name: "providerChange.activePhoneNumber6",
	// 	placeholder: "telephoneNumber",
	// 	dependencies: "keepPhoneNumber",
	// 	match: true,
	// },
	{
		inputType: "signature",
		name: "providerChange.signature",
		placeholder: "Signature",
		width: "100%",
		dependencies: "keepPhoneNumber",
		match: true,
	},

	{
		inputType: "title",
		title: "dataPolicy",
	},
	{
		inputType: "checkbox",
		name: "createDataPolicy.fullData",
		placeholder: "fullData",
		width: "100%",
	},
	{
		inputType: "checkbox",
		name: "createDataPolicy.abbreviateData",
		placeholder: "abbreviateData",
		width: "100%",
	},
	{
		inputType: "checkbox",
		name: "createDataPolicy.deleteData",
		placeholder: "deleteData",
		width: "100%",
	},
	{
		inputType: "title",
		title: "advertisingConsent",
	},
	{
		inputType: "signature",
		name: "createDataPolicy.advertisingContentSignature",
		placeholder: "Signature",
		width: "100%",
	},
	{
		inputType: "title",
		title: "contractSummary",
	},
	{
		inputType: "signature",
		name: "createDataPolicy.contractSummarySignature",
		placeholder: "Signature",
		width: "100%",
	},
	{
		inputType: "title",
		title: "orderPlacement",
	},
	{
		inputType: "signature",
		name: "createDataPolicy.orderPlacementSignature",
		placeholder: "Signature",
		width: "100%",
	},
];
// export const SOT = [
// 	{
// 		inputType: "input",
// 		name: "orderProductNumber",
// 		placeholder: "orderProductNumber",
// 	},
// 	{
// 		inputType: "datePicker",
// 		name: "createOrderDetails.signatureDate",
// 		placeholder: "signatureDate",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "tariffId",
// 		placeholder: "Tariff",
// 		url: `/tariff/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "products",
// 		disableClearable: false,
// 		multiple: true,
// 		placeholder: "Product",
// 		url: "/product/tariff/:tariffId:",
// 		dependencies: "tariffId",
// 		hiddenOnPreview: true,
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "projectCategoryId",
// 		placeholder: "projectCategoryId",
// 		url: `/projectCategory/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "locationId",
// 		placeholder: "Locations",
// 		url: `/location/all/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.firstName",
// 		placeholder: "FirstName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.lastName",
// 		placeholder: "lastName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.interneHinweise",
// 		placeholder: "interneHinweise",
// 	},
// ];
// export const DTG = [
// 	{
// 		inputType: "input",
// 		name: "orderProductNumber",
// 		placeholder: "orderProductNumber",
// 	},
// 	{
// 		inputType: "datePicker",
// 		name: "createOrderDetails.signatureDate",
// 		placeholder: "signatureDate",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "tariffId",
// 		placeholder: "Tariff",
// 		url: `/tariff/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "products",
// 		disableClearable: false,
// 		multiple: true,
// 		placeholder: "Product",
// 		url: "/product/tariff/:tariffId:",
// 		dependencies: "tariffId",
// 		hiddenOnPreview: true,
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "projectCategoryId",
// 		placeholder: "projectCategoryId",
// 		url: `/projectCategory/drop-down/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "asyncDropDown",
// 		name: "locationId",
// 		placeholder: "Locations",
// 		url: `/location/all/:clientId:`,
// 		dependencies: "clientId",
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.firstName",
// 		placeholder: "FirstName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "createOrderDetails.lastName",
// 		placeholder: "lastName",
// 	},
// 	{
// 		inputType: "input",
// 		name: "customFieldsValue.interneHinweise",
// 		placeholder: "interneHinweise",
// 	},
// ];

export const DEFAULT = [
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/tariff/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId:",
		dependencies: "tariffId",
		hiddenOnPreview: true,
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "ProjectCategories",
		url: `/projectCategory/drop-down/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId:`,
		dependencies: "clientId",
	},
	{
		inputType: "input",
		name: "createOrderDetails.firstName",
		placeholder: "firstName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.lastName",
		placeholder: "lastName",
	},
	{
		inputType: "input",
		name: "createOrderDetails.userEmail",
		placeholder: "userEmail",
	},
];
export const DEFAULT1 = [
	{
		inputType: "asyncDropDown",
		name: "locationId",
		placeholder: "Locations",
		url: `/location/all/:clientId`,
		dependencies: "clientId",
		item: {
			parentId: "",
		},
	},
	{
		inputType: "asyncDropDown",
		name: "tariffId",
		placeholder: "Tariff",
		url: `/location/:locationId/tariffs`,
		dependencies: "locationId",
		clearField: "products",
		item: {
			parentId: "",
		},
	},
	{
		inputType: "asyncDropDown",
		name: "products",
		disableClearable: false,
		multiple: true,
		placeholder: "Product",
		url: "/product/tariff/:tariffId",
		dependencies: "tariffId",
		hiddenOnPreview: true,
		item: {
			parentId: "",
		},
	},
	{
		inputType: "asyncDropDown",
		name: "projectCategoryId",
		placeholder: "ProjectCategories",
		url: `/projectCategory/drop-down/:clientId`,
		dependencies: "clientId",
		item: {
			parentId: "",
		},
	},
];
export default {
	TNG,
	DGN,
	GFD,
	DGF,
	EON,
	TER,
	HNT,
	VFK,
	DEFAULT,
};

export const importedFields = [
	{
		placeholder: i18n.t("FileId"),
		field: "fileId",
	},
	{
		placeholder: i18n.t("contractId"),
		field: "contractId",
	},
	{
		placeholder: i18n.t("ContractDate"),
		field: "dateOfContract",
	},
	{
		placeholder: i18n.t("Status"),
		field: "orderStatus",
	},
	{
		placeholder: i18n.t("VPName"),
		field: "vpName",
	},
	{
		placeholder: i18n.t("VPNumber"),
		field: "vpNumber",
	},
];
