import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Formik} from "formik";
import * as yup from "yup";
import InputComponent from "../../../common/input";
import ButtonComponent from "../../../common/button";
import {actions as registerAction} from "../../../store/sagas/app/auth/register";
const validationSchema = yup.object().shape({
	email: yup
		.string()
		.lowercase()
		.strict()
		.label("E-mail")
		.email("Enter a valid email")
		.required("Please enter a registered email"),
	password: yup
		.string()
		.label("Password")
		.required()
		.min(8, "Password must have at least 8 characters "),
	// eslint-disable-next-line react/sort-prop-types
});

const Register = (props) => {
	const {navigation, classNames, submitRegister, register} = props;

	return (
		<div className={`${classNames}`}>
			<Formik
				initialValues={register}
				onSubmit={(values) =>
					submitRegister({
						email: values.email,
						password: values.password,
					})
				}
				validationSchema={validationSchema}
			>
				{({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
					<form autoComplete="off" noValidate onSubmit={handleSubmit}>
						<span className="title">settlement</span>
						<InputComponent
							name="email"
							placeholder="E-Mail"
							type="email"
							errors={errors.email}
							values={values.email}
							handleBlur={handleBlur}
							handleChange={handleChange}
							touched={touched.email}
						/>
						<InputComponent
							name="password"
							placeholder="Password"
							type="password"
							errors={errors.password}
							values={values.password}
							handleBlur={handleBlur}
							handleChange={handleChange}
							touched={touched.password}
						/>
						<ButtonComponent classNames="authBtn" type="submit" buttonText="Continue" />
						<div className="login-bottom">
							<span className="terms-privacy">
								By creating an account, you are agreeing to our{" "}
								<span>Terms of Service</span> and <span>Privacy Policy.</span>
							</span>
							<span className="no-account">Already have an account?</span>
							<span
								className="create-account"
								onClick={() => navigation("/auth/login")}
							>
								Login
							</span>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

const mapStateToProps = (state) => ({
	register: state?.app?.register?.user,
});
const mapDispatchToProps = {
	submitRegister: registerAction.register,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
