import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as clientActions} from "@sagas/client/index";
import {actions as clientDeletedActions} from "@sagas/client/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Client>Restore");

const PREFIX = "@app/Client/restore";
export const RESTORE = `${PREFIX}RESTORE`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;

const _state = {
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	restore: (payload) => createAction(RESTORE, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
};

export const sagas = {
	*restore({payload}) {
		try {
			const {id} = payload;
			const {list, size} = yield select((state) => state.app.client.deleted);
			const item = list.find((e) => e.id === id);
			yield axios.put(`/client/restore/${id}`);
			yield put(clientDeletedActions.delete(id));
			yield put(clientActions.addClientSuccess(item));
			yield put(clientActions.clientAddIncreaseTotalSize());
			if (list.length >= size) {
				yield put(clientDeletedActions.fetch());
			}
			ToastSuccesComponent(i18n.t("ClientRestoreSuccess"));
			payload.handleClientModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(RESTORE, sagas.restore);
};
