import Signature from "@src/common/signature";
import React, { useRef } from "react";
import { PenSignature, ResetIcon } from "@src/assets/SvgComponents";
import OrderViewTitle from "./title";

const SignatureComponent = ({ title, subtitle, setFieldValue, element, value }) => {
	const signatureRef = useRef();
	return (
		<div className="signatureComponent__container">
			<div className="signatureComponent__container--firstSection">
				<div className="signatureComponent__container--firstSection-container">
					<OrderViewTitle
						icon={<PenSignature stroke="#6C1EB0" />}
						title={title}
						subtitle={subtitle}
					/>
					<OrderViewTitle title={title} subtitle={subtitle} />
				</div>
			</div>
			<div className="termCondition__container--borderTop"></div>
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div className="signatureComponent__container--signature">
					<div className="signatureComponent__container--signature-component">
						{value ? <img src={value} alt="" /> : (
							<Signature width={335} height={160} setFieldValue={setFieldValue} element={element} signatureRef={signatureRef} useCustomRef={true} />
						)}
					</div>
					<div className="signatureComponent__container--signature-buttons">
						{!value && (
							<div className="signatureComponent__container--signature-buttons-reset" onClick={() => {
								setFieldValue(element.name, "");
								signatureRef.current.clear();
							}}>
								<div>
									<ResetIcon />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
};

export default SignatureComponent;
