import Form from "@src/common/Form";
import React from "react";
import {useTranslation} from "react-i18next";

const ReplacePdf = ({open, selectedPdf, handleClose, clientId, loading, replaceGenericPdf}) => {
	const {t} = useTranslation();
	const handleSubmit = (values) => {
		const payloadObj = {
			clientId,
			base64: values?.file?.[0]?.link,
			fileName: values?.file?.[0]?.name?.substring(
				0,
				values?.file?.[0]?.name?.lastIndexOf("."),
			),
			generateFileName: "|companyName|,$CURRENT_DATE$,#00001#",
			pdfFileId: selectedPdf.id,
		};

		replaceGenericPdf({payloadObj, handleClose});
	};
	const formData = {
		inputs: [
			{
				inputType: "upload",
				required: true,
				field: "file",
				title: "PDF",
				accept: ".pdf",
			},
		],
		title: t("replacePdf"),
		submitFn: (values) => {
			handleSubmit(values);
		},
	};
	return <Form formData={formData} open={open} handleClose={handleClose} loading={loading} />;
};

export default ReplacePdf;
