import React from "react";
import {useTranslation} from "react-i18next";

const PdfNameInformation = () => {
	const {t} = useTranslation();
	return (
		<div className="fileName-generator-container">
			<div className="fileName-generator-container-title">PDF NAME</div>
			<div className="fileName-generator-container-wrapper">
				<p>{t("Tousethesethreedifferentcharacters")}</p>
				<div className="fileName-generator-container-wrapper-list">
					<ol>
						<li>{t("Usetheverticalbarcharacter")}</li>
						<li>{t("Usethehashcharacter")}</li>
						<li>{t("Usethedollarsigncharacter")}</li>
					</ol>
					<p className="instructions-example">{t("Herearetheavailableenums")}</p>
				</div>
				<p className="instructions-example">{t("example")}</p>
			</div>
		</div>
	);
};

export default PdfNameInformation;
