import React from "react";

const Tabs = ({items, setActive, value}) => (
	<div className="client-settings__tabs">
		{items?.map((item, index) => (
			<div
				className={`tab ${value === item ? "active" : ""}`}
				onClick={() => setActive(item)}
				key={index}
			>
				<span>{item}</span>
			</div>
		))}
	</div>
);

export default Tabs;
