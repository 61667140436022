import {actions as messageActions} from "@sagas/messages/index";
import moment from "moment";

// eslint-disable-next-line import/no-cycle
import {getStore} from "../../store";
class MessageEntity {
	// eslint-disable-next-line max-statements
	CREATE = (data) => {
		const {dispatch, getState} = getStore();
		const {messages} = getState().app;
		const message = {
			createdAtTimestamp: data.createdAtTimestamp,
			fullName: `${data.senderName}`,
			id: data?.messageId,
			text: data?.text,
			topicId: data?.topicId,
			files: data?.files,
			replyMessage: data?.replyMessage,
			type: data?.type,
		};
		const insideTopic = messages?.index?.insideTopic;

		dispatch(
			messageActions.setUnseenMessages({
				topicId: data?.topicId,
				value: "increment",
			}),
		);
		// if someone create a new group or channel than add it do topics
		if (data?.type && insideTopic?.topicId !== data?.topicId) {
			if (data.type === "PRIVATE") {
				data = {
					...data,
					name: data.senderName,
				};
			}
			dispatch(messageActions.createGroupChannelSuccess({data}));
			// change seen only in saga / don't send request to change it to true
			dispatch(
				messageActions.seenMessageSuccess({
					topicId: data?.topicId,
					type: data?.type,
					value: false,
				}),
			);
		}

		if (insideTopic?.topicId === message?.topicId && message?.id) {
			dispatch(messageActions.addMessageInsideTopic(message));
			// send seen request
			dispatch(
				messageActions.seenMessage({
					topicId: message?.topicId,
					value: true,
				}),
			);
			dispatch(messageActions.changeTopicOrder({topicId: message?.topicId}));
			dispatch(messageActions.setLastMessage({topicId: data?.topicId, lastMessage: message}));
		} else {
			// change seen only in saga / don't send request to change it to true
			dispatch(
				messageActions.seenMessageSuccess({
					topicId: message?.topicId,
					value: false,
				}),
			);
			dispatch(messageActions.changeTopicOrder({topicId: message?.topicId}));
		}
	};

	DELETE = (data) => {
		const {dispatch, getState} = getStore();
		const {users} = getState().app;
		const {loggedUser} = users?.index;
		const message = {
			id: data.messageId,
			deletedAt: moment().valueOf(),
			userId: data.senderId,
			text:
				loggedUser?.id === data?.senderId
					? "This message has been deleted"
					: "Other user deleted a message !",
		};
		dispatch(messageActions.replaceDeletedMessage(message));
	};
}

export default new MessageEntity();
