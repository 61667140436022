import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as tariffActions} from "@sagas/tariff/clientTariff";
import {actions as deletedTariffActions} from "@sagas/tariff/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Sagas>Delete>Index");

const PREFIX = "@app/tariffs/deleteClientTariff";
export const DELETE_TARIFF = `${PREFIX}DELETE_TARIFF`;
export const DEACTIVATE_TARIFF = `${PREFIX}DEACTIVATE_TARIFF`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_DELETE_MODAL = `${PREFIX}SHOW_DELETE_MODAL`;

const _state = {
	loading: false,
	deleteShowModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_DELETE_MODAL:
				draft.deleteShowModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteTariff: (payload) => createAction(DELETE_TARIFF, {payload}),
	deactivateTariff: (payload) => createAction(DEACTIVATE_TARIFF, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowDeleteModal: (payload) => createAction(SHOW_DELETE_MODAL, {payload}),
};

export const sagas = {
	*deactivateTariff({payload}) {
		yield put(actions.setLoading(true));

		try {
			const list = yield select((state) => state.app.tariffs.clientTariff.clientTariffs);
			const size = yield select((state) => state.app.tariffs.clientTariff.size);
			const item = list.find((e) => e.id === payload.id);
			yield axios.put(`/tariff/deactivate/${payload.id}`);
			yield put(tariffActions.deactivateTarrifOnState(payload.id));
			yield put(deletedTariffActions.add(item));
			ToastSuccesComponent(i18n.t("TariffDeactiveSuccess"));
			payload.handleTariffModal(false);
			yield put(tariffActions.totalSizeDecrease());
			if (list.length >= size) {
				yield put(tariffActions.fetchClientTariffs(payload.client));
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteTariff({payload}) {
		yield put(actions.setLoading(true));

		try {
			yield axios.put(`/tariff/delete/${payload?.id}`);
			yield put(deletedTariffActions.delete(payload?.id));
			ToastSuccesComponent(i18n.t("TariffDeleteSuccess"));
			payload.handleTariffModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DEACTIVATE_TARIFF, sagas.deactivateTariff);
	yield takeLatest(DELETE_TARIFF, sagas.deleteTariff);
};
