import React from "react";
import {Image, Link, StyleSheet, Text, View} from "@react-pdf/renderer";
import {useTranslation} from "react-i18next";
import Logo from "@assets/img/oneriLogo.png";

const CalculationTablePdf = ({data}) => {
	const {t} = useTranslation();
	const splittedAddress = data?.address?.split(",");
	return (
		<View style={styles.fixedFooter} fixed>
			<View style={styles.footer}>
				<View style={[styles.footerText, styles.extraFooterTextStyle]}>
					<Text>{data?.companyName}</Text>
					<Text>{splittedAddress?.[0] ? `${splittedAddress?.[0]},` : data?.address}</Text>
					{splittedAddress?.[1] && <Text>{splittedAddress?.[1]}</Text>}
				</View>
				<View style={styles.footerText}>
					<Text>{data?.bankNumber}</Text>
					<Text>{data?.swiftCode}</Text>
					<Text>{data?.bicCode}</Text>
					<Text>{data?.bankName} </Text>
				</View>
				<View style={styles.footerText}>
					<Text>{t("taxNumber")}</Text>
					<Text>{data?.taxNumber}</Text>
					{/* <Text>DE328302609 </Text> */}
				</View>
				<View style={styles.footerText}>
					<Text>{t("ManagingDirectors")}</Text>
					<Text>{data?.firstDirector}</Text>
					<Text>{data?.secondDirector} </Text>
				</View>
				<View style={styles.footerText}>
					<Text>{t("Jurisdiction")}</Text>
					<Text>{data?.firstJurisdiction}</Text>
					<Text>{data?.secondJurisdiction} </Text>
				</View>
				{/* <View style={styles.footerText}>
					<Text style={{color: "#fff"}}> </Text>
					<Text>{data?.website}</Text>
					<Text style={{color: "#fff"}}> </Text>
				</View> */}
			</View>
			<View style={styles.website}>
				<Text>
					erstellt mit{" "}
					<Link href="https://oneri.de/" style={styles.websiteLink}>
						oneri.de
					</Link>
				</Text>
				<Image src={Logo} style={styles.websiteLogo} />
			</View>
		</View>
	);
};

export default CalculationTablePdf;

const styles = StyleSheet.create({
	viewContainer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
	},
	viewContainerWeb: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
	},
	footer: {
		display: "flex",
		flexDirection: "row",
		fontSize: 7,
		justifyContent: "space-around",
		marginTop: 5,
		fontFamily: "Helvetica",
		lineHeight: 1.5,
	},
	footerText: {
		display: "flex",
		flexDirection: "column",
		marginTop: 5,
	},
	extraFooterTextStyle: {
		maxWidth: 80,
		textOverflow: "",
	},
	fixedFooter: {
		// position: "absolute",
		bottom: 0,
		left: 0,
		right: 20,
		marginTop: 25,
		borderTop: "3px solid #3279BE",
		display: "flex",
		flexDirection: "column",
	},
	website: {
		color: "#252525",
		fontSize: 8,
		fontWeight: 400,
		marginTop: 20,
		width: "100%",
		flex: 1,
		display: "flex",
		justifyContent: "center",
		flexDirection: "row",
		fontFamily: "Helvetica",
	},
	websiteLink: {
		color: "#252525",
		textDecoration: "none",
		fontSize: 8,
	},
	websiteLogo: {
		height: 7,
		paddingLeft: 10,
	},
});
