import {toCamelCase} from "@src/components/dashboard/Energy/OrderForm/helper";

export const saveTenantFeatureEndpointData = (data) => {
	const newObj = {};

	for (let i = 0; i < data.length; i++) {
		const currentFeature = data?.[i];
		const roleIds = currentFeature?.roleIds?.split(",")?.map((it) => +it);
		if (currentFeature.type) {
			newObj[toCamelCase(currentFeature?.type)] = roleIds;
		} else {
			newObj[currentFeature?.endpointUrl] = roleIds;
		}
	}
	return newObj;
};
