import React from "react";
import ModalComponent from "@common/modal";
import {useTranslation} from "react-i18next";
import {BackIconModal, Close} from "@src/assets/SvgComponents";
import {useFormik} from "formik";
import * as yup from "yup";
import {withRouter} from "react-router";
import ButtonComponent from "@src/common/button";
import {connect} from "react-redux";
import {actions as createClientActions} from "@sagas/client/create";
import InputComponent from "@common/input";

function ClientInfoModal({showModal, setShowModal, addContactInfo, contact, match}) {
	const {t} = useTranslation();
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const validationSchema = yup.object().shape({
		firstName: yup.string().label(t("firstName")).required(t("FirstNameIsReq")),
		email: yup
			.string()
			.lowercase()
			.strict()
			.label("Email")
			.email()
			.required("Enter a valid email"),
		phone: yup.string().label(t("MobilePhone")).required(t("IsRequired")),
	});
	const formik = useFormik({
		validationSchema,
		initialValues: {
			firstName: contact?.contactForDocument || "",
			email: contact?.emailForDocument || "",
			phone: contact?.telephoneForDocument || "",
		},
		enableReinitialize: true,
		onSubmit: (values, {resetForm}) => {
			const obj = {
				...values,
				clientId: +match.params.clientId,
			};
			addContactInfo(obj);
			resetForm();
			handleCloseModal();
		},
	});
	const {values, handleSubmit, setFieldValue, errors, touched} = formik;
	return (
		<ModalComponent
			open={showModal === "edit"}
			handleClose={handleCloseModal}
			disableEnforceFocus
			customClassNames="statistics__upload__documents"
			width={600}
			positionModal="right"
		>
			<div className="statistics__upload__documents-header">
				<div onClick={handleCloseModal} style={{cursor: "pointer"}}>
					<BackIconModal fill="var(--color-primary)" />
				</div>
				<span className="statistics__upload__documents-header-text">
					{contact?.clientId ? t("Edit Client Info") : t("Create Client Info")}
				</span>
				<div onClick={handleCloseModal} style={{cursor: "pointer"}}>
					<Close fill="var(--color-primary)" />
				</div>
			</div>
			<div className="statistics__upload__documents-body" style={{width: "100%", gap: 0}}>
				<InputComponent
					className="custom-input"
					label={t("ContactName")}
					values={values?.firstName}
					errorClass="errorClass"
					handleChange={(value) => setFieldValue("firstName", value.target.value)}
					errors={errors?.firstName}
					touched={touched?.firstName}
				/>
				<InputComponent
					className="custom-input"
					label={t("ContactEmail")}
					type="email"
					values={values?.email}
					errorClass="errorClass"
					handleChange={(value) => setFieldValue("email", value.target.value)}
					errors={errors?.email}
					touched={touched?.email}
				/>
				<InputComponent
					className="custom-input"
					label={t("ContactPhone")}
					values={values?.phone}
					typ="number"
					errorClass="errorClass"
					handleChange={(value) => setFieldValue("phone", value.target.value)}
					errors={errors?.phone}
					touched={touched?.phone}
				/>
			</div>
			<div className="statistics__upload__documents-footer">
				<ButtonComponent type="submit" buttonText={t("Upload")} onClick={handleSubmit} />
			</div>
		</ModalComponent>
	);
}
const mapStateToProps = (state) => ({
	contact: state.app.client.create.contact,
});

const mapDispatchToProps = {
	addContactInfo: createClientActions.addContactInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientInfoModal));
