/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import {
  DocumentSignature,
  GlassSignature,
  PenSignature,
  XiconSignature,
} from '@assets/SvgComponents'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import { actions as contactlessOrderActions } from '@sagas/contactlessOrder/index'
import Steps from './components/Steps'
import CheckBoxComponent from '@src/common/checkbox'
import SignatureComponent from './components/signatureComponent'
import ButtonComponent from '@src/common/button'
import { ToastErrorComponent } from '@src/common/ToastComponent/ToastComponent'
import { getValue } from './helper'

const ContactlessSignature = ({
  getContactlessOrder,
  contactlessState,
  submitOrder,
  language,
}) => {
  const steps = ['Info', 'Terms & Conditions', 'Signature']
  const [activeStep, setActiveStep] = useState('Info')
  const [signature, setSignature] = useState('')
  const { contactlessOrder, params, loading } = contactlessState;
  useEffect(() => {
    getContactlessOrder()
  }, [])
  const groupedFields = _.groupBy(contactlessOrder?.getOrderField?.orderFieldViewList, language === "en" ? 'parentNameEn' : 'parentNameDe')
  const handleStep = (item) => setActiveStep(item)
  const setFieldValue = (_, value) => {
    setSignature(value)
  }
  const handleSubmitOrder = () => {
    if (!signature) {
      ToastErrorComponent("Signature shouldn't be empty")
    } else {
      submitOrder({
        ...params,
        contactlessSignatures: signature,
      })
    }
  }
  useEffect(() => {
    setSignature(contactlessOrder?.contractView?.contactlessSignatures);
  }, [contactlessOrder])
  return (
    <div className="contactless__superContainer">
      <div className="contactless__container">
        <div className="contactless__container--preview">
          <span>Preview Contract</span>
          <span>
            <XiconSignature />
          </span>
        </div>
        <div className="contactless__container--username">
          <span>
            <PenSignature />
          </span>
          <span>{`Hallo, ${'username'}`}</span>
        </div>
        <div className="contactless__container--steps">
          <Steps items={steps} active={activeStep} onClick={handleStep} />
        </div>
        <div className="contactless__container--contractData">
          <div className="contactless__container--contractData-icon-text">
            <span>
              <GlassSignature />
            </span>
            <div className="contactless__container--contractData-text">
              <span>Vertragsdaten</span>
              <span>Bitte überprüfen Sie die Vertragsdaten</span>
            </div>
          </div>
          {activeStep === 'Info' && (
            <div className="contactless__container--contractData--infos">
              {Object.entries(groupedFields)?.map((item) => {
                const [key, fields] = item
                const filteredFields = fields?.filter((field) => !field.hideMobile && !field.hideWeb && field.dropDownParentId === null)
                if (filteredFields?.length === 0) return null;
                return (
                  <div key={key} className="group_container">
                    <div className="group_container__title">{key}</div>
                    {filteredFields?.length > 0 ? (
                      filteredFields?.map((field, index) => {
                        const { labelDe, labelEn } = field;
                        return (
                          (
                            <div className="field__info" key={index}>
                              <p className="field__info__name">
                                {language === "en" ? labelEn : labelDe}
                              </p>
                              <p className="field__info__value">
                                {getValue(field, contactlessOrder, language)}
                              </p>
                            </div>
                          )
                        )
                      })
                    ) : (
                      <div className="field__info" style={{ border: 'none' }}>
                        kein abweichende Rechnungsanschrift
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
          {activeStep === 'Terms & Conditions' && (
            <>
              <div className="terms__container">
                <div className="terms__container-header">
                  <span className="terms__container-header-icon">
                    <DocumentSignature />
                  </span>
                  <span className="terms__container-header-title">
                    Allgemeine Geschäftsbedingungen
                  </span>
                </div>
                <div className="terms__container-content">
                  <p>
                    Lorem Ipsum Is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum. is
                    simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has
                    survived not only five centuries, but also the leap into
                    remaining essentially unchanged. It was popularised in the
                    ... Lorem Ipsum Is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with{' '}
                  </p>
                </div>
                <div className="terms__container-checkbox">
                  <CheckBoxComponent
                    checked={true}
                    label="Ich habe die Allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese"
                  />
                </div>
              </div>
              <div className="terms__container">
                <div className="terms__container-header">
                  <span className="terms__container-header-icon">
                    <DocumentSignature />
                  </span>
                  <span className="terms__container-header-title">
                    Widerrufsbelehrung
                  </span>
                </div>
                <div className="terms__container-content">
                  <p>
                    Lorem Ipsum Is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum. is
                    simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has
                    survived not only five centuries, but also the leap into
                    remaining essentially unchanged. It was popularised in the
                    ... Lorem Ipsum Is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with{' '}
                  </p>
                </div>
                <div className="terms__container-checkbox">
                  <CheckBoxComponent
                    checked={true}
                    label="Ich habe die Widerrufsbelehrung gelesen und akzeptiere diese"
                  />
                </div>
              </div>
            </>
          )}
          {activeStep === 'Signature' && (
            <div className="signature--component">
              <SignatureComponent
                setFieldValue={setFieldValue}
                value={contactlessOrder?.contractView?.contactlessSignatures}
                element={{
                  name: 'signature',
                }}
              />
              {!contactlessOrder?.contractView?.contactlessSignatures && (
                <div className="submit__contactless_order-btn">
                  <ButtonComponent
                    buttonText="Save"
                    onClick={handleSubmitOrder}
                    disabled={loading}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  editedOrderFields: state.app.client.index.editedOrderFields,
  contactlessState: state.app.contaclessOrder.index,
  language: state.app.language.index.language,
})
const mapDispatchToProps = {
  getContactlessOrder: contactlessOrderActions.getContactlessOrder,
  submitOrder: contactlessOrderActions.submitContactlessOrder,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ContactlessSignature))
