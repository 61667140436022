/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation/";
import {actions as importsActions} from "@sagas/imports";
import DataGrid from "react-data-grid";
import {withRouter} from "react-router";
import Back from "@assets/img/back__icon.svg";
import Loader from "@common/Loader";
import {rowRenderer, rowHeight} from "./rowRenderer";

const InsideImport = (props) => {
	const {getSingleImport, singleImport, match, navigate, loading} = props;

	useEffect(() => {
		getSingleImport(match.params.id);
		// eslint-disable-next-line
	}, [match.params.id]);

	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	useEffect(() => {
		const arr = [];
		if (!!singleImport?.headers?.length && singleImport?.data) {
			setColumns(singleImport.headers);
			singleImport.data?.map((row) => {
				arr.push({
					...row.payload,
					...(row.errors.length > 0 && {errors: row.errors}),
				});
				setRows(arr);
				return arr;
			});
		}
	}, [singleImport]);

	return (
		<div className="imports__details__container">
			<div className="imports__details__top">
				<img onClick={() => navigate("/admin/orders/imported/")} src={Back} alt="" />
				<h1>{singleImport.fileName} </h1>
			</div>

			<div className="uploaded__csv__container">
				{!loading && (
					<DataGrid
						className="rdg-light"
						columns={columns}
						rows={rows}
						headerRowHeight={40}
						rowHeight={rowHeight}
						rowRenderer={rowRenderer}
					/>
				)}

				{loading && (
					<div className="loader__contianer">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	singleImport: state.app.imports.index.singleImport,
	loading: state.app.imports.index.loading,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	getSingleImport: importsActions.getImportById,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InsideImport));
