/* eslint-disable max-len */
import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as conditionActions} from "@sagas/teamConditions/index";
import {ENDPOINT} from "@src/utils/endpoint";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import {actions as salesOrgActions} from "@sagas/salesOrganizations/index";
const logger = new Logger("teams client");
const PREFIX = "@app/teamConditions/create";

export const CREATE_CONDITION = `${PREFIX}CREATE_CONDITION`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_SHOW_MODAL = `${PREFIX}SET_SHOW_MODAL`;

const _state = {
	loading: false,
	showModal: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_SHOW_MODAL:
				draft.showModal = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	createCondition: (payload) => createAction(CREATE_CONDITION, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowModal: (payload) => createAction(SET_SHOW_MODAL, {payload}),
};

export const sagas = {
	*createCondition({payload}) {
		yield put(actions.setLoading(true));
		const {values, type, tableId} = payload;
		try {
			const isInduction = type === "induction_bonus_trainers_conditions";
			const isRow = type.includes("row");
			const payloadObj = {
				...values,
				conditionType: "MONTHLY",
				salesOrganizations: values?.salesOrganizations?.map((slsOrg) => slsOrg.value),
				...(isRow && {tableId}),
			};
			if (isInduction) {
				payloadObj.team = values?.team?.id ? values?.team?.id : values?.team || null;
				payloadObj.trainer = values?.trainer?.id
					? values?.trainer?.id
					: values?.trainer || null;
				payloadObj.intern = values?.intern?.id
					? values?.intern?.id
					: values?.intern || null;
			}
			if (values?.id) {
				const response = yield axios.post(
					ENDPOINT.UPDATE_TABLE.replace(":type", type),
					payloadObj,
				);
				// TODO temporary solution to send get request , should be replaced by backend response (when backend is done);
				if (isInduction || isRow) {
					yield put(conditionActions.getConditions(type.replace("-row", "")));
				} else {
					const data = type === "manager_vo" ? response?.data?.data : values;
					yield put(conditionActions.updateTableOnState({data, type}));
					ToastSuccesComponent(i18n.t("RowEditSuccess"));
				}
			} else {
				const response = yield axios.post(
					ENDPOINT.CREATE_TABLE.replace(":type", type),
					payloadObj,
				);
				// TODO temporary solution to send get request , should be replaced by backend response (when backend is done);
				if (isInduction || isRow) {
					yield put(conditionActions.getConditions(type.replace("-row", "")));
				} else {
					const data = type === "career_conditions" ? response?.data?.data : values;
					yield put(
						conditionActions.addTableOnState({
							...data,
							id: response?.data?.data.id,
						}),
					);
				}
				ToastSuccesComponent(i18n.t("RowCreateSuccess"));
			}
			if (type === "manager_vo") {
				yield put(salesOrgActions.getAllSalesOrganizations("filtered"));
			}
			yield put(actions.setShowModal(false));
			yield put(conditionActions.clearTableValues());
			payload.closeModal();
		} catch (error) {
			if (
				error?.response?.data?.message?.[0]?.name === "INDUCTION_MEMBER_OR_TRAINER_EXISTS"
			) {
				ToastErrorComponent(i18n.t("TrainerOrInternAssigned"));
			}
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_CONDITION, sagas.createCondition);
};
