import axios from "@utils/axios";

export const handleDownload = (file) => {
	axios.get(file.fileUrl, {responseType: "blob"}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const a = document.createElement("a");
		a.href = url;
		a.download = file.fileName || response?.config?.url;
		a.click();
	});
};

export const handleDownloadAnyType = (file) => {
	axios
		.get(file.fileUrl, {responseType: "blob"})
		.then((response) => {
			const contentType = response.headers["content-type"];
			const blob = new Blob([response.data], {type: contentType});
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = file.fileName || file.fileUrl.split("/").pop();
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		})
		.catch((error) => {
			// eslint-disable-next-line no-console
			console.error("Error downloading the file:", error);
		});
};
