import {DeleteLeadStatusIcon, EditLeadStatusIcon} from "@src/assets/SvgComponents";
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import Button from "@src/common/components/button";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {actions as trainingActions} from "@sagas/trainings";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useUrlParams} from "@src/utils/useUrlParams";
import plusLogo from "../../../../../assets/img/plusIconBackground.svg";

const TemplateCategories = (props) => {
	const {categories, deleteCategoryById, loading, handleVisibility} = props;
	const {t} = useTranslation();

	const [searchQuery, setSearchQuery] = useState("");
	const {setModal} = useUrlParams();
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [categoryStatusToDelete, setCategoryStatusToDelete] = useState(null);

	const handleModal = (id) => {
		setShowDeleteModal(true);
		setCategoryStatusToDelete(id);
	};
	const handleConfirm = () => {
		deleteCategoryById({id: categoryStatusToDelete, type: "category"});
		setShowDeleteModal(false);
		handleVisibility(false);
	};

	const openModal = (type, id) => {
		setModal(type, id);
		handleVisibility(false);
	};

	useEffect(() => {
		if (searchQuery !== "") {
			setFilteredOptions(
				categories.filter((item) =>
					item?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
				),
			);
		} else {
			setFilteredOptions(categories);
		}
	}, [searchQuery, categories]);

	return (
		<>
			<DeleteModal
				open={showDeleteModal}
				setShowDeleteModal={setShowDeleteModal}
				confirm={handleConfirm}
				modalType="permanantDelete"
				disabled={loading}
			/>
			<div className="lead-statuses__popover">
				<div className="lead-statuses__popover-header">
					<BigSearchBar
						handleSearch={(e) => setSearchQuery(e.target.value)}
						value={searchQuery}
						widthSearchBar="318px"
					/>
					<Button
						text=""
						borderRadius="8px"
						border="1px solid #979797"
						color="#979797"
						width="30px"
						height="30px"
						icon={plusLogo}
						leftRounded={false}
						handleChange={() => openModal("category")}
						hideTooltip={true}
						smallButtonWithTooltip
					/>
				</div>
				<div className="leadStatus-container">
					{filteredOptions?.[0] ? (
						filteredOptions?.map((item, index) => (
							<div key={index} className="leadStatus-container-wrapper">
								<span className="leadStatus-container-wrapper-name">
									{item.name}
								</span>
								<div className="leadStatus-container-wrapper-icons">
									<span onClick={() => openModal("category", item?.value)}>
										<EditLeadStatusIcon />
									</span>
									<span onClick={() => handleModal(item?.value)}>
										<DeleteLeadStatusIcon />
									</span>
								</div>
							</div>
						))
					) : (
						<span className="leadStatus-container-noResults">{t("NoData")}</span>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	categories: state.app.trainings.index.categories,
	loading: state.app.salesTracker.leadStatus.loading,
});
const mapDispatchToProps = {
	deleteCategoryById: trainingActions.deleteCategoryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCategories);
