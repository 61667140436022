/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import moment from "moment";
import {actions as messageActions} from "@sagas/messages";
import {actions as createMessagesActions} from "@sagas/messages/create";
import {actions as deleteMessagesActions} from "@sagas/messages/delete";
import Loader from "@common/Loader";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {useUrlParams} from "@src/utils/useUrlParams";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";
import SingleMessage from "../SingleMessage";
import "moment/locale/de";
import "moment/locale/en-gb";
import EmptyMessageComponent from "./EmptyMessageComponent";

const MessagesRightSide = ({
	insideTopic,
	loggedUserId,
	addingNewMessage,
	setInsideTopicToEmpty,
	match,
	topicLoading,
	setMessageToReply,
	setAddNewMessage,
	page,
	setPage,
	totalPages,
	setLoaderPlacement,
	loaderPlacement,
	setFetchedPages,
	fetchedPages,
	isDeactivated,
	deletedState,
	deleteMessage,
}) => {
	const {t, i18n} = useTranslation();
	const {loading: deleteLoader} = deletedState;
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id} = getUrlParams("modal", "id");
	const [isFetching, setIsFetching] = useState(false);
	const location = useLocation();
	const isRootMessagesRoute = location.pathname === `/admin/messages/`;

	useEffect(() => {
		setLoaderPlacement("");
		setPage(1);
		setFetchedPages([1]);
		return () => {
			setInsideTopicToEmpty();
		};
	}, [match.params.id]);

	const handleScroll = _.debounce((event) => {
		if (isFetching) {
			return;
		}
		setIsFetching(true);
		const container = event.target;
		const scrollPosition = Math.abs(container.scrollTop);
		const maxScrollPosition = container.scrollHeight - container.clientHeight;
		if (scrollPosition <= 1 && page > 1) {
			setPage(page - 1);
			setFetchedPages([...fetchedPages, page - 1]);
			setLoaderPlacement("Down");
		} else if (
			scrollPosition >= maxScrollPosition - 1 &&
			(scrollPosition !== 0 || maxScrollPosition !== 0) &&
			page < totalPages
		) {
			setPage(page + 1);
			setFetchedPages([...fetchedPages, page + 1]);
			setLoaderPlacement("Up");
		}
		setIsFetching(false);
	}, 500);

	const uniqueMessages = _.uniqBy(insideTopic.messages, "id");

	const groupedMessages = {};

	uniqueMessages.forEach((item) => {
		const createdAt = moment(item?.createdAtTimestamp);
		const today = moment().startOf("day");
		const yesterday = moment().subtract(1, "day").startOf("day");
		const date = createdAt.startOf("day");
		let group;
		if (date.isSame(today, "d")) {
			group = t("Today");
		} else if (date.isSame(yesterday, "d")) {
			group = t("Yesterday");
		} else {
			const formatString =
				date.year() !== moment().year() ? "dddd, MMMM D ,YYYY" : "dddd, MMMM D";
			moment.locale(i18n.language);
			const formattedDate = date.format(formatString);
			group = formattedDate;
		}
		if (!groupedMessages[group]) {
			groupedMessages[group] = [];
		}
		groupedMessages[group].push(item);
	});

	const handleCloseConfirmModal = () => setModal(false);

	return (
		<div
			className={`inside__topic__container ${
				!Object.keys(groupedMessages).length > 0 ? "loading" : ""
			}`}
			onScroll={handleScroll}
		>
			{isRootMessagesRoute > 0 && (
				<div className="inside__topic__container-emptyComponent">
					<EmptyMessageComponent />
				</div>
			)}

			{topicLoading && loaderPlacement === "Down" && (
				<div className="inside__topic__container__loader-down">
					<Loader />
				</div>
			)}
			{/* TODO here should be added a page to indicate that there're no messages on this topic */}
			{/* {!Object.keys(groupedMessages).length > 0 && !isRootMessagesRoute && (
				<div className="inside__topic__container__loader-down">
					<Loader />
				</div>
			)} */}
			<DeleteModal
				open={modal === "delete"}
				handleClose={handleCloseConfirmModal}
				setShowDeleteModal={handleCloseConfirmModal}
				calculationModal
				loading={deleteLoader}
				newTitle={t("areYouSureDeleteMsg")}
				confirm={() => deleteMessage({setMessageToReply, id, setModal})}
			/>
			{!addingNewMessage &&
				Object.entries(groupedMessages).map(([group, messages], groupIndex) => (
					<>
						{messages.map((item, index) =>
							item?.type ? (
								<div className="new_activities-indicator" key={item.id}>
									<span className="new_activities-indicator__text">
										{i18n.language === "en"
											? item?.text?.split("|")[0]
											: item?.text?.split("|")[1]}
									</span>
								</div>
							) : (
								<SingleMessage
									// name={item?.item}
									key={index}
									time={moment(item?.createdAtTimestamp).format("hh:mm")}
									message={item?.text}
									image={item?.image}
									name={item?.fullName}
									myMessage={loggedUserId === item?.userId}
									id={item?.id}
									lastSeenMessage={insideTopic?.lastSeenMessageId}
									lastDeliveredMessage={insideTopic.lastDeliveredMessageId}
									loggedUserId={loggedUserId}
									user={item?.userId}
									files={item?.files}
									messageToReply={item?.replyId}
									setMessageToReply={setMessageToReply}
									replyMessage={item?.replyMessage}
									mentionIds={item?.mentionIds}
									setAddNewMessage={setAddNewMessage}
									topicId={parseInt(match.params.id)}
									hasWriteAccess={insideTopic?.writeAccess}
									item={item}
									isDeactivated={isDeactivated}
									setDeleteModal={setModal}
								/>
							),
						)}
						<div className="new_messages-indicator" key={groupIndex}>
							<span className="new_messages-indicator__breakline"></span>
							<span className="new_messages-indicator__text"> {group}</span>
						</div>
					</>
				))}
			{topicLoading && loaderPlacement === "Up" && (
				<div className="inside__topic__container__loader-up">
					<Loader />
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state) => ({
	insideTopic: state.app.messages.index.insideTopic,
	topicLoading: state.app.messages.index.topicLoading,
	loggedUserId: state.app.users.index.loggedUserId,
	addingNewMessage: state.app.messages.create.addingNewMessage,
	page: state.app.messages.index.page,
	totalPages: state.app.messages.index.totalPages,
	loaderPlacement: state.app.messages.index.loaderPlacement,
	fetchedPages: state.app.messages.index.fetchedPages,
	deletedState: state.app.messages.delete,
});

const mapDispatchToProps = {
	setInsideTopicToEmpty: messageActions.setInsideTopicToEmpty,
	setAddNewMessage: createMessagesActions.setAddNewMessage,
	setPage: messageActions.setPage,
	setLoaderPlacement: messageActions.setLoaderPlacement,
	setFetchedPages: messageActions.setFetchedPages,
	deleteMessage: deleteMessagesActions.deleteMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessagesRightSide));
