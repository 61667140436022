import Form from "@src/common/Form";
import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {withRouter} from "react-router";
import {getLabel} from "@src/components/generic-pdf/createOrder/multiplecontainers/helper";
import useFieldGenerator from "@src/utils/useFieldGenerator";

const GenerateFieldsModal = ({
	open,
	item,
	handleClose,
	clientId,
	clientIdString,
	fetchGenericOrderByID,
}) => {
	const {loading, generateProductAndTariffFields} = useFieldGenerator();
	const {sections} = useSelector((state) => state.app.client.index);
	useEffect(() => {
		if (open) {
			fetchGenericOrderByID(clientId);
		}
	}, [open, clientId]);
	const formData = {
		inputs: [
			{
				inputType: "dropdown",
				field: "sectionId",
				options: sections?.map((item) => ({
					...item,
					value: item.id,
					label: getLabel(item, "name"),
				})),
				title: "Choose section",
				required: true,
			},
		],
		title: "Assign fields to a section",
		submitFn: (values) => {
			generateProductAndTariffFields({
				sectionId: values?.sectionId,
				item,
				clientIdString,
				clientId,
				handleClose,
			});
		},
	};
	return <Form formData={formData} open={open} handleClose={handleClose} loading={loading} />;
};

const mapDispatchToProps = {
	fetchGenericOrderByID: clientActions.fetchGenericOrderByID,
};
export default connect(null, mapDispatchToProps)(withRouter(GenerateFieldsModal));
