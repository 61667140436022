import React, {useEffect} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import EmailSettingsComponent from "./EmailSettingsComponent";
import ClientFieldsSettings from "./ClientFieldsSettings";
import GeneratedPdfsSettings from "./GeneratedPdfsSettings";
import SFTPConnection from "./SFTPConnection";
import ContractPdfSettings from "./ContractPdfSettings";
import DefaultOrderStatus from "./DefaultOrderStatus";

const OrderSettings = (props) => {
	const {match, fields, getClientFields} = props;
	const {clientId} = match.params;
	useEffect(() => {
		getClientFields(clientId);
	}, [clientId]);
	return (
		<div className="activeTab-container-wrapper">
			<div className="accordions-wrapper">
				<SFTPConnection />
			</div>
			<div className="accordions-wrapper">
				<EmailSettingsComponent />
			</div>
			<div className="accordions-wrapper">
				<ClientFieldsSettings
					clientFields={fields?.filter((field) => !field?.dropDownParentId)}
				/>
			</div>
			<div className="accordions-wrapper">
				<GeneratedPdfsSettings />
			</div>
			<div className="accordions-wrapper">
				<ContractPdfSettings />
			</div>
			<div className="accordions-wrapper">
				<DefaultOrderStatus />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	fields: state.app.client.index.editedOrderFields,
});
const mapDispatchToProps = {
	getClientFields: clientActions.fetchGenericOrderByID,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderSettings));
