import {DATE_FORMAT} from "@src/common/constants";
import HtmlTooltip from "@src/common/htmlTooltip";
import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";

function ColorPercentage({row, type = "multiple", color = "rgba(208,10,18,1)"}) {
	const {t} = useTranslation();
	const total = type === "multiple" && row?.reduce((acc, item) => acc + item.count, 0);
	const completedPercentage = type === "single" && (row?.completed / row?.total) * 100;
	return (
		<>
			{type === "multiple" ? (
				<div className="color-percentage-wrapper">
					{row &&
						row.map((item, index) => {
							const percentage = (item.count / total) * 100;
							return (
								<HtmlTooltip
									maxWidth={500}
									title={
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: 5,
											}}
										>
											<span
												style={{
													width: 10,
													height: 10,
													background: item?.color,
												}}
											/>
											<span>{t(item.name)}</span>
											<span>
												{item.count} / {total}
											</span>
										</div>
									}
								>
									<div
										key={index}
										className="color-percentage-wrapper_item"
										style={{
											width: `${percentage}%`,
											backgroundColor: item.color,
										}}
									/>
								</HtmlTooltip>
							);
						})}
				</div>
			) : (
				<HtmlTooltip maxWidth={500} title={<span>{completedPercentage.toFixed(0)} %</span>}>
					<div className="color-percentage-wrapper-single">
						<div
							className="color-percentage-wrapper-single_item"
							style={{
								background: `linear-gradient(90deg, ${color} 0%,
                                 ${color} ${completedPercentage}%, rgba(228,228,228,1) 
                                 ${completedPercentage}%, rgba(228,228,228,1) 100%)`,
							}}
						/>
						<span className="title">{moment(row?.date).format(DATE_FORMAT)}</span>
					</div>
				</HtmlTooltip>
			)}
		</>
	);
}
export default ColorPercentage;
