import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
const useDialogStyles = makeStyles({
	paper: {
		minWidth: "350px",
	},
});

const MontSerratFont = {
	fontFamily: "Montserrat",
};

const LeavePageAlert = (props) => {
	const {open, handleClose, handleAgree} = props;

	const dialogClasses = useDialogStyles();

	const {t} = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			classes={dialogClasses}
		>
			<DialogTitle id="alert-dialog-title" style={MontSerratFont}>
				{t("AreYouSureLeave")}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" style={MontSerratFont}>
					{t("AllDataLost")}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} style={MontSerratFont}>
					{t("Disagree")}
				</Button>
				<Button onClick={handleAgree} autoFocus style={MontSerratFont}>
					{t("Agree")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LeavePageAlert;
