/* eslint-disable no-nested-ternary */
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CloseUploadModalIcon, FiltersIcon, StrictSearchIcon} from "@assets/SvgComponents";
import {Popover} from "antd";
import SearchIcon from "../../assets/img/search_black_24dp.svg";
import Filters from "../Filters";

function BigSearchBar(props) {
	const {
		value,
		handleSearch,
		placeholder = "TypeToSearch",
		customClass,
		withFilters = false,
		hasCloseIcon = false,
		handleClose,
		style,
		replaceSearchIcon = false,
		widthSearchBar = "",
		disabled = false,
		filterItems = [],
		filterValues,
		setFilterValues,
		clearFilterValues,
		hasStrictSearch = false,
		onStrictIconClick,
		headerSearch = false,
		displaySearch = true,
	} = props;

	const {t} = useTranslation();
	const [showFilters, setShowFilters] = useState(false);
	const [strictSearchActive, setStrictSearchActive] = useState(false);
	const [showStrictIcon, setShowStrictIcon] = useState(false);
	const [inputVisible, setInputVisible] = useState(false);

	const handleVisibility = (visible) => setShowFilters(visible);
	const handleOpenSearch = (visible) => setInputVisible(visible);

	return (
		<div
			style={{
				width: withFilters ? "" : widthSearchBar,
				...style,
			}}
			className={`big__search__bar__container ${customClass || ""}`}
		>
			{withFilters && (
				<Filters
					filterItems={filterItems}
					handleVisibility={handleVisibility}
					open={showFilters}
					setShowFilters={setShowFilters}
					filterValues={filterValues}
					clearFilterValues={clearFilterValues}
					setFilterValues={setFilterValues}
				>
					<div className="searchbar-filters">
						<FiltersIcon />
					</div>
				</Filters>
			)}
			{displaySearch && (
				<>
					{!headerSearch ? (
						<div
							className="search__input"
							style={{width: withFilters ? "80%" : "100%"}}
						>
							<input
								value={value || ""}
								onChange={handleSearch}
								type="text"
								placeholder={t(placeholder)}
								disabled={disabled}
								onFocus={() => {
									if (hasStrictSearch) {
										setShowStrictIcon(true);
									}
								}}
							></input>

							{!replaceSearchIcon ? (
								<img src={SearchIcon} alt="" />
							) : value !== "" ? (
								<span className="clear_input_icon" onClick={handleClose}>
									<CloseUploadModalIcon fill="#979797" width="11" height="12" />
								</span>
							) : (
								<img src={SearchIcon} alt="" />
							)}
							{hasCloseIcon && (
								<span onClick={handleClose}>
									<CloseUploadModalIcon fill="#979797" width="11" height="12" />
								</span>
							)}
							<span
								className={`strict__icon ${showStrictIcon && "visible"} ${
									strictSearchActive && "active"
								}`}
								onClick={() => {
									setStrictSearchActive((prevState) => {
										onStrictIconClick(!prevState);
										return !prevState;
									});
								}}
							>
								<StrictSearchIcon />
							</span>
						</div>
					) : (
						<Popover
							trigger="click"
							open={inputVisible}
							onOpenChange={handleOpenSearch}
							placement="bottomRight"
							overlayClassName="popover__search__input"
							content={
								<div className="big__search__bar__container">
									<div className="search__input popover-search__input">
										<span
											className={`strict__icon ${
												showStrictIcon && "visible"
											} ${strictSearchActive && "active"}`}
											onClick={() => {
												setStrictSearchActive((prevState) => {
													onStrictIconClick(!prevState);
													return !prevState;
												});
											}}
										>
											<StrictSearchIcon />
										</span>
										<input
											value={value || ""}
											onChange={handleSearch}
											type="text"
											placeholder={t(placeholder)}
											disabled={disabled}
											onFocus={() => {
												if (hasStrictSearch) {
													setShowStrictIcon(true);
												}
											}}
										></input>

										{!replaceSearchIcon ? (
											<img src={SearchIcon} alt="" />
										) : value !== "" ? (
											<span
												className="clear_input_icon"
												onClick={handleClose}
											>
												<CloseUploadModalIcon
													fill="#979797"
													width="11"
													height="12"
												/>
											</span>
										) : (
											<img src={SearchIcon} alt="" />
										)}
										{hasCloseIcon && (
											<span onClick={handleClose}>
												<CloseUploadModalIcon
													fill="#979797"
													width="11"
													height="12"
												/>
											</span>
										)}
									</div>
								</div>
							}
						>
							<div className="show__search__input">
								<img
									src={SearchIcon}
									alt=""
									onClick={() => setInputVisible(!inputVisible)}
								/>
							</div>
						</Popover>
					)}
				</>
			)}
		</div>
	);
}

export default BigSearchBar;
