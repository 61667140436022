/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
import axios from "axios";

const {REACT_APP_GET_AG_URL, REACT_APP_ONERI_REQUESTS, REACT_APP_GET_AG_AUTHORIZATION} =
	process.env;

const getAgAxios = axios.create({
	baseURL: REACT_APP_ONERI_REQUESTS, // All requests should be sent to ONERI_REQUESTS
});

export const axiosRequests = {
	getAgRequest: async ({url, data, json, method, baseURL}) =>
		getAgAxios.post(
			REACT_APP_ONERI_REQUESTS,
			(data = {
				url: `${baseURL || REACT_APP_GET_AG_URL}${url}`,
				method: method || "post",
				token: `Basic ${REACT_APP_GET_AG_AUTHORIZATION}`,
				json,
				data,
			}),
		),
	getAgAxiosWithoutToken: async ({url, method}) =>
		getAgAxios.post(REACT_APP_ONERI_REQUESTS, {
			url,
			method,
		}),
	axios: getAgAxios,
};
