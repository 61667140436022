import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as productActions, actions as userActions} from "@sagas/products";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Products delete");

const PREFIX = "@app/products/delete";
export const DELETE_PRODUCT = `${PREFIX}DELETE_PRODUCT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_DELETE_MODAL = `${PREFIX}SHOW_DELETE_MODAL`;

const _state = {
	loading: false,
	deleteShowModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_DELETE_MODAL:
				draft.deleteShowModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteProduct: (payload) => createAction(DELETE_PRODUCT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowDeleteModal: (payload) => createAction(SHOW_DELETE_MODAL, {payload}),
};

export const sagas = {
	*deleteProduct(id) {
		yield put(actions.setLoading(true));

		try {
			yield axios.put(`/product/delete/${id.payload}`);
			yield put(productActions.deleteProductOnState(id.payload));
			ToastSuccesComponent(i18n.t("ProductDeleteSuccess"));
			yield put(actions.setShowDeleteModal(false));
			yield put(userActions.totalSizeDecrease());
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_PRODUCT, sagas.deleteProduct);
};
