/* eslint-disable no-restricted-syntax */
export const handleInputShowValue = (message, setObject) => {
	// eslint-disable-next-line no-useless-escape
	const regex = /{{id: (\d+), name: ([\w\s.\-äÄöÖüÜß]+), topicId: (\w*)}}/g;
	const matches = message?.matchAll(regex);
	const objects = [];
	for (const match of matches) {
		const id = parseInt(match[1]);
		const name = match[2];
		const topicId = match[3] !== "" && match[3] !== "null" ? match[3] : null;

		objects.push({id, name, topicId});
	}
	if (setObject) {
		setObject(objects);
		return objects;
	}

	let replacedMessage = message;
	for (const object of objects) {
		replacedMessage = replacedMessage.replace(
			`{{id: ${object.id}, name: ${object.name}, topicId: ${object.topicId}}}`,
			`@${object.name} `,
		);
	}
	return replacedMessage;
};
export const handleOutputShowValue = (message, objects) => {
	let replacedMessage = message;
	for (const object of objects) {
		replacedMessage = replacedMessage.replace(
			`@${object.name}`,
			`{{id: ${object.id}, name: ${object.name}, topicId: ${object.topicId}}}`,
		);
	}
	return replacedMessage;
};
