import React from "react";
import HtmlTooltip from "@common/htmlTooltip";
import {ReactComponent as ErrorImg} from "@assets/img/error_black_24dp.svg";
import {ReactComponent as SuccessImg} from "@assets/img/check_circle_black_24dp.svg";
import {Row} from "react-data-grid";
import i18n from "../../../../i18n";

export const rowHeight = 50;
export const rowRenderer = (row) => (
	<div key={row.rowIdx}>
		{row?.row?.errors?.length ? (
			<div className="rowErrors" style={{top: rowHeight * row.rowIdx + 40}}>
				<HtmlTooltip
					placement="top"
					title={
						<div className="errors-container">
							{row.row.errors.map((e, i) => (
								<>
									<span key={i}>{e.message}</span>
									<br />
								</>
							))}
						</div>
					}
				>
					<ErrorImg />
				</HtmlTooltip>
			</div>
		) : (
			<div className="succesRows" style={{top: rowHeight * row.rowIdx + 40}}>
				<HtmlTooltip
					placement="top"
					title={<div className="success-container">{i18n.t("Valid")}</div>}
				>
					<SuccessImg />
				</HtmlTooltip>
			</div>
		)}

		<Row {...row} key={row.rowIdx} className={row?.row?.errors?.length ? "error" : ""} />
	</div>
);

export default rowRenderer;
