import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import DataGrid, {TextEditor} from "react-data-grid";
import Back from "@assets/img/back__icon.svg";
import {actions as navigation} from "@sagas/navigation";
import {actions as createImports} from "@sagas/imports/create";
import {actions as orderImportAcions} from "@sagas/orders/orderImports";
import Loader from "@common/Loader";
import ButtonComponent from "@common/button";
import Modal from "@common/modal";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import ColumnHeader from "@components/dashboard/orders/imports/ColumnHeader";
import i18n from "../../../../i18n";
import ErrorsModal from "../../orders/imports/ErrorsModal";
import {generateColumns} from "./helper";

const PreviewCsv = (props) => {
	const {
		navigate,
		setShowModal,
		clearErrorsData,
		setRowsElem,
		updateOptionsOnState,
		setCsvData,
		draftImport,
		statuses,
		createImportState,
	} = props;
	const {
		csvData,
		loading,
		showModal,
		errorsData: errors,
		importType,
		statusRequiredFields,
		matchingField,
		query,
		fileName,
		importChangeStatus,
	} = createImportState;
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const getType = () => {
		switch (importType) {
			case "fromStatus":
				const cols = generateColumns(
					[{name: "same"}, ...csvData?.columns],
					[],
					{
						show: false,
						item: null,
					},
					matchingField,
					statuses,
				);
				return {
					allCells: statusRequiredFields,
					editableColumn: "clientOrderId",
					submitFunction: () =>
						draftImport({
							nav: "change-order-status",
							jsonData: csvData,
							convertedColumns: cols,
							clientId: query?.clientId,
							matchingField,
							fileName,
							importChangeStatus,
						}),
				};
			// Add cases here for each entity, allCells is the array of required link fields,
			// navigate is the url for entity tables before execute.
			default:
				return null;
		}
	};
	useEffect(() => {
		const columnArr = [];
		const arr = [];
		if (!!csvData?.columns?.length && csvData?.data) {
			setColumns(csvData.columns);
			csvData.columns.forEach((item) => {
				columnArr.push({...columnObj, ...item});
			});
			setColumns(columnArr);
			csvData.data.forEach((row) => {
				arr.push(row);
				setRows(arr);
			});
		}
		return () => clearErrorsData();
	}, []);

	useEffect(() => {
		updateOptionsOnState(
			getType()?.allCells.filter((cel) => !selectedOptions?.includes(cel.value)),
		);
	}, [selectedOptions, getType()?.allCells]);

	const columnReplica = csvData.columns;

	const columnObj = {
		editor: TextEditor,
		headerRenderer: ({column}) => (
			<>
				<ColumnHeader
					column={column}
					setSelectedOptions={setSelectedOptions}
					csvData={csvData && csvData}
					setCsvData={setCsvData}
					filterColumns={true}
				/>
			</>
		),
		editable: false,
		resizable: true,
		minWidth: 220,
	};
	useEffect(() => {
		if (selectedOptions?.[0]) {
			const newArr = columns.map((item) => {
				const matchingItem = columnReplica.find((elem) => elem?.key === item?.key);
				if (matchingItem && matchingItem.name === getType()?.editableColumn) {
					return {...item, editable: true};
				}
				return item;
			});
			setColumns(newArr);
		}
	}, [selectedOptions, columns?.length]);

	const handleUpdateRows = (elem) => {
		setRows(elem);
	};
	useEffect(() => {
		setRowsElem(rows);
		// eslint-disable-next-line
  }, [rows])
	return (
		<>
			<Modal
				className="add_edit_user"
				open={showModal}
				handleClose={() => setShowModal(false)}
			>
				<ErrorsModal errors={errors} setShowModal={setShowModal} />
			</Modal>
			<div className="import__order__container">
				<img
					className="back__icon__import"
					onClick={() => {
						navigate(`/admin/imports`);
					}}
					src={Back}
					alt=""
				/>
				<ButtonComponent
					classNames={`confirm__uload__csv ${rows.length ? "uploaded" : ""}`}
					onClick={() => {
						const type = getType();
						if (
							type &&
							type.allCells.every((cel) => selectedOptions?.includes(cel.value))
						) {
							type.submitFunction();
						} else {
							ToastInfoComponent(i18n.t("SelectAllOptionsInfo"));
						}
					}}
					buttonText={i18n.t("ConfirmUpload")}
					disabled={loading}
				/>
				{rows.length > 0 && csvData?.columns && (
					<div className="uploaded__csv__container">
						{!!errors.length && (
							<ButtonComponent
								classNames="show__errors"
								onClick={() => setShowModal(true)}
								buttonText={i18n.t("Show")}
								disabled={loading}
							/>
						)}
						<DataGrid
							className={`rdg-light ${loading ? "blur" : ""}`}
							columns={columns}
							rows={rows}
							enableCellSelect={true}
							onRowsChange={(e) => handleUpdateRows(e)}
							rowHeight={32}
							headerRowHeight={100}
						/>
						{loading && (
							<div className="loader__contianer">
								<Loader />
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	createImportState: state.app.imports.create,
	statuses: state.app.orders.statuses.statuses,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	setShowModal: createImports.setShowModal,
	clearErrorsData: createImports.clearErrorsData,
	setRowsElem: createImports.setRowsElem,
	updateOptionsOnState: orderImportAcions.setFilteredOptions,
	setCsvData: createImports.setCsvData,
	draftImport: createImports.draftImport,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewCsv));
