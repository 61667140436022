/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as navigateActions} from "@sagas/navigation";
import {actions as trainingActions} from "@sagas/trainings";
import {actions as createTrainingActions} from "@sagas/trainings/create";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import {NoDataIcon} from "@src/assets/SvgComponents";
import TrainingCard from "./Card";
import TopContent from "./TopContent";
import CreateCategoryModal from "./category/create";
import GridSkeleton from "../../Analytics/components/GridSkeleton";

const ROLES = ["TENANT", "BACK-OFFICE", "MANAGEMENTINFO", "CLIENT"];

const TrainingsComp = (props) => {
	const {
		navigate,
		templates,
		clearState,
		clearPages,
		filters,
		getTrainingsByStatus,
		getCategories,
		loading,
		loggedUser,
		setTrainingsImg,
	} = props;
	const {t} = useTranslation();
	const [dropDownValue, setdropDownValue] = useState({});
	const {getUrlParams, setUrlParams, setQuery, setModal} = useUrlParams();
	const {tab, query} = getUrlParams("tab", "query");
	const initializeParams = () => {
		const tabToSet = !ROLES?.includes(loggedUser?.role?.name)
			? t("published")
			: tab || t("draft");
		setUrlParams(tabToSet, "tab", {paramName: "query", paramValue: filters});
	};
	useEffect(() => {
		getCategories();
		if (!filters?.category?.value) {
			initializeParams();
		}
		return () => setTrainingsImg(null);
		// eslint-disable-next-line
	}, []);
	const setPublishedToggle = (item) => {
		if (item !== tab) {
			setUrlParams(item, "tab");
			setQuery(filters);
		}
	};

	useEffect(() => {
		getTrainingsByStatus({status: tab === t("draft") ? "draft" : "published", query});
		return () => {
			clearState();
			clearPages();
		};
		// eslint-disable-next-line
	}, [query?.fromDate, query?.toDate, query?.search, query?.category, query?.subCategories?.length, dropDownValue, tab]);

	const handleCard = (id) => {
		navigate(`/admin/trainings/${id}`);
	};

	const toggleTemplates = (val) => {
		setPublishedToggle(val);
	};

	const cleanQuery = () => {
		setQuery({
			fromDate: null,
			toDate: null,
			search: null,
			category: null,
			subCategories: [],
		});
	};
	return (
		<div className="trainings">
			<div className="dashboard__container trainings__dashboard">
				<TopContent
					toggleTemplates={toggleTemplates}
					setPublishedToggle={setPublishedToggle}
					publishedToggle={tab}
					length={templates.length}
					navigate={navigate}
					dropDownValue={dropDownValue}
					setdropDownValue={setdropDownValue}
					loggedUser={loggedUser}
					filters={query}
					setFilters={setQuery}
					clearQuery={cleanQuery}
				/>
				<CreateCategoryModal setModal={setModal} />
				<div>
					{loading ? (
						<GridSkeleton />
					) : templates?.[0] ? (
						<div className="trainings__cards">
							{templates?.map((item, index) => (
								<TrainingCard
									bgImg={item?.cover}
									navigate={navigate}
									onClick={() => handleCard(item?.id)}
									category={item?.category}
									title={item?.title}
									id={item?.id}
									key={index}
									loggedUser={loggedUser}
									isDraft={tab}
								/>
							))}
						</div>
					) : (
						<div className="empty">
							<div>
								<NoDataIcon width="64" height="79" fill="#979797" />
							</div>
							<span className="empty-text">{t("NoData")}!</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	login: state.app.auth.login,
	templates: state.app.trainings.index.templates,
	categories: state.app.trainings.index.categories,
	filters: state.app.trainings.index.filters,
	loading: state.app.trainings.index.loading,
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	navigate: navigateActions.navigate,
	getTrainings: trainingActions.getTrainings,
	getTrainingsByCat: trainingActions.getTrainingsByCat,
	getCategories: trainingActions.getCategories,
	clearPages: trainingActions.clearPages,
	clearState: createTrainingActions.clearState,
	setTrainingsImg: createTrainingActions.setTrainingsImg,
	getTrainingsByStatus: trainingActions.getTrainingsByStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrainingsComp));
