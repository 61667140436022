/* eslint-disable import/no-cycle */
import MessageEntity from "./entities/message";
import StatusHistoryEntity from "./entities/statusHistory";
import OrderStatusImportEntity from "./entities/orderStatusImport";
import ExportOrdersEntity from "./entities/exportOrders";

const handlers = {
	Messages: MessageEntity,
	StatusHistory: StatusHistoryEntity,
	OrderStatusImport: OrderStatusImportEntity,
	ExportOrders: ExportOrdersEntity,
};

export default handlers;
