import * as yup from "yup";
import i18n from "@src/i18n";
import moment from "moment";

export const validationSchema = yup.object().shape({
	date: yup.mixed().required(i18n.t("DateIsReq")).nullable(),
});

export const baseFilterItemProps = {
	inputType: "antd-dropdown",
	name: "label",
	value: "value",
	isMultiple: false,
	show: true,
	key: "label",
	optionVal: "value",
	optionLabel: "label",
};
export const initialQuery = {
	search: "",
	clientPayoutModel: null,
	year: moment().year(),
};
