import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

export const RadioGroupInput = ({
	children,
	value,
	title,
	name,
	onChange,
	direction,
	disabled,
	defaultValue,
	fontSize,
}) => (
	<FormControl component="fieldset" disabled={disabled}>
		{title && (
			<FormLabel
				component="legend"
				style={{
					color: "#252525",
					fontFamily: "Montserrat",
					fontWeight: "600",
					fontSize,
				}}
			>
				{title}
			</FormLabel>
		)}
		<RadioGroup
			aria-label={name}
			name={name}
			onChange={onChange}
			style={{display: "flex", flexDirection: direction}}
			value={value}
			defaultValue={defaultValue}
		>
			{children}
		</RadioGroup>
	</FormControl>
);

export default RadioGroupInput;
