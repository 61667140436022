import React from "react";
import {useTranslation} from "react-i18next";

const FooterTraining = (props) => {
	const {changePage, pages, activePage, navigate, clearFilterValues} = props;
	const {t} = useTranslation();

	const handleChange = (type) => {
		changePage({type: type.type, page: pages?.length});

		if (activePage === pages?.length && type?.type === "next") {
			clearFilterValues();
			navigate("/admin/trainings");
		}
	};

	return (
		<div className="footerTraining">
			<div className="footerTraining__left">
				{activePage !== 1 && (
					<div
						className="footerTraining__btn footerTraining__back"
						onClick={() => handleChange({type: "prev", page: pages?.length})}
					>
						{t("Back")}
					</div>
				)}
				<div className="footerTraining__title">{pages[activePage - 2]?.title}</div>
			</div>
			<div className="footerTraining__right">
				<div
					className="footerTraining__btn footerTraining__next"
					onClick={() => handleChange({type: "next", page: pages?.length})}
				>
					{activePage === pages?.length ? t("CompleteTraining") : t("Next")}
				</div>
				<div className="footerTraining__title">{pages[activePage]?.title}</div>
			</div>
		</div>
	);
};

export default FooterTraining;
