import React, {useRef, useState} from "react";

const PinInput = ({length, onComplete, disabled}) => {
	const [pin, setPin] = useState(Array(length).fill(""));
	const [maskedPin, setMaskedPin] = useState(Array(length).fill(""));
	const inputRefs = Array(length)
		.fill(0)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		.map(() => useRef(null));

	const handleChange = (event, index) => {
		const {value} = event.target;
		if (/^[0-9]*$/.test(value) && value.length <= 1) {
			const newPin = [...pin];
			newPin[index] = value;
			setPin(newPin);
			setMaskedPin(newPin.map((digit) => (digit ? "*" : "")));

			if (value !== "" && index < length - 1 && inputRefs[index + 1].current) {
				inputRefs[index + 1].current.focus();
			}

			if (newPin.every((digit) => digit !== "")) {
				const enteredPin = +newPin.join("");
				if (newPin.every((digit) => digit !== "")) {
					onComplete(newPin.join(""));
					setPin(Array(length).fill(""));
					setMaskedPin(Array(length).fill(""));

					if (inputRefs[0].current) {
						inputRefs[0].current.focus();
					}
				}
			}
		}
	};

	const handleKeyDown = (event, index) => {
		if (event.key === "Backspace" && index > 0 && pin[index] === "") {
			// Move to the previous input field on backspace
			inputRefs[index - 1].current.focus();
		}
	};

	const renderPinInputs = () =>
		Array.from({length}).map((_, index) => (
			<input
				key={index}
				type="text"
				disabled={disabled}
				value={maskedPin[index]}
				maxLength={1}
				onChange={(value) => handleChange(value, index)}
				onKeyDown={(event) => handleKeyDown(event, index)}
				ref={inputRefs[index]}
				className="password-asterisks"
			/>
		));

	return <div className="pin-input">{renderPinInputs()}</div>;
};

export default PinInput;
