import React from "react";
import BackButton from "../../../../assets/img/arrowLeftIcon.svg";
import xModal from "../../../../assets/img/xModal.svg";

const AnalyticHeaderComponent = ({title, handlePrevious, current, handleClose}) => (
	<div className="analyticHeader__header">
		<img src={BackButton} alt="" onClick={handlePrevious} style={{cursor: "pointer"}} />
		<span>{title}</span>
		<img src={xModal} alt="" onClick={handleClose} style={{cursor: "pointer"}} />
	</div>
);

export default AnalyticHeaderComponent;
