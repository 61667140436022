import * as yup from "yup";
import i18n from "@src/i18n";

export const validationSchema = yup.object().shape({
	name: yup.string().required(i18n.t("FieldNameReq")),
	labelEn: yup.string().required(i18n.t("FieldNameEnReq")),
	labelDe: yup.string().required(i18n.t("FieldNameDeReq")),
	inputType: yup.string().when("dropDownParentId", {
		is: (dropDownParentId) => !dropDownParentId,
		then: yup.string().required(i18n.t("InputTypeReq")).nullable(),
		otherwise: yup.string().optional(),
	}),
});

export const editCustomFieldsSchema = yup.object().shape({
	labelEn: yup.string().nullable().required(i18n.t("FieldNameEnReq")),
	labelDe: yup.string().nullable().required(i18n.t("FieldNameDeReq")),
	autoFillInput: yup.string().nullable().optional(),
	isRequired: yup.boolean().nullable().optional(),
});

export const inputTypes = [
	{value: "CHECKBOX", label: i18n.t("checkbox")},
	{value: "DROPDOWN", label: i18n.t("dropdown")},
	{value: "TEXT", label: i18n.t("Text")},
	{value: "EMAIL", label: "Email"},
	{value: "IMAGE", label: i18n.t("signature")},
	{value: "NUMBER", label: i18n.t("number")},
	{value: "DATE", label: i18n.t("date")},
	{value: "UPLOAD", label: i18n.t("upload")},
	{value: "TEXTAREA", label: i18n.t("textarea")},
	{value: "TITLE", label: i18n.t("title")},
	{value: "PRODUCT", label: i18n.t("product")},
	{value: "TARIFF", label: i18n.t("tariff")},
	{value: "BIRTHDAY", label: i18n.t("birthdate")},
	{value: "BIC", label: i18n.t("bic")},
	{value: "BANK_NAME", label: i18n.t("bankname")},
];

export const autoFillInputs = [
	{value: "street", label: i18n.t("street")},
	{value: "zipCode", label: i18n.t("postCode")},
	{value: "houseNumber", label: i18n.t("houseNr")},
	{value: "partLocation", label: i18n.t("subLocation")},
	{value: "location", label: i18n.t("location")},
];
