import React from "react";
import UploadCards from "./UploadCards";

const MappedFiles = ({files, copied, setCopied}) => (
	<>
		{files.length > 0 && (
			<div className="mappedFiles__container">
				{files.map((file, index) => (
					<UploadCards
						key={index}
						fileTitle={file?.fileName}
						fileUrl={file?.fileUrl}
						fileExt={file?.name?.split(".").pop()}
						fileMb={(file?.fileSize / 1024).toFixed(2)}
						copied={copied}
						setCopied={setCopied}
					/>
				))}
			</div>
		)}
	</>
);

export default MappedFiles;
