import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import moment from "moment";
import {actions as navigation} from "@sagas/navigation";

function ClientCustomUpdate(props) {
	const {row} = props;

	return (
		<div className="user__table-fullName">
			<p>{moment(row.updatedAt).format("DD-MM-YYYY HH:mm:ss")}</p>
		</div>
	);
}

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(ClientCustomUpdate));
