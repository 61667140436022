import {ShowInfoIcon} from "@src/assets/SvgComponents";
import {Storage} from "@src/utils/local-storage";
import {Switch} from "antd";
import React from "react";

const CustomSwitch = ({
	setFieldValue,
	field,
	title,
	disabled,
	value,
	additionalInfo,
	toggleInfoVisibility,
}) => {
	const theme = Storage.get("theme");

	const handleSwitch = (value) => setFieldValue(field, value);
	return (
		<div className="switch__wrapper">
			<div className="switch__wrapper-title">
				<span className="switch__wrapper-title-label">{title}</span>
				{additionalInfo && (
					<span className="info__icon" onClick={toggleInfoVisibility}>
						<ShowInfoIcon fill="#979797" />
					</span>
				)}
			</div>
			<Switch
				onChange={handleSwitch}
				disabled={disabled}
				checked={value}
				style={{backgroundColor: value && `${theme.primary}80`}}
			/>
		</div>
	);
};
export default CustomSwitch;
