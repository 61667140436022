import {put, select, takeLatest} from "redux-saga/effects";
import produce from "immer";
import Logger from "@src/utils/logger";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import axios from "axios";
import {ENDPOINT} from "@src/utils/endpoint";
import moment from "moment";
import createAction from "../../../../../utils/action-creator";

const logger = new Logger("Sagas>Analytics>Goals>Views");
const PREFIX = "@app/analytics/goals/views";

export const GET_VIEWS = `${PREFIX}GET_VIEWS`;
export const GET_VIEWS_SUCCESS = `${PREFIX}GET_VIEWS_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_VIEW = `${PREFIX}ADD_VIEW`;
export const ADD_VIEW_SUCCESS = `${PREFIX}ADD_VIEW_SUCCESS`;
export const DELETE_VIEW = `${PREFIX}DELETE_VIEW`;
export const DELETE_VIEW_SUCCESS = `${PREFIX}DELETE_VIEW_SUCCESS`;
export const SET_VIEW_TYPE = `${PREFIX}SET_VIEW_TYPE`;
export const GET_VIEWS_BY_ID = `${PREFIX}GET_VIEWS_BY_ID`;
export const GET_VIEWS_BY_ID_SUCCESS = `${PREFIX}GET_VIEWS_BY_ID_SUCCESS`;
export const EDIT_VIEW = `${PREFIX}EDIT_VIEW`;
export const EDIT_VIEW_SUCCESS = `${PREFIX}EDIT_VIEW_SUCCESS`;
export const GET_GOALS_BY_TYPE = `${PREFIX}GET_GOALS_BY_TYPE`;
export const GET_GOALS_BY_TYPE_SUCCESS = `${PREFIX}GET_GOALS_BY_TYPE_SUCCESS`;
export const EDIT_SIZE_GOAL = `${PREFIX}EDIT_SIZE_GOAL`;
export const EDIT_PAGE_GOAL = `${PREFIX}EDIT_PAGE_GOAL`;
export const SET_TOTAL_SIZE_GOAL = `${PREFIX}SET_TOTAL_SIZE_GOAL`;
export const SET_TOTAL_PAGES_GOAL = `${PREFIX}SET_TOTAL_PAGES_GOAL`;
export const RESET_PAGINATION_GOAL = `${PREFIX}RESET_PAGINATION_GOAL`;
export const GET_GOALS_TYPE_BY_ID = `${PREFIX}GET_GOALS_TYPE_BY_ID`;
export const GET_GOALS_TYPE_BY_ID_SUCCESS = `${PREFIX}GET_GOALS_TYPE_BY_ID_SUCCESS`;
export const EDIT_SIZE_TYPE = `${PREFIX}EDIT_SIZE_TYPE`;
export const EDIT_PAGE_TYPE = `${PREFIX}EDIT_PAGE_TYPE`;
export const SET_TOTAL_SIZE_TYPE = `${PREFIX}SET_TOTAL_SIZE_TYPE`;
export const SET_TOTAL_PAGES_TYPE = `${PREFIX}SET_TOTAL_PAGES_TYPE`;
export const RESET_PAGINATION_TYPE = `${PREFIX}RESET_PAGINATION_TYPE`;
export const SET_TYPE_LOADING = `${PREFIX}SET_TYPE_LOADING`;
export const EDIT_GOALS_TYPE_BY_ID_SUCCESS = `${PREFIX}EDIT_GOALS_TYPE_BY_ID_SUCCESS`;
const _state = {
	views: [],
	loading: false,
	view: null,
	goalsByType: {
		data: [],
		page: 1,
		size: 30,
		totalPages: 0,
		totalSize: 0,
	},
	goalsTypeById: {
		data: [],
		page: 1,
		size: 30,
		totalPages: 0,
		totalSize: 0,
		loading: false,
	},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case GET_VIEWS_SUCCESS:
				draft.views = payload;
				break;
			case ADD_VIEW_SUCCESS:
				draft.views = [payload.data, ...state.views];
				draft.goalsByType.data = [
					...state.goalsByType?.data,
					{name: payload?.data?.name, color: payload?.data?.color, id: payload?.data?.id},
				];
				break;
			case GET_VIEWS_BY_ID:
				draft.view = payload;
				break;
			case DELETE_VIEW_SUCCESS:
				draft.views = state.views.filter((item) => item?.id !== payload);
				draft.goalsByType.data = state.goalsByType.data.filter(
					(item) => item?.id !== payload,
				);
				break;
			case EDIT_VIEW_SUCCESS:
				draft.views = state.views.map((item) => {
					if (item?.id === payload.id) {
						return payload;
					}
					return item;
				});
				draft.goalsByType.data = state.goalsByType.data?.map((item) => {
					if (item?.id === payload.id) {
						return {...item, name: payload?.name, color: payload?.color};
					}
					return item;
				});
				break;
			case GET_GOALS_BY_TYPE_SUCCESS:
				draft.goalsByType.data = payload;
				break;
			case EDIT_PAGE_GOAL:
				draft.goalsByType.page = payload;
				break;
			case EDIT_SIZE_GOAL:
				draft.goalsByType.size = payload;
				break;
			case SET_TOTAL_SIZE_GOAL:
				draft.goalsByType.totalSize = payload;
				break;
			case SET_TOTAL_PAGES_GOAL:
				draft.goalsByType.totalPages = payload;
				break;
			case RESET_PAGINATION_GOAL:
				draft.goalsByType.totalSize = 0;
				draft.goalsByType.size = 30;
				draft.goalsByType.page = 1;
				draft.goalsByType.totalPages = 0;
				break;
			case EDIT_PAGE_TYPE:
				draft.goalsTypeById.page = payload;
				break;
			case EDIT_SIZE_TYPE:
				draft.goalsTypeById.size = payload;
				break;
			case SET_TOTAL_SIZE_TYPE:
				draft.goalsTypeById.totalSize = payload;
				break;
			case SET_TOTAL_PAGES_TYPE:
				draft.goalsTypeById.totalPages = payload;
				break;
			case RESET_PAGINATION_TYPE:
				draft.goalsTypeById.totalSize = 0;
				draft.goalsTypeById.size = 30;
				draft.goalsTypeById.page = 1;
				draft.goalsTypeById.totalPages = 0;
				draft.goalsTypeById.data = [];
				break;
			case GET_GOALS_TYPE_BY_ID_SUCCESS:
				const updatedGoalByTpe = payload?.data?.map((i) => {
					if (i.endDate < moment().valueOf()) {
						return {
							...i,
							hasExpired: true,
						};
					}
					return i;
				});
				if (payload?.page === 1) {
					draft.goalsTypeById.data = updatedGoalByTpe;
				} else {
					draft.goalsTypeById.data = [...state.goalsTypeById.data, ...updatedGoalByTpe];
				}
				break;
			case SET_TYPE_LOADING:
				draft.goalsTypeById.loading = payload;
				break;
			case EDIT_GOALS_TYPE_BY_ID_SUCCESS:
				const index = state.goalsTypeById?.data.findIndex((g) => g.id === payload.id);
				const updatedIndex = {
					...payload,
					hasExpired: payload?.endDate < moment().valueOf(),
				};
				draft.goalsTypeById.data[index] = updatedIndex;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getViews: (payload) => createAction(GET_VIEWS, {payload}),
	getViewsSuccess: (payload) => createAction(GET_VIEWS_SUCCESS, {payload}),
	addView: (payload) => createAction(ADD_VIEW, {payload}),
	addViewSuccess: (payload) => createAction(ADD_VIEW_SUCCESS, {payload}),
	deleteView: (payload) => createAction(DELETE_VIEW, {payload}),
	deleteViewSuccess: (payload) => createAction(DELETE_VIEW_SUCCESS, {payload}),
	getViewById: (payload) => createAction(GET_VIEWS_BY_ID, {payload}),
	getViewByIdSuccess: (payload) => createAction(GET_VIEWS_BY_ID_SUCCESS, {payload}),
	editView: (payload) => createAction(EDIT_VIEW, {payload}),
	editViewSuccess: (payload) => createAction(EDIT_VIEW_SUCCESS, {payload}),
	getGoalsByType: (payload) => createAction(GET_GOALS_BY_TYPE, {payload}),
	getGoalsByTypeSuccess: (payload) => createAction(GET_GOALS_BY_TYPE_SUCCESS, {payload}),
	getGoalsTypeById: (payload) => createAction(GET_GOALS_TYPE_BY_ID, {payload}),
	getGoalsTypeByIdSuccess: (payload) => createAction(GET_GOALS_TYPE_BY_ID_SUCCESS, {payload}),
	editSizeGoal: (payload) => createAction(EDIT_SIZE_GOAL, {payload}),
	editPageGoal: (payload) => createAction(EDIT_PAGE_GOAL, {payload}),
	setTotalSizeGoal: (payload) => createAction(SET_TOTAL_SIZE_GOAL, {payload}),
	setTotalPagesGoal: (payload) => createAction(SET_TOTAL_PAGES_GOAL, {payload}),
	resetPaginationGoal: (payload) => createAction(RESET_PAGINATION_GOAL, {payload}),
	editSizeType: (payload) => createAction(EDIT_SIZE_TYPE, {payload}),
	editPageType: (payload) => createAction(EDIT_PAGE_TYPE, {payload}),
	setTotalSizeType: (payload) => createAction(SET_TOTAL_SIZE_TYPE, {payload}),
	setTotalPagesType: (payload) => createAction(SET_TOTAL_PAGES_TYPE, {payload}),
	resetPaginationType: (payload) => createAction(RESET_PAGINATION_TYPE, {payload}),
	setTypeLoading: (payload) => createAction(SET_TYPE_LOADING, {payload}),
	editGoalsTypeByIdSuccess: (payload) => createAction(EDIT_GOALS_TYPE_BY_ID_SUCCESS, {payload}),
};

export const sagas = {
	*getViews() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`${ENDPOINT.GOAL_VIEWS}`);
			yield put(actions.getViewsSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*addView({payload}) {
		yield put(actions.setLoading(true));
		const {values, setModal} = payload;
		try {
			const response = yield axios.post(ENDPOINT.GOAL_VIEWS, values);
			yield put(actions.addViewSuccess(response?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			setModal(false);
			yield put(actions.setLoading(false));
		}
	},
	*deleteView({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.delete(ENDPOINT.GOAL_VIEWS_ID.replace(":id", payload));
			yield put(actions.deleteViewSuccess(payload));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getViewById({id}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(ENDPOINT.GOAL_VIEWS_ID.replace(":id", id));
			yield put(actions.getViewByIdSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*editView({payload}) {
		yield put(actions.setLoading(true));
		const {values, setModal} = payload;
		try {
			const response = yield axios.put(
				ENDPOINT.GOAL_VIEWS_ID.replace(":id", values?.id),
				values,
			);
			yield put(actions.editViewSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			setModal(false);
			yield put(actions.setLoading(false));
		}
	},
	*getGoalsByType({payload}) {
		yield put(actions.setLoading(true));
		const {groupViewType, query} = payload;
		try {
			const response = yield axios.post(
				`${ENDPOINT.GOAL_BY_TYPE.replace(":type", groupViewType)}?page=${1}&size=${30}`,
				query,
			);
			yield put(actions.getGoalsByTypeSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getGoalsTypeById({payload}) {
		yield put(actions.setTypeLoading(true));
		const {groupType, id, setPanelLoading, query} = payload;
		const {size, page} = yield select((state) => state.app.analytics.view.goalsTypeById);
		try {
			const response = yield axios.post(
				`${ENDPOINT.GOAL_BY_TYPE.replace(
					":type",
					groupType,
				)}/${id}?page=${page}&size=${size}`,
				query,
			);
			yield put(actions.setTotalSizeType(response.data.totalSize));
			yield put(actions.setTotalPagesType(response.data.totalPages));
			yield put(actions.getGoalsTypeByIdSuccess(response?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			if (setPanelLoading) {
				setPanelLoading(false);
			}
			yield put(actions.setTypeLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_VIEWS, sagas.getViews);
	yield takeLatest(ADD_VIEW, sagas.addView);
	yield takeLatest(DELETE_VIEW, sagas.deleteView);
	yield takeLatest(GET_VIEWS_BY_ID, sagas.getViewById);
	yield takeLatest(EDIT_VIEW, sagas.editView);
	yield takeLatest(GET_GOALS_BY_TYPE, sagas.getGoalsByType);
	yield takeLatest(GET_GOALS_TYPE_BY_ID, sagas.getGoalsTypeById);
};
