/* eslint-disable array-callback-return */
import React from "react";
import {
	EditRowIcon,
	FailedRowIcon,
	HideRowIcon,
	PreviewRowIcon,
	ShowRowIcon,
	SuccessRowIcon,
	ThreeDotsIcon,
} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import StatusBox from "@src/common/components/status-box";
import moment from "moment";
import {Dropdown} from "antd";
import _ from "lodash";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import {FORMAT_DATE} from "@src/common/constants";
import DropDownComp from "./DropDownComp";

export const getRowClassName = (row) =>
	row.same === true && row?.type !== "DETAIL" ? "purple-row" : "";
export const getPublishedRowClassName = (row) =>
	row?.status === "SUCCESS" ? "purple-row" : "failed-row";
export const orderColumns = [
	{
		name: "",
		key: "same",
		formatter: ({row}) => (row?.same ? <SuccessRowIcon /> : ""),
		width: 50,
		cellClass: "first-cell",
	},
	{name: i18n.t("VPNumber"), key: "vpNumber", width: 0, sortable: true},
	{name: i18n.t("VPName"), key: "salesPartnerName", sortable: true},
	{
		name: i18n.t("ID"),
		key: "orderId",
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("Client"),
		key: "clientName",
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("contractId"),
		key: "clientOrderId",
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("TariffName"),
		key: "tariffName",
		sortable: true,
		resizable: true,
	},

	{
		name: i18n.t("ProjectCategory"),
		key: "projectCategoryName",
		sortable: true,
		resizable: true,
	},

	{
		name: i18n.t("ProjectLocation"),
		key: "locationName",
		sortable: true,
		resizable: true,
	},

	{
		name: i18n.t("ContractDate"),
		key: "createdAt1",
		formatter: ({row}) => <span>{moment(row?.createdAt).format("DD.MM.YYYY")}</span>,
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("Status"),
		key: "orderStatus",
		width: 230,
		formatter: ({row}) => (
			<StatusBox status={row?.statusName || row?.orderStatus} translateText />
		),
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("OrderProductNumber"),
		key: "orderProductNumber",
		sortable: true,
		resizable: true,
	},
];
export const checkValidOrderStatus = (value, defaultStatus) => {
	const arr = Object.keys({}).map((item) => item);
	if (arr.includes(value)) {
		return value;
	}
	return defaultStatus;
};

export const generateRows = (rowsElem, columns, matchingField, importChangeStatus) => {
	if (!rowsElem || !columns) {
		return [];
	}
	const rows = rowsElem.map((rowData) => {
		const row = {};
		columns.forEach((column) => {
			row[column.name] = rowData[column?.key];
		});
		const status = checkValidOrderStatus(row?.orderStatus, importChangeStatus);
		return {...row, orderStatus: status};
	});
	const groupedArr = _.chain(rows)
		.groupBy(matchingField)
		.map((value) => {
			const parent = value[0];
			return {
				...parent,
				...(value.length > 1 && {children: value.slice(1)}),
			};
		})
		.value();
	return groupedArr;
};

export const camelCaseToHumanReadable = (str) => {
	const words = str.split(/(?=[A-Z])/);
	const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
	return capitalizedWords.join(" ");
};
const getFormatedRow = (
	index,
	item,
	row,
	options,
	setShowDeleteModal,
	matchingField,
	filteringStatusOptions,
) => {
	if (item.key === "orderStatus" && row?.same) {
		return <StatusBox row={row?.orderStatus} translateText />;
	}
	if ((item.key === matchingField || item.key === "orderStatus") && !row?.same) {
		return (
			<DropDownComp
				row={row?.[matchingField]}
				orderStatus={row.orderStatus}
				index={index}
				fromStatus={item.key === "orderStatus"}
				options={item.key === matchingField ? options : filteringStatusOptions}
				val={row?.[item.key]}
			/>
		);
	}
	if (item.key === "same") {
		return (
			<div
				style={{
					cursor: "pointer",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				onClick={() =>
					setShowDeleteModal({
						show: true,
						item: row,
					})
				}
			>
				<FailedRowIcon />
			</div>
		);
	}
	if (item.key === "same" && row.same) return <SuccessRowIcon />;
	return <span>{row?.[item.key]}</span>;
};
export const generateColumns = (
	data,
	options,
	setShowDeleteModal,
	matchingField,
	filteringStatusOptions,
) => {
	if (options) {
		const sortedData = data.sort((a, b) => parseInt(a.key) - parseInt(b.key));
		const cols = sortedData.map((item, index) => ({
			key: item?.name,
			name: index === 0 ? "" : i18n.t(camelCaseToHumanReadable(item?.name)),
			resizable: index !== 0,
			formatter: ({row}) =>
				getFormatedRow(
					index,
					item,
					row,
					options,
					setShowDeleteModal,
					matchingField,
					filteringStatusOptions,
				),
			width: index === 0 && 50,
			cellClass: index === 0 ? "first-cell" : "",
			show: index === 0 ? true : item.show,
		}));
		return cols;
	}
	const cols = Object.keys(data?.[0])
		.map(
			(key) =>
				key !== "same" && {
					key,
					name: i18n.t(key),
					resizable: true,
				},
		)
		.filter(Boolean);

	return cols;
};

const orderColumnsFormatter = (row, item) => {
	switch (item) {
		case "productInfo":
			if (row?.[item] !== null) {
				return (
					<HtmlTooltip
						title={
							<div className="tooltip-wrapper">
								{row?.[item]?.map((item) => (
									<div className="tooltip-wrapper_row">
										<span style={{fontWeight: 600}}>{item?.name}: </span>
										<span>{item?.value}</span>
									</div>
								))}
							</div>
						}
						placement="bottom-end"
					>
						<span>{row?.[item]?.reduce((acc, obj) => acc + obj.value, 0)}</span>
					</HtmlTooltip>
				);
			}
			return "-";
		case "tariffInfo":
			if (row?.[item] !== null) {
				return (
					<HtmlTooltip title={<span>{row?.[item]?.name}</span>} placement="bottom-end">
						<>
							<span>{row?.[item]?.name}</span>
						</>
					</HtmlTooltip>
				);
			}
			return "-";
		case "tariffProvision":
			if (row.tariffInfo !== null) {
				return (
					<HtmlTooltip
						title={<span>{row?.tariffInfo?.value}</span>}
						placement="bottom-end"
					>
						<span>{row?.tariffInfo?.value}</span>
					</HtmlTooltip>
				);
			}
			return "-";
		case "orderStatus":
			return (
				<StatusBox
					status={row?.systemStatusView?.previousStatus}
					name={row?.systemStatusView?.previousStatusName}
				/>
			);
		case "newStatus":
			if ((row?.newStatus && row?.newStatus !== "") || row?.status) {
				return (
					<StatusBox
						status={row?.systemStatusView?.orderStatus}
						name={row?.systemStatusView?.statusName}
					/>
				);
			}
			return "";
		case "createdAt":
			return <span>{FORMAT_DATE(row?.createdAt)}</span>;
		default:
			return <span>{row?.[item]}</span>;
	}
};
const defaultOrderColumns = [
	"firstName",
	"lastName",
	"orderStatus",
	"orderId",
	"clientOrderId",
	"clientName",
	"newStatus",
	"createdAt",
];
export const generateOrdersColumns = (data, ordersFilteredColumns, status) => {
	const dataNew = {...data, tariffProvision: ""};
	const formatFirstRow = (row) => {
		if (status === "DRAFT") {
			if (row?.same) {
				return <SuccessRowIcon />;
			}
			return "";
		}
		if (row?.orderStatus === row?.newStatus) {
			return <SuccessRowIcon />;
		}
		if (status === "PUBLISHED" && row?.status === "SUCCESS") {
			return <SuccessRowIcon />;
		}
		return <FailedRowIcon />;
	};
	const cols = Object.keys(dataNew).map((item, index) => ({
		key: item,
		name: i18n.t(item),
		resizable: true,
		width: index === 0 && 50,
		cellClass: index === 0 ? "first-cell" : "",
		show: defaultOrderColumns.includes(item),
		formatter: ({row}) => orderColumnsFormatter(row, item),
	}));
	if (!ordersFilteredColumns?.[0]) {
		return [
			{
				name: "",
				key: "same",
				formatter: ({row}) => formatFirstRow(row),
				width: 50,
				cellClass: "first-cell",
				show: true,
				resizable: false,
			},
			...cols,
		];
	}
	return ordersFilteredColumns;
};

export function compareArrays(array1, array2, property) {
	const set2 = new Set(array2.map((obj) => obj[property]));
	const result = array1.map((obj) => ({
		...obj,
		same: set2.has(obj[property]),
		[property]: obj?.oldContract ? obj?.oldContract : obj[property],
		children: obj.children?.map((it) => ({
			...it,
			same: set2.has(it[property]),
		})),
	}));
	result.sort((a, b) => {
		if (a.same === false && b.same !== false) {
			return 1;
		}
		if (a.same !== false && b.same === false) {
			return -1;
		}
		return 0;
	});

	return result;
}
export function compareDataArrays(array1, array2, property, setOptions) {
	// generate orders data
	const set1 = new Set(array1?.map((obj) => obj[property]));
	const updatedArray2 = array2?.map((item) => {
		const find = array1?.find((it) => it?.[property] === String(item?.[property]));
		if (find) {
			return {
				...item,
				orderId: String(item.orderId),
				[property]: String(item[property]),
				newStatus: find.orderStatus,
			};
		}
		return item;
	});
	const uniqueFromArr1 = updatedArray2
		.filter((obj) => set1.has(obj[property]))
		.map((obj) => ({...obj, same: true}));

	const uniqueFromArr2 = updatedArray2.filter((obj) => !set1.has(obj[property]));
	setOptions(
		uniqueFromArr2?.map((item) => ({
			name: item?.[property],
			value: `${item?.[property]}`,
			id: item?.orderId,
		})),
	);
	const ordersData = [...uniqueFromArr1, ...uniqueFromArr2];
	// generate draft data using orders data
	const set2 = new Set(ordersData?.map((obj) => obj[property]));
	const importsData = array1?.map((obj) => ({
		...obj,
		same: set2.has(obj[property]),
		matchedBy: set2.has(obj[property]) && !obj?.matchedBy ? "import" : obj.matchedBy,
		ordID: obj.ordID || +obj.orderId,
		children: obj.children?.map((it) => ({
			...it,
			same: set2.has(it[property]),
		})),
	}));
	return {importsData, ordersData};
}
export const actionsFormatter = (row, handlePreviewEdit) => {
	const items = [
		{
			key: "preview",
			label: i18n.t("PreviewOrder"),
			icon: (
				<div
					style={{
						width: "20px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<PreviewRowIcon color="#252525" />
				</div>
			),
			onClick: () => {
				handlePreviewEdit(row.orderId, "preview");
			},
		},
		{
			key: "edit",
			label: i18n.t("EditOrder"),
			icon: (
				<div
					style={{
						width: "20px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<EditRowIcon strokeWidth={1.7} stroke="#252525" />
				</div>
			),
			onClick: () => {
				handlePreviewEdit(row.orderId, "edit");
			},
		},
	];
	return (
		<Dropdown
			menu={{items}}
			overlayClassName="popover__actions-wrapper"
			placement="bottomLeft"
			trigger={["click"]}
		>
			<div className="three-dots-icon">
				<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
			</div>
		</Dropdown>
	);
};
export const getExpandRow = (headerColumns, arrayName) => ({
	key: "expanded",
	name: "",
	minWidth: 30,
	cellClass: "custom__cell-expander",
	width: 30,
	colSpan(args) {
		return args?.type === "ROW" && args?.row?.type === "DETAIL"
			? Object.keys?.(headerColumns)?.length + (arrayName === "children" ? 1 : 2)
			: undefined;
	},
	formatter({row, tabIndex, onRowChange}) {
		if (row.type === "DETAIL") {
			return (
				<DataGridTable
					stopPropagation
					wrapperStyles={{
						borderRadius: 0,
						padding: "10px, 2px",
					}}
					data={row?.[arrayName]}
					rows={row?.[arrayName]}
					columns={headerColumns || []}
					rowClass={getRowClassName}
					style={{
						height: row?.[arrayName]?.length * 44 + 52,
					}}
				/>
			);
		}
		if (row?.[arrayName]) {
			return (
				<div
					style={{
						float: "right",
						display: "table",
						blockSize: "100%",
						cursor: "pointer",
					}}
				>
					<span
						onClick={() => {
							onRowChange({...row, expanded: !row?.expanded});
						}}
					>
						<span tabIndex={tabIndex}>
							{row.expanded ? <HideRowIcon /> : <ShowRowIcon />}
						</span>
					</span>
				</div>
			);
		}
		return null;
	},
});
export const statusImportsColumns = [
	{
		name: i18n.t("Name"),
		key: "fileName",
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("clientName"),
		key: "clientName",
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("DateCreated"),
		key: "createdAt",
		sortable: true,
		formatter: ({row}) => FORMAT_DATE(row.createdAt),
		resizable: true,
	},
	{
		name: i18n.t("ImportedFrom"),
		key: "firstName",
		sortable: true,
		resizable: true,
		formatter: ({row}) => (
			<span>
				{row?.firstName} {row?.lastName}
			</span>
		),
	},
	{
		name: i18n.t("Status"),
		key: "status",
		sortable: true,
		resizable: true,
		formatter: ({row}) => <StatusBox status={i18n.t(row?.importOrderType)} />,
	},
];
