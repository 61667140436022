/* eslint-disable func-names */
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import IbanValidator from "@src/components/dashboard/Energy/components/IbanValidator";
import React, {useEffect, useRef, useState} from "react";
import CustomPopover from "../Popover";

const InputComponent = (props) => {
	const {
		errors,
		touched,
		handleBlur,
		handleChange,
		onKeyDown,
		values,
		name,
		placeholder = "",
		type = "text",
		errorClass,
		defaultValue,
		className,
		label,
		newClass,
		bottomBreak = false,
		min,
		disabled,
		hidden = false,
		setFieldValue,
		onlyPlaceholder,
		inputRef,
		onPaste = () => {},
		hasInfoIcon = false,
		infoText,
		maxLength,
		style = {},
		icon,
		isIbanInput = false,
		item,
		toggleInfoVisibility,
		additionalInfo,
	} = props;

	const [openPopover, setOpenPopover] = useState(false);
	const inputElement = useRef(null);

	const getTextWidth = (text, font) => {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		context.font = font;
		return context.measureText(text).width;
	};

	const handleVisibility = (visible) => {
		if (inputElement.current) {
			const inputWidth = inputElement.current.offsetWidth - 40;
			const {font} = window.getComputedStyle(inputElement.current);
			const textWidth = getTextWidth(values || "", font);

			if (type !== "password" && textWidth > inputWidth) {
				setOpenPopover(visible);
			} else {
				setOpenPopover(false);
			}
		}
	};

	const handleMouseEnter = () => {
		if (inputElement.current) {
			const inputWidth = inputElement.current.offsetWidth - 40;
			const {font} = window.getComputedStyle(inputElement.current);
			const textWidth = getTextWidth(values || "", font);

			if (type !== "password" && textWidth > inputWidth) {
				setOpenPopover(true);
			}
		}
	};

	const handleMouseLeave = () => {
		setOpenPopover(false);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Tab") {
			setOpenPopover(false);
		}
		if (onKeyDown) {
			onKeyDown(e);
		}
	};

	useEffect(() => {
		if (hidden) {
			setFieldValue(name, "");
		}
	}, [hidden]);

	if (hidden) {
		return null;
	}
	return (
		<div
			className={`form_input ${errors && touched ? "error" : ""} ${newClass || ""} ${
				additionalInfo && "relative"
			}`}
			style={style}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{(label || placeholder) && <p className="form_input__label">{label || placeholder}</p>}
			{hasInfoIcon && (
				<CustomPopover
					placement="bottom"
					customClass="custom__input__popover"
					menu={{
						items: [
							{
								key: infoText,
								label: infoText,
							},
						],
					}}
					arrow
				>
					<span className="info__icon">
						<ShowInfoIcon fill="#C1C1C1" />
					</span>
				</CustomPopover>
			)}
			<div>
				<CustomPopover
					customClass="custom__input__popover"
					handleVisibility={handleVisibility}
					open={openPopover}
					menu={{
						items: [
							{
								key: values,
								label: values,
							},
						],
					}}
					arrow={true}
				>
					<div className="input-contanier">
						<input
							ref={inputRef || inputElement}
							name={name}
							onBlur={handleBlur}
							onChange={disabled ? () => {} : handleChange}
							onKeyDown={handleKeyDown}
							placeholder={placeholder || onlyPlaceholder}
							type={type}
							value={values}
							style={{
								color: "#252525",
								borderRadius: "5px",
							}}
							defaultValue={defaultValue}
							className={className}
							min={min}
							disabled={disabled}
							onPaste={onPaste}
							onFocus={(e) => {
								e.target.addEventListener(
									"wheel",
									function (e) {
										e.preventDefault();
									},
									{passive: false},
								);
							}}
						/>
						<div className="icon">{icon}</div>
						{additionalInfo && (
							<div
								className="info__toggler"
								onClick={toggleInfoVisibility}
								style={{
									top: touched && errors ? "24px" : "auto",
								}}
							>
								<ShowInfoIcon
									fill="#ECECEC"
									fillColor="#979797"
									height={16}
									width={16}
								/>
							</div>
						)}
						{isIbanInput && (
							<IbanValidator
								item={item}
								value={values}
								setFieldValue={setFieldValue}
							/>
						)}
					</div>
				</CustomPopover>
				<div className="input-required">
					<div>{errors && touched && <span className={errorClass}>*{errors}</span>}</div>
					<div>
						{maxLength && (
							<p
								className="textarea_max_length"
								style={{
									display: "flex",
									justifyContent: "flex-end",
									marginTop: "5px",
									color: "#ddd",
								}}
							>
								{values?.length || 0}/{maxLength}
							</p>
						)}
					</div>
				</div>
			</div>
			{bottomBreak && <div className="bottomBreak"></div>}
		</div>
	);
};

export default InputComponent;
