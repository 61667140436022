import React, {useEffect} from "react";
import {connect} from "react-redux";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {Empty} from "antd";
import Loader from "@src/common/Loader";
import {NoDataIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import RelationBox from "./RelationBox";

function Relations({getRelations, relations, lead, getLeadById, loading, clearRelationsState}) {
	const {t} = useTranslation();
	useEffect(() => {
		getRelations(lead?.id);
		return () => clearRelationsState();
	}, []);
	if (loading)
		return (
			<div className="relations-wrapper">
				<Loader />
			</div>
		);
	return (
		<div className="relations-wrapper">
			{relations?.[0] ? (
				relations.map((item) => (
					<div
						onClick={() => getLeadById({id: item?.id})}
						style={{background: `${item?.leadColor}55`}}
						className="relations-wrapper_item"
					>
						<RelationBox data={item} />
					</div>
				))
			) : (
				<div className="empty">
					<div>
						<div>
							<NoDataIcon width="64" height="79" fill="#979797" />
						</div>
						<span className="empty-text">{t("NoData")}!</span>
					</div>
				</div>
			)}
		</div>
	);
}
const mapStateToProps = (state) => ({
	relations: state.app.salesTracker.index.relations,
	loading: state.app.salesTracker.index.relationLoading,
	lead: state.app.salesTracker.index.lead,
});
const mapDispatchToProps = {
	getRelations: salesTrackerActions.getRelations,
	getLeadById: salesTrackerActions.getLeadById,
	clearRelationsState: salesTrackerActions.clearRelationsState,
};
export default connect(mapStateToProps, mapDispatchToProps)(Relations);
