import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {ExpandAccordionIcon} from "@src/assets/SvgComponents";
import React from "react";
import AccordionHeader from "./AccordionHeader";

const ConditionAccordion = (props) => {
	const {
		children,
		handleModal,
		item,
		title,
		expanded,
		onChange,
		defaultExpanded = false,
		show = false,
		id,
		deletable = false,
		editModalType = "edit",
	} = props;
	return (
		<Accordion
			className="conditions__container-tables__accordion"
			expanded={expanded}
			onChange={onChange}
			defaultExpanded={defaultExpanded}
		>
			<AccordionSummary
				expandIcon={<ExpandAccordionIcon />}
				className="conditions__container-tables__accordion-header"
			>
				<AccordionHeader
					title={title}
					show={show}
					// teams={teamIds?.map((t) => t?.name)}
					fromDate={item?.startDate}
					toDate={item?.endDate}
					item={item}
					deletable={deletable}
					// showSaveIcon={showSaveIcon}
					// isExpired={isExpired}
					// onCopy={(e) => handleModal(e, "duplicate", id)}
					// onUpdate={(e) => handleModal(e, "update", id)}
					onEdit={(e) => {
						e.stopPropagation();
						handleModal(editModalType, id);
					}}
					onDelete={(e) => {
						e.stopPropagation();
						handleModal("delete", id, item);
					}}
				/>
			</AccordionSummary>
			{children}
		</Accordion>
	);
};

export default ConditionAccordion;
