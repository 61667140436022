import {put, select, takeLatest} from "redux-saga/effects";
import produce from "immer";
import axios from "axios";
import Logger from "@utils/logger";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import createAction from "../../../../utils/action-creator";

const logger = new Logger("Sagas>salesTracker>Imports");
const PREFIX = "@app/salesTracker/Imports";

export const GET_IMPORTS = `${PREFIX}GET_IMPORTS`;
export const GET_IMPORTS_SUCCESS = `${PREFIX}GET_IMPORTS_SUCCESS`;
export const GET_SINGLE_IMPORT = `${PREFIX}GET_SINGLE_IMPORT`;
export const GET_SINGLE_IMPORT_SUCCESS = `${PREFIX}GET_SINGLE_IMPORT_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FILTERS = `${PREFIX}FILTERS`;
export const SET_SIZE = `${PREFIX}SET_SIZE`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const SET_TOTAL_SIZE = `${PREFIX}SET_TOTAL_SIZE`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const DELETE_CSV = `${PREFIX}DELETE_CSV`;

const _state = {
	filters: {
		fromDate: null,
		toDate: null,
		search: "",
	},
	page: 1,
	size: 30,
	totalPages: 1,
	totalSize: 1,
	imports: [],
	singleImport: {},
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case GET_IMPORTS_SUCCESS:
				draft.imports = payload;
				break;
			case GET_SINGLE_IMPORT_SUCCESS:
				draft.singleImport = payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.filters[payload?.name] = payload.value;
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case SET_SIZE:
				draft.size = payload;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case SET_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	getImports: (payload) => createAction(GET_IMPORTS, {payload}),
	fetchImportsSuccess: (payload) => createAction(GET_IMPORTS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getSingleImportSuccess: (payload) => createAction(GET_SINGLE_IMPORT_SUCCESS, {payload}),
	getImportById: (payload) => createAction(GET_SINGLE_IMPORT, {payload}),
	filterImports: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(SET_TOTAL_SIZE, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editSize: (payload) => createAction(SET_SIZE, {payload}),
	deleteCsv: (payload) => createAction(DELETE_CSV, {payload}),
};

export const sagas = {
	*getImports({payload}) {
		yield put(actions.setLoading(true));
		const {size, page} = yield select((state) => state.app.salesTracker.imports);
		try {
			const response = yield axios.post(
				`/csv/lead_csv_files?page=${page}&size=${size}`,
				payload,
			);

			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchImportsSuccess(response?.data?.data?.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getImportById(id) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/csv/${id.payload}`);

			yield put(actions.getSingleImportSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteCsv({payload}) {
		const {importItem, status, leadStatuses} = payload;
		yield put(actions.setLoading(true));
		try {
			yield axios.put(`/lead/delete/csv`, {
				csvFileId: importItem.id,
				statusIds: [status?.value],
				leadStatuses: leadStatuses?.map((it) => it?.value),
			});
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};
export const watcher = function* w() {
	yield takeLatest(GET_IMPORTS, sagas.getImports);
	yield takeLatest(GET_SINGLE_IMPORT, sagas.getImportById);
	yield takeLatest(DELETE_CSV, sagas.deleteCsv);
};
