import {Skeleton} from "antd";
import React from "react";

function CustomCollapseSkeleton() {
	return (
		<div className="custom-collapse-skeleton_wrapper">
			{Array.from({length: 10}).map((_, rowIndx) => (
				<div className="box" key={`row-${rowIndx}`}>
					{Array.from({length: 4}).map((_, colIndx) => (
						<div className="cell">
							<Skeleton
								paragraph={{rows: 1}}
								key={`col-${rowIndx}-${colIndx}`}
								active
							/>
						</div>
					))}
				</div>
			))}
		</div>
	);
}

export default CustomCollapseSkeleton;
