/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from "react";
import {Circle, GoogleMap, Marker, withGoogleMap} from "react-google-maps";
import {actions as leadByMapActions} from "@sagas/salesTracker/mapView";
import {actions as leadActions} from "@sagas/salesTracker/index";
import {connect, useSelector} from "react-redux";
import {useUrlParams} from "@src/utils/useUrlParams";
import useGeoLocation from "./useGeoLocation";
import {circleOptions, multipleLeadsIcon, singleLeadIcon, svgToBase64} from "./icons";
import GroupedLeadsModal from "./GroupedLeadsModal";
import {getAddressByCoordinates} from "./helper";

const CustomMarker = ({item, multipleLeads, leadArray, setGroupedLeads}) => {
	const {setModal} = useUrlParams();

	const svgString = multipleLeads
		? multipleLeadsIcon
		: singleLeadIcon({fill: `${item?.color}95`, stroke: item?.color});

	const customIconBase64 = svgToBase64(svgString);

	return (
		<>
			<Marker
				position={{lat: item.latitude, lng: item.longitude}}
				onClick={() => {
					if (multipleLeads) {
						setGroupedLeads({data: leadArray, open: true});
					} else {
						setModal("lead", item.leadId);
					}
				}}
				label={
					multipleLeads
						? {
								text: `${leadArray?.length}`, // Label text
								color: "white", // Text color
								fontWeight: "bold", // Font weight
								fontSize: "14px", // Font size
						  }
						: undefined
				}
				icon={{
					url: customIconBase64,
				}}
			/>
		</>
	);
};

const LeadMap = withGoogleMap(
	({
		lat,
		lng,
		handleCoordinatesChange,
		leads,
		setGroupedLeads,
		setZoomLevel,
		setInitialValues,
	}) => {
		const mapRef = useRef(null);
		const {setModal} = useUrlParams();

		const handleBoundsChanged = () => {
			if (mapRef.current) {
				const bounds = mapRef.current.getBounds();
				const zoom = mapRef.current.getZoom();
				setZoomLevel(zoom);
				if (bounds) {
					const northEast = bounds.getNorthEast();
					const southWest = bounds.getSouthWest();

					const northLat = northEast.lat();
					const southLat = southWest.lat();
					const eastLng = northEast.lng();
					const westLng = southWest.lng();
					handleCoordinatesChange({northLat, southLat, eastLng, westLng});
				}
			}
		};
		const handleMapClick = async (e) => {
			const latitude = e.latLng.lat();
			const longitude = e.latLng.lng();
			const addressResult = await getAddressByCoordinates({latitude, longitude});
			const obj = {
				createAddress: {
					...addressResult,
				},
			};
			setInitialValues(obj);
			setModal("lead");
		};
		if (!lat || !lng) {
			return null;
		}
		return (
			<GoogleMap
				defaultZoom={8}
				defaultCenter={{lat, lng}}
				ref={mapRef}
				onDragEnd={handleBoundsChanged}
				onZoomChanged={handleBoundsChanged}
				onClick={handleMapClick}
			>
				<Circle center={{lat, lng}} radius={100} options={circleOptions} />
				<Marker position={{lat, lng}} />
				{Object.keys(leads)?.map((key) => {
					const leadArray = leads?.[key] || [];
					const item = leadArray?.[0];
					const multipleLeads = leadArray?.length > 1;
					return (
						<CustomMarker
							item={item}
							key={key}
							multipleLeads={multipleLeads}
							leadArray={leadArray}
							setGroupedLeads={setGroupedLeads}
						/>
					);
				})}
			</GoogleMap>
		);
	},
);

const LeadsMapView = ({getLeads, query, setInitialValues}) => {
	const {setQuery} = useUrlParams();
	const {coordinates} = useGeoLocation({setQuery, query});
	const [zoomLevel, setZoomLevel] = useState(8);
	const [groupedLeads, setGroupedLeads] = useState({
		data: [],
		open: false,
	});

	const handleCoordinatesChange = (value) => setQuery({...query, ...value});

	const {leads, loading} = useSelector((state) => state.app.salesTracker.mapView);
	useEffect(() => {
		if (
			query?.northLat &&
			query?.southLat &&
			query?.eastLng &&
			query?.westLng &&
			zoomLevel >= 8 &&
			!loading
		) {
			getLeads(query);
		}
	}, [
		query?.from,
		query?.to,
		query?.northLat,
		query?.southLat,
		query?.eastLng,
		query?.westLng,
		query?.clientId,
		query?.statusesIds?.length,
	]);

	const closeModal = () => setGroupedLeads({open: false, data: []});

	return (
		<>
			<GroupedLeadsModal data={groupedLeads} handleClose={closeModal} />
			<LeadMap
				{...coordinates}
				containerElement={<div style={{height: "calc(100vh - 160px)", width: "100%"}} />}
				mapElement={<div style={{height: "100%", width: "100%"}} />}
				handleCoordinatesChange={handleCoordinatesChange}
				leads={leads}
				setGroupedLeads={setGroupedLeads}
				setZoomLevel={setZoomLevel}
				setInitialValues={setInitialValues}
			/>
		</>
	);
};

const mapDispatchToProps = {
	getLeads: leadByMapActions.getLeadsByMap,
	setInitialValues: leadActions.setLeadInitialValues,
};
export default connect(null, mapDispatchToProps)(LeadsMapView);
