import React from "react";
import i18n from "../../../i18n";
import ClientCustomColumn from "./ClientCustomColumn";
import ClientCustomName from "./ClientCustomName";

export const clientColumnsRemote = [
	{
		name: i18n.t("clientName"),
		key: "clientName",
		image: "imageField",
		cellClass: "custom__client__cell",
		headerCellClass: "headerStyles",
		sortable: true,
		resizable: true,
		formatter: ({row}) => (
			<ClientCustomColumn
				row={row}
				link="/admin/clients"
				navLink={`${row.firstName} ${row.lastName}`}
			/>
		),
		headerStyle: {
			// backgroundColor: "#f6f5f9",
			fontFamily: "Montserrat",
			fontSize: "1.3rem",
			// color: "#6C1EB0",
		},
		cellStyle: {
			color: "#6C1EB0",
			backgroundColor: "rgba(68, 34, 139, 0.05)",
			marginRight: "190px",
			borderRightStyle: "solid",
			borderRightColor: "#f5f5f5",
			borderRightWidth: "1px",
		},
	},
	{
		name: i18n.t("ClientOrderID"),
		key: "clientId",
		sortable: true,
		headerCellClass: "headerStyles",
		resizable: true,
	},
	{
		name: i18n.t("ContactPersonName"),
		key: "firstName",
		headerCellClass: "headerStyles",
		formatter: ({row}) => <ClientCustomName row={row} />,
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("email"),
		key: "email",
		sortable: true,
		resizable: true,
		headerCellClass: "headerStyles",
	},
];

export const clientColumnsRemoteDeleted = [
	{
		name: i18n.t("clientName"),
		key: "clientName",
		cellClass: "custom__client__cell",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<ClientCustomColumn
				row={row}
				canNavigate={false}
				navLink={`${row.firstName} ${row.lastName}`}
			/>
		),
		headerStyle: {
			// backgroundColor: "#f6f5f9",
			fontFamily: "Montserrat",
			fontSize: "1.3rem",
			// color: "#6C1EB0",
		},
		cellStyle: {
			color: "#6C1EB0",
			backgroundColor: "rgba(68, 34, 139, 0.05)",
			marginRight: "190px",
			borderRightStyle: "solid",
			borderRightColor: "#f5f5f5",
			borderRightWidth: "1px",
		},
	},
	{
		name: i18n.t("ClientOrderID"),
		key: "clientId",
		sortable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("ContactPersonName"),
		sortable: true,
		key: "firstName",
		headerCellClass: "headerStyles",
		formatter: ({row}) => <ClientCustomName row={row} />,
	},
	{name: i18n.t("email"), key: "email", sortable: true, headerCellClass: "headerStyles"},
];

export const cleintData = [
	{clientName: "Tollen Company", name: "Elex", email: "tollen@hotmail.com"},
	{clientName: "Lummer", name: "Warren", email: "lummer@gmail.com"},
	{clientName: "GogG", name: "John", email: "gogg@gmail.com"},
	{clientName: "Techframe", name: "Ron", email: "ron@tframe.de"},
	{clientName: "Set Set", name: "Avebire", email: "set@gmail.com"},
];
