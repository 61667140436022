import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as tenantActions} from "@sagas/tenants";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Tenant lock");

const PREFIX = "@app/tenant/lock";
export const LOCK_TENANT = `${PREFIX}LOCK_TENANT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_LOCK_MODAL = `${PREFIX}SHOW_LOCK_MODAL`;

const _state = {
	loading: false,
	lockShowModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_LOCK_MODAL:
				draft.lockShowModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	lockTenant: (payload) => createAction(LOCK_TENANT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowLockModal: (payload) => createAction(SHOW_LOCK_MODAL, {payload}),
};

export const sagas = {
	*lockTenant(id) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put(`/tenant/lock/${id.payload}`);
			yield put(tenantActions.lockTenantOnState(id.payload));
			ToastSuccesComponent(i18n.t("TenantLockSuccess"));
			yield put(actions.setShowLockModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(LOCK_TENANT, sagas.lockTenant);
};
