/* eslint-disable*/

import axios from "axios";
import { configureStore } from '../store';
import { actions as logoutActions } from '@sagas/auth/logout';
import { ToastErrorComponent } from "@common/ToastComponent/ToastComponent";

const APP_STORE = configureStore();

const { REACT_APP_WEB_API_URL } = process.env;
// configure axios defaults
axios.defaults.baseURL = `${REACT_APP_WEB_API_URL}`
// axios.defaults.baseURL = "https://qa-tframe.oneri.de/api";
axios.defaults.config = {
	headers: { "Content-Type": "multipart/form-data", "Accept-Language": "fr-CH" },
};
export const axiosWithoutToken = axios.create({
	baseURL: axios.defaults.baseURL,
	headers: axios.defaults.config,
});

export const setupAxios = (store) => {
	axios.interceptors.request.use((config) => {
		// config token as default per request
		const _token = window.localStorage.getItem("token");
		const _language = window.localStorage.getItem("language");
		config.headers["Accept-Language"] = `${_language}`;
		// DO NOT ADD TOKEN ON S3 REQUESTS !

		if (!config.url.includes("s3.amazonaws.com") && !config.url.includes("ftp-test.oneri.de")) {
			if (_token) {
				config.headers.Authorization = `Bearer ${_token}`;
			}
		}
		return config;
	});

	// Add a response interceptor
	axios.interceptors.response.use(
		(response) => {
			/*
			 * if error: "No permission!"
			 * logout & go to login page
			 */
			if (response && response.data && response.data.error) {
				if (response.data.error === "No permission!") {
					return;
				}
			}
			return response;
		},
		(error) => {
			const _error = { ...error };
			if (!_error.response) {
				return;
			}
			if (_error.response && _error.response.status === 401) {
				/*
				 * Unauthorized Request
				 * store.dispatch(userActions.logout());
				 */
				// APP_STORE.dispatch(logoutActions.logout());
				// ToastErrorComponent(_error?.response?.data?.message);
			} else if (_error.response && _error.response.status === 403) {
				/*
				 * Forbiden Request
				 * store.dispatch(forbidenRequest());
				 */
			} else if (_error.response && _error.response.status === 404) {
				/*
				 * route not found
				 * store.dispatch(erroredRequest());
				 * history.push('/error/404');
				 * store.dispatch(userActions.logout());
				 */
			} else {
				/*
				 * TODO FIX THIS
				 * store.dispatch(userActions.logout());
				 */
			}

			return Promise.reject(_error);
		},
	);
};

export default axios;