import i18n from "@src/i18n";
import {stringFormatNumber} from "@src/utils/convertNumber";
import React from "react";
export const columns = [
	{
		key: "month",
		name: i18n.t("month"),
	},
	{
		key: "provision",
		name: i18n.t("provision"),
		formatter: ({row}) => stringFormatNumber(row?.provision),
	},
	{
		key: "cancellations",
		name: i18n.t("cancellationReserve"),
		formatter: ({row}) => {
			const formattedNumber = stringFormatNumber(row?.cancellations);
			return row?.cancellations > 0 ? `-${formattedNumber}` : formattedNumber;
		},
	},
	{
		key: "canceledContracts",
		name: i18n.t("canceledContracts"),
		formatter: ({row}) => {
			if (row?.canceledContracts == null) {
				return "-";
			}
			return `-${stringFormatNumber(row?.canceledContracts)}`;
		},
	},
	{
		key: "overhead",
		name: i18n.t("overhead"),
		formatter: ({row}) => stringFormatNumber(row?.overhead),
	},
	{
		key: "manualBookings",
		name: i18n.t("manualBookings"),
		formatter: ({row}) => stringFormatNumber(row?.manualBookings),
	},
	{
		key: "total",
		name: i18n.t("total"),
		formatter: ({row}) => stringFormatNumber(row?.total),
	},
];

export const formatDate = (dateArray) => {
	const months = [
		"Januar",
		"Februar",
		"März",
		"April",
		"Mai",
		"Juni",
		"Juli",
		"August",
		"September",
		"Oktober",
		"November",
		"Dezember",
	];

	const [year, month] = dateArray;
	const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-based index

	let formattedDate;
	if (year === currentYear) {
		formattedDate = `${months[month - 1]} bis ${months[currentMonth - 1]}, ${year}`;
	} else {
		formattedDate = `${months[month - 1]} bis Dezember, ${year}`;
	}

	return formattedDate;
};
