import MessagesContainer from "@common/messagesComponents/MessagesContainer";
import React from "react";

const Messages = () => (
	<div className="messages__container">
		<MessagesContainer />
	</div>
);

export default Messages;
