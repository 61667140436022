import produce from "immer";
import {call, put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/users/users";
import {ToastErrorComponent, ToastSuccesComponent} from "@common/ToastComponent/ToastComponent";
import moment from "moment";
import {actions as navigateActions} from "@sagas/navigation";
import i18n from "../../../../../i18n";

const PREFIX = "@app/users/user/index/";
export const ADD_USER = `${PREFIX}ADD_USER`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_USER_SUCCESS = `${PREFIX}ADD_USER_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const SHOW_TEAM_MODAL = `${PREFIX}SHOW_TEAM_MODAL`;
export const FILL_USERS = `${PREFIX}FILL_USERS`;
export const SET_STEP = `${PREFIX}SET_STEP`;
export const FILL_STEP = `${PREFIX}FILL_STEP`;
export const RESET_STEP = `${PREFIX}RESET_STEP`;
export const SET_SELECTED_USERS = `${PREFIX}SET_SELECTED_USERS`;
export const SET_NUM_OF_SELECTED_USERS = `${PREFIX}SET_NUM_OF_SELECTED_USERS`;

const _state = {
	loading: false,
	userAdded: false,
	userInitialValues: {
		dateOfBirth: moment().valueOf(),
		exitDate: moment().valueOf(),
		entryDate: moment().valueOf(),
		roleId: null,
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		postCode: "",
		city: "",
		company: "",
		taxNumberUrl: "",
		mobilePhone: "",
		idNumberUrlFront: "",
		idNumberUrlBack: "",
		businessLicenseUrl: "",
		hrNumberUrl: "",
		vatEntitlementUrl: "",
		vatNumberUrl: "",
		tradeLicenceUrl: "",
		passportNr: "",
		idNumber: "",
		taxNumber: "",
		// passportValidUntil: moment().valueOf(),
		taxOffice: "",
		businessLicense: "",
		hrNumber: "",
		nameOfBankAccountHolder: "",
		vatEntitlement: "no",
		vatNumber: "",
		extVpNr: "",
		iban: "",
		bic: "",
		bankName: "",
		userRoles: "",
		isCompany: "no",
		companyType: "",
		gender: "",
		agency: "",
		logo: "",
		agencyType: "SIMPLE",
	},
	teamInitialValues: {
		groupName: "",
		users: [],
	},
	showModal: false,
	teamModal: false,
	step: 1,
	numOfSelectedUsers: 0,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_USER_SUCCESS:
				draft.userAdded = action.payload;
				break;
			case FILL_USERS:
				const structureObj = Object.entries(action.payload.structure || {});
				const [structureId, structureName] = structureObj[0] || [];
				const stufeObj = Object.entries(action.payload.stufe || {});
				const [stufeId, stufeName] = stufeObj[0] || [];
				const obj = {
					...action.payload,
					userRoles: {
						value: action.payload?.role?.id,
						name: action.payload?.role?.name,
					},
					companyType: {
						value: action.payload.companyType,
						name: action.payload.companyType,
					},
					gender: {
						value: action.payload.gender,
						name: action.payload.gender === "MALE" ? i18n.t("Male") : i18n.t("Female"),
					},
					agency: {
						value: action.payload?.agencyData?.agencyId,
						name: action.payload?.agencyData?.companyName,
					},
					vatEntitlement: action.payload?.vatEntitlement === true ? "yes" : "no",
					company: action.payload?.company === null ? "" : action.payload?.company,
					isCompany: action.payload?.isCompany === true ? "yes" : "no",
					selectStructureId: null,
					selectStufeId: null,
					supervisor: {
						name: action?.payload?.supervisor?.name || "",
						value: action?.payload?.supervisor?.id || null,
					},
				};
				if (structureId) {
					obj.selectStructureId = {
						name: structureName,
						value: +structureId,
					};
				}
				if (stufeId) {
					obj.selectStufeId = {
						name: stufeName,
						value: +stufeId,
					};
				}

				if (action.payload.id) {
					draft.userInitialValues = obj;
				} else {
					draft.userInitialValues = {
						dateOfBirth: moment().valueOf(),
						exitDate: moment().valueOf(),
						entryDate: moment().valueOf(),
						roleId: null,
						firstName: "",
						lastName: "",
						email: "",
						phoneNumber: "",
						postCode: "",
						city: "",
						company: "",
						taxNumberUrl: "",
						mobilePhone: "",
						idNumberUrlFront: "",
						idNumberUrlBack: "",
						businessLicenseUrl: "",
						hrNumberUrl: "",
						vatEntitlementUrl: "",
						vatNumberUrl: "",
						tradeLicenceUrl: "",
						passportNr: "",
						idNumber: "",
						taxNumber: "",
						// passportValidUntil: moment().valueOf(),
						taxOffice: "",
						businessLicense: "",
						hrNumber: "",
						nameOfBankAccountHolder: "",
						vatEntitlement: "no",
						vatNumber: "",
						extVpNr: "",
						iban: "",
						bic: "",
						bankName: "",
						userRoles: "",
						companyType: "",
						gender: "",
						isCompany: "no",
						agency: "",
						agencyType: "SIMPLE",
						logo: "",
					};
				}
				break;

			case FILL_STEP:
				draft.userInitialValues = {
					...state.userInitialValues,
					...action.payload.values,
				};
				break;
			case SET_STEP:
				if (action.payload < state.step) {
					draft.step = action.payload;
				} else {
					draft.step = state.step + 1;
				}

				break;
			case RESET_STEP:
				draft.step = 1;
				break;
			case SET_NUM_OF_SELECTED_USERS:
				draft.numOfSelectedUsers = action.payload;
				break;
			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) {
					draft.teamInitialValues = {
						roleId: null,
						firstName: "",
						lastName: "",
						email: "",
						phoneNumber: "",
						extVpNr: "",
						career: "",
						distributionChannel: "",
						fixedLevel: "",
						intConsultant: "",
						salutation: "",
						street: "",
						nr: "",
						postCode: "",
						country: "",
						dateOfBirth: moment().valueOf(),
						exitDate: moment().valueOf(),
						entryDate: moment().valueOf(),
						company: "",
						legalForm: "",
						taxNumber: "",
						branch: "",
						fax: "",
						mobilePhone: "",
						accountNumber: "",
						advertisedBy: "",
						fromCentralRe: "",
						vatrequired: "",
						isCompany: "",
						tradeLicense: "",
						copyOfRegister: "",
						passport: "",
					};
				}
				break;
			case SET_SELECTED_USERS:
				draft.teamInitialValues = {
					...state.teamInitialValues,
					users: action.payload,
				};
				break;
			case SHOW_TEAM_MODAL:
				draft.teamModal = action.payload;
				if (!action.payload) {
					draft.teamInitialValues = {
						groupName: "",
						users: "",
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addUser: (payload) => createAction(ADD_USER, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addUserSucces: (payload) => createAction(ADD_USER_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	setShowTeamModal: (payload) => createAction(SHOW_TEAM_MODAL, {payload}),
	fillUsers: (payload) => createAction(FILL_USERS, {payload}),
	fillStep: (payload) => createAction(FILL_STEP, {payload}),
	setStep: (payload) => createAction(SET_STEP, {payload}),
	resetStep: (payload) => createAction(RESET_STEP, {payload}),
	setSelectedUsers: (payload) => createAction(SET_SELECTED_USERS, {payload}),
	setNumOfSelectedUsers: (payload) => createAction(SET_NUM_OF_SELECTED_USERS, {payload}),
};

const convertBase64 = (file) =>
	new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			resolve(fileReader.result);
		};
		fileReader.onerror = (error) => {
			reject(error);
		};
	});

const handleFileRead = async (event) => {
	const file = event;
	const base64 = await convertBase64(file);
	return base64;
};

const AGENCY_ROLE = ["AGENCY"];

export const sagas = {
	*addUser({payload}) {
		yield put(actions.setLoading(true));

		const {values} = payload;
		try {
			const {loggedUser} = yield select((state) => state.app.users.index);

			let response;
			if (values?.id) {
				const editObj = {
					...values,
					roleId: loggedUser?.role?.name?.includes(AGENCY_ROLE)
						? 7
						: values.userRoles.value,
					vatEntitlement: values.vatEntitlement === "yes",
					isCompany: values.isCompany === "yes",
					company: values.isCompany === "no" ? null : values.company,
					taxNumberUrl: values?.taxNumberUrl?.name
						? yield call(handleFileRead, values.taxNumberUrl)
						: null,
					idNumberUrlFront: values?.idNumberUrlFront?.name
						? yield call(handleFileRead, values.idNumberUrlFront)
						: null,
					idNumberUrlBack: values?.idNumberUrlBack?.name
						? yield call(handleFileRead, values.idNumberUrlBack)
						: null,
					businessLicenseUrl: values?.businessLicenseUrl?.name
						? yield call(handleFileRead, values.businessLicenseUrl)
						: null,
					hrNumberUrl: values?.hrNumberUrl?.name
						? yield call(handleFileRead, values.hrNumberUrl)
						: null,
					vatEntitlementUrl: values?.vatEntitlementUrl?.name
						? yield call(handleFileRead, values.vatEntitlementUrl)
						: null,
					vatNumberUrl: values?.vatNumberUrl?.name
						? yield call(handleFileRead, values.vatNumberUrl)
						: null,
					tradeLicenceUrl: values?.tradeLicenceUrl?.name
						? yield call(handleFileRead, values.tradeLicenceUrl)
						: null,
					logo: values?.logo?.name ? yield call(handleFileRead, values.logo) : null,
					companyType: values?.companyType?.name,
					gender: values?.gender?.value,
					selectStructureId: values?.selectStructureId?.value,
					selectStufeId: values?.selectStufeId?.value,
					supervisorId: values?.supervisor?.value,
				};
				delete editObj.userRoles;
				delete editObj.role;
				delete editObj.supervisor;

				response = yield axios.put(`/user/update/`, editObj);

				yield put(userActions.editUserOnState(editObj));
				ToastSuccesComponent(i18n.t("UserEditSuccess"));
			} else {
				const obj = {
					...values,
					taxNumberUrl: values.taxNumberUrl
						? yield call(handleFileRead, values.taxNumberUrl)
						: null,
					idNumberUrlFront: values.idNumberUrlFront
						? yield call(handleFileRead, values.idNumberUrlFront)
						: null,
					idNumberUrlBack: values.idNumberUrlBack
						? yield call(handleFileRead, values.idNumberUrlBack)
						: null,
					businessLicenseUrl: values?.businessLicenseUrl
						? yield call(handleFileRead, values.businessLicenseUrl)
						: null,
					hrNumberUrl: values?.hrNumberUrl
						? yield call(handleFileRead, values.hrNumberUrl)
						: null,
					vatEntitlementUrl: values.vatEntitlementUrl
						? yield call(handleFileRead, values.vatEntitlementUrl)
						: null,
					vatNumberUrl: values.vatNumberUrl
						? yield call(handleFileRead, values.vatNumberUrl)
						: null,
					tradeLicenceUrl: values.tradeLicenceUrl
						? yield call(handleFileRead, values.tradeLicenceUrl)
						: null,
					logo: values?.logo?.name ? yield call(handleFileRead, values.logo) : null,
					vatEntitlement: values.vatEntitlement === "yes",
					isCompany: values.isCompany === "yes",
					company: values?.isCompany === "no" ? null : values.company,
					roleId: loggedUser?.role?.name?.includes(AGENCY_ROLE)
						? 7
						: values.userRoles.value,
					agency: values?.userRoles?.value === 7 ? values?.agency?.value : null,
					companyType: values?.companyType?.name,
					gender: values?.gender?.value,
					agencyType:
						values?.userRoles?.value === 6 || values?.userRoles?.value === 14
							? values?.agencyType
							: null,
					selectStructureId: values?.selectStructureId?.value,
					selectStufeId: values?.selectStufeId?.value,
					supervisorId: values?.supervisor?.value,
				};
				delete obj.userRoles;
				delete obj.supervisor;
				response = yield axios.post(`/user/create`, obj);
				ToastSuccesComponent(i18n.t("UserCreateSuccess"));
				yield put(userActions.addUserOnState(response.data.data));
			}
			yield put(actions.setShowModal(false));
			yield put(navigateActions.navigate("/admin/users"));
			yield put(actions.resetStep());
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_USER, sagas.addUser);
};
