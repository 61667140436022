/* eslint-disable import/no-cycle */
import produce from "immer";
import {delay, put, takeLatest} from "redux-saga/effects";
import i18n from "../../../../i18n";
import createAction from "../../../../utils/action-creator";
import {actions as navigation} from "../navigation";
import {LOGOUT} from "./logout";
import Logger from "../../../../utils/logger";
import axios from "../../../../utils/axios";
import {requestForToken} from "../../../../utils/firebase";

const logger = new Logger("Auth Login");

const PREFIX = "@app/files/Index";
export const LOGIN = `${PREFIX}LOGIN`;
export const LOGIN_SUCCESS = `${PREFIX}LOGIN_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_ME = `${PREFIX}FETCH_ME`;
export const CHECK_ERROR = `${PREFIX}CHECK_ERROR`;

const _state = {
	data: {
		email: "",
		password: "",
		// fromPhone: false,
	},
	token: null,
	deviceToken: null,
	isAuth: false,
	loading: false,
	hasError: {error: false, statusCode: null},
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case LOGIN_SUCCESS:
				draft.token = action.payload.token;
				draft.isAuth = action.payload.isAuth;
				break;

			case LOGOUT:
				draft.token = null;
				draft.isAuth = false;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case CHECK_ERROR:
				draft.hasError = {
					error: action.payload.error,
					statusCode: action.payload.statusCode,
				};
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	login: (payload) => createAction(LOGIN, {payload}),
	// fetchMe: () => createAction(FETCH_ME),
	loginSuccess: (payload) => createAction(LOGIN_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	checkError: (payload) => createAction(CHECK_ERROR, {payload}),
};

export const sagas = {
	*login({payload}) {
		yield put(actions.setLoading(true));
		yield put(actions.checkError({error: false, statusCode: null}));
		try {
			const response = yield axios.post("/auth", payload.values);
			const devicetokenPromise = requestForToken();
			yield delay(1000); // add a delay of 1 second
			devicetokenPromise.then((token) => {
				axios.post("/registerDeviceToken", {token});
			});
			yield localStorage.setItem("token", response?.data?.token);
			yield put(actions.loginSuccess({token: response.data.token, isAuth: true}));
			yield put(navigation.navigate("/admin/dashboard"));
		} catch (error) {
			yield put(actions.checkError({error: true, statusCode: error?.response?.status}));
			const {formActions} = payload;
			formActions.setFieldError("password", i18n.t("YourEmailOrPassword"));

			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(LOGIN, sagas.login);
};
