import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import TableRemote from "@common/table/remote";
import {actions as createClientActions} from "@sagas/client/create";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import FormikForm from "@common/FormikForm";
import {actions as tenantActions} from "@sagas/tenants";
import {actions as createTenantActions} from "@sagas/tenants/create";
import {actions as deleteTenantActions} from "@sagas/tenants/delete";
import {actions as lockTenantActions} from "@sagas/tenants/locked";
import {useTranslation} from "react-i18next";
import {
	DeleteRowIcon,
	EditRowIcon,
	LockIcon,
	TenantsIcon,
	UnLockIcon,
} from "@src/assets/SvgComponents";
import Button from "@src/common/components/button";
import plusLogo from "@assets/img/plusIconBackground.svg";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import {clientColumnsRemote} from "./tenantColumns";
import Modal from "../../../common/modal";
import {clientElements} from "./tenantElements";

const Clients = (props) => {
	const {
		fillTenant,
		page,
		size,
		totalSize,
		editPage,
		editSize,
		showModal,
		setShowModal,
		deleteTenant,
		deleteShowModal,
		setShowDeleteModal,
		lockTenant,
		lockShowModal,
		setShowLockModal,
		loading,
		getTenants,
		tenants,
		search,
		filterTenants,
		addTenant,
		tenantInitialValues,
	} = props;

	const [deleteUserId, setDeleteUserId] = useState(null);
	const [lockedUserId, setLockedUserId] = useState(null);

	const handleSearch = (value) => {
		filterTenants({name: "search", value: value.target.value});
	};

	const getIdAndShowModal = (id) => {
		setDeleteUserId(id);
		setShowDeleteModal(true);
	};

	const getIdAndShowLockModal = (id) => {
		setLockedUserId(id);
		setShowLockModal(true);
	};
	useEffect(() => {
		getTenants();
	}, []);

	const actionsFormatter = (row) => (
		<div className="dashboard__container__tenant__actions">
			<HtmlTooltip title={<span>{row?.locked ? t("UnlockClient") : t("LockClient")}</span>}>
				<span onClick={() => getIdAndShowLockModal(row.id)}>
					{row?.locked ? <LockIcon /> : <UnLockIcon />}
				</span>
			</HtmlTooltip>
			<HtmlTooltip title={<span>{t("EditClient")}</span>}>
				<span
					onClick={() => {
						fillTenant(row);
						setShowModal(true);
					}}
				>
					<EditRowIcon />
				</span>
			</HtmlTooltip>
			<HtmlTooltip title={<span>{t("deleteClient")}</span>}>
				<span onClick={() => getIdAndShowModal(row.id)}>
					<DeleteRowIcon />
				</span>
			</HtmlTooltip>
		</div>
	);

	const {t} = useTranslation();

	const actions = {
		name: "Actions",
		width: 100,
		resizable: false,
		sortable: false,
		formatter: ({row}) => actionsFormatter(row),
	};

	return (
		<>
			<DeleteModal
				setShowDeleteModal={setShowDeleteModal}
				confirm={() => deleteTenant(deleteUserId)}
				word={t("ThisTenant")}
				open={deleteShowModal}
				handleClose={() => setShowDeleteModal(false)}
			/>

			<DeleteModal
				setShowDeleteModal={setShowLockModal}
				confirm={() => lockTenant(lockedUserId)}
				open={lockShowModal}
				calculationModal={true}
				newTitle={t("AreYouSureToLocked")}
				handleClose={() => setShowLockModal(false)}
			/>

			<Modal
				className="add_edit_user"
				open={showModal}
				handleClose={() => setShowModal(false)}
			>
				<FormikForm
					elements={clientElements}
					loading={loading}
					initialValues={tenantInitialValues}
					setShowModal={setShowModal}
					handleSubmit={addTenant}
					clientId={undefined}
					addText={t("CreateTenant")}
					editText={t("EditTenant")}
				/>
			</Modal>
			<div className="dashboard__container clients">
				<div className="header-component">
					<div className="dashboard__left__text-header">
						<div className="header-component-wrapper">
							<TenantsIcon />
							<span className="header-component-text">{t("Tenants")}</span>
						</div>
					</div>
					<div className="dashboard__left__text-actions">
						<Button
							borderRadius="8px"
							// eslint-disable-next-line max-len
							background="linear-gradient(94.98deg, rgb(108, 30, 176) 0%, rgb(64, 0, 119) 100%)"
							width="140px"
							height="36px"
							leftRounded={true}
							text={t("CreateTenant")}
							icon={plusLogo}
							handleChange={() => setShowModal(true)}
						/>

						<BigSearchBar
							value={search}
							handleSearch={handleSearch}
							withFilters={false}
							customClass="sales_tracker-searchBar"
						/>
					</div>
				</div>

				<div className="clientTable">
					<DataGridTable data={tenants} columns={[...clientColumnsRemote, actions]} />
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	optionsClients: state.app.client.index.optionsClients,
	client: state.app.client.create.clientInitialValues,
	clients: state.app.client.index.clients,
	clientInitialValues: state.app.client.create.clientInitialValues,

	// tenants
	tenants: state.app.tenants.index.tenants,
	size: state.app.tenants.index.size,
	totalSize: state.app.tenants.index.totalSize,
	loading: state.app.tenants.index.loading,
	page: state.app.tenants.index.page,
	search: state.app.tenants.index.search,
	tenantInitialValues: state.app.tenants.create.tenantInitialValues,
	showModal: state.app.tenants.create.showModal,

	deleteShowModal: state.app.tenants.delete.deleteShowModal,
	lockShowModal: state.app.tenants.locked.lockShowModal,
});

const mapDispatchToProps = {
	getTenants: tenantActions.getTenants,
	filterTenants: tenantActions.filterTenants,
	addTenant: createTenantActions.addTenant,
	fillTenant: createTenantActions.fillTenant,
	setShowModal: createTenantActions.setShowModal,
	editPage: tenantActions.editPage,
	editSize: tenantActions.editSize,

	addClient: createClientActions.addClient,
	deleteTenant: deleteTenantActions.deleteTenant,
	setShowDeleteModal: deleteTenantActions.setShowDeleteModal,
	lockTenant: lockTenantActions.lockTenant,
	setShowLockModal: lockTenantActions.setShowLockModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
