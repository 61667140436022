import DataGridTable from "@src/common/table/DataGridTable";
import React from "react";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";

const Table = ({
	columnsWithActions,
	selectedRows,
	setSelectedRows,
	filteredTableTabs,
	tableTab,
	tableRef,
	setActiveTab,
	setReEditPage,
	reEditPage,
	handlePreviewEdit,
	previewRow,
	showEditModal,
	editPage,
	editSize,
	setSelectedRowObjects,
	ordersState,
}) => {
	const {page, loading, totalSize, totalPages, size, orders} = ordersState;

	const {t} = useTranslation();
	return (
		<div className="loader__and__table-orderspage">
			<DataGridTable
				columns={columnsWithActions}
				data={orders}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				onSelectedRowsChange={setSelectedRows}
				editSize={editSize}
				editPage={editPage}
				loading={loading}
				withTabs
				customTabs={filteredTableTabs}
				currentTab={tableTab}
				tableRef={tableRef}
				onTabClick={setActiveTab}
				size={size}
				page={page}
				totalPages={totalPages}
				totalSize={totalSize}
				pagination
				setReEditPage={setReEditPage}
				reEditPage={reEditPage}
				previewRow={previewRow}
				showEditModal={showEditModal}
				onRowClick={(rowIndex, row, column) => {
					if (column?.key !== "actions" && column?.key !== "select-row") {
						handlePreviewEdit(row.orderId, "preview");
					} else if (column?.key === "select-row" && row.statusName === t("inProgress")) {
						setSelectedRows(new Set());
					}
				}}
				setSelectedRowObjects={setSelectedRowObjects}
				selectObject
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ordersState: state.app.orders.index,
	showEditModal: state.app.orders.edit.showModal,
});

const mapDispatchToProps = {
	editPage: orderActions.editPage,
	editSize: orderActions.editSize,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Table));
