import React from "react";
import {BackButton} from "@src/assets/SvgComponents";

const BackButtonComponents = ({onClick, className = ""}) => (
	<div onClick={onClick} className={className}>
		<BackButton />
	</div>
);

export default BackButtonComponents;
