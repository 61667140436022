import * as yup from "yup";
import i18n from "../../../i18n";

export const channelElements = {
	btnText: i18n.t("AddUsersToChannel"),
	btnEditText: i18n.t("EditChannel"),

	validationSchema: {
		add: yup.object().shape({
			users: yup.array().label("Users").required("LastNameIsReq"),
		}),
	},
	lists: {
		add: [
			{
				title: "Add User",
				type: "dropdown",
				field: "users",
				inputType: "text",
				name: "users",
				newClass: "userName__dropdown",
			},
		],
	},

	hasID: "users",
	modalTitle: "User",
};

export const userValidationSchema = yup.object().shape({
	users: yup.array().label("Users").required("LastNameIsReq"),
});
