import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const AndroidSwitch = styled(Switch)(({theme}) => ({
	padding: 7.5,
	"& .MuiSwitch-track": {
		borderRadius: 22 / 2,

		"&:before, &:after": {
			content: '""',
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			width: 18,
			height: 18,
			margin: 1,
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.3)",
		width: 18,
		height: 18,
		margin: 1,
	},
}));
