import {CopyIcon, DeleteRowIcon, EditRowIcon, SaveRowIcon} from "@src/assets/SvgComponents";
import HtmlTooltip from "@src/common/htmlTooltip";
import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";
import HeaderIcon from "./HeaderIcon";

const AccordionHeader = (props) => {
	const {
		title,
		teams,
		fromDate,
		toDate,
		onCopy,
		onEdit,
		onDelete,
		onUpdate,
		showSaveIcon,
		isExpired,
	} = props;
	const {t} = useTranslation();
	return (
		<div className="custom--header">
			<div className="custom--header-title">
				<span>{title}</span>
			</div>
			<div className="custom--header-teams">
				{teams?.slice(0, 3)?.map((t, index) => (
					<span key={index}>{`#${t}`}</span>
				))}
				{teams?.length > 3 && (
					<HtmlTooltip
						title={
							<div className="condition-team__tooltip">
								{teams?.map((t, index) => (
									<span key={index}>{`${index + 1}. #${t}`}</span>
								))}
							</div>
						}
						placement="bottom-start"
					>
						<span>...</span>
					</HtmlTooltip>
				)}
			</div>
			<div className="custom--header-dates">
				<span>{moment(fromDate).format("DD.MM.YYYY")}</span>
				{toDate && (
					<>
						<span>{t("Until")}</span>
						<span>{moment(toDate).format("DD.MM.YYYY")}</span>
					</>
				)}
			</div>
			<div className="custom--header-icons">
				<HeaderIcon title="Copy Table" onClick={onCopy}>
					<CopyIcon width="18" height="18" />
				</HeaderIcon>
				<HeaderIcon title="Edit Table" onClick={onEdit} isExpired={isExpired}>
					<EditRowIcon
						width="18"
						height="18"
						stroke={isExpired ? "#C1C1C1" : "#252525"}
					/>
				</HeaderIcon>
				<HeaderIcon title="Delete Table" onClick={onDelete}>
					<DeleteRowIcon width="18" height="18" />
				</HeaderIcon>
				<HeaderIcon title="Save Table" onClick={onUpdate} showIcon={showSaveIcon}>
					<SaveRowIcon />
				</HeaderIcon>
			</div>
		</div>
	);
};

export default AccordionHeader;
