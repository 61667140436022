import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as productActions} from "@sagas/products/clientProduct";
import {actions as productDeletedActions} from "@sagas/products/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Product>Restore");

const PREFIX = "@app/product/restore";
export const RESTORE = `${PREFIX}RESTORE`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const SET_LOADER = `${PREFIX}SET_LOADER`;

const _state = {
	showModal: false,
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			case SET_LOADER:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	restore: (payload) => createAction(RESTORE, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	setLoading: (payload) => createAction(SET_LOADER, {payload}),
};

export const sagas = {
	*restore({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {id} = payload;
			const response = yield axios.put(`/product/restore/${id}`);
			yield put(productDeletedActions.delete(id));
			yield put(productActions.addProductOnState(response?.data?.data));
			yield put(productActions.totalSizeIncrease());
			ToastSuccesComponent(i18n.t("ProductRestoreSuccess"));
			payload.handleProductModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(RESTORE, sagas.restore);
};
