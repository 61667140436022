import React from "react";
import {actions as createActions} from "@sagas/messages/create";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import Form from "@src/common/Form";
import {channelInputs} from "./helper";

const CreateChannel = ({show, close, createChannel, loading}) => {
	const {t} = useTranslation();
	const formData = {
		inputs: channelInputs,
		title: t("CreateChannel"),
		submitFn: (values, actions) => createChannel({values, formActions: actions, close}),
	};
	return <Form open={show} handleClose={close} loading={loading} formData={formData} />;
};

const mapStateToProps = (state) => ({
	loading: state.app.messages.create.channelLoading,
});

const mapDispatchToProps = {
	createChannel: createActions.createChannel,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateChannel));
