/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ToastErrorComponent, ToastSuccesComponent} from "@common/ToastComponent/ToastComponent";
import {actions as navigation} from "@sagas/navigation";
import moment from "moment";
// import {statusData} from "@src/components/dashboard/orders/staticData";
import _ from "lodash";
import i18n from "../../../../i18n";

const logger = new Logger("import create");

const PREFIX = "@app/imports/create";
export const PARSE_CSV = `${PREFIX}PARSE_CSV`;
export const IMPORT_CSV = `${PREFIX}IMPORT_CSV`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const PARSE_CSV_SUCCESS = `${PREFIX}PARSE_CSV_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const TOGGLE_VALUE = `${PREFIX}TOGGLE_VALUE`;
export const GET_ERRORS_DATA = `${PREFIX}GET_ERRORS`;
export const CLEAR_ERRORS_DATA = `${PREFIX}CLEAR_ERRORS_DATA`;
export const SET_CSV_DATA = `${PREFIX}SET_CSV_DATA`;
export const SET_ROWS_ELEM = `${PREFIX}SET_ROWS_ELEM`;
export const IS_ORDER_IMPORT = `${PREFIX}IS_ORDER_IMPORT`;
export const IMPORT_ORDERS = `${PREFIX}IMPORT_ORDERS`;
export const IS_STATUS_IMPORT = `${PREFIX}IS_STATUS_IMPORT`;
export const UPDATE_CSV_DATA = `${PREFIX}UPDATE_CSV_DATA`;
export const SET_PROCEED = `${PREFIX}SET_PROCEED`;
export const SET_METCHED_ORDERS = `${PREFIX}SET_METCHED_ORDERS`;
export const SET_IMPORT_CHANGE_STATUS = `${PREFIX}SET_IMPORT_CHANGE_STATUS`;
export const SET_SELECTED_ORDER_IDS = `${PREFIX}SET_SELECTED_ORDER_IDS`;
export const FILTER_MATCHED_ORDERS = `${PREFIX}FILTER_MATCHED_ORDERS`;
export const SET_STATUS_REQUIRED_FIELDS = `${PREFIX}SET_STATUS_REQUIRED_FIELDS`;
export const SET_IMPORTS_FILTERED_COLUMNS = `${PREFIX}SET_IMPORTS_FILTERED_COLUMNS`;
export const SET_IMPORTS_DATA = `${PREFIX}SET_IMPORTS_DATA`;
export const SET_ORDERS_DATA = `${PREFIX}SET_ORDERS_DATA`;
export const MATCH_ORDERS = `${PREFIX}MATCH_ORDERS`;
export const MATCH_ORDERS_SUCCESS = `${PREFIX}MATCH_ORDERS_SUCCESS`;
export const UNMATCH_ORDERS = `${PREFIX}UNMATCH_ORDERS`;
export const UNMATCH_ORDERS_SUCCESS = `${PREFIX}UNMATCH_ORDERS_SUCCESS`;
export const DELETE_IMPORTS_DATA = `${PREFIX}DELETE_IMPORTS_DATA`;
export const DELETE_IMPORTS_DATA_SUCCESS = `${PREFIX}DELETE_IMPORTS_DATA_SUCCESS`;
export const SET_ORDERS_FILTERED_COLUMNS = `${PREFIX}SET_ORDERS_FILTERED_COLUMNS`;
export const DRAFT_IMPORT = `${PREFIX}DRAFT_IMPORT`;
export const DRAFT_IMPORT_SUCCESS = `${PREFIX}DRAFT_IMPORT_SUCCESS`;
export const CHANGE_ROW_STATUS = `${PREFIX}CHANGE_ROW_STATUS`;
export const MATCH_ORDERS_ON_STATE = `${PREFIX}MATCH_ORDERS_ON_STATE`;
export const GET_DRAFTS = `${PREFIX}GET_DRAFTS`;
export const GET_DRAFTS_SUCCESS = `${PREFIX}GET_DRAFTS_SUCCESS`;
export const IMPORT_ADD_REMOVE_USER = `${PREFIX}IMPORT_ADD_REMOVE_USER`;
export const GET_DRAFT_BY_ID = `${PREFIX}GET_DRAFT_BY_ID`;
export const GET_DRAFT_BY_ID_SUCCESS = `${PREFIX}GET_DRAFT_BY_ID_SUCCESS`;
export const SET_UPDATE_ROWS = `${PREFIX}SET_UPDATE_ROWS`;
export const SET_COLUMNS_IMPORT = `${PREFIX}SET_COLUMNS_IMPORT`;
export const CLEAN_STATE = `${PREFIX}CLEAN_STATE`;
export const SET_QUERY = `${PREFIX}SET_QUERY`;
export const GET_ORDERS = `${PREFIX}GET_ORDERS`;
export const GET_ORDERS_SUCCESS = `${PREFIX}GET_ORDERS_SUCCESS`;
export const SET_ORDERS_LOADING = `${PREFIX}SET_ORDERS_LOADING`;
export const SET_SELECTED_FILE = `${PREFIX}SET_SELECTED_FILE`;
export const ACTIVE_USERS_ADD = `${PREFIX}ACTIVE_USERS_ADD`;
export const ACTIVE_USERS_REMOVE = `${PREFIX}ACTIVE_USERS_REMOVE`;
export const SET_OPTIONS = `${PREFIX}SET_OPTIONS`;
export const UPDATE_STATUS_IMPORT = `${PREFIX}UPDATE_STATUS_IMPORT`;
export const UPDATE_STATUS_IMPORT_SUCCESS = `${PREFIX}UPDATE_STATUS_IMPORT_SUCCESS`;
export const UNMATCH_ORDERS_ON_STATE = `${PREFIX}UNMATCH_ORDERS_ON_STATE`;
export const DELETE_ORDERS_ON_STATE = `${PREFIX}DELETE_ORDERS_ON_STATE`;
export const SET_CLIENT_ID = `${PREFIX}SET_CLIENT_ID`;

export function findFurthestAndNearestDates(data, key) {
	const dates = data.map((obj) => moment(obj?.[key], "DD.MM.YYYY"));
	const furthestDate = moment.min(dates);
	const nearestDate = moment.max(dates);
	return {
		fromDate: furthestDate?.startOf("day").valueOf(),
		toDate: nearestDate?.endOf("day").valueOf(),
	};
}
const _state = {
	csvData: {},
	clientId: null,
	projectId: null,
	userIds: [],
	showModal: false,
	loading: false,
	errorsData: [],
	rowsElem: [],
	importType: "",
	proceed: false,
	matchedOrders: [],
	importChangeStatus: null,
	selectedOrderIds: [],
	statusRequiredFields: [
		{name: i18n.t("dateOfContract"), value: "dateOfContract"},
		{name: i18n.t("Status"), value: "orderStatus"},
		{name: i18n.t("ClientOrderID"), value: "clientOrderId"},
	],
	matchingField: "clientOrderId",
	importsFilteredColumns: [],
	importsData: [],
	ordersData: [],
	ordersFilteredColumns: [],
	importDraftId: null,
	drafts: [],
	singleDraft: [],
	updateRows: true,
	columnsImport: [],
	isEditMode: false,
	query: {
		fromDate: null,
		toDate: null,
		clientId: null,
	},
	fileName: "",
	orders: [],
	ordersLoading: false,
	lastModified: null,
	importActiveUsers: [],
	options: [],
	clientIdName: null,
	importOrderViewDetails: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case ACTIVE_USERS_ADD:
				draft.importActiveUsers = action.payload;
				break;
			case ACTIVE_USERS_REMOVE:
				draft.importActiveUsers = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case PARSE_CSV_SUCCESS:
				draft.userIds = action?.payload?.userIds || null;
				draft.clientId = action.payload?.clientId || null;
				draft.projectId = action.payload?.projectId || null;
				draft.csvData = action.payload?.csvData || null;
				break;

			case SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			case GET_ERRORS_DATA:
				const arr = [];
				action?.payload?.map((item) => {
					arr.push(`${item.name} ${item.message}`);
					return arr;
				});
				draft.errorsData = arr;
				break;
			case CLEAR_ERRORS_DATA:
				draft.errorsData = [];

				break;
			case SET_CSV_DATA:
				draft.csvData = action.payload;
				break;
			case SET_ROWS_ELEM:
				draft.rowsElem = action.payload;
				break;
			case IS_ORDER_IMPORT:
				draft.importType = action.payload;
				break;
			case UPDATE_CSV_DATA:
				draft.csvData = {...state.csvData, columns: action?.payload};
				break;
			case SET_PROCEED:
				const allSameTrue = action.payload?.every((item) => item.same === true);
				draft.proceed = allSameTrue;
				break;
			case SET_METCHED_ORDERS:
				draft.matchedOrders = [...state.matchedOrders, action?.payload];
				break;
			case FILTER_MATCHED_ORDERS:
				draft.matchedOrders = state.matchedOrders?.filter(
					(order) =>
						order?.[state?.matchingField] !== action?.payload?.[state?.matchingField],
				);
				draft.selectedOrderIds = state.selectedOrderIds?.filter(
					(id) => id !== action?.payload?.[state.matchingField],
				);
				break;
			case SET_IMPORT_CHANGE_STATUS:
				draft.importChangeStatus = action.payload;
				break;
			case SET_SELECTED_ORDER_IDS:
				draft.selectedOrderIds = action.payload;
				break;
			case SET_STATUS_REQUIRED_FIELDS:
				draft.statusRequiredFields[draft.statusRequiredFields.length - 1] = action.payload;
				draft.matchingField = action.payload?.value;
				break;
			case SET_IMPORTS_FILTERED_COLUMNS:
				draft.importsFilteredColumns = action.payload;
				break;
			case SET_ORDERS_DATA:
				draft.ordersData = action.payload;
				break;
			case SET_IMPORTS_DATA:
				draft.importsData = action.payload;
				break;
			case MATCH_ORDERS_SUCCESS:
				const importsArr = [...state.importsData];
				const ordersArr = [...state.ordersData];
				const importArr = importsArr?.map((item) => {
					if (item?.[state?.matchingField] === action.payload.oldContract) {
						return {
							...item,
							matchedBy: "user",
							children: item.children?.map((it) => ({
								...it,
								same: true,
								oldContract: action.payload.oldContract,
								[state?.matchingField]: action.payload.newContract,
								ordID: +action.payload.id,
								orderStatus: item.orderStatus,
								matchedBy: "user",
							})),
							[state?.matchingField]: action.payload.newContract,
							same: true,
							ordID: +action.payload.id,
							oldContract: action.payload.oldContract,
							orderStatus: item.orderStatus,
						};
					}
					return item;
				});
				const orderArr = ordersArr.map((item) => {
					if (
						String(item?.[state?.matchingField]) === String(action.payload.newContract)
					) {
						const updatedItem = {
							...item,
							same: true,
							childId: action?.payload?.oldContract,
							newStatus: action?.payload?.newStatus,
						};
						draft.matchedOrders = [...state?.matchedOrders, updatedItem];
						return updatedItem;
					}
					return item;
				});
				draft.importsData = importArr;
				draft.ordersData = orderArr;
				draft.singleDraft = {
					...state?.singleDraft,
					data: importArr,
					clientId: action.payload.clientId,
					matchingField: action.payload.matchingField,
					dates: {
						fromDate: action?.payload?.dates?.fromDate,
						toDate: action?.payload?.dates?.toDate,
					},
				};
				draft.lastModified = {
					firstName: action.payload.user.firstName,
					lastName: action.payload.user.lastName,
					time: moment().valueOf(),
				};
				break;
			case UNMATCH_ORDERS_SUCCESS:
				const updatedImportArray = [...state.importsData];
				const updImportArray = updatedImportArray.map((row) => {
					if (
						row?.[state?.matchingField] === action.payload?.item?.[state?.matchingField]
					) {
						return {
							...row,
							same: false,
							[state?.matchingField]: row?.oldContract,
							children: row.children?.map((it) => ({
								...it,
								same: false,
								[state?.matchingField]: row?.oldContract,
							})),
						};
					}
					return row;
				});
				draft.ordersData = state?.ordersData.map((item) => {
					if (
						item?.[state?.matchingField] ===
						action?.payload?.item?.[state?.matchingField]
					) {
						draft.matchedOrders = state.matchedOrders?.filter(
							(order) =>
								order?.[state?.matchingField] !== item?.[state?.matchingField],
						);
						draft.selectedOrderIds = state.selectedOrderIds?.filter(
							(id) => id !== item?.[state.matchingField],
						);
						return {
							...item,
							same: false,
							newStatus: "",
						};
					}
					return item;
				});
				draft.lastModified = action.payload?.user;
				draft.importsData = updImportArray;
				draft.singleDraft = {
					...state?.singleDraft,
					data: updImportArray,
				};
				break;
			case DELETE_IMPORTS_DATA_SUCCESS:
				draft.importsData = state?.importsData.filter(
					(item) =>
						item?.[state?.matchingField] !==
						action?.payload?.item?.[state?.matchingField],
				);
				if (action?.payload?.item.matchedBy === "import") {
					draft.ordersData = state?.ordersData.map((item) => {
						if (
							item?.[state?.matchingField] ===
							action?.payload?.item?.[state?.matchingField]
						) {
							const option = {
								key: +item?.[state?.matchingField],
								value: item?.[state?.matchingField],
								ordID:
									action?.payload?.ordID ||
									+action.payload?.item?.[state?.matchingField],
							};
							draft.options = [option, ...state.options];
							return {
								...item,
								same: false,
							};
						}
						return item;
					});
				}
				draft.lastModified = action?.payload.user;
				break;
			case SET_ORDERS_FILTERED_COLUMNS:
				draft.ordersFilteredColumns = action?.payload;
				break;
			case CHANGE_ROW_STATUS:
				draft.importsData = state.importsData?.map((item) => {
					if (item?.[state?.matchingField] === action.payload.oldContract) {
						return {
							...item,
							children: item.children?.map((item) => ({
								...item,
								orderStatus: action?.payload?.status,
							})),
							orderStatus: action?.payload?.status,
						};
					}
					return item;
				});
				break;
			case DRAFT_IMPORT_SUCCESS:
				draft.importDraftId = action?.payload.id;
				draft.query = {
					...state.query,
					fromDate: action?.payload.dates.fromDate,
					toDate: action?.payload.dates.toDate,
				};
				break;
			case MATCH_ORDERS_ON_STATE:
				const filteredOptions = state.options.filter(
					(opt) => opt.value !== action.payload?.[state.matchingField],
				);
				draft.options = filteredOptions;
				draft.lastModified = action.payload.user;
				draft.importsData = state.importsData.map((item) => {
					if (item?.[state.matchingField] === action.payload.oldContract) {
						return {
							...item,
							same: true,
							[state.matchingField]: action.payload[state.matchingField],
							orderStatus: action.payload.orderStatus,
							ordID: action.payload?.ordID,
							oldContract: action.payload.oldContract,
							matchedBy: "user",
							children: item.children?.map((item) => ({
								...item,
								same: true,
								[state.matchingField]: action.payload[state.matchingField],
								orderStatus: action.payload.orderStatus,
								ordID: action.payload?.ordID,
								oldContract: action.payload.oldContract,
								matchedBy: "user",
							})),
						};
					}
					return item;
				});
				draft.ordersData = state.ordersData.map((item) => {
					if (
						String(item?.[state.matchingField]) ===
						String(action.payload[state.matchingField])
					) {
						return {
							...item,
							same: true,
							newStatus: action?.payload?.orderStatus,
						};
					}
					return item;
				});
				break;
			case GET_DRAFTS_SUCCESS:
				draft.drafts = action?.payload?.map((it) => ({
					...it,
					importOrderJsonData: JSON.parse(it?.importOrderJsonData),
				}));
				break;
			case GET_DRAFT_BY_ID_SUCCESS:
				const obj = JSON.parse(action?.payload?.importOrderJsonData);
				draft.lastModified = {
					firstName: action.payload?.updatedBy,
					time: action.payload?.updatedAt,
				};
				draft.singleDraft = obj;
				draft.matchingField = obj.matchingField;
				draft.importOrderViewDetails = action.payload.importOrderViewDetails || [];
				break;
			case SET_UPDATE_ROWS:
				draft.updateRows = action.payload;
				break;
			case SET_COLUMNS_IMPORT:
				draft.columnsImport = action.payload;
				break;
			case CLEAN_STATE:
				draft.importDraftId = null;
				draft.isEditMode = false;
				draft.singleDraft = [];
				draft.importsData = [];
				draft.rowsElem = [];
				draft.ordersData = [];
				draft.csvData = {};
				draft.importsFilteredColumns = [];
				draft.ordersFilteredColumns = [];
				draft.orders = [];
				draft.query = null;
				draft.fileName = "";
				draft.lastModified = null;
				draft.importActiveUsers = [];
				draft.options = [];
				draft.selectedOrderIds = [];
				draft.importType = "";
				break;
			case SET_QUERY:
				draft.query = action.payload;
				break;
			case GET_ORDERS_SUCCESS:
				draft.orders = action.payload;
				break;
			case SET_ORDERS_LOADING:
				draft.ordersLoading = action?.payload;
				break;
			case SET_SELECTED_FILE:
				draft.fileName = action?.payload;
				break;
			case SET_OPTIONS:
				draft.options = action?.payload;
				break;
			case UNMATCH_ORDERS_ON_STATE:
				draft.importsData = state?.importsData.map((row) => {
					if (row?.oldContract === action.payload?.[state?.matchingField]) {
						return {
							...row,
							same: false,
							[state?.matchingField]: row?.oldContract,
						};
					}
					return row;
				});
				draft.lastModified = action.payload.user;
				const ordArr = state?.ordersData.map((item) => {
					if (String(item?.orderId) === String(action?.payload?.ordID)) {
						draft.matchedOrders = state.matchedOrders?.filter(
							(order) =>
								order?.[state?.matchingField] !== item?.[state?.matchingField],
						);
						draft.selectedOrderIds = state.selectedOrderIds?.filter(
							(id) => id !== item?.[state.matchingField],
						);

						const option = {
							key: item?.[state?.matchingField],
							value: item?.[state?.matchingField],
							ordID: action?.payload?.ordID,
						};
						draft.options = [option, ...state.options];
						return {
							...item,
							same: false,
							newStatus: "",
						};
					}
					return item;
				});
				draft.ordersData = ordArr;
				break;
			case DELETE_ORDERS_ON_STATE:
				const filteredImportsData = state?.importsData.filter(
					(item) =>
						item?.[state?.matchingField] !== action?.payload?.[state?.matchingField],
				);
				if (action?.payload.matchedBy === "import") {
					draft.ordersData = state?.ordersData.map((item) => {
						if (
							item?.[state?.matchingField] === action?.payload?.[state?.matchingField]
						) {
							const option = {
								key: +item?.[state?.matchingField],
								value: item?.[state?.matchingField],
								ordID:
									action?.payload?.ordID ||
									+action.payload?.[state?.matchingField],
							};
							draft.options = [option, ...state.options];
							return {
								...item,
								same: false,
							};
						}
						return item;
					});
				}
				draft.importsData = filteredImportsData;
				draft.ordersData = filteredImportsData;
				draft.singleDraft = {
					...state.singleDraft,
					data: filteredImportsData,
				};
				draft.lastModified = action.payload.user;
				break;
			case SET_CLIENT_ID:
				draft.clientIdName = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	parseCsv: (payload) => createAction(PARSE_CSV, {payload}),
	importCsv: (payload) => createAction(IMPORT_CSV, {payload}),
	parseCsvSuccess: (payload) => createAction(PARSE_CSV_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	toggleValue: (payload) => createAction(TOGGLE_VALUE, {payload}),
	getErrorsData: (payload) => createAction(GET_ERRORS_DATA, {payload}),
	clearErrorsData: (payload) => createAction(CLEAR_ERRORS_DATA, {payload}),
	setCsvData: (payload) => createAction(SET_CSV_DATA, {payload}),
	setRowsElem: (payload) => createAction(SET_ROWS_ELEM, {payload}),
	setIsOrderImport: (payload) => createAction(IS_ORDER_IMPORT, {payload}),
	importOrders: (payload) => createAction(IMPORT_ORDERS, {payload}),
	updateCsvData: (payload) => createAction(UPDATE_CSV_DATA, {payload}),
	setProceed: (payload) => createAction(SET_PROCEED, {payload}),
	setMatchedOrders: (payload) => createAction(SET_METCHED_ORDERS, {payload}),
	setImportChangeStatus: (payload) => createAction(SET_IMPORT_CHANGE_STATUS, {payload}),
	setSelectedOrderIds: (payload) => createAction(SET_SELECTED_ORDER_IDS, {payload}),
	filterMatchedOrders: (payload) => createAction(FILTER_MATCHED_ORDERS, {payload}),
	setRequiredFields: (payload) => createAction(SET_STATUS_REQUIRED_FIELDS, {payload}),
	setImportsFilteredColumns: (payload) => createAction(SET_IMPORTS_FILTERED_COLUMNS, {payload}),
	setImportsData: (payload) => createAction(SET_IMPORTS_DATA, {payload}),
	setOrdersData: (payload) => createAction(SET_ORDERS_DATA, {payload}),
	matchOrders: (payload) => createAction(MATCH_ORDERS, {payload}),
	matchOrdersSuccess: (payload) => createAction(MATCH_ORDERS_SUCCESS, {payload}),
	unMatchOrders: (payload) => createAction(UNMATCH_ORDERS, {payload}),
	unMatchOrdersSuccess: (payload) => createAction(UNMATCH_ORDERS_SUCCESS, {payload}),
	deleteImportsData: (payload) => createAction(DELETE_IMPORTS_DATA, {payload}),
	deleteImportsDataSuccess: (payload) => createAction(DELETE_IMPORTS_DATA_SUCCESS, {payload}),
	setOrdersFilteredColumns: (payload) => createAction(SET_ORDERS_FILTERED_COLUMNS, {payload}),
	draftImport: (payload) => createAction(DRAFT_IMPORT, {payload}),
	draftImportSuccess: (payload) => createAction(DRAFT_IMPORT_SUCCESS, {payload}),
	changeRowStatus: (payload) => createAction(CHANGE_ROW_STATUS, {payload}),
	matchOrdersOnState: (payload) => createAction(MATCH_ORDERS_ON_STATE, {payload}),
	getDrafts: (payload) => createAction(GET_DRAFTS, {payload}),
	getDraftsSuccess: (payload) => createAction(GET_DRAFTS_SUCCESS, {payload}),
	importAddOrRemoveUser: (payload) => createAction(IMPORT_ADD_REMOVE_USER, {payload}),
	getDraftById: (payload) => createAction(GET_DRAFT_BY_ID, {payload}),
	getDraftByIdSuccess: (payload) => createAction(GET_DRAFT_BY_ID_SUCCESS, {payload}),
	setUpdateRows: (payload) => createAction(SET_UPDATE_ROWS, {payload}),
	setColumnsImport: (payload) => createAction(SET_COLUMNS_IMPORT, {payload}),
	cleanState: (payload) => createAction(CLEAN_STATE, {payload}),
	setQuery: (payload) => createAction(SET_QUERY, {payload}),
	getOrders: (payload) => createAction(GET_ORDERS, {payload}),
	getOrdersSuccess: (payload) => createAction(GET_ORDERS_SUCCESS, {payload}),
	setOrdersLoading: (payload) => createAction(SET_ORDERS_LOADING, {payload}),
	setSelectedFile: (payload) => createAction(SET_SELECTED_FILE, {payload}),
	importActiveUsersAdd: (payload) => createAction(ACTIVE_USERS_ADD, {payload}),
	importActiveUsersRemove: (payload) => createAction(ACTIVE_USERS_REMOVE, {payload}),
	setOptions: (payload) => createAction(SET_OPTIONS, {payload}),
	updateStatusImport: (payload) => createAction(UPDATE_STATUS_IMPORT, {payload}),
	updateStatusImportSuccess: (payload) => createAction(UPDATE_STATUS_IMPORT_SUCCESS, {payload}),
	unMatchOrdersOnState: (payload) => createAction(UNMATCH_ORDERS_ON_STATE, {payload}),
	deleteOrdersOnState: (payload) => createAction(DELETE_ORDERS_ON_STATE, {payload}),
	setClientId: (payload) => createAction(SET_CLIENT_ID, {payload}),
};

export const generateRows = (rowsElem, columns, matchingField, importChangeStatus) => {
	if (!rowsElem || !columns) {
		return [];
	}
	const rows = rowsElem.map((rowData) => {
		const row = {};
		columns.forEach((column) => {
			row[column.name] = rowData[column?.key];
		});
		const status = checkValidOrderStatus(row?.orderStatus, importChangeStatus);
		return {...row, orderStatus: status};
	});
	const groupedArr = _.chain(rows)
		.groupBy(matchingField)
		.map((value) => {
			const parent = value[0];
			return {
				...parent,
				...(value.length > 1 && {children: value.slice(1)}),
			};
		})
		.value();
	return groupedArr;
};

export const checkValidOrderStatus = (value, defaultStatus) => {
	const status = value;
	if (status) {
		return status.value;
	}
	return defaultStatus;
};

export const sagas = {
	*parseCsv({payload}) {
		yield put(actions.setLoading(true));
		try {
			const formData = new FormData();
			formData.append("csv", payload.uploadedCsv, payload?.fileName);
			const response = yield axios.post(
				`/csv/parse${payload?.params ? `?roles=${payload?.params}` : ""}`,
				formData,
			);
			yield put(
				actions.parseCsvSuccess({
					csvData: response.data.data,
					clientId: payload?.client && payload.client,
					projectId: payload?.projectId && payload.projectId,
					userIds: payload?.users && payload?.users.map((it) => it.value),
				}),
			);
			if (payload?.fromOrder) {
				yield put(actions.setIsOrderImport("fromOrder"));
				yield put(actions.setClientId(payload.clientId));
				yield put(navigation.navigate("/orders/import"));
			} else if (payload?.fromStatus) {
				yield put(actions.setIsOrderImport("fromStatus"));
				yield put(navigation.navigate("/admin/imported/previewCsv"));
			} else {
				yield put(navigation.navigate("/orders/import"));
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*importCsv({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {csvData, rowsElem, projectId, clientId, userIds} = yield select(
				(state) => state.app.imports.create,
			);
			const obj = {
				...csvData,
				data: rowsElem,
			};
			const response = yield axios.post(
				`/csv/import_leads${payload ? `?roles=${payload}` : ""}`,
				{
					importCsvData: obj,
					userIds,
					clientId,
					projectId,
				},
			);
			yield put(navigation.navigate("/admin/imports"));
			yield put(actions.parseCsvSuccess(response.data.data));
			ToastSuccesComponent(i18n.t("CsvUploadedSuccessfully"));
		} catch (error) {
			yield put(actions.getErrorsData(error?.response?.data.data));
			ToastErrorComponent(i18n.t("InvalidCsv"));
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*importOrders({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.post(`/import/orders/status`, payload);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(navigation.navigate("/admin/imports/change-order-status/tables"));
			yield put(actions.setLoading(false));
		}
	},
	*importAddOrRemoveUser({payload}) {
		try {
			yield axios.post(`/import-order/add-remove-user`, payload);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*draftImport({payload}) {
		yield put(actions.setLoading(true));
		const findDateKey = payload.jsonData.columns.find(
			(item) => item?.name === "dateOfContract",
		);
		const dates = findFurthestAndNearestDates(payload?.jsonData?.data, findDateKey?.key);
		const convertRows = generateRows(
			payload?.jsonData.data,
			payload?.jsonData.columns,
			payload.matchingField,
			payload.importChangeStatus,
		);
		const jsonData = JSON.stringify({
			columns: payload?.convertedColumns,
			data: convertRows,
			clientId: payload?.clientId,
			matchingField: payload.matchingField,
		});
		try {
			const response = yield axios.post(`/import-order/upload`, {
				jsonData,
				clientId: payload?.clientId,
				fileName: payload?.fileName,
				fromDate: dates.fromDate,
				toDate: dates.toDate,
			});
			yield put(actions.draftImportSuccess({id: response.data.data, dates}));
			yield put(
				navigation.navigate(
					// eslint-disable-next-line max-len
					`/admin/imports/change-order-status/tables?id=${response?.data?.data}&fromDate=${dates.fromDate}&toDate=${dates.toDate}&clientId=${payload?.clientId}&status=DRAFT`,
				),
			);
		} catch (error) {
			if (Number.isNaN(dates?.fromDate) || dates?.fromDate === null) {
				ToastErrorComponent(i18n.t("ValidDatesError"));
			}
			logger.error(error);
			yield put(actions.setLoading(false));
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*matchOrders({payload}) {
		try {
			yield put(
				actions.matchOrdersSuccess({
					oldContract: payload?.oldContract,
					newContract: payload?.newContract,
					clientId: payload?.clientId,
					matchingField: payload.matchingField,
					dates: payload?.dates,
					user: payload?.user,
					id: payload?.id,
					newStatus: payload?.orderStatus,
				}),
			);
			const {singleDraft, importsData} = yield select((state) => state.app.imports.create);
			const stringyObj = JSON.stringify({
				data: importsData,
				columns: singleDraft.columns,
				clientId: payload?.clientId,
				matchingField: payload.matchingField,
				dates: payload?.dates,
				user: {
					firstName: payload?.user?.firstName,
					lastName: payload?.user?.lastName,
					time: moment().valueOf(),
				},
			});
			const find = importsData.find(
				(item) => item?.[payload.matchingField] === payload?.newContract,
			);
			const findUpdated = {
				...find,
				oldContract: payload?.oldContract,
				type: "MATCH",
				newStatus: payload?.orderStatus,
				user: {
					firstName: payload?.user?.firstName,
					lastName: payload?.user?.lastName,
					time: moment().valueOf(),
				},
			};
			const stringyRow = JSON.stringify(findUpdated);
			yield axios.post(`/import-order/update`, {
				jsonData: stringyObj,
				rowJson: stringyRow,
				id: payload.draftId,
			});
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
	*getDrafts() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/import-order/all`);
			yield put(actions.getDraftsSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getDraftById({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/import-order/${payload}`);
			yield put(actions.getDraftByIdSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getOrders({payload}) {
		yield put(actions.setOrdersLoading(true));
		try {
			const response = yield axios.post(`/import-order/filter`, {
				...payload,
				showTariff: true,
				showProduct: true,
			});
			yield put(actions.getOrdersSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setOrdersLoading(false));
		}
	},
	*updateStatusImport({payload}) {
		yield put(actions.setLoading(true));
		const getEnglishTranslation = (key) => {
			const englishTranslations = i18n.getDataByLanguage("en");
			return englishTranslations?.translation[key] || key;
		};
		// fix this

		const getStatus = (status) => {
			switch (status) {
				case "CANCELLED_CUSTOMER":
				case "CANCELLED_PROVIDER":
				case "CANCELLED_DOUBLE":
				case "CANCELLED_INCORRECT_ORDER":
				case "CANCELLED_CREDIT_WORTH":
					return "CANCELLED";
				case "CLARIFICATION":
				case "BILLING_NEXT_MONTH":
					return "APPROVED";
				default:
					return status;
			}
		};
		const updateOrderStatusObjects = payload?.data?.map((it) => ({
			orderId: it?.ordID,
			status: getStatus(it?.orderStatus),
			statusName: it?.orderStatus,
		}));
		try {
			const response = yield axios.put("order/update-status-import", {
				updateOrderStatusObjects,
				importOrderId: payload?.draftId,
			});
			yield put(actions.updateStatusImportSuccess(response?.data?.data));
			ToastSuccesComponent(i18n.t("ImportPublished"));
			yield put(navigation.navigate("/admin/imports/change-order-status"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*unMatchOrders({payload}) {
		try {
			yield put(
				actions.unMatchOrdersSuccess({
					item: payload?.item,
					user: payload?.user,
				}),
			);
			const {singleDraft, importsData, matchingField} = yield select(
				(state) => state.app.imports.create,
			);
			const stringyObj = JSON.stringify(singleDraft);
			const find = importsData.find(
				(item) => item?.[matchingField] === payload?.item?.oldContract,
			);
			const findUpdated = {
				...find,
				type: "UNMATCH",
				user: {
					firstName: payload?.user?.firstName,
					lastName: payload?.user?.lastName,
					time: moment().valueOf(),
				},
			};
			const stringyRow = JSON.stringify(findUpdated);
			yield axios.post(`/import-order/update`, {
				jsonData: stringyObj,
				rowJson: stringyRow,
				id: payload.draftId,
			});
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
	*deleteImportsData({payload}) {
		try {
			yield put(
				actions.deleteImportsDataSuccess({
					item: payload?.item,
					user: payload?.user,
				}),
			);
			const {singleDraft, importsData, matchingField} = yield select(
				(state) => state.app.imports.create,
			);
			const obj = importsData.filter(
				(item) => item?.[matchingField] !== payload.item?.[matchingField],
			);
			const filteredImportsData = {
				...singleDraft,
				data: obj,
			};
			const stringyObj = JSON.stringify(filteredImportsData);
			const findUpdated = {
				...payload?.item,
				type: "DELETE",
				user: {
					firstName: payload?.user?.firstName,
					lastName: payload?.user?.lastName,
					time: moment().valueOf(),
				},
			};
			const stringyRow = JSON.stringify(findUpdated);
			yield axios.post(`/import-order/update`, {
				jsonData: stringyObj,
				rowJson: stringyRow,
				id: payload.draftId,
			});
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(PARSE_CSV, sagas.parseCsv);
	yield takeLatest(IMPORT_CSV, sagas.importCsv);
	yield takeLatest(IMPORT_ORDERS, sagas.importOrders);
	yield takeLatest(DRAFT_IMPORT, sagas.draftImport);
	yield takeLatest(MATCH_ORDERS, sagas.matchOrders);
	yield takeLatest(GET_DRAFTS, sagas.getDrafts);
	yield takeLatest(IMPORT_ADD_REMOVE_USER, sagas.importAddOrRemoveUser);
	yield takeLatest(GET_DRAFT_BY_ID, sagas.getDraftById);
	yield takeLatest(GET_ORDERS, sagas.getOrders);
	yield takeLatest(UPDATE_STATUS_IMPORT, sagas.updateStatusImport);
	yield takeLatest(UNMATCH_ORDERS, sagas.unMatchOrders);
	yield takeLatest(DELETE_IMPORTS_DATA, sagas.deleteImportsData);
};
