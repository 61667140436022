import {UserIcon} from "@src/assets/SvgComponents";
import HtmlTooltip from "@src/common/htmlTooltip";
import {Tooltip} from "antd";
import React from "react";

function Userbox({current, user, minWidth = "160px"}) {
	return (
		<div style={{minWidth}} className={`userBoxContanier${current ? " current" : ""}`}>
			<div className={`iconBox${current ? " current" : ""}`}>
				<UserIcon stroke={current ? "var(--color-primary)" : "#979797"} />{" "}
			</div>
			<div className="nameBox">
				<HtmlTooltip
					title={<span>{user}</span>}
					border="1px solid #e8e8e8"
					paddingLeft="10px"
					paddingRight="10px"
					paddingTop="5px"
					paddingBottom="5px"
					placement="bottom"
					disableHoverListener={false}
					fontWeight="600"
					color="#ffff"
					fontSize="12px"
					backgroundColor="#C1C1C1"
				>
					<span style={{color: current && "var(--color-primary)"}} className="user">
						{user}
					</span>
				</HtmlTooltip>
			</div>
		</div>
	);
}

export default Userbox;
