/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import produce from "immer";
import _ from "lodash";
import {put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import axios from "axios";
import {ENDPOINT} from "@src/utils/endpoint";
import Logger from "@utils/logger";
import moment from "moment";
import createAction from "../../../../utils/action-creator";

const logger = new Logger("Momitoring index");
const PREFIX = "@app/monitoring/index";

const SET_LOADING = `${PREFIX}SET_LOADING`;
const GET_MONITORING_DATA = `${PREFIX}GET_MONITORING_DATA`;
const GET_MONITORING_DATA_SUCCESS = `${PREFIX}GET_MONITORING_DATA_SUCCESS`;

const _state = {
	monitorings: [],
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case GET_MONITORING_DATA_SUCCESS:
				draft.monitorings = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getMonitoringData: (payload) => createAction(GET_MONITORING_DATA, {payload}),
	getMonitoringDataSuccess: (payload) => createAction(GET_MONITORING_DATA_SUCCESS, {payload}),
};

export const sagas = {
	*getMonitoringData({payload}) {
		yield put(actions.setLoading(true));
		try {
			if (payload.type) {
				const from = moment().startOf("month").utcOffset(0, true).valueOf();
				const to = moment().endOf("month").valueOf();
				const {data} = yield axios.post(
					ENDPOINT.GET_MONITORING_DATA.replace(":type", payload.type),
					{
						from,
						to,
						minPoints: payload?.minPoints,
						maxPoints: payload?.maxPoints,
					},
				);
				yield put(actions.getMonitoringDataSuccess(data.data));
			} else {
				yield put(actions.getMonitoringDataSuccess([]));
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_MONITORING_DATA, sagas.getMonitoringData);
};
