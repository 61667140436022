import React from "react";
import ClientCustomUpdate from "./ClientCustomUpdate";
import i18n from "../../../../i18n";

export const clientColumnsRemote = {
	category: [
		{
			title: i18n.t("projectCategories"),
			field: "name",
		},
	],
	locations: [{title: i18n.t("Locations"), field: "name"}],
	product: [
		{
			title: i18n.t("productName"),
			field: "name",
		},
		{
			title: i18n.t("lastUpdate"),
			field: "updatedAt",
			render: (row) => (
				<ClientCustomUpdate
					row={row}
					link="client"
					navLink={`${row.firstName} ${row.lastName}`}
				/>
			),
		},
	],
	tariff: [
		{
			title: i18n.t("TariffName"),
			field: "name",
		},
	],
	comission: [
		{
			title: i18n.t("productName"),
			field: "productName",
		},
		{
			title: i18n.t("TariffName"),
			field: "tariffName",
		},
	],
	bonus: [
		{
			title: i18n.t("NumOfContracts"),
			field: "contratsNr",
		},
		{
			title: i18n.t("Value"),
			field: "value",
		},
	],
};

export const clientData = [
	{
		id: 4,
		label: i18n.t("Locations"),
		key: "locations",
	},
	{
		id: 1,
		label: i18n.t("projectCategories"),
		key: "category",
	},
	{
		id: 2,
		label: i18n.t("Tariff"),
		key: "tariff",
	},
	{
		id: 3,
		label: i18n.t("products"),
		key: "product",
	},
	{
		id: 5,
		label: i18n.t("clientSettings"),
		key: "settings",
	},
	{
		id: 6,
		label: i18n.t("statisticAndDocuments"),
		key: "statisticAndDocuments",
	},
	{
		id: 7,
		label: i18n.t("uploadPdf"),
		key: "uploadPdf",
	},
	{
		id: 8,
		label: i18n.t("orderForm"),
		key: "orderForm",
	},
];
