import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import i18n from "@src/i18n";
import {ENDPOINT} from "@src/utils/endpoint";
import {initialValues} from "./utils";
const logger = new Logger("teams client");
const PREFIX = "@app/teamConditions/index";

export const ADD_TABLE_ON_STATE = `${PREFIX}ADD_TABLE_ON_STATE`;
export const GET_CONDITIONS = `${PREFIX}GET_CONDITIONS`;
export const GET_CONDITIONS_SUCCESS = `${PREFIX}GET_CONDITIONS_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_ACTIVE_TAB = `${PREFIX}SET_ACTIVE_TAB`;
export const SET_TABLE_VALUES = `${PREFIX}SET_TABLE_VALUES`;
export const DUPLICATE_TABLE = `${PREFIX}DUPLICATE_TABLE`;
export const CLEAR_TABLE_VALUES = `${PREFIX}CLEAR_TABLE_VALUES`;
export const REMOVE_TABLE_FROM_STATE = `${PREFIX}REMOVE_TABLE_FROM_STATE`;
export const UPDATE_TABLE_ON_STATE = `${PREFIX}UPDATE_TABLE_ON_STATE`;
export const FILL_TABLE = `${PREFIX}FILL_TABLE`;

const _state = {
	conditionTables: [],
	initialValues: {},
	loading: false,
	activeTab: {title: i18n.t("Overhead"), type: "overhead"},
	tableToUpdate: {},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case ADD_TABLE_ON_STATE:
				draft.conditionTables = [...(state.conditionTables || []), payload];
				break;
			case GET_CONDITIONS_SUCCESS:
				draft.conditionTables = payload || [];
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_ACTIVE_TAB:
				draft.activeTab = payload;
				break;
			case SET_TABLE_VALUES:
				if (payload?.id) {
					const foundedTable = state.conditionTables.find((it) => it?.id === payload?.id);
					draft.initialValues = {
						...foundedTable,
						...(foundedTable?.structure && {structureId: foundedTable.structure.id}),
						...(foundedTable?.stufe && {stufeId: foundedTable.stufe.id}),
						id: payload.type === "duplicate" ? null : foundedTable.id,
					};
				} else {
					const type = payload?.conditionType;
					draft.initialValues = initialValues[type] || {};
				}
				break;
			case DUPLICATE_TABLE:
				draft.conditionTables = [...state?.conditionTables, payload];
				break;
			case CLEAR_TABLE_VALUES:
				draft.initialValues = {};
				break;
			case REMOVE_TABLE_FROM_STATE:
				const filteredTables = state.conditionTables.filter((item) => item?.id !== payload);
				draft.conditionTables = filteredTables;
				break;
			case UPDATE_TABLE_ON_STATE:
				const copiedState = [...state.conditionTables];
				const {data, type} = payload;
				const tableIdx = copiedState.findIndex((item) => item.id === data?.id);
				const updatedTable = data;
				if (type === "career_conditions") {
					updatedTable.rows = copiedState[tableIdx]?.rows || [];
				}
				copiedState[tableIdx] = updatedTable;
				draft.conditionTables = copiedState;
				draft.tableToUpdate = {};
				break;
			case FILL_TABLE:
				draft.tableToUpdate = payload;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	addTableOnState: (payload) => createAction(ADD_TABLE_ON_STATE, {payload}),
	getConditions: (payload) => createAction(GET_CONDITIONS, {payload}),
	getConditionsSuccess: (payload) => createAction(GET_CONDITIONS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setActiveTab: (payload) => createAction(SET_ACTIVE_TAB, {payload}),
	setTableValues: (payload) => createAction(SET_TABLE_VALUES, {payload}),
	duplicateTable: (payload) => createAction(DUPLICATE_TABLE, {payload}),
	clearTableValues: (payload) => createAction(CLEAR_TABLE_VALUES, {payload}),
	removeTableFromState: (payload) => createAction(REMOVE_TABLE_FROM_STATE, {payload}),
	updateTableOnState: (payload) => createAction(UPDATE_TABLE_ON_STATE, {payload}),
	fillTable: (payload) => createAction(FILL_TABLE, {payload}),
};

export const sagas = {
	*getConditions({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(ENDPOINT.GET_CONDITIONS.replace(":type", payload));
			yield put(actions.getConditionsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
			yield put(actions.getConditionsSuccess([]));
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_CONDITIONS, sagas.getConditions);
};
