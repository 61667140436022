import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ENDPOINT} from "@src/utils/endpoint";
import _ from "lodash";
import {groupItemsByDates, updateArrayBasedOnType} from "./helper";

const logger = new Logger("Sagas>tariffsByLocation>History");

const PREFIX = "@app/tariffsByLocation/history";

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_TARIFF_HISTORY = `${PREFIX}FETCH_TARIFF_HISTORY`;
export const FETCH_TARIFF_HISTORY_SUCCESS = `${PREFIX}FETCH_TARIFF_HISTORY_SUCCESS`;
export const CLEAR_TARIFF_HISTORY = `${PREFIX}CLEAR_TARIFF_HISTORY`;
export const CREATE_TARIFF_HISTORY = `${PREFIX}CREATE_TARIFF_HISTORY`;
export const EDIT_TARIFF_HISTORY_SUCCESS = `${PREFIX}EDIT_TARIFF_HISTORY_SUCCESS`;
export const DELETE_TARIFF_HISTORY = `${PREFIX}DELETE_TARIFF_HISTORY`;
export const SET_MODAL_LOADING = `${PREFIX}SET_MODAL_LOADING`;
const _state = {
	loading: false,
	tariffHistories: [],
	modalLoading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case FETCH_TARIFF_HISTORY_SUCCESS:
				draft.tariffHistories = payload;
				break;
			case CLEAR_TARIFF_HISTORY:
				draft.tariffHistories = [];
				break;
			case EDIT_TARIFF_HISTORY_SUCCESS:
				const {values, type} = payload;
				const flatenedArray = state.tariffHistories.flatMap((h) => h.data);
				const newArr = updateArrayBasedOnType(flatenedArray, type, values);
				draft.tariffHistories = groupItemsByDates(newArr);
				break;
			case SET_MODAL_LOADING:
				draft.modalLoading = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchTariffHistory: (payload) => createAction(FETCH_TARIFF_HISTORY, {payload}),
	fetchTariffHistorySuccess: (payload) => createAction(FETCH_TARIFF_HISTORY_SUCCESS, {payload}),
	clearTariffHistory: (payload) => createAction(CLEAR_TARIFF_HISTORY, {payload}),
	createTariffHistory: (payload) => createAction(CREATE_TARIFF_HISTORY, {payload}),
	editTariffHistorySuccess: (payload) => createAction(EDIT_TARIFF_HISTORY_SUCCESS, {payload}),
	deleteTariffHistory: (payload) => createAction(DELETE_TARIFF_HISTORY, {payload}),
	setModalLoading: (payload) => createAction(SET_MODAL_LOADING, {payload}),
};

export const sagas = {
	*fetchTariffHistory({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {data} = yield axios.get(ENDPOINT.FETCH_TARIFF_HISTORY.replace(":id", payload));
			yield put(actions.fetchTariffHistorySuccess(data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*createTariffHistory({payload}) {
		yield put(actions.setModalLoading(true));
		try {
			const {values, setModal} = payload;
			const endpoint = values?.id
				? ENDPOINT.UPDATE_TARIFF_HISTORY
				: ENDPOINT.CREATE_TARIFF_HISTORY;
			const {data} = yield axios.post(endpoint, values);
			if (values.id) {
				yield put(actions.editTariffHistorySuccess({values, type: "edit"}));
			} else {
				yield put(
					actions.editTariffHistorySuccess({
						values: {
							...values,
							id: data?.data,
						},
						type: "add",
					}),
				);
			}
			setModal(false);
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setModalLoading(false));
		}
	},
	*deleteTariffHistory({payload}) {
		yield put(actions.setModalLoading(true));
		try {
			const {id, setModal} = payload;
			yield axios.put(ENDPOINT.DELETE_TARIFF_HISTORY.replace(":id", id));
			yield put(actions.editTariffHistorySuccess({values: id, type: "delete"}));
			setModal(false);
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setModalLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_TARIFF_HISTORY, sagas.fetchTariffHistory);
	yield takeLatest(CREATE_TARIFF_HISTORY, sagas.createTariffHistory);
	yield takeLatest(DELETE_TARIFF_HISTORY, sagas.deleteTariffHistory);
};
