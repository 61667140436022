import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Sagas>Tariffs>Index");

const PREFIX = "@app/tariffs/index";
export const GET_TARIFFS = `${PREFIX}GET_TARIFFS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_TARIFFS_SUCCESS = `${PREFIX}FETCH_TARIFFS_SUCCESS`;
export const ADD_TARIFF_ON_STATE = `${PREFIX}ADD_TARIFF_ON_STATE`;
export const EDIT_TARIFF_ON_STATE = `${PREFIX}EDIT_TARIFF_ON_STATE`;
export const DELETE_TARIFF_ON_STATE = `${PREFIX}DELETE_TARIFF_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const FETCH_TARIFFS_BY_DROPDOWN = `${PREFIX}FETCH_TARIFFS_BY_DROPDOWN`;
export const FETCH_TARIFFS_BY_DROPDOWN_SUCCESS = `${PREFIX}FETCH_TARIFFS_BY_DROPDOWN_SUCCESS`;
export const FETCH_TARIFFS_BY_LOCATION_ID = `${PREFIX}FETCH_TARIFFS_BY_LOCATION_ID`;
export const FETCH_TARIFFS_BY_LOCATION_ID_SUCCESS = `${PREFIX}FETCH_TARIFFS_BY_LOCATION_ID_SUCCESS`;

const _state = {
	list: [],
	optionsTariffs: [],
	loading: false,
	page: 1,
	size: 10,
	totalSize: 5,
	search: "",
	tariffByLocations: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = action.payload.value;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case EDIT_PAGE:
				draft.page = action.payload + 1;
				break;
			case FETCH_TARIFFS_SUCCESS:
				draft.list = action.payload;

				break;
			case FETCH_TARIFFS_BY_DROPDOWN_SUCCESS:
				draft.optionsTariffs = action.payload?.map((e) => ({
					name: e.name,
					value: e.id,
					commissionProducts: e.commissionProducts,
					label: e.name,
				}));
				break;
			case SIZE_EDIT:
				draft.size = action.payload;

				break;
			case ADD_TARIFF_ON_STATE:
				draft.list = [action.payload].concat(state.list);
				break;
			case EDIT_TARIFF_ON_STATE:
				const index = state.list.findIndex((c) => c.id === action.payload.id);
				draft.list[index] = action.payload;
				break;
			case DELETE_TARIFF_ON_STATE:
				draft.list = state.list.filter((tariff) => tariff.id !== action.payload);
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case FETCH_TARIFFS_BY_LOCATION_ID_SUCCESS:
				draft.tariffByLocations = action.payload.map((item) => ({
					label: item.name,
					value: item.id,
				}));
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	getTarrifs: (payload) => createAction(GET_TARIFFS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchTarrifsSuccess: (payload) => createAction(FETCH_TARIFFS_SUCCESS, {payload}),
	addTarrifOnState: (payload) => createAction(ADD_TARIFF_ON_STATE, {payload}),
	editTarrifOnState: (payload) => createAction(EDIT_TARIFF_ON_STATE, {payload}),
	deleteTarrifOnState: (payload) => createAction(DELETE_TARIFF_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterTariffs: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	fetchTariffsByDropdown: (payload) => createAction(FETCH_TARIFFS_BY_DROPDOWN, {payload}),
	fetchTariffsByDropdownSuccess: (payload) =>
		createAction(FETCH_TARIFFS_BY_DROPDOWN_SUCCESS, {payload}),
	fetchTariffsByLocationId: (payload) => createAction(FETCH_TARIFFS_BY_LOCATION_ID, {payload}),
	fetchTariffsByLocationIdSuccess: (payload) =>
		createAction(FETCH_TARIFFS_BY_LOCATION_ID_SUCCESS, {payload}),
};

export const sagas = {
	*getTarrifs() {
		yield put(actions.setLoading(true));
		const {size} = yield select((state) => state.app.tariffs.index);
		const {search} = yield select((state) => state.app.tariffs.index);
		const {page} = yield select((state) => state.app.tariffs.index);
		try {
			const response = yield axios.get(
				`/tariff/all?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchTarrifsSuccess(response?.data?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchTariffsByDropdown(id) {
		yield put(actions.setLoading(true));
		try {
			let response;

			if (id.payload !== undefined) {
				response = yield axios.get(`/tariff/drop-down/${id.payload}`);
			} else {
				response = yield axios.get(`/tariff/drop-down/`);
			}
			yield put(actions.fetchTariffsByDropdownSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchTariffsByLocationId({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/location/${payload}/tariffs`);
			yield put(actions.fetchTariffsByLocationIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_TARIFFS, sagas.getTarrifs);
	yield takeLatest(FETCH_TARIFFS_BY_DROPDOWN, sagas.fetchTariffsByDropdown);
	yield takeLatest(FETCH_TARIFFS_BY_LOCATION_ID, sagas.fetchTariffsByLocationId);
};
