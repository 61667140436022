import {PlusCircleIcon} from "@src/assets/SvgComponents";
import React, {useEffect} from "react";
import DataGrid from "react-data-grid";
import {useTranslation} from "react-i18next";

const StructureTable = ({
	columns,
	rows,
	id,
	setModal,
	addRow = "stufe",
	fetchOnAddRow,
	tableId,
	structureId,
}) => {
	const {t} = useTranslation();
	useEffect(() => {
		if (structureId && fetchOnAddRow && structureId !== 0) {
			fetchOnAddRow(structureId);
		}
	}, [fetchOnAddRow]);

	const handleAddRow = () => {
		setModal(addRow, "", [{type: "add"}, {tableId: `${id}`}, {structureId: `${structureId}`}]);
		if (fetchOnAddRow && structureId) {
			fetchOnAddRow(structureId);
		}
	};
	return (
		<>
			<DataGrid
				className="rdg-light"
				columns={columns}
				rows={rows}
				style={{height: rows?.length * 44 + 52}}
				rowHeight={44}
				headerRowHeight={50}
			/>
			<div className="add__new__row" onClick={handleAddRow}>
				<div className="add__new__row-content">
					<span>
						<PlusCircleIcon />
					</span>
					<span>{t("addRow")}</span>
				</div>
			</div>
		</>
	);
};

export default StructureTable;
