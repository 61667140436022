import React from "react";
import i18n from "@src/i18n";
import {FORMAT_DATE} from "@src/common/constants";
import StatusBox from "@src/common/components/status-box";

export const importsColumns = [
	{
		name: i18n.t("Name"),
		key: "name",
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("DateCreated"),
		key: "createdAt",
		sortable: true,
		formatter: ({row}) => FORMAT_DATE(row.createdAt),
		resizable: true,
	},
	{
		name: i18n.t("ImportedFrom"),
		key: "uploadedBy",
		sortable: true,
		resizable: true,
	},
	{
		name: i18n.t("Status"),
		key: "status",
		sortable: true,
		resizable: true,
		formatter: ({row}) => <StatusBox status={i18n.t(row.status)} />,
	},
];
