import {CopyIcon, DeleteRowIcon, EditRowIcon} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import React from "react";

const alignmentEnums = [
	{label: "Right", value: "RIGHT"},
	{label: "Left", value: "LEFT"},
	{label: "Center", value: "CENTER"},
];
export const editFieldInputs = [
	{
		field: "fontSize",
		title: i18n.t("fontSize"),
		inputType: "number",
		required: true,
		showField: "editFont",
	},
	{
		field: "width",
		title: i18n.t("width"),
		inputType: "number",
		required: true,
		showField: "editWidth",
	},
	{
		field: "height",
		title: i18n.t("height"),
		inputType: "number",
		required: true,
		showField: "editHeight",
	},
	{
		field: "x",
		title: i18n.t("horizontal"),
		inputType: "number",
		required: true,
		showField: "editX",
	},
	{
		field: "y",
		title: i18n.t("vertical"),
		inputType: "number",
		required: true,
		showField: "editY",
	},
	{
		field: "alignment",
		title: i18n.t("alignment"),
		inputType: "dropdown",
		options: alignmentEnums,
		required: true,
		showField: "editAlign",
	},
];

const iconProps = {
	stroke: "#252525",
	width: "18",
	height: "18",
};
export const multipleItems = [
	{
		key: "editWidth",
		label: i18n.t("editWidth"),
		icon: <EditRowIcon {...iconProps} />,
	},
	{
		key: "editX",
		label: i18n.t("editX"),
		icon: <EditRowIcon {...iconProps} />,
	},
	{
		key: "editY",
		label: i18n.t("editY"),
		icon: <EditRowIcon {...iconProps} />,
	},
	{
		key: "editAlign",
		label: i18n.t("editAlign"),
		icon: <EditRowIcon {...iconProps} />,
	},
];

export const singleItems = [
	{
		key: "clone",
		label: i18n.t("cloneField"),
		icon: <CopyIcon {...iconProps} />,
	},
	{
		key: "editField",
		label: i18n.t("editField"),
		icon: <EditRowIcon {...iconProps} />,
	},
	{
		key: "orderField",
		label: i18n.t("editOrderField"),
		icon: <EditRowIcon {...iconProps} />,
	},
	{
		key: "delete",
		label: i18n.t("removeField"),
		icon: <DeleteRowIcon {...iconProps} />,
	},
];

export const isInsideParent = (file, item) => {
	const dropTarget = file.current.getBoundingClientRect();
	const {x, y, width, height} = item;

	return x >= 0 && x <= dropTarget.width - width && y >= 0 && y <= dropTarget.height - height;
};

export const checkLeftDimensions = (fileRef, item, left, top) => {
	const dropTarget = fileRef.current.getBoundingClientRect();
	const newItem = {...item, cloneField: true, parentId: item.id, x: left + 20, y: top + 20};
	const isEnoughWidthLeft = newItem.x <= dropTarget.width - newItem.width;
	const isEnoughHeightLeft = newItem.y <= dropTarget.height - newItem.height;
	if (!isEnoughWidthLeft) {
		newItem.x = left - 20;
	}
	if (!isEnoughHeightLeft) {
		newItem.y = top - 20;
	}
	return newItem;
};
