import DataGridTable from "@src/common/table/DataGridTable";
import {Collapse} from "antd";
import React, {useState} from "react";
import Loader from "@src/common/Loader";
import {renderClosedHeader, renderOpenedHeader} from "./helper";

const {Panel} = Collapse;
function CustomCollapse({
	index,
	item,
	setActiveKey,
	activeKey,
	groupViewType,
	state,
	editPage,
	editSize,
	resetPaginationType,
	title = "totalGoals",
	actions,
	columns,
	tableColumns,
	name = "name",
	mainTitle = item.name,
	borderLess = false,
	subTitle,
}) {
	const {data, page, size, totalPages, totalSize, loading} = state;
	const [reEditPage, setReEditPage] = useState(true);

	const renderPanel = () => {
		const getRowClassName = () => "gray-row";
		return (
			<div className="panel-wrapper">
				<div
					style={{background: item?.color}}
					className={`color ${borderLess && "hide"}`}
				/>
				<DataGridTable
					style={{
						height: data?.[0] ? 400 + (loading ? 40 : 0) : 200,
					}}
					wrapperStyles={{
						height: data?.[0] ? 440 : 200,
					}}
					rows={data || []}
					data={data || []}
					columns={[
						...(tableColumns || columns.filter((item) => item.hide !== groupViewType)),
						actions,
					]}
					rowClass={getRowClassName}
					size={size}
					page={page}
					totalPages={totalPages}
					totalSize={totalSize}
					pagination
					reEditPage={reEditPage}
					setReEditPage={setReEditPage}
					editSize={editSize}
					editPage={editPage}
					loading={page > 1 && loading}
					scrollDivBackground="#f9f9f9"
				/>
			</div>
		);
	};
	const handleGroupClick = (active) => {
		if (item?.[title] > 0) {
			resetPaginationType();
			setActiveKey(active);
			setReEditPage(false);
		}
	};
	return (
		<Collapse
			onChange={() => handleGroupClick(activeKey === index ? null : index)}
			className={`custom-collapse ${activeKey === index ? "opened" : ""}`}
			collapsible="header"
			accordion
			activeKey={activeKey}
			destroyInactivePanel
		>
			<Panel
				key={index}
				showArrow={false}
				className="custom-panel"
				header={
					activeKey !== index
						? renderClosedHeader(
								item,
								borderLess,
								title,
								mainTitle,
								columns,
								groupViewType,
								name,
								subTitle,
						  )
						: renderOpenedHeader(item, activeKey, index, mainTitle, subTitle)
				}
			>
				{page === 1 && loading ? <Loader /> : renderPanel()}
			</Panel>
		</Collapse>
	);
}

export default CustomCollapse;
