import moment from "moment";
import * as yup from "yup";
import i18n from "@src/i18n";

export const validationSchema = yup.object().shape({
	type: yup.string().required("Type is required"),
	publishTime: yup.string().when(["type"], {
		is: (type) => type === "immediately",
		then: yup.string().optional().nullable(),
		otherwise: yup.string().required(i18n.t("DateIsReq")).nullable(),
	}),
});

export const disabledDate = (current) => current < moment().startOf("day");
const range = (start, end) => {
	const comparingArr = [];
	const result = [];
	for (let i = 0; i < end; i++) {
		comparingArr.push(i);
	}
	for (let i = start; i < end; i++) {
		result.push(i);
	}
	return comparingArr?.filter((h) => !result?.includes(h));
};
export const disabledDateTime = (current) => {
	const currDate = moment(current).isAfter(moment(), "hour");
	const hour = currDate ? 0 : moment().hour();
	const minute = currDate ? 0 : moment().minute() + 1;
	return {
		disabledHours: () => range(hour, 24),
		disabledMinutes: () => range(minute, 60),
	};
};
export const svgToDataUri = (svgString) =>
	new Promise((resolve, reject) => {
		const img = new window.Image();
		const svgBlob = new Blob([svgString], {type: "image/svg+xml"});
		const url = URL.createObjectURL(svgBlob);

		img.onload = () => {
			const canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;

			const context = canvas.getContext("2d");
			context.drawImage(img, 0, 0);

			const dataUri = canvas.toDataURL("image/png");
			URL.revokeObjectURL(url);
			resolve(dataUri);
		};

		img.onerror = (error) => {
			URL.revokeObjectURL(url);
			reject(error);
		};

		img.src = url;
	});
export const fetchSvgContent = async (url) => {
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`Failed to fetch SVG: ${response.statusText}`);
		}
		const svgContent = await response.text();
		return svgContent;
	} catch (error) {
		return null;
	}
};
