import React, {useEffect} from "react";
import {actions as clientActions} from "@sagas/client";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import Form from "@src/common/Form";
import {actions as tariffActions} from "@sagas/tariff/index";
import {pdfInputs} from "./staticData";

const AssignModal = (props) => {
	const {
		showModal,
		setShowModal,
		tariffs,
		match,
		fetchTariffsByDropdown,
		saveGenericPdf,
		loading,
	} = props;
	const {id, clientId} = match.params;
	const {t} = useTranslation();
	useEffect(() => {
		if (showModal) {
			fetchTariffsByDropdown(clientId);
		}
	}, [showModal]);
	const handleClose = () => {
		setShowModal(false);
	};
	const inputs = pdfInputs.map((item) => {
		if (item.field === "tariffId") {
			return {
				...item,
				options: tariffs,
			};
		}
		return item;
	});
	const formData = {
		inputs,
		title: t("pdfSettings"),
		getUrl: `client/${clientId}/pdf_fields/:id`,
		id: +id,
		submitFn: (values) => {
			delete values.pdfFieldViewList;
			saveGenericPdf({values: {...values, fields: null, pdfId: +id}, onClose: handleClose});
		},
	};
	return (
		<Form formData={formData} open={showModal} handleClose={handleClose} loading={loading} />
	);
};

const mapStateToProps = (state) => ({
	tariffs: state.app.tariffs.index.optionsTariffs,
	loading: state.app.client.index.isUploading,
});
const mapDispatchToProps = {
	fetchTariffsByDropdown: tariffActions.fetchTariffsByDropdown,
	saveGenericPdf: clientActions.saveGenericPdf,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignModal));
