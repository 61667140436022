import DataGridTable from "@src/common/table/DataGridTable";
import {changeStateValue} from "@src/store/sagas/app/messages/utils";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {actions as tenantActions} from "@sagas/tenants/index";
import {connect} from "react-redux";
import Button from "@src/common/components/button";
import saveIcon from "@assets/img/save-icon.svg";
import {getPermissionColumns} from "./permissionColumns";

const Permissions = ({tenantFeatures, loading, setTenantFeatures}) => {
	const {t} = useTranslation();
	const [data, setData] = useState([]);
	const handleCheckbox = (e, field, id) => {
		const dataIndex = data.findIndex((row) => row?.id === id);
		const updatedState = changeStateValue({
			state: data,
			position: dataIndex,
			value: e.target.checked,
			changeValue: field,
		});
		setData(updatedState);
	};

	const columns = getPermissionColumns(handleCheckbox);
	useEffect(() => {
		setData(tenantFeatures);
	}, [tenantFeatures]);
	return (
		<div className="permissions__container">
			<Button
				icon={saveIcon}
				customClassName="saveButton"
				disabled={loading}
				leftRounded={true}
				text={t("Save")}
				handleChange={() => setTenantFeatures(data)}
				smallButtonWithTooltip
			/>
			<DataGridTable data={data} columns={columns} pagination={false} />
		</div>
	);
};

const mapStateToProps = (state) => ({
	tenantFeatures: state.app.users.index.tenantFeatures,
	loading: state.app.tenants.index.loading,
});

const mapDispatchToProps = {
	setTenantFeatures: tenantActions.setTenantFeatures,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Permissions));
