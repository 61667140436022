/* eslint-disable max-len */
import React, {useState} from "react";
import {Formik, FieldArray} from "formik";
import InputComponent from "@common/input";
import DropDownInput from "@common/dropDown";
import CloseIcon from "@common/closeIcon/CloseIcon";
import ButtonComponent from "@common/button";
import {ReactComponent as AddIcon} from "@assets/img/add_black_24dp.svg";
import {ReactComponent as RemoveIcon} from "@assets/img/remove_black_24dp.svg";
import {useTranslation} from "react-i18next";

const FormikForm = (props) => {
	const {elements, loading, initialValues, clientId, options, setShowModal, handleSubmit} = props;
	const {validationSchema} = elements;

	const hasID = initialValues?.[elements.hasID];

	const {t} = useTranslation();

	const [count, setCount] = useState(0);

	const addProductHandler = (arrayHelpers) => {
		arrayHelpers.push({
			name: "",
			description: "",
		});
		setCount(count + 1);
	};
	const removeProductHandler = (arrayHelpers, index) => {
		arrayHelpers.remove(index);
		setCount(count - 1);
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) =>
				handleSubmit({values: {...values, clientId}, formActions: actions})
			}
			validationSchema={
				!hasID && !validationSchema?.edit ? validationSchema.add : validationSchema.edit
			}
		>
			{({values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
				<form
					autoComplete="off"
					noValidate
					onSubmit={handleSubmit}
					className="edit__and__create__form"
				>
					<div className="close__modal__icon__and__name">
						<h1>{`${hasID ? "Edit" : "Add"} ${elements.modalTitle}`}</h1>
						<CloseIcon className="close__modal__icon" click={() => setShowModal()} />
					</div>
					{elements?.lists?.[hasID && validationSchema?.edit ? "edit" : "add"]?.map(
						(element, index) => (
							<React.Fragment key={index}>
								{element.type === "input" && (
									<InputComponent
										name={element.field}
										placeholder={element.title}
										type={element.inputType}
										errorClass="errorClass"
										errors={errors?.[element?.field]}
										values={values?.[element?.field]}
										handleBlur={handleBlur}
										handleChange={handleChange}
										touched={touched[element?.field]}
										bottomBreak={element?.bottomBreak}
									/>
								)}

								{element.type === "dropdown" && (
									<DropDownInput
										title="name"
										className={`${element?.currency && "dropown_currency"} ${
											element?.newClass && element.newClass
										} `}
										errors={errors[element?.field]}
										name={element.name}
										id="currencyId"
										onChange={(event, value) => {
											setFieldValue(element.field, value);
										}}
										options={options[element?.field]}
										touched={touched?.[element?.field]}
										value={values?.[element?.field]}
										placeholder={element.title}
									/>
								)}

								<FieldArray
									name="defaultProducts"
									render={(arrayHelpers) => (
										<>
											{element.addFields === true &&
												values?.defaultProducts?.map((x, index) => (
													<div>
														<div className="add_extra__product__fields">
															<InputComponent
																name="name"
																placeholder={t("productName")}
																type="text"
																errorClass="errorClass"
																errors={
																	errors?.defaultProducts?.[index]
																		?.name
																}
																values={
																	values.defaultProducts[index]
																		.name
																}
																handleBlur={handleBlur}
																handleChange={(e) =>
																	setFieldValue(
																		`defaultProducts.${index}.name`,
																		e.target.value,
																	)
																}
																touched={
																	touched?.defaultProducts?.[
																		index
																	]?.name
																}
															/>
															<InputComponent
																name="description"
																placeholder={t(
																	"ProductDescription",
																)}
																type="text"
																errorClass="errorClass"
																errors={
																	errors?.defaultProducts?.[index]
																		?.description
																}
																values={
																	values.defaultProducts[index]
																		.description
																}
																handleBlur={handleBlur}
																handleChange={(e) =>
																	setFieldValue(
																		`defaultProducts.${index}.description`,
																		e.target.value,
																	)
																}
																touched={
																	touched?.defaultProducts?.[
																		index
																	]?.description
																}
															/>
															<div
																className="tariff__modal__icons__remove"
																onClick={() =>
																	removeProductHandler(
																		arrayHelpers,
																		index,
																	)
																}
															>
																{index >= 1 && (
																	<>
																		<RemoveIcon className="tariff__modal__remove__icon" />
																		<span>
																			{t("RemoveFields")}
																		</span>
																	</>
																)}
															</div>
														</div>
														<div>
															<div
																className="tariff__modal__icons__add"
																onClick={() =>
																	addProductHandler(arrayHelpers)
																}
															>
																{!hasID && index === count && (
																	<>
																		<AddIcon className="tariff__modal__add__icon" />{" "}
																		<span>
																			{t("AddFields")}
																		</span>
																	</>
																)}
																{hasID && setCount(index)}
																{hasID && index === count && (
																	<>
																		<AddIcon className="tariff__modal__add__icon" />{" "}
																		<span>
																			{t("AddFields")}
																		</span>
																	</>
																)}
															</div>
														</div>
													</div>
												))}
										</>
									)}
								/>
							</React.Fragment>
						),
					)}
					<ButtonComponent
						disabled={loading}
						loading={loading}
						buttonText={elements.btnText}
						type="submit"
					/>
				</form>
			)}
		</Formik>
	);
};

export default FormikForm;
