import React from "react";
import {Image, Link, StyleSheet, Text, View} from "@react-pdf/renderer";
import Logo from "@assets/img/oneriLogo.png";

const AgnecyFooter = (props) => {
	const {data} = props;

	return (
		<View style={styles.fixedFooter} fixed>
			<View style={styles.footer}>
				<View style={styles.footerText}>
					<Text>{data?.companyName}</Text>
					<Text>{data?.address}</Text>
					<Text>{data?.location}</Text>
				</View>
				<View style={styles.footerText}>
					<Text>{data?.iban}</Text>
					<Text></Text>
					<Text>{data?.bankName} </Text>
				</View>
				<View style={styles.footerText}>
					<Text>Steuernummer</Text>
					<Text>{data?.taxNumber}</Text>
					<Text> </Text>
				</View>
				<View style={styles.footerText}>
					<Text style={{fontWeight: 600}}>Geschäftsführer</Text>
					<Text>{data?.agencyName !== null ? data?.agencyName : data?.spFullName}</Text>
					<Text> </Text>
				</View>
				{/* <View style={styles.footerText}>
					<Text> Gerichtsstand</Text>
					<Text>-</Text>
					<Text> - </Text>
				</View> */}
				{/* <View style={styles.footerText}>
				<Text style={{color: "#fff"}}> Gerichtsstand</Text>
				<Text>www.maxprom.de</Text>
				<Text style={{color: "#fff"}}> HRB 15475 </Text>
			</View> */}
			</View>
			<View style={styles.website}>
				<Text>
					erstellt mit{" "}
					<Link href="https://oneri.de/" style={styles.websiteLink}>
						oneri.de
					</Link>
				</Text>
				<Image src={Logo} style={styles.websiteLogo} />
			</View>
		</View>
	);
};

export default AgnecyFooter;

const styles = StyleSheet.create({
	viewContainer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
	},
	viewContainerWeb: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
	},
	footer: {
		display: "flex",
		flexDirection: "row",
		fontSize: 7,
		justifyContent: "space-around",
		fontFamily: "Helvetica",
	},
	footerText: {
		display: "flex",
		flexDirection: "column",
		marginTop: 5,
	},
	fixedFooter: {
		bottom: 0,
		left: 0,
		right: 20,
		marginTop: 25,
		borderTop: "3px solid #3279BE",
		display: "flex",
		flexDirection: "column",
	},
	website: {
		color: "#252525",
		fontSize: 8,
		fontWeight: 400,
		marginTop: 10,
		width: "100%",
		flex: 1,
		display: "flex",
		justifyContent: "center",
		flexDirection: "row",
		fontFamily: "Helvetica",
	},
	websiteLink: {
		color: "#252525",
		textDecoration: "none",
		fontSize: 8,
	},
	websiteLogo: {
		height: 7,
		paddingLeft: 10,
	},
});
