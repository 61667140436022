import ButtonComponent from "@src/common/button";
import {useFormik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as orderActions} from "@sagas/orders";
import axios from "axios";
import {DatePicker} from "antd";
import {generateConfig, locale} from "@utils/datepicker-config";
import {PublishedIcon, ScheduleIcon} from "@src/assets/SvgComponents";
import {disabledDate, disabledDateTime, validationSchema} from "./helper";

const PublishModal = (props) => {
	const {t} = useTranslation();
	const {
		handleClose,
		setCalculationStatus,
		ids,
		pdfBase64,
		loading,
		match,
		setPublishCalculationModal,
		billingMonthId,
		initialValues = {
			type: "immediately",
			publishTime: null,
		},
		clientPayoutModel = null,
	} = props;
	const {id} = match.params;
	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			setCalculationStatus({
				uuids: id ? [id] : ids,
				publishTime: values?.type === "immediately" ? null : values.publishTime,
				onSuccess: handleClose,
				change: true,
				getAll: !!ids,
				billingMonthId,
				clientPayoutModel,
			});
			if (pdfBase64) {
				await axios.post(`/files/upload`, {
					file: pdfBase64,
					fileName: `temporary_pdf_settlement_${id}.pdf`,
				});
			}
		},
	});
	const {values, touched, errors, setFieldValue, handleSubmit} = formik;
	const handleItemClick = (value) => {
		setFieldValue("type", value);
	};
	return (
		<form onSubmit={handleSubmit}>
			<div className="publish__popover__component">
				<div className="publish__popover__component-body">
					<div
						className={`publish__popover__component-body-select ${
							values?.type === "immediately" && "active"
						}`}
						onClick={() => handleItemClick("immediately")}
					>
						<span className="icon">
							<PublishedIcon stroke="#252525" />
						</span>
						<span className="title">{t("PublishNow")}</span>
					</div>
					<div
						className={`publish__popover__component-body-select ${
							values?.type === "scheduleDate" && "active"
						}`}
						onClick={() => handleItemClick("scheduleDate")}
					>
						<span className="icon">
							<ScheduleIcon />
						</span>
						<span className="title">{t("ScheduleDate")}</span>
					</div>
					{values?.type === "scheduleDate" && (
						<div className="date__picker">
							<DatePicker
								showTime={{format: "HH:mm"}}
								format="DD/MM/YYYY HH:mm"
								disabledDate={disabledDate}
								disabledTime={disabledDateTime}
								locale={locale}
								generateConfig={generateConfig}
								placeholder="DD/MM/YYYY HH:mm"
								onChange={(value) => {
									setFieldValue(
										"publishTime",
										value ? moment(value).startOf("minute").valueOf() : null,
									);
								}}
								value={values?.publishTime ? moment(values?.publishTime) : null}
							/>
							<div className="date__picker-bottom">
								{touched?.publishTime && errors?.publishTime && (
									<span className="errorClass">{errors?.publishTime}</span>
								)}
								{initialValues?.publishTime && (
									<span
										className="clear__btn"
										onClick={() => {
											setCalculationStatus({
												uuids: [id],
												change: false,
												onSuccess: handleClose,
												publishTime: null,
												clientPayoutModel,
											});
										}}
									>
										Clear Date
									</span>
								)}
							</div>
						</div>
					)}
				</div>
				<div className="publish__popover__component-body-actions">
					<ButtonComponent
						classNames="confirm__button"
						buttonText={t("Confirm")}
						onClick={handleSubmit}
						disabled={loading}
					/>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.orders.index.loading,
});

const mapDispatchToProps = {
	setCalculationStatus: orderActions.setCalculationStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PublishModal));
