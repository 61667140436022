import {NoApiKeyLogo} from "@src/assets/SvgComponents";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation";
import {useTranslation} from "react-i18next";

const NoApiKeyPage = ({navigate, loggedUser}) => {
	const {t} = useTranslation();
	return (
		<div className="no_apiKey--wrapper">
			<div className="no_apiKey--wrapper-inner">
				<NoApiKeyLogo />
				<span className="main__title">{t("apiKeyMissing")}</span>
				<span className="information">{t("apiKeyMissingInfo")}</span>
				{loggedUser?.role?.name === "ADMIN" && (
					<div
						className="no_apiKey--wrapper-inner-btn"
						onClick={() => navigate("/admin/user-profile?from=apiKeyPage")}
					>
						{t("addNewApiKey")}
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NoApiKeyPage));
