import React, {useState} from "react";
import {useFormik} from "formik";
import AnalyticsStepper from "../SteperCommon";
import FirstStep from "../../components/FirstStep";
import AnalyticHeaderComponent from "../../components/AnalyticHeaderComponent";

function ActivityReports() {
	const [current, setCurrent] = useState(0);
	const handleStepper = () => {
		if (current + 1 < 3) {
			setCurrent(current + 1);
		}
	};

	const formik = useFormik({
		initialValues: {
			type: "users",
			clients: [],
			users: [],
		},

		onSubmit: (values) => {
			handleStepper();
		},
	});

	return (
		<div className="activityReports__container">
			<div className="activityReports__container--navigation">
				<AnalyticHeaderComponent title="Activity Reports" />
			</div>
			<AnalyticsStepper
				current={current}
				steps={3}
				className="activityReports__container--stepper"
			/>
			{current === 0 && <FirstStep changeStep={handleStepper} formik={formik} />}
		</div>
	);
}

export default ActivityReports;
