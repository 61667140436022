import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as tariffActions} from "@sagas/tariff/clientTariff";
import {actions as tariffDeletedActions} from "@sagas/tariff/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Users index");

const PREFIX = "@app/tariff/restore";
export const RESTORE = `${PREFIX}RESTORE`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;

const _state = {
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	restore: (payload) => createAction(RESTORE, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
};

export const sagas = {
	*restore({payload}) {
		try {
			const {id} = payload;
			const response = yield axios.put(`/tariff/restore/${id}`);
			yield put(tariffDeletedActions.delete(id));
			yield put(tariffActions.addTarrifOnState(response?.data?.data));
			yield put(tariffActions.totalSizeIncrease());
			ToastSuccesComponent(i18n.t("TariffRestoreSuccess"));
			payload.handleTariffModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(RESTORE, sagas.restore);
};
