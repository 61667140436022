/* eslint-disable jsx-a11y/media-has-caption */
import {
	DocumentIcon,
	DocumentIconMsg,
	FileIcon,
	FileIconMsg,
	ImageMesg,
	MP4MediaIcon,
	MP4MediaIconMsg,
	PDFIcon,
	PdfIcon,
	PptIcon,
} from "@src/assets/SvgComponents";
import React from "react";

export const renderFiles = (file) => {
	switch (file.extension) {
		case "pdf":
		case "xlsx":
		case "mp4":
			return <p>{file?.filename}</p>;
		default:
			return <img src={`data:image/png;base64,${file.base64}`} alt={file.filename} />;
	}
};

export const renderPreviewFiles = (file, isEditor = false) => {
	switch (file?.fileType) {
		case "pdf":
			return (
				<iframe
					style={isEditor ? {height: "100vh", width: "100%"} : {}}
					src={file?.fileUrl || file}
					title={file?.fileName}
				></iframe>
			);
		case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
		case "vnd.openxmlformats-officedocument.presentationml.presentation":
		case "vnd.openxmlformats-officedocument.wordprocessingml.document":
		case "msword":
		case "xlsx":
			// eslint-disable-next-line max-len
			const googleViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
				file?.fileUrl,
			)}`;
			return (
				<iframe
					style={isEditor ? {height: "100vh", width: "100%"} : {}}
					src={googleViewerUrl}
					title={file?.fileName}
				></iframe>
			);
		// case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
		//   return (
		//     <iframe
		//       src={`https://view.officeapps.live.com/op/embed.aspx?src=${file.fileUrl}`}
		//       title={file.fileName}
		//     />
		//   )
		case "mp4":
			return (
				<video
					style={isEditor ? {height: "100vh", width: "100%"} : {height: 500, width: 550}}
					controls
				>
					<source src={file.fileUrl} type="video/mp4" />
				</video>
			);
		default:
			return (
				<img style={isEditor ? {width: "100%"} : {}} src={file?.fileUrl || file} alt="" />
			);
	}
};
export const renderFileIcons = (fileType, myMessage) => {
	switch (fileType) {
		case "vnd.openxmlformats-officedocument.presentationml.presentation":
			return <PptIcon fill={myMessage ? "#fff" : "#8342BC"} />;
		case "xlsx":
		case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			return <DocumentIcon fill={myMessage ? "#fff" : "#8342BC"} />;
		case "mp4":
			return <MP4MediaIcon fill={myMessage ? "#fff" : "#8342BC"} />;
		case "pdf":
			return <PdfIcon fill={myMessage ? "#fff" : "#8342BC"} />;
		default:
			return <FileIcon fill={myMessage ? "#fff" : "#8342BC"} />;
	}
};

export const renderMessagesFileIcons = (fileType, myMessage) => {
	switch (fileType) {
		case "vnd.openxmlformats-officedocument.presentationml.presentation":
			return <FileIconMsg width="22" height="24" />;
		case "xlsx":
		case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			return <DocumentIconMsg width="22" height="24" />;
		case "mp4":
			return <MP4MediaIconMsg width="22" height="24" />;
		case "pdf":
			return <FileIconMsg width="22" height="24" />;
		case "jpg":
		case "png":
			return <ImageMesg width="22" height="24" />;
		default:
			return <FileIconMsg width="22" height="24" />;
	}
};

// fill={myMessage ? "#fff" : "#8342BC"}
