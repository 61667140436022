import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {connect} from "react-redux";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {actions as teamActions} from "@sagas/teams";
import GroupedSelect from "@src/common/Grouped-Select";
import {useFormik} from "formik";
import Button from "@src/common/components/button";
import {actions as usersActions} from "@sagas/users/users";
import {useUrlParams} from "@src/utils/useUrlParams";
import {checkRoles} from "@src/utils/checkRoles";
import {formatGroupOptions} from "../helper";
// eslint-disable-next-line import/no-cycle
import {userRolesDropdown} from "../../helper";

function Userstab({
	editLeadUsers,
	salesTrackerStore,
	usersOptions,
	getTeams,
	getAgencies,
	teams,
	agencies,
	selectedStatuses,
	type = "id",
	selectedRows,
	closeModal,
	loggedUser,
	getUserDropdown,
	getUsersByLeaderId,
}) {
	const {getUrlParams} = useUrlParams();
	const {query} = getUrlParams("tab", "query");
	const [groupedOptions, setGroupedOptions] = useState([]);
	useEffect(() => {
		if (type === "selected") {
			if (checkRoles("getUsersByLeaderId")) {
				getUsersByLeaderId(loggedUser?.id);
			} else {
				getTeams({isTeam: false, all: true});
				getAgencies({isTeam: true, all: true});
				getUserDropdown(
					checkRoles("getUsersByHeadOfSalesId") ? ["HEAD_OF_SALES"] : userRolesDropdown,
				);
			}
		}
	}, [loggedUser]);
	useEffect(() => {
		const items = formatGroupOptions(
			checkRoles("getTeamsDropdownAsLeader") ? [] : teams,
			checkRoles("getTeamsDropdownAsLeader") ? [] : agencies,
			usersOptions,
			null,
			values?.selectedUsers,
		);
		setGroupedOptions(items);
	}, [teams, agencies, usersOptions, loggedUser]);
	const {lead} = salesTrackerStore;
	const findInitialUsers = () => {
		const users = usersOptions?.filter((user) => lead.userIds?.includes(user?.id));
		const initialValues = users?.map((item) => ({
			label: item?.name,
			value: item?.id,
		}));
		return initialValues;
	};
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			selectedUsers: type === "id" ? findInitialUsers() : [],
		},
		onSubmit: (values) => {
			const data = {
				leadsIds:
					type === "id"
						? [lead?.id]
						: selectedRows
								?.filter((item) => !selectedStatuses?.ids?.includes(item?.statusId))
								?.map((it) => it?.id),
				removeId: null,
				addId: values?.selectedUsers?.map((item) => item?.value),
				statusIds: type === "id" ? null : selectedStatuses?.ids || null,
				type: type === "id" ? "SINGLE" : "MULTIPLE",
			};
			editLeadUsers({
				data,
				query,
			});
			closeModal();
		},
	});
	const {values, handleSubmit, setFieldValue} = formik;
	return (
		<div className="userstab-wrapper">
			<GroupedSelect
				options={groupedOptions}
				label={t("ChooseUsers")}
				values={values?.selectedUsers}
				field="selectedUsers"
				setFieldValue={setFieldValue}
				handleSubmit={handleSubmit}
				type={type}
				width={type !== "selected" && "80%"}
			/>
			{type === "selected" && (
				<Button
					text={t("Add")}
					borderRadius="8px"
					width="100%"
					height="42px"
					leftRounded={true}
					handleChange={handleSubmit}
					disabled={!values?.selectedUsers?.[0]}
				/>
			)}
		</div>
	);
}
const mapStateToProps = (state) => ({
	selectedStatuses: state.app.salesTracker.index.selectedStatuses,
	usersOptions: state.app.users.index.usersOptions,
	salesTrackerStore: state.app.salesTracker.index,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	editLeadUsers: salesTrackerActions.editLeadUsers,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getUserDropdown: usersActions.getUserDropdown,
	getUsersByLeaderId: usersActions.getUsersByLeaderId,
};
export default connect(mapStateToProps, mapDispatchToProps)(Userstab);
