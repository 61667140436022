import {Storage} from "./local-storage";

export const getEndpointBasedOnVersion = (feature, endpoint) => {
	let formattedEndpoint = endpoint,
		isV2CurrentVersion = false;
	const features = Storage.get("features");
	if (features?.[feature]) {
		formattedEndpoint = `/v2${endpoint}`;
		isV2CurrentVersion = true;
	}
	return {endpoint: formattedEndpoint, isV2CurrentVersion};
};
