import {checkRoles} from "@utils/checkRoles";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import {connect} from "react-redux";
import {actions as createTrainingActions} from "@sagas/trainings/create";
import {withRouter} from "react-router";
import {DeleteRowIcon, EditRowIcon} from "@src/assets/SvgComponents";
import {useUrlParams} from "@src/utils/useUrlParams";
import draftLogo from "../../../../assets/img/draftLogo.svg";

const ROLES = ["TENANT", "BACK-OFFICE", "MANAGEMENTINFO", "CLIENT"];

const TrainingCard = (props) => {
	const {
		onClick,
		category,
		isDraft,
		title,
		id,
		navigate,
		bgImg,
		loggedUser,
		deleteTraining,
		draftTraining,
	} = props;
	const {t} = useTranslation();
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id: modalId} = getUrlParams("modal", "id");
	const handleDeleteAndDraft = () => {
		if (modal === "delete") {
			deleteTraining({id: modalId, isDraft});
		}
		if (modal === "draft") {
			draftTraining(modalId);
		}
		setModal(false);
	};
	return (
		<div className="trainingCard">
			<DeleteModal
				setShowDeleteModal={setModal}
				confirm={handleDeleteAndDraft}
				open={(modal === "draft" || modal === "delete") && id === modalId}
				handleClose={() => setModal(false)}
			/>
			<div className="trainingCard__top" onClick={onClick}>
				{bgImg && <img src={bgImg} alt="" />}
				{!bgImg && (
					<>
						<div className="trainingCard__category">{category}</div>
						<div className="trainingCard__title">{title}</div>
					</>
				)}
			</div>
			{bgImg && (
				<div className="no__img__trainingCard__category">
					<div className="trainingCard__category">{category}</div>
					<div className="trainingCard__title">{title}</div>
					{ROLES?.includes(loggedUser?.role?.name) && (
						<div className="trainingCard__bottom">
							{checkRoles("editTemplate") && (
								<div
									className="trainingCard__bottom-text"
									onClick={() => navigate(`/admin/trainings/edit/training/${id}`)}
								>
									<EditRowIcon />
									<span>{t("Edit")}</span>
								</div>
							)}
							{checkRoles("draftTemplate") && (
								<>
									{isDraft === "published" && (
										<div
											className="trainingCard__bottom-text"
											onClick={() => setModal("draft", id)}
										>
											<img
												alt="draftLogo"
												src={draftLogo}
												className="trainingCard__bottom-icon"
											/>{" "}
											<span>{t("Draft")}</span>
										</div>
									)}
								</>
							)}
							{checkRoles("deleteTemplate") && (
								<div
									className="trainingCard__bottom-text"
									onClick={() => setModal("delete", id)}
								>
									<DeleteRowIcon />
									<span>{t("Delete")}</span>
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
	deleteTraining: createTrainingActions.deleteTraining,
	draftTraining: createTrainingActions.draftTraining,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrainingCard));
