import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/comission";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Comissions create");

const PREFIX = "@app/comission/create";
export const ADD_COMISSION = `${PREFIX}ADD_COMISSION`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_COMISSION_SUCCESS = `${PREFIX}ADD_COMISSION_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_COMMISION = `${PREFIX}FILL_COMMISION`;

const _state = {
	loading: false,
	comissionAdded: false,
	comissionInitialValues: {
		productId: "",
		tariffId: "",
		provision: "",
		cancellationReserve: "",
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_COMISSION_SUCCESS:
				draft.comissionAdded = action.payload;
				break;
			case FILL_COMMISION:
				draft.comissionInitialValues = action.payload;
				break;
			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) {
					draft.comissionInitialValues = {
						productId: 0,
						tariffId: 0,
						provision: 0,
						cancellationReserve: 0,
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addComission: (payload) => createAction(ADD_COMISSION, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addComissionSucces: (payload) => createAction(ADD_COMISSION_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillComission: (payload) => createAction(FILL_COMMISION, {payload}),
};

export const sagas = {
	*addComission({payload}) {
		yield put(actions.setLoading(true));
		const {values} = payload;

		try {
			if (values?.tableData) {
				const obj = {
					...values,
					productId: values?.productId?.value,
					tariffId: values?.tariffId?.value,
				};
				yield axios.put(`/commission/update/`, obj);
				yield put(userActions.editComissionOnState(values));
				ToastSuccesComponent(i18n.t("ComisionUpdateSuccess"));
			} else {
				const obj = {
					...values,
					productId: values?.productId?.value,
					tariffId: values?.tariffId?.value,
				};
				yield axios.post(`/commission/create/`, obj);
				const comissionObj = {
					...values,
					productName: values?.productId?.name,
					tariffName: values?.tariffId?.name,
				};
				yield put(userActions.addComissionOnState(comissionObj));
				ToastSuccesComponent(i18n.t("ComisionCreateSucces"));
			}
			yield put(actions.setShowModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_COMISSION, sagas.addComission);
};
