import React from "react";
import TableCustomColumn from "@common/tableCustomColumn/TableCustomColumn";
import {DATE_FORMAT, FORMAT_DATE} from "@common/constants";
import moment from "moment";
import StatusBox from "@src/common/components/status-box";
import i18n from "../../i18n";

export const usersColumnsCSV = [
	{title: i18n.t("FirstName"), field: "firstName"},
	{title: i18n.t("LastName"), field: "lastName"},
	{title: i18n.t("Email"), field: "email"},
	{title: i18n.t("Role"), field: "role.name"},
	{title: i18n.t("agency"), field: "company"},
	{title: i18n.t("VPNumber"), field: "extVpNr"},
	{title: i18n.t("Gender"), field: "gender"},
	{title: i18n.t("HouseNumber"), field: "houseNumber"},
	{title: i18n.t("Street"), field: "street"},
	{title: i18n.t("City"), field: "city"},
	{title: i18n.t("PostCode"), field: "postCode"},
	{title: i18n.t("IDNumber"), field: "idNumber"},
	{title: i18n.t("HRNumber"), field: "hrNumber"},
	{title: i18n.t("DateOfBirth"), field: "dateOfBirth"},
	{title: i18n.t("MobilePhone"), field: "mobilePhone"},
	{title: i18n.t("TaxNumber"), field: "taxNumber"},
	{title: i18n.t("BankName"), field: "bankName"},
	{title: i18n.t("TaxOffice"), field: "taxOffice"},
	{title: i18n.t("Iban"), field: "iban"},
	{title: `${i18n.t("VATEntitlement")} URL`, field: "vatEntitlementUrl"},
	{title: `${i18n.t("TaxNumber")} URL`, field: "taxNumberUrl"},
	{title: `${i18n.t("BusinessLicense")} URL`, field: "businessLicenseUrl"},
	{title: `${i18n.t("HrNumber")} URL`, field: "hrNumberUrl"},
	{title: `${i18n.t("IDNumber")} Back URL`, field: "idNumberUrlBack"},
	{title: `${i18n.t("IDNumber")} Front URL`, field: "idNumberUrlFront"},
	{title: `${i18n.t("VATNumber")} URL`, field: "vatNumberUrl"},
];

export const usersColumns = [
	{
		key: "name",
		cellClass: "custom-cell-class",
		name: i18n.t("Username"),
		resizable: true,
		sortable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => (
			<TableCustomColumn
				row={row}
				link="users"
				navLink={`${row?.firstName} ${row?.lastName}`}
			/>
		),
	},
	{
		name: i18n.t("Role"),
		key: "role.name",
		sortable: true,
		headerCellClass: "headerStyles",
		resizable: true,
		formatter: ({row}) => <span>{i18n.t(`${row?.role?.name}`).toUpperCase()}</span>,
	},
	{
		name: i18n.t("AgencyName"),
		key: "company",
		headerCellClass: "headerStyles",
		sortable: true,
		resizable: true,
		formatter: ({row}) => <span>{row?.company}</span>,
	},
	{
		name: i18n.t("VPNumber"),
		key: "extVpNr",
		headerCellClass: "headerStyles",
		resizable: true,
		sortable: true,
		formatter: ({row}) => <span>{row?.extVpNr ? row?.extVpNr : ""}</span>,
	},
	{
		name: i18n.t("Email"),
		key: "email",
		resizable: true,
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => <span>{row?.email ? row?.email : ""}</span>,
		export: false,
	},
];

export const deletedColumns = [
	...usersColumns,
	{name: i18n.t("deactivatedBy"), key: "deactivatedBy", resizable: true, sortable: true},
	{
		name: i18n.t("deactivatedAt"),
		key: "deactivatedAt",
		resizable: true,
		sortable: true,
		formatter: ({row}) => (
			<span>{row?.deactivatedAt ? moment(row?.deactivatedAt).format(DATE_FORMAT) : ""}</span>
		),
	},
];

const statusColors = {
	APPROVED: "#56A8CA",
	PENDING: "#F5BB6F",
	PAID: "#60C7AB",
	ZAHLUNGSFREIGABE: "#60C7AB",
	UNVOLLSTÄNDIG: "#F5BB6F",
	ANGENOMMEN: "#56A8CA",
	SETTLED: "#1e8659",
	ABGERECHNET: "#1e8659",
	CANCELLED: "#ff5658",
	STORNO: "#ff5658",
	Storno: "#ff5658",
	Delivered: "#1e8659",
	DELIVERED: "#1e8659",
	Finished: "#1e8659",
	FINISHED: "#1e8659",
	Abgeschlossen: "#1e8659",
	ABGESCHLOSSEN: "#1e8659",
	EXPORTIERT: "#1e8659",
	BONUS: "#002D62",
	Bonus: "#002D62",
	Success: "#1D875A",
	Warning: "#E3254D",
};

export const ordersColumns = [
	{name: i18n.t("contractId"), key: "clientOrderId"},

	{
		name: i18n.t("Client"),
		key: "clientName",
	},
	{
		name: i18n.t("Status"),
		key: "orderStatus",
		export: true,
		formatter: ({row}) => {
			const {color, statusName, orderStatus} = row?.systemStatusView || {};
			return (
				<StatusBox
					status={orderStatus}
					flag={!!row?.voSettledUUID}
					name={statusName}
					color={color}
				/>
			);
		},
	},
	{name: i18n.t("ProjectLocation"), key: "locationName"},
	{name: i18n.t("SalesPerson"), key: "salesPartnerName"},

	{
		name: i18n.t("ContractDate"),
		key: "createdAt1",
		formatter: ({row}) => <span>{moment(row.createdAt).format("DD.MM.YYYY")}</span>,
	},
	{
		name: i18n.t("projectCatergory"),
		key: "projectCategoryName",
	},

	{
		name: i18n.t("FirstName"),
		key: "firstName",
	},
	{
		name: i18n.t("LastName"),
		key: "lastName",
	},
];

export const importedColumns = [
	{
		name: i18n.t("Name"),
		key: "name",
		sortable: true,
	},

	{
		name: i18n.t("DateCreated"),
		key: "createdAt",
		sortable: true,
		formatter: ({row}) => FORMAT_DATE(row.createdAt),
	},
	{
		name: i18n.t("ImportedFrom"),
		key: "uploadedBy",
		sortable: true,
	},
	{
		name: i18n.t("Status"),
		key: "status",
		sortable: true,
		formatter: ({row}) => <StatusBox status={i18n.t(row.status)} />,
	},
];
export const exportColumns = [
	{
		title: i18n.t("Name"),
		field: "name",
	},

	{
		title: i18n.t("DateCreated"),
		field: "createdAt",
		render: (row) => FORMAT_DATE(row.createdAt),
	},
	{
		title: i18n.t("ImportedFrom"),
		field: "uploadedBy",
	},
	{
		title: i18n.t("Status"),
		field: "status",
		render: (row) => <span style={{color: statusColors[row.status]}}>{row.status}</span>,
	},
];
export const leaderboardEmployeeColumns = [
	{
		name: i18n.t("Nr"),
		key: "nr",
		width: 0,
		formatter: ({row, rowIdx}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{rowIdx + 1}
			</span>
		),
	},
	{
		name: i18n.t("name"),
		key: "fullName",
		formatter: ({row}) => (
			<span style={{color: row.active && "#6c1eb0", fontWeight: row.active && 700}}>
				{row.fullName}
			</span>
		),
	},
	{
		name: i18n.t("Orders"),
		key: "contracts",
		sortable: true,
		width: 0,
		formatter: ({row}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{row.contracts}
			</span>
		),
	},
];

export const leaderboardColumns = [
	...leaderboardEmployeeColumns,
	{
		name: i18n.t("Points"),
		key: "points",
		sortable: true,
		width: 0,
		formatter: ({row}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{(row.points || 0).toFixed(2)}
			</span>
		),
	},
];
