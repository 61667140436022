import _ from "lodash";

export const getSelectOptionsFromDataTeams = (data, label, value) => {
	let arr = [];
	if (data?.length > 0) {
		arr = data?.map((item) => ({
			label: item[label],
			value: item[value],
			type: item.type,
		}));
	}
	return arr;
};

export const addTypeMap = (data, type) => {
	let arr = [];
	if (data?.length > 0) {
		arr = data?.map((item) => ({
			...item,
			type,
		}));
	}
	return arr;
};

export const sortDataForAutocomplete = (data, label, value) => {
	let arr = [];
	if (data?.length > 0) {
		arr = data?.map((item) => ({
			label: item[label],
			value: item[value],
			type: item.type,
			selected: false,
		}));
	}
	const sortedArray = arr.sort((a, b) => a.type - b.type);
	return sortedArray;
};
const handleTypeAutoComplete = (value, options, setOptions, helperAarr, setFieldValue) => {
	const findMembers = helperAarr[value.type].find((item) => item.id === value.value);
	const members = getSelectOptionsFromDataTeams(findMembers?.members, "name", "id");
	const selectedOptions = options.map((item) => {
		const sameId = members.find((ix) => ix?.value === item?.value);

		if (value.value === item.value && item.type !== "User") {
			item = {...item, selected: true};
		}
		if (item?.value === sameId?.value) {
			item = {...item, selected: true, teamOrAgency: value.value};
		}
		return item;
	});
	setOptions([...selectedOptions]);
	setFieldValue("users", [
		...selectedOptions.filter(
			(item) => item.selected && item.type !== "Team" && item.type !== "Agency",
		),
	]);
};
export const handleAutoCompleteChange = (setFieldValue, value, options, setOptions, helperAarr) => {
	// const cloneOptions = _.cloneDeep(options)
	if (value.type === "User") {
		const selectedUserOptions = options.map((item) => {
			if (item?.value === value.value) {
				item = {...item, selected: true};
			}
			return item;
		});
		setOptions([...selectedUserOptions]);
		setFieldValue("users", [
			...selectedUserOptions.filter(
				(item) => item.selected && item.type !== "Team" && item.type !== "Agency",
			),
		]);
	} else {
		handleTypeAutoComplete(value, options, setOptions, helperAarr, setFieldValue);
	}
};
