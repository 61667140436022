import locale from "date-fns/locale/en-GB";
import React, {useState} from "react";
import {generateConfig} from "@src/utils/datepicker-config";
import {DatePicker, Popover} from "antd";
import Button from "@src/common/components/button";
import {Close, DatePickerIcon} from "@src/assets/SvgComponents";

const ButtonsArray = [
	{
		text: "Week",
		sText: "W",
		type: "week",
	},
	{
		text: "Month",
		sText: "M",
		type: "month",
	},
	{
		text: "Quarter",
		sText: "Q",
		type: "quarter",
	},
	{
		text: "Year",
		sText: "Y",
		type: "year",
	},
	{
		text: "Custom",
		sText: "C",
		type: "custom",
	},
];

const modalHeight = {
	week: "310px",
	month: "350px",
	quarter: "140px",
	year: "350px",
	custom: "320px",
};

const modalWidth = {
	custom: "560px",
};

const DatePickerWithTypes = ({value = "DD-MM-YYYY", handleChange, withIcon = false}) => {
	const {RangePicker} = DatePicker;
	const [type, setType] = useState("week");
	const [open, setOpen] = useState(false);

	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	const renderDatePicker = () => (
		<div
			className="date-picker-types"
			style={{height: modalHeight[type], width: modalWidth[type]}}
		>
			<div className="date-picker-types__buttons">
				{ButtonsArray.map((item, index) => (
					<Button
						key={index}
						handleChange={() => setType(item.type)}
						background={
							type === item.type
								? "linear-gradient(94.98deg, #6C1EB0 0%, #400077 100%)"
								: "#DADADA"
						}
						text={item.text}
						type="submit"
						smallButtonWithTooltip={true}
						textForSmallButton={item.sText}
					/>
				))}
			</div>
			{type === "custom" ? (
				<RangePicker
					open={open}
					onChange={(dates, dateString) => handleChange(dates, dateString, type)}
					format={type === "day" && "DD-MM-YYYY"}
					locale={locale}
					generateConfig={generateConfig}
				/>
			) : (
				<DatePicker
					open={open}
					onChange={(dates, dateString) => handleChange(dates, dateString, type)}
					picker={type}
					format={type === "day" && "DD-MM-YYYY"}
					locale={locale}
					generateConfig={generateConfig}
				/>
			)}
		</div>
	);
	const handleDelete = (e) => {
		e.preventDefault();
		e.stopPropagation();
		handleOpenChange(false);
		handleChange(null);
	};
	return (
		<div className="date-picker-with-types">
			<Popover
				content={renderDatePicker()}
				title="Select a time period"
				trigger="click"
				open={open}
				// placement="bottom"
				placement="bottomRight"
				showArrow={true}
				onOpenChange={handleOpenChange}
			>
				<div className="date-picker-with-types__input">
					<span onClick={() => handleOpenChange(!open)}>{value}</span>
					{withIcon && (
						<div className="date-picker-with-types__input-icons">
							<span onClick={() => handleOpenChange(!open)}>
								<DatePickerIcon />
							</span>
							{value !== "DD-MM-YYYY" && (
								<span onClick={(e) => handleDelete(e)}>
									<Close fill="#c1c1c1" width={10} height={10} />
								</span>
							)}
						</div>
					)}
				</div>
			</Popover>
		</div>
	);
};

export default DatePickerWithTypes;
