/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {actions as billingMonthActions} from "@sagas/calculations/billingMonths";
import {actions as calculationActions} from "@sagas/calculations/index";
import {getCurrentUser} from "@utils/currentUser";
import {CalculationsIcon, RestoreRowIcon} from "@assets/SvgComponents";
import {checkRoles} from "@src/utils/checkRoles";
import Button from "@src/common/components/button";
import CheckBoxComponent from "@src/common/checkbox";
import CustomPopover from "@src/common/Popover";
import CollapsableTable from "@src/common/collapsableTable";
import DataGridTable from "@src/common/table/DataGridTable";
import {useUrlParams} from "@src/utils/useUrlParams";
import {actions as navigation} from "@sagas/navigation/index";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {useFeatures} from "@src/utils/useFeatures";
import {Skeleton} from "antd";
import PublishModal from "./CalculationDetails/PublishModal";
import {calculationColumns} from "./CalulationColumns";
import CalculationExport from "./CalculationExport";
import {PAYOUT_MODELS} from "../clients/clientElements";
import {baseFilterItemProps, initialQuery} from "./helper";
const Calculations = ({
	getCalculations,
	calcEditSize,
	calcEditPage,
	currentUser,
	deleteCalculation,
	getBillingMonths,
	navigate,
	billingMonthsState,
	calculationsState,
}) => {
	const {page, loading, totalSize, totalPages, size, calculations} = calculationsState;
	const {billingMonths, loading: billingMonthsLoader, billingMonthsDropdown} = billingMonthsState;
	const {years} = billingMonthsDropdown;
	const {t} = useTranslation();
	const resetPage = () => calcEditPage(1);
	const loggedUser = getCurrentUser();
	const {features} = useFeatures();
	const isV2CurrentVersion = !!features?.Change_order_status_v2;
	const {getUrlParams, setUrlParams, setQuery, setModal} = useUrlParams(resetPage);
	const {billingMonthId, query, id, modal, tab, calcType, clientPayoutModel} = getUrlParams(
		"billing-month-id",
		"query",
		"id",
		"modal",
		"tab",
		"calcType",
		"clientPayoutModel",
	);
	const initializeParams = (tab) => {
		setUrlParams(
			billingMonthId || null,
			"billing-month-id",
			{
				paramName: "query",
				paramValue: {...initialQuery},
			},
			{
				paramName: "tab",
				paramValue: tab || "Calculation",
			},
		);
	};
	const [publishCalculationModal, setPublishCalculationModal] = useState(false);
	const [exportPopover, setExportPopover] = useState(false);
	const [selectedRows, setSelectedRows] = useState({
		all: false,
		ids: [],
	});
	const [reEditPage, setReEditPage] = useState(true);
	const handleSearch = (e) => {
		const {value} = e.target;
		setQuery({...query, search: value});
		const debounced = _.debounce(() => {
			if (billingMonthId !== "none") {
				getCalculations({
					billingMonthId,
					search: value,
					clientPayoutModel: query?.clientPayoutModel || null,
				});
			}
		}, 1000);
		debounced();
	};
	useEffect(() => {
		getBillingMonths({forDropdown: false});
	}, []);

	useEffect(() => {
		initializeParams();
	}, [tab]);

	const filteredMonths = useMemo(
		() => billingMonths.filter((month) => month.year === +query?.year || month.id === null),
		[query?.year, billingMonths],
	);

	useEffect(() => {
		setReEditPage(false);
		if (loggedUser.role === "SP") {
			const obj = {
				spId: loggedUser?.id,
				billingMonthId,
			};
			getCalculations(obj);
		} else if (billingMonthId !== "none") {
			getCalculations({
				billingMonthId,
				search: query?.search,
				clientPayoutModel: query?.clientPayoutModel,
			});
		}
	}, [page, size, billingMonthId, tab, query?.clientPayoutModel]);
	const actionsFormatter = ({row}) =>
		checkRoles("deleteSettlements") &&
		row?.status !== "PUBLISHED" && (
			<div
				style={{
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
				}}
				onClick={() => handleDeleteModal(row)}
			>
				<RestoreRowIcon />
			</div>
		);
	const actions = {
		key: "actions",
		name: t("Revert"),
		formatter: actionsFormatter,
		cellClass: (row) => (row?.status === "PUBLISHED" ? "price" : ""),
		sortable: false,
		resizable: false,
		width: 50,
	};
	const handleDeleteModal = (row) => {
		const {clientPayoutModel, team, uuid} = row;
		const paramArray = team === "HeadOfSales" ? [{calcType: "vosettlement"}] : [];
		if (clientPayoutModel) {
			paramArray.push({clientPayoutModel});
		}
		setModal("revert", uuid, paramArray);
	};
	const handleDeleteCalculation = () => {
		deleteCalculation({
			uuid: id,
			setSelectedRows,
			billingMonthId,
			calcType,
			clientPayoutModel,
		});
		setModal(false);
	};
	const columnsWithActions = [...calculationColumns, actions]?.map((item) => {
		if (item.key === "select-row") {
			return {
				...item,
				formatter: ({row}) => (
					<CheckBoxComponent
						checked={selectedRows?.ids?.includes(row?.uuid)}
						handleChange={(e) => {
							if (e.target.checked && !selectedRows?.ids?.includes(row?.uuid)) {
								setSelectedRows((prevState) => ({
									all: calculations.length - prevState.ids.length === 1,
									ids: [...prevState?.ids, row?.uuid],
								}));
							} else {
								setSelectedRows((prevState) => ({
									all: false,
									ids: prevState?.ids?.filter((id) => id !== row?.uuid),
								}));
							}
						}}
						disabled={row.status !== "DRAFT"}
					/>
				),
				headerRenderer: () => (
					<CheckBoxComponent
						handleChange={(e) => {
							if (e.target.checked) {
								setSelectedRows({
									all: true,
									ids: calculations?.map((calc) => calc.uuid),
								});
							} else {
								setSelectedRows({
									all: false,
									ids: [],
								});
							}
						}}
						checked={
							selectedRows?.all && calculations?.length === selectedRows?.ids?.length
						}
					/>
				),
			};
		}
		return item;
	});
	const handleClose = () => {
		setPublishCalculationModal(false);
		setSelectedRows({all: false, ids: []});
	};
	const handlePopover = (visibility) => setPublishCalculationModal(visibility);
	const items = [
		{
			key: "publish",
			label: (
				<PublishModal
					handleClose={handleClose}
					setPublishCalculationModal={setPublishCalculationModal}
					ids={selectedRows?.ids}
					billingMonthId={billingMonthId}
					clientPayoutModel={query?.clientPayoutModel}
				/>
			),
		},
	];
	const exportItems = [
		{
			key: "export",
			label: <CalculationExport exportWithDates={false} />,
		},
	];
	const expandHandler = (item) => {
		setUrlParams(item === billingMonthId ? "none" : item, "billing-month-id");
		calcEditPage(1);
		calcEditSize(30);
	};
	const onRowClick = (row) => navigate(`/admin/calculations/${row?.uuid}/${row?.spId}`);
	const filterItems = [
		{
			...baseFilterItemProps,
			label: t("chooseClientType"),
			field: "clientPayoutModel",
			options: PAYOUT_MODELS,
		},
		{
			...baseFilterItemProps,
			label: t("chooseYear"),
			field: "year",
			options: years,
		},
	];
	const handleClearFilters = () => setQuery({...initialQuery, search: query?.search});
	const isAgencySpOrSimpleAgency =
		currentUser?.role?.name === "AGENCY-SP" &&
		(currentUser?.agencyType === "SIMPLE" || currentUser?.role?.name === "AGENCY_70");
	return (
		<>
			<DeleteModal
				open={modal === "revert"}
				handleClose={() => setModal(false)}
				calculationModal
				newTitle={t("AreuSureDeleteSettlement")}
				setShowDeleteModal={setModal}
				confirm={handleDeleteCalculation}
			/>
			<div className="dashboard__container">
				<div className="header-component">
					<div className="dashboard__left__text-header">
						<div className="header-component-wrapper">
							<CalculationsIcon />
							<span className="header-component-text">{t("Settlement")}</span>
						</div>
					</div>
					<div className="dashboard__left__text-actions">
						{checkRoles("exportCalculation") && (
							<CustomPopover
								menu={{items: exportItems}}
								trigger={["click"]}
								customClass="publish__popover"
								open={exportPopover}
								placement="bottomRight"
								handleVisibility={(visibility) => setExportPopover(visibility)}
							>
								<div>
									<Button
										text={t("Export")}
										minWidth="120px"
										handleChange={() => setExportPopover(true)}
									/>
								</div>
							</CustomPopover>
						)}
						{checkRoles("editManualCorr") && (
							<CustomPopover
								menu={{items}}
								customClass="publish__popover"
								open={publishCalculationModal}
								handleVisibility={handlePopover}
								trigger={["click"]}
								disabled={selectedRows?.ids?.length === 0}
							>
								<div className="publish__btn">
									<Button
										text={t("Publish")}
										minWidth="120px"
										disabled={selectedRows?.ids?.length === 0}
										handleChange={handlePopover}
									/>
									{selectedRows?.ids?.length > 0 && (
										<span className="publish__btn--count">
											{selectedRows?.ids?.length > 999
												? "999+"
												: selectedRows?.ids?.length}
										</span>
									)}
								</div>
							</CustomPopover>
						)}
						{/* added this role check because there's no search param for agency-sp calculcations */}
						{checkRoles("filterCalculations") && (
							<BigSearchBar
								handleSearch={handleSearch}
								value={query?.search}
								headerSearch
								withFilters
								filterItems={filterItems}
								setQuery={setQuery}
								setFilterValues={setQuery}
								clearFilterValues={handleClearFilters}
								filterValues={query}
							/>
						)}
					</div>
				</div>
				<div className="calculationsTable__wrapper">
					<div className="loader__and__table-orderspage">
						<div className="loader__and__table-orderspage-collapseContainer">
							{isV2CurrentVersion ? (
								!billingMonthsLoader ? (
									filteredMonths?.map((item) => (
										<CollapsableTable
											key={item.id}
											title={t(item.month)}
											year={item?.year}
											exportable={!item.id}
											data={
												isAgencySpOrSimpleAgency ? [] : calculations || []
											}
											columns={columnsWithActions.map((column) => ({
												...column,
												resizable: true,
												headerCellClass:
													column.key === "select-row"
														? "custom__cell__checkbox"
														: "headerStyles",
											}))}
											onTableExpand={() => expandHandler(item?.id)}
											expanded={billingMonthId === item?.id}
											pagination={loggedUser?.role !== "AGENCY-SP"}
											paginationProps={{
												loading,
												size,
												page,
												totalPages,
												totalSize,
												setReEditPage,
												reEditPage,
												editSize: calcEditSize,
												editPage: calcEditPage,
												showEditModal: false,
												previewRow: false,
											}}
											onRowClick={onRowClick}
										/>
									))
								) : (
									<div className="full__loader">
										{Array.from({length: 13}).map((_, rowIndx) => (
											<div key={rowIndx} className="row">
												<Skeleton.Input
													active
													style={{
														height: 20,
														borderRadius: 5,
													}}
												/>
												<Skeleton.Input
													active
													style={{
														height: 20,
														minWidth: 40,
														maxWidth: 40,
														borderRadius: 10,
													}}
												/>
											</div>
										))}
									</div>
								)
							) : (
								<div style={{padding: "20px"}}>
									<div className="loader__and__table-orderspage">
										<DataGridTable
											columns={columnsWithActions.map((column) => ({
												...column,
												resizable: true,
												headerCellClass:
													column.key === "select-row"
														? "custom__cell__checkbox"
														: "headerStyles",
											}))}
											enableCellSelect={false}
											data={
												isAgencySpOrSimpleAgency ? [] : calculations || []
											}
											loading={loading}
											size={size}
											page={page}
											totalPages={totalPages}
											totalSize={totalSize}
											pagination={loggedUser?.role !== "AGENCY-SP"}
											setReEditPage={setReEditPage}
											reEditPage={reEditPage}
											editSize={calcEditSize}
											editPage={calcEditPage}
											showEditModal={false}
											previewRow={false}
											onRowClick={(index, row, column) =>
												column?.key !== "select-row" &&
												column?.key !== "actions" &&
												onRowClick(row)
											}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	calculationsState: state.app.calculations.index,
	currentUser: state.app.users.index.loggedUser,
	billingMonthsState: state.app.calculations.billingMonths,
});

const mapDispatchToProps = {
	calcEditSize: calculationActions.editSize,
	calcEditPage: calculationActions.editPage,
	getCalculations: calculationActions.getCalculations,
	deleteCalculation: calculationActions.deleteCalculation,
	getBillingMonths: billingMonthActions.getBillingMonths,
	navigate: navigation.navigate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Calculations));
