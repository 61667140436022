/* eslint-disable no-nested-ternary */
import {CloseModalIcon} from "@src/assets/SvgComponents";
import {Popover, DatePicker} from "antd";
import React from "react";
import moment from "moment";
import {generateConfig, getDateRange, getWeekRange, locale} from "@utils/datepicker-config";
import {useTranslation} from "react-i18next";
import ButtonComponent from "../button";
import DatePickerWithTypes from "../DatePicker/datePickerWithTypes";
import FiltersSelectComponent from "./FiltersSelectComponent";

const Filters = (props) => {
	const {
		filterItems,
		children,
		trigger = "click",
		handleVisibility,
		open,
		setShowFilters,
		filterValues,
		setFilterValues,
		clearFilterValues,
	} = props;
	const {t} = useTranslation();
	const {RangePicker} = DatePicker;
	const handleDeselect = (item, value) => {
		const filteredItemValues = filterValues[item?.field].filter((item) => item !== value) || [];
		setFilterValues({
			...filterValues,
			[item.field]: filteredItemValues,
		});
	};
	const handleClear = (item) => {
		if (item.dependentField) {
			setFilterValues({
				...filterValues,
				[item.dependentField]: null,
				[item.field]: null,
			});
		} else if (item.dependentFields) {
			const newObj = {
				...filterValues,
				[item.field]: null,
			};
			item.dependentFields.forEach((field) => {
				newObj[field] = null;
			});
			setFilterValues(newObj);
		} else {
			setFilterValues({
				...filterValues,
				[item.field]: null,
			});
		}
	};
	const handleValues = async (item, value) => {
		if (item?.sagaFunction && value) {
			await item.sagaFunction(value);
		}
		if (item.isMultiple) {
			const prevValues = filterValues[item.field] || [];
			if (!prevValues.includes(value?.[0])) {
				setFilterValues({
					...filterValues,
					[item.field]: [...prevValues, value],
				});
			}
		} else if (item.dependentField) {
			setFilterValues({
				...filterValues,
				[item.dependentField]: null,
				[item.field]: value,
			});
		} else if (item.dependentFields) {
			const newObj = {
				...filterValues,
				[item.field]: value || null,
			};
			item.dependentFields.forEach((field) => {
				newObj[field] = null;
			});
			setFilterValues(newObj);
		} else {
			setFilterValues({
				...filterValues,
				[item.field]: value,
			});
		}
	};
	const filterItem = (item, value) => {
		const prevValues = filterValues[item.field] || [];
		const filteredValues = prevValues?.filter((val) => val !== value);
		setFilterValues({
			...filterValues,
			[item.field]: filteredValues,
		});
	};
	const handlePickerChange = (dateString, type, fields) => {
		const [from, to, dateType] = fields;
		if (!dateString) {
			return setFilterValues({
				...filterValues,
				[from]: null,
				[to]: null,
				[dateType]: null,
			});
		}
		let dates = null;
		if (type === "week") {
			dates = getWeekRange(dateString);
		} else if (type !== "custom") {
			dates = getDateRange(dateString);
		} else if (type === "custom") {
			dates = {
				start: moment(dateString?.[0]),
				end: moment(dateString?.[1]),
			};
		}
		setFilterValues({
			...filterValues,
			[dateType]: type,
			[from]: dates?.start,
			[to]: dates?.end,
		});
	};
	const popoverContentRender = (item) => {
		const {
			inputType,
			options,
			label,
			isMultiple,
			field,
			name,
			value,
			placeholder,
			maxDate,
			minDate,
			key,
			optionVal,
			optionLabel,
			secondLabel,
		} = item;
		if ((item.dependendFrom && !filterValues?.[item.dependendFrom]) || !item.show) return null;
		switch (inputType) {
			case "antd-dropdown":
				return (
					<>
						<FiltersSelectComponent
							options={options}
							value={
								isMultiple
									? filterValues?.[field] || []
									: options?.find(
											(opt) => opt?.[value] === filterValues?.[field],
									  ) ?? null
							}
							onSelect={(val) => {
								handleValues(item, val);
							}}
							key={key}
							optionVal={optionVal}
							mode={isMultiple ? "multiple" : undefined}
							placeholder={t("SelectAnOption")}
							onDeselect={(val) => handleDeselect(item, val)}
							onClear={() => handleClear(item)}
							optionLabel={optionLabel}
							width="100%"
							label={label}
							secondLabel={secondLabel}
						/>
					</>
				);
			case "rangePicker":
				return (
					<div key={field} className="filters__component-inputs-rangePicker">
						<p>{t("chooseDates")}</p>
						<RangePicker
							inputReadOnly
							onChange={(date) => {
								if (date === null) {
									setFilterValues({
										...filterValues,
										[field[0]]: null,
										[field[1]]: null,
									});
								} else {
									const [fromDate, endDate] = [
										moment(date?.[0]).valueOf(),
										moment(date?.[1]).valueOf(),
									];
									setFilterValues({
										...filterValues,
										[field[0]]: moment(fromDate).startOf("day").valueOf(),
										[field[1]]: moment(endDate).endOf("day").valueOf(),
									});
								}
							}}
							className="rangepicker__picker"
							disabledDate={(current) => {
								if (current) {
									const currentDate = moment(current).valueOf();
									if (minDate && maxDate) {
										return currentDate < minDate || currentDate > maxDate;
									}
									if (minDate) {
										return currentDate < minDate;
									}
									if (maxDate) {
										return currentDate > maxDate;
									}
									return false;
								}
							}}
							value={[
								filterValues?.[field[0]] ? moment(filterValues?.[field[0]]) : null,
								filterValues?.[field[1]] ? moment(filterValues?.[field[1]]) : null,
							]}
							placeholder={placeholder}
							locale={locale}
							generateConfig={generateConfig}
							format="DD-MM-YYYY"
						/>
					</div>
				);
			case "rangePickerWithTypes":
				return (
					<div key={field} className="filters__component-inputs-rangePicker">
						<p>{label}</p>
						<DatePickerWithTypes
							withIcon
							handleChange={(_, dates, dateString) =>
								handlePickerChange(dates, dateString, field)
							}
							value={
								filterValues?.[field[0]]
									? `${moment(filterValues?.[field[0]]).format(
											"DD-MM-YYYY",
									  )} - ${moment(filterValues?.[field[1]]).format("DD-MM-YYYY")}`
									: "DD-MM-YYYY"
							}
						/>
					</div>
				);
			default:
				return <></>;
		}
	};

	return (
		<Popover
			trigger={trigger}
			open={open}
			onOpenChange={handleVisibility}
			placement="bottomRight"
			content={
				<div className="filters__component">
					<div className="filters__component-header">
						<span className="filters__component-header-title">{t("Filters")}</span>
						<span
							className="filters__component-header-icon"
							onClick={() => setShowFilters(false)}
						>
							<CloseModalIcon width="12" height="12" />
						</span>
					</div>
					<div className="filters__component-inputs">
						{filterItems?.map((item) => popoverContentRender(item))}
					</div>
					<div className="filters__component-actions">
						<ButtonComponent
							buttonText={t("clearFilters")}
							onClick={() => {
								clearFilterValues();
								setShowFilters(false);
							}}
						/>
					</div>
				</div>
			}
		>
			{children}
		</Popover>
	);
};

export default Filters;
