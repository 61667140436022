import i18n from "@src/i18n";
const baseObj = {
	startDate: null,
	endDate: null,
	conditionType: "MONTHLY",
	name: "",
	teamIds: [],
	id: null,
};
export const overheadInitialValue = {
	...baseObj,
	countType: "",
	overheadLeader: {
		name: i18n.t("TeamLeader"),
		minContracts: 0,
		minPoints: 0,
		minPointsTeam: 0,
		minContractsTeam: 0,
	},
	overheadTrainer: {
		name: i18n.t("Trainer"),
		minContracts: 0,
		minPoints: 0,
		minPointsTeam: 0,
		minContractsTeam: 0,
	},
	type: "overhead",
};

export const selfProductionInitialValue = {
	...baseObj,
	selfProductionLeader: {
		name: i18n.t("TeamLeader"),
		provision: 0,
		maxTeamMembers: 0,
	},
	selfProductionTrainer: {
		name: i18n.t("Trainer"),
		provision: 0,
		maxTeamMembers: 0,
	},
	type: "self_production",
};

export const returnNameBasedOnPayload = (activeTab) =>
	activeTab === "overhead" ? "overHeadCondition" : "selfProductionCondition";
