import React, {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {EditRowIcon, ExpandAccordionIcon, ExportIcon} from "@src/assets/SvgComponents";
import CalculationExport from "@src/components/dashboard/calculations/CalculationExport";
import {checkRoles} from "@src/utils/checkRoles";
import DataGridTable from "../table/DataGridTable";
import Loader from "../Loader";
import CustomPopover from "../Popover";

const CollapsableTable = (props) => {
	const {
		data,
		columns,
		title,
		onTableExpand,
		expanded,
		loading,
		editable,
		onEditClick,
		item,
		pagination = false,
		paginationProps = {},
		tableHeight = 350,
		exportable,
		year,
		onRowClick,
	} = props;
	const fullHeight = data?.length > 0 ? data.length * 44 + 60 : tableHeight;
	const [openPopover, setOpenPopover] = useState(false);
	const handleVisibility = (visible) => setOpenPopover(visible);
	const exportItems = [
		{
			key: "export",
			label: <CalculationExport exportWithDates />,
		},
	];
	return (
		<Accordion className="collapsable__table" onChange={onTableExpand} expanded={expanded}>
			<AccordionSummary
				expandIcon={<ExpandAccordionIcon fill="#979797" width="20" height="20" />}
				className="collapsable__table-header"
			>
				<div className="custom__header">
					<span className="custom__header-title">{title}</span>
					<div className="custom__header-icons">
						{year && <span className="year">{year}</span>}
						{editable && (
							<span
								onClick={(e) => {
									e.stopPropagation();
									onEditClick(item);
								}}
							>
								<EditRowIcon />
							</span>
						)}
						{exportable && checkRoles("exportCalculation") && (
							<CustomPopover
								menu={{items: exportItems}}
								trigger={["click"]}
								customClass="publish__popover"
								open={openPopover}
								placement="bottomRight"
								handleVisibility={handleVisibility}
							>
								<span onClick={(e) => e.stopPropagation()}>
									<ExportIcon stroke="#979797" height="16" width="16" />
								</span>
							</CustomPopover>
						)}
					</div>
				</div>
			</AccordionSummary>
			{expanded && (
				<>
					{loading ? (
						<div className="collapsable__table-loader">
							<Loader />
						</div>
					) : (
						<DataGridTable
							data={data}
							columns={columns}
							{...paginationProps}
							pagination={pagination}
							style={{height: pagination ? tableHeight : fullHeight}}
							onRowClick={(index, row, column) =>
								onRowClick &&
								column?.key !== "select-row" &&
								column?.key !== "actions" &&
								onRowClick(row)
							}
						/>
					)}
				</>
			)}
		</Accordion>
	);
};
export default CollapsableTable;
