import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as categoryActions} from "@sagas/projectCategories/clientCategory";
import {actions as categoryDeletedActions} from "@sagas/projectCategories/deleted";
import {actions as deleteCategoryActions} from "@sagas/projectCategories/deleteClientCategory";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Users index");

const PREFIX = "@app/projectCategories/restore";
export const RESTORE = `${PREFIX}RESTORE`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;

const _state = {
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	restore: (payload) => createAction(RESTORE, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
};

export const sagas = {
	*restore({payload}) {
		yield put(deleteCategoryActions.setLoading(true));
		try {
			const categories = yield select((state) => state.app.projectCategories.deleted.list);
			const {totalSize} = yield select((state) => state.app.projectCategories.deleted);
			const {id} = payload;
			const category = categories.find((e) => e.id === id);
			yield axios.put(`/projectCategory/restore/${id}`);
			yield put(categoryDeletedActions.delete(id));
			yield put(categoryDeletedActions.editTotalSize(totalSize - 1));
			if (category?.baugo?.id) {
				const baugoObj = {
					...category?.baugo,
					bonusName: category?.baugo?.name
						? category?.baugo?.name
						: category?.baugo?.bonusName,
				};
				yield put(categoryActions.addCategoryOnState(category));
				const categoryObj = {...category};
				delete categoryObj?.baugo;
				yield put(
					categoryActions.addCategoryOnState({
						...baugoObj,
						name: baugoObj?.bonusName,
						projectCategories: {...categoryObj, value: category?.id},
					}),
				);
			} else {
				yield put(categoryActions.addCategoryOnState(category));
			}
			ToastSuccesComponent(i18n.t("CategoryRestoreSuccess"));
			payload.handleCategoryModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(deleteCategoryActions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(RESTORE, sagas.restore);
};
