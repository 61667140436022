/* eslint-disable max-len */
import {UserActivitiesCardIcon} from "@src/assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import {DATE_FORMAT} from "@src/common/constants";
import {activityStatuses} from "@src/utils/activityStatuses";
import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";

function Card({title, cards, isLastCard}) {
	const {t} = useTranslation();
	return (
		<>
			{cards?.[0] && <span className="title">{t(title)}</span>}
			{cards.map((item, index) => (
				<div className="card">
					<div
						className={`stepper 
                        ${index + 1 === cards?.length ? "last" : ""} 
                        ${index + 1 === cards?.length && isLastCard ? "lastCard" : ""}`}
					/>
					<div className="card-wrapper">
						<div className="card-wrapper_left">
							<div className="icon-wrapper">
								<UserActivitiesCardIcon />
							</div>
						</div>
						<div className="card-wrapper_right">
							<div className="card-wrapper_right-top">
								<span className="card-wrapper_right-title">
									{`${t("CareerStages")}:`}
									<span className="card-wrapper_right-data">{`${item?.fromStufe} → ${item?.toStufe}`}</span>
								</span>
								<span className="card-wrapper_right-title">
									{`${t("RequirementAchievedOn")}:`}
									<span className="card-wrapper_right-data">
										{item?.achievedDate
											? moment(item?.achievedDate).format(DATE_FORMAT)
											: "-"}
									</span>
								</span>
								<span className="card-wrapper_right-title">
									{`${t("StartingOn")}:`}
									<span className="card-wrapper_right-data">
										{moment(item?.dateStarted).format(DATE_FORMAT)}
									</span>
								</span>
								<span className="card-wrapper_right-title">
									{`${t("CyclesCompleted")}:`}
									<span className="card-wrapper_right-data">
										{`${item.nrOfCompletedCycles} / ${item.totalCycles}`}
									</span>
								</span>
							</div>
							<StatusBox
								row={item?.status}
								statusData={activityStatuses}
								height="30px"
								containerHeight="30px"
								translateText
							/>
						</div>
					</div>
				</div>
			))}
		</>
	);
}

export default Card;
