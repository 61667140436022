import {DeleteRowIcon, EditRowIcon, GrabIcon, PlusCircle} from "@src/assets/SvgComponents";
import React, {useEffect} from "react";
import {useUrlParams} from "@src/utils/useUrlParams";
import {getLabel} from "./helper";

export const Item = React.memo(
	React.forwardRef(
		(
			{
				dragOverlay,
				dragging,
				disabled,
				fadeIn,
				handle,
				handleProps,
				height,
				index,
				listeners,
				onRemove,
				sorting,
				style,
				transition,
				transform,
				value,
				wrapperStyle,
				item,
				orderField,
				...props
			},
			ref,
		) => {
			const {setModal} = useUrlParams();
			useEffect(() => {
				if (!dragOverlay) {
					return;
				}

				document.body.style.cursor = "grabbing";

				return () => {
					document.body.style.cursor = "";
				};
			}, [dragOverlay]);

			const handleAction = (e) => {
				e.preventDefault();
				e.stopPropagation();
				const id = e.currentTarget.id === "child" && orderField ? item.fieldId : item.id;
				setModal(e.currentTarget.id, id);
			};

			return (
				<li
					className={`Wrapper ${fadeIn && "fadeIn"} ${sorting && "sorting"} ${
						dragOverlay && "dragOverlay"
					}`}
					style={{
						...wrapperStyle,
						transition: [transition, wrapperStyle?.transition]
							.filter(Boolean)
							.join(", "),
						"--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
						"--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
						"--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
						"--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
						"--index": index,
					}}
					ref={ref}
				>
					<div
						className={`Item ${dragging && "dragging"} ${handle && "withHandle"} ${
							dragOverlay && "dragOverlay"
						}`}
						style={style}
					>
						<div className="Item__content">
							<span
								className="grab__icon"
								data-cypress="draggable-item"
								{...(!handle ? listeners : undefined)}
								{...props}
								tabIndex={!handle ? 0 : undefined}
							>
								<GrabIcon />
							</span>
							<div className="Item__content-name">
								<span>{getLabel(item)}</span>
							</div>
							{item?.clientId && (
								<div className="Item__content-actions">
									{item?.inputType === "DROPDOWN" && (
										<span id="child" onMouseDown={handleAction}>
											<PlusCircle />
										</span>
									)}
									<span
										id={orderField ? "orderField" : "customField"}
										onMouseDown={handleAction}
									>
										<EditRowIcon />
									</span>
									{orderField && (
										<span
											id={
												orderField
													? "deleteOrderField"
													: "deleteCustomField"
											}
											onMouseDown={handleAction}
										>
											<DeleteRowIcon />
										</span>
									)}
								</div>
							)}
						</div>
						{item?.options?.[0] && (
							<div className="Item__options">
								{item.options?.map((opt, index) => (
									<div
										className="Item__options-single"
										key={`${opt.name}---${index}`}
									>
										<span className="Item__options-single-label">
											{getLabel(opt)}
										</span>
										{item?.clientId && (
											<>
												<span
													onMouseDown={(e) => {
														e.stopPropagation();
														setModal(
															orderField
																? "orderField"
																: "customField",
															opt.id,
															[
																{
																	dropDownParentId: orderField
																		? item.fieldId
																		: item.id,
																},
															],
														);
													}}
												>
													<EditRowIcon />
												</span>
												{orderField && (
													<span
														onMouseDown={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setModal(
																orderField
																	? "deleteOrderField"
																	: "deleteCustomField",
																opt.id,
															);
														}}
													>
														<DeleteRowIcon />
													</span>
												)}
											</>
										)}
									</div>
								))}
							</div>
						)}
					</div>
				</li>
			);
		},
	),
);
