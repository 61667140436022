/* eslint-disable max-len */
export const getAddressByCoordinates = async ({latitude, longitude}) => {
	const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
	try {
		const response = await fetch(url);
		const data = await response.json();
		const {address} = data;
		const obj = {
			zipCode: address.postcode,
			partLocation: address.country,
			location: address.town || address.city,
			houseNumber: address.house_number,
			street: address.road,
			latitude,
			longitude,
		};
		return obj;
	} catch (error) {
		return error;
	}
};
