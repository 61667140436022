import React from "react";
const CreateUser = (props) => {
	const {active = false, word, onClick, templates, disabled = false} = props;

	return (
		<div
			onClick={onClick}
			className={`edit__and__create__user__single__tabs ${templates && "templates"} ${
				active && "active"
			} `}
		>
			{props.children}
			<p style={{color: disabled ? "gray" : ""}}>{word}</p>
		</div>
	);
};

export default CreateUser;
