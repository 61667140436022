import {
	InfoEnergieAttach,
	InfoEnergiePen,
	InfoEnergieShare,
	InfoEnergieWave,
} from "@src/assets/SvgComponents";
import React from "react";

const Infos = () => (
	<div className="infosEnergie__container">
		<div className="infosEnergie__container--first">
			<span>
				<InfoEnergiePen />
			</span>
			<span>Dieser Anbieter akzeptiert die digitale Unterschrift</span>
		</div>
		<div className="infosEnergie__container--second">
			<span>
				<InfoEnergieWave />
			</span>
			<span>keine EEG-Umlage enthalten</span>
		</div>
		<div className="infosEnergie__container--third">
			<span>
				<InfoEnergieAttach />
			</span>
			<span>
				Versorger-Kundenbelehrung, neben userer Kundenbelehrung (DSGVO) ist immer auch die
				Versorger - eigene Kundenbelehrung mit auszufüllen.
			</span>
		</div>
		<div className="infosEnergie__container--fourth">
			<span>
				<InfoEnergieShare />
			</span>
			<span>
				Eprimo ist nun eine Tochter der E.ON und damit in allen Grundversorgungsgebeiten der
				E.ON Gruppe nur eingschränkt verfügbar. Grundsätzlich ist Vorversorger E.ON nicht
				möglich.
			</span>
		</div>
	</div>
);

export default Infos;
