import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "../../../store/sagas/app/navigation";

const TenantCostumColumn = (props) => {
	const {row, navigate, link} = props;

	return (
		<div
			className="user__table__photo__and__name user__table-green"
			onClick={() => navigate(`/admin/tenants/${link}/${row?.id}`)}
		>
			<p>{row.name}</p>
		</div>
	);
};

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(TenantCostumColumn));
