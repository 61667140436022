/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "../../../../utils/action-creator";
import {actions as navigation} from "../navigation";
import axios from "../../../../utils/axios";
import Logger from "../../../../utils/logger";

const logger = new Logger("Auth Register");

const PREFIX = "@app/auth/register";
export const REGISTER = `${PREFIX}REGISTER`;
export const REGISTER_SUCCESS = `${PREFIX}REGISTER_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_ME = `${PREFIX}FETCH_ME`;

const _state = {
	user: {
		email: "",
		password: "",
		confirmPassword: "",
	},
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case REGISTER_SUCCESS:
				draft.user = {
					...action.payload,
				};
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	register: (payload) => createAction(REGISTER, {payload}),
	registerSuccess: (payload) => createAction(REGISTER_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*register({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.post("/user", {
				payload,
			});
			yield put(navigation.navigate("/auth/login"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(REGISTER, sagas.register);
};
