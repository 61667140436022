import {push, goBack} from "connected-react-router";
import {put, takeLatest} from "redux-saga/effects";

import createAction from "../../../../utils/action-creator";

const PREFIX = "@app/navigation/";

export const NAVIGATE = `${PREFIX}NAVIGATE`;
export const GO_BACK = `${PREFIX}GO_BACK`;

export const actions = {
	navigate: (payload) => createAction(NAVIGATE, {payload}),
	goBack: () => createAction(GO_BACK),
};

export const sagas = {
	*navigate({payload: to}) {
		yield put(push(to));
	},
	*goBack() {
		yield put(goBack());
	},
};

export const watcher = function* w() {
	yield takeLatest(NAVIGATE, sagas.navigate);
	yield takeLatest(GO_BACK, sagas.goBack);
};
