import React, {useMemo} from "react";
import {
	DashboardIcon,
	UsersIcon,
	OrdersIcon,
	ClientsIcon,
	CalculationsIcon,
	TrainingsIcon,
	MessagesIcon,
	SettingsIcon,
	SupportIcon,
	SalesTrackerIcon,
	TenantsIcon,
	EnergyIcon,
	ImportsIcon,
} from "@assets/SvgComponents";
import {useFeatures} from "@src/utils/useFeatures";
import {checkRoles} from "@src/utils/checkRoles";
import i18n from "../../i18n";

const drawerItems = [
	{
		icon: <DashboardIcon />,
		name: i18n.t("Dashboard"),
		link: "/admin/dashboard",
		drawerName: "dashboard",
		// roles: [
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"AGENCY-SP",
		// 	"SP",
		// 	"EMPLOYEE",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// 	"ADMIN",
		// ],
		show: "displayDashboard",
	},

	{
		icon: <UsersIcon />,
		name: i18n.t("Users"),
		link: "/admin/users?tab=users&table-tab=active",
		drawerName: "users",
		// roles: ["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"],
		show: "displayUsers",
	},
	{
		icon: <OrdersIcon />,
		name: i18n.t("Orders"),
		link: "/admin/orders?tab=orders&table-tab=all",
		drawerName: "orders",
		show: "displayOrders",

		// roles: [
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"CLIENT",
		// 	"AGENCY-SP",
		// 	"SP",
		// 	"EMPLOYEE",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// 	"ADMIN",
		// ],
	},
	{
		icon: <ClientsIcon />,
		name: i18n.t("ClientsList"),
		link: "/admin/clients?&table-tab=active",
		drawerName: "clients",
		// roles: ["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"],
		show: "displayClients",
	},
	{
		icon: <TenantsIcon />,
		name: "Tenants",
		link: "/admin/tenants/",
		drawerName: "tenants",
		// roles: ["ADMIN"],
		show: "displayTenants",
	},
	{
		icon: <CalculationsIcon />,
		name: i18n.t("Calculation"),
		link: "/admin/calculations/",
		drawerName: "calculations",
		// roles: [
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"SP",
		// 	"AGENCY-SP",
		// 	"EMPLOYEE",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// 	"ADMIN",
		// ],
		show: "displayCalculations",
	},
	{
		icon: <TrainingsIcon />,
		name: i18n.t("Trainings"),
		link: "/admin/trainings/",
		drawerName: "trainings",
		feature: "Trainings",
		// roles: [
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"SP",
		// 	"AGENCY-SP",
		// 	"EMPLOYEE",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// 	"ADMIN",
		// ],
		show: "displayTrainings",
	},
	{
		icon: <MessagesIcon />,
		name: i18n.t("Messages"),
		link: "/admin/messages/",
		drawerName: "messages",
		feature: "Messages",
		// roles: [
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"SP",
		// 	"ADMIN",
		// 	"AGENCY-SP",
		// 	"EMPLOYEE",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// ],
		show: "displayMessages",
	},
	{
		icon: <SalesTrackerIcon />,
		name: i18n.t("SalesTracker"),
		drawerName: "salestracker",
		link: "/admin/salestracker",
		feature: "SalesTracker",
		// roles: [
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"SP",
		// 	"ADMIN",
		// 	"AGENCY-SP",
		// 	"EMPLOYEE",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// ],
		show: "displaySalesTracker",
	},
	{
		icon: <EnergyIcon />,
		name: i18n.t("Energy"),
		drawerName: "energy",
		link: "/admin/energy",
		feature: "Energy",
		// roles: ["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"],
		show: "displayEnergy",
	},
	{
		icon: <ImportsIcon />,
		name: i18n.t("Imports"),
		drawerName: "imports",
		link: "/admin/imports",
		feature: "Imports",
		// roles: [
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"SP",
		// 	"ADMIN",
		// 	"AGENCY-SP",
		// 	"EMPLOYEE",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// ],
		show: "displayImports",
	},
];

const bottomItems = [
	{
		icon: <SettingsIcon />,
		name: i18n.t("Settings"),
		link: "/admin/user-profile",
		drawerName: "user-profile",
		// roles: [
		// 	"ADMIN",
		// 	"TENANT",
		// 	"MANAGEMENTINFO",
		// 	"BACK-OFFICE",
		// 	"USER",
		// 	"SP",
		// 	"AGENCY",
		// 	"AGENCY_70",
		// 	"AGENCY-SP",
		// 	"EMPLOYEE",
		// 	"CLIENT",
		// 	"EMPLOYEE_SHOP",
		// 	"EMPLOYEE_TL",
		// 	"EMPLOYEE_AB",
		// ],
		show: "displaySettings",
	},
	{
		icon: <SupportIcon />,
		name: i18n.t("Support"),
		// roles: ["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"],
		feature: "Tickets",
		link: "/admin/support",
		drawerName: "support",
		show: "displaySupport",
	},
];

export default function useDrawerItems() {
	const {features} = useFeatures();
	const items = useMemo(() => {
		const filterDrawerItems = drawerItems.filter(
			(elem) => checkRoles(elem.show) && (!elem?.feature || features?.[elem?.feature]),
		);
		const filterBottomItems = bottomItems.filter(
			(elem) => checkRoles(elem.show) && (!elem?.feature || features?.[elem?.feature]),
		);
		return {filterDrawerItems, filterBottomItems};
	}, [JSON.stringify(features)]);
	return items;
}
