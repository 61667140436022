import {ShowInfoIconBlack} from "@src/assets/SvgComponents";
import React from "react";
import {useTranslation} from "react-i18next";

const StatusInfo = ({status}) => {
	const {statusName, icon, color, description} = status;
	const {t} = useTranslation();
	return (
		<div className="status__info-container">
			<div className="status__info-container--title">
				<span className="icon">
					<ShowInfoIconBlack />
				</span>
				<span className="text">{t("autoIndicator")}</span>
			</div>
			<div className="status__info-container--status" style={{borderColor: color}}>
				<div
					className="status__info-container--status-iconWrapper"
					style={{borderColor: color}}
				>
					<span>{icon}</span>
				</div>
				<div className="status__info-container--status-statusWrapper">
					<span className="status" style={{color}}>
						{statusName}
					</span>
				</div>
			</div>
			<div className="status__info-container--message">
				<span>{description}</span>
			</div>
		</div>
	);
};

export default StatusInfo;
