/* eslint-disable react/jsx-max-depth */
import React, {useEffect, useMemo, useState} from "react";
import {actions as clientActions} from "@sagas/client";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import _ from "lodash";
import {searchCustomFields} from "@src/utils/searchCustomFields";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {useUrlParams} from "@src/utils/useUrlParams";
import {useTranslation} from "react-i18next";
import MultipleContainers from "./createOrder/multiplecontainers";
import CreateCustomField from "./createOrder/createCustomField";
import CreateAndEditSection from "./createOrder/components/CreateAndEditSection";
import EditCustomField from "./createOrder/components/EditCustomField";
import PreviewOrderForm from "./createOrder/components/PreviewOrderForm";

const compareArrays = (sourceArray, targetArray) => {
	const targetArrayIds = targetArray?.map((item) => item.fieldId);
	const filteredArray = sourceArray.filter((item) => !targetArrayIds.includes(item.id));
	return _.uniqBy(filteredArray, "id");
};

const CreateDraggableOrder = ({
	match,
	getAllCostumFields,
	fetchGenericOrderByID,
	groupedCustomFields,
	sections,
	editedOrderFields,
	deleteSection,
	loading,
	deleteOrderField,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [containers, setContainers] = useState({left: [], right: []});
	const [fields, setFields] = useState({});
	const {t} = useTranslation();
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id} = getUrlParams("modal", "id");
	const {clientId} = match.params;
	useEffect(() => {
		if (clientId) {
			getAllCostumFields(clientId);
			fetchGenericOrderByID(clientId);
		}
	}, [clientId]);

	useEffect(() => {
		const newObj = {};
		const differences = compareArrays(groupedCustomFields, editedOrderFields);
		newObj.customFields = differences;
		newObj.staticSection = [];
		const groupedFields = _.groupBy(editedOrderFields, "parentId");
		sections.forEach((section) => {
			// If no fields are grouped by this section's id, set an empty array
			if (!groupedFields[section.id]) {
				groupedFields[section.id] = [];
			} else {
				groupedFields[section?.id] = groupedFields?.[section.id]?.sort(
					(a, b) => a.orderSortNr - b?.orderSortNr,
				);
			}
		});
		setContainers({left: ["customFields"], right: ["staticSection", ...sections]});
		setFields({...newObj, ...groupedFields});
	}, [sections, editedOrderFields, groupedCustomFields]);

	const filteredFields = useMemo(() => {
		if (searchQuery) {
			return {
				...fields,
				customFields: searchCustomFields(fields.customFields, searchQuery),
			};
		}
		return fields;
	}, [searchQuery, fields]);
	const handleSearch = (e) => setSearchQuery(e.target.value);
	const modalConfig = {
		deleteSection: {
			type: "deleteSection",
			confirm: () => deleteSection({id, setModal}),
			newTitle: t("areYouSureDeleteSection"),
		},
		deleteOrderField: {
			type: "deleteOrderField",
			confirm: () => deleteOrderField({id, setModal}),
			newTitle: t("areYouSureDeleteField"),
		},
		deleteCustomField: {
			type: "deleteCustomField",
			confirm: () => setModal(false),
			newTitle: t("areYouSureDeleteField"),
		},
	};
	const activeModal = modalConfig?.[modal] || null;
	return (
		<>
			{activeModal && (
				<DeleteModal
					calculationModal
					newTitle={activeModal.newTitle}
					open={modal === activeModal.type}
					handleClose={() => setModal(false)}
					setShowDeleteModal={setModal}
					confirm={activeModal.confirm}
					loading={loading}
					disabled={loading}
				/>
			)}
			<CreateCustomField />
			<CreateAndEditSection />
			<EditCustomField />
			<PreviewOrderForm />
			<MultipleContainers
				containers={containers}
				setContainers={setContainers}
				fields={filteredFields}
				setFields={setFields}
				handleSearch={handleSearch}
				searchValue={searchQuery}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	groupedCustomFields: state.app.client.index.groupedCustomFields,
	editedOrderFields: state.app.client.index.groupedOrderFields,
	sections: state.app.client.index.sections,
	loading: state.app.client.index.isLoading,
});
const mapDispatchToProps = {
	getAllCostumFields: clientActions.getAllCostumFields,
	fetchGenericOrderByID: clientActions.fetchGenericOrderByID,
	deleteSection: clientActions.deleteSection,
	deleteOrderField: clientActions.deleteOrderField,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateDraggableOrder));
