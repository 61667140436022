import React from "react";
import {PlusCircle, PreviewRowIcon} from "@src/assets/SvgComponents";
import {useUrlParams} from "@src/utils/useUrlParams";
import SectionToggler from "./SectionToggler";

const Header = ({title, sections, toggleSectionVisibility, withActions = false}) => {
	const {setModal} = useUrlParams();
	return (
		<div className="drag__and__drop-header">
			<span className="drag__and__drop-header-title">{title}</span>
			{withActions && (
				<div className="drag__and__drop-header-actions">
					<span className="plus" onClick={() => setModal("preview")}>
						<PreviewRowIcon color="#979797" />
					</span>
					{sections?.[0] && (
						<SectionToggler
							sections={sections}
							toggleSectionVisibility={toggleSectionVisibility}
						/>
					)}
					<span className="plus" onClick={() => setModal("section")}>
						<PlusCircle />
					</span>
				</div>
			)}
		</div>
	);
};

export default Header;
