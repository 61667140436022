import React, {useEffect, useMemo, useState} from "react";
import StatisticBars from "@common/statisticBars/StatisticBars";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders/index";
import moment from "moment";
import UserStats from "@common/userStats.js/UserStats";
import DashboardOne from "@common/DashboardOne/UserStats";
import {checkRoles} from "@utils/checkRoles";
import {DatePicker, Skeleton} from "antd";
import {DashboardIcon} from "@assets/SvgComponents";
import {generateConfig} from "@utils/datepicker-config";
import locale from "date-fns/locale/en-GB";
import DataGridTable from "@src/common/table/DataGridTable";
import {actions as clientActions} from "@sagas/client/index";
import {actions as goalActions} from "@sagas/analytics/index";
import {actions as createGoalAction} from "@sagas/analytics/goals/create";
import Tabs from "@src/common/components/tabs";
import {actions as categoriesActions} from "@sagas/projectCategories/index";
import ClientsBar from "@src/common/ClientsBar/ClientsBar";
import Leaderboard from "@src/common/Leaderboard/Leaderboard";
import {useUrlParams} from "@src/utils/useUrlParams";
import {useFeatures} from "@src/utils/useFeatures";
import Boxes from "../../common/infoSmallBox";
import "moment/locale/en-gb";
import {ordersColumns} from "./columns";
import HeaderActions from "./Analytics/components/HeaderActions";
import Analytics from "./Analytics";
import AnalyticsModal from "./Analytics/modals/AnalyticsModal";
import {dashboardBoxes, initialQuery} from "./staticData";
import DashboardTwo from "./DashboardTwo";
import AlertsCards from "./Analytics/components/AlertsCardComponent.js/index";
import GridSkeleton from "./Analytics/components/GridSkeleton";

const Dashboard = (props) => {
	const {t} = useTranslation();
	const {
		getOrders,
		cleanQuery,
		getRevenues,
		getOrderGraphs,
		loggedUser,
		clients,
		getClients,
		analyticState,
		clearInitialValues,
		getChartByClient,
		fetchProjectCategories,
		getUserStats,
		getLeaderboard,
		getLeaderboardEmployees,
		setQuery,
		getGoals,
		gridLoading,
		ordersState,
	} = props;
	const {goals, loading} = analyticState;
	const {
		query,
		orders,
		revenueLoading,
		orderGraphs,
		revenues,
		selectedClient,
		leaderboardLoading,
		graphLoading,
	} = ordersState;
	const {features: tenantFeatures} = useFeatures();
	const roleData = localStorage.getItem("roleData");
	const timezone = new Date().getTimezoneOffset();
	const [activeKey, setActiveKey] = useState(null);
	const [selectedGoals, setSelectedGoals] = useState([]);
	const {
		getUrlParams,
		setUrlParams,
		setModal,
		setQuery: setParamQuery,
		clearUrlQuery,
	} = useUrlParams();
	const {
		tab: currentTab,
		query: paramQuery,
		modal,
		id,
		groupViewType,
	} = getUrlParams("tab", "query", "modal", "id", "groupViewType");
	const initializeQueryParams = (item) => {
		setUrlParams(
			item,
			"tab",
			{
				paramName: "query",
				paramValue: {...initialQuery},
			},
			{paramValue: "GridView", paramName: "groupViewType"},
		);
	};
	const tabs = useMemo(() => {
		const tabsArray = [];
		if (roleData && tenantFeatures?.General_dashboard) {
			tabsArray.push({
				name: "General",
				type: "general",
			});
		}
		if (roleData && checkRoles("seeDashboard2") && tenantFeatures?.Management_dashboard) {
			tabsArray.push({
				name: "dashboardManagment",
				type: "dashboard2",
			});
		}
		if (roleData && checkRoles("seeDashboard1") && tenantFeatures?.Admin_dashboard) {
			tabsArray.push({
				name: "dashboardAdmin",
				type: "dashboard1",
			});
		}
		if (roleData && checkRoles("getGoals") && tenantFeatures?.Goals) {
			tabsArray.push({
				name: "Performance",
				type: "performance",
			});
		}
		return tabsArray;
	}, [tenantFeatures, roleData]);
	const [activeTab, setActiveTab] = useState(
		tabs?.length > 0
			? tabs[0]
			: {
					name: "",
					type: "",
			  },
	);

	useEffect(() => {
		if (tabs?.length > 0) {
			if (!currentTab) {
				const firstTab = tabs[0];
				setActiveTab(firstTab);
				setUrlParams(firstTab.type);
			} else {
				const foundedTab = tabs?.find((tab) => tab.type === currentTab);
				setActiveTab(foundedTab);
			}
		}
	}, [currentTab, tabs, roleData]);
	useEffect(() => {
		if (groupViewType !== "GridView") {
			setSelectedGoals([]);
		}
	}, [groupViewType]);

	const [datesGraph, setDatesGraph] = useState({
		from: moment().startOf("month").valueOf(),
		to: moment().endOf("month").valueOf(),
	});
	const [active, setActive] = useState("custom");
	const [rangeType, setRangeType] = useState("month");
	const [isInitialRender, setIsInitialRender] = useState(true);
	const {RangePicker} = DatePicker;

	useEffect(() => {
		setQuery({
			...query,
			clientId: null,
			toDate: moment().valueOf(),
			fromDate: moment().startOf("month").valueOf(),
			status: null,
			teamId: null,
			agencyId: null,
		});
		getClients();
		// eslint-disable-next-line
		return () => cleanQuery();
	}, []);
	const onChange = (date, active) => {
		const obj = {
			from:
				active !== "custom"
					? moment(date).startOf(active).valueOf()
					: moment(date?.[0]).startOf("day").valueOf(),
			to:
				active !== "custom"
					? moment(date).endOf(active).valueOf()
					: moment(date?.[1]).endOf("day").valueOf(),
		};
		setDatesGraph(obj);
	};
	const handleActive = ({type}) => {
		setActive(type);
		if (type !== "custom") {
			setRangeType("");
		}
		setDatesGraph({
			from: moment().startOf(type).valueOf(),
			to: moment().endOf(type).valueOf(),
		});
	};
	useEffect(() => {
		if (query?.fromDate !== null && query?.toDate !== null) {
			if (activeTab?.type === "general") {
				getOrders({
					dashboardPage: true,
				});
				if (tenantFeatures?.Goals) {
					getGoals({
						showDashboard: true,
						startDate: moment(query.fromDate)
							.startOf("day")
							.subtract(timezone, "minutes")
							.valueOf(),
						endDate: moment(query.toDate)
							.endOf("day")
							.subtract(timezone, "minutes")
							.valueOf(),
					});
				}
			}
			if (activeTab?.type !== "performance" && activeTab?.type !== "dashboard2") {
				getUserStats();
				getLeaderboard();
			}
			if (activeTab?.type === "dashboard2") {
				getLeaderboardEmployees();
			}
			if (activeTab.type !== "performance") {
				getRevenues();
			}
		}
	}, [query?.fromDate, query?.toDate, activeTab?.type, tenantFeatures]);
	useEffect(() => {
		if (isInitialRender) {
			setIsInitialRender(false);
			return;
		}
		const type = active !== "custom" ? active : rangeType;
		if (datesGraph?.from && datesGraph?.to) {
			if (type !== "") {
				if (!selectedClient.clientId) {
					getOrderGraphs({dates: datesGraph, type, active});
				} else {
					fetchProjectCategories(selectedClient.id);
					getChartByClient({
						dates: datesGraph,
						type,
						active,
						id: selectedClient.clientId,
					});
				}
			}
			setQuery({
				...query,
				toDate: datesGraph.to,
				fromDate: datesGraph.from,
			});
		}
	}, [datesGraph, rangeType, selectedClient]);

	const revenueItems = dashboardBoxes(loggedUser, activeTab);
	const handleTabClick = (item) => {
		if (item.type === currentTab) return;
		if (item.type === "performance") {
			initializeQueryParams(item.type);
		} else {
			setUrlParams(item.type, "tab");
			clearUrlQuery();
		}
	};
	return (
		<div className="dashboard__container">
			<div className="header-component">
				<div className="dashboard__left__text-header">
					<div className="header-component-wrapper">
						<DashboardIcon />
						<span className="header-component-text">{t("Dashboard")}</span>
					</div>
					<Tabs
						items={tabs}
						onClick={(_, item) => {
							handleTabClick(item);
						}}
						value={activeTab.type}
					/>
				</div>
				{tabs?.length > 0 && (
					<div className="dashboard__left__text-actions">
						{activeTab.type === "general" ||
						activeTab.type === "dashboard1" ||
						activeTab.type === "dashboard2" ? (
							<div className="orders__daterange__picker">
								<div>
									<span className="statistics__information__text">
										{active !== "custom" ? (
											<DatePicker
												onChange={(dates) => onChange(dates, active)}
												defaultValue={moment()}
												picker={active}
												format={active === "day" && "DD-MM-YYYY"}
												locale={locale}
												generateConfig={generateConfig}
											/>
										) : (
											<RangePicker
												format="DD-MM-YYYY"
												onChange={(dates) => onChange(dates, active)}
												defaultValue={[
													moment(datesGraph.from),
													moment(datesGraph.to),
												]}
												locale={locale}
												generateConfig={generateConfig}
											/>
										)}
									</span>
								</div>
							</div>
						) : (
							<HeaderActions
								query={paramQuery}
								setQuery={setParamQuery}
								setModal={setModal}
								modal={modal}
								id={id}
								setUrlParams={setUrlParams}
								groupViewType={groupViewType}
								setSelected={setSelectedGoals}
								selected={selectedGoals}
								cleanQuery={clearUrlQuery}
								setActiveKey={setActiveKey}
								activeKey={activeKey}
								goals={goals}
							/>
						)}
					</div>
				)}
			</div>
			{tabs?.length > 0 && activeTab.type !== "performance" && (
				<div className="dashboard__stats">
					{revenueItems?.map((item) => (
						<>
							{revenueLoading ? (
								<div className="revenue-skeleton">
									<Skeleton
										paragraph={{rows: 1}}
										active
										style={{height: 74, width: "100%", borderRadius: 8}}
									/>
								</div>
							) : (
								<Boxes
									key={item.field}
									icon={item.icon}
									number={item.formatter(revenues?.[item.field] || "0")}
									nameOfInfo={item.title}
								/>
							)}
						</>
					))}
				</div>
			)}
			{activeTab?.type === "dashboard1" && (
				<>
					<AnalyticsModal
						type={modal}
						showModal={!!modal}
						handleClose={() => {
							setModal(false);
							clearInitialValues();
						}}
						loading={loading}
					/>
					<div className="dashboard__left__and__right__container">
						<div className="dashboard__left__side">
							<div className="dashboard__left__side__bottom">
								<DashboardOne
									dates={query}
									handleActive={handleActive}
									active={active}
									rangeType={rangeType}
									setRangeType={setRangeType}
									setActive={setActive}
									datesGraph={datesGraph}
								/>
							</div>
						</div>
						<div className="dashboard__left__side">
							<div
								className="dashboard__left__side__bottom"
								style={{
									display: "flex",
									gap: 10,
									width: "100%",
								}}
							>
								{checkRoles("seeLeaderboard") &&
									(leaderboardLoading ? (
										<div className="leaderboard-skeleton">
											<Skeleton.Input
												active
												style={{
													height: 30,
													borderRadius: 8,
													width: 400,
												}}
											/>
											<Skeleton.Input
												active
												style={{
													borderRadius: 8,
													width: 400,
													height: 510,
												}}
											/>
										</div>
									) : (
										<div
											style={{
												width: "25%",
											}}
											className="userstats-container--left"
										>
											<Leaderboard />
										</div>
									))}
								<div
									style={{
										width: "75%",
										height: "100%",
									}}
								>
									<ClientsBar
										dates={query}
										handleActive={handleActive}
										active={active}
										datesGraph={datesGraph}
										orderGraphs={orderGraphs}
										rangeType={rangeType}
										setRangeType={setRangeType}
										setActive={setActive}
										clients={clients}
										orders={orders}
										graphLoading={graphLoading}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{activeTab?.type === "dashboard2" && (
				<>
					<DashboardTwo
						handleActive={handleActive}
						active={active}
						setActive={setActive}
						datesGraph={datesGraph}
						setDatesGraph={setDatesGraph}
						rangeType={rangeType}
						setRangeType={setRangeType}
					/>
				</>
			)}
			{activeTab?.type === "general" && (
				<>
					<AnalyticsModal
						type={modal}
						showModal={!!modal}
						handleClose={() => {
							setModal(false);
							clearInitialValues();
						}}
						loading={loading}
					/>
					<div className="dashboard__left__and__right__container">
						<div className="dashboard__left__side">
							<div className="dashboard__left__side__bottom">
								<StatisticBars
									dates={query}
									handleActive={handleActive}
									active={active}
									datesGraph={datesGraph}
									orderGraphs={orderGraphs}
									rangeType={rangeType}
									setRangeType={setRangeType}
									setActive={setActive}
									clients={clients}
									graphLoading={graphLoading}
								/>
							</div>
						</div>
						{/* {checkRoles("seeTopStats") && (
							<div className="dashboard__left__side">
								<div className="dashboard__left__side__bottom">
									<DashboardTopStats dates={query} />
								</div>
							</div>
						)} */}
						<div className="dashboard__left__side">
							<div className="dashboard__left__side__bottom">
								<UserStats dates={query} leaderboardLoading={leaderboardLoading} />
							</div>
						</div>
						<div
							className="analytics__container"
							style={{padding: "20px 0", height: "auto"}}
						>
							{goals?.[0] &&
								!gridLoading &&
								goals
									?.filter((item) => item?.showDashboard)
									.map((item, index) => (
										<AlertsCards
											key={index}
											item={item}
											fromDashboard
											setModal={setModal}
											modalState={{modal, id}}
										/>
									))}
						</div>
						{gridLoading && (
							<div>
								<GridSkeleton />
							</div>
						)}
						{checkRoles("seeLast30Orders") && (
							<div className="dashboard__right__side">
								<div className="loader__and__table-dashboardpage">
									<DataGridTable
										columns={ordersColumns.map((column) => ({
											...column,
											resizable: true,
											headerCellClass: "headerStyles",
										}))}
										enableCellSelect={false}
										data={orders}
										pagination={false}
									/>
								</div>
							</div>
						)}
					</div>
				</>
			)}
			{activeTab?.type === "performance" && (
				<Analytics
					query={paramQuery}
					setModal={setModal}
					modalState={{modal, id}}
					groupViewType={groupViewType}
					setSelected={setSelectedGoals}
					selected={selectedGoals}
					setActiveKey={setActiveKey}
					activeKey={activeKey}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ordersState: state.app.orders.index,
	loggedUser: state.app.users.index.loggedUser,
	clients: state.app.client.index.clientsByDropdown,
	analyticState: state.app.analytics.index,
	gridLoading: state.app.analytics.index.loading,
});

const mapDispatchToProps = {
	setQuery: orderActions.setQuery,
	cleanQuery: orderActions.cleanQuery,
	getOrders: orderActions.getOrders,
	getOrderGraphs: orderActions.getOrdersGraphs,
	getRevenues: orderActions.getRevenues,
	getUserStats: orderActions.getUserStats,
	getClients: clientActions.fetchClientsByDropdown,
	getGoals: goalActions.fetchGoals,
	clearInitialValues: createGoalAction.clearInitialValues,
	getChartByClient: orderActions.getChartByClient,
	fetchProjectCategories: categoriesActions?.fetchProjectCategoriesByDropdown,
	getLeaderboard: orderActions.getLeaderboard,
	getLeaderboardEmployees: orderActions.getLeaderboardEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
