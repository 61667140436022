import CustomPopover from "@src/common/Popover";
import React from "react";
import {ThreeDots} from "@src/assets/SvgComponents";
import {AnalyticCardAction} from "../../../staticData";

const GoalActions = ({
	handleActions,
	showDashboard,
	textColor,
	Icon = ThreeDots,
	disabled = false,
	showMobile = false,
	showEdit = true,
	multiple = false,
	selected,
	removeMobile = false,
	fromDashboard = false,
	expired = false,
}) => (
	<CustomPopover
		menu={{
			items: AnalyticCardAction({
				showDashboard,
				showMobile,
				showEdit,
				multiple,
				selected,
				removeMobile,
				fromDashboard,
				expired,
			}),
			onClick: (item) => item.key !== "noActions" && handleActions(item),
		}}
		width="auto"
		disabled={disabled}
		minWidth="100px"
		customClass="alertsCards__container__popover"
	>
		<span className="alertsCards__container--popup">
			<Icon stroke={textColor} />
		</span>
	</CustomPopover>
);

export default GoalActions;
