import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as conditionActions} from "@sagas/teamConditionsV1/index";
import {ENDPOINT} from "@src/utils/endpoint";
import {overheadInitialValue, selfProductionInitialValue} from "./utils";
const logger = new Logger("teams client");
const PREFIX = "@app/teamConditionsV1/create";

export const CREATE_CONDITION = `${PREFIX}CREATE_CONDITION`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_SHOW_MODAL = `${PREFIX}SET_SHOW_MODAL`;

const _state = {
	loading: false,
	showModal: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_SHOW_MODAL:
				draft.showModal = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	createCondition: (payload) => createAction(CREATE_CONDITION, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowModal: (payload) => createAction(SET_SHOW_MODAL, {payload}),
};

export const sagas = {
	*createCondition({payload}) {
		yield put(actions.setLoading(true));
		const {values, type} = payload;
		try {
			const initialValues =
				type === "overhead" ? overheadInitialValue : selfProductionInitialValue;
			const valuesObj = {
				...initialValues,
				...values,
				teamIds: values?.teamIds?.map((team) => ({
					id: team?.value,
					name: team?.label,
				})),
			};
			const response = yield axios.post(
				ENDPOINT.CREATE_TABLE_V1.replace(":type", type),
				valuesObj,
			);
			if (values?.id) {
				yield put(conditionActions.updateTableOnState(valuesObj));
			} else {
				yield put(
					conditionActions.addTableOnState({
						...valuesObj,
						id: response?.data?.data,
					}),
				);
			}
			payload.handleClose();
			yield put(conditionActions.clearTableValues());
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_CONDITION, sagas.createCondition);
};
