import produce from "immer";
import {put, select, takeEvery, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import _ from "lodash";
import {actions as createTopicActions} from "@sagas/messages/create";
import moment from "moment";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import {findIndexFunc, findTopicFunc, changeStateValue} from "./utils";
import {actions as navigateActions} from "../navigation";

const logger = new Logger("Messages index");

const PREFIX = "@app/messages/index";

export const FETCH_ALL_TOPICS = `${PREFIX}FETCH_ALL_TOPICS`;
export const FETCH_ALL_TOPICS_SUCCESS = `${PREFIX}FETCH_ALL_TOPICS_SUCCESS`;
export const FETCH_ALL_TOPICS_LOADING = `${PREFIX}FETCH_ALL_TOPICS_LOADING`;
export const FETCH_TOPICS_BY_ID = `${PREFIX}FETCH_TOPICS_BY_ID`;
export const FETCH_TOPICS_BY_ID_SUCCESS = `${PREFIX}FETCH_TOPICS_BY_ID_SUCCESS`;
export const FETCH_TOPIC_LOADING = `${PREFIX}FETCH_TOPIC_LOADING`;
export const FETCH_TOPICS_BY_TOPIC_ID = `${PREFIX}FETCH_TOPICS_BY_TOPIC_ID`;
export const FETCH_TOPICS_BY_TOPIC_ID_SUCCESS = `${PREFIX}FETCH_TOPICS_BY_TOPIC_ID_SUCCESS`;
export const ADD_MESSAGE_INSIDE_TOPIC = `${PREFIX}ADD_MESSAGE_INSIDE_TOPIC`;
export const UPDATE_MESSAGE_INSIDE_TOPIC = `${PREFIX}UPDATE_MESSAGE_INSIDE_TOPIC`;
export const SET_TOPIC_ID = `${PREFIX}SET_TOPIC_ID`;
export const SET_INSIDE_TOPIC_TO_EMPTY = `${PREFIX}SET_INSIDE_TOPIC_TO_EMPTY`;
export const CREATE_GROUP_CHANNEL_SUCCESS = `${PREFIX}CREATE_GROUP_CHANNEL_SUCCESS`;

export const SEEN_MESSAGE = `${PREFIX}SEEN_MESSAGE`;
export const SEEN_MESSAGE_SUCCESS = `${PREFIX}SEEN_MESSAGE_SUCCESS`;
export const CHANGE_TOPIC_ORDER = `${PREFIX}CHANGE_TOPIC_ORDER`;
export const ADD_USER_TO_CHANNEL = `${PREFIX}ADD_USER_TO_CHANNEL`;
export const ADD_USER_TO_CHANNEL_SUCCESS = `${PREFIX}ADD_USER_TO_CHANNEL_SUCCESS`;
export const REMOVE_USER_FROM_CHANNEL = `${PREFIX}REMOVE_USER_FROM_CHANNEL`;
export const REMOVE_USER_FROM_CHANNEL_SUCCESS = `${PREFIX}REMOVE_USER_FROM_CHANNEL_SUCCESS`;
export const LEAVE_CHANNEL = `${PREFIX}LEAVE_CHANNEL`;
export const LEAVE_CHANNEL_SUCCESS = `${PREFIX}LEAVE_CHANNEL_SUCCESS`;
export const LEAVE_CHANNEL_SUCCESS_LOADING = `${PREFIX}LEAVE_CHANNEL_SUCCESS_LOADING`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const SET_TOTAL_MESSAGES = `${PREFIX}SET_TOTAL_MESSAGES`;
export const SET_LOADER_PLACEMENT = `${PREFIX}SET_LOADER_PLACEMENT`;
export const SET_FETCHED_PAGES = `${PREFIX}SET_FETCHED_PAGES`;
export const GET_MESSAGE_PAGE = `${PREFIX}GET_MESSAGE_PAGE`;
export const SEARCH_MESSAGES_INSIDE_TOPIC = `${PREFIX}SEARCH_MESSAGES_INSIDE_TOPIC`;
export const SET_SEARCH_RESULTS = `${PREFIX}SET_SEARCH_RESULTS`;
export const SET_SEARCH_PAGE = `${PREFIX}SET_SEARCH_SIZE`;
export const SET_QUERY = `${PREFIX}SET_QUERY`;
export const CLEAR_QUERY = `${PREFIX}CLEAN_QUERY`;
export const SET_SEARCH_TOTAL_PAGES = `${PREFIX}SET_SEARCH_TOTAL_PAGES`;
export const SET_SEARCH_LOADING = `${PREFIX}SET_SEARCH_LOADING`;
export const SET_TOTAL_FILES_SUCCESS = `${PREFIX}SET_TOTAL_FILES_SUCCESS`;
export const GET_FILES = `${PREFIX}GET_FILES`;
export const SET_MEDIA_LOADING = `${PREFIX}SET_MEDIA_LOADING`;
export const SET_WRITE_ACCESS = `${PREFIX}SET_WRITE_ACCESS`;
export const SET_WRITE_ACCESS_SUCCESS = `${PREFIX}SET_WRITE_ACCESS_SUCCESS`;
export const SET_UNSEEN_MESSAGES = `${PREFIX}SET_UNSEEN_MESSAGES`;
export const SET_LAST_MESSAGE = `${PREFIX}SET_LAST_MESSAGE`;
export const ADD_USER_TO_GROUP_SUCCESS = `${PREFIX}ADD_USER_TO_GROUP_SUCCESS`;
export const REMOVE_USER_FROM_GROUP_SUCCESS = `${PREFIX}REMOVE_USER_FROM_GROUP_SUCCESS`;
export const DELETE_SINGLE_MESSAGE_SUCCESS = `${PREFIX}DELETE_SINGLE_MESSAGE_SUCCESS`;
export const REPLACE_DELETED_MESSAGE = `${PREFIX}REPLACE_DELETED_MESSAGE`;
export const GET_LINKS = `${PREFIX}GET_LINKS`;
export const GET_LINKS_SUCCESS = `${PREFIX}GET_LINKS_SUCCESS`;

const _state = {
	channel: [],
	group: [],
	private: [],
	insideTopic: {
		messages: [],
	},
	topicId: 0,
	topicName: "",
	numOfMembers: 0,
	fetchLoading: false,
	topicLoading: false,
	leaveChannelLoading: false,
	messageId: null,
	size: 30,
	page: 1,
	totalPages: 1,
	totalMessages: 1,
	loaderPlacement: "",
	fetchedPages: [1],
	searchResults: [],
	totalFiles: {
		files: [],
		page: 1,
		size: 32,
		totalFiles: 0,
		totalPages: 0,
	},
	query: {
		topicId: null,
		page: 1,
		size: 15,
		search: "",
	},
	searchTotalPages: 1,
	searchLoading: false,
	mediaLoading: false,
	links: [],
	linkTotalPages: 1,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case FETCH_ALL_TOPICS_SUCCESS:
				const name = action.payload[1];
				const arr = action.payload[0];
				draft[name] = arr;
				break;
			case FETCH_ALL_TOPICS_LOADING:
				draft.fetchLoading = action.payload;
				break;
			case FETCH_TOPICS_BY_ID_SUCCESS:
				draft.topicId = action.payload.topicId;
				draft.insideTopic = {
					...action.payload,
					messages: action?.payload?.messages,
				};
				break;
			case FETCH_TOPIC_LOADING:
				draft.topicLoading = action.payload;
				break;
			case FETCH_TOPICS_BY_TOPIC_ID_SUCCESS:
				draft.topicId = action.payload.topicId;
				if (state?.loaderPlacement === "Up") {
					draft.insideTopic = {
						// ...state?.insideTopic,
						...action.payload,
						messages: [...state?.insideTopic?.messages, ...action?.payload?.messages],
					};
				} else if (state?.loaderPlacement === "NOLOADER") {
					draft.insideTopic = {
						...action?.payload,
						messages: [...action.payload.messages],
					};
				} else {
					draft.insideTopic = {
						// ...state?.insideTopic,
						...action.payload,
						messages: [...action?.payload?.messages, ...state?.insideTopic?.messages],
					};
				}
				draft.topicName = action.payload.name;
				draft.numOfMembers = action.payload?.numOfMembers;
				break;
			case ADD_MESSAGE_INSIDE_TOPIC:
				draft.insideTopic = {
					...state?.insideTopic,
					messages:
						state.insideTopic?.messages?.length === 0
							? [action.payload]
							: [action.payload, ...state.insideTopic?.messages],
				};
				break;
			case UPDATE_MESSAGE_INSIDE_TOPIC:
				draft.messageId = action?.payload?.id;
				const allMessages = [...state?.insideTopic?.messages];
				const findIndex = findIndexFunc(
					state?.insideTopic?.messages,
					action.payload?.createdAtTimestamp,
					"createdAtTimestamp",
				);

				if (findIndex !== -1) {
					allMessages[findIndex] = action.payload;
				}
				draft.insideTopic = {...state.insideTopic, messages: allMessages};
				break;
			case SET_TOPIC_ID:
				draft.topicId = action.payload;
				break;
			case SET_INSIDE_TOPIC_TO_EMPTY:
				draft.insideTopic = {
					messages: [],
				};
				draft.topicName = "";
				draft.numOfMembers = 0;
				break;
			case CREATE_GROUP_CHANNEL_SUCCESS:
				const checkGroup = state?.private.find(
					(topic) => topic?.topicId === action.payload?.data?.topicId,
				);
				const checkChannel = state?.channel.find(
					(topic) => topic?.topicId === action.payload?.data?.topicId,
				);
				if (action.payload?.data?.type === "CHANNEL") {
					if (!checkChannel) {
						draft.channel = [
							{
								...action?.payload?.data,
								topicMembers:
									action?.payload?.data?.users?.length ||
									action?.payload?.data?.topicMembers,
							},
							...state.channel,
						];
					}
				} else if (!checkGroup) {
					draft.private = [
						{
							...action?.payload?.data,
							name: action?.payload?.data?.name,
							topicMembers:
								action?.payload?.data?.users?.length ||
								action?.payload?.data?.topicMembers,
						},
						...state.private,
					];
				}
				break;
			case ADD_USER_TO_CHANNEL_SUCCESS:
				if (action.payload?.topicId === state?.insideTopic?.topicId) {
					const members = [...state?.insideTopic?.topicMembers, ...action?.payload?.data];
					const removeDuplicate = _.uniqBy(members, "id");
					draft.numOfMembers = removeDuplicate?.length;
					draft.insideTopic = {
						...state?.insideTopic,
						topicMembers: removeDuplicate,
					};
				}
				break;
			case ADD_USER_TO_GROUP_SUCCESS:
				if (action.payload?.topicId === state?.insideTopic?.topicId) {
					const members = [...state?.insideTopic?.topicMembers, ...action?.payload?.data];
					const removeDuplicate = _.uniqBy(members, "id")?.map((it) => ({
						...it,
						deactivatedAt: 0,
					}));
					draft.numOfMembers = removeDuplicate?.length;
					const updatedUsers = removeDuplicate?.filter(
						(user) => user?.id !== state?.insideTopic?.ownerId,
					);

					draft.insideTopic = {
						...state?.insideTopic,
						topicMembers: removeDuplicate,
						name: updatedUsers
							?.map((item) => `${item?.firstName} ${item?.lastName}`)
							?.join(","),
					};
				}
				break;
			case REMOVE_USER_FROM_CHANNEL_SUCCESS:
				if (action.payload?.topicId === state?.insideTopic?.topicId) {
					draft.numOfMembers = action.payload?.data?.length;
					draft.insideTopic = {
						...state?.insideTopic,
						topicMembers: action.payload?.data,
					};
				}
				break;
			case REMOVE_USER_FROM_GROUP_SUCCESS:
				if (action.payload?.topicId === state?.insideTopic?.topicId) {
					draft.numOfMembers = action.payload?.data?.length;
					const updatedUsers = action?.payload?.data?.filter(
						(item) => item?.id !== state?.insideTopic?.ownerId,
					);
					draft.insideTopic = {
						...state?.insideTopic,
						topicMembers: action.payload?.data,
						name: updatedUsers
							?.map((item) => `${item?.firstName} ${item?.lastName}`)
							?.join(","),
					};
				}
				break;
			case SEEN_MESSAGE_SUCCESS:
				const checkGroupSeen = findIndexFunc(
					state?.private,
					action.payload?.topicId,
					"topicId",
				);
				const checkChannelSeen = findIndexFunc(
					state?.channel,
					action.payload?.topicId,
					"topicId",
				);
				if (state?.channel?.[checkChannelSeen]) {
					const newChannels = changeStateValue({
						state: state.channel,
						position: checkChannelSeen,
						value: action.payload.value,
						changeValue: "lastMessageIsSeen",
					});
					draft.channel = newChannels;
				} else if (state?.private?.[checkGroupSeen]) {
					const newPrivate = changeStateValue({
						state: state.private,
						position: checkGroupSeen,
						value: action.payload.value,
						changeValue: "lastMessageIsSeen",
					});
					draft.private = newPrivate;
				}
				break;
			case CHANGE_TOPIC_ORDER:
				const findTopicChannel = findTopicFunc(
					state?.channel,
					action.payload.topicId,
					"topicId",
				);
				const findTopicGroup = findTopicFunc(
					state?.private,
					action.payload.topicId,
					"topicId",
				);

				if (findTopicChannel) {
					const removeTopic = state?.channel?.filter(
						(topic) => topic?.topicId !== findTopicChannel?.topicId,
					);
					draft.channel = [findTopicChannel, ...removeTopic];
				}
				if (findTopicGroup) {
					const removeTopic = state?.private?.filter(
						(topic) => topic?.topicId !== findTopicGroup?.topicId,
					);
					draft.private = [findTopicGroup, ...removeTopic];
				}

				break;
			case LEAVE_CHANNEL_SUCCESS:
				if (action?.payload?.newAdmin) {
					const indexOfChannel = findIndexFunc(
						state?.channel,
						action?.payload?.topicId,
						"topicId",
					);
					if (state?.channel[indexOfChannel]) {
						const newChannel = changeStateValue({
							state: state?.channel,
							position: indexOfChannel,
							value: action?.payload?.newAdmin,
							changeValue: "ownerId",
						});
						const newArr = newChannel.filter(
							(item) => item.topicId !== action.payload.topicId,
						);
						draft.channel = newArr;
					}
				} else {
					const newArr = state?.channel?.filter(
						(item) => item?.topicId !== action.payload?.topicId,
					);
					draft.channel = newArr;
				}
				break;
			case LEAVE_CHANNEL_SUCCESS_LOADING:
				draft.leaveChannelLoading = action.payload;
				break;
			case EDIT_PAGE:
				draft.page = action.payload;
				break;
			case SET_TOTAL_MESSAGES:
				draft.totalMessages = action.payload;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = action.payload;
				break;
			case SET_LOADER_PLACEMENT:
				draft.loaderPlacement = action.payload;
				break;
			case SET_FETCHED_PAGES:
				draft.fetchedPages = action.payload;
				break;
			case SET_SEARCH_RESULTS:
				const {messages} = action.payload;
				if (!messages) {
					draft.searchResults = [];
				} else if (state?.query?.page > 1) {
					draft.searchResults = [];
					draft.searchResults = [...state?.searchResults, ...messages];
				} else {
					draft.searchResults = messages;
				}
				break;
			case SET_SEARCH_PAGE:
				draft.searchPage = action.payload;
				break;
			case SET_QUERY:
				draft.query = action.payload;
				break;
			case SET_SEARCH_TOTAL_PAGES:
				draft.searchTotalPages = action.payload;
				break;
			case CLEAR_QUERY:
				draft.query = {
					page: 1,
					size: 15,
					search: "",
					topicId: null,
				};
				draft.searchResults = [];
				draft.totalFiles = {
					...state?.totalFiles,
					files: [],
				};
				break;
			case SET_SEARCH_LOADING:
				draft.searchLoading = action.payload;
				break;
			case SET_TOTAL_FILES_SUCCESS:
				const {page, size, totalFiles, totalPages, files} = action.payload;
				draft.totalFiles = {
					files: page !== 1 ? [...state?.totalFiles?.files, ...files] : files,
					page,
					size,
					totalFiles,
					totalPages,
				};
				break;
			case SET_MEDIA_LOADING:
				draft.mediaLoading = action.payload;
				break;
			case SET_WRITE_ACCESS_SUCCESS:
				const {userId, writeAccess} = action.payload;
				const members = state?.insideTopic?.topicMembers;
				const memberToSetAccessIndex = findIndexFunc(members, userId, "id");
				if (members[memberToSetAccessIndex]) {
					const newTopicMembers = changeStateValue({
						state: members,
						position: memberToSetAccessIndex,
						value: writeAccess,
						changeValue: "writeAccess",
					});
					draft.insideTopic.topicMembers = newTopicMembers;
				}
				break;
			case SET_UNSEEN_MESSAGES:
				const {topicId, value} = action.payload;
				const privateIndex = findIndexFunc(state?.private, topicId, "topicId");
				const channelIndex = findIndexFunc(state?.channel, topicId, "topicId");
				if (state?.channel[channelIndex]) {
					const newChannels = changeStateValue({
						state: state?.channel,
						position: channelIndex,
						value:
							value === "increment"
								? state?.channel[channelIndex]?.notSeenMessages + 1
								: value,
						changeValue: "notSeenMessages",
					});
					draft.channel = newChannels;
				} else if (state?.private[privateIndex]) {
					const newPrivate = changeStateValue({
						state: state?.private,
						position: privateIndex,
						value:
							value === "increment"
								? state?.private[privateIndex]?.notSeenMessages + 1
								: value,
						changeValue: "notSeenMessages",
					});
					draft.private = newPrivate;
				}
				break;
			case SET_LAST_MESSAGE:
				const {topicId: id, lastMessage} = action.payload;
				const privateTopicIndex = findIndexFunc(state?.private, id, "topicId");
				const channelTopicIndex = findIndexFunc(state?.channel, id, "topicId");
				if (state?.channel[channelTopicIndex]) {
					const newChannels = changeStateValue({
						state: state?.channel,
						position: channelTopicIndex,
						value: lastMessage,
						changeValue: "lastMessage",
					});
					draft.channel = newChannels;
				} else if (state?.private[privateTopicIndex]) {
					const newPrivate = changeStateValue({
						state: state.private,
						position: privateTopicIndex,
						value: lastMessage,
						changeValue: "lastMessage",
					});
					draft.private = newPrivate;
				}
				break;
			case DELETE_SINGLE_MESSAGE_SUCCESS:
				const copiedMessages = [...state?.insideTopic?.messages];
				const {messageToDeleteId, loggedUserId} = action?.payload;
				const messageIdx = findIndexFunc(copiedMessages, messageToDeleteId, "id");
				if (messageIdx !== -1) {
					const message = copiedMessages[messageIdx];
					copiedMessages[messageIdx] = {
						...message,
						deletedAt: moment().valueOf(),
					};
				}
				draft.insideTopic = {
					...state.insideTopic,
					messages: [...copiedMessages],
				};
				break;
			case REPLACE_DELETED_MESSAGE:
				const allMessagesOfTopic = [...state.insideTopic.messages];
				const messageIndex = findIndexFunc(allMessagesOfTopic, action.payload.id, "id");
				if (messageIndex !== -1) {
					allMessagesOfTopic[messageIndex] = action.payload;
				}
				draft.insideTopic = {
					...state.insideTopic,
					messages: [...allMessagesOfTopic],
				};
				break;
			case GET_LINKS_SUCCESS:
				draft.links =
					state.query.page > 1
						? [...state.links, ...action?.payload.messages]
						: action?.payload.messages;
				draft.linkTotalPages = action.payload.totalPages;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	fetchAllTopics: (payload) => createAction(FETCH_ALL_TOPICS, {payload}),
	fetchAllTopicsSuccess: (payload) => createAction(FETCH_ALL_TOPICS_SUCCESS, {payload}),
	fetchAllTopicsLoading: (payload) => createAction(FETCH_ALL_TOPICS_LOADING, {payload}),
	fetchTopicsByUserId: (payload) => createAction(FETCH_TOPICS_BY_ID, {payload}),
	fetchTopicsByUserIdSuccess: (payload) => createAction(FETCH_TOPICS_BY_ID_SUCCESS, {payload}),
	fetchTopicsByTopicId: (payload) => createAction(FETCH_TOPICS_BY_TOPIC_ID, {payload}),
	fetchTopicsByTopicIdSuccess: (payload) =>
		createAction(FETCH_TOPICS_BY_TOPIC_ID_SUCCESS, {payload}),
	fetchTopicLoading: (payload) => createAction(FETCH_TOPIC_LOADING, {payload}),
	addMessageInsideTopic: (payload) => createAction(ADD_MESSAGE_INSIDE_TOPIC, {payload}),
	updateMessageInsideTopic: (payload) => createAction(UPDATE_MESSAGE_INSIDE_TOPIC, {payload}),
	setTopicId: (payload) => createAction(SET_TOPIC_ID, {payload}),
	setInsideTopicToEmpty: (payload) => createAction(SET_INSIDE_TOPIC_TO_EMPTY, {payload}),
	createGroupChannelSuccess: (payload) => createAction(CREATE_GROUP_CHANNEL_SUCCESS, {payload}),

	seenMessage: (payload) => createAction(SEEN_MESSAGE, {payload}),
	seenMessageSuccess: (payload) => createAction(SEEN_MESSAGE_SUCCESS, {payload}),
	changeTopicOrder: (payload) => createAction(CHANGE_TOPIC_ORDER, {payload}),
	addUserToChannel: (payload) => createAction(ADD_USER_TO_CHANNEL, {payload}),
	addUserToChannelSuccess: (payload) => createAction(ADD_USER_TO_CHANNEL_SUCCESS, {payload}),
	removeUserFromChannel: (payload) => createAction(REMOVE_USER_FROM_CHANNEL, {payload}),
	removeUserFromChannelSuccess: (payload) =>
		createAction(REMOVE_USER_FROM_CHANNEL_SUCCESS, {payload}),
	removeUserFromGroupSuccess: (payload) =>
		createAction(REMOVE_USER_FROM_GROUP_SUCCESS, {payload}),
	leaveChannelSuccess: (payload) => createAction(LEAVE_CHANNEL_SUCCESS, {payload}),
	leaveChannelLoading: (payload) => createAction(LEAVE_CHANNEL_SUCCESS_LOADING, {payload}),
	setPage: (payload) => createAction(EDIT_PAGE, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	setTotalMessages: (payload) => createAction(SET_TOTAL_MESSAGES, {payload}),
	setLoaderPlacement: (payload) => createAction(SET_LOADER_PLACEMENT, {payload}),
	setFetchedPages: (payload) => createAction(SET_FETCHED_PAGES, {payload}),
	getMessagePage: (payload) => createAction(GET_MESSAGE_PAGE, {payload}),
	searchMessagesInsideTopic: (payload) => createAction(SEARCH_MESSAGES_INSIDE_TOPIC, {payload}),
	setSearchResults: (payload) => createAction(SET_SEARCH_RESULTS, {payload}),
	setSearchPage: (payload) => createAction(SET_SEARCH_PAGE, {payload}),
	setQuery: (payload) => createAction(SET_QUERY, {payload}),
	setSearchTotalPages: (payload) => createAction(SET_SEARCH_TOTAL_PAGES, {payload}),
	clearQuery: (payload) => createAction(CLEAR_QUERY, {payload}),
	setSearchLoading: (payload) => createAction(SET_SEARCH_LOADING, {payload}),
	setTotalFilesSuccess: (payload) => createAction(SET_TOTAL_FILES_SUCCESS, {payload}),
	getFiles: (payload) => createAction(GET_FILES, {payload}),
	setMediaLoading: (payload) => createAction(SET_MEDIA_LOADING, {payload}),
	setWriteAccess: (payload) => createAction(SET_WRITE_ACCESS, {payload}),
	setWriteAccessSuccess: (payload) => createAction(SET_WRITE_ACCESS_SUCCESS, {payload}),
	leaveChannel: (payload) => createAction(LEAVE_CHANNEL, {payload}),
	setUnseenMessages: (payload) => createAction(SET_UNSEEN_MESSAGES, {payload}),
	setLastMessage: (payload) => createAction(SET_LAST_MESSAGE, {payload}),
	addUserToGroupSuccess: (payload) => createAction(ADD_USER_TO_GROUP_SUCCESS, {payload}),
	deleteSingleMessageSuccess: (payload) => createAction(DELETE_SINGLE_MESSAGE_SUCCESS, {payload}),
	replaceDeletedMessage: (payload) => createAction(REPLACE_DELETED_MESSAGE, {payload}),
	getLinks: (payload) => createAction(GET_LINKS, {payload}),
	getLinksSuccess: (payload) => createAction(GET_LINKS_SUCCESS, {payload}),
};

export const sagas = {
	*fetchAllTopics({payload}) {
		yield put(actions.fetchAllTopicsLoading(true));
		try {
			const response = yield axios.get(`/messages/${payload}`);

			yield put(actions.fetchAllTopicsSuccess([response?.data.data, payload]));
		} catch (error) {
			logger.error(error);
		} finally {
			logger.info("SUCCESS");
			yield put(actions.fetchAllTopicsLoading(false));
		}
	},
	*fetchTopicsByUserId(id) {
		try {
			yield axios.get(`/messages/with/${id.payload}`);
		} catch (error) {
			logger.error(error);
		} finally {
			logger.info("SUCCESS");
		}
	},
	*fetchTopicsByTopicId(id) {
		yield put(actions.fetchTopicLoading(true));
		try {
			const {page, size} = yield select((state) => state.app.messages.index);
			const response = yield axios.get(
				`/messages/topic/${id.payload}?page=${page}&size=${size}`,
			);
			yield put(actions.fetchTopicsByTopicIdSuccess(response?.data.data));
			yield put(
				actions.seenMessage({
					topicId: response?.data.data?.topicId,
					type: response?.data?.data?.topicType,
					value: true,
				}),
			);
			if (
				response.data.data.topicType === "CHANNEL" ||
				response.data.data.topicType === "MAINCHANNEL"
			) {
				yield put(createTopicActions.setChannelUsers(response.data.data.topicMembers));
			} else if (response.data.data.topicType === "GROUP") {
				yield put(createTopicActions.setGroupUsers(response.data.data.topicMembers));
			}
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
			yield put(actions.setTotalMessages(response?.data?.data?.totalMessages));
		} catch (error) {
			logger.error(error);
		} finally {
			logger.info("SUCCESS");
			yield put(actions.fetchTopicLoading(false));
		}
	},
	*getMessagePage({payload}) {
		try {
			const {topicId, replyId} = payload;
			const {size} = yield select((state) => state.app.messages.index);
			const response = yield axios.get(
				`messages/topic/page/?topicId=${topicId}&replyId=${replyId}&pageSize=${size}`,
			);
			yield put(actions.setPage(response?.data?.data?.page));
			yield put(actions.setLoaderPlacement("NOLOADER"));
		} catch (error) {
			logger.error(error);
		}
	},
	*getFiles() {
		yield put(actions.setMediaLoading(true));
		try {
			const {page, size, topicId} = yield select((state) => state.app.messages.index.query);
			const response = yield axios.get(
				`messages/topic/files?topicId=${topicId}&page=${page}&size=${size}`,
			);
			yield put(actions.setTotalFilesSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setMediaLoading(false));
		}
	},
	*setWriteAccess({payload}) {
		try {
			const response = yield axios.put(`/messages/topic/writeaccess`, payload);
			yield put(actions.setWriteAccessSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*seenMessage({payload}) {
		try {
			yield axios.post(`/messages/seen/${payload?.topicId}`);
			yield put(actions.seenMessageSuccess(payload));
			yield put(actions.setUnseenMessages({topicId: payload.topicId, value: 0}));
		} catch (error) {
			return error;
		}
	},
	*searchMessagesInsideTopic() {
		yield put(actions.setSearchLoading(true));
		try {
			const {page, size, search, topicId} = yield select(
				(state) => state.app.messages.index.query,
			);

			const response = yield axios.get(
				`/messages/topic/search?topicId=${topicId}
        &search=${search}&page=${page}&size=${size}`,
			);
			yield put(actions.setSearchResults(response?.data?.data));
			yield put(actions.setSearchTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setSearchLoading(false));
		}
	},
	*removeUserFromChannel({payload}) {
		const {channelInitialValues, groupInitialValues} = yield select(
			(state) => state.app.messages.create,
		);
		yield put(actions.leaveChannelLoading(true));
		try {
			const response = yield axios.put(`/messages/user/remove/`, payload);
			const newArr = channelInitialValues.users.filter((item) => item.id !== payload.userId);
			const newGroupArr = groupInitialValues.users.filter(
				(item) => item.id !== payload.userId,
			);
			yield put(actions.addMessageInsideTopic(response?.data?.data));
			if (payload?.type === "CHANNEL" || payload?.type === "MAINCHANNEL") {
				yield put(createTopicActions.setChannelUsers(newArr));
				yield put(
					actions.removeUserFromChannelSuccess({
						topicId: payload?.topicId,
						data: newArr,
					}),
				);
			} else if (payload?.type === "GROUP") {
				yield put(createTopicActions.setGroupUsers(newGroupArr));
				yield put(
					actions.removeUserFromGroupSuccess({
						topicId: payload?.topicId,
						data: newGroupArr,
					}),
				);
			}

			yield put(createTopicActions.setShowModal(false));
			yield put(actions.leaveChannelLoading(false));
		} catch (error) {
			return error;
		}
	},
	*leaveChannel({payload}) {
		try {
			let url = `/messages/channel/${payload?.topicId}`;
			if (payload?.newAdmin) {
				url += `?userId=${payload?.newAdmin}`;
			}

			yield axios.put(url);
			yield put(
				actions.leaveChannelSuccess({
					topicId: payload?.topicId,
					newAdmin: payload?.newAdmin,
				}),
			);
			yield put(navigateActions.navigate("/admin/messages/"));
		} catch (error) {
			ToastErrorComponent(error.response.data.message);
			logger.error(error);
		}
	},
	*getLinks() {
		yield put(actions.setMediaLoading(true));
		try {
			const {page, size, topicId} = yield select((state) => state.app.messages.index.query);
			const response = yield axios.get(
				`messages/topic/links?topicId=${topicId}&page=${page}&size=${size}`,
			);
			yield put(actions.getLinksSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setMediaLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeEvery(FETCH_ALL_TOPICS, sagas.fetchAllTopics);
	yield takeLatest(FETCH_TOPICS_BY_ID, sagas.fetchTopicsByUserId);
	yield takeLatest(FETCH_TOPICS_BY_TOPIC_ID, sagas.fetchTopicsByTopicId);
	yield takeLatest(SEEN_MESSAGE, sagas.seenMessage);
	yield takeLatest(REMOVE_USER_FROM_CHANNEL, sagas.removeUserFromChannel);
	yield takeLatest(GET_MESSAGE_PAGE, sagas.getMessagePage);
	yield takeLatest(SEARCH_MESSAGES_INSIDE_TOPIC, sagas.searchMessagesInsideTopic);
	yield takeLatest(GET_FILES, sagas.getFiles);
	yield takeLatest(SET_WRITE_ACCESS, sagas.setWriteAccess);
	yield takeLatest(LEAVE_CHANNEL, sagas.leaveChannel);
	yield takeLatest(GET_LINKS, sagas.getLinks);
};
