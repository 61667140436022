import MaterialTable, {MTableActions} from "material-table";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Closed from "@assets/img/expandPurpleDown.svg";
import Opened from "@assets/img/expandPurpleUp.svg";
import {ReactComponent as File} from "@assets/img/description_black_24dp.svg";

const TableRemote = ({
	data,
	title,
	columns,
	actions,
	localization,
	headerColor = null,
	parentChildData = null,
	selection = false,
	minBodyHeight = 0,
	maxBodyHeight = 500,
	fetchFunction,
	separatedPagination,
	separatedSetPage,
	separatedSetSize,
	usePagination = true,
	fetchById,
	refTable = null,
	useCustomRef = false,
	details = null,
	onRowClickTrue,
	exportButton = {
		csv: false,
		pdf: false,
	},
	setToolbar,
	toggle = false,
	onSelectionChange,
	actionsColumnIndex = -1,
	fileName,
	customOptions,
	pageSizeOptions = [5, 10, 30, 50, 100],
	exportAriaLabel,
	exportTitle,
	exportName,
	headerStyleOptions = {},
}) => {
	const {t} = useTranslation();
	const {totalSize, size, page, search} = separatedPagination;
	const tableRef = React.createRef();
	const editableData = data?.map((o) => ({...o})) ?? [];
	const [showTable, setShowTable] = useState(!toggle);
	useEffect(() => {
		if (fetchFunction) {
			if (fetchById) {
				fetchFunction(fetchById);
			} else {
				fetchFunction({size, page, search});
			}
		}
		// eslint-disable-next-line
	}, [size, page, fetchFunction, search]);

	useEffect(() => {
		if (useCustomRef) {
			refTable.current.onQueryChange();
		} else {
			tableRef.current.onQueryChange();
		}
		// eslint-disable-next-line
	}, [tableRef, refTable, data]);

	return (
		<>
			{toggle && (
				<div onClick={() => setShowTable(!showTable)} className="toggle-container">
					<span>
						{t(`Deactivated`)} ({editableData.length})
					</span>
					<img alt="" src={showTable ? Closed : Opened} />
				</div>
			)}

			<MaterialTable
				actions={actions}
				columns={columns}
				localization={{
					header: {actions: t("Actions")},
					body: {
						localization,
						emptyDataSourceMessage: (
							<div className="material__table__no__data">
								<File className="material__table__no__data__img" />
								<p className="material__table__no__data__text"> {t("NoData")} </p>
							</div>
						),
					},
					pagination: {
						labelDisplayedRows: `{from}-{to} ${t("Of")} {count}`,
						labelRowsSelect: t("Rows"),
						labelRowsPerPage: t("RowsPerPage"),
						firstAriaLabel: t("FirstPage"),
						firstTooltip: t("FirstPage"),
						previousAriaLabel: t("PreviousPage"),
						previousTooltip: t("PreviousPage"),
						nextAriaLabel: t("NextPage"),
						nextTooltip: t("NextPage"),
						lastAriaLabel: t("LastPage"),
						lastTooltip: t("LastPage"),
					},
					toolbar: {
						exportTitle,
						exportAriaLabel,
						exportName,
						// exportTitle: "Down-Load Auftragsliste",
						// exportAriaLabel: "Down-Load Auftragsliste",
						// exportName: "Down-Load Auftragsliste",
						// nRowsSelected: `{0} ${t("RowsSelected")}`,
						nRowsSelected: `{0} ${t("RowsSelected")}`,
					},
				}}
				components={{
					Actions: (props) => (
						<div className="actions-custom">
							<MTableActions {...props} />
						</div>
					),
				}}
				data={() =>
					new Promise((resolve) => {
						resolve({
							data: editableData,
							page: page - 1,
							totalCount: totalSize,
						});
					})
				}
				onChangePage={(page) => {
					separatedSetPage(page);
				}}
				onChangeRowsPerPage={(size) => separatedSetSize(size)}
				options={{
					exportButton,
					exportFileName: fileName,
					actionsColumnIndex,
					headerStyle: {
						backgroundColor: headerColor,
						position: "sticky",
						top: 0,
						fontWeight: 600,
						...headerStyleOptions,
					},
					minBodyHeight,
					maxBodyHeight: showTable ? maxBodyHeight : 0,
					rowStyle: {
						border: "0px",
					},
					pageSizeOptions,
					pageSize: size,
					draggable: false,
					filtering: false,
					sorting: true,
					search: false,
					toolbar: setToolbar,
					emptyRowsWhenPaging: false,
					searchAutoFocus: true,
					paging: showTable ? usePagination : false,
					selection,
					// selectionProps: (rowData) => ({
					// 	disabled: !rowData.exportPdf,
					// }),
					actionsCellStyle: {
						justifyContent: "flex-end",
					},
					...customOptions,
				}}
				parentChildData={parentChildData}
				// style={{borderRadius: ""}}
				tableRef={useCustomRef ? refTable : tableRef}
				title={title || ""}
				totalCount={60}
				detailPanel={details}
				onRowClick={onRowClickTrue ? (event, rowData, togglePanel) => togglePanel() : null}
				onSelectionChange={onSelectionChange}
			/>
		</>
	);
};

export default TableRemote;
