import {
	CloseUploadModalIcon,
	SelectExpandIcon,
	SelectIcon,
	ToggleAccordionIcon2,
} from "@src/assets/SvgComponents";
import {Select} from "antd";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SelectedTeamMember from "../Select/SelectedTeamMember";
import {filterOptions, filterState, handleOptionClick} from "./helper";

const GroupedSelect = ({
	options,
	label,
	touched,
	errors,
	values = [],
	field,
	setFieldValue,
	customRender = false,
	onDeselect,
	onSetLead,
	disabled,
	newClassName = "",
	handleSubmit,
	type = "id",
	renderInnerModal,
	innerModal,
	datesModal,
	setDatesModal,
	placeholder,
	width = "100%",
	id,
}) => {
	const {t} = useTranslation();
	const [stateOptions, setStateOptions] = useState([]);
	const [reEdit, setReEdit] = useState(true);
	const [inputValue, setInputValue] = useState("");
	const [expanded, setExpanded] = useState("USERS");
	const isExpanded = (type) => expanded === type;
	const onGroupClick = (type) => setExpanded((prevState) => (prevState === type ? null : type));
	useEffect(() => {
		setReEdit(true);
	}, [id]);
	useEffect(() => {
		if (reEdit) {
			setStateOptions(options);
		}
	}, [options, reEdit]);
	const filterItem = (opt) => {
		if (handleSubmit && type === "id") {
			handleSubmit();
		}
		const newState = filterState(values, opt);
		setFieldValue(field, newState);
	};
	const dropdownRenderer = () => (
		<div className="gruped__select-wrapper">
			{stateOptions?.map((opt) => (
				<div key={opt?.type} className="gruped__select-wrapper-item">
					<div
						className="gruped__select-wrapper-item-header"
						onClick={() => onGroupClick(opt?.type)}
					>
						<span className="item__name">{opt?.label}</span>
						<span className={`item__icon ${isExpanded(opt?.type) && "expanded"}`}>
							<SelectExpandIcon />
						</span>
					</div>
					{isExpanded(opt?.type) && opt?.options?.[0] && (
						<div className="children_wrapper">
							{filterOptions(opt?.options, inputValue)?.map((option) => (
								<div
									key={opt?.value}
									className="children_wrapper-item"
									onClick={() =>
										handleOptionClick(
											setFieldValue,
											values,
											option,
											field,
											handleSubmit,
											type,
											setStateOptions,
											stateOptions,
											opt?.type,
											setReEdit,
										)
									}
								>
									{option.checked && option.options?.length !== 0 && (
										<SelectIcon />
									)}
									<span className="item__name">{option?.label}</span>
								</div>
							))}
						</div>
					)}
				</div>
			))}
		</div>
	);
	return (
		<div style={{width}} className={`customdropdown__wrapper ${newClassName}`}>
			{label && <span className="select__title">{label}</span>}
			<Select
				searchValue={inputValue}
				onSearch={(value) => setInputValue(value)}
				showSearch
				options={stateOptions}
				dropdownRender={dropdownRenderer}
				suffixIcon={<ToggleAccordionIcon2 />}
				disabled={disabled}
				placeholder={placeholder}
				dropdownStyle={{zIndex: 9999}}
			/>
			{touched && errors && <span className="errorClass">*{errors}</span>}
			{!customRender && (
				<div className="options-wrapper">
					{values?.[0] ? (
						values?.map((opt, index) => (
							<div key={`${opt?.value}---${index}`} className="options-wrapper-item">
								<span className="options-wrapper-item-text">{opt?.label}</span>
								<span
									className="options-wrapper-item-clearIcon"
									onClick={() => filterItem(opt)}
								>
									<CloseUploadModalIcon fill="#fff" width="10" height="10" />
								</span>
							</div>
						))
					) : (
						<div className="options-wrapper-no_data">
							<span>*{t("NoData")}</span>
						</div>
					)}
				</div>
			)}
			{customRender && values?.[0] && (
				<div className="custom-render-wrapper">
					{values?.map((item) => (
						<SelectedTeamMember
							user={item}
							onDeselect={onDeselect}
							onSetLead={onSetLead}
							renderInnerModal={renderInnerModal}
							innerModal={innerModal}
							datesModal={datesModal}
							setDatesModal={setDatesModal}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default GroupedSelect;
