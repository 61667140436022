import CryptoJS from "crypto-js";
const {REACT_APP_ROLE_SECRET} = process.env;
export function encryptData(data) {
	return CryptoJS.AES.encrypt(JSON.stringify(data), REACT_APP_ROLE_SECRET).toString();
}

export function decryptData(encryptedData) {
	const bytes = CryptoJS.AES.decrypt(encryptedData, REACT_APP_ROLE_SECRET);
	return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}
