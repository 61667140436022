export const filterFunc = (state, value, comparedValue) => {
	const filteredState = state?.filter((item) => item?.[value] !== comparedValue);
	return filteredState;
};
export function reorderSections(sections, draggedSectionId, newNr) {
	// Find the dragged section
	const draggedSection = sections.find((section) => section.id === draggedSectionId);

	if (!draggedSection) return sections; // If section doesn't exist, return the original list

	// Remove the dragged section from the array
	const updatedSections = sections.filter((section) => section.id !== draggedSectionId);

	// Re-insert the dragged section at the new `nr` position
	updatedSections.splice(newNr - 1, 0, draggedSection);

	// Recalculate the `nr` values so they remain sequential from 1
	const reorderedSections = updatedSections.map((section, index) => ({
		...section,
		nr: index + 1,
	}));

	return reorderedSections;
}

export function handleSortSections(payload, allSections) {
	const {previousSortNr, targetSortNr, sectionId} = payload;
	const section = allSections?.find((sect) => sect.id === sectionId);

	if (!section) {
		return allSections; // If section doesn't exist, return original sections
	}

	// Adjust the affected sections based on the move direction
	let updatedSectionList = allSections.map((sec) => {
		// Skip the dragged section in the first pass
		if (sec.id === sectionId) return sec;

		// Moved down, adjust sections with nr between previousSortNr and targetSortNr
		if (targetSortNr > previousSortNr && sec.nr > previousSortNr && sec.nr <= targetSortNr) {
			return {...sec, nr: sec.nr - 1};
		}

		// Moved up, adjust sections with nr between targetSortNr and previousSortNr
		if (targetSortNr < previousSortNr && sec.nr >= targetSortNr && sec.nr < previousSortNr) {
			return {...sec, nr: sec.nr + 1};
		}

		// For sections outside the affected range, return them unchanged
		return sec;
	});

	// Update the dragged section's nr and insert it back into the correct position
	const updatedSection = {...section, nr: targetSortNr};

	// Replace or update the dragged section in the list
	updatedSectionList = updatedSectionList.map((sec) =>
		sec.id === sectionId ? updatedSection : sec,
	);

	// Return the updated section list
	return updatedSectionList?.sort((a, b) => a.nr - b.nr);
}
