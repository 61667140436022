import {getCurrentUsersRole} from "./currentUser";
const roles = [
	"ADMIN",
	"TENANT",
	"CLIENT",
	"SP",
	"EMPLOYEE",
	"AGENCY",
	"AGENCY-SP",
	"BACK-OFFICE",
	"MANAGEMENTINFO",
	"CLIENT",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_TL",
	"EMPLOYEE_AB",
];
const permissionsAdmin = [
	"settleContracts",
	"setPaidDate",
	"addUsers",
	"editUsers",
	"activateUsers",
	"deactivateUsers",
	"deleteUsers",
	"exportUsers",
	"createTeam",
	"selectUsers",
	"editTeam",
	"deleteTeam",
	"editOrder",
	"downloadOrder",
	"changeStatusOrder",
	"exportOrders",
	"addClient",
	"editClient",
	"deactivateClient",
	"activateClient",
	"addlocations",
	"deactivateLocation",
	"editLocation",
	"deleteLocation",
	"activateLocation",
	"changeOrderUpLocation",
	"changeOrderDownLocation",
	"addcategory",
	"deactivateCategory",
	"editCategory",
	"editBonusCategory",
	"deleteCategory",
	"activateCategory",
	"changeOrderUpCategory",
	"changeOrderDownCategory",
	"addtariff",
	"deactivateTariff",
	"editTariff",
	"deleteTariff",
	"activateTariff",
	"changeOrderUpTariff",
	"changeOrderDownTariff",
	"exportTariff",
	"addproduct",
	"deactivateProducts",
	"editProducts",
	"deleteProducts",
	"activateProducts",
	"changeOrderUpProducts",
	"changeOrderDownProducts",
	"editClientSettings",
	"addTemplate",
	"editTemplate",
	"addCategoryTemplate",
	"deleteTemplate",
	"draftTemplate",
	"changeClient",
	"seeInfoChef",
	"filterTeams",
	"filterAgencies",
	"filterStatus",
	"seeTopStats",
	"seeUsersStats",
	"seeLast30Orders",
	"seeSelect",
	"seeImports",
	"editManualCorr",
	"billingDetails",
	"createStatus",
	"createLeadStatus",
	"sortLeads",
	"moveStatuses",
	"deleteSettlements",
	"addConditions",
	"filterWithUser",
	"filterAddUser",
	"importOrder",
	"deleteLeads",
	"getGoals",
	"exportCalculation",
	"seeLeaderboard",
	"userProfileStatistic",
	"seeDashboard1",
	"seeDashboard2",
	"seeReceivedCommission",
	"seeStatistics",
	"seeTimeline",
	"addSalesOrganization",
	"editSalesOrg",
	"deleteSalesOrg",
	"seeTotalRevenue",
	"createOrderStatusImport",
	"createOrderImport",
	"createLeadImport",
	// urls
	"seeDashboard",
	"seeTenants",
	"seeTenantSettings",
	"seeSPUserDetails",
	"seeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeUsers",
	"seeClients",
	"seeClient",
	"seeImports",
	"seeInsideImport",
	"seeUpdatedStatuses",
	"seeImportCsv",
	"seeInsideOrderImport",
	"seeTrainings",
	"seeSingleTraining",
	"seeAddEditTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeEnergy",
	"seeImportsPage",
	"seeCreateImport",
	"seeOrderTables",
	"seePreviewCsv",
	"seePreviewCsvById",
	"seeCreatePdf",
	"seeCalculationImportTable",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayUsers",
	"displayClients",
	"displayOrders",
	"displayTenants",
	"displayEnergy",
	"displayImports",
	"displaySettings",
	"displaySupport",
	"setGroupViewGoals",
	"createGroupViewGoals",
	"createGoals",
	"seeActionsGoals",
	"searchGoals",
	"addCardToDashboardGoals",
	"addCardToMobileGoals",
	"removeCardToDashboardGoals",
	"removeCardToMobileGoals",
	"editGoals",
	"deleteGoal",
	"seeManagerVo",
	"seePersonnelDevelopment",
	"seeInductionBonus",
	"seeMobilityBonus",
	"seeOwnContracts",
	"seeOverhead",
	"seeCareerConditions",
	// teamactions
	"searchUsers",
	// condition actions
	"editCondition",
	"duplicateCondition",
	"deleteCondition",
	// monitoring actions
	"monitoringTrainer",
	"monitoringLeader",
	"monitoringHoD",
	"maintainStatusTrainer",
	"maintainStatusLeader",
	"maintainStatusHoD",
	"editMonitoringLeader",
	"editMonitoringHoD",
	"editMaintainStatusTrainer",
	"printSalesOrganization",
	// Energy
	"AddFilterEnergy",
	// order actions,
	"filterByClientType",
	"replaceOnUploadPdf",
	// client settings actions
	"uploadClientLogo",
	"seeEmailOnClientSettings",
	"seeContractsOnClientSettings",
	// statistic and documents
	"seeSaveOnStatisticAndDocument",
	"seePrintOnStatisticAndDocument",
	"uploadStatisticAndDocument",
	"editOnStatisticAndDocument",
	// uploadPDF
	"editOnUploadPdf",
	"deleteOnUploadPdf",
	// order actions,
	"filterByClientType",
	"filterCalculations",
];
const permissionsTenant = [
	"settleContracts",
	"setPaidDate",
	"addUsers",
	"editUsers",
	"activateUsers",
	"deactivateUsers",
	"deleteUsers",
	"exportUsers",
	"createTeam",
	"selectUsers",
	"editTeam",
	"deleteTeam",
	"editOrder",
	"downloadOrder",
	"changeStatusOrder",
	"exportOrders",
	"addClient",
	"editClient",
	"deactivateClient",
	"activateClient",
	"addlocations",
	"deactivateLocation",
	"editLocation",
	"deleteLocation",
	"activateLocation",
	"changeOrderUpLocation",
	"changeOrderDownLocation",
	"addcategory",
	"deactivateCategory",
	"editCategory",
	"editBonusCategory",
	"deleteCategory",
	"activateCategory",
	"changeOrderUpCategory",
	"changeOrderDownCategory",
	"addtariff",
	"deactivateTariff",
	"editTariff",
	"deleteTariff",
	"activateTariff",
	"changeOrderUpTariff",
	"changeOrderDownTariff",
	"exportTariff",
	"addproduct",
	"deactivateProducts",
	"editProducts",
	"deleteProducts",
	"activateProducts",
	"changeOrderUpProducts",
	"changeOrderDownProducts",
	"editClientSettings",
	"addTemplate",
	"editTemplate",
	"addCategoryTemplate",
	"deleteTemplate",
	"draftTemplate",
	"changeClient",
	"seeInfoChef",
	"filterTeams",
	"filterAgencies",
	"filterStatus",
	"seeTopStats",
	"seeUsersStats",
	"seeLast30Orders",
	"seeSelect",
	"seeImports",
	"editManualCorr",
	"billingDetails",
	"createStatus",
	"createLeadStatus",
	"sortLeads",
	"moveStatuses",
	"deleteSettlements",
	"addConditions",
	"filterWithUser",
	"filterAddUser",
	"importOrder",
	"deleteLeads",
	"getGoals",
	"exportCalculation",
	"seeLeaderboard",
	"userProfileStatistic",
	"seeDashboard1",
	"seeDashboard2",
	"seeReceivedCommission",
	"seeStatistics",
	"seeTimeline",
	"addSalesOrganization",
	"editSalesOrg",
	"deleteSalesOrg",
	"seeTotalRevenue",
	"createOrderStatusImport",
	"createOrderImport",
	"createLeadImport",
	// urls
	"seeDashboard",
	"seeUsers",
	"seeCreateEditUsers",
	"seeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeClients",
	"seeClient",
	"seeImports",
	"seeInsideImport",
	"seeUpdatedStatuses",
	"seeImportCsv",
	"seeInsideOrderImport",
	"seeTrainings",
	"seeSingleTraining",
	"seeAddEditTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeEnergy",
	"seeImportsPage",
	"seeCreateImport",
	"seeOrderTables",
	"seePreviewCsv",
	"seePreviewCsvById",
	"seeSalesTrackerInsideImport",
	"seeCreatePdf",
	"seeCalculationImportTable",
	"seeSupport",
	"seeLockedComponent",
	"seeTeams",
	"seeAgencies",
	"seeSalesOrganizations",
	"seeManagerVo",
	"seePersonnelDevelopment",
	"seeInductionBonus",
	"seeMobilityBonus",
	"seeOwnContracts",
	"seeOverhead",
	"seeCareerConditions",
	// drawer
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayUsers",
	"displayClients",
	"displayOrders",
	"displayEnergy",
	"displayImports",
	"displaySettings",
	"displaySupport",
	// goals actions
	"setGroupViewGoals",
	"createGroupViewGoals",
	"createGoals",
	"seeActionsGoals",
	"searchGoals",
	"addCardToDashboardGoals",
	"addCardToMobileGoals",
	"removeCardToDashboardGoals",
	"removeCardToMobileGoals",
	"editGoals",
	"deleteGoal",
	// team actions
	"searchUsers",
	// condition actions
	"editCondition",
	"duplicateCondition",
	"deleteCondition",
	// monitoring actions
	"monitoringTrainer",
	"monitoringLeader",
	"monitoringHoD",
	"maintainStatusTrainer",
	"maintainStatusLeader",
	"maintainStatusHoD",
	"editMonitoringLeader",
	"editMonitoringHoD",
	"editMaintainStatusTrainer",
	// sales organizations actions
	"printSalesOrganization",
	"seeEnergyTabOrders",
	"addUsersOnLeads",
	// client settings actions
	"uploadClientLogo",
	"seeEmailOnClientSettings",
	"seeContractsOnClientSettings",
	// statistic and documents
	"seeSaveOnStatisticAndDocument",
	"seePrintOnStatisticAndDocument",
	"uploadStatisticAndDocument",
	"editOnStatisticAndDocument",
	// uploadPDF
	"editOnUploadPdf",
	"deleteOnUploadPdf",
	// Energy
	"AddFilterEnergy",
	// order actions,
	"filterByClientType",
	// calculation actions
	"filterCalculations",
];

const permissionsBackOffice = [
	"settleContracts",
	"setPaidDate",
	"addUsers",
	"editUsers",
	"activateUsers",
	"deactivateUsers",
	"deleteUsers",
	"exportUsers",
	"createTeam",
	"selectUsers",
	"editTeam",
	"deleteTeam",
	"editOrder",
	"downloadOrder",
	"changeStatusOrder",
	"exportOrders",
	"addClient",
	"editClient",
	"deactivateClient",
	"activateClient",
	"addlocations",
	"deactivateLocation",
	"editLocation",
	"deleteLocation",
	"activateLocation",
	"changeOrderUpLocation",
	"changeOrderDownLocation",
	"addcategory",
	"deactivateCategory",
	"editCategory",
	"editBonusCategory",
	"deleteCategory",
	"activateCategory",
	"changeOrderUpCategory",
	"changeOrderDownCategory",
	"addtariff",
	"deactivateTariff",
	"editTariff",
	"deleteTariff",
	"activateTariff",
	"changeOrderUpTariff",
	"changeOrderDownTariff",
	"exportTariff",
	"addproduct",
	"deactivateProducts",
	"editProducts",
	"deleteProducts",
	"activateProducts",
	"changeOrderUpProducts",
	"changeOrderDownProducts",
	"editClientSettings",
	"addTemplate",
	"editTemplate",
	"addCategoryTemplate",
	"deleteTemplate",
	"draftTemplate",
	"changeClient",
	"seeInfoChef",
	"filterTeams",
	"filterAgencies",
	"filterStatus",
	"seeTopStats",
	"seeUsersStats",
	"seeLast30Orders",
	"seeSelect",
	"seeImports",
	"editManualCorr",
	"billingDetails",
	"createStatus",
	"createLeadStatus",
	"sortLeads",
	"moveStatuses",
	"deleteSettlements",
	"addConditions",
	"filterWithUser",
	"filterAddUser",
	"importOrder",
	"deleteLeads",
	"getGoals",
	"exportCalculation",
	"seeLeaderboard",
	"userProfileStatistic",
	"seeDashboard2",
	"seeDashboard1",
	"seeReceivedCommission",
	"seeStatistics",
	"seeTimeline",
	"addSalesOrganization",
	"editSalesOrg",
	"deleteSalesOrg",
	"seeTotalRevenue",
	"createOrderStatusImport",
	"createOrderImport",
	"createLeadImport",
	"seeDashboard",
	"seeUsers",
	"seeCreateEditUsers",
	"seeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeClients",
	"seeClient",
	"seeImports",
	"seeInsideImport",
	"seeUpdatedStatuses",
	"seeImportCsv",
	"seeInsideOrderImport",
	"seeTrainings",
	"seeSingleTraining",
	"seeAddEditTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeEnergy",
	"seeImportsPage",
	"seeCreateImport",
	"seeOrderTables",
	"seePreviewCsv",
	"seePreviewCsvById",
	"seeSalesTrackerInsideImport",
	"seeCreatePdf",
	"seeCalculationImportTable",
	"seeSupport",
	"seeLockedComponent",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayUsers",
	"displayClients",
	"displayOrders",
	"displayEnergy",
	"displayImports",
	"displaySettings",
	"displaySupport",
	"setGroupViewGoals",
	"createGroupViewGoals",
	"createGoals",
	"seeActionsGoals",
	"searchGoals",
	"addCardToDashboardGoals",
	"addCardToMobileGoals",
	"removeCardToDashboardGoals",
	"removeCardToMobileGoals",
	"editGoals",
	"deleteGoal",
	"seeTeams",
	"seeAgencies",
	"seeSalesOrganizations",
	"searchUsers",
	// condition actions
	"editCondition",
	"duplicateCondition",
	"deleteCondition",
	"seeManagerVo",
	"seePersonnelDevelopment",
	"seeInductionBonus",
	"seeMobilityBonus",
	"seeOwnContracts",
	"seeOverhead",
	"seeCareerConditions",
	// monitoring actions
	"monitoringTrainer",
	"monitoringLeader",
	"monitoringHoD",
	"maintainStatusTrainer",
	"maintainStatusLeader",
	"maintainStatusHoD",
	"editMonitoringLeader",
	"editMonitoringHoD",
	"editMaintainStatusTrainer",
	// sales organizations actions
	"printSalesOrganization",
	"addUsersOnLeads",
	// client settings actions
	"uploadClientLogo",
	"seeEmailOnClientSettings",
	"seeContractsOnClientSettings",
	// statistic and documents
	"seeSaveOnStatisticAndDocument",
	"seePrintOnStatisticAndDocument",
	"uploadStatisticAndDocument",
	"editOnStatisticAndDocument",
	// uploadPDF
	"editOnUploadPdf",
	"deleteOnUploadPdf",
	// Energy
	"AddFilterEnergy",
	// order actions,
	"filterByClientType",
	"filterCalculations",
];
const permissionsManagementInfo = [
	"exportTariff",
	"exportOrders",
	"exportUsers",
	"changeClient",
	"seeInfoChef",
	"filterTeams",
	"filterAgencies",
	"filterStatus",
	"seeTopStats",
	"seeUsersStats",
	"seeLast30Orders",
	"seeSelect",
	"deleteSettlements",
	"exportCalculation",
	"seeLeaderboard",
	"seeReceivedCommission",
	"seeStatistics",
	"seeTimeline",
	"seeTotalRevenue",
	"seeDashboard1",
	"getGoals",
	"createOrderStatusImport",
	"createOrderImport",
	"createLeadImport",
	"seeDashboard",
	"seeUsers",
	"seeCreateEditUsers",
	"seeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeClients",
	"seeClient",
	"seeImports",
	"seeInsideImport",
	"seeUpdatedStatuses",
	"seeImportCsv",
	"seeInsideOrderImport",
	"seeTrainings",
	"seeSingleTraining",
	"seeAddEditTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeEnergy",
	"seeImportsPage",
	"seeCreateImport",
	"seeOrderTables",
	"seePreviewCsv",
	"seePreviewCsvById",
	"seeSalesTrackerInsideImport",
	"seeSupport",
	"seeLockedComponent",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayUsers",
	"displayClients",
	"displayOrders",
	"displayOrders",
	"displayEnergy",
	"displayImports",
	"displaySettings",
	"displaySupport",
	"setGroupViewGoals",
	"createGroupViewGoals",
	"createGoals",
	"seeActionsGoals",
	"searchGoals",
	"addCardToDashboardGoals",
	"addCardToMobileGoals",
	"removeCardToDashboardGoals",
	"removeCardToMobileGoals",
	"editGoals",
	"deleteGoal",
	"seeTeams",
	"seeAgencies",
	"seeSalesOrganizations",
	"searchUsers",
	// Energy
	"AddFilterEnergy",
	// client settings actions
	"uploadClientLogo",
	"seeEmailOnClientSettings",
	"seeContractsOnClientSettings",
	// statistic and documents
	"seeSaveOnStatisticAndDocument",
	"seePrintOnStatisticAndDocument",
	"uploadStatisticAndDocument",
	"editOnStatisticAndDocument",
	// uploadPDF
	"editOnUploadPdf",
	"deleteOnUploadPdf",
	// order actions,
	"filterByClientType",
	"filterCalculations",
];
const permissionsClient = [
	"downloadOrder",
	"exportOrders",
	"createLeadImport",
	"seeDashboard",
	"seeUserProfile",
	"seeOrders",
	"seeUpdatedStatuses",
	"seeImportCsv",
	"displayOrders",
	"displaySettings",
];
const permissionsAgency = [
	"exportTariff",
	"exportOrders",
	"changeClient",
	"filterStatus",
	"seeLeaderboard",
	"seeDashboard",
	"seeUsers",
	"seeCreateEditUsers",
	"seeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeImportCsv",
	"seeTrainings",
	"seeSingleTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeSalesTrackerInsideImport",
	"seeLockedComponent",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayOrders",
	"displaySettings",
	"searchUsers",
];
const permissionsSP = [
	"exportTariff",
	"exportOrders",
	"changeClient",
	"filterStatus",
	"seeLeaderboard",
	"seeDashboard",
	"seeSPUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeImportCsv",
	"seeTrainings",
	"seeSingleTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeSalesTrackerInsideImport",
	"seeLockedComponent",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayOrders",
	"displaySettings",
];
const permissionsAgencySP = [
	"exportTariff",
	"exportOrders",
	"changeClient",
	"filterStatus",
	"seeDashboard",
	"seeAgencyUserDetails",
	"seeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeImportCsv",
	"seeTrainings",
	"seeSingleTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeSalesTrackerInsideImport",
	"seeLockedComponent",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayOrders",
	"displaySettings",
];
const permissionsEmployee = [
	"exportTariff",
	"exportOrders",
	"changeClient",
	"filterStatus",
	"seeLeaderboard",
	"seeDashboard",
	"seeEmployeeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeImports",
	"seeImportCsv",
	"seeTrainings",
	"seeSingleTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeSalesTrackerInsideImport",
	"seeLockedComponent",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayOrders",
	"displaySettings",
];
const permissionsEmployeeSecondary = [
	"exportTariff",
	"exportOrders",
	"changeClient",
	"filterStatus",
	"seeLeaderboard",
	"seeDashboard",
	"seeEmployeeUserDetails",
	"seeUserProfile",
	"seeCalculations",
	"seeCalculationDetails",
	"seeOrders",
	"seeImportCsv",
	"seeTrainings",
	"seeSingleTraining",
	"seeMessages",
	"seeSalesTracker",
	"seeSalesTrackerInsideImport",
	"seeLockedComponent",
	"displayDashboard",
	"displayCalculations",
	"displayTrainings",
	"displayMessages",
	"displaySalesTracker",
	"displayOrders",
	"displaySettings",
];
const teamLeaderPermissions = [
	"seeImportsPage",
	"createLeadImport",
	"seeCreateImport",
	"displayImports",
	"importAsLeader",
	"deleteLeads",
	"getUsersByLeaderId",
	"addUsersOnLeads",
	"filterAddUser",
	"parseCsvAsLeader",
	"getTeamsDropdownAsLeader",
	"seeImports",
];

const headOfDepartmentPermissions = [
	"seeImportsPage",
	"createLeadImport",
	"seeCreateImport",
	"displayImports",
	"importAsLeader",
	"deleteLeads",
	"getUsersByLeaderId",
	"addUsersOnLeads",
	"filterAddUser",
	"parseCsvAsLeader",
	"getTeamsDropdownAsLeader",
	"seeImports",
];
const headOfSalesPermissions = [
	"deleteLeads",
	"getUsersByHeadOfSalesId",
	"filterAddUser",
	"getTeamsDropdownAsLeader",
	"seeImports",
	"seeLeaderboard",
	"editOrder",
];

const getExtraRole = (user) => {
	if (user && user.isTeamLeader) {
		return teamLeaderPermissions;
	}
	if (user && user.isHeadOfDepartment) {
		return headOfDepartmentPermissions;
	}
	if (user && user.isHeadOfSales) {
		return headOfSalesPermissions;
	}
	return [];
};
const getPermissions = (user) => {
	switch (user?.name) {
		case "ADMIN":
			return [...new Set([...permissionsAdmin, ...getExtraRole(user)])];
		case "TENANT":
			return [...new Set([...permissionsTenant, ...getExtraRole(user)])];
		case "SP":
			return [...new Set([...permissionsSP, ...getExtraRole(user)])];
		case "EMPLOYEE":
			return [...new Set([...permissionsEmployee, ...getExtraRole(user)])];
		case "EMPLOYEE_SHOP":
		case "EMPLOYEE_TL":
		case "EMPLOYEE_AB":
			return [...new Set([...permissionsEmployeeSecondary, ...getExtraRole(user)])];
		case "BACK-OFFICE":
			return [...new Set([...permissionsBackOffice, ...getExtraRole(user)])];
		case "AGENCY":
		case "AGENCY_70":
			return [...new Set([...permissionsAgency, ...getExtraRole(user)])];
		case "AGENCY-SP":
			return [...new Set([...permissionsAgencySP, ...getExtraRole(user)])];
		case "MANAGEMENTINFO":
			return [...new Set([...permissionsManagementInfo, ...getExtraRole(user)])];
		case "CLIENT":
			return [...new Set([...permissionsClient, ...getExtraRole(user)])];
		default:
	}
};

export const checkRoles = (permission) => {
	const user = getCurrentUsersRole();
	if (!user) return true;
	const permissions = getPermissions(user);
	return permissions?.includes(permission) ?? false;
};
