import React from "react";
import ModalComponent from "@common/modal";
import {DownloadIcon, CloseUploadModalIcon} from "@assets/SvgComponents";
import {renderPreviewFiles} from "../MessagesContainer/helpers";

const PreviewFile = (props) => {
	const {showModal, setPreviewPdf, previewFile, canDownload, downloadFn} = props;
	return (
		<ModalComponent
			open={showModal}
			handleClose={() => setPreviewPdf(false)}
			customClassNames="messages__modal"
			width="700"
		>
			<div className="modal__container">
				<div className="modal__container-header">
					<span className="file-name">{previewFile?.fileName}</span>
					<div className="icons">
						{canDownload && (
							<span onClick={() => downloadFn(previewFile)}>
								<DownloadIcon />
							</span>
						)}
						<span onClick={() => setPreviewPdf(false)}>
							<CloseUploadModalIcon width="11" height="12" />
						</span>
					</div>
				</div>
				<div className="modal__container-content">{renderPreviewFiles(previewFile)}</div>
			</div>
		</ModalComponent>
	);
};
export default PreviewFile;
