import React from "react";
import ProfilePicture from "./components/profilepicture/profilePicture";
import GeneralInformation from "./components/generalInformation/generalInformation";
import FinancialInformation from "./components/financialInformation/financialInformation";
import DistributionChannel from "./components/distributionChannel/distributionChannel";

const UserProfile = ({singleUser}) => (
	<>
		<ProfilePicture singleUser={singleUser} />
		<GeneralInformation singleUser={singleUser} />
		<FinancialInformation singleUser={singleUser} />
		<DistributionChannel singleUser={singleUser} />
	</>
);

export default UserProfile;
