import {FirstStepIcon, SecondStepIcon, ThirdStepIcon} from "@src/assets/SvgComponents";
import {Steps} from "antd";
import React from "react";

function AnalyticsStepper({current, steps, className, setFieldValue, disabled}) {
	const icons = [FirstStepIcon, SecondStepIcon, ThirdStepIcon];

	const dataArray = icons.slice(0, steps).map((Icon, index) => ({
		id: index + 1,
		icon: <Icon key={index} width="18" height="18" />,
		disabled: current !== index + 1,
	}));

	const handleStep = (current) => {
		if (!disabled) {
			setFieldValue("step", current);
		}
	};

	return (
		<div className="stepper__container">
			<Steps
				current={current}
				items={dataArray}
				className={className}
				onChange={handleStep}
			/>
		</div>
	);
}

export default AnalyticsStepper;
