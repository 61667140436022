import React from "react";
import {ReactComponent as ExpandIcon} from "@assets/img/expand_more_black_24dp.svg";
import {actions as navigation} from "@sagas/navigation";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {AddMessageIcon} from "@src/assets/SvgComponents";
import MessagePrivateTopic from "../MessagesContainer/MessagesPrivateTopic";

const MessagesLeftTopics = (props) => {
	const {
		topicContainerName,
		topicName,
		privateUnread,
		privateTopic,
		numOfUnread,
		color,
		id,
		navigate,
		match,
		clicked,
		closed,
		clickAdd,
		clickOption,
		item,
	} = props;

	const topicClick = () => {
		navigate(`/admin/messages/${id}`);
		clickOption();
	};
	return (
		<div className="messages__left__topic ">
			{topicContainerName && (
				<>
					<div className="messages__topic__container__name__and__icon" onClick={clicked}>
						<ExpandIcon
							className={`messages__topic__expand__icon ${!closed && "rotate"}`}
						/>
						<p className="messages__topic__container__name" style={{fontWeight: 600}}>
							{topicContainerName}
						</p>
					</div>
					<div onClick={clickAdd} className="messages__topic__add__icon">
						<AddMessageIcon />
					</div>
				</>
			)}
			{topicName && (
				<MessagePrivateTopic
					topicName={topicName}
					privateUnread={privateUnread}
					active={+match.params.id === +id}
					numOfUnread={numOfUnread}
					color={color}
					clicked={topicClick}
					privateTopic={privateTopic}
					item={item}
				/>
			)}
		</div>
	);
};
const mapDispatchToProps = {
	navigate: navigation.navigate,
};

export default connect(null, mapDispatchToProps)(withRouter(MessagesLeftTopics));
