import PreviewOrderForm from "@src/components/generic-pdf/createOrder/components/PreviewOrderForm";
import React, {useEffect, useMemo} from "react";
import {connect} from "react-redux";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {generateOrderInitialValues} from "./helper";

const EditOrder = ({open, onClose, order, editedOrderFields, fillOrder}) => {
	const orderInitialValues = useMemo(() => {
		if (open && order && editedOrderFields.length > 0) {
			// generate initial values
			return generateOrderInitialValues(order, editedOrderFields);
		}
		return null;
	}, [open, order, editedOrderFields]);

	useEffect(() => {
		if (!open) {
			fillOrder(null);
		}
	}, [open]);

	if (!open) {
		return null;
	}
	return (
		<PreviewOrderForm
			withTabs={false}
			clientId={order?.clientId?.value}
			open={open}
			onClose={onClose}
			orderInitialValues={orderInitialValues}
			loader={!order || editedOrderFields.length === 0}
			modalTitle="EditOrder"
		/>
	);
};

const mapStateToProps = (state) => ({
	order: state.app.orders.edit.order,
	editedOrderFields: state.app.client.index.editedOrderFields,
});

const mapDispatchToProps = {
	fillOrder: editOrderActions.fillOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);
