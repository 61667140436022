import * as yup from "yup";
import i18n from "../../../i18n";

export const clientElements = {
	btnText: i18n.t("Submit"),
	validationSchema: {
		add: yup.object().shape({
			firstName: yup.string().label(i18n.t("FirstName")).required(i18n.t("FirstNameIsReq")),
			lastName: yup.string().label(i18n.t("LastName")).required(i18n.t("LastNameIsReq")),
			name: yup.string().label(i18n.t("TenantName")).required(i18n.t("TenantNameIsReq")),
			domain: yup
				.string()
				.label(i18n.t("TenantDomain"))
				.required(i18n.t("TenantDomainIsReq")),
			email: yup
				.string()
				.lowercase()
				.strict()
				.label(i18n.t("EmailAddress"))
				.email()
				.required(i18n.t("EmailIsRequred")),
		}),
	},
	lists: {
		add: [
			{
				title: i18n.t("TenantName"),
				type: "input",
				field: "name",
				inputType: "text",
			},
			{
				title: i18n.t("FirstName"),
				type: "input",
				field: "firstName",
				inputType: "text",
			},
			{
				title: i18n.t("LastName"),
				type: "input",
				field: "lastName",
				inputType: "text",
			},
			{
				title: i18n.t("EmailAddress"),
				type: "input",
				field: "email",
				inputType: "text",
			},
			{
				title: i18n.t("TenantDomain"),
				type: "input",
				field: "domain",
				inputType: "text",
			},
		],
	},

	hasID: "id",
	modalTitle: "Tenant",
};

export const headerStyle = {
	backgroundColor: "#fbfbfb",
	fontFamily: "Montserrat",
	fontSize: "1.3rem",
};
export const cellStyle = {
	backgroundColor: "#fbfbfb",
	marginRight: "190px",
	borderRightStyle: "solid",
	borderRightColor: "#f5f5f5",
	borderRightWidth: "1px",
	color: "#1e8659",
};
