import HtmlTooltip from "@src/common/htmlTooltip";
import React from "react";

const HeaderIcon = ({title, onClick, children, showIcon = true, isExpired}) => (
	<HtmlTooltip title={<span>{title}</span>} placement="bottom">
		<span
			onClick={onClick}
			style={{
				visibility: showIcon ? "visible" : "hidden",
				pointerEvents: isExpired ? "none" : "auto",
			}}
		>
			{children}
		</span>
	</HtmlTooltip>
);

export default HeaderIcon;
