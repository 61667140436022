import React, {useEffect, useRef, useState} from "react";

const ProgressCard = ({strokeColor = "#6C1EB0", percentage}) => {
	const calculatedWidth = Math.max(percentage, 0);
	const fillerRef = useRef(null);
	const [dimensions, setDimensions] = useState({width: 0, height: 0});
	useEffect(() => {
		if (fillerRef.current) {
			const {offsetWidth, offsetHeight} = fillerRef.current;
			setDimensions({width: offsetWidth, height: offsetHeight});
		}
	}, [calculatedWidth]);
	const getMinWidth = () => {
		if (dimensions?.width < dimensions?.height && percentage > 0.5) {
			return dimensions?.height;
		}
		return "unset";
	};
	return (
		<div className="progress-wrapper">
			<div className="progress-bar">
				<div
					ref={fillerRef}
					className="filler"
					style={{
						width: `${calculatedWidth}%`,
						backgroundColor: strokeColor,
						minWidth: getMinWidth(),
					}}
				>
					{percentage >= 50 && (
						<span className="high-progress">{percentage.toFixed(0)}%</span>
					)}
				</div>
				{percentage < 50 && (
					<span
						className="percentage"
						style={{
							color: strokeColor,
							left: `${
								dimensions?.width < dimensions?.height && percentage > 0.5
									? `${dimensions?.height + 2}px`
									: `${percentage}%`
							}`,
						}}
					>
						{percentage?.toFixed(0)}%
					</span>
				)}
			</div>
		</div>
	);
};

export default ProgressCard;
