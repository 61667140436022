import {Skeleton} from "antd";
import React from "react";

export default function TableSkeleton({noPadding, withTitle, length = 30}) {
	return (
		<div
			className="table_skeleton"
			style={{
				padding: noPadding ? 0 : "12px",
			}}
		>
			{withTitle && (
				<Skeleton.Input
					active
					style={{
						height: 34,
						borderRadius: 4,
						width: "30%",
						marginBottom: 15,
					}}
				/>
			)}
			{Array.from({length}).map(() => (
				<Skeleton.Input
					active
					style={{
						height: 34,
						borderRadius: 4,
						width: "100%",
					}}
				/>
			))}
		</div>
	);
}
