import {UploadNewIcon} from "@src/assets/SvgComponents";
import React from "react";
import {useTranslation} from "react-i18next";

const Upload = ({img, accept, canSave = false, onSaveClick, onUpload, id = "upload"}) => {
	const {t} = useTranslation();
	return (
		<div className="upload__container">
			<img src={img} alt="" className={`${!img && "hideImg"}`} />
			{canSave ? (
				<button className="upload__label save" onClick={onSaveClick} type="button">
					{t("Save")}
				</button>
			) : (
				<>
					<label className="upload__label" htmlFor={id}>
						{t("upload")} <UploadNewIcon />
					</label>
					<input
						type="file"
						accept={accept}
						className="upload__btn"
						onChange={onUpload}
						id={id}
					/>
				</>
			)}
		</div>
	);
};

export default Upload;
