import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as productActions, actions as userActions} from "@sagas/products/clientProduct";
import {actions as deletedProductActions} from "@sagas/products/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";
const logger = new Logger("Products delete");

const PREFIX = "@app/products/deleteClientProduct";
export const DELETE_PRODUCT = `${PREFIX}DELETE_PRODUCT`;
export const DEACTIVATE_PRODUCT = `${PREFIX}DEACTIVATE_PRODUCT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_DELETE_MODAL = `${PREFIX}SHOW_DELETE_MODAL`;

const _state = {
	loading: false,
	deleteShowModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_DELETE_MODAL:
				draft.deleteShowModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteProduct: (payload) => createAction(DELETE_PRODUCT, {payload}),
	deactivateProduct: (payload) => createAction(DEACTIVATE_PRODUCT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowDeleteModal: (payload) => createAction(SHOW_DELETE_MODAL, {payload}),
};

export const sagas = {
	*deactivateProduct({payload}) {
		yield put(actions.setLoading(true));
		try {
			const list = yield select((state) => state.app.products.clientProduct.clientProducts);
			const size = yield select((state) => state.app.products.clientProduct.size);

			const item = list.find((e) => e.id === payload.id);
			yield axios.put(`/product/deactivate/${payload.id}`);
			yield put(productActions.deactivateProductOnState(payload.id));
			yield put(deletedProductActions.add(item));
			ToastSuccesComponent(i18n.t("ProductDeactiveSuccess"));
			payload.handleProductModal(false);
			yield put(userActions.totalSizeDecrease());
			if (list.length >= size) {
				yield put(productActions.fetchClientProducts(payload?.client));
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteProduct({payload}) {
		yield put(actions.setLoading(true));

		try {
			const {id, client} = payload;
			const {list, size} = yield select((state) => state.app.products.deleted);
			yield axios.put(`/product/delete/${id}`);
			yield put(deletedProductActions.delete(id));
			ToastSuccesComponent(i18n.t("ProductDeleteSuccess"));
			payload.handleProductModal(false);
			if (list.length >= size) {
				yield put(deletedProductActions.editSize(size));
				yield put(deletedProductActions.fetch(client));
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DEACTIVATE_PRODUCT, sagas.deactivateProduct);
	yield takeLatest(DELETE_PRODUCT, sagas.deleteProduct);
};
