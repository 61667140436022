import i18n from "@src/i18n";

export const channelInputs = [
	{
		field: "topicName",
		title: i18n.t("ChannelName"),
		inputType: "text",
		required: true,
	},
];
