export const fields = [
	{
		title: "",
		items: [
			{key: "hrNumber"},
			{key: "idNumber"},
			{key: "taxNumber"},
			{key: "vatEntitlement"},
			{key: "vatNumber"},
		],
	},
	{
		title: "",
		items: [
			{key: "businessLicenseUrl"},
			{key: "hrNumberUrl"},
			{key: "idNumberUrlFront"},
			{key: "idNumberUrlBack", icon: ""},
			{key: "taxNumberUrl"},
			{key: "vatEntitlementUrl"},
			{key: "vatNumberUrl", icon: ""},
		],
	},
	{
		title: "",
		items: [{key: "iban"}, {key: "bankName"}, {key: "taxOffice"}],
	},
];
