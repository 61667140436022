import React from "react";
import TenantCostumColumn from "./TenantCostumColumn";
import TenantCustomName from "./TenantCustomName";
import {headerStyle, cellStyle} from "./tenantElements";

export const clientColumnsRemote = [
	{
		name: "Tenant Name",
		key: "name",
		formatter: ({row}) => (
			<TenantCostumColumn row={row} link="client" navLink={`${row.firstName} `} />
		),
		headerStyle,
		cellStyle,
	},
	{name: "Domain", key: "domain", formatter: ({row}) => <TenantCustomName row={row} />},
	{name: "Tenant ID", key: "id"},
];
