import React from "react";
import i18n from "../../../../i18n";

const autoFocusAndSelect = (input) => {
	if (input?.focus && input?.select) {
		input.focus();
		input.select();
	}
};

export const columnObj = {
	editor: ({ row, column, onRowChange, onClose }) => (
		<>
			{row.editable === false ? (
				<span>{row[column.key]}</span>
			) : (
				<input
					ref={autoFocusAndSelect}
					className="rdg-text-editor"
					value={row[column.key]}
					onChange={(event) => {
						onRowChange({
							...row,
							[column.key]: Number(event.target.value),
						});
					}}
					onBlur={() => onClose(true)}
					type="number"
				/>
			)}
		</>
	),
	headerRenderer: ({ column }) => (
		<div className="editable-formatter">
			<div className="editable-formatter-header">
				<div className="editable-formatter-header-element first">{column.name}</div>
			</div>
		</div>
	),
};
export const overHeadColumns = [
	{
		name: i18n.t("leaderTrainerCompensation"),
		editable: false,
		resizable: true,
		key: "name",
		formatter: ({ row }) => <span>{i18n.t(row?.name)}</span>,
	},
	{
		key: "min:countType",
		name: "Minimum:countType",
		resizable: true,
		editable: true,
	},
	{
		key: "min:countTypeTeam",
		resizable: true,
		name: "Minimum:countTypeTeamMember",
		editable: true,
	},
];
export const selfProductionColumns = [
	{
		key: "name",
		name: i18n.t("leaderTrainerProduction"),
		editable: false,
		resizable: true,
		formatter: ({ row }) => <span>{i18n.t(row?.name)}</span>,
	},
	{
		key: "provision",
		name: i18n.t("IndividualContract"),
		resizable: true,
		editable: true,
		formatter: ({ row }) => <span>{row?.provision?.toFixed(2)}€</span>,
	},
	{
		key: "maxTeamMembers",
		name: i18n.t("MaxTeamSize"),
		resizable: true,
		editable: true,
	},
];

const TYPES = [
	{ value: "CONTRACTS", label: i18n.t("Contracts") },
	{ value: "POINTS", label: i18n.t("Points") },
];

export const conditionElements = {
	btnText: i18n.t("AddNewTable"),
	btnEditText: i18n.t("EditTable"),
	lists: [
		{
			title: "Name",
			field: "name",
			inputType: "text",
			bottomBreak: true,
			required: true,
		},
		{
			title: "Type",
			field: "countType",
			inputType: "dropdown",
			options: TYPES,
			multiple: false,
			type: "overhead",
			required: true,
		},
		{
			title: [i18n.t("StartDate"), i18n.t("EndDate")],
			fields: ["startDate", "endDate"],
			inputType: "rangePicker",
			dependentFields: ["teamIds"],
			required: [true, false],
		},
		{
			title: "Add Teams",
			field: "teamIds",
			inputType: "dropdown",
			name: "teams",
			required: true,
			dependentFrom: "startDate",
			options: [],
			inputValue: "obj",
			multiple: true,
		},
	],

	hasID: "name",
	modalTitle: "User",
};
