export const fields = [
	{
		title: "",
		items: [{key: "extVpNr"}, {key: "company"}],
	},
	{
		title: "",
		items: [{key: "role", type: "simpleView"}, {key: "companyType"}],
	},
	{
		title: "",
		items: [{key: "agencyType"}, {key: "agencyLogo", icon: ""}],
	},
];
