import React from "react";
import HtmlTooltip from "../htmlTooltip";

function infoSmallBox(props) {
	const {number, nameOfInfo, icon} = props;
	return (
		<div className="info__small__box">
			<div className="info__small__box__icon">
				<HtmlTooltip title={<span>{nameOfInfo}</span>}>
					<div className="info__small__box__name__of__info">{nameOfInfo}</div>
				</HtmlTooltip>
				<img src={icon} alt="" />
			</div>
			<div className="info__small__box__right__part">
				<h1 className="info__small__box__number">{number}</h1>
			</div>
		</div>
	);
}

export default infoSmallBox;
