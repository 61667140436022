/* eslint-disable brace-style */
import produce from "immer";
import {put, select, takeLatest, takeEvery, delay, fork, call, take} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as navigation} from "@sagas/navigation";
import {ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import {handleInputShowValue} from "@utils/message-converter";
import {eventChannel} from "redux-saga";
import {actions as messageActions} from "./index";
import i18n from "../../../../i18n";
const logger = new Logger("Messages create");

const PREFIX = "@app/messages/create";

export const CREATE_MESSAGE = `${PREFIX}CREATE_MESSAGE`;
export const CREATE_CHANNEL = `${PREFIX}CREATE_CHANNEL`;
export const CREATE_CHANNEL_MODAL = `${PREFIX}CREATE_CHANNEL_MODAL`;
export const SET_SHOW_MODAL = `${PREFIX}SET_SHOW_MODAL`;
export const ADD_MEMBERS_TO_CHANNEL = `${PREFIX}ADD_MEMBERS_TO_CHANNEL`;
export const SET_ADD_NEW_MESSAGE = `${PREFIX}SET_ADD_NEW_MESSAGE`;
export const CREATE_GROUP_OR_PRIVATE_TOPIC = `${PREFIX}CREATE_GROUP_OR_PRIVATE_TOPIC`;
export const ADD_NEW_ID_TO_LIST = `${PREFIX}ADD_NEW_ID_TO_LIST`;
export const SET_TOPIC_ID = `${PREFIX}SET_TOPIC_ID`;
export const SET_LIST_OF_NAMES = `${PREFIX}SET_LIST_OF_NAMES`;
export const SET_DELIVERED = `${PREFIX}SET_DELIVERED`;
export const CREATE_GROUP_CHANNEL_SUCCESS = `${PREFIX}CREATE_GROUP_CHANNEL_SUCCESS`;
export const SET_USERS_OF_CHANNEL = `${PREFIX}SET_USERS_OF_CHANNEL`;
export const SET_CHANNEL_LOADING = `${PREFIX}SET_CHANNEL_LOADING`;
export const SET_FIRSTMESSAGE_LOADING = `${PREFIX}SET_FIRSTMESSAGE_LOADING`;
export const UPLOAD_FILE = `${PREFIX}UPLOAD_FILE`;
export const SET_FILES = `${PREFIX}SET_FILES`;
export const REMOVE_FILE = `${PREFIX}REMOVE_FILE`;
export const ADDING_USERS_LOADING = `${PREFIX}ADDING_USERS_LOADING`;
export const SET_GROUP_USERS = `${PREFIX}SET_GROUP_USERS`;
export const REMOVE_FILE_SUCCESS = `${PREFIX}REMOVE_FILE_SUCCESS`;
export const REMOVE_LOADING_FILES = `${PREFIX}REMOVE_LOADING_FILES`;
export const UPDATE_PERCENTAGE = `${PREFIX}UPDATE_PERCENTAGE`;
export const SET_TOPIC_CREATE_LOADER = `${PREFIX}SET_TOPIC_CREATE_LOADER`;

export const FILE_SIZE = (value) => value * 1024 * 1024;
export const FILE_FORMATS = [
	"image/jpeg",
	"image/png",
	"application/pdf",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"video/mp4",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/msword",
];
export const createUploadChannel = (element, config) =>
	eventChannel((emitter) => {
		const onUploadProgress = (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			emitter({percentCompleted, element});
		};
		config.onUploadProgress = onUploadProgress;
		return () => {};
	});

const _state = {
	message: {
		text: "",
	},
	channelModal: false,
	channelLoading: false,
	channelInitialValues: {
		users: [],
	},
	groupInitialValues: {
		users: [],
	},
	showAddUserToChannel: false,
	addingNewMessage: false,
	listOfIds: [],
	listOfNames: [],
	topicId: 0,
	delivered: false,
	loadingFirstMess: false,
	files: [],
	addingUserLoader: false,
	createTopicLoader: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case CREATE_CHANNEL_MODAL:
				draft.channelModal = action.payload;
				break;
			case SET_SHOW_MODAL:
				draft.showAddUserToChannel = action.payload;
				break;
			case SET_ADD_NEW_MESSAGE:
				draft.addingNewMessage = action.payload;
				break;
			case ADD_NEW_ID_TO_LIST:
				draft.listOfIds = action.payload;
				break;

			case SET_TOPIC_ID:
				draft.topicId = action.payload;
				break;
			case SET_LIST_OF_NAMES:
				draft.listOfNames = action.payload;
				break;
			case SET_USERS_OF_CHANNEL:
				draft.channelInitialValues = {
					...state.channelInitialValues,
					users: action.payload,
				};
				break;
			case SET_GROUP_USERS:
				draft.groupInitialValues = {
					...state.groupInitialValues,
					users: action.payload,
				};
				break;
			case SET_CHANNEL_LOADING:
				draft.channelLoading = action.payload;
				break;
			case SET_FIRSTMESSAGE_LOADING:
				draft.loadingFirstMess = action.payload;
				break;
			case SET_FILES:
				if (action.payload?.length === 0) {
					draft.files = action.payload;
				} else {
					draft.files = [...state?.files, action.payload];
				}
				break;
			case REMOVE_LOADING_FILES:
				draft.files = state.files.filter((file) => !file.loading);
				break;
			case REMOVE_FILE_SUCCESS:
				draft.files = state?.files?.filter((file) => file?.id !== action.payload);
				break;
			case ADDING_USERS_LOADING:
				draft.addingUserLoader = action.payload;
				break;
			case UPDATE_PERCENTAGE:
				const {filename, base64, type, extension, size} = action.payload.element;
				const updatedFiles = state.files.map((item) => ({
					...item,
					filename: item?.filename || filename,
					base64: item?.base64 || base64,
					type: item?.type || type,
					extension: item?.extension || extension,
					size: item?.size || size,
					percentCompleted:
						item?.percentCompleted === 100
							? item?.percentCompleted
							: action.payload.percentCompleted,
				}));
				draft.files = updatedFiles;
				break;
			case SET_TOPIC_CREATE_LOADER:
				draft.createTopicLoader = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	/** CREATE MESSAGE */
	createMessage: (payload) => createAction(CREATE_MESSAGE, {payload}),
	setFirstMessageLoading: (payload) => createAction(SET_FIRSTMESSAGE_LOADING, {payload}),

	/** CREATE CHANNEL */
	createChannel: (payload) => createAction(CREATE_CHANNEL, {payload}),
	setChannelModal: (payload) => createAction(CREATE_CHANNEL_MODAL, {payload}),

	/** ADD USER TO CHANNEL MODAL */
	setShowModal: (payload) => createAction(SET_SHOW_MODAL, {payload}),

	/** ADD MEMBERS TO CHANNEL */
	addMembers: (payload) => createAction(ADD_MEMBERS_TO_CHANNEL, {payload}),

	/** ADD NEW MESSAGE TOGGLE */
	setAddNewMessage: (payload) => createAction(SET_ADD_NEW_MESSAGE, {payload}),

	/** ADD NEW ID TO GROUP OF ID */
	setList: (payload) => createAction(ADD_NEW_ID_TO_LIST, {payload}),

	/** ADD NEW NAME TO GROUP OF NAMES */
	setNames: (payload) => createAction(SET_LIST_OF_NAMES, {payload}),

	//* *FILL USERS OF A CHANNEL */

	setChannelUsers: (payload) => createAction(SET_USERS_OF_CHANNEL, {payload}),

	/** CREATE GROUP OR PRIVATE MESSAGE */

	createGroupOrPrivateMessage: (payload) =>
		createAction(CREATE_GROUP_OR_PRIVATE_TOPIC, {payload}),

	setTopicId: (payload) => createAction(SET_TOPIC_ID, {payload}),

	setChannelLoading: (payload) => createAction(SET_CHANNEL_LOADING, {payload}),
	uploadFile: (payload) => createAction(UPLOAD_FILE, {payload}),
	setFiles: (payload) => createAction(SET_FILES, {payload}),
	removeFile: (payload) => createAction(REMOVE_FILE, {payload}),
	setAddingUserLoader: (payload) => createAction(ADDING_USERS_LOADING, {payload}),
	setGroupUsers: (payload) => createAction(SET_GROUP_USERS, {payload}),
	removeFileSuccess: (payload) => createAction(REMOVE_FILE_SUCCESS, {payload}),
	removeLoadingFiles: (payload) => createAction(REMOVE_LOADING_FILES, {payload}),
	updatePercentage: (payload) => createAction(UPDATE_PERCENTAGE, {payload}),
	setTopicCreateLoader: (payload) => createAction(SET_TOPIC_CREATE_LOADER, {payload}),
};

function* handleProgress(channel) {
	while (true) {
		const {percentCompleted, element} = yield take(channel);
		yield put(actions.updatePercentage({element, percentCompleted}));
	}
}
export const sagas = {
	*createMessage({payload}) {
		try {
			yield put(messageActions.setPage(1));
			if (payload?.[1] === true) {
				if (payload?.files?.length > 0) {
					yield delay(100);
				}
				const response = yield axios.post(`/messages`, payload?.[0]);
				yield put(messageActions.addMessageInsideTopic(payload?.[0]));
				yield put(
					messageActions.updateMessageInsideTopic({
						...payload[0],
						id: response?.data?.data?.messageId,
						files: response?.data?.data?.files,
						replyMessage: response?.data?.data?.replyMessage,
					}),
				);
				yield put(navigation.navigate(`/admin/messages/${payload[0].topicId}`));
				yield put(messageActions.changeTopicOrder({topicId: payload?.[0]?.topicId}));
			} else {
				yield put(messageActions.addMessageInsideTopic(payload));
				if (payload?.files?.length > 0) {
					yield delay(100);
				}
				const response = yield axios.post(`/messages`, payload);
				yield put(
					messageActions.updateMessageInsideTopic({
						...payload,
						id: response?.data?.data?.messageId,
						files: response?.data?.data?.files,
						replyMessage: response?.data?.data?.replyMessage,
					}),
				);
				yield put(messageActions.changeTopicOrder({topicId: payload?.topicId}));
				yield put(
					messageActions.setLastMessage({
						topicId: payload?.topicId,
						lastMessage: {
							...payload,
							text: handleInputShowValue(payload?.text),
						},
					}),
				);
			}
		} catch (error) {
			logger.error(error);
		} finally {
			logger.info("SUCCESS");
		}
	},
	*uploadFile({payload}) {
		try {
			for (let index = 0; index < payload.length; index++) {
				yield put(
					actions.setFiles({
						loading: true,
					}),
				);
				const element = payload[index];
				const {size, filename, type, base64} = element;
				const MAX_FILE_MB = type === "video/mp4" ? 50 : 10;
				if (FILE_FORMATS.includes(type) && size <= FILE_SIZE(MAX_FILE_MB)) {
					const config = {};
					const channel = yield call(createUploadChannel, element, config);
					yield fork(handleProgress, channel);

					const response = yield call(
						axios.post,
						"/messages/file/upload",
						{
							file: `data:${type};base64,${base64}`,
							fileName: filename,
						},
						config,
					);
					yield put(actions.removeLoadingFiles());
					yield put(
						actions.setFiles({
							...element,
							id: response.data.data,
							loading: false,
							percentCompleted: 100,
						}),
					);
					channel.close();
				} else if (!FILE_FORMATS.includes(type)) {
					ToastErrorComponent(i18n.t("FileTypeError"));
				} else if (size > FILE_SIZE) {
					ToastErrorComponent(i18n.t("fileSizeError"));
				}
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		}
	},
	*removeFile({payload}) {
		try {
			yield axios.put(`messages/file/delete/${payload}`);
			yield put(actions.removeFileSuccess(payload));
		} catch (error) {
			logger.error(error);
		}
	},
	*addMembers({payload}) {
		yield put(actions.setAddingUserLoader(true));
		const {setShowModal} = payload;
		try {
			const {private: privateTopics, channel} = yield select(
				(state) => state.app.messages.index,
			);
			const foundItem = [...privateTopics, ...channel]?.find(
				(item) => item?.topicId === +payload?.id,
			);
			let arr = [];
			arr = [...arr, payload?.values?.users.map((item) => item?.id)];

			const obj = {
				userIds: arr[0],
				topicId: +payload?.id,
			};
			const response = yield axios.post(`/messages/group_channel/add`, obj);

			const filteredUsers = payload?.values?.users?.map((usr) => ({
				id: usr?.id,
				firstName: usr?.firstName,
				lastName: usr?.lastName,
				name: `${usr?.firstName} ${usr?.lastName}`,
				writeAccess: payload?.type !== "MAINCHANNEL",
			}));
			yield put(messageActions.addMessageInsideTopic(response?.data?.data[1]));
			if (foundItem.type === "CHANNEL" || foundItem.type === "MAINCHANNEL") {
				yield put(actions.setChannelUsers(payload?.values?.users));
				yield put(
					messageActions.addUserToChannelSuccess({
						data: filteredUsers,
						topicId: +payload?.id,
					}),
				);
			} else if (foundItem.type === "GROUP") {
				yield put(actions.setGroupUsers(payload?.values?.users));
				yield put(
					messageActions.addUserToGroupSuccess({
						data: filteredUsers,
						topicId: +payload?.id,
					}),
				);
			}
			yield put(
				messageActions.seenMessage({
					topicId: +payload?.id,
					value: true,
				}),
			);
		} catch (error) {
			logger.error(error);
		} finally {
			setShowModal(false);
			logger.info("SUCCESS");
			yield put(actions.setAddingUserLoader(false));
			yield put(actions.setShowModal(false));
		}
	},
	*createChannel({payload}) {
		const {loggedUserId} = yield select((state) => state.app.users.index);

		const arr = payload?.values?.users
			? [loggedUserId, ...payload.values?.users]
			: [loggedUserId];

		const obj = {
			...payload.values,
			type: "CHANNEL",
			users: [...arr],
		};

		try {
			yield put(actions.setChannelLoading(true));
			const response = yield axios.post(`/messages/group_channel`, obj);

			yield put(actions.setChannelModal(false));
			yield put(
				messageActions.createGroupChannelSuccess({
					data: response?.data?.data,
					type: payload?.type,
				}),
			);
			payload.close();
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setChannelLoading(false));
			yield put(messageActions.fetchAllTopics("channel"));
			logger.info("SUCCESS");
		}
	},
	*createGroupOrPrivateMessage({payload}) {
		const {listOfIds} = yield select((state) => state.app.messages.create);

		let response;

		// CHECKING IF NUMBER OF SELECTED USERS IS GREATER THAN ONE
		// IF IT IS WE CREATE A GROUP WITH THEIR IDS

		yield put(actions.setTopicCreateLoader(true));

		try {
			yield put(actions.setFirstMessageLoading(true));
			if (listOfIds.length > 1) {
				const obj = {
					type: "GROUP",
					users: listOfIds,
				};
				response = yield axios.post(`/messages/group_channel`, obj);

				yield put(actions.setChannelModal(false));
			} else {
				response = yield axios.get(`/messages/with/${listOfIds?.[0]}`);
			}
		} catch (error) {
			logger.error(error);
		} finally {
			logger.info("SUCCESS");
		}

		const obj = {...payload, topicId: response.data.data.topicId};
		const newMessage = true;
		yield delay(1000);
		// CREATE A MESSAGE TO CREATED TOPIC
		if (payload) {
			const {text, mentionIds, replyId, fullName, userId, files, createdAtTimestamp} =
				payload;
			if (files?.length > 0) {
				for (let index = 0; index < files.length; index++) {
					const messageObject = {
						topicId: response.data.data.topicId,
						text: index === files.length - 1 ? text : "",
						files: [files[index]?.id],
						replyId: index === files.length - 1 ? replyId : null,
						mentionIds: index === files.length - 1 ? mentionIds : [],
						createdAtTimestamp,
						userId,
						fullName,
					};
					yield delay(100);
					yield put(actions.createMessage([messageObject, newMessage]));
				}
			}
			if (!payload?.fromMention) {
				yield put(actions.createMessage([obj, newMessage]));
			}
		} else {
			yield put(navigation.navigate(`/admin/messages/${response.data.data.topicId}`));
		}

		// SET FALSE VARIABLE THAT WE USE TO CHECK IF USER IS CREATING NEW TOPIC
		yield put(actions.setAddNewMessage(false));
		yield put(
			messageActions.createGroupChannelSuccess({
				data: response?.data?.data,
				type: payload?.type,
			}),
		);
		yield put(actions.setFirstMessageLoading(false));
		yield put(actions.setTopicCreateLoader(false));
		yield put(actions.setList([]));
		yield put(actions.setNames([]));
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_CHANNEL, sagas.createChannel);
	yield takeEvery(CREATE_MESSAGE, sagas.createMessage);
	yield takeLatest(ADD_MEMBERS_TO_CHANNEL, sagas.addMembers);
	yield takeLatest(CREATE_GROUP_OR_PRIVATE_TOPIC, sagas.createGroupOrPrivateMessage);
	yield takeEvery(UPLOAD_FILE, sagas.uploadFile);
	yield takeEvery(REMOVE_FILE, sagas.removeFile);
};
