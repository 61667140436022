import {CheckedIcon} from "@src/assets/SvgComponents";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";

const FiltersColumns = ({setColumnsOnState, columnsOnState, setUpdateRows, matchingField}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredOptions, setFilteredOptions] = useState([]);
	const handleChange = (key) => {
		setUpdateRows(false);
		const updatedColumns = columnsOnState.map((item) => {
			if (item.key === key) {
				return {
					...item,
					show: !item.show,
				};
			}
			return item;
		});
		setColumnsOnState(updatedColumns);
	};
	useEffect(() => {
		if (searchQuery !== "") {
			setFilteredOptions(
				columnsOnState.filter((item) =>
					item?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
				),
			);
		} else {
			setFilteredOptions(columnsOnState);
		}
	}, [searchQuery, columnsOnState]);
	return (
		<>
			<div className="filters__popover">
				<div className="filters__popover-header">
					<BigSearchBar
						handleSearch={(e) => setSearchQuery(e.target.value)}
						value={searchQuery}
						widthSearchBar="318px"
					/>
				</div>
				<div className="filters-container">
					{filteredOptions?.[0] &&
						filteredOptions?.map((item, index) => (
							<div key={index} className="filters-container-wrapper">
								<div
									onClick={
										matchingField !== item.name
											? () => handleChange(item?.key)
											: () => null
									}
									className="filters-container-wrapper-icons"
									style={{
										border:
											matchingField !== item.name
												? "1px solid #6C1EB0"
												: "1px solid #C1C1C1",
									}}
								>
									{item.show && (
										<CheckedIcon
											width="10.8"
											height="10.8"
											fill={
												matchingField === item?.name ? "#C1C1C1" : "#6C1EB0"
											}
										/>
									)}
								</div>
								<span className="filters-container-wrapper-name">
									{item?.label}
								</span>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	// ordersFilteredColumns: state.app.imports.create.ordersFilteredColumns,
	matchingField: state.app.imports.create.matchingField,
});
const mapDispatchToProps = {
	// setOrdersFilteredColumns: createImports.setOrdersFilteredColumns,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersColumns);
