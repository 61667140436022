import CustomAccordion from "@src/common/CustomAccordion";
import InputFile from "@src/common/fileInput";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import UploadButtonComponent from "@src/common/components/uploadFiles/uploadButtonComponent";
import CloseIcon from "@src/common/closeIcon/CloseIcon";
import {UploadIcon, UploadNewIcon} from "@src/assets/SvgComponents";
import {ReactComponent as ImgIcon} from "../../../../../../assets/img/Upload icon.svg";

const Brand = (props) => {
	const {t} = useTranslation();
	const {selectedFile, setSelectedFile, client, currentTab, handleChange, logo, resetLogo} =
		props;

	const renderImage = () => {
		if (selectedFile || client?.logo) {
			return (
				<img
					src={logo || client?.logo}
					alt=""
					width="300px"
					border="none"
					height="150px"
					className="edit__user__logo__img"
				/>
			);
		}
		return (
			<div>
				<div
					style={{
						width: "300px",
						height: "150px",
					}}
				></div>
			</div>
		);
	};

	useEffect(() => () => resetLogo(), []);

	return (
		<div className="activeTab-container-wrapper">
			<p className="activeTab-container-wrapper-title">{currentTab}</p>
			<CustomAccordion title={t("UploadTenantLogo")}>
				<div className="edit__user__logo__div-clientSett">
					<div className="edit__user__logo__content">
						{renderImage()}
						{selectedFile === null && !client?.logo && (
							<div className="client_setting_brand_logo">
								<h3 className="edit__user__logo__title">{t("Dragdata")}</h3>
								<div className="client_setting_brand_logo_content">
									<span>{t("Upload")}</span>
									<div>
										<UploadNewIcon />
									</div>
								</div>
							</div>
						)}
						<InputFile
							onChange={(e) => handleChange(e)}
							label={
								selectedFile === null && !client?.logo ? "" : t("UploadTenantLogo")
							}
							labelClassName="change__profile__picture"
							accept="image/*"
							className={`input__file__component ${
								selectedFile === null &&
								!client?.logo &&
								"input__file__component__empty"
							}`}
						/>
					</div>
				</div>
			</CustomAccordion>
		</div>
	);
};

export default Brand;
