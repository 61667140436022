import React, {useEffect} from "react";
import InputFile from "@common/fileInput";
import DropDownInput from "@common/dropDown";
import {ReactComponent as Image} from "@assets/img/image_black_24dp.svg";
import {useTranslation} from "react-i18next";
import Button from "@common/components/button";
import {actions as trainingActions} from "@sagas/trainings";
import {actions as navigation} from "@sagas/navigation";
import {connect} from "react-redux";
import {BackButton} from "@src/assets/SvgComponents";
import editIcon from "../../../../assets/img/editlogo.svg";
import publishIcon from "../../../../assets/img/publishlogo.svg";
import saveIcon from "../../../../assets/img/save-icon.svg";

const TopContent = (props) => {
	const {
		handleSubmit,
		selectedFileName,
		handleFileRead,
		published,
		paramId,
		setFieldValue,
		loading,
		categories,
		category,
		filters,
		filterTrainings,
		getCategoryById,
		setSubCategoryValue,
		subCategoryValue,
		errors,
		touched,
		goBack,
	} = props;
	const {t} = useTranslation();

	useEffect(() => {
		if (filters.category?.value) {
			getCategoryById(filters.category?.value);
		}
	}, [filters.category]);

	useEffect(() => {
		filterTrainings({name: "category", value: null});

		return () => {
			filterTrainings({name: "category", value: "All"});
		};
	}, []);

	return (
		<div className="addTopContent">
			<div className="addTopContent__top">
				<div className="addTopContent__backBtn" onClick={goBack}>
					<div>
						<BackButton width="15px" height="15px" />
					</div>
					<span>{t("Back")}</span>
				</div>
				<div className="addTopContent__btns">
					{paramId && !published && (
						<Button
							handleChange={(e) => {
								setFieldValue("status", "edit");
								handleSubmit(e);
							}}
							disabled={loading}
							text={t("Edit")}
							type="submit"
							leftRounded={true}
							icon={editIcon}
							smallButtonWithTooltip
						/>
					)}
					<Button
						handleChange={(e) => {
							setFieldValue("status", paramId ? "publish" : "add");
							handleSubmit(e);
						}}
						text={paramId ? t("Publish") : t("Save")}
						disabled={loading}
						type="submit"
						icon={paramId ? publishIcon : saveIcon}
						backgroundColor={paramId ? "rgba(68, 34, 139, 0.7)" : ""}
						smallButtonWithTooltip
						placement="bottom-end"
					/>
				</div>
			</div>
			<div
				className={`addTopContent__categories ${
					filters.category?.value ? "flex__categories" : ""
				}`}
			>
				<DropDownInput
					options={categories}
					title="name"
					placeholder={t("category")}
					className="trainingsTop__dropdown"
					value={filters.category}
					onChange={(event, value) => {
						filterTrainings(
							{name: "category", value} || {name: "category", value: "All"},
						);
						setSubCategoryValue({});
					}}
					name="value"
					errors={filters.category === null && t("categoryIsReq")}
					touched={filters.category === null && t("categoryIsReq")}
				/>
				{filters.category?.value && (
					<DropDownInput
						options={
							category.subCategories?.map((e) => ({
								name: e.name,
								value: e.id,
							})) || []
						}
						title="name"
						placeholder={t("Sub Categorie")}
						className="trainingsTop__dropdown"
						value={subCategoryValue}
						onChange={(event, value) => {
							setSubCategoryValue(value);
						}}
						name="value"
						errors={subCategoryValue?.value === null && t("subCategoryIsReq")}
						touched={subCategoryValue?.value === null && t("subCategoryIsReq")}
					/>
				)}
			</div>
			<div className="addTopContent__category-content">
				<div className="top__content__add__cover__container">
					<p
						className={`top__content__add__cover__image__name ${
							selectedFileName !== null && "active"
						} `}
					>
						{selectedFileName ? t("TrainingPhotoUploaded") : " "}
					</p>
					<div>
						<Image
							htmlFor="upload-photo-label"
							className={`image ${selectedFileName !== null && "active"} `}
						/>
						<InputFile
							labelClassName="top__content__add__cover__input"
							label={selectedFileName !== null ? t("ChangePhoto") : t("AddPhoto")}
							accept="image/*"
							touched={touched?.photo}
							errors={errors?.photo}
							onChange={(e) => handleFileRead(e.target.files[0], setFieldValue)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

// export default TopContent;
const mapStateToProps = (state) => ({
	categories: state.app.trainings.index.categories,
	category: state.app.trainings.index.category,
	showModal: state.app.trainings.create.showModal,
	loading: state.app.trainings.create.loading,
	filters: state.app.trainings.index.filters,
});
const mapDispatchToProps = {
	filterTrainings: trainingActions.filterTrainings,
	getTrainingsByStatus: trainingActions.getTrainingsByStatus,
	getCategoryById: trainingActions.getCategoryById,
	goBack: navigation.goBack,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopContent);
