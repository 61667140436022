export const baugoList = (list) => {
	const arr = [];

	// eslint-disable-next-line no-unused-expressions
	list?.map((item) => {
		arr.push(item);
		if (item?.baugo?.parentId) {
			const obj = {
				...item?.baugo,
				bonusName: item?.baugo?.name,
			};
			delete obj?.name;
			arr.push(obj);
		}
		return item;
	});

	return arr;
};
