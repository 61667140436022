import React from "react";
import Conditions from "./TeamConditions";
import ConditionsVersion1 from "./TeamConditionsV1";

const ConditionsWrapper = () => {
	const tenantFeatures = localStorage.getItem("features");
	const parsedFeatures = JSON.parse(tenantFeatures);
	return <>{parsedFeatures["Conditions v2"] ? <Conditions /> : <ConditionsVersion1 />}</>;
};

export default ConditionsWrapper;
