import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation";
import {actions as messageActions} from "@sagas/messages";
import {handleInputShowValue} from "@utils/message-converter";
import {handleDownload} from "@utils/download-file";
import {actions as createMessageActions} from "@sagas/messages/create";
import {actions as deleteMessagesActions} from "@sagas/messages/delete";
import PreviewFile from "../PreviewFileComponent";
import ReplyMessage from "../MessagesContainer/ReplyMessage/ReplyMessage";
import MediaBox from "../MediaComponent/MediaBox";
import SingleMessagePopover from "./SingleMessagePopover";
import DeletedMessage from "./DeletedMessage";
import {isHyperLink} from "./helper";

const SingleMessage = (props) => {
	const {t} = useTranslation();
	const {
		name,
		time,
		message,
		myMessage,
		id,
		lastSeenMessage,
		lastDeliveredMessage,
		loggedUserId,
		user,
		files,
		replyMessage,
		setMessageToReply,
		setAddNewMessage,
		navigate,
		getMessagePage,
		topicId,
		createGroupOrPrivateMessage,
		setList,
		hasWriteAccess,
		item,
		isDeactivated,
		loading,
		insideTopic,
		loggedUser,
		setDeleteModal,
	} = props;

	const [msgObject, setMsgObject] = useState([]);
	const [correctMsg, setCorrectMsg] = useState("");
	const [previewFile, setPreviewFile] = useState(null);
	const [previewPdf, setPreviewPdf] = useState(false);
	const [showActionsPopover, setShowActionsPopover] = useState(false);
	const handleOpen = (file) => {
		setPreviewFile(file);
		setPreviewPdf(true);
	};
	const topicClick = (topicId, userId) => {
		if (topicId !== null) {
			navigate(`${topicId}`);
			setAddNewMessage(false);
		} else {
			setList([userId]);
			createGroupOrPrivateMessage({
				userId,
				text: "",
				fromMention: true,
			});
			setAddNewMessage(false);
		}
	};

	useEffect(() => {
		const objects = handleInputShowValue(message, setMsgObject);
		setMsgObject((prevState) => [...prevState, ...objects]);
		return () => {
			setMsgObject([]);
		};
	}, [message]);
	useEffect(() => {
		const correctMsg = handleInputShowValue(message);
		setCorrectMsg((prevState) => `${prevState} ${correctMsg}`);
		return () => {
			setCorrectMsg("");
		};
	}, [message]);
	const handlePopover = async (key) => {
		if (key === "reply") {
			setMessageToReply(item);
		} else if (key === "copy") {
			await navigator.clipboard.writeText(item.text);
		} else if (key === "delete") {
			setDeleteModal(key, item?.id);
		}
		setShowActionsPopover(false);
	};

	function splitMessage(message) {
		const segments = [];
		let currentSegment = "",
			isInMention = false,
			isInHyperlink = false;

		for (let i = 0; i < message.length; i++) {
			const char = message[i];

			if (char === "@") {
				if (!isInMention && !isInHyperlink) {
					if (currentSegment.trim() !== "") {
						segments.push(currentSegment.trim());
						currentSegment = "";
					}
					isInMention = true;
				}
			} else if (char === " " && !isInHyperlink) {
				if (isInMention) {
					isInMention = false;
				} else if (currentSegment.trim() !== "") {
					segments.push(currentSegment.trim());
					currentSegment = "";
				}
			} else if (char === "http" && message.slice(i, i + 4) === "http") {
				if (!isInMention && !isInHyperlink) {
					if (currentSegment.trim() !== "") {
						segments.push(currentSegment.trim());
						currentSegment = "";
					}
					isInHyperlink = true;
				}
			} else if (char === " " && isInHyperlink) {
				if (message.slice(i - 4, i).toLowerCase() === "http") {
					isInHyperlink = false;
				}
			}

			currentSegment += char;
		}

		if (currentSegment.trim() !== "") {
			segments.push(currentSegment.trim());
		}

		return segments;
	}

	return (
		<>
			<PreviewFile
				setPreviewPdf={setPreviewPdf}
				showModal={previewPdf}
				previewFile={previewFile}
				canDownload={true}
				downloadFn={handleDownload}
			/>

			{lastSeenMessage === id &&
				lastSeenMessage !== lastDeliveredMessage &&
				user !== loggedUserId && (
					<div className="new_messages-indicator">
						<span className="new_messages-indicator__breakline"></span>
						<span className="new_messages-indicator__text">{t("NewMessages")}</span>
					</div>
				)}
			<div
				className={`single__message__container ${myMessage ? "myMessage" : ""}`}
				style={{opacity: id ? 1 : 0.5}}
			>
				{item.deletedAt ? (
					<DeletedMessage name={name} myMessage={myMessage} time={time} />
				) : (
					<div
						className={`single__message__container-threeDotsAndContainer ${
							myMessage ? "myMessage" : ""
						}`}
					>
						<div
							className={`single__message__container-threeDots ${
								myMessage ? "myMessage" : ""
							}`}
						>
							{hasWriteAccess && !isDeactivated && (
								<SingleMessagePopover
									handlePopover={handlePopover}
									myMessage={myMessage}
									topicType={insideTopic?.topicType}
									currentUserId={loggedUser?.id}
									admin={insideTopic?.ownerId}
									open={showActionsPopover}
									setOpen={setShowActionsPopover}
									isMessageEmpty={item.text === ""}
								/>
							)}
						</div>
						<div
							className={`single__message__container-box-wrapper ${
								myMessage ? "myMessage" : ""
							}`}
						>
							<div>
								<div className="single__message__image__container">
									<div className="single__message__image">{name?.charAt(0)}</div>
								</div>
							</div>
							<div className={`singleMessageBox ${myMessage ? "myMessage" : ""}`}>
								<div
									className={`name__message__time__container ${
										myMessage ? "myMessage" : ""
									}`}
								>
									<div className="name__message__time__container">
										<div className="name__and__time">
											<span className="message__sender__name">
												{myMessage ? t("you") : name}
											</span>
										</div>
									</div>
								</div>
								<div
									className={`single__message__container-box ${
										myMessage ? "myMessage" : ""
									}`}
									id={id}
								>
									{replyMessage && (
										<div className={`bottom ${!myMessage ? "myMessage" : ""}`}>
											<div className="top">
												<ReplyMessage
													item={replyMessage}
													close={false}
													myMessage={myMessage}
													handleOpen={handleOpen}
													handleDownload={handleDownload}
													getMessagePage={getMessagePage}
													topicId={topicId}
													time={time}
												/>
											</div>
										</div>
									)}

									<div
										className="message__content"
										style={{gap: correctMsg.trim() === "" ? "0px" : "10px"}}
									>
										{files?.length > 0 && (
											<div className="file__container">
												{files?.map((item, index) => (
													<MediaBox
														item={item}
														key={index}
														handleOpen={handleOpen}
														myMessage={myMessage}
													/>
												))}
											</div>
										)}
										<p
											className={`message__text ${
												myMessage ? "myMessage" : ""
											}`}
										>
											{splitMessage(correctMsg).map((segment, index) => {
												if (segment.startsWith("@")) {
													const username = segment.substring(1);
													const mentionedUser = msgObject.find(
														(user) => user.name === username,
													);
													if (mentionedUser) {
														return (
															<span
																className="mentioned__user"
																key={index}
																style={{
																	color: !myMessage && "#6C1EB0",
																	pointerEvents: loading
																		? "none"
																		: "",
																}}
																onClick={() =>
																	topicClick(
																		mentionedUser.topicId,
																		mentionedUser.id,
																	)
																}
															>
																{segment}
															</span>
														);
													}
													return <span key={index}> {segment} </span>;
												}
												if (isHyperLink(segment)) {
													const href = segment.startsWith("http")
														? segment
														: `https://${segment}`;
													return (
														<a
															href={href}
															key={index}
															target="_blank"
															className={`clickable__link ${
																myMessage && "myMessage"
															}`}
														>
															{segment}
														</a>
													);
												}
												return <span key={index}> {segment} </span>;
											})}
										</p>
									</div>
								</div>
								<div className={`messageTime ${myMessage ? "myMessage" : ""}`}>
									<span
										className={`message__sent__time ${
											myMessage ? "myMessage" : ""
										}`}
									>
										{time}
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	insideTopic: state.app.messages.index.insideTopic,
	loading: state.app.messages.create.loadingFirstMess,
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	navigate: navigation.navigate,
	changeTopicOrder: messageActions.changeTopicOrder,
	getMessagePage: messageActions.getMessagePage,
	createGroupOrPrivateMessage: createMessageActions.createGroupOrPrivateMessage,
	setList: createMessageActions.setList,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMessage);
