import i18n from "../../../../i18n";
export const requiredCells = [
	{name: "Product Name", value: "productName"},

	{name: "Provision Price", value: "provPrice"},

	{name: "Deal Number", value: "dealNumber"},
];
export const requiredCellsLeads = [
	{name: i18n.t("postcode"), value: "PLZ"},
	{name: i18n.t("TheLocation"), value: "Ort"},
	{name: i18n.t("SubLocation"), value: "Teilort"},
	{name: i18n.t("Street"), value: "Straße"},
	{name: "Nr", value: "Nr"},
	{name: i18n.t("additive"), value: "Zusatz"},
	{name: i18n.t("Lead"), value: "Potential"},
	{name: i18n.t("surname"), value: "Nachname"},
	{name: i18n.t("FirstName"), value: "Vorname"},
	{name: i18n.t("StatusOne"), value: "Status Eiz"},
	{name: i18n.t("StatusTwo"), value: "Status Zwei"},
];

// added those fields as a temporary solution for order import,
// because we implemented it only for this client,
// in the future we should get those fields from client's custom fields
// also added those fieldId keys just to indicate that those fields are
// custom fields , they're not their real ids...

const baseFields = [
	{
		labelEn: "Status",
		labelDe: "Status",
		value: "Status",
		isRequired: false,
	},
	{
		labelEn: "Vp Name",
		labelDe: "Vp Name",
		value: "VP Name",
		isRequired: false,
	},
	{
		labelEn: "VP Number",
		labelDe: "VP Nummer",
		value: "VP Number",
		isRequired: false,
	},
	{
		labelEn: "Contract Id",
		labelDe: "Antrags-ID",
		value: "Contract Id",
		isRequired: false,
	},
	{
		labelEn: "Date of Contract",
		labelDe: "Auftrags-Datum",
		value: "Date of Contract",
		isRequired: false,
	},
	{
		labelEn: "Order Product Number",
		labelDe: "Vertrags-ID Produktgeber",
		value: "orderProductNumber",
		isRequired: false,
		fieldId: 10,
	},
	{
		labelEn: "Tariff",
		labelDe: "Tarif",
		value: "Tariff",
		isRequired: false,
	},
	{
		labelEn: "Locations",
		labelDe: "Standorte",
		value: "Locations",
		isRequired: false,
	},
	{
		labelEn: "Project Categories",
		labelDe: "Projektkategorien",
		value: "Project Categories",
		isRequired: true,
	},
	{
		labelEn: "First name",
		labelDe: "Vorname",
		value: "firstName",
		isRequired: false,
		fieldId: 0,
	},
	{
		labelEn: "Last name",
		labelDe: "Nachname",
		value: "lastName",
		isRequired: false,
		fieldId: 1,
	},
];

export const VSE_FIELDS = [
	...baseFields,
	{
		labelEn: "Company Name",
		labelDe: "Firmenname",
		value: "companynamevse_VSE",
		isRequired: false,
		fieldId: 2,
	},
	{
		labelEn: "Street",
		labelDe: "Straße",
		value: "streetvse_VSE",
		isRequired: false,
		fieldId: 3,
	},
	{
		labelEn: "House Number",
		labelDe: "Hausnr",
		value: "housenumbervse_VSE",
		isRequired: false,
		fieldId: 4,
	},
	{
		labelEn: "Additive",
		labelDe: "Zusatz",
		value: "additivevse_VSE",
		isRequired: false,
		fieldId: 5,
	},
	{
		labelEn: "Location",
		labelDe: "Ort",
		value: "locationvse_VSE",
		isRequired: false,
		fieldId: 6,
	},
	{
		labelEn: "Partial Location",
		labelDe: "Ortsteil",
		value: "partiallocationvse_VSE",
		isRequired: false,
		fieldId: 7,
	},
	{
		labelEn: "Postal Code",
		labelDe: "PLZ",
		value: "postalcodevse_VSE",
		isRequired: false,
		fieldId: 8,
	},
	{
		labelEn: "Signature date",
		labelDe: "Datum der Unterschrift",
		value: "signaturedatevse_VSE",
		isRequired: true,
		fieldId: 9,
	},
];

const EPO_FIELDS = [
	...baseFields,
	{
		labelEn: "Remarks",
		labelDe: "Bemerkungen",
		value: "remarks_EPO",
		isRequired: false,
		fieldId: 2,
	},
	{
		labelEn: "Signature date",
		labelDe: "Datum der Unterschrift",
		value: "signaturedate_EPO",
		isRequired: true,
		fieldId: 3,
	},
	{
		labelEn: "Note field",
		labelDe: "Hinweisfeld",
		value: "notefield_EPO",
		isRequired: true,
		fieldId: 4,
	},
];
const EPE_FIELDS = [
	...baseFields,
	{
		labelEn: "Remarks",
		labelDe: "Bemerkungen",
		value: "remarks_EPE",
		isRequired: false,
		fieldId: 2,
	},
	{
		labelEn: "Signature date",
		labelDe: "Datum der Unterschrift",
		value: "signaturedate_EPE",
		isRequired: true,
		fieldId: 3,
	},
	{
		labelEn: "Note field",
		labelDe: "Hinweisfeld",
		value: "notefield_EPE",
		isRequired: true,
		fieldId: 4,
	},
];
export const OPO_FIELDS = [
	...baseFields,

	{
		labelEn: "Remarks",
		labelDe: "Bemerkungen",
		value: "remarks_OPO",
		isRequired: false,
		fieldid: 1,
	},
	{
		labelEn: "Signature date",
		labelDe: "Datum der Unterschrift",
		value: "signaturedate_OPO",
		isRequired: true,
		fieldId: 2,
	},
	{
		labelEn: "Note field",
		labelDe: "Hinweisfeld",
		value: "notefield_OPO",
		isRequired: false,
		fieldId: 3,
	},
];
export const fields = {
	VSE: VSE_FIELDS,
	EPO: EPO_FIELDS,
	EPE: EPE_FIELDS,
	OPO: OPO_FIELDS,
};
