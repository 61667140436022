/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, takeLatest, takeLeading} from "redux-saga/effects";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import createAction from "../../../../utils/action-creator";
import {actions as navigation} from "../navigation";
import Logger from "../../../../utils/logger";
import axios from "../../../../utils/axios";
import i18n from "../../../../i18n";
const logger = new Logger("Forgot Password");

const PREFIX = "@app/auth/forgot-password";
export const FORGOT_PASSWORD = `${PREFIX}FORGOT_PASSWORD`;
export const CHECK_TOKEN = `${PREFIX}CHECK_TOKEN`;
export const CHECK_TOKEN_SUCCESS = `${PREFIX}CHECK_TOKEN_SUCCESS`;
export const EMAIL_SENT_SUCCESS = `${PREFIX}EMAIL_SENT_SUCCESS`;
export const FORGOTTEN_PASSWORD_RESET = `${PREFIX}FORGOTTEN_PASSWORD_RESET`;
export const SET_FORGOT_MODAL = `${PREFIX}SET_FORGOT_MODAL`;

export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	loading: false,
	requestSent: false,
	tokenUsed: false,
	forgotModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EMAIL_SENT_SUCCESS:
				draft.requestSent = true;
				break;
			case CHECK_TOKEN_SUCCESS:
				draft.tokenUsed = true;
				break;
			case SET_FORGOT_MODAL:
				draft.forgotModal = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	forgotPassword: (payload) => createAction(FORGOT_PASSWORD, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setRequestSent: (payload) => createAction(EMAIL_SENT_SUCCESS, {payload}),
	checkToken: (payload) => createAction(CHECK_TOKEN, {payload}),
	setTokenUsed: (payload) => createAction(CHECK_TOKEN_SUCCESS, {payload}),
	resetForgotenPassword: (payload) => createAction(FORGOTTEN_PASSWORD_RESET, {payload}),
	setForgotModal: (payload) => createAction(SET_FORGOT_MODAL, {payload}),
};

export const sagas = {
	*forgotPassword({payload}) {
		yield put(actions.setLoading(true));

		try {
			yield axios.post("/user/password/reset", payload.values);
			ToastSuccesComponent(i18n.t("EmailSentSuccessfully"));
			actions.setRequestSent(true);
			payload.handleModal(false);
			yield put(navigation.navigate("/admin/user-profile"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*checkToken({payload}) {
		yield put(actions.setLoading(true));

		try {
			yield axios.get(`/user/password/change/?token=${payload}`);
		} catch (error) {
			yield put(actions.setTokenUsed(true));
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*resetForgotenPassword({payload}) {
		yield put(actions.setLoading(true));

		try {
			yield axios.post("/user/password/new", payload.values);
			ToastSuccesComponent(i18n.t("PasswordReset"));
			yield put(navigation.navigate("/admin/dashboard"));
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLeading(FORGOT_PASSWORD, sagas.forgotPassword);
	yield takeLatest(CHECK_TOKEN, sagas.checkToken);
	yield takeLatest(FORGOTTEN_PASSWORD_RESET, sagas.resetForgotenPassword);
};
