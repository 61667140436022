/* eslint-disable no-nested-ternary */
import React, {useState} from "react";
import {handleDownload} from "@utils/download-file";
import {
	DownloadFileIcon,
	ExcelMediaIcon,
	PdfMediaIcon,
	MP4MediaIcon,
	PowerPointMediaIcon,
	PreviewMessageFileIcon,
	CloseModalIcon,
	NoDataIcon,
	UploadNewIcon,
} from "@assets/SvgComponents";
import {useTranslation} from "react-i18next";
import Loader from "@common/Loader";
import _ from "lodash";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as messagesActions} from "@sagas/messages";
import {handleDownloadNoToken} from "@src/utils/download-file-notoken";
import PreviewFile from "../PreviewFileComponent";

const MediaComponent = (props) => {
	const {
		totalFiles,
		mediaLoading,
		setQuery,
		query,
		getFiles,
		showTitle = true,
		title = null,
		canDownload = true,
		canRemove = false,
		height = "calc(100vh - 291px)",
		onRemove,
		downloadWithoutToken = false,
		canUploadMore = false,
		uploadInputHandler,
		id,
		customClassName,
		boxClassName = "",
		canView = false,
	} = props;
	const {totalFiles: allFilesLength, files} = totalFiles;
	const {t} = useTranslation();
	const [previewFile, setPreviewFile] = useState(null);
	const [previewPdf, setPreviewPdf] = useState(false);
	const [fetchedPages, setFetchedPages] = useState([1]);
	const handleScroll = _.debounce((event) => {
		const container = event.target;
		const scrollPosition = Math.abs(container.scrollTop);
		const maxScrollPosition = container.scrollHeight - container.clientHeight;
		if (
			Math.round(scrollPosition) === maxScrollPosition &&
			(scrollPosition !== 0 || maxScrollPosition !== 0) &&
			!fetchedPages.includes(totalFiles.page + 1) &&
			totalFiles.page < totalFiles.totalPages
		) {
			setFetchedPages((prevState) => [...prevState, totalFiles.page + 1]);
			setQuery({
				...query,
				page: totalFiles.page + 1,
			});
			getFiles();
		}
	}, 500);
	const handleDownloadFiles = (file) => {
		if (downloadWithoutToken) {
			handleDownloadNoToken(file);
		} else {
			handleDownload(file);
		}
	};

	const renderFileIcon = (file) => {
		switch (file?.fileType) {
			case "pdf":
				return (
					<div className="pdf-logo">
						<PdfMediaIcon fill="#d9d9d9" />
					</div>
				);
			case "vnd.openxmlformats-officedocument.wordprocessingml.document":
				return (
					<div className="pdf-logo">
						<PdfMediaIcon fill="#d9d9d9" />
					</div>
				);
			case "vnd.openxmlformats-officedocument.presentationml.presentation":
				return (
					<div className="pdf-logo">
						<PowerPointMediaIcon fill="#d9d9d9" />
					</div>
				);
			case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
				return (
					<div className="pdf-logo">
						<ExcelMediaIcon fill="#d9d9d9" />
					</div>
				);
			case "mp4":
				return (
					<div className="pdf-logo">
						<MP4MediaIcon fill="#d9d9d9" />
					</div>
				);
			default:
				return <img src={file.fileUrl || file.fileUrl} alt="" />;
		}
	};

	return (
		<>
			<PreviewFile
				setPreviewPdf={setPreviewPdf}
				showModal={previewPdf}
				previewFile={previewFile}
				canDownload={canDownload}
				downloadFn={handleDownloadFiles}
			/>

			<div
				className={`chat_info_container-media ${customClassName}`}
				onScroll={handleScroll}
				style={{
					height: !canDownload ? "170px" : height,
					overflowY: height === "auto" ? "hidden" : "auto",
				}}
			>
				{!mediaLoading ? (
					<div className={`chat_info_container-media-wrapper ${boxClassName}`}>
						{showTitle && (
							<div className="title">
								<p className="media__text">
									{title || `${t("Media")} (${allFilesLength})`}
								</p>
								{canUploadMore && (
									<div className="title__input">
										<label
											htmlFor={`orderFile${id}`}
											className="title__Upload-icon"
										>
											<UploadNewIcon stroke="#000" />
										</label>
										<input
											type="file"
											accept="image/*,.pdf"
											style={{display: "none"}}
											id={`orderFile${id}`}
											onChange={uploadInputHandler}
											multiple
										/>
									</div>
								)}
							</div>
						)}
						{files.length !== 0 ? (
							<div className="files">
								{files?.map((file, index) => (
									<div
										className="box"
										key={index}
										onClick={() => {
											setPreviewPdf(true);
											setPreviewFile(file);
										}}
									>
										{/* {file?.fileType === "pdf" ? (
										<div className="pdf-logo">
											<PDFIcon />
										</div>
									) : file?.fileType ===
									  "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
										<div className="pdf-logo">
											<ExcelLogo />
										</div>
									) : (
										<img src={file.fileUrl || file} alt="" />
									)} */}
										{/* {renderFileIcon(file)} */}
										{renderFileIcon(file)}

										{canView ? (
											<span className="preview__icon">
												<PreviewMessageFileIcon />
											</span>
										) : (
											canDownload && (
												<span
													className="download__icon"
													onClick={(e) => {
														e.stopPropagation();
														handleDownloadFiles(file);
													}}
												>
													<DownloadFileIcon />
												</span>
											)
										)}

										{canRemove && (
											<span
												className="remove__icon"
												onClick={(e) => {
													e.stopPropagation();
													onRemove(file);
												}}
											>
												<CloseModalIcon fill="#252525" />
											</span>
										)}
									</div>
								))}
							</div>
						) : (
							!showTitle && (
								<div className="chat-info-no-data">
									<div>
										<div>
											<NoDataIcon width="64" height="79" fill="#979797" />
										</div>
										<span className="chat-info-no-data__text">
											{t("NoData")}!
										</span>
									</div>
								</div>
							)
						)}
					</div>
				) : (
					<div className="chat_info_container-media-loader">
						<Loader />
					</div>
				)}
			</div>
		</>
	);
};
const mapDispatchToProps = {
	getFiles: messagesActions.getFiles,
	setQuery: messagesActions.setQuery,
};

export default connect(null, mapDispatchToProps)(withRouter(MediaComponent));
