/* eslint-disable max-len */
import React, {useEffect} from "react";
import {connect} from "react-redux";
import InputComponent from "@src/common/input";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePickerWithTypes from "@src/common/DatePicker/datePickerWithTypes";
import {getDateRange, getWeekRange} from "@src/utils/datepicker-config";
import {actions as tariffActions} from "@sagas/tariff/index";
import {actions as productActions} from "@sagas/products/index";
import {actions as locationActions} from "@sagas/locations/index";
import {ToggleAccordionIcon2} from "@src/assets/SvgComponents";
import moment from "moment";
import i18n from "@src/i18n";
import {DATE_FORMAT} from "@src/common/constants";
import {goalsArray} from "../../staticData";

const AlertsSecondStep = ({
	clientList,
	formik,
	getTariffs,
	tariffs,
	getProducts,
	products,
	getLocations,
	locations,
	views,
}) => {
	const {setFieldValue, handleBlur, values, touched, errors, handleChange} = formik;

	const handlePickerChange = (date, dateString, type) => {
		let dates = null;
		if (type === "week") {
			dates = getWeekRange(dateString);
		} else if (type === "custom") {
			dates = {
				start: moment(dateString?.[0]),
				end: moment(dateString?.[1]),
			};
		} else {
			dates = getDateRange(dateString);
		}
		setFieldValue("dateType", type);
		setFieldValue("startDate", dates.start);
		setFieldValue("endDate", dates.end);
	};
	useEffect(() => {
		if (values.targetClientsList?.[0]) {
			const clientId = values?.targetClientsList?.[0]?.id || values?.targetClientsList?.[0];
			getTariffs(clientId);
			getProducts(clientId);
			getLocations(clientId);
		}
	}, [values.targetClientsList]);
	const getOptionSelected = (option, value) =>
		option?.value === value?.value || option?.value === value?.id;

	const baseAutocompleteProps = {
		popupIcon: <ToggleAccordionIcon2 />,
		disabled: !values.targetClientsList?.[0],
		disableClearable: false,
		className: "autocomplete",
		filterSelectedOptions: true,
		getOptionSelected,
		multiple: true,
		disableCloseOnSelect: true,
	};

	return (
		<div className="firstStep__container">
			<div className="activity__modal__dropdown__menu">
				<div className="activity__modal__dropdown__menu--items-each inputFocus">
					<label className="firstStep__container__title">{i18n.t("Title")}</label>
					<InputComponent
						name="name"
						type="text"
						errorClass="errorClass"
						errors={errors?.name}
						values={values?.name}
						handleBlur={handleBlur}
						handleChange={handleChange}
						touched={touched.name}
						newClass="secondStep__input"
						maxLength={70}
					/>
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div
						className="activity__modal__dropdown__menu--items-each"
						style={{gap: "5px"}}
					>
						<label>{i18n.t("date")}</label>
						<DatePickerWithTypes
							value={
								values.startDate
									? `${moment(values.startDate).format(DATE_FORMAT)} - ${moment(
											values.endDate,
									  )
											.utc()
											.format(DATE_FORMAT)}`
									: DATE_FORMAT
							}
							handleChange={handlePickerChange}
						/>
						{errors?.startDate && touched?.startDate && (
							<span className="errorClass">*{errors?.startDate}</span>
						)}
					</div>
				</div>
				{/* <div className="activity__modal__dropdown__menu--items-switch">
					<div className="activity__modal__dropdown__menu--items-switch-items">
						<div className="activity__modal__dropdown__menu--items-switch-items-repeat">
							<label>{i18n.t("repeat")}</label>
							<div className="activity__modal__dropdown__menu--items-switch-items-repeat-info">
								<CustomPopover
									menu={{items}}
									// borderRadius="10px"
									minWidth="100px"
									customClass="activity__modal--info"
								>
									<span>
										<ShowInfoIconBlack width="13px" height="13px" />
									</span>
								</CustomPopover>
							</div>
						</div>
						<FormGroup>
							<FormControlLabel
								checked={values?.repeatGoal}
								onChange={() => setFieldValue("repeatGoal", !values?.repeatGoal)}
								inputProps={{"aria-label": "ant design"}}
								color="primary"
								control={<AndroidSwitch defaultChecked />}
								name="repeatGoal"
							/>
						</FormGroup>
					</div>
				</div> */}
				<div className="activity__modal__dropdown__menu--items">
					<div className="activity__modal__dropdown__menu--items-each">
						<label>{i18n.t("goal")}</label>
						<Autocomplete
							name="goalType"
							defaultValue={goalsArray.find(
								(element) => element.value === values.goalType,
							)}
							disabled
							popupIcon={<ToggleAccordionIcon2 />}
							disableClearable={false}
							className="goal__container"
							disableCloseOnSelect={false}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option?.label}
							getOptionSelected={(option, value) => option?.value === value?.value}
							multiple={false}
							onChange={(event, value) => {
								setFieldValue("goalType", value.value);
							}}
							options={goalsArray || []}
							// classes={{popper: classes.popper}}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder="Select"
									variant="outlined"
								/>
							)}
						/>
						{errors?.goalType && touched?.goalType && (
							<span className="errorClass">*{errors?.goalType}</span>
						)}
					</div>
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div className="activity__modal__dropdown__menu--items-each">
						<label>{i18n.t("GroupView")}</label>
						<Autocomplete
							name="groupTypeId"
							defaultValue={views.find(
								(element) => element?.id === values?.groupTypeId?.id,
							)}
							popupIcon={<ToggleAccordionIcon2 />}
							disableClearable={false}
							className="goal__container"
							disableCloseOnSelect={false}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option?.name}
							getOptionSelected={(option, value) => option?.id === value?.id}
							multiple={false}
							onChange={(event, value) => {
								if (value !== null) {
									setFieldValue("groupTypeId", value.id);
								} else {
									setFieldValue("groupTypeId", null);
								}
							}}
							options={views || []}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder="Select"
									variant="outlined"
								/>
							)}
						/>
						{errors?.groupTypeId && touched?.groupTypeId && (
							<span className="errorClass">*{errors?.groupTypeId}</span>
						)}
					</div>
				</div>

				<div className="activity__modal__dropdown__menu--items-each inputFocus">
					<label>{i18n.t("amount")}</label>
					<InputComponent
						name="amount"
						type="number"
						errorClass="errorClass"
						values={values?.amount}
						handleBlur={handleBlur}
						handleChange={handleChange}
						newClass="secondStep__input"
					/>
					<div>
						{errors.amount && touched.amount && (
							<span className="errorClass">*{errors.amount}</span>
						)}
					</div>
				</div>

				{!values?.clientList?.[0]?.id && (
					<div className="activity__modal__dropdown__menu--items">
						<div className="activity__modal__dropdown__menu--items-each">
							<label>{i18n.t("targetClients")}</label>
							<Autocomplete
								popupIcon={<ToggleAccordionIcon2 />}
								disableClearable={false}
								className="autocomplete"
								disableCloseOnSelect={false}
								filterSelectedOptions={true}
								getOptionLabel={(option) => option?.clientName}
								getOptionSelected={(option, value) => option?.id === value?.id}
								multiple={false}
								value={
									clientList.find(
										(client) =>
											client.id === values.targetClientsList?.[0] ||
											client.id === values?.targetClientsList?.[0]?.id,
									) || null
								}
								onChange={(event, value) => {
									setFieldValue("targetClientsList", value ? [value.id] : []);
									setFieldValue("targetClients", value ? [value.id] : null);
									setFieldValue("targetLocationsList", []);
									setFieldValue("targetProductsList", []);
									setFieldValue("targetTariffsList", []);
								}}
								options={clientList || []}
								renderInput={(params) => (
									<TextField
										{...params}
										className="text-field"
										id="name"
										name="name"
										placeholder="Select"
										variant="outlined"
									/>
								)}
							/>
							<div>
								{errors.targetClients && touched.targetClientsList && (
									<span className="errorClass">*{errors.targetClients}</span>
								)}
							</div>
						</div>
					</div>
				)}
				<div className="activity__modal__dropdown__menu--items">
					<div className="activity__modal__dropdown__menu--items-each activity__modal__dropdown__select-status">
						<label className={!values.target ? "disabled__labels" : "active__labels"}>
							{i18n.t("targetTarrifs")}
						</label>
						<Autocomplete
							{...baseAutocompleteProps}
							getOptionLabel={(option) => option.name}
							value={values?.targetTariffsList || []}
							onChange={(event, value) => {
								setFieldValue("targetTariffsList", value || []);
							}}
							options={tariffs || []}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder="Select"
									variant="outlined"
								/>
							)}
						/>
						{errors?.targetTariffsList && touched?.targetTariffsList && (
							<span className="errorClass">*{errors?.targetTariffsList}</span>
						)}
					</div>
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div className="activity__modal__dropdown__menu--items-each activity__modal__dropdown__select-status">
						<label className={!values.target ? "disabled__labels" : "active__labels"}>
							{i18n.t("targetProducts")}
						</label>
						<Autocomplete
							{...baseAutocompleteProps}
							getOptionLabel={(option) => option.name}
							value={values?.targetProductsList || []}
							onChange={(event, value) => {
								setFieldValue("targetProductsList", value || []);
							}}
							options={products || []}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder="Select"
									variant="outlined"
								/>
							)}
						/>
					</div>
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div className="activity__modal__dropdown__menu--items-each activity__modal__dropdown__select-status">
						<label className={!values.target ? "disabled__labels" : "active__labels"}>
							{i18n.t("targetLocations")}
						</label>
						<Autocomplete
							{...baseAutocompleteProps}
							getOptionLabel={(option) => option.locationName}
							value={values.targetLocationsList || []}
							onChange={(event, value) => {
								setFieldValue("targetLocationsList", value || []);
							}}
							options={locations || []}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder="Select"
									variant="outlined"
								/>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	clientList: state.app.client.index.clientsByDropdown,
	tariffs: state.app.tariffs.index.optionsTariffs,
	products: state.app.products.index.optionsProducts,
	locations: state.app.locations.index.allLocations,
	views: state.app.analytics.view.views,
});

const mapDispatchToProps = {
	getTariffs: tariffActions.fetchTariffsByDropdown,
	getProducts: productActions.fetchProductsByDropdown,
	getLocations: locationActions.fetchAllLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsSecondStep);
