import {TenantsIcon} from "@src/assets/SvgComponents";
import Tabs from "@src/common/components/tabs";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {actions as tenantActions} from "@sagas/tenants/index";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation";
import PageList from "@common/PageList";
import AccountSettings from "../../userProfile/pages/AccountSettings";
import PublicProfile from "../../userProfile/pages/PublicProfile";
import BillingDetails from "../../userProfile/pages/BillingDetails";
import Permissions from "./Permissions";

const TenantSettings = (props) => {
	const {match, tenant, getSingleTenant, navigate} = props;
	const {t} = useTranslation();
	const [activeTab, setActiveTab] = useState(t("AccDetails"));
	const pageList = [
		{
			name: t("Tenants"),
			fn: () => navigate(`/admin/tenants/`),
		},
		{
			name: tenant?.name,
			fn: () => null,
		},
	];
	useEffect(() => {
		getSingleTenant(match?.params?.id);
	}, [match?.params?.id]);
	return (
		<div className="dashboard__container">
			<div className="header-component">
				<div className="dashboard__left__text-header">
					<div className="header-component-wrapper">
						<TenantsIcon />
						<span className="header-component-text" style={{cursor: "pointer"}}>
							{tenant?.name}
						</span>
					</div>
					<Tabs
						items={["AccDetails", "PublicProfile", "BillingDetails", "Permissions"]}
						onClick={(e) => {
							setActiveTab(e.target.outerText);
						}}
						value={activeTab}
					/>
				</div>
			</div>
			<div className="tenant_settings__container">
				<div className="tenant_settings__container-pageList">
					<PageList list={pageList} />
				</div>
				<div className="tenant_settings__container-content">
					<div className="settings__wrapper-content">
						{activeTab === t("AccDetails") && <AccountSettings />}
						{activeTab === t("PublicProfile") && <PublicProfile />}
						{activeTab === t("BillingDetails") && <BillingDetails />}
						{activeTab === t("Permissions") && <Permissions />}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	tenant: state.app.tenants.index.singleUser,
});

const mapDispatchToProps = {
	getSingleTenant: tenantActions.getSingleTenant,
	navigate: navigation.navigate,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantSettings));
