import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation";
import PrivacyPolicyHeader from "./PrivacyPolicyHeader";
import PrivacyPolicyText from "./PrivacyPolicyText";
import PrivacyPolicyFooter from "./PrivacyPolicyFooter";

const PrivacyPolicy = ({navigate, match}) => (
	<div className="privacy__policy__container">
		<PrivacyPolicyHeader navigate={navigate} />
		<PrivacyPolicyText match={match} />
		<PrivacyPolicyFooter />
	</div>
);

const mapDispatchToProps = {
	navigate: navigation.navigate,
};

export default connect(null, mapDispatchToProps)(withRouter(PrivacyPolicy));
