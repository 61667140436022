import {CalculationsIcon, MessagesIcon, SalesTrackerIcon} from "@src/assets/SvgComponents";
import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";

function NotificationBox({item, readNotification}) {
	const {t} = useTranslation();
	const notificationsTypes = {
		SETTLEMENT: {
			icon: <CalculationsIcon fill="#3B71CA" height={24} width={24} />,
			color: "#3B71CA",
			description: t("AppointmentDescription"),
		},
		MESSAGE: {
			icon: <MessagesIcon fill="#9A729E" height={24} width={24} />,
			color: "#9A729E",
			description: t("AppointmentDescription"),
		},
		APPOINTMENT_REMINDER: {
			icon: <SalesTrackerIcon fill="#5D7E93" height={24} width={24} />,
			color: "#5D7E93",
			description: t("AppointmentDescription"),
		},
	};
	function formatTimestamp(timestamp) {
		const now = moment();
		const date = moment(timestamp);

		const diffInHours = now.diff(date, "hours");

		if (diffInHours < 24) {
			return date.format("DD.MM.YY HH:mm");
		}
		if (diffInHours < 48) {
			return t("aDayAgo");
		}
		return `${Math.floor(diffInHours / 24)} ${t("daysAgo")}`;
	}
	const handleReadNotification = () => {
		readNotification({id: item?.id, leadId: item?.leadId});
	};
	return (
		<div
			onClick={handleReadNotification}
			style={{
				borderColor: `${notificationsTypes?.[item?.notificationType]?.color}3a`,
				borderWidth: 1,
				borderStyle: "solid",
				backgroundColor: item?.isRead
					? `${notificationsTypes?.[item?.notificationType]?.color}0f`
					: `${notificationsTypes?.[item?.notificationType]?.color}40`,
				transition: "background-color 0.3s ease",
			}}
			className="notificationBoxWrapper"
		>
			<div
				style={{
					borderColor: `${notificationsTypes?.[item?.notificationType]?.color}3a`,
					borderWidth: 1,
					borderStyle: "solid",
					backgroundColor: `${notificationsTypes?.[item?.notificationType]?.color}2b`,
				}}
				className="notificationBoxWrapper_iconWrapper"
			>
				{notificationsTypes?.[item?.notificationType]?.icon}
			</div>
			<div className="notificationBoxWrapper_contentWrapper">
				<div className="notificationBoxWrapper_contentWrapper_leftSide">
					<span className="name">{t(item.name)}</span>
					<span className="description">{item?.description}</span>
				</div>
				<div className="notificationBoxWrapper_contentWrapper_rightSide">
					<span className="date">{formatTimestamp(item?.createdAt)}</span>
				</div>
			</div>
		</div>
	);
}

export default NotificationBox;
