import {  RightIcon } from '@src/assets/SvgComponents'
import React from 'react'

const Steps = ({ items, onClick, active }) => {
  return (
    <div className="steps__container">
      {items?.map((item, index) => {
        return (
          <div
            onClick={() => onClick(item)}
            key={item}
            className={`step ${active === item ? 'active' : ''}`}
          >
            <span className='name'>{item}</span>
            {index < items.length - 1 && <span className='icon'><RightIcon /></span>}
          </div>
        )
      })}
    </div>
  )
}

export default Steps
