import React from "react";
import ButtonComponent from "@src/common/button";
import {useTranslation} from "react-i18next";
import ActivityBox from "@src/common/ActivityBox";
import {EmailIcon} from "@src/assets/SvgComponents";
import BaseFields from "./BaseFields";
import {checkErrors} from "./helpers";
import SectionBox from "./components/SectionBox";
import FieldsRenderer from "./components/FieldsRenderer";

const Form = ({
	formik,
	baseFields,
	groupedFields,
	handleCollapse,
	collapsedSections,
	fieldOptions,
	editedOrderFields,
	uploadedFiles,
	setUploadedFiles,
	retrieveDataFromGetAg,
	options,
	loading,
	editMode,
	editLoading,
	order,
	emailErrors,
	setEmailErrors,
	validateEmail,
	setState,
	state,
}) => {
	const {t} = useTranslation();
	const {handleSubmit, setFieldValue, values, errors, isSubmitting} = formik;
	const isLoading = loading || editLoading || isSubmitting || state === "started";
	return (
		<form onSubmit={handleSubmit} className="order__form">
			<div className="inputs__wrapper">
				<BaseFields
					values={values}
					setFieldValue={setFieldValue}
					baseFields={baseFields}
					errors={errors}
				/>
			</div>
			{groupedFields?.map((section) => {
				const {id, showField, fields, nameEn, collapse} = section;
				if (!showField || nameEn === "Section Hide") return null;
				const fieldErrors = checkErrors(fields, errors);
				return (
					<SectionBox
						key={`${id}--${fields.length}`}
						item={section}
						handleClick={handleCollapse}
						collapsed={collapsedSections?.[id]}
						disabled={!collapse}
						error={fieldErrors}
					>
						<FieldsRenderer
							fields={fields}
							values={values}
							errors={errors}
							setFieldValue={setFieldValue}
							fieldOptions={fieldOptions}
							orderFields={editedOrderFields}
							uploadedFiles={uploadedFiles}
							setUploadedFiles={setUploadedFiles}
							retrieveDataFromGetAg={retrieveDataFromGetAg}
							options={options}
							editMode={editMode}
							emailErrors={emailErrors}
							setEmailErrors={setEmailErrors}
							order={order}
							validateEmail={validateEmail}
							setState={setState}
							state={state}
						/>
					</SectionBox>
				);
			})}
			{order?.emailLogs?.[0] && (
				<div className="order__activity">
					<p className="order__activity--title">{t("activity")}</p>
					<div className="order__activity--container">
						{order?.emailLogs?.map(({email, id, sendAt, type}, index) => (
							<ActivityBox
								key={`${id}-${order?.orderId}--${index}`}
								email={email}
								sendAt={sendAt}
								sender={order.salesPartnerName}
								Icon={EmailIcon}
								title={type === "OFFER" ? t("OfferEmailSentTo") : t("EmailSentTo")}
							/>
						))}
					</div>
				</div>
			)}
			<div className="order__form-actions">
				<ButtonComponent
					classNames="order-edit-button"
					type="submit"
					buttonText={t("Save")}
					onClick={handleSubmit}
					disabled={isLoading}
					loading={isLoading}
				/>
			</div>
		</form>
	);
};

export default Form;
