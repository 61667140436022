import {useHistory} from "react-router-dom";

const kebabToCamelCase = (str) => str.replace(/-./g, (match) => match.charAt(1).toUpperCase());

const getValue = (value) => {
	if (value === "null") return null;
	const num = Number(value);
	if (!Number.isNaN(num) && Number.isFinite(num)) return num;
	return value;
};

export const useUrlParams = (resetPage) => {
	const history = useHistory();
	const queryParams = new URLSearchParams(window.location.search);
	const getUrlParams = (param, ...args) => {
		const paramsObj = {};
		const queryParam = queryParams.get(param);
		const camelCaseArg = kebabToCamelCase(param);
		paramsObj[camelCaseArg] = getValue(queryParam);
		for (let i = 0; i < args.length; i++) {
			const currentArg = args[i];
			if (currentArg === "query") {
				const newQuery = queryParams.get("query");
				paramsObj.query = JSON.parse(newQuery);
			} else {
				const camelCaseArg = kebabToCamelCase(currentArg);
				const oneParam = queryParams.get(currentArg);
				paramsObj[camelCaseArg] = getValue(oneParam);
			}
		}
		return paramsObj;
	};
	const setUrlParams = (paramValue, paramName = "tab", ...args) => {
		const currentParams = new URLSearchParams(window.location.search);
		const queryValue = currentParams.get("query");
		currentParams.set(paramName, paramValue);
		for (let i = 0; i < args.length; i++) {
			const currentArg = args[i];
			if (queryValue && currentArg.paramName === "query") {
				// only set query from setQuery function
				// eslint-disable-next-line no-continue
				continue;
			} else {
				const valueToSet =
					currentArg?.paramName === "query"
						? JSON.stringify(currentArg.paramValue)
						: currentArg.paramValue;
				currentParams.set(currentArg.paramName, valueToSet);
			}
		}
		history.replace({search: currentParams.toString()});
	};
	const setQuery = (queryValues) => {
		if (resetPage) {
			resetPage();
		}
		const currentParams = new URLSearchParams(window.location.search);

		currentParams.set("query", JSON.stringify(queryValues));
		history.replace({search: currentParams.toString()});
	};
	const clearUrlQuery = () => {
		const currentParams = new URLSearchParams(window.location.search);
		const params = currentParams.toString();
		const paramsArr = params
			?.replace("?", "")
			.split("&")
			.map((pair) => {
				const [key, val] = pair.split("=");

				return [key, decodeURIComponent(val || "")];
			});
		for (let i = 0; i < paramsArr?.length; i++) {
			const [key] = paramsArr[i];
			if (key !== "tab") {
				currentParams.delete(key);
			}
		}
		history.replace({search: currentParams.toString()});
	};
	const removeParams = (...params) => {
		const queryParams = new URLSearchParams(window.location.search);
		for (let i = 0; i < params?.length; i++) {
			const param = params[i];
			const paramValue = queryParams.get(param);
			if (paramValue) {
				queryParams.delete(param);
			}
		}
		history.replace({search: queryParams.toString()});
	};
	const setModal = (modal, id, extraParams) => {
		let queryParams = new URLSearchParams(window.location.search);
		if (modal) {
			queryParams.set("modal", modal);
			if (id) {
				queryParams.set("id", id);
			}
			if (extraParams) {
				extraParams.forEach((param) => {
					const key = Object.keys(param)[0];
					const value = param[key];
					queryParams.set(key, value);
				});
			}
		} else {
			let params = queryParams.toString();
			const indexOfModal = params.indexOf("modal");
			if (indexOfModal !== -1) {
				// Slice the string up to the start of "modal", removing it and everything after
				params = params.slice(0, indexOfModal - (indexOfModal > 0 ? 1 : 0));
			}
			queryParams = new URLSearchParams(params);
		}
		history.push({search: queryParams.toString()});
	};
	return {getUrlParams, setUrlParams, setQuery, clearUrlQuery, removeParams, setModal};
};
