import React from "react";
import RenderTables from "./components/renderTables/renderTables";

const BenutzerDetails = ({monthlyDataUser, year}) => (
	<>
		<RenderTables monthlyDataUser={monthlyDataUser} year={year} />
	</>
);

export default BenutzerDetails;
