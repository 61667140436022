import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "../../../store/sagas/app/navigation";

function ClientCustomName(props) {
	const {row} = props;

	return (
		<div className="user__table-fullName">
			<p>
				{row.firstName} {row.lastName}
			</p>
		</div>
	);
}

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(ClientCustomName));
