import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Commission index");

const PREFIX = "@app/comission/comission";
export const GET_COMMISSION = `${PREFIX}GET_COMMISSION`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_COMISSION_SUCCESS = `${PREFIX}FETCH_COMISSION_SUCCESS`;
export const ADD_COMISSION_ON_STATE = `${PREFIX}ADD_COMISSION_ON_STATE`;
export const EDIT_COMISSION_ON_STATE = `${PREFIX}EDIT_COMISSION_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;

const _state = {
	comissions: [],
	loading: false,
	page: 1,
	size: 10,
	totalSize: 5,
	search: "",
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload + 1;
				break;

			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}

				draft.search = action.payload.value;

				break;
			case SIZE_EDIT:
				draft.size = action.payload;
				break;
			case FETCH_COMISSION_SUCCESS:
				draft.comissions = action.payload?.data?.map((e) => ({
					productId: {name: e.productName, value: e.productId},
					tariffId: {name: e.tariffName, value: e.tariffId},
					provision: e?.provision,
					cancellationReserve: e?.cancellationReserve,
				}));
				break;
			case ADD_COMISSION_ON_STATE:
				draft.comissions = [action.payload].concat(state.comissions);
				break;
			case EDIT_COMISSION_ON_STATE:
				const index = state.comissions.findIndex((c) => c.id === action.payload.id);
				draft.comissions[index] = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getComission: (payload) => createAction(GET_COMMISSION, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchComissionSuccess: (payload) => createAction(FETCH_COMISSION_SUCCESS, {payload}),
	addComissionOnState: (payload) => createAction(ADD_COMISSION_ON_STATE, {payload}),
	editComissionOnState: (payload) => createAction(EDIT_COMISSION_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterComissions: (payload) => createAction(FILTERS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
};

export const sagas = {
	*getComission() {
		yield put(actions.setLoading(true));
		const {size} = yield select((state) => state.app.comission.index);
		const {search} = yield select((state) => state.app.comission.index);
		const {page} = yield select((state) => state.app.comission.index);

		try {
			const response = yield axios.get(
				`/commission/all?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchComissionSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_COMMISSION, sagas.getComission);
};
