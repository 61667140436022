import TextAreaComponent from "@src/common/TextArea/TextAreaComponent";
import {useFormik} from "formik";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Button from "@common/components/button";
import {actions as createOrderActions} from "@sagas/orders/create";
import {actions as calculationActions} from "@sagas/calculations/index";
import {CloseModalIcon} from "@src/assets/SvgComponents";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import {useUrlParams} from "@src/utils/useUrlParams";
import CustomSelect from "@src/common/Select";
import {useFeatures} from "@src/utils/useFeatures";
import i18n from "@src/i18n";
import {settleOrdersValidationSchema as validationSchema} from "./staticData";

const SettleOrders = ({
	handleClose,
	options,
	selectedRows,
	changeOrdersStatus,
	initialValues,
	title = i18n.t("settleOrders"),
	button = i18n.t("settleOrders"),
	statusValue = "SETTLED",
	bonuses,
	tableTab,
	userCalculations,
	getUserCalculations,
	open,
	createSettlementForHeadOfSales,
	selectedRowObjects,
	statuses,
	clearSelectedRows,
}) => {
	const {years, months} = options;
	const {checkFeature} = useFeatures();
	const isV2CurrentVersion = checkFeature("Change_order_status_v2");
	const {getUrlParams} = useUrlParams();
	const {query} = getUrlParams("tab", "query");
	const {salesOrganizationId} = query || {};
	const {t} = useTranslation();
	const areCurrentContractsBonus = tableTab === "BONUS";
	const formik = useFormik({
		initialValues: {
			...initialValues,
			tableTab,
			isV2CurrentVersion,
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: (values, {resetForm}) => {
			resetForm();
			selectHandlerPaidContracts(values);
		},
	});
	const selectHandlerPaidContracts = (values) => {
		const singleStatus = statuses.find((item) =>
			areCurrentContractsBonus
				? item.status === "BONUS_SETTLED"
				: item.status === statusValue,
		);
		if (Array.from(selectedRows)?.[0]) {
			const orders = selectedRowObjects.map((i) => ({
				id: i.orderId,
				clientPayoutModel: i.clientPayoutModel,
			}));
			if (salesOrganizationId) {
				createSettlementForHeadOfSales({
					orders,
					billingMonthId: values?.billingMonthId,
					description: values?.description,
					salesOrganizationId,
				});
			} else {
				changeOrdersStatus({
					orders,
					systemStatusId: singleStatus.id,
					...values,
				});
			}
			clearSelectedRows();
			handleClose();
		} else {
			ToastErrorComponent(t("PleaseSelectAction"));
		}
	};
	const {values, touched, errors, setFieldValue, handleSubmit, resetForm} = formik;
	const monthOptions = useMemo(() => {
		const monthsArr = values?.year ? months[values?.year] : [];
		return monthsArr;
	}, [values?.year]);
	useEffect(() => {
		if (!salesOrganizationId) {
			if (values?.billingMonthId) {
				getUserCalculations({
					billingMonthId: values?.billingMonthId,
					userId: query?.userIds,
				});
			} else {
				setFieldValue("settledUuid", null);
			}
		}
	}, [values?.billingMonthId]);
	useEffect(() => {
		if (!open) {
			resetForm();
		}
	}, [open]);
	return (
		<div className="settle_order_modal">
			<div className="settle_order_modal-titleAndIcon">
				<span className="settle_order_modal-titleAndIcon-title">{title}</span>
				<span className="settle_order_modal-titleAndIcon-icon" onClick={handleClose}>
					<CloseModalIcon fill="var(--color-primary)" />
				</span>
			</div>
			<form onSubmit={handleSubmit} className="settle__orders__form">
				{isV2CurrentVersion && (
					<>
						<CustomSelect
							options={years}
							onSelect={(value) => {
								setFieldValue("year", value || null);
								setFieldValue("month", null);
								setFieldValue("billingMonthId", null);
							}}
							placeholder={t("chooseYear")}
							value={values?.year}
							errors={errors?.year}
							touched={touched?.year}
							onClear={() => {
								setFieldValue("year", null);
								setFieldValue("month", null);
							}}
						/>
						<CustomSelect
							options={monthOptions}
							onSelect={(value, option) => {
								setFieldValue("month", option ? option.value : null);
								setFieldValue(
									"billingMonthId",
									option ? option.billingMonthId : null,
								);
								setFieldValue("settledUuid", null);
							}}
							disabled={!values.year}
							placeholder={t("chooseMonth")}
							value={values?.month}
							errors={errors?.month}
							touched={touched?.month}
							onClear={() => {
								setFieldValue("month", null);
								setFieldValue("billingMonthId", null);
							}}
						/>
					</>
				)}
				{areCurrentContractsBonus && (
					<>
						<CustomSelect
							options={bonuses?.map((item) => ({
								label: item?.name,
								value: item?.id,
							}))}
							onSelect={(value) => {
								setFieldValue("baugoId", value || null);
							}}
							placeholder={t("chooseBonus")}
							value={values?.baugoId}
							errors={errors?.baugoId}
							touched={touched?.baugoId}
							onClear={() => setFieldValue("baugoId", null)}
						/>
						<CustomSelect
							options={userCalculations?.map((calc) => ({
								value: calc,
								label: calc,
							}))}
							onSelect={(value) => {
								setFieldValue("settledUuid", value);
							}}
							placeholder={t("chooseSettlementNumber")}
							value={values?.settledUuid}
							errors={errors?.settledUuid}
							touched={touched?.settledUuid}
							onClear={() => setFieldValue("settledUuid", null)}
						/>
					</>
				)}
				{isV2CurrentVersion && (
					<TextAreaComponent
						maxLength={70}
						displayRandomText={false}
						value={values?.description}
						setFieldValue={setFieldValue}
						breakLine={false}
						width="100%"
						errors={errors?.description}
						touched={touched?.description}
						errorClass="errorClass"
					/>
				)}
				<div className="buttonsWrapper">
					<Button
						text={t("Cancel")}
						customClassName="buttonsWrapper__cancel"
						handleChange={handleClose}
					/>
					<Button
						text={button}
						customClassName="buttonsWrapper__confirm"
						handleChange={handleSubmit}
					/>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	options: state.app.calculations.billingMonths.billingMonthsDropdown,
	bonuses: state.app.projectCategories.bonuses.bonuses,
	userCalculations: state.app.calculations.index.userCalculations,
	statuses: state.app.orders.statuses.statuses,
});

const mapDispatchToProps = {
	changeOrdersStatus: createOrderActions.changeOrdersStatus,
	getUserCalculations: calculationActions.getUserCalculations,
	createSettlementForHeadOfSales: calculationActions.createSettlementForHeadOfSales,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettleOrders));
