/* eslint-disable no-console */
import React, {useEffect, useState} from "react";
import {BlobProvider} from "@react-pdf/renderer";
import axios from "axios";
import CalculationTablePdf from "./CalculationDetails/CalculationTablePdf";

const PdfViewer = ({match}) => {
	const {settlementId, token} = match.params;
	const [singleCalculation, setSingleCalculation] = useState();
	const [billingDetails, setBillingDetails] = useState();
	const [pdfBase64, setPdfBase64] = useState(null);

	const customHeaders = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const fetchData = async () => {
		const responseBilling = await axios.get(`/tenant/get/tenantSettings`, customHeaders);
		const response = await axios.post(
			`/order/settled/?page=${1}&size=${1000}`,
			{
				uuid: settlementId,
				search: "",
				fromDate: null,
				toDate: null,
			},
			customHeaders,
		);

		setSingleCalculation(response?.data?.data);
		setBillingDetails(responseBilling?.data?.data);
	};

	useEffect(() => {
		if (token && settlementId) {
			fetchData();
		}
	}, [token]);

	useEffect(async () => {
		if (pdfBase64) {
			await axios.post(
				`/files/upload`,
				{
					file: pdfBase64,
					fileName: `temporary_pdf_settlement_${settlementId}.pdf`,
				},
				customHeaders,
			);
		}
	}, [pdfBase64]);

	return (
		<>
			{singleCalculation?.data?.[0] && billingDetails !== undefined && (
				<>
					<div style={{width: "100%", height: "100%"}}>
						{pdfBase64 === null && (
							<BlobProvider
								document={
									<CalculationTablePdf
										data={singleCalculation}
										from="preview"
										billingDetails={billingDetails}
										id={settlementId}
									/>
								}
							>
								{({blob}) => {
									const blobToBase64 = (blob) =>
										new Promise((resolve, reject) => {
											const reader = new FileReader();
											reader.onload = () => {
												const base64String = reader.result.split(",")[1];
												resolve(base64String);
											};
											reader.onerror = reject;
											reader.readAsDataURL(blob);
										});
									if (blob) {
										blobToBase64(blob)
											.then((base64String) => {
												setPdfBase64(
													`data:application/pdf;base64,${base64String}`,
												);
											})
											.catch((error) => {
												console.error(
													"Error converting Blob to Base64:",
													error,
												);
											});
									}
									return <div>Theres something going on on the fly</div>;
								}}
							</BlobProvider>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default PdfViewer;
