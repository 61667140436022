import AllOrdersIcon from "@assets/img/all_orders_icon.svg";
import ParkingOrdersIcon from "@assets/img/parking_orders_icon.svg";
import ExportedToUtilitiesIcon from "@assets/img/exported_to_utilities.svg";
import CanceledContractIcon from "@assets/img/canceled_contracts_icon.svg";
import AssumedIcon from "@assets/img/assumed_icon.svg";
import i18n from "@src/i18n";

export const statuses = [
	{
		label: i18n.t("allOrder"),
		name: "All Orders",
		icon: AllOrdersIcon,
		bgColor: "purple",
	},
	{
		label: i18n.t("Pending"),
		name: "Pending",
		icon: ParkingOrdersIcon,
		bgColor: "orange",
	},
	{
		label: i18n.t("Delivered"),
		name: "Delivered",
		icon: ExportedToUtilitiesIcon,
		bgColor: "blue",
	},
	{
		label: i18n.t("canceledOrders"),
		name: "Cancelled",
		icon: CanceledContractIcon,
		bgColor: "red",
	},
	{
		label: i18n.t("Approved"),
		name: "Approved",
		icon: AssumedIcon,
		bgColor: "lightblue",
	},
];
