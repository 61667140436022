import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as messagesActions} from "@sagas/messages";
import {handleInputShowValue} from "@src/utils/message-converter";
import {scrollToMessage} from "@src/utils/scroll-to-message";
import moment from "moment";
import _ from "lodash";
import {isHyperLink} from "../SingleMessage/helper";

const LinksComponent = ({
	links,
	getMessagePage,
	insideTopic,
	setShowChatInfoModal,
	clearQuery,
	setQuery,
	query,
	getLinks,
	linkTotalPages,
}) => {
	const [fetchedPages, setFetchedPages] = useState([1]);
	const closeModal = () => {
		clearQuery();
		setShowChatInfoModal(false);
	};
	const handleScroll = _.debounce((event) => {
		const container = event.target;
		const scrollPosition = Math.abs(container.scrollTop);
		const maxScrollPosition = container.scrollHeight - container.clientHeight;
		if (
			Math.round(scrollPosition) === maxScrollPosition &&
			(scrollPosition !== 0 || maxScrollPosition !== 0) &&
			!fetchedPages.includes(query.page + 1) &&
			query.page < linkTotalPages
		) {
			setFetchedPages((prevState) => [...prevState, query.page + 1]);
			setQuery({
				...query,
				page: query.page + 1,
			});
			getLinks();
		}
	}, 500);

	return (
		<div className="search__messages-modal messages-modal-links" onScroll={handleScroll}>
			<div className="search_results">
				{links
					?.filter((word) => isHyperLink(word.text))
					.map((item, index) => (
						<div className="search_results-wrapper" key={index}>
							<div className="search_results-wrapper-header">
								<span className="result-date">
									{moment(item?.createdAtTimestamp).format("M/D/YYYY")}
								</span>
							</div>
							<div
								className="result-message"
								onClick={() => {
									closeModal();
									scrollToMessage(item?.id, insideTopic?.topicId, getMessagePage);
								}}
							>
								<span className="result-message-user">@{item?.fullName}</span>
								<span className="result-message-message">
									{item?.text &&
										handleInputShowValue(item?.text)
											.split(" ")
											.map((word, index) => {
												if (isHyperLink(word)) {
													return (
														<span className="matched-word" key={index}>
															{word}
														</span>
													);
												}
												return <> {word} </>;
											})}
								</span>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	query: state.app.messages.index.query,
	insideTopic: state.app.messages.index.insideTopic,
	mediaLoading: state.app.messages.index.mediaLoading,
	links: state.app.messages.index.links,
	linkTotalPages: state.app.messages.index.linkTotalPages,
});

const mapDispatchToProps = {
	setQuery: messagesActions.setQuery,
	clearQuery: messagesActions.clearQuery,
	getLinks: messagesActions.getLinks,
	getMessagePage: messagesActions.getMessagePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LinksComponent));
