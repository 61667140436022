import {CommentsIcon, ParentLeadIcon} from "@src/assets/SvgComponents";
import React from "react";

function RelationBox({data}) {
	return (
		<>
			<div className="box-left">
				<span className="box-left-name">
					{data?.firstName || data?.lastName
						? `${data?.firstName || ""} ${data?.lastName || ""}`
						: data?.generateLeadId}
				</span>
				<span className="box-left-address">
					{data?.viewAddress?.street} Nr.{data?.viewAddress?.houseNumber}
					{data?.viewAddress?.addition} {data?.viewAddress?.zipCode}{" "}
					{data?.viewAddress?.partLocation}, {data?.viewAddress?.location}
				</span>
			</div>
			<div className="box-right">
				{data?.isParent && <ParentLeadIcon />}
				{data?.leadActivityCount > 0 && (
					<div className="sales_tracker_icons-comments">
						<CommentsIcon />
						<span className="activity_count">{data?.leadActivityCount}</span>
					</div>
				)}
				{data?.weGrouping && (
					<div className="lead__potential">
						<span>{data?.weGrouping}</span>
					</div>
				)}
			</div>
		</>
	);
}

export default RelationBox;
