import React from "react";

const Tabs = ({items, setActiveTab, value}) => (
	<div className="tab_wrapper">
		{items?.map((item, index) => (
			<div
				key={index}
				onClick={(e) => setActiveTab(item)}
				className={`tab ${value === item.type ? "active" : ""}`}
			>
				{item.title}
			</div>
		))}
	</div>
);

export default Tabs;
