import moment from "moment";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {
	checkDate,
	checkValidDateInMonth,
	datesInitialValues,
	fillValues,
	handleRef,
} from "./helper";

export default function useDatePicker({
	value,
	minCharacters,
	isBirthdayInput,
	setFieldValue,
	convertedType,
	minDate,
	maxDate,
	name,
}) {
	const [dates, setDates] = useState(datesInitialValues);
	const dayRef = useRef();
	const monthRef = useRef();
	const yearRef = useRef();
	const {t} = useTranslation();
	const [fieldError, setFieldError] = useState(false);
	const handleValues = (item, value) => setDates((prev) => ({...prev, [item]: value}));
	const clearValues = () => setDates(datesInitialValues);

	const handleDay = (value) => {
		const newVal = `${value}`;
		clearValues();
		if (value <= 31) {
			handleValues("day", newVal);
			if (newVal.length === 2) {
				handleRef(dayRef, monthRef);
			}
		}
		setFieldError(value > 31);
		if (!value) setFieldValue(name, "");
	};
	const handleMonth = (value) => {
		const newVal = `${value}`;
		if (!value) {
			handleRef(monthRef, dayRef);
			handleValues("month", null);
			setFieldError(false);
		} else if (newVal.length <= 2) {
			handleValues("month", value);
			const isValidDate = checkValidDateInMonth(dates.day, value);
			if (isValidDate && newVal.length === 2) {
				handleRef(monthRef, yearRef);
			}
			setFieldError(!isValidDate);
		}
	};
	useEffect(() => {
		const result = fillValues(value);
		setDates(result);
	}, [value]);

	const handleYear = (value) => {
		const newVal = `${value}`;
		if (newVal.length === 4) {
			const newObj = {
				...dates,
				month: dates.month - 1,
				year: value,
			};
			const timestamp = moment(newObj).format("DD/MM/YYYY");
			const validateDate = checkDate(timestamp, isBirthdayInput, minCharacters);
			if (validateDate) {
				setFieldError(null);
				setFieldValue(name, timestamp);
				yearRef.current.blur();
			} else if (minCharacters) {
				setFieldError(`${t("dateError")} ${minCharacters} ${t("yearsAgo")}`);
			}
		}
		if (!value) {
			handleRef(yearRef, monthRef);
			handleValues("year", null);
		} else if (newVal.length <= 4) {
			handleValues("year", value);
		}
	};
	const inputs = [
		{
			ref: dayRef,
			value: dates.day,
			placeholder: "DD",
			onChangeText: handleDay,
			name: "day",
		},
		{
			ref: monthRef,
			value: dates.month,
			placeholder: "MM",
			onChangeText: handleMonth,
			name: "month",
		},
		{
			ref: yearRef,
			value: dates.year,
			placeholder: "YYYY",
			onChangeText: handleYear,
			maxLength: 4,
			name: "year",
		},
	];
	const disabledDate = (current) => {
		if (current) {
			if (minDate && maxDate) {
				const comparingMinDate = moment().subtract(minDate, convertedType);
				const comparingMaxDate = moment().add(maxDate, convertedType);

				return current.isBefore(comparingMinDate) || current.isAfter(comparingMaxDate);
			}
			if (minDate) {
				if (isBirthdayInput) {
					const comparingDate = moment().subtract(minDate, convertedType);
					return moment(comparingDate).isBefore(current);
				}
				const comparingMinDate = moment().subtract(minDate, convertedType);
				return current.isBefore(comparingMinDate);
			}
			if (maxDate) {
				const comparingMaxDate = moment().add(maxDate, convertedType);
				return current.isAfter(comparingMaxDate);
			}
			if (minDate === 0) {
				const comparingDate = moment().add(1, convertedType);
				return current.isBefore(comparingDate);
			}
			if (maxDate === 0) {
				const comparingDate = moment().subtract(1, convertedType);
				return current.isAfter(comparingDate);
			}
		}
		return false;
	};

	return {disabledDate, fieldError, inputs};
}
