import React from "react";
import {tabs} from "../helpers";

const Tabs = ({handleTab, activeTab}) => {
	const tab = "";
	return (
		<div className="tabs__view-wrapper">
			{tabs.map(({Icon, label, type}) => (
				<div
					key={type}
					onClick={() => handleTab(type)}
					className={`single__tab ${activeTab === type && "active"}`}
				>
					<Icon />
					<span>{label}</span>
				</div>
			))}
		</div>
	);
};

export default Tabs;
