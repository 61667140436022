import React from "react";

function MerkmaleSection() {
	return (
		<div className="merkmaleSection__container">
			<div className="merkmaleSection__container--title">Merkmale</div>
			<div className="merkmaleSection__container--sections">
				<div className="merkmaleSection__container--sections-firstwithline">
					<div className="merkmaleSection__container--sections-firstwithline-first">
						<span>1 Monat Kündigungsfrist</span>
						<span>12 Monate E-Preisgarantie</span>
						<span>12 Monate Mindestlaufzeit</span>
					</div>
					<div className="merkmaleSection__container--line"></div>
				</div>
				<div className="merkmaleSection__container--sections-second">
					<span>mtl. Zahlungsrhythmus</span>
					<span>12 Abschläge</span>
					<span>Icons</span>
				</div>
			</div>
		</div>
	);
}

export default MerkmaleSection;
