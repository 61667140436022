import {ArrowDownIcon, ArrowUpIcon, NoDataIcon} from "@src/assets/SvgComponents";
import {Dropdown, Skeleton} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import DataGrid, {Row} from "react-data-grid";
import {useTranslation} from "react-i18next";
import Loader from "../Loader";
import {paginationItems} from "./paginationItems";
import {sortWithDetailedRows} from "./sortDetailedRows";
import {tabs} from "./staticData";
import TableSkeleton from "./TableSkeleton";

function DataGridTable({
	columns,
	data,
	selectedRows,
	setSelectedRows,
	editSize,
	size,
	totalSize,
	loading,
	pagination,
	rowHeight = 44,
	height = 700,
	enableCellSelect = true,
	editPage,
	page,
	totalPages,
	reEditPage,
	setReEditPage,
	previewRow,
	showEditModal,
	onRowClick,
	toggle = false,
	rows,
	onRowsChange,
	activeTab,
	style,
	onTabClick,
	currentTab,
	withTabs,
	rowClass,
	className = "table__wrapper",
	wrapperStyles,
	customClassName = "",
	customTabs = [],
	tableRef,
	getByID = true,
	headerRowHeight = 40,
	scrollDivBackground = "#ffffff",
	stopPropagation = false,
	selectObject = false,
	setSelectedRowObjects,
	key = "orderId",
}) {
	const {t} = useTranslation();
	function rowKeyGetter(row) {
		return (getByID && row?.orderId) || row?.id || row?.month || row?.uuid;
	}
	const theme = localStorage.getItem("theme");
	const parsedTheme = JSON.parse(theme);

	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [showTable, setShowTable] = useState(!toggle);
	const [sortColumns, setSortColumns] = useState([]);
	const onSortColumnsChange = useCallback((sortColumns) => {
		setSortColumns(sortColumns.slice(-1));
	}, []);
	const sortedRows = useMemo(() => {
		if (sortColumns?.length === 0) return rows?.[0] ? rows : data;
		const {columnKey, direction} = sortColumns[0];
		let sortedRows = [...data];
		const rowsToSort = rows?.[0] ? [...rows] : [...data];
		rowsToSort.forEach((row) => {
			if (columnKey?.includes(".")) {
				const splittedKeys = columnKey.split(".");
				const [firstKey, secondKey] = splittedKeys;
				if (typeof row[firstKey]?.[secondKey] === "number") {
					sortedRows = sortedRows?.sort(
						(a, b) => a[firstKey]?.[secondKey] - b[firstKey]?.[secondKey],
					);
				} else {
					sortedRows = sortedRows?.sort((a, b) =>
						a[firstKey]?.[secondKey]?.localeCompare(b[firstKey]?.[secondKey], {
							sensitivity: "base",
						}),
					);
				}
			} else if (typeof row[columnKey] === "string") {
				sortedRows = sortedRows?.sort((a, b) => {
					const numberA = +a[columnKey];
					const numberB = +b[columnKey];
					if (numberA && numberB) {
						return a[columnKey] - b[columnKey];
					}
					return a[columnKey]?.localeCompare(b[columnKey], {
						sensitivity: "base",
					});
				});
			} else if (typeof row[columnKey] === "number") {
				sortedRows = sortedRows?.sort((a, b) => a[columnKey] - b[columnKey]);
			}
		});
		if (rowsToSort.some((row) => row.type === "MASTER" && row.expanded)) {
			const updatedRows = sortWithDetailedRows(sortedRows, rowsToSort, direction);
			return updatedRows;
		}
		return direction === "DESC" ? sortedRows.reverse() : sortedRows;
	}, [data, sortColumns, rows]);
	useEffect(() => {
		if (activeTab) setSortColumns([]);
	}, [activeTab]);
	const rowRenderer = (row) => (
		<div
			style={{cursor: onRowClick && "pointer", overflowY: "auto"}}
			key={row.rowIdx}
			className="row-container"
		>
			<Row
				{...row}
				key={row.rowIdx}
				className={`${row?.row?.errors?.length ? "error" : ""} ${
					row?.row?.child ? "data__grid__row__child" : ""
				}`}
			/>
		</div>
	);
	async function handleScrollPage(event) {
		event.preventDefault();
		if (!pagination || loading || !isAtBottom(event) || page >= totalPages || !reEditPage)
			return;
		editPage(page + 1);
	}
	function isAtBottom({currentTarget}) {
		setReEditPage(true);
		return (
			currentTarget.scrollTop >= currentTarget.scrollHeight - currentTarget.clientHeight - 1
		);
	}
	const emptyRowsView = () => (
		<div
			className="empty-rows-view dataGrid_view"
			style={{height: "100%", color: parsedTheme?.primary}}
		>
			<NoDataIcon width={70} height={70} fill="#C1C1C1" stroke="#979797" />
			<span style={{color: "#979797"}}>{t("NoData")}</span>
		</div>
	);
	const tabsArray = customTabs?.length > 0 ? customTabs : tabs;
	const handleStopPropagation = (event) => {
		event.stopPropagation();
		event.preventDefault();
	};
	const handleSelectedRowsChange = (newSelectedRows) => {
		setSelectedRows(newSelectedRows);
		if (selectObject) {
			const selectedObjects = Array.from(newSelectedRows).map((id) =>
				data.find((row) => row?.[key] === id),
			);
			setSelectedRowObjects(selectedObjects);
		}
	};
	return (
		<>
			{showTable && (
				<>
					{withTabs && (
						<div
							className={`table__tabs ${
								!previewRow && !showEditModal && loading ? "loading" : ""
							}`}
						>
							{tabsArray?.map((tab) =>
								!previewRow && !showEditModal && loading ? (
									<Skeleton.Input
										active
										style={{
											borderRadius: 5,
											maxWidth: 100,
											minWidth: 100,
											height: 30,
										}}
									/>
								) : (
									<div
										key={tab.type}
										onClick={() => onTabClick(tab.type)}
										className={`tab ${currentTab === tab.type ? "active" : ""}`}
										style={{
											pointerEvents:
												!previewRow && !showEditModal && loading
													? "none"
													: "auto",
										}}
									>
										<span className="icon">{tab.icon}</span>
										<span className="name">{tab.name}</span>

										{currentTab === tab?.type && selectedRows?.size > 0 && (
											<span
												className={
													tab.type === "PAID"
														? "selected__count"
														: "selected__count second"
												}
											>
												{selectedRows?.size > 99
													? "99+"
													: selectedRows?.size}
											</span>
										)}
									</div>
								),
							)}
						</div>
					)}
					<div
						onClick={stopPropagation ? (e) => handleStopPropagation(e) : () => {}}
						onDoubleClick={stopPropagation ? (e) => handleStopPropagation(e) : () => {}}
						className={`${customClassName} ${className}`}
						style={wrapperStyles}
					>
						<DataGrid
							className="rdg-light"
							columns={columns.map((item) => ({
								...item,
								editable: item.editable,
							}))}
							rows={sortedRows}
							enableCellSelect={enableCellSelect}
							rowKeyGetter={rowKeyGetter}
							rowRenderer={rowRenderer}
							selectedRows={selectedRows}
							onSelectedRowsChange={handleSelectedRowsChange}
							rowHeight={rowHeight}
							style={{height: "calc(100% - 40px)", ...style}}
							height={height}
							onRowsChange={onRowsChange}
							onScroll={handleScrollPage}
							emptyRowsRenderer={emptyRowsView}
							headerRowHeight={headerRowHeight}
							onRowClick={onRowClick && onRowClick}
							sortColumns={sortColumns}
							onSortColumnsChange={onSortColumnsChange}
							rowClass={rowClass}
							ref={tableRef}
						/>
						{pagination && (
							<>
								{!previewRow && !showEditModal && loading && (
									<div
										className={`infinitscroll_loader ${page === 1 && "center"}`}
										style={{
											top: withTabs && page === 1 ? "42px" : "auto",
										}}
									>
										{page === 1 ? (
											<TableSkeleton rowHeight={rowHeight} />
										) : (
											<Loader />
										)}
									</div>
								)}
								<div
									className="custom-pagination-wrapper"
									style={{
										position: loading && page !== 1 && "relative",
										bottom: loading && page !== 1 && "40px",
									}}
								>
									<div className="custom-pagination">
										{totalSize > 0 && (
											<Dropdown
												menu={{
													items: paginationItems(
														editSize,
														editPage,
														t,
														totalSize,
														setDropdownVisible,
														setReEditPage,
													),
												}}
												overlayClassName="popover__actions-wrapper"
												placement="bottomLeft"
												trigger={["click"]}
												open={dropdownVisible}
												onOpenChange={() =>
													setDropdownVisible(!dropdownVisible)
												}
											>
												<div className="three-dots-icon">
													{`${size} ${t("Rows")}`}
													{dropdownVisible ? (
														<ArrowUpIcon />
													) : (
														<ArrowDownIcon />
													)}
												</div>
											</Dropdown>
										)}
										<span className="custom-pagination-information">
											{`${
												data?.filter((r) => r?.type !== "DETAIL")?.length
											} ${t("from")} ${totalSize}`}
										</span>
									</div>
								</div>
							</>
						)}
					</div>
				</>
			)}
		</>
	);
}

export default DataGridTable;
