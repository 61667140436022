/* eslint-disable max-len */
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {actions as createSalesTrackerActions} from "@sagas/salesTracker/create";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {actions as clientActions} from "@sagas/client";
import {actions as teamActions} from "@sagas/teams";
import {actions as usersActions} from "@sagas/users/users";
import {actions as locationActions} from "@sagas/locations";
import {actions as salesOrgActions} from "@sagas/salesOrganizations";
import {connect} from "react-redux";
import {getCurrentUser} from "@utils/currentUser";
import Form from "@src/common/Form";
import {formatOptionsForSelect} from "@src/utils/getOptionsForSelect";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {useUrlParams} from "@src/utils/useUrlParams";
import {checkRoles} from "@src/utils/checkRoles";
import {formatGroupOptions, renderModalTitle, VIEWS, WIDTH_VALUES} from "./helper";
import {userRolesDropdown} from "../helper";

const SalesTrackerModal = (props) => {
	const {
		setShowModal,
		showModal,
		lead,
		createLead,
		clearLead,
		optionsClients,
		getClients,
		duplicateLead,
		teams,
		agencies,
		getTeams,
		getAgencies,
		fetchLocations,
		locations,
		editSize,
		leadStatuses,
		statuses,
		getUserDropdown,
		usersOptions,
		getLeadById,
		loggedUser,
		loading,
		getAllSalesOrganizations,
		getUsersByLeaderId,
	} = props;
	const {t} = useTranslation();
	const {getUrlParams} = useUrlParams();
	const {id, viewType, query} = getUrlParams("id", "view-type", "query");
	const [groupedOptions, setGroupedOptions] = useState([]);
	const [duplicateModal, setDuplicateModal] = useState(false);
	const [activeView, setActiveView] = useState(() =>
		viewType === "mapView" ? VIEWS.preview : VIEWS.edit,
	);
	const toggleActiveView = () =>
		setActiveView((prev) => (prev === VIEWS.edit ? VIEWS.preview : VIEWS.edit));
	const currentUser = getCurrentUser();
	const findClientLocationCheck = optionsClients.find((item) => item?.value === lead?.clientId);
	const currentStatus = statuses?.find((item) => item.id === lead?.statusId);
	useEffect(() => {
		if (loggedUser?.role) {
			if (lead?.id) {
				if (checkRoles("getUsersByLeaderId")) {
					getUsersByLeaderId(loggedUser?.id);
				} else {
					getTeams({isTeam: false, all: true});
					getAgencies({isTeam: true, all: true});
				}
			} else {
				getClients();
				if (!checkRoles("getUsersByLeaderId")) {
					getTeams({isTeam: false, all: true});
					getAgencies({isTeam: true, all: true});
					getUserDropdown(
						checkRoles("getUsersByHeadOfSalesId")
							? ["HEAD_OF_SALES"]
							: userRolesDropdown,
					);
				} else {
					getUsersByLeaderId(loggedUser?.id);
				}
			}
			getAllSalesOrganizations("all");
		}
		return () => editSize(10);
	}, [loggedUser]);

	const filteredClients = useMemo(() => {
		if (viewType === "mapView") {
			return optionsClients.filter((item) => item.leads && item.leadsLocation);
		}
		return optionsClients.filter((item) => item.leads);
	}, [viewType, optionsClients]);

	useEffect(() => {
		const items = formatGroupOptions(
			checkRoles("getTeamsDropdownAsLeader") ? [] : teams,
			checkRoles("getTeamsDropdownAsLeader") ? [] : agencies,
			usersOptions,
		);
		setGroupedOptions(items);
	}, [teams, agencies, usersOptions, loggedUser]);

	useEffect(() => {
		if (id && showModal) {
			getLeadById({id});
		}
	}, [id, showModal]);

	const handleLead = (values) => {
		if (!lead.id) {
			const newUsers = checkRoles("addUsersOnLeads")
				? values?.selectedUsers?.map((user) => user?.value)
				: [currentUser?.id];
			const obj = {
				...values,
				currentUser: currentUser.id,
				updateLeadId: null,
				userIds: newUsers,
				createAddress: {
					...values.createAddress,
					latitude: lead?.createAddress?.latitude,
					longitude: lead?.createAddress?.longitude,
				},
			};
			createLead({values: obj, handleCloseModal, viewType, query});
		} else {
			const obj = {
				...values,
				updateLeadId: lead?.id,
				userIds: lead?.userIds,
				currentUser: currentUser?.id,
			};
			createLead({values: obj, handleCloseModal, viewType, query});
		}
	};
	const handleCloseModal = () => {
		clearLead();
		setShowModal(false);
	};
	const handleConfirm = () => {
		duplicateLead({lead, handleCloseModal});
		setDuplicateModal(false);
	};
	const getClientLocations = (clientId) => {
		editSize(1000);
		fetchLocations(clientId);
	};
	const currentLeadStatus = useMemo(
		() => leadStatuses?.find((it) => it.id === lead?.leadStatusId),
		[lead.leadStatusId, leadStatuses],
	);
	const inputs = [
		{
			title: t("firstName"),
			field: "firstName",
			inputType: "text",
			width: WIDTH_VALUES[310],
			hidden: activeView === VIEWS.preview,
		},
		{
			title: t("lastName"),
			field: "lastName",
			inputType: "text",
			width: WIDTH_VALUES[310],
			hidden: activeView === VIEWS.preview,
		},
		{
			title: t("ZipCode"),
			field: "createAddress.zipCode",
			inputType: "text",
			required: true,
			disabled: !!lead?.id || !!lead.createAddress.zipCode,
			width: WIDTH_VALUES[140],
			defaultValue: lead.createAddress.zipCode,
			hidden: activeView === VIEWS.preview,
		},
		{
			title: t("Location"),
			field: "createAddress.location",
			inputType: "text",
			required: true,
			disabled: !!lead?.id || !!lead.createAddress.location,
			width: WIDTH_VALUES[480],
			defaultValue: lead.createAddress.location,
			hidden: activeView === VIEWS.preview,
		},
		{
			title: t("SubLocation"),
			field: "createAddress.partLocation",
			inputType: "text",
			required: true,
			disabled: !!lead?.id || !!lead.createAddress.partLocation,
			width: WIDTH_VALUES[140],
			defaultValue: lead.createAddress.partLocation,
			hidden: activeView === VIEWS.preview,
		},
		{
			title: t("Street"),
			field: "createAddress.street",
			inputType: "text",
			required: true,
			disabled: !!lead?.id || !!lead.createAddress.street,
			width: WIDTH_VALUES[344],
			defaultValue: lead.createAddress.street,
			hidden: activeView === VIEWS.preview,
		},
		{
			title: "No.",
			field: "createAddress.houseNumber",
			inputType: "text",
			required: true,
			disabled: !!lead?.id || !!lead.createAddress.houseNumber,
			width: WIDTH_VALUES[116],
			defaultValue: lead.createAddress.houseNumber,
			hidden: activeView === VIEWS.preview,
		},
		{
			title: t("ChangeStatus"),
			field: "leadStatus",
			inputType: activeView === VIEWS.preview ? "button-input" : "dropdown",
			additionalField: "leadStatusType",
			hidden: currentStatus?.statusType === "SUCCESS" || !lead?.id,
			options: leadStatuses,
			dependentFields: ["date"],
			customWidth: "50%",
		},
		{
			title: t("ChooseClient"),
			field: "client",
			inputType: "dropdown",
			hidden: !!lead?.id,
			required: true,
			options: filteredClients,
			dependentFields: ["projectId"],
			onChange: getClientLocations,
		},
		{
			title: t("ChooseProject"),
			field: "projectId",
			inputType: "dropdown",
			dependentFrom: "client",
			hidden: !!lead?.id,
			options: formatOptionsForSelect(locations, "id", "locationName"),
		},
		{
			title: t("SelectDate"),
			field: "date",
			inputType: "datepicker",
			dependentFrom: "leadStatusType",
			dependentOption: "DATE",
			showTime: true,
		},
		{
			title: t("Description"),
			field: "description",
			inputType: "textarea",
			maxLength: 100,
			hidden:
				!lead?.id ||
				currentStatus?.statusType === "SUCCESS" ||
				activeView === VIEWS.preview,
		},
		{
			title: t("ChooseUsers"),
			field: "selectedUsers",
			inputType: "groupedSelect",
			options: groupedOptions,
			required: currentUser.role === "TENANT" || currentUser.role === "BACK-OFFICE",
			multiple: true,
			hidden: !!lead?.id,
		},
		{
			inputType: "map",
			noInput: true,
			hidden:
				!lead?.id ||
				!findClientLocationCheck?.leadsLocation ||
				lead?.createAddress?.latitude === null ||
				lead?.createAddress?.longitude === null ||
				activeView === VIEWS.preview,
			value: {
				lat: lead?.createAddress?.latitude,
				lng: lead?.createAddress?.longitude,
			},
		},
		{
			inputType: "activities",
			noInput: true,
			hidden: !lead?.id || activeView === VIEWS.preview,
			field: "leadActivity",
			value: lead,
			options: usersOptions,
		},
		{
			title: t("currentStatus"),
			field: "currentStatus",
			hidden: true,
			inputType: "text",
			defaultValue: currentLeadStatus?.label,
			itemColor: currentLeadStatus?.color,
		},
	];
	const formData = {
		inputs,
		id,
		title: id ? t("Update") : t("Create"),
		initialValues: lead,
		submitFn: handleLead,
	};

	const openDuplicateModal = () => setDuplicateModal(true);
	const closeDuplicateModal = () => setDuplicateModal(false);
	const previewInputs =
		lead.id && activeView === VIEWS.preview
			? inputs?.filter((it) => it.inputType === "text")
			: null;
	return (
		<>
			<Form
				open={showModal}
				handleClose={handleCloseModal}
				formData={formData}
				width={700}
				inputsWrapperClassName="gapLess__inputs"
				canSave={currentStatus?.statusType !== "SUCCESS" || lead?.parentId !== null}
				CustomTitle={() => renderModalTitle(lead)}
				duplicate={!!lead?.id}
				onDuplicateClick={openDuplicateModal}
				loading={loading}
				preview={activeView === VIEWS.edit}
				onViewToggle={lead.id ? toggleActiveView : null}
				previewInputs={previewInputs}
			/>
			<DeleteModal
				calculationModal
				newTitle={t("AreuSureDuplicate")}
				setShowDeleteModal={closeDuplicateModal}
				open={duplicateModal}
				confirm={handleConfirm}
				handleClose={closeDuplicateModal}
			/>
		</>
	);
};
const mapStateToProps = (state) => ({
	lead: state.app.salesTracker.index.lead,
	loading: state.app.salesTracker.index.modalLoading,
	children: state.app.salesTracker.create.children,
	optionsClients: state.app.client.index.optionsClients,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	locations: state.app.locations.index.allLocations,
	leadStatuses: state.app.salesTracker.leadStatus.leadStatuses,
	statuses: state.app.salesTracker.statuses.statuses,
	usersOptions: state.app.users.index.usersOptions,
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	createLead: createSalesTrackerActions.createLead,
	clearLead: salesTrackerActions.clearLead,
	getClients: clientActions.fetchClientsByDropdown,
	duplicateLead: createSalesTrackerActions.duplicateLead,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getUserDropdown: usersActions.getUserDropdown,
	fetchLocations: locationActions.fetchAllLocations,
	editSize: locationActions.editSize,
	getLeadById: salesTrackerActions.getLeadById,
	getAllSalesOrganizations: salesOrgActions.getAllSalesOrganizations,
	getUsersByLeaderId: usersActions.getUsersByLeaderId,
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesTrackerModal);
