import React from "react";

function PriorityBox({row}) {
	return (
		<div style={{border: `1px solid ${row?.color}`}} className="priority-box-wrapper">
			<div
				style={{border: `1px solid ${row?.color}`, background: `${row?.color}30`}}
				className="priority-box-wrapper_box"
			>
				<div style={{background: row?.color}} className="dot" />
			</div>
			<span style={{color: row?.color}} className="priority-box-wrapper_name">
				{row?.name}
			</span>
		</div>
	);
}
export default PriorityBox;
