import {Skeleton} from "antd";
import React from "react";

function GridSkeleton() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			{Array.from({length: 3}).map((_, index) => (
				<div key={index} className="goals__skeleton">
					{Array.from({length: 5}).map((_, rowIndx) => (
						<div
							key={rowIndx}
							style={{
								height: 240,
								borderRadius: 10,
								width: "100%",
								background: "#e1e1e1",
								padding: 10,
							}}
						>
							<Skeleton active />
						</div>
					))}
				</div>
			))}
		</div>
	);
}

export default GridSkeleton;
