import {useSortable} from "@dnd-kit/sortable";
import React from "react";
import useMountStatus from "./useMountStatus";
import {Item} from "./Item";

export default function SortableItem({
	disabled,
	id,
	index,
	handle,
	style,
	containerId,
	getIndex,
	wrapperStyle,
	item,
	orderField = false,
}) {
	const {
		setNodeRef,
		setActivatorNodeRef,
		listeners,
		isDragging,
		isSorting,
		over,
		overIndex,
		transform,
		transition,
	} = useSortable({
		id,
	});
	const mounted = useMountStatus();
	const mountedWhileDragging = isDragging && !mounted;

	return (
		<Item
			ref={disabled ? undefined : setNodeRef}
			value={id}
			dragging={isDragging}
			sorting={isSorting}
			handle={handle}
			handleProps={handle ? {ref: setActivatorNodeRef} : undefined}
			index={index}
			wrapperStyle={wrapperStyle({index})}
			style={style({
				index,
				value: id,
				isDragging,
				isSorting,
				overIndex: over ? getIndex(over.id) : overIndex,
				containerId,
			})}
			transition={transition}
			transform={transform}
			fadeIn={mountedWhileDragging}
			listeners={listeners}
			item={item}
			orderField={orderField}
		/>
	);
}
