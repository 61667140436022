/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "../../../../utils/action-creator";
import Logger from "../../../../utils/logger";
import axios from "../../../../utils/axios";

const logger = new Logger("Trainings Upload Files");
const PREFIX = "@app/uploadFiles/Index";

export const ADD_UPLOADFILE = `${PREFIX}ADD_UPLOADFILE`;
export const ADD_UPLOADFILE_SUCCESS = `${PREFIX}ADD_UPLOADFILE_SUCCESS`;
export const UPLOAD_LOADING = `${PREFIX}UPLOAD_LOADING`;
export const ADD_NULLABLE_FILES = `${PREFIX}ADD_NULLABLE_FILES`;

const _state = {
	uploadedFiles: [],
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case ADD_UPLOADFILE_SUCCESS:
				const files =
					payload.method === "get"
						? payload.files
						: [...state.uploadedFiles, payload.files];
                 if(payload.filterNullableFiles) {
                    const allFiles = [...state.uploadedFiles];
					const firstFileIdx = allFiles.findIndex(file => file.fileUrl === null);
					if(firstFileIdx !== -1) {
						allFiles[firstFileIdx] = payload.files;
						draft.uploadedFiles = allFiles;
					}
				 } else {
					draft.uploadedFiles = files;
				 }
				// draft.uploadedFiles = payload?.filterNullableFiles
				// 	? files?.filter((file) => file.fileUrl !== null)
				// 	: files;
				break;
			case UPLOAD_LOADING:
				draft.loading = payload;
				break;
			case ADD_NULLABLE_FILES:
				const newArr = Array.from({ length: payload }, () => ({ fileUrl: null }));
				draft.uploadedFiles = [...newArr,...state.uploadedFiles];
				break;	
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	uploadFile: (payload) => createAction(ADD_UPLOADFILE, {payload}),
	uploadFileSuccess: (payload) => createAction(ADD_UPLOADFILE_SUCCESS, {payload}),
	setLoading: (payload) => createAction(UPLOAD_LOADING, {payload}),
	addNullableFiles: (payload) => createAction(ADD_NULLABLE_FILES , {payload})
};

export const sagas = {
	*uploadFiles({payload}) {
		if(payload?.files) {
			yield put(actions.addNullableFiles(payload?.files?.length))
		}
		yield put(actions.setLoading(true));
		try {
			if (payload?.files) {
				for (let i = 0; i < payload?.files?.length; i++) {
					const file = payload.files[i];
					const response = yield axios.post(
						`/template/${payload.templateId}/file/upload`,
						{
							file: file.link,
							fileName: file.name,
						},
					);
					yield put(
						actions.uploadFileSuccess({
							files: {
								...file,
								fileUrl: response?.data?.data,
								fileSize: file.size,
							},
							filterNullableFiles: true,
						}),
					);
				}
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_UPLOADFILE, sagas.uploadFiles);
};
