import i18n from '@src/i18n';
import * as yup from 'yup'

export const energyTypes = [
	{ value: 'STROM', name: 'Strom', type: 1 },
	{ value: 'GAS', name: 'Gas', type: 2 },
]
export const customerTypes = [
	{ value: 'PRIVATE', name: 'Privat', type: 0 },
	{ value: 'COMMERCIAL', name: 'Gewerbe', type: 1 },
	// { value: 'WEG', name: 'WEG' },
]

export const countries = [
	{ value: "Deustchland", name: "Deustchland" },
	{ value: "Österreich", name: "Österreich" },
];
export const germanRegions = [
	{ name: 'BW', labelEn: 'Baden-Württemberg', labelDe: 'Baden-Württemberg' },
	{ name: 'BY', labelEn: 'Bavaria', labelDe: 'Bayern' },
	{ name: 'BE', labelEn: 'Berlin', labelDe: 'Berlin' },
	{ name: 'BB', labelEn: 'Brandenburg', labelDe: 'Brandenburg' },
	{ name: 'HB', labelEn: 'Bremen', labelDe: 'Bremen' },
	{ name: 'HH', labelEn: 'Hamburg', labelDe: 'Hamburg' },
	{ name: 'HE', labelEn: 'Hesse', labelDe: 'Hessen' },
	{ name: 'NI', labelEn: 'Lower Saxony', labelDe: 'Niedersachsen' },
	{
		name: 'MV',
		labelEn: 'Mecklenburg-Vorpommern',
		labelDe: 'Mecklenburg-Vorpommern',
	},
	{
		name: 'NW',
		labelEn: 'North Rhine-Westphalia',
		labelDe: 'Nordrhein-Westfalen',
	},
	{ name: 'RP', labelEn: 'Rhineland-Palatinate', labelDe: 'Rheinland-Pfalz' },
	{ name: 'SL', labelEn: 'Saarland', labelDe: 'Saarland' },
	{ name: 'SN', labelEn: 'Saxony', labelDe: 'Sachsen' },
	{ name: 'ST', labelEn: 'Saxony-Anhalt', labelDe: 'Sachsen-Anhalt' },
	{ name: 'SH', labelEn: 'Schleswig-Holstein', labelDe: 'Schleswig-Holstein' },
	{ name: 'TH', labelEn: 'Thuringia', labelDe: 'Thüringen' },
]

export const germanCities = [
	{ name: 'BER', labelEn: 'Berlin', labelDe: 'Berlin' },
	{ name: 'HAM', labelEn: 'Hamburg', labelDe: 'Hamburg' },
	{ name: 'MUC', labelEn: 'Munich', labelDe: 'München' },
	{ name: 'CGN', labelEn: 'Cologne', labelDe: 'Köln' },
	{ name: 'FRA', labelEn: 'Frankfurt', labelDe: 'Frankfurt' },
	{ name: 'STR', labelEn: 'Stuttgart', labelDe: 'Stuttgart' },
	{ name: 'DUS', labelEn: 'Dusseldorf', labelDe: 'Düsseldorf' },
	{ name: 'DTM', labelEn: 'Dortmund', labelDe: 'Dortmund' },
	{ name: 'ESS', labelEn: 'Essen', labelDe: 'Essen' },
	{ name: 'LEJ', labelEn: 'Leipzig', labelDe: 'Leipzig' },
	{ name: 'DRS', labelEn: 'Dresden', labelDe: 'Dresden' },
	{ name: 'HAJ', labelEn: 'Hanover', labelDe: 'Hannover' },
	{ name: 'NUE', labelEn: 'Nuremberg', labelDe: 'Nürnberg' },
	{ name: 'BRE', labelEn: 'Bremen', labelDe: 'Bremen' },
	{ name: 'DU', labelEn: 'Duisburg', labelDe: 'Duisburg' },
	{ name: 'BO', labelEn: 'Bochum', labelDe: 'Bochum' },
]

export const contractTypesTo = [
	{
		name: 'notDefined',
		labelEn: 'Contract duration (until) undefined',
		labelDe: 'Vertragslaufzeit (bis) egal',
	},
	{
		name: 'threeMonths',
		labelEn: 'Contract duration (until) 3 months',
		labelDe: 'Vertragslaufzeit (bis) 3 Monate',
	},
	{
		name: 'sixMonths',
		labelEn: 'Contract duration (until) 6 months',
		labelDe: 'Vertragslaufzeit (bis) 6 Monate',
	},
	{
		name: 'twelveMonths',
		labelEn: 'Contract duration (until) 12 months',
		labelDe: 'Vertragslaufzeit (bis) 12 Monate',
	},
	{
		name: 'twentyFourMonths',
		labelEn: 'Contract duration (until) 24 months',
		labelDe: 'Vertragslaufzeit (bis) 24 Monate',
	},
	{
		name: 'thirtySixMonths',
		labelEn: 'Contract duration (until) 36 months',
		labelDe: 'Vertragslaufzeit (bis) 36 Monate',
	},
]

export const contractTypesFrom = [
	{
		name: 'notDefined',
		labelEn: 'Contract duration (from) undefined',
		labelDe: 'Vertragslaufzeit (ab) egal',
	},
	{
		name: 'threeMonths',
		labelEn: 'Contract duration (from) 3 months',
		labelDe: 'Vertragslaufzeit (ab) 3 Monate',
	},
	{
		name: 'sixMonths',
		labelEn: 'Contract duration (from) 6 months',
		labelDe: 'Vertragslaufzeit (ab) 6 Monate',
	},
	{
		name: 'twelveMonths',
		labelEn: 'Contract duration (from) 12 months',
		labelDe: 'Vertragslaufzeit (ab) 12 Monate',
	},
	{
		name: 'twentyFourMonths',
		labelEn: 'Contract duration (from) 24 months',
		labelDe: 'Vertragslaufzeit (ab) 24 Monate',
	},
	{
		name: 'thirtySixMonths',
		labelEn: 'Contract duration (from) 36 months',
		labelDe: 'Vertragslaufzeit (ab) 36 Monate',
	},
]

export const priceGarantiesOptions = [
	{
		name: 'notDefined',
		labelEn: 'Price guarantee undefined',
		labelDe: 'E-Preisgarantie egal',
	},
	{
		name: 'threeMonths',
		labelEn: 'Price guarantee 3 months',
		labelDe: 'E-Preisgarantie 3 Monate',
	},
	{
		name: 'sixMonths',
		labelEn: 'Price guarantee 6 months',
		labelDe: 'E-Preisgarantie 6 Monate',
	},
	{
		name: 'twelveMonths',
		labelEn: 'Price guarantee 12 months',
		labelDe: 'E-Preisgarantie 12 Monate',
	},
	{
		name: 'twentyFourMonths',
		labelEn: 'Price guarantee 24 months',
		labelDe: 'E-Preisgarantie 24 Monate',
	},
	{
		name: 'thirtySixMonths',
		labelEn: 'Price guarantee 36 months',
		labelDe: 'E-Preisgarantie 36 Monate',
	},
]

export const tariffs = [
	{
		name: 'all',
		labelEn: 'Standard (All tariffs)',
		labelDe: 'Standard (Alle Tarife)',
	},
	{
		name: 'festpreis',
		labelEn: 'Standard (Fixed price)',
		labelDe: 'Standard (Festpreis)',
	},
	{
		name: 'dynamic',
		labelEn: 'Standard (Dynamic/Spot)',
		labelDe: 'Standard (Dynamisch / Spot)',
	},
	{
		name: 'heatPump',
		labelEn: 'Heating storm heat pump',
		labelDe: 'Heizstorm Wärmenpumpe',
	},
	{
		name: 'nightStorage',
		labelEn: 'Heating storm night storage',
		labelDe: 'Heizstorm Nachtspeicher',
	},
	{
		name: 'storm',
		labelEn: 'Charging storm / Car storm',
		labelDe: 'Ladestorm / Autostorm',
	},
]

export const tariffAndEmailOptions = [
	{
		name: 'both',
		labelEn: 'Tariffs with and without email requirement',
		labelDe: 'Tarife mit- und ohne E-Mail Pflicht',
	},
	{
		name: 'emailReq',
		labelEn: 'Tariffs with email requirement',
		labelDe: 'Tarife mit- E-Mail Pflicht',
	},
	{
		name: 'emailNotReq',
		labelEn: 'Tariffs without email requirement',
		labelDe: 'Tarife ohne E-Mail Pflicht',
	},
]

export const customerTypesOptions = [
	{
		name: 'unselected',
		labelEn: 'Customer type undefined',
		labelDe: 'Kundentyp egal',
	},
	{
		name: 'newCustomer',
		labelEn: 'New customer tariffs',
		labelDe: 'Neukunden Tarife',
	},
	{
		name: 'existingCustomer',
		labelEn: 'Existing customer tariffs',
		labelDe: 'Bestandskunden Tarife',
	},
]

export const leadTimeOptions = [
	{ name: 'all', labelEn: 'Lead time (all)', labelDe: 'Vorlaufzeit (alle)' },
	{
		name: 'minSixMonths',
		labelEn: 'Lead time min. 6 months',
		labelDe: 'Vorlaufzeit min. 6 Monate',
	},
	{
		name: 'minNineMonths',
		labelEn: 'Lead time min. 9 months',
		labelDe: 'Vorlaufzeit min. 9 Monate',
	},
	{
		name: 'minTwelveMonths',
		labelEn: 'Lead time min. 12 months',
		labelDe: 'Vorlaufzeit min. 12 Monate',
	},
]

export const networkFeesOptions = [
	{
		name: 'all',
		labelEn: 'Network fees (all)',
		labelDe: 'Netzentgelte (alle)',
	},
	{
		name: 'thisYearIncluded',
		labelEn: 'Provisional network charges for 2024 included',
		labelDe: 'vorläufige Netzentgelte 2024 enthalten',
	},
]

const memberOptions = [
	{
		nr: 1,
		countValue: 1400,
	},
	{
		nr: 2,
		countValue: 2400,
	},
	{
		nr: 3,
		countValue: 3400,
	},
	{
		nr: 4,
		countValue: 4400,
	},
];
const houseOptions = [
	{
		nr: 50,
		countValue: 5000,
	},
	{
		nr: 100,
		countValue: 12000,
	},
	{
		nr: 150,
		countValue: 18000,
	},
	{
		nr: 180,
		countValue: 20000,
	}
]

const tariffOptions = [
	{
		value: i18n.t("standardTariff"),
		name: i18n.t("standardTariff"),
	},
	{
		value: i18n.t("heatingCurrentPump"),
		name: i18n.t("heatingCurrentPump"),
		type: "Strom",
	},
	{
		value: i18n.t("heatingNight"),
		name: i18n.t("heatingNight"),
		type: "Strom",
	},
	{
		value: i18n.t("chargingElectricity"),
		name: i18n.t("chargingElectricity"),
		type: "Strom",
	}
]
export const filterInputs = [
	{
		inputType: 'dropdown',
		options: energyTypes,
		field: 'calculatorType',
		parentName: 'Calculation',
		defaultValue: 'Strom',
		title: i18n.t("calculatorType"),
	},
	{
		inputType: 'dropdown',
		options: customerTypes,
		field: 'customerType',
		parentName: 'Calculation',
		defaultValue: 'Privat',
		title: i18n.t("customerType"),
	},
	{
		inputType: 'dropdown',
		options: countries,
		field: 'country',
		parentName: 'Calculation',
		defaultValue: 'Deustchland',
		title: i18n.t("country"),
	},
	{
		inputType: 'number',
		field: 'postalCode',
		parentName: 'Calculation',
		placeholder: i18n.t("Zipcode"),
		dependencyFields: ['city'],
		min: 2,
		required: true,
		generic: true,
		title: i18n.t("Zipcode")
	},
	{
		inputType: 'dropdown',
		field: 'city',
		parentName: 'Calculation',
		placeholder: 'Bitte PLZ eingeben',
		dependentFrom: 'postalCode',
		dependencyFields: ['street'],
		required: true,
		title: i18n.t("locations"),
		generic: true,
	},
	{
		inputType: "dropdown",
		field: "street",
		parentName: 'Calculation',
		placeholder: 'Bitte PLZ eingeben',
		dependentFrom: 'city',
		required: true,
		title: i18n.t("Street"),
		generic: true,
	},
	{
		inputType: 'number',
		field: 'houseNr',
		parentName: 'Calculation',
		placeholder: 'Nr.',
		dependentFrom: 'street',
		generic: true,
		required: true,
		title: i18n.t("houseNr"),
	},
	{
		inputType: 'select',
		field: 'familyMembers',
		options: memberOptions,
		parentName: 'Calculation',
		placeholder: 'Kein Netzbetreiber',
		defaultValue: 3,
		dependentFrom: "calculatorType",
		type: "Strom",
	},
	{
		inputType: "selectHouse",
		field: "house",
		options: houseOptions,
		defaultValue: 150,
		parentName: 'Calculation',
		dependentFrom: "calculatorType",
		type: "Gas",
	},
	{
		inputType: 'number',
		field: 'kwYear',
		parentName: 'Calculation',
		placeholder: 'kWh/Jahr',
		half: true,
		defaultValue: "3400",
		dependentFrom: "calculatorType",
		type: "Strom",
	},
	{
		inputType: 'number',
		field: 'kwYearGas',
		parentName: 'Calculation',
		placeholder: 'kWh/Jahr',
		defaultValue: "18000",
		dependentFrom: "calculatorType",
		type: "Gas",
	},
	{
		inputType: 'number',
		field: 'kwYearNT',
		parentName: 'Calculation',
		placeholder: 'NT (kWh/Jahr',
		half: true,
		dependentFrom: "calculatorType",
		type: "Strom",
	},
	{
		inputType: "dropdown",
		options: tariffOptions,
		field: "tariff",
		parentName: 'Filter',
		dependentFrom: "calculatorType",
	},
	{
		inputType: "date",
		field: "priceDate",
		parentName: 'Filter',
		placeholder: i18n.t("priceDate")
	},
	{
		inputType: 'checkbox',
		field: 'includeBonus',
		placeholder: i18n.t("includeBonus"),
		parentName: 'Filter',
	},
	{
		inputType: "checkbox",
		field: "tariffWithDigitalSignature",
		placeholder: i18n.t("tariffDigitalSignature"),
		parentName: "Filter",
	}
]

const STRING_INPUTS = ['colorPicker', 'text', 'number']
export const generateInitialValues = (inputs) => {
	const initialValues = {}
	for (let i = 0; i < inputs?.length; i++) {
		const { multiple, defaultValue, field, inputType, fields, dependentFrom } =
			inputs[i]
		if (defaultValue) {
			initialValues[field] = defaultValue
		} else if (inputType === 'rangePicker') {
			for (let f = 0; f < fields.length; f++) {
				initialValues[fields[f]] = null
			}
		} else if (inputType === 'checkbox') {
			initialValues[field] = false
		} else if (multiple) {
			initialValues[field] = []
		} else if (STRING_INPUTS.includes(inputType)) {
			initialValues[field] = ''
		} else {
			initialValues[field] = null
		}
	}
	return initialValues
}

export const generateYupSchema = (inputs) => {
	const schemaFields = {}
	if (inputs?.length > 0) {
		inputs.forEach((item) => {
			const { field, inputType, required, min, title } = item

			let fieldSchema

			switch (inputType) {
				case 'number':
				case 'input':
					fieldSchema = yup
						.string()
						.test(
							'not-only-space',
							i18n.t('fieldNonEmpty'),
							(value) =>
								value === null || value === undefined || value.trim() !== '',
						)
						.nullable()
					break
				case 'dropdown':
					fieldSchema = yup.mixed().nullable()
					break
				case 'date':
					fieldSchema = yup.date().nullable()
					break
				default:
					fieldSchema = yup.mixed().nullable()
			}
			if (required) {
				fieldSchema = fieldSchema.required(`${title} ${i18n.t('isRequired')}`)
			}
			if (min) {
				fieldSchema = fieldSchema?.min(
					min,
					`${title} ${i18n.t('atLeast')} ${min} ${i18n.t('chars')}`,
				)
			}
			schemaFields[field] = fieldSchema.label(title)
		})
	}
	return yup.object().shape(schemaFields)
}

export const filterOptions = (options, dependentFrom, values) => {
	if (dependentFrom && values?.[dependentFrom]) {
		return options?.filter((opt) => !opt.type || opt.type === values[dependentFrom]);
	}
	return options;
};

export const tabs = ["Calculation", "Filter"];