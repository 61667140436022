export const findItemAndUpdateState = (state, payload) => {
	const copiedState = [...state];
	const itemIdx = copiedState?.findIndex((item) => item.id === payload.id);
	copiedState[itemIdx] = payload;
	return copiedState;
};
export const filterItemAndUpdateState = (state, id) => {
	const copiedState = [...state];
	const filteredState = copiedState.filter((item) => item.id !== id);
	return filteredState;
};
