/* eslint-disable max-len */
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import NoApiKeyPage from "./NoApiKeyPage";

const Support = ({apps}) => {
	const supportApp = apps?.find((app) => app.name === "Support");
	const language = localStorage.getItem("language");
	const apiKey = supportApp?.apiKey;
	const tenantId = window.location.host;
	const token = localStorage.getItem("token");
	// const src = `${process.env.REACT_APP_SUPPORT_URL}/admin/tickets?apiKey=${apiKey}&lang=${language}&tenantId=${tenantId}&token=${token}`;
	const src = `https://support.oneri.de/admin/tickets?apiKey=${apiKey}&lang=${language}&tenantId=${tenantId}&token=${token}`;
	return (
		<>
			{apiKey ? (
				<iframe
					src={src}
					style={{
						width: "100%",
						height: "100vh",
						border: "none",
					}}
					title="Ticketing system"
				/>
			) : (
				<NoApiKeyPage />
			)}
		</>
	);
};
const mapStateToProps = (state) => ({
	apps: state.app.marketPlace.index.marketPlaceApps,
});
export default connect(mapStateToProps, null)(withRouter(Support));
