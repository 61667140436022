import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/products/clientProduct";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";
const logger = new Logger("Sagas>Products>Index");
const PREFIX = "@Dixi App/products/createClientProduct";
export const ADD_PRODUCT = `${PREFIX}ADD_PRODUCT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_PRODUCT_SUCCESS = `${PREFIX}ADD_PRODUCT_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_PRODUCTS = `${PREFIX}FILL_PRODUCTS`;

const _state = {
	loading: false,
	productAdded: false,
	productsInitialValues: {
		name: "",
		// commission: "",
		// price: "",
		employeeProvision: "",
		employeeShopProvision: "",
		spProvision: "",
		agencyProvision: "",
		agencySPProvision: "",
		teamLeaderProvision: "",
		employeeTlProvision: "",
		employeeAbProvision: "",
		agency70Provision: "",
		price: 0,
		receivedCommision: 0,
		cancellationReserve: {
			name: "0%",
			value: "0",
		},
		cancellationReserveType: {
			name: "PERCENTAGE",
			value: "PERCENTAGE",
		},
		tariffIds: [],
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_PRODUCT_SUCCESS:
				draft.productAdded = action.payload;
				break;
			case FILL_PRODUCTS:
				const {cancellationReserve, cancellationReserveType} = action.payload;
				draft.productsInitialValues = {
					...action.payload,
					price: action.payload.price || 0,
					receivedCommision: action.payload.receivedCommision || 0,
					cancellationReserve: {
						name: cancellationReserve
							? String(
									`${
										cancellationReserve?.name?.replace("%", "") ||
										cancellationReserve
									}%`,
							  )
							: "0%",
						value: cancellationReserve
							? String(`${cancellationReserve?.value || cancellationReserve}`)
							: "0",
					},
					cancellationReserveType: {
						name: cancellationReserveType?.name || cancellationReserveType,
						value: cancellationReserveType?.value || cancellationReserveType,
					},
				};
				break;
			case SHOW_MODAL:
				draft.showModal = !state.showModal;
				if (!action.payload) {
					draft.productsInitialValues = {
						name: "",
						// commission: "",
						// price: "",
						employeeProvision: "",
						employeeShopProvision: "",
						spProvision: "",
						agencyProvision: "",
						agencySPProvision: "",
						teamLeaderProvision: "",
						agency70Provision: "",
						price: 0,
						receivedCommision: 0,
						cancellationReserve: {
							name: "0%",
							value: "0",
						},
						cancellationReserveType: {
							name: "PERCENTAGE",
							value: "PERCENTAGE",
						},
						tariffIds: [],
					};
				}
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	addProduct: (payload) => createAction(ADD_PRODUCT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addProductSucces: (payload) => createAction(ADD_PRODUCT_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillProducts: (payload) => createAction(FILL_PRODUCTS, {payload}),
};

export const sagas = {
	*addProduct({payload}) {
		yield put(actions.setLoading(true));
		const {values, setModal} = payload;

		try {
			let response;
			const newObj = {
				...values,
				cancellationReserve: values?.cancellationReserve?.value,
				cancellationReserveType: values?.cancellationReserveType?.value,
				tariffIds: values?.tariffIds?.map((item) => item?.value || item?.id),
			};
			if (values?.id) {
				response = yield axios.put(`/product/update/`, newObj);
				yield put(userActions.editProductOnState(values));
				ToastSuccesComponent(i18n.t("ProductUpdateSuccess"));
			} else {
				response = yield axios.post(`/product/create/`, newObj);
				const userObj = {
					...values,
					id: response?.data?.data?.id,
					nr: response?.data?.data?.nr,
					isHidden: false,
				};
				yield put(userActions.addProductOnState(userObj));
				ToastSuccesComponent(i18n.t("ProductCreateSuccess"));
				yield put(userActions.totalSizeIncrease());
			}
			setModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_PRODUCT, sagas.addProduct);
};
