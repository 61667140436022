import {ErrorResponseIcon, SuccessResponseIcon} from "@src/assets/SvgComponents";
import useEmailValidation from "@src/utils/useEmailValidation";
import {useFeatures} from "@src/utils/useFeatures";
import React from "react";
import Loader from "../Loader";

const EmailInput = ({
	handleInput,
	element,
	value,
	label,
	error,
	touched,
	setEmailErrors,
	editMode,
	disabled,
	validateEmail,
	state,
	setState,
}) => {
	const {name} = element;
	const {checkFeature} = useFeatures();
	const canValidate = checkFeature("CHECK_EMAIL") && state === null && editMode;
	const handleBlur = () => {
		if (canValidate && value) {
			validateEmail({value, setEmailErrors, name, handleInput});
		}
	};
	const onChangeHandler = (e) => {
		setState(null);
		handleInput(e);
		setEmailErrors((prev) => ({
			...prev,
			[name]: false,
		}));
	};
	return (
		<div className="email-input__wrapper">
			<label>{label}</label>
			<div className="email-input__wrapper-input">
				<div className="inputWrapper">
					<input
						onChange={onChangeHandler}
						value={value}
						name={name}
						onBlur={handleBlur}
						disabled={disabled}
					/>
					{state && (
						<div className="inputWrapper-icons">
							{state === "started" && <Loader />}
							{state === "success" && <SuccessResponseIcon />}
							{state === "error" && <ErrorResponseIcon />}
						</div>
					)}
				</div>
			</div>
			{touched && error && <span className="errorClass">{error}</span>}
		</div>
	);
};

export default EmailInput;
