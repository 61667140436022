import moment from "moment";

export const locale = {
	lang: {
		locale: "en-gb",
		rangePlaceholder: ["Start week", "End week"],
		today: "Today",
		now: "Now",
		backToToday: "Back to today",
		ok: "Ok",
		clear: "Clear",
		month: "Month",
		year: "Year",
		timeSelect: "Select time",
		dateSelect: "Select date",
		monthSelect: "Choose a month",
		yearSelect: "Choose a year",
		decadeSelect: "Choose a decade",
		yearFormat: "YYYY",
		dateFormat: "D/M/YYYY",
		dayFormat: "D",
		dateTimeFormat: "D/M/YYYY HH:mm:ss",
		weekFormat: "YYYY-WW",
		monthBeforeYear: true,
		firstDayOfWeek: 1, // set Monday as the first day of the week
	},
};
export const generateConfig = {
	weekDayFormat: "dd", // show day names as abbreviated text (e.g. "Mon")
	getWeekStartDate: (date) => {
		// calculate the start of the week as Monday
		const weekStart = moment(date).startOf("week");
		return weekStart.isoWeekday(1);
	},
};

export const getDateRange = (inputString) => {
	const formats = {
		"YYYY-MM": "month",
		YYYY: "year",
		"YYYY-[Q]Q": "quarter",
	};

	const format = Object.keys(formats).find((f) => moment(inputString, f, true).isValid());

	if (!format) {
		throw new Error("Invalid date format");
	}

	const dateObj = moment(inputString, format);
	const start = dateObj.clone().startOf(formats[format]);
	const end = dateObj.clone().endOf(formats[format]);

	return {start, end};
};

export const getWeekRange = (weekYearString) => {
	const weekYear = moment(weekYearString, "YYYY-[W]WW");
	const startOfWeek = weekYear.clone().startOf("isoWeek");
	const endOfWeek = weekYear.clone().endOf("isoWeek");
	return {start: startOfWeek, end: endOfWeek};
};
