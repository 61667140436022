import {NoDataIcon} from "@src/assets/SvgComponents";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {actions as usersActions} from "@sagas/users/users/index";
import {actions as teamActions} from "@sagas/teams";
import {checkRoles} from "@src/utils/checkRoles";
import Comments from "../CommentsComponent";
import Relations from "../Relations";
import Userstab from "../Userstab";

function Activities({lead, loggedUser}) {
	const {t} = useTranslation();
	const [active, setActive] = useState("comments");
	const currentLanguage = localStorage.getItem("language");
	const renderTabs = (lead) => {
		switch (active) {
			case "comments":
				if (lead?.leadActivity?.[0]) {
					return lead?.leadActivity?.map((itm, index) => (
						<Comments
							key={index}
							user={itm.fullName}
							firstName={itm?.fullName?.split(" ")[0]}
							lastName={itm?.fullName?.split(" ")[1]}
							status={
								// eslint-disable-next-line no-nested-ternary
								currentLanguage === "en"
									? itm?.commentEn !== null
										? itm?.commentEn
										: itm?.comment
									: itm.comment
							}
							statusColor="#6C1EB0"
							createdAt={itm.createdAt}
						/>
					));
				}
				return (
					<div className="empty">
						<div>
							<div>
								<NoDataIcon width="64" height="79" fill="#979797" />
							</div>
							<span className="empty-text">{t("NoData")}!</span>
						</div>
					</div>
				);

			case "users":
				return <Userstab />;
			case "relations":
				return <Relations />;
			default:
				return "-";
		}
	};
	return (
		<div className="modal__activity">
			<div className="modal__activity__header">
				<p>{t("activity")}</p>
			</div>
			<div className="comments_container">
				<div className="comments_container-tabswrapper">
					<div className="tabs">
						<span
							onClick={() => setActive("comments")}
							className={`comments_container-tabswrapper_item ${
								active === "comments" ? " active" : ""
							}`}
						>
							{t("Comments")}
						</span>
						{checkRoles("deleteLeads") && (
							<span
								onClick={() => setActive("users")}
								className={`comments_container-tabswrapper_item ${
									active === "users" ? " active" : ""
								}`}
							>
								{t("Users")}
							</span>
						)}
						<span
							onClick={() => setActive("relations")}
							className={`comments_container-tabswrapper_item ${
								active === "relations" ? " active" : ""
							}`}
						>
							{t("Relations")}
						</span>
					</div>
				</div>
				{renderTabs(lead)}
			</div>
		</div>
	);
}
const mapStateToProps = (state) => ({
	usersOptions: state.app.users.index.usersOptions,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	getUserDropdown: usersActions.getUserDropdown,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
};
export default connect(mapStateToProps, mapDispatchToProps)(Activities);
