import React from "react";

const LabelButton = ({children, className, htmlFor}) => (
	<label
		htmlFor={htmlFor || "order__upload__file__inputupload-photo"}
		className={className || "order__upload__file__name"}
	>
		{children}
	</label>
);

export default LabelButton;
