import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as createTariffByLocationsActions} from "@sagas/tariff/tariffByLocations/create";

const logger = new Logger("Sagas>tariffsByLocation>Index");

const PREFIX = "@app/tariffsByLocation/index";

export const GET_SINGLE_TARIFF_LOCATION = `${PREFIX}GET_SINGLE_TARIFF_LOCATION`;
export const GET_SINGLE_TARIFF_LOCATION_SUCCESS = `${PREFIX}GET_SINGLE_TARIFF_LOCATION_SUCCESS`;
export const GET_DELETE_TABLE_BY_TARIFF = `${PREFIX}GET_DELETE_TABLE_BY_TARIFF`;
export const GET_DELETE_TABLE_BY_TARIFF_SUCCESS = `${PREFIX}GET_DELETE_TABLE_BY_TARIFF_SUCCESS`;
export const CLEAN_SINGLE_TARIFF = `${PREFIX}CLEAN_SINGLE_TARIFF`;
export const CLEAN_DELETED_TABLES = `${PREFIX}CLEAN_DELETET_TABLES`;
export const ADD_DELETED_TABLE_ON_STATE = `${PREFIX}ADD_DELETED_TABLE_ON_STATE`;

const _state = {
	loading: false,
	singleTariff: {},
	deletedDataTable: {},
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case GET_SINGLE_TARIFF_LOCATION_SUCCESS:
				draft.singleTariff = action.payload;
				break;
			case GET_DELETE_TABLE_BY_TARIFF_SUCCESS:
				draft.deletedDataTable = action.payload || {};
				break;
			case CLEAN_SINGLE_TARIFF:
				draft.singleTariff = {};
				break;
			case CLEAN_DELETED_TABLES:
				draft.deletedDataTable = {};
				break;
			case ADD_DELETED_TABLE_ON_STATE:
				const insertObject = {
					...action.payload,
					data: action?.payload?.data || [],
				};
				draft.deletedDataTable = {
					...state.deletedDataTable,
					tables: [...(state.deletedDataTable.tables || []), insertObject],
				};
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getSingleTariffByLocation: (payload) => createAction(GET_SINGLE_TARIFF_LOCATION, {payload}),
	getSingleTariffByLocationnSuccess: (payload) =>
		createAction(GET_SINGLE_TARIFF_LOCATION_SUCCESS, {payload}),
	cleanSingleTariffByLocations: (payload) => createAction(CLEAN_SINGLE_TARIFF, {payload}),
	getDeletedTableByTariffSuccess: (payload) =>
		createAction(GET_DELETE_TABLE_BY_TARIFF_SUCCESS, {payload}),
	getDeletedTableByTariff: (payload) => createAction(GET_DELETE_TABLE_BY_TARIFF, {payload}),
	cleanDeletedTableByTariff: (payload) => createAction(CLEAN_DELETED_TABLES, {payload}),
	addDeletedtableOnState: (payload) => createAction(ADD_DELETED_TABLE_ON_STATE, {payload}),
};

export const sagas = {
	*getSingleTariffByLocation(id) {
		try {
			const response = yield axios.get(
				`/tariff_commission/locations/${id.payload}?page=1&size=1000000&search=`,
			);
			// yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.getSingleTariffByLocationnSuccess(response?.data?.data));
			yield put(
				createTariffByLocationsActions.fetchTariffTable(response?.data?.data?.tables),
			);
			yield put(
				createTariffByLocationsActions.fillInitialValues({
					name: response?.data?.data.name,
					commission: response?.data?.data.commission,
					// cancellationReserve: response?.data?.data.cancellationReserve.toString(),
					cancellationReserve:
						response?.data?.data.cancellationReserve === null
							? null
							: String(`${response?.data?.data.cancellationReserve}%`),

					cancellationReserveType: response?.data?.data.cancellationReserveType,
					clientType: response?.data?.data.clientType,
					energyType: response?.data?.data.energyType,
				}),
			);
		} catch (error) {
			logger.error(error);
		} finally {
			logger.info("Success");
		}
	},
	*getDeletedTableByTariff({payload}) {
		try {
			const response = yield axios.get(`/tariff_commission/inactive/${payload}`);
			yield put(actions.getDeletedTableByTariffSuccess(response?.data?.data));
			yield put(
				createTariffByLocationsActions.fillInitialValues({
					name: response?.data?.data.name,
					commission: response?.data?.data.commission,
					cancellationReserve: String(`${response?.data?.data.cancellationReserve}%`),
					cancellationReserveType: response?.data?.data.cancellationReserveType,
				}),
			);
		} catch (error) {
			logger.error(error);
		} finally {
			logger.info("Success");
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_SINGLE_TARIFF_LOCATION, sagas.getSingleTariffByLocation);
	yield takeLatest(GET_DELETE_TABLE_BY_TARIFF, sagas.getDeletedTableByTariff);
};
