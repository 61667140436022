import {HumanLogo} from "@src/assets/SvgComponents";
import React from "react";

const SelectMembers = ({item, onClick, value}) => (
	<div className="options-container">
		{Array.from({length: item.options.length}).map((_, index) => (
			<div
				className={`single__option ${value === item.options[index]?.nr ? "active" : ""}`}
				key={index}
				onClick={() => onClick(item.field, item.options[index])}
			>
				{[...Array(item.options[index]?.nr)].map((_, idx) => (
					<HumanLogo
						key={idx}
						fill={value === item.options[index]?.nr ? "#fff" : "#979797"}
					/>
				))}
			</div>
		))}
	</div>
);

export default SelectMembers;
