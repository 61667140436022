import React from "react";
import {actions as statusesActions} from "@sagas/salesTracker/statuses";
import {connect} from "react-redux";
import Form from "@src/common/Form";
import i18n from "@src/i18n";
import {inputs} from "./popoverItems";
const CreateStatus = (props) => {
	const {showModal, setShowModal, createStatus, loading, id} = props;
	const handleClose = () => setShowModal(false);
	const formData = {
		inputs,
		id,
		title: id ? i18n.t("EditStatus") : i18n.t("CreateStatus"),
		submitFn: (values) =>
			createStatus({values: {...values, statusId: values?.id}, handleClose}),
		getUrl: "sales_tracker/statuses/:id",
	};
	return (
		<Form open={showModal} loading={loading} formData={formData} handleClose={handleClose} />
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.salesTracker.statuses.statusLoader,
});
const mapDispatchToProps = {
	createStatus: statusesActions.createStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStatus);
