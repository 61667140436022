import {put, takeLatest} from "redux-saga/effects";
import produce from "immer";
import axios from "axios";
import Logger from "@utils/logger";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import {actions as leadActions} from "@sagas/salesTracker/index";
import i18n from "@src/i18n";
import createAction from "../../../../utils/action-creator";
import {findIndexFunc} from "../messages/utils";

const logger = new Logger("Sagas>salesTracker>LeadStatus");
const language = localStorage.getItem("language");

const label = {
	en: "name",
	de: "nameDe",
};

const PREFIX = "@app/salesTracker/leadStatus";
export const CREATE_LEAD_STATUS = `${PREFIX}CREATE_LEAD_STATUS`;
export const CREATE_LEAD_STATUS_SUCCESS = `${PREFIX}CREATE_LEAD_STATUS_SUCCESS`;
export const EDIT_LEAD_STATUS = `${PREFIX}EDIT_LEAD_STATUS`;
export const EDIT_LEAD_STATUS_SUCCESS = `${PREFIX}EDIT_LEAD_STATUS_SUCCESS`;
export const GET_ALL_LEAD_STATUSES = `${PREFIX}GET_ALL_LEAD_STATUSES`;
export const GET_ALL_LEAD_STATUSES_SUCCESS = `${PREFIX}GET_ALL_LEAD_STATUSES_SUCCESS`;
export const GET_LEAD_STATUS_BY_ID = `${PREFIX}GET_LEAD_STATUS_BY_ID`;
export const GET_LEAD_STATUS_BY_ID_SUCCESS = `${PREFIX}GET_LEAD_STATUS_BY_ID_SUCCESS`;
export const DELETE_LEAD_STATUS = `${PREFIX}DELETE_LEAD_STATUS`;
export const DELETE_LEAD_STATUS_ON_STATE = `${PREFIX}DELETE_LEAD_STATUS_ON_STATE`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_LEAD_STATUS_MODAL = `${PREFIX}SET_LEAD_STATUS_MODAL`;
export const UPDATE_LEAD_STATUS_ON_STATE = `${PREFIX}UPDATE_LEAD_STATUS_ON_STATE`;
export const CLEAR_LEAD_STATUS = `${PREFIX}CLEAR_LEAD_STATUS`;
export const MOVE_LEAD_STATUES_IN_PENDING = `${PREFIX}MOVE_LEAD_STATUSES_IN_PENDING`;

const _state = {
	leadStatus: {
		name: "",
		nameDe: "",
		statusId: null,
		leadStatusId: null,
		leadStatusType: null,
	},
	leadStatuses: [],
	loading: false,
	leadStatusModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case CREATE_LEAD_STATUS_SUCCESS:
				draft.leadStatuses = [
					...state?.leadStatuses,
					{
						...action.payload,
						value: action?.payload?.id,
						label: action?.payload?.[label[language]],
					},
				];
				break;
			case GET_ALL_LEAD_STATUSES_SUCCESS:
				draft.leadStatuses = action.payload?.map((status) => ({
					...status,
					value: status.id,
					label: status?.[label[language]],
				}));
				break;
			case GET_LEAD_STATUS_BY_ID_SUCCESS:
				draft.leadStatus = action.payload;
				break;
			case DELETE_LEAD_STATUS_ON_STATE:
				draft.leadStatuses = state.leadStatuses.filter(
					(status) => status.id !== action.payload,
				);
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SET_LEAD_STATUS_MODAL:
				draft.leadStatusModal = action.payload;
				break;
			case UPDATE_LEAD_STATUS_ON_STATE:
				const statusToUpdateIndex = findIndexFunc(
					state?.leadStatuses,
					action.payload?.id,
					"id",
				);
				const allStatuses = [...state.leadStatuses];
				allStatuses[statusToUpdateIndex] = {
					...action.payload,
					label: language === "en" ? action.payload.name : action.payload.nameDe,
					value: action.payload.id,
				};
				draft.leadStatuses = allStatuses;
				break;
			case CLEAR_LEAD_STATUS:
				draft.leadStatus = {
					name: "",
					nameDe: "",
					statusId: null,
					leadStatusId: null,
					leadStatusType: null,
				};
				break;
			case MOVE_LEAD_STATUES_IN_PENDING:
				const copiedStatuses = [...state?.leadStatuses];
				const updatedStatuses = copiedStatuses?.map((status) => {
					if (status.statusId === action.payload) {
						return {
							...status,
							statusId: 1,
						};
					}
					return status;
				});
				draft.leadStatuses = updatedStatuses;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	createLeadStatus: (payload) => createAction(CREATE_LEAD_STATUS, {payload}),
	createLeadStatusSuccess: (payload) => createAction(CREATE_LEAD_STATUS_SUCCESS, {payload}),
	fetchAllLeadStatuses: (payload) => createAction(GET_ALL_LEAD_STATUSES, {payload}),
	getAllStatusesSuccess: (payload) => createAction(GET_ALL_LEAD_STATUSES_SUCCESS, {payload}),
	getLeadStatusById: (payload) => createAction(GET_LEAD_STATUS_BY_ID, {payload}),
	getLeadStatusByIdSuccess: (payload) => createAction(GET_LEAD_STATUS_BY_ID_SUCCESS, {payload}),
	deleteLeadStatus: (payload) => createAction(DELETE_LEAD_STATUS, {payload}),
	deleteLeadStatusOnState: (payload) => createAction(DELETE_LEAD_STATUS_ON_STATE, {payload}),
	editLeadStatus: (payload) => createAction(EDIT_LEAD_STATUS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setLeadStatusModal: (payload) => createAction(SET_LEAD_STATUS_MODAL, {payload}),
	updateLeadStatusOnState: (payload) => createAction(UPDATE_LEAD_STATUS_ON_STATE, {payload}),
	clearLeadStatus: (payload) => createAction(CLEAR_LEAD_STATUS, {payload}),
	moveLeadStatusesInPending: (payload) => createAction(MOVE_LEAD_STATUES_IN_PENDING, {payload}),
};

export const sagas = {
	*fetchAllLeadStatuses() {
		try {
			const response = yield axios.get("sales_tracker/lead_status");
			yield put(actions.getAllStatusesSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getLeadStatusById({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/sales_tracker/lead_status/${payload}`);
			yield put(actions.getLeadStatusByIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*createLeadStatus({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post(`/sales_tracker/lead_status`, payload?.values);
			if (payload?.values?.id) {
				yield put(actions.updateLeadStatusOnState(response?.data?.data));
				ToastSuccesComponent(i18n.t("LeadStatusUpdated"));
			} else {
				yield put(actions.createLeadStatusSuccess(response?.data?.data));
				ToastSuccesComponent(i18n.t("LeadStatusCreated"));
			}
			yield put(actions.setLeadStatusModal(false));
			yield put(leadActions.getLeads());
			yield put(leadActions.clearSelectedStatuses());
			yield put(actions.clearLeadStatus());
			payload.handleClose();
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.code);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteLeadStatus({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.delete(`/sales_tracker/lead_status/${payload}`);
			yield put(actions.deleteLeadStatusOnState(payload));
			yield put(
				leadActions.changeLeadStatus({
					id: payload,
					statusId: 1,
					field: "leadStatusId",
				}),
			);
			ToastSuccesComponent(i18n.t("LeadStatusDeleted"));
			yield put(leadActions.clearSelectedStatuses());
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_LEAD_STATUS, sagas.createLeadStatus);
	yield takeLatest(GET_ALL_LEAD_STATUSES, sagas.fetchAllLeadStatuses);
	yield takeLatest(GET_LEAD_STATUS_BY_ID, sagas.getLeadStatusById);
	yield takeLatest(DELETE_LEAD_STATUS, sagas.deleteLeadStatus);
};
