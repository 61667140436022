import {ShowInfoIcon} from "@src/assets/SvgComponents";
import ButtonComponent from "@src/common/button";
import SimpleDropDownInput from "@src/common/dropDown/simpleDropDown";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {actions as clientActions} from "@sagas/client";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import CustomAccordion from "@src/common/CustomAccordion";
import CustomSelect from "@src/common/Select";

const DISALLOWED_STATUSES = ["SETTLED", "CONTRACT_REACTIVATED", "BONUS_SETTLED", "BONUS"];

const DefaultOrderStatus = ({updateSettings, loading, client, statuses}) => {
	const [status, setStatus] = useState(null);
	const {t} = useTranslation();
	const filteredOptions = statuses?.filter(({status}) => !DISALLOWED_STATUSES.includes(status));
	const handleSave = () => {
		updateSettings({
			...client,
			defaultOrderStatus: status?.value || client?.defaultOrderStatus?.id,
		});
	};
	useEffect(() => {
		if (client?.defaultOrderStatus) {
			setStatus({
				...client.defaultOrderStatus,
				value: client.defaultOrderStatus.id,
				label: client?.defaultOrderStatus.statusName,
			});
		}
	}, []);
	return (
		<CustomAccordion title={t("orderStatusSelection")} className="contracts__accordion">
			<div className="generated__pdf__container">
				<div className="generated__pdf__container-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("orderStatusSelectionInfo")}</span>
				</div>

				<div className="generated__pdf__container-content">
					<div className="dropdown--container">
						<p className="dropdown--container-label">{t("ChooseStatus")}</p>
						<CustomSelect
							options={filteredOptions}
							onSelect={(_, value) => {
								setStatus(value);
							}}
							value={status}
							onClear={() => {
								setStatus(null);
							}}
						/>
					</div>
				</div>
				<div className="generated__pdf__container-actions">
					<ButtonComponent
						buttonText={t("Save")}
						disabled={loading || !status}
						loading={loading}
						onClick={handleSave}
					/>
				</div>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isLoading,
	client: state.app.client.index.client,
	statuses: state.app.orders.statuses.statuses,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultOrderStatus));
