/* eslint-disable max-len */
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {actions as clientActions} from "@sagas/client";
import {connect} from "react-redux";
import {useUrlParams} from "@src/utils/useUrlParams";
import Form from "@src/common/Form";
import {actions as productActions} from "@sagas/products/index";
import {actions as tariffActions} from "@sagas/tariff/index";
import {orderFieldInputs} from "./staticData";
import {getLabel} from "../multiplecontainers/helper";

const EditCustomFeild = ({
	products,
	loading,
	updateCustomField,
	match,
	groupedOrderFields,
	tariffs,
	fetchProductsByDropdown,
	fetchTariffsByDropdown,
}) => {
	const currentLanguage = localStorage.getItem("language");
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id, dropDownParentId} = getUrlParams("modal", "id", "dropDownParentId");
	const [dependencyFields, setDependencyFields] = useState([]);
	const [assignInputTo, setAssignInputTo] = useState(null);
	const {clientId} = match.params;
	const {t} = useTranslation();
	const isOpen = modal === "orderField";
	const productOptions = products.map((prod) => ({
		...prod,
		label: prod.name,
	}));
	const handleMount = (data) => {
		setDependencyFields(data.dependencyFields);
		setAssignInputTo(data.assignInputTo);
	};
	useEffect(() => {
		if (isOpen && clientId) {
			fetchProductsByDropdown(clientId);
			fetchTariffsByDropdown(clientId);
		}
	}, [clientId, isOpen]);

	const filteredFields = useMemo(
		() =>
			groupedOrderFields
				.filter(
					(field) => field?.id !== id && !field?.assignInputTo && !field?.assignInputFrom,
				)
				.map((it) => ({
					value: it.name,
					label: getLabel(it),
				})),
		[groupedOrderFields, id, currentLanguage],
	);
	const inputs = orderFieldInputs?.map((it) => {
		if (it.field === "productId" || it.field === "calculationFields") {
			return {
				...it,
				options: productOptions,
			};
		}
		if (it.field === "dependencyFields") {
			return {
				...it,
				options: filteredFields?.filter((item) => item.value !== assignInputTo),
				onChange: (value, clear = false) => {
					setDependencyFields((prev) => {
						if (clear) {
							return value;
						}
						return [...(prev || []), value];
					});
				},
			};
		}
		if (it.field === "tariffId") {
			return {
				...it,
				options: tariffs,
			};
		}
		if (it.field === "assignInputTo") {
			return {
				...it,
				options: filteredFields?.filter((item) => !dependencyFields?.includes(item.value)),
				onChange: (value) => setAssignInputTo(value),
			};
		}
		if (it.field === "orderSortNr" || it.field === "exportSortNr") {
			return {
				...it,
				hidden: !!dropDownParentId,
			};
		}
		return it;
	});
	const formData = {
		inputs,
		title: t("editOrderField"),
		id,
		getUrl: `order_field/:id`,
		submitFn: (values, {setFieldError}) => {
			const obj = values?.customOptionsString
				? values?.customOptionsString.split(",").map((item) => ({
						name: item.trim(),
						labelEn: item.trim(),
						labelDe: item.trim(),
				  }))
				: null;
			if (values.minDate === 0 && values.maxDate === 0) {
				setFieldError("maxDate", t("maxAgeError"));
				return;
			}
			updateCustomField({
				values: {
					...values,
					clientId: +clientId,
					calculationFields: values?.showAs === "TOTAL" ? values?.calculationFields : [],
					customOptionsString: values?.customOptionsString,
					customOptionsArrayString: obj !== null ? JSON.stringify(obj) : null,
					assignInputTo: values?.assignInputTo ? [values?.assignInputTo] : null,
				},
				setModal,
			});
		},
	};
	return (
		<Form
			open={isOpen}
			formData={formData}
			handleClose={() => setModal(false)}
			loading={loading}
			onMount={handleMount}
		/>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isLoading,
	products: state.app.products.index.optionsProducts,
	groupedOrderFields: state.app.client.index.groupedOrderFields,
	tariffs: state.app.tariffs.index.optionsTariffs,
});
const mapDispatchToProps = {
	updateCustomField: clientActions.updateCustomField,
	fetchProductsByDropdown: productActions.fetchProductsByDropdown,
	fetchTariffsByDropdown: tariffActions.fetchTariffsByDropdown,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditCustomFeild));
