import React from "react";
import {CloseUploadModalIcon} from "@assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import {userRoleData} from "@src/utils/userRoleData";

const ChannelUsers = ({
	users,
	loggedUser,
	usersFromState,
	setUsersFromState,
	isOwner,
	removeAdmin = false,
	title,
	parentRemoveHandler = null,
	isAnalytics = false,
}) => {
	const handleRemove = (item) => {
		setUsersFromState(usersFromState.filter((itm) => itm.id !== item?.id));
	};
	return (
		<div className="find__user__container">
			{!!users?.length &&
				users?.map((item, key) => (
					<div className="user__name" key={key}>
						<div className="user__name-wrapper">
							<div className="user__name-wrapper_title">
								{(item?.firstName || item[title])?.toUpperCase().charAt(0)}
							</div>
							<h1 className={isAnalytics ? "isAnalytics__text" : ""}>
								{item?.firstName || item[title]} {item?.lastName}{" "}
							</h1>
						</div>

						{((item?.id !== loggedUser && isOwner) || removeAdmin) && (
							<div className="status-wrapper">
								{item?.role?.name && (
									<span className="role">
										<StatusBox
											row={item?.role?.name}
											statusData={userRoleData}
											height="30px"
										/>
									</span>
								)}
								<p
									onClick={() =>
										parentRemoveHandler
											? parentRemoveHandler(item)
											: handleRemove(item)
									}
								>
									<CloseUploadModalIcon
										fill="#979797"
										width="10px"
										height="10px"
									/>
								</p>
							</div>
						)}
					</div>
				))}
		</div>
	);
};

export default ChannelUsers;
