import React from "react";
import ConditionAccordion from "@src/components/dashboard/Users/TeamConditions/Accordion/Accordion";
import DataGridTable from "@src/common/table/DataGridTable";
import {useTranslation} from "react-i18next";
import {columns} from "./helper";
import "./styles.scss";

const RenderTables = ({monthlyDataUser, year}) => {
	const {t} = useTranslation();
	return (
		<div className="renderTables__container">
			<ConditionAccordion
				defaultExpanded
				title={
					<div className="accordion-title">
						<p>{t("salesHistory")}</p>
						<p>{year}</p>
					</div>
				}
			>
				<DataGridTable
					columns={columns}
					data={monthlyDataUser}
					style={{
						height: monthlyDataUser?.length ? (monthlyDataUser.length + 1) * 44 : 300,
					}}
				/>
			</ConditionAccordion>
		</div>
	);
};

export default RenderTables;
