/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from "react";
import Forms, {
	convertCustomFieldsToForm1,
} from "@components/dashboard/orders/createEditOrder/forms";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as clientActions} from "@sagas/client";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import CloseIcon from "@common/closeIcon/CloseIcon";
import {useTranslation} from "react-i18next";
import Loader from "@common/Loader";
import {MoveRightIcon, TimelineIcon} from "@src/assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import {checkRoles} from "@src/utils/checkRoles";
import {useUrlParams} from "@src/utils/useUrlParams";
import useGenericLocations from "@src/utils/useGenericLocations";
import _ from "lodash";
import {actions as createOrderActions} from "@sagas/orders/create";
import Timeline from "./Timeline";
import Preview from "./Preview";
import {clientWithoutGenericArr, requestTypeConfig} from "./helper";

function CreateEditOrder({
	setShowEditModal,
	fillOrder,
	fetchGenericOrderByID,
	editedOrderFields,
	setShowTimeline,
	getActivities,
	getActivitiesById,
	setOrdersWithChanges,
	changeOrdersStatus,
	changeStatusLoading,
	editOrderState,
}) {
	const {order, showTimeline, activities, activity, activitiesLoading} = editOrderState;
	if (!order)
		return (
			<div className="full__width__loader">
				<Loader />
			</div>
		);
	const getObject = () => {
		if (
			(activity !== null && currentVersion !== selectedVersion?.id) ||
			selectedVersion?.isChangeStatus
		) {
			return activity;
		}
		return order;
	};
	const {getUrlParams} = useUrlParams();
	const {options, retrieveDataFromGetAg} = useGenericLocations();
	const {tab} = getUrlParams("tab");
	const [checked, setChecked] = useState(false);
	const [currentVersion, setCurrentVersion] = useState(null);
	const [selectedVersion, setSelectedVersion] = useState({id: null, isChangeStatus: false});
	const isGenericClient = !clientWithoutGenericArr.includes(getObject()?.clientName);
	const {t} = useTranslation();
	useEffect(() => {
		if (!showTimeline) {
			setSelectedVersion({id: null, isChangeStatus: false});
		}
	}, [showTimeline]);

	const handleShowChanges = (value) => {
		setChecked((prev) => !prev);
		setOrdersWithChanges(value);
	};
	useEffect(() => () => fillOrder(null), []);
	useEffect(() => {
		if (isGenericClient) {
			if (tab === "energyOrders") {
				fetchGenericOrderByID({clientId: 0, clientContractType: "ENERGY"});
			} else {
				fetchGenericOrderByID(getObject()?.clientId.value);
			}
		}
	}, []);

	const handleGetAgRequests = () => {
		for (let i = 0; i < fieldMapping.length; i++) {
			const currentField = fieldMapping[i];
			const value = _.get(order, currentField.name);
			const {requestType, dependentFrom} = currentField.item;
			if (value && currentField.item.requestType && requestTypeConfig?.[requestType]) {
				let dependentValue;
				if (dependentFrom) {
					const foundedDep = fieldMapping.find((itm) => itm.item.name === dependentFrom);
					dependentValue = _.get(order, foundedDep?.name);
				}
				retrieveDataFromGetAg(
					{...currentField.item, type: requestTypeConfig?.[requestType]},
					value,
					dependentValue,
				);
			}
		}
	};

	// useEffect(() => {
	// 	if (order) handleGetAgRequests();
	// }, [order, editedOrderFields]);

	const filteredFields = editedOrderFields.filter(
		(item) => !item.hideWeb && item.parentNameEn !== "Section Hide",
	);

	const fieldMapping = isGenericClient
		? convertCustomFieldsToForm1(filteredFields)
		: Forms?.[getObject()?.clientName];
	const handleGetById = (item, index, firstMonth, act) => {
		setSelectedVersion({id: item?.id, isChangeStatus: item?.action === "CHANGE_STATUS"});
		getActivitiesById(item?.id);
		setChecked(false);
		if (index === 0 && firstMonth === act) {
			setCurrentVersion(item?.id);
		}
	};
	const getSelectedVersion = (item, index, firstMonth, act) => {
		if (selectedVersion?.id !== null) {
			if (selectedVersion?.id === item?.id) {
				return true;
			}
			return false;
		}
		return false;
	};

	const object = getObject();
	if (!object) return null;
	const {
		previousStatus,
		systemStatus,
		updatedStatus,
		orderId,
		clientPayoutModel,
		isScheduleDeleted,
	} = object;
	const getBoxProps = (field) => ({
		name: object[field]?.statusName?.value || systemStatus?.statusName,
		color: object[field]?.color?.value || systemStatus?.color,
		status: object[field]?.orderStatus?.value || object[field]?.orderStatus,
	});
	return (
		<>
			<div
				className={`timelineContainer${showTimeline ? "-show" : ""}`}
				style={{
					justifyContent: activitiesLoading && "center",
					alignItems: activitiesLoading && "center",
				}}
			>
				{showTimeline &&
					(activitiesLoading ? (
						<Loader />
					) : (
						<Timeline
							setShowTimeline={setShowTimeline}
							activities={activities}
							selectedVersion={selectedVersion}
							currentVersion={currentVersion}
							handleGetById={handleGetById}
							handleShowChanges={handleShowChanges}
							getSelectedVersion={getSelectedVersion}
							checked={checked}
						/>
					))}
			</div>
			<div className={`previewContainer${showTimeline ? "-show" : ""}`}>
				<div className="orders__modal__icon__and__name">
					<span>{t("PreviewOrder")}</span>
					<div className="icons-wrapper">
						<>
							{checkRoles("changeStatusOrder") && (
								<div className="icons-wrapper_status">
									{previousStatus?.orderStatus?.changed && checked && (
										<>
											<StatusBox {...getBoxProps("previousStatus")} />
											<MoveRightIcon width={30} />
										</>
									)}
									{(!checked || !previousStatus) && (
										<StatusBox
											editable={
												!showTimeline &&
												systemStatus?.statusName !== t("InProgress")
											}
											orderIdsAndClientTypes={{
												id: orderId,
												clientPayoutModel,
											}}
											changeOrdersStatus={changeOrdersStatus}
											loading={changeStatusLoading}
											{...getBoxProps("systemStatus")}
										/>
									)}
									{updatedStatus?.orderStatus?.changed && checked && (
										<StatusBox {...getBoxProps("updatedStatus")} />
									)}
								</div>
							)}
							{checkRoles("seeTimeline") && !isScheduleDeleted && (
								<div
									className="icons-wrapper_timeline"
									onClick={() => getActivities(orderId)}
								>
									<TimelineIcon />
								</div>
							)}
						</>
						<CloseIcon
							className="close__modal__icon"
							click={() => setShowEditModal(false)}
						/>
					</div>
				</div>
				<div>
					<Preview
						fieldMapping={fieldMapping}
						order={object !== null && object}
						options={options}
					/>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	editOrderState: state.app.orders.edit,
	editedOrderFields: state.app.client.index.editedOrderFields,
	changeStatusLoading: state.app.orders.create.loading,
});

const mapDispatchToProps = {
	fillOrder: editOrderActions.fillOrder,
	fetchGenericOrderByID: clientActions.fetchGenericOrderByID,
	setShowTimeline: editOrderActions.setShowTimeline,
	getActivities: editOrderActions.getActivities,
	getActivitiesById: editOrderActions.getActivitiesById,
	setOrdersWithChanges: editOrderActions.setOrdersWithChanges,
	changeOrdersStatus: createOrderActions.changeOrdersStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateEditOrder));
