import i18n from "@src/i18n";
import {formatNumberDe, stringFormatNumber} from "@src/utils/convertNumber";
import {checkRoles} from "@src/utils/checkRoles";
import revenuesLogo from "../../assets/img/revenuesLogo.svg";
import totalOrdersLogo from "../../assets/img/totalOrdersLogo.svg";
import cancellationLogo from "../../assets/img/cancellationLogo.svg";
import pendingLogo from "../../assets/img/pendingLogo.svg";
import pointsAchievementLogo from "../../assets/img/pointsAchievementLogo.svg";
import receivedCommission from "../../assets/img/receivedCommission.svg";

export const dashboardBoxes = (loggedUser, activeTab) => {
	const items = [
		{
			title: i18n.t("receivedCommission"),
			icon: receivedCommission,
			formatter: stringFormatNumber,
			field: "receivedCommission",
			show: checkRoles("seeReceivedCommission") && activeTab?.type === "dashboard1",
		},
		{
			title: i18n.t("TotalRevenue"),
			icon: revenuesLogo,
			field: "totalRevenue",
			formatter: stringFormatNumber,
			show: checkRoles("seeTotalRevenue"),
		},
		{
			title: i18n.t("TotalOrders"),
			icon: totalOrdersLogo,
			field: "total",
			formatter: formatNumberDe,
			show: true,
		},
		{
			title: i18n.t("TotalCancelled"),
			icon: cancellationLogo,
			field: "cancelled",
			formatter: formatNumberDe,
			show: true,
		},
		{
			title: i18n.t("TotalPending"),
			icon: pendingLogo,
			field: "pending",
			formatter: formatNumberDe,
			show: activeTab.type === "general" || activeTab.type === "dashboard2",
		},
		{
			title: i18n.t("PointsAchieved"),
			icon: pointsAchievementLogo,
			field: "points",
			formatter: formatNumberDe,
			show: activeTab.type === "general",
		},
	];
	return items.filter((item) => item.show);
};
export const initialQuery = {
	dateType: null,
	from: null,
	to: null,
	search: "",
	clientId: null,
	locationId: null,
	priority: null,
	status: null,
	teamId: null,
	agencyId: null,
	userId: null,
	productId: null,
	tariffId: null,
	groupTypeId: null,
	dateStatus: "ALL",
};
