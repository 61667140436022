/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from "react";
import {Page, Document, StyleSheet, Text, Image, View} from "@react-pdf/renderer";
import moment from "moment";
import MainTable from "@common/calculationPdf/calculationMainTable";
import FirstTable from "@common/calculationPdf/FirstTable";
import SecondTable from "@common/calculationPdf/SecondTable";
import CancellationTable from "@common/calculationPdf/CancellationTable";
import {useTranslation} from "react-i18next";
import Footer from "@common/calculationPdf/Footer";
import AgnecyFooter from "@common/calculationPdf/AgencyFooter";
import SVGImage from "./SvgImage";

const CalculationTablePdf = (props) => {
	const {data, from, id, billingDetails} = props;
	const {t} = useTranslation();
	const {billingMonthsView} = data?.details;
	const settlementMonth = billingMonthsView?.id
		? `${t(billingMonthsView?.month)} ${billingMonthsView?.year}`
		: moment(data?.date)?.format("MMMM YYYY");

	const companyName =
		(data?.details?.spRole === "AGENCY" || data?.details?.spRole === "AGENCY-SP") &&
		data?.details?.agencyType === "DETAILED"
			? data?.details?.companyName
			: billingDetails?.companyName;

	const companyLogo =
		data?.details?.spRole === "AGENCY" || data?.details?.spRole === "AGENCY-SP"
			? `${data?.details?.agencyLogo}?disablecache`
			: `${billingDetails?.tenantLogo}?disablecache`;

	const footerDetails =
		(data?.details?.spRole === "AGENCY" || data?.details?.spRole === "AGENCY-SP") &&
		data?.details?.agencyType === "DETAILED" ? (
			<AgnecyFooter data={data.details} />
		) : (
			<Footer data={billingDetails} />
		);
	const getSpRole = () => {
		const {spRole} = data.details || {};
		if (!spRole) return "";
		if (spRole.includes("/")) {
			return spRole
				.split("/")
				.map((item) => t(item.trim()))
				.join(" / ");
		}
		return t(spRole);
	};
	return (
		<Document>
			<Page
				size="A4"
				style={from === "download" ? styles.viewContainer : styles.viewContainerWeb}
			>
				<View style={styles.header}></View>
				<View style={styles.logoDiv} fixed>
					<View style={styles.topContentName}>
						<Text style={{fontSize: 8}}>{`${
							(data?.details?.spRole === "AGENCY" ||
								data?.details?.spRole === "AGENCY-SP") &&
							data?.details?.agencyType === "DETAILED"
								? "erstellt im Auftrag von:"
								: "erstellt durch:"
						} ${companyName}`}</Text>
						<Text style={{fontSize: 8}}>
							{(data?.details?.spRole === "AGENCY" ||
								data?.details?.spRole === "AGENCY-SP") &&
							data?.details?.agencyType === "DETAILED"
								? data?.details?.address
								: billingDetails?.address}
						</Text>

						{/* <Text style={{fontSize: 10}}>
							Zeitraum: {moment(data?.date).format("MMMM YYYY")}
						</Text> */}
					</View>
					{(data?.details?.agencyLogo !== null ||
						billingDetails?.tenantLogo !== null) && (
						<Image source={companyLogo} style={styles.logo} />
					)}
				</View>
				<View style={styles.line} fixed></View>
				<View style={styles.empty} fixed></View>

				<View style={styles.header}></View>
				<View style={styles.topContent}>
					<View
						style={[
							styles.topContentName,
							{
								height:
									data?.details?.clientPayoutModel === "DISTRIBUTION_MODEL"
										? 37
										: 25,
							},
						]}
					>
						<Text style={{fontSize: 8, fontFamily: "Helvetica"}}>
							{t("VPNumber")}:
							<Text style={{fontWeight: "bold", fontFamily: "Helvetica"}}>
								{data?.details?.vpNumber}
							</Text>
						</Text>
						<Text style={{fontSize: 8, fontFamily: "Helvetica"}}>
							Status:
							<Text style={{fontWeight: "bold", fontFamily: "Helvetica"}}>
								{getSpRole()}
							</Text>
						</Text>
						{data?.details?.clientPayoutModel === "DISTRIBUTION_MODEL" && (
							<Text style={{fontSize: 8, fontFamily: "Helvetica"}}>
								Stufe:{" "}
								<Text style={{fontWeight: "bold", fontFamily: "Helvetica"}}>
									{data?.details?.stufe?.name}
								</Text>
							</Text>
						)}
					</View>
					{billingDetails?.qrCode && (
						<SVGImage
							uri={billingDetails?.qrCode}
							styles={
								from === "preview" ? styles?.qrCodelogoPreview : styles?.qrCodelogo
							}
						/>
					)}
					<FirstTable data={billingDetails} />
				</View>
				<View style={styles.tableThreeAndTitle}>
					<View style={{...styles.agencyInfo}}>
						{(data?.details?.agencyType === "SIMPLE" ||
							data?.details?.spRole === "AGENCY_70") &&
						data?.details?.spRole === "AGENCY" ? (
							<>
								<Text
									style={{
										fontFamily: "Helvetica-Bold",
										fontSize: 10,
										paddingBottom: 2,
									}}
								>
									{data?.details?.companyName}
								</Text>
								{/* <Text style={{paddingBottom: 2, fontFamily: "Helvetica"}}>
									{data?.details?.agencyName}
								</Text> */}
								<Text style={{paddingBottom: 2, fontFamily: "Helvetica"}}>
									{data?.details?.address}
								</Text>
								<Text style={{paddingBottom: 2, fontFamily: "Helvetica"}}>
									{data?.details?.location}
								</Text>
							</>
						) : (
							<>
								<Text style={{fontFamily: "Helvetica-Bold"}}>
									{data?.details?.spFullName}
								</Text>
								<Text>{data?.details?.address}</Text>
								<Text>{data?.details?.location}</Text>
							</>
						)}
					</View>
					<View>
						<SecondTable id={id} data={data} />
					</View>
				</View>
				<View style={styles.tableThreeAndTitle}>
					<View style={styles.topContentTime}>
						<Text style={{fontFamily: "Helvetica-Bold"}}>
							Übersicht Provisionsabrechnung für {settlementMonth}
						</Text>
						<View>
							<Text style={{fontSize: 8, marginTop: 10}}>
								Nachfolgend sehen Sie eine Zusammenfassung der abgerechneten
								Vorgänge. Die Details entnehmen Sie bitte der beigefügten
								Einzelaufstellung (Excel – Tabelle). Diese ist Bestandteil dieser
								Übersicht.
							</Text>
						</View>
						<View style={styles.boxShadow}></View>
					</View>
					<CancellationTable data={data?.details} />
				</View>
				<MainTable data={data?.details} contracts={data?.data} />
				{/* <SummaryTable data={data} /> */}
				<View style={styles.bottomTextDiv}>
					{/* {data?.details?.taxBoolean === false && (
						<Text style={{fontWeight: "bold", fontSize: "8px"}}>
							{t("ValueAddedTax")} § 19 UStG.
						</Text>
					)} */}
					<Text style={{color: "#fff", fontSize: 8}}>Blank Text</Text>
					<Text style={styles.bottomText}>{t("DeatilsOnBilledContracts")}</Text>
					<Text style={{color: "#fff", fontSize: 8}}>Blank Text</Text>

					{data?.details?.agencyType === "SIMPLE" ||
					data?.details?.spRole === "AGNECY_70" ||
					data?.details?.spRole === "SP" ? (
						<View>
							<Text style={styles.bottomText}>
								{data?.details?.clientPayoutModel !== "DISTRIBUTION_MODEL"
									? "Wird keine Umsatzsteuer ausgewiesen, erfolgt der Umsatzsteuerausweis gemäß § 19 UStG."
									: ""}
							</Text>
							<Text style={styles.bottomText}>
								{data?.details?.clientPayoutModel !== "DISTRIBUTION_MODEL"
									? "Diese Abrechnung dient der Vorab-Information und ist nicht nicht für steuerliche und/oder buchhalterische Zwecke geeignet."
									: ""}
							</Text>
							<Text style={{color: "#fff", fontSize: 8}}>Blank Text</Text>
						</View>
					) : (
						<View>
							{data?.details?.agencyType === "DETAILED" ? (
								<>
									<Text style={styles.bottomText}>
										{data?.details?.clientPayoutModel !== "DISTRIBUTION_MODEL"
											? "Wird keine Umsatzsteuer ausgewiesen, erfolgt der Umsatzsteuerausweis gemäß § 19 UStG."
											: ""}
									</Text>
									<Text style={styles.bottomText}>
										{data?.details?.clientPayoutModel !== "DISTRIBUTION_MODEL"
											? "Diese Abrechnung dient der Vorab-Information und ist nicht nicht für steuerliche und/oder buchhalterische Zwecke geeignet."
											: ""}
									</Text>
									<Text style={styles.bottomText}>
										{data?.details?.clientPayoutModel !== "DISTRIBUTION_MODEL"
											? "Entsprechende Gutschriften mit Verweis auf die jeweilige Abrechnungsnummer werden direkt von Deiner Agentur versandt."
											: ""}
									</Text>
								</>
							) : (
								<Text style={styles.bottomText}>
									{data?.details?.clientPayoutModel !== "DISTRIBUTION_MODEL"
										? "Diese Abrechnung dient der Vorab-Information und ist nicht für steuerliche und/oder buchhalterische Zwecke geeignet."
										: ""}
								</Text>
							)}
							<Text style={{color: "#fff", fontSize: 8}}>Blank Text</Text>
						</View>
					)}
					{data?.details?.agencyType === "SIMPLE" ||
						(data?.details?.spRole === "AGENCY_70" && (
							<Text style={styles.bottomText}>
								Die Abrechnung wurde erstellt im Auftrag von:
								{data?.details?.agencyName}, {data?.details?.address},
								{data?.details?.companyName}
							</Text>
						))}
				</View>
				<View style={styles.footer} fixed>
					{footerDetails}
				</View>
				{/* <PdfTable
					data={data}
					from={from}
					title="settlementDetails"
					title2={`- ${data?.[0]?.salesPartnerName} - ${moment(
						data?.[0]?.createdAt,
					).format(DATE_FORMAT)}`}
				/> */}
			</Page>
		</Document>
	);
};

export default CalculationTablePdf;

const styles = StyleSheet.create({
	viewContainer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
		paddingTop: 10,
		paddingRight: 25,
		paddingLeft: 25,
		paddingBottom: 50,
	},
	viewContainerWeb: {
		display: "flex",
		flexDirection: "column",
		width: 938,
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
		paddingTop: 30,
		paddingRight: 47,
		paddingLeft: 42,
		paddingBottom: 30, // 60
	},
	header: {
		width: 938,
		height: "30px",
		backgroundColor: "#fff",
		color: "#252525",
	},
	divider: {
		width: 938,
		height: "100px",
		backgroundColor: "#fff",
	},
	logo: {
		width: "150px",
		height: "50px",
		objectFit: "contain",
	},
	qrCodelogo: {
		width: "90px",
		height: "90px",
		objectFit: "contain",
		position: "absolute",
		left: 250,
		border: "3px solid #000",
		padding: 3,
	},
	qrCodelogoPreview: {
		width: "90px",
		height: "90px",
		objectFit: "contain",
		position: "absolute",
		left: 210,
		border: "3px solid #000",
		padding: 3,
	},
	topContent: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		color: "#252525",
	},
	topContentName: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		fontSize: "8px",
		color: "#252525",
		fontFamily: "Helvetica",
		height: 25,
		width: 250,
	},
	tableThreeAndTitle: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 10,
		color: "#252525",
	},
	bottomText: {
		fontSize: 8,
		color: "#252525",
		fontFamily: "Helvetica",
	},
	bottomTextDiv: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		// marginBottom: 30,
		marginTop: "auto",
		color: "#252525",
	},
	logoDiv: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		// borderBottom: "1px solid #3279BE",
		alignItems: "flex-end",
		paddingBottom: 30,
	},
	line: {
		width: "100%",
		borderBottom: "1px solid #3279BE",
	},
	empty: {
		width: "100%",
	},
	agencyInfo: {
		display: "flex",
		flexDirection: "column",
		fontSize: 8,
		justifyContent: "center",
		color: "#252525",
	},
	topContentTime: {
		display: "flex",
		fontSize: 8,
		width: "57.5%",
		border: "1px solid #252525",
		borderRight: "4px outset rgba(50,121,190,1)",
		borderBottom: "4px outset rgba(50,121,190,1)",
		paddingVertical: 10,
		paddingHorizontal: 7,
		position: "relative",
		zIndex: 22,
		color: "#252525",
		fontFamily: "Helvetica",
	},
});
