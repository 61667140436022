import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Menu from "@mui/material/Menu";
import {ReactComponent as ArrowDown} from "@assets/img/arrow_down.svg";
import {useTranslation} from "react-i18next";
import Star from "@assets/img/star_black_24dp.svg";
import ModalComponent from "@common/modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@mui/material";
import {getSelectOptionsFromDataTeams} from "@utils/select";
import {CloseModalIcon} from "@assets/SvgComponents";
import DatePickerItem from "@common/DatePicker";
import moment from "moment";
import {Formik} from "formik";
import * as yup from "yup";
import Button from "@common/components/button";
import {checkInternDates, isInsideRange} from "@src/utils/isInsideRange";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
const useStyles = makeStyles(() => ({
	accordion__container__menu: {
		display: "flex",
		flexDirection: "column",
		padding: "8px",
	},
}));

const EMPLOYEE_ROLES = ["EMPLOYEE", "EMPLOYEE_SHOP", "EMPLOYEE_TL", "EMPLOYEE_AB"];

function UserAccordion(props) {
	const {t} = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [practiceModal, setPracticeModal] = useState(null);
	const [modalType, setModalType] = useState("");
	const [selectedTrainer, setSelectedTrainer] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const [selectedIntern, setSelectedIntern] = useState(null);
	const handleTrainerSelect = (value) => {
		const selectedObject = usersFromState.find((obj) => obj.id === value);
		setSelectedTrainer(selectedObject);
	};
	const validationSchema = yup.object().shape({
		fromDate: yup.string().nullable().label(t("fromDate")).required(t("DateIsReq")),
		toDate: yup.string().nullable().label(t("toDate")).optional(),
		trainerId: yup.string().nullable().label(t("trainerId")).required(t("trainerReq")),
	});
	const validationSchemaTrainer = yup.object().shape({
		fromDate: yup.string().nullable().label(t("fromDate")).required(t("DateIsReq")),
		toDate: yup.string().nullable().label(t("toDate")).optional(),
	});
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleCloseModal = () => {
		setPracticeModal(false);
		setSelectedTrainer(null);
	};
	const {
		data,
		id,
		teamLeader,
		setTeamLeader,
		showStar,
		usersFromState,
		setUsersFromState,
		isEditing,
		teamDates,
		setCanSubmit,
		setFilteredUsers,
		setHeadOfDepartmentOptions,
		setFieldValue,
		values,
		endpointPermissions,
	} = props;
	const classes = useStyles();
	const handelMakeTeamLeader = () => {
		setTeamLeader(id);
		handleClose();
	};

	const allTrainers = usersFromState.filter((item) => item.trainer);
	const trainerExists = usersFromState.some((obj) => obj.trainer === true);
	const removeFromArray = () => {
		const found = usersFromState.some((obj) => obj.trainerId === id);
		if (found) {
			const updatedArray = usersFromState.map((obj) => {
				if (obj.trainerId === id) {
					return {
						...obj,
						trainerId: null,
						internship: false,
						fromDate: null,
						toDate: null,
					};
				}
				return obj;
			});
			setUsersFromState(updatedArray.filter((item) => item.id !== id));
		} else {
			setUsersFromState(usersFromState.filter((item) => item.id !== id));
		}
		setFilteredUsers((prevUsers) => [...prevUsers, data]);
		if (data.role.name.startsWith("EMPLOYEE")) {
			setHeadOfDepartmentOptions((prevState) => {
				const filteredState = prevState.filter((user) => user.id !== data.id);
				return [...filteredState, data];
			});
		}
		const filteredValue = values?.users?.filter((user) => user?.id !== data.id);
		setFieldValue("users", filteredValue);
		handleClose();
		if (teamLeader === id) {
			setTeamLeader(null);
		}
	};
	const removeAsTrainer = () => {
		const updatedArray = usersFromState.map((obj) => {
			if (obj.id === id) {
				return {...obj, trainer: false, fromDate: null, toDate: null};
			}
			if (obj.trainerId === id) {
				return {...obj, trainerId: null, internship: false, fromDate: null, toDate: null};
			}
			return obj;
		});
		setUsersFromState(updatedArray);
		handleClose();
	};
	const removeAsIntern = () => {
		const updatedArray = usersFromState.map((obj) => {
			if (obj.id === id) {
				return {...obj, internship: false, trainerId: null, fromDate: null, toDate: null};
			}
			return obj;
		});
		setUsersFromState(updatedArray);
		handleClose();
	};
	const handlePracticeModal = (type) => {
		setModalType(type);
		handleClose();
		setPracticeModal(id);
	};
	const handleEditDates = (data) => {
		handlePracticeModal(data?.internship ? "intern" : "trainer");
		handleTrainerSelect(data?.internship ? data?.trainerId : data?.id);
		if (data?.internship) {
			const foundedIntern = usersFromState?.find((item) => item?.id === data?.id);
			setSelectedIntern(foundedIntern);
		}
		setEditMode(true);
	};
	const modalTitle = modalType === "intern" ? t("AddIntern") : t("AddTrainer");
	const modalTitleBasedOnModalType = {
		intern: t("EditInernDates"),
		trainer: t("EditTrainerDates"),
	};
	const valuesBasedOnModalType = {
		intern: selectedIntern,
		trainer: selectedTrainer,
	};
	const correctModalTitle = !editMode ? modalTitle : modalTitleBasedOnModalType?.[modalType];
	useEffect(() => {
		const areInternDatesInsideRange = checkInternDates(usersFromState);
		setCanSubmit(areInternDatesInsideRange);
	}, [practiceModal]);
	return (
		<div className={`accordion__container${data?.internship ? "-child" : ""}`}>
			<div className="user__accordion__dropdown">
				<div>
					<span className={`${data?.internship ? "child" : ""}`}>
						{data?.firstName} {data?.lastName}{" "}
					</span>
					<span className="date">
						{data?.fromDate && moment(data?.fromDate).format("DD/MM/YYYY")}
						{data?.toDate && `- ${moment(data?.toDate).format("DD/MM/YYYY")}`}
					</span>
				</div>
				<div className="user__accordion__element">
					{showStar && (
						<div className="user__accordion__star__and__text">
							<img src={Star} alt="" />
							<p> {t("TeamLeader")} </p>
						</div>
					)}
					{data?.trainer && (
						<div className="user__accordion__star__and__text">
							{/* <img src={Star} alt="" /> */}
							<p> {t("Trainer")} </p>
						</div>
					)}
					{data?.internship && (
						<div className="user__accordion__star__and__text">
							{/* <img src={Star} alt="" /> */}
							<p> {t("Internship")} </p>
						</div>
					)}
					{!showStar && <ArrowDown onClick={handleClick} />}
				</div>
			</div>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				className={classes.accordion__container__menu}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{endpointPermissions?.makeTeamLeader?.includes(data?.role?.id) &&
					!isEditing &&
					!data?.trainer &&
					!data?.internship && (
						<div onClick={handelMakeTeamLeader} className="team_modal_user_accordion">
							{t("MakeTeamLead")}
						</div>
					)}
				{!data?.internship && !data?.trainer && (
					<div
						onClick={() => handlePracticeModal("trainer")}
						className="team_modal_user_accordion"
					>
						{t("Trainer")}
					</div>
				)}
				{!data?.internship && trainerExists && !data?.trainer && (
					<div
						onClick={() => handlePracticeModal("intern")}
						className="team_modal_user_accordion"
					>
						{t("Internship")}{" "}
					</div>
				)}
				{data?.trainer && (
					<div onClick={removeAsTrainer} className="team_modal_user_accordion">
						{t("RemoveAsTrainer")}{" "}
					</div>
				)}
				{data?.internship && (
					<div onClick={removeAsIntern} className="team_modal_user_accordion">
						{t("RemoveAsIntern")}{" "}
					</div>
				)}
				{(data?.internship || data?.trainer) && (
					<div
						onClick={() => handleEditDates(data)}
						className="team_modal_user_accordion"
					>
						{data?.internship
							? modalTitleBasedOnModalType?.intern
							: modalTitleBasedOnModalType?.trainer}
					</div>
				)}
				<div onClick={removeFromArray} className="team_modal_user_accordion">
					{t("Remove")}{" "}
				</div>
			</Menu>
			<ModalComponent
				open={practiceModal === id}
				handleClose={() => setPracticeModal(false)}
				disableEnforceFocus
				width={470}
				positionModal="center"
			>
				<div className="delete__modal__component__import intern_modal">
					<div className="delete__modal__component__import-header">
						<span className="delete__modal__component__import-header-title">
							{correctModalTitle}
						</span>
						<div onClick={handleCloseModal} className="close__icon">
							<CloseModalIcon fill="#000" width="10px" height="10px" />
						</div>
					</div>
					<div className="delete__modal__component__import-header-body">
						<>
							<Formik
								initialValues={
									editMode
										? valuesBasedOnModalType?.[modalType]
										: {fromDate: null, toDate: null, trainerId: null}
								}
								validationSchema={
									modalType === "intern"
										? validationSchema
										: validationSchemaTrainer
								}
								enableReinitialize={true}
								onSubmit={(values) => {
									const isInside = isInsideRange(selectedTrainer, values);

									if (modalType === "intern") {
										if (isInside) {
											const newItems = [...usersFromState];
											const itemIndex = newItems.findIndex(
												(item) => item.id === id,
											);
											newItems[itemIndex] = {
												...newItems[itemIndex],
												trainerId: values?.trainerId,
												internship: true,
												trainer: false,
												fromDate: values?.fromDate,
												toDate: values?.toDate,
											};
											setUsersFromState(newItems);
											setPracticeModal(null);
										} else {
											ToastErrorComponent(t("InternshipDatesInfo"));
										}
									} else {
										const newItems = [...usersFromState];
										const itemIndex = newItems.findIndex(
											(item) => item.id === id,
										);
										newItems[itemIndex] = {
											...newItems[itemIndex],
											trainer: true,
											fromDate: values?.fromDate,
											toDate: values?.toDate,
										};
										setUsersFromState(newItems);
										handleClose();
										setPracticeModal(null);
									}
								}}
							>
								{({values, errors, touched, handleSubmit, setFieldValue}) => (
									<form
										onSubmit={handleSubmit}
										className="edit__and__create__form"
									>
										<div>
											{modalType === "intern" && (
												<div>
													<Autocomplete
														disableClearable={false}
														className="autocomplete"
														disableCloseOnSelect={false}
														filterSelectedOptions={true}
														getOptionLabel={(option) => option.label}
														getOptionSelected={(option, value) =>
															option?.value === value?.value
														}
														disabled={editMode}
														value={{
															label: selectedTrainer?.name,
															value: selectedTrainer?.id,
														}}
														multiple={false}
														onChange={(event, value) => {
															if (value !== null) {
																handleTrainerSelect(value?.value);
																setFieldValue("fromDate", null);
																setFieldValue("toDate", null);
																setFieldValue(
																	"trainerId",
																	value?.value,
																);
															} else {
																setFieldValue("fromDate", null);
																setFieldValue("toDate", null);
																setFieldValue("trainerId", null);
																setSelectedTrainer(null);
															}
														}}
														options={getSelectOptionsFromDataTeams(
															allTrainers,
															"name",
															"id",
														)}
														classes={{popper: classes.popper}}
														renderInput={(params) => (
															<TextField
																{...params}
																className="text-field"
																id="name"
																name="name"
																placeholder={t("Trainer")}
																variant="outlined"
															/>
														)}
													/>
													{touched?.trainerId && errors?.trainerId && (
														<span className="errorClass">
															{errors?.trainerId}
														</span>
													)}
												</div>
											)}
											<div className={`datepicker-teams ${modalType && 'add'} `}>
												<div className="datepicker-teams--start">
													<DatePickerItem
														minDate={
															modalType === "intern"
																? selectedTrainer?.fromDate
																: moment(teamDates?.from).valueOf()
														}
														maxDate={
															// eslint-disable-next-line max-len
															// eslint-disable-next-line no-nested-ternary
															modalType === "intern"
																? selectedTrainer?.toDate
																: teamDates?.to !== null
																? moment(teamDates?.to).valueOf()
																: null
														}
														value={values.fromDate}
														label={t("StartDate")}
														onChange={(newValue) => {
															if (newValue) {
																setFieldValue(
																	"fromDate",
																	moment(newValue)
																		.startOf("day")
																		.add(2, "hours")
																		.valueOf(),
																);
															}
														}}
														disabled={
															modalType === "intern"
																? values?.trainerId === null
																: false
														}
														errors={errors?.fromDate}
														touched={touched.fromDate}
													/>
												</div>
												<DatePickerItem
													minDate={moment(values.fromDate)
														.add(1, "day")
														.valueOf()}
													maxDate={
														// eslint-disable-next-line max-len
														// eslint-disable-next-line no-nested-ternary
														modalType === "intern"
															? selectedTrainer?.toDate
															: teamDates?.to !== null
															? moment(teamDates?.to).valueOf()
															: null
													}
													errors={errors?.toDate}
													touched={touched?.toDate}
													label={t("EndDate")}
													disabled={
														modalType === "intern"
															? values?.fromDate === null ||
															  values?.trainerId === null
															: values?.fromDate === null
													}
													value={values.toDate}
													showClearBtn={
														(modalType === "intern" &&
															!selectedTrainer?.toDate) ||
														(modalType === "trainer" && !teamDates.to)
													}
													onClear={() => {
														setFieldValue("toDate", null);
														if (modalType === "intern") {
															setSelectedIntern({
																...selectedIntern,
																toDate: null,
															});
														} else {
															setSelectedTrainer({
																...selectedTrainer,
																toDate: null,
															});
														}
													}}
													onChange={(newValue) => {
														if (newValue) {
															setFieldValue(
																"toDate",
																moment(newValue)
																	.startOf("day")
																	.add(2, "hours")
																	.valueOf(),
															);
														}
													}}
												/>
											</div>
										</div>
										<Button
											text={correctModalTitle}
											type="submit"
											minWidth="100%"
										/>
									</form>
								)}
							</Formik>
						</>
					</div>
				</div>
			</ModalComponent>
		</div>
	);
}

export default UserAccordion;
