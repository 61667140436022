import React from "react";

function Row({title, description, onClick}) {
	return (
		<div onClick={onClick} className="row">
			<div className="row-left">
				<span className="row-left_title">{title}</span>
				<span className="row-left_description">{description}</span>
			</div>
		</div>
	);
}

export default Row;
