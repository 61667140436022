import StatusBox from "@src/common/components/status-box";
import React from "react";
import {connect} from "react-redux";
import {actions as createOrderActions} from "@sagas/orders/create";
import {useTranslation} from "react-i18next";

const Header = ({order, loading, changeOrdersStatus}) => {
	const {orderId, clientPayoutModel, systemStatus} = order;
	const {statusName, color, orderStatus} = systemStatus;
	const {t} = useTranslation();
	return (
		<div className="order__form__modal-header">
			<StatusBox
				editable={statusName !== t("InProgress")}
				orderIdsAndClientTypes={{
					id: orderId,
					clientPayoutModel,
				}}
				changeOrdersStatus={changeOrdersStatus}
				loading={loading}
				name={statusName}
				color={color}
				status={orderStatus}
			/>
		</div>
	);
};
const mapStateToProps = (state) => ({
	loading: state.app.orders.create.loading,
	order: state.app.orders.edit.order,
});
const mapDispatchToProps = {
	changeOrdersStatus: createOrderActions.changeOrdersStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
