/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import produce from "immer";
import _ from "lodash";
import { put, select, takeLatest } from "redux-saga/effects";
import { ToastErrorComponent, ToastSuccesComponent } from "@src/common/ToastComponent/ToastComponent";
import { axiosWithoutToken } from "@utils/axios";
import { ENDPOINT } from "@src/utils/endpoint";
import Logger from "@utils/logger";
import { actions as navigation } from "@sagas/navigation";
import createAction from "../../../../utils/action-creator";

const PREFIX = "@app/pinCode/";
const logger = new Logger("Contactless order:");

export const SET_PIN = `${PREFIX}SET_PIN`;
export const SET_PIN_CORRECT = `${PREFIX}SET_PIN_CORRECT`;
export const SET_ORDER_ID = `${PREFIX}SET_ORDER_ID`;
export const VERIFY_PIN = `${PREFIX}VERIFY_PIN`;
export const VALIDATE_TOKEN = `${PREFIX}VALIDATE_TOKEN`;
export const VALIDATE_TOKEN_SUCCESS = `${PREFIX}VALIDATE_TOKEN_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_CONTACTLESS_ORDER = `${PREFIX}GET_CONTACTLESS_ORDER`;
export const GET_CONTACTLESS_ORDER_SUCCESS = `${PREFIX}GET_CONTACTLESS_ORDER_SUCCESS`;
export const SET_PARAMS = `${PREFIX}SET_PARAMS`;
export const RESEND_ORDER_PIN = `${PREFIX}RESEND_ORDER_PIN`;
export const SET_BUTTON_PHASE = `${PREFIX}SET_BUTTON_PHASE`;
export const SUBMIT_CONTACTLESS_ORDER = `${PREFIX}SUBMIT_CONTACTLESS_ORDER`;

const _state = {
	pin: null,
	isPinCorrect: "unknown",
	orderId: null,
	validToken: false,
	loading: true,
	buttonPhase: "initial",
	contactlessOrder: null,
	params: {
		orderId: "",
		token: "",
		tokenPin: "",
	},
};

const reducer = (state = _state, { type, payload }) =>
	produce(state, (draft) => {
		switch (type) {
			case VALIDATE_TOKEN_SUCCESS:
				draft.validToken = payload;
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;
			case SET_PIN_CORRECT:
				draft.isPinCorrect = payload;
				break;
			case GET_CONTACTLESS_ORDER_SUCCESS:
				draft.contactlessOrder = {
					...payload,
					contractView: {
						...payload.contractView,
						customFieldValue: payload.contractView.customFieldsValue,
					}
				};
				break;
			case SET_PARAMS:
				draft.params = payload;
				break;
			case SET_BUTTON_PHASE:
				draft.buttonPhase = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setPin: (payload) => createAction(SET_PIN, { payload }),
	setPinCorrect: (payload) => createAction(SET_PIN_CORRECT, { payload }),
	setOrderId: (payload) => createAction(SET_ORDER_ID, { payload }),
	verifyPin: (payload) => createAction(VERIFY_PIN, { payload }),
	validateToken: (payload) => createAction(VALIDATE_TOKEN, { payload }),
	validateTokenSuccess: (payload) => createAction(VALIDATE_TOKEN_SUCCESS, { payload }),
	setLoading: (payload) => createAction(SET_LOADING, { payload }),
	getContactlessOrder: (payload) => createAction(GET_CONTACTLESS_ORDER, { payload }),
	getContactlessOrderSuccess: (payload) => createAction(GET_CONTACTLESS_ORDER_SUCCESS, { payload }),
	setParams: (payload) => createAction(SET_PARAMS, { payload }),
	setButtonPhase: (payload) => createAction(SET_BUTTON_PHASE, { payload }),
	resendOrderPin: (payload) => createAction(RESEND_ORDER_PIN, { payload }),
	submitContactlessOrder: (payload) => createAction(SUBMIT_CONTACTLESS_ORDER, { payload }),
};

export const sagas = {
	*validateToken({ payload }) {
		yield put(actions.setLoading(true));
		try {
			yield axiosWithoutToken.post(ENDPOINT.VALIDATE_TOKEN, payload);
			yield put(actions.validateTokenSuccess(true));
			yield put(actions.setParams(payload));
		} catch (error) {
			logger.error(error);
			yield put(navigation.navigate(`/pinvalidation/generateToken/${payload.orderId}`));
			yield put(actions.validateTokenSuccess(false));
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*verifyPin({ payload }) {
		try {
			yield axiosWithoutToken.post(ENDPOINT.VERIFY_PIN, payload);
			yield put(actions.setPinCorrect(true));
			yield put(navigation.navigate("/order/contactless"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			yield put(actions.setPinCorrect(false));
		}
	},
	*getContactlessOrder() {
		yield put(actions.setLoading(true));
		try {
			const { params } = yield select((state) => state.app.contaclessOrder.index);
			const response = yield axiosWithoutToken.post(ENDPOINT.GET_CONTACTLESS_ORDER, params);
			const { data } = response?.data;
			yield put(actions.getContactlessOrderSuccess(data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*resendOrderPin({ payload }) {
		yield put(actions.setButtonPhase("loading"));
		try {
			const response = yield axiosWithoutToken.post(ENDPOINT.RESEND_ORDER_PIN, {
				orderId: payload,
			});
			const { data } = response?.data;
			yield put(actions.setButtonPhase("success"));
		} catch (error) {
			yield put(actions.setButtonPhase("failed"));
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*submitContactlessOrder({ payload }) {
		yield put(actions.setLoading(true));
		try {
			yield axiosWithoutToken.post(ENDPOINT.SUBMIT_SIGNATURE, payload);
			ToastSuccesComponent("Signature submitted!")
		} catch (error) {
			logger.error(error);
		}
	}
};

export const watcher = function* w() {
	yield takeLatest(VALIDATE_TOKEN, sagas.validateToken);
	yield takeLatest(VERIFY_PIN, sagas.verifyPin);
	yield takeLatest(GET_CONTACTLESS_ORDER, sagas.getContactlessOrder);
	yield takeLatest(RESEND_ORDER_PIN, sagas.resendOrderPin);
	yield takeLatest(SUBMIT_CONTACTLESS_ORDER, sagas.submitContactlessOrder);
};
