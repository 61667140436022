import {RemoveUserIcon} from "@assets/SvgComponents";
import React, {useEffect, useState} from "react";

const SelectedUsers = ({setUserOptions, userOptions, noDataMessage, setFieldValue}) => {
	const [data, setData] = useState([]);
	const deleteUser = (value) => {
		const selectedUserOptions = userOptions.map((item) => {
			if (item.value === value.teamOrAgency) {
				item = {...item, selected: false};
			}
			if (item?.value === value.value) {
				item = {...item, selected: false};
			}
			return item;
		});
		setUserOptions([...selectedUserOptions]);
		setFieldValue("users", [...selectedUserOptions.filter((item) => item.selected)]);
	};

	useEffect(() => {
		setData(
			userOptions?.filter(
				(item) => item.selected && item.type !== "Team" && item.type !== "Agency",
			),
		);
	}, [userOptions]);

	return (
		<div className="selected_users_wrapper">
			<div className="selected__users__display">
				{data?.[0] ? (
					data?.map((user, index) => (
						<div className="selected_users_container" key={index}>
							<span className="selected__user__name">{user?.label}</span>
							<span
								className="remove_btn"
								onClick={() => {
									deleteUser(user);
								}}
							>
								<RemoveUserIcon />
							</span>
						</div>
					))
				) : (
					<span className="selected_users_errors">{noDataMessage}</span>
				)}
			</div>
		</div>
	);
};

export default SelectedUsers;
