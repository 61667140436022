import {MinusIconRounded, PlusIconRounded} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import React from "react";

const currentLanguage = i18n.language;

const SectionBox = ({item, children, handleClick, collapsed, disabled, error}) => (
	<div className="section__box-wrapper" style={{border: error ? `2px solid red` : undefined}}>
		<div
			className="section__box-wrapper-header"
			style={{
				pointerEvents: disabled ? "none" : "auto",
			}}
			onClick={() => {
				if (!disabled) {
					handleClick(item.id);
				}
			}}
		>
			<p>{currentLanguage === "en" ? item.nameEn : item.nameDe}</p>
			{!disabled && (
				<div className="collapse-icon">
					{collapsed ? <PlusIconRounded /> : <MinusIconRounded />}
				</div>
			)}
		</div>
		{(collapsed || disabled) && (
			<div className="inputs__wrapper" style={{marginTop: 20}}>
				{children}
			</div>
		)}
	</div>
);

export default SectionBox;
