/* eslint-disable no-nested-ternary */
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as orderActions} from "@sagas/orders/index";
import {actions as importActions, actions as createImports} from "@sagas/imports/create";
import DataGridTable from "@src/common/table/DataGridTable";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import Modal from "@common/modal";
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import Button from "@src/common/components/button";
import CustomPopover from "@src/common/Popover";
import {CheckIcon} from "@src/assets/SvgComponents";
import importFiltersIcon from "@assets/img/filter-columns.svg";
import {useUrlParams} from "@src/utils/useUrlParams";
import {wsCloseImports, wsConnectImport} from "@src/ws";
import {getCurrentUser} from "@src/utils/currentUser";
import TableSkeleton from "@src/common/table/TableSkeleton";
import SkeletonInput from "antd/lib/skeleton/Input";
import {SETTLED_ACTION_STATUSES} from "@src/utils/constants";
import {actions as orderStatusAction} from "@sagas/orders/statuses";
import ImportsHeader from "./ImportsHeader";
import {
	actionsFormatter,
	compareDataArrays,
	generateColumns,
	getExpandRow,
	getRowClassName,
	camelCaseToHumanReadable,
	generateOrdersColumns,
	getPublishedRowClassName,
} from "./helper";
import ExpandTables from "./ExpandTables";
import CreateEditOrder from "../../orders/createEditOrder";
import {salesTrackerButtonProps} from "../../sales-tracker/staticData";
import FilterColumns from "./FilterColumns";

const OrdersTables = ({
	getOrders,
	toggleEditOrderModal,
	showEditModal,
	getOrderPreview,
	setImportsFilteredColumns,
	setImportsData,
	setOrdersData,
	deleteImportsData,
	unMatchOrders,
	setOrdersFilteredColumns,
	setUpdateRows,
	setColumnsImport,
	cleanState,
	getDraftById,
	setOptions,
	showTimeline,
	createImportsState,
	statuses,
	getOrderStatuses,
}) => {
	const {
		columnsImport,
		matchingField,
		importsFilteredColumns,
		ordersData,
		importsData,
		ordersFilteredColumns,
		singleDraft,
		orders,
		ordersLoading,
		options,
		importOrderViewDetails,
	} = createImportsState;
	const {t} = useTranslation();
	const {getUrlParams} = useUrlParams();
	const {
		id: draftId,
		fromDate,
		toDate,
		clientId,
		status,
	} = getUrlParams("id", "fromDate", "toDate", "clientId", "status");
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredOrdersData, setFilteredOrdersData] = useState([]);
	const [showPopoverFiltersImport, setShowPopoverFiltersImport] = useState(false);
	const [showPopoverFiltersOrders, setShowPopoverFiltersOrders] = useState(false);
	const handleVisibility = (visibility) => setShowPopoverFiltersImport(visibility);
	const handleVisibilityOrders = (visibility) => setShowPopoverFiltersOrders(visibility);
	const [expanded, setExpanded] = useState(null);
	const [orderColumns, setOrdersColumns] = useState([]);
	const [previewRow, setPreviewRow] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState({
		show: false,
		item: null,
	});
	const itemsImports = [
		{
			key: "imports",
			label: (
				<FilterColumns
					setColumnsOnState={setImportsFilteredColumns}
					columnsOnState={importsFilteredColumns}
					setUpdateRows={setUpdateRows}
				/>
			),
		},
	];
	const itemsOrders = [
		{
			key: "orders",
			label: (
				<FilterColumns
					setColumnsOnState={setOrdersFilteredColumns}
					columnsOnState={ordersFilteredColumns}
					setUpdateRows={setUpdateRows}
				/>
			),
		},
	];
	const handlePreviewEdit = (id, type) => {
		if (type === "preview") {
			setPreviewRow(true);
		} else {
			setPreviewRow(false);
		}
		toggleEditOrderModal(true);
		getOrderPreview(id);
	};

	useEffect(() => {
		if (searchQuery !== "") {
			setFilteredOrdersData(
				ordersData.filter(
					(item) =>
						item?.clientOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
						String(item?.orderId)?.toLowerCase().includes(searchQuery.toLowerCase()) ||
						item?.clientName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
						item?.orderStatus?.toLowerCase().includes(searchQuery.toLowerCase()) ||
						item?.salesPartnerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
						item?.tariffInfo?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
				),
			);
		} else {
			setFilteredOrdersData(ordersData);
		}
	}, [searchQuery, ordersData]);

	const filteredStatuses = useMemo(
		() => statuses?.filter((item) => !SETTLED_ACTION_STATUSES.includes(item.status)),
		[statuses],
	);

	const actions = {
		key: "actions",
		name: t("Actions"),
		formatter: ({row}) => actionsFormatter(row, handlePreviewEdit),
		sortable: false,
		resizable: false,
		width: 50,
	};
	useEffect(() => {
		getOrderStatuses();
		wsConnectImport(+draftId);
		getDraftById(+draftId);
		getOrders({
			clientId: +clientId,
			fromDate: +fromDate,
			toDate: +toDate,
		});
		return () => {
			cleanState();
			wsCloseImports();
		};
	}, []);

	const handleCloseModal = () => {
		setShowDeleteModal({show: false, item: null});
	};
	const handleConfirm = () => {
		if (showDeleteModal?.item?.same && showDeleteModal?.item?.matchedBy !== "import") {
			unMatchOrders({
				item: showDeleteModal?.item,
				draftId: +draftId,
				user: getCurrentUser(),
			});
		} else {
			deleteImportsData({
				item: showDeleteModal?.item,
				draftId: +draftId,
				user: getCurrentUser(),
			});
		}
		handleCloseModal();
	};

	useEffect(() => {
		// filter columns for left table
		const filteredColumns = importsFilteredColumns.filter((item) => item?.show === true);
		if (!importsFilteredColumns?.[0] && singleDraft?.columns) {
			setImportsFilteredColumns(
				singleDraft?.columns?.map((item) => ({
					...item,
					label: item?.name !== "" ? camelCaseToHumanReadable(item?.name) : "Matched",
					icon: <CheckIcon stroke="#252525" />,
					show: item.show,
				})),
			);
		}
		const ordersColumns = ordersData?.[0]
			? generateOrdersColumns(ordersData?.[0], ordersFilteredColumns, status)
			: [];
		// filter columns for right table
		const filteredOrdersColumns = ordersColumns.filter((item) => item?.show === true);
		if (orders?.[0]) {
			if (!ordersFilteredColumns?.[0]) {
				setOrdersFilteredColumns(
					ordersColumns.map((item) => ({
						...item,
						label: item?.name !== "" ? item?.name : "Matched",
						icon: <CheckIcon stroke="#252525" />,
					})),
				);
			}
		}
		const cols = generateColumns(
			[...filteredColumns],
			options,
			setShowDeleteModal,
			matchingField,
			filteredStatuses,
		);
		// these two states are for the right and left columns.
		setOrdersColumns(filteredOrdersColumns);
		setColumnsImport(cols);
	}, [
		orders.length,
		singleDraft?.data?.length,
		options.length,
		importsFilteredColumns,
		ordersFilteredColumns,
	]);

	useEffect(() => {
		const data = compareDataArrays(
			singleDraft?.data,
			orders,
			singleDraft?.matchingField,
			setOptions,
		);
		setOrdersData(data?.ordersData);
		setImportsData(data?.importsData);
	}, [
		orders?.length,
		singleDraft?.data?.length,
		importsFilteredColumns,
		ordersFilteredColumns,
		singleDraft,
	]);
	return (
		<>
			<Modal
				className="add_edit_user preview"
				open={showEditModal}
				handleClose={() => toggleEditOrderModal(false)}
				width={showTimeline ? 1000 : 600}
				positionModal="right"
				overflow="hidden"
				border={previewRow && "none"}
				padding={previewRow && 0}
				display={previewRow && "flex"}
			>
				<CreateEditOrder setShowEditModal={toggleEditOrderModal} previewMode={previewRow} />
			</Modal>
			<div className="dashboard__container">
				<ImportsHeader final ordersData={ordersData} />
				<div className="importsPage-tables">
					{status === "DRAFT" && (
						<>
							<div
								style={{
									backgroundColor: ordersLoading ? "#e1e1e1" : "#fff",
									width:
										expanded === "left"
											? "100%"
											: expanded === "right"
											? "0%"
											: "50%",
								}}
								className="importsPage-tables_left"
							>
								{expanded !== "right" && (
									<div className="importsPage-tables_left-title">
										{ordersLoading ? (
											<SkeletonInput
												active
												style={{
													width: "100%",
													borderRadius: 8,
												}}
											/>
										) : (
											<>
												<span>{t("ImportedContracts")}</span>
												<CustomPopover
													customClass="custom-popover-filters"
													placement="bottomLeft"
													menu={{items: itemsImports}}
													open={showPopoverFiltersImport}
													handleVisibility={handleVisibility}
													trigger={["click"]}
												>
													<div>
														<Button
															{...salesTrackerButtonProps}
															icon={importFiltersIcon}
															leftRounded={false}
															rightRounded={false}
															hideTooltip={true}
															smallButtonWithTooltip
														/>
													</div>
												</CustomPopover>
											</>
										)}
									</div>
								)}
								{ordersLoading ? (
									<div
										style={{
											overflow: "hidden",
											maxHeight: "calc(100% - 38px)",
										}}
									>
										<TableSkeleton noPadding />
									</div>
								) : (
									<DataGridTable
										stopPropagation
										className=""
										wrapperStyles={{
											width: "100%",
											height: "100%",
										}}
										getByID={false}
										rowClass={getRowClassName}
										data={importsData || []}
										columns={[
											getExpandRow(columnsImport, "children"),
											...columnsImport,
										]}
										rowHeight={({row}) =>
											row?.type === "DETAIL"
												? row?.children.length * 44 + 100
												: 44
										}
										onRowsChange={(rows, {indexes}) => {
											const updatedRows = [...rows];
											const row = updatedRows[indexes[0]];
											const detailRowIndex = updatedRows.findIndex(
												(r) =>
													r.type === "DETAIL" &&
													(r.parentId === row?.[matchingField] ||
														r.parentId === row?.oldContract),
											);
											if (row.children) {
												if (row.expanded) {
													if (detailRowIndex === -1) {
														const newDetailRow = {
															...row,
															type: "DETAIL",
															children: row?.children,
															parentId:
																row?.same && row?.oldContract
																	? row?.oldContract
																	: row?.[matchingField],
														};
														updatedRows.splice(
															indexes[0] + 1,
															0,
															newDetailRow,
														);
													}
												} else if (detailRowIndex !== -1) {
													updatedRows.splice(detailRowIndex, 1);
												}
												setImportsData(updatedRows);
											}
										}}
									/>
								)}
							</div>
							<ExpandTables setExpanded={setExpanded} expanded={expanded} />
						</>
					)}
					<div
						style={{
							backgroundColor: ordersLoading ? "#e1e1e1" : "#fff",
							width:
								expanded === "right" || status !== "DRAFT"
									? "100%"
									: expanded === "left"
									? "0%"
									: "50%",
						}}
						className="importsPage-tables_left"
					>
						{expanded !== "left" && (
							<div className="importsPage-tables_left-title">
								{ordersLoading ? (
									<SkeletonInput
										active
										style={{
											width: "100%",
											borderRadius: 8,
										}}
									/>
								) : (
									<>
										<span>{t("ActualContracts")}</span>
										<div className="importsPage-tables_left-filters">
											<CustomPopover
												customClass="custom-popover-filters"
												menu={{items: itemsOrders}}
												placement="bottomLeft"
												open={showPopoverFiltersOrders}
												handleVisibility={handleVisibilityOrders}
												trigger={["click"]}
											>
												<div>
													<Button
														{...salesTrackerButtonProps}
														icon={importFiltersIcon}
														leftRounded={false}
														rightRounded={false}
														hideTooltip={true}
														smallButtonWithTooltip
													/>
												</div>
											</CustomPopover>
											<BigSearchBar
												withFilters={false}
												value={searchQuery}
												handleSearch={(value) =>
													setSearchQuery(value?.target?.value)
												}
											/>
										</div>
									</>
								)}
							</div>
						)}
						{ordersLoading ? (
							<div
								style={{
									overflow: "hidden",
									maxHeight: "calc(100% - 38px)",
								}}
							>
								<TableSkeleton noPadding />
							</div>
						) : (
							<DataGridTable
								className=""
								wrapperStyles={{
									width: "100%",
									height: "100%",
								}}
								rowClass={
									status === "DRAFT" ? getRowClassName : getPublishedRowClassName
								}
								data={
									status !== "DRAFT" ? importOrderViewDetails : filteredOrdersData
								}
								columns={[...orderColumns, actions]}
							/>
						)}
					</div>
				</div>
			</div>
			<DeleteModal
				setShowDeleteModal={handleCloseModal}
				open={showDeleteModal.show}
				handleClose={handleCloseModal}
				confirm={handleConfirm}
				calculationModal
				newTitle={
					showDeleteModal?.item?.same && showDeleteModal?.item?.matchedBy !== "import"
						? t("AreYouSureUnMatch")
						: t("AreYouSureDelete")
				}
			/>
		</>
	);
};
const mapStateToProps = (state) => ({
	showEditModal: state.app.orders.edit.showModal,
	showTimeline: state.app.orders.edit.showTimeline,
	loggedUserId: state.app.users.index.loggedUserId,
	createImportsState: state.app.imports.create,
	statuses: state.app.orders.statuses.statuses,
});

const mapDispatchToProps = {
	toggleEditOrderModal: editOrderActions.toggleModal,
	getOrderPreview: orderActions.getOrderPreview,
	setImportsFilteredColumns: createImports.setImportsFilteredColumns,
	setOrdersData: createImports.setOrdersData,
	setImportsData: createImports.setImportsData,
	deleteImportsData: createImports.deleteImportsData,
	unMatchOrders: createImports.unMatchOrders,
	setOrdersFilteredColumns: createImports.setOrdersFilteredColumns,
	setUpdateRows: createImports.setUpdateRows,
	cleanState: createImports.cleanState,
	setColumnsImport: createImports.setColumnsImport,
	getOrders: createImports.getOrders,
	getDraftById: importActions.getDraftById,
	setOptions: importActions.setOptions,
	getOrderStatuses: orderStatusAction.getOrderStatuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersTables));
