/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Bar, Doughnut} from "react-chartjs-2";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders/index";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import Leaderboard from "@common/Leaderboard/Leaderboard";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {checkRoles} from "@src/utils/checkRoles";
import {Skeleton} from "antd";
import {optionsPercentageGraph, percentageGraphColors, optionsBarGraph} from "./staticData";
import OrderStats from "./OrderStats";

let tempArrLabels = [],
	tempArrDataset = [],
	obj = {
		label: "",
		data: [],
		backgroundColor: "",
		borderWidth: 1,
	};

function UserStats({
	userStats,
	query,
	billingDetails,
	loggedUser,
	leaderboardLoading,
	statsLoading,
}) {
	const {t} = useTranslation();
	const [dataBar, setDataBar] = useState({labels: [], datasets: []});
	const [colors, setColors] = useState({});
	const tempArr = dataBar?.datasets?.map((e) => e.data);
	const flattenedArray = [].concat(...tempArr);
	const maxValue = Math.max(...flattenedArray);
	const isOdd = maxValue % 2 !== 0;
	const optionsBarGraphLabels = {
		...optionsBarGraph,
		plugins: {
			...optionsBarGraph?.plugins,
			datalabels: {
				...optionsBarGraph?.plugins?.datalabels,
				color: colors?.AGENCY,
			},
		},
		scales: {
			...optionsBarGraph.scales,
			yAxes: {
				...optionsBarGraph.scales.yAxes,
				ticks: {
					...optionsBarGraph.scales.yAxes.ticks,
					stepSize: isOdd ? 20 : 15,
				},
			},
		},
	};
	const [showGraph, setShowGraph] = useState(true);
	if (!_.isEmpty(userStats)) {
		tempArrDataset = [];
		tempArrLabels = [];
		Object.keys(userStats?.percentageMap).map((item) => {
			tempArrLabels.push(t(item));
			tempArrDataset.push(userStats?.percentageMap[item]);
			return null;
		});
	}
	useEffect(() => {
		setColors({
			AGENCY: billingDetails?.primaryColor,
			EMPLOYEE: billingDetails?.secondaryColor,
			SP: billingDetails?.hoverColor,
		});
	}, [billingDetails]);
	useEffect(() => {
		const chartDates = [];
		const clientsData = [];
		const orderStats = [];
		const keyArr = [];
		const helpArr = [];
		if (userStats?.clientOrderDataList?.[0]) {
			// eslint-disable-next-line no-unused-expressions
			userStats?.clientOrderDataList?.map((item) => helpArr.push(item));
			for (let index = 0; index < helpArr.length; index++) {
				const element = helpArr[index];
				chartDates.push(element?.clientName);
				clientsData.push(element?.orderRole);
			}
			clientsData.map((client) => Object.keys(client)?.map((key) => keyArr.push(key)));
			const uniqueChars = [...new Set(keyArr)];
			// eslint-disable-next-line array-callback-return
			uniqueChars.filter((item) => {
				const keys = [];
				let label;
				const color = [];
				clientsData.map((cli) =>
					// eslint-disable-next-line array-callback-return
					Object.entries(cli)?.map(([key, value]) => {
						if (item === key) {
							label = t(item);
							color.push(colors?.[item]);
							keys.push(value);
						}
					}),
				);
				obj = {...obj, label, backgroundColor: color, data: keys};
				orderStats.push(obj);
				setDataBar({
					labels: chartDates,
					datasets: orderStats,
				});
				setShowGraph(true);
			});
		} else {
			setShowGraph(false);
		}
	}, [userStats.clientOrderDataList, colors]);
	const allZeros = tempArrDataset.every((element) => element === 0);
	const data = {
		labels: tempArrLabels,
		datasets: [
			{
				data: tempArrDataset,
				backgroundColor: Object.values(colors),
				borderColor: percentageGraphColors.borderColor,
				borderWidth: 3,
			},
		],
	};

	if (leaderboardLoading || statsLoading) {
		return (
			<div className="leaderboard-skeleton-wrapper">
				{Array.from({length: 2}).map((_, rowIndx) => (
					<div className={rowIndx === 0 ? "left" : "right"}>
						<Skeleton.Input
							active
							style={{
								height: 30,
								width: "100%",
								borderRadius: 8,
							}}
						/>
						<Skeleton.Input
							active
							style={{
								borderRadius: 8,
								width: "100%",
								height: 450,
							}}
						/>
					</div>
				))}
			</div>
		);
	}
	return (
		<div className="userstats-parent">
			{checkRoles("seeLeaderboard") ? (
				<>
					<div className="userstats-container--left">
						<Leaderboard />
					</div>
				</>
			) : (
				""
			)}
			{checkRoles("seeUsersStats") && (
				<div className="userstats-container">
					<div className="userstats-container--top">
						<OrderStats
							title={t("TotalOrders")}
							totalOrders={userStats?.totalOrders?.totalOrders}
							orders={userStats?.totalOrders?.totalOrdersWithoutCancellation}
							cancelled={userStats?.totalOrders?.totalCancelled}
							colors={colors}
						/>
						{userStats?.roleData &&
							Object.entries(userStats?.roleData)?.map((item) => {
								const [key, values] = item;
								return (
									<OrderStats
										key={key}
										title={t(key)}
										colors={colors}
										totalOrders={values?.totalOrders}
										orders={values?.totalOrdersWithoutCancellation}
										cancelled={values?.totalCancelled}
									/>
								);
							})}
					</div>
					<div className="userstats-container--bottom">
						<div className="userstats-middle">
							{!allZeros ? (
								<Doughnut
									data={data}
									options={optionsPercentageGraph}
									plugins={[ChartDataLabels]}
								/>
							) : (
								<div className="noDataMsg">{t("NoData")}</div>
							)}
						</div>
						<div className="userstats-right">
							{showGraph ? (
								<>
									<div className="statistics__information__text">
										<span className="statistics__information__text-first">
											{t("GraphByClients")}
										</span>
										<div className="statistics__information__text__selectedDate">
											<span>
												{Number.isNaN(query.fromDate)
													? ""
													: moment(query.fromDate).format("DD-MM-YYYY")}
											</span>
											-
											<span>
												{Number.isNaN(query.toDate)
													? ""
													: moment(query.toDate).format("DD-MM-YYYY")}
											</span>
										</div>
									</div>
									<Bar
										data={dataBar}
										options={optionsBarGraphLabels}
										plugins={[ChartDataLabels]}
									/>
								</>
							) : (
								<div className="noDataMsg">{t("NoData")}</div>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
const mapStateToProps = (state) => ({
	userStats: state.app.orders.index.userStats,
	query: state.app.orders.index.query,
	billingDetails: state.app.userProfile.settings.billingDetails,
	loggedUser: state.app.users.index.loggedUser,
});

const mapDispatchToProps = {
	getUserStats: orderActions.getUserStats,
	getLeaderboard: orderActions.getLeaderboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStats);
