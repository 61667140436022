import React from "react";
import BackButtonComponents from "@src/common/BackButton";
import i18n from "../../../../i18n";

const TrainingSteps = (props) => {
	const {readPages, navigateBack, length} = props;

	return (
		<div className="trainingTop">
			{/* <div className="trainingTop__backBtn" onClick={navigateBack}>
        {i18n.t('Back')}
      </div> */}
			<div className="back__button__order_pdf">
				<BackButtonComponents onClick={navigateBack} className="back__button_client" />
			</div>
			<div className="trainingTop__links">
				{i18n.t("Template")} / {i18n.t("Managment")}
			</div>
			<div className="trainingTop__stepsContent">
				<div className="trainingTop__stepsContent-title">
					<span>{readPages}</span>/<span>{length}</span> {i18n.t("Completed")}
				</div>
				<div className="trainingTop__stepsContent-steps">
					{Array.from({length}).map((itm, index) => {
						const condition = index + 1 <= readPages;
						return (
							<div
								key={index}
								className={`trainingTop__stepsContent-step ${
									condition ? "trainingTop__stepsContent-step--active" : ""
								}`}
							></div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default TrainingSteps;
