import React from "react";

const OrderViewTitle = ({icon, title, subtitle, titleClassName, subtitleClassName}) => (
	<div className="orderview__title">
		<div className="orderview__title--icon">
			<span>{icon}</span>
		</div>
		<div className="orderview__title--text">
			<span className={`orderview__title--text-title ${titleClassName}`}>{title}</span>
			<span className={`orderview__title--text-subtitle ${subtitleClassName}`}>
				{subtitle}
			</span>
		</div>
	</div>
);

export default OrderViewTitle;
