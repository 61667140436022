/* eslint-disable max-len */
import produce from "immer";
import {put, select, takeLatest, takeEvery} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {getCurrentUser} from "@utils/currentUser";
import {
	ToastComponent,
	ToastErrorComponent,
	ToastInfoStatus,
	ToastSuccesComponent,
} from "@src/common/ToastComponent/ToastComponent";
import {actions as orderActions} from "@sagas/orders";
import {actions as navigateActions} from "@sagas/navigation";
import moment from "moment";
import {getEndpointBasedOnVersion} from "@src/utils/getEndpointBasedOnVersion";
import {ENDPOINT} from "@src/utils/endpoint";
import {CONDITION_ENUMS_FOR_CALCULATIONS} from "@src/utils/constants";
import i18n from "../../../../i18n";
const logger = new Logger("Sagas>Calculations>Index");

const PREFIX = "@app/calculations/index";

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_CALCULATIONS = `${PREFIX}GET_CALCULATIONS`;
export const FETCH_CALCULATIONS_SUCCESS = `${PREFIX}FETCH_CALCULATIONS_SUCCESS`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const GET_CALCULATIONS_BY_ID = `${PREFIX}GET_CALCULATIONS_BY_ID`;
export const FETCH_CALCULATIONS_BY_ID_SUCCESS = `${PREFIX}FETCH_CALCULATIONS_BY_ID_SUCCESS`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const SET_STATUS = `${PREFIX}SET_STATUS`;
export const DELETE_CALCULATION = `${PREFIX}DELETE_CALCULATION`;
export const EDIT_TOTAL_PAGES = `${PREFIX}EDIT_TOTAL_PAGES`;
export const CLEAN_CALCULATION_BY_ID_STATE = `${PREFIX}CLEAN_CALCULATION_BY_ID_STATE`;
export const UPLOAD_CALCULATION_FILE = `${PREFIX}UPLOAD_CALCULATION_FILE`;
export const UPLOAD_CALCULATION_FILE_SUCCESS = `${PREFIX}UPLOAD_CALCULATION_FILE_SUCCESS`;
export const SET_EXCEL_DATA = `${PREFIX}SET_EXCEL_DATA`;
export const PARSE_CSV = `${PREFIX}PARSE_CSV`;
export const SET_CSV_DATA = `${PREFIX}SET_CSV_DATA`;
export const CLEAR_CSV_DATA = `${PREFIX}CLEAR_CSV_DATA`;
export const UPLOAD_LOADING = `${PREFIX}UPLOAD_LOADING`;
export const GET_USER_CALCULATIONS = `${PREFIX}GET_USER_CALCULATIONS`;
export const GET_USER_CALCULATIONS_SUCCESS = `${PREFIX}GET_USER_CALCULATIONS_SUCCESS`;
export const CREATE_SETTLEMENT_HEADOFSALES = `${PREFIX}CREATE_SETTLEMENT_HEADOFSALES`;
export const DELETE_MANUAL_CORRECTION_ON_STATE = `${PREFIX}DELETE_MANUAL_CORRECTION_ON_STATE`;

const currentUser = getCurrentUser();
const isAgencySp = currentUser?.role === "AGENCY-SP";

const _state = {
	calculations: [],
	singleCalculation: {},
	size: 30,
	page: 1,
	totalSize: 10,
	totalPages: 1,
	loading: false,
	uploadLoading: false,
	filters: {
		fromDate: null,
		toDate: null,
		search: "",
	},
	status: "SETTLED",
	file: {},
	csvData: [],
	excelData: {
		tabs: [],
		activeTab: {
			value: "",
			index: 0,
		},
	},
	userCalculations: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case EDIT_TOTAL_PAGES:
				draft.totalPages = action.payload;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;
				break;
			case FETCH_CALCULATIONS_SUCCESS:
				if (state.page !== 1) {
					draft.calculations = [...state?.calculations, ...action?.payload.data];
				} else {
					draft.calculations = isAgencySp ? action?.payload : action.payload.data;
				}
				break;
			case FETCH_CALCULATIONS_BY_ID_SUCCESS:
				const filteredData = action.payload?.data?.filter(
					(item) =>
						!(
							CONDITION_ENUMS_FOR_CALCULATIONS.includes(item.tariffName) &&
							item.provision === 0
						),
				);
				draft.singleCalculation = {
					...action.payload,
					data: filteredData,
				};
				break;
			case CLEAN_CALCULATION_BY_ID_STATE:
				draft.singleCalculation = {};
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.filters[action.payload?.name] = action.payload.value;
				break;
			case SET_STATUS:
				draft.status = action.payload;
				break;

			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SET_EXCEL_DATA:
				draft.excelData = action.payload;
				break;
			case SET_CSV_DATA:
				draft.csvData = [...state?.csvData, action.payload];
				break;
			case UPLOAD_CALCULATION_FILE_SUCCESS:
				draft.file = action.payload;
				break;
			case CLEAR_CSV_DATA:
				draft.csvData = [];
				break;
			case UPLOAD_LOADING:
				draft.uploadLoading = action.payload;
				break;
			case GET_USER_CALCULATIONS_SUCCESS:
				draft.userCalculations = action.payload;
				break;
			case DELETE_MANUAL_CORRECTION_ON_STATE:
				const filteredArray = state.singleCalculation.data.filter(
					(item) => item.manualCorrectionId !== action.payload,
				);
				draft.singleCalculation = {
					...state.singleCalculation,
					data: filteredArray,
				};
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editTotalPage: (payload) => createAction(EDIT_TOTAL_PAGES, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	setStatus: (payload) => createAction(SET_STATUS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	getCalculations: (payload) => createAction(GET_CALCULATIONS, {payload}),
	fetchCalculationsSuccess: (payload) => createAction(FETCH_CALCULATIONS_SUCCESS, {payload}),
	filterCalculations: (payload) => createAction(FILTERS, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	getCalculationById: (payload) => createAction(GET_CALCULATIONS_BY_ID, {payload}),
	fetchCalculationsByIdSuccess: (payload) =>
		createAction(FETCH_CALCULATIONS_BY_ID_SUCCESS, {payload}),
	deleteCalculation: (payload) => createAction(DELETE_CALCULATION, {payload}),
	cleanSettlementState: (payload) => createAction(CLEAN_CALCULATION_BY_ID_STATE, {payload}),
	uploadCalculationFile: (payload) => createAction(UPLOAD_CALCULATION_FILE, {payload}),
	uploadCalculationFileSuccess: (payload) =>
		createAction(UPLOAD_CALCULATION_FILE_SUCCESS, {payload}),
	setExcelData: (payload) => createAction(SET_EXCEL_DATA, {payload}),
	setCsvData: (payload) => createAction(SET_CSV_DATA, {payload}),
	parseCsv: (payload) => createAction(PARSE_CSV, {payload}),
	clearCsvData: (payload) => createAction(CLEAR_CSV_DATA, {payload}),
	setUploadLoading: (payload) => createAction(UPLOAD_LOADING, {payload}),
	getUserCalculations: (payload) => createAction(GET_USER_CALCULATIONS, {payload}),
	getUserCalculationsSuccess: (payload) => createAction(GET_USER_CALCULATIONS_SUCCESS, {payload}),
	createSettlementForHeadOfSales: (payload) =>
		createAction(CREATE_SETTLEMENT_HEADOFSALES, {payload}),
	deleteManualCorrectionOnState: (payload) =>
		createAction(DELETE_MANUAL_CORRECTION_ON_STATE, {payload}),
};

export const sagas = {
	*getCalculations({payload}) {
		yield put(actions.setLoading(true));
		const {page, size} = yield select((state) => state.app.calculations.index);
		let response;
		try {
			const {billingMonthId, search, clientPayoutModel} = payload;
			const newEndpoint = isAgencySp
				? ENDPOINT.GET_SP_CALCULATIONS
				: ENDPOINT.GET_CALCULATIONS.replace(":page", page)
						.replace(":size", size)
						.replace(":search", search || "")
						.replace(":billingMonthId", billingMonthId)
						.replace(":clientPayoutModel", clientPayoutModel || null);
			if (isAgencySp) {
				response = yield axios.post(newEndpoint, {
					spId: currentUser?.id,
					fromDate: moment().startOf("year").valueOf(),
					toDate: moment().endOf("year").valueOf(),
				});
			} else {
				response = yield axios.get(newEndpoint);
			}
			yield put(actions.fetchCalculationsSuccess(response?.data?.data));
			yield put(actions.editTotalSize(response?.data?.data?.totalSize));
			yield put(actions.editTotalPage(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getCalculationById({payload}) {
		yield put(actions.setLoading(true));
		const {filters} = yield select((state) => state.app.calculations.index);
		// UNCOMMENT AND CHANGE REQUEST WHEN PAGINATION FIXED
		// const {page} = yield select((state) => state.app.calculations.index);
		// const {size} = yield select((state) => state.app.calculations.index);
		try {
			const {endpoint} = getEndpointBasedOnVersion(
				"Change_order_status_v2",
				ENDPOINT.GET_CALCULATION_BY_ID,
			);
			const response = yield axios.post(endpoint, {
				userId: +payload?.userId,
				uuid: payload?.uuid,
				search: filters.search,
				fromDate: filters?.fromDate,
				toDate: filters?.toDate,
			});
			yield put(actions.fetchCalculationsByIdSuccess(response?.data?.data));
			yield put(actions.editTotalSize(response?.data?.data?.totalSize));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteCalculation({payload}) {
		try {
			const calcType = payload?.calcType === 0 ? null : payload?.calcType;
			const clientPayoutModel = payload?.clientPayoutModel || null;
			const {endpoint} = getEndpointBasedOnVersion(
				"Change_order_status_v2",
				`/order/revertStatus/${payload?.uuid}?calcType=${calcType}&clientPayoutModel=${clientPayoutModel}`,
			);
			const response = yield axios.post(endpoint);
			if (response?.data?.status === 200) {
				if (response?.data?.data === "PROCESSING") {
					ToastComponent(
						`${i18n.t("StatusChangePending")}`,
						"warning",
						orderActions,
						"statusHistory",
					);
				} else {
					yield put(
						actions.getCalculations({billingMonthId: payload?.billingMonthId || null}),
					);
					ToastInfoStatus(
						`${i18n.t("StatusChangeSuccess")}`,
						orderActions,
						navigateActions,
					);
				}
				payload.setSelectedRows({all: false, ids: []});
			}
		} catch (error) {
			logger.error(error);
		}
	},
	*uploadCalculationFile({payload}) {
		yield put(actions.setUploadLoading(true));
		try {
			const response = yield axios.post("/order/file/upload/settlement", {
				file: payload?.base64,
				fileName: payload?.fileName,
				settledUuid: payload?.uuid,
			});
			yield put(actions.uploadCalculationFileSuccess(response?.data?.data));
			yield put(
				navigateActions.navigate(
					`/admin/calculations-file/${payload?.uuid}/import/${payload?.userId}`,
				),
			);
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setUploadLoading(false));
		}
	},
	*parseCsv({payload}) {
		try {
			const formData = new FormData();
			formData.append("csv", payload.csv, payload?.fileName);
			const response = yield axios.post(`/csv/parse`, formData);
			yield put(actions.setCsvData(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getUserCalculations({payload}) {
		try {
			const {userId, billingMonthId} = payload;
			const response = yield axios.get(`/v2/billing-months-settlements/by-user`, {
				params: {
					billingMonthId,
					userId,
				},
			});
			yield put(actions.getUserCalculationsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*createSettlementForHeadOfSales({payload}) {
		try {
			yield axios.post(ENDPOINT.CREATE_SETTLEMENT_HEADOFSALES, payload);
			ToastSuccesComponent(i18n.t("SettlementCreateSuccess"));
		} catch (error) {
			if (error?.response?.status === 409) {
				ToastErrorComponent(error?.response?.data?.message);
			} else {
				ToastErrorComponent(i18n.t("SettlementCreateError"));
			}
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_CALCULATIONS, sagas.getCalculations);
	yield takeLatest(GET_CALCULATIONS_BY_ID, sagas.getCalculationById);
	yield takeLatest(DELETE_CALCULATION, sagas.deleteCalculation);
	yield takeLatest(UPLOAD_CALCULATION_FILE, sagas.uploadCalculationFile);
	yield takeEvery(PARSE_CSV, sagas.parseCsv);
	yield takeLatest(GET_USER_CALCULATIONS, sagas.getUserCalculations);
	yield takeLatest(CREATE_SETTLEMENT_HEADOFSALES, sagas.createSettlementForHeadOfSales);
};
