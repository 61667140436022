import {actions as orderActions} from "@sagas/orders/index";

// eslint-disable-next-line import/no-cycle
import {getStore} from "../../store";
class StatusHistoryEntity {
	CREATE = (data) => {
		const {dispatch} = getStore();
		dispatch(orderActions.updateStatusesOnState(data));
	};

	COMPLETE = (data) => {
		const {dispatch} = getStore();
		dispatch(orderActions.updateStatusesOnStateFINISHED(data));
	};
}

export default new StatusHistoryEntity();
