import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
	HasWriteAccessIcon,
	DoesntHaveWriteAccessIcon,
	CloseUploadModalIcon,
	PlusIcon,
} from "@assets/SvgComponents";
import ModalComponent from "@common/modal";
import ButtonComponent from "@common/button";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as messagesActions} from "@sagas/messages";
import {actions as createMessagesActions} from "@sagas/messages/create";

const ParticipantsComponent = ({
	setShowAddUserToChannelModal,
	loggedUser,
	isOwner,
	owner,
	handleRemoveUser,
	setWriteAccess,
	insideTopic,
}) => {
	const {topicMembers, topicType} = insideTopic;
	const [deleteModal, setDeleteModal] = useState(false);
	const [accessModal, setAccesModal] = useState(false);
	const [userToDelete, setUserToDelete] = useState(null);
	const [userToChangeAccess, setUserToChangeAccess] = useState(null);
	const {t} = useTranslation();
	const handleDelete = () => {
		handleRemoveUser(userToDelete);
		setDeleteModal(false);
	};
	const handleAccess = () => {
		setWriteAccess({
			topicId: insideTopic?.topicId,
			userId: userToChangeAccess.id,
			writeAccess: userToChangeAccess.writeAccess,
		});
		setAccesModal(false);
	};
	return (
		<>
			<ModalComponent open={deleteModal} handleClose={() => setDeleteModal(false)}>
				<div className="delete_user_modal">
					<p className="delete_user_modal-title">{t("AreYouSureToRemoveFromChannel")}</p>
					<div className="actions">
						<ButtonComponent
							onClick={() => setDeleteModal(false)}
							buttonText={t("No")}
							newClass="go_back-btn"
						/>
						<ButtonComponent onClick={handleDelete} buttonText={t("Yes")} />
					</div>
				</div>
			</ModalComponent>
			<ModalComponent open={accessModal} handleClose={() => setAccesModal(false)}>
				<div className="delete_user_modal">
					<p className="delete_user_modal-title">
						{userToChangeAccess?.writeAccess
							? t("AreYouSureToDenyWrite")
							: t("AreYouSureToAllowWrite")}
					</p>
					<div className="actions">
						<ButtonComponent
							onClick={() => setAccesModal(false)}
							buttonText={t("No")}
							newClass="go_back-btn"
						/>
						<ButtonComponent onClick={handleAccess} buttonText={t("Yes")} />
					</div>
				</div>
			</ModalComponent>
			<div className="channel_participiants">
				<div className="channel_participiants-title">
					<p>{t("Participants")}</p>
				</div>
				<div className="channel_participiants-admins">
					<div className="top_part">
						<p className="chat_admins-top">{t("ChatAdmins")}(1)</p>
						{/* {isOwner && <span className="actions-top">{t("Add")}</span>} */}
					</div>
					<div className="bottom_part">
						<p className="chat_admins-bottom">{owner?.name}</p>
						{/* {isOwner && <span className="actions-bottom">X</span>} */}
					</div>
				</div>
				<div className="channel_participiants-users">
					<div className="top_part">
						<p className="chat_users-top">
							{t("Participants")} ({topicMembers?.length})
						</p>
						{isOwner && topicType !== "PRIVATE" && (
							<span
								className="actions-top"
								onClick={() => setShowAddUserToChannelModal(true)}
							>
								<PlusIcon />
							</span>
						)}
					</div>
					<div className="bottom_part">
						{topicMembers?.map((member, index) => (
							<div className="bottom_part-wrapper" key={index}>
								<p className="chat_users-bottom">
									{member?.name || `${member?.firstName} ${member?.lastName}`}
									{member?.deactivatedAt !== 0 && (
										<span className="deactivated">{` - ${t(
											"Deactivated",
										)}`}</span>
									)}
								</p>

								{isOwner && member?.id !== loggedUser.id && (
									<div className="bottom_part-wrapper-actions">
										<span
											className="actions-bottom"
											onClick={() => {
												setAccesModal(true);
												setUserToChangeAccess(member);
											}}
										>
											{member?.writeAccess ? (
												<span>
													<HasWriteAccessIcon />
												</span>
											) : (
												<DoesntHaveWriteAccessIcon />
											)}
										</span>
										<span
											className="actions-bottom"
											onClick={() => {
												setDeleteModal(true);
												setUserToDelete(member?.id);
											}}
										>
											<CloseUploadModalIcon
												fill="#979797"
												width="17"
												height="13"
											/>
										</span>
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
	insideTopic: state.app.messages.index.insideTopic,
});

const mapDispatchToProps = {
	setWriteAccess: messagesActions.setWriteAccess,
	setShowAddUserToChannelModal: createMessagesActions.setShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParticipantsComponent));
