import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ENDPOINT} from "@src/utils/endpoint";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import {actions as structuresActions} from "./index";

const logger = new Logger("Structure create");
const PREFIX = "@app/structures/create";

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const CREATE_STRUCTURE_TABLE = `${PREFIX}CREATE_STRUCTURE_TABLE`;
export const CREATE_STUFE = `${PREFIX}CREATE_STUFE`;

const _state = {
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	createStructureTable: (payload) => createAction(CREATE_STRUCTURE_TABLE, {payload}),
	createStufe: (payload) => createAction(CREATE_STUFE, {payload}),
};

export const sagas = {
	*createStructureTable({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {values, setModal} = payload;
			if (!values?.id) {
				const response = yield axios.post(ENDPOINT.CREATE_STRUCTURE, values);
				yield put(
					structuresActions.addTableOnState({
						...values,
						id: response?.data?.data,
						viewStufeList: [],
					}),
				);
			} else {
				yield axios.put(ENDPOINT.UPDATE_STRUCTURE.replace(":id", values?.id), values);
				yield put(structuresActions.updateStructureOnState(values));
			}
			ToastSuccesComponent(
				!values.id ? i18n.t("StructureCreatedSuccess") : i18n.t("StructureEditedSuccess"),
			);
			setModal(false);
		} catch (error) {
			ToastErrorComponent(
				!payload?.values?.id ? i18n.t("StructureCreateFail") : i18n.t("StructureEditFail"),
			);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*createStufe({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {values, setModal} = payload;
			if (!values.id) {
				const response = yield axios.post(ENDPOINT.CREATE_STUFE, values);
				yield put(structuresActions.addStufeOnState(response?.data?.data));
			} else {
				const response = yield axios.put(ENDPOINT.UPDATE_STUFE, values);
				yield put(structuresActions.updateStuffeOnState(response?.data?.data));
			}
			ToastSuccesComponent(
				!values.id ? i18n.t("StuffeCreatedSuccess") : i18n.t("StuffeEditedSuccess"),
			);
			setModal(false);
		} catch (error) {
			if (error?.response?.status === 409) {
				ToastErrorComponent(error.response.data.message);
			}
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_STRUCTURE_TABLE, sagas.createStructureTable);
	yield takeLatest(CREATE_STUFE, sagas.createStufe);
};
