import NewPassword from "@components/auth/components/NewPassword";
import CreateTariff from "@components/dashboard/CreateTariff";
import PrivacyPolicy from "@components/PrivacyPolicy/index";
import ContactlessSignature from "@src/components/dashboard/ContactlessSignature";
import PdfViewerForMobile from "../dashboard/calculations/PdfViewer";
import Auth from "../auth";
import Logout from "../auth/components/logout";
import Home from "../home";
import PinValidation from "../dashboard/PinValidation";
import GenerateTokenComponent from "../dashboard/PinValidation/components/generateTokenComponent";

export const AUTH_ROUTES = [
	{
		path: "/auth/new-password/:token",
		component: NewPassword,
	},
	{
		path: "/auth/:toggle",
		component: Auth,
	},
	{
		path: "/pinvalidation/generateToken/:orderId",
		component: GenerateTokenComponent,
	},
	{
		path: "/pinvalidation/:space/:token/:tokenPin",
		component: PinValidation,
	},
	{
		path: "/pinvalidation/:token/:tokenPin",
		component: PinValidation,
	},
	{
		path: "/order/contactless",
		component: ContactlessSignature,
	},
	{
		path: "/logout",
		component: Logout,
	},
	{
		path: "/admin/clients/:id/:clientId/edit/tariff/:tariffId",
		component: CreateTariff,
	},
	{
		path: "/admin/clients/:id/:clientId/create/tariff",
		component: CreateTariff,
	},
	{
		path: "/privacy-policy/:lang",
		component: PrivacyPolicy,
	},
	{
		path: "/pdf-mobile/:settlementId/:token",
		component: PdfViewerForMobile,
	},
	{
		path: "/",
		component: Home,
	},
];
