import React from "react";
import {ModalCloseIcon} from "@src/assets/SvgComponents";
import {Modal} from "antd";

const ModalComponent = ({open, handleClose, width = 500, title, children, customClasss = ""}) => (
	<Modal
		open={open}
		className={`custom__modal ${customClasss}`}
		footer={null}
		onCancel={handleClose}
		width={width}
		title={title}
		closable
		closeIcon={<ModalCloseIcon />}
	>
		{children}
	</Modal>
);
export default ModalComponent;
