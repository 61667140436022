import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";

const ActivityBox = (props) => {
	const {customClass = "", Icon, iconProps = {}, email, sendAt, sender, title} = props;
	const {t} = useTranslation();
	return (
		<div className={`activity__box__container ${customClass}`}>
			<div className="activity__box__container-icon">
				<Icon {...iconProps} />
			</div>
			<div className="activity__box__container--content">
				<p className="activity__box__container--content-top">
					{title} <b>{email}</b>
				</p>
				<p className="activity__box__container--content-middle">
					{t("from")}: <span>{sender}</span>
				</p>
				<p className="activity__box__container--content-bottom">
					{t("sent")} <span>{moment(sendAt).format("DD/MM/YYYY HH:mmA")}</span>
				</p>
			</div>
		</div>
	);
};

export default ActivityBox;
