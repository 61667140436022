import {CheckedIcon, CommentsIcon, DeleteStatusIcon} from "@assets/SvgComponents";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import {getCurrentUser} from "@utils/currentUser";
import React from "react";
import {connect} from "react-redux";
import {Checkbox} from "@material-ui/core";
import {checkRoles} from "@src/utils/checkRoles";
import {useUrlParams} from "@src/utils/useUrlParams";
import LeadUsers from "./LeadUsers";
const Box = ({
	handleModal,
	item,
	boxBackground,
	deleteLeads,
	setSelectedRows,
	selectedRows,
	statusType,
	loading,
	setSelectedStatuses,
	selectedStatuses,
	statusId,
	totalSize,
	leads,
	setShowModal,
	hasActions = true,
	boxClassName,
}) => {
	const {getUrlParams} = useUrlParams();
	const {modal, id: deleteLeadId, query} = getUrlParams("modal", "id", "query");
	if (!item) {
		return null;
	}
	const {
		firstName,
		lastName,
		viewAddress,
		id,
		leadActivityCount,
		weGrouping,
		generateLeadId,
		userDetails,
		statusOne,
		statusTwo,
	} = item;
	const {street, houseNumber, zipCode, location, addition, partLocation} = viewAddress;
	const {ids, totalSize: selectedSize} = selectedStatuses;
	const currentUser = getCurrentUser();
	const handleSelect = (e) => {
		e.stopPropagation();
		const isChecked = e.target.checked;
		if (isChecked) {
			const allRows = [...selectedRows, item];
			// checking if all fetched leads are selected
			const areAllLeadsSelected = leads?.every((item) =>
				allRows?.some((row) => item?.id === row?.id),
			);
			// checking if there're more leads , if false add this status to selectedStatuses
			if (areAllLeadsSelected && leads?.length === totalSize) {
				setSelectedStatuses({
					ids: [...ids, statusId],
					totalSize: selectedSize + totalSize,
				});
			}
			setSelectedRows((prevSelectedRows) => [...prevSelectedRows, item]);
		} else {
			const filteredStatuses = ids?.filter((it) => it !== statusId);
			let newTotalSize = selectedSize;
			if (ids.includes(statusId)) {
				newTotalSize = selectedSize - totalSize;
			}

			setSelectedStatuses({ids: filteredStatuses, totalSize: newTotalSize});
			setSelectedRows((prevSelectedRows) =>
				prevSelectedRows.filter((rowId) => rowId.id !== id),
			);
		}
	};
	const handleDeleteLeads = () => {
		deleteLeads({ids: [item], currentUser: currentUser.id, query});
		setSelectedRows([]);
	};
	return (
		<>
			<DeleteModal
				setShowDeleteModal={setShowModal}
				open={modal === "deleteLead" && deleteLeadId === id}
				disabled={loading}
				handleClose={() => setShowModal(false)}
				confirm={handleDeleteLeads}
				modalType="permanantDelete"
			/>
			<div
				className={`sales_tracker_box ${boxClassName}`}
				style={{backgroundColor: `${boxBackground}55`}}
				onClick={() => handleModal(id)}
			>
				{hasActions && (
					<div className="sales_tracker_box-checkbox">
						<Checkbox
							type="checkbox"
							name="selectAll"
							id="selectAll"
							onClick={(e) => handleSelect(e)}
							checked={selectedRows?.includes(item)}
							checkedIcon={<CheckedIcon />}
						/>
					</div>
				)}
				<div className="sales_tracker_box-content">
					<div className="sales_tracker_box-header">
						<p className="user__name">
							{firstName || lastName
								? `${firstName || ""} ${lastName || ""}`
								: generateLeadId}
						</p>
						{statusType !== "SUCCESS" && checkRoles("deleteLeads") && hasActions && (
							<div
								className="sales_tracker_box-header-icon"
								onClick={(e) => {
									e.stopPropagation();
									setShowModal("deleteLead", id);
								}}
							>
								<DeleteStatusIcon />
							</div>
						)}
					</div>
					<span className="user__adress">
						{street} Nr.{houseNumber}
						{addition} {zipCode} {partLocation}, {location}
					</span>
					{(statusOne || statusTwo) && (
						<div className="statuses">
							<span>{statusOne && statusOne}</span>
							<span>{statusTwo && statusTwo}</span>
						</div>
					)}
					<div className="sales_tracker_box-footer">
						<LeadUsers userDetails={userDetails} backgroundColor={boxBackground} />
						<div className="sales_tracker_icons">
							{leadActivityCount > 0 && (
								<div className="sales_tracker_icons-comments">
									<CommentsIcon />
									<span className="activity_count">{leadActivityCount}</span>
								</div>
							)}
							{weGrouping && (
								<div className="lead__potential">
									<span>{weGrouping}</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	loading: state.app.salesTracker.delete.loading,
});
export default connect(mapStateToProps, null)(Box);
