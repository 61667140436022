// eslint-disable-next-line max-len
import {categoryElements} from "@components/dashboard/clients/Client/components/projectCategories/categoryElements";
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import Modal from "@common/modal";
import {actions as clientTariff} from "@sagas/tariff/clientTariff";
import {actions as createClientTariff} from "@sagas/tariff/createClientTariff";
import {actions as deleteClientTariff} from "@sagas/tariff/deleteClientTariff";
import {actions as deletedTariffActions} from "@sagas/tariff/deleted";
import {actions as restoreTariffActions} from "@sagas/tariff/restore";
import TariffForm from "@components/dashboard/clients/Client/components/tariffs/AddEditForm";
import {useTranslation} from "react-i18next";
import {actions as navigateActions} from "@sagas/navigation";
import ArrowUp from "@assets/img/arrow_upward.svg";
import ArrowDown from "@assets/img/arrow_downward.svg";
import {checkRoles} from "@utils/checkRoles";
import {
	DeleteRowIcon,
	EditRowIcon,
	PreviewRowIcon,
	RestoreRowIcon,
} from "@src/assets/SvgComponents";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import {useUrlParams} from "@src/utils/useUrlParams";
import tariffElements from "./tariffElements";

const Tariffs = (props) => {
	const {
		match,
		getTarrifs,
		tariffs,
		deleteTariff,
		editSize,
		size,
		search,
		totalSize,
		page,
		editPage,
		modals,
		showModal,
		tariffClientValues,
		loading,
		addTariff,
		addProducts,
		fetchDeleted,
		deletedTariffData,
		editPageDeleted,
		editSizeDeleted,
		restoreTariff,
		onInputChange,
		navigate,
		fetchLoading,
		changeTariffOrder,
		deactivateTariff,
		totalPages,
		toggleTariffVisibility,
		deleteLoading,
		singleTariff,
		loggedUser,
	} = props;
	const {
		setShowModal,
		closeToggleModal,
		openToggleModal,
		currentTab,
		setCurrentTab,
		openFieldsModal,
	} = modals;
	const isAdmin = loggedUser?.role?.name === "ADMIN";
	const {getUrlParams, setModal} = useUrlParams();
	const {modal, id} = getUrlParams("modal", "id");
	const handleTariffModal = (type, id) => setModal(type, id);
	const {clientId, id: client} = match.params;
	const [reEditPage, setReEditPage] = useState(true);

	const formData = tariffElements;

	const {t} = useTranslation();

	const changeTrfOrder = (data) => {
		const itemIndex = tariffs.findIndex((item) => item?.id === data?.id);
		if (
			(itemIndex !== 0 && data?.action === "up") ||
			(itemIndex + 1 < tariffs?.length && data?.action === "down")
		) {
			changeTariffOrder({
				id: data?.id,
				newRow:
					data?.action === "up" ? tariffs[itemIndex - 1]?.nr : tariffs[itemIndex + 1]?.nr,
				action: data?.action,
			});
		}
	};
	const actionsFormatter = (row) => (
		<div
			className="table-actions-wrapper"
			style={{width: isAdmin ? "200px" : "180px", paddingRight: "20px"}}
		>
			{isAdmin && (
				<HtmlTooltip title={<span>Generate fields</span>} placement="bottom-end">
					<span
						className={`preview__row ${!row?.isHidden && "hide"}`}
						onClick={() => openFieldsModal(row, "tariff")}
					>
						<PreviewRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deactivateTariff") && (
				<HtmlTooltip
					title={<span>{!row?.isHidden ? t("SecondShow") : t("Hide")}</span>}
					placement="bottom-end"
					onClick={() => openToggleModal(row?.id)}
				>
					<span className={`preview__row ${!row?.isHidden && "hide"}`}>
						<PreviewRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderUpTariff") && (
				<HtmlTooltip title={<span>{t("Up")}</span>} placement="bottom-end">
					<img
						src={ArrowUp}
						alt=""
						style={{pointerEvents: tariffs?.[0]?.id === row?.id ? "none" : ""}}
						onClick={() => changeTrfOrder({id: row?.id, action: "up"})}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderDownTariff") && (
				<HtmlTooltip title={<span>{t("Down")}</span>} placement="bottom-end">
					<img
						src={ArrowDown}
						alt=""
						onClick={() => changeTrfOrder({id: row?.id, action: "down"})}
						style={{
							pointerEvents:
								tariffs?.[tariffs?.length - 1]?.id === row?.id ? "none" : "",
						}}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("editTariff") && (
				<HtmlTooltip title={<span>{t("EditTariff")}</span>} placement="bottom-end">
					<span
						onClick={() =>
							navigate(`/admin/clients/${clientId}/${client}/edit/tariff/${row.id}`)
						}
					>
						<EditRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deactivateTariff") && (
				<HtmlTooltip title={<span>{t("DeactivateTariff")}</span>} placement="bottom-end">
					<span onClick={() => handleTariffModal("deactivate", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actions = {
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: isAdmin ? 220 : 180,
		formatter: ({row}) => actionsFormatter(row),
		headerCellClass: "headerStyles",
	};
	const actionsDeletedFormatter = (row) => (
		<div className="table-actions-wrapper">
			{checkRoles("activateTariff") && (
				<HtmlTooltip title={<span>{t("ActivateTariff")}</span>} placement="bottom-end">
					<span onClick={() => handleTariffModal("restore", row?.id)}>
						<RestoreRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deleteTariff") && (
				<HtmlTooltip
					title={<span>{t("PermanentDeleteTariff")}</span>}
					placement="bottom-end"
				>
					<span onClick={() => handleTariffModal("delete", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actionsDeleted = {
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: 50,
		formatter: ({row}) => actionsDeletedFormatter(row),
		headerCellClass: "headerStyles",
	};
	const cancellationType = [
		{
			name: t("percentage"),
			value: "PERCENTAGE",
		},
		{
			name: t("cValue"),
			value: "VALUE",
		},
	];
	useEffect(() => {
		if (currentTab === "active") {
			getTarrifs({clientId, search});
		}
	}, [page, size, search, currentTab]);

	useEffect(() => {
		if (currentTab === "inactive") {
			fetchDeleted({clientId, search});
		}
	}, [deletedTariffData?.size, deletedTariffData?.page, search, currentTab]);
	const tableProps = {
		active: {
			data: tariffs,
			editPage,
			editSize,
			size,
			page,
			totalSize,
			totalPages,
			loading: fetchLoading,
			columns: [...tariffElements?.columns, actions],
		},
		inactive: {
			data: deletedTariffData?.list,
			columns: [
				...categoryElements?.columns?.filter((column) => column?.key !== "expander"),
				actionsDeleted,
			],
			editPage: editPageDeleted,
			editSize: editSizeDeleted,
			size: deletedTariffData?.size,
			totalSize: deletedTariffData?.totalSize,
			totalPages: deletedTariffData?.totalPages,
			loading: deletedTariffData?.loading,
			page: deletedTariffData?.page,
		},
	};
	const modalConfig = {
		deactivate: {
			confirm: () => deactivateTariff({id, client: clientId, handleTariffModal}),
			type: "deactivate",
			word: t("ThisTariff"),
		},
		restore: {
			confirm: () => restoreTariff({id, handleTariffModal}),
			type: "restore",
			word: t("ThisTariff"),
			modalType: "activate",
		},
		delete: {
			confirm: () => deleteTariff({id, client: clientId, handleTariffModal}),
			type: "delete",
			word: t("ThisTariff"),
			modalType: "permanantDelete",
		},
		visibility: {
			confirm: () =>
				toggleTariffVisibility({
					id: singleTariff?.id,
					closeModal: closeToggleModal,
					isHidden: !singleTariff?.isHidden,
				}),
			type: "visibility",
			newTitle: `${t(`AreYouSureTo${singleTariff?.isHidden ? "Show" : "Hide"}`)} "${
				singleTariff?.name
			}"?`,
			disabled: deleteLoading,
			calculationModal: true,
		},
	};
	const activeModal = modal ? modalConfig[modal] : null;
	return (
		<>
			{activeModal && (
				<DeleteModal
					calculationModal={activeModal?.calculationModal}
					setShowDeleteModal={() => handleTariffModal(false)}
					confirm={activeModal.confirm}
					newTitle={activeModal?.newTitle}
					open={activeModal.type === modal}
					handleClose={() => handleTariffModal(false)}
					disabled={activeModal.disabled}
					modalType={activeModal.modalType}
					word={activeModal.word}
				/>
			)}
			<Modal className="add_edit_user" open={showModal} handleClose={() => setShowModal()}>
				<TariffForm
					elements={formData}
					loading={loading}
					initialValues={tariffClientValues}
					setShowModal={setShowModal}
					handleSubmit={addTariff}
					clientId={clientId}
					options={{
						cancellationReserveType: cancellationType,
					}}
					rerenderForm={false}
					addProducts={addProducts}
					onInputChange={onInputChange}
				/>
			</Modal>
			<div className="loader__and__table">
				<DataGridTable
					{...tableProps[currentTab]}
					reEditPage={reEditPage}
					setReEditPage={setReEditPage}
					withTabs
					onTabClick={setCurrentTab}
					currentTab={currentTab}
					pagination
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	totalSize: state.app.tariffs.clientTariff.totalSize,
	totalPages: state.app.tariffs.clientTariff.totalPages,
	size: state.app.tariffs.clientTariff.size,
	page: state.app.tariffs.clientTariff.page,
	fetchLoading: state.app.tariffs.clientTariff.loading,
	showModal: state.app.tariffs.createClientTariff.showModal,
	deleteLoading: state.app.tariffs.deleteClientTariff.loading,
	tariffs: state.app.tariffs.clientTariff.clientTariffs,
	tariffClientValues: state.app.tariffs.createClientTariff.tariffaValues,
	loading: state.app.tariffs.createClientTariff.loading,
	deletedTariffData: state.app.tariffs.deleted,
	singleTariff: state.app.tariffs.clientTariff.singleTariff,
	loggedUser: state.app.users.index.loggedUser,
});

const mapDispatchToProps = {
	filterTariffs: clientTariff.filterTariffs,
	getTarrifs: clientTariff.fetchClientTariffs,
	editPage: clientTariff.editPage,
	editSize: clientTariff.editSize,
	deleteTariff: deleteClientTariff.deleteTariff,
	deactivateTariff: deleteClientTariff.deactivateTariff,
	addTariff: createClientTariff.addTariffa,
	addProducts: createClientTariff.addProducts,
	onInputChange: createClientTariff.onInputChange,
	fetchDeleted: deletedTariffActions.fetch,
	editPageDeleted: deletedTariffActions.editPage,
	editSizeDeleted: deletedTariffActions.editSize,
	restoreTariff: restoreTariffActions.restore,
	navigate: navigateActions.navigate,
	changeTariffOrder: clientTariff.changeTariffOrder,
	toggleTariffVisibility: clientTariff.toggleTariffVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tariffs));
