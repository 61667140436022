/* eslint-disable */
import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import embed from 'embed-video'

const DraftText = (props) => {
  const {
    value,
    onEditorStateChange,
    errorClass,
    errors,
    touched,
    options = [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'image',
      'remove',
      'history',
    ],
  } = props

  const [uploadImg, setUploadImg] = useState([])

  const getFileBase64 = (file, callback) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => callback(reader.result)
    reader.onerror = (error) => {}
  }

  const _uploadImageCallBack = (file) =>
    new Promise((resolve, reject) =>
      getFileBase64(file, (data) => resolve({ data: { link: data } })),
    )

  return (
    <div className="draftContent">
      <Editor
        editorState={value}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
		  options,
          image: {
            uploadCallback: _uploadImageCallBack,
            previewImage: true,
          },
          embedded: {
            embedCallback: (link) => {
              let detectedSrc = ''
              let slice = null
              slice = /(https.*?)"/.exec(link)
              if (!slice) {
                detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link))
              }
              return slice
                ? slice?.[1]
                : (detectedSrc && detectedSrc[1]) || link
            },
          },
        }}
      />
      {errors && touched && <span className={errorClass}>{errors}</span>}
    </div>
  )
}

export default DraftText
