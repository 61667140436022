import React, {useEffect} from "react";
import {Redirect, Route} from "react-router-dom";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getCurrentUser} from "../utils/currentUser";

const ProtectedRoute = ({
	roles,
	component: Component,
	render,
	show = true,
	loggedUser,
	...rest
}) => {
	const user = getCurrentUser();
	useEffect(() => {
		if (!show) {
			return <Redirect to="/auth/login" />;
		}
		// eslint-disable-next-line
  }, [show])

	return (
		<Route
			{...rest}
			render={(props) => {
				if (!user) {
					return <Redirect to="/auth/login" />;
				}
				if (user) {
					if (show) {
						return Component ? <Component {...props} {...rest} /> : render(props);
					}
					if (user?.role === "ADMIN") {
						return <Redirect to="/admin/tenants" />;
					}
					if (user?.role === "CLIENT") {
						return <Redirect to="/admin/orders" />;
					}
					if (user?.role === "TENANT" || user?.role === "BACK-OFFICE") {
						return <Redirect to="/admin/dashboard" />;
					}
				}
			}}
		/>
	);
};
const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
});
export default connect(mapStateToProps, null)(withRouter(ProtectedRoute));
