import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation";

const Home = (props) => {
	const {navigate} = props;
	// eslint-disable-next-line no-unused-vars
	const [isAuthenticated, setAuthenticated] = useState(() => {
		const token = localStorage.getItem("token");
		return token !== null;
	});
	useEffect(() => {
		if (isAuthenticated) {
			navigate("/admin/dashboard");
		} else {
			navigate("/auth/login");
		}
		// eslint-disable-next-line
	}, []);
	return (
		<>
			<div>
				<div className="loader">Loading...</div>
			</div>
		</>
	);
};

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(Home);
