import React from "react";
import {Close, UploadIcon, UploadNewIcon} from "@assets/SvgComponents";
import {actions as clientActions} from "@sagas/client";
import {actions as teamActions} from "@sagas/teams";
import {actions as usersActions} from "@sagas/users/users";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import UploadButtonComponent from "@src/common/components/uploadFiles/uploadButtonComponent";

const FirstStep = ({formik, selectedFile, setSelectedFile, title}) => {
	const {t} = useTranslation();
	const {setFieldValue, values} = formik;

	const handleUploadImport = (files) => {
		if (
			files[0]?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, {type: "array"});
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];
				const csv = XLSX.utils.sheet_to_csv(worksheet);
				const csvFile = new Blob([csv], {type: "text/csv"});
				setFieldValue("uploadedCsv", csvFile);
			};
			reader.readAsArrayBuffer(files[0]);
		} else {
			setFieldValue("uploadedCsv", files[0]);
		}
		setSelectedFile(files[0].name);
	};

	const handleRemoveUpload = () => {
		setSelectedFile(null);
		setFieldValue("uploadedCsv", "");
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.currentTarget.classList.add("drag-over");
	};

	const handleDrop = (e) => {
		e.preventDefault();
		const droppedFiles = e.dataTransfer.files;
		if (droppedFiles?.length === 1) {
			if (
				droppedFiles[0]?.type ===
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
				droppedFiles[0]?.type === "text/csv"
			) {
				handleUploadImport(droppedFiles);
			} else {
				ToastInfoComponent(t("FileTypesError"));
			}
		} else {
			ToastInfoComponent(t("UploadLengthInfo1"));
		}
		e.currentTarget.classList.remove("drag-over");
	};

	return (
		<div className="upload__input" onDragOver={handleDragOver} onDrop={handleDrop}>
			<div className="middle">
				<div className="middle__content">
					<span>{t("Dragdata")}</span>
					<div>
						<UploadButtonComponent
							onFileUpload={(files) => handleUploadImport(files)}
							accept=".csv,.xlsx"
							height={50}
							text="Upload"
							backgroundColor="var(--color-primary)"
							icon={<UploadNewIcon />}
						/>
					</div>
				</div>
			</div>
			<div className="bottom">
				{selectedFile && values?.uploadedCsv !== "" && (
					<div className="upload__content">
						<div className="upload__content__first">
							<div className="upload__content__first__type">
								<span>XLSX</span>
							</div>
							<p className="file__name">{selectedFile}</p>
						</div>
						<div className="upload__content__second">
							<div className="upload__content__second__size">
								{values?.uploadedCsv?.size}Mb
							</div>
							<div
								onClick={handleRemoveUpload}
								className="upload__content__second__delete"
							>
								<Close fill="#979797" />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	optionsClients: state.app.client.index.optionsClients,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	users: state.app.users.index.users,
	totalSize: state.app.users.index.totalSize,
	locations: state.app.locations.index.locations,
});

const mapDispatchToProps = {
	getClients: clientActions.fetchClientsByDropdown,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getUsers: usersActions.getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirstStep));
