import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {makeStyles} from "@material-ui/core/styles";
import {CheckedIcon, CheckedUsersIcon} from "@src/assets/SvgComponents";
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles({
	root: {
		"&:hover": {
			backgroundColor: "transparent",
		},
		paddingLeft: 0,
		paddingRight: "5px",
	},
	icon: {
		borderRadius: "50%",
		width: 20,
		height: 20,
		border: "1px solid var(--color-primary)",
		backgroundImage: "/assets/img/check.svg",
		"$root.Mui-focusVisible &": {
			outline: "2px auto #239c69",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			// backgroundColor: '#ebf1f5',
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			// background: "rgba(206,217,224,.5)",
		},
	},
	checkedIcon: {
		// backgroundColor: '#3f51b5',
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		border: "1px solid var(--color-primary)",
		color: "var(--color-primary)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	imgStyle: {
		width: 18,
		height: 18,
	},
});

function StyledRadio(props) {
	const classes = useStyles();

	return (
		<Radio
			checkedIcon={
				<span className={clsx(classes.icon, classes.checkedIcon)}>
					{/* <img className={clsx(classes.imgStyle)} src={Logo} alt="" />
					 */}
					<CheckedUsersIcon />
				</span>
			}
			className={classes.root}
			color="default"
			disableRipple
			icon={<span className={classes.icon} />}
			{...props}
		/>
	);
}

export const RadioInput = ({
	value = null,
	label = "",
	checked,
	disabled = false,
	onChange,
	name,
}) => (
	<FormControlLabel
		className={`${checked === value ? "radio-label-checked" : "radio-label"}`}
		control={<StyledRadio />}
		label={<p className="radio-label-checked-text">{label}</p>}
		value={value}
		disabled={disabled}
		onChange={onChange}
		name={name}
	/>
);
export default RadioInput;
