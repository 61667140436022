import {DeleteLeadStatusIcon} from "@src/assets/SvgComponents";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const SubCategoryItem = ({items, hidden, setFieldValue, field, deleteFn}) => {
	const [modal, setModal] = useState({
		id: null,
		index: null,
		open: false,
	});
	const {t} = useTranslation();
	const handleModal = (open, id = null, index = null) => setModal({open, id, index});
	const handleDelete = () => {
		if (modal.id) {
			deleteFn({id: modal?.id, type: "subCategory"});
		}
		const filteredState = items.filter((_, index) => index !== modal?.index);
		setFieldValue(field, filteredState);
		handleModal(false);
	};
	if (hidden) return null;
	return (
		<>
			<DeleteModal
				open={modal?.open}
				setShowDeleteModal={() => handleModal(false)}
				handleClose={() => handleModal(false)}
				confirm={handleDelete}
				modalType="permanantDelete"
			/>
			<div className="create-status-modal-form-subCategory">
				<label className="create-status-modal-form-subCategory-label">{t("Created")}</label>
				<div className="create-status-modal-form-subCategory-label-items">
					{items?.map((item, index) => (
						<div
							key={`${item.id}---${index}`}
							className="create-status-modal-form-subCategory-inMap"
						>
							<div>{item.name}</div>
							<div
								onClick={() => handleModal(true, item.id, index)}
								className="create-status-modal-form-subCategory-inMap-delete"
							>
								<DeleteLeadStatusIcon />
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default SubCategoryItem;
