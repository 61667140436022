/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React from "react";
import {useTranslation} from "react-i18next";
import {fields} from "./helper";
import "./styles.scss";

const GeneralInformation = ({singleUser}) => {
	const {t} = useTranslation();

	// Function to render individual items
	const renderItems = (items) =>
		items.map(({key, type, icon}, index) => {
			let span;
			if (type === "date") {
				const timestamp = singleUser[key];
				const date = new Date(timestamp);

				span = timestamp !== null ? date.toLocaleDateString() : null;
			} else {
				span = singleUser[key];
			}
			return (
				<div
					className="general-information-container--body--item--field"
					key={`${index + key}`}
				>
					<div className="general-information-container--body--item--field-key">
						<span>{t(key)}</span>
						<span>:</span>
					</div>
					<div className="general-information-container--body--item--field-value">
						{span ? <span>{span}</span> : "-"}
					</div>
				</div>
			);
		});

	// Mapping through 'fields' to render each field set
	const renderFields = fields.map(({title, items}, index) => (
		// Each field set has a unique key using 'title'
		<div className="general-information-container--body--item" key={`${index + title}`}>
			<p className="general-information-container--body--item--title">{t(title)}</p>
			{/* Render the items for each field set */}
			{renderItems(items)}
		</div>
	));

	// Returning the JSX for the component
	return (
		<div className="general-information-container">
			<h3>{t("GeneralInformation")}</h3>
			<div className="general-information-container--body">{renderFields}</div>
		</div>
	);
};

export default GeneralInformation;
