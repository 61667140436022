import _ from "lodash";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";

// eslint-disable-next-line import/no-cycle
import {getStore} from "../store";
// eslint-disable-next-line import/no-cycle
import handlers from "./handlers";

const {REACT_APP_WEB_API_URL} = process.env;

let ws, client, user, messageSubscription, importSubscription, importDestination;

// eslint-disable-next-line max-statements
const handleSocket = (message) => {
	const dataString = _.get(message, "body");
	if (dataString) {
		const data = JSON.parse(dataString);
		const picked = _.pick(data, ["entity", "action", "record"]);
		if (picked) {
			const {entity, action, record} = picked;
			const resolverClass = handlers[entity];
			if (resolverClass) {
				const resolverAction = resolverClass[action];
				if (resolverAction) {
					resolverAction(record);
				}
			}
		}
	}
};

function connectClient() {
	if (!client) {
		const _token = window.localStorage.getItem("token");
		const url = `${REACT_APP_WEB_API_URL}/oneri-ws?auth_token=${_token}`;
		ws = new SockJS(url);
		client = Stomp.over(ws);
	}
	return client;
}

function wsConnect() {
	const {getState} = getStore();
	const {app} = getState();
	user = app?.users?.index?.userInitialValues;
	client = connectClient();
	client.connect(
		{},
		() => {
			messageSubscription = client.subscribe(`/messages/user_${user?.guid}`, (message) => {
				handleSocket(message);
			});
		},
		() => {
			setTimeout(() => {
				wsConnect();
			}, 10000);
		},
	);
}

function wsConnectImport(importId) {
	if (client) {
		const {getState} = getStore();
		const {app} = getState();
		user = app?.users?.index?.userInitialValues;
		importDestination = `/imports/user_${user?.guid}/import_${importId}`;
		importSubscription = client.subscribe(
			`/imports/user_${user?.guid}/import_${importId}`,
			(message) => {
				handleSocket(message);
			},
		);
	} else {
		wsConnect();
		setTimeout(() => {
			wsConnectImport(importId);
		}, 5000);
	}
}

function wsCloseGeneral() {
	if (client) {
		if (messageSubscription) {
			// eslint-disable-next-line no-unused-expressions
			messageSubscription?.unsubscribe(user.guid);
		}
		client.disconnect(() => {
			user = null;
		});
	}
	if (ws) {
		ws.close();
	}
}

function wsCloseImports() {
	if (client) {
		if (importSubscription) {
			// eslint-disable-next-line no-unused-expressions
			importSubscription?.unsubscribe(importDestination);
		}
	}
}

function handleTabClose() {
	wsCloseGeneral();
	wsCloseImports();
}

window.addEventListener("beforeunload", handleTabClose);

export {wsConnect, wsCloseGeneral as wsClose, wsConnectImport, wsCloseImports};
