/* eslint-disable max-len */
import i18n from "@src/i18n";
import _ from "lodash";
import * as Yup from "yup";
import {germanCities, germanRegions} from "../staticData";

const getLabel = (options, filterValue) => {
	const selectedItem = options.find((item) => filterValue === item.value);
	return selectedItem?.label;
};

export const generateInitialValues = (fields, filter, tariff) => {
	const fieldMap = {
		tariffname_ENG: tariff?.tarifName,
		tariffprice_ENG: tariff?.gesamtpreisOhneBoniBrutto,
		baseprice_ENG: tariff?.grundpreisBrutto,
		workingprice_ENG: tariff?.arbeitspreisBrutto,
		country_ENG: filter?.country,
		filledstreet_ENG: filter?.street,
		filledlocation_ENG: filter?.city,
		postcode_ENG: filter?.postalCode,
		filledhousenr_ENG: filter?.houseNr,
		tariffexternalid_ENG: tariff?.tarifID,
		clientexternalid_ENG: tariff?.versorgerID,
	};
	return fields.reduce((acc, field) => {
		acc[field.name] =
			fieldMap[field.name] ??
			(field.inputType === "CHECKBOX" ? false : field.defaultValue || "");
		return acc;
	}, {});
};

export const toCamelCase = (str) => {
	const words = str.toLowerCase().split("_");
	for (let i = 1; i < words.length; i++) {
		words[i] = words[i].substring(0, 1).toUpperCase() + words[i].substring(1);
	}
	return words.join("");
};

export const groupInputs = (fields, sections) => {
	const obj = {};
	sections.forEach((sect) => {
		obj[sect.nameDe] = fields?.filter((field) => field?.parentId === sect.id);
	});
	return obj;
};
export const groupedInputs = (fieldData, groupBy = "inputType") => {
	const groupByLayout = _.groupBy(fieldData, groupBy);
	return groupByLayout;
};
export const createOptionsForDropDowns = (fields) => {
	const groupedByDropDown = groupedInputs(fields, "dropDownParentId");
	return groupedByDropDown;
};

export const generateYupSchema = (jsonArray, currentLanguage) => {
	const schemaFields = {};
	jsonArray.forEach((field) => {
		// this dependentFrom field will serve to make validations for this field
		// 'when a field is dependent from another field 'dependentFrom' will determine if that field is required or not
		// also the same field will be used to hide/show field
		const {
			name,
			inputType,
			isRequired,
			labelEn,
			labelDe,
			maxCharacters,
			minCharacters,
			dependentFrom,
		} = field;

		let fieldSchema;

		const fieldLabel = currentLanguage === "en" ? labelEn : labelDe;

		switch (inputType) {
			case "TEXT":
				fieldSchema = Yup.string().nullable();
				break;

			case "DATE":
				fieldSchema = Yup.number().nullable();
				break;

			case "DROPDOWN":
				fieldSchema = field.isMultiple ? Yup.array().nullable() : Yup.string().nullable();
				break;

			case "CHECKBOX":
				fieldSchema = Yup.boolean().nullable();
				break;
			case "NUMBER":
				fieldSchema = Yup.number().nullable();
				break;
			default:
				fieldSchema = Yup.mixed().nullable();
		}

		// this check will serve to make a field required if it isn't depenedent from another field,
		if (isRequired && !dependentFrom) {
			fieldSchema = fieldSchema.required(`${fieldLabel} ${i18n.t("isRequired")}`);
		}

		if (maxCharacters) {
			fieldSchema = fieldSchema?.max(
				maxCharacters,
				`${fieldLabel} ${i18n.t("atMost")} ${maxCharacters} ${i18n.t("chars")}`,
			);
		}

		if (minCharacters) {
			fieldSchema = fieldSchema?.min(
				minCharacters,
				`${fieldLabel} ${i18n.t("atLeast")} ${minCharacters} ${i18n.t("chars")}`,
			);
		}
		if (dependentFrom) {
			fieldSchema = fieldSchema.when([`${dependentFrom}`], {
				is: (dependentFrom) => !!dependentFrom,
				then: fieldSchema.required(`${fieldLabel} ${i18n.t("isRequired")}`),
				otherwise: fieldSchema.optional(),
			});
		}

		schemaFields[name] = fieldSchema.label(labelEn);
	});

	return Yup.object().shape(schemaFields);
};

export const shouldHideField = ({item, filter, values}) => {
	const {hideOnPrivateTariffs, dependentFrom, isGeneric, hideWeb, dropDownParentId, hideMobile} =
		item;
	if (hideOnPrivateTariffs && filter?.customerType === "Privat") return true;
	if (dependentFrom && !isGeneric) {
		if (!dependentFrom?.split(",").some((dependentField) => values?.[dependentField.trim()])) {
			return true;
		}
	}
	if (dropDownParentId || hideMobile || hideWeb) return true;

	return false;
};

export const userRolesDropdown = [
	"SP",
	"EMPLOYEE",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_TL",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_AB",
];

export const baseFields = [
	{
		title: i18n.t("ChooseClient"),
		name: "clientId",
		options: "clients",
	},
	{
		title: i18n.t("ChooseTariff"),
		name: "tariffId",
		options: "energyTariffsDropdown",
	},
];

export const validateBaseFields = [
	{
		inputType: "NUMBER",
		name: "userIdFromBackOffice",
		isRequired: true,
		labelEn: i18n.t("Users"),
		labelDe: i18n.t("Users"),
	},
	{
		inputType: "NUMBER",
		name: "clientId",
		isRequired: true,
		labelEn: i18n.t("Client"),
		labelDe: i18n.t("Client"),
	},
	{
		inputType: "NUMBER",
		name: "tariffId",
		isRequired: true,
		labelEn: i18n.t("Tariff"),
		labelDe: i18n.t("Tariff"),
	},
];
