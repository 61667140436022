/* eslint-disable max-len */
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import ButtonComponent from "@src/common/button";
import CustomAccordion from "@src/common/CustomAccordion";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import CustomSelect from "@src/common/Select";

const ContractPdfSettings = ({loading, updateSettings, statuses, client}) => {
	const {t} = useTranslation();
	const [status, setStatus] = useState(null);
	const handleClick = () => {
		if (status) {
			updateSettings({
				deliverPdfWithStatusId: status?.id,
			});
		} else {
			ToastErrorComponent(t("PleaseSelectStatus"));
		}
	};
	useEffect(() => {
		if (client?.deliverPdfWithStatus) {
			setStatus({
				value: client.deliverPdfWithStatus,
				label: client?.deliverPdfWithStatusName,
			});
		}
	}, []);

	return (
		<CustomAccordion title={t("ContractPdfSettings")} className="contracts__accordion">
			<div className="generated__pdf__container">
				<div className="generated__pdf__container-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("ContractPdfInfo")}</span>
				</div>

				<div className="generated__pdf__container-content">
					<div className="dropdown--container">
						<p className="dropdown--container-label">{t("ChooseStatus")}</p>
						<CustomSelect
							options={statuses}
							onSelect={(_, value) => {
								setStatus(value);
							}}
							value={status}
							onClear={() => {
								setStatus(null);
							}}
						/>
					</div>
				</div>
				<div className="generated__pdf__container-actions">
					<ButtonComponent
						buttonText={t("Save")}
						disabled={loading}
						loading={loading}
						onClick={handleClick}
					/>
				</div>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isLoading,
	deliverPdfWithStatusName: state.app.client.index.client.deliverPdfWithStatusName,
	statuses: state.app.orders.statuses.statuses,
	client: state.app.client.index.client,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContractPdfSettings));
