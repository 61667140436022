import React from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {useTranslation} from "react-i18next";
import {formatNumber} from "@src/utils/convertNumber";

const CalculationTablePdf = (props) => {
	const {data} = props;
	const {t} = useTranslation();
	return (
		<>
			<View style={styles.topContentTable}>
				<View style={styles.topContentTableHeader}>
					<Text
						style={{
							fontWeight: "bold",
							fontFamily: "Helvetica-Bold",
							fontSize: 8,
						}}
					>
						{t("InformationReserveAccount")}
					</Text>
				</View>
				<View
					style={{
						...styles.topContentTableRows,
						fontFamily: "Helvetica-Bold",
					}}
				>
					<Text style={{...styles.cancellationTableRow, fontFamily: "Helvetica"}}>
						{t("BalanceBeforeBilling")}
					</Text>
					<Text style={{...styles.cancellationTableRowRight, fontFamily: "Helvetica"}}>
						{formatNumber(data?.currentCancellationReserveBalance, true)} €
					</Text>
				</View>
				<View style={styles.topContentTableRows}>
					<Text style={{...styles.cancellationTableRow, fontFamily: "Helvetica"}}>
						{t("NewAdditionCancellationReserves")}
					</Text>
					<Text style={{...styles.cancellationTableRowRight, fontFamily: "Helvetica"}}>
						{formatNumber(data?.thisMonthCancellationReserve, true)} €
					</Text>
				</View>
				<View style={styles.topContentTableRows}>
					<Text style={{...styles.cancellationTableRow, fontFamily: "Helvetica"}}>
						{t("CancellationDeductions")}
					</Text>
					<Text style={{...styles.cancellationTableRowRight, fontFamily: "Helvetica"}}>
						{data?.canceledContractsCancellationReserve === 0
							? `${formatNumber(data?.canceledContractsCancellationReserve)} €`
							: ` -${formatNumber(data?.canceledContractsCancellationReserve)} €`}
					</Text>
				</View>
				<View style={styles.topContentTableRows}>
					<Text style={{...styles.cancellationTableRow, fontFamily: "Helvetica"}}>
						{t("Withdrawals")}
					</Text>
					<Text style={{...styles.cancellationTableRowRight, fontFamily: "Helvetica"}}>
						{data?.returnedCancellationReserve === 0
							? `${formatNumber(data?.returnedCancellationReserve)} €`
							: ` -${formatNumber(data?.returnedCancellationReserve)} €`}
					</Text>
				</View>
				<View style={{...styles.topContentTableFooter, fontFamily: "Helvetica-Bold"}}>
					<Text style={styles.cancellationTableRow}>{t("BalanceNEW")}</Text>
					<Text style={styles.cancellationTableRowRight}>
						{formatNumber(data?.newCancellationReserveBalance, true)} €
					</Text>
				</View>
			</View>
		</>
	);
};

export default CalculationTablePdf;

const styles = StyleSheet.create({
	topContentTable: {
		display: "flex",
		flexDirection: "column",
		fontSize: "8px",
		border: "1px #000 solid",
		width: "200px",
	},
	topContentTableHeader: {
		borderBottom: "1px #000 solid",
		fontWeight: "bold",
		backgroundColor: "#EFEFEF",
		padding: 5,
	},
	topContentTableRows: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px #000 solid",
	},
	topContentTableRow: {
		display: "flex",
		flexDirection: "row",
	},
	cancellationTableRow: {
		marginLeft: 4,
		width: 80,
		borderRight: "1px #080808 solid",
		padding: 2,
		fontSize: 7,
	},
	topContentTableFooter: {
		display: "flex",
		flexDirection: "row",
	},
	tableThreeAndTitle: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 10,
	},
	cancellationTableRowRight: {
		width: 120,
		marginLeft: 5,
		textAlign: "right",
		padding: 2,
		fontSize: 7,
	},
});
