import {Skeleton} from "antd";
import React from "react";
function DrawerSkeleton() {
	return (
		<div className="skeleton-wrapper">
			<Skeleton.Image active style={{borderRadius: 5, marginTop: 60}} />
			<div className="skeleton-wrapper-body">
				{Array.from({length: 8}).map((_, index) => (
					<Skeleton.Input key={index} active style={{height: 30, borderRadius: 5}} />
				))}
			</div>
			<div className="skeleton-wrapper-body">
				{Array.from({length: 2}).map((_, index) => (
					<Skeleton.Input active style={{height: 30, borderRadius: 5}} />
				))}
			</div>
			<div className="skeleton-wrapper-body">
				<Skeleton.Input active style={{height: 50, width: 200}} />
			</div>
		</div>
	);
}

export default DrawerSkeleton;
