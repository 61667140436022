import {checkWidth} from "@src/utils/check-width";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TabPopover from "./TabPopover";
function Tabs({wrapperRef, onClick, items = [], value}) {
	const {t} = useTranslation();
	const [visibleItems, setVisibleItems] = useState(items);
	useEffect(() => {
		const wrapper = wrapperRef?.current;
		if (!wrapper) return;
		const resizeObserver = new ResizeObserver(() => {
			checkWidth(wrapper, setVisibleItems, items, 200);
		});
		resizeObserver.observe(wrapper);
		checkWidth(wrapper, setVisibleItems, items, 200);
		return () => resizeObserver.disconnect();
	}, [items, wrapperRef]);
	return (
		<div ref={wrapperRef || null} className="tab-wrapper">
			{visibleItems?.map((item, index) => (
				<div
					key={index}
					className={`tab-wrapper-box ${
						_.difference(items, visibleItems) && index + 1 === visibleItems?.length
							? "wrapped"
							: ""
					}`}
				>
					<div
						className={`tab ${
							item?.type === value || value === t(item) ? "active" : ""
						}`}
						onClick={(e) => onClick(e, item)}
					>
						{item?.name ? t(item?.name) : t(item)}
					</div>
					<div
						className={`bar ${
							item?.type === value || value === t(item) ? "active" : ""
						}`}
					></div>
				</div>
			))}
			{wrapperRef && (
				<TabPopover
					items={items}
					visibleItems={visibleItems}
					onClick={onClick}
					value={value}
				/>
			)}
		</div>
	);
}
export default Tabs;
