import {delay, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {getCurrentUser} from "@utils/currentUser";
import produce from "immer";
import {wsClose} from "@src/ws";
// eslint-disable-next-line import/no-cycle
import {actions as userActions} from "../users/users/index";
import createAction from "../../../../utils/action-creator";
import {actions as navigation} from "../navigation";
import Logger from "../../../../utils/logger";
import {requestForToken} from "../../../../utils/firebase";
const logger = new Logger("Auth Logout");

const PREFIX = "@app/auth/logout";
export const LOGOUT = `${PREFIX}LOGOUT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	logout: () => createAction(LOGOUT),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*logout() {
		yield put(actions.setLoading(true));
		try {
			const user = getCurrentUser();
			const {id} = user;
			const devicetokenPromise = requestForToken();
			devicetokenPromise.then((token) => {
				axios.put(`/deleteDeviceToken`, {
					userId: id,
					token,
				});
			});
			wsClose();
			yield delay(1000); // add a delay of 1 second
			localStorage.removeItem("token");
			localStorage.removeItem("sortType");
			localStorage.removeItem("roleData");
			yield put(userActions.clearUserInitialValues());
			yield put(navigation.navigate("/auth/login"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(LOGOUT, sagas.logout);
};
