import InputFile from "@src/common/fileInput";
import MediaComponent from "@src/common/messagesComponents/MediaComponent";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import {filterFunc} from "@src/store/sagas/app/client/utils";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const OrderEmails = (props) => {
	const {
		setSubject,
		subject,
		setDescription,
		description,
		uploadedPDFs,
		setUploadedPDFs,
		removeAttachment,
		setSelectedPDFs,
		selectedPDFs,
	} = props;
	const {t} = useTranslation();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [fileToRemove, setFileToRemove] = useState(null);
	const handleRemove = (file) => {
		setShowDeleteModal(true);
		setFileToRemove(file);
	};
	const handleRemoveAttachment = () => {
		if (fileToRemove?.id) {
			removeAttachment({id: fileToRemove?.id});
			setUploadedPDFs(filterFunc(uploadedPDFs, "id", fileToRemove?.id));
		} else {
			removeAttachment({base64: fileToRemove?.fileUrl});
			setUploadedPDFs(filterFunc(uploadedPDFs, "fileUrl", fileToRemove?.fileUrl));
			setSelectedPDFs(filterFunc(selectedPDFs, "fileUrl", fileToRemove?.fileUrl));
		}
		setShowDeleteModal(false);
		setFileToRemove(null);
	};
	const handleChangePDF = (event) => {
		const fileList = event.target.files;
		if (uploadedPDFs?.length + fileList?.length > 3) {
			ToastInfoComponent(t("uploadLengthInfo"));
			event.preventDefault();
			return;
		}
		const fileDataArray = Array.from(fileList).map(
			(file) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onloadend = () => {
						resolve({
							fileName: file.name,
							fileUrl: reader.result,
							type: file.type,
							fileType: file.name.split(".").pop()?.toLowerCase(),
							size: file.size,
						});
					};
					reader.onerror = () => {
						reader.abort();
						reject(new DOMException("Problem parsing input file."));
					};
				}),
		);
		Promise.all(fileDataArray).then((resolvedFiles) => {
			if (resolvedFiles?.every((item) => item?.fileType === "pdf")) {
				setUploadedPDFs((prevState) => [...prevState, ...resolvedFiles]);
				setSelectedPDFs(resolvedFiles);
			} else {
				ToastInfoComponent("Only PDFs are allowed");
			}
		});
	};
	return (
		<div className="client_settings-paragraph-picker">
			<h1>{t("Writeyourparagraphsfortitleshere")}</h1>
			<p>{t("TouseThisCharachter")}</p>
			<div style={{width: 400}}>
				<ol>
					<li>{t("UseSquareBracketsCharacter")}</li>
				</ol>
				<p className="instructions-example">{t("herearetheavailableenums")}</p>
			</div>
			<p className="instructions-example">{t("EXAMPLE")}</p>
			<div className="client_settings-paragraphs">
				<div className="client_settings-paragraphInputs">
					<p>{t("EmailSubject")}</p>
					<textarea
						type="text"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
					/>
				</div>
				<div className="client_settings-paragraphInputs">
					<p>{t("EmailDescription")}</p>
					<textarea
						type="text"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
				{/* <div className="client_settings-paragraphInputs">
							<p>{t("TitleBankdescription")}</p>
							<textarea
								type="text"
								// defaultValue={client?.bankParagraph}
								value={generateID}
								onChange={(e) => setGenerateID(e.target.value)}
							/>
						</div>
						<div className="client_settings-paragraphInputs">
							<p>{t("TitleEmaildescription")}</p>
							<textarea
								type="text"
								// defaultValue={client?.emailParagraph}
								value={emailParagraph}
								onChange={(e) => setEmailParagraph(e.target.value)}
							/>
						</div>
			          <div className="client_settings-paragraphInputs">
                    <p>{t("TitleConfirmationdescription")}</p>
                     <textarea
                    type="text"
                    // defaultValue={client?.confirmationParagraph}
                    value={confirmationParagraph}
                    onChange={(e) => setConfirmationParagraph(e.target.value)}
                />
            </div> */}
			</div>
			{/* <div className="attachments-field">
				<div className="attachments-field-input">
					<InputFile
						onChange={(e) => handleChangePDF(e)}
						accept="application/pdf"
						name="pdf-upload"
						multiple={true}
						borderedInput={true}
					/>
				</div>
				<div className="attachments-field-files">
					<MediaComponent
						totalFiles={{files: uploadedPDFs}}
						showTitle={false}
						canDownload={false}
						canRemove={true}
						onRemove={handleRemove}
						height="150px"
					/>
				</div>
			</div> */}
			<DeleteModal
				setShowDeleteModal={setShowDeleteModal}
				confirm={handleRemoveAttachment}
				open={showDeleteModal}
				modalType="permanantDelete"
			/>
		</div>
	);
};
export default OrderEmails;
