import React from "react";

const RightPages = (props) => {
	const {activePage, length} = props;

	return (
		<div className="rightPages">
			{Array.from({length}).map((itm, index) => {
				const condition = index + 1 === activePage;
				return (
					<div
						key={index}
						className={`rightPages__page ${
							condition ? "rightPages__page--active" : ""
						}`}
					>
						{index + 1}
					</div>
				);
			})}
		</div>
	);
};

export default RightPages;
