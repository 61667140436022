import CloseIcon from "@common/closeIcon/CloseIcon";
import React from "react";
import {useTranslation} from "react-i18next";

const Pages = (props) => {
	const {pages, handleAddPage, activePage, handleSelectPage, deletePage} = props;

	const handleChangePage = (e, index) => {
		e.stopPropagation();
		deletePage(index);
	};

	const {t} = useTranslation();

	return (
		<div className="addPage">
			{pages?.map((page, index) => (
				<div
					key={index}
					className={`addPage__page ${
						activePage === index + 1 ? "addPage__page--active" : ""
					}`}
					onClick={() => handleSelectPage(index + 1)}
				>
					{pages?.length > 1 && (
						<CloseIcon
							className="green__icon"
							click={(e) => handleChangePage(e, index)}
						/>
					)}
					{index + 1}
				</div>
			))}
			<div className="addPage__page addPage__page-add" onClick={handleAddPage}>
				{t("Add")}
			</div>
		</div>
	);
};

export default Pages;
