import React from "react";
import ErrorLogo from "../../assets/img/priority_high_black_24dp.svg";

const InvalidCsvErrors = (props) => {
	const {errors} = props;
	return (
		<div className="invalid__csv__errors__container">
			{errors.map((item) => (
				<div>
					{item} <img src={ErrorLogo} alt="" />
				</div>
			))}
		</div>
	);
};

export default InvalidCsvErrors;
