import CheckBoxComponent from "@src/common/checkbox";
import React from "react";
import i18n from "@src/i18n";

export const getPermissionColumns = (handleCheckbox) => [
	{
		key: "name",
		name: "Name",
		formatter: ({row}) => <span>{i18n.t(`${row?.name}`)}</span>,
		headerCellClass: "headerStyles permission-custom-cell",
		cellClass: "permission-custom-cell",
		resizable: true,
	},
	{
		key: "valueMobile",
		name: i18n.t("ShowWeb"),
		headerCellClass: "headerStyles permissionHeaderCell",
		cellClass: "permission-checkbox-cell",
		formatter: ({row}) => (
			<CheckBoxComponent
				checked={row?.valueWeb}
				handleChange={(e) => handleCheckbox(e, "valueWeb", row?.id)}
			/>
		),
		resizable: true,
	},
	{
		key: "valueWeb",
		name: i18n.t("ShowMobile"),
		headerCellClass: "headerStyles permissionHeaderCell",
		cellClass: "permission-checkbox-cell",
		formatter: ({row}) => (
			<CheckBoxComponent
				checked={row?.valueMobile}
				handleChange={(e) => handleCheckbox(e, "valueMobile", row?.id)}
			/>
		),
		resizable: true,
	},
];
