import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useUrlParams} from "@src/utils/useUrlParams";
import {actions as notificationsActions} from "@sagas/notifications/index";
import InsideTopic from "../InsideTopic";
import BottomComponents from "../BottomComponents";
import NotificationsWrapper from "../NotificationsWrapper";
const MessagesRightSide = ({match, addingNewMessage, insideTopic, resetState}) => {
	const {getUrlParams} = useUrlParams();
	const {notifications} = getUrlParams("notifications");
	const [messages, setMessages] = useState([]);
	const [messageToReply, setMessageToReply] = useState(null);
	const isDeactivated = insideTopic?.topicMembers?.every((user) => !user?.writeAccess);
	useEffect(
		() => () => {
			resetState();
		},
		[],
	);
	return (
		<>
			{notifications === "true" ? (
				<NotificationsWrapper />
			) : (
				<div className="messages__right__side__container">
					<>
						<InsideTopic
							messages={messages}
							setMessages={setMessages}
							messageToReply={messageToReply}
							setMessageToReply={setMessageToReply}
							isDeactivated={isDeactivated}
						/>
						{(addingNewMessage || match.params.id) && (
							<BottomComponents
								messages={messages}
								setMessages={setMessages}
								messageToReply={messageToReply}
								setMessageToReply={setMessageToReply}
								isDeactivated={isDeactivated}
							/>
						)}
					</>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	addingNewMessage: state.app.messages.create.addingNewMessage,
	insideTopic: state.app.messages.index.insideTopic,
	systemNotificationsState: state.app.notifications.index,
});
const mapDispatchToProps = {
	resetState: notificationsActions.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessagesRightSide));
