// import {Autocomplete, TextField} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {SelectIcon} from "@assets/SvgComponents";
import {actions as clientActions} from "@sagas/client";
import {actions as teamActions} from "@sagas/teams";
import {actions as usersActions} from "@sagas/users/users";
import {actions as locationActions} from "@sagas/locations";
import {getSelectOptionsFromDataTeams, handleAutoCompleteChange} from "@utils/select";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useUrlParams} from "@src/utils/useUrlParams";
import SelectedUsers from "../SelectedUsers";
import GroupAccordion from "../groupAccordion";

const SecondStep = ({
	formik,
	teams,
	agencies,
	locations,
	fetchLocations,
	userOptions,
	setUserOptions,
	optionsClients,
	editSize,
	fromOrder,
}) => {
	const {setUrlParams} = useUrlParams();
	const CLIENT_WITH_IMPORT = ["VSE", "EPO", "EPE", "OPO"];
	const {t} = useTranslation();
	const [inputValue, setInputValue] = useState("");
	const {values, setFieldValue, errors, touched} = formik;
	useEffect(() => {
		editSize(1000);
		if (values.client) {
			fetchLocations(values?.client);
			setFieldValue("projectId", null);
		}
		return () => editSize(10);
	}, [values?.client]);
	const helperAarr = {
		Team: teams,
		Agency: agencies,
		Users: userOptions,
	};

	return (
		<div className="step_2_container">
			<div className="chooseClientDropdown">
				<span className="dropdownlabel">{t("ChooseClient")}</span>
				<Autocomplete
					disableClearable={false}
					disableCloseOnSelect={false}
					filterSelectedOptions={true}
					getOptionLabel={(option) => option.name}
					getOptionSelected={(option, value) => option?.value === value?.value}
					limitTags={20}
					multiple={false}
					onChange={(event, value) => {
						setUrlParams(value?.clientId, "clientId");
						setFieldValue("client", value?.value);
					}}
					style={{
						border: errors?.client ? "1px solid #e82416" : "",
						borderRadius: errors?.client ? "5px" : "",
					}}
					options={
						fromOrder
							? optionsClients?.filter((item) =>
									CLIENT_WITH_IMPORT?.includes(item?.clientId),
							  )
							: optionsClients.filter((item) => item.leads)
					}
					renderInput={(params) => (
						<TextField
							{...params}
							className="text-field"
							id="name"
							name="name"
							placeholder={t("Client")}
							variant="outlined"
						/>
					)}
				/>
				{values?.client && !fromOrder && (
					<>
						<label htmlFor="selectStatus">{t("ChooseProject")}</label>
						<Autocomplete
							disableClearable={false}
							disableCloseOnSelect={false}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option?.label}
							getOptionSelected={(option, value) => option?.value === value?.value}
							limitTags={20}
							multiple={false}
							onChange={(event, value) => {
								if (value !== null) {
									setFieldValue("projectId", value?.value);
								} else {
									setFieldValue("projectId", null);
								}
							}}
							options={
								values?.client !== null
									? getSelectOptionsFromDataTeams(locations, "locationName", "id")
									: []
							}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="label"
									name="label"
									placeholder={t("Locations")}
									variant="outlined"
								/>
							)}
						/>
					</>
				)}
			</div>
			<div className="chooseUsersDropdown">
				<Autocomplete
					id="grouped-demo"
					disableClearable={false}
					disableCloseOnSelect={false}
					filterSelectedOptions={true}
					groupBy={(option) => (fromOrder ? "allOptions" : option.type)}
					getOptionLabel={(option) => option?.label}
					getOptionSelected={(option, value) => option?.value === value?.value}
					value={null}
					limitTags={20}
					multiple={false}
					onChange={(event, value) => {
						if (!fromOrder) {
							handleAutoCompleteChange(
								setFieldValue,
								value,
								userOptions,
								setUserOptions,
								helperAarr,
							);
						} else {
							setUserOptions((prevOptions) => {
								const newUsers = prevOptions.map((item) => ({
									...item,
									selected:
										item.value === value?.value &&
										!item.selected &&
										item.type === "User",
								}));
								return newUsers;
							});
							setFieldValue("users", [value?.value]);
						}
					}}
					renderOption={(option) => (
						<li className="selectedIcon__dropdown">
							{option?.selected && (
								<span className="selectedIcon__icon">
									<SelectIcon />
								</span>
							)}
							<span>{option?.label}</span>
						</li>
					)}
					inputValue={inputValue}
					onInputChange={(event, value, reason) => {
						if (event && event.type === "blur") {
							setInputValue("");
						} else if (reason !== "reset") {
							setInputValue(value);
						}
					}}
					options={userOptions}
					renderInput={(params) => (
						<TextField
							{...params}
							className="text-field"
							id="userLabel"
							name="userLabel"
							placeholder={`${t("Search")}...`}
							variant="outlined"
						/>
					)}
					renderGroup={(params) => <GroupAccordion data={params} />}
				/>
				{errors?.users && touched.users && (
					<span className="errorClass">{errors?.users}</span>
				)}
			</div>
			<SelectedUsers
				setUserOptions={setUserOptions}
				userOptions={userOptions}
				noDataMessage={t("NoUsersSelected")}
				setFieldValue={setFieldValue}
			/>
		</div>
	);
};
const mapStateToProps = (state) => ({
	optionsClients: state.app.client.index.optionsClients,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	users: state.app.users.index.users,
	totalSize: state.app.users.index.totalSize,
	locations: state.app.locations.index.locations,
});

const mapDispatchToProps = {
	getClients: clientActions.fetchClientsByDropdown,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getUsers: usersActions.getUsers,
	fetchLocations: locationActions.fetchLocations,
	editSize: locationActions.editSize,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SecondStep));
