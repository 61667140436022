import React from "react";
import "./styles.scss";

const ProfilePicture = ({singleUser}) => {
	const {firstName, lastName, imgUrl, role} = singleUser;

	return (
		<div className="profile-picture-container">
			<div className="profile-picture-container--left">
				{imgUrl ? (
					<img src={imgUrl} alt={firstName} />
				) : (
					<span>
						{firstName?.charAt(0)}
						{lastName?.charAt(0)}
					</span>
				)}
			</div>
			<div className="profile-picture-container--right">
				<p className="profile-picture-container--right--title">
					{firstName} {lastName}
				</p>
				<p className="profile-picture-container--right--description">{role?.name}</p>
			</div>
		</div>
	);
};

export default ProfilePicture;
