import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/users/users";
import {actions as deletedUserActions} from "@sagas/users/users/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import {handleError} from "@src/utils/handleError";
import i18n from "../../../../../i18n";

const logger = new Logger("Users index");

const PREFIX = "@app/users/user/index";
export const DELETE_USER = `${PREFIX}DELETE_USER`;
export const DEACTIVATE_USER = `${PREFIX}DEACTIVATE_USER`;

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_DELETE_MODAL = `${PREFIX}SHOW_DELETE_MODAL`;

const _state = {
	loading: false,
	deleteShowModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SHOW_DELETE_MODAL:
				draft.deleteShowModal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteUser: (payload) => createAction(DELETE_USER, {payload}),
	deactivateUser: (payload) => createAction(DEACTIVATE_USER, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowDeleteModal: (payload) => createAction(SHOW_DELETE_MODAL, {payload}),
};

export const sagas = {
	*deactivateUser({payload}) {
		yield put(actions.setLoading(true));
		const {id, setModal} = payload;
		try {
			const users = yield select((state) => state.app.users.index.users);
			const user = users.find((e) => e.id === id);
			yield axios.put(`/user/deactivate/${id}`);
			yield put(userActions.deleteUserOnState({users, user}));
			ToastSuccesComponent(i18n.t("UserDeactivateSuccess"));

			setModal(false);
			yield put(actions.setShowDeleteModal(false));
		} catch (error) {
			logger.error(error);
			setModal(false);
			handleError({
				error: error?.response?.data?.message,
				customMessage: error?.response?.data?.message,
			});
		} finally {
			yield put(actions.setLoading(false));
			yield put(actions.setShowDeleteModal(false));
		}
	},
	*deleteUser(payload) {
		yield put(actions.setLoading(true));

		try {
			// eslint-disable-next-line
			yield axios.put(`/user/delete/${payload?.payload?.id}`);
			yield put(deletedUserActions.delete(payload?.payload?.id));
			ToastSuccesComponent(i18n.t("UserDeleteSuccess"));
			payload.payload.setPermanantDeleteModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_USER, sagas.deleteUser);
	yield takeLatest(DEACTIVATE_USER, sagas.deactivateUser);
};
