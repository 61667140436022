import React from "react";

import i18n from "../../../../../../i18n";

export const locationElements = {
	columns: [
		{
			name: i18n.t("Locations"),
			key: "locationName",
			sortable: true,
			headerCellClass: "headerStyles",
			formatter: ({row}) => <span>{row?.locationName}</span>,
		},
		// {
		// 	title: i18n.t("ZipCode"),
		// 	field: "zipCode",
		// },
	],
	btnText: i18n.t("Save"),

	lists: [
		{
			title: i18n.t("LocationName"),
			type: "input",
			field: "locationName",
			inputType: "text",
			required: true,
		},
	],
	hasID: "locationName",
	modalTitle: i18n.t("TheLocation"),
};
