import {DeleteRowIcon, EditRowIcon, PreviewRowIcon, ThreeDotsIcon} from "@src/assets/SvgComponents";
import CustomPopover from "@src/common/Popover";
import React from "react";
import i18n from "@src/i18n";
export const actionsFormatter = (row, handleCalcModal) => {
	const items = [
		{
			key: "edit",
			label: i18n.t("EditPrice"),
			onClick: () => handleCalcModal("price", row?.manualCorrectionId),
			icon: (
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<EditRowIcon />
				</div>
			),
		},
		{
			key: "delete",
			label: i18n.t("DeletePrice"),
			onClick: () => handleCalcModal("delete", row?.manualCorrectionId),
			icon: (
				<div style={{display: "flex", alignItems: "center"}}>
					<DeleteRowIcon />
				</div>
			),
		},
		row?.fileId
			? {
					key: "preview",
					label: i18n.t("Preview File"),
					onClick: () => handleCalcModal("file", row?.fileId),
					icon: (
						<div style={{display: "flex", alignItems: "center"}}>
							<PreviewRowIcon />
						</div>
					),
			  }
			: null,
	];
	return (
		row?.orderId === null &&
		!!row?.manualCorrectionId && (
			<CustomPopover
				menu={{items}}
				customClass="popover__actions-wrapper"
				placement="bottomLeft"
				trigger={["click"]}
			>
				<div className="three-dots-icon">
					<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
				</div>
			</CustomPopover>
		)
	);
};
