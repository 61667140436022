import CheckBoxComponent from "@src/common/checkbox";
import React from "react";
import {useTranslation} from "react-i18next";
import CloseIcon from "@common/closeIcon/CloseIcon";
import moment from "moment";
import _ from "lodash";
import Userbox from "@src/common/components/userbox";
import {RestoreRowIcon} from "@src/assets/SvgComponents";
import HtmlTooltip from "@src/common/htmlTooltip";

function Timeline({
	setShowTimeline,
	activities,
	selectedVersion,
	currentVersion,
	handleGetById,
	handleShowChanges,
	getSelectedVersion,
	checked,
}) {
	const {t} = useTranslation();
	if (activities === null || _.isEmpty(activities))
		return (
			<div className="full__width__loader">
				<span className="noData">{t("NoData")}</span>
			</div>
		);
	return (
		<>
			<div style={{height: 45}} className="orders__modal__icon__and__name">
				<span>{t("Activity")}</span>
				<CloseIcon className="close__modal__icon" click={() => setShowTimeline(false)} />
			</div>
			<div className="timelineContainer-show_body">
				{Object.entries(activities).map(([act, entries]) => {
					const firstMonth = Object.keys(activities)[0];
					const sortedEntries = entries.slice().sort((a, b) => b.createdAt - a.createdAt);
					return (
						<div className="monthWrapper">
							<span className="day">{act}</span>
							{sortedEntries.map((item, index) => {
								const isCurrentVersion = getSelectedVersion(
									item,
									index,
									firstMonth,
									act,
								);
								const isFirstVersion = index === 0 && firstMonth === act;
								const content = (
									<div
										onClick={() => handleGetById(item, index, firstMonth, act)}
										className={`timelineContainer-show_body-box${
											isCurrentVersion ? " current" : ""
										}`}
									>
										<div className="left">
											<span className="date">
												{moment(+item.createdAt).format(
													"DD.MM.YYYY, h:mm A",
												)}
											</span>
											{isFirstVersion && (
												<div className="version">
													<RestoreRowIcon
														color="var(--color-primary)"
														width={14}
														height={13}
													/>
													<span className="currentVersion">
														{t("CurrentVersion")}
													</span>
												</div>
											)}
										</div>
										<Userbox
											current={isCurrentVersion}
											user={item.userData.name}
										/>
									</div>
								);

								return item.action === "CHANGE_STATUS" ? (
									<HtmlTooltip
										placement="bottom-end"
										title={<span>{t("ChangeStatus")}</span>}
									>
										{content}
									</HtmlTooltip>
								) : (
									content
								);
							})}
						</div>
					);
				})}
			</div>
			<div className="timelineContainer-show_footer">
				<div className="timelineContainer-show_footer-checkbox">
					{(selectedVersion?.id !== null && selectedVersion?.id !== currentVersion) ||
					selectedVersion?.isChangeStatus ? (
						<CheckBoxComponent
							checked={checked}
							handleChange={(val) => handleShowChanges(!checked)}
							label={t("ShowChanges")}
						/>
					) : null}
				</div>
			</div>
		</>
	);
}

export default Timeline;
