import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation";
import Back from "@assets/img/back__icon.svg";
import ImportsHeader from "./ImportsHeader";

const PreviewCsvById = ({navigate}) => {
	const {t} = useTranslation();
	const handleBack = () => {
		navigate("/admin/imports/change-order-status");
	};
	return (
		<div className="dashboard__container">
			<ImportsHeader />
			<div className="importsPage-layout">
				<div className="importsPage-layout_header">
					<img style={{cursor: "pointer"}} onClick={handleBack} src={Back} alt="" />
				</div>
				{/* <DataGridTable data={importDataCsv} columns={importColumnsCsv} /> */}
			</div>
		</div>
	);
};
const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(PreviewCsvById));
