import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";
import {checkRoles} from "@src/utils/checkRoles";
import {getSelectOptionsFromDataTeams} from "@src/utils/select";
import {useFeatures} from "@src/utils/useFeatures";
import {PAYOUT_MODELS} from "../clients/clientElements";

const Filters = (props) => {
	const {
		query,
		setQuery,
		cleanQuery,
		tableTab,
		optionsClients,
		locations,
		optionsProjectCategories,
		usersOptions,
		statuses,
		teams,
		agencies,
		allSalesOrganizations,
		handleSearch,
	} = props;
	const {t} = useTranslation();
	const {checkFeature} = useFeatures();
	const filterItems = [
		{
			inputType: "rangePicker",
			placeholder: [t("from"), t("to")],
			field: ["fromDate", "toDate"],
			show: true,
			maxDate: moment().endOf("day").valueOf(),
		},
		{
			inputType: "antd-dropdown",
			label: t("chooseClientType"),
			name: "label",
			value: "value",
			field: "clientPayoutModel",
			isMultiple: false,
			options: PAYOUT_MODELS,
			show: true,
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			dependentFields: ["locationId", "projectCategoryId"],
			label: t("Clients"),
			name: "name",
			value: "value",
			field: "clientId",
			isMultiple: false,
			options: optionsClients || [],
			show: checkRoles("changeClient"),
			key: "name",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			value: "value",
			name: "label",
			dependendFrom: "clientId",
			field: "locationId",
			label: t("Locations"),
			options: getSelectOptionsFromDataTeams(locations, "locationName", "id"),
			isMultiple: false,
			show: checkRoles("changeClient"),
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			value: "value",
			name: "label",
			dependendFrom: "clientId",
			field: "projectCategoryId",
			label: t("projectCategories"),
			options: getSelectOptionsFromDataTeams(optionsProjectCategories, "name", "id"),
			isMultiple: false,
			show: checkRoles("changeClient") && tableTab === "BONUS",
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			name: "label",
			value: "value",
			options: statuses,
			show: checkRoles("filterStatus") && tableTab === "all",
			isMultiple: false,
			label: t("Statuses"),
			field: "systemStatusId",
			key: "statusName",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			name: "label",
			value: "value",
			options: getSelectOptionsFromDataTeams(teams, "name", "id"),
			isMultiple: false,
			label: t("AllTeams"),
			field: "teamId",
			show: checkRoles("filterTeams"),
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			name: "label",
			value: checkRoles("filterAgencies") && "value",
			field: "agencyId",
			options: getSelectOptionsFromDataTeams(agencies, "name", "id"),
			isMultiple: false,
			label: t("AllAgencies"),
			show: checkRoles("filterAgencies"),
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			name: "label",
			value: "value",
			field: "salesOrganizationId",
			options: allSalesOrganizations,
			isMultiple: false,
			label: t("SalesOrganizations"),
			show: checkFeature("Sales_Organization"),
			key: "name",
			optionVal: "id",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			name: "name",
			value: "id",
			options: usersOptions,
			isMultiple: tableTab !== "BONUS",
			label: checkRoles("getUsersByHeadOfSalesId") ? t("Members") : t("Users"),
			field: "userIds",
			show: true,
			key: "name",
			optionVal: "id",
			optionLabel: "name",
			secondLabel: "company",
		},
	];
	return (
		<BigSearchBar
			withFilters
			headerSearch
			filterItems={filterItems}
			setFilterValues={setQuery}
			clearFilterValues={() => cleanQuery(tableTab)}
			hasStrictSearch
			filterValues={query}
			onStrictIconClick={(value) => {
				setQuery({
					...query,
					isExactSearch: value,
				});
			}}
			value={query?.search}
			handleSearch={handleSearch}
		/>
	);
};

export default Filters;
