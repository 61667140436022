import React from "react";
import {actions as contaclessOrderActions} from "@sagas/contactlessOrder/index";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import X from "../../../../assets/img/Xgeneratecode.png";
import GenerateTokenButton from "./generateTokenButton";

const GenerateTokenComponent = (props) => {
	const {match, buttonPhase, resendOrderPin} = props;
	const {orderId} = match.params;
	const handleClick = () => {
		resendOrderPin(orderId);
	};
	return (
		<div style={{marginTop: "150px"}}>
			<div className="generateToken__container">
				<div className="generateToken__container--titleText">
					<div className="generateToken__container--titleText-img">
						<img src={X} alt="" style={{width: "350px", height: "auto"}} />
					</div>
					<span className="generateToken__container--titleText-sorry">SORRY!</span>
					<span className="generateToken__container--titleText-expired">
						the token has expired<span>.</span>
					</span>
				</div>
				<div className="generateToken__container--button">
					<GenerateTokenButton buttonPhase={buttonPhase} handleClick={handleClick} />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	buttonPhase: state.app.contaclessOrder.index.buttonPhase,
});

const mapDispatchToProps = {
	resendOrderPin: contaclessOrderActions.resendOrderPin,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenerateTokenComponent));
