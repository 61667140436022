import CollapsableTable from "@src/common/collapsableTable";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as monitoringsActions} from "@src/store/sagas/app/monitoring/index";
import {useUrlParams} from "@src/utils/useUrlParams";
import Form from "@src/common/Form";
import {columns, expandableTables, inputs} from "./helper";

const Monitoring = ({monitoringsState, getMonitoringData}) => {
	const {t} = useTranslation();
	const {setUrlParams, getUrlParams, removeParams, setModal} = useUrlParams();
	const {monitoringType, modal} = getUrlParams("monitoring-type", "modal");
	const [expandedData, setExpandedData] = useState({
		minPoints: 40,
		maxPoints: 71,
	});
	const [typeColumns, setTypeColumns] = useState([]);
	const {loading, monitorings} = monitoringsState;

	const handleRangeModal = (type) => setModal(type);

	const handleSubmitRange = (values) => {
		setExpandedData({
			type: monitoringType,
			...values,
		});
		handleRangeModal(false);
	};
	const expandHandler = (paramValue) => {
		if (monitoringType === paramValue) {
			removeParams("monitoring-type");
		} else {
			setUrlParams(paramValue, "monitoring-type");
		}
	};

	useEffect(() => {
		if (monitoringType) {
			getMonitoringData({...expandedData, type: monitoringType});
			const cols = columns[monitoringType];
			const formattedCols = cols.map((col) => {
				if (col.dynamic) {
					return {
						...col,
						resizable: true,
						name: col.name
							.replace("40", expandedData.minPoints)
							.replace("71", expandedData.maxPoints),
					};
				}
				return {...col, resizable: true};
			});
			setTypeColumns(formattedCols);
		}
	}, [expandedData, monitoringType]);
	const formData = {
		inputs,
		title: t("Points"),
		submitFn: handleSubmitRange,
	};
	return (
		<>
			<Form
				formData={formData}
				open={modal === "open"}
				handleClose={() => handleRangeModal(false)}
			/>
			<div className="monitoring__container">
				<p className="monitoring__container-title">{t("MonthlyMonitoringReport")}</p>
				{expandableTables
					.filter((item) => item.show)
					.map((item, idx) => (
						<CollapsableTable
							key={`${item.type}__${idx}`}
							data={monitorings}
							editable={item.editable}
							columns={typeColumns}
							loading={loading}
							title={item.title}
							onEditClick={(item) => handleRangeModal("open")}
							item={item}
							onTableExpand={() => expandHandler(item?.type)}
							expanded={monitoringType === item.type}
						/>
					))}
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	monitoringsState: state.app.monitoring.index,
});

const mapDispatchToProps = {
	getMonitoringData: monitoringsActions.getMonitoringData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Monitoring));
