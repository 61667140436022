import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as categoryActions} from "@sagas/projectCategories";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";

const logger = new Logger("Sagas>ProjectCategories>Create");

const PREFIX = "@app/ProjectCategories/create";
export const ADD_CATEGORY = `${PREFIX}ADD_CATEGORY`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_CATEGORY = `${PREFIX}FILL_CATEGORY`;

const _state = {
	loading: false,
	categoryValues: {
		name: "",
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case FILL_CATEGORY:
				draft.categoryValues = action.payload;
				break;
			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) {
					draft.categoryValues = {
						name: "",
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addCatgory: (payload) => createAction(ADD_CATEGORY, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillCatgory: (payload) => createAction(FILL_CATEGORY, {payload}),
};

export const sagas = {
	*addCatgory({payload}) {
		const {values} = payload;
		yield put(actions.setLoading(true));

		try {
			let response;
			if (values?.id) {
				response = yield axios.put(`/projectCategory/update/`, values);
				yield put(categoryActions.editCategoryOnState(values));
				ToastSuccesComponent(i18n.t("CategoryUpdateSuccess"));
			} else {
				response = yield axios.post(`/projectCategory/create/`, values);
				const obj = {
					...values,
					id: response?.data?.data,
				};
				yield put(categoryActions.addCategoryOnState(obj));
				ToastSuccesComponent(i18n.t("CategoryCreateSuccess"));
			}
			yield put(actions.setShowModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_CATEGORY, sagas.addCatgory);
};
