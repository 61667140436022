import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";

const logger = new Logger("Sagas>projectCategories>Index");

const PREFIX = "@app/projectCategories/index";
export const GET_PROJECT_CATEGORIES = `${PREFIX}GET_PROJECT_CATEGORIES`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_PROJECT_CATEGORIES_SUCCESS = `${PREFIX}FETCH_PROJECT_CATEGORIES_SUCCESS`;
export const ADD_CATEGORY_ON_STATE = `${PREFIX}ADD_CATEGORY_ON_STATE`;
export const EDIT_CATEGORY_ON_STATE = `${PREFIX}EDIT_CATEGORY_ON_STATE`;
export const DELETE_CATEGORY_ON_STATE = `${PREFIX}DELETE_CATEGORY_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const FETCH_PROJECT_CATEGORIES_BY_DROPDOWN = `${PREFIX}FETCH_PROJECT_CATEGORIES_BY_DROPDOWN`;
// eslint-disable-next-line max-len
export const FETCH_PROJECT_CATEGORIES_BY_DROPDOWN_SUCCESS = `${PREFIX}FETCH_PROJECT_CATEGORIES_BY_DROPDOWN_SUCCESS`;
// eslint-disable-next-line max-len
export const FETCH_PROJECT_CATEGORIES_BY_ID_DROPDOWN_SUCCESS = `${PREFIX}FETCH_PROJECT_CATEGORIES_BY_ID_DROPDOWN_SUCCESS`;
export const CLEAR_OPTIONS_PROJECT_CATEGORIES = `${PREFIX}CLEAR_OPTIONS_PROJECT_CATEGORIES`;

const _state = {
	projectCategories: [],
	loading: false,
	optionsProjectCategories: [],
	clientsProjectCategories: [],
	page: 1,
	size: 10,
	totalSize: 5,
	search: "",
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload + 1;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = action.payload.value;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;
				break;
			case FETCH_PROJECT_CATEGORIES_SUCCESS:
				draft.projectCategories = action.payload;
				break;
			case FETCH_PROJECT_CATEGORIES_BY_ID_DROPDOWN_SUCCESS:
				draft.optionsProjectCategories = action.payload;
				break;
			case CLEAR_OPTIONS_PROJECT_CATEGORIES:
				draft.optionsProjectCategories = [];
				break;
			case FETCH_PROJECT_CATEGORIES_BY_DROPDOWN_SUCCESS:
				draft.clientsProjectCategories = action.payload?.map((e) => ({
					name: e.name,
					value: e.id,
				}));
				break;
			case ADD_CATEGORY_ON_STATE:
				draft.projectCategories = [action.payload].concat(state.projectCategories);
				break;
			case EDIT_CATEGORY_ON_STATE:
				const index = state.projectCategories.findIndex((c) => c.id === action.payload.id);
				draft.projectCategories[index] = action.payload;
				break;
			case DELETE_CATEGORY_ON_STATE:
				draft.projectCategories = state.projectCategories.filter(
					(category) => category.id !== action.payload,
				);
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getProjectCategories: (payload) => createAction(GET_PROJECT_CATEGORIES, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchProjectCategoriesSucces: (payload) =>
		createAction(FETCH_PROJECT_CATEGORIES_SUCCESS, {payload}),
	addCategoryOnState: (payload) => createAction(ADD_CATEGORY_ON_STATE, {payload}),
	editCategoryOnState: (payload) => createAction(EDIT_CATEGORY_ON_STATE, {payload}),
	deleteCategoryOnState: (payload) => createAction(DELETE_CATEGORY_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterProjectCategories: (payload) => createAction(FILTERS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	fetchProjectCategoriesByDropdown: (payload) =>
		createAction(FETCH_PROJECT_CATEGORIES_BY_DROPDOWN, {payload}),
	fetchProjectCategoriesByDropdownSuccess: (payload) =>
		createAction(FETCH_PROJECT_CATEGORIES_BY_DROPDOWN_SUCCESS, {payload}),
	fetchProjectCategoriesByIdDropdownSuccess: (payload) =>
		createAction(FETCH_PROJECT_CATEGORIES_BY_ID_DROPDOWN_SUCCESS, {payload}),
	clearOptionsProjectCategories: (payload) =>
		createAction(CLEAR_OPTIONS_PROJECT_CATEGORIES, {payload}),
};

export const sagas = {
	*getProjectCategories() {
		yield put(actions.setLoading(true));
		const {size} = yield select((state) => state.app.projectCategories.index);
		const {search} = yield select((state) => state.app.projectCategories.index);
		const {page} = yield select((state) => state.app.projectCategories.index);
		try {
			const response = yield axios.get(
				`/projectCategory/all?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchProjectCategoriesSucces(response?.data?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchProjectCategoriesByDropdown(id) {
		yield put(actions.setLoading(true));
		try {
			if (id.payload) {
				const response = yield axios.get(`/projectCategory/show/${id.payload}`);
				yield put(actions.fetchProjectCategoriesByIdDropdownSuccess(response?.data.data));
			} else {
				const response = yield axios.get(`/projectCategory/drop-down`);
				yield put(actions.fetchProjectCategoriesByDropdownSuccess(response?.data?.data));
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_PROJECT_CATEGORIES, sagas.getProjectCategories);
	yield takeLatest(FETCH_PROJECT_CATEGORIES_BY_DROPDOWN, sagas.fetchProjectCategoriesByDropdown);
};
