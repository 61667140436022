import React from "react";
// import {useTranslation} from "react-i18next";
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import i18n from "../../i18n";
import CustomPopover from "../Popover";
import {AndroidSwitch} from "./antSwitch";

const index = (props) => {
	const {label, checked, switchChangeHandler, infoText = ""} = props;
	const items = [
		{
			key: infoText,
			label: infoText,
			icon: <ShowInfoIcon height="10" width="10" />,
		},
	];
	return (
		<>
			<div className="client_settings_switch_label">
				<span>{i18n.t(`${label}`)}</span>
				{infoText !== "" && (
					<CustomPopover
						menu={{items}}
						customClass="switch-info__container"
						placement="bottomLeft"
					>
						<span className="info-icon">
							<ShowInfoIcon />
						</span>
					</CustomPopover>
				)}
			</div>
			{/* <Switch checked={checked} onChange={switchChangeHandler} color="primary" /> */}
			<FormGroup>
				<FormControlLabel
					checked={checked}
					onChange={switchChangeHandler}
					inputProps={{"aria-label": "ant design"}}
					color="primary"
					control={<AndroidSwitch />}
				/>
			</FormGroup>
		</>
	);
};

export default index;
