import {DatePicker} from "antd";
import React from "react";
import locale from "date-fns/locale/en-GB";
import moment from "moment";
import {DATE_FORMAT} from "../constants";

const CustomDatePicker = (props) => {
	const {
		label,
		value,
		disabled,
		field,
		setFieldValue,
		touched,
		error,
		hidden,
		picker = "day",
		showTime = false,
	} = props;
	if (hidden) return null;
	return (
		<div className="range__picker-wrapper">
			<div className="range__picker-wrapper-container">
				<DatePicker
					disabled={disabled}
					locale={locale}
					picker={picker}
					value={value ? moment(value) : null}
					placeholder={label}
					format={showTime ? "DD.MM.YYYY HH:mm" : DATE_FORMAT}
					showTime={showTime}
					onChange={(value) => {
						const newValue = value ? moment(value).endOf(picker).valueOf() : null;
						setFieldValue(field, showTime ? moment(value).valueOf() : newValue);
					}}
				/>
			</div>
			{touched && error && <span className="errorClass">{error}</span>}
		</div>
	);
};

export default CustomDatePicker;
