import React from "react";

const SelectedTargets = ({name, items, title}) => (
	<>
		<span style={{display: "flex", gap: "3px", flexWrap: "wrap"}}>
			{title} :
			{items ? (
				<span
					className="bold__values"
					style={{display: "flex", gap: "3px", flexWrap: "wrap"}}
				>
					{items.map((item) => item[name]).join(", ")}
				</span>
			) : (
				""
			)}
		</span>
	</>
);
export default SelectedTargets;
