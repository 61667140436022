/* eslint-disable max-len */
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {checkRoles} from "@utils/checkRoles";
import Button from "@src/common/components/button";
import saveIcon from "@assets/img/save-icon.svg";
import {useUrlParams} from "@src/utils/useUrlParams";
import {actions as orderStatusAction} from "@sagas/orders/statuses";
import Tabs from "./Tabs";
import Brand from "./Brand";
import EmailSettings from "./EmailSettings";
import Contracts from "./Contracts";
const ClientSettings = ({
	changeLogo,
	clientId,
	client,
	loading,
	logo,
	changeLogoSuccess,
	updateSettings,
	removeAttachment,
	resetLogo,
	initialLogo,
	getOrderStatuses,
}) => {
	const {t} = useTranslation();
	const {getUrlParams, setUrlParams} = useUrlParams();
	const {currentTab} = getUrlParams("current-tab");
	const [selectedFile, setSelectedFile] = useState(null);
	const [checkedPDF, setCheckedPDF] = useState(true);
	const [checkedOfferEmail, setCheckedOfferEmail] = useState(true);
	const [leads, setLeads] = useState(false);
	const [leadsLocation, setLeadsLocation] = useState(false);
	const [sendEmailForOrder, setSendEmailForOrder] = useState(false);
	const [includeGeneratedPdf, setIncludeGeneratedPdf] = useState(false);
	const [checkedOrderPdfEmail, setCheckedOrderPdfEmail] = useState(true);
	const [description, setDescription] = useState(client?.description);
	const [subject, setSubject] = useState(client?.subject);
	const [switchTouched, setSwitchTouched] = useState(false);
	const [color, setColor] = useState(client?.pdfColor);
	const [email, setEmail] = useState(client?.pdfEmail);
	const [emailName, setEmailName] = useState(client?.pdfEmailName);
	const [uploadedPDFs, setUploadedPDFs] = useState(client?.attachedFiles);
	const [selectedPDFs, setSelectedPDFs] = useState([]);
	// TODO- COMMENTED UNTILL WE FIX PDF GENERIC
	const [generateExternalId, setGenerateID] = useState(client?.generateExternalId);
	const initializeParams = () => {
		setUrlParams(currentTab || t("Brand"), "current-tab");
	};
	const setCurrentTab = (tab) => {
		setUrlParams(t(tab), "current-tab");
	};
	useEffect(() => {
		initializeParams();
		setColor(client?.pdfColor);
		setCheckedPDF(client?.exportPdf);
		setEmail(client?.pdfEmail);
		setEmailName(client?.pdfEmailName);
		setCheckedOfferEmail(client?.sendOfferEmail);
		setCheckedOrderPdfEmail(client?.sendOrderPdfEmail);
		setGenerateID(client?.generateExternalId);
		setLeads(client?.leads);
		setLeadsLocation(client?.leadsLocation);
		setDescription(client?.description);
		setSubject(client?.subject);
		setSendEmailForOrder(client?.sendEmailForOrder);
		setIncludeGeneratedPdf(client?.includeGeneratedPdf);
		setUploadedPDFs(client?.attachedFiles);
		// return () => changeLogoSuccess(null);
		// TODO- COMMENTED UNTILL WE FIX PDF GENERIC
		// setEmailParagraph(client?.emailParagraph);
		// setConfirmationParagraph(client?.confirmationParagraph);
	}, [client]);

	useEffect(() => {
		getOrderStatuses();
	}, []);

	const handleUpdate = () => {
		if (selectedFile) {
			changeLogo({selectedFile, clientId});
		}
		if (
			switchTouched ||
			color !== client?.pdfColor ||
			// TODO- COMMENTED UNTILL WE FIX PDF GENERIC
			generateExternalId !== client?.generateExternalId ||
			// emailParagraph !== client?.emailParagraph ||
			// confirmationParagraph !== client?.confirmationParagraph ||
			email !== client?.pdfColor
		) {
			updateSettings({
				clientId,
				checkedOfferEmail,
				checkedOrderPdfEmail,
				// TODO- COMMENTED UNTILL WE FIX PDF GENERIC
				// confirmationParagraph,
				// emailParagraph,
				// bankParagraph,
				email,
				emailName,
				sendEmailForOrder,
				includeGeneratedPdf,
				description,
				subject,
				uploadedPDFs: [],
			});
		}
		setSelectedPDFs([]);
	};
	const handleChange = (e) => {
		setSelectedFile(e.target.files[0]);
		changeLogoSuccess(URL.createObjectURL(e.target.files[0]));
	};
	const switchChangeHandler = () => {
		setSwitchTouched(true);
		setCheckedPDF(!checkedPDF);
	};
	const tabItems = [
		checkRoles("uploadClientLogo") && t("Brand"),
		checkRoles("seeEmailOnClientSettings") && t("EmailSettings"),
		checkRoles("seeContractsOnClientSettings") && t("Contracts"),
	].filter(Boolean);

	const resetLogoHandler = () => {
		setSelectedFile(initialLogo);
		resetLogo();
	};
	if (!currentTab || !client?.id) return null;

	return (
		<>
			<div className="client__settings__page__header">
				<h1 className="client__settings__title">{t("clientSettings")}</h1>
				{checkRoles("editClientSettings") && (
					<>
						{(selectedFile !== null ||
							switchTouched ||
							color !== client?.pdfColor ||
							checkedOfferEmail !== client?.sendOfferEmail ||
							checkedOrderPdfEmail !== client?.sendOrderPdfEmail ||
							generateExternalId !== client?.generateExternalId ||
							// TODO- COMMENTED UNTILL WE FIX PDF GENERIC
							// bankParagraph !== client?.bankParagraph ||
							// emailParagraph !== client?.emailParagraph ||
							// confirmationParagraph !== client?.confirmationParagraph ||
							email !== "") &&
							currentTab !== t("Contracts") && (
								<Button
									icon={saveIcon}
									type="submit"
									customClassName="saveButton"
									disabled={loading}
									background="linear-gradient(94.98deg, rgb(108, 30, 176) 0%, rgb(64, 0, 119) 100%)"
									leftRounded={true}
									text={t("Save")}
									handleChange={() => handleUpdate(selectedFile)}
									smallButtonWithTooltip
								/>
							)}
					</>
				)}
			</div>
			<div className="user__settings-container">
				<div className="user__settings-container-tabs">
					<Tabs items={tabItems} value={currentTab} setActive={setCurrentTab} />
				</div>
				{currentTab === t("Brand") && (
					<Brand
						selectedFile={selectedFile}
						client={client}
						currentTab={currentTab}
						handleChange={handleChange}
						logo={logo}
						setSelectedFile={setSelectedFile}
						resetLogo={resetLogoHandler}
					/>
				)}
				{currentTab === t("EmailSettings") && (
					<EmailSettings
						currentTab={currentTab}
						handleUpdate={handleUpdate}
						email={email}
						emailName={emailName}
						setEmail={setEmail}
						setEmailName={setEmailName}
						sendEmailForOrder={sendEmailForOrder}
						setSendEmailForOrder={setSendEmailForOrder}
						setIncludeGeneratedPdf={setIncludeGeneratedPdf}
						includeGeneratedPdf={includeGeneratedPdf}
						checkedOfferEmail={checkedOfferEmail}
						setCheckedOfferEmail={setCheckedOfferEmail}
						selectedPDFs={selectedPDFs}
						setSelectedPDFs={setSelectedPDFs}
						removeAttachment={removeAttachment}
						setDescription={setDescription}
						description={description}
						subject={subject}
						setSubject={setSubject}
						checkedOrderPdfEmail={checkedOrderPdfEmail}
						setCheckedOrderPdfEmail={setCheckedOrderPdfEmail}
						setUploadedPDFs={setUploadedPDFs}
						uploadedPDFs={uploadedPDFs}
					/>
				)}
				{currentTab === t("Contracts") && (
					<Contracts
						currentTab={currentTab}
						checkedPDF={checkedPDF}
						switchChangeHandler={switchChangeHandler}
						setLeads={setLeads}
						leads={leads}
						setLeadsLocation={setLeadsLocation}
						leadsLocation={leadsLocation}
						color={color}
						setColor={setColor}
						generateExternalId={generateExternalId}
						setGenerateID={setGenerateID}
						buttonFn={updateSettings}
						clientId={client.id}
					/>
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	client: state.app.client.index.client,
	loading: state.app.client.index.loading,
	logo: state.app.client.index.logoUrl,
	initialLogo: state.app.client.index.initialLogo,
});
const mapDispatchToProps = {
	changeLogo: clientActions.changeLogo,
	changeLogoSuccess: clientActions.changeLogoSuccess,
	resetLogo: clientActions.resetLogoSuccess,
	updateSettings: clientActions.updateSettings,
	removeAttachment: clientActions.removeAttachment,
	getOrderStatuses: orderStatusAction.getOrderStatuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientSettings));
