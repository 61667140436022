import React from "react";
import {scrollToMessage} from "@utils/scroll-to-message";
import {handleInputShowValue} from "@utils/message-converter";
import MediaBox from "@common/messagesComponents/MediaComponent/MediaBox";
import {CloseReplayMessage} from "@src/assets/SvgComponents";
import moment from "moment";
import {renderMessagesFileIcons} from "../helpers";
const ReplyMessage = (props) => {
	const {
		item,
		close = "true",
		setValue,
		width = "100%",
		myMessage,
		handleOpen,
		getMessagePage,
		topicId,
		isReply,
	} = props;
	const {id, fullName, text, files, createdAtTimestamp} = item;
	const formattedTimestamp = moment(createdAtTimestamp).format("h:mm");
	return (
		<>
			{!isReply ? (
				<div
					className="reply__message__container"
					style={{width}}
					onClick={() => scrollToMessage(id, topicId, getMessagePage)}
				>
					<div className="reply__message__container-box-wrapper">
						<div
							className={`reply__message__container-box ${
								myMessage ? "myMessage" : ""
							}`}
							style={{
								borderBottom: close && "1px solid rgb(0 0 0 / 12%)",
							}}
						>
							<div
								className={
									!close && myMessage
										? "top__part"
										: "top__part top__part__active"
								}
							>
								<div className="reply__message__image__container">
									<div className="reply__message__image">
										{fullName?.charAt(0)}
									</div>
								</div>

								<div className="name__message__time__container">
									<div className="name__and__time">
										<span className="message__sender__name">{fullName}</span>
										{close && (
											<div
												className="close_reply_message"
												onClick={(e) => {
													e.stopPropagation();
													setValue(null);
												}}
											>
												<CloseReplayMessage stroke="#fff" />
											</div>
										)}
									</div>
									<div className="name__message__time__container-time">
										<span>{formattedTimestamp}</span>
									</div>
								</div>
							</div>
							<div className="message__content">
								{!close && files?.length > 0 && (
									<div className="file__container">
										{files?.map((file, index) => (
											<MediaBox
												key={index}
												item={file}
												handleOpen={handleOpen}
											/>
										))}
									</div>
								)}
								{close &&
									files?.length > 0 &&
									renderMessagesFileIcons(files?.[0]?.fileType)}
								<p className="message__text">
									{files?.length > 0
										? close && files?.[0]?.fileName
										: handleInputShowValue(text)}
								</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="message__content">
					{!close && files?.length > 0 && (
						<div className="file__container">
							{files?.map((file, index) => (
								<MediaBox key={index} item={file} handleOpen={handleOpen} />
							))}
						</div>
					)}
					{close && files?.length > 0 && renderMessagesFileIcons(files?.[0]?.fileType)}
					<p className="message__text">
						{files?.length > 0
							? close && files?.[0]?.fileName
							: handleInputShowValue(text)}
					</p>
				</div>
			)}
		</>
	);
};

export default ReplyMessage;
