import {put, select, takeLatest} from "redux-saga/effects";
import produce from "immer";
import axios from "axios";
import Logger from "@utils/logger";
import {actions as leadActions} from "@sagas/salesTracker";
import {actions as leadAddressActions} from "@sagas/salesTracker/addresses";
import {ToastInfoComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import createAction from "../../../../utils/action-creator";
const logger = new Logger("SalesTracker delete");

const PREFIX = "@app/salesTracker/delete";

export const DELETE_LEADS = `${PREFIX}DELETE_LEADS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	lead: {},
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteLeads: (payload) => createAction(DELETE_LEADS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};
export const sagas = {
	*deleteLeads({payload}) {
		yield put(actions.setLoading(true));
		const {streetView, query, addressId} = payload;
		const {selectedStatuses} = yield select((state) => state.app.salesTracker.index);
		const {statuses} = yield select((state) => state.app.salesTracker.statuses);

		try {
			const ids = payload?.ids
				?.filter(
					(row) =>
						row?.statusType !== "SUCCESS" &&
						!selectedStatuses?.ids?.includes(row?.statusId),
				)
				?.map((item) => item?.id);
			const payloadObject = {
				statusIds: statuses
					?.filter(
						(it) =>
							selectedStatuses?.ids.includes(it.id) && it?.statusType !== "SUCCESS",
					)
					.map((item) => item.id),
				leadsFilter: query,
				ids,
			};
			yield axios.put(`/lead/delete`, payloadObject);
			if (streetView) {
				yield put(leadAddressActions.getAddresses({query, resetPage: true}));
				yield put(leadAddressActions.clearFetchedPages({addressId}));
			} else {
				yield put(leadActions.deleteLeadsOnState({data: payloadObject, statuses}));
				yield put(leadActions.getLeads({id: payload?.currentUser}));
			}
			ToastSuccesComponent(i18n.t(`Lead${payload?.ids?.length > 1 ? "s" : ""}Deleted`));
			yield put(leadActions.clearSelectedStatuses());
			if (payload?.ids?.some((item) => item?.statusType === "SUCCESS")) {
				ToastInfoComponent(i18n.t("YouCantDeleteSuccess"));
			}
			if (payload.setShowModal) {
				payload.setShowModal(false);
			}
		} catch (error) {
			logger.error("generate error", error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_LEADS, sagas.deleteLeads);
};
