import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as marketPlaceActions} from "@sagas/marketplace/index";
import SetApiKeyComponent from "./components/SetApiKeyComponent";

const MarketPlace = ({marketPlaceApps, setModal}) => (
	<div className="marketplace__container">
		<SetApiKeyComponent />
		<div className="marketplace__container-apps">
			{marketPlaceApps?.map((app) => (
				<div
					key={app.key}
					className={`app__wrapper ${app?.apiKey ? "available" : "unavailable"}`}
					onClick={() => setModal({...app, show: true})}
				>
					<div className="app__wrapper__top">
						<img src={app?.iconImage} alt={app?.name} className="icon" />
						<span className="name">{app?.name}</span>
					</div>
					<div className="app__wrapper__bottom">
						<span className="app__wrapper__bottom-status">
							{app?.apiKey ? "Available" : "Unavailable"}
						</span>
					</div>
				</div>
			))}
		</div>
	</div>
);

const mapStateToProps = (state) => ({
	marketPlaceApps: state.app.marketPlace.index.marketPlaceApps,
	modalState: state.app.marketPlace.index.modalState,
});

const mapDispatchToProps = {
	setModal: marketPlaceActions.setModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketPlace));
