import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as conditionActions} from "@sagas/teamConditionsV1/index";
import {ENDPOINT} from "@src/utils/endpoint";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";

const logger = new Logger("teams client");
const PREFIX = "@app/teamConditionsV1/delete";

export const DELETE_TABLE = `${PREFIX}DELETE_TABLE`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteTable: (payload) => createAction(DELETE_TABLE, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*deleteTable({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {type, id} = payload;
			yield axios.put(ENDPOINT.DELETE_TABLE_V1.replace(":type/:id", `${type}/${id}`));
			yield put(conditionActions.removeTableFromState(id));
			ToastSuccesComponent(i18n.t("ConditionTableDeleted"));
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_TABLE, sagas.deleteTable);
};
