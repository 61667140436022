import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as clientActions} from "@sagas/client";
import Form from "@src/common/Form";
import {useUrlParams} from "@src/utils/useUrlParams";
import {sectionInputs} from "./staticData";

const CreateAndEditSection = (props) => {
	const {genericPdfs, getPdfs, match, createSection, sections, updateSection, loading} = props;
	const {t} = useTranslation();
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id} = getUrlParams("modal", "id");
	const {clientId} = match.params;

	useEffect(() => {
		getPdfs(clientId);
	}, []);
	const inputs = sectionInputs?.map((it) => {
		if (it.field === "pdfId") {
			return {
				...it,
				options: genericPdfs?.map(({fileName, id}) => ({
					label: fileName,
					value: id,
				})),
			};
		}
		return it;
	});
	const formData = {
		inputs,
		title: id ? t("editSection") : t("addSection"),
		id,
		getUrl: `form_sections/:id`,
		submitFn: (values) => {
			const payloadObj = {
				...values,
				clientId: +clientId,
				collapse: values.collapse || false,
			};
			if (id) {
				updateSection({
					values: payloadObj,
					setModal,
				});
			} else {
				payloadObj.nr = sections?.length + 1;
				createSection({
					values: payloadObj,
					setModal,
				});
			}
		},
	};
	return (
		<Form
			open={modal === "section"}
			formData={formData}
			handleClose={() => setModal(false)}
			loading={loading}
		/>
	);
};

const mapStateToProps = (state) => ({
	genericPdfs: state.app.client.index.genericPdfs,
	loading: state.app.client.index.isLoading,
	sections: state.app.client.index.sections,
});
const mapDispatchToProps = {
	getPdfs: clientActions.fetchGenericPdf,
	createSection: clientActions.createSection,
	updateSection: clientActions.updateSection,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateAndEditSection));
