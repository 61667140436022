import React, {useEffect} from "react";
import {Formik} from "formik";
import * as yup from "yup";
import InputComponent from "@common/input";
import ButtonComponent from "@common/button";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation";
import icon from "@assets/img/plus.172.png";
import i18n from "../../../i18n";
import {actions as forgotAction} from "../../../store/sagas/app/auth/forgot";

const validationSchema = yup.object().shape({
	password: yup
		.string()
		.label("Password")
		.required(i18n.t("PasswordRequired"))
		.min(6, i18n.t("PasswordMustBe")),
	confirmPassword: yup
		.string()
		.required(i18n.t("ConfirmPasswordReq"))
		.label("Confirm password")
		// eslint-disable-next-line func-names
		.test("passwords-match", i18n.t("PasswrodMatch"), function (value) {
			return this.parent.password === value;
		}),
});

const NewPassword = (props) => {
	const {navigate, requestSent, checkToken, tokenUsed, match, resetForgotenPassword, loading} =
		props;

	const {token} = match.params;
	useEffect(() => {
		checkToken(token);
		// eslint-disable-next-line
	}, [tokenUsed]);

	return (
		<div className="auth">
			{tokenUsed ? (
				<div className="token__invalid__div">
					<img src={icon} alt="" />
					<h1> {i18n.t("InvalidToken")} </h1>
					<p onClick={() => navigate("/auth/forgot-password")}>
						{i18n.t("RequestNewToken")}{" "}
					</p>
				</div>
			) : (
				<div className="middle">
					<Formik
						initialValues={{password: "", confirmPassword: "", token}}
						validationSchema={validationSchema}
						onSubmit={(values, actions) =>
							resetForgotenPassword({values, formActions: actions})
						}
					>
						{({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
							<form autoComplete="off" noValidate onSubmit={handleSubmit}>
								<>
									<span className="forgot-password__title">
										{i18n.t("PasswordRecovery")}
									</span>
									<InputComponent
										name="password"
										placeholder={i18n.t("NewPassword")}
										type="password"
										errorClass="errorClass"
										errors={errors.password}
										values={values.password}
										handleBlur={handleBlur}
										handleChange={handleChange}
										touched={touched.password}
									/>
									<InputComponent
										name="confirmPassword"
										placeholder={i18n.t("ConfirmPassword")}
										type="password"
										errorClass="errorClass"
										errors={errors.confirmPassword}
										values={values.confirmPassword}
										handleBlur={handleBlur}
										handleChange={handleChange}
										touched={touched.confirmPassword}
									/>
									<ButtonComponent
										buttonText={requestSent ? "Email Sent" : i18n.t("Send")}
										disabled={loading}
										classNames={`authBtn ${requestSent && "disabled"}`}
									/>{" "}
								</>
							</form>
						)}
					</Formik>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	requestSent: state.app.auth.forgot.requestSent,
	tokenUsed: state.app.auth.forgot.tokenUsed,
	loading: state.app.auth.forgot.loading,
});
const mapDispatchToProps = {
	checkToken: forgotAction.checkToken,
	navigate: navigation.navigate,
	resetForgotenPassword: forgotAction.resetForgotenPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewPassword));
