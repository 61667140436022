import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as clientActions} from "@sagas/client";
import {actions as clientDeletedActions} from "@sagas/client/deleted";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../i18n";
const logger = new Logger("Client delete");

const PREFIX = "@app/client/delete";
export const DELETE_CLIENT = `${PREFIX}DELETE_CLIENT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	loading: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteClient: (payload) => createAction(DELETE_CLIENT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*deleteClient({payload}) {
		yield put(actions.setLoading(true));

		try {
			const {id} = payload;
			const {clients: list, size} = yield select((state) => state.app.client.index);
			const item = list.find((e) => e.id === id);
			yield axios.put(`/client/delete/${id}`);
			yield put(clientActions.deleteClientOnState(id));
			yield put(clientDeletedActions.add(item));
			ToastSuccesComponent(i18n.t("ClientDeleteSuccess"));
			yield put(clientActions.clientAddDecreaseTotalSize());
			if (list?.length >= size) {
				yield put(clientActions.fetchClients());
			}
			payload.handleClientModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_CLIENT, sagas.deleteClient);
};
