import ButtonComponent from "@src/common/button";
import {DatePicker} from "antd";
import {useFormik} from "formik";
import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as orderActions} from "@sagas/orders";
import {generateConfig, locale} from "@utils/datepicker-config";
import {validationSchema} from "./helper";

const CalculationExport = ({
	exportCalculations,
	loading,
	billingMonthsDropdown,
	exportWithDates = true,
}) => {
	const {years, months} = billingMonthsDropdown;
	const yearsArr = years?.map((year) => +year?.label);
	const {t} = useTranslation();
	const formik = useFormik({
		initialValues: {
			date: null,
			billingMonth: null,
		},
		validationSchema,
		onSubmit: (values) => exportCalculations(values),
	});
	const disabledDate = (current) => {
		if (!current) {
			return false;
		}
		if (exportWithDates) {
			return false;
		}
		const currentYear = moment(current).year();
		return yearsArr?.length > 0 && !yearsArr.includes(currentYear);
	};
	const {values, touched, errors, setFieldValue, handleSubmit} = formik;
	const handleValues = (value) => {
		setFieldValue("date", value ? moment(value).valueOf() : null);
		if (!exportWithDates && yearsArr?.length > 0) {
			const year = value ? moment(value).year() : null;
			if (year) {
				const monthsOfYear = months[year] || [];
				const currentMonth = moment(value).month();
				const foundedMonth = monthsOfYear?.find((month) => month.monthIdx === currentMonth);
				if (foundedMonth) {
					setFieldValue("billingMonth", foundedMonth);
				}
			} else {
				setFieldValue("billingMonth", null);
			}
		} else {
			setFieldValue("billingMonth", null);
		}
	};
	return (
		<form onSubmit={handleSubmit} className="export__calc__container">
			<div className="export__calc__container-body">
				<div className="export__calc__container-body-picker">
					<DatePicker
						picker="month"
						value={values?.date ? moment(values?.date) : null}
						placeholder={t("SelectMonth")}
						locale={locale}
						generateConfig={generateConfig}
						disabledDate={disabledDate}
						onChange={(value) => handleValues(value)}
					/>
					{touched?.date && errors?.date && (
						<span className="errorClass">{errors?.date}</span>
					)}
				</div>
				<div className="export__calc__container-body-actions">
					<ButtonComponent
						classNames="confirm__button"
						buttonText={t("Export")}
						onClick={handleSubmit}
						disabled={loading}
					/>
				</div>
			</div>
		</form>
	);
};
const mapStateToProps = (state) => ({
	loading: state.app.orders.index.loading,
	billingMonthsDropdown: state.app.calculations.billingMonths.billingMonthsDropdown,
});

const mapDispatchToProps = {
	exportCalculations: orderActions.exportCalculations,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CalculationExport));
