import produce from "immer";
import {put,takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ENDPOINT} from "@src/utils/endpoint";
import i18n from "../../../../i18n";
import _ from "lodash";
const logger = new Logger("Sagas>Calculations>billingMonths");

const PREFIX = "@app/calculations/billingMonths";

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_BILLING_MONTHS = `${PREFIX}GET_BILLING_MONTHS`;
export const GET_BILLING_MONTHS_SUCCESS = `${PREFIX}GET_BILLING_MONTHS_SUCCESS`;
export const GET_BILLING_MONTHS_DROPDOWN = `${PREFIX}GET_BILLING_MONTHS_DROPDOWN`;

const _state = {
	loading: false,
    billingMonths: [],
    billingMonthsDropdown: {
        years: [],
        months: [],
    }
};

const reducer = (state = _state, {type,payload}) =>
	produce(state, (draft) => {
		switch (type) {
            case SET_LOADING:
                draft.loading = payload;
                break;
            case GET_BILLING_MONTHS_SUCCESS:
                draft.billingMonths = [...payload,{
                    month: "allSettlements",
                    fromDate: null,
                    toDate: null,
                    id: null,
                }];
                break;    
            case GET_BILLING_MONTHS_DROPDOWN:
                const newObj = {
                    years: [],
                    months: {},
                };
                const groupedOptions = _.groupBy(payload,"year");
                Object.keys(groupedOptions).forEach((year) => {
                    const currentValues = groupedOptions[year];
                    newObj.years.push({
                        label: year,
                        value: year,
                    });
                    const formattedMonths = currentValues?.map((val,idx) => {
                        return {
                            ...val,
                            value: val.month,
                            label: i18n.t(val.month),
                            billingMonthId: val.id,
                            monthIdx: idx,
                        }
                    });
                    newObj.months[year] = formattedMonths;
                })
                draft.billingMonthsDropdown = newObj;
                break;    
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING , {payload}),
    getBillingMonths: (payload) => createAction(GET_BILLING_MONTHS , {payload}),
    getBillingMonthsSuccess: (payload) => createAction(GET_BILLING_MONTHS_SUCCESS , {payload}),
    getBillingMonthsDropdown: (payload) => createAction(GET_BILLING_MONTHS_DROPDOWN , {payload})
};

export const sagas = {
    *getBillingMonths({payload}) {
        yield put(actions.setLoading(true));
        try {
            const response = yield axios.get(ENDPOINT.GET_BILLING_MONTHS);
            // if(payload.forDropdown) {
                yield put(actions.getBillingMonthsDropdown(response?.data?.data))
            // } else {
                yield put(actions.getBillingMonthsSuccess(response?.data?.data));
            // }
        } catch (error) {
            logger.error(error);
        } finally {
            yield put(actions.setLoading(false));
        }
    }
};

export const watcher = function* w() {
	yield takeLatest(GET_BILLING_MONTHS,sagas.getBillingMonths);
};