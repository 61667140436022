/* eslint-disable no-nested-ternary */
import * as yup from "yup";
import i18n from "@src/i18n";

export const generateYupSchema = (inputs) => {
	const schemaFields = {};
	if (inputs?.length > 0) {
		inputs.forEach((item) => {
			const {
				field,
				inputType,
				required,
				title,
				multiple,
				dependentField,
				disableWhen,
				fields,
				min,
				hidden,
				dependentOption,
				dependentFrom,
				noInput,
				prioritizeValidation,
				noSpaces,
				dependentFromValue,
				max,
				matchField,
			} = item;
			if (hidden || noInput) return;

			let fieldSchema;

			switch (inputType) {
				case "text":
				case "password":
					fieldSchema = yup
						.string()
						.test(
							"not-only-space",
							i18n.t("fieldNonEmpty"),
							(value) => value === null || value === undefined || value.trim() !== "",
						)
						.nullable();
					break;
				case "dropdown":
					fieldSchema = multiple ? yup.array().nullable() : yup.mixed().nullable();
					break;
				case "number":
				case "date":
					fieldSchema = yup.number().nullable();
					break;
				case "groupedSelect":
					fieldSchema = yup.array().nullable();
					break;
				case "teamDropdown":
					fieldSchema = multiple ? yup.array().nullable() : yup.mixed().nullable();
					break;
				case "email":
					fieldSchema = yup
						.string()
						.email(`${title} ${i18n.t("CheckEmailFormat")}`)
						.nullable();
					break;

				default:
					fieldSchema = yup.mixed().nullable();
			}
			if (noSpaces) {
				fieldSchema = fieldSchema.test("no-spaces", i18n.t("NoSpacesFileName"), (value) => {
					const spaceRegex = /\s/;
					if (spaceRegex.test(value)) {
						return false;
					}
					return true;
				});
			}
			if (matchField) {
				fieldSchema = fieldSchema.test(
					"match",
					`${title} ${i18n.t("isRequired")}`,
					function (value) {
						return this.parent[matchField] === value;
					},
				);
			}
			if (required && (prioritizeValidation || !disableWhen)) {
				fieldSchema = fieldSchema.required(`${title} ${i18n.t("isRequired")}`);
			}
			if (required && multiple) {
				fieldSchema = fieldSchema.min(1, `${title} ${i18n.t("isRequired")}`);
			}
			if (required && dependentFromValue?.[0]) {
				fieldSchema = yup[multiple ? "array" : "mixed"]().when([dependentFrom], {
					is: (type) => dependentFromValue.includes(type),
					then: (schema) =>
						multiple
							? schema.min(1, `${title} ${i18n.t("isRequired")}`)
							: schema.required(`${title} ${i18n.t("isRequired")}`),
					otherwise: (schema) => schema.nullable(),
				});
			}
			if (inputType === "number" && min) {
				fieldSchema = fieldSchema.min(min, `${title} ${i18n.t("noNegative")}`);
			}
			if (inputType === "number" && max) {
				fieldSchema = fieldSchema.max(max, `${title} ${i18n.t("noBiggerthan")} ${max}`);
			}
			if (inputType === "rangePicker") {
				fields.forEach((f, index) => {
					if (required[index]) {
						schemaFields[f] = yup
							.number()
							.required(`${title[index]} ${i18n.t("isRequired")}`)
							.nullable();
					} else {
						schemaFields[f] = yup.number().optional().nullable();
					}
				});
			} else if (field.includes(".")) {
				const [obj, itm] = field.split(".");
				schemaFields[obj] = yup.object().shape({
					...(schemaFields[obj]?.fields || {}),
					[itm]: fieldSchema.label(title),
				});
			} else {
				schemaFields[field] = fieldSchema.label(title);
			}
			if (dependentField && inputType === "number") {
				const parentField = inputs?.find((input) => input.field === dependentField);
				if (schemaFields?.[field] && schemaFields?.[parentField?.field]) {
					if (disableWhen) {
						schemaFields[field] = schemaFields[field].when([parentField.field], {
							is: (value) => value === disableWhen,
							then: yup.number().optional().nullable(),
							otherwise: yup
								.number()
								.required(`${title} ${i18n.t("isRequired")}`)
								.nullable(),
						});
					} else {
						schemaFields[field] = schemaFields[field]?.test(
							"dependentFieldMustBeGreaterThanParent",
							`${title} ${i18n.t("mustBeGreaterThan")} ${parentField.title}`,
							function (value) {
								const fromValue = this.resolve(yup.ref(parentField.field));
								return fromValue ? value > fromValue : true;
							},
						);
					}
				}
			}
			// if needed to make a field required/optional based on option selected from dropdown
			// dependentFrom should match name of field
			// dependentOption should match option's value which will make field required
			if (dependentFrom && dependentOption) {
				const parentField = inputs?.find(
					(input) =>
						input.field === dependentFrom || input?.additionalField === dependentFrom,
				);
				if (schemaFields?.[field] && schemaFields?.[parentField?.field]) {
					schemaFields[field] = schemaFields[field].when(
						[parentField?.additionalField || parentField?.field],
						{
							is: (value) => value === dependentOption,
							then: yup
								.mixed()
								.required(`${title} ${i18n.t("isRequired")}`)
								.nullable(),
							otherwise: yup.mixed().optional().nullable(),
						},
					);
				}
			}
		});
	}
	return yup.object().shape(schemaFields);
};
