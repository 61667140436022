const STRING_INPUTS = ["colorPicker", "text", "number", "email"];
export const generateInitialValues = (inputs) => {
	const initialValues = {};
	for (let i = 0; i < inputs?.length; i++) {
		const {multiple, defaultValue, field, inputType, fields, noInput} = inputs[i];
		// eslint-disable-next-line no-continue
		if (noInput) continue;
		if (field?.includes(".")) {
			const [obj, key] = field.split(".");
			if (!initialValues[obj]) {
				initialValues[obj] = {};
			}
			initialValues[obj][key] = defaultValue || "";
		} else if (defaultValue !== undefined && inputType !== "rangePicker") {
			initialValues[field] = defaultValue;
		} else if (inputType === "rangePicker") {
			for (let f = 0; f < fields.length; f++) {
				initialValues[fields?.[f]] = defaultValue?.[f] || null;
			}
		} else if (inputType === "checkbox") {
			initialValues[field] = false;
		} else if (multiple) {
			initialValues[field] = [];
		} else if (STRING_INPUTS.includes(inputType)) {
			initialValues[field] = "";
		} else {
			initialValues[field] = null;
		}
	}
	return initialValues;
};
