import React, {useEffect, useRef} from "react";
import SignatureCanvas from "react-signature-canvas";

const Signature = ({
	setFieldValue,
	element,
	width = 300,
	height = 100,
	signatureRef,
	useCustomRef,
	disabled,
}) => {
	const sig = useRef();
	const ref = useCustomRef ? signatureRef : sig;
	const onSignatureEnd = (setFieldValue, element) => {
		setFieldValue(element.name, ref?.current?.toDataURL());
	};
	useEffect(() => {
		if (ref.current) {
			if (disabled) {
				ref.current.off();
			} else {
				ref.current.on();
			}
		}
	}, [disabled]);
	return (
		<SignatureCanvas
			ref={ref}
			id="signature"
			penColor="black"
			onEnd={() => onSignatureEnd(setFieldValue, element)}
			// backgroundColor={disabled ? "transparent" : "#fff"}
			canvasProps={{
				width,
				height,
				className: `sigCanvas ${disabled && "disabled"}`,
			}}
			// clear
		/>
	);
};
export default Signature;
