/* eslint-disable max-len */
import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {changeOrderFn} from "@utils/functions";
import {actions as deleteaCategoryActions} from "@sagas/projectCategories/deleteClientCategory";
import {ENDPOINT} from "@src/utils/endpoint";
import {baugoList} from "./utils";

const logger = new Logger("Category client");

const PREFIX = "@app/store/sagas/app/category/clientCategory";

// clients
export const FETCH_CLIENT_CATEGORY = `${PREFIX}FETCH_CLIENT_CATEGORY`;
export const FETCH_CLIENT_CATEGORY_SUCCESS = `${PREFIX}FETCH_CLIENT_CATEGORY_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const ADD_CATEGORY_ON_STATE = `${PREFIX}ADD_CATEGORY_ON_STATE`;
export const EDIT_CATEGORY_ON_STATE = `${PREFIX}EDIT_CATEGORY_ON_STATE`;
export const DELETE_CATEGORY_ON_STATE = `${PREFIX}DELETE_CATEGORY_ON_STATE`;
export const DEACTIVATE_CATEGORY_ON_STATE = `${PREFIX}DEACTIVATE_CATEGORY_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const CLIENT_CATEGORY_ADD_TOTAL_SIZE_INCREASE = `${PREFIX}CLIENT_CATEGORY_ADD_TOTAL_SIZE_INCREASE`;
export const CLIENT_CATEGORY_ADD_TOTAL_SIZE_DECREASE = `${PREFIX}CLIENT_CATEGORY_ADD_TOTAL_SIZE_DECREASE`;
export const CHANGE_PROJECT_CATEGORY_ORDER = `${PREFIX}CHANGE_PROJECT_CATEGORY_ORDER`;
export const CHANGE_PROJECT_CATEGORY_ORDER_SUCCESS = `${PREFIX}CHANGE_PROJECT_CATEGORY_ORDER_SUCCESS`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const TOGGLE_CATEGORY_VISIBILITY = `${PREFIX}TOGGLE_CATEGORY_VISIBILITY`;
export const TOGGLE_CATEGORY_VISIBILITY_SUCCESS = `${PREFIX}TOGGLE_CATEGORY_VISIBILITY_SUCCESS`;
export const GET_CLIENT_CATEGORIES_DROPDOWN = `${PREFIX}GET_CLIENT_CATEGORIES_DROPDOWN`;
export const GET_CLIENT_CATEGORIES_DROPDOWN_SUCCESS = `${PREFIX}GET_CLIENT_CATEGORIES_DROPDOWN_SUCCESS`;
export const FETCH_CATEGORY_BY_ID = `${PREFIX}FETCH_CATEGORY_BY_ID`;
export const FETCH_CATEGORY_BY_ID_SUCCESS = `${PREFIX}FETCH_CATEGORY_BY_ID_SUCCESS`;

const _state = {
	clientCategories: [],
	clientCategoriesOrder: [],
	isLoading: false,
	page: 1,
	size: 30,
	totalPages: 1,
	totalSize: 5,
	search: "",
	categoriesOptions: [],
	singleCategory: {},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_CLIENT_CATEGORY_SUCCESS:
				const filteredBaugoList = baugoList(payload);

				draft.clientCategories = filteredBaugoList;
				draft.clientCategoriesOrder = payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case LOADING:
				draft.isLoading = payload;
				break;
			case ADD_CATEGORY_ON_STATE:
				// adding baugo
				if (payload?.projectCategories?.value) {
					const checkClientCategories = state.clientCategoriesOrder.findIndex(
						(item) => item?.id === payload?.projectCategories?.value,
					);
					const cloneClientOrder = [...state.clientCategoriesOrder];
					if (checkClientCategories !== -1) {
						cloneClientOrder[checkClientCategories] = {
							...cloneClientOrder[checkClientCategories],
							baugo: {
								id: payload?.id,
								bonusName: payload?.name,
								parentId: payload?.projectCategories?.value,
							},
						};
					}
					draft.clientCategoriesOrder = cloneClientOrder;
					draft.clientCategories = [
						...state.clientCategories,
						{
							id: payload?.id,
							bonusName: payload?.name,
							parentId: payload?.projectCategories?.value,
						},
					];
				} else {
					// first time creating project category
					draft.clientCategories = [{...payload, baugo: {}}].concat(
						state.clientCategories,
					);
					draft.clientCategoriesOrder = [
						{...payload, baugo: {}},
						...state.clientCategoriesOrder,
					];
				}
				break;
			case EDIT_CATEGORY_ON_STATE:
				const index = state.clientCategories.findIndex((c) => c.id === payload.id);
				// update clientCategories list
				if (payload?.parentId) {
					const foundedParentIdx = state.clientCategories?.findIndex(
						(item) => item.id === payload?.parentId,
					);
					if (foundedParentIdx !== -1) {
						const foundedParent = state.clientCategories[foundedParentIdx];
						const baugoIdx = foundedParent?.baugos?.findIndex(
							(baugo) => baugo.id === payload.id,
						);

						if (baugoIdx !== -1) {
							const updatedBaugos = [...foundedParent.baugos];
							updatedBaugos[baugoIdx] = payload;

							const newParent = {
								...foundedParent,
								baugos: updatedBaugos,
							};
							const updatedClientCategories = [...state.clientCategories];
							updatedClientCategories[foundedParentIdx] = newParent;
							draft.clientCategories = updatedClientCategories;
						}
					}
				}
				if (state.clientCategories[index]?.bonusName) {
					const obj = {
						...payload,
						bonusName: payload?.name,
					};
					delete obj.name;
					draft.clientCategories[index] = obj;
				} else {
					draft.clientCategories[index] = payload;
				}
				break;
			case DEACTIVATE_CATEGORY_ON_STATE:
				const clientCategories = state.clientCategories.filter(
					(category) => category.id !== payload.id && category.parentId !== payload.id,
				);
				if (state.page !== 1 && clientCategories.length === 0) {
					draft.page = 1;
				} else {
					draft.clientCategories = clientCategories;
				}
				draft.clientCategoriesOrder = state.clientCategoriesOrder.filter(
					(category) => category.id !== payload,
				);
				break;
			case DELETE_CATEGORY_ON_STATE:
				draft.clientCategories = state.clientCategories.filter(
					(category) => category.id !== payload,
				);
				break;
			case CLIENT_CATEGORY_ADD_TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case CLIENT_CATEGORY_ADD_TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			case CHANGE_PROJECT_CATEGORY_ORDER_SUCCESS:
				const itemIndex = state.clientCategoriesOrder.findIndex(
					(item) => item?.id === payload?.id,
				);
				const newValue = changeOrderFn(
					state?.clientCategoriesOrder,
					itemIndex,
					payload?.action,
				);
				const filteredBaugoList2 = baugoList(newValue);
				draft.clientCategoriesOrder = newValue;
				draft.clientCategories = filteredBaugoList2;
				break;
			case TOGGLE_CATEGORY_VISIBILITY_SUCCESS:
				draft.clientCategories = state?.clientCategories?.map((item) =>
					item.id === payload ? {...item, isHidden: !item.isHidden} : item,
				);
				break;
			case GET_CLIENT_CATEGORIES_DROPDOWN_SUCCESS:
				draft.categoriesOptions = payload?.map((item) => ({
					...item,
					value: item.id,
					label: item.name,
				}));
				break;
			case FETCH_CATEGORY_BY_ID_SUCCESS:
				draft.singleCategory = payload;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchClientCategories: (payload) => createAction(FETCH_CLIENT_CATEGORY, {payload}),
	fetchClientCategoriesSuccess: (payload) =>
		createAction(FETCH_CLIENT_CATEGORY_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	addCategoryOnState: (payload) => createAction(ADD_CATEGORY_ON_STATE, {payload}),
	editCategoryOnState: (payload) => createAction(EDIT_CATEGORY_ON_STATE, {payload}),
	deleteCategoryOnState: (payload) => createAction(DELETE_CATEGORY_ON_STATE, {payload}),
	deactivateCategoryOnState: (payload) => createAction(DEACTIVATE_CATEGORY_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterCategories: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	clientCategoryAddIncreaseTotalSize: (payload) =>
		createAction(CLIENT_CATEGORY_ADD_TOTAL_SIZE_INCREASE, {payload}),
	clientCategoryAddDecreaseTotalSize: (payload) =>
		createAction(CLIENT_CATEGORY_ADD_TOTAL_SIZE_DECREASE, {payload}),
	changeProjectCategoryOrder: (payload) => createAction(CHANGE_PROJECT_CATEGORY_ORDER, {payload}),
	changeProjectCategoryOrderSuccess: (payload) =>
		createAction(CHANGE_PROJECT_CATEGORY_ORDER_SUCCESS, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	toggleCategoryVisibility: (payload) => createAction(TOGGLE_CATEGORY_VISIBILITY, {payload}),
	toggleCategoryVisibilitySuccess: (payload) =>
		createAction(TOGGLE_CATEGORY_VISIBILITY_SUCCESS, {payload}),
	getClientCategoriesDropdown: (payload) =>
		createAction(GET_CLIENT_CATEGORIES_DROPDOWN, {payload}),
	getclientCategoriesDropdownSuccess: (payload) =>
		createAction(GET_CLIENT_CATEGORIES_DROPDOWN_SUCCESS, {payload}),
	fetchCategoryById: (payload) => createAction(FETCH_CATEGORY_BY_ID, {payload}),
	fetchCategoryByIdSuccess: (payload) => createAction(FETCH_CATEGORY_BY_ID_SUCCESS, {payload}),
};

export const sagas = {
	*fetchClientCategories({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size, page} = yield select(
				(state) => state.app.projectCategories.clientCategory,
			);
			const {clientId, search} = payload;
			const response = yield axios.get(
				// eslint-disable-next-line max-len
				`/projectCategory/all/${clientId}?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchClientCategoriesSuccess(response?.data?.data.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeProjectCategoryOrder({payload}) {
		yield put(actions.setLoading(true));
		try {
			if (payload?.newRow !== undefined) {
				yield axios.put("/projectCategory/change", {
					id: payload?.id,
					newRow: payload?.newRow,
				});
				yield put(actions.changeProjectCategoryOrderSuccess(payload));
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*toggleCategoryVisibility({payload}) {
		yield put(deleteaCategoryActions.setLoading(true));
		try {
			const {id, closeModal, isHidden} = payload;
			yield axios.post(ENDPOINT.TOGGLE_CATEGORY, {
				id,
				isHidden,
			});
			yield put(actions.toggleCategoryVisibilitySuccess(id));
			closeModal();
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(deleteaCategoryActions.setLoading(false));
		}
	},
	*getClientCategoriesDropdown({payload}) {
		try {
			const response = yield axios.get(`/projectCategory/drop-down/${payload}`);
			yield put(actions.getclientCategoriesDropdownSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*fetchCategoryById({payload}) {
		try {
			const response = yield axios.get(`/projectCategory/${payload}`);
			yield put(actions.fetchCategoryByIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_CLIENT_CATEGORY, sagas.fetchClientCategories);
	yield takeLatest(CHANGE_PROJECT_CATEGORY_ORDER, sagas.changeProjectCategoryOrder);
	yield takeLatest(TOGGLE_CATEGORY_VISIBILITY, sagas.toggleCategoryVisibility);
	yield takeLatest(GET_CLIENT_CATEGORIES_DROPDOWN, sagas.getClientCategoriesDropdown);
	yield takeLatest(FETCH_CATEGORY_BY_ID, sagas.fetchCategoryById);
};
