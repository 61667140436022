import {LockedKeyIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation/";

const LockedComponent = ({loggedUser, navigate}) => {
	const {t} = useTranslation();

	useEffect(() => {
		if (!loggedUser?.locked) {
			navigate("/admin/dashboard");
		}
	}, []);

	return (
		<div className="lockedPage__container">
			<div className="lockedPage__container_iconTitle">
				<LockedKeyIcon />
				<span>{t("LockedAccount")}</span>
			</div>
			<div className="lockedPage__container_text">
				<span>{t("LockedText")}</span>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
});
const mapDispatchToProps = {
	navigate: navigation.navigate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LockedComponent));
