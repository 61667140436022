import {ShowInfoIconBlack} from "@src/assets/SvgComponents";
import React from "react";
import {useTranslation} from "react-i18next";

const InformationTooltip = ({data}) => {
	const {t} = useTranslation();
	return (
		<div className="tooltip__wrapper">
			<div className="tooltip__wrapper-header">
				<ShowInfoIconBlack />
				<span>Information</span>
			</div>
			<p className="tooltip__wrapper-body">{data}</p>
		</div>
	);
};

export default InformationTooltip;
