/* eslint-disable max-len */
import axios from "axios";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {generateInitialValues} from "./generateInitialValues";
import {generateYupSchema} from "./generateValidationSchema";

const getData = async (url, id) => {
	const response = await axios.get(url.replace(":id", id));
	return response?.data?.data;
};

export const useForm = (formData, open, onMount) => {
	const {inputs, submitFn, id, getUrl} = formData;
	const [initialValues, setInitialValues] = useState({});
	const [pending, setPending] = useState(false);
	const validationSchema = generateYupSchema(inputs);
	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values, actions) => submitFn(values, actions),
	});
	useEffect(() => {
		const fetchData = async () => {
			setPending(true);
			try {
				if (id) {
					const initializeInputs = async (values) => {
						inputs.map(async (inp) => {
							if (inp.fetchOnChange && values?.[inp.field]) {
								await inp?.fetchOnChange(values?.[inp.field]);
							}
						});
					};

					if (formData?.initialValues) {
						setInitialValues(formData.initialValues);
						await initializeInputs(formData.initialValues);
					} else {
						const data = await getData(getUrl, id);
						if (onMount) onMount(data);
						await initializeInputs(data);
						setInitialValues(data);
					}
				} else {
					const values = generateInitialValues(inputs);
					setInitialValues(values);
				}
			} catch (error) {
				return error;
			} finally {
				setPending(false);
			}
		};

		if (open) {
			fetchData();
		}
	}, [id, formData?.initialValues, open]);
	return {formik, pending};
};
