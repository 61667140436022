import moment from "moment";
import React from "react";

const Comments = ({user, status, statusColor, createdAt, lastName, firstName}) =>
	status !== undefined && (
		<div className="comment_wrapper">
			<div className="user_avatar">
				<span>
					{firstName?.charAt(0)}
					{lastName?.charAt(0)}
				</span>
			</div>
			<div className="comment__container">
				<div className="comment_info">
					<p className="comment_user">{user}</p>
					<p className="comment_time">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</p>
				</div>
				<div className="comment">
					<p className="comment_title" style={{color: statusColor}}>
						{status}
					</p>
				</div>
			</div>
		</div>
	);

export default Comments;
