import React from "react";
import {useTranslation} from "react-i18next";
import {fields} from "./helper";
import "./styles.scss";

const FinancialInformation = ({singleUser}) => {
	const {t} = useTranslation();
	// Function to render individual items
	const renderItems = (items) =>
		items.map(({key, type}, index) => {
			let span;
			if (type === "simpleView") {
				span = singleUser[key]?.name;
			} else {
				span = singleUser[key];
			}

			return (
				<div
					className="financial-information-container--body--item--field"
					key={`${index + key}`}
				>
					<div className="financial-information-container--body--item--field-key">
						<span>{t(key)}</span>
						<span>:</span>
					</div>
					<div className="financial-information-container--body--item--field-value">
						<span>{span ? <>{span}</> : "-"}</span>
					</div>
				</div>
			);
		});

	// Mapping through 'fields' to render each field set
	const renderFields = fields.map(({title, items}, index) => (
		// Each field set has a unique key using 'title'
		<div className="financial-information-container--body--item" key={`${index + title}`}>
			<p className="financial-information-container--body--item--title">{title}</p>
			{/* Render the items for each field set */}
			{renderItems(items)}
		</div>
	));

	// Returning the JSX for the component
	return (
		<div className="financial-information-container">
			<h3>{t("Distributionchannel")}</h3>
			<div className="financial-information-container--body">{renderFields}</div>
		</div>
	);
};

export default FinancialInformation;
