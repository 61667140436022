/* eslint-disable max-len */
import React from "react";
import {connect} from "react-redux";
import {actions as trainingActions} from "@sagas/trainings";
import {actions as createTrainingActions} from "@sagas/trainings/create";
import _ from "lodash";
import {useUrlParams} from "@src/utils/useUrlParams";
import Form from "@src/common/Form";
import {useTranslation} from "react-i18next";
import {categoryInputs} from "./helper";

const CreateCategoryModal = (props) => {
	const {addCategory, updateTemplateCategory, deleteCategoryById, setModal, categoryLoading} =
		props;
	const {getUrlParams} = useUrlParams();
	const {t} = useTranslation();
	const {modal, id} = getUrlParams("modal", "id");
	const closeModal = () => {
		setModal(false);
	};
	const handleSubmit = (values) => {
		if (values.id) {
			updateTemplateCategory(values);
		} else {
			addCategory(values);
		}
		closeModal();
	};
	const formData = {
		inputs: categoryInputs?.map((item) => {
			if (item.inputType === "subCategoriesRender") {
				return {
					...item,
					deleteFn: deleteCategoryById,
				};
			}
			return item;
		}),
		getUrl: `/template/category/get/:id`,
		id,
		submitFn: handleSubmit,
		title: id ? t("categoryEdit") : t("CreateCategory"),
	};
	return (
		<Form
			open={modal === "category"}
			handleClose={closeModal}
			formData={formData}
			loading={categoryLoading}
		/>
	);
};

const mapStateToProps = (state) => ({
	categoryLoading: state.app.trainings.create.categoryLoading,
});
const mapDispatchToProps = {
	deleteCategoryById: trainingActions.deleteCategoryById,
	addCategory: createTrainingActions.addTemplateCategory,
	updateTemplateCategory: createTrainingActions.updateTemplateCategory,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateCategoryModal);
