import {CloseUploadModalIcon} from "@assets/SvgComponents";
import ModalComponent from "@common/modal";
import ButtonComponent from "@common/button";
import {getCurrentUser} from "@utils/currentUser";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as messagesActions} from "@sagas/messages";
import TeamForm from "@components/dashboard/Users/Team/TeamForm";
import {actions as createMessagesActions} from "@sagas/messages/create";
import {actions as usersActions} from "@sagas/users/users";
import {Formik} from "formik";
import * as yup from "yup";
import {useUrlParams} from "@src/utils/useUrlParams";
import CustomSelect from "@src/common/Select";
import {channelElements} from "../MessagesContainer/MessageElements";
import {RenderTabs} from "./helper";

const ChatInfoModal = (props) => {
	const {
		open,
		insideTopic,
		setShowChatInfoModal,
		getFiles,
		setQuery,
		clearQuery,
		query,
		leaveChannelLoading,
		match,
		channelInitialValues,
		addMembers,
		loggedUser,
		usersByTenant,
		getUsersByTenant,
		addingUserLoader,
		leaveChannel,
		removeUserFromChannel,
		showAddUserToChannel,
		setShowAddUserToChannelModal,
		groupInitialValues,
		correctGroupName,
		correctTopicName,
		totalFiles,
		mediaLoading,
		getLinks,
		fetchTopicsByTopicId,
	} = props;
	const {topicMembers, topicId, topicType, ownerId} = insideTopic;
	const {id} = match.params;
	const currentUser = getCurrentUser();
	const {setUrlParams, getUrlParams, removeParams} = useUrlParams();
	const {tab} = getUrlParams("tab");
	const initializeParams = () => {
		if (open) {
			const tabToInsert = topicType === "PRIVATE" ? "personalInfo" : "members";
			setUrlParams(tab || tabToInsert);
		} else {
			removeParams("tab");
		}
	};
	const {t} = useTranslation();
	const [assignAdmin, setAssignAdmin] = useState(false);
	const [leaveChannelModal, setLeaveChannelModal] = useState(false);
	const userToDisplay = topicMembers?.find((member) => member.id !== currentUser?.id);
	let usersOptions = usersByTenant;
	const isOwner = ownerId === currentUser.id;
	const adminOptions = topicMembers?.filter((item) => item?.id !== ownerId);
	usersOptions = usersOptions?.map((user) => ({
		...user,
		name: `${user?.firstName} ${user?.lastName}`,
		value: `${user?.firstName} ${user?.lastName}`,
	}));
	const handleClose = () => {
		clearQuery();
		setShowChatInfoModal(false);
		setShowAddUserToChannelModal(false);
	};
	useEffect(() => {
		if (open) {
			// fetchTopicsByTopicId(id);
		}
		getUsersByTenant();
	}, [open, id]);
	useEffect(() => {
		initializeParams();
	}, [open, topicType, id]);

	const handleRemoveUser = (id) => {
		const checkUser = topicMembers?.find((member) => member?.id === id);
		if (checkUser) {
			removeUserFromChannel({
				topicId,
				userId: id,
				type: topicType,
			});
		}
	};
	useEffect(() => {
		if (tab === "media") {
			setQuery({
				...query,
				topicId: id,
				page: 1,
				size: 32,
			});
			getFiles();
		} else if (tab === "links") {
			setQuery({
				...query,
				topicId: id,
				page: 1,
				size: 32,
			});
			getLinks();
		}
	}, [tab]);

	if (!tab) return null;
	const handleTabClick = (value) => {
		if (value === "personalInfo" || value === "members") {
			clearQuery();
		}
		setUrlParams(value, "tab");
	};

	return (
		<>
			<ModalComponent
				open={leaveChannelModal}
				handleClose={() => setLeaveChannelModal(false)}
			>
				<div className="delete_user_modal">
					<p className="delete_user_modal-title">{t("AreYouSureToLeaveChannel")}</p>
					{assignAdmin && adminOptions?.length > 0 && (
						<Formik
							initialValues={{
								newAdmin: null,
							}}
							validationSchema={yup.object().shape({
								newAdmin: yup.object().required(t("newAdminReq")).nullable(),
							})}
							onSubmit={(values) => {
								leaveChannel({
									topicId,
									newAdmin: values?.newAdmin.id,
								});
								setLeaveChannelModal(false);
								setShowChatInfoModal(false);
							}}
						>
							{({values, setFieldValue, handleSubmit, errors, touched}) => (
								<div className="assign-admin-dropdown">
									<form onSubmit={handleSubmit}>
										<CustomSelect
											options={adminOptions.map((adm) => ({
												...adm,
												label: adm?.name,
												value: adm?.id,
											}))}
											onClear={() => setFieldValue("newAdmin", "")}
											onSelect={(value, option) =>
												setFieldValue("newAdmin", option)
											}
											showSearch
											allowClear
											value={values?.newAdmin}
											placeholder={t("AssignNewAdmin")}
											optionFilterProp="label"
											errors={errors?.newAdmin}
											touched={touched?.newAdmin}
										/>
										<div className="actions">
											<ButtonComponent
												onClick={() => setLeaveChannelModal(false)}
												buttonText={t("No")}
												newClass="go_back-btn"
											/>
											<ButtonComponent type="submit" buttonText={t("Yes")} />
										</div>
									</form>
								</div>
							)}
						</Formik>
					)}
					{!assignAdmin && (
						<div className="actions">
							<ButtonComponent
								onClick={() => setLeaveChannelModal(false)}
								buttonText={t("No")}
								newClass="go_back-btn"
							/>
							<ButtonComponent
								onClick={() => {
									leaveChannel({topicId});
									setLeaveChannelModal(false);
									setShowChatInfoModal(false);
								}}
								buttonText={t("Yes")}
							/>
						</div>
					)}
				</div>
			</ModalComponent>
			<ModalComponent
				open={showAddUserToChannel}
				handleClose={() => setShowAddUserToChannelModal(false)}
				customClassNames="add_users-channel_modal"
				positionModal="right"
				width="560"
				hideBackdrop={true}
			>
				<TeamForm
					leaveChannelLoading={leaveChannelLoading}
					match={match}
					isChannel={true}
					elements={channelElements}
					initialValues={
						topicType === "CHANNEL" ? channelInitialValues : groupInitialValues
					}
					setShowModal={setShowAddUserToChannelModal}
					handleSubmitFr={addMembers}
					editText={topicType === "CHANNEL" ? t("EditChannel") : t("EditGroup")}
					loggedUser={loggedUser}
					options={{
						users: usersOptions,
					}}
					removeUserFromChannel={removeUserFromChannel}
					handleRemoveUserFromChannel={handleRemoveUser}
					loading={addingUserLoader}
					insideTopic={insideTopic}
				/>
			</ModalComponent>
			<ModalComponent
				open={open}
				positionModal="right"
				customClassNames="chat_info_container"
				handleClose={handleClose}
				width="600"
			>
				<div className="chat_info_container-header">
					<div className="title">
						{topicType === "PRIVATE" ? t("ContactInfo") : t("GroupInfo")}
					</div>
					<div className="close_modal-icon" onClick={handleClose}>
						<CloseUploadModalIcon width="12" height="12" fill="#979797" />
					</div>
				</div>
				<div className="chat_info_container-info">
					<div className="photo_and_name">
						<div className="image">
							{topicType === "PRIVATE" && userToDisplay?.imgUrl !== null ? (
								<img src={userToDisplay?.imgUrl} alt="" />
							) : (
								<div className="channel_logo">#</div>
							)}
						</div>
						<p className="name">
							{topicType === "GROUP" ? correctGroupName : correctTopicName}
						</p>
						{topicType === "PRIVATE" && (
							<span className="user_role">
								{t(`${userToDisplay?.role?.name}`).toUpperCase()}
							</span>
						)}
					</div>
				</div>
				<div className="chat_info_container-tabs">
					{topicType === "PRIVATE" ? (
						<div
							className={`personal_info-tab ${tab === "personalInfo" && "active"}`}
							onClick={() => handleTabClick("personalInfo")}
						>
							{t("PersonalInformation")}
						</div>
					) : (
						<div
							className={`channel_participiants-tab ${tab === "members" && "active"}`}
							onClick={() => handleTabClick("members")}
						>
							{t("Participants")}
						</div>
					)}
					<div
						className={`media-tab ${tab === "media" && "active"}`}
						onClick={() => handleTabClick("media")}
					>
						{t("Media")}
					</div>
					<div
						className={`media-tab ${tab === "links" && "active"}`}
						onClick={() => handleTabClick("links")}
					>
						{t("Links")}
					</div>
				</div>
				<RenderTabs
					userToDisplay={userToDisplay}
					currentTab={tab}
					handleRemoveUser={handleRemoveUser}
					currentUser={currentUser}
					insideTopic={insideTopic}
					totalFiles={totalFiles}
					mediaLoading={mediaLoading}
					query={query}
					setShowChatInfoModal={setShowChatInfoModal}
				/>
				{topicType === "CHANNEL" && (
					<>
						<div className="chat_info_container-actions">
							<span
								onClick={() => {
									setLeaveChannelModal(true);
									if (isOwner && adminOptions?.length > 0) {
										setAssignAdmin(true);
									}
								}}
							>
								{t("leaveChannel")}
							</span>
						</div>
					</>
				)}
			</ModalComponent>
		</>
	);
};

const mapStateToProps = (state) => ({
	query: state.app.messages.index.query,
	leaveChannelLoading: state.app.messages.index.leaveChannelLoading,
	channelInitialValues: state.app.messages.create.channelInitialValues,
	groupInitialValues: state.app.messages.create.groupInitialValues,
	loggedUser: state.app.users.index.loggedUser,
	usersByTenant: state.app.users.index.usersByTenant,
	channel: state.app.messages.index.channel,
	addingUserLoader: state.app.messages.create.addingUserLoader,
	showAddUserToChannel: state.app.messages.create.showAddUserToChannel,
	insideTopic: state.app.messages.index.insideTopic,
	totalFiles: state.app.messages.index.totalFiles,
	mediaLoading: state.app.messages.index.mediaLoading,
});

const mapDispatchToProps = {
	getFiles: messagesActions.getFiles,
	setQuery: messagesActions.setQuery,
	clearQuery: messagesActions.clearQuery,
	setShowAddUserToChannelModal: createMessagesActions.setShowModal,
	addMembers: createMessagesActions.addMembers,
	getUsersByTenant: usersActions.getUsersByTenant,
	leaveChannel: messagesActions.leaveChannel,
	removeUserFromChannel: messagesActions.removeUserFromChannel,
	getLinks: messagesActions.getLinks,
	fetchTopicsByTopicId: messagesActions.fetchTopicsByTopicId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChatInfoModal));
