import {Autocomplete, TextField} from "@mui/material";
import {useFormik} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {actions as importActions} from "@sagas/salesTracker/imports";
import * as yup from "yup";
import ModalComponent from "@common/modal";
import ButtonComponent from "@src/common/button";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {getSelectOptionsFromDataTeams} from "@utils/select";

function DeleteImportedLeadModal({
	deleteModal,
	setDeleteModal,
	importItem,
	deleteCsv,
	statuses,
	leadStatuses,
}) {
	const {t} = useTranslation();
	const currentLanguage = localStorage.getItem("language");
	const nameBasedOnCurrLanguage = currentLanguage === "en" ? "name" : "nameDe";
	const [filteredStatuses, setFilteredStatuses] = useState([]);

	const formik = useFormik({
		initialValues: {
			status: null,
			leadStatuses: [],
		},
		validationSchema: yup.object().shape({
			status: yup.object().required("Status is required").nullable(),
		}),
		onSubmit: (values, {resetForm}) => {
			deleteCsv({importItem, ...values});
			setDeleteModal(false);
			resetForm();
		},
	});
	const {values, touched, errors, setFieldValue, handleSubmit, resetForm} = formik;
	return (
		<ModalComponent
			open={deleteModal}
			handleClose={() => {
				setDeleteModal(false);
				resetForm();
			}}
			disableEnforceFocus
			width="500px"
			customClassNames="upload__overlay"
			positionModal="center"
		>
			<form onSubmit={handleSubmit}>
				<div className="delete__modal__component__import">
					<h1>{t("AreYouSureToDeleteImport")}</h1>
					<div className="delete__modal__component__import__autocomplete">
						<Autocomplete
							disableClearable={false}
							disableCloseOnSelect={false}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option?.label}
							getOptionSelected={(option, value) => option?.value === value?.value}
							limitTags={20}
							multiple={false}
							value={values?.status}
							onChange={(event, value) => {
								if (value) {
									setFieldValue("status", value);
									const filteredArray = leadStatuses?.filter(
										(item) => item?.statusId === value?.value,
									);
									setFilteredStatuses(filteredArray);
								} else {
									setFieldValue("status", null);
									setFilteredStatuses([]);
									setFieldValue("leadStatuses", []);
								}
							}}
							options={getSelectOptionsFromDataTeams(
								statuses,
								nameBasedOnCurrLanguage,
								"id",
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="label"
									name="label"
									placeholder={t("Status")}
									variant="outlined"
								/>
							)}
						/>
						{touched?.status && errors?.status && (
							<span className="errorClass">{errors?.status}</span>
						)}
					</div>
					<div className="delete__modal__component__import__autocomplete">
						<Autocomplete
							disableClearable={false}
							disableCloseOnSelect={false}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option?.label}
							getOptionSelected={(option, value) => option?.value === value?.value}
							limitTags={20}
							multiple={true}
							disabled={values?.status === null}
							onChange={(event, value) => setFieldValue("leadStatuses", value)}
							value={values?.leadStatuses}
							options={getSelectOptionsFromDataTeams(
								filteredStatuses,
								nameBasedOnCurrLanguage,
								"id",
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="label"
									name="label"
									placeholder={t("LeadStatuses")}
									variant="outlined"
								/>
							)}
						/>
					</div>
					<div className="delete__modal__component__import__buttons">
						<ButtonComponent
							classNames="cancel__button"
							buttonText={t("Cancel")}
							type="button"
							onClick={() => setDeleteModal(false)}
						/>
						<ButtonComponent classNames="confirm__button" buttonText={t("Confirm")} />
					</div>
				</div>
			</form>
		</ModalComponent>
	);
}

const mapStateToProps = (state) => ({
	leadStatuses: state.app.salesTracker.leadStatus.leadStatuses,
	statuses: state.app.salesTracker.statuses.statuses,
});

const mapDispatchToProps = {
	deleteCsv: importActions.deleteCsv,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteImportedLeadModal));
