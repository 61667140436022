import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "../../store/sagas/app/navigation";

function TableCustomColumn(props) {
	const {row, navigate, link} = props;
	const handleClick = () => {
		if (!row.deactivatedBy && !row?.deactivatedAt) {
			return navigate(`${link}/${row?.id}`);
		}
	};
	return (
		<div className="user__table__photo__and__name" onClick={handleClick}>
			{row?.imgUrl && row?.imgUrl !== null ? (
				<img src={row.imgUrl} alt="" />
			) : (
				<div className="user__table__initials">
					<span>{row?.firstName?.charAt(0)}</span>
				</div>
			)}
			<p>{row?.productName ? row?.productName : `${row?.firstName}  ${row?.lastName}`}</p>
		</div>
	);
}

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(TableCustomColumn));
