import React from "react";
import {useTranslation} from "react-i18next";
import MediaComponent from "../MediaComponent";
import LinksComponent from "./LinksComponent";
import ParticipantsComponent from "./ParticipantsComponent";
import PersonalInfoComponent from "./PersonalInfoComponent";

export const RenderTabs = (props) => {
	const {
		currentTab,
		insideTopic,
		currentUser,
		userToDisplay,
		handleRemoveUser,
		totalFiles,
		query,
		mediaLoading,
		setShowChatInfoModal,
	} = props;
	const {ownerId, topicMembers} = insideTopic;
	const {t} = useTranslation();
	const owner = topicMembers?.find((member) => member?.id === ownerId);
	const isOwner = owner?.id === currentUser?.id;
	switch (currentTab) {
		case "media":
			return (
				<MediaComponent
					totalFiles={totalFiles}
					query={query}
					mediaLoading={mediaLoading}
					canView={true}
					showTitle={false}
					customClassName="chat_info_container-media-messages"
					boxClassName="chat_info_container-media-wrapperbox"
				/>
			);
		case "members":
			return (
				<ParticipantsComponent
					isOwner={isOwner}
					owner={owner}
					handleRemoveUser={handleRemoveUser}
				/>
			);
		case "links":
			return <LinksComponent setShowChatInfoModal={setShowChatInfoModal} />;
		default:
			return <PersonalInfoComponent userToDisplay={userToDisplay} />;
	}
};
