import ModalComponent from "@common/modal";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as clientActions} from "@sagas/client";
import {actions as importOrderActions} from "@sagas/orders/orderImports";
import {actions as teamActions} from "@sagas/teams";
import {actions as usersActions} from "@sagas/users/users";
import {checkRoles} from "@src/utils/checkRoles";
import UploadImport from "./uploadImport";
import {userRolesDropdown} from "../helper";

const UploadInput = ({
	setShowUploadModal,
	showUploadFileModal,
	getClients,
	getTeams,
	getAgencies,
	getUserDropdown,
	totalSize,
	orderImportState,
	setShowModal,
	importType,
	loggedUser,
	getUsersByLeaderId,
}) => {
	const [counter, setCounter] = useState(1);
	const {showModal} = orderImportState;

	useEffect(() => {
		getClients();
		if (checkRoles("getUsersByLeaderId")) {
			getUsersByLeaderId(loggedUser?.id);
		} else {
			getTeams({isTeam: false, all: true});
			getAgencies({isTeam: true, all: true});
			getUserDropdown(
				checkRoles("getUsersByHeadOfSalesId") ? ["HEAD_OF_SALES"] : userRolesDropdown,
			);
		}
	}, [totalSize, loggedUser]);
	const handleClose = () => {
		if (showModal) {
			setShowModal(false);
		} else {
			setShowUploadModal(false);
		}
	};
	return (
		<ModalComponent
			open={showModal || showUploadFileModal}
			handleClose={handleClose}
			disableEnforceFocus
			customClassNames="upload__overlay"
			positionModal="right"
		>
			<UploadImport
				counter={counter}
				setCounter={setCounter}
				setShowUploadModal={handleClose}
				importType={importType}
			/>
		</ModalComponent>
	);
};
const mapStateToProps = (state) => ({
	totalSize: state.app.users.index.totalSize,
	orderImportState: state.app.orders.import,
	loggedUser: state.app.users.index.loggedUser,
});

const mapDispatchToProps = {
	getClients: clientActions.fetchClientsByDropdown,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getUserDropdown: usersActions.getUserDropdown,
	setShowModal: importOrderActions.showModal,
	getUsersByLeaderId: usersActions.getUsersByLeaderId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UploadInput));
