/* eslint-disable no-nested-ternary */
import JSZip from "jszip";
import {saveAs} from "file-saver";
import i18n from "@src/i18n";
import * as XLSX from "xlsx";
import moment from "moment";
import {getNestedValue} from "@components/dashboard/orders";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";

export const exportCsvHandler = async (columns, singleCalculation, match) => {
	const columnTitles = columns.map((columnDef) => columnDef.title);
	const extraColumns = columns.map((columnDef) => columnDef);
	const singleCalc = singleCalculation?.data?.map((obj) => ({
		...obj,
		cancellationReserve:
			obj?.orderStatus !== "BONUS" && obj?.orderId !== null && obj?.cancellationReserve
				? ` ${obj?.cancellationReserve}% / ${(
						(obj?.cancellationReserve / 100) *
						obj?.provision
				  ).toFixed(2)} €`
				: "-",
		salesPartnerName: obj?.writerName ? obj?.salesPartnerName : "-",
		writerName: obj?.writerName ? obj?.writerName : obj?.salesPartnerName,
		createdAt1: moment(obj?.createdAt).format("DD.MM.YYYY"),
		provision1:
			obj?.orderStatus === "CANCELLED"
				? `${obj?.provision1}${obj?.provision1?.includes("€") ? "" : "€"}`
				: obj.provision1,
	}));
	const rowsData = singleCalc.map((rowData) =>
		extraColumns.map((columnDef) => getNestedValue(rowData, columnDef.field)),
	);
	const exportData = [columnTitles, ...rowsData];
	const wb = XLSX.utils.book_new();
	const ws = XLSX.utils.aoa_to_sheet(exportData);
	const wscols = columnTitles?.map(() => ({wch: 15}));
	ws["!cols"] = wscols;
	XLSX.utils.sheet_add_aoa(ws, [[`${i18n.t("CancelationReserve")}`]], {
		origin: `C${exportData.length + 1}`,
	});
	XLSX.utils.sheet_add_aoa(ws, [[`${i18n.t("SubTotal")}`]], {
		origin: `C${exportData.length + 2}`,
	});

	XLSX.utils.sheet_add_aoa(
		ws,
		[[`${singleCalculation?.details?.taxBoolean ? "19%MwST" : "0%MwST"}`]],
		{origin: `C${exportData.length + 3}`},
	);
	XLSX.utils.sheet_add_aoa(ws, [[`${i18n.t("Total")}`]], {
		origin: `C${exportData.length + 4}`,
	});
	XLSX.utils.sheet_add_aoa(
		ws,
		[[`- ${singleCalculation?.details?.thisMonthCancellationReserve?.toFixed(2)} €`]],
		{origin: `K${exportData.length + 1}`},
	);
	XLSX.utils.sheet_add_aoa(ws, [[`${singleCalculation?.details?.totalValueString} €`]], {
		origin: `K${exportData.length + 4}`,
	});
	XLSX.utils.sheet_add_aoa(ws, [[`${singleCalculation?.details?.sumString} €`]], {
		origin: `K${exportData.length + 2}`,
	});
	XLSX.utils.sheet_add_aoa(
		ws,
		[
			[
				`${
					singleCalculation?.details?.tax !== null
						? singleCalculation?.details?.tax?.toFixed(2)
						: "0"
				} €`,
			],
		],
		{
			origin: `K${exportData.length + 3}`,
		},
	);
	const name =
		singleCalculation?.details?.spRole === "AGENCY" ||
		// eslint-disable-next-line no-nested-ternary
		singleCalculation?.details?.spRole === "AGENCY-SP"
			? singleCalculation?.details?.agencyType === "SIMPLE" ||
			  singleCalculation?.details?.spRole === "AGENCY_70"
				? singleCalculation.details.agencyName
				: singleCalculation.details?.spFullName
			: singleCalculation.details?.spFullName;

	XLSX.utils.book_append_sheet(wb, ws, `Abrechnung ${match?.params?.id}`);
	XLSX.writeFileXLSX(wb, `Abrechnung ${name}-${match?.params?.id}.xlsx`);
	if (singleCalculation?.files?.[0]) {
		const zip = new JSZip();
		const folder = zip.folder("Order Uploads");
		singleCalculation.files.forEach((url, index) => {
			const blobPromise = fetch(url.url).then((r) => {
				if (r.status === 200) return r.blob();
				return Promise.reject(new Error(r.statusText));
			});
			const fileName = `Abrechnung ${name}-${match?.params?.id}_${index}.xlsx`;
			folder.file(fileName, blobPromise);
		});
		zip.generateAsync({type: "blob"}).then((blob) => {
			// Generate a unique zip file name
			const zipFileName = `Abrechnung-${name}-${match?.params?.id}-files.zip`;
			saveAs(blob, zipFileName);
		});
		zip.files = {};
	}
};

export const handleUploadFile = async (event, parseCsv, uploadFile, id, setExcelData, userId) => {
	const file = event.target.files[0];
	const validExtensions = ["xlsx", "xls"];
	const fileExtension = file.name.split(".").pop()?.toLowerCase();
	if (!validExtensions.includes(fileExtension)) {
		ToastErrorComponent(i18n.t("ExcelTypeInfo"));
	} else {
		const fileData = new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				resolve({
					fileName: file.name,
					base64: reader.result,
					type: file.type,
					extension: file.name.split(".").pop()?.toLowerCase(),
					size: file.size,
				});
			};
			reader.onerror = () => {
				reader.abort();
				reject(new DOMException("Problem parsing input file."));
			};
		});
		const resolvedFile = await fileData;

		const xlsxReader = new FileReader();

		xlsxReader.onload = async (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, {type: "array"});
			workbook.SheetNames.forEach((item, index) => {
				const worksheet = workbook.Sheets[workbook.SheetNames[index]];
				const csv = XLSX.utils.sheet_to_csv(worksheet);
				const csvFile = new Blob([csv], {type: "text/csv"});
				parseCsv({csv: csvFile, fileName: file?.name});
			});
			uploadFile({...resolvedFile, uuid: id, userId});
			setExcelData({
				activeTab: {
					value: workbook.SheetNames[0],
					index: 0,
				},
				tabs: workbook.SheetNames,
			});
		};
		xlsxReader.readAsArrayBuffer(file);
	}
};

export const priceInputs = [
	{
		inputType: "number",
		title: i18n.t("Price"),
		field: "price",
		required: true,
	},
	{
		title: i18n.t("Description"),
		field: "description",
		inputType: "textarea",
		maxLength: 70,
	},
];
