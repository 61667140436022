import {exportCsvHandler} from "@src/components/dashboard/orders/ordersHelper";
import {actions as ordersActions} from "@sagas/orders/index";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import pako from "pako";
// eslint-disable-next-line import/no-cycle
import {getStore} from "../../store";
class ExportOrdersEntity {
	COMPLETE = (data) => {
		const {dispatch, getState} = getStore();
		const {orders} = getState().app;
		const decompressData = () => {
			try {
				const byteArray = base64ToByteArray(data?.data);
				const decompressedData = pako.inflate(byteArray, {to: "string"});
				const jsonObject = JSON.parse(decompressedData);
				return jsonObject;
			} catch (error) {
				ToastErrorComponent(i18n.t("DownloadFailed"));
			}
		};
		// Function to convert Base64 string to Uint8Array
		const base64ToByteArray = (base64String) => {
			const binString = window.atob(base64String);
			const byteArray = new Uint8Array(binString.length);
			for (let i = 0; i < binString.length; i++) {
				byteArray[i] = binString.charCodeAt(i);
			}
			return byteArray;
		};
		const jsonData = decompressData();
		const {viewOrders, orderFilter} = jsonData;
		if (orders?.index?.exportStatus === "PROGRESS") {
			dispatch(ordersActions.setExportStatus("COMPLETE"));
			exportCsvHandler({
				data: viewOrders,
				query: orderFilter,
			});
		}
	};

	FAILED = () => {
		const {dispatch} = getStore();
		ToastErrorComponent(i18n.t("DownloadFailed"));
		dispatch(ordersActions.setExportStatus(""));
	};
}

export default new ExportOrdersEntity();
