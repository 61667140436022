import moment from "moment";
import React, {useState} from "react";
import DataGrid from "react-data-grid";
import {useTranslation} from "react-i18next";
import {columnObj} from "./conditionElements";
const ConditionTable = ({columns, rows, fillTable, activeTab, item}) => {
	const [updatedRows, setUpdatedRows] = useState([]);
	const isTableExpired = item?.endDate ? moment(item?.endDate)?.isBefore(moment()) : false;
	const {t} = useTranslation();
	const handleUpdateRows = (e, row) => {
		const {column, indexes} = row;
		// updating minContractsTeam field on both trainer and leader if one of them has changed
		const newRows =
			column.key === "minContractsTeam"
				? e?.map((r) => ({
						...r,
						minContractsTeam: e[indexes[0]]?.minContractsTeam,
				  }))
				: e;
		const [leader, trainer] = newRows;
		const baseTableProperties = {
			id: item?.id,
			name: item?.name,
			teamIds: item?.teamIds,
			conditionType: item?.conditionType,
			startDate: item?.startDate,
			endDate: item?.endDate,
		};
		if (activeTab === "overhead") {
			fillTable({
				...baseTableProperties,
				overheadLeader: {...leader},
				overheadTrainer: {...trainer},
				type: "overhead",
				countType: item?.countType,
			});
		} else {
			fillTable({
				...baseTableProperties,
				selfProductionLeader: {...leader},
				selfProductionTrainer: {...trainer},
				type: "self_production",
			});
		}
		setUpdatedRows(newRows);
	};
	return (
		<DataGrid
			className="rdg-light"
			columns={columns?.map((item) => ({
				...item,
				...columnObj,
				getRowMetaData: (row) => row,
				editable: !isTableExpired && item.editable,
			}))}
			rows={updatedRows?.[0] ? updatedRows : rows}
			enableCellSelect={true}
			onRowsChange={handleUpdateRows}
			rowHeight={44}
			headerRowHeight={50}
		/>
	);
};

export default ConditionTable;
