import React from "react";
import {useTranslation} from "react-i18next";

const staticSections = ["Locations", "Tarifs", "products", "ProjectCategories"];

const StaticSection = () => {
	const {t} = useTranslation();
	return (
		<div className="static-section__wrapper">
			<span className="static-section__wrapper-title">{t("staticSection")}</span>
			{staticSections.map((sect, index) => (
				<div key={`${sect}--${index}`} className="static-section__item">
					<span className="section__name">{t(sect)}</span>
				</div>
			))}
		</div>
	);
};

export default StaticSection;
