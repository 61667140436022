import {ENDPOINT} from "@src/utils/endpoint";
import axios from "axios";
import _ from "lodash";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

// if there're other non supported input types add here
const NON_DRAGABLE_INPUT_TYPES = ["UPLOAD"];

const getData = async (url) => {
	try {
		const {data} = await axios.get(url);
		return data?.data;
	} catch (error) {
		return error;
	}
};

const reOrderChildrens = (array, baseArray) => {
	const childrenMap = array.reduce((map, item) => {
		if (item.dropDownParentId) {
			if (!map[item.dropDownParentId]) {
				map[item.dropDownParentId] = [];
			}
			map[item.dropDownParentId].push(item);
		}
		return map;
	}, {});
	const result = [];
	Object.keys(childrenMap).forEach((key) => {
		const foundedParent = baseArray?.find((parent) => parent.fieldId === +key);
		if (foundedParent) {
			const doesParentExist = _.some(array, {id: foundedParent.fieldId});
			const staticId = foundedParent.fieldId;
			result.push({
				...foundedParent,
				static: true,
				staticId,
				id: null,
			});
			if (doesParentExist) {
				result.push({...foundedParent, id: foundedParent.fieldId});
			}
			if (childrenMap[foundedParent.fieldId]) {
				result.push(...childrenMap[foundedParent.fieldId]);
			}
		}
	});
	array.forEach((item) => {
		if (!item.dropDownParentId && !childrenMap[item.fieldId]) {
			result.push(item);
		}
	});
	const finalResult = result.filter((item) => {
		if (item.static) {
			return childrenMap[item.staticId] && childrenMap[item.staticId].length > 0;
		}
		return true;
	});

	return finalResult;
};

export default function useDragAndDrop() {
	const [draggableItems, setDraggableItems] = useState([]);
	const [orderFields, setOrderFields] = useState([]);
	const [droppableItems, setDroppableItems] = useState({});
	const [selectedItemToDelete, setSelectedItemToDelete] = useState(null);
	const [confirmModal, setConfirmModal] = useState({
		open: false,
		loading: false,
	});
	const [pdfData, setPdfData] = useState({});
	const {clientId, id} = useParams();

	const getDataAndPopulateState = async () => {
		const {pdfFieldViewList, ...rest} = await getData(
			ENDPOINT.FETCH_PDF.replace(":clientId", clientId).replace(":pdfId", id),
		);
		const {orderFieldViewList} = await getData(
			ENDPOINT.FETCH_ORDER_FIELDS.replace(":clientId", clientId),
		);
		setOrderFields(orderFieldViewList);
		const filterDragableItems = orderFieldViewList?.filter(
			(field) =>
				!pdfFieldViewList?.some((item) => item.fieldId === field.fieldId) &&
				!NON_DRAGABLE_INPUT_TYPES.includes(field.inputType),
		);

		const customDraggableItemsWithType = filterDragableItems?.map((item) => ({
			...item,
			type: "CUSTOM_FIELD",
			id: item?.fieldId,
		}));
		setDraggableItems(reOrderChildrens(customDraggableItemsWithType, orderFieldViewList));
		setDroppableItems(_.groupBy(pdfFieldViewList, "id"));
		setPdfData(rest);
	};

	const handleShowDeleteModal = (item) => {
		setConfirmModal((prev) => ({...prev, open: true}));
		setSelectedItemToDelete(item);
	};
	const closeConfirmModal = () => setConfirmModal({open: false, loading: false});

	const handleDeletePdfField = async () => {
		setConfirmModal((prev) => ({...prev, loading: true}));
		try {
			await axios.delete(ENDPOINT.DELETE_PDF_FIELD.replace(":id", selectedItemToDelete?.id));
			if (!selectedItemToDelete?.clone) {
				setDraggableItems((prev) => {
					const concatedArray = [
						{
							...selectedItemToDelete,
							id: selectedItemToDelete?.fieldId,
							type: "CUSTOM_FIELD",
						},
						...prev,
					];
					return reOrderChildrens(concatedArray, orderFields);
				});
			}
			setDroppableItems((prev) => {
				const newObj = {};
				Object.values(prev).forEach((f) => {
					const field = f[0];
					if (selectedItemToDelete.clone) {
						if (!(field.parentId === selectedItemToDelete.parentId && field.clone)) {
							newObj[field.id] = f;
						}
					} else if (field.fieldId !== selectedItemToDelete.fieldId) {
						newObj[field.id] = f;
					}
				});
				return newObj;
			});
		} catch (error) {
			return error;
		} finally {
			closeConfirmModal();
		}
	};

	const handleAddPdfField = async (draggedItem) => {
		try {
			const payload = {
				fieldId: draggedItem?.id,
				x: draggedItem?.x,
				y: draggedItem?.y,
				labelEn: draggedItem?.labelEn,
				labelDe: draggedItem?.labelDe,
				alignment: "LEFT",
				width:
					draggedItem?.inputType === "CHECKBOX" || draggedItem?.showAs === "CHECKBOX"
						? 30
						: 180,
				height: 30,
				fontSize: 10,
				clone: false,
				type: "CUSTOM_FIELD",
				pdfId: +id,
			};
			const {data} = await axios.post(ENDPOINT.CREATE_PDF_FIELD, payload);
			setDraggableItems((prev) => {
				const updatedArray = prev?.filter((item) => item.id !== draggedItem?.id);
				return reOrderChildrens(updatedArray, orderFields);
			});
			setDroppableItems((prev) => ({
				...prev,
				[data?.data?.id]: [{...data?.data}],
			}));
		} catch (error) {
			return error;
		}
	};
	const handleUpdatePdfField = async (draggedItem) => {
		try {
			const payload = {
				fieldId: draggedItem?.id,
				x: draggedItem?.x,
				y: draggedItem?.y,
				labelEn: draggedItem?.labelEn,
				labelDe: draggedItem?.labelDe,
				alignment: draggedItem?.alignment || "LEFT",
				width: draggedItem?.width || 180,
				height: draggedItem?.height || 30,
				fontSize: draggedItem?.fontSize || 10,
				clone: draggedItem?.cloneField,
				parentId: draggedItem?.cloneField ? draggedItem?.fieldId : null,
				type: "CUSTOM_FIELD",
				pdfId: +id,
			};
			if (!draggedItem?.cloneField) {
				setDroppableItems((prev) => ({
					...prev,
					[draggedItem?.id]: [
						{...draggedItem, ...payload, fieldId: draggedItem?.fieldId, clone: false},
					],
				}));
			}
			const {data} = await axios.put(ENDPOINT.UPDATE_PDF_FIELD_DND, payload);
			setDroppableItems((prev) => ({
				...prev,
				[data?.data?.id]: [{...payload, ...data?.data}],
			}));
		} catch (error) {
			return error;
		}
	};
	const updatePdfFieldOnState = (item) => {
		setDroppableItems((prev) => ({
			...prev,
			[item.id]: [{...item}],
		}));
	};
	const handleMoveBox = (item) => {
		if (item?.id in droppableItems) {
			handleUpdatePdfField(item);
		} else {
			handleAddPdfField(item);
		}
	};

	useEffect(() => {
		getDataAndPopulateState();
	}, [clientId, id]);
	return {
		draggableItems,
		droppableItems,
		pdfData,
		handleDeletePdfField,
		handleShowDeleteModal,
		confirmModal,
		handleMoveBox,
		closeConfirmModal,
		updatePdfFieldOnState,
		orderFields,
	};
}
