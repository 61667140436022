import * as yup from "yup";
import React from "react";
import {HideRowIcon, ShowRowIcon} from "@src/assets/SvgComponents";
import i18n from "../../../../../../i18n";
export const categoryElements = {
	columns: [
		{
			key: "expanded",
			name: "",
			minWidth: 30,
			cellClass: "custom__cell-expander",
			width: 30,
			colSpan(args) {
				return args?.type === "ROW" && args?.row?.type === "DETAIL" ? 4 : undefined;
			},
			formatter({row, tabIndex, onRowChange}) {
				return (
					<div
						style={{
							float: "right",
							display: row?.parentId || row?.baugos?.length === 0 ? "none" : "table",
							blockSize: "100%",
						}}
					>
						<span
							onClick={() => {
								onRowChange({...row, expanded: !row?.expanded});
							}}
						>
							<span tabIndex={tabIndex}>
								{row?.expanded ? <HideRowIcon /> : <ShowRowIcon />}
							</span>
						</span>
					</div>
				);
			},
		},
		{
			name: i18n.t("ID"),
			key: "id",
			sortable: true,
			headerCellClass: "headerStyles",
			resizable: true,
		},

		{
			name: i18n.t("category"),
			key: "name",
			image: "imageField",
			headerCellClass: "headerStyles",
			sortable: true,
			resizable: true,
			cellClass: "category-custom__cell",
			formatter: ({row}) => <span>{row?.parentId ? "" : row?.name}</span>,
			headerStyle: {
				fontFamily: "Montserrat",
				fontSize: "1.3rem",
			},
			cellStyle: {
				// backgroundColor: "#fbfbfb",
				backgroundColor: "#44228b0d",
				color: "#6C1EB0",
				marginRight: "190px",
				borderRightStyle: "solid",
				borderRightColor: "#f5f5f5",
				borderRightWidth: "1px",
			},
		},
		{
			name: "Bonus",
			key: "bonusName",
			sortable: true,
			resizable: true,
			headerCellClass: "headerStyles",
			formatter: ({row}) => <span>{row?.parentId ? row?.name : ""}</span>,
		},
	],
	btnText: i18n.t("Submit"),
	lists: [
		{
			title: i18n.t("categoryName"),
			type: "input",
			field: "name",
			name: "name",
			required: true,
			inputType: "text",
		},
		{
			title: i18n.t("categoryRelatedTo"),
			type: "dropdown",
			field: "projectCategories",
			inputType: "dropdown",
			name: "value",
			newClass: "dropdown-commission",
			inputValue: "obj",
		},
	],
	hasID: "id",
	modalTitle: i18n.t("category"),
};
