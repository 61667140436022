import React, {useState} from "react";
import UploadButtonComponent from "@src/common/components/uploadFiles/uploadButtonComponent";
import {UploadNewIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";

function UploadCard({setFieldValue, errors, accept, setSelectedFile, importing = false}) {
	const {t} = useTranslation();
	const theme = localStorage.getItem("theme");
	const [key, setKey] = useState(0);
	const parsedTheme = JSON.parse(theme);
	const fileToBase64 = (blob) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const base64String = reader.result.split(",")[1];
				resolve(base64String);
			};
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	const handleFileUpload = async (files) => {
		const base64Files = await Promise.all(
			Array.from(files).map(async (file) => {
				const base64String = await fileToBase64(file);
				return {
					name: file.name,
					size: file.size,
					link: `data:${file.type};base64,${base64String}`,
				};
			}),
		);
		setFieldValue("file", base64Files);
	};
	const handleUploadImport = (files) => {
		if (
			files?.[0]?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		) {
			setKey(key + 1);
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, {type: "array"});
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];
				const csv = XLSX.utils.sheet_to_csv(worksheet);
				const csvFile = new Blob([csv], {type: "text/csv"});
				setFieldValue("uploadedCsv", csvFile);
			};
			reader.readAsArrayBuffer(files[0]);
		} else {
			setFieldValue("uploadedCsv", files[0]);
		}
		setSelectedFile(files[0].name);
	};
	return (
		<div className="upload-box-container">
			<span className="upload-box-container-label">{t("Upload")}</span>
			<div className="upload-box-container-box">
				<span className="upload-box-container-box__label">{t("Dragdata")}</span>
				<UploadButtonComponent
					height={50}
					backgroundColor={parsedTheme?.primary}
					margin={0}
					icon={<UploadNewIcon />}
					onFileUpload={importing ? handleUploadImport : handleFileUpload}
					text="Upload"
					accept={accept}
					key={key}
				/>
			</div>
			{errors && <span className="errorClass">{errors}</span>}
		</div>
	);
}

export default UploadCard;
