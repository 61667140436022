import ButtonComponent from "@common/button";
import CloseIcon from "@common/closeIcon/CloseIcon";
import InvalidCsvErrors from "@common/InvalidCsvErrors";
import React from "react";
import i18n from "../../../../i18n";

const ErrorsModal = (props) => {
	const {setShowModal, errors} = props;
	return (
		<div className="edit__and__create__form">
			<div className="close__modal__icon__and__name">
				<h1>{i18n.t("InvalidCsv")}</h1>
				<CloseIcon className="close__modal__icon" click={() => setShowModal(false)} />
			</div>
			<InvalidCsvErrors errors={errors} />

			<div className="modal__cancel__confirm__buttons">
				<ButtonComponent
					buttonText={i18n.t("Close")}
					classNames="primaryButton cancel"
					onClick={() => setShowModal(false)}
				/>
			</div>
		</div>
	);
};

export default ErrorsModal;
