import React, {useEffect, useState} from "react";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as trainingActions} from "@sagas/trainings";
import {actions as createTrainingActions} from "@sagas/trainings/create";
import AddTemplateCategoryModal from "@common/addTemplateCategory";
import moment from "moment";
import ModalComponent from "@common/modal";
import {checkRoles} from "@utils/checkRoles";
import Button from "@common/components/button";
import Tabs from "@common/components/tabs";
import {TrainingsIcon} from "@assets/SvgComponents";
import CustomPopover from "@src/common/Popover";
import plusLogo from "../../../../assets/img/plusIconBackground.svg";
import folderPlus from "../../../../assets/img/folder-plus.svg";
import TemplateCategory from "./category/index";
import {salesTrackerButtonProps} from "../../sales-tracker/staticData";

const ROLES = ["TENANT", "BACK-OFFICE", "MANAGEMENTINFO", "CLIENT"];

const TopContent = (props) => {
	const {
		navigate,
		toggleTemplates,
		publishedToggle,
		categories,
		addTemplateCategory,
		showModal,
		setShowModal,
		loading,
		loggedUser,
		category,
		getCategoryById,
		filters,
		setFilters,
		clearQuery,
	} = props;

	const {t} = useTranslation();
	const [showPopover, setShowPopover] = useState(false);
	const [openFilters, setOpenFilters] = useState(false);

	const onChangeVal = (e) => {
		setFilters({
			...filters,
			search: e.target.value,
		});
	};

	useEffect(() => {
		if (typeof filters?.category === "number") {
			getCategoryById(filters?.category);
		}
	}, [filters?.category]);

	const handleVisibility = (visibility) => setShowPopover(visibility);

	const items = [{key: "menu", label: <TemplateCategory handleVisibility={handleVisibility} />}];
	const filterItems = [
		{
			inputType: "antd-dropdown",
			label: t("category"),
			options: categories,
			sagaFunction: getCategoryById,
			field: "category",
			dependentField: "subCategories",
			isMultiple: false,
			show: true,
			name: "name",
			value: "value",
			key: "name",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			label: t("subCategoryName"),
			field: "subCategories",
			name: "name",
			options: category?.subCategories || [],
			dependendFrom: "category",
			isMultiple: true,
			value: "id",
			show: true,
			noDataMsg: "No Subcategories Selected",
			key: "name",
			optionVal: "id",
			optionLabel: "name",
		},
		{
			inputType: "rangePicker",
			placeholder: [t("from"), t("to")],
			field: ["fromDate", "toDate"],
			show: true,
			maxDate: moment().endOf("day").valueOf(),
		},
	];
	return (
		<>
			<div className="header-component">
				<ModalComponent
					className="add_edit_user"
					open={showModal}
					handleClose={setShowModal}
				>
					<AddTemplateCategoryModal
						handleSubmit={addTemplateCategory}
						setShowModal={setShowModal}
						loading={loading}
					/>
				</ModalComponent>
				<div className="dashboard__left__text-header">
					<div className="header-component-wrapper">
						<TrainingsIcon />
						<span className="header-component-text">{t("Template")}</span>
					</div>
					{ROLES?.includes(loggedUser?.role?.name) && (
						<div>
							<Tabs
								items={["draft", "published"]}
								onClick={(e) => toggleTemplates(e.target.innerText)}
								value={publishedToggle}
							/>
						</div>
					)}
				</div>
				<div className="dashboard__left__text-actions">
					<div className="trainingsTop__rightSide">
						<div className="trainingsTop__btns">
							<CustomPopover
								customClass="custom-popover-leadstatuses"
								menu={{items}}
								open={showPopover}
								handleVisibility={handleVisibility}
							>
								<div>
									<Button
										{...salesTrackerButtonProps}
										icon={plusLogo}
										leftRounded={false}
										rightRounded={false}
										hideTooltip={true}
										smallButtonWithTooltip
									/>
								</div>
							</CustomPopover>
							{checkRoles("addTemplate") && (
								<>
									{ROLES?.includes(loggedUser?.role?.name) && (
										<Button
											text={t("AddTemplate")}
											icon={folderPlus}
											handleChange={() =>
												navigate("/admin/trainings/add/training")
											}
											leftRounded={false}
											backgroundColor="#44228bb3"
											smallButtonWithTooltip
										/>
									)}
								</>
							)}
						</div>
						<BigSearchBar
							withFilters={true}
							handleFilters={() => setOpenFilters(!openFilters)}
							value={filters?.search}
							handleSearch={onChangeVal}
							filterItems={filterItems}
							filterValues={filters}
							setFilterValues={setFilters}
							clearFilterValues={clearQuery}
							headerSearch
						/>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	categories: state.app.trainings.index.categories,
	showModal: state.app.trainings.create.showModal,
	loading: state.app.trainings.create.loading,
	category: state.app.trainings.index.category,
});
const mapDispatchToProps = {
	filterTrainings: trainingActions.filterTrainings,
	getCategoryById: trainingActions.getCategoryById,
	getTrainingsByStatus: trainingActions.getTrainingsByStatus,
	addTemplateCategory: createTrainingActions.addTemplateCategory,
	setShowModal: createTrainingActions.setShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopContent));
