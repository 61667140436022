import React from "react";
import i18n from "@src/i18n";
import {checkRoles} from "@src/utils/checkRoles";

export const expandableTables = [
	{
		title: i18n.t("MonitoringTrainer"),
		type: "trainer",
		show: checkRoles("monitoringTrainer"),
	},
	{
		title: i18n.t("MonitoringLeader"),
		type: "leader",
		editable: checkRoles("editMonitoringLeader"),
		show: checkRoles("monitoringLeader"),
	},
	{
		title: i18n.t("MonitoringHoD"),
		type: "headOfDepartmentQualified",
		editable: checkRoles("editMonitoringHoD"),
		show: checkRoles("monitoringHoD"),
	},
	{
		title: i18n.t("MaintainStatusTrainer"),
		type: "trainerStatus",
		editable: checkRoles("editMaintainStatusTrainer"),
		show: checkRoles("maintainStatusTrainer"),
	},
	{
		title: i18n.t("MaintainStatusLeader"),
		type: "leaderStatus",
		show: checkRoles("maintainStatusLeader"),
	},
	{
		title: i18n.t("MaintainStatusHoD"),
		type: "headOfDepartmentStatus",
		show: checkRoles("maintainStatusHoD"),
	},
];

const baseColumns = [
	{
		key: "fullName",
		name: i18n.t("fullname"),
	},
	{
		key: "vpNumber",
		name: i18n.t("VPNumber"),
	},
];

const lastBaseColumn = {
	key: "statusConfirmed",
	name: i18n.t("statusConfirmed"),
	formatter: ({row}) => <span>{i18n.t(row?.statusConfirmed)}</span>,
};

export const columns = {
	trainer: [
		...baseColumns,
		{
			key: "pointsOfLastMonth",
			name: i18n.t("PointsLastMonth"),
			formatter: ({row}) => (
				<span>{row?.pointsOfLastMonth ? row?.pointsOfLastMonth?.toFixed(2) : ""}</span>
			),
		},
		{
			key: "averagePointsOfLastThreeMonths",
			name: i18n.t("PointsLast3Months"),
			formatter: ({row}) => (
				<span>
					{row?.averagePointsOfLastThreeMonths
						? row?.averagePointsOfLastThreeMonths?.toFixed(2)
						: ""}
				</span>
			),
		},
		{
			key: "qualificationTrainer",
			name: i18n.t("QualificationTrainer"),
			formatter: ({row}) => <span>{i18n.t(row?.isTrainerQualified)}</span>,
		},
	],
	leader: [
		...baseColumns,
		{
			key: "averagePointsLastMonth",
			name: i18n.t("PointsLastMonthTrainer"),
			dynamic: true,
		},
		{
			key: "numberOfInternships",
			name: i18n.t("sizeInterns"),
			dynamic: true,
		},
		{
			key: "internsLastMonth",
			name: i18n.t("averageOnTrainerPoints"),
		},
		{
			key: "qualificationTrainer",
			name: i18n.t("QualificationTrainer"),
			formatter: ({row}) => <span>{i18n.t(row?.qualificationTeamLeader)}</span>,
		},
	],
	headOfDepartmentQualified: [
		...baseColumns,
		{
			key: "numberOfTeamMembers",
			name: i18n.t("NrTeamMembers"),
		},
		{
			key: "numberOfTeamMembersLongerThatSixMonths",
			name: i18n.t("trainersLongerThanSixMonths"),
		},
		{
			key: "teamTurnoverPerMonth",
			name: i18n.t("teamTurnOver"),
			formatter: ({row}) => <span>{row?.teamTurnoverPerMonth || ""}</span>,
		},
		{
			key: "qualificationHeadOfDepartment",
			name: i18n.t("qualificationHeadOfDepartment"),
			formatter: ({row}) => <span>{i18n.t(row?.qualificationHeadOfDepartment)}</span>,
		},
	],
	trainerStatus: [
		...baseColumns,
		{
			key: "countTraineesWith40To71PointsLastMonth",
			name: i18n.t("PointsLastMonthTrainer"),
			dynamic: true,
		},
		{
			key: "countTraineesWith40To71PointsLast6Months",
			name: i18n.t("trainersLongerThanSixMonthsSecond"),
			dynamic: true,
		},
		{...lastBaseColumn},
	],
	leaderStatus: [
		...baseColumns,
		{
			key: "dailyTeamTurnoverRateLastMonthInPoints",
			name: i18n.t("teamTurnOverLeader"),
		},
		{
			key: "dailyTeamTurnoverRateLastMonthInPointsOnAverage",
			name: i18n.t("teamTurnOverSinceBeginning"),
		},
		{...lastBaseColumn},
	],
	headOfDepartmentStatus: [
		...baseColumns,
		{
			key: "avgDailyTurnoverPointsPerTeamMemberLast6Months",
			name: i18n.t("teamTurnOverLeader"),
			formatter: ({row}) => (
				<span>
					{row?.avgDailyTurnoverPointsPerTeamMemberLast6Months
						? row?.avgDailyTurnoverPointsPerTeamMemberLast6Months?.toFixed(2)
						: ""}
				</span>
			),
		},
		{
			key: "avgMonthlyTurnoverPointsLast6Months",
			name: i18n.t("teamTurnOverSixMonthsPerMonth"),
			formatter: ({row}) => (
				<span>
					{row?.avgMonthlyTurnoverPointsLast6Months
						? row?.avgMonthlyTurnoverPointsLast6Months?.toFixed(2)
						: ""}
				</span>
			),
		},
		{...lastBaseColumn},
	],
};

export const inputs = [
	{
		inputType: "number",
		field: "minPoints",
		title: i18n.t("MinPoints"),
		defaultValue: 40,
		required: true,
	},
	{
		inputType: "number",
		field: "maxPoints",
		title: i18n.t("MaxPoints"),
		defaultValue: 71,
		required: true,
		dependentField: "minPoints",
	},
];
