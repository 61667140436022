import _ from "lodash";

export const formatNumber = (number) => {
	const formattedNumber = new Intl.NumberFormat("de-DE", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(number);
	return formattedNumber;
};

export const stringFormatNumber = (number) => {
	const newNumber = typeof number === "number" ? number.toString() : number;
	const formattedNumber = newNumber.replace(/[,]/g, "");
	return `${formatNumber(formattedNumber)}€`;
};

export const countContracts = (type, clientName, isSupervisor, payoutModel) => {
	const splitOwnContracts = type.filter((contract) => !contract?.isSupervisor);
	const countDifference = type.filter((contract) => contract?.isSupervisor).length;
	const groupByLayout = _.groupBy(
		payoutModel === "DISTRIBUTION_MODEL" ? splitOwnContracts : type,
		"clientName",
	);
	const groupByOrderId = Object.entries(groupByLayout)
		.map((item) => {
			if (item[0] !== "null") {
				return _.groupBy(item[1], "clientOrderId");
			}
			return null;
		})
		.filter((item) => item !== null);
	const numberOfOrdersByClient = groupByOrderId.reduce((acc, clientData) => {
		Object.keys(clientData).forEach((clientOrderId) => {
			const clientName = clientData[clientOrderId][0]?.clientName;
			if (clientName) {
				acc[clientName] = (acc[clientName] || 0) + 1;
			}
		});
		return acc;
	}, {});
	return isSupervisor ? countDifference : numberOfOrdersByClient?.[clientName];
};

export const formatNumberDe = (number) => {
	const numberToFormat = number || 0;
	const formattedNumber = new Intl.NumberFormat("de-DE", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(numberToFormat);
	return formattedNumber;
};
