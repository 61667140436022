import React from "react";
import Logo from "@assets/img/oneriLogo.png";
import moment from "moment";

const PrivacyPolicyFooter = (props) => {
	const {navigate} = props;
	return (
		<div className="privacy__policy__footer">
			<div className="privacy__policy__footer__items">
				<img className="privacy__policy__oneri__logo" src={Logo} alt="" />
				<p onClick={() => navigate(`/auth/login`)}>© {moment().format("YYYY")} ONERI</p>
			</div>
		</div>
	);
};

export default PrivacyPolicyFooter;
