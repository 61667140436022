// change table's item order
export const changeOrderFn = (list, itemIndex, action) => {
	const newList = [...list];

	const clickedItem = newList.splice(itemIndex, 1);

	newList.splice(action === "up" ? itemIndex - 1 : itemIndex + 1, 0, {
		...clickedItem?.[0],
		nr: newList[action === "up" ? itemIndex - 1 : itemIndex]?.nr,
	});
	newList[itemIndex] = {
		...newList[itemIndex],
		nr: clickedItem?.[0]?.nr,
	};

	return newList;
};
