import React from "react";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as clientActions} from "@sagas/client";
import Form from "@src/common/Form";
import {inputTypes} from "./staticData";

const CreateCustonField = (props) => {
	const {loading, createCustomField, editCustomField, match} = props;
	const {getUrlParams, setModal} = useUrlParams();
	const {t} = useTranslation();
	const {clientId} = match.params;
	const {id, modal} = getUrlParams("id", "modal");
	const isChild = modal === "child";
	const formData = {
		inputs: [
			{
				inputType: "text",
				required: true,
				field: "name",
				title: "Name",
				disabled: !!id && !isChild,
			},
			{
				inputType: "text",
				required: true,
				field: "labelEn",
				title: t("fieldLabelEn"),
			},
			{
				inputType: "text",
				required: true,
				field: "labelDe",
				title: t("fieldLabelDe"),
			},
			{
				inputType: "dropdown",
				required: true,
				field: "inputType",
				options: inputTypes,
				title: t("ChooseInputType"),
				hidden: !!id || isChild,
			},
		],
		title: id && !isChild ? t("EditCustomField") : t("CreateCustomField"),
		id: !isChild && id,
		getUrl: `client/custom_fields/:id`,
		submitFn: (formValues) => {
			const values = {
				...formValues,
				clientId: +clientId,
				showField: true,
				showAs: "VALUE",
			};
			if (values.id) {
				editCustomField({values, setModal});
			} else if (isChild) {
				createCustomField({
					values: {
						...values,
						inputType: "CHECKBOX",
						dropDownParentId: id,
					},
					setModal,
				});
			} else {
				createCustomField({values, setModal});
			}
		},
	};

	return (
		<Form
			open={modal === "customField" || modal === "child"}
			formData={formData}
			handleClose={() => setModal(false)}
			loading={loading}
		/>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isLoading,
});
const mapDispatchToProps = {
	createCustomField: clientActions.createCustomField,
	editCustomField: clientActions.editCustomField,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateCustonField));
