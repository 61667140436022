import React, {useEffect} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {SettingsIcon} from "@assets/SvgComponents";
import _ from "lodash";
import BackButtonComponents from "@src/common/BackButton";
import {actions as navigation} from "@sagas/navigation";
import {actions as userEditAction} from "../../../store/sagas/app/userProfile/index";
import {RenderTabs} from "./helper";
import {settingsItems} from "./staticData";
import Item from "./components/Item";

const UserProfile = ({setActiveTab, activeTab, navigate}) => {
	const {t} = useTranslation();
	const queryParams = new URLSearchParams(window.location.search);
	const redirectedFrom = queryParams.get("from");
	const tabs = settingsItems?.filter((item) => item.show);
	const handleGoBack = () => {
		setActiveTab({type: "", title: ""});
		navigate("/admin/user-profile");
	};
	useEffect(() => {
		if (redirectedFrom === "apiKeyPage") {
			setActiveTab({
				title: t("Marketplace"),
				type: "marketplace",
			});
		} else {
			const foundedItem = tabs.find((tab) => tab.path === redirectedFrom);
			if (foundedItem) {
				setActiveTab({
					title: foundedItem.title,
					type: foundedItem.path,
				});
			} else {
				handleGoBack();
			}
		}
	}, [redirectedFrom]);
	return (
		<div className="dashboard__container settings__wrapper">
			<div className="header-component">
				<div className="dashboard__left__text-header">
					<div className="header-component-wrapper">
						{activeTab?.type ? (
							<BackButtonComponents className="back__btn" onClick={handleGoBack} />
						) : (
							<SettingsIcon />
						)}
						<span className="header-component-text">
							{activeTab?.title || t("Settings")}
						</span>
					</div>
				</div>
			</div>
			{activeTab.type === "" ? (
				<div className="items__wrapper">
					{tabs.map((item) => (
						<Item
							key={item.path}
							title={item.title}
							subTitle={item.subTitle}
							onClick={() => {
								setActiveTab({title: item.title, type: item.path});
								navigate(`/admin/user-profile?from=${item?.path}`);
							}}
						/>
					))}
				</div>
			) : (
				<div className="settings__wrapper-content">
					<RenderTabs activeChange={activeTab.type} />
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	activeTab: state.app.userProfile.index.activeTab,
});

const mapDispatchToProps = {
	setActiveTab: userEditAction.setActiveTab,
	navigate: navigation.navigate,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfile));
