/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as userActions} from "@sagas/users/users";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import handleErrors from "@common/handleErrors";
import i18n from "../../../../i18n";
import {actions as logoutActions} from "../auth/logout";

const logger = new Logger("Sagas>Delete>Index");

const PREFIX = "@app/tariffs/delete";
export const CHANGE_PHOTO = `${PREFIX}CHANGE_PHOTO`;
export const CHANGE_PHOTO_SUCCESS = `${PREFIX}CHANGE_PHOTO_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FILL_USERS = `${PREFIX}FILL_USERS`;
export const UPDATE_USER = `${PREFIX}UPDATE_USER`;
export const UPDATE_SETTINGS = `${PREFIX}UPDATE_SETTINGS`;
export const UPDATE_PASSWORD = `${PREFIX}UPDATE_PASSWORD`;
export const SET_SHOW_MODAL = `${PREFIX}SET_SHOW_MODAL`;
export const CHANGE_EMAIL = `${PREFIX}CHANGE_EMAIL`;
export const SET_ACTIVE_TAB = `${PREFIX}SET_ACTIVE_TAB`;

const _state = {
	loading: false,
	imgUrl: "",
	showModal: false,
	activeTab: {
		type: "",
		title: "",
	},
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case FILL_USERS:
				draft.userInitialValues = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case CHANGE_PHOTO_SUCCESS:
				draft.imgUrl = action.payload;
				break;
			case SET_SHOW_MODAL:
				draft.showModal = action.payload;
				break;
			case SET_ACTIVE_TAB:
				draft.activeTab = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	changePhoto: (payload) => createAction(CHANGE_PHOTO, {payload}),
	changePhotoSuccess: (payload) => createAction(CHANGE_PHOTO_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fillUsers: (payload) => createAction(FILL_USERS, {payload}),
	updateUser: (payload) => createAction(UPDATE_USER, {payload}),
	updateSettings: (payload) => createAction(UPDATE_SETTINGS, {payload}),
	updatePassword: (payload) => createAction(UPDATE_PASSWORD, {payload}),
	setShowModal: (payload) => createAction(SET_SHOW_MODAL, {payload}),
	changeEmail: (payload) => createAction(CHANGE_EMAIL, {payload}),
	setActiveTab: (payload) => createAction(SET_ACTIVE_TAB, {payload}),
};

export const sagas = {
	*changePhoto({payload}) {
		yield put(actions.setLoading(true));

		try {
			const formData = new FormData();
			formData.append("file", payload.selectedFile);
			const response = yield axios.put(`/user/profile/`, formData);

			yield put(actions.changePhotoSuccess(response.data?.data));
			ToastSuccesComponent(i18n.t("ImageChangeSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*updateUser({payload}) {
		yield put(actions.setLoading(true));
		try {
			const obj = {
				firstName: payload.values.firstName,
				lastName: payload.values.lastName,
				phoneNumber: payload.values.phoneNumber,
				city: payload.values.city,
				country: payload.values.country,
			};

			delete obj.role;
			yield put(userActions.editUserSettingsSuccess(payload.values));
			yield axios.put(`/user/update/profile/`, obj);
			ToastSuccesComponent(i18n.t("DetailsChangeSuccess"));
		} catch (error) {
			const errors = error?.response?.data.message;
			ToastErrorComponent(errors);
			handleErrors(errors, payload?.formActions);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*updatePassword({payload}) {
		yield put(actions.setLoading(true));

		try {
			yield axios.post(`/user/change-password/current`, payload.values);
			ToastSuccesComponent(i18n.t("DetailsChangeSuccess"));
			payload.handleModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message[0]?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeEmail({payload}) {
		yield put(actions.setLoading(true));

		try {
			yield axios.put(`/user/update/email/`, payload.values);

			ToastSuccesComponent(i18n.t("EmailChangeSuccess"));
			yield put(logoutActions.logout());
			payload.handleModal(false);
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
			const errors = error?.response?.data.message;

			handleErrors(errors, payload?.formActions);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CHANGE_PHOTO, sagas.changePhoto);
	// yield takeLatest(CHANGE_LOGO, sagas.changeLogo);
	yield takeLatest(UPDATE_USER, sagas.updateUser);
	yield takeLatest(UPDATE_PASSWORD, sagas.updatePassword);
	yield takeLatest(CHANGE_EMAIL, sagas.changeEmail);
};
