import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import {actions as createTariffByLocationsActions} from "@sagas/tariff/tariffByLocations/create";
import {actions as locationActions} from "@sagas/locations";
import moment from "moment";
import {ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import _, {isNaN} from "lodash";
import Form from "@src/common/Form";
import {tableInputs} from "./helper";

const CreateTarrifTable = ({
	tarrifTableInitialValues,
	createTariffTable,
	editTarriffTable,
	clearTarriffTable,
	locations,
	setShowModal,
	tarrifTable,
	copy,
	open,
	id,
}) => {
	const {t} = useTranslation();
	const locationsOptions = locations.map((item) => ({
		label: item?.locationName,
		name: item?.locationName,
		value: item?.id,
	}));

	useEffect(
		() => () => {
			clearTarriffTable();
		},
		[],
	);
	const formData = {
		inputs: tableInputs?.map((itm) => ({
			...itm,
			options: itm.inputType === "dropdown" ? locationsOptions : null,
		})),
		id,
		initialValues: tarrifTableInitialValues,
		title: id && !copy ? t("Edit") : t("CreateTable"),
		submitFn: (values) => handleSubmitTariffTable({values}),
	};

	const handleSubmitTariffTable = ({values}) => {
		const valuesClone = _.cloneDeep({...values, tableID: moment().valueOf()});
		let canSubmit = true;
		if (tarrifTable?.[0]) {
			for (let tTableIndex = 0; tTableIndex < tarrifTable.length; tTableIndex++) {
				const element = _.cloneDeep(tarrifTable?.[tTableIndex]);
				const locationName = element?.tableUUID ? "locations" : "locationsOptions";
				// tableUUID comes from back-end
				// tableID is added when user create multiple tables until user press save tariff.
				const tableCheckId = element?.tableUUID ? "tableUUID" : "tableID";
				const tableID = element.tableUUID;
				for (
					let elementIndex = 0;
					elementIndex < element?.[locationName].length;
					elementIndex++
				) {
					const loc = element?.[locationName]?.[elementIndex];
					const canContinue = valuesClone?.locationsOptions?.find(
						(lOptions) => lOptions.value === loc.value,
					);
					// on edit skip date validation of the table that we are editing;
					if (tableID === valuesClone?.[tableCheckId] && !copy) {
						// eslint-disable-next-line no-continue
						continue;
					}
					// checked if TO is Null or NaN
					if (valuesClone.to === null || isNaN(valuesClone.to)) {
						valuesClone.to = moment().add(10, "years").valueOf();
					}
					if (element.to === null || isNaN(element.to)) {
						element.to = moment().add(10, "years").valueOf();
					}
					// check if valuesClone are in between dates of other tables;
					// check if valuesClone are in same date of other tables;
					if (canContinue) {
						if (
							moment(valuesClone.from).isBetween(element.from, element.to) ||
							moment(valuesClone.to).isBetween(element.from, element.to) ||
							moment(valuesClone.from).isSame(element.to, "day") ||
							moment(valuesClone.from).isSame(element.from, "day") ||
							moment(valuesClone.to).isSame(element.to, "day") ||
							moment(valuesClone.to).isSame(element.from, "day")
						) {
							canSubmit = false;
						} else {
							canSubmit = true;
						}
					}
				}
				if (!canSubmit) break;
			}
		}
		if (canSubmit) {
			const obj = {
				...values,
				tableName: values?.tableName,
				to: values?.to,
				from: values?.from,
				locations: values?.locationsOptions,
				isNew: true,
				receivedCommission: values?.receivedCommission,
			};
			if (tarrifTableInitialValues?.isEditing && copy === false) {
				editTarriffTable(obj);
			} else if (copy !== null) {
				createTariffTable({
					...copy,
					tableUUID: `${moment().valueOf()}`,
					tableName: values.tableName,
					isNew: true,
					to: values?.to,
					from: values?.from,
					locations: values?.locationsOptions,
					receivedCommission: values?.receivedCommission,
				});
			} else {
				createTariffTable(obj);
			}
			clearTarriffTable();
			setShowModal(false);
		} else {
			ToastErrorComponent(t("CanNotAddTableCheckDates"));
		}
	};
	return <Form open={open} handleClose={() => setShowModal(false)} formData={formData} />;
};

const mapStateToProps = (state) => ({
	locations: state.app.locations.index.locations,
	tarrifTableInitialValues: state?.app?.tariffByLocations?.create?.tarrifTableInitialValues,
	tarrifTable: state?.app?.tariffByLocations?.create?.tarrifTable,
});

const mapDispatchToProps = {
	fetchLocations: locationActions.fetchLocations,
	createTariffTable: createTariffByLocationsActions.createTariffTable,
	editTarriffTable: createTariffByLocationsActions.editTarriffTable,
	clearTarriffTable: createTariffByLocationsActions.clearTarriffTable,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateTarrifTable));
