import produce from "immer";
import {put, select, takeEvery, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import _ from "lodash";
import {actions as messageActions} from "@sagas/messages/index";
import {ENDPOINT} from "@src/utils/endpoint";
import {findIndexFunc, findTopicFunc, changeStateValue} from "./utils";
import {actions as navigateActions} from "../navigation";

const logger = new Logger("Messages delete");

const PREFIX = "@app/messages/delete";
const SET_MODAL = `${PREFIX}SET_MODAL`;
const DELETE_SINGLE_MESSAGE = `${PREFIX}DELETE_SINGLE_MESSAGE`;
const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	messageToDeleteId: null,
	showModal: false,
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_MODAL:
				const {value, id} = payload;
				draft.messageToDeleteId = id || null;
				draft.showModal = value;
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	deleteMessage: (payload) => createAction(DELETE_SINGLE_MESSAGE, {payload}),
	setModal: (payload) => createAction(SET_MODAL, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*deleteMessage({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {insideTopic} = yield select((state) => state.app.messages.index);
			const {loggedUser} = yield select((state) => state.app.users.index);
			yield axios.post(
				ENDPOINT.DELETE_MESSAGE.replace(":topicId", insideTopic.topicId).replace(
					":messageId",
					payload?.id,
				),
			);
			yield put(
				messageActions.deleteSingleMessageSuccess({
					messageToDeleteId: payload?.id,
					loggedUserId: loggedUser?.id,
				}),
			);
			if (payload.setMessageToReply) {
				payload.setMessageToReply((prevMsg) =>
					prevMsg?.id === payload?.id ? null : prevMsg,
				);
			}
			payload.setModal(false);
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
			yield put(actions.setModal({value: false}));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_SINGLE_MESSAGE, sagas.deleteMessage);
};
