import React from "react";

const MerkmaleCalculation = ({item}) => {
	const {
		gesamtpreisOhneBoniBrutto,
		arbeitspreisHTCtKWhBrutto,
		grundpreisBrutto,
		grundpreisNetto,
	} = item;
	return (
		<div className="merkmaleCalculation__superContainer">
			<div className="merkmaleCalculation__container">
				<div className="merkmaleCalculation__container--first">
					<span className="merkmaleCalculation__container--first-text">
						Preis im 1. Jahr
					</span>
					<span className="merkmaleCalculation__container--first-number">
						{gesamtpreisOhneBoniBrutto} €
					</span>
				</div>
				<div className="merkmaleCalculation__container--line"></div>
				<div className="merkmaleCalculation__container--second">
					<span className="merkmaleCalculation__container--second-text">
						Arbeitspreis
					</span>
					<span className="merkmaleCalculation__container--second-number">
						{arbeitspreisHTCtKWhBrutto?.toFixed(2)} ct/kWh
					</span>
				</div>
				<div className="merkmaleCalculation__container--line"></div>
				<div className="merkmaleCalculation__container--third">
					<span className="merkmaleCalculation__container--third-text">
						Grundpreis / Jahr
					</span>
					<span className="merkmaleCalculation__container--third-number">
						{grundpreisBrutto} €{" "}
						<span>{(grundpreisBrutto - grundpreisNetto)?.toFixed(2)} € mtl.</span>
					</span>
				</div>
			</div>
			<div className="merkmaleCalculation__superContainer--bottomText">
				<span>die angegebenen Preise sind brutto</span>
			</div>
		</div>
	);
};

export default MerkmaleCalculation;
