/* eslint-disable max-len */
import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as productActios} from "@sagas/products";
import "./index.scss";
import {makeStyles} from "@material-ui/core/styles";
import {ArrowDownIcon, DatePickerIcon, ToggleAccordionIcon2} from "@src/assets/SvgComponents";

const useStyles = makeStyles({
	paperFullWidth: {
		overflowY: "visible",
	},
	dialogContentRoot: {
		overflowY: "visible",
	},
});

const DropDownInput = ({
	title,
	options,
	value,
	name,
	onChange,
	limitTags = 1,
	filterSelectedOptions = true,
	placeholder = "",
	multiple = false,
	touched,
	errors,
	editProductId,
	disabled,
	chosenProductId,
	className,
	errorClass = "validation-error",
	withTouched = true,
	noTouched = false,
	disablePortal = false,
	withClasses = false,
	disableClearable = true,
	onBlur,
	hidden = false,
}) => {
	options = options.map((a) => ({name: a[title], value: a[name], product: a?.product}));
	const classes = useStyles();

	value =
		value?.[title] && value.value
			? {name: value?.[title], value: value.value, product: value?.product}
			: null;

	useEffect(() => {
		if (value?.product) {
			editProductId(value?.value);
		}
		// eslint-disable-next-line
	}, [chosenProductId, value]);
	if (hidden) return null;

	return (
		<>
			<Autocomplete
				popupIcon={<ArrowDownIcon height="20px" fill="#c1c1c1" />}
				className={`AutocompleteCl ${className}`}
				disableClearable={disableClearable}
				filterSelectedOptions={filterSelectedOptions}
				getOptionLabel={(option) => (option && option[title] ? option[title] : "")}
				getOptionSelected={(option, value) => option.value === value.value}
				limitTags={limitTags}
				multiple={multiple}
				onChange={onChange}
				onBlur={onBlur}
				options={options}
				placeholder="Name"
				renderInput={(params) => (
					<>
						<TextField
							{...params}
							className="dropdown-option"
							label={placeholder}
							placeholder={placeholder}
							variant="outlined"
							// The 'focused' state is utilized to display the label at the top of the input.
							// The !disabled condition is used to prevent errors in edit mode, especially -
							// when the autocomplete dropdown is awaiting a value, and the input is focused before the value is provided.
							focused={!disabled}
						/>
					</>
				)}
				value={value}
				disabled={disabled}
				style={{
					border: "none",
					outline: "none",
				}}
				classes={withClasses ? classes : null}
				disablePortal={disablePortal}
			/>
			{withTouched && touched && errors && (
				<span className={errorClass}>*{touched && errors}</span>
			)}
			{noTouched && errors && <span className={errorClass}>{errors}</span>}
		</>
	);
};
const mapStateToProps = (state) => ({
	chosenProductId: state.app.products.index.chosenProductId,
});
const mapDispatchToProps = {
	editProductId: productActios.editProductId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DropDownInput));
