import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as productActios} from "@sagas/products";
import "./index.scss";
import {ToggleAccordionIcon2} from "@src/assets/SvgComponents";
import {userRoleData} from "@src/utils/userRoleData";
import StatusBox from "../components/status-box";

const DropDownInputSp = ({
	title,
	options,
	value,
	onChange,
	limitTags = 1,
	filterSelectedOptions = true,
	placeholder = "",
	multiple = false,
	touched,
	errors,
	disabled,
	className,
	setInputValue,
	inputValue,
}) => (
	<>
		<Autocomplete
			popupIcon={<ToggleAccordionIcon2 />}
			className={`AutocompleteCl ${className}`}
			disableClearable={true}
			filterSelectedOptions={filterSelectedOptions}
			getOptionLabel={(option) => (option && option[title] ? option[title] : "")}
			getOptionSelected={(option, value) => option.value === value.value}
			limitTags={limitTags}
			multiple={multiple}
			onChange={onChange}
			options={options}
			placeholder="Name"
			renderOption={(props, option) => (
				<span {...props} key={option.id} className="option-wrapper">
					{option?.name || option.clientName}
					{options?.find((i) => i.name === option?.name)?.role?.name && (
						<span className="role">
							<StatusBox
								row={options?.find((i) => i.name === option?.name)?.role?.name}
								statusData={userRoleData}
								height="30px"
							/>
						</span>
					)}
				</span>
			)}
			inputValue={inputValue}
			onInputChange={(event, value, reason) => {
				if ((event && event.type === "blur") || reason === "reset") {
					setInputValue("");
				} else if (reason !== "reset") {
					setInputValue(value);
				}
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					className="dropdown-option"
					label={placeholder}
					placeholder={placeholder}
					variant="outlined"
				/>
			)}
			value={value}
			disabled={disabled}
			style={{
				border: "none",
				outline: "none",
			}}
		/>
		{touched && errors && <span className="validation-error">{touched && errors}</span>}
	</>
);
const mapStateToProps = (state) => ({
	chosenProductId: state.app.products.index.chosenProductId,
});
const mapDispatchToProps = {
	editProductId: productActios.editProductId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DropDownInputSp));
