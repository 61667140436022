import React, {useState} from "react";
import {statuses} from "./helper";
import "./styles.scss";

const TopHeaderStatistik = ({filterUsersWithStatus, statistikStatusCount}) => {
	const [hover, setHover] = useState(false);
	const [active, setActive] = useState(statuses[0]);

	const renderStatuses = statuses.map((item) => {
		const {icon, label, bgColor, name} = item;
		return (
			<div
				onMouseEnter={() => setHover(item)}
				onMouseLeave={() => setHover(null)}
				onClick={() => {
					setActive(item);
					filterUsersWithStatus({
						name: "status",
						value: name === "All Orders" ? "" : name,
					});
				}}
				className={`statuses-container--item ${
					hover === item || active === item
						? `statuses-container--item--icon--background-color--${bgColor}--hover`
						: ""
				}`}
				key={label}
			>
				<div
					className={`statuses-container--item--icon
           			statuses-container--item--icon--background-color--${bgColor}`}
				>
					<img alt="icon" src={icon} />
				</div>
				<div className="statuses-container--item--info">
					<p>{label}</p> <span>{statistikStatusCount?.data?.[name] || 0}</span>
				</div>
			</div>
		);
	});

	return <div className="statuses-container">{renderStatuses}</div>;
};

export default TopHeaderStatistik;
