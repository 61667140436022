export const Storage = {
	get: (item) => {
		const retrievedItem = localStorage.getItem(item);
		if (retrievedItem) {
			return JSON.parse(retrievedItem);
		}
	},
	subscribe: (item, callback) => {
		const listener = (event) => {
			if (event.type === item) {
				callback(event);
			}
		};
		window.addEventListener(item, listener);

		return () => window.removeEventListener(item, listener);
	},
};
