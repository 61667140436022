import React, {useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import InputComponent from "@src/common/input";
import {EditRowIcon} from "@src/assets/SvgComponents";
import {actions as languageActions} from "@sagas/language/index";
import i18n from "@src/i18n";
import {useUrlParams} from "@src/utils/useUrlParams";
import {actions as userEditAction} from "@sagas/userProfile/index";
import {actions as forgotAction} from "@sagas/auth/forgot";
import Form from "@src/common/Form";
import Upload from "../components/Upload";
import {modalData, MODALS} from "./helper";

const AccountSettings = ({
	loggedUser,
	changePhoto,
	toggleSagaLanguage,
	updatePassword,
	changeEmail,
	forgotPassword,
	userProfileState,
}) => {
	const {imgUrl, loading} = userProfileState;
	const [selectedFile, setSelectedFile] = useState(null);
	const _language = localStorage.getItem("language");
	const {setModal, getUrlParams} = useUrlParams();
	const {modal} = getUrlParams("modal");

	const handleUpdate = () => {
		if (selectedFile) {
			changePhoto({selectedFile, setSelectedFile});
		}
	};
	const toggleLanguage = (val) => {
		window.location.reload();
		i18n.changeLanguage(val);
		toggleSagaLanguage(val);
	};
	const handleModal = (type) => setModal(type);

	const formData = {
		inputs: modalData?.[modal]?.inputs || [],
		title: modalData?.[modal]?.title || "",
		submitFn: (values, actions) => {
			if (modal === "password") {
				updatePassword({values, formActions: actions, handleModal});
			}
			if (modal === "email") {
				changeEmail({values, formActions: actions, handleModal});
			}
			if (modal === "recoverPassword") {
				forgotPassword({values, formActions: actions, handleModal});
			}
		},
	};

	return (
		<>
			<Form
				open={MODALS.includes(modal)}
				handleClose={() => handleModal(false)}
				formData={formData}
				loading={loading}
			/>
			<div className="userProfile-wrapper">
				<div className="userProfile-section">
					<span className="title">{i18n.t("profilePic")}</span>
					<div className="userProfile-section-content">
						<Upload
							accept="image/*"
							onUpload={(e) => setSelectedFile(e.target.files[0])}
							canSave={selectedFile !== null}
							onSaveClick={() => handleUpdate(selectedFile)}
							img={
								imgUrl ||
								(selectedFile
									? URL.createObjectURL(selectedFile)
									: loggedUser?.imgUrl)
							}
						/>
					</div>
				</div>
				<div className="userProfile-section">
					<span className="title">{i18n.t("ChangeEmail")}</span>
					<div className="userProfile-section-content">
						<div className="userProfile-section-content-inner">
							<InputComponent
								newClass="disabled__input"
								values={loggedUser?.email}
								disabled
							/>
							<div className="iconsWrapper">
								<span onClick={() => handleModal("email")}>
									<EditRowIcon />
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="userProfile-section">
					<span className="title">{i18n.t("ChangePassword")}</span>
					<div className="userProfile-section-content">
						<div className="userProfile-section-content-inner">
							<InputComponent
								newClass="disabled__input"
								values="* * * * * * * * * *"
								disabled
							/>
							<div className="iconsWrapper">
								<span onClick={() => handleModal("password")}>
									<EditRowIcon />
								</span>
							</div>
						</div>
					</div>
					<div className="userProfile-section-bottom">
						<p className="nonClickable">{i18n.t("CantRemember")}</p>
						<p onClick={() => handleModal("recoverPassword")} className="clickable">
							{i18n.t("ResetYourPassword")}
						</p>
					</div>
				</div>
				<div className="userProfile-section">
					<span className="title">{i18n.t("changeLang")}</span>
					<div className="userProfile-section-content">
						<div className="userProfile-section-content-inner">
							<span className="subtitle">{i18n.t("selectLang")}</span>
							<div className="language__toggler-wrapper">
								<div
									className={`item ${_language === "en" && "active"}`}
									onClick={() => toggleLanguage("en")}
								>
									<span>EN</span>
								</div>
								<div
									className={`item ${_language === "de" && "active"}`}
									onClick={() => toggleLanguage("de")}
								>
									<span>DE</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
	userProfileState: state.app.userProfile.index,
});

const mapDispatchToProps = {
	changePhoto: userEditAction.changePhoto,
	toggleSagaLanguage: languageActions.toggleLanguage,
	updatePassword: userEditAction.updatePassword,
	changeEmail: userEditAction.changeEmail,
	forgotPassword: forgotAction.forgotPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountSettings));
