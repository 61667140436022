import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as conditionActions} from "@src/store/sagas/app/teamConditionsV1/index";
import {actions as createConditionActions} from "@src/store/sagas/app/teamConditionsV1/create";
import {actions as deleteConditionActions} from "@src/store/sagas/app/teamConditionsV1/delete";
import Loader from "@src/common/Loader";
import {useUrlParams} from "@src/utils/useUrlParams";
import moment from "moment";
import Tabs from "./Tabs";
import ConditionTable from "./ConditionTable";
import ConditionAccordion from "./Accordion/Accordion";
import ConfirmModal from "./ConfirmModal";
import CreateTable from "./CreateTable";
import {tabs} from "./utils";
import {overHeadColumns, selfProductionColumns} from "./conditionElements";

const ConditionsVersion1 = (props) => {
	const {conditionState, getConditions, setTableValues, deleteCondition, editTable, fillTable} =
		props;
	const {t} = useTranslation();
	const {overHeadCondition, selfProductionCondition, conditionTables, loading, tableToUpdate} =
		conditionState;
	const {getUrlParams, setUrlParams, setModal, removeParams} = useUrlParams();
	const {conditionType, modal, id} = getUrlParams("tab", "condition-type", "modal", "id");

	useEffect(() => {
		if (!conditionType) {
			setUrlParams("Conditions", "tab", {
				paramName: "condition-type",
				paramValue: "overhead",
			});
		}
	}, []);
	const handleModal = (e, type, id) => {
		e.stopPropagation();
		if (type === "edit") {
			setModal(conditionType, id);
		} else if (type === "update") {
			editTable({values: tableToUpdate});
		} else {
			setModal(type, id);
		}
	};
	const handleClose = () => setModal(false);
	const handleEdit = () => {
		if (modal === "copy") {
			setModal("duplicate", id);
		} else {
			deleteCondition({
				type: conditionType,
				id,
			});
			handleClose();
		}
	};
	useEffect(() => {
		if (conditionType) {
			getConditions(conditionType);
		}
	}, [conditionType]);
	useEffect(() => {
		if (modal === "duplicate" && conditionType && id && conditionTables?.length > 0) {
			setTableValues({id, type: modal, conditionType});
		}
	}, [modal, id, conditionType, conditionTables]);
	const closeModal = () => setModal(false);
	if (!conditionType) return null;

	return (
		<>
			<CreateTable
				handleClose={closeModal}
				open={modal === conditionType || modal === "duplicate"}
				tables={conditionTables}
				conditionType={conditionType}
				initialValues={
					conditionType === "overhead" ? overHeadCondition : selfProductionCondition
				}
			/>
			<ConfirmModal
				handleEdit={handleEdit}
				handleClose={handleClose}
				modalType={modal}
				showConfirmModal={modal === "delete" || modal === "copy"}
			/>
			<div className="conditions__container">
				<div className="conditions__container-tabs">
					<Tabs
						setActiveTab={(item) =>
							setUrlParams("Conditions", "tab", {
								paramName: "condition-type",
								paramValue: item,
							})
						}
						value={conditionType}
						items={tabs}
					/>
				</div>
				<div className="conditions__container-tables">
					{!loading ? (
						<>
							{conditionTables?.[0] &&
								conditionTables.map((table, index) => (
									<ConditionAccordion
										handleModal={handleModal}
										key={index}
										item={table}
										showSaveIcon={tableToUpdate?.id === table?.id}
										classNames="conditions__columns__edited"
										isExpired={
											table?.endDate
												? moment(table?.endDate).isBefore(moment())
												: false
										}
									>
										<ConditionTable
											rows={
												conditionType === "overhead"
													? [table.overheadLeader, table.overheadTrainer]
													: [
															table.selfProductionLeader,
															table.selfProductionTrainer,
													  ]
											}
											columns={
												conditionType === "overhead"
													? overHeadColumns?.map((col) => {
															if (
																col?.key === "min:countType" ||
																col?.key === "min:countTypeTeam"
															) {
																const type = table?.countType
																	? table?.countType
																			?.charAt(0)
																			.toUpperCase() +
																	  table?.countType
																			?.slice(1)
																			.toLowerCase()
																	: "Contracts";
																return {
																	...col,
																	name: t(
																		`${col.name.replace(
																			":countType",
																			type,
																		)}`,
																	),
																	key: col.key.replace(
																		":countType",
																		type,
																	),
																};
															}
															return col;
													  })
													: selfProductionColumns
											}
											fillTable={fillTable}
											activeTab={conditionType}
											item={table}
										/>
									</ConditionAccordion>
								))}
						</>
					) : (
						<div className="conditions__container-tables__loader">
							<Loader />
						</div>
					)}
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	conditionState: state.app.conditionsV1.index,
	showModal: state.app.conditionsV1.create.showModal,
});

const mapDispatchToProps = {
	setActiveTab: conditionActions.setActiveTab,
	setShowModal: createConditionActions.setShowModal,
	duplicateTable: conditionActions.duplicateTable,
	getConditions: conditionActions.getConditions,
	setTableValues: conditionActions.setTableValues,
	clearTableValues: conditionActions.clearTableValues,
	fillTable: conditionActions.fillTable,
	deleteCondition: deleteConditionActions.deleteTable,
	editTable: createConditionActions.createCondition,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConditionsVersion1));
