import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as bonusActions} from "@sagas/bonuses/clientBonus";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import moment from "moment";
import i18n from "../../../../i18n";

const logger = new Logger("Sagas>Bonus>Create Client Bonus");

const PREFIX = "@app/BonusClient/createClientBonus";
export const ADD_BONUS = `${PREFIX}ADD_BONUS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_BONUS = `${PREFIX}FILL_BONUS`;

const _state = {
	loading: false,
	bonusesValues: {
		contratsNr: null,
		value: null,
		frequency: {
			name: i18n.t("Monthly"),
			value: "monthly",
		},
		from: null,
		to: null,
	},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case FILL_BONUS:
				draft.bonusesValues = {
					...action.payload,

					frequency: {
						name: action?.payload?.frequency?.name
							? action?.payload?.frequency?.name
							: action?.payload?.frequency,
						value: action?.payload?.frequency.value
							? action?.payload?.frequency.value?.toLowerCase()
							: action?.payload?.frequency?.toLowerCase(),
					},
					from: action.payload.from,
					to: action.payload.to,
				};
				break;
			case SHOW_MODAL:
				draft.showModal = !state.showModal;
				if (!action.payload) {
					draft.bonusesValues = {
						id: null,
						contratsNr: null,
						value: null,
						frequency: {
							name: "MONTHLY",
							value: "monthly",
						},
						from: null,
						to: null,
					};
				}
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addBonus: (payload) => createAction(ADD_BONUS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillBonus: (payload) => createAction(FILL_BONUS, {payload}),
};

export const sagas = {
	*addBonus({payload}) {
		yield put(actions.setLoading(true));
		const {values} = payload;

		try {
			if (values?.id) {
				const obj = {
					...values,
					from: moment(values.from).valueOf(),
					to: moment(values.to).valueOf(),
					frequency: values.frequency.name,
				};
				delete obj.currencyId;

				yield axios.put(`/bonuses/update/`, obj);
				yield put(bonusActions.editBonusOnState(values));

				ToastSuccesComponent(i18n.t("BonusEditSuccess"));
			} else {
				const obj = {
					...values,
					frequency: values.frequency.name,
				};
				delete obj.currencyId;

				yield axios.post(`/bonuses/create/`, obj);

				yield put(bonusActions.addBonusOnState(values));
				yield put(bonusActions.clientBonusAddIncreaseTotalSize());
				ToastSuccesComponent(i18n.t("BonusEditSuccess"));
			}
			yield put(actions.setShowModal(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_BONUS, sagas.addBonus);
};
