import React from "react";
// import Logo from "@assets/img/oneri_green.svg";
import Logo from "@assets/img/oneriLogo.png";
import Back from "@assets/img/back__icon.svg";

const PrivacyPolicyHeader = (props) => {
	const {navigate} = props;
	return (
		<div className="privacy__policy__header">
			<div className="privacy__policy__header__items">
				<img onClick={() => navigate(`/auth/login`)} src={Back} alt="" />
				<img className="privacy__policy__oneri__logo" src={Logo} alt="" />
			</div>
		</div>
	);
};

export default PrivacyPolicyHeader;
