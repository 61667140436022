export const checkWidth = (wrapper, setItems, data, decrease = 0) => {
	const width = wrapper.offsetWidth;
	if (width) {
		switch (true) {
			case width < 700:
				setItems(data.slice(0, 2));
				break;
			case width < 800 && width > 700:
				setItems(data.slice(0, 3));
				break;
			case width < 850 && width > 799:
				setItems(data.slice(0, 4));
				break;
			case width < 1150 && width > 849:
				setItems(data.slice(0, 5));
				break;
			case width < 1370 - decrease && width > 999:
				setItems(data.slice(0, 6));
				break;
			default:
				setItems(data);
				break;
		}
	}
};
