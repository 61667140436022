import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation";
import ImportsHeader from "./components/ImportsHeader";
import Row from "./components/Row";
import {importTypes} from "./components/entityHelper";

const ImportsPage = ({navigate, match, loggedUser}) => {
	const handleNavigate = (to) => {
		navigate(`${match.path}/${to}`);
	};
	return (
		<div className="dashboard__container">
			<ImportsHeader />
			<div className="importsPage-layout">
				{importTypes
					?.filter((impType) => impType?.show)
					.map((impType, index) => (
						<Row
							key={index}
							title={impType?.title}
							description={impType?.description}
							onClick={() => handleNavigate(impType?.path)}
						/>
					))}
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImportsPage));
