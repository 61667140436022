import ModalComponent from "@src/common/CustomModal";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useUrlParams} from "@src/utils/useUrlParams";
import Box from "../Box";

const GroupedLeadsModal = ({data, handleClose}) => {
	const {t} = useTranslation();
	const {setModal} = useUrlParams();
	const handleLead = (id) => {
		setModal("lead", id);
		handleClose();
	};
	const {selectedStatuses} = useSelector((state) => state.app.salesTracker.index);
	return (
		<ModalComponent
			open={data.open}
			handleClose={handleClose}
			title={`${data?.data?.length} ${t("Leads")}`}
		>
			{data?.data?.map((item) => (
				<Box
					key={item.leadId}
					hasActions={false}
					boxBackground={item.color}
					item={{
						...item,
						leadColor: item.color,
						id: item.leadId,
						viewAddress: {
							street: item.street,
							addition: item.addition,
							houseNumber: item.houseNumber,
							zipCode: item.zipCode,
							partLocation: item.partLocation,
							location: item?.location,
						},
					}}
					selectedStatuses={selectedStatuses}
					boxClassName="modal__box"
					handleModal={handleLead}
				/>
			))}
		</ModalComponent>
	);
};

export default GroupedLeadsModal;
