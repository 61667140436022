const baseObj = {
	id: null,
	name: null,
	startDate: null,
	endDate: null,
	conditionType: "MONTHLY",
	countType: null,
	roleOfTeam: null,
	specification: null,
};
const overheadInitialValue = {
	...baseObj,
	minTeamSize: null,
	amount: null,
};

const selfProductionInitialValue = {
	...baseObj,
	value: null,
	conditionOrders: null,
};

const mobilityConditionsInitialValues = {
	...baseObj,
	countFrom: null,
	countTo: null,
	amount: null,
};

const personnelDevelopmentInitialValues = {
	...baseObj,
	minTeamSize: null,
	amount: null,
	oneTimeAmount: null,
};

const inductionBonusInitialValues = {
	...baseObj,
	team: null,
	trainer: null,
	intern: null,
	startConditionBonus: null,
	countInternFrom: null,
	countInternTo: null,
	amount: null,
};
const managerVOInitialValues = {
	...baseObj,
	deduction1: null,
	deduction2: null,
	compensationIncomingCommission: null,
	salesOrganizations: [],
};

export const initialValues = {
	overhead: overheadInitialValue,
	bonus_own_contracts: selfProductionInitialValue,
	mobility_conditions: mobilityConditionsInitialValues,
	overhead_personnel_conditions: personnelDevelopmentInitialValues,
	induction_bonus_trainers_conditions: inductionBonusInitialValues,
	manager_vo: managerVOInitialValues,
};
