/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import moment from "moment";
import createAction from "../../../../../utils/action-creator";
import axios from "../../../../../utils/axios";
import {actions as templateActions} from ".";
import Logger from "../../../../../utils/logger";

const logger = new Logger("Create Template");

const PREFIX = "@app/admin/templates/create";
export const CREATE = `${PREFIX}CREATE`;
export const CLEAR_FORM = `${PREFIX}CLEAR_FORM`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	data: {
		number_codes: null,
		name: "",
	},
	loading: false,
	modal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case CLEAR_FORM:
				draft.data = state.data;
				break;
			case SHOW_MODAL:
				draft.modal = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	create: (payload) => createAction(CREATE, {payload}),
	clearForm: (payload) => createAction(CLEAR_FORM, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	showModal: (payload) => createAction(SHOW_MODAL, {payload}),
};

export const sagas = {
	*create({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.post("/generate", payload);
			yield put(actions.showModal(false));
			const obj = {
				name: payload.name,
				codes: payload.number_codes,
				date_added: moment(),
			};
			yield put(templateActions.addOnList(obj));
		} catch (error) {
			logger.error("generate error", error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE, sagas.create);
};
