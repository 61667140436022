import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import i18n from "../../../../../i18n";

const logger = new Logger("Users index");

const PREFIX = "@app/users/user/index";
export const EDIT_USER = `${PREFIX}EDIT_USER`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const EDIT_USER_SUCCESS = `${PREFIX}EDIT_USER_SUCCESS`;

const _state = {
	loading: false,
	userEdited: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;

			case EDIT_USER_SUCCESS:
				draft.userEdited = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	editUser: (payload) => createAction(EDIT_USER, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	editUserSucces: (payload) => createAction(EDIT_USER_SUCCESS, {payload}),
};

export const sagas = {
	*editUser(payload) {
		yield put(actions.setLoading(true));

		const {values} = payload.payload;
		try {
			yield axios.post(`/user/update/`, values);
			ToastSuccesComponent(i18n.t("UserEditSuccess"));
			yield put(actions.editUserSucces(true));
			yield put(actions.editUserSucces(false));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(EDIT_USER, sagas.editUser);
};
