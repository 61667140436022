import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ToastSuccesComponent, ToastInfoComponent} from "@common/ToastComponent/ToastComponent";
import {ENDPOINT} from "@src/utils/endpoint";
import {actions as deleteConditionActions} from "@src/store/sagas/app/teamConditions/delete";
import {actions as structuresActions} from "./index";
import i18n from "../../../../i18n";

const logger = new Logger("Structure create");
const PREFIX = "@app/structures/delete";

export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const DELETE_STRUCTURE_TABLE = `${PREFIX}DELETE_STRUCTURE_TABLE`;
export const DELETE_STUFE = `${PREFIX}DELETE_STUFE`;

const _state = {
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_LOADING:
				draft.loading = payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	deleteStructureTable: (payload) => createAction(DELETE_STRUCTURE_TABLE, {payload}),
	deleteStufe: (payload) => createAction(DELETE_STUFE, {payload}),
};

export const sagas = {
	*deleteStructureTable({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {id, setModal, conditionIds} = payload;
			yield axios.put(ENDPOINT.DELETE_STRUCTURE.replace(":id", id));
			yield put(structuresActions.removeTableFromState(id));
			setModal(false);
			ToastSuccesComponent(i18n.t("StructureDeletedSuccess"));
			if (conditionIds) {
				for (let i = 0; i < conditionIds.length; i++) {
					const id = conditionIds[i];
					yield put(
						deleteConditionActions.deleteTable({
							type: "career_conditions",
							id,
							hideToast: true,
						}),
					);
				}
			}
		} catch (error) {
			if (error.response.data.message.includes("STRUCTURE_IS_ASSIGNED_TO_USER")) {
				ToastInfoComponent("You cannot delete a structure assigned to an user!!!");
				payload.setModal(false);
			}
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteStufe({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {id, setModal} = payload;
			yield axios.put(ENDPOINT.DELETE_STUFE.replace(":id", id));
			yield put(structuresActions.deleteStufeOnState(id));
			setModal(false);
			ToastSuccesComponent(i18n.t("StuffeDeletedSuccess"));
		} catch (error) {
			if (error.response.data.message.includes("STUFE_IS_ASSIGNED_TO_USER")) {
				ToastInfoComponent("You cannot delete a stufe assigned to an user!!!");
				payload.setModal(false);
			}
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(DELETE_STRUCTURE_TABLE, sagas.deleteStructureTable);
	yield takeLatest(DELETE_STUFE, sagas.deleteStufe);
};
