import {useState} from "react";
import {axiosRequests} from "./getAgAxios";

const getLocations = async (value) => {
	if (value.length > 4) {
		const {data} = await axiosRequests.getAgRequest({
			url: "/jaxrs/OrtsinfoService13x0/getOrteByPlz",
			data: value,
		});
		const locations = data?.data?.ort;
		if (locations) {
			try {
				return locations.flatMap((location) => [
					{
						labelEn: location.ortsname,
						labelDe: location.ortsname,
						name: location.ortsname,
						label: location.ortsname,
						value: location.ortsname,
					},
					...(location.ortsteil?.length
						? location.ortsteil.map((itm) => ({
								labelEn: `${location.ortsname} | ${itm}`,
								labelDe: `${location.ortsname} | ${itm}`,
								name: `${location.ortsname} | ${itm}`,
								label: `${location.ortsname} | ${itm}`,
								value: `${location.ortsname} | ${itm}`,
						  }))
						: []),
				]);
			} catch (e) {
				return [];
			}
		}
		return [];
	}
	return [];
};

const getStreets = async (postCode, location) => {
	const {data} = await axiosRequests.getAgRequest({
		url: "/jaxrs/OrtsinfoService13x0/getStrassenByPlzOrt",
		json: {
			plz: postCode,
			ort: location.split(" | ")[1] || location.split(" | ")[0],
		},
	});
	if (data?.data?.strasse) {
		const newArr = data?.data?.strasse?.map(({name}) => ({
			labelEn: name,
			labelDe: name,
			name,
			label: name,
			value: name,
		}));
		return newArr;
	}
	return [];
};

const useGenericLocations = () => {
	const [options, setOptions] = useState({});
	const retrieveDataFromGetAg = async (item, value, dependentFrom) => {
		if (item.type === "postCode") {
			const locations = await getLocations(value);
			if (item.dependencyFields) {
				setOptions((prev) => ({
					...prev,
					[item.dependencyFields[0]]: locations,
				}));
			}
		} else if (item.type === "location" && value) {
			const streets = await getStreets(dependentFrom, value);
			if (item.dependencyFields) {
				setOptions((prev) => ({
					...prev,
					[item.dependencyFields[0]]: streets,
				}));
			}
		}
	};
	return {options, retrieveDataFromGetAg};
};
export default useGenericLocations;
