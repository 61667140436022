import React from "react";
import AccountSettings from "./pages/AccountSettings";
import BillingDetails from "./pages/BillingDetails";
import EmailSettings from "./pages/EmailSettings";
import MarketPlace from "./pages/MarketPlace";
import PublicProfile from "./pages/PublicProfile";

export const RenderTabs = (props) => {
	const {activeChange} = props;
	switch (activeChange) {
		case "account-settings":
			return <AccountSettings />;
		case "public-profile":
			return <PublicProfile />;
		case "billing-details":
			return <BillingDetails />;
		case "email-templates":
			return <EmailSettings />;
		case "marketplace":
			return <MarketPlace />;
		default:
			return <></>;
	}
};
