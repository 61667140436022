import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {ExpandAccordionIcon} from "@src/assets/SvgComponents";
import React from "react";
import AccordionHeader from "./AccordionHeader";

const ConditionAccordion = (props) => {
	const {children, handleModal, item, showSaveIcon, isExpired} = props;
	const {name, teamIds, startDate, endDate, id} = item;
	return (
		<Accordion className="conditions__container-tables__accordion">
			<AccordionSummary
				expandIcon={<ExpandAccordionIcon />}
				className="conditions__container-tables__accordion-header"
			>
				<AccordionHeader
					title={name}
					teams={teamIds?.map((t) => t?.name)}
					fromDate={startDate}
					toDate={endDate}
					showSaveIcon={showSaveIcon}
					isExpired={isExpired}
					onCopy={(e) => handleModal(e, "copy", id)}
					onUpdate={(e) => handleModal(e, "update", id)}
					onEdit={(e) => handleModal(e, "edit", id)}
					onDelete={(e) => handleModal(e, "delete", id)}
				/>
			</AccordionSummary>
			{children}
		</Accordion>
	);
};

export default ConditionAccordion;
