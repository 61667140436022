import HtmlTooltip from "@src/common/htmlTooltip";
import React from "react";

function Button({
	icon,
	text,
	handleChange,
	handleButton,
	leftRounded = false,
	rightRounded = false,
	disabled = false,
	type,
	color = "#fff",
	border = "none",
	borderRadius = "8px",
	width = "30px",
	height = "30px",
	minWidth = "100px",
	justifyContent = "center",
	customClassName,
	smallButtonWithTooltip = false,
	hideTooltip = false,
	textForSmallButton = null,
	placement = "bottom",
	fontSize = "",
	fontWeight = "",
}) {
	if (smallButtonWithTooltip) {
		return (
			<HtmlTooltip
				title={<span>{text}</span>}
				placement={placement}
				disableHoverListener={hideTooltip}
			>
				<button
					// eslint-disable-next-line react/button-has-type
					type={type}
					disabled={disabled}
					style={{
						color,
						borderRadius,
						width,
						height,
						opacity: disabled ? "0.7" : "1",
						backgroundColor: disabled ? "#f0f0f0" : "",
						border: disabled ? "1px solid #C1C1C1" : "",
						pointerEvents: disabled ? "none" : "",
						minWidth: "30px",
						justifyContent,
						fontSize,
						fontWeight,
					}}
					className={`createuserButton ${
						// eslint-disable-next-line no-nested-ternary
						leftRounded ? "leftRounded" : rightRounded ? "rightRounded" : ""
					} ${customClassName}`}
					onClick={handleChange}
				>
					{textForSmallButton || <img src={icon} alt="" />}
				</button>
			</HtmlTooltip>
		);
	}
	return (
		<>
			{type === "submit" ? (
				<button
					// eslint-disable-next-line react/button-has-type
					type={type}
					disabled={disabled}
					style={{
						border,
						borderRadius,
						width,
						height,
						backgroundColor: disabled && "#E0DFE0",
						color: disabled && "#C1C1C1",
						pointerEvents: disabled ? "none" : "",
						minWidth,
						justifyContent,
						fontSize,
						fontWeight,
					}}
					className={`createuserButton ${
						// eslint-disable-next-line no-nested-ternary
						leftRounded ? "leftRounded" : rightRounded ? "rightRounded" : ""
					} ${customClassName}`}
					onClick={handleButton}
				>
					{icon && <img src={icon} alt="" />}
					<span>{text}</span>
				</button>
			) : (
				<div
					disabled={disabled}
					style={{
						border,
						borderRadius,
						width,
						height,
						color: disabled && "#C1C1C1",
						backgroundColor: disabled && "#E0DFE0",
						pointerEvents: disabled ? "none" : "",
						minWidth,
						justifyContent,
						fontSize,
						fontWeight,
					}}
					className={`createuserButton ${
						// eslint-disable-next-line no-nested-ternary
						leftRounded ? "leftRounded" : rightRounded ? "rightRounded" : ""
					} ${customClassName}`}
					onClick={(e) => handleChange(e)}
				>
					{icon && <img src={icon} alt="" />}
					<span>{text}</span>
				</div>
			)}
		</>
	);
}

export default Button;
