import React from "react";
import {AttachIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";

const UploadButtonComponent = ({
	margin,
	height = 32,
	backgroundColor = "#6C1EB0",
	onFileUpload,
	icon = <AttachIcon width="15" height="16" />,
	// eslint-disable-next-line max-len
	accept = ".png, .jpg, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .mov, .mp4, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword",
	text = "uploadFile",
	key,
}) => {
	const {t} = useTranslation();
	return (
		<>
			<div className="uploadFiles__container" style={{marginTop: margin}}>
				<label htmlFor="file">
					<div
						className="uploadFiles__container_attach"
						style={{
							background: backgroundColor,
							height,
						}}
					>
						<div className="uploadFiles__container_attach-icon">
							{t(text)}
							{icon}
						</div>
						<input
							type="file"
							style={{display: "none"}}
							id="file"
							name="files"
							multiple={true}
							onChange={(e) => {
								onFileUpload(e.target.files);
							}}
							accept={accept}
							key={key}
						/>
					</div>
				</label>
			</div>
		</>
	);
};

export default UploadButtonComponent;
