/* eslint-disable no-nested-ternary */
import React, {useState} from "react";
import {useDrop, useDrag} from "react-dnd";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import {useTranslation} from "react-i18next";
import {isInsideParent} from "./staticData";

export const TargetBox = ({
	children,
	moveBox,
	imgRef,
	droppableItems,
	draggableItems,
	handleLinesDrop,
	lines,
	setLines,
}) => {
	const [focusedLine, setFocusedLine] = useState(null);
	const {t} = useTranslation();
	const [{isOver}, drop] = useDrop(
		() => ({
			accept: "box",
			collect: (monitor) => ({
				isActive: monitor.canDrop() && monitor.isOver(),
				isOver: !!monitor.isOver(),
			}),
			drop: (item, monitor) => {
				const offset = monitor.getSourceClientOffset();
				const dropTargetXy = imgRef.current.getBoundingClientRect();
				const x = offset.x - dropTargetXy.left;
				const y = offset.y - dropTargetXy.top + 1;
				if (item.type === "vertical" || item.type === "horizontal") {
					// Handle dropped line
					handleLinesDrop(item, x, y);
				} else if (offset && imgRef.current) {
					const isWithinBounds = isInsideParent(imgRef, {
						...item,
						x,
						y,
						width: item?.width
							? item?.width
							: item?.inputType === "CHECKBOX"
							? 30
							: 180,
						height: item.height || 30,
					});
					if (isWithinBounds) {
						moveBox({...item, x, y});
					} else {
						ToastErrorComponent(t("fieldOutsidePdfError"));
					}
				}
				return undefined;
			},
		}),
		[droppableItems, draggableItems],
	);
	const containerStyle = {
		border: "2px dashed #ccc",
		opacity: isOver ? "0.5" : "1",
	};

	const handleFocus = (index) => {
		setFocusedLine(index);
	};

	const handleDelete = (index) => {
		const updatedLines = lines.filter((line, lIndex) => lIndex !== index);
		setLines(updatedLines);
		setFocusedLine(null);
	};
	return (
		<div ref={drop} className="target-box" style={containerStyle}>
			<div className="target-box__lines">
				{lines.map((line, index) => (
					<DraggableLine
						key={index}
						index={index}
						type={line.type}
						position={line.position}
						onFocus={handleFocus}
						onDelete={handleDelete}
						focusedLine={focusedLine}
						handleLinesDrop={handleLinesDrop}
					/>
				))}
			</div>
			{children}
		</div>
	);
};

const DraggableLine = ({
	index,
	type,
	position,
	onFocus,
	onDelete,
	focusedLine,
	handleLinesDrop,
}) => {
	const [{isDragging}, drag] = useDrag({
		type: "box",
		item: {index, type, position},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	const lineStyle = {
		position: "absolute",
		background: focusedLine === index ? "red" : "black",
		cursor: "move",
		zIndex: "100",
		opacity: isDragging ? 0.5 : 1,
		outline: "none",
	};

	const handleMouseDown = () => {
		onFocus(index);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Backspace" && focusedLine === index) {
			onDelete(index);
		}
		let moveStep = 5; // Adjust this value based on the desired movement step
		const isOptionKeyPressed = e.altKey;
		moveStep = isOptionKeyPressed ? 1 : 5;
		// Handle arrow key movements
		switch (e.key) {
			case "ArrowLeft":
				if (type === "vertical") {
					handleLinesDrop(
						{index, type, position},
						position - moveStep,
						position - moveStep,
					);
				}
				e.preventDefault(); // Prevent default scroll action
				break;
			case "ArrowRight":
				if (type === "vertical")
					handleLinesDrop(
						{index, type, position},
						position + moveStep,
						position + moveStep,
					);
				e.preventDefault();
				break;
			case "ArrowUp":
				if (type === "horizontal")
					handleLinesDrop(
						{index, type, position},
						position - moveStep,
						position - moveStep,
					);
				e.preventDefault();
				break;
			case "ArrowDown":
				if (type === "horizontal")
					handleLinesDrop(
						{index, type, position},
						position + moveStep,
						position + moveStep,
					);
				e.preventDefault();
				break;
			default:
				break;
		}
	};

	if (type === "vertical") {
		lineStyle.width = "2px";
		lineStyle.height = "100%";
		lineStyle.left = `${position}px`;
	} else if (type === "horizontal") {
		lineStyle.width = "595px";
		lineStyle.height = "2px";
		lineStyle.top = `${position}px`;
	}

	return (
		<div
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={0}
			ref={drag}
			onMouseDown={handleMouseDown}
			onKeyDown={handleKeyDown}
			style={lineStyle}
		></div>
	);
};
