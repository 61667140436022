import {EmptyMessageComp} from "@src/assets/SvgComponents";
import React from "react";

const EmptyMessageComponent = () => (
	<div className="emptyMessage__continer">
		<EmptyMessageComp />
		<div className="emptyMessage__continer-text">
			<span className="emptyMessage__continer-text-first">Chat is Empty</span>
			<span className="emptyMessage__continer-text-second">
				Pick a person from left menu and start your conversation
			</span>
		</div>
	</div>
);

export default EmptyMessageComponent;
