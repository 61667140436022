import CreateEditOrder from "@components/dashboard/orders/createEditOrder";
import React, {useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import createOrderStatusLogo from "@assets/img/createStatusIcon.svg";
import {actions as createOrderActions} from "@sagas/orders/create";
import {actions as teamActions} from "@sagas/teams";
import {useTranslation} from "react-i18next";
import {actions as tariffActios} from "@sagas/tariff";
import {actions as clientActions} from "@sagas/client";
import {actions as projectCategoriesActions} from "@sagas/projectCategories/clientCategory";
import {actions as categoryBonusesActions} from "@sagas/projectCategories/bonuses";
import {actions as locationActions} from "@sagas/locations";
import {actions as billingMonthsActions} from "@sagas/calculations/billingMonths";
import {actions as usersActions} from "@sagas/users/users/index";
import Modal from "@common/modal";
import {ToastErrorComponent, ToastInfoComponent} from "@common/ToastComponent/ToastComponent";
import {actions as navigation} from "@sagas/navigation/";
import calculationsLogo from "@assets/img/calculations-logo.svg";
import notCalculationsLogo from "@assets/img/notCalculations-logo.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HtmlTooltip from "@common/htmlTooltip";
import {checkRoles} from "@utils/checkRoles";
import {CheckLogo, OrdersIcon} from "@assets/SvgComponents";
import Tabs from "@common/components/tabs";
import Button from "@common/components/button";
import {Popover} from "antd";
import DataGridTable from "@src/common/table/DataGridTable";
import {useUrlParams} from "@src/utils/useUrlParams";
import {actions as salesOrgActions} from "@sagas/salesOrganizations/index";
import {actions as orderStatusAction} from "@sagas/orders/statuses";
import {Autocomplete} from "@material-ui/lab";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import TextField from "@material-ui/core/TextField";
import {useFeatures} from "@src/utils/useFeatures";
import {buildPayload} from "@src/store/sagas/app/orders/utils";
import axios from "axios";
import TableSkeleton from "@src/common/table/TableSkeleton";
import CustomPopover from "@src/common/Popover";
import {baseOrderStatusesObj} from "@src/utils/constants";
import downloadIcon from "../../../assets/img/downloadicon.svg";
import {
	infoChefColumns,
	columns,
	updatedStatusesColumns,
	tableTabs,
	userRolesDropdown,
	settleModalInitialValues,
	reactivateModalInitialValues,
	baseTabsArray,
} from "./staticData";
import SettleOrders from "./SettleOrders";
import InformationTooltip from "./components/InformationTooltip";
import Table from "./Table";
import CreateStatusActions from "../sales-tracker/components/leadStatuses/CreateStatusActions";
import PriceForm from "./components/PriceForm";
import EditOrder from "./createEditOrder/EditOrder";
import {salesTrackerButtonProps} from "../sales-tracker/staticData";
import EditAndCreateOrderStatus from "./EditAndCreateOrderStatus";
import Filters from "./Filters";
import {actionsFormatter, getByIdFormatter} from "./tableActions";

export function getNestedValue(obj, key, splitBy = ".") {
	return key?.split(splitBy)?.reduce((result, key) => result?.[key], obj);
}

const Orders = (props) => {
	const {
		getOrders,
		fetchTariffsByDropdown,
		fetchProjectCategoriesByDropdown,
		editSize,
		editPage,
		fetchClientsByDrowdown,
		changeOrdersStatus,
		navigate,
		downloadOrderById,
		getOrderPreview,
		toggleEditOrderModal,
		getTeams,
		getAgencies,
		getInfoChefOrders,
		fetchLocations,
		editLocationSize,
		getUpdatedStatuses,
		editTeamSize,
		getBillingMonths,
		getUserDropdown,
		getBonuses,
		getAllSalesOrganizations,
		getOrderStatuses,
		loggedUser,
		setExportStatus,
		ordersState,
		statuses,
		editOrderState,
	} = props;
	const {
		query: sagaQuery,
		page,
		totalSize,
		size,
		orders,
		downloadLoading,
		infoChefOrders,
		updatedStatuses,
		statusesLoader,
	} = ordersState;
	const {showTimeline, showModal: showEditModal} = editOrderState;
	const {t} = useTranslation();
	const [selectedPds, setSelectedPds] = useState([]);
	const [previewRow, setPreviewRow] = useState(false);
	const [showPopover, setShowPopover] = useState(false);
	const [autocompleteOpen, setAutocompleteOpen] = useState(false);
	const [selectedRows, setSelectedRows] = useState(() => new Set());
	const [selectedRowObjects, setSelectedRowObjects] = useState([]);
	const [settleModal, setSettleModal] = useState(settleModalInitialValues);
	const [reEditPage, setReEditPage] = useState(true);
	const [reactivateModal, setReactivateModal] = useState(reactivateModalInitialValues);
	const [status, setStatus] = useState(null);
	const {checkFeature} = useFeatures();
	const isV2CurrentVersion = checkFeature("Change_order_status_v2");
	const tableRef = useRef();
	const resetPage = () => {
		if (tableRef.current) {
			tableRef.current.scrollToRow(0);
		}
		editPage(1);
	};
	const items = [
		{
			key: "menu",
			label: <CreateStatusActions type="orderStatuses" handleVisibility={setShowPopover} />,
		},
	];
	const {setUrlParams, getUrlParams, setQuery, clearUrlQuery, setModal} = useUrlParams(resetPage);
	const {tab, tableTab, query} = getUrlParams("tab", "table-tab", "query");
	const initializeQueryParams = (item, customTableTab) => {
		const tableTabToInsert = customTableTab || tableTab || "all";
		setUrlParams(
			item || "orders",
			"tab",
			{paramValue: tableTabToInsert, paramName: "table-tab"},
			{
				paramName: "query",
				paramValue: {...sagaQuery, fromDate: null, toDate: null},
			},
			false,
		);
	};
	const setActiveTab = (paramValue) => {
		setUrlParams(paramValue, "table-tab");
		cleanQuery(paramValue);
	};
	const cleanQuery = (paramValue) => {
		setQuery({
			...sagaQuery,
			unFilteredStatus: paramValue === "all" ? null : paramValue,
		});
	};
	const clearSelectedRows = () => {
		setSelectedRows(new Set());
		setSelectedRowObjects([]);
	};
	useEffect(() => {
		if (tab === "orders" || !tab) initializeQueryParams();
		if (tab === "orders" && isV2CurrentVersion) {
			getAllSalesOrganizations("all");
		}
		if (tab === "statusHistory") {
			getUpdatedStatuses();
		}
		if (tab === "infoChef") {
			getInfoChefOrders();
		}
		setReEditPage(false);
		editPage(1);
		editSize(30);
	}, [tab]);
	useEffect(() => {
		fetchTariffsByDropdown();
		fetchClientsByDrowdown();
		getOrderStatuses();
		getTeams({isTeam: false, all: true});
		getAgencies({isTeam: true, all: true});
		getBillingMonths({forDropdown: true});
	}, []);
	useEffect(() => {
		editLocationSize(1000);
		if (query?.clientId) {
			fetchLocations(query?.clientId);
			fetchProjectCategoriesByDropdown(query?.clientId);
		}
		return () => {
			editSize(30);
			editPage(1);
			setReEditPage(false);
			editTeamSize(30);
		};
	}, [query?.clientId]);
	useEffect(() => {
		if (query?.projectCategoryId) {
			getBonuses(query?.projectCategoryId);
		}
	}, [query?.projectCategoryId]);
	useEffect(() => {
		if (loggedUser?.role) {
			if (checkRoles("getUsersByHeadOfSalesId")) {
				getUserDropdown("HEAD_OF_SALES");
			} else {
				getUserDropdown(
					tableTab === "BONUS"
						? [...userRolesDropdown, "AGENCY", "AGENCY_70"]
						: userRolesDropdown,
				);
			}
		}
		resetPage();
		editSize(30);
	}, [tableTab, loggedUser]);

	useEffect(() => {
		clearSelectedRows();
		if (tab === "orders") {
			getOrders(query);
		}
	}, [JSON.stringify(query), page, size, tableTab, tab]);
	const selectHandler = (event) => {
		event.stopPropagation();
		if (Array.from(selectedRows)?.[0]) {
			if (status.status === "CONTRACT_REACTIVATED") {
				handleReactivateModal(true);
			} else {
				changeOrdersStatus({
					orders: selectedRowObjects.map((i) => ({
						id: i.orderId,
						clientPayoutModel: i.clientPayoutModel,
					})),
					systemStatusId: status?.value,
				});
				clearSelectedRows();
			}
		} else {
			ToastErrorComponent(t("PleaseSelectAction"));
		}
		setAutocompleteOpen(false);
	};
	const handleSearch = (value) => {
		const search = value.target.value;
		const newQuery = {...query, search};
		setQuery(newQuery);
		const debounced = _.debounce(() => {
			getOrders(newQuery);
		}, 1000);
		debounced();
	};

	const downloadCsv = async (data, type) => {
		if (type === "single") {
			if (data.exportPdf && data.fileNames?.[0]) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "multiple") {
			const filteredData = orders?.filter(
				(item) => item?.exportPdf && data?.includes(item.orderId) && item?.fileNames?.[0],
			);
			if (filteredData.length > 0) {
				downloadOrderById({filteredData, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "homeNetMultiplePdf") {
			if (data.exportPdf && data.fileNames && data.fileNames.length > 0) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
			setSelectedPds([]);
		}
	};

	const handlePreviewEdit = (id, type) => {
		setPreviewRow(type === "preview");
		toggleEditOrderModal(true);
		getOrderPreview(id);
	};

	const handleAction = (type, row) => {
		const {orderId, fileNames, clientName} = row;
		if (type === "preview" || type === "edit") {
			handlePreviewEdit(orderId, type);
		}
		if (type === "price") {
			setModal("price", orderId);
		}
		if (type === "download") {
			if (clientName !== "HomeNet") {
				downloadCsv(row, "single");
			} else {
				setSelectedPds(fileNames);
				downloadCsv(row, "homeNetMultiplePdf");
			}
		}
		if (type === "navigate") {
			onRowClick(row);
		}
	};
	const actions = {
		key: "actions",
		name: t("Actions"),
		formatter: ({row}) =>
			tab !== "statusHistory"
				? actionsFormatter({row, handleAction, tableTab})
				: getByIdFormatter({row, handleAction}),
		sortable: false,
		resizable: false,
		width: 50,
	};
	const columnsWithActions = useMemo(() => {
		const newCols = columns?.filter((col) => (col?.type ? col.type === tab : true));
		return [...newCols, actions];
	}, [tab, loggedUser, tableTab]);

	const onRowClick = (row) => navigate(`/admin/orders/${row?.id}`);

	const handleCloseSettleModal = () => setSettleModal(settleModalInitialValues);
	const handleCloseReactivateModal = () => setReactivateModal(reactivateModalInitialValues);
	const handleSettleModal = (visible) => {
		if (Array.from(selectedRows)?.[0] && !isSettleBonusDisabled) {
			if (isV2CurrentVersion || tableTab === "BONUS") {
				setSettleModal({
					...settleModalInitialValues,
					open: visible,
				});
			} else {
				const singleStatus = statuses.find((item) => item.status === "SETTLED");
				changeOrdersStatus({
					orders: selectedRowObjects.map((i) => ({
						id: i.orderId,
						clientPayoutModel: i.clientPayoutModel,
					})),
					systemStatusId: singleStatus.id,
				});
				clearSelectedRows();
			}
		}
	};
	const handleReactivateModal = (visible) => {
		if (Array.from(selectedRows)?.[0]) {
			setReactivateModal({
				...reactivateModalInitialValues,
				open: visible,
			});
		}
	};
	const handleTabClick = (item) => {
		if (item === tab) return;
		if (item === "orders") {
			initializeQueryParams(item, "all");
			setQuery(sagaQuery);
		} else {
			setUrlParams(item, "tab");
			clearUrlQuery();
		}
	};
	const exportCsvRequest = async (payload) => {
		if (totalSize > 0) {
			ToastInfoComponent(t("DownloadInProcess"));
			setExportStatus("PROGRESS");
			try {
				await axios.post(`/order/export?page=${1}&size=${totalSize}`, {
					...buildPayload(null, payload),
					export: true,
				});
			} catch (error) {
				ToastErrorComponent(t("DownloadFailed"));
			}
		}
	};
	const isSettleBonusDisabled =
		selectedRows?.size === 0 ||
		(tableTab === "BONUS" &&
			(Array.isArray(query?.userIds) ? !query?.userIds?.[0] : !query?.userIds));
	const filteredStatuses = useMemo(
		() =>
			statuses.filter(
				(item) =>
					(item.status !== "SETTLED" && item.status !== "BONUS_SETTLED") ||
					item.status === "CONTRACT_REACTIVATED",
			),
		[statuses],
	);

	const renderStatusOptions = () => {
		if (tab === "orders") {
			return (
				<>
					<CustomPopover
						customClass="custom-popover-leadstatuses"
						menu={{items}}
						open={showPopover}
						handleVisibility={setShowPopover}
						placement="bottomRight"
					>
						<div>
							<Button
								{...salesTrackerButtonProps}
								icon={createOrderStatusLogo}
								leftRounded={false}
								rightRounded={false}
								hideTooltip
								smallButtonWithTooltip
							/>
						</div>
					</CustomPopover>
					<ClickAwayListener onClickAway={() => setAutocompleteOpen(false)}>
						<div className="wrapper__change-status">
							<Autocomplete
								open={autocompleteOpen}
								disableClearable={false}
								disableCloseOnSelect={false}
								className="test-autocompleteee"
								filterSelectedOptions={false}
								getOptionLabel={(option) => option.label}
								getOptionSelected={(option, value) =>
									option?.value === value?.value
								}
								onOpen={() => setAutocompleteOpen(true)}
								onClose={(event, reason) => {
									setAutocompleteOpen(
										reason === "select-option" || reason === "blur",
									);
								}}
								limitTags={20}
								multiple={false}
								onChange={(event, value) => setStatus(value)}
								options={filteredStatuses}
								renderInput={(params) => (
									<TextField
										{...params}
										className="text-field"
										id="name"
										name="name"
										placeholder={t("SelectOrderStatus")}
										variant="outlined"
									/>
								)}
								value={status}
								renderOption={(item, option) => {
									const baseStatus = baseOrderStatusesObj?.[item?.status];
									return (
										<li
											className={`custom__option ${
												option?.selected ? "selected" : ""
											}`}
											key={item?.id}
										>
											<span>{baseStatus?.dropdownIcon} </span>
											<span>{item?.label}</span>
										</li>
									);
								}}
								PopperComponent={(props) => (
									<Popper {...props} className="autoCompleteStyle">
										<div className="popperComponent">
											{props.children}
											<div
												className={`popperComponentStyle ${
													status ? "popperComponentStyle-second" : ""
												}`}
												onMouseDown={selectHandler}
											>
												<CheckLogo fill={status ? "#400077" : "#cacaca"} />
											</div>
										</div>
									</Popper>
								)}
							/>
						</div>
					</ClickAwayListener>
				</>
			);
		}
		return null;
	};
	return (
		<>
			<EditAndCreateOrderStatus />
			<PriceForm />
			{previewRow ? (
				<Modal
					open={showEditModal}
					handleClose={() => toggleEditOrderModal(false)}
					disableEnforceFocus
					className="add_edit_user preview"
					width={showTimeline ? 1000 : 600}
					positionModal="right"
					overflow="hidden"
					border={previewRow && "none"}
					padding={previewRow && 0}
					display={previewRow && "flex"}
				>
					<CreateEditOrder
						setShowEditModal={toggleEditOrderModal}
						previewMode={previewRow}
						setReEditPage={setReEditPage}
					/>
				</Modal>
			) : (
				<EditOrder open={showEditModal} onClose={() => toggleEditOrderModal(false)} />
			)}
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={downloadLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="dashboard__container orders">
				<div className="header-component" style={{height: "auto"}}>
					<div className="dashboard__left__text-header">
						<div className="header-component-wrapper">
							<OrdersIcon />
							<span className="header-component-text">{t("Orders")}</span>
						</div>
						<Tabs
							items={checkRoles("seeInfoChef") ? baseTabsArray : ["orders"]}
							onClick={(_, item) => handleTabClick(item)}
							value={t(tab)}
						/>
					</div>
					{tab !== "infoChef" && tab !== "statusHistory" && (
						<div className="dashboard__left__text-ordersactions">
							{checkRoles("settleContracts") && tableTab !== "all" && (
								<Popover
									trigger={["click"]}
									open={settleModal.open}
									overlayClassName="popup_add_edit_user paddingLess"
									onOpenChange={handleSettleModal}
									placement="bottomRight"
									content={
										<SettleOrders
											handleClose={handleCloseSettleModal}
											selectedRows={selectedRows}
											clearSelectedRows={clearSelectedRows}
											selectedRowObjects={selectedRowObjects}
											initialValues={settleModal.initialValues}
											tableTab={tableTab}
											open={settleModal.open}
										/>
									}
								>
									<HtmlTooltip
										title={<InformationTooltip data={t("bonusSettleRules")} />}
										disableHoverListener={
											tableTab !== "BONUS" || !isSettleBonusDisabled
										}
									>
										<div className="wrapper__change-status">
											<Button
												border={
													selectedRows?.size > 0
														? "none"
														: "1px solid #C1C1C1"
												}
												handleChange={() => {}}
												hideTooltip
												disabled={isSettleBonusDisabled}
												icon={
													selectedRows?.size
														? calculationsLogo
														: notCalculationsLogo
												}
												smallButtonWithTooltip
											/>
										</div>
									</HtmlTooltip>
								</Popover>
							)}
							<Filters
								query={query}
								setQuery={setQuery}
								cleanQuery={cleanQuery}
								tableTab={tableTab}
								handleSearch={handleSearch}
								{...props}
							/>
							{tableTab === "all" && checkFeature("Sales_Organization") && (
								<Popover
									trigger={["click"]}
									open={settleModal.open}
									overlayClassName="popup_add_edit_user paddingLess"
									onOpenChange={
										query?.salesOrganizationId !== null &&
										query?.status === "SETTLED" &&
										Array.from(selectedRows)?.[0]
											? handleSettleModal
											: null
									}
									placement="bottomRight"
									content={
										<SettleOrders
											handleClose={handleCloseSettleModal}
											selectedRows={selectedRows}
											clearSelectedRows={clearSelectedRows}
											initialValues={settleModal.initialValuesForSalesOrg}
											tableTab={tableTab}
											open={settleModal.open}
											selectedRowObjects={selectedRowObjects}
										/>
									}
								>
									<HtmlTooltip
										title={
											<InformationTooltip data={t("settleSalesOrgRules")} />
										}
									>
										<div className="wrapper__change-status">
											<Button
												border={
													selectedRows?.size > 0
														? "none"
														: "1px solid #C1C1C1"
												}
												handleChange={() => {}}
												hideTooltip
												disabled={
													query?.salesOrganizationId === null ||
													query?.status !== "SETTLED" ||
													!Array.from(selectedRows)?.[0]
												}
												icon={
													query?.salesOrganizationId === null ||
													query?.status !== "SETTLED" ||
													!Array.from(selectedRows)?.[0]
														? notCalculationsLogo
														: calculationsLogo
												}
												smallButtonWithTooltip
											/>
										</div>
									</HtmlTooltip>
								</Popover>
							)}
							<div className="wrapper__buttons">
								{checkRoles("exportOrders") && (
									<div
										className={
											totalSize > 0
												? "createuserExport"
												: "createuserExportDisabled"
										}
									>
										{Array.from(selectedRows)?.length > 0 ? (
											<>
												<HtmlTooltip
													placement="top"
													title={<div>{t("Download")} PDF</div>}
												>
													<img
														className="download_button"
														onClick={() =>
															downloadCsv(
																Array.from(selectedRows),
																"multiple",
															)
														}
														alt="download"
														src={downloadIcon}
													/>
												</HtmlTooltip>
											</>
										) : (
											<div
												className={
													totalSize > 0
														? "createuserExport"
														: "createuserExportDisabled"
												}
											>
												<HtmlTooltip
													placement="top"
													title={
														<div>
															{totalSize > 0
																? `${t("Download")} ${t(
																		"OrderList",
																  )}`
																: t("NoDataDownload")}
														</div>
													}
												>
													<img
														className="download_button"
														onClick={exportCsvRequest}
														alt="download"
														src={downloadIcon}
													/>
												</HtmlTooltip>
											</div>
										)}
									</div>
								)}
							</div>
							{checkRoles("changeStatusOrder") &&
								tableTab === "all" &&
								renderStatusOptions()}
							{tableTab === "all" && (
								<Popover
									trigger={["click"]}
									open={reactivateModal.open}
									overlayClassName="popup_reactivated_user paddingLess"
									onOpenChange={handleReactivateModal}
									placement="bottomRight"
									content={
										<SettleOrders
											handleClose={handleCloseReactivateModal}
											selectedRows={selectedRows}
											clearSelectedRows={clearSelectedRows}
											initialValues={reactivateModal.initialValues}
											title={t("reactivateContract")}
											button={t("reactivate")}
											statusValue="CONTRACT_REACTIVATED"
											tableTab={tableTab}
											open={reactivateModal.open}
											selectedRowObjects={selectedRowObjects}
										/>
									}
								></Popover>
							)}
						</div>
					)}
				</div>
				<div className="orders-page">
					{tab === "orders" ? (
						<Table
							columnsWithActions={columnsWithActions}
							selectedRows={selectedRows}
							setSelectedRows={setSelectedRows}
							filteredTableTabs={tableTabs}
							tableTab={tableTab}
							tableRef={tableRef}
							setActiveTab={setActiveTab}
							setReEditPage={setReEditPage}
							reEditPage={reEditPage}
							handlePreviewEdit={handlePreviewEdit}
							previewRow={previewRow}
							setSelectedRowObjects={setSelectedRowObjects}
							selectedRowObjects={selectedRowObjects}
						/>
					) : (
						<div className="loader__and__table-orderspage">
							<DataGridTable
								columns={
									tab === "infoChef" ? infoChefColumns : updatedStatusesColumns
								}
								enableCellSelect={false}
								data={tab === "infoChef" ? infoChefOrders : updatedStatuses}
								loading={statusesLoader}
								onRowClick={(rowIndex, row) =>
									tab === "statusHistory" && onRowClick(row)
								}
							/>
							{!previewRow && !showEditModal && statusesLoader && (
								<div className="infinitscroll_loader center">
									<TableSkeleton />
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	ordersState: state.app.orders.index,
	editOrderState: state.app.orders.edit,
	optionsClients: state.app.client.index.optionsClients,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	locations: state.app.locations.index.locations,
	optionsProjectCategories: state.app.projectCategories.clientCategory.categoriesOptions,
	usersOptions: state.app.users.index.usersOptions,
	allSalesOrganizations: state.app.salesOrganizations.index.allSalesOrganizations,
	loggedUser: state.app.users.index.loggedUser,
	statuses: state.app.orders.statuses.statuses,
});

const mapDispatchToProps = {
	editPage: orderActions.editPage,
	editSize: orderActions.editSize,
	editTeamSize: teamActions.editSize,
	getOrders: orderActions.getOrders,
	getOrderPreview: orderActions.getOrderPreview,
	downloadOrderById: orderActions.downloadOrderById,
	changeOrdersStatus: createOrderActions.changeOrdersStatus,
	fetchTariffsByDropdown: tariffActios.fetchTariffsByDropdown,
	fetchProjectCategoriesByDropdown: projectCategoriesActions.getClientCategoriesDropdown,
	fetchClientsByDrowdown: clientActions.fetchClientsByDropdown,
	toggleEditOrderModal: editOrderActions.toggleModal,
	navigate: navigation.navigate,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getInfoChefOrders: orderActions.getInfoChefOrders,
	fetchLocations: locationActions.fetchLocations,
	editLocationSize: locationActions.editSize,
	getUpdatedStatuses: orderActions.getUpdatedStatuses,
	getBillingMonths: billingMonthsActions.getBillingMonths,
	getUserDropdown: usersActions.getUserDropdown,
	getBonuses: categoryBonusesActions.fetchBonuses,
	getAllSalesOrganizations: salesOrgActions.getAllSalesOrganizations,
	getOrderStatuses: orderStatusAction.getOrderStatuses,
	setExportStatus: orderActions.setExportStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
