/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from "react";
import {FieldArray, Formik} from "formik";
import InputComponent from "@common/input";
import DropDownInputSp from "@common/dropDown/DropDownSp";
import CloseIcon from "@common/closeIcon/CloseIcon";
import ButtonComponent from "@common/button";
import {useTranslation} from "react-i18next";
import ChannelUsers from "@common/messagesComponents/ChannelUsers";
import DatePickerItem from "@common/DatePicker";
import moment from "moment";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import _ from "lodash";
import {Autocomplete, TextField} from "@mui/material";
import UserAccordion from "./UserAccordion";

const TeamForm = (props) => {
	const {
		elements,
		loading,
		initialValues,
		options,
		setShowModal,
		handleSubmitFr,
		isChannel = false,
		loggedUser,
		match,
		handleRemoveUserFromChannel,
		addText,
		editText,
		insideTopic,
		teams,
		users,
		clearTeamValues,
		id,
		endpointPermissions,
	} = props;
	const {validationSchema} = elements;
	const {t} = useTranslation();
	const [newArr, setNewArr] = useState(initialValues?.users ? initialValues?.users : []);
	const [inputValue, setInputValue] = useState("");
	const [headOfDepartment, setHeadOfDepartment] = useState(initialValues?.headOfDepartment?.id);
	const [canSubmit, setCanSubmit] = useState(true);
	const [headOfDepartmentOptions, setHeadOfDepartmentOptions] = useState([]);
	const [dates, setDates] = useState({
		from: null,
		to: null,
	});
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [teamLeader, setTeamLeader] = useState(initialValues?.teamLeader || null);
	const [usersFromState, setUsersFromState] = useState(
		initialValues?.users?.length > 0 ? initialValues?.users : [],
	);
	let newData = [];
	const isToDisabled = Boolean(initialValues.to !== null);
	// remove from option array if we have select user in table checkbox
	options.users = options?.users?.map((item) => {
		// eslint-disable-next-line no-unused-expressions
		const findUser = usersFromState?.find((usr) => usr?.id === item?.id);
		if (!findUser) {
			newData.push(item);
		}

		return item;
	});

	const isOwner = insideTopic?.ownerId === loggedUser.id;

	const trainers = usersFromState.filter((item) => item.trainer);
	const nonTrainerOrIntern = usersFromState.filter((item) => !item.trainer && !item.internship);
	const interns = usersFromState.filter((item) => item.internship);
	const teamLead = nonTrainerOrIntern.find((item) => item.id === teamLeader || null);
	const nonTrainerOrInternWithoutTeamLeader = nonTrainerOrIntern.filter(
		(item) => item.id !== teamLeader,
	);
	const orderedUsersFromState = [
		...(teamLead ? [teamLead] : []), // add team leader at the top of the list if it exists
		...trainers.flatMap((trainer) => [
			trainer,
			...interns.filter((intern) => intern.trainerId === trainer.id),
		]),
		...nonTrainerOrInternWithoutTeamLeader,
	];
	const largestTrainerDate =
		trainers?.length > 0 ? Math.max(...trainers.map((item) => item.toDate)) : undefined;
	useEffect(() => {
		setDates({
			from: initialValues?.from,
			to: initialValues?.to,
		});
	}, [initialValues?.from, initialValues?.to]);
	useEffect(() => {
		setUsersFromState(initialValues?.users);
	}, [initialValues?.users?.length]);
	useEffect(() => {
		if (!isChannel) {
			const groupTeamsByDates = _.groupBy(teams, (item) => `${item.from},${item.to}`);
			let filteredHeadOfDepartmentUsers = [...options?.users],
				filteredUsers = [...options?.users];
			const processTeam = (team) => {
				// skip checks for current team (on edit mode only)
				const newTeams = team?.filter((team) => team.id !== initialValues?.id);
				const members = _.flatMap(newTeams, "members").map((item) => item.id);
				const filteredMembers = members?.filter((member) => member !== headOfDepartment);
				// store non head of department users here
				const membersWithoutHeadOfDepartment = _.flatMap(newTeams, "members")
					?.filter((item) => !item.headOfDepartment)
					?.map((member) => member.id);
				filteredUsers = filteredUsers?.filter(
					(user) => !filteredMembers?.includes(user?.id),
				);
				// filter out users that are on membersWithoutHeadOfDepartment array
				// because an user can only be a head of department on the same time on different team
				// while other users can be on just one time at the same time
				filteredHeadOfDepartmentUsers = filteredHeadOfDepartmentUsers?.filter(
					(user) => !membersWithoutHeadOfDepartment.includes(user?.id),
				);
			};
			const allTeams = Object.entries(groupTeamsByDates);
			for (let i = 0; i < allTeams.length; i++) {
				const currentItem = allTeams[i];
				const [key, team] = currentItem;
				const [startDate, endDate] = key.split(",");
				const convertedStartDate = +startDate;
				if (endDate === "null") {
					if (dates?.to && !moment(dates?.to).isBefore(convertedStartDate)) {
						processTeam(team);
					} else if (!dates?.to) {
						processTeam(team);
					}
				} else if (endDate) {
					if (
						(dates.from && moment(dates.from).isBefore(+endDate)) ||
						(dates?.to && moment(dates?.to).isBefore(+endDate))
					) {
						processTeam(team);
					}
				}
			}
			setFilteredUsers(filteredUsers);

			const filteredMembersByRole = filteredHeadOfDepartmentUsers?.filter((user) =>
				endpointPermissions?.makeHeadOfDepartment?.includes(user?.role?.id),
			);

			setHeadOfDepartmentOptions(filteredMembersByRole);
			if (dates?.from !== null) {
				const userIds = filteredUsers?.map((item) => item.id);
				setUsersFromState((prevState) => {
					const filteredMembers = [];
					prevState.forEach((user) => {
						if (userIds.includes(user.id)) {
							filteredMembers.push(user);
						}
					});
					return filteredMembers;
				});
			}
		}
	}, [dates, headOfDepartment]);
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				if (initialValues.users?.length === usersFromState?.length && insideTopic) return;
				if (!canSubmit) {
					ToastErrorComponent(t("InternshipDatesInfo"));
					return;
				}
				if (usersFromState.length > 0) {
					handleSubmitFr({
						values: {
							name: values?.name,
							users: usersFromState,
							id: values?.id,
							from: values?.from,
							to: values?.to,
						},
						id: match?.params?.id || null,
						teamLeader,
						formActions: actions,
						type: insideTopic?.topicType,
						headOfDepartment: values?.headOfDepartment?.id,
						setShowModal,
					});
				} else {
					handleSubmitFr({
						values,
						id: match?.params?.id || null,
						teamLeader,
						formActions: actions,
						headOfDepartment: values.headOfDepartment.id,
						setShowModal,
					});
				}
			}}
			validationSchema={
				!id && !validationSchema?.edit ? validationSchema.add : validationSchema.edit
			}
			enableReinitialize={true}
		>
			{({values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
				<form
					autoComplete="off"
					noValidate
					onSubmit={handleSubmit}
					className="edit__and__create__form"
				>
					<div className="close__modal__icon__and__name">
						<h1>{`${id ? elements.btnEditText : elements.btnText}`}</h1>
						<CloseIcon
							className="close__modal__icon"
							click={() => {
								setShowModal(false);
								clearTeamValues();
							}}
						/>
					</div>
					{elements?.lists?.[id ? "edit" : "add"]?.map((element, index) => (
						<React.Fragment key={index}>
							{element.type === "input" && (
								<>
									{!isChannel && (
										<InputComponent
											name={element.field}
											placeholder={t("Name")}
											type={element.inputType}
											errorClass="errorClass"
											errors={errors?.[element?.field]}
											values={values?.[element?.field]}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched[element?.field]}
										/>
									)}
								</>
							)}
							{element.type === "datepicker" && (
								<>
									{!isChannel && (
										<div className="datepicker-teams">
											<div>
												<DatePickerItem
													disabled={id}
													maxDate={
														values.to === null || !values?.to
															? undefined
															: moment(values.to)
																	.add(-1, "day")
																	.valueOf()
													}
													value={values.from}
													label={t("StartDate")}
													onChange={(newValue) => {
														if (newValue) {
															setFieldValue(
																"from",
																moment(newValue)
																	.startOf("day")
																	.add(2, "hours")
																	.valueOf(),
															);
															setDates((prevState) => ({
																...prevState,
																from: moment(newValue)
																	.startOf("day")
																	.add(2, "hours")
																	.valueOf(),
															}));
															setHeadOfDepartment(null);
															setFieldValue("headOfDepartment", null);
														}
													}}
													errors={errors?.from}
													touched={touched.from}
												/>
											</div>
											<DatePickerItem
												minDate={moment(values.from)
													.add(1, "day")
													.valueOf()}
												errors={errors?.to}
												touched={touched?.to}
												disabled={
													(id && isToDisabled) ||
													values.from === null ||
													values.from === ""
												}
												label={t("EndDate")}
												value={values.to}
												onChange={(newValue) => {
													if (newValue) {
														setFieldValue(
															"to",
															moment(newValue)
																.startOf("day")
																.add(2, "hours")
																.valueOf(),
														);
														setDates((prevState) => ({
															...prevState,
															to: moment(newValue)
																.startOf("day")
																.add(2, "hours")
																.valueOf(),
														}));
													}
												}}
											/>
										</div>
									)}
								</>
							)}
							<FieldArray
								name="users"
								render={(arrayHelpers) => (
									<>
										{!isChannel && element.type === "dropdown" && (
											<div>
												<DropDownInputSp
													setInputValue={setInputValue}
													inputValue={inputValue}
													title="name"
													className={`${
														element?.currency && "dropown_currency"
													} ${element?.newClass && element.newClass} `}
													errors={errors[element?.field]}
													name={element.name}
													id="currencyId"
													onChange={(event, value) => {
														// remove selected value from options
														newData = newData.filter(
															(item) => item?.id !== value?.id,
														);
														setFilteredUsers((prevState) =>
															prevState?.filter(
																(item) => item.id !== value?.id,
															),
														);
														arrayHelpers.push(value);
														const arr = [...newArr];
														const findItem = newArr?.find(
															(item) => item?.id === value?.id,
														);
														if (!findItem) {
															arr.push(value);
														}
														setNewArr(arr);
														const onlyIds = usersFromState?.map(
															(item) => item.id,
														);
														if (!onlyIds.includes(value.id)) {
															setUsersFromState([
																...usersFromState,
																value,
															]);
														}
													}}
													// disabled={dates.from === null}
													options={filteredUsers?.filter(
														(user) => user.id !== headOfDepartment,
													)}
													touched={touched?.[element?.field]}
													value={null}
													placeholder={t("AddUser")}
												/>
											</div>
										)}
										{element.type === "formikDropdown" && (
											<Autocomplete
												options={headOfDepartmentOptions}
												className={`AutocompleteCl 	${
													element?.newClass && element.newClass
												}`}
												getOptionSelected={(option, value) =>
													option.id === value.id
												}
												getOptionLabel={(option) => option.name}
												value={values[element.field]}
												disabled={values?.from === null}
												disableClearable
												name={element.name}
												onChange={(_, value) => {
													setFieldValue(element.field, value);
													setHeadOfDepartment(value.id);
													setUsersFromState((prevState) => {
														const filteredState = prevState?.filter(
															(item) =>
																!item.headOfDepartment &&
																item.id !== value.id,
														);
														setFieldValue("users", filteredState);
														return [
															...filteredState,
															{
																...value,
																headOfDepartment: true,
															},
														];
													});
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														className="dropdown-option"
														label={element.title}
														placeholder={element.title}
													/>
												)}
												style={{
													border: "none",
													outline: "none",
												}}
											/>
										)}
										{element.type === "dropdown" && isChannel && isOwner && (
											<div>
												<DropDownInputSp
													setInputValue={setInputValue}
													inputValue={inputValue}
													title="name"
													className={`${
														element?.currency && "dropown_currency"
													} ${element?.newClass && element.newClass} `}
													errors={errors[element?.field]}
													name={element.name}
													id="currencyId"
													onChange={(event, value) => {
														// remove selected value from options
														newData = newData.filter(
															(item) => item?.id !== value?.id,
														);
														arrayHelpers.push(value);
														const arr = [...newArr];
														const findItem = newArr?.find(
															(item) => item?.id === value?.id,
														);
														if (!findItem) {
															arr.push(value);
														}
														setNewArr(arr);
														if (!usersFromState.includes(value)) {
															setUsersFromState([
																...usersFromState,
																value,
															]);
														}
													}}
													options={newData}
													touched={touched?.[element?.field]}
													value={null}
													placeholder={t("AddUser")}
												/>
											</div>
										)}
									</>
								)}
							/>
							{/* {!leaveChannelLoading && (
									<div className="leave__channel__wrapper">
										{isChannel && (
											<p
												onClick={() =>
													removeUserFromChannel({
														topicId: +match.params.id,
														userId: loggedUser.id,
														leaveBtn: true,
													})
												}
											>
												{t("LeaveChanel")}
											</p>
										)}
									</div>
								)} */}
						</React.Fragment>
					))}
					{orderedUsersFromState.length > 0 &&
						orderedUsersFromState
							?.filter((item) => !item.headOfDepartment)
							?.map((user) => (
								<>
									{!isChannel && (
										<UserAccordion
											isEditing={id}
											key={user?.id}
											id={user?.id}
											data={user}
											setNewArr={setNewArr}
											newArr={newArr}
											setTeamLeader={setTeamLeader}
											showStar={user?.id === teamLeader}
											teamLeader={teamLeader}
											usersFromState={usersFromState}
											setUsersFromState={setUsersFromState}
											teamDates={{from: values?.from, to: values?.to}}
											setCanSubmit={setCanSubmit}
											setFilteredUsers={setFilteredUsers}
											setHeadOfDepartmentOptions={setHeadOfDepartmentOptions}
											setFieldValue={setFieldValue}
											values={values}
											endpointPermissions={endpointPermissions}
										/>
									)}
								</>
							))}
					{isChannel && (
						<ChannelUsers
							topicId={parseInt(match.params.id)}
							removeUserFromChannel={handleRemoveUserFromChannel}
							setFieldValue={setFieldValue}
							value={values?.users}
							// users={concatAllUsers(values?.users)}
							users={usersFromState.filter(
								(user) =>
									!initialValues.users.some(
										(initialUser) => initialUser.id === user.id,
									),
							)}
							loggedUser={loggedUser?.id}
							usersFromState={usersFromState}
							setUsersFromState={setUsersFromState}
							isOwner={isOwner}
						/>
					)}
					{!isChannel && (
						<ButtonComponent
							disabled={loading}
							loading={loading}
							buttonText={id ? elements.btnEditText : elements.btnText}
							type="submit"
						/>
					)}
					{isChannel && isOwner && (
						<ButtonComponent
							disabled={loading}
							loading={loading}
							buttonText={id ? elements.btnEditText : elements.btnText}
							type="submit"
						/>
					)}
				</form>
			)}
		</Formik>
	);
};

export default TeamForm;
