/* eslint-disable max-len */
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import CustomAccordion from "@src/common/CustomAccordion";
import CustomFieldItem from "@src/common/customFieldItem";
import React, {useState, useMemo, useEffect} from "react";
import {useTranslation} from "react-i18next";
import ButtonComponent from "@src/common/button";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import {searchCustomFields} from "@src/utils/searchCustomFields";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import InputComponent from "@src/common/input";
import CustomSelect from "@src/common/Select";
import {filterFieldsBasedOnSelection} from "./utils";

const ClientFieldsSettings = ({
	clientFields,
	fieldSettings,
	updateSettings,
	loading,
	setFieldsSettingsData,
	statuses,
	client,
}) => {
	const {t} = useTranslation();
	const {date, fields, statusNameList} = fieldSettings;
	const [searchQuery, setSearchQuery] = useState("");
	const handleCheckbox = (value, item) => {
		const {checked} = value.target;
		const isSelected = fields?.includes(item.name);
		// if a field is checked and if it hasn't been checked before we add it to array
		// otherwise we should filter it out of the array
		setFieldsSettingsData({
			...fieldSettings,
			fields:
				checked && !isSelected
					? [item.name, ...fields]
					: fields?.filter((field) => field !== item.name),
		});
	};
	const handleSubmit = () => {
		if (!date) {
			ToastErrorComponent(t("CantDeleteWithoutDate"));
		} else {
			updateSettings();
		}
	};

	// this will return an object with two arrays which will be used to render
	// selected and unselected fields
	const filteredFields = useMemo(() => {
		const sourceFields = searchQuery
			? searchCustomFields(clientFields, searchQuery)
			: clientFields;
		return filterFieldsBasedOnSelection(sourceFields, fields);
	}, [searchQuery, clientFields, fields]);

	useEffect(() => {
		if (client?.scheduleDeleteStatusList) {
			setFieldsSettingsData({
				...fieldSettings,
				statusIds: client?.scheduleDeleteStatusList?.map((i) => i.statusId),
			});
		}
	}, []);
	return (
		<CustomAccordion title={t("FieldDeletionSettings")} className="contracts__accordion">
			<div className="clientFields__wrapper">
				<div className="clientFields__wrapper-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("ClientFieldsInfo")}</span>
				</div>
				<div className="clientFields__wrapper__filters">
					<div className="clientFields__wrapper__filters-container">
						<div className="dropdown--container">
							<p className="dropdown--container-label">{t("ChooseStatus")}</p>
							<CustomSelect
								options={statuses}
								onSelect={(_, value) => {
									setFieldsSettingsData({
										...fieldSettings,
										statusIds: [...fieldSettings?.statusIds, value?.id],
									});
								}}
								value={fieldSettings?.statusIds}
								onClear={() => {
									setFieldsSettingsData({
										...fieldSettings,
										statusIds: [],
									});
								}}
								mode="multiple"
							/>
						</div>
						<InputComponent
							values={date}
							type="number"
							hasInfoIcon
							label={t("ChooseDays")}
							infoText={t("FieldsDeletionDays")}
							min={1}
							handleChange={(e) => {
								setFieldsSettingsData({
									...fieldSettings,
									date: Number(e.target.value),
								});
							}}
						/>
					</div>

					<BigSearchBar
						handleSearch={(event) => {
							setSearchQuery(event.target.value);
						}}
						value={searchQuery}
						disabled={statusNameList?.length === 0}
					/>
				</div>
				<p className="client__fields__title">{t("DeleteFields")}</p>
				<div className="client__fields__container">
					{filteredFields.selected?.map((field, index) => (
						<CustomFieldItem
							item={field}
							key={index}
							fromCustomField={false}
							disabled={statusNameList?.length === 0}
							checked={fields?.includes(field.name)}
							handleCheckbox={handleCheckbox}
						/>
					))}
				</div>
				<div className="client__fields__breakLine" />
				<p className="client__fields__title">{t("SaveFields")}</p>
				<div className="client__fields__container">
					{filteredFields.unselected?.map((field, index) => (
						<CustomFieldItem
							item={field}
							key={index}
							disabled={statusNameList?.length === 0}
							checked={false}
							fromCustomField={false}
							handleCheckbox={handleCheckbox}
						/>
					))}
				</div>
				<div className="clientFields__wrapper-actions">
					<ButtonComponent
						buttonText={t("Save")}
						disabled={loading || statusNameList?.length === 0}
						loading={loading}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	fieldSettings: state.app.client.index.client.fieldSettings,
	statuses: state.app.orders.statuses.statuses,
	loading: state.app.client.index.isLoading,
	client: state.app.client.index.client,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
	setFieldsSettingsData: clientActions.setFieldsSettingsData,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientFieldsSettings));
