import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "../../../store/sagas/app/navigation";

function ClientCustomColumn(props) {
	const {row, navigate, link, canNavigate = true} = props;
	return (
		<div
			className="user__table__photo__and__name user__table-green"
			onClick={() => {
				if (canNavigate) {
					navigate(`${link}/${row?.id}/${row?.clientId}`);
				}
			}}
		>
			<p>{row.clientName}</p>
		</div>
	);
}

const mapDispatchToProps = {
	navigate: navigation.navigate,
};
export default connect(null, mapDispatchToProps)(withRouter(ClientCustomColumn));
