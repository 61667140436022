/* eslint-disable no-nested-ternary */
import ButtonComponent from "@common/button";
import React from "react";
import Modal from "@common/modal";
import {useTranslation} from "react-i18next";

function DeleteModal(props) {
	const {t} = useTranslation();
	const {
		setShowDeleteModal,
		confirm,
		word,
		open,
		handleClose,
		disabled = false,
		modalType = "delete",
		settle = false,
		calculationModal = false,
		draft = false,
		newTitle = "",
		unmatch = false,
		loading = false,
	} = props;

	const isDraft = draft
		? t("AreYouSureDraft")
		: unmatch
		? t("AreYouSureUnMatch")
		: t("AreYouSureDeactivate");

	return (
		<Modal open={open} handleClose={handleClose}>
			{calculationModal ? (
				<div className="delete__modal__component">
					<h1>{newTitle || t("AreUSureCalculation")}</h1>
					<div className="delete__modal__component__button">
						<ButtonComponent
							classNames="cancel__button"
							buttonText={t("Cancel")}
							onClick={() => setShowDeleteModal(false)}
						/>
						<ButtonComponent
							classNames="confirm__button"
							buttonText={t("Confirm")}
							disabled={disabled}
							onClick={confirm}
							loading={loading}
						/>
					</div>
				</div>
			) : (
				<div className="delete__modal__component">
					{}
					{settle ? (
						<h1> {t("AreYouSureSettle")} </h1>
					) : (
						<h1>
							{modalType === "delete" ? (
								isDraft
							) : (
								// t`AreYouSureDeactivate`
								<>
									{modalType === "permanantDelete"
										? t`AreYouSureDelete`
										: t`AreYouSureActivate`}
								</>
							)}
							{word}?
						</h1>
					)}
					<div className="delete__modal__component__button">
						<ButtonComponent
							classNames="cancel__button"
							buttonText={t("Cancel")}
							onClick={() => setShowDeleteModal(false)}
						/>
						<ButtonComponent
							classNames="confirm__button"
							buttonText={t("Confirm")}
							disabled={disabled}
							onClick={confirm}
							loading={loading}
						/>
					</div>
				</div>
			)}
		</Modal>
	);
}

export default DeleteModal;
